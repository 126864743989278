import type { ScriptEditorStore } from '../stores/scripteditor';
import { createTypedActions } from '../stores/AltStore';

class ScriptEditorActions {
  init: ScriptEditorStore['handleInit'] = function() {
    this.dispatch();
  };

  close: ScriptEditorStore['handleClose'] = function() {
    this.dispatch();
  };

  updateText: ScriptEditorStore['handleUpdateText'] = function(options) {
    this.dispatch(options);
  };

  setFilter: ScriptEditorStore['handleSetFilter'] = function(options) {
    this.dispatch(options);
  };

  updateFilteredText: ScriptEditorStore['handleUpdateFilteredText'] = function(options) {
    this.dispatch(options);
  };

  commitChanges: ScriptEditorStore['handleCommitChanges'] = function() {
    this.dispatch();
  };

  frameInserted: ScriptEditorStore['handleFrameInsert'] = function () {
    this.dispatch();
  };
}

const actions = createTypedActions<ScriptEditorActions>(ScriptEditorActions);
export { actions as ScriptEditorActions };
