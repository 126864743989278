/** @format */
/* eslint-disable no-sequences */
export function loadProfitwell() {
  if (!BoordsConfig.email) return;
  (function (i, s, o, g, r, a, m) {
    i['ProfitWellObject'] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    'https://dna8twue3dlxq.cloudfront.net/js/profitwell.js',
    'profitwell',
  );
  profitwell('auth_token', '7cbdd04a3c083925eb686c896bccefc7');
  profitwell('user_email', BoordsConfig.email);
}
