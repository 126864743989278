/** @format */
import React from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FeatureInline, type FeatureName } from '../features/Feature';

interface BeforeAfterPanelProps {
  type: 'before' | 'after';
  items?: string[];
  features?: FeatureName[];
}

export const QuizBeforeAfter: React.FC<BeforeAfterPanelProps> = ({
  type,
  items,
  features,
}) => (
  <div
    className={classNames(
      'px-4 pt-3 pb-5 sm:px-6 sm:pt-4 sm:pb-8 border border-2 rounded-lg ',
      type === 'before'
        ? 'text-brand-pink bg-brand-pink-25/20 border-brand-pink'
        : 'text-type-green bg-type-green/5 border-type-green',
    )}
  >
    <h3 className="mb-3 text-base font-semibold sm:text-lg ">
      {type === 'before' ? <>{`Before Boords`}</> : <>{`After Boords`}</>}
    </h3>
    <div className="space-y-2 pt-[0.1rem]">
      {items &&
        items.map((item, idx) => (
          <div key={`before-${idx}`} className="flex items-center py-[0.09rem]">
            {type === 'before' ? (
              <XMarkIcon className="flex-shrink-0 w-5 h-5" />
            ) : (
              <CheckIcon className="flex-shrink-0 w-5 h-5" />
            )}

            <div className="flex-auto ml-2 text-sm">{item}</div>
          </div>
        ))}
      {features &&
        features.map((feature) => (
          <FeatureInline name={feature} key={`feature-${feature}`} />
        ))}
    </div>
  </div>
);
