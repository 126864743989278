/** @prettier */
import { isString, isNumber, isNaN } from 'underscore';

/** When necessary, it will convert the passed value to a number. If this is not
 possible, it will either call the `onError` function, or throw an error * */
export function ensureNumber(num): number;
export function ensureNumber(
  num,
  onError?: (err: Error) => void,
): number | undefined;
export function ensureNumber(num, onError?: (err: Error) => void) {
  let output = num;
  if (isString(num)) output = parseInt(num, 10);
  if (!isNumber(output) || isNaN(output)) {
    const err = new Error(
      `Value could not be converted to a number, got "${num}", which is a ${typeof num}`,
    );

    if (onError) {
      onError(err);
    } else {
      throw err;
    }
  }

  return output;
}
