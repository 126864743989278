/**@prettier */
import * as React from 'react';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import type { DetailedFrame } from 'javascripts/types/frame';
import { FrameActions } from 'javascripts/flux/actions/frame';
import { useTranslation } from 'react-i18next';

interface FrameImageStatusOverlayProps {
  title: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
}

export const FrameImageStatusOverlay: React.FC<
  FrameImageStatusOverlayProps
> = ({ title, onClick, children }) => {
  return (
    <div
      className="absolute inset-0 z-20 flex items-center justify-center h-full"
      onClick={onClick}
    >
      <div className="absolute inset-0 bg-black rounded-sm opacity-40"></div>
      <div className="flex items-center gap-4 z-10 bg-black rounded px-3.5 pt-1 pb-1.5">
        <span className="text-sm text-white">{title}</span>
        {children}
      </div>
    </div>
  );
};

export const FrameImageStatus: React.FC<{
  frame: DetailedFrame;
}> = (props) => {
  const { t } = useTranslation();
  const handleOverlayErrorClick = React.useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(
    (e) => {
      e.preventDefault();
      FrameActions.setImageStatus({
        frame: props.frame,
        image_status: 'complete',
      });
    },
    [props.frame],
  );

  var status = props.frame.image_status;
  if (!status || status === 'complete') return null;
  if (status === 'image_error') {
    return (
      <FrameImageStatusOverlay
        onClick={handleOverlayErrorClick}
        title={props.frame.error_message!}
      />
    );
  } else {
    return (
      <FrameImageStatusOverlay title={t('frames.status.' + status)}>
        <LoadingIndicator className="w-4 h-4 text-white" />
      </FrameImageStatusOverlay>
    );
  }
};
