/** @prettier */
import * as React from 'react';
import { PanelbarFrameAssets } from './ImageLibrary/PanelbarFrameAssets';
import type { IStoryboard } from '../../types/storyboard';
import classNames from 'classnames';
import { PanelbarImageAssets } from './ImageLibrary/PanelbarImageAssets';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { canDropImages } from '../shared/canDropImages';
import type { fileDropResult } from '../shared/dragAndDropUtils';
import { AssetsActions } from '../../flux/actions/assets';
import { once } from 'underscore';
import { usePersistedState } from '../../helpers/usePersistedState';
import { useStore } from '../../helpers/useStore';
import Tip from '../shared/Tip';
import type { allowedStoryboardViews } from '../../flux/stores/storyboard';
import Tabs from 'blackbird/components/tabs/Tabs';
import { useTranslation } from 'react-i18next';
import { eventSource } from 'blackbird/helpers/eventContextHelper';
import { PanelbarImageLibraryIcon } from './PanelbarImageLibraryIcon';
import FrameOverlayBrowser from '../frame_editor/FrameOverlayBrowser';
import { UnsplashCategories } from '../frame_editor/toolbar/image_search/UnsplashCategories';
import { IconFinderContainer } from '../frame_editor/toolbar/image_search/IconFinderContainer';
import { PanelbarBackgroundSelector } from './PanelbarBackgroundSelector';
import { GeneratorForm } from 'blackbird/components/generator/GeneratorForm';
import logger from 'javascripts/helpers/logger';
import { GeneratorContext } from 'blackbird/components/generator/GeneratorContext';
import type { DetailedFrame, IFrame } from 'javascripts/types/frame';
import { PanelbarLibraryTitle } from './PanelbarLibraryTitle';
import { GeneratorTokenCount } from 'blackbird/components/generator/GeneratorTokenCount';
import { TourHintable } from '../tours/TourHintable';
import { PanelbarShapes } from './PanelbarShapes';
const LS_KEY = 'imageLibraryTabSelected';

type categoryTypes =
  | 'frames'
  | 'uploads'
  | 'photos'
  | 'iconFinder'
  | 'shapes'
  | 'cameraMoves'
  | 'ai'
  | 'text'
  | 'backgrounds'
  | 'illustrations';

const isAllowed = (
  category: categoryTypes,
  hasFrameFocus: boolean,
  storyboardView: allowedStoryboardViews,
) => {
  if (category === 'uploads') {
    return storyboardView === 'grid' || hasFrameFocus;
  }

  return ['frames', 'uploads', 'photos'].includes(category) || hasFrameFocus;
};

export const PanelbarImageLibrary: React.FC<{
  frameInformation?: React.ReactElement;
  storyboard: IStoryboard;
  frames: IFrame[];
  newVersion?: boolean;
  hasFrameFocus: boolean;
}> = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'imageLibrary',
  });

  // Set the default values (if no local state is present)
  const defaultFrameFocusView: categoryTypes = BoordsConfig.ShowGeneratorUI
    ? 'ai'
    : 'iconFinder';
  const defaultGridView: categoryTypes = 'photos';

  // load either default panel, or local state
  const [activeCategory, setActiveCategory] = usePersistedState<categoryTypes>(
    LS_KEY,
    props.hasFrameFocus ? defaultFrameFocusView : defaultGridView,
  );
  const { setStoryboard, setFrame, setIsOpen } =
    React.useContext(GeneratorContext);

  const storyboardView = useStore<allowedStoryboardViews>(
    'storyboard',
    (s) => s.view,
  );

  const activeIndex = useStore<number>('frameFocus', (f) => f.activeIndex);

  // Update the tab if the storyboard view changes
  React.useEffect(() => {
    setActiveCategory((current: categoryTypes) => {
      return !isAllowed(current, props.hasFrameFocus, storyboardView)
        ? 'frames'
        : current;
    }, false);
  }, [storyboardView, props.hasFrameFocus, setActiveCategory]);

  React.useEffect(() => {
    Track.event.defer(`panelbar_open`, {
      panelType: 'imageLibrary',
      subPanel: activeCategory,
      context: eventSource(undefined),
      category: 'Product',
    });
  }, [activeCategory]);

  // Load AI data
  React.useEffect(() => {
    if (activeCategory === 'ai') {
      setStoryboard(props.storyboard);
      setFrame(props.frames[activeIndex] as DetailedFrame);
    }
  }, [activeCategory, props.frames, activeIndex, props.storyboard]);

  React.useEffect(() => {
    setIsOpen(false);
  }, [FrameFocusActions.close]);

  const handleTabSelect = React.useCallback(
    (category) => {
      const newCategory = category as categoryTypes;
      if (isAllowed(newCategory, props.hasFrameFocus, storyboardView))
        setActiveCategory(newCategory);
    },
    [setActiveCategory, props.hasFrameFocus, storyboardView],
  );

  const switchToUploadCategory = React.useCallback(
    once(() => setActiveCategory('uploads')),
    [setActiveCategory],
  );

  const uploadTabEnabled = isAllowed(
    'uploads',
    props.hasFrameFocus,
    storyboardView,
  );

  const [collectedProps, drop] = useDrop(() => ({
    accept: NativeTypes.FILE,
    canDrop: (props, monitor) =>
      uploadTabEnabled && canDropImages(false)!(props, monitor),
    collect: (monitor) => {
      return {
        hoverDrop: monitor.canDrop() && monitor.isOver(),
        hasError:
          uploadTabEnabled &&
          monitor.isOver() &&
          !canDropImages(false)!(null, monitor),
      };
    },
    hover: uploadTabEnabled ? switchToUploadCategory : undefined,
    drop(currentProps, monitor) {
      const item: fileDropResult = monitor.getItem();
      const file = item.items?.[0]?.getAsFile();

      if (file) {
        AssetsActions.uploadImageAsset.defer({
          storyboardId: props.storyboard.id,
          projectId: props.storyboard.project.id,
          teamId: props.storyboard.project.owner.id,
          target: 'project',
          file,
        });
      }
    },
  }));
  const { hoverDrop, hasError } = collectedProps;
  const assetTab = uploadTabEnabled
    ? [
        {
          caption: t('assets'),
          key: 'uploads',
        },
      ]
    : [];
  const IconTab = props.hasFrameFocus
    ? [
        {
          caption: t('icons'),
          key: 'iconFinder',
        },
      ]
    : [];

  const ExtendedTabs = props.hasFrameFocus
    ? [
        {
          caption: t('backgrounds.tab'),
          key: 'backgrounds',
        },
        {
          caption: t('cameraMoves'),
          key: 'cameraMoves',
        },
        {
          caption: t('shapes.tab'),
          key: 'shapes',
        },
      ]
    : [];

  const AITab =
    props.hasFrameFocus && BoordsConfig.ShowGeneratorUI
      ? [
          {
            caption: t('ai'),
            key: 'ai',
          },
        ]
      : [];

  const tabs = [
    ...IconTab,
    ...AITab,
    {
      caption: t('stock'),
      key: 'photos',
    },
    ...ExtendedTabs,
    {
      caption: t('frames.title'),
      key: 'frames',
    },
    ...assetTab,
  ];

  return (
    <div
      className={classNames('flex flex-col', props.newVersion && 'min-h-full')}
    >
      {props.frameInformation && <>{props.frameInformation}</>}

      <div className={classNames('flex bg-white flex-auto min-h-full')}>
        <div className={classNames('flex-auto min-h-full')}>
          {!props.newVersion && (
            <div className="mx-6 mt-4">
              <Tabs
                type="rounded"
                size="lg"
                onSelect={handleTabSelect}
                tabs={tabs}
                defaultValue={activeCategory}
                overflowScroll
              />
            </div>
          )}
          <div
            className={classNames(
              'relative flex flex-col flex-auto',
              props.newVersion && 'min-h-full',
            )}
            ref={drop}
          >
            {activeCategory === 'frames' ? (
              <>
                <PanelbarLibraryTitle
                  title={`Frame Images`}
                  subtitle={`Insert images from existing storyboard frames`}
                />
                <PanelbarFrameAssets
                  storyboardId={props.storyboard.id}
                  storyboardSlug={props.storyboard.short_slug}
                />
              </>
            ) : activeCategory === 'ai' ? (
              <>
                <PanelbarLibraryTitle
                  title={`Image Generator`}
                  subtitle={`Create storyboard images with AI`}
                >
                  <GeneratorTokenCount />
                </PanelbarLibraryTitle>
                <div className="p-6">
                  <GeneratorForm panelbar />
                </div>
              </>
            ) : activeCategory === 'uploads' ? (
              <>
                <PanelbarLibraryTitle
                  title={`Image Uploads`}
                  subtitle={`Upload images to use in your storyboards`}
                />
                <PanelbarImageAssets
                  storyboard={props.storyboard}
                  hasEmptyState={!hoverDrop}
                  hasError={hasError}
                  hasFrameFocus={props.hasFrameFocus}
                />
                {!props.hasFrameFocus && (
                  <div className="p-6">
                    <Tip text={t('draggingAssetToGrid')} />
                  </div>
                )}
              </>
            ) : activeCategory === 'iconFinder' ? (
              <>
                <PanelbarLibraryTitle
                  title={`Icon Library`}
                  subtitle={`Create storyboards with pre-designed assets`}
                />
                <IconFinderContainer />
              </>
            ) : activeCategory === 'cameraMoves' ? (
              <>
                <PanelbarLibraryTitle
                  title={`Camera Moves`}
                  subtitle={`Overlay arrows to show camera movement`}
                />
                <div className="flex-auto min-h-full px-3 pt-4">
                  <FrameOverlayBrowser hideTitle />
                </div>
              </>
            ) : activeCategory === 'backgrounds' ? (
              <PanelbarBackgroundSelector
                teamId={props.storyboard.project.owner.id}
              />
            ) : activeCategory === 'shapes' ? (
              <>
                <PanelbarLibraryTitle
                  title={t('shapes.title')}
                  subtitle={t('shapes.subtitle')}
                />
                <div className="min-h-full px-3 pt-4">
                  <PanelbarShapes />
                </div>
              </>
            ) : (
              <>
                <PanelbarLibraryTitle
                  title={`Photo Library`}
                  subtitle={`Browse thousands of Unsplash photos`}
                />
                <UnsplashCategories
                  className="px-3 pt-4 pb-8"
                  innerPadding="px-3"
                />
              </>
            )}
          </div>
        </div>

        {props.newVersion && (
          <div className="flex-shrink-0 min-h-full text-center border-l w-14 xl:w-16 border-border">
            <TourHintable
              overlayPosition="left"
              step="imageLibrarySidebar"
              deferred
            >
              <div className="sticky top-[6.5rem] pb-6">
                {tabs.map((tab) => (
                  <TourHintable
                    overlayPosition="left"
                    step="wizardCameraMovesMenu"
                    key={tab.key}
                    canShow={tab.key === 'cameraMoves'}
                    hideNext
                    deferred
                  >
                    <div
                      className={classNames(
                        'flex flex-col items-center mt-4 xl:mt-6 space-y-1 cursor-pointer hover:text-type-primary group',
                        tab.key === activeCategory
                          ? 'text-type-primary'
                          : 'text-type-disabled',
                      )}
                      onClick={() => handleTabSelect(tab.key)}
                    >
                      <PanelbarImageLibraryIcon
                        id={tab.key}
                        active={tab.key === activeCategory}
                      />
                      <div className="px-2 text-xs">{tab.caption}</div>
                    </div>
                  </TourHintable>
                ))}
              </div>
            </TourHintable>
          </div>
        )}
      </div>
    </div>
  );
};
