const _ = require('underscore');
(() => {
  class WhosOnlineStore {

    constructor() {
      this.channel       = null;
      this.members       = [];
      this.storyboard_id = null;
      this.is_listening  = false;
      this.pusher_loaded = false;

      this.bindListeners({
        handleInit:          WhosOnlineActions.INIT,
        handleUnsub:         WhosOnlineActions.UNSUB,
        handlePusherLoaded:  RealtimeActions.PUSHER_LOADED,
      });
    }

    handlePusherLoaded() {
      this.pusher_loaded = true;
      this._tryRealtime();
    }

    handleInit(data) {
      this.storyboard_id = data.storyboard_id;
      this._tryRealtime();
    }

    _tryRealtime() {
      if (!this.is_listening && this.pusher_loaded && !_.isNull(this.storyboard_id)) {
        this.is_listening = true;
        this.channel      = window.pusher.subscribe("presence-storyboard-" + this.storyboard_id);

        this.channel.bind("pusher:subscription_succeeded", function(members) {
          this._updateMembers();
        }.bind(this));

        this.channel.bind('pusher:member_added', function(member){
          this._updateMembers();
          RequestActions.success.defer(member.info.name + " opened this storyboard");
        }.bind(this));

        this.channel.bind('pusher:member_removed', function(member){
          this._updateMembers();
          RequestActions.success.defer(member.info.name + " left this storyboard");
        }.bind(this));
      }
    }

    handleUnsub(data) {
      window.pusher.unsubscribe("presence-storyboard-" + data.storyboard_id);
    }

    _updateMembers() {
      this.members = [];
      this.channel.members.each(function(member) {
        this.members.push(member);
        this.emitChange();
      }.bind(this));
    }
  }

  window.WhosOnlineStore = alt.createStore(WhosOnlineStore, 'WhosOnlineStore');

})();
