/** @prettier */
import * as React from 'react';
import { sortBy } from 'underscore';
import { TextSearch } from '../shared/TextSearch';
import type { ArchivedStoryboard as Storyboard } from 'javascripts/flux/stores/archive';
import { useMaintainSize } from '../shared/useMaintainSize';
import { EmptyState } from 'blackbird/components/feedback/empty-state/EmptyState';
import { useTranslation } from 'react-i18next';

const propsToSearch: (keyof Storyboard)[] = ['document_name'];

export const ArchivedStoryboards: React.FC<{
  storyboards: Storyboard[];
}> = (props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useMaintainSize(containerRef, [props.storyboards]);

  const storyboards = React.useMemo(
    () =>
      sortBy(props.storyboards, (p) =>
        new Date(p.deleted_at!).valueOf(),
      ).reverse(),
    [props.storyboards],
  );

  if (props.storyboards.length < 1) {
    return <EmptyState>{t('modals.archive.empty.storyboards')}</EmptyState>;
  }

  return (
    <TextSearch options={storyboards} propsToSearch={propsToSearch}>
      {(filteredOptions) => (
        <div className="w-full" ref={containerRef}>
          {filteredOptions.map((s) => (
            <ArchivedStoryboard key={s.id} storyboard={s} />
          ))}
        </div>
      )}
    </TextSearch>
  );
};
