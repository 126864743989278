const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const XhrErrorMessage = require('./XhrErrorMessage');
const Container = require('../shared/Container').default;
const { Button } = require('../shared/Button');

require('../../flux/stores/xhr_error');

const XhrErrorContainer = createReactClass({
  displayName: 'XhrErrorContainer',

  propTypes:      {
    status_code:  PropTypes.number.isRequired,
    context:      PropTypes.string.isRequired,
    show:         PropTypes.bool.isRequired,
  },

  _reload(e) {
    e.preventDefault();
    window.location.reload();
  },

  _status(e) {
    e.preventDefault();
    window.location = "http://status.boords.com";
  },

  _dashboard(e) {
    e.preventDefault();
    window.location = "/";
  },

  render() {
    var possibleEmptyErrors = ["An Ad Blocking plugin is preventing access to Boords", "A VPN or Firewall is restricting access", "Your internet connection has briefly dropped-out"];
    var reloadCta = <Button type="button" preset="purple" text="I've checked these, reload the page" onClick={this._reload} dotsOnClick hasShadow />;
    var statusCta = <Button type="button" preset="purple" text="Go to Status Page" onClick={this._status} backgroundColors="lh-solid bg-purple shadow-btn white" padding="ph4 pv3" dotsOnClick={true} />;

    if (!this.props.show) {
      return null;
    }
    if (this.props.status_code === 0) {

      if (this.props.context === "dashboard") {
        return (
          <XhrErrorMessage
            {...this.props}
            title="Sorry, something is preventing us from loading your dashboard"
            subtitle="Don't worry, your projects and storyboards are still safe! There's just something stopping us from fetching them, likely one of the following. Please check them, then try again."
            listItems={possibleEmptyErrors}
            cta={reloadCta}
          />
        );
      } else {
        return (
          <XhrErrorMessage
            {...this.props}
            title="Sorry, something is preventing us from loading this storyboard"
            subtitle="Don't worry, your storyboard is still safe! There's just something stopping us from loading it, likely one of the following:"
            listItems={possibleEmptyErrors}
            cta={reloadCta}
          />
        );
      }

    } else if (/5[0-9][0-9]$/.test(this.props.status_code)) {

      if (this.props.context === "dashboard") {
        return (
          <XhrErrorMessage
            {...this.props}
            title="Sorry, we're having trouble fetching your dashboard"
            subtitle="We're experiencing a temporary issue with our server which means we can't load your projects and storyboards just now. We have been notified of the error, and The Boords Team are working on a fix. We're very sorry for any inconvenience caused, and hope to be back up and running as soon as possible. Please check back a little later."
            cta={statusCta}
          />
        );
      } else {
        return (
          <XhrErrorMessage
            {...this.props}
            title="Sorry, we're having trouble fetching your storyboard"
            subtitle="We're experiencing a temporary issue with our server which means we can't load your storyboard just now. The Boords Team are aware and are working on a fix. We're very sorry for any inconvenience caused, and hope to be back up and running as soon as possible. Please check back a little later."
            cta={statusCta}
          />
        );
      }

    } else if (/4[0-9][0-9]$/.test(this.props.status_code)) {
      if (this.props.context === "dashboard") {
        return (
          <XhrErrorMessage
            {...this.props}
            title="Sorry, we couln't load your dashboard"
            subtitle="Please contact us via the chat icon in the bottom right corner and we'll help you as soon as we can"
          />
        );
      } else {
        return (
          <XhrErrorMessage
            {...this.props}
            title="Storyboard not found"
            subtitle="Perhaps the URL has changed since you last edited it?"
            cta={<Button type="button" preset="purple" text="Go to Dashboard" onClick={this._dashboard} dotsOnClick hasShadow />}
          />
        );
      }
    } else {
      return null;
    }
  }
})

window.XhrErrorContainer = Container('XhrError')(XhrErrorContainer);
