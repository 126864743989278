const _ = require('underscore');

module.exports = length => {
  const simplifyFactor = 9;
  const valueAt = ms => {
    // Reaches peak every 2000ms, but start in the middle
    const normalizedValue = (ms / 2000) + (2000 / 2);
    const valueInRadians = (normalizedValue * 360) * Math.PI / 180
    return (Math.sin(valueInRadians) + 1) / 2;
  };

  return _.times(
    Math.round(length / simplifyFactor),
    i => valueAt(i * simplifyFactor)
  );
}
