/** @prettier */
import { getPDFIcon } from './loadPDFIcons';
import type { PDFDocumentStyle, DocInfo } from '../types';
import { createTextFactory } from './createText';
import { getCurrentPageNumber } from './getCurrentPageNumber';
import { createPDFImage } from './PDFImage';
import { isNumber } from 'underscore';

interface Props {
  style: Pick<
    PDFDocumentStyle,
    | 'margin'
    | 'footerFontsize'
    | 'colors'
    | 'showPageNumber'
    | 'footerLogoMaxHeight'
    | 'footerLogoMaxWidth'
    | 'footerMargin'
  >;
  text?: string;
  bottom: number;
  debug?: boolean;
  pageNumber?: number;
}

export const createPDFFooter = (docInfo: DocInfo, props: Props) => {
  const { style } = props;
  const maxLogoWidth = style.footerLogoMaxWidth;
  const maxLogoHeight = style.footerLogoMaxHeight;
  const doc = docInfo.doc;

  const pageNumber = isNumber(props.pageNumber)
    ? String(props.pageNumber + 1)
    : getCurrentPageNumber(doc) - 1;

  const createText = createTextFactory(docInfo);
  const docWidth = doc.internal.pageSize.width;

  const marginSide = style.footerMargin.side;
  const availableWidth = docWidth - marginSide * 2;

  const x = marginSide;
  const usedDimensions = {
    width: availableWidth,
    height: 0,
  };

  let pageNumberSize = { width: 0, height: style.footerFontsize };

  if (style.showPageNumber) {
    // Page number (we have to subtract one, because the first page is left
    // blank (see bottom of PDFRenderer)
    pageNumberSize = createText(pageNumber, {
      x: docWidth - marginSide,
      y: props.bottom,
      lineHeightFactor: 1,
      maxWidth: availableWidth,
      originY: 'bottom',
      fontSize: style.footerFontsize,
      color: style.colors.light,
      align: 'right',
    });

    pageNumberSize.width += 5;
  }

  let logo;
  if (getPDFIcon('footerLogo')) {
    const imageInfo = getPDFIcon('footerLogo')!;
    logo = createPDFImage(docInfo, {
      imageInfo,
      x: docWidth - marginSide - pageNumberSize.width,
      maxHeight: maxLogoHeight,
      maxWidth: maxLogoWidth,
      y: props.bottom - pageNumberSize.height / 2,
      originX: 'right',
      originY: 'middle',
    });

    if (imageInfo.originalUrl.indexOf('made-with-boords') > 0) {
      doc.link(logo.x, logo.y, logo.width, logo.height, {
        url: 'https://boords.com/?from_pdf=true',
      });
    }
  }

  // Footer text (document name)
  if (props.text) {
    createText(props.text, {
      x: x,
      y: props.bottom,
      lineHeightFactor: 1,
      maxWidth: availableWidth,
      originY: 'bottom',
      fontSize: style.footerFontsize,
      color: style.colors.light,
    });
  }

  usedDimensions.height += Math.max(
    logo ? logo.height / 2 : 0,
    pageNumberSize.height,
  );

  if (props.debug) {
    doc.setDrawColor(255, 200, 200);
    doc.rect(
      x,
      props.bottom - usedDimensions.height,
      usedDimensions.width,
      usedDimensions.height,
    );
  }

  return usedDimensions;
};
