/** @format */

import type { WizardItemType } from './types';
import type { availableTourNames } from 'javascripts/flux/stores/tours';

const wizardUrlWithTour = (
  path: string,
  tourName: availableTourNames,
  queryString: '&' | '?' = '&',
) => `${path}${queryString}tour=${tourName}`;

const wizardItemClickTrack = (itemType: WizardItemType) => {
  Track.event.defer(`wizard_item_click`, {
    itemType,
    posthogCapture: true,
  });
};

export const wizardActionMap = (
  itemType: WizardItemType,
): (() => void | null) => {
  if (itemType === 'create-storyboard') {
    return () => {
      wizardItemClickTrack(itemType);
      window.location.href = '/storyboards/new';
    };
  } else if (itemType === 'add-subdomain') {
    return () => {
      wizardItemClickTrack(itemType);
      window.location.href = '/settings/team/domain';
    };
  } else if (itemType === 'add-image') {
    return () => {
      wizardItemClickTrack(itemType);
      window.location.href = wizardUrlWithTour(
        '/storyboards/first?forcefirst=true&panel=editor',
        'wizardAddImage',
      );
    };
  } else if (itemType === 'camera-moves') {
    return () => {
      wizardItemClickTrack(itemType);
      window.location.href = wizardUrlWithTour(
        '/storyboards/first?forcefirst=true&panel=editor',
        'wizardCameraMoves',
      );
    };
  } else if (itemType === 'play-animatic') {
    return () => {
      wizardItemClickTrack(itemType);
      window.location.href = wizardUrlWithTour(
        '/storyboards/first?forcefirst=true&panel=player',
        'wizardAnimatic',
      );
    };
  } else if (itemType === 'invite-teammates') {
    return () => {
      wizardItemClickTrack(itemType);
      window.location.href = '/settings/team/invite';
    };
  } else {
    return () => {
      null;
    };
  }
};
