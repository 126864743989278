/** @prettier */

import { useStore } from '../../../../javascripts/helpers/useStore';
import type { StoryboardStore } from '../../../../javascripts/flux/stores/storyboard';
import type { StoryboardAnalysisStore } from '../../../../javascripts/flux/stores/storyboardAnalysis';
import React from 'react';
import { useStoreAction } from 'javascripts/helpers/useStoreAction';
import { useOnMount } from 'javascripts/helpers/useOnMount';
import { toMins } from 'javascripts/helpers/format-time';
import { useRollout } from 'javascripts/helpers/rollout';

interface StoryboardDurationContainerProps {
  storyboardId: number;
}

interface StoryboardDurationProps {
  duration?: number;
}
export const StoryboardDuration: React.FC<StoryboardDurationProps> = (
  props,
) => {
  return (
    <div className="flex items-center text-type-subdued whitespace-nowrap">
      {toMins(props.duration ?? 0, false)} Duration
    </div>
  );
};

export const useCanShowDuration = () => {
  const storyboardDurationEnabled = useRollout('Storyboardduration');
  return useStore<boolean, StoryboardStore>(
    'storyboard',
    (s) =>
      storyboardDurationEnabled &&
      (s.storyboard.preferences?.show_storyboard_duration ?? true),
  );
};

export const StoryboardDurationContainer: React.FC<
  StoryboardDurationContainerProps
> = (props) => {
  const { storyboardId } = props;

  const duration = useStore<number | undefined, StoryboardAnalysisStore>(
    'storyboardAnalysis',
    (store) => store.analysis[storyboardId]?.duration,
  );

  const init = useStoreAction('StoryboardAnalysisActions', 'init');
  useOnMount(init);

  return <StoryboardDuration duration={duration} />;
};
