/** @prettier */
import classNames from 'classnames';
import * as React from 'react';

interface ImageSearchBreadcrumbProps {
  onClick: () => void;
  title: string;
  noun: string;
  count?: number;
  newLine?: boolean;
}

export const ImageSearchBreadcrumb: React.FC<ImageSearchBreadcrumbProps> = ({
  onClick,
  newLine,
  count,
  title,
  noun,
}): React.ReactElement => {
  return (
    <div
      className={classNames(
        'flex ',
        newLine ? 'flex-col items-start mb-5' : 'mb-4 items-center',
      )}
    >
      <div
        className={classNames(
          'flex items-center flex-shrink-0 cursor-pointer text-type-disabled hover:text-type-primary space-x-1',
          newLine && 'text-sm',
        )}
        onClick={onClick}
      >
        <span className="">{noun}</span>
      </div>
      <div
        className={classNames('space-x-1', !newLine ? 'ml-1' : 'font-semibold')}
      >
        {!newLine && <span className="text-type-disabled">{`/`}</span>}
        <span>{title}</span>
        {count && (
          <span className="text-xs font-normal text-right text-type-disabled">{`${count} icon sets`}</span>
        )}
      </div>
    </div>
  );
};
