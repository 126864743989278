/** @format */
import Dialog from 'blackbird/components/dialog/Dialog';
import * as React from 'react';
import Button from 'blackbird/components/button/Button';
import type { ButtonType } from 'blackbird/components/button/types';
import { FrameEditorActions } from 'javascripts/flux/actions/frame_editor';
import classNames from 'classnames';

interface FrameEditorDialogAction {
  label: string;
  buttonType?: ButtonType;
  onClick: () => void;
}

export interface FrameEditorDialogOptions {
  actions: FrameEditorDialogAction[];
  message: string;
  title?: string;
}

interface Props {
  options?: FrameEditorDialogOptions | null;
}

export const FrameEditorDialog: React.FC<Props> = (props) => {
  const initialFocus = React.useRef<HTMLButtonElement | null>(null);
  const getRefProps = (action: FrameEditorDialogAction) => {
    return ['Save', 'Try again'].includes(action.label)
      ? { ref: initialFocus }
      : {};
  };
  const handleClick = (action: FrameEditorDialogAction) => {
    FrameEditorActions.closeDialog();
    action.onClick();
  };

  const options = props.options;
  if (!options) return null;

  return (
    <Dialog
      size="confirm"
      isOpen
      isDark
      title={options.title}
      description={options.message}
      onCancel={FrameEditorActions.closeDialog.defer}
      hideActions
      initialFocus={initialFocus}
    >
      <div className="flex justify-start items-stretch gap-2">
        {options.actions.map((action) => (
          <div
            key={action.label}
            className={classNames(
              action.buttonType === 'destructive'
                ? 'flex-auto items-stretch flex justify-end'
                : 'flex items-stretch',
            )}
          >
            <Button
              size="sm"
              type={action.buttonType}
              onClick={() => handleClick(action)}
              {...getRefProps(action)}
            >
              {action.label}
            </Button>
          </div>
        ))}
      </div>
    </Dialog>
  );
};
