/** @prettier */
import React, { useContext } from 'react';
import { OfferAddonDialog } from '../offer/OfferAddonDialog';
import { QuizContext } from './QuizContext';

export const QuizOffer: React.FC = () => {
  const { role, handleProceed, isAi, isTeam } = useContext(QuizContext);

  return (
    <OfferAddonDialog
      fancy
      onDecline={() => handleProceed()}
      role={role.toLowerCase().slice(0, -1)}
      isAi={isAi}
      isTeam={isTeam}
    />
  );
};
