/** @format */
import { createTypedActions } from '../stores/AltStore';
import * as i18next from 'i18next';
import { isString } from 'underscore';

/** Either a message to display or an object with the
 * translation key and optional `data` object for i18next */
export type translateableNotification = {
  key: string;
  /** @deprecated in favour of `key' **/
  message?: string;
  data?: Record<string, unknown>;
  link?: string;
};

export interface TranslateableNotificationFunc {
  (msg: translateableNotification): void;

  /** @deprecated Please use translateable format (i.e. {@link translateableNotification}) */
  (string): void;
}

class RequestActions {
  redirect = function (url) {
    window.location.href = url;
  };

  start = function () {
    this.dispatch();
  };

  success: TranslateableNotificationFunc = function (message) {
    this.dispatch(message);
  };

  ajaxError = function (obj) {
    this.dispatch(obj);
  };

  error: TranslateableNotificationFunc = function (message) {
    this.dispatch(message);
  };

  complete = function () {
    this.dispatch();
  };
}

const actions = createTypedActions<RequestActions>(RequestActions);
export { actions as RequestActions };

(window as any).RequestActions = actions;
