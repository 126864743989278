import Cookies from 'js-cookie'

type ICookieSuffixes = 'trial_plan' | 'trial_interval' | 'is_marketing_opt_in' | 'trial_puffin_plan' | 'require_payment_method'

export const CookieService = (suffix: ICookieSuffixes) =>
  Cookies.get(`${BoordsConfig.sessionName}_${suffix}`)

export const CookieSetService = (suffix: ICookieSuffixes, value: any) =>
  Cookies.set(`${BoordsConfig.sessionName}_${suffix}`, value)

export const CookieDeleteService = (suffix: ICookieSuffixes) =>
  Cookies.remove(`${BoordsConfig.sessionName}_${suffix}`)
