/** @format */
const _ = require('underscore');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Container = require('./Container2');
const { IconButton } = require('blackbird/components/common/IconButton');
const { IconSpacer } = require('../IconSpacer');
const HoverControls = require('../shared/HoverControls');
const { setRollbarContext } = require('../../helpers/rollbar');
const CloseIcon = require('blackbird/images/icons/close.svg').default;
const PlayIcon = require('blackbird/images/icons/play.svg').default;
const SkipToStartIcon =
  require('blackbird/images/icons/skip-to-start.svg').default;
const SkipToEndIcon = require('blackbird/images/icons/skip-to-end.svg').default;
const ArrowRightIcon =
  require('blackbird/images/icons/arrow-right.svg').default;
const PauseIcon = require('blackbird/images/icons/pause.svg').default;
const Icon = require('blackbird/components/icon/Icon').default;

const FullScreenOverlays = createReactClass({
  displayName: 'FullScreenOverlays',
  mixins: [PureRenderMixin],

  propTypes: {
    isPlaying: PropTypes.bool.isRequired,
    frames: PropTypes.array.isRequired,
    toggleFullscreen: PropTypes.func.isRequired,
    togglePlay: PropTypes.func.isRequired,
    goToFrame: PropTypes.func.isRequired,
    frame: PropTypes.object.isRequired,
    currentFrameIndex: PropTypes.number.isRequired,
    isWaitingOnAudio: PropTypes.bool.isRequired,
    onSetIndex: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      showPlayingOverlay: false,
    };
  },

  scheduleHidePlayOverlay: () => {},

  componentDidMount() {
    this._isMounted = true;
    this.scheduleHidePlayOverlay = _.debounce(() => {
      if (!this._isMounted) return; // eslint-disable-line
      this.setState({ showPlayingOverlay: false });
    }, 1500);

    setRollbarContext('Full Screen Player');
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isPlaying !== this.props.isPlaying) {
      this.setState({ showPlayingOverlay: true });
      this.scheduleHidePlayOverlay();
    }
  },

  componentWillUnmount() {
    setRollbarContext('previous');
    this._isMounted = false;
  },

  lastClickTimeStamp: 0,
  onClick(e) {
    if (e.target === e.currentTarget) {
      e.preventDefault();

      this.props.togglePlay();

      if (e.timeStamp - this.lastClickTimeStamp < 100) {
        this.props.toggleFullscreen();
      }

      this.lastClickTimeStamp = e.timeStamp;
    }
  },

  onPlayClick(e) {
    this.props.togglePlay(); // We should NOT send the event data here
  },

  // TODO: this is a copy from navigation.jsx, maybe unnneccesary duplication
  onSkip(e) {
    const direction = e.currentTarget.value;
    if (direction === 'start') {
      this.props.goToFrame(_.first(this.props.frames));
    } else if (direction === 'end') {
      this.props.goToFrame(_.last(this.props.frames));
    }
  },

  navigateNext() {
    this.props.onSetIndex(this.props.currentFrameIndex + 1);
  },

  navigatePrevious() {
    this.props.onSetIndex(this.props.currentFrameIndex - 1);
  },

  render() {
    const { frame, frames, isPlaying, isWaitingOnAudio } = this.props;
    const playOpacity = this.state.showPlayingOverlay ? 1 : 0;

    const isFirstFrame = frame === _.first(frames);
    const isLastFrame = frame === _.last(frames);

    return (
      <div
        className="absolute inset-0 flex items-center justify-center"
        onClick={this.onClick}
      >
        <HoverControls
          className="opacity-0 transition-opacity absolute top-0 left-0 p-10"
          force={BoordsConfig.IsTouchDevice ? !isPlaying : false}
        >
          <button
            type="button"
            onClick={this.props.toggleFullscreen}
            className="bg-white flex-shrink-0 focus-visible:outline-blue flex items-center justify-center h-10 w-10 rounded-full"
          >
            <Icon icon={<CloseIcon />} />
          </button>
        </HoverControls>

        <div
          className="opacity-0 transition-opacity duration-300 text-xl font-bold rounded-lg p-3"
          style={{ opacity: playOpacity }}
        >
          <Icon
            icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
            className="w-20"
          />
        </div>

        <HoverControls className="absolute bottom-2 bg-white/50 p-4 rounded-md">
          <IconSpacer>
            <IconButton
              iconClassName="h-10 w-auto"
              aria-label="Go to first frame (cmd + Left arrow key)"
              value="start"
              icon={<SkipToStartIcon />}
              onClick={this.onSkip}
              disabled={isFirstFrame}
            />
            <IconButton
              iconClassName="h-10 w-auto rotate-180"
              aria-label="Go to previous frame (Left arrow key)"
              value="previous"
              icon={<ArrowRightIcon />}
              onClick={this.navigatePrevious}
              disabled={isFirstFrame}
            />
            <IconButton
              iconClassName="h-10 w-auto"
              aria-label={isPlaying ? 'Pause' : 'Play'}
              value="play"
              icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
              onClick={this.onPlayClick}
              disabled={isWaitingOnAudio}
            />
            <IconButton
              iconClassName="h-10 w-auto"
              aria-label="Go to next frame (Right arrow key)"
              value="next"
              icon={<ArrowRightIcon />}
              onClick={this.navigateNext}
              disabled={isLastFrame}
            />
            <IconButton
              iconClassName="h-10 w-auto"
              aria-label="Go to last frame (cmd + Right arrow key)"
              value="end"
              icon={<SkipToEndIcon />}
              onClick={this.onSkip}
              disabled={isLastFrame}
            />
          </IconSpacer>
        </HoverControls>
      </div>
    );
  },
});

module.exports = Container(FullScreenOverlays, ['togglePlay', 'goToFrame']);
