const _ = require('underscore');
const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
window.NewProjectFormContainer = createReactClass({

  propTypes: {
    team_id: PropTypes.number,
  },

  getInitialState: function() {
    return {
      teams: NewProjectStore.getState().teams,
      assignedProjectTeam: false,
    }
  },

  componentDidMount: function() {
    NewProjectStore.listen(this._onChange);
    NewProjectActions.fetchTeams(this.props.projectId);
  },

  componentWillUnmount: function() {
    NewProjectStore.unlisten(this._onChange);
  },

  _onChange: function(state) {
    this.setState({teams: state.teams});

    if (this.state.teams && !this.state.assignedProjectTeam) {
      this.setState({
        assignedProjectTeam: true,
      });
      if (!_.isUndefined(this.props.team_id)) {
        NewProjectActions.setProjectTeamId.defer(this.props.team_id);
      } else {
        NewProjectActions.setProjectTeamId.defer(this.state.teams[0].id);
      }
    }
  },

  render: function() {

    if (!this.state.teams) {
      return <FlyoverLoading />;
    } else {
      return <FlyoverNewProjectForm {...this.props} teams={this.state.teams} />;
    }

  }

});
