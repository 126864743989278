window.NewStoryboardActions = alt.createActions(function () {

  this.setNewStoryboardProjectId = function (project_id) {
    this.dispatch(project_id);
  };

  this.fetchProjectsAndSetId = function (project_id) {
    this.dispatch(project_id);
  };

  this.fetchProjects = function () {
    this.dispatch();
  };

  this.setFrameFields = function (project_id) {
    this.dispatch(project_id);
  };

});
