/** @prettier */
import { tourEvents, type TourBlueprint } from './tourData';
import { hasRolloutFlagEnabled } from 'javascripts/helpers/rollout';

/**
 * A simple array of the names of all the steps that we have, this is
 * mostly inorder to generate a type for all these steps. The `as const` is
 * important for that. We then use the steps in the object below.
 */
// prettier-ignore
const generatorTourSteps = ['generatorOpen', 'generatorPrompt','generatorTimer', 'generatorInsert'] as const;

export type generatorTourAvailableSteps = typeof generatorTourSteps[number];

export const generatorTour: TourBlueprint<generatorTourAvailableSteps> = {
  name: 'generator',
  steps: [
    {
      name: 'generatorOpen',
      header: 'Quick Start - Step 1/3 ✨',
      body: `Click "Generate" to create your first storyboard image using our AI Image Generator.`,
    },
    {
      name: 'generatorPrompt',
      header: 'Quick Start - Step 2/3 ✨',
      body: hasRolloutFlagEnabled('Welcometemplate')
        ? `Click here to generate an image using the prompt.`
        : `Enter a prompt then click here to generate an image. Stuck for ideas? Try "a car in a forest".`,
    },
    {
      name: 'generatorTimer',
      header: 'Quick Start - Step 3/3 ✨',
      body: `We're generating your image for you. This typically takes 30 to 60 seconds. Not long to go!`,
    },
  ],
};
