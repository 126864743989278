/** @prettier */
const _ = require('underscore');
import Dialog from 'blackbird/components/dialog/Dialog';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import { withTranslation } from 'react-i18next';
const navigateToRoute = require('../../helpers/router/navigate-to-route');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const inputRef = React.createRef();

const FlyoverNewProjectForm = createReactClass({
  propTypes: {
    teams: PropTypes.array.isRequired,
    team_id: PropTypes.number,
  },

  getInitialState: function () {
    return {
      error: false,
      isValid: false,
    };
  },

  componentDidMount: function () {
    setTimeout(
      function () {
        inputRef && inputRef.current.focus();
      }.bind(this),
      300,
    );
  },

  _resetForm: function () {
    inputRef.current.value = '';
    this.setState({ error: false });
  },

  _onFormSubmit: function (e) {
    e.preventDefault();
    this.setState({ error: false });
    var name = inputRef.current.value;

    if (name) {
      NewProjectActions.create({ name: name });
    } else {
      this.setState({ error: 'Please choose a name for your project' });
      RequestActions.error.defer(
        'Sorry, there was a problem creating your project',
      );
    }
  },

  _updateValidation() {
    this.setState({
      isValid: !_.isEmpty(inputRef.current.value),
    });
  },
  handleCancel() {
    if (!_.isUndefined(this.props.navigateTo)) {
      navigateToRoute(this.props.navigateTo);
    } else if (!_.isUndefined(this.props.replaceWith)) {
      FlyoverActions.open({
        type: this.props.replaceWith,
      });
    } else if (BoordsConfig.controller === 'projects') {
      navigateToRoute('root');
    } else {
      FlyoverActions.close();
    }
  },
  render: function () {
    const { t } = this.props;
    const errorClass = this.state.error ? 'error' : '';
    const btnProps = {
      disabled: !this.state.isValid,
      htmlType: 'submit',
      /**
       * form attribute allows button to submit a form even if its outside the form
       */
      form: 'new_project',
    };
    if (!this.props.teams) {
      return null;
    }
    return (
      <Dialog
        isOpen
        hideCancel
        isDark
        title={t('modals.new_project.title')}
        subtitle={t('modals.new_project.subtitle')}
        confirmText={t('modals.new_project.button')}
        onCancel={this.handleCancel}
        confirmBtnProps={btnProps}
      >
        <form
          noValidate="novalidate"
          id="new_project"
          onSubmit={this._onFormSubmit}
        >
          <fieldset>
            <div className="form-inputs space-y-6">
              <TextInput
                label={t('modals.new_project.label')}
                type="text"
                className="form__input"
                placeholder={t('modals.new_project.placeholder')}
                name="project[name]"
                id="project_name"
                autoFocus={true}
                required={true}
                ref={inputRef}
                onChange={this._updateValidation}
                onCut={this._updateValidation}
                onPaste={this._updateValidation}
                error={this.state.error}
                inputSize="lg"
              />

              <NewProjectFormTeam {...this.props} />
            </div>
          </fieldset>
        </form>
      </Dialog>
    );
  },
});
window.FlyoverNewProjectForm = withTranslation()(FlyoverNewProjectForm);
