/** @prettier */
import { FontSettings } from '../../types';

/**
 * Uses the given font style if the `fontSettings` indicate that it is
 * available, falls back to other variations (or normal) if not.
 */
export const fallbackFontStyle = (
  tokenFontStyle: string,
  fontSettings?: FontSettings,
) => {
  let fontStyle = tokenFontStyle;

  if (fontSettings && !fontSettings[tokenFontStyle]) {
    // If we don't have the requested font style, we want to fall back
    fontStyle = 'normal';

    // With boldItalic, we want to fallback to italic or bold if those
    // are available
    if (tokenFontStyle === 'bolditalic') {
      if (fontSettings['italic']) {
        fontStyle = 'italic';
      } else if (fontSettings['bold']) {
        fontStyle = 'bold';
      }
    }
  }

  return fontStyle;
};
