const { LoadingIndicator } = require('blackbird/components/common/loading-indicator/LoadingIndicator');
const createReactClass = require('create-react-class');
const { default: logger } = require('javascripts/helpers/logger');
const PropTypes = require('prop-types')
const ignoreWebP = require('../../../helpers/ignore-webp');

module.exports = createReactClass({
  displayName: 'ShareableFrameViewer',

  propTypes: {
    frame: PropTypes.object.isRequired,
    aspectRatio: PropTypes.string,
    onClick: PropTypes.func
  },

  getInitialState() {
    return { isLoading: true }
  },

  componentDidMount() {
    this.preloadImage();
    this._isMounted = true;
  },

  componentWillUnmount() {
    this._isMounted = false;
  },

  componentDidUpdate(prevProps) {
    if (this.props.frame.id !== prevProps.frame.id) {
      this.setState({ isLoading: true })
      this.preloadImage();
    }
  },

  preloadImage() {
    const frameId = this.props.frame.id;

    const markAsLoaded = () => {
      if (frameId === this.props.frame.id && this._isMounted) this.setState({ isLoading: false });
    };

    const i = new Image();

    i.onload = markAsLoaded;
    i.onerror = logger.error;

    i.src = this.getImage();

  },

  getImage() {
    return ignoreWebP(this.props.frame.large_image_url);
  },

  render() {
    const url = this.getImage();
    const { width, height } = BoordsFrameSizeHelper(this.props.aspectRatio);
    const heightRatio = (height/width) * 100

    return (
      <div
        className="relative w-full"
        onClick={this.props.onClick}
        style={{
          paddingTop: heightRatio + "%",
          cursor: this.props.onClick ? 'pointer': undefined
        }}
      >
        { this.state.isLoading
          ? (
             <LoadingIndicator fill />
          ) : <img src={url} className="absolute inset-0" />
        }
      </div>
    )
  }
});
