/** @format */
import * as React from 'react';
import type { frameAspectRatio } from 'javascripts/types/storyboard';
import type {
  AnnotationToolEngine,
  AnnotationToolEngineProps,
} from './AnnotationToolEngine';
import type { FrameStore } from 'javascripts/flux/stores/frame';
import { activeAnnotationLocalState } from 'javascripts/helpers/local-state';
import { useStore } from 'javascripts/helpers/useStore';
import type { CommentStore } from 'javascripts/flux/stores/comment';
import { CommentAnnotationsActions } from 'javascripts/flux/actions/commentAnnotations';
import {
  type CommentAnnotationsStore,
  newAvailabilityContext,
} from 'javascripts/flux/stores/commentAnnotations';
import classNames from 'classnames';
import { logInDevelopment } from 'javascripts/helpers/logger';
import type { WidthAndHeight } from 'javascripts/types/frame';

interface Props {
  frameAspectRatio: frameAspectRatio;
  sizes: WidthAndHeight;
  currentFrameId: number;
  /** Should clicks pass through when not interactive? Mostly true on animatic */
  passThrough: boolean;
}

/** The main component for dealing with the annotation */
export const AnnotationOverlay: React.FC<Props> = ({
  frameAspectRatio,
  sizes,
  currentFrameId,
  passThrough,
}) => {
  const currentAnnotation = useStore<
    AnnotationToolEngineProps | null,
    CommentAnnotationsStore
  >('commentAnnotations', (s) => s.currentAnnotation);
  const currentEngine = useStore<
    AnnotationToolEngine | null,
    CommentAnnotationsStore
  >('commentAnnotations', (s) => s.engine);
  const cachedSizes = React.useRef(sizes);

  const ref = React.useRef<HTMLDivElement>(null);
  const shouldShowTool = !!currentAnnotation;
  const currentAnnotationId = currentAnnotation?.id;
  const restored = React.useRef<boolean>(false);
  const framesAreReady = useStore<boolean, FrameStore>(
    'frame',
    (f) => !f.is_loading,
  );
  const commentsAreReady = useStore<boolean, CommentStore>(
    'comment',
    (c) => c.fetched,
  );

  // Register this overlay as a place where annotations can be shown
  React.useEffect(() => {
    const availabilityContext = newAvailabilityContext({
      name: 'overlay',
      editor: true,
      preview: true,
    });
    CommentAnnotationsActions.addContext.defer(availabilityContext);
    return () => {
      CommentAnnotationsActions.removeContext.defer(availabilityContext);
    };
  }, []);

  React.useEffect(() => {
    if (cachedSizes.current !== sizes && currentEngine) {
      currentEngine.resize(sizes);
      cachedSizes.current = sizes;
    }
  }, [sizes, currentEngine]);

  /** If we need to restore a comment on load, do it. */
  React.useEffect(() => {
    const saved = activeAnnotationLocalState.getValue();
    if (!saved) return;
    // If we've already triggered this thing this session, return
    if (restored.current) return;
    if (!commentsAreReady || !framesAreReady) return;

    if (!currentAnnotationId && saved.frameId === currentFrameId) {
      CommentAnnotationsActions.open.defer({
        commentId: saved,
        interactive: false,
        silent: true,
      });
      restored.current = true;
    } else {
      logInDevelopment(
        `preventing opening an annotation because current frame (${currentFrameId}) is not ${saved.frameId}`,
      );
      // We might still be open, so if we're opening another frame we should
      // close the editor for now
      CommentAnnotationsActions.close.defer();
    }

    // We don't need to respond to commentId here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsAreReady, framesAreReady, currentFrameId]);

  // The first time we need to `showTool`, we will create a new engine. This
  // should not refresh
  React.useEffect(() => {
    let engine: AnnotationToolEngine;
    if (!shouldShowTool || !ref.current || currentEngine) return;

    import('./AnnotationToolEngine').then(({ AnnotationToolEngine }) => {
      engine = new AnnotationToolEngine(
        ref.current!,
        frameAspectRatio,
        currentAnnotation,
        sizes,
      );
      CommentAnnotationsActions.registerEngine.defer(engine);
    });

    return () => {
      engine?.unmount();
      CommentAnnotationsActions.registerEngine.defer(null);
    };
    // We don't want to tear this down if the context or current annotation changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowTool, frameAspectRatio]);

  if (shouldShowTool) {
    const className = classNames(
      'absolute top-0 left-0',
      !currentAnnotation.interactive && passThrough && 'pointer-events-none',
    );
    return <div ref={ref} className={className} />;
  } else {
    return null;
  }
};
