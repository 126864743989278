/** @prettier */
import { createTypedActions } from '../stores/AltStore';
import { FrameEditorStore } from '../stores/frame_editor';

class FrameEditorActions {
  updateShapeType: FrameEditorStore['handleUpdateShapeType'] = function (
    options,
  ) {
    this.dispatch(options);
  };

  updateFrame: FrameEditorStore['handleUpdateFrame'] = function (data) {
    this.dispatch(data);
  };

  open: FrameEditorStore['handleOpen'] = function (data) {
    this.dispatch(data);
  };

  updateEditingMode: FrameEditorStore['handleUpdateMode'] = function (newMode) {
    this.dispatch(newMode);
  };

  updateSaveState: FrameEditorStore['handleUpdateSaveState'] = function (data) {
    this.dispatch(data);
  };

  updateLoading: FrameEditorStore['handleUpdateLoading'] = function (data) {
    this.dispatch(data);
  };

  duplicateFrame: FrameEditorStore['handleDuplicateFrame'] = function (data) {
    this.dispatch(data);
  };

  openDialog: FrameEditorStore['handleOpenDialog'] = function (data) {
    this.dispatch(data);
  };

  closeDialog: FrameEditorStore['handleCloseDialog'] = function () {
    this.dispatch();
  };

  cycleShapes: FrameEditorStore['handleCycleShapes'] = function () {
    this.dispatch();
  };

  togglePreview: FrameEditorStore['handleTogglePreview'] = function (data) {
    this.dispatch(data);
  };

  updateHistoryIndex: FrameEditorStore['handleUpdateHistoryIndex'] = function (
    data,
  ) {
    this.dispatch(data);
  };
  updateDecoration: FrameEditorStore['handleUpdateDecoration'] = function (
    data,
  ) {
    this.dispatch(data);
  };

  updateOnionSkinStrength: FrameEditorStore['handleUpdateOnionSkinStrength'] =
    function (data) {
      this.dispatch(data);
    };
}

const actions = createTypedActions<FrameEditorActions>(FrameEditorActions);
export { actions as FrameEditorActions };
