/** @format */
import { LockClosedIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import * as React from 'react';

export const ScriptEditorStoryboardBanner: React.FC<{ className?: string }> = (
  props,
) => (
  <div
    className={classNames(
      'z-0 flex items-center py-1 text-xs bg-surface-frame space-x-2',
      props.className,
    )}
  >
    <LockClosedIcon className="w-3.5" />
    <div>{`Script View`}</div>
    <div className="mt-0.5 text-black/50">{`Close the Script Editor to make frame edits`}</div>
  </div>
);
