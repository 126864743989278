let screenfullLoaded = false;
require('./load-script');

module.exports = callback => {
  if (screenfullLoaded) { callback(); return };

  loadScript('https://cdnjs.cloudflare.com/ajax/libs/screenfull.js/4.2.1/screenfull.min.js', () => {
    if (!screenfull) return

    screenfull.onerror(console.error)
    screenfullLoaded = true;
    callback();
  })
}
