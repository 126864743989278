import { setFrameField } from "javascripts/helpers/fieldDataHelpers";
import { deserializeJSONFields } from "javascripts/helpers/frame/frameJSON";
import * as _ from 'underscore'

window.RealtimeActions = alt.createActions(function () {

  this.pusherLoaded = function () {
    this.dispatch();
  };

  this.reorderFrames = function(data) {
    var frames = [];
    _.each(data.frameIds, function (id) {
      const found = _.find(data.frameArray, function(f) {
        return f?.id == id;
      })

      if (found) frames.push(found);
    });
    return frames;
  };

  /** @param { Parameters<typeof FrameStore.handleSaveText>[0] } data  */
  this.liveTextUpdate = function(data) {
    // FIXME: Why are we sending every frame including layer data over the wire?
    const frameIndex = _.findIndex(data.frameArray, function(f) {
      return f && f.id === data.frame.frame_id;
    });

    if (frameIndex >= 0) {
      const frame = deserializeJSONFields(data.frameArray[frameIndex])

      setFrameField(frame, data.frame.field_id, data.frame.text)
      frame.update_field_state = true;

      data.frameArray[frameIndex] = frame;
      window.FrameStore.emitChange();

      frame.update_field_state = false;
    }


    return data.frameArray;
  };

  this.liveImageUpdate = function(data) {
    var frame = _.find(data.frameArray, function(f) {
      return f?.id == data.frame.id;
    });
    if (frame) {
      frame.thumbnail_image_url = data.frame.thumbnail_image_url;
      frame.large_image_url     = data.frame.large_image_url;
    } else if (data.frame.sort_order > data.frameArray.length) {
      data.frameArray.splice(parseInt(data.frame.sort_order, 10), 0, data.frame);
    }
    return data.frameArray;
  };

  this.insertNewFrame = function(data) {
    data.frameArray.splice(parseInt(data.index, 10), 0, data.frame);
    return data.frameArray;
  };

  this.storyboardChange = function(obj) {

    if (obj.context == "shareable") {

      obj.store.storyboard.frames = obj.operator();
      obj.store.emitChange();

    } else if (obj.context == "edit") {

      obj.store.update_all_frames = true;
      obj.store.frames            = obj.operator();
      obj.store.emitChange();
      obj.store.update_all_frames = false
      obj.store.emitChange();

      RequestActions.success.defer(`${obj.data.name} made a change`);

    }

  }

  this.bindStoryboard = function(obj) {
    var store = obj.store;
    if (!store.is_listening && store.pusher_loaded && !_.isUndefined(store.storyboard_id) && !_.isNull(store.storyboard_id)) {
      store.is_listening = true;
      store.channel      = window.pusher.subscribe("private-storyboard-frames-" + store.storyboard_id);

      RealtimeActions._bindStoryboardSort(obj);
      RealtimeActions._bindStoryboardTextUpdate(obj);
      RealtimeActions._bindStoryboardNewFrame(obj);
      RealtimeActions._bindStoryboardImage(obj);
    }
  }

  this._bindStoryboardSort = function(obj) {
    obj.store.channel.bind("client-sort", function(data) {
      RealtimeActions.storyboardChange.defer({
        data:      data,
        store:     obj.store,
        context:   obj.context,
        operator:  function() {
          return RealtimeActions.reorderFrames({
            frameArray:  (obj.context == "shareable") ? obj.store.storyboard.frames : obj.store.frames,
            frameIds:    data.frameIds,
          });
        },
      });
    });
  }

  this._bindStoryboardTextUpdate = function(obj) {
    obj.store.channel.bind("client-live-text-update", function(data) {
      RealtimeActions.storyboardChange({
        data:      data,
        store:     obj.store,
        context:   obj.context,
        operator:  function() {
          return RealtimeActions.liveTextUpdate({
            frameArray:  (obj.context == "shareable") ? obj.store.storyboard.frames : obj.store.frames,
            frame:       data.frame,
          });
        },
      });
    });
  }

  this._bindStoryboardNewFrame = function(obj) {
    obj.store.channel.bind("client-live-new-frame", function(data) {
      RealtimeActions.storyboardChange({
        data:      data,
        store:     obj.store,
        context:   obj.context,
        operator:  function() {

          if (obj.context == "shareable") {
            obj.store.view = "grid";
            obj.store.emitChange();
          }

          return RealtimeActions.insertNewFrame({
            frameArray:  (obj.context == "shareable") ? obj.store.storyboard.frames : obj.store.frames,
            frame:       data.frame,
            index:       data.index,
          });
        },
      });
    });
  }

  this._bindStoryboardImage = function(obj) {
    obj.store.channel.bind("client-live-image", function(data) {
      RealtimeActions.storyboardChange({
        data:      data,
        store:     obj.store,
        context:   obj.context,
        operator:  function() {

          if (obj.context == "shareable") {
            obj.store.view = "grid";
            obj.store.emitChange();
          }

          return RealtimeActions.liveImageUpdate({
            frameArray:  (obj.context == "shareable") ? obj.store.storyboard.frames : obj.store.frames,
            frame:       data.frame,
          });
        },
      });
    });
  }

});
