/** @prettier */
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import Dialog from 'blackbird/components/dialog/Dialog';
import { TeamManagementActions } from 'javascripts/flux/actions/team_management';
import type { ProjectAccessChanges } from 'javascripts/flux/stores/team_management';
import * as React from 'react';
import * as _ from 'underscore';
import { isEmpty, isUndefined } from 'underscore';
import Container from '../shared/Container';
import { ProjectAccessItem } from './ProjectAccessItem';

interface IState {
  teamMember?: any;
  teamProjects: any[];
  projectsWithAccess: { [key: string]: boolean };
  changes: ProjectAccessChanges;
  isSaving: boolean;
}

class EditProjectAccess extends React.Component<any, IState> {
  constructor(props) {
    super(props);
    this.state = {
      changes: {},
      teamProjects: [],
      projectsWithAccess: {},
      isSaving: false,
    };
  }

  componentDidMount() {
    this.props.fetch();
    this.UNSAFE_componentWillReceiveProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const team = nextProps.teamManagement.team;
    const projects = nextProps.teamManagement.teamProjects;

    if (
      this.props.teamManagement.team !== team ||
      this.state.teamProjects !== projects
    ) {
      const matchingTeamMember = team
        ? team.active_and_pending_members.find(
            (m) => m.id === nextProps.member.user_id,
          )
        : null;

      if (!matchingTeamMember) return;

      this.setState({
        teamMember: matchingTeamMember,
        teamProjects: projects || [],
        projectsWithAccess: matchingTeamMember.projects_with_access.reduce(
          (o, i) => {
            o[i] = true;
            return o;
          },
          {},
        ),
      });
    }
  }

  handleUpdateAccess = (id, newValue) => {
    this.setState((state) => ({
      changes: { ...state.changes, [id]: newValue },
    }));
  };

  handleSaveClick = () =>
    new Promise<void>((resolve) => {
      this.setState({ isSaving: true }, () => {
        TeamManagementActions.batchToggleProjectAccess({
          newAccess: this.state.changes,
          membership_id: this.state.teamMember.membership_id,
          team_id: this.props.teamManagement.team.id,
          callback: resolve,
        });
      });
    }).then(() => {
      FlyoverActions.close.defer();
    });

  disabledButtonProps = { disabled: true };

  render() {
    return (
      <Dialog
        title="Set project access permissions"
        isOpen
        confirmText="Save"
        onCancel={FlyoverActions.close.defer}
        onConfirm={this.handleSaveClick}
        confirmBtnProps={
          isEmpty(this.state.changes) ? this.disabledButtonProps : undefined
        }
      >
        {this.state.teamProjects.length && this.state.teamMember ? (
          <div className="flex flex-col gap-6 text-[18px]">
            <div className="text-type-subdued">
              Choose which projects {this.state.teamMember.name} can access
            </div>

            {this.state.teamProjects.map((p) => {
              const overrideValue = this.state.changes[p.id];

              const canAccess = isUndefined(overrideValue)
                ? this.state.projectsWithAccess[p.slug]
                : overrideValue;

              return (
                <ProjectAccessItem
                  key={p.id}
                  project={p}
                  canAccess={canAccess ?? false}
                  onChange={this.handleUpdateAccess}
                  isBusy={this.state.isSaving}
                />
              );
            })}
          </div>
        ) : (
          <LoadingIndicator />
        )}
      </Dialog>
    );
  }
}

export const ConnectedEditProjectAccess = Container(
  ['teamManagement', 'dashboard'],
  (storeData, ownProps) => ({
    fetch: () => {
      TeamManagementActions.fetchWithProjects.defer(ownProps.member.team_id);
    },
  }),
)(EditProjectAccess);
