const React = require('react');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const { markdownParser } = require('../../helpers/markdown-parser');


const Markdown = createReactClass({

  propTypes:    {
    text:                 PropTypes.string.isRequired,
    className:            PropTypes.string,
    dir:                  PropTypes.string,
    openLinksInNewWindow: PropTypes.bool,
    allowHeadings:        PropTypes.bool,
    breakWords:           PropTypes.bool,
  },

  getDefaultProps: function() {
    return {
      className: "",
      dir: 'auto',
      breakWords: true,
      openLinksInNewWindow: true,
      allowHeadings: false,
    };
  },

  _html: function() {
    try {
      return {
        __html: markdownParser(this.props.text, {
          openLinksInNewWindow: this.props.openLinksInNewWindow,
          allowHeadings: this.props.allowHeadings,
        })
      };
    } catch(e) {
      return {__html: this.props.text}
    }
  },

  render: function() {
    return (
      <div
        className={"prose lh-copy " + this.props.className}
        style={this.props.breakWords ? { wordBreak: 'break-word' } : null }
        dangerouslySetInnerHTML={this._html()}
        onClick={this.props.onClick}
        dir={this.props.dir}
      />
    );
  }
});

window.Markdown = React.memo(Markdown)
