/** @format */
import { detect } from 'detect-browser';
import { BannerActions } from 'javascripts/flux/actions/banner';
import logger from './logger';

export function checkBrowserCompatibility() {
  const options = {
    required: { e: -4, f: -5, o: -3, s: 14.4, c: -5 },
    insecure: true,
    unsupported: true,
    reminder: 24,
    api: 2022.07,
    position: 'bottom',
    nomessage: true,
    onshow: (infos) => {
      const browser = detect();
      if (browser) {
        if (browser?.name === 'safari' && browser.version.indexOf('17') === 0) {
          logger.log(
            `ignoring browser update warning because it's safari 17 (which was marked as a false positive)`,
          );
          return;
        }
      }

      BannerActions.add.defer({
        type: 'unsupported_browser',
        data: {
          browserName: infos.browser.t,
          handleDismiss: () => {
            (window as any)._buorgres.setCookie(options.reminderClosed);
            BannerActions.remove.defer('unsupported_browser');
          },
        },
      });
    },
    reminderClosed: 150,
    l: 'en',
  };
  (window as any).$buoop = options;

  var e = document.createElement('script');
  e.src = '//browser-update.org/update.min.js';
  e.async = !0;
  document.body.appendChild(e);
}
