/** @format */
import * as React from 'react';

/** Wraps the component's children in a `div` with `className` if the
 * `condition` is true */
export const ConditionalWrap: React.FC<{
  className?: string;
  condition: boolean;
}> = (props) =>
  React.Children.count(props.children) > 0 && props.condition ? (
    <div className={props.className}>{props.children}</div>
  ) : (
    <>{props.children}</>
  );
