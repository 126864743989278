/** @prettier */
import * as React from 'react';
import { Hr } from './Hr';
import { ToolbarButton } from '../../ToolbarButton';
import type { ContextMenuProps } from '../types';
import { OptionButton } from './OptionButton';
import { alignmentOptions } from './toolbarOptions';

import FlipHorizontalIcon from 'blackbird/images/icons/flip-horizontal.svg';
import FlipVerticalIcon from 'blackbird/images/icons/flip-vertical.svg';
import SendToFrontIcon from 'blackbird/images/icons/send-to-front.svg';
import SendToBackIcon from 'blackbird/images/icons/send-to-back.svg';
import DeleteIcon from 'blackbird/images/icons/delete-2.svg';

export const SharedContextMenu: React.FC<
  ContextMenuProps & {
    hideFirstHr?: boolean;
  }
> = ({ actions, disabled, selected, hideFirstHr }) => {
  const showFlip = selected.type !== 'textbox';
  const [alignPopupOpen, setAlignPopupOpen] = React.useState(false);

  return (
    <>
      {showFlip && !hideFirstHr && <Hr />}
      {showFlip && (
        <ToolbarButton
          labelKeyPrefix="frameEditor.toolbar"
          value="flip-x"
          labelPosition="top"
          onClick={actions.handleFlipObjects}
          icon={<FlipHorizontalIcon />}
        />
      )}

      {showFlip && (
        <ToolbarButton
          labelKeyPrefix="frameEditor.toolbar"
          value="flip-y"
          labelPosition="top"
          onClick={actions.handleFlipObjects}
          icon={<FlipVerticalIcon />}
        />
      )}

      <Hr />

      <OptionButton
        labelKey="title"
        labelKeyPrefix="frameEditor.toolbar.align"
        onChange={actions.handleAlignObjects}
        value={'middle' /* this just determines the icon here */}
        options={alignmentOptions}
        isOpen={alignPopupOpen}
        onActivate={() => setAlignPopupOpen((x) => !x)}
        name="alignment"
        disabled={disabled}
      />

      <ToolbarButton
        labelKeyPrefix="frameEditor.toolbar"
        value="forward"
        labelPosition="top"
        onClick={actions.handleReorder}
        disabled={disabled}
        icon={<SendToFrontIcon />}
      />

      <ToolbarButton
        labelKeyPrefix="frameEditor.toolbar"
        value="backward"
        labelPosition="top"
        onClick={actions.handleReorder}
        disabled={disabled}
        icon={<SendToBackIcon />}
      />

      <ToolbarButton
        labelKeyPrefix="frameEditor.toolbar"
        value="delete"
        labelPosition="top"
        onClick={actions.handleDeleteObject}
        disabled={disabled}
        icon={<DeleteIcon />}
      />
    </>
  );
};

SharedContextMenu.displayName = 'SharedContextMenu';
