/** @prettier */
import * as React from 'react';
import {
  ErrorBoundaryContent,
  type IProps as IContentProps,
} from './ErrorBoundaryContent';
import { rollbar } from 'javascripts/helpers/rollbar';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
const errorHandler = RequestErrorHandler('ErrorBoundary');

export const ErrorBoundaryContext = React.createContext<(error: Error) => void>(
  () => {},
);

interface IState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<IContentProps, IState> {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  triggerError = (error: Error) => {
    this.setState({ hasError: true });
    this.logError(error);
  };

  logError(error) {
    errorHandler({
      messageKey: null,
      extraData: {
        crashedMessageShown: true,
      },
    })(error);

    rollbar.info('crashed message shown');
    if (typeof Track !== 'undefined')
      Track.event.defer('crashed_message_shown');
  }

  componentDidCatch(error) {
    this.logError(error);
  }

  render() {
    let containerClass = this.props.size === 'large' ? 'pa4' : 'pa2';
    if (this.props.bg) containerClass += ' ' + this.props.bg;

    return (
      <ErrorBoundaryContext.Provider value={this.triggerError}>
        {this.state.hasError ? (
          <div
            className={
              'w-100 h-100 flex items-center justify-center ' + containerClass
            }
          >
            <ErrorBoundaryContent {...this.props} />
          </div>
        ) : (
          this.props.children
        )}
      </ErrorBoundaryContext.Provider>
    );
  }
}
