/** @format */

import { notNull } from './notUndefined';

export function moveInArray<T = unknown>(
  array: T[],
  itemsToMove: T[],
  newIndex,
): T[] {
  const originalIndex = array.indexOf(itemsToMove[0]);

  const filtered = array.map((f) =>
    // Create a new array that replaces all the selected items with null
    // This allows us to keep indexes intact when inserting items again
    itemsToMove.indexOf(f) >= 0 ? null : f,
  );

  const adjustedIndex = originalIndex < newIndex ? newIndex + 1 : newIndex;
  // Insert the new frames at the new index
  const reordered = filtered
    .slice(0, adjustedIndex)
    .concat(itemsToMove)
    .concat(filtered.slice(adjustedIndex));

  return reordered.filter(notNull);
}
