/** @prettier */
import * as React from 'react';
// import { Select } from '../../shared/Select';
import { IStoryboard } from '../../../types/storyboard';
import { useStore } from '../../../helpers/useStore';
import { StoryboardActions } from '../../../flux/actions/storyboard';
import Select from 'blackbird/components/form/select/Select';
import Button from 'blackbird/components/button/Button';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'underscore';

interface SiblingStoryboards {
  storyboards: IStoryboard[] | null;
  isLoading: boolean;
  error: boolean;
}

export const CopyFromStoryboard = (props) => {
  const { t } = useTranslation();
  const projects =
    useStore<SiblingStoryboards>('project', (s) => s.siblingStoryboards) || {};

  React.useEffect(
    () =>
      ProjectActions.fetchSiblingStoryboards.defer({
        projectID: props.projectID,
        storyboardID: props.storyboardID,
      }),
    [props.projectID, props.storyboardID],
  );

  const [storyboardSlug, setStoryboardSlug] = React.useState<string>();
  const isLoading = isUndefined(projects.isLoading) ? true : props.isLoading;

  const onChange = (value) => setStoryboardSlug(value);

  const handleCopyClick = () =>
    StoryboardActions.copyFrameFields(storyboardSlug!);
  const storyboards = projects.storyboards ?? [];
  const options = storyboards.map((storyboard) => ({
    label: storyboard.document_name,
    value: storyboard.slug,
  }));
  return (
    <div className="flex items-end gap-2">
      <div className="flex-grow">
        <Select
          disableInput
          size="md"
          selectContainerClassName="w-full"
          label={`${t('sidebar.settings.storyboard.copy_settings_from')}:`}
          disabled={isLoading}
          value={storyboardSlug}
          onChange={onChange}
          options={options}
          loading={isLoading || !projects.storyboards}
          placeholder={'Choose storyboard…'}
        />
      </div>
      <div className="ml1">
        <Button htmlType="button" size="md" onClick={handleCopyClick}>
          {t('sidebar.settings.storyboard.copy')}
        </Button>
      </div>
    </div>
  );
};
