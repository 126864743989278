/** @prettier */
import * as React from 'react';
import { DashboardLayout } from './DashboardLayout';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import DialogContextProvider from 'blackbird/components/dialog/DialogContext';
import { posthogClient } from 'javascripts/helpers/posthog';
import { PostHogProvider } from 'posthog-js/react';
import { WizardProvider } from 'blackbird/components/wizard/WizardContext';

const DashboardContainer = (props) => (
  <PostHogProvider client={posthogClient()}>
    <DefaultStoreContextProvider>
      <WizardProvider>
        <DialogContextProvider>
          <DashboardLayout {...props} />
        </DialogContextProvider>
      </WizardProvider>
    </DefaultStoreContextProvider>
  </PostHogProvider>
);

(window as any).DashboardContainer = DashboardContainer;
