/** @prettier */
/* eslint  react-perf/jsx-no-new-object-as-prop:0 */
import { MultiPanel } from '../../shared/MultiPanel';
import { withContainer as DownloadOverlay } from '../../player/DownloadOverlay';
import { ExportOptions } from './ExportOptions';
import { ExportScriptContainer } from './ExportScriptContainer';
import * as React from 'react';
import { withContainer as ExportImages } from './ExportImages';
import { withContainer as ExportAfterEffects } from './ExportAfterEffects';
import { ExportSpreadsheetContainer } from './ExportSpreadsheetContainer';
import Popover from 'blackbird/components/common/popover/Popover';
import Button from 'blackbird/components/button/Button';
import { useStore } from 'javascripts/helpers/useStore';
import { IFPermissionPanel } from 'javascripts/helpers/has-permission';
import { strictUserFeaturesType } from 'javascripts/flux/stores/user_permissions';
const overlayWidth = 300;

interface IProps {
  disabled: boolean;
  title: string;
}
interface ExportWrapperProps {
  storyboardOwnerId: number;
  featureName: strictUserFeaturesType;
  children: React.ReactElement;
}

const ExportWrapper: React.FC<ExportWrapperProps> = ({
  storyboardOwnerId,
  featureName,
  children,
}) => (
  <IFPermissionPanel
    featureName={featureName}
    storyboardOwnerId={storyboardOwnerId}
  >
    {children}
  </IFPermissionPanel>
);

export const StoryboardDownloadButton = React.memo<IProps>((props) => {
  const storyboard = useStore('storyboard', (state) => state.storyboard);
  return (
    <Popover placement="bottom">
      <Popover.Button className="h-full">
        <Button disabled={props.disabled} type="outline">
          {props.title}
        </Button>
      </Popover.Button>
      <Popover.Panel className="z-40">
        <MultiPanel
          useChevron
          width={overlayWidth}
          defaultPanel="index"
          innerPadding="p-4"
          panels={{
            index: {
              Component: ExportOptions,
              props: {
                innerPadding: '',
              },
            },
            animatic: {
              title: 'Download Animatic',
              Component: DownloadOverlay,
              props: {
                storyboardOwnerId: storyboard.project.owner.user_id,
                storyboardId: storyboard.id,
                frameDurationMismatch: false,
                showTitle: false,
              },
            },
            spreadsheet: {
              title: 'Download Spreadsheet',
              Component: ExportSpreadsheetContainer,
              props: {
                storyboardOwnerId: storyboard.project.owner.user_id,
                storyboardId: storyboard.id,
              },
            },
            script: {
              title: 'Download Script',
              Component: ExportScriptContainer,
              props: {
                storyboardOwnerId: storyboard.project.owner.user_id,
                storyboardId: storyboard.id,
              },
            },
            images: {
              title: 'Download Images',
              Component: ExportImages,
              props: {
                storyboardSlug: storyboard.slug,
                storyboardOwnerId: storyboard.project.owner.user_id,
                storyboardId: storyboard.id,
              },
            },
            afterEffects: {
              title: 'Export to After Effects',
              Component: ExportAfterEffects,
              props: {
                storyboardSlug: storyboard.slug,
                storyboardOwnerId: storyboard.project.owner.user_id,
                storyboardId: storyboard.id,
              },
            },
          }}
        />
      </Popover.Panel>
    </Popover>
  );
});

StoryboardDownloadButton.displayName = 'StoryboardDownloadButton';
