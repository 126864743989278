/** @format */

import type { DialogContext } from 'blackbird/components/dialog/DialogContext';
import { isEmpty } from 'underscore';
import { ajax } from './ajax';
import { openConfirmDialog } from './openDialog';
import { httpMethods, RequestErrorHandler } from './request-error-handler';
const errorHandler = RequestErrorHandler('popupMenu');

interface SharedOptions {
  url?: string;
  label: string;
  className?: null | string;
  newSection?: null | boolean;
  confirmWith?: null | string;
  params: Record<string, unknown>;
}

interface JSAction extends SharedOptions {
  actionType: 'js';
  JsActionIdentifier: 'edit_project_access';
}

interface HTTPAction extends SharedOptions {
  actionType: 'http';
  method: httpMethods;
}

export type RubyMenuAction = HTTPAction | JSAction;

const _performHttpRequest = (props: HTTPAction) => {
  const { url, params, method } = props;
  ajax({
    url: url,
    method: method,
    dataType: 'json',
    data: params,
    success: () => {
      window.location.reload();
    },
    // We let the error handler find the right error message.
    error: errorHandler({ method }),
  });
};

const _performJsAction = (props: JSAction) => {
  const { JsActionIdentifier, params } = props;

  if (JsActionIdentifier === 'edit_project_access') {
    FlyoverActions.open({
      type: 'editProjectAccess',
      props: { member: params.membership },
    });
  }
};

const handleRequest = (options: RubyMenuAction) => {
  if (options.actionType === 'js') {
    _performJsAction(options);
  } else if (options.actionType === 'http') {
    _performHttpRequest(options);
  }
};

export const convertPostActionsToContextMenuOptions = (
  options: RubyMenuAction[],
  context: any,
): ContextMenuOption[] =>
  options.map((option, i) => {
    const { label, confirmWith } = option;
    return {
      label,
      id: String(i),
      onItemSelect: () => {
        if (confirmWith && !isEmpty(confirmWith)) {
          openConfirmDialog(confirmWith, context).then((confirmed) => {
            if (confirmed) handleRequest(option);
          });
        } else {
          handleRequest(option);
        }
      },
    };
  });
