/** @prettier */

import React from 'react';
import classNames from 'classnames';
import { IconButton, type IconButtonprops } from '../../common/IconButton';
import { PenLine } from 'lucide-react';

interface AnnotationToggleProps extends Partial<IconButtonprops> {
  disabled: boolean;
  active: boolean;
  label: string;
  inactiveColor?: 'subdued' | 'black';
  className?: string;
  onClick: React.MouseEventHandler<HTMLElement>;
}

export const AnnotationToggle: React.FC<AnnotationToggleProps> = ({
  active,
  className,
  inactiveColor = 'black',
  label,
  ...rest
}) => {
  // Turn move the handler to the button again?
  return (
    <div className={classNames('relative', className)}>
      <div
        className={classNames(
          'absolute left-0 z-0 w-1.5 h-1.5 rounded-full top-1 left-0.5 mt-[0.03rem] pointer-events-none',
          active ? 'bg-brand-pink' : 'bg-black/30',
        )}
      />
      <IconButton
        iconClassName={classNames('w-5 h-5 z-10 relative')}
        color={active ? 'black' : inactiveColor}
        {...rest}
        aria-label={label}
        icon={<PenLine width={10} strokeWidth={2} />}
      />
    </div>
  );
};
