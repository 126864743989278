const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const Memberships = require("./TeamMemberships.jsx")
window.Teammates = createReactClass({

  propTypes:  {
    team:     PropTypes.object.isRequired,
  },

  getInitialState: function() {
    return {
      showArchivedMembers:  false,
      archivedMembers:      [],
    };
  },

  _handleViewArchivedMembersClick: function(e) {
    e.preventDefault();
    this.setState({showArchivedMembers: true});
  },

  render: function() {

    var team                     = this.props.team,
      archivedMembersHiddenClass = this.state.showArchivedMembers || 'hidden',
      showArchivedMembersButton  = null;

    if (this.state.showArchivedMembers) {
      showArchivedMembersButton = <label>Archived Teammates</label>;
    } else {
      showArchivedMembersButton = <p><a className="light ul" href="#" onClick={this._handleViewArchivedMembersClick}>View archived Teammates</a></p>;
    }

    return (
        <div>
          <div className="mb3">
            <ProjectTeammates
              team={team}
              members={Memberships(team.active_and_pending_members, ["admin", "manager", "supermember"])}
              showToggle={false}
              title="Teammates"
            />
            <ProjectTeammates
              team={team}
              members={Memberships(team.active_and_pending_members, ["member"])}
              showToggle={false}
              title="Restricted Teammates"
              {...this.props}
            />
            {/*<ProjectTeammates
              team={team}
              members={Memberships(team.active_and_pending_members, ["guest"])}
              showToggle={false}
              title="Guests"
              {...this.props}
            />*/}
            <ProjectTeammates
              team={team}
              members={team.unregistered_invitations}
              title="Pending Invitations"
              showToggle={false}
              {...this.props}
            />
            <ProjectTeammates
              team={team}
              members={team.archived_members}
              title="Archived Teammates"
              showToggle={false}
              allowCollapse={true}
              {...this.props}
            />

            <div className="silver mv4 tc">
              <a className="f5 underline" target="_blank" href="https://intercom.help/boords/getting-started/team-roles">
                Learn about Teammate permissions
              </a>
            </div>
          </div>

        </div>
      )

  }
});
