/** @prettier */
import classNames from 'classnames';
import Img from 'javascripts/components/shared/Img';
import * as React from 'react';
import { useDrag } from 'react-dnd';
import { last } from 'underscore';
import {
  type imageLibraryDragItem,
  DRAGGABLE_TYPES,
} from '../../../shared/dragAndDropUtils';

interface Props {
  icon: any;
  fullUrl?: string;
  previewUrl?: string;
  onClick?: (imageUrl: string) => void;
}

const markAsDownloaded = () => {
  Track.event.defer('insert_iconfinder_asset');
};

export const IconFinderItem: React.FC<Props> = ({
  icon,
  onClick,
  previewUrl,
  fullUrl,
}) => {
  const fullSizeUrl = fullUrl
    ? fullUrl
    : last(icon.raster_sizes)?.formats[0].preview_url;

  const [show, setShow] = React.useState(true);

  const [{ isDragging }, ref] = useDrag(() => ({
    type: DRAGGABLE_TYPES.iconfinder,
    item: {
      id: icon.icon_id,
      image: fullSizeUrl,
    } as imageLibraryDragItem,
    canDrag: () => !onClick,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const handleClick = React.useCallback(() => {
    onClick!(fullSizeUrl);
    markAsDownloaded();
  }, [onClick, fullSizeUrl]);

  const imageSrc = React.useCallback(() => {
    try {
      if (previewUrl) {
        return previewUrl;
      } else if (icon.raster_sizes[5].formats[0].preview_url) {
        return '#';
      } else {
        setShow(false);
        return '#';
      }
    } catch {
      setShow(false);
      return '#';
    }
  }, [previewUrl, icon]);

  return !show ? null : (
    <div className="flex items-center justify-center" ref={ref}>
      <div
        className={classNames(
          `p-2 rounded bg-surface-light  w-full h-full flex items-center justify-center relative`,
          {
            'opacity-60': isDragging,
            'opacity-100': !isDragging,
            'hover:bg-action-secondary-hover cursor-pointer': onClick,
            'cursor-grab': !onClick,
          },
        )}
        onClick={onClick ? handleClick : undefined}
      >
        <Img small containerClasses="aspect-square" src={imageSrc()} />
      </div>
    </div>
  );
};
