/** @prettier */
import * as React from 'react';
import { IconSpacerGroup } from '../../IconSpacer';
import { ToolbarSelect } from './ToolbarInput';
import { brushSizes } from '../frameEditorDefaults';
import type { DrawingTool } from '../FrameEditorEngine';
import type { ContextMenuActions } from '../types';
import { ColorPicker } from '../../shared/ColorPicker.react';
import { ColorPickerWellButton } from 'javascripts/components/shared/ColorPickerWellButton';

export const BottomBarBrushOptions: React.FC<{
  frameEditor?: DrawingTool;
  actions: ContextMenuActions;
  editingMode: string;
}> = ({ actions, frameEditor, editingMode }) => {
  if (!frameEditor) return null;
  const lastBrushSize = frameEditor.getLastUsedValue('strokeWidth', 'PSStroke');
  const lastColor = frameEditor.getLastUsedValue(
    'stroke',
    'PSStroke',
  ) as string;

  return (
    <IconSpacerGroup>
      <ToolbarSelect
        className="w85px mr2 shrink-0"
        value={lastBrushSize}
        onChange={(e) => actions.handleSetStrokeWidth('PSStroke', e)}
      >
        {brushSizes.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </ToolbarSelect>

      {editingMode === 'draw' && (
        <ColorPicker
          color={lastColor}
          onChange={(e) => actions.handleSetStrokeColor('PSStroke', e)}
          icon={<ColorPickerWellButton color={lastColor} />}
          showAlpha
        />
      )}
    </IconSpacerGroup>
  );
};
