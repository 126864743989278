/** @format */
import { eventSource } from 'blackbird/helpers/eventContextHelper';
import { ajaxRuby } from 'javascripts/helpers/ajax';
import logger from 'javascripts/helpers/logger';
import { ensureFrameFieldsHaveIds } from 'javascripts/helpers/storyboard/frameFieldHelpers';
import type { IFrame } from 'javascripts/types/frame';
import type { IShareableStoryboard } from 'javascripts/types/storyboard';
import { PlayerActions } from '../actions/player';
import { ShareableActions } from '../actions/shareable';
import { FakeAltStoreClass } from './AltStore';

type possibleViews = 'grid' | 'player' | 'zoom';

export class ShareableStore extends FakeAltStoreClass<ShareableStore> {
  storyboard?: IShareableStoryboard;
  storyboard_id: number;
  activeIndex = 0;
  view: possibleViews = 'grid';

  fetched = false;
  is_listening = false;
  hasPlayer = false;
  pusher_loaded = false;
  isFullscreen = false;

  constructor() {
    super();
    this.bindListeners({
      handleFetch: ShareableActions.FETCH,
      handlePusherLoaded: RealtimeActions.PUSHER_LOADED,
      handleSetStoryboard: ShareableActions.SET_STORYBOARD,
      handleUpdateView: ShareableActions.UPDATE_VIEW,
      handleOpenFrame: ShareableActions.OPEN_FRAME,
      handleSetActiveIndex: ShareableActions.SET_ACTIVE_INDEX,
      handleSetFullscreen: ShareableActions.SET_FULLSCREEN,
      handleOpenFrameIndex: ShareableActions.OPEN_FRAME_INDEX,
      handleReceive: ShareableActions.RECEIVE,
    });
  }

  handleSetStoryboard(data) {
    this.storyboard = undefined;
    this.fetched = false;
    this.emitChange();

    data.storyboard.frames = data.frames;
    this.storyboard = data.storyboard;
    this.storyboard_id = data.storyboard.id;

    if (this.activeIndex > this.storyboard!.frames.length) {
      this.activeIndex = 0;
    }
    this.fetched = true;
    this.ensureFrameFields();
    this.emitChange();
  }

  ensureFrameFields() {
    if (!this.storyboard) return;
    if (!this.storyboard.frame_fields) {
      this.storyboard.frame_fields =
        StoryboardStore.getState().default_frame_fields;
    } else {
      // Ensure all frame fields have frame ids, and save those if they need
      // updating
      this.storyboard.frame_fields = ensureFrameFieldsHaveIds(
        this.storyboard.frame_fields,
        // We don't want to save here, because we're in shareable
        () => {},
      );
    }
  }

  handlePusherLoaded() {
    this.pusher_loaded = true;
    RealtimeActions.bindStoryboard.defer({
      store: this,
      context: 'shareable',
    });
  }

  handleFetch(data) {
    this.storyboard_id = data.storyboard_id;
    RealtimeActions.bindStoryboard.defer({
      store: this,
      context: 'shareable',
    });
    ajaxRuby({
      method: 'get',
      url: '/s/' + data.hashid + '.json',
      success: function (response) {
        // trigger an action, so we can listen to it in the router
        ShareableActions.receive.defer(response);
      }.bind(this),
      error: function (response) {
        XhrErrorActions.show.defer({
          status_code: response.status,
          context: 'storyboard:shareable',
          response,
        });
      }.bind(this),
    });
  }

  handleReceive(response) {
    this.storyboard = response;
    this.fetched = true;

    this.storyboard!.preferences = {
      ...StoryboardStore.getState().default_preferences,
      ...(response.preferences || {}),
    };

    this.view = 'grid';
    this.hasPlayer = response.has_animatic;
    this.ensureFrameFields();
    IntercomActions.enterContext.defer('shareable');
  }

  handleUpdateView(viewType) {
    if (!this.storyboard) return;
    if (!viewType) throw new Error('Needs to supply viewType');
    const frameToOpen = this.storyboard.frames[this.activeIndex];
    this.view = viewType;

    if (viewType === 'player') {
      PlayerActions.open.defer({
        frame: frameToOpen ? frameToOpen : this.storyboard.frames[0],
        storyboard: this.storyboard,
        isEditable: false,
      });
    } else {
      PlayerActions.close.defer();
    }
  }

  /**
   * Opens the frame at the current (non-zero-indexed) index. Used to
   * translate a URL to a frame
   */
  handleOpenFrameIndex({ frameIndex, view }) {
    if (!this.storyboard) throw new Error('no storyboard is present');
    this.handleOpenFrame({
      frame: this.storyboard.frames[frameIndex - 1],
      view,
    });
  }

  handleOpenFrame({
    frame,
    view = 'zoom',
  }: {
    frame: IFrame;
    view: possibleViews;
  }) {
    if (!this.storyboard) throw new Error('no storyboard is present');
    const frameToUse = frame || this.storyboard.frames[this.activeIndex];
    this.view = view;

    if (this.view === 'player') {
      PlayerActions.open.defer({
        frame: frameToUse,
        storyboard: this.storyboard,
        isEditable: false,
      });
    } else {
      const newIndex = this.storyboard.frames.findIndex(
        (f) => frameToUse.id === f.id,
      );
      if (newIndex < 0) throw new Error('could not find frame :(');
      this.activeIndex = newIndex;
    }
  }

  handleSetActiveIndex(index) {
    if (!this.storyboard) throw new Error('no storyboard is present');
    if (index < 0 || index > this.storyboard.frames.length - 1) return;
    this.activeIndex = index;
  }

  handleSetFullscreen(newValue) {
    if (this.hasPlayer) return;
    if (this.isFullscreen) {
      // This event fires twice unless we wrap it in
      // a conditional
      Track.event.defer(`fullscreen`, {
        context: eventSource(undefined),
        animatic: false,
        category: 'Product',
      });
    }
    this.isFullscreen = newValue;
  }
}

(window as any).ShareableStore = alt.createStore(
  ShareableStore,
  'ShareableStore',
);
