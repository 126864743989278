/**@prettier */
// const {
//   default: DisclosureRight,
// } = require('blackbird/images/icons/disclosure-right.svg');
const {
  default: ArrowRight,
} = require('blackbird/images/icons/arrow-right.svg');
const { default: Icon } = require('blackbird/components/icon/Icon');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const { default: classNames } = require('classnames');
const fontSizes = {
  medium: 'f4',
  larger: 'f4',
  small: 'f5',
};
const fontWeights = {
  medium: 'medium',
  larger: 'heavy',
  small: 'heavy',
};

//TODO: need icon asset for new window.
const NewTabIcon = () => {
  return <></>;
};

module.exports = createReactClass({
  displayName: 'OverlayListItem',

  propTypes: {
    onClick: PropTypes.func,
    showArrow: PropTypes.bool,
    showPopupIndicator: PropTypes.bool,
    text: PropTypes.string.isRequired,
    description: PropTypes.string,
    isActive: PropTypes.bool,
    showBorder: PropTypes.bool.isRequired,
    height: PropTypes.string,
    href: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    padding: PropTypes.string,
    size: PropTypes.oneOf(Object.keys(fontWeights)),
    center: PropTypes.bool,
    newWindow: PropTypes.bool,
    icon: PropTypes.object,
    onMouseEnter: PropTypes.func,
    animateIconOpacity: PropTypes.bool,
    disabled: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      showArrow: true,
      height: 'h3',
      padding: 'pa2',
      size: 'medium',
      center: false,
      className: '',
      animateIconOpacity: false,
    };
  },
  handleClick(e) {
    e.stopPropagation();
    this.props.onClick(e);
  },

  render() {
    const fontSize = fontSizes[this.props.size];
    const fontWeight = fontWeights[this.props.size];

    let Tag = 'button';

    let itemProps;

    if (this.props.disabled) {
      itemProps = { type: 'button', disabled: true };
    } else if (this.props.href) {
      Tag = 'a';
      if (this.props.newWindow) {
        itemProps = {
          href: this.props.href,
          target: '_blank',
        };
      } else {
        itemProps = { href: this.props.href };
      }
    } else {
      itemProps = {
        onClick: this.handleClick,
        type: 'button',
        value: this.props.value,
      };
    }

    return (
      <Tag
        {...itemProps}
        className={classNames(
          'focus:outline-none flex items-center text-left w-full hover:bg-surface-light',
          `avenir-${fontWeight}`,
          this.props.height,
          this.props.padding,
          fontSize,
          {
            'b--light-grey bb bw1': this.props.showBorder,
            'bg-surface-light': this.props.isActive,
            tc: this.props.center,
            'o-undim': this.props.animateIconOpacity,
            'text-type-disabled': this.props.disabled,
            'cursor-pointer': !this.props.disabled,
          },
          this.props.className,
        )}
        onMouseEnter={this.props.onMouseEnter}
      >
        {this.props.icon ? (
          <Icon
            icon={this.props.icon}
            className={classNames(
              'h-6 w-auto mr-5',
              this.props.disabled && 'opacity-40',
            )}
          />
        ) : null}
        <div className="flex-grow overflow-hidden">
          <div className="truncate">{this.props.text}</div>

          {this.props.description && (
            <div className="text-sm text-type-subdued">
              {this.props.description}
            </div>
          )}
        </div>
        {this.props.showArrow ? (
          <Icon
            className={classNames(
              'w-7 h-auto flex items-center',
              this.props.disabled && 'opacity-30',
            )}
            icon={<ArrowRight />}
          />
        ) : this.props.showPopupIndicator ? (
          <NewTabIcon />
        ) : null}
      </Tag>
    );
  },
});
