/** @prettier */
import * as React from 'react';
import * as navigateToRoute from '../../../helpers/router/navigate-to-route';
import { getFeatureRestrictionCTA } from '../../../helpers/has-permission';
import Button from 'blackbird/components/button/Button';
import { FeatureUnavailable } from 'blackbird/components/common/FeatureUnavailable';

export const PanelbarCommentsNoPermissions: React.FunctionComponent<{
  ownerId: number;
}> = (props) => {
  if (!props.ownerId) throw new Error('needs user id');
  const cta = getFeatureRestrictionCTA(props.ownerId, 'comments');

  if (BoordsConfig.HasV3) {
    return (
      <div className="p-6">
        <FeatureUnavailable featureName="comments" />
      </div>
    );
  } else {
    return (
      <div className="flex flex-col px-6 mt-6 gap-6">
        <div className="text-lg text-type-primary">{cta.title}</div>
        <div className="text-left rounded text-type-subdued">
          {cta.description}
        </div>
        {props.ownerId === BoordsConfig.Uid && (
          <Button
            className="no-outline"
            size="md"
            onClick={() => navigateToRoute('pricing')}
          >
            {`Compare plans`}
          </Button>
        )}
      </div>
    );
  }
};
