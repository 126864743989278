/** @format */
import { createTypedActions } from '../stores/AltStore';
import type { ArchiveStore } from '../stores/archive';

class ArchiveActions {
  fetch: ArchiveStore['handleFetch'] = function () {
    this.dispatch();
  };

  restoreProject: ArchiveStore['handleRestoreProject'] = function (project) {
    this.dispatch(project);
  };

  projectRestored: ArchiveStore['handleFetch'] = function () {
    this.dispatch();
  };
}

const actions = createTypedActions<ArchiveActions>(ArchiveActions);
export { actions as ArchiveActions };
