/** @prettier */
import { eachFrameFieldMap } from '../../../helpers/eachFrameFieldMap';
import { SVGIcon } from '../../shared/SVGIcon';
import { generateFrameFieldLabel } from '../../../helpers/generateFrameFieldLabel';
import type { IFrame } from '../../../types/frame';
import type { FrameField, IStoryboard } from '../../../types/storyboard';
import { every, isNull, isUndefined } from 'underscore';
import classNames from 'classnames';
import React from 'react';
import { getFrameField } from 'javascripts/helpers/fieldDataHelpers';
import { stylesForIndexLength } from 'javascripts/helpers/styleForIndexLength';
import RichTextInput, {
  type RichTextInputChangeEventHandler,
} from 'blackbird/components/form/richTextInput/RichTextInput';
import { isFrameFieldEmpty } from 'javascripts/helpers/storyboard/frameFieldHelpers';
import { TagIcon } from '@heroicons/react/20/solid';
import logger from 'javascripts/helpers/logger';

interface Props {
  frame: IFrame;
  index: number;
  noBackground?: boolean;
  isImageLibraryLayout?: boolean;
  storyboard: IStoryboard;
  frame_fields: FrameField[];
  // If true, show the number of the frame if no label is present
  hideIndex?: boolean;
  labelsHaveBackground: boolean;
  onUpdateFrameField?: RichTextInputChangeEventHandler;
}

/** This component is shared between the shareable/present layout and the player
 * sidebar */
const ShareableItemInfo: React.FC<Props> = ({ frame, ...props }) => {
  const {
    include_label_text_in_output,
    include_frame_number_in_pdf,
    include_icons_in_pdf,
  } = props.storyboard;

  const index = frame.number || props.index;
  const noContent = React.useMemo(
    () =>
      every(
        eachFrameFieldMap(props.frame_fields, (f, fieldId) =>
          getFrameField(frame, fieldId),
        ),
        (i) => i === null || isFrameFieldEmpty(i),
      ),
    [frame, props.frame_fields],
  );

  if (isUndefined(frame) || isNull(frame)) {
    return null;
  }

  const indexLength = String(index).length;
  const styles = stylesForIndexLength[indexLength].w;
  const canEdit = !!props.onUpdateFrameField;

  const hasReference = !isFrameFieldEmpty(getFrameField(frame, 'reference'));
  const hasFrameNumber = include_frame_number_in_pdf && !props.hideIndex;
  const hasReferenceIcon =
    hasReference && (include_label_text_in_output || canEdit);

  const showReferenceField =
    (hasReference && canEdit) ||
    (hasReference && include_label_text_in_output) ||
    hasFrameNumber;

  const showBottomBorder =
    (showReferenceField && props.hideIndex) ||
    (!noContent && props.frame_fields.length && props.hideIndex);

  return (
    <div
      className={classNames(
        'relative',
        props.isImageLibraryLayout && !noContent && 'pt-8',
      )}
    >
      <div
        className={classNames(
          `flex-grow bg-white rounded-sm`,
          props.hideIndex ? 'px-6' : 'px-3',
          {
            '-ml-1.5': !noContent && props.hideIndex && include_icons_in_pdf,
          },
        )}
      >
        {showReferenceField ? (
          <div
            className={classNames('text-sm flex items-start gap-2', {
              'border-b border-b-border last:border-none':
                !noContent && !props.hideIndex,
              'py-4': !props.hideIndex,
              'pb-5': !noContent && props.hideIndex,
            })}
          >
            {hasFrameNumber ? (
              <div
                className={
                  'bg-surface rounded-lg truncate leading-6 text-sm text-center shrink-0 text-type-subdued'
                }
                style={styles}
              >
                {index}
              </div>
            ) : (
              hasReferenceIcon && (
                <TagIcon
                  className="w-[1.15rem] mt-[0.32rem] ml-[0.3rem] mr-[0.2rem] pr-[0.1rem]"
                  title={`Label`}
                />
              )
            )}

            {(canEdit || include_label_text_in_output) &&
            (hasReference || !props.hideIndex) ? (
              <div className={classNames(`w-full text-sm leading-6`)}>
                <RichTextInput
                  value={getFrameField(frame, 'reference')}
                  name="reference"
                  placeholder={canEdit ? `Label` : undefined}
                  onChange={props.onUpdateFrameField!}
                  disabled={!canEdit}
                  className={classNames(
                    'prose',
                    canEdit &&
                      'focus-within:ring ring-offset-4 rounded-sm focus-within:text-type-primary cursor-pointer',
                  )}
                  immediate={false}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {eachFrameFieldMap(
          props.frame_fields,
          (fieldInfo, fieldId, i, label) => {
            const value = getFrameField(frame, fieldId);

            // We've already shown the frame label, so we can skip the first field.
            // We also do not need to show empty fields here. `getFrameField` will
            // default to '' if a field cannot be found
            if (fieldId === 'reference' || isFrameFieldEmpty(value))
              return null;

            // hideIndex is true when this is shown on shareable as is
            // non-editable
            return (
              <div
                className={classNames('text-sm  flex items-start gap-2', {
                  'border-b border-b-border py-4 last:border-none':
                    !props.hideIndex,
                  'text-type-subdued pb-5': props.hideIndex,
                })}
                key={fieldId}
              >
                {include_icons_in_pdf ? (
                  <div
                    className={`mid-grey shrink-0 mt2 ${
                      props.labelsHaveBackground
                        ? 'flex items-center justify-center h20px bg-barely-grey br2 tc mr2'
                        : 'pt1' // compensate for line height lol
                    }`}
                    title={fieldInfo.label}
                    style={
                      // When we don't have a frame number, we don't have to apply
                      // a width in order to align with something
                      props.labelsHaveBackground && hasFrameNumber
                        ? styles
                        : undefined
                    }
                  >
                    {fieldInfo.icon ? (
                      <SVGIcon
                        className="h-6 w-auto -mt-0.5"
                        icon={fieldInfo.icon as any}
                      />
                    ) : (
                      <span className="f7 silver avenir-heavy">
                        {generateFrameFieldLabel(label)}
                      </span>
                    )}
                  </div>
                ) : null}
                <div className={`flex-auto text-sm text-type-subdued`}>
                  <RichTextInput
                    key={index + fieldId}
                    value={value}
                    name={fieldId}
                    placeholder={label}
                    onChange={props.onUpdateFrameField!}
                    disabled={!canEdit}
                    className={classNames(
                      'prose',
                      canEdit &&
                        'focus-within:ring ring-offset-4 rounded-sm focus-within:text-type-primary cursor-pointer',
                    )}
                    immediate={false}
                  />
                </div>
              </div>
            );
          },
        )}
      </div>

      {showBottomBorder && (
        <div
          className={classNames(
            'relative pb-4',
            !props.isImageLibraryLayout && 'mb-6',
          )}
        >
          <div className="absolute bottom-0 left-0 right-0 h-[0.1rem] border-b border-b-border" />
        </div>
      )}
    </div>
  );
};

const memo = React.memo<Props>(ShareableItemInfo);
export { memo as ShareableItemInfo };
