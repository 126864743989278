/** @prettier */
import { PlayerActions } from 'javascripts/flux/actions/player';
import type { DetailedFrame } from '../types/frame';
import { idleTimeout } from './idle-timeout';

export const getDuration = (
  frames: DetailedFrame[],
  /** Do we want a result as soon as possible? For example when we don't have a
   * previous value yet*/
  eager: boolean,
  /** Can be called multiple times */
  callback: (duration: number) => void,
) => {
  const nativeDuration = frames.reduce((count, frame) => {
    return count + frame.duration ?? 2000;
  }, 0);

  // If we want a result ASAP, call it now
  if (eager) callback(nativeDuration);

  idleTimeout(() => {
    if (frames.length === 0) return;
    // If there is an animatic, get the length from that
    PlayerActions.fetchAudioFile.defer({
      storyboardId: frames[0].storyboard_id,
      callback: (response) => {
        // The "empty" / default response has a duration of 0
        if (response && response.duration !== 0) {
          callback(response.duration * 1000);
        } else if (!eager) {
          callback(nativeDuration);
        }
      },
    });
  });
};
