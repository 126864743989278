window.FlyoverActions = alt.createActions(function () {

  this.open = function (data) {
    this.dispatch(data);
  };

  this.close = function () {
    this.dispatch();
  };

});

