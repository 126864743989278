const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');

module.exports = createReactClass({
  displayName: 'IconfinderEmpty',

  propTypes: {
    text: PropTypes.string.isRequired,
  },

  render() {
    return (
      <div className="text-type-subdued text-sm flex items-center justify-center pb-16">
        <span>{this.props.text}</span>
      </div>
    );
  }
});
