const _ = require('underscore');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');

window.AdminTrial = createReactClass({

  propTypes: {
    id: PropTypes.number.isRequired,
  },

  getInitialState: function() {
    return {
      trialStarted: false,
    }
  },

  _trial: function(e) {

    var duration = prompt("Please enter a Trial length in days");

    if (!_.isEmpty(duration)) {

      ajaxHelper.ajax({
        method: "post",
        url: "/admin/" + this.props.id + "/trial.json",
        data: {
          duration: duration,
        },
        success: function (response) {
          RequestActions.success.defer(duration + " day Trial started");
          this.setState({
            trialStarted: true,
          });
        }.bind(this),
        error: function(response) {
          RequestActions.error.defer("Sorry, we couldn't complete that task");
        }.bind(this),
      });
    }
  },

  render: function() {
    if (this.state.trialStarted) {
      return null;
    } else {
      return (
        <AdminButton
          onClick={this._trial}
          text="Start/extend trial"
        />
      );
    }
  }
});
