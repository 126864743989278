const _ = require('underscore');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');

window.AdminActiveUntil = createReactClass({

  propTypes: {
    id: PropTypes.number.isRequired,
  },

  _active: function(e) {

    var activeUntil = prompt("Please enter a date this subscription should run until. This is often the date from the user's stripe subscription page.");

    if (!_.isEmpty(activeUntil)) {
      ajaxHelper.ajax({
        method: "post",
        url: "/admin/" + this.props.id + "/active_until.json",
        data: {
          active_until: activeUntil,
        },
        success: function () {
          RequestActions.success.defer("Active date set, redirecting...");
          window.location = "/admin/" + this.props.id;
        }.bind(this),
        error: function() {
          RequestActions.error.defer("Date not changed");
        }.bind(this),
      });
    } else {
      RequestActions.error.defer("Date not changed");
    }
  },

  render: function() {
    return (
      <AdminButton
        onClick={this._active}
        text="Update subscription end date"
      />
    );
  }
});
