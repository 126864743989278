/** @prettier */

import React, { FC } from 'react';
import classNames from 'classnames';
import { useFocusable } from '../../../helpers/hooks/useFocusable';
import { nanoid } from 'nanoid/non-secure';

interface RadioButtonProps {
  className?: string;
  disabled?: boolean;
  label?: string;
  onChange?: (checked: boolean, value: string) => void;
  checked?: boolean;
  id?: string;
  [x: string]: any;
}

const RadioButton: FC<RadioButtonProps> = (props) => {
  const {
    className,
    disabled,
    label,
    onChange,
    checked = false,
    ...restProps
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && onChange) {
      onChange(event.currentTarget.checked, event.target.value);
    }
  };

  const [ref, hasFocus, inputStyle] = useFocusable();
  const [id] = React.useState(props.id ?? nanoid(10));

  return (
    <label
      className={classNames(
        'inline-flex items-center group h-6 cursor-default relative',
        { 'cursor-not-allowed': disabled },
        className,
      )}
      htmlFor={id}
    >
      <input
        ref={ref}
        tabIndex={disabled ? -1 : 0}
        className="absolute"
        type="radio"
        onChange={handleChange}
        checked={checked}
        id={id}
        style={inputStyle}
        {...restProps}
      />

      <span
        className={classNames(
          'box-border inline-flex w-4 h-4 rounded-xl border-2 items-center justify-center bg-transparent relative',
          {
            'border-border-dark group-hover:border-icon-hover': !disabled,
            'border-action-primary-disabled': disabled,
            'outline-blue': hasFocus,
          },
        )}
      >
        {checked && (
          <span
            className={classNames('rounded-xl inline-block w-2 h-2 absolute', {
              'bg-black group-hover:bg-icon-hover': !disabled,
              'bg-action-primary-disabled': disabled,
            })}
          />
        )}
      </span>

      {Boolean(label) && (
        <div
          className={classNames('ml-3.5 cursor-default', {
            'text-action-primary-disabled cursor-not-allowed': disabled,
          })}
        >
          {label}
        </div>
      )}
    </label>
  );
};

export default RadioButton;
