/** @prettier */
import * as React from 'react';
import { LockClosedIcon } from '@heroicons/react/20/solid';

interface BannerLockedProps {
  text: string;
}

export const BannerLocked: React.FC<BannerLockedProps> = ({
  text,
}): React.ReactElement => (
  <div className="flex items-center pl-3 pr-3.5 pt-1 pb-1.5 leading-tight font-semibold rounded-md bg-brand-yellow/70 text-black/70">
    <LockClosedIcon className="w-3.5 mt-[0.1rem]" />
    <span className="ml-1.5">{text}</span>
  </div>
);
