/** @format */
import screenSizeMonitor from './screenSizeMonitor';
import * as React from 'react';

/**
 * Efficiently monitors the current screen size and returns a boolean variable
 * indicating if the current screen width is above the `breakpoint` value.
 * The keyName is so that you can attach multiple listeners to the same width.
 */
export const useScreenSizeMonitor = (keyName: string, breakpoint: number) => {
  const [result, setResult] = React.useState(window.innerWidth > breakpoint);

  React.useEffect(() => {
    screenSizeMonitor.register(keyName, breakpoint);
    screenSizeMonitor.listen(keyName, setResult);
    return () => screenSizeMonitor.unlisten(keyName, setResult);
  }, [keyName, breakpoint]);

  return result;
};
