/** @prettier */
import type { frameFieldId, IFrame } from '../types/frame';
import wordsCount from 'words-count';
import { eachFrameFieldMap } from './eachFrameFieldMap';
import type {
  FrameField,
  IStoryboardPreferences,
  WordCountFromValue,
} from '../types/storyboard';
import { getFrameField } from './fieldDataHelpers';
import { isUndefined } from 'underscore';
import { markdownParser } from './markdown-parser';

const parser = new DOMParser();

type ExportType = {
  wordCount: number;
  fieldsUsed: frameFieldId[];
};

export const getWordCount = (
  frames: IFrame[],
  frameFields: FrameField[],
  preferences: Partial<IStoryboardPreferences>,
): ExportType => {
  const output: ExportType = {
    wordCount: 0,
    fieldsUsed: [],
  };

  let fieldIdToUse: WordCountFromValue = 'all';
  if (isUndefined(preferences.word_count_from)) {
    fieldIdToUse = preferences.word_count_all_fields ? 'all' : 'voiceover';
  } else {
    fieldIdToUse = preferences.word_count_from;
  }

  frames.forEach((frame, i) => {
    const amounts = eachFrameFieldMap(frameFields, (field, fieldId) => {
      if (fieldIdToUse === fieldId || fieldIdToUse === 'all') {
        // Make a list of all fields that are actually counted. We only have to
        // do this once
        if (i === 0) output.fieldsUsed.push(fieldId);

        const value = getFrameField(frame, fieldId);
        const parsedHTML = parser.parseFromString(
          markdownParser(value),
          'text/html',
        );

        return wordsCount(parsedHTML.body.innerText, {
          punctuation: ['-'],
        });
      } else {
        return 0;
      }
    });

    output.wordCount += amounts.reduce((o, i) => o + i, 0);
  });

  return output;
};
