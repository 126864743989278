/** @prettier */
import React, { CSSProperties, Dispatch, SetStateAction } from 'react';

const inputStyle: CSSProperties = {
  clipPath: 'polygon(0 0)',
  position: 'absolute',
};

type setRefType = Dispatch<SetStateAction<HTMLInputElement | null>>;

type returnType = [
  /** Use this as the value for the element's `ref` attribute */
  setRefType,
  /** is the item currently focused */
  boolean,
  /** convenient list of styles to apply to the input item when you want it
   * visually hidden but still accessible */
  CSSProperties,
];

/** Keeps track of an element's focus state and returns it as a variable. You
 * might still have to add a `tabIndex` to it.  */
export const useFocusable = (): returnType => {
  const [hasFocus, setHasFocus] = React.useState(false);
  const [ref, setRef] = React.useState<HTMLInputElement | null>();

  const handleFocus = React.useCallback<EventListener>(
    (e) => setHasFocus(e.type === 'focus'),
    [setHasFocus],
  );

  React.useEffect(() => {
    ref?.addEventListener('focus', handleFocus);
    ref?.addEventListener('blur', handleFocus);

    return () => {
      ref?.removeEventListener('focus', handleFocus);
      ref?.removeEventListener('blur', handleFocus);
    };
  }, [ref, handleFocus]);

  return [setRef, hasFocus, inputStyle];
};
