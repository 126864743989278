/** @prettier */
const { default: Avatar } = require('blackbird/components/avatar/Avatar');
const createReactClass = require('create-react-class');
const {
  getFirstCharacter,
} = require('javascripts/helpers/get-first-character');
var PropTypes = require('prop-types');

window.TeammateAvatarName = createReactClass({
  propTypes: {
    member: PropTypes.object.isRequired,
    showRole: PropTypes.bool,
  },

  render: function () {
    var member = this.props.member;

    return (
      <div className="flex gap-6 items-center">
        <Avatar
          size="sm"
          image={member.avatar_url}
          initial={getFirstCharacter(member.name ?? member.email ?? '?')}
        />
        <div className="text-black">
          {!member.name ? (
            <div>
              <div className="truncate" title={member.email}>
                {member.email}
              </div>
              <div className="text-type-disabled text-sm">Invitation sent</div>
            </div>
          ) : (
            <div>
              <div className="truncate">
                {member.name}
                {member.email === window.BoordsConfig.Email && ' (you)'}
              </div>
              {this.props.showRole ? (
                <div className="text-type-disabled text-sm">
                  {window.TeamRoleHelper.call(member.role)}
                </div>
              ) : (
                <div
                  className="text-type-disabled text-sm truncate"
                  title={member.email}
                >
                  {member.email}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
});
