/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { StoryboardStartersActions } from '../actions/storyboard_starters';
import { deserialise } from 'kitsu-core';
import { IStoryboard } from '../../types/storyboard';
import { toSlug } from '../../helpers/to-slug';
import { indexBy } from 'underscore';
import { ajax } from 'javascripts/helpers/ajax';
const api = require('../../helpers/api')();

const errorHandler = RequestErrorHandler('storyboardStarters');

export interface StoryboardStarter {
  type: 'starters';
  id: number;
  links: any;
  storyboard: {
    data: IStoryboard;
    links: any;
  };
  name: string;
  new_storyboard_name: string;
  description?: string;
  storyboard_id: number;
  /** This doesn't come from the server, but is calculated on the frontend */
  slug: string;
}

export class StoryboardStartersStore extends FakeAltStoreClass<StoryboardStartersStore> {
  starters?: Record<string, StoryboardStarter>;
  isLoading = false;

  constructor() {
    super();
    this.bindListeners({
      handleFetch: StoryboardStartersActions.FETCH,
    });
  }

  handleFetch() {
    this.isLoading = true;
    ajax({
      url: api.setRailsApiUrl('starters?include=storyboard'),
      beforeSend: api.setRailsApiAuthHeader,
    })
      .then((response) => {
        this.isLoading = false;
        const starters = deserialise(response).data.map(
          (i: StoryboardStarter) => ({
            ...i,
            slug: toSlug(i.name),
          }),
        );

        this.starters = indexBy(starters, (i) => i.slug);
        this.emitChange();
      })
      .catch(
        errorHandler({}, () => {
          this.isLoading = false;
          this.emitChange();
        }),
      );
  }
}

(window as any).StoryboardStartersStore = alt.createStore(
  StoryboardStartersStore,
  'StoryboardStarters',
);
