/** @prettier */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Memberships = require('../../teams/TeamMemberships.jsx');
const { default: Button } = require('blackbird/components/button/Button');
const {
  LoadingIndicator,
} = require('blackbird/components/common/loading-indicator/LoadingIndicator');
const can = require('../../../helpers/restricted-actions')();

module.exports = createReactClass({
  displayName: 'TeamPopupTeammembers',

  propTypes: {
    project: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  },

  mixins: [PureRenderMixin],

  getInitialState() {
    return TeamManagementStore.getState();
  },

  componentDidMount() {
    TeamManagementStore.listen(this._onChange);
  },

  componentWillUnmount() {
    TeamManagementStore.unlisten(this._onChange);
  },

  onStoreUpdate() {
    this.forceUpdate();
  },

  _onChange: function (state) {
    this.setState(state, () => this.forceUpdate());
  },

  _onCollaborationUpgrade: function (e) {
    if (can.access({ type: 'collaboration' })) {
      window.location = '/settings/team/manage';
    }
  },

  render() {
    const { team, isFetching } = this.state;
    const { HasFullCollaboration, TeamRole } = BoordsConfig;

    return (
      <div>
        <div className="flex items-center pb-3">
          <div className="flex-auto text-lg text-type-primary">{`Team Members`}</div>
          <div className="flex-shrink-0">
            <Button
              type="solid"
              link={
                HasFullCollaboration && TeamRole !== 'admin'
                  ? '/settings/team/' + team.id + '/manage'
                  : '/settings/team/manage'
              }
              size="sm"
            >
              {`Manage Team`}
            </Button>
          </div>
        </div>

        {isFetching && !team ? (
          <LoadingIndicator text={`Loading people…`} pad />
        ) : (
          <div className="overflow-y-auto" style={{ maxHeight: 400 }}>
            {HasFullCollaboration ? (
              <ProjectTeammates
                team={team}
                members={Memberships(team.active_and_pending_members, [
                  'admin',
                  'manager',
                  'supermember',
                ])}
                showToggle={false}
                project={this.props.project}
                {...this.props}
              />
            ) : (
              <ProjectTeammates
                team={team}
                members={Memberships(team.active_and_pending_members, [
                  'admin',
                ])}
                showToggle={false}
                project={this.props.project}
                {...this.props}
              />
            )}

            {
              <ProjectTeammates
                team={team}
                members={Memberships(team.active_and_pending_members, [
                  'member',
                ])}
                showToggle={HasFullCollaboration}
                project={this.props.project}
                {...this.props}
              />
            }

            <ProjectTeammates
              team={team}
              members={Memberships(team.active_and_pending_members, ['guest'])}
              showToggle={HasFullCollaboration}
              project={this.props.project}
              {...this.props}
            />

            <ProjectTeammates
              team={team}
              members={team.unregistered_invitations}
              showToggle={false}
              project={this.props.project}
              {...this.props}
            />
          </div>
        )}
      </div>
    );
  },
});
