/** @format */

import React, { useContext } from 'react';
import classNames from 'classnames';
import {
  StoryboardBreadcrumbContext,
  type ProjectHierarchy,
} from './StoryboardBreadcrumbContext';
import { ProjectSkeletonBreadcrumb } from '../projects/ProjectSkeleton';

const BreadcrumbItem: React.FC<{
  name: string;
  href: string;
  isLast: boolean;
}> = ({ name, href, isLast }) => {
  const content = (
    <div
      className={classNames(
        'text-type-subdued truncate whitespace-nowrap',
        'underline cursor-pointer text-type-subdued decoration-border-mid hover:no-underline underline-offset-2',
      )}
    >
      {name}
    </div>
  );
  const wrappedContent = <a href={href}>{content}</a>;
  return (
    <>
      {wrappedContent}
      {!isLast && <div className="text-type-disabled mx-1.5">{`/`}</div>}
    </>
  );
};

const EllipsisItem: React.FC = () => (
  <>
    <div className="mx-0 text-type-disabled">...</div>
    <div className="text-type-disabled mx-1.5">/</div>
  </>
);

export const StoryboardBreadcrumbContents: React.FC = () => {
  const { projectHierarchy, teamName, isLoading } = useContext(
    StoryboardBreadcrumbContext,
  );

  if (isLoading) {
    return <ProjectSkeletonBreadcrumb />;
  }

  if (!projectHierarchy || !teamName) {
    return null;
  }

  const buildBreadcrumbTrail = (
    project: ProjectHierarchy,
  ): ProjectHierarchy[] => {
    const trail: ProjectHierarchy[] = [];
    let currentProject: ProjectHierarchy | null = project;
    while (currentProject) {
      trail.unshift(currentProject);
      currentProject = currentProject.parent;
    }
    return trail;
  };

  const breadcrumbTrail = buildBreadcrumbTrail(projectHierarchy);

  const renderTruncatedBreadcrumb = () => {
    if (breadcrumbTrail.length <= 2) {
      // If there are 2 or fewer items, render them all
      return breadcrumbTrail.map((project, index) => (
        <BreadcrumbItem
          key={project.id}
          name={project.name}
          href={`/p/${project.slug}`}
          isLast={index === breadcrumbTrail.length - 1}
        />
      ));
    } else {
      // Render ellipsis and last two items
      return (
        <>
          <EllipsisItem />
          {breadcrumbTrail.slice(-2).map((project, index, array) => (
            <BreadcrumbItem
              key={project.id}
              name={project.name}
              href={`/p/${project.slug}`}
              isLast={index === array.length - 1}
            />
          ))}
        </>
      );
    }
  };

  return (
    <div className="flex items-center text-sm">
      <BreadcrumbItem name={teamName} href="/" isLast={false} />
      {renderTruncatedBreadcrumb()}
    </div>
  );
};
