/* eslint no-console: 0 */
const _ = require('underscore');
const Env = require("../../helpers/env.js")();

import {posthogClient} from 'javascripts/helpers/posthog.ts';

window.Track = alt.createActions(function () {

  const removedEvents = [`storyboard_load`, `starter_cta_load`, `storyboard_update`, `panelbar_open`, `panelbar_load`, `banner_view`, `generator_open`, `generator_complete`, `insert_blank_frame`, `presentation mode view`, `wizard_open`, `added text to storyboard`, `added images to storyboard`]

  const logging = JSON.parse(BoordsConfig.logTracking ?? false);
  const dev = Env.development()
  // const dev = false

  const posthog = posthogClient()

  const enrichPayload = (payload) => {
    return {
      ...payload,
      plan: BoordsConfig.PlanSlug,
      status: BoordsConfig.SubscriptionStatus,
    }
  };

  this.page = function (obj) {
    const props = enrichPayload(obj)

    if (dev) {
      if (!logging) return;
      console.info("[SEGMENT PAGE]: " + props.title);
      console.log(JSON.stringify(_.omit(props, 'title'), null, 2));
    } else {
      analytics.page("flyover", props.title, props, BoordsConfig.segmentTrackConfig);
    }
  };

  this.event = function (eventAction, payload={category: "Product"}, timeout = 0) {
    const props = enrichPayload(payload)

    if (removedEvents.includes(eventAction)) {
      if (dev) {
        if (!logging) return;
        console.log("🗑️ [EVENT NOT SENT]: " + eventAction, props);
      }
      return
    }

    if (dev) {
      if (!logging) return;
      setTimeout(() => {
        if (props.category === "Checkout" || props.posthogCapture) {
          console.log("[POSTHOG CAPTURE]: " + `ph_${eventAction}`, props);

          // We DO want to send these events to segment
          // as well as posthog
          if (eventAction === "CompleteRegistration") {
            console.log("💰 [SEGMENT CHECKOUT EVENT]: " + eventAction, props);
          } else if (eventAction === "InitiateCheckout") {
            console.log("💰 [SEGMENT CHECKOUT EVENT]: " + eventAction, props);
          } else if (eventAction === "Purchase") {
            console.log("💰 [SEGMENT CHECKOUT EVENT]: Subscribe", {
              value: (parseInt(props.value) / 100).toFixed(2),
              currency: BoordsConfig.Currency.toUpperCase(),
              predicted_ltv: props.newPlanInterval === "month" ? ((parseInt(props.value) / 100) * 6).toFixed(2) : (parseInt(props.value) / 100).toFixed(2),
            });
          }
        } else {
          console.log("[SEGMENT EVENT]: " + eventAction, props);
        }
      }, timeout)
    } else {
      setTimeout(() => {
        if (props.category === "Checkout" || props.posthogCapture) {
          posthog.capture(`ph_${eventAction}`, props)
          // We DO want to send these events to segment
          // as well as posthog
          if (eventAction === "CompleteRegistration") {
            analytics.track(eventAction, props);
          } else if (eventAction === "InitiateCheckout") {
            analytics.track(eventAction, props);
          } else if (eventAction === "Purchase") {
            analytics.track("Subscribe", {
              value: (parseInt(props.value) / 100).toFixed(2),
              currency: BoordsConfig.Currency.toUpperCase(),
              predicted_ltv: props.newPlanInterval === "month" ? ((parseInt(props.value) / 100) * 6).toFixed(2) : (parseInt(props.value) / 100).toFixed(2),
            })
          }
        } else {
          analytics.track(eventAction, props, BoordsConfig.segmentTrackConfig);
        }
      }, timeout)
    }
  };

  this.once = function(eventAction, payload={category: "Product"}) {
    const props = enrichPayload(payload)

    const key = "singleEvents";
    if (!window.LocalState.idIsInArray(eventAction, key)) {
      window.Track.event(eventAction, props);
      window.LocalState.addToArray(eventAction, key);
    }
  }

  this.aggregate = function(payload) {
    const props = enrichPayload(payload)

    const existingCount = _.isUndefined(window.LocalState.getValue(props.event)) ? 0 : parseInt(window.LocalState.getValue(props.event), 10);
    const newCount = existingCount + props.count;
    window.LocalState.setValue(props.event, newCount);
    if (newCount >= props.threshold) {
      window.Track.once(props.event + "_" + props.threshold, {category: "Product"});
    }
  };

  // No need to enrich group and track calls which are handled
  // in _segment_tracking.html.erb
  this.group = function (obj) {
    if (dev) {
      if (!logging) return;
      console.info("[SEGMENT GROUP]: " + obj.id);
      console.log(JSON.stringify(_.omit(obj, 'id'), null, 2));
    } else {
      analytics.group(obj.id, _.omit(obj, 'id'), BoordsConfig.segmentTrackConfig);
    }
  };

  this.identify = function (obj) {
    if (dev) {
      if (!logging) return;
      console.info("[SEGMENT IDENTIFY]: " + obj.id);
      console.log(JSON.stringify(_.omit(obj, 'id'), null, 2));
    } else {
      posthog.alias(obj.id)
      analytics.identify(obj.id, _.omit(obj, 'id'), BoordsConfig.segmentIdentifyConfig);
      posthog.identify(obj.id, _.omit(obj, 'id'))
    }
  };

});

