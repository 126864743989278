/** @prettier */
import classNames from 'classnames';
import { ColorPicker } from 'javascripts/components/shared/ColorPicker.react';
import { ColorPickerWellButton } from 'javascripts/components/shared/ColorPickerWellButton';
import { Plus } from 'lucide-react';
import React, { useContext } from 'react';
import { GeneratorContext } from './GeneratorContext';

export const GeneratorColors: React.FC = () => {
  const { style, isPanelbar, colors, setColors, addColor, removeColor } =
    useContext(GeneratorContext);

  if (!style.hasColors) {
    return null;
  }

  const handleChange = (index: number) => (color: string) => {
    const newColors = [...colors];
    newColors[index] = color;
    setColors(newColors);
  };

  return (
    <div>
      <div className="flex items-center mb-1.5">
        <div
          className={classNames(
            'font-semibold mr-1',
            isPanelbar ? 'text-sm' : 'text-base',
          )}
        >
          {`Color Guide`}
        </div>
        <HelpPopup text={`Specify colors you'd like to use in your image.`} />
      </div>
      <div className="flex items-center space-x-2">
        {colors.map((color, index) => (
          <div key={index} className="relative cursor-pointer group/color">
            <ColorPicker
              placement="bottom-start"
              color={color}
              onChange={handleChange(index)}
              icon={<ColorPickerWellButton color={color} />}
              showAlpha={false}
              updateOnChange={true}
            />
            <div
              onClick={() => removeColor(index)}
              className="absolute flex items-center justify-center w-4 h-4 text-xs text-black border rounded-full opacity-0 cursor-pointer bg-white/70 -top-2 -right-2 border-border group-hover/color:opacity-100"
            >
              {`×`}
            </div>
          </div>
        ))}
        {colors.length < 4 && (
          <div
            onClick={addColor}
            className="flex items-center justify-center border cursor-pointer w-7 h-7 border-border rounded-md text-type-subdued"
          >
            <Plus className="w-4 h-4" />
          </div>
        )}
      </div>
    </div>
  );
};
