/** @format */
const styleForIndexLength = (v) => ({
  p: { paddingLeft: v + 0.6 + 'em' },
  w: { width: v + 'em' },
});

/** pre-generated styles for index length */
export const stylesForIndexLength = [
  styleForIndexLength(null),
  styleForIndexLength(1.8), // 2
  styleForIndexLength(2.3), // 2d
  styleForIndexLength(2.9), // 11a
  styleForIndexLength(3.55), // 100a
];
