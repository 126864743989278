/** @prettier */
import * as React from 'react';

export const PricingTableEduBanner: React.FC = () => {
  return (
    <div className="absolute px-10 -top-[2.5rem] -left-10 -right-10 bg-brand-yellow-25 py-2 text-sm">
      <div className="font-semibold text-center text-black/70">
        {`50% education & non-profit discount applied`}
      </div>
    </div>
  );
};
