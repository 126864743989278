/**@prettier */
import React, { useContext } from 'react';
import Logo from 'blackbird/components/logo/Logo';

import type { FeedbackKind } from 'blackbird/components/feedback/types';
import SignupScreen from './SignupScreen';
import SignInScreen from './SignInScreen';
import FinishOverboardingScreen from './FinishOverboarding';
import ProjectScreen from './ProjectScreen';
import TeamNameScreen from './TeamNameScreen';
import ResetPasswordForm from './ResetPassword';
import WelcomeScreen from './WelcomeScreen';
import ChangePasswordScreen from './ChangePassword';
import EnterCC from './EnterCC';
import type { SoundBite } from 'javascripts/flux/stores/testimonial';
import './styles.css';
import AddTaxId from './AddTaxId';
import Button from 'blackbird/components/button/Button';
import { SubdomainProvider } from 'blackbird/helpers/contexts/SubdomainContext';
import classNames from 'classnames';

export enum OnboardingType {
  SIGN_IN = 'signin',
  SIGN_UP = 'signup',
  SIGN_UP_EMAIL = 'signup_email',
  WELCOME = 'welcome',
  NAME_PROJECT = 'name_project',
  NAME_TEAM = 'name_team',
  ENTER_CC = 'enter_cc',
  TAX_ID = 'tax_id',
  FINISHING = 'finishing',
  RESET_PASSWORD = 'reset_password',
  CHANGE_PASSWORD = 'change_password',
}

export interface OnboardingProps {
  type: OnboardingType;
  notification?: {
    kind: FeedbackKind;
    message: string;
  };
  testimonial?: SoundBite;
  projectName?: string;
  projectId?: number;
  username?: string;
  hideSideGraphic?: boolean;
  authenticityToken?: string;
  formAction?: string;
  onToggleScreen?: (type: OnboardingType) => void;
  googleSigninPath?: string;
  onSignIn?: ({ email, password }: { email: string; password: string }) => void;
  onResetPassword?: (email: string) => void;
  onSignUp?: ({
    email,
    password,
    name,
  }: {
    email: string;
    password: string;
    name: string;
  }) => void;
  onSurveyComplete?: (role?: string) => void;
  onSaveTeamName?: () => void;
  onNameSkip?: () => void;
  onProjectSetup?: (projectName: string) => void;
  onEnterCC?: (projectName: string) => void;
  onChangePassword?: ({
    password,
    confirmedPass,
  }: {
    password: string;
    confirmedPass: string;
  }) => void;
  passwordResetToken?: string;
  inviteToken?: string;
  invitedEmail?: string;
  upgradeProps?: Record<string, any>;
  upgradeState?: Record<string, any>;
  planType?: string;
}

export interface ITeamNameProps {
  name?: string;
  subdomain?: string;
}

const TeamNameContainer: React.FC<OnboardingProps> = (props) => (
  <SubdomainProvider team_id={BoordsConfig.TeamId}>
    <TeamNameScreen {...props} />
  </SubdomainProvider>
);

const ComponentMap: Record<OnboardingType, React.FC<any>> = {
  [OnboardingType.SIGN_UP]: SignupScreen,
  [OnboardingType.SIGN_IN]: SignInScreen,
  [OnboardingType.SIGN_UP_EMAIL]: SignupScreen,
  [OnboardingType.RESET_PASSWORD]: ResetPasswordForm,
  [OnboardingType.ENTER_CC]: EnterCC,
  [OnboardingType.TAX_ID]: AddTaxId,
  [OnboardingType.FINISHING]: FinishOverboardingScreen,
  [OnboardingType.NAME_PROJECT]: ProjectScreen,
  [OnboardingType.NAME_TEAM]: TeamNameContainer,
  [OnboardingType.WELCOME]: WelcomeScreen,
  [OnboardingType.CHANGE_PASSWORD]: ChangePasswordScreen,
};

const Onboarding: React.FC<OnboardingProps> = (props) => {
  const FormComponent = ComponentMap[props.type];
  return (
    <div className="relative flex min-h-vp">
      <div
        className={classNames(
          'flex flex-col flex-grow sm:p-8 bg-white sm:bg-surface',
        )}
      >
        <FormComponent {...props} />
      </div>
    </div>
  );
};

export default Onboarding;
