const { default: Select } = require('blackbird/components/form/select/Select');
const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
const NewProjectFormTeam = createReactClass({

  propTypes: {
    teams: PropTypes.array.isRequired,
    teamId: PropTypes.number
  },
  componentDidMount:function(){
    NewProjectStore.listen(this._onChange);
    if (!this.props.teamId) this._setProjectTeamId(this.props.teams[0].id)
  },
  componentWillUnmount: function() {
    NewProjectStore.unlisten(this._onChange);
  },
  _setProjectTeamId: function(value) {
    NewProjectActions.setProjectTeamId(parseInt(value));
  },
  getInitialState: function() {
    return {
      projectTeamId: this.props.teamId ?? NewProjectStore.getState().project_team.id,
    }
  },
  _onChange:function(state){
    this.setState({projectTeamId:state.project_team.id})
  },
  render: function() {
    const {t} = this.props;
    var teams = this.props.teams;
    const options =  teams.map((team)=>({
      value:String(team.id),
      label:team.name
    }));
    if (teams.length <= 1) {

      return null;

    } else {

      return (
        <Select
          disableInput
          label={t('modals.new_project.team')}
          value={String(this.state.projectTeamId)}
          selectContainerClassName='w-full'
          options={options}
          size="md"
          onChange={this._setProjectTeamId}/>
      );

    }

  }

});
window.NewProjectFormTeam = NewProjectFormTeam;
