/** @format */
import * as i18next from 'i18next';
import { FakeAltStoreClass } from './AltStore';
import {
  RequestActions,
  type translateableNotification,
} from '../actions/request';
import '../actions/team_management';
import '../actions/coverpage';
import { isString } from 'underscore';

export class RequestStore extends FakeAltStoreClass<RequestStore> {
  message_type: 'notice' | 'error';
  message: string | null;
  messageLink?: string;
  working = false;

  constructor() {
    super();
    this.bindListeners({
      handleStart: [RequestActions.START, CoverpageActions.SAVE],
      handleSuccess: RequestActions.SUCCESS,
      handleError: RequestActions.ERROR,
      handleComplete: RequestActions.COMPLETE,
    });
  }

  handleStart() {
    this.working = true;
  }

  handleSuccess(message) {
    this.message_type = 'notice';
    this.parseTranslateableNotification(message);
    this.working = false;
  }

  handleError(message: translateableNotification) {
    this.message_type = 'error';
    this.parseTranslateableNotification(message);
    this.working = false;
  }

  handleComplete() {
    this.message = null;
    this.messageLink = undefined;
    this.working = false;
  }

  private parseTranslateableNotification(message: translateableNotification) {
    if (!isString(message)) {
      this.message = message.key
        ? i18next.t(message.key, message.data)
        : message.message!;
      this.messageLink = message.link;
    } else {
      this.message = message;
      this.messageLink = undefined;
    }
  }
}
(window as any).RequestStore = alt.createStore(RequestStore, 'RequestStore');
