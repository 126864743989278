/** @format */

import classNames from 'classnames';
import { LayoutTemplate } from 'lucide-react';
import React, { useState } from 'react';
import { SecondaryLink } from '../common/SecondaryLink';
import { TeamUpgradePill } from '../team/TeamUpgradePill';
import { ManageTemplatesButton } from './ManageTemplatesLink';
import { useTemplates } from './TemplatesContext';
import { TemplateSelect } from './TemplateSelect';

interface TemplateCtaProps {
  children: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const TemplateCta: React.FC<TemplateCtaProps> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <div
      className={classNames(
        className,
        'flex items-center py-2 pl-3 pr-4 text-sm bg-surface rounded-md group/templatecta',
        onClick && 'cursor-pointer',
      )}
      onClick={onClick}
    >
      <div className="flex-shrink-0 mr-2">
        <LayoutTemplate className="w-4 h-4 opacity-60" />
      </div>
      <div className="pb-0.5 flex-auto">{children}</div>
    </div>
  );
};

interface ActiveTemplateIndicatorProps {
  teamId: number;
  defaultTemplateId?: string;
  onSelect?: (templateId: string) => void;
  children: React.ReactNode;
}

export const ActiveTemplateIndicator: React.FC<ActiveTemplateIndicatorProps> = (
  props,
) => {
  const { children, defaultTemplateId, teamId } = props;
  const {
    templates,
    fetchTemplates,
    currentTemplate,
    setCurrentTemplate,
    setCurrentTemplateFromId,
    isFetched,
    isFeatureAvailable,
  } = useTemplates();

  const [showTemplateForm, setShowTemplateForm] = useState(false);

  React.useEffect(() => {
    if (!isFetched) {
      fetchTemplates(teamId as any);
    }
  }, [teamId, isFetched]);

  React.useEffect(() => {
    if (isFetched && defaultTemplateId) {
      setCurrentTemplateFromId(defaultTemplateId);
    }
  }, [defaultTemplateId, isFetched]);

  return !isFetched ? (
    <></>
  ) : currentTemplate && !showTemplateForm ? (
    <>
      {currentTemplate ? (
        <TemplateCta>
          <div className="flex items-center ">
            <div className="flex-auto pr-2 ">
              <span className="inline-block max-w-xs pt-1 truncate text-ellipsis">
                <span>{`Using`}</span>
                <span className="mx-1 font-semibold">
                  {currentTemplate.name}
                </span>
                <span>{`template`}</span>
              </span>
            </div>
            <SecondaryLink
              onClick={() => {
                setCurrentTemplate(null);
                setShowTemplateForm(false);
              }}
            >
              {`Remove`}
            </SecondaryLink>
          </div>
        </TemplateCta>
      ) : (
        <TemplateCta onClick={() => setShowTemplateForm(true)}>
          <div>{`Create with template`}</div>
        </TemplateCta>
      )}
    </>
  ) : currentTemplate && showTemplateForm ? (
    <div>
      <TemplateSelect label={`Template`} {...props} />
      <div className="flex items-center mt-2 space-x-3">
        <ManageTemplatesButton useDirectLink />
      </div>
    </div>
  ) : !isFeatureAvailable ? (
    <div>
      <>{children}</>
      <div className="flex items-center my-4">
        <div className="flex-auto border-t border-border" />
        <div className="mx-2 text-sm text-type-disabled">{`or`}</div>
        <div className="flex-auto border-t border-border" />
      </div>
      <TemplateCta className="!bg-premium-50">
        <div className="flex items-center">
          <div className="flex-auto">{`Create using template`}</div>
          <div>
            <TeamUpgradePill />
          </div>
        </div>
      </TemplateCta>
    </div>
  ) : isFeatureAvailable && templates.length > 0 ? (
    <div>
      <>{children}</>
      <div className="flex items-center my-4">
        <div className="flex-auto border-t border-border" />
        <div className="mx-2 text-sm text-type-disabled">{`or`}</div>
        <div className="flex-auto border-t border-border" />
      </div>
      <TemplateCta
        onClick={() => {
          setCurrentTemplateFromId(templates[0].id);
          setShowTemplateForm(true);
        }}
      >
        <span className="group-hover/templatecta:underline">{`Create using template`}</span>
      </TemplateCta>
    </div>
  ) : (
    <>{children}</>
  );
};
