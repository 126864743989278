const _ = require('underscore');
(() => {
  class PermissionStore {

    constructor() {
      this.source   = null;
      this.url      = null;
      this.callback = null;

      this.bindListeners({
        handleVerifyManageStoryboard:    PermissionActions.VERIFY_MANAGE_STORYBOARD,
        handleVerifyStoryboard:          PermissionActions.VERIFY_STORYBOARD,
        handleVerifyProject:             PermissionActions.VERIFY_PROJECT,
        handleVerifyStoryboardMobility:  PermissionActions.VERIFY_STORYBOARD_MOBILITY,
      });
    }

    handleVerifyProject(obj) {
      this._verifyPermissions(obj.team_id + "/new_project_within_team", obj, 'newProject');
    }

    handleVerifyStoryboard(obj) {
      this._verifyPermissions(obj.project_id + "/new_storyboard_within_project", obj, 'newStoryboard');
    }

    handleVerifyStoryboardMobility(obj) {
      this._verifyPermissions(obj.storyboard_id + "/storyboard_mobility", obj, 'moveStoryboard');
    }

    handleVerifyManageStoryboard(obj) {
      this._verifyPermissions(obj.storyboard_id + "/manage_storyboard", obj, 'manageStoryboard');
    }

    _verifyPermissions(url, obj, identifier = 'undefined') {
      ajaxHelper.ajax({
        method: "get",
        url: '/permission/' + url + '.json',
        success: function(response) {
          obj.onSuccess(response);
          this.emitChange();
        }.bind(this),
        error: function(request, status, error) {
          if (identifier === 'newStoryboard') {
            const {
              count,
              limit,
              team_admin_id
            } = request.response.JSON.data.attributes

            FlyoverActions.open.defer({
              type: 'userPermission',
              props: {
                featureName: "newStoryboard",
                identifier: 'newStoryboard',
                userId: BoordsConfig.Uid,
                count: count,
                limit: limit,
                team_admin_id: team_admin_id,
              },
            });
          } else if (_.isUndefined(obj.project_id)) {
            FlyoverActions.open.defer({
              type: "newProject",
            });
          } else {
            obj.onError();
            this.emitChange();
          }
        }.bind(this),
      });
    }

  }

  window.PermissionStore = alt.createStore(PermissionStore, 'PermissionStore');

})();
