/** @prettier */
import * as React from 'react';
import { useDrag } from 'react-dnd';
import {
  imageLibraryDragItem,
  DRAGGABLE_TYPES,
} from '../../../shared/dragAndDropUtils';
import classNames from 'classnames';
import Button from 'blackbird/components/button/Button';
import Img from 'javascripts/components/shared/Img';
import {
  apiRequest,
  reportApiRequestError,
} from 'blackbird/helpers/apiRequestHelper';
import type { UnsplashPhoto, UnsplashUser } from './UnsplashCategories';

interface Props {
  table: UnsplashPhoto;
  naturalAspectRatio?: boolean;
  onClick?: (imageUrl: string) => void;
}

const unsplashCredit = (user: UnsplashUser) =>
  '<div class="f6">Photo by <a class="underline no-underline-hover" href="' +
  user.links.html +
  '?utm_source=boords&utm_medium=referral">' +
  user.name +
  '</a> on <a class="underline no-underline-hover" href="https://unsplash.com/?utm_source=boords&utm_medium=referral">Unsplash</a></div>';

const markAsDownloaded = async (photo: UnsplashPhoto) => {
  Track.event.defer('insert_unsplash_image');

  const request = await apiRequest({
    method: 'put',
    path: `unsplash/${photo.id}.json`,
  });
  if (!request.ok)
    return reportApiRequestError(request, { method: 'put', messageKey: null });
};

export const UnsplashItem: React.FC<Props> = ({
  table,
  onClick,
  naturalAspectRatio,
}) => {
  const [{ isDragging }, ref] = useDrag(() => ({
    type: DRAGGABLE_TYPES.unsplash,
    item: {
      id: table.id,
      image: table.urls.full,
    } as imageLibraryDragItem,
    canDrag: () => !onClick,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop()) markAsDownloaded(table);
    },
  }));

  const handleClick = React.useCallback(() => {
    onClick!(table.urls.regular);
    markAsDownloaded(table);
  }, [onClick, table]);

  return (
    <div
      className={`relative flex items-center justify-center ${
        onClick ? 'pointer' : 'cursor-grab'
      }`}
      ref={ref}
    >
      {onClick ? (
        <div className="absolute inset-0 z-10 opacity-0 hover:opacity-100">
          <div className="absolute inset-0 rounded-sm bg-type-primary opacity-30 ma1" />
          <div className="absolute inset-0 z-20 flex items-center justify-center">
            <Button size="sm" onClick={handleClick}>
              Insert
            </Button>
          </div>
          <div className="absolute bottom-0 right-0 z-30 ma2">
            <HelpPopup text={unsplashCredit(table.user)} />
          </div>
        </div>
      ) : null}

      {naturalAspectRatio ? (
        <div className="relative group">
          <Img
            src={table.urls.thumb}
            className={classNames('max-w-full max-h-full')}
          />
          <a
            className="absolute bottom-0 right-0 z-30 m-1 opacity-0 group-hover:opacity-100"
            href={`${table.user.links.html}?utm_source=boords&utm_medium=referral`}
            target="blank"
            rel="noopener noreferrer"
          >
            <HelpPopup text={unsplashCredit(table.user)} />
          </a>
        </div>
      ) : (
        <div
          className={classNames(
            `cover rounded-sm  w-full aspect-square z-0 bg-center`,
            {
              'opacity-60': isDragging,
            },
          )}
          style={{ backgroundImage: "url('" + table.urls.thumb + "')" }}
        />
      )}
    </div>
  );
};
