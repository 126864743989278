/**@prettier */
const navigateToRoute = require('../../helpers/router/navigate-to-route');
const createReactClass = require('create-react-class');
const { default: Dialog } = require('blackbird/components/dialog/Dialog');
const { default: Button } = require('blackbird/components/button/Button');
window.ArchiveUpgradePrompt = createReactClass({
  _upgrade(e) {
    e.preventDefault();
    FlyoverActions.open.defer({
      type: 'inlinePricing',
    });
    // navigateToRoute('pricing');
  },

  _delete(e) {
    e.preventDefault();
    StoryboardActions.deleteStoryboard(this.props.storyboard);
  },

  render() {
    return (
      <Dialog
        size="slim"
        hideActions
        isOpen
        isDark
        title={`Delete Storyboard?`}
        subtitle={`Pick your plan to archive & restore storyboards.`}
      >
        <div className="flex flex-col text-center gap-4">
          <div>
            {`Archived projects and storyboards are only available for paid plans. If you delete this storyboard, you can't restore it later.`}
          </div>
        </div>
        <div className="flex flex-col items-center gap-6">
          <Button
            className="w-full"
            size="lg"
            onClick={this._upgrade}
            type="pink"
          >
            {`Compare Plans`}
          </Button>
          <div className="flex justify-center w-full text-sm">
            <a
              className="underline cursor-pointer text-type-subdued hover:no-underline"
              onClick={this._delete}
            >
              No, I want to delete this storyboard
            </a>
          </div>
        </div>
      </Dialog>
    );
  },
});
