/** @prettier */
import classNames from 'classnames';
import * as React from 'react';
import { times } from 'underscore';

export const CircularProgressIndicator: React.FC<{
  steps: number;
  value: number;
  showCheck?: boolean;
  hideNumber?: boolean;
}> = (props) => {
  const { hideNumber } = props;

  return (
    <div className="relative flex items-center justify-center avenir-heavy white lh-solid">
      {times(props.steps, (step) => {
        const active = step < props.value;
        const isLastStep = step + 1 === props.steps;
        return (
          <React.Fragment key={step}>
            <div
              key={step}
              className={classNames(
                `rounded-full text-xs leading-none flex items-center justify-center tc z-1`,
                {
                  'bg-type-primary text-white': active && !hideNumber,
                  'bg-surface-frame': active && hideNumber,
                  'w-3 h-3 mr-1': hideNumber,
                  'w-7 h-7': !hideNumber,
                  'border-2 border-border text-type-disabled':
                    !active && !hideNumber,
                  'border-2 bg-transparent border-surface-frame':
                    !active && hideNumber,
                },
              )}
            >
              {!hideNumber && step + 1}
            </div>
            {!isLastStep && !hideNumber && (
              <div className="w-[68px] border-t-2 border-border border-dashed"></div>
            )}
          </React.Fragment>
        );
      })}

      {props.showCheck && (
        <React.Fragment>
          <div className="w-[68px] border-t-2 border-border border-dashed"></div>

          <div
            className={`w-7 h-7 rounded-full text-xs flex items-center justify-center tc z-1 border-2 border-border text-type-disabled`}
          >
            {`✓`}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
