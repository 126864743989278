/** @format */
import { eachFrameFieldMap } from 'javascripts/helpers/eachFrameFieldMap';
import { getFrameField } from 'javascripts/helpers/fieldDataHelpers';
import type { FrameField } from 'javascripts/types/storyboard';
import type { IFrame } from '../../../types/frame';

export const getAllText = (userFrameFields: FrameField[], frames: IFrame[]) => {
  return frames.reduce((o, current) => {
    eachFrameFieldMap(userFrameFields, (frameField, fieldId) => {
      const fieldValue = getFrameField(current, fieldId);
      if (fieldValue.length) {
        o += ' ' + fieldValue;
      }
    });

    return o;
  }, '');
};
