/** @prettier */
import * as React from 'react';
import { formatCurrency } from 'javascripts/helpers/currencyHelper';

import { type Price } from 'blackbird/helpers/contexts/BillingContext';

interface PricingYearlySavingProps {
  prices: Price[];
}

export const PricingYearlySaving: React.FC<PricingYearlySavingProps> = ({
  prices,
}) => {
  const [yearlySaving, setYearlySaving] = React.useState<number | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (prices) {
      const yearlyPlan = prices.find(
        (p) => p.interval === 'year' && p.currency === BoordsConfig.Currency,
      );
      const monthlyPlan = prices.find(
        (p) => p.interval === 'month' && p.currency === BoordsConfig.Currency,
      );

      if (monthlyPlan && yearlyPlan) {
        setYearlySaving((monthlyPlan.amount * 12 - yearlyPlan.amount) / 100);
      }
    }
  }, [prices]);

  return !yearlySaving ? null : (
    <div className="flex items-center justify-start text-xs text-type-green space-x-1">
      <span className="text-type-subdued">{`Charged annually`}</span>
      <span>{`(Save`}</span>
      <span className="">
        <span className="">{`~${formatCurrency({
          amount: yearlySaving,
          currency: BoordsConfig.Currency,
          showZeros: false,
        })})`}</span>
      </span>
    </div>
  );
};
