window.UpgradePromptActions = alt.createActions(function () {

  this.show = function (source) {
    this.dispatch(source);
  };

  this.setSource = function (source) {
    this.dispatch(source);
  };

});

