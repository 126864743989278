/** @prettier */
import { type FrameStore } from '../stores/frame';
import { createTypedActions } from '../stores/AltStore';

// Does this have to be a class? would a simple object work?
class FrameActions {
  tour: FrameStore['handleTour'] = function (options) {
    this.dispatch(options);
  };

  fetchFrames: FrameStore['handleFetchFrames'] = function (options) {
    this.dispatch(options);
  };

  receiveFrames: FrameStore['handleReceiveFrames'] = function (options) {
    this.dispatch(options);
  };

  setFramesStatus: FrameStore['handleSetFramesStatus'] = function (options) {
    this.dispatch(options);
  };

  moveToIndex: FrameStore['handleMoveToIndex'] = function (options) {
    this.dispatch(options);
  };

  updateFrameImageUrls: FrameStore['handleUpdateFrameImageUrls'] = function (
    args,
  ) {
    this.dispatch(args);
  };

  updateFrameGeneratorPreferences: FrameStore['handleUpdateFrameGeneratorPreferences'] =
    function (args) {
      this.dispatch(args);
    };

  selectFrame: FrameStore['handleSelectFrame'] = function (frame_id) {
    this.dispatch(frame_id);
  };

  selectMultiple: FrameStore['handleSelectMultiple'] = function (frame_id) {
    this.dispatch(frame_id);
  };

  deselectAll: FrameStore['handleDeselectAll'] = function () {
    this.dispatch();
  };

  selectAll: FrameStore['handleSelectAll'] = function () {
    this.dispatch();
  };

  setImageStatus: FrameStore['handleSetImageStatus'] = function (obj) {
    this.dispatch(obj);
  };

  deleteFrames: FrameStore['handleDeleteFrames'] = function (frame_id) {
    this.dispatch(frame_id);
  };

  showUploadImagePrompt: FrameStore['handleShowUploadImagePrompt'] = function (
    frame_id,
  ) {
    this.dispatch(frame_id);
  };

  bulkFrameUpload: FrameStore['handleBulkFrameUpload'] = function (options) {
    this.dispatch(options);
  };

  sortFrames: FrameStore['handleSortFrames'] = function (options) {
    this.dispatch(options);
  };

  saveText: FrameStore['handleSaveText'] = function (options) {
    this.dispatch(options);
  };

  uploadImage: FrameStore['handleUploadImage'] = function (options) {
    this.dispatch(options);
  };

  uploadDrawnImage: FrameStore['handleUploadDrawnImage'] = function (options) {
    this.dispatch(options);
  };

  insertFrame: FrameStore['handleInsertFrame'] = function (options) {
    this.dispatch(options);
  };

  insertFrames: FrameStore['handleInsertFrames'] = function (options) {
    this.dispatch(options);
  };

  triggerDropzoneFileDialog: FrameStore['handleTriggerDropzoneFileDialog'] =
    function (frame_id) {
      this.dispatch(frame_id);
    };

  startDragging: FrameStore['handleStartDragging'] = function (frame_id) {
    this.dispatch(frame_id);
  };

  endDragging: FrameStore['handleEndDragging'] = function () {
    this.dispatch();
  };

  mouseEnterInsertBefore: FrameStore['handleMouseEnterInsertBefore'] =
    function (frame_id) {
      this.dispatch(frame_id);
    };

  mouseEnterInsertAfter: FrameStore['handleMouseEnterInsertAfter'] = function (
    frame_id,
  ) {
    this.dispatch(frame_id);
  };

  mouseLeaveInsertBefore: FrameStore['handleMouseLeaveInsertBefore'] =
    function () {
      this.dispatch();
    };

  mouseLeaveInsertAfter: FrameStore['handleMouseLeaveInsertAfter'] =
    function () {
      this.dispatch();
    };

  clearFrame: FrameStore['handleClearFrame'] = function (frame_id) {
    this.dispatch(frame_id);
  };

  updateDuration: FrameStore['handleUpdateDuration'] = function (frame_id) {
    this.dispatch(frame_id);
  };

  batchUpdateDurations: FrameStore['handleBatchUpdateDurations'] = function (
    updates,
  ) {
    this.dispatch(updates);
  };

  batchSet: FrameStore['handleBatchSet'] = function (updates) {
    this.dispatch(updates);
  };

  selectGroup: FrameStore['handleSelectGroup'] = function (updates) {
    this.dispatch(updates);
  };

  groupFrames: FrameStore['handleGroupFrames'] = function (updates) {
    this.dispatch(updates);
  };

  ungroupFrames: FrameStore['handleUngroupFrames'] = function (updates) {
    this.dispatch(updates);
  };

  updateFrameHistory: FrameStore['handleUpdateFrameHistory'] = function (
    updates,
  ) {
    this.dispatch(updates);
  };

  ensureUndoItems: FrameStore['ensureUndoItems'] = function () {
    this.dispatch();
  };

  updateImageStatus: FrameStore['handleUpdateImageStatus'] = function (args) {
    this.dispatch(args);
  };

  permanentlyDeleteFrame: FrameStore['handlePermanentlyDeleteFrame'] =
    function (args) {
      this.dispatch(args);
    };
}

const actions = createTypedActions<FrameActions>(FrameActions);
export { actions as FrameActions };

// This is not how it's supposed to be, but so many views rely on this, that
// it's better to keep it like this until every instance is updated to use
// actual imports
(window as any).FrameActions = actions;
