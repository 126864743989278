const nameHeading = name => {
  return name ? `Welcome, ${name}!` : 'Welcome to Boords!'
}

const staticWelcomeStepCopy = name => {
  return {
    userType: {
      heading: nameHeading(name),
      subheading: 'What is your job title?',
      roles: [
        {
          label: 'Founder / CEO',
          slug: 'founder'
        },
        {
          label: 'Creative Director',
          slug: 'creative_director'
        },
        {
          label: 'Motion Designer',
          slug: 'motion_designer'
        },
        {
          label: 'Producer',
          slug: 'producer'
        },
        {
          label: 'Animator',
          slug: 'animator'
        },
        {
          label: 'Filmmaker',
          slug: 'filmmaker'
        },
        {
          label: 'Student or Teacher',
          slug: 'student'
        },
        {
          label: 'Other',
          slug: 'other'
        },
      ]
    },
    starterTemplate: {
      heading: 'Choose Storyboard Type',
      subheading: "What are you storyboarding today?",
      otherButton: 'Other'
    }
  }
}

const storyboardNameValues = (obj = {}) => (
  Object.assign({
    heading: 'Enter Storyboard Name',
    subheading: 'You can change this later on',
    placeholder: 'E.g. Film Name',
    button: 'Generate Storyboard'
  }, obj)
)

const welcomeNameCopy = slug => {
  switch (slug) {
    case 'product-launch':
      return storyboardNameValues({
        heading: "Enter Product Name",
        subheading: "You're creating a Product Launch storyboard. What's the name of the Product?",
        placeholder: 'e.g. iPhone',
      })
    case 'product-explainer':
      return storyboardNameValues({
        heading: "Enter Product Name",
        subheading: "You're creating a Product Explainer storyboard. What's the name of the Product?",
        placeholder: 'e.g. iPhone',
        button: 'Generate Explainer Storyboard'
      })
    case 'how-to-video':
      return storyboardNameValues({
        heading: 'Enter Storyboard Name',
        subheading: "You're creating a 'How To' storyboard. What is your storyboard about?",
        placeholder: 'e.g. How to do X'
      })
    case 'startup-explainer':
      return storyboardNameValues({
        heading: 'Enter Startup Name',
        subheading: "You're creating a Startup Explainer storyboard. What's the name of the Startup?",
        placeholder: 'e.g. Tesla',
        button: 'Generate Explainer Storyboard'
      })
    case 'short-film':
      return storyboardNameValues({
        heading: 'Enter Film Name',
        subheading: "You're creating a Short Film storyboard. What will the film be called?",
        placeholder: 'e.g. Taxi Driver',
        button: 'Generate Short Film Storyboard'
      })
    case 'commercial':
      return storyboardNameValues({
        heading: 'Enter Product or Service Name',
        subheading: "You're creating a TV Ad storyboard. What product or service is this Ad for?",
        placeholder: 'e.g. Old Spice',
        button: 'Generate TV Ad Storyboard'
      })
    case 'presentation':
      return storyboardNameValues({
        heading: 'Enter Presentation Name',
        subheading: "You're creating a Presentation storyboard. What is this presentation about?",
        placeholder: 'e.g. End of year report',
        button: 'Generate Presentation Storyboard'
      })
    case 'basic':
      return storyboardNameValues({
        heading: 'Name your first project',
        subheading: "We'll generate a simple storyboard and animatic for this project to get you started",
        placeholder: 'e.g. Pixar Documentary',
        button: 'Complete Setup'
      })
    default:
      return storyboardNameValues()
  }
}

const welcomeFinishedCopy = name => {
  return {
    heading: `Creating ${name}`,
    steps: [
      `Generating storyboard...`,
      `Building animatic...`,
      `Rendering frames...`,
      `Finishing up...`
    ]
  }
}

export {
  welcomeNameCopy,
  welcomeFinishedCopy,
  staticWelcomeStepCopy
}
