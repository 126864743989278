/** @prettier */
import React from 'react';
import type {
  IShareableStoryboard,
  IStoryboard,
} from '../../../../javascripts/types/storyboard';
import Button from '../../button/Button';
import { IconButton } from '../../common/IconButton';
import FullscreenIcon from '../../../images/icons/fullscreen.svg';
import { PresentViewMadeWithBoordsContainer } from './PresentationMadeWithBoords';
import { WithSeparator } from '../../common/WithSeparator';
import { VersionDropdown } from '../../menus/VersionDropdown';
import { WordCountContainer } from './WordCount';
import { useStore } from 'javascripts/helpers/useStore';
import { useTranslation } from 'react-i18next';
import { ConditionalTooltip } from 'blackbird/components/feedback/tooltip/Tooltip';
import { isUndefined, last } from 'underscore';
import { ShareableStore } from 'javascripts/flux/stores/shareable';

interface Props {
  storyboard: IStoryboard & IShareableStoryboard;
  /** Is the current user logged in and do they have permission to make
   * changes */
  canManage: boolean;
  onFullscreenClick: React.MouseEventHandler<HTMLButtonElement>;
  onDisableMadeWithBoords: () => void;
  showCount?: boolean;
}

const hasNewVersion = (storyboard: IShareableStoryboard): boolean => {
  if (
    storyboard.versions.length &&
    storyboard.preferences.share_with_version_notification
  ) {
    const lastVersion = last(storyboard.versions)!;
    return (
      lastVersion && storyboard.version_number !== lastVersion.version_number
    );
  }
  return false;
};

export const PresentationHeader: React.FC<Props> = (props) => {
  const { storyboard, showCount } = props;
  const versions = storyboard.versions;
  const { t } = useTranslation();

  const canShowWordCount = useStore<boolean, ShareableStore>(
    'shareable',
    (s) => {
      const preferences = s.storyboard?.preferences;
      if (!preferences) return false;

      return isUndefined(preferences.word_count_from)
        ? preferences.show_word_count
        : preferences.word_count_from !== null;
    },
  );

  return (
    <header className="sticky z-10 flex items-center justify-between px-6 bg-white h-17 top-banner">
      {/*
       * Left section of the header*
       */}

      <div className="flex items-center min-w-0 gap-5">
        <WithSeparator>
          {/**
           *  By default flexbox parents increase their width according
           *  to their content so that they are never
           *  smaller than their contents
           *  (https://www.w3.org/TR/css-flexbox-1/#min-size-auto)
           *
           * This default behavior interferes with proper truncating of heading.
           * Settings min-width-0 on parent flexbox containers and then using
           * truncate on the heading resolves this issue.
           */}

          <div className="flex items-center min-w-0 gap-5">
            <h1 className="text-lg truncate" title={storyboard.document_name}>
              {storyboard.document_name}
            </h1>

            {storyboard.preferences.share_with_version_number
              ? storyboard.version_count > 1 && (
                  <>
                    {storyboard.preferences.share_with_version_switching ||
                    props.canManage ? (
                      <ConditionalTooltip
                        title={
                          props.canManage
                            ? 'Hidden from guests, enable version switching in settings'
                            : undefined
                        }
                      >
                        <div>
                          <VersionDropdown
                            isShareable
                            versions={versions}
                            selectedVersion={storyboard.version_number}
                            showTotalCount
                          />
                        </div>
                      </ConditionalTooltip>
                    ) : (
                      <div className="flex-shrink-0 text-sm rounded-sm text-type-subdued">
                        {t('storyboard.versions.currentVersionWithTotal', {
                          currentVersionNumber: storyboard.version_number,
                          versionCount: versions.length,
                        })}
                      </div>
                    )}
                    {hasNewVersion(storyboard) ? (
                      <div className="flex-shrink-0 text-sm rounded-sm text-type-subdued">
                        {t('shareable.newVersion')}
                      </div>
                    ) : null}
                  </>
                )
              : null}
          </div>
          {(showCount && canShowWordCount) || storyboard.has_watermark ? (
            <div className="flex gap-4">
              {showCount && canShowWordCount && (
                <WordCountContainer storyboardId={storyboard.id} />
              )}
              <PresentViewMadeWithBoordsContainer
                onDisableClick={props.onDisableMadeWithBoords}
                canManage={props.canManage}
                storyboard={storyboard}
              />
            </div>
          ) : (
            BoordsConfig.IsProfessionalFree && (
              <PresentViewMadeWithBoordsContainer
                onDisableClick={props.onDisableMadeWithBoords}
                canManage={props.canManage}
                storyboard={storyboard}
              />
            )
          )}
        </WithSeparator>
      </div>

      {/* Right section of the header */}
      <div className="flex items-center flex-shrink-0 gap-5 ml-14">
        <WithSeparator>
          {props.canManage && (
            <Button
              size="sm"
              type="secondary"
              className="whitespace-nowrap"
              link={'/storyboards/' + storyboard.slug}
            >
              {`Exit presentation mode`}
            </Button>
          )}

          <IconButton
            className="focus:outline-blue"
            aria-label="Full screen"
            onClick={props.onFullscreenClick}
            icon={<FullscreenIcon />}
          />
        </WithSeparator>
      </div>
    </header>
  );
};
