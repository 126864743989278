/** @format */
import React, { useState, useEffect } from 'react';
import { EditableTitle } from 'blackbird/components/common/EditableTitle';
import type { Project } from 'javascripts/flux/stores/dashboard';

interface ProjectRenamingContainerProps {
  clickable?: boolean;
  project: Project;
  hasRenamePermission?: boolean;
}

const ProjectRenamingContainer: React.FC<ProjectRenamingContainerProps> = (
  props,
) => {
  const { clickable, project, hasRenamePermission } = props;
  const [hasPointerEvents, setHasPointerEvents] = useState(clickable);
  const [isEditable, setEditable] = useState(false);

  const saveAction = (name: string) => {
    DashboardActions.updateProject.defer({
      id: project.id,
      slug: project.slug,
      name: name,
    });
  };
  const onProjectChange = ({ projectIdBeingRenamed }) => {
    // I found it to be a better UX to leave it clickable once it's been
    // activated already. This doesn't seem to work now, because the whole
    // UI seems to rerender, but that's not the concern of this component.
    if (projectIdBeingRenamed === project.id) {
      setHasPointerEvents(true);
      setEditable(true);
    }
  };
  const handleBlur = () => {
    setEditable(false);
  };
  useEffect(() => {
    ProjectStore.listen(onProjectChange);

    return () => {
      ProjectStore.unlisten(onProjectChange);
    };
  }, []);

  return (
    <header className="project__header">
      <div className="js_project_name_admin project__name ">
        <div className="flex items-center">
          <div className="js-project-drag-handle drag-button" />
          <i className="mt-8px mr3 boords-icon--folder" />
          <div className="flex-auto">
            <EditableTitle
              readOnly={!hasRenamePermission}
              onBlur={handleBlur}
              onChange={saveAction}
              value={project.name}
              isEditable={isEditable}
              clickable={hasPointerEvents}
            />
            <div className="text-type-disabled text-sm font-medium">
              {project.storyboards.length +
                ' ' +
                (project.storyboards.length === 1
                  ? 'storyboard'
                  : 'storyboards')}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default ProjectRenamingContainer;
