/** @prettier */
import Tabs from 'blackbird/components/tabs/Tabs';
import type { TabProps } from 'blackbird/components/tabs/types';
import type { allTourSteps } from 'javascripts/tours/tourData';
import * as React from 'react';
import { usePermission } from '../../helpers/has-permission';
import { TourHintable } from '../tours/TourHintable';
import CloseIcon from 'blackbird/images/icons/close.svg';
import Icon from 'blackbird/components/icon/Icon';
import { useStore } from 'javascripts/helpers/useStore';
import { useTranslation } from 'react-i18next';
import SidePanelExpandIcon from 'blackbird/images/icons/side-panel-expand.svg';
import SidePanelCollapseIcon from 'blackbird/images/icons/side-panel-collapse.svg';
import { IconButton } from 'blackbird/components/common/IconButton';
import DisclosureIcon from 'blackbird/images/icons/disclosure-left.svg';
import classNames from 'classnames';
import { type IconTabItem, IconTabBar } from './IconTabBar';

const tourHintableSteps: allTourSteps[] = [
  'saveAndCloseTheEditor',
  'closeAnimatic',
];

export const frameFocusTopBarHeight = 120;

interface Props {
  onTriggerClose: () => void;
  onSetMode: () => void;
  onSetIndex: (number) => void;
  maxIndex: number;
}

export const FrameFocusTopBar = React.memo<Props>((props) => {
  const { t } = useTranslation();
  const canAccessAnimatic =
    usePermission(BoordsConfig.Uid, 'animatics') === true;

  const tabs = React.useMemo<TabProps[]>(
    () => [
      {
        key: 'frameEditor',
        caption: t('frameEditor.tabName'),
      },
      {
        key: 'player',
        caption: t('player.tabName'),
        disabled: !canAccessAnimatic,
        tooltip: !canAccessAnimatic
          ? t('warnings.featureNotInPlan')
          : undefined,
      },
    ],
    [canAccessAnimatic, t],
  );

  const mode = useStore('frameFocus', (s) => s.mode);
  const activeIndex = useStore('frameFocus', (s) => s.activeIndex);
  const panelbarIsOpen = useStore('frameFocus', (s) => s.hasSidebar);

  const navTabs: IconTabItem[] = [
    {
      icon: <DisclosureIcon />,
      name: 'prev',
      active: true,
      title: t('actions.frames.navigateToPrevious'),
      onClick: () => props.onSetIndex(activeIndex - 1),
      disabled: activeIndex === 0 || panelbarIsOpen,
      iconClassName: classNames(panelbarIsOpen && 'pointer-events-none'),
    },
    {
      icon: <DisclosureIcon />,
      name: 'next',
      active: true,
      iconClassName: classNames(
        'rotate-180',
        panelbarIsOpen && 'pointer-events-none',
      ),
      title: t('actions.frames.navigateToNext'),
      onClick: () => props.onSetIndex(activeIndex + 1),
      disabled: activeIndex === props.maxIndex || panelbarIsOpen,
    },
  ];

  return (
    <div className="flex items-center justify-center w-100">
      <div className="inline-flex flex-auto flex-shrink-0">
        <TourHintable
          step={tourHintableSteps}
          overlayPosition="bottom"
          preset="rounded"
          onNext={() => {
            props.onTriggerClose();
          }}
        >
          <button
            type="button"
            onClick={props.onTriggerClose}
            className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white rounded-full focus-visible:outline-blue"
          >
            <span className="-mt-[4px] mr-0.5 fullsize:mr-0">
              <Icon icon={<CloseIcon />} />
            </span>
          </button>
        </TourHintable>
      </div>

      <div className="flex-auto flex-shrink-0 pl-14">
        <Tabs
          type="rounded"
          size="lg"
          onSelect={props.onSetMode}
          tabs={tabs}
          defaultValue={mode}
          className="w-[12rem] mx-auto"
          tabClassName=""
          customBorder
          manual
        />
      </div>

      <div className="relative flex justify-end flex-auto flex-shrink-0 gap-4">
        <div
          className={classNames(
            'absolute top-0 z-50',
            panelbarIsOpen ? 'right-10 ' : 'right-[8.5rem] ',
          )}
        ></div>
        <div
          className={classNames(
            'transition-opacity duration-500',
            panelbarIsOpen ? 'opacity-0' : 'opacity-100',
          )}
        >
          <IconTabBar icons={navTabs} />
        </div>

        <IconButton
          onClick={() => FrameFocusActions.toggleSidebar.defer()}
          aria-label={
            panelbarIsOpen
              ? t('actions.sidebar.close')
              : t('actions.sidebar.open')
          }
          icon={
            panelbarIsOpen ? <SidePanelCollapseIcon /> : <SidePanelExpandIcon />
          }
        />
      </div>
    </div>
  );
});

FrameFocusTopBar.displayName = 'FrameFocusTopBar';
