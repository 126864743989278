/** @prettier */
import * as React from 'react';
import { ActivityItem } from '../../flux/stores/ActivityLog';
import { transformFilestackUrl } from '../../helpers/transformFilestackImage';
import Avatar from 'blackbird/components/avatar/Avatar';
import format from 'date-fns/format';
import formatRelative from 'date-fns/formatRelative';

export const ActivityLogItem: React.FC<{
  activity: ActivityItem;
}> = ({ activity }) => {
  const createdAt = new Date(activity.created_at);
  const avatarUrl = activity.user_avatar_url;
  const avatar =
    avatarUrl && avatarUrl.indexOf('cdn.filestackcontent') >= 0
      ? transformFilestackUrl({
          url: avatarUrl,
          width: 100,
          height: 100,
        })
      : avatarUrl;

  return (
    <div className="flex gap-4 border-b border-b-border pb-4">
      <Avatar className="flex-shrink-0" size="sm" image={avatar} />
      <div className="flex-auto flex flex-col gap-1">
        <Markdown
          text={activity.description}
          className="activityLog__text"
          openLinksInNewWindow={false}
        />
        <time
          dateTime={createdAt.toISOString()}
          title={format(createdAt, 'EEEE,MMMM d,yyyy hh:mm aa')}
          className="text-type-disabled text-xs"
        >
          {formatRelative(createdAt, new Date())}
        </time>
      </div>
    </div>
  );
};
