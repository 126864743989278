(() => {
  class UpgradePromptStore {
    constructor() {

      this.is_showing_flyover                = false;
      this.is_showing_banner                 = true;
      this.source                            = "Billing page";
      this.user                              = false;
      this.is_showing_completeness_checklist = false;

      this.bindListeners({
        handleHide:               [FlyoverActions.CLOSE],
        handleReceive:            UserActions.RECEIVE,
        handleShow:               UpgradePromptActions.SHOW,
        handleSetSource:          UpgradePromptActions.SET_SOURCE,
        handleArchiveStoryboard:  StoryboardActions.ARCHIVE_STORYBOARD,
        handleArchiveProject:     DashboardActions.ARCHIVE_PROJECT,
      });
    }

    handleSetSource(source) {
      this.source = source;
    }

    handleShow(source) {
      this.handleSetSource(source);
      FlyoverActions.open.defer({
        type: "checkout",
        is_annual: false,
        plan_name: 'individual',
      });
    }

    handleReceive(user) {
      this.user = user;
    }

    handleArchiveStoryboard() {
      this.user.top_level_storyboard_count--;
      this.syncUserAcrossStores();
    }

    handleArchiveProject() {
      this.user.active_project_count--;
      this.syncUserAcrossStores();
    }

    // This makes sure the pro upgrade, storyboard count etc is received across other stores where the user is used
    syncUserAcrossStores() {
      UserActions.receive.defer(this.user);
    }

    handleHide() {
      this.is_showing_flyover = false;
      this.emitChange();
    }

  }

  window.UpgradePromptStore = alt.createStore(UpgradePromptStore, 'UpgradePromptStore');

})();
