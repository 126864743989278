window.ProjectActions = alt.createActions(function () {

  this.transferOwnership = function (data) {
    this.dispatch(data);
  };

  this.editName = function (id) {
    this.dispatch(id);
  };

  this.fetchSiblingStoryboards = function (id) {
    this.dispatch(id);
  };

});
