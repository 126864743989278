/** @prettier */
/* tslint:disable no-empty */
/**
 * For info on this component, check /docs/Container.md
 */

import * as React from 'react';
import {
  forEach,
  isArray,
  isEmpty,
  isFunction,
  isObject,
  isString,
  isUndefined,
  reduce,
} from 'underscore';
const createReactClass = require('create-react-class');
const PureRenderMixin = require('react-addons-pure-render-mixin');

interface IGenericObject {
  [storeProp: string]: any;
}

interface IContainerOptions {
  /**
   * Should the container act like a "pure" react component? Usually this is
   * the right decision for performance reasons, but in some cases, mutated
   * objects will not trigger updates, see the Container.md file for more info
   * @default true
   */
  isPure?: boolean;
}

type objectOrNull = null | IGenericObject;

interface IStoresAsObject {
  [storeName: string]: (
    storeData: IGenericObject,
    ownProps: IGenericObject,
  ) => objectOrNull;
}

/**
 * A way of adding functions to the props of the wrapped component. This is
 * useful if you rely on proptypes to validate the presence of these functions.
 * Additionally, if you add an `onMount` function here, it will automatically
 * be called on launching the component
 */
type IActionsFunc = (
  storeData: IGenericObject,
  ownProps: IGenericObject,
) => {
  [handlerName: string]: (...args: any[]) => void;
} & {
  /** Automatically call this function on mount*/
  onMount?: (...args: any[]) => void;
};

type StoresProp = string | string[] | IStoresAsObject;

const decapitalize = (str) => str.slice(0, 1).toLowerCase() + str.slice(1);

const getStore = (storeName) => {
  const name = storeName.slice(0, 1).toUpperCase() + storeName.slice(1);

  const output =
    name.indexOf('Store') < 0 ? window[name + 'Store'] : window[name];

  if (!output) {
    if (process.env.NODE_ENV === 'test')
      return { getState: () => {}, listen: () => {} };
    throw new Error(`Store with name ${name} (or ${name}Store) not found`);
  }
  return output;
};

const forEachStore = (stores, func) => {
  if (isString(stores)) {
    func(getStore(stores), stores);
  } else if (isArray(stores)) {
    stores.forEach((s) => func(getStore(s), s));
  } else if (isObject(stores)) {
    forEach(stores, (value, key) => func(getStore(key), key));
  }
};

export default (
    stores: StoresProp,
    actionsFunc?: IActionsFunc,
    options: IContainerOptions = { isPure: true },
  ) =>
  (Component) =>
    createReactClass({
      displayName: `Container(${
        Component.displayName || Component.name || 'unknown'
      })`,

      // See Container.md for more info on this
      mixins: options.isPure !== false ? [PureRenderMixin] : null,

      getInitialState() {
        return this.getStateFromStores();
      },

      getStateFromStores() {
        let state = {};
        if (isString(stores)) {
          state = getStore(stores).getState();
        } else if (isArray(stores)) {
          forEachStore(stores, (store, name) => {
            state[decapitalize(name)] = store.getState();
          });
        } else if (isObject(stores)) {
          state = reduce(
            stores,
            (output, func, storeName) => {
              if (isFunction(func)) {
                output[decapitalize(storeName)] = func(
                  getStore(storeName).getState(),
                  this.props,
                );
              }
              return output;
            },
            {},
          );
        }

        if (actionsFunc && isFunction(actionsFunc)) {
          const actions = actionsFunc(state, this.props);
          forEach(actions, (v, k) => {
            if (!isFunction(v))
              console.error(
                `Container: the action passed as '${k}' is not a function`,
              );
          });
          Object.assign(state, actions); // merge in the actions
        }

        // In case the store returns undefined (during testing)
        return state || null;
      },

      onUpdate() {
        this.setState(this.getStateFromStores());
      },

      componentDidMount() {
        forEachStore(stores, (s) => s.listen(this.onUpdate));

        if (
          // eslint-disable-next-line
          process.env.NODE_ENV === 'development' &&
          (isUndefined(Component.propTypes) || isEmpty(Component.propTypes))
        ) {
          // console.groupCollapsed(`%cThe component “${Component.displayName || Component.name || 'unknown'}” doesn't have any propTypes`, "background: #fefbe7; font-weight:normal");
          // console.log('please consider adding some or all of the following propTypes, so you know for sure your component receives the right data:\n', Object.keys({...this.state, ...this.props}));
          // console.groupEnd()
        }

        if (isFunction(this.state.onMount)) {
          this.state.onMount();
        }
      },

      componentWillUnmount() {
        forEachStore(stores, (s) => s.unlisten(this.onUpdate));
      },

      render() {
        return <Component {...this.props} {...this.state} />;
      },
    });
