/** @prettier */
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import { last, times } from 'underscore';
import type { DocInfo, TokenListInfo } from '../../types';
import { fallbackFontStyle } from './fallbackFontStyle';
const errorHandler = RequestErrorHandler('splitUpTokensIntoLines');

/**
 * An object with the result of `flattenTokens` showing the metadata required
 * to render text (and formatting)
 */
export interface SequentialTokenText {
  type: 'text';
  text: string;
  isDel?: boolean;
  fontStyle: 'normal' | 'bold' | 'italic' | 'bolditalic';
  // FIXME: why is a number allowed?
  link?: string | number;
  isRtl?: boolean;
  list?: TokenListInfo;
}

interface SequentialTokenSpace {
  type: 'space';
  raw?: string;
  amount?: number;
}

export type SequentialToken = SequentialTokenSpace | SequentialTokenText;

export interface LineInfo {
  tokens: SequentialTokenText[];
  estimatedWidth: number;
  isParagraphRtl: boolean;
  /** The amount of pixels the current line should be indented. This is based on
   * the listSymbol and the current font size */
  indent?: number;
}

/**
 * An array of lines of text (in the form of markdown tokens).
 * We also calculate their estimated width so we can center them */
export const splitUpTokensIntoLines = (
  {
    docInfo,
    tokens,
    wrapped = false,
    indent = 0,
    ...options
  }: {
    docInfo: DocInfo;
    tokens: SequentialToken[];
    maxWidth: number;
    fontName: string;
    debug?: boolean;
    wrapped?: boolean;
    indent?: number;
  },
  tries = 0,
): LineInfo[] =>
  tokens.reduce<LineInfo[]>(
    (lines, currentToken) => {
      const doc = docInfo.doc;

      // With some difficult strings, we sometimes end up in infinite loops,
      // this will limit it. It's possible that we need a lot of steps to split
      // up long paragraphs at high text sizes, so it might take some tries! so don't put this limit too low, otherwise sentences
      // will be cut off
      if (tries > 25) {
        errorHandler({ messageKey: null, severity: 'warn' })(
          new Error(
            'Early exit while splitting up token (preventing infinite loop)',
          ),
        );
        return lines;
      }

      // Process line breaks
      if (currentToken.type === 'space') {
        lines.push(
          ...times(currentToken.amount ?? 1, () => ({
            tokens: [],
            isParagraphRtl: false,
            estimatedWidth: 0,
          })),
        );
        return lines;
      }

      // Start processing the current token
      const currentLine = last(lines)!;
      const fontStyle = fallbackFontStyle(
        currentToken.fontStyle,
        docInfo.fontSettings,
      );
      let childIndent = indent;
      doc.setFont(options.fontName, fontStyle);

      if (currentToken.list) {
        const marker = `${currentToken.list.currentMarker} `;
        if (!wrapped) {
          currentToken.text = marker + currentToken.text;
          childIndent = doc.getTextWidth(marker);
        }
      }

      const spaceLeft = options.maxWidth - currentLine.estimatedWidth - indent;
      const split = doc
        .splitTextToSize(currentToken.text, spaceLeft)
        // Sometimes some of these values are "", ignore those
        .filter((f) => f.length > 0);

      const [addToThisLine, firstSplit] = split;
      if (split.length === 0) return lines;

      const enougSpaceForMore = !firstSplit || firstSplit?.length > 1;

      /** We don't want to split a string if there are no spaces to do it,
       * unless the entire string of text is larger than the maximum width, in
       * wich case we have to, otherwise we get infinite recursion with scripts
       * like japanese */
      const canSplit =
        (currentToken.text.indexOf(' ') >= 0 && enougSpaceForMore) ||
        doc.getTextWidth(currentToken.text) > options.maxWidth;

      if (split.length > 1) {
        // If we cannot split the string, place the current token on a new line
        // and abort processing.
        if (!canSplit) {
          lines.push(
            ...splitUpTokensIntoLines(
              {
                docInfo,
                tokens: [{ ...currentToken, text: currentToken.text }],
                wrapped,
                indent,
                ...options,
              },
              tries + 1,
            ),
          );
          return lines;
        }

        // Prepare the split
        let length = addToThisLine.length;
        if (currentToken.text.slice(length).trim().length === 1) {
          // We want to prevent single-character orphans, this way we keep them
          // on the same line
          length += 1;
        }

        // Split up the currentToken in to a token for the current line, and a token for the next line. (we have to create a new line to `lines`)
        const textSplitIndex =
          currentToken.text.indexOf(addToThisLine) + length;

        const currentLineToken = {
          ...currentToken,
          text: currentToken.text.slice(0, textSplitIndex),
        };

        // Add this stuff to the current line
        currentLine.tokens.push(currentLineToken);
        currentLine.estimatedWidth += doc.getTextWidth(currentLineToken.text);
        currentLine.isParagraphRtl = currentToken.isRtl ?? false;
        currentLine.indent = indent;

        // Move the stuff that didn't fit onto the next line
        const nextLineToken: SequentialTokenText = {
          ...currentToken,
          text: currentToken.text.slice(textSplitIndex).trimLeft(),
        };

        // We want to call the same process again for everything that's left over
        lines.push(
          ...splitUpTokensIntoLines(
            {
              docInfo,
              tokens: [nextLineToken],
              ...options,
              wrapped: true,
              indent: childIndent,
            },
            tries + 1,
          ),
        );
      } else {
        // This else statement is executed if we only have one line in the first place
        currentLine.tokens.push(currentToken);
        currentLine.estimatedWidth += doc.getTextWidth(addToThisLine);
        currentLine.isParagraphRtl = currentToken.isRtl ?? false;
        currentLine.indent = indent;
      }

      return lines;
    },
    [
      {
        indent: 0,
        tokens: [],
        estimatedWidth: 0,
        isParagraphRtl: false,
      },
    ],
  );
