/** @format */
import * as React from 'react';
import Dialog from 'blackbird/components/dialog/Dialog';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import BoordsLogo from 'blackbird/images/logo/logomark.svg';
import {
  PricingContainer,
  type PricingContainerProps,
} from './PricingContainer';
import { PricingContext, PricingProvider } from './PricingContext';
import { formatCurrency } from 'javascripts/helpers/currencyHelper';
import { PricingTableStoryboardCount } from './PricingTableFeatures';
import { FeatureInline, type FeatureName } from '../features/Feature';
import {
  type LocalPlanData,
  type Price,
} from 'blackbird/helpers/contexts/BillingContext';
import Button from '../button/Button';
import { CloseButton } from '../common/CloseButton';
import { type currentUserFeaturesType } from 'javascripts/flux/stores/user_permissions';

import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { posthog } from 'posthog-js';

const PricingFeatureLockedFlyover: React.FC<PricingContainerProps> = ({
  triggeredFromFeatureName,
}) => {
  const featureName: currentUserFeaturesType | undefined =
    triggeredFromFeatureName;
  // const featureName: currentUserFeaturesType | undefined =
  //   'password_protection';
  const { plans } = React.useContext(PricingContext);

  const [prices, setPrices] = React.useState<Price[] | undefined>(undefined);
  const [price, setPrice] = React.useState<Price | undefined>(undefined);
  const [billingInterval, setBillingInterval] = React.useState<string>('month');
  const [plan, setPlan] = React.useState<LocalPlanData | undefined>(undefined);

  const [title, setTitle] = React.useState<string>('Upgrade required');
  const [description, setDescription] = React.useState<string>(
    'Dramatically increase pre-production efficiency with the shortcut to effective storyboards.',
  );

  const [extraFeature, setExtraFeature] = React.useState<
    FeatureName | undefined
  >(undefined);

  const [features, setFeatures] = React.useState<FeatureName[]>([
    'remove-branding',
    'remove-frame-limit',
    'remove-export-limit',
    // 'storyboard-generator-with-characters',
    // 'script-generator',
    'cancel-anytime',
    'moneyback',
  ]);

  React.useEffect(() => {
    if (featureName === 'newStoryboard' && plan) {
      setTitle(`Expand your storyboarding capacity`);
      setDescription(
        `You've reached the 2 storyboard limit on the free plan. Upgrade to create up to ${plan.maxStoryboards} storyboards with unlimited frames.`,
      );
    } else if (featureName === 'frameLimit') {
      setTitle(`Unlock unlimited frames`);
      setDescription(
        `You've reached the 12 frame limit on free storyboards. Upgrade to create storyboards with unlimited frames.`,
      );
    } else if (featureName === 'frame_fields') {
      setTitle(`Unlock advanced customization`);
      setDescription(
        `Upgrade to add custom text fields and create storyboards tailored to your workflow.`,
      );
      setExtraFeature('custom-text-fields');
    } else if (featureName === 'password_protection') {
      setTitle(`Unlock secure sharing`);
      setDescription(
        `Upgrade to enable password-protected links and share storyboards with confidence.`,
      );
      setExtraFeature('password-protection');
    } else if (featureName === 'branding') {
      setTitle(`Unlock white-label sharing`);
      setDescription(
        `Upgrade to share storyboards without Boords branding for a polished, professional appearance.`,
      );
    }
  }, [featureName, plan]);

  React.useEffect(() => {
    if (!plan && plans) {
      const individual = plans.find((p) => p.slug === 'individual');
      if (individual) {
        setPlan(individual);
      }
    }
  }, [plan, plans]);

  // Set all prices
  React.useEffect(() => {
    if (plan) {
      const individualPrices = plan.prices.filter(
        (p) => p.currency === BoordsConfig.Currency,
      );
      setPrices(individualPrices);
    }
  }, [plan]);

  // Set specific price
  React.useEffect(() => {
    if (prices) {
      const individualPrice = prices.find(
        (p) =>
          p.currency === BoordsConfig.Currency &&
          p.interval === billingInterval,
      );
      setPrice(individualPrice);
    }
  }, [prices, billingInterval]);

  return !plans || !plan || !price || !prices ? null : (
    <Dialog
      size="md"
      hideActions
      isOpen
      isDark
      hideClose
      keepOpen
      containerClasses="!pb-0 !pt-0 !px-0"
      title={`x`}
      titleTextContainerClasses="opacity-0"
      // onEscapeOrOutsideClick={() => {
      //   FlyoverActions.close();
      // }}
    >
      <div className="relative ">
        <div className="-mt-[4.7rem] relative">
          <div className="absolute z-10 opacity-50 top-4 right-4 scale-75">
            <CloseButton
              customBackground
              className="cursor-pointer bg-black/10 !w-8 !h-8"
              onHandleCancel={() => {
                FlyoverActions.close();
              }}
            />
          </div>
          <div className="grid grid-cols-10">
            {/* Left column */}
            <div className="flex items-center col-span-6">
              <div className="px-10 pb-10">
                <div className="mb-4">
                  <BoordsLogo className={`w-10`} />
                </div>
                <div className="mb-1 text-lg font-semibold">{title}</div>
                <div className="text-type-subdued">{description}</div>

                {/* <PricingTestimonials /> */}
                {/* Moneyback Guarantee */}
                {/* <div className="flex items-center space-x-4"> */}
                {/*   {/1* No risk blurb *1/} */}
                {/*   <div className="flex-auto"> */}
                {/*     <div className="text-sm font-semibold"> */}
                {/*       {`Take a no-risk look at a paid plan`} */}
                {/*     </div> */}
                {/*     <div className="text-xs leading-tight text-type-subdued"> */}
                {/*       {`If you decide Boords isn't for you we'll refund your payment in full, no questions asked.`} */}
                {/*     </div> */}
                {/*   </div> */}
                {/* </div> */}
              </div>
            </div>

            {/* Right column */}
            <div className="px-6 pt-8 pb-8 border-l bg-surface col-span-4 border-border">
              {/* Plan name & price */}
              <div className="pb-4 mb-4 border-b border-border">
                <div className="text-lg font-semibold">{`${plan.name}`}</div>
                <div className="mt-3 mb-4 text-xs text-type-subdued">{`The complete storyboarding solution for freelancers and creative professionals.`}</div>
                <div className="flex items-baseline">
                  <div className="text-xl text-brand-yellow">
                    {`${formatCurrency({
                      amount: Math.floor(
                        price.amount /
                          (price.interval === 'year' ? 12 : 1) /
                          100,
                      ),
                      currency: BoordsConfig.Currency,
                      showZeros: false,
                    })}`}
                  </div>
                  {/* {price.interval === 'year' && ( */}
                  {/*   <PricingYearlySaving prices={prices} /> */}
                  {/* )} */}
                  <div className="ml-1 text-sm font-normal text-type-subdued/80">{`per month`}</div>
                </div>
              </div>

              {/* Features */}
              <div className="space-y-1.5 !text-type-subdued relative">
                {extraFeature && (
                  <FeatureInline
                    name={extraFeature}
                    iconColor="text-brand-yellow"
                  />
                )}
                <PricingTableStoryboardCount
                  plan={plan}
                  iconColor={`text-brand-yellow`}
                />
                {features.map((feature) => (
                  <FeatureInline
                    key={feature}
                    name={feature}
                    iconColor="text-brand-yellow"
                  />
                ))}
              </div>

              <div className="mt-8 mb-4">
                <Button
                  type="yellow"
                  rounded
                  className="w-full"
                  link={`/checkout/${plan.slug}/${billingInterval}ly`}
                >
                  <span className="text-sm font-normal">{`Go to Payment`}</span>
                </Button>
              </div>

              <div className="flex justify-center">
                <div className="flex items-center">
                  <LockClosedIcon className="w-3 text-type-disabled" />
                  <div className="ml-0.5 text-xs text-type-disabled">
                    <>{`Guaranteed `}</>
                    <span className="font-medium">{`safe & secure`}</span>

                    <>{` payments`}</>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export const PricingFeatureLocked: React.FC<PricingContainerProps> = (
  props,
) => {
  // const variant = useFeatureFlagVariantKey('appInlinePermissionFlyover');
  // posthog.featureFlags.override({ appInlinePermissionFlyover: 'test' });

  React.useEffect(() => {
    Track.event.defer('Upgrade prompt open', {
      context: props.triggeredFromFeatureName,
      category: 'Checkout',
    });
  }, [props.triggeredFromFeatureName]);

  return <PricingContainer {...props} />;
};
