/** @prettier */
import {
  type TourStepTokens,
  tourEvents,
  type TourBlueprint,
} from './tourData';

const tourSteps = ['wizardCameraMovesMenu', 'wizardCameraMovesInsert'] as const;

export type wizardCameraMovesTourAvailableSteps = typeof tourSteps[number];

const tokens: TourStepTokens = {
  name: 'Add your first camera move',
  count: 10,
};

export const wizardCameraMovesTour: TourBlueprint<wizardCameraMovesTourAvailableSteps> =
  {
    name: 'wizardCameraMoves',
    allowRestart: true,
    steps: [
      {
        name: 'wizardCameraMovesMenu',
        header: `Open the Camera Moves panel`,
        body: `Select the Camera Moves item from the image editor menu.`,
        waitsOn: tourEvents.openCameraMoves,
        tokens,
      },
      {
        name: 'wizardCameraMovesInsert',
        header: 'Choose a Camera Move',
        body: `Select a camera move from the menu to overlay it on your image.`,
        tokens,
      },
    ],
  };
