/** @prettier */
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Banner from 'blackbird/components/feedback/banner/Banner';
import React, { useContext } from 'react';
import slugify from 'slugify';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { SubdomainContext } from 'blackbird/helpers/contexts/SubdomainContext';

export const SubdomainForm: React.FC = () => {
  const {
    subdomain,
    setSubdomain,
    originalSubdomain,
    isUsed,
    submitted,
    loaded,
    canSubmit,
    setCanSubmit,
    UpdateSubdomain,
  } = useContext(SubdomainContext)!;

  return (
    <div className="space-y-4">
      {!loaded ? (
        <LoadingIndicator containerClassName="w-5 mt-6" />
      ) : (
        <>
          <div className="flex items-end space-x-4">
            <div className="w-full max-w-sm">
              <TextInput
                inputSize="md"
                label={`Enter your company name`}
                name={'subdomain'}
                type="text"
                aria-label={`Domain`}
                value={subdomain}
                onChange={(e) => {
                  const slug = slugify(e.target.value).toLowerCase();
                  setCanSubmit(false);
                  setSubdomain(slug);
                }}
              />
            </div>
            {isUsed && <Banner customWidth kind={'error'} message={`Used`} />}
          </div>
          <p className="text-type-subdued">
            {`Your workspace will be: `}
            <a className="underline" href="#">{`${
              subdomain ? subdomain : `app`
            }.boords.com`}</a>
          </p>

          <Button
            htmlType="button"
            disabled={
              !canSubmit ||
              isUsed ||
              subdomain.length < 1 ||
              subdomain === originalSubdomain
            }
            onClick={() => UpdateSubdomain()}
          >
            {!submitted ? `Save` : 'Processing...'}
          </Button>
        </>
      )}
    </div>
  );
};
