/** @format */

import { parseAndSanitizeText } from 'blackbird/helpers/parseAndSanitizeText';
import { isString } from 'underscore';

const parser = new DOMParser();
const htmlIsEmpty = (html: string | DocumentFragment) => {
  if (!html || (isString(html) && html.length < 1)) return true;
  const content = isString(html)
    ? parser.parseFromString(html, 'text/html').body.textContent
    : html.textContent;

  if (!content) return true;
  return content.trim().length === 0;
};

export const scriptEditorFieldToHTML = (fieldValue = ''): HTMLDivElement => {
  const div = document.createElement('div');
  const html = parseAndSanitizeText(fieldValue, true);

  if (!htmlIsEmpty(html)) {
    div.append(html);
  } else {
    const paragraph = document.createElement('p');
    // Add a zero-width character so the paragraph takes up space
    paragraph.textContent = '\u200B';
    div.appendChild(paragraph);
    div.classList.add('empty');
  }

  return div;
};
