/** @format */
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import React from 'react';
import { ShareableProjectContents } from './ShareableProjectContents';
import { ShareableProjectProvider } from './ShareableProjectContext';

interface ShareableProjectContainerProps {
  share_slug: string;
}

export const ShareableProjectContainer: React.FC<
  ShareableProjectContainerProps
> = ({ share_slug }) => {
  return (
    <DefaultStoreContextProvider>
      <ShareableProjectProvider share_slug={share_slug}>
        <ShareableProjectContents />
      </ShareableProjectProvider>
    </DefaultStoreContextProvider>
  );
};

(window as any).ShareableProjectContainer = ShareableProjectContainer;
