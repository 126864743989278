/** @prettier */
import { hasRolloutFlagEnabled } from 'javascripts/helpers/rollout';
import type { FrameStore } from '../flux/stores/frame';

export const isUserSubjectToFreeLimit = () =>
  !hasRolloutFlagEnabled('Framelimitexemption') &&
  (BoordsConfig.Freeloader ||
    BoordsConfig.IsProfessionalFree ||
    BoordsConfig.IsPuffinTrial) &&
  BoordsConfig.IsStoryboardAdmin;

type requiredProps = Pick<
  FrameStore,
  'frames' | 'unpaidFrameCountLimit' | 'paidFrameCountLimit'
>;

export const isUserSubjectToPaidLimit = () =>
  hasRolloutFlagEnabled('Framelimitexemption') && !BoordsConfig.Freeloader;

export const getUserFrameLimit = (state: {
  unpaidFrameCountLimit: number;
  paidFrameCountLimit: number;
}) => {
  if (isUserSubjectToFreeLimit()) {
    return state.unpaidFrameCountLimit;
  } else if (isUserSubjectToPaidLimit()) {
    return state.paidFrameCountLimit;
  } else {
    return 999;
  }
};

export const cannotCreateNewFrame = (frameStoreState: requiredProps) => {
  return frameStoreState.frames.length >= getUserFrameLimit(frameStoreState);
};

export const showFrameLimitNotification = () => {
  FlyoverActions.open.defer({
    type: 'frameLimit',
    props: {
      paid: !isUserSubjectToFreeLimit(),
    },
  });
};

/**
 * This function returns `true` if new frames can **not** be created.
 * That way it can be used to control flow
 * @example if (notificationIfCannotCreateNewFrame(state)) return;
 */
export const notificationIfCannotCreateNewFrame = (
  frameStoreState: requiredProps,
): boolean => {
  if (cannotCreateNewFrame(frameStoreState)) {
    showFrameLimitNotification();
    return true;
  } else {
    return false;
  }
};
