/** @format */

import React from 'react';

import { type IStoryboard } from 'javascripts/types/storyboard';
import { TeamProvider } from './TeamContext';
import { TeamStoryboardShareToolbar } from './TeamStoryboardShareToolbar';
import { ProjectsProvider } from '../projects/ProjectsContext';

export interface TeamStoryboardShareContainerProps {
  storyboard: IStoryboard;
  teamId: string;
}
export const TeamStoryboardShareContainer: React.FC<
  TeamStoryboardShareContainerProps
> = ({ storyboard, teamId }) => {
  return (
    <ProjectsProvider updateUrlOnLoad={false}>
      <TeamProvider teamId={teamId}>
        <TeamStoryboardShareToolbar storyboard={storyboard} />
      </TeamProvider>
    </ProjectsProvider>
  );
};
