/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import { FrameActions } from '../actions/frame';
import { ShareableActions } from '../actions/shareable';
import { idleTimeout } from '../../helpers/idle-timeout';
import { getWordCount } from '../../helpers/getWordCount';
import {
  throttle,
  isObject,
  isEmpty,
  pick,
  isNumber,
  isUndefined,
} from 'underscore';
import type { StoryboardAnalysis, IStoryboard } from '../../types/storyboard';
import { StoryboardActions } from '../actions/storyboard';
import { StoryboardAnalysisActions } from '../actions/storyboardAnalysis';
import { LocalState, typedLocalState } from '../../helpers/local-state';
import { getDuration } from 'javascripts/helpers/getDuration';
import { notNullOrUndefined } from 'javascripts/helpers/notUndefined';
import logger from 'javascripts/helpers/logger';

const LS_ANALYSIS_OLD = 'storyboard_analysis';

interface AnalysisStorage {
  [storyboardId: string]: StoryboardAnalysis | undefined;
}

const localState = typedLocalState<AnalysisStorage>('storyboardAnalysis');

export class StoryboardAnalysisStore extends FakeAltStoreClass<StoryboardAnalysisStore> {
  analysis: AnalysisStorage = {};

  constructor() {
    super();

    this.bindListeners({
      handleInit: StoryboardAnalysisActions.INIT,
      scheduleAnalysis: [
        FrameActions.SAVE_TEXT,
        FrameActions.RECEIVE_FRAMES,
        FrameActions.UPDATE_FRAME_HISTORY,
        StoryboardActions.RECEIVE,
        StoryboardAnalysisActions.SCHEDULE_ANALYSIS,
        ShareableActions.RECEIVE,
      ],
    });
  }

  handleInit() {
    if (isEmpty(this.analysis)) this.analysis = this.getAnalysis();
  }

  getStoryboard = (): IStoryboard =>
    StoryboardStore.getState().storyboard ||
    ShareableStore.getState().storyboard;

  analyseStoryboard = () => {
    const { is_loading, frames } = FrameStore.getState();
    const storyboard = this.getStoryboard();

    // When requesting a background render to some frames, this might get called
    // without us having a storyboard. Let's exit early
    if (!storyboard) return;

    const preferences = storyboard.preferences;
    const fields =
      storyboard.frame_fields ??
      StoryboardStore.getState().default_frame_fields;

    // There have been some cases where there are no preferences
    if (is_loading || !preferences) return;

    const shouldShowWordCount = isUndefined(preferences.word_count_from)
      ? preferences.show_word_count
      : preferences.word_count_from !== null;

    const wordCount = shouldShowWordCount
      ? getWordCount(frames, fields, preferences).wordCount
      : undefined;

    const currentDuration = this.getAnalysis()[storyboard.id]?.duration;

    this.saveAnalysis(storyboard.id, {
      wordCount,
      duration: currentDuration,
    });

    if (preferences.show_storyboard_duration) {
      getDuration(frames, !isNumber(currentDuration), (duration) => {
        if (duration === currentDuration) return;
        this.saveAnalysis(storyboard.id, {
          wordCount,
          duration: duration ?? currentDuration,
        });
      });
    }
  };

  scheduleAnalysis = throttle(() => idleTimeout(this.analyseStoryboard), 1000);

  getAnalysis = (): AnalysisStorage => {
    let local = {};
    try {
      const stored = localState.getValue() ?? {};
      if (isObject(stored)) {
        local = stored;
      }
    } catch {
      logger.log('could not fetch cached analysis');
    }

    try {
      if (LocalState.getValue(LS_ANALYSIS_OLD)) {
        // Because of a bug, we created a bad entry, here we need to remove it
        // https://www.notion.so/Move-frames-from-one-storyboard-to-another-38da9b52382e4855ba7a0545e35376d4
        LocalState.setValue(LS_ANALYSIS_OLD, undefined);
      }
    } catch {
      // do nothing
    }

    return local;
  };

  saveAnalysis = (storyboardId: number, analysis: StoryboardAnalysis) => {
    this.analysis[storyboardId] = pick(analysis, notNullOrUndefined);

    const persisted: AnalysisStorage = pick<AnalysisStorage>(
      {
        ...this.getAnalysis(),
        [storyboardId]: analysis,
      },
      (v) => !isEmpty(v),
    );

    localState.setValue(persisted);

    this.emitChange();
  };
}

(window as any).StoryboardAnalysisStore = alt.createStore(
  StoryboardAnalysisStore,
  'StoryboardAnalysisStore',
);
