/** @prettier */
import * as React from 'react';
import { ToolbarButton } from '../../ToolbarButton';
import type { modeOptions } from './toolbarOptions';
import { FrameEditorActions } from '../../../flux/actions/frame_editor';

export const ToolbarModeButton: React.FC<{
  mode: typeof modeOptions[number];
  isActive: boolean;
  disabled?: boolean;
}> = ({ mode, ...props }) => {
  const onClick = React.useCallback(
    (e) => FrameEditorActions.updateEditingMode(e.currentTarget.value),
    [],
  );

  return (
    <ToolbarButton
      key={mode.value}
      labelKeyPrefix="frameEditor.toolbar.mode"
      labelKey={String(mode.labelKey ?? mode.value)}
      value={mode.value}
      active={props.isActive}
      onClick={onClick}
      labelPosition="top"
      disabled={props.disabled}
      icon={React.createElement(mode.icon)}
    />
  );
};
