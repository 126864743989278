const _ = require('underscore')
/**
  This module allows components to track changes to the screen width.
  Components can register a breakpoint under a name, so that they can receive
  updates for that breakpoint (just a true/false)

  This module also deals with throttling the resize events.

  You can also automate this by using the screensizeMonitorHOC (Higher Order
  Component).
 */
module.exports = (() => {
  const brackets = {}
  const callbacks = {
    all: []
  };
  let isListening = false;

  const onWidthChange = _.throttle(() => {
    _.forEach(brackets, (breakpoint, name) => {
      _.forEach(callbacks[name], f => f(window.innerWidth > breakpoint ))
    })
    _.forEach(callbacks.all, f => f())
  }, 200)

  const get = (name) => window.innerWidth > brackets[name];

  return {
    register: (name, breakpoint) => {
      if (!_.isFinite(breakpoint)) {
        throw new Error('breakpoint param is not a number');
      }

      brackets[name] = breakpoint;
      return get(name);
    },
    listen: (name, callback) => {
      if (!_.isArray(callbacks[name])) callbacks[name] = [];

      callbacks[name].push(callback);

      if (!isListening) {
        window.addEventListener('resize', onWidthChange);
        isListening = true;
      }
    },
    unlisten: (name, callback) => {
      callbacks[name] = _.without(callbacks[name], callback);
    },
    get: get
  }
})()
