/** @format */

import React from 'react';
import { PlusIcon } from 'lucide-react';
import Button from 'blackbird/components/button/Button';
import { ProjectsEmpty } from './ProjectsEmpty';
import { ProjectsContext } from '../ProjectsContext';

export const ProjectOverviewEmpty: React.FC = () => {
  const { setIsNewProjectModalOpen } = React.useContext(ProjectsContext);
  return (
    <ProjectsEmpty
      title={`Add a project`}
      subtitle={`Click the button below to create a project`}
      className="pb-20"
    >
      <Button
        type="pink"
        rounded
        onClick={() => {
          setIsNewProjectModalOpen(true);
        }}
      >
        <span className="flex items-center">
          <span className="mr-1.5 -ml-1.5 pt-0.5 leading-none">
            <PlusIcon className="w-5 h-5" strokeWidth={2.5} />
          </span>
          <span>{`New Project`}</span>
        </span>
      </Button>
    </ProjectsEmpty>
  );
};
