/** @prettier */
import React, { useContext, useState } from 'react';
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import type { OnboardingProps } from './Onboarding';
import { useTranslation } from 'react-i18next';
import NewStoryboardAspectRatio from 'javascripts/components/new_project/NewStoryboardAspectRatio';
import { AiPrompt } from 'blackbird/components/ai/AiPrompt';
import { BoordsAiContext } from 'blackbird/helpers/contexts/BoordsAiContext';

const ProjectScreen: React.FC<OnboardingProps> = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'welcome.firstProject',
  });

  const { showAi } = useContext(BoordsAiContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const projectName = form['project[name]'].value;
    props.onProjectSetup && props.onProjectSetup(projectName);
  };

  if (showAi && props.projectId) {
    return (
      <div className="flex items-center justify-center flex-grow">
        <div className="flex flex-col flex-grow max-w-md">
          <div className="mb-8 space-y-4">
            <div className="text-2xl text-type-primary">
              {t('ai.heading', { name: props.username })}
            </div>
            <div className="text-type-subdued">{t('ai.subheading')}</div>
          </div>
          <AiPrompt
            simplified
            showDivider={false}
            project_id={props.projectId}
          />
        </div>
      </div>
    );
  } else {
    return (
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-center flex-grow"
      >
        <fieldset className="flex flex-col flex-grow max-w-md space-y-4">
          <legend className="text-2xl text-type-primary">{t('heading')}</legend>
          <span className="text-type-subdued">{t('subheading')}</span>
          <TextInput
            name="project[name]"
            placeholder={t('placeholder')}
            className="flex-grow-0"
          />

          <NewStoryboardAspectRatio />

          <div className="flex flex-col pt-8">
            <Button size="lg" htmlType="submit">
              {t('button')}
            </Button>
          </div>
        </fieldset>
      </form>
    );
  }
};
export default ProjectScreen;
