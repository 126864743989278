/** @format */

import React, { useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { CheckIcon } from 'lucide-react';
import { ProjectsContext, type ProjectGroup } from './ProjectsContext';
import { ProjectsPlusButton } from './ProjectsPlusButton';
import { ProjectsAccordionItem } from './ProjectsAccordionItem';
import { ProjectsGroupLabel } from './ProjectsGroupLabel';
import { ProjectsTeamDropdown } from './menus/ProjectsTeamDropdown';
import { ProjectsAccordionSort } from './ProjectsAccordionSort';
import Tooltip from '../feedback/tooltip/Tooltip';

export const ProjectGroupComponent: React.FC<{
  group: ProjectGroup;
  isSelectionMode?: boolean;
}> = ({ group, isSelectionMode }) => {
  const {
    newProjectParent,
    setNewProjectParent,
    setNewProjectGroup,
    newProjectGroup,
    selectedStoryboards,
    createNestedProjects,
    sortOption,
  } = useContext(ProjectsContext);

  const selectedGroup = newProjectGroup === group && !newProjectParent;

  const nestedProjects = useMemo(
    () => createNestedProjects(group.projects),
    [group.projects, sortOption, createNestedProjects],
  );

  // Don't allow storyboards to be moved to the top level
  useEffect(() => {
    if (selectedStoryboards.length > 0) {
      setNewProjectGroup(null);
    }
  }, [selectedStoryboards, setNewProjectGroup]);

  return (
    <div>
      {/* Header */}
      {isSelectionMode ? (
        <Tooltip title={`Move to top level`} placement="top">
          <div
            className={classNames(
              'pr-3 pl-3 py-1.5 rounded-md cursor-pointer mb-0.5 flex items-center',
              selectedGroup && 'bg-brand-blue-25',
              !selectedGroup &&
                selectedStoryboards.length === 0 &&
                'hover:bg-surface',
            )}
            onClick={() => {
              if (selectedStoryboards.length === 0) {
                setNewProjectParent(null);
                setNewProjectGroup(group);
              }
            }}
          >
            <div className="flex-auto">
              <ProjectsGroupLabel group={group} />
            </div>
            {selectedGroup && <CheckIcon className="w-4 h-4 text-brand-blue" />}
          </div>
        </Tooltip>
      ) : (
        <div className="flex items-center pl-3 pr-3 mb-2">
          <div className="flex-auto">
            <ProjectsTeamDropdown group={group} />
          </div>
          <div className="flex items-center space-x-2">
            <ProjectsAccordionSort />
            <ProjectsPlusButton group={group} />
          </div>
        </div>
      )}

      {nestedProjects.length > 0 ? (
        <div>
          {nestedProjects.map((project) => (
            <ProjectsAccordionItem
              key={project.id}
              isSelectionMode={isSelectionMode}
              project={project}
              level={0}
            />
          ))}
        </div>
      ) : (
        <p className="px-3 text-xs text-type-disabled">{`No projects in this group`}</p>
      )}
    </div>
  );
};
