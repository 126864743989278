/** @prettier */
import * as React from 'react';
import { FrameActions } from '../../flux/actions/frame';
import { ShareableItemInfo } from '../shareable/frames/ShareableItemInfo';
import { usePermission } from '../../helpers/has-permission';
import FrameComments from './FrameComments';
import type { IStoryboard } from '../../types/storyboard';
import type { IFrame } from '../../types/frame';
import type { RichTextInputChangeEventHandler } from 'blackbird/components/form/richTextInput/RichTextInput';

interface Props {
  storyboard: IStoryboard;
  frames: IFrame[];
  restrictWidth?: boolean;
  canManage?: boolean;
  activeIndex: number;
  showFrameStatus?: boolean;
  hideComments?: boolean;
}

const FramePanelBar: React.FC<Props> = ({ frames, activeIndex, ...props }) => {
  const [portalRef, setPortalRef] = React.useState<HTMLDivElement | null>(null);
  const storyboardId = props.storyboard.id;
  const handleUpdateField = React.useCallback<RichTextInputChangeEventHandler>(
    (value, e, name) => {
      const currentFrame = frames[activeIndex];
      FrameActions.saveText.defer({
        storyboard_id: storyboardId,
        frame_id: currentFrame.id,
        field_id: name!,
        text: value,
        update_field_state: true,
      });
    },
    [frames, activeIndex, storyboardId],
  );

  const currentFrame = frames[activeIndex];
  const canEditFields = BoordsConfig.controller !== 'shareable';
  const commentsAreEnabled =
    usePermission(props.storyboard.project.owner.user_id, 'comments') ===
      true &&
    props.storyboard.has_comments_enabled &&
    !props.hideComments;

  return (
    <>
      <ShareableItemInfo
        // Make sure we abandon focus when navigating away
        key={currentFrame.id}
        frame={currentFrame}
        storyboard={props.storyboard}
        index={activeIndex + 1}
        frame_fields={props.storyboard.frame_fields!}
        onUpdateFrameField={canEditFields ? handleUpdateField : undefined}
        labelsHaveBackground={false}
        isImageLibraryLayout={props.hideComments}
        noBackground
        hideIndex
      />

      {commentsAreEnabled && (
        <>
          <div className="flex flex-col flex-grow px-3 md:px-6">
            <FrameComments
              portalRef={portalRef}
              storyboard={props.storyboard}
              activeFrame={currentFrame}
              canManage={props.canManage}
              width="w-full"
            />
          </div>
          <div
            className="sticky z-50 px-3 pb-6 bg-white md:px-6 bottom-0"
            ref={setPortalRef}
          />
        </>
      )}
    </>
  );
};

const memoized = React.memo(FramePanelBar);
export { memoized as FramePanelBar };

memoized.displayName = 'FramePanelBar';
