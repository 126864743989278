/** @prettier */
import * as React from 'react';
import Container from '../../shared/Container';
import type {
  FrameField,
  IStoryboardPreferences,
  WordCountFromValue,
} from '../../../types/storyboard';
import { CopyFromStoryboard } from './CopyFromStoryboard';
import type { IStoryboardInStore } from '../../../types/storyboard';
import { notificationIfNoPermission } from '../../../helpers/has-permission';
import { StoryboardActions } from '../../../flux/actions/storyboard';
import { StoryboardAnalysisActions } from '../../../flux/actions/storyboardAnalysis';
import { LocalState } from '../../../helpers/local-state';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import Toggle, {
  type ToggleOnChangeHandler,
} from 'blackbird/components/toggle/Toggle';
import { times } from 'underscore';
import { FrameFieldEditor } from './FrameFieldEditor';
import { addFrameField } from 'javascripts/helpers/storyboard/frameFieldHelpers';
import { hasRolloutFlagEnabled } from 'javascripts/helpers/rollout';
import Select, {
  SelectChangeHandler,
} from 'blackbird/components/form/select/Select';
import type { Option } from 'blackbird/components/common/types';
import { eachFrameFieldMap } from 'javascripts/helpers/eachFrameFieldMap';
import { notNullOrUndefined } from 'javascripts/helpers/notUndefined';
import { StoryboardPreferenceToggle } from '../ShareFlyover/StoryboardPreferenceToggle';

export type StateFrameField = FrameField & { placeholder: string };

export const advancedSettingsKey = 'storyboardSettingsAdvancedOpen';

interface Props extends WithTranslation {
  // Basically extends StoryboardStore
  storyboard: {
    storyboard: IStoryboardInStore;
    default_frame_fields: FrameField[];
    frame_fields_version: number;
  };
  coverpage: {
    cover?: any;
  };
  fetch?: () => void;
}

interface State {
  frame_fields: Array<StateFrameField>;
  hasChanges: boolean;
  isCopySettingsOpen: boolean;
  isAdvancedOpen: boolean;
}

class StoryboardSettings extends React.PureComponent<Props, State> {
  timeout: any;

  constructor(props) {
    super(props);
    this.state = {
      frame_fields: [],
      hasChanges: false,
      isAdvancedOpen: false,
      isCopySettingsOpen: LocalState.getValue(advancedSettingsKey) || false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.frame_fields_version !==
      this.props.storyboard.frame_fields_version
    ) {
      this.handleUpdateState(nextProps);
    }
  }

  // Can maybe find a way to not require this
  handleUpdateState = (overrideProps?: Props) => {
    const props: Props = overrideProps || this.props;
    const storyboard = props.storyboard.storyboard;
    const frameFields =
      storyboard.frame_fields ?? props.storyboard.default_frame_fields;

    this.setState({
      frame_fields: times(frameFields.length, (i) => {
        const defaultField: FrameField | undefined =
          props.storyboard.default_frame_fields[i];

        /** The new frame field to create. If there is a default frame we
         * inherit its properties */
        const item: StateFrameField = {
          icon: undefined,
          ...defaultField,
          placeholder: defaultField?.label ?? '',
        };

        // when these objects are the same, it means the defaults are used,
        // so we don't have to bother copying the properties
        if (
          storyboard.frame_fields !== props.storyboard.default_frame_fields &&
          storyboard.frame_fields[i]
        ) {
          Object.assign(item, storyboard.frame_fields[i]);
        }

        // FIXME: write why do we do this?
        if (item.label === defaultField?.label) {
          item.label = '';
        }

        return item;
      }),
    });
  };

  handleCoverChange = (name: string, newValue: boolean) => {
    if (
      notificationIfNoPermission(
        this.props.storyboard.storyboard.project.owner.id,
        'shareable',
      )
    )
      return;

    CoverpageActions.updateValue({
      attr: `storyboard.${name}`,
      value: newValue,
    });
  };

  handleToggleCopySettings = () => {
    this.setState((state) => {
      LocalState.setValue(advancedSettingsKey, !state.isCopySettingsOpen);
      return { isCopySettingsOpen: !state.isCopySettingsOpen };
    });
  };

  handleToggleAdvanced = () => {
    this.setState((state) => {
      return { isAdvancedOpen: !state.isAdvancedOpen };
    });
  };

  handleToggleComments(newValue) {
    StoryboardActions.update({
      name: 'has_comments_enabled',
      value: newValue,
    });
    StoryboardActions.save.defer();
  }

  handleSendingCommentEmails(newValue) {
    StoryboardActions.update({
      name: 'is_sending_comment_emails',
      value: newValue,
    });
    StoryboardActions.save.defer();
  }

  handleTogglePreference: ToggleOnChangeHandler = (newValue, e) => {
    const name = e.currentTarget.name as keyof IStoryboardPreferences;
    if (!name) throw new Error('event target must have name');
    const analyseForNames: typeof name[] = [
      'show_word_count',
      'word_count_all_fields',
      'show_storyboard_duration',
    ];

    StoryboardActions.updatePreference({
      value: newValue,
      name,
    });

    if (analyseForNames.includes(name)) {
      StoryboardAnalysisActions.scheduleAnalysis.defer();
    }
  };

  handleAddFieldClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    const placeholder = this.props.t('frameFields.newFieldInputPlaceholder', {
      defaultValue: '',
    });

    this.setState((state) => {
      return {
        frame_fields: addFrameField(state.frame_fields, {
          placeholder: placeholder,
        }),
      };
    });
  };

  componentDidMount() {
    CoverpageActions.fetch.defer(this.props.storyboard.storyboard.id);
    this.handleUpdateState();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  getWordCountOptions = (
    frameFields: FrameField[],
  ): Option<WordCountFromValue>[] => {
    const t = this.props.t;
    return [
      {
        label: t('sidebar.settings.storyboard.word_count_from.null'),
        value: null,
      },
      ...eachFrameFieldMap(frameFields, (_, fieldId, i, label) => ({
        label: t('sidebar.settings.storyboard.word_count_from.field', {
          fieldLabel: label,
        }),
        value: fieldId,
      })).filter(notNullOrUndefined),
      {
        label: t('sidebar.settings.storyboard.word_count_from.all'),
        value: 'all',
      },
    ];
  };

  handleUpdateWordCount: SelectChangeHandler = (value) => {
    StoryboardActions.updatePreference({
      value: value,
      name: 'word_count_from',
    });
    StoryboardAnalysisActions.scheduleAnalysis.defer();
  };

  render() {
    const { t } = this.props;
    const coverStoreLoaded = !!this.props.coverpage.cover;
    const storyboard = this.props.storyboard.storyboard;
    const storyboardDurationEnabled =
      hasRolloutFlagEnabled('Storyboardduration');

    return (
      <>
        <div className="px-6 mt-6">
          <FrameFieldEditor />
        </div>

        <div className="flex justify-start px-6 pt-1 pb-6 text-sm -mt-14">
          <button
            type="button"
            className="underline cursor-pointer text-type-subdued decoration-border-mid hover:no-underline underline-offset-2"
            onClick={this.handleToggleAdvanced}
          >
            {t(
              `sidebar.settings.storyboard.${
                this.state.isAdvancedOpen ? 'hide' : 'show'
              }_advanced_options`,
            )}
          </button>
        </div>

        {this.state.isAdvancedOpen && (
          <div className="px-6 pb-6 mt-4 text-sm space-y-4">
            {storyboardDurationEnabled && (
              <div className="flex items-center justify-between">
                {t('sidebar.settings.storyboard.show_storyboard_duration')}
                <Toggle
                  onChange={this.handleTogglePreference}
                  value={storyboard.preferences?.show_storyboard_duration}
                  name={'show_storyboard_duration'}
                  disabled={!coverStoreLoaded}
                />
              </div>
            )}

            <StoryboardPreferenceToggle
              preference="include_label_text_in_edit_view"
              onChange={this.handleTogglePreference}
              label={t(
                'sidebar.settings.storyboard.include_label_text_in_edit_view',
              )}
              isSaving={storyboard.is_saving}
              value={storyboard.preferences?.include_label_text_in_edit_view}
            />

            <div className="flex items-center justify-between">
              <div className="flex">
                {t('sidebar.settings.storyboard.include_label_text_in_output')}
                <HelpPopup
                  text={t(
                    'sidebar.settings.storyboard.include_label_text_in_output_elaboration',
                  )}
                />
              </div>
              <Toggle
                name="include_label_text_in_output"
                value={
                  coverStoreLoaded
                    ? this.props.coverpage.cover.storyboard
                        .include_label_text_in_output
                    : true
                }
                onChange={(value) =>
                  this.handleCoverChange('include_label_text_in_output', value)
                }
                disabled={!coverStoreLoaded}
              />
            </div>

            <div className="flex items-center justify-between">
              {t('sidebar.settings.storyboard.show_icons')}
              <Toggle
                name="include_icons_in_pdf"
                value={
                  coverStoreLoaded
                    ? this.props.coverpage.cover.storyboard.include_icons_in_pdf
                    : true
                }
                onChange={(value) =>
                  this.handleCoverChange('include_icons_in_pdf', value)
                }
                disabled={!coverStoreLoaded}
              />
            </div>
            <div className="flex items-center justify-between">
              {t('sidebar.settings.storyboard.show_frame_no')}
              <Toggle
                name="include_frame_number_in_pdf"
                value={
                  coverStoreLoaded
                    ? this.props.coverpage.cover.storyboard
                        .include_frame_number_in_pdf
                    : true
                }
                onChange={(value) =>
                  this.handleCoverChange('include_frame_number_in_pdf', value)
                }
                disabled={!coverStoreLoaded}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="pr-2 whitespace-nowrap">
                {t('sidebar.settings.storyboard.word_count_from.title')}
              </div>
              <Select
                value={storyboard.preferences?.word_count_from as string}
                options={this.getWordCountOptions(storyboard.frame_fields)}
                className="shrink"
                inputClassName="p-1 px-2"
                zIndex="z-sidebar"
                size="xs"
                onChange={this.handleUpdateWordCount}
                disableInput
              />
            </div>

            <div className="bt bw2px b--light-grey pb14px pt14px">
              <div className="">
                {this.state.isCopySettingsOpen ? (
                  <>
                    <CopyFromStoryboard
                      storyboardID={storyboard.id}
                      projectID={storyboard.project.id}
                    />

                    <button
                      type="button"
                      className="mt-2 text-sm no-padding-x text-type-subdued"
                      onClick={this.handleToggleCopySettings}
                    >
                      {t('sidebar.settings.storyboard.hide')}
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="text-sm underline cursor-pointer text-type-subdued decoration-border-mid hover:no-underline underline-offset-2 no-padding-x"
                    onClick={this.handleToggleCopySettings}
                  >
                    {t('sidebar.settings.storyboard.copy_settings_from')}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export const StoryboardSettingsContainer = withTranslation()(
  Container(['storyboard', 'coverpage'])(StoryboardSettings),
);
