/** @prettier */
import classNames from 'classnames';
import React from 'react';

export const PlayerPopoverTitle: React.FC<
  React.HTMLAttributes<HTMLHeadingElement>
> = ({ className = 'mb-6', ...rest }) => (
  <h3
    className={classNames(
      // To avoid specificity issues, only add font-semibold class
      // if no other font classes are added
      { 'font-semibold': !className.includes('font-') },
      className,
    )}
    {...rest}
  />
);
