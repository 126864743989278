/** @prettier */
import * as React from 'react';

/**
 * enters and exist an intercom context when this component mounts and unmounts
 */
export function useIntercomContext(
  contextName: string,
  /** optional, but can be used to add another condition to entering the context */
  isActive: boolean = true,
) {
  React.useEffect(() => {
    if (isActive) IntercomActions.enterContext.defer(contextName);

    return () => {
      if (isActive) IntercomActions.leaveContext.defer(contextName);
    };
  }, [contextName, isActive]);
}
