/** @format */
/* eslint react-perf/jsx-no-new-object-as-prop:0 */
import * as React from 'react';
import { getScriptEditorColor } from './scriptEditorColors';
import type { DetailedFrame } from 'javascripts/types/frame';
import * as memoize from 'memoizee';
import {
  TransformingTextArea,
  type TransformingTextAreaChangeHandler,
} from 'blackbird/components/form/text-input/TextInput';
import './filteredScriptEditor.scss';
import { type ScriptEditorFilteredData } from 'javascripts/flux/stores/scripteditor';
import { scriptEditorFieldToHTML } from './scriptEditorFieldToHTML';
import {
  entireElementIsSelected,
  scriptEditorDeletionHandler,
} from './scriptEditorDeletionHander';
import type { RichTextInputPasteEventHandler } from 'blackbird/components/form/richTextInput/RichTextInput';
import { sanitizeHTML } from 'blackbird/components/form/richTextInput/sanitizeHTML';
import { markdownParser } from 'javascripts/helpers/markdown-parser';
import logger from 'javascripts/helpers/logger';
import { times } from 'underscore';
import { focusInsideElement } from './focusInsideElement';
import { parseAndSanitizeText } from 'blackbird/helpers/parseAndSanitizeText';

interface Props {
  onChange: (props: ScriptEditorFilteredData) => void;
  frameCount: number;
  fieldIndex: number;
  filter?: string;
  value?: ScriptEditorFilteredData | null;
  frames: DetailedFrame[];
}

export class FilteredScriptEditor extends React.PureComponent<Props> {
  static defaultProps = {
    fieldIndex: 0,
  };

  inputRef = React.createRef<HTMLDivElement>();

  getFrameNumberLength = memoize((frames: DetailedFrame[]) =>
    frames.reduce((maxCount, current) => {
      const currentLength = current.number?.length ?? 1;

      return Math.max(currentLength, maxCount);
    }, 1),
  );

  // Intercept enter + backspace keys and prevent them from creating,
  // new lines. instead, go to the next/previous line
  handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (
      (e.key === 'Backspace' || e.key === 'Delete') &&
      entireElementIsSelected(this.inputRef.current!)
    ) {
      const newFieldData: ScriptEditorFilteredData = this.props.frames.reduce(
        (o, f) => {
          o[f.id] = '';
          return o;
        },
        {},
      );

      const newHTML = this.transformIn(newFieldData);
      e.preventDefault();
      this.inputRef.current!.innerHTML = newHTML;
      const el = this.inputRef.current!;
      this.props.onChange(newFieldData);

      // Now we place the cursor inside the first paragraph tag, so the user can
      // start typing immediately
      focusInsideElement(el.querySelector('p'));
    } else {
      scriptEditorDeletionHandler(e);
    }
  };

  handleChange: TransformingTextAreaChangeHandler<ScriptEditorFilteredData> = (
    newValue,
  ) => {
    this.props.onChange(newValue);
  };

  handlePaste: RichTextInputPasteEventHandler = (e, textPasted, inputEl) => {
    const selection = document.getSelection();
    const range = selection?.getRangeAt(0);
    const editorEl = e.currentTarget;

    let parsedHTML: DocumentFragment;

    if (textPasted instanceof DocumentFragment) {
      parsedHTML = textPasted;
    } else {
      parsedHTML = parseAndSanitizeText(textPasted, true);
    }

    if (!range) throw new Error('expecting range');

    // If the user's selection spans multiple frames, these elements would be
    // the same. We may want to break out if this is the case
    if (range.commonAncestorContainer === e.currentTarget) {
      const lastEl = editorEl.lastChild!;
      const firstElementIsSelected =
        range.isPointInRange(editorEl.firstElementChild!, 0) ||
        range.isPointInRange(editorEl.querySelector('p')!, 1);

      const lastElementIsSelected = range.isPointInRange(
        lastEl,
        lastEl.textContent!.length,
      );

      if (firstElementIsSelected && lastElementIsSelected) {
        // We can leave this…
      } else {
        // In this case, the user has selected across multiple frames, we don't
        // really know what to do here, so we will leave
        return false;
      }
    }

    // At this point, we expect the parsed HTML to be a DocumentFragment
    // consisting of paragraphs (maybe with line breaks text nodes)
    const paragraphs = Array.from(parsedHTML.children);

    // Find out what div we (frameContainer) are in
    const startContainer = range.startContainer;
    const currentElement =
      startContainer instanceof HTMLElement
        ? startContainer
        : startContainer.parentElement;
    const currentFrameContainer: HTMLElement =
      currentElement!.closest('div[data-frame]')!;

    // Insert the first paragraph at the current cursor position
    // TODO: If this item has an empty paragraph in it, we should clear it out
    // in order to prevent the creation of an empty line
    document.execCommand('insertHTML', false, paragraphs.shift()?.outerHTML);
    range.collapse(false);

    // Then, for every subsequent paragraph, insert it underneath as new frames
    let insertAfterElement = currentFrameContainer;
    for (const paragraph of paragraphs) {
      const newDiv = document.createElement('div');
      newDiv.appendChild(paragraph);
      // TODO: use insertHTML somehow?
      insertAfterElement.insertAdjacentElement('afterend', newDiv);
      insertAfterElement = newDiv;
    }

    // Now we need to rewrite the frame containers so they have the right props.
    // We start by adding new frame containers if needed
    const diff =
      this.props.frames.length -
      inputEl.querySelectorAll(':scope > div').length;

    if (diff > 0) {
      logger.log(`Adding ${diff} containers`);
      times(diff, () => {
        const newDiv = scriptEditorFieldToHTML('');
        // TODO: use insertHTML somehow?
        editorEl.appendChild(newDiv);
      });
    }

    const frameContainers: HTMLElement[] = Array.from(
      // This shouldn't be too specific, because newly created divs might not
      // have any attributes yet
      inputEl.querySelectorAll(':scope > div'),
    );

    frameContainers.forEach((container, i) => {
      const frame: DetailedFrame | undefined = this.props.frames[i];
      if (frame) {
        this.decorateFrameContainer(container, frame);
      } else {
        container.remove();
        // FIXME: this isn't the best UX, but it matches what we have now
        logger.log(`deleted a frame container to keep frame count`);
      }
    });
  };

  decorateFrameContainer = (
    frameContainer: HTMLElement,
    frame: DetailedFrame | undefined,
  ) => {
    frameContainer.dataset.field = this.props.filter;
    if (frame) {
      frameContainer.dataset.number = frame.number;
      frameContainer.dataset.frame = String(frame.id);
    } else {
      // The frame doesn't exist
      frameContainer.dataset.number = '?';
      frameContainer.dataset.frame = undefined;
    }
  };

  parser = new DOMParser();
  transformIn = (data?: ScriptEditorFilteredData): string => {
    if (!data) return '';
    const outputContainer = document.createElement('div');

    this.props.frames.forEach((frame) => {
      const frameContainer = scriptEditorFieldToHTML(data[frame.id]);
      this.decorateFrameContainer(frameContainer, frame);
      outputContainer.appendChild(frameContainer);
    });

    return outputContainer.innerHTML;
  };

  transformOut = (string?: string): ScriptEditorFilteredData => {
    const result = this.parser.parseFromString(string ?? '', 'text/html');

    return this.props.frames.reduce((output, frame) => {
      const value = result
        .querySelector(`div[data-frame="${frame.id}"]`)
        ?.innerHTML.replace(/\u200B/, '');

      if (value) output[frame.id] = value;
      return output;
    }, {});
  };

  render() {
    if (this.props.value === null) return null;
    return (
      <TransformingTextArea
        className={`leading-9 text-sm ${
          getScriptEditorColor(this.props.fieldIndex)[0]
        }`}
        textAreaClassName="p-0 overflow-x-hidden filteredScriptEditor has-lists"
        value={this.props.value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        // onSelect={this.handleSelect}
        onPaste={this.handlePaste}
        noBorder
        ref={this.inputRef}
        data-indexlength={this.getFrameNumberLength(this.props.frames)}
        transformIn={this.transformIn}
        transformOut={this.transformOut}
        immediate
      />
    );
  }
}
