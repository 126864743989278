/** @prettier */

const { default: Icon } = require('blackbird/components/icon/Icon');
import ProjectIcon from 'blackbird/images/icons/project.svg';
import Button from 'blackbird/components/button/Button';
import React from 'react';
import { SecondaryLink } from 'blackbird/components/common/SecondaryLink';

interface UsageProps {
  plan: string;
  renewal_date: string;
  count_users: number;
  limit_users: number;
  count_storyboards: number;
  limit_storyboards: number;
  show_generator_settings: boolean;
  show_generator_upgrade_prompt: boolean;
  recurring_token_count: number;
  topup_token_count: number;
  max_recurring_images: number;
}

interface UsageBarProps {
  title: string;
  subtitle?: string;
  count: number;
  limit: number;
}

interface UnlimitedBarProps {
  title: string;
  subtitle?: string;
}

const UsagePill: React.FC = ({ children }) => (
  <div className="bg-surface px-3 pt-0.5 pb-1 rounded-full text-sm text-type-subdued">
    {children}
  </div>
);

const UsageBar: React.FC<UsageBarProps> = ({
  title,
  count,
  limit,
  subtitle,
}) => {
  const [width, setWidth] = React.useState(0);
  const [unlimited, setUnlimited] = React.useState(false);
  const [bg, setBg] = React.useState('bg-surface-success');

  React.useEffect(() => {
    const percentageWidth = (count / limit) * 100;
    if (percentageWidth < 4) {
      setWidth(4);
    } else if (percentageWidth > 100) {
      setWidth(100);
      setBg('bg-surface-critical');
    } else if (percentageWidth > 70) {
      setWidth(percentageWidth);
      setBg('bg-surface-warning');
    } else {
      setWidth(percentageWidth);
    }
  }, [count, limit]);

  React.useEffect(() => {
    if (limit === 10000) {
      setUnlimited(true);
      setBg('bg-brand-blue-25');
    }
  }, [limit]);

  return (
    <div className="my-8">
      <div className="flex items-center mb-2">
        <div className="flex items-baseline flex-auto">
          <div className="font-semibold">{title}</div>

          {subtitle && (
            <div className="ml-2 text-sm text-type-subdued">{subtitle}</div>
          )}
        </div>
        <UsagePill>{`${count} / ${unlimited ? `Unlimited` : limit}`}</UsagePill>
      </div>
      <div className="relative w-full h-4 rounded-full bg-surface">
        <div
          className={`absolute top-0 bottom-0 left-0 rounded-full ${bg}`}
          style={{ width: `${width}%` }}
        />
      </div>
    </div>
  );
};

const UnlimitedBar: React.FC<UnlimitedBarProps> = ({ title, subtitle }) => {
  return (
    <div className="my-8">
      <div className="flex items-center mb-2">
        <div className="flex items-baseline flex-auto">
          <div className="font-semibold">{title}</div>
          {subtitle && (
            <div className="ml-2 text-sm text-type-subdued">{subtitle}</div>
          )}
        </div>
        <UsagePill>{`Unlimited`}</UsagePill>
      </div>
    </div>
  );
};

const Usage: React.FC<UsageProps> = ({
  plan,
  count_storyboards,
  limit_storyboards,
  count_users,
  limit_users,
  show_generator_settings,
  recurring_token_count,
  topup_token_count,
  max_recurring_images,
  renewal_date,
}) => {
  const hasRecurringImageCredits = max_recurring_images > 0;
  return (
    <div className="w-full max-w-md">
      <div className="flex items-center mb-6 space-x-2">
        <Icon icon={<ProjectIcon />} />
        <div className="text-lg">{`${plan} Plan`}</div>
      </div>

      {['Workflow', 'Standard'].includes(plan) ? (
        <div className="my-8">
          <div className="flex items-center mb-2">
            <div className="flex items-baseline flex-auto">
              <div className="mr-2 font-semibold">{`Additional Users`}</div>
              <SecondaryLink href="/settings/team/manage">{`Manage team`}</SecondaryLink>
            </div>
            <UsagePill>{count_users - 1}</UsagePill>
          </div>
        </div>
      ) : (
        <>
          {limit_users === 10000 ? (
            <UnlimitedBar title="Team Members" />
          ) : (
            <UsageBar
              title="Team Members"
              count={count_users}
              limit={limit_users}
            />
          )}
        </>
      )}

      {limit_storyboards === 10000 ? (
        <UnlimitedBar title="Active Storyboards" />
      ) : (
        <UsageBar
          title="Active Storyboards"
          count={count_storyboards}
          limit={limit_storyboards}
        />
      )}

      {show_generator_settings &&
        (!hasRecurringImageCredits ? (
          <UsageBar
            title={`Free AI Image Generator Credit Usage`}
            count={5 - topup_token_count}
            limit={5}
          />
        ) : max_recurring_images === 10000 ? (
          <UnlimitedBar title="AI Image Generator Credits" />
        ) : (
          <>
            <div className="flex items-center mt-16 mb-6 space-x-2">
              <div className="text-lg">{`AI Image Generator Credits`}</div>
            </div>
            <UsageBar
              title={`Recurring Credits`}
              subtitle={`Renews ${renewal_date}`}
              count={max_recurring_images - recurring_token_count}
              limit={max_recurring_images}
            />

            <div className="flex items-center mb-2">
              <div className="flex-auto font-semibold">{`Topup Credits`}</div>
              <UsagePill>{`${topup_token_count}`}</UsagePill>
            </div>
          </>
        ))}

      <div className="pt-6 mt-8 border-t border-border">
        <Button
          rounded
          onClick={() => {
            FlyoverActions.open.defer({
              type: 'inlinePricing',
            });
          }}
        >{`Compare Plans`}</Button>
      </div>
    </div>
  );
};

(window as any).Usage = Usage;
