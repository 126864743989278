/** @prettier */
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import * as React from 'react';
import Icon from 'blackbird/components/icon/Icon';
import EmptyStateIcon from 'blackbird/images/icons/empty-state.svg';

interface StatusIndicatorProps {
  text: string;
  loading?: boolean;
  empty?: boolean;
}

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  loading,
  text,
  empty,
}): React.ReactElement => {
  return (
    <div className="min-h-[30rem] flex-col flex items-center justify-center">
      {loading ? (
        <LoadingIndicator secondary text={text} />
      ) : (
        <>
          {empty && (
            <div className="mb-4 opacity-20">
              <Icon icon={<EmptyStateIcon />} className="" />
            </div>
          )}
          <div className="flex items-center justify-center pb-16 text-sm text-type-subdued">
            <span>{text}</span>
          </div>
        </>
      )}
    </div>
  );
};
