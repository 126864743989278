/**@prettier */
/* eslint no-constant-condition:1 */
import { Disclosure } from '@headlessui/react';
import Icon from 'blackbird/components/icon/Icon';
import AddSmallIcon from 'blackbird/images/icons/add-small.svg';
import ChevronIcon from 'blackbird/images/icons/arrow.svg';
import classNames from 'classnames';
import ProjectDashboardPopupMenu from 'app/assets/javascripts/components/popup_menus/ProjectDashboardPopupMenu';
import { Fragment } from 'react';
import Folder from 'blackbird/images/icons/folder.svg';
import DragHandleIcon from 'blackbird/images/icons/drag-handle.svg';
import { withTranslation } from 'react-i18next';
import { IconButton } from 'blackbird/components/common/IconButton';
import Button from 'blackbird/components/button/Button';

const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');

const DashboardProjectItem = createReactClass({
  propTypes: {
    project: PropTypes.object.isRequired,
    projectsLength: PropTypes.number.isRequired,
    teamId: PropTypes.number.isRequired,
    user: PropTypes.object,
    actions: PropTypes.array,
  },

  getDefaultProps: function () {
    return {
      localStateKey: 'expandedDashboardProjects',
    };
  },

  getInitialState: function () {
    return {
      collapsed: true,
      menuVisible: false,
    };
  },

  componentDidMount: function () {
    if (
      window.LocalState.idIsInArray(
        this.props.project.id,
        this.props.localStateKey,
      )
    ) {
      this.setState({
        collapsed: false,
      });
    } else if (this.props.projectsLength <= 1) {
      this.setState({
        collapsed: false,
      });
      window.LocalState.addToArray(
        this.props.project.id,
        this.props.localStateKey,
      );
    }
  },

  _handleCollapseClick: function () {
    if (this.state.menuVisible) return;
    if (this.state.collapsed) {
      window.LocalState.addToArray(
        this.props.project.id,
        this.props.localStateKey,
      );
    } else {
      window.LocalState.removeFromArray(
        this.props.project.id,
        this.props.localStateKey,
      );
    }
    this.setState({ collapsed: !this.state.collapsed });
  },

  handleFirstOpen() {
    TeamManagementActions.fetch.defer(this.props.teamId);
  },
  handleMenuVisible(open) {
    if (!open) {
      // We will toggle it in the next event loop cycle
      // because _handleCollpaseClick is called after this function
      setTimeout(() => {
        this.setState({
          menuVisible: open,
        });
      }, 1);
    } else {
      this.setState({
        menuVisible: open,
      });
    }
  },
  handleNewStoryboardClick() {
    FlyoverActions.open({
      type: 'newStoryboard',
      projectId: this.props.project.id,
    });
  },
  render: function () {
    const { project, actions, t } = this.props;
    const hasRenamePermission = actions.includes('projects.rename');
    var containerClass;
    var isProjectAdmin;
    var projectStoryboards;

    if (!this.props.user) {
      return null;
    }

    if (project.is_admin) {
      containerClass = ' is--admin';
      isProjectAdmin = true;
    } else {
      containerClass = ' is--not-admin';
      isProjectAdmin = false;
    }

    projectStoryboards = (
      <DashboardStoryboards
        {...this.props}
        isProjectAdmin={isProjectAdmin}
        projectId={project.id}
        storyboards={project.storyboards}
      />
    );

    return (
      <article
        className={classNames(
          'project bg-white px-10 group rounded-lg group' + containerClass,
        )}
      >
        {/* No way to make this a controlled component in HeadlessUI at the moment , has to use the key for rerender */}
        <Disclosure
          key={`disclosure-${!this.state.collapsed}`}
          defaultOpen={!this.state.collapsed}
        >
          {({ open }) => (
            <>
              <div className="flex items-center py-6">
                <Disclosure.Button as={Fragment}>
                  <div
                    onClick={this._handleCollapseClick}
                    className={classNames('flex-grow', {
                      'pointer-events-none': this.state.menuVisible,
                    })}
                  >
                    <div className="relative flex items-center gap-6">
                      <Icon
                        icon={<DragHandleIcon />}
                        className="absolute opacity-0 js-project-drag-handle -left-7 top-3 group-hover:opacity-100 cursor-grab transition-opacity"
                        title={t('dashboard.projectDragHandle')}
                      />
                      <Folder />

                      <ProjectName {...this.props} />
                    </div>
                  </div>
                </Disclosure.Button>
                <div className="flex items-center gap-3">
                  {actions.includes('storyboards.create') &&
                    project.storyboards.length > 6 && (
                      <>
                        <Button
                          type="secondary"
                          size="sm"
                          className="opacity-0 group-hover:opacity-100 transition-opacity delay-300"
                          onClick={this.handleNewStoryboardClick}
                          icon={<Icon icon={<AddSmallIcon />} />}
                        >
                          {t('dashboard.newStoryboardButton')}
                        </Button>
                      </>
                    )}

                  <ProjectDashboardPopupMenu
                    onVisibilityChange={this.handleMenuVisible}
                    project={project}
                    disabled={!hasRenamePermission}
                    disabledText={t(
                      'warnings.permission.project.rename_archive',
                    )}
                  />

                  <IconButton
                    hasTooltip={false}
                    aria-label="Expand"
                    onClick={this._handleCollapseClick}
                    icon={<Icon icon={<ChevronIcon />} />}
                    iconClassName={classNames(
                      'w-10 cursor-pointer transform ml-0.5',
                      {
                        '-rotate-90': !open,
                      },
                    )}
                  />
                </div>
              </div>
              <Disclosure.Panel>{projectStoryboards}</Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </article>
    );
  },
});

window.DashboardProjectItem = withTranslation()(DashboardProjectItem);
