import { Trans, useTranslation } from 'react-i18next';
import React, {useState} from 'react';
import Button from 'blackbird/components/button/Button';
import Checkbox from 'blackbird/components/form/checkbox/Checkbox';
import {RequestActions} from 'javascripts/flux/actions/request';
import {CookieSetService} from 'javascripts/helpers/cookie-service';
import logger from 'javascripts/helpers/logger';
const { default: Dialog } = require('blackbird/components/dialog/Dialog');

interface FlyoverGoogleTermsProps {
  googleSigninPath: string
}
export const FlyoverGoogleTerms: React.FC<FlyoverGoogleTermsProps> = ({ googleSigninPath}) => {
  const { t } = useTranslation();
  const [marketing, setMarketing] = useState(false)
  const [terms, setTerms] = useState(false)

  const submit = () => {
    if (!terms) {
      RequestActions.error(t("signup.confirmDialog.error"))
    } else {
      CookieSetService("is_marketing_opt_in", marketing)
      setTimeout(() => {
        window.location.href = googleSigninPath
       }, 100)
    }
  }

  return (
    <Dialog
      isDark
      isBlur
      hideCancel
      keepOpen
      hideClose
      confirmBtnRounded
      alignButtons={`left`}
      confirmText={t('signup.confirmDialog.button')}
      onConfirmBtnClick={submit}
      title={t("signup.confirmDialog.title")}
      onCloseBtnClick={() => {
        FlyoverActions.close();
      }}
      isOpen
    >
        <div className='space-y-2'>
          <Checkbox
            id="terms"
            onChange={() => {
              setTerms(!terms)
            }}
            label={
              <Trans
                t={t}
                i18nKey="signup.terms"

                // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                components={{
                  terms: (
                    <a
                      className="underline hover:no-underline"
                      href="https://boords.com/page/terms-of-service"
                    >
                      Terms of Service
                    </a>
                  ),
                }}
              />
            }
            controlled
          />
          <Checkbox id="marketing" label={t("signup.is_marketing_opt_in")} controlled onChange={() => {
            setMarketing(!marketing)
          }}/>
      </div>
    </Dialog>
  )
}

(window as any).FlyoverGoogleTerms = FlyoverGoogleTerms;
