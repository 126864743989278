/** @prettier */
import { fabric } from 'fabric';
import { PSBrush } from '@arch-inc/fabricjs-psbrush';
import type { FabricObject, XYCoordinates } from './types';
const Arrow = require('./fabric-arrow');

export type frameEditorShapeTypes =
  | 'EraserBrush'
  | 'ellipse'
  | 'rect'
  | 'triangle'
  | 'arrow'
  | 'straightBrush'
  | 'hexagon';

type frameEditorShapeCreationFunction = (
  klass: any,
  color: string,
  pointer: XYCoordinates,
  limeWidth?: number,
  width?: number,
) => FabricObject;
export interface FrameEditorShapeDefinition {
  klass: any;
  create: frameEditorShapeCreationFunction;
  resizeFunc: (pointer: XYCoordinates, constrain?: boolean) => any;
}

const defaultShapeCreator: frameEditorShapeCreationFunction = (
  Klass,
  color,
  pointer,
) => {
  return new Klass({
    left: pointer.x,
    top: pointer.y,
    fill: color,
  });
};

function defaultShapeResize(pointer, constrain) {
  const width = Math.abs(pointer.x - this.left);
  const height = Math.abs(pointer.y - this.top);
  const constrainedSize = Math.min(width, height);

  const changes = {
    width: constrain ? constrainedSize : width,
    height: constrain ? constrainedSize : height,
    originX: 'left',
    originY: 'top',
  };

  changes.originX = this.left > pointer.x ? 'right' : 'left';
  changes.originY = this.top > pointer.y ? 'bottom' : 'top';

  return changes;
}

function ellipseResize(pointer, constrain) {
  const width = Math.abs(pointer.x - this.left) / 2;
  const height = Math.abs(pointer.y - this.top) / 2;
  const constrainedSize = Math.min(width, height);

  const changes = {
    rx: constrain ? constrainedSize : width,
    ry: constrain ? constrainedSize : height,
    originX: 'left',
    originY: 'top',
  };

  changes.originX = this.left > pointer.x ? 'right' : 'left';
  changes.originY = this.top > pointer.y ? 'bottom' : 'top';

  return changes;
}

/** This is the fabric-native eraserbrush that wasn't available originally */
export const FabricOriginalEraserBrush = fabric.EraserBrush;

const EraserBrush = fabric.util.createClass(PSBrush, {
  color: null,
  createPSStroke: function (points) {
    const path = this.callSuper('createPSStroke', points);
    path.isEraser = true;
    return path;
  },
});

fabric.EraserBrush = EraserBrush;
fabric.EraserBrush.fromObject = fabric.BaseBrush.fromObject;

export const ellipse: FrameEditorShapeDefinition = {
  klass: fabric.Ellipse,
  create: defaultShapeCreator,
  resizeFunc: ellipseResize,
};

export const rect: FrameEditorShapeDefinition = {
  klass: fabric.Rect,
  create: defaultShapeCreator,
  resizeFunc: defaultShapeResize,
};

export const triangle: FrameEditorShapeDefinition = {
  klass: fabric.Triangle,
  create: defaultShapeCreator,
  resizeFunc: defaultShapeResize,
};

export const arrow: FrameEditorShapeDefinition = {
  klass: Arrow,
  create: function (Klass, color, pointer, lineWidth = 37) {
    const points = [pointer.x, pointer.y, pointer.x, pointer.y];
    return new Arrow(points, {
      strokeWidth: lineWidth,
      stroke: color,
      originX: 'center',
      originY: 'center',
      strokeUniform: false,
    });
  },
  resizeFunc: function (pointer) {
    return {
      x2: pointer.x,
      y2: pointer.y,
    };
  },
};

export const straightBrush: FrameEditorShapeDefinition = {
  klass: fabric.Line,
  create: function (Klass, color, pointer, lineWidth, width = 300) {
    const points = [pointer.x, pointer.y, pointer.x + width, pointer.y];
    return new Klass(points, {
      strokeWidth: lineWidth,
      stroke: color,
      // Ideally, this is true! but it seems to cause issues after scaling
      strokeUniform: false,
    });
  },
  resizeFunc: function (pointer) {
    const diffX = Math.abs(this.x1 - pointer.x);
    const diffY = Math.abs(this.y1 - pointer.y);
    const constrainX = diffX < 15;
    const constrainY = diffY < 15;

    const newValues = {
      x2: constrainX ? this.x1 : pointer.x,
      y2: constrainY ? this.y1 : pointer.y,
    };

    return newValues;
  },
};

// Based off https://stackoverflow.com/a/29320278/847689
function regularPolygonPoints(sideCount, radius) {
  var sweep = (Math.PI * 2) / sideCount;
  var cx = radius;
  var cy = radius;
  var points: XYCoordinates[] = [];
  for (var i = 0; i < sideCount; i++) {
    var x = cx + radius * Math.cos(i * sweep);
    var y = cy + radius * Math.sin(i * sweep);
    points.push({ x: x, y: y });
  }
  return points;
}

export const hexagon: FrameEditorShapeDefinition = {
  klass: fabric.Polygon,
  resizeFunc: defaultShapeResize,
  create: function (Klass, color, pointer, lineWidth, radius) {
    const points = regularPolygonPoints(6, radius ?? 300);

    return new Klass(points, {
      top: pointer.y,
      left: pointer.x,
      strokeWidth: lineWidth,
      strokeLineJoin: 'bevil',
      fill: color,
      /** When `false`, the stoke width will scale with the object. When `true`, the stroke will always match the exact pixel size entered for stroke width. default to false */
      strokeUniform: false,
    });
  },
};

export { EraserBrush };
