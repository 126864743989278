/** @prettier */
/* eslint-disable react/display-name */
import * as React from 'react';
import type {
  FabricObject,
  ContextMenuProps,
  ContextMenuActions,
} from './types';
import { shapeTypesByValue } from './toolbar/toolbarOptions';
import { ShapeContextMenu } from './toolbar/ShapeContextMenu';
import { SharedContextMenu } from './toolbar/SharedContextMenuButtons';
import { DrawingContextMenu } from './toolbar/DrawingContextMenu';
import { contains } from 'underscore';
import { TextContextMenu } from './toolbar/TextContextMenu';
import { MultiSelectContextMenu } from './toolbar/MultiSelectContextMenu';
import { isNaN } from 'underscore';
import { clampNumber } from '../../helpers/clampNumber';
import { updatedPropEventName } from './updatedPropEventName';
import { IconSpacer } from '../IconSpacer';

/**
 * This has to be more than 40px, in order to prevent the menu from overlapping
 * the rotating control when the object is upside down
 */
const DISTANCE = 45;
const HEIGHT = 55;
const TOP_BAR_HEIGHT = 95;

const repositionEvents = [
  'moving',
  'modified',
  'scaling',
  'skewing',
  updatedPropEventName,
];

const guardNaN = (no) => (isNaN(no) ? 0 : no);

/** Find styles that would place the context bar above the selected object. */
const getStyles = (
  selected: FabricObject,
  container: React.RefObject<HTMLDivElement>,
  toolbarEl: React.RefObject<HTMLDivElement>,
): React.CSSProperties => {
  /** Gets the screen (not canvas) dimensions of the selected item */
  const { left, top, height } = selected.getBoundingRect(false, true);
  const parentElement = container.current?.parentElement;
  if (!container.current || !parentElement) return {};
  const containerDimensions = container.current.getBoundingClientRect();

  const spaceLeftX = parentElement.clientWidth - containerDimensions.width;
  const spaceLeftY = parentElement.clientHeight - containerDimensions.height;

  const paddingX = spaceLeftX / 2;
  const paddingY = spaceLeftY / 2;

  const padding = 10;
  const maxX = containerDimensions.width + spaceLeftX + padding;
  const currentWidth = toolbarEl.current?.clientWidth ?? 550;
  const actualLeft = guardNaN(left) + paddingX;

  const actualTop = guardNaN(top + paddingY) + height;
  const minY = -containerDimensions.top + TOP_BAR_HEIGHT + HEIGHT;
  const maxY = parentElement.clientHeight + paddingY - HEIGHT;
  const flippedTop = actualTop - HEIGHT - DISTANCE - height;

  /** In this case, flipped means the menu is on top of the element */
  const flipY = actualTop >= maxY - HEIGHT && flippedTop > minY;

  return {
    height: HEIGHT,
    position: 'absolute',
    willChange: 'left, top',
    left: clampNumber(actualLeft, -padding, maxX - currentWidth),
    top: flipY ? flippedTop : clampNumber(actualTop + DISTANCE, -padding, maxY),
  };
};

export const FrameEditorContextMenu = React.memo<{
  selected: FabricObject;
  disabled: boolean;
  actions?: ContextMenuActions;
  container: React.RefObject<HTMLDivElement>;
}>(function FrameEditorContextMenu({ selected, ...props }) {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const [position, setPosition] = React.useState<React.CSSProperties | null>(
    null,
  );

  /**
   * When we change the selected object, we want to rerender, so let's keep
   * a version variable that we can use as a "key" prop. React will do the rest
   * This will fix an issue where the context menu might not rerender properly
   * when moving selection between two objects of the same type */
  const [version, setVersion] = React.useState(0);

  // Add listeners to the current selected object to keep track if it moves,
  // and update it if it does
  React.useEffect(() => {
    if (!selected) return;
    setVersion((v) => v + 1);

    const effect = ({ transform }) => {
      if (!transform) return;
      setPosition(getStyles(transform.target, props.container, elementRef));
    };

    setPosition(getStyles(selected, props.container, elementRef));

    repositionEvents.forEach((eventName) => selected.on(eventName, effect));
    return () => {
      repositionEvents.forEach((eventName) => selected.off(eventName, effect));
    };
  }, [selected, props.container]);

  let ContextMenuToRender: React.ComponentType<ContextMenuProps> | undefined;

  const type = selected?.type;
  if (shapeTypesByValue[type] || type === 'polygon') {
    ContextMenuToRender = ShapeContextMenu;
  } else if (contains(['PSStroke', 'path', 'line'], type)) {
    ContextMenuToRender = DrawingContextMenu;
  } else if (type === 'textbox') {
    ContextMenuToRender = TextContextMenu;
  } else if (type === 'activeSelection') {
    ContextMenuToRender = MultiSelectContextMenu;
  } else if (type === 'image') {
    ContextMenuToRender = () => null;
  }

  if (!position || !ContextMenuToRender || !props.actions) return null;

  return (
    <div
      className="z-10 p-3 pb-1.5 bg-white rounded-lg shadow-md"
      style={position}
      ref={elementRef}
    >
      <IconSpacer>
        <ContextMenuToRender
          disabled={props.disabled}
          actions={props.actions!}
          selected={selected}
          key={version}
        />
        <SharedContextMenu
          hideFirstHr={type === 'image'}
          disabled={props.disabled}
          actions={props.actions!}
          selected={selected}
        />
      </IconSpacer>
    </div>
  );
});
