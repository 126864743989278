/** @prettier */

export type BadgeSize = 'lg' | 'sm' | 'xl';

export type BadgeColor =
  | 'white'
  | 'blue'
  | 'green'
  | 'yellow'
  | 'pink'
  | 'pinkBorder'
  | 'violet'
  | 'yellowToPink';

export const labelsToSpace = [
  'professionaltrial',
  'professionalstarter',
  'professionalmedium',
  'professionallarge',
  'professionalextralarge',
];

export const BadgeLabelColors = {
  admin: 'yellow',
  owner: 'yellowToPink',
  active: 'green',
  'full access': 'yellow',
  'restricted access': 'blue',
  professionaltrial: 'yellowToPink',
  professionalstarter: 'yellowToPink',
  professionalmedium: 'yellowToPink',
  professionallarge: 'yellowToPink',
  professionalextralarge: 'yellowToPink',
};
