/** @format */

import React, { createContext, useEffect, useState, useCallback } from 'react';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import logger from 'javascripts/helpers/logger';
import { RequestActions } from 'javascripts/flux/actions/request';
import { rollbar } from 'javascripts/helpers/rollbar';

const errorHandler = RequestErrorHandler('ShareableProject');

// Updated type definitions based on the new query
export interface ShareableProject {
  id: number;
  name: string;
  description: string;
  slug: string;
  created_at: string;
  updated_at: string;
  team_id: number;
  parent_id?: number;
  share_slug?: string;
  share_with_description: boolean;
  share_with_sub_projects: boolean;
  share_with_password: boolean;
  share_password?: string;
  share_expires_at: string | null;
}

export interface Storyboard {
  id: number;
  project_id: number;
  slug: string;
  document_name: string;
  frame_aspect_ratio: string;
  sort_order: number | null;
  created_at: string;
  updated_at: string;
  short_slug: string;
  denormalized_frames: string;
  cover_image: string | null;
}

export interface Team {
  id: number;
  name: string;
  logo_url: string | undefined;
}

export interface SubProject {
  id: number;
  name: string;
  slug: string;
  share_slug?: string;
  description: string;
  created_at: string;
  updated_at: string;
  team_id: number;
}

interface ShareableProjectResponse {
  data: {
    attributes: {
      project: ShareableProject;
      storyboards: Storyboard[];
      team: Team;
      sub_projects: SubProject[];
    };
  };
}

interface ShareableProjectContextProps {
  project: ShareableProject | null;
  storyboards: Storyboard[];
  team: Team | null;
  subProjects: SubProject[];
  isLoading: boolean;
  error: string | null;
  fetchShareableProject: () => Promise<void>;
}

const defaultValues: ShareableProjectContextProps = {
  project: null,
  storyboards: [],
  team: null,
  subProjects: [],
  isLoading: false,
  error: null,
  fetchShareableProject: async () => {},
};

export const ShareableProjectContext =
  createContext<ShareableProjectContextProps>(defaultValues);

interface ShareableProjectProviderProps {
  children: React.ReactNode;
  share_slug: string;
}

export const ShareableProjectProvider: React.FC<
  ShareableProjectProviderProps
> = ({ children, share_slug }) => {
  const [project, setProject] = useState<ShareableProject | null>(null);
  const [storyboards, setStoryboards] = useState<Storyboard[]>([]);
  const [team, setTeam] = useState<Team | null>(null);
  const [subProjects, setSubProjects] = useState<SubProject[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleError = (message: string, error?: Error) => {
    RequestActions.error.defer(message);
    if (error) {
      logger.error(error);
      rollbar.error(error);
    }
  };

  const fetchShareableProject = useCallback(async () => {
    if (isLoading) return; // Prevent concurrent requests
    setIsLoading(true);
    setError(null);

    try {
      const request = await apiRequest({
        path: `shareable_projects/${share_slug}`,
        method: 'get',
      });

      if (!request.ok) {
        throw new Error('Failed to fetch shareable project');
      }

      const response: ShareableProjectResponse = await request.json();
      const { project, storyboards, team, sub_projects } =
        response.data.attributes;

      document.title = `${project.name} • ${team.name}`;

      setProject(project);
      setStoryboards(storyboards);
      setTeam(team);
      setSubProjects(sub_projects);
    } catch (err) {
      handleError('Error fetching shareable project', err);
      errorHandler({ method: 'get' })(err);
    } finally {
      setIsLoading(false);
    }
  }, [share_slug]);

  useEffect(() => {
    fetchShareableProject();
    IntercomActions.enterContext.defer('shareable');
  }, [fetchShareableProject]);

  const value: ShareableProjectContextProps = {
    project,
    storyboards,
    team,
    subProjects,
    isLoading,
    error,
    fetchShareableProject,
  };

  return (
    <ShareableProjectContext.Provider value={value}>
      {children}
    </ShareableProjectContext.Provider>
  );
};
