/** @prettier */
/* eslint  react-perf/jsx-no-new-object-as-prop:0 */
import * as React from 'react';
import { type IStoryboardInStore } from '../../types/storyboard';
import withCustomPopper, {
  type CustomPopperProps,
} from 'blackbird/helpers/popper';
import { Translation } from 'react-i18next';
import WaveForSquare from 'blackbird/images/icons/wave-for-square.svg';
import Approved from 'blackbird/images/icons/approved.svg';

import { isUserSubjectToFreeLimit } from '../../helpers/can-create-new-frame';
import { FrameActions } from '../../flux/actions/frame';
import ArrowIcon from 'blackbird/images/icons/arrow.svg';
import { FileUploadActions } from '../../flux/actions/file_upload';
import {
  ImageSourcesMultiPanel,
  ImageSourcesMultiPanelDefaultWidth as imageSourcesMultiPanelDefaultWidth,
} from './AddImageMultipanel/ImageSourcesMultiPanel';
import Popover from 'blackbird/components/common/popover/Popover';
import Button from 'blackbird/components/button/Button';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import Icon from 'blackbird/components/icon/Icon';
import Square from 'blackbird/images/icons/square.svg';
import { GridViewDropZone } from './GridviewDropzone';
import logger from 'javascripts/helpers/logger';

import { BoordsAiContext } from 'blackbird/helpers/contexts/BoordsAiContext';
import { times } from 'underscore';

const frames: number[] = [];
for (let f = 1; f <= 60; f++) {
  frames.push(f);
}

export interface IProps {
  storyboard: IStoryboardInStore;
  enableFilestack: boolean;
}

interface IState {
  newFramesCount: number;
  newFramesArray: number[];
}

const FrameRectangle: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className="flex flex-col w-32 mx-auto gap-2">
      <div
        className={classNames(
          'border-[3px] border-type-primary w-full rounded-sm self-center h-[66px]',
          className,
        )}
      >
        {children}
      </div>
      <div className="h-[3px] bg-type-primary w-1/2 rounded opacity-20" />
      <div className="h-[3px] bg-type-primary w-1/3 rounded opacity-20" />
    </div>
  );
};
class FramesBlankCanvasClass extends React.PureComponent<
  IProps & CustomPopperProps,
  IState
> {
  static contextType = BoordsAiContext;
  context!: React.ContextType<typeof BoordsAiContext>;

  constructor(e) {
    super(e);

    const limit = isUserSubjectToFreeLimit()
      ? FrameStore.getState().unpaidFrameCountLimit
      : 60;

    this.state = {
      newFramesCount: 6,
      newFramesArray: times(limit, (i) => i + 1),
    };
  }

  _change = (value) => {
    this.setState({
      newFramesCount: value,
    });
  };

  _createFrames = (e) => {
    e.preventDefault();
    FrameActions.insertFrames({
      storyboard_id: this.props.storyboard.id,
      frame_count: this.state.newFramesCount,
    });
  };

  handleAddButtonClick = () => {
    FileUploadActions.openPickerAndAddFrames.defer({
      options: {
        accept: 'image/*',
      },
      frame_aspect_ratio: this.props.storyboard.frame_aspect_ratio,
      team_id: this.props.storyboard.project.owner.id,
      storyboard_id: this.props.storyboard.id,
      maxFrames: this.state.newFramesArray.length,
    });
  };

  render() {
    const {
      setPopperParentRef,
      setPopperElement,
      popperStyles,
      popperAttributes,
    } = this.props;

    return (
      <Translation>
        {(t, { i18n }) => (
          <div className="flex justify-center pt-12 fullsize:pt-24 gap-6 xl:gap-10">
            <div className="flex flex-col p-10 text-center bg-white rounded-xl gap-5 w-80">
              <FrameRectangle />
              <div className="text-lg">
                {t('storyboard.blankCanvas.frames.title')}
              </div>
              <div className="text-type-subdued">
                {t('storyboard.blankCanvas.frames.subtitle')}
              </div>
              <div className="flex flex-col items-center mt-auto gap-6">
                <Listbox
                  value={this.state.newFramesCount}
                  onChange={this._change}
                >
                  <Listbox.Button
                    ref={setPopperParentRef}
                    className="bg-action-secondary flex gap-4 items-center flex-shrink-0 text-sm rounded-md font-semibold py-1.5 px-4"
                  >
                    <span>{this.state.newFramesCount}</span>
                    <Icon icon={<ArrowIcon />} />
                  </Listbox.Button>
                  <Listbox.Options
                    style={popperStyles.popper}
                    {...popperAttributes.popper}
                    ref={setPopperElement}
                    className="z-10 p-2 overflow-y-auto bg-white rounded-lg shadow-lg w-60 focus-visible:outline-none space-y-2"
                  >
                    {this.state.newFramesArray.map((f) => (
                      <Listbox.Option value={f} key={f}>
                        {({ active }) => (
                          <div
                            className={classNames(
                              'py-3 px-3 rounded-lg flex space-x-6 items-center',
                              {
                                'bg-surface-light': active,
                              },
                            )}
                          >
                            {'' + f + ' frames'}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Listbox>
                <Button htmlType="button" onClick={this._createFrames}>
                  {t('storyboard.blankCanvas.frames.button')}
                </Button>
              </div>
            </div>
            <div className="flex items-center text-type-disabled">{`or`}</div>
            <GridViewDropZone
              storyboard={this.props.storyboard}
              sortOrder={0}
              className="flex flex-col p-10 text-center bg-white rounded-xl w-80"
            >
              <div className="flex flex-col gap-5">
                <FrameRectangle className="relative flex justify-center">
                  <WaveForSquare className="absolute  fill-brand-blue rotate-90 -top-[14px] bottom-0 w-[39px]" />
                  <Square className="absolute -top-[3px] bottom-0 w-[66px]" />
                </FrameRectangle>
                <div className="text-lg ">
                  {t('storyboard.blankCanvas.images.title')}
                </div>
                <div className="text-type-subdued">
                  {this.props.enableFilestack
                    ? t('storyboard.blankCanvas.images.subtitle.withFilestack')
                    : t(
                        'storyboard.blankCanvas.images.subtitle.withoutFilestack',
                      )}
                </div>
              </div>

              <Popover
                className="mt-auto"
                // width={overlayWidth}
                placement="top"
              >
                <Popover.Button as="div">
                  <Button htmlType="button">
                    {t('storyboard.blankCanvas.images.button')}
                  </Button>
                </Popover.Button>
                <Popover.Panel className="z-40">
                  <ImageSourcesMultiPanel
                    storyboard={this.props.storyboard}
                    replace={false}
                  />
                </Popover.Panel>
              </Popover>
            </GridViewDropZone>

            {this.context.showAi && (
              <>
                <div className="flex items-center text-type-disabled">{`or`}</div>
                <div className="flex flex-col p-10 text-center bg-white rounded-xl gap-5 w-80">
                  <div className="flex items-center justify-center mt-4 mb-5">
                    <Approved className="relative w-[137px] h-[53px]" />
                  </div>
                  <div className="text-lg">
                    {t('storyboard.blankCanvas.ai.title')}
                  </div>
                  <div className="text-type-subdued">
                    {t('storyboard.blankCanvas.ai.subtitle')}
                  </div>
                  <div className="flex flex-col items-center pt-6 mt-auto gap-6">
                    <Button
                      htmlType="button"
                      type="fancy"
                      onClick={() => {
                        FlyoverActions.open({
                          type: 'aiScript',
                          storyboard: this.props.storyboard,
                        });
                      }}
                    >
                      {t('storyboard.blankCanvas.ai.button')}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </Translation>
    );
  }
}
export const FramesBlankCanvas = withCustomPopper(FramesBlankCanvasClass)({
  placement: 'bottom',
  maxHeight: 250,
});
