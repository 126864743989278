/** @format */
import { ProjectsProvider } from 'blackbird/components/projects/ProjectsContext';
import { TemplatesProvider } from 'blackbird/components/templates/TemplatesContext';
import { WizardContext } from 'blackbird/components/wizard/WizardContext';
import logger from 'javascripts/helpers/logger';

const createReactClass = require('create-react-class');
const { default: NewStoryboardForm } = require('./NewStoryboardForm');

window.NewStoryboardFormContainer = createReactClass({
  getInitialState: function () {
    return NewStoryboardStore.getState();
  },

  componentDidMount: function () {
    NewStoryboardStore.listen(this._onChange);
    NewStoryboardActions.fetchProjectsAndSetId(this.props.projectId);
  },

  componentWillUnmount: function () {
    NewStoryboardStore.unlisten(this._onChange);
  },

  _onChange: function (state) {
    if (this.props.projectId && BoordsConfig.HasV3) {
      this.setState((prevState) => ({
        ...state,
        show_project_dropdown: false,
        new_storyboard_project: {
          ...prevState.new_storyboard_project,
          id: this.props.projectId,
        },
      }));
    } else {
      this.setState(state);
    }
  },
  handleCloseClick: function () {
    /** Close flyover on modal close */
    FlyoverActions.close();
  },

  render: function () {
    if (!this.state.projects) {
      return <FlyoverLoading />;
    } else {
      return (
        <ProjectsProvider>
          <TemplatesProvider>
            <WizardContext.Consumer>
              {(WizardContext) => (
                <NewStoryboardForm
                  teamId={this.props.teamId}
                  defaultTemplateId={this.props.defaultTemplateId}
                  actions={this.props.actions}
                  wizardContext={WizardContext}
                  onClose={this.handleCloseClick}
                  {...this.state}
                />
              )}
            </WizardContext.Consumer>
          </TemplatesProvider>
        </ProjectsProvider>
      );
    }
  },
});
