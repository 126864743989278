/** @prettier */

import type {
  frameAspectRatio,
  AspectRatioName,
} from 'javascripts/types/storyboard';
import { getMissingFrameImageURL } from './getMissingFrameImageURL';
import { frameAspectRatioNormalizer } from './frame-size-helper';
import ignoreWebP from './ignore-webp';
import { isImagePlaceholder } from './isImagePlaceholder';

/** Grabs the thumbnail or full size image and loads the correct fallback image
 * depending on aspect ratio */
export const getFrameImage = (
  src: string | undefined | null,
  aspectRatio: frameAspectRatio | AspectRatioName,
) => {
  const normalized: AspectRatioName = frameAspectRatioNormalizer(aspectRatio);

  if (!src || isImagePlaceholder(src)) {
    return getMissingFrameImageURL(normalized);
  } else {
    return ignoreWebP(src);
  }
};
