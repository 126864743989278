/** @prettier */
import { PDFToCanvasImage } from '../coverToPng';
import * as fitjs from 'fit.js';
import { createPDFImage } from './PDFImage';
import { DocInfo } from '../types';

const watermarkURL =
  require('file-loader!../../../../../../lib/templates/watermarks/A4-landscape.pdf').default;

export const applyPDFWatermark = (docInfo: DocInfo) =>
  new Promise<void>((resolve, reject) => {
    PDFToCanvasImage(watermarkURL, 1, 3)
      .then((imageInfo) => {
        const { x, y, width, height } = fitjs(
          {
            x: 0,
            y: 0,
            width: imageInfo.width,
            height: imageInfo.height,
          },
          {
            x: 0,
            y: 0,
            width: docInfo.doc.internal.pageSize.width,
            height: docInfo.doc.internal.pageSize.height,
          },
          {
            hAlign: fitjs.CENTER, // or fit.LEFT, fit.RIGHT
            vAlign: fitjs.CENTER, // or fit.TOP, fit.BOTTOM

            // Fit within the area or fill it all (true)
            cover: true,
            apply: false,
          },
        );

        createPDFImage(docInfo, {
          x,
          y,
          width,
          height,
          imageInfo,
        });
        resolve();
      }, reject)
      .catch(reject);
  });
