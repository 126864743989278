/** @prettier */
import { versionNotificationsDismissedLocalState } from 'javascripts/helpers/local-state';
import type { IStoryboardInStore } from 'javascripts/types/storyboard';
import { last } from 'underscore';
import { Monitor } from './Monitor';

export interface StoryboardVersionMonitorData {
  url: string;
  currentVersion: number;
  lastVersion: number;
  storyboardId: number;
}

export class StoryboardVersionMonitor extends Monitor<StoryboardVersionMonitorData> {
  init() {
    const storyboard: IStoryboardInStore | undefined =
      StoryboardStore?.getState()?.storyboard;
    if (!storyboard) return;
    const dismissed = versionNotificationsDismissedLocalState.getValue() ?? [];

    if (storyboard.versions.length && dismissed.indexOf(storyboard.id) < 0) {
      const lastVersion = last(storyboard.versions);
      if (
        lastVersion &&
        storyboard.version_number !== lastVersion.version_number
      ) {
        this.notifyListeners({
          url: storyboard.newest_version,
          currentVersion: storyboard.version_number,
          lastVersion: lastVersion.version_number,
          storyboardId: storyboard.id,
        });
      }
    }
  }
}
