/** @prettier */
const createReactClass = require('create-react-class');
const { default: FrameEditorUI } = require('./FrameEditorUI');
const { FrameEditorActions } = require('../../flux/actions/frame_editor');
const { RequestActions } = require('../../flux/actions/request');

module.exports = createReactClass({
  displayName: 'FrameEditorContainer',

  getInitialState: function () {
    return FrameEditorStore.getState();
  },

  componentDidMount: function () {
    FrameEditorStore.listen(this._onChange);
    FrameStore.listen(this._onSavingChange);
  },

  componentWillUnmount: function () {
    FrameEditorStore.unlisten(this._onChange);
    FrameStore.unlisten(this._onSavingChange);
  },

  _onChange: function (state) {
    this.setState(state);
  },

  _onSavingChange: function (state) {
    const newSaveState = state.is_saving;
    if (state.isSaving !== this.state.isSaving) {
      this.setState({ isSaving: newSaveState });
    }
  },

  save(imageData, callback) {
    const savedFrame = this.state.frame;

    if (imageData.isEmpty) {
      // If the editor says the frame is totally empty, run the clear action
      FrameActions.clearFrame(this.state.frame.id);
      if (callback) callback();
    } else {
      // This should maybe happen in the store actions?
      FrameActions.uploadDrawnImage.defer(
        Object.assign({}, imageData, {
          id: this.state.frame.id,
          callback: (success) => {
            if (!success) {
              // If we can't actually offer a reason here (like we can with the actual error popup, this is kinda less useful)
              FrameEditorActions.openDialog({
                title: 'Error saving image',
                message: 'Something went wrong saving your image',
                actions: [
                  {
                    label: 'Retry',
                    buttonType: 'solid',
                    onClick: () => this.save(imageData, callback),
                  },
                  {
                    label: 'Edit',
                    buttonType: 'secondary',
                    onClick: () => {},
                  },
                  {
                    label: 'Discard',
                    buttonType: 'destructive',
                    onClick: () => callback?.(),
                  },
                ],
              });
              imageData.onError();
              return;
            }

            let frameName = savedFrame.number ?? savedFrame.sort_order;
            if (savedFrame.reference) {
              const reference = new DOMParser()
                .parseFromString(savedFrame.reference, 'text/html')
                .body.innerText.trim();
              if (reference) frameName += ` (${reference})`;
            }

            // There are situations (cropping) where we can't optimistically
            // fire this prematurely, so we need to put this callback in this
            // function.
            if (callback) callback();
            RequestActions.success.defer({
              key: 'frameEditor.saved',
              data: { frameName },
            });
          },
        }),
      );
    }
  },

  render() {
    if (!this.state.frame) return null;

    return (
      <FrameEditorUI
        {...this.props}
        {...this.state}
        // These forms of state propagation are kind of smelly. Ideally, we
        // allow the frame editor to update its own state through the store.
        onChangeMode={FrameEditorActions.updateEditingMode.defer}
        onUpdateSaveState={FrameEditorActions.updateSaveState.defer}
        updateHistoryIndex={FrameEditorActions.updateHistoryIndex.defer}
        togglePreview={FrameEditorActions.togglePreview}
        onDropImage={FilestackActions.openWithFile}
        onSave={this.save}
        ref={this.props.componentRef}
      />
    );
  },
});
