/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import { FrameActions } from '../actions/frame';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import * as ShotlistConstants from '../../components/shotlist/ShotlistConstants';
import { StoryboardActions } from '../actions/storyboard';
import * as qs from 'querystringify';
import type {
  IStoryboardInStore,
  FrameField,
  IStoryboard,
  frameAspectRatio,
  IStoryboardPreferences,
  WordCountFromValue,
} from '../../types/storyboard';

import '../actions/frame';
import '../actions/pdf';
import '../actions/comment';
import '../actions/coverpage';
import '../actions/storyboard';
import { LocalState } from '../../helpers/local-state';
import { StoryboardAnalysisActions } from '../actions/storyboardAnalysis';
import { ToursActions, tourEvents } from '../actions/tours';
import { PanelbarActions } from '../actions/panelbar';
import { fetchAndRerenderFrames } from '../../helpers/bulk-render-frames';
import { defaultFrameFields } from '../../helpers/defaultFrameFields';
import logger from 'javascripts/helpers/logger';
import { RequestActions } from '../actions/request';
import { ajax, ajaxRuby } from 'javascripts/helpers/ajax';
import { ensureFrameFieldsHaveIds } from 'javascripts/helpers/storyboard/frameFieldHelpers';
// prettier-ignore
import {
  debounce, includes,isArray,isBoolean,isEmpty,isEqual,isNull,isObject,isString, isUndefined, omit, pluck, values,
} from 'underscore';
import type { GenericCallback } from 'blackbird/helpers/types';
import { IFrame } from 'javascripts/types/frame';
import { removeUnsupportedCharacters } from 'javascripts/helpers/removeUnsupportedCharacters';

interface LoadEventParams {
  frames: IFrame[];
  action: 'load' | 'update';
}

const storyboardErrorHandler = RequestErrorHandler('storyboard');
const { UserPermissionsActions } = require('../actions/user_permissions');

const api = require('../../helpers/api')();

export const ONBOARDING_STORYBOARD_REDIRECT_URL =
  'onboardingStoryboardRedirectUrl';

export type allowedStoryboardViews = 'grid' | typeof ShotlistConstants.SHOTLIST;

export class StoryboardStore extends FakeAltStoreClass<StoryboardStore> {
  storyboard: IStoryboardInStore;
  // For onboarding
  simplified_view = qs.parse(window.location.search).ftu_variant === 'aha';
  view: allowedStoryboardViews;
  showGridViewCommentColumn = false;

  activeIndex = 0;
  editing = true;
  error = false;
  is_fetching = false;
  is_saving = false;
  is_showing_status_message = false;
  lockable = false;
  locked = false;
  timeout?: any;
  new_storyboard_ratio: frameAspectRatio = '16x9';

  frame_fields_version = 1;
  default_frame_fields = defaultFrameFields;

  /** This also determines which preferences are allowed to be used in {@link handleUpdatePreference}  */
  default_preferences: Readonly<Partial<IStoryboardPreferences>> = {
    share_as_animatic: false,
    share_as_grid: false,
    share_with_frame_status: false,
    share_with_version_number: false,
    share_with_version_switching: false,
    show_word_count: false,
    word_count_all_fields: false,
    hide_completed_comments_edit: false,
    hide_completed_comments_present: true,
    show_comment_badge: true,
    show_storyboard_duration: false,
    subtitles_from: 'voiceover',
    include_label_text_in_edit_view: true,
    share_with_version_notification: true,
    share_with_word_count: false,
    word_count_from: undefined,
    charset_override: null,
  };

  constructor() {
    super();

    // BoordsConfig might be undefined in testing conditions
    const canShowShotlist = process.env.NODE_ENV !== 'test';

    this.view = canShowShotlist
      ? LocalState.getValue(ShotlistConstants.LS_MODE_KEY) ?? 'grid'
      : 'grid';

    this.showGridViewCommentColumn =
      canShowShotlist &&
      LocalState.getValue(ShotlistConstants.SHOTLIST_COMMENTS_ENABLE) === true;

    this.bindListeners({
      handleArchiveStoryboard: StoryboardActions.ARCHIVE_STORYBOARD,
      handleToggleViewMode: StoryboardActions.TOGGLE_VIEW_MODE,
      handleArchiveStoryboardVersion:
        StoryboardActions.ARCHIVE_STORYBOARD_VERSION,
      handleDeleteStoryboard: StoryboardActions.DELETE_STORYBOARD,
      handleCreate: StoryboardActions.CREATE,
      handleDuplicate: StoryboardActions.DUPLICATE,
      handleFetch: StoryboardActions.FETCH,
      handleReceive: StoryboardActions.RECEIVE,
      handleRequestComplete: StoryboardActions.REQUEST_COMPLETE,
      handleTrackLoadEvent: StoryboardActions.TRACK_LOAD_EVENT,
      handleRequestStart: [
        StoryboardActions.REQUEST_START,
        FrameActions.BULK_FRAME_UPLOAD,
        FrameActions.SORT_FRAMES,
        FrameActions.INSERT_FRAME,
        FrameActions.UPLOAD_IMAGE,
      ],
      handleRestoreStoryboard: StoryboardActions.RESTORE_STORYBOARD,
      handleSave: StoryboardActions.SAVE,
      handleSetLayout: PdfActions.SET_LAYOUT,
      handleSetStoryboard: StoryboardActions.SET_STORYBOARD,
      handleUpdate: StoryboardActions.UPDATE,
      handleSettingsUpdate: CoverpageActions.UPDATE_VALUE,
      handleVersion: StoryboardActions.VERSION,
      handleSwitchAspectRatio: StoryboardActions.SWITCH_ASPECT_RATIO,
      handleUpdateAndSave: StoryboardActions.UPDATE_AND_SAVE,
      handleUpdatePreference: StoryboardActions.UPDATE_PREFERENCE,
      handleClearProjectCache: StoryboardActions.CLEAR_PROJECT_CACHE,
      handleToggleSimplifiedView: StoryboardActions.TOGGLE_SIMPLIFIED_VIEW,
      handleCopyFrameFields: StoryboardActions.COPY_FRAME_FIELDS,
      handleCommitFrameFields: StoryboardActions.COMMIT_FRAME_FIELDS,
      handleToggleGridViewCommentColumn:
        StoryboardActions.TOGGLE_GRID_VIEW_COMMENT_COLUMN,
      handleUpdateFrameFieldAtIndex:
        StoryboardActions.UPDATE_FRAME_FIELD_AT_INDEX,
      handleReload: StoryboardActions.RELOAD,
    });
  }

  handleClearProjectCache() {
    ajax({
      beforeSend: api.setRailsApiAuthHeader,
      method: 'post',
      dataType: 'json',
      url: api.setGoApiUrl('projects/' + this.storyboard.project.id),
      error: storyboardErrorHandler({
        message: null,
        rollbarMessage: '[GO API ERROR] POST projects/project_id',
      }),
    });
  }

  handleSetLayout(obj) {
    this.storyboard.pdf_layout = obj.layout_name;
  }

  handleSetStoryboard(storyboard) {
    this.handleReceive(storyboard);
    FrameActions.fetchFrames.defer({
      storyboard_id: storyboard.id,
    });
  }

  handleToggleViewMode(view: allowedStoryboardViews) {
    this.view = view;
    LocalState.setValue(ShotlistConstants.LS_MODE_KEY, view);
    if (view === ShotlistConstants.SHOTLIST) {
      ToursActions.triggerEvent.defer(tourEvents.shotListOpened);
    }
    Track.event.defer(`storyboard_toggle_to_${view}`, { category: 'Product' });
  }

  handleSwitchAspectRatio(value) {
    this.new_storyboard_ratio = value;
  }

  handleDuplicate(storyboard) {
    this._callDuplicate({
      storyboard: storyboard,
      method: 'duplicate',
      copyComments: false,
      copyFrameStatuses: false,
    });
  }

  handleVersion(args: {
    storyboard: IStoryboard | IStoryboardInStore;
    copyComments: boolean;
    copyFrameStatuses: boolean;
  }) {
    this._callDuplicate({
      storyboard: args.storyboard,
      method: 'version',
      copyComments: args.copyComments,
      copyFrameStatuses: args.copyFrameStatuses,
    });
  }

  _callDuplicate(args: {
    method?: string;
    storyboard: IStoryboard;
    copyComments?: boolean;
    copyFrameStatuses?: boolean;
  }) {
    var action = isUndefined(args.method) ? 'duplicate' : args.method;

    if (action === 'version') {
      RequestActions.success.defer('Creating new version...');
    } else {
      RequestActions.success.defer('Duplicating storyboard...');
    }

    ajax({
      method: 'post',
      url: '/storyboards/' + args.storyboard.slug + '/' + action + '.json',
      data: {
        copy_comments: args.copyComments,
        copy_frame_statuses: args.copyFrameStatuses,
      },
      success: function (response) {
        location.href = '/storyboards/' + response.slug;

        if (action === 'version') {
          RequestActions.success.defer('Success! Redirecting...');
        } else {
          RequestActions.success.defer('Success! Redirecting...');
        }
      }.bind(this),
      error: storyboardErrorHandler({
        messageKey: 'storyboard.errors.duplicate',
        rollbarMessage: 'Error duplicating storyboard',
      }),
    });
  }

  handleArchiveStoryboard(storyboard: IStoryboard) {
    ajax({
      method: 'post',
      url: '/storyboards/' + storyboard.slug + '/archive.json',
      success: function (response) {
        RequestActions.success.defer('Storyboard archived');
        DashboardActions.fetch.defer();
        if (this.storyboard) {
          this.storyboard.is_archived = true;
        }
        this.emitChange();
      }.bind(this),
      error: storyboardErrorHandler({
        messageKey: 'storyboard.errors.archive',
        rollbarMessage: 'Error archiving storyboard',
      }),
    });
  }

  handleDeleteStoryboard(storyboard: IStoryboard) {
    ajax({
      method: 'delete',
      url: '/storyboards/' + storyboard.slug + '.json',
      success: function () {
        RequestActions.success('Storyboard deleted');
        if (!isNull(this.storyboard)) {
          location.href = '/';
        } else {
          DashboardActions.fetch.defer();
          FlyoverActions.close.defer();
        }
        this.emitChange();
      }.bind(this),
      error: storyboardErrorHandler({
        messageKey: 'storyboard.errors.delete',
        rollbarMessage: 'Error deleting storyboard',
      }),
    });
  }

  handleArchiveStoryboardVersion(storyboard: { slug: string }) {
    ajax({
      method: 'post',
      url: '/storyboards/' + storyboard.slug + '/archive_version.json',
      success: function (response) {
        PanelbarActions.close();
        RequestActions.success(
          'Version archived. Redirecting you to newest version...',
        );
        location.href = '/storyboards/' + response.slug;
      }.bind(this),
      error: storyboardErrorHandler({
        messageKey: 'storyboard.errors.archiveVersion',
        rollbarMessage: 'Error archiving version',
      }),
    });
  }

  handleRestoreStoryboard(storyboard: IStoryboard) {
    ajaxRuby({
      method: 'post',
      url: '/storyboards/' + storyboard.slug + '/restore.json',
      success: () => {
        RequestActions.success.defer('Storyboard restored');
        if (this.storyboard) {
          this.storyboard.is_archived = false;
          this.emitChange();
        } else if (isUndefined(this.storyboard)) {
          // It's possible we're in the settings, in that case, we want to
          // redirect to the dashboard
          window.location.href = '/';
        }

        FlyoverActions.close.defer();
        DashboardActions.fetch.defer();
      },
      error: storyboardErrorHandler({
        messageKey: 'storyboard.errors.restore',
        rollbarMessage: 'Error restoring storyboard',
      }),
    });
  }

  handleCreate(data: {
    documentName: string;
    projectId: number;
    blank_frame_count?: number;
    ftu_variant?: string;
    track_first_storyboard?: boolean;
    redirectTo?: string;
    saveRedirectToLocalStorage?: boolean;
    generateThumbnails?: boolean;
    callbackOnly?: boolean;
    templateId?: string;
    starter_storyboard_id?: number;
    frame_fields?: FrameField[];
    callback?: (success: boolean, data?: any) => void;
  }) {
    ajax({
      method: 'post',
      beforeSend: api.setRailsApiAuthHeader,
      url: api.setRailsApiUrl('storyboards'),
      data: {
        data: {
          type: 'storyboards',
          attributes: omit(
            {
              name: removeUnsupportedCharacters(data.documentName),
              template_id: data.templateId,
              starter_storyboard_id: data.starter_storyboard_id,
              blank_frame_count: data.blank_frame_count,
              track_first_storyboard: data.track_first_storyboard,
              frame_aspect_ratio: isUndefined(data.starter_storyboard_id)
                ? this.new_storyboard_ratio
                : undefined,
              frame_fields: JSON.stringify(data.frame_fields),
            },
            isNull,
          ),
          relationships: {
            project: {
              data: {
                type: 'projects',
                id: data.projectId.toString(),
              },
            },
          },
        },
      },
      success: function (response) {
        const destination =
          typeof data.redirectTo !== 'undefined'
            ? `${response.data.attributes.edit_url}${data.redirectTo}`
            : response.data.attributes.edit_url;

        if (data.generateThumbnails) {
          const redirect = () =>
            data.saveRedirectToLocalStorage
              ? LocalState.setValue(
                  ONBOARDING_STORYBOARD_REDIRECT_URL,
                  destination,
                )
              : RequestActions.redirect.defer(destination);

          fetchAndRerenderFrames({
            storyboardId: parseInt(response.data.id),
            slug: response.data.attributes.short_slug,
          })
            .then(() => {
              redirect();
              data.callback?.(true);
            })
            // This error is already reported inside fetchAndRerenderFrames
            .catch(redirect);
        } else if (data.callbackOnly) {
          data.callback?.(true, response);
        } else {
          RequestActions.redirect.defer(destination);
          data.callback?.(true, response);
        }
      }.bind(this),
      error: storyboardErrorHandler(
        {
          // We leave userMessage blank, because we expect a good error message
          // from the server (like 'storyboard needs to have a name')
          rollbarMessage: 'Error creating storyboard',
        },
        () => data.callback?.(false),
      ),
    });
  }

  handleFetch({
    slug,
    cachebust,
    callback,
  }: {
    slug: string;
    cachebust?: boolean;
    callback?: () => void;
  }) {
    ajax({
      method: 'get',
      url: '/storyboards/' + slug + '.json',
      success: function (response) {
        StoryboardActions.receive(response);
        FrameActions.fetchFrames({
          storyboard_id: response.id,
          cachebust,
          callback,
        });

        this.emitChange();
      }.bind(this),
      error: function (response, textStatus, errorThrown) {
        XhrErrorActions.show.defer({
          status_code: response.status,
          context: 'storyboard:fetch',
          response: response,
        });
      }.bind(this),
    });
  }

  handleReload() {
    const hashid = this.storyboard.slug?.match(/(\w+)(?:\-|$)/)?.[1];

    if (!hashid) {
      storyboardErrorHandler({
        messageKey: 'storyboard.errors.reload',
      })(new Error('Could not extract hashId'));
      return;
    }

    this.handleFetch({ slug: hashid, cachebust: true });
  }

  handleReceive(storyboard: IStoryboard) {
    if (!isObject(storyboard))
      return storyboardErrorHandler()(new Error('unexpected server response'));
    this.storyboard = storyboard as IStoryboardInStore;

    this.receivePreferences(storyboard);

    UserPermissionsActions.fetch.defer({
      userId: storyboard.project.owner.user_id,
    });

    // Ensure all frame fields have frame ids, and save those if they need
    // updating
    this.storyboard.frame_fields = ensureFrameFieldsHaveIds(
      storyboard.frame_fields,
      (fields) => this.handleCommitFrameFields(fields),
    );

    if (!isArray(this.storyboard.frame_fields)) {
      storyboardErrorHandler({
        message: null,
        severity: 'warn',
        rollbarMessage: `frame_fields was not an array`,
      })({ got: this.storyboard.frame_fields });
    }
    this.emitChange();
  }

  handleTrackLoadEvent({ frames, action }: LoadEventParams) {
    try {
      let frames_with_images = 0;
      let frames_with_text = 0;
      let frames_with_status = 0;
      let frames_with_layer_data = 0;
      const invalidFrameText = ['', '<p><br></p>', '<p></p>'];

      const jsonFieldPresent = (data: string | undefined) => {
        return typeof data !== 'undefined' && data !== '{}' && data !== '';
      };

      frames.map((frame) => {
        let frame_has_text = false;
        const emptyFrame = frame.thumbnail_image_url.includes('assets/missing');
        const hasLayerData = jsonFieldPresent(frame.layer_data);
        const hasStatus = jsonFieldPresent(frame.status);

        // Check for text
        if (frame.field_data) {
          Object.keys(frame.field_data).forEach((key) => {
            if (
              frame.field_data &&
              frame.field_data[key] &&
              !invalidFrameText.includes(frame.field_data[key] as string)
            ) {
              frame_has_text = true;
            }
          });
          if (frame_has_text) {
            frames_with_text += 1;
          }
        }

        // Check for images
        if (hasLayerData) {
          frames_with_images += 1;
          frames_with_layer_data += 1;
        } else if (!emptyFrame) {
          frames_with_images += 1;
        }

        // Check for status
        if (hasStatus) {
          frames_with_status += 1;
        }
      });

      Track.event.defer(
        `storyboard_${action}`,
        {
          layout: this.view, // grid or shotlist
          isVersioned: this.storyboard.version_number > 1,
          aspectRatio: this.storyboard.frame_aspect_ratio,
          frames: frames.length,
          frames_with_layer_data: frames_with_layer_data,
          frames_with_images: frames_with_images,
          frames_with_text: frames_with_text,
          frames_with_status: frames_with_status,
          frame_fields: this.storyboard.frame_fields.length,
          versions: this.storyboard.versions.length,
          hasCommentsEnabled: this.storyboard.has_comments_enabled,
          commentEmails: this.storyboard.is_sending_comment_emails,
          isArchived: this.storyboard.is_archived,
          showDuration: this.storyboard.preferences?.show_storyboard_duration,
          showWordCount: this.storyboard.preferences?.show_word_count,
          category: 'Product',
        },
        action === 'load' ? 1000 : 0,
      );
    } catch (e) {
      logger.error(e);
    }
  }

  /** Applies new `storyboard.preferences` and frame fields, and merging them
   * with default values if necessary */
  receivePreferences(storyboard: IStoryboard) {
    if (
      isUndefined(storyboard.preferences?.share_with_version_number) &&
      storyboard.preferences?.share_with_version_switching === true
    ) {
      logger.log(
        'Setting `share_with_version_number` to true to match existing `share_with_version_switching` setting',
      );
      storyboard.preferences.share_with_version_number = true;
      this.handleUpdatePreference({
        name: 'share_with_version_number',
        value: true,
      });
      this.emitChange();
    }

    if (
      isUndefined(storyboard.preferences?.word_count_from) &&
      !isEmpty(storyboard.preferences)
    ) {
      let value: WordCountFromValue = 'voiceover';
      if (!storyboard.preferences?.show_word_count) {
        value = null;
      } else if (storyboard.preferences.word_count_all_fields) {
        value = 'all';
      }

      logger.log(
        `setting \`word_count_from\` to '${value}' to migrate from previous settings`,
      );

      this.handleUpdatePreference({
        name: 'word_count_from',
        value,
      });
    }

    this.storyboard.preferences = {
      ...this.default_preferences,
      ...(storyboard.preferences || {}),
    };
  }

  handleUpdate(data: { name: keyof IStoryboardInStore; value: any }) {
    if (!this.storyboard)
      // This happens when we're updating a storyboard's password from the
      // dashboard
      return logger.log(
        "cannot update storyboard field, we don't have a storyboard",
      );

    this.storyboard[data.name as any] = data.value;
    // this.is_saving = true;
    this.emitChange();
  }

  handleUpdatePreference<T>(data: {
    name: keyof IStoryboardPreferences;
    value: any;
    callback?: GenericCallback;
  }) {
    if (Object.keys(this.default_preferences).indexOf(data.name) < 0)
      throw new Error(`The preference key ${data.name} is not allowed`);

    this.storyboard.preferences = {
      ...this.storyboard.preferences,
      [data.name]: data.value,
    };
    this.is_saving = true;
    this.emitChange();

    if (data.name === 'show_word_count')
      StoryboardAnalysisActions.scheduleAnalysis.defer();

    this.handleSave({ notificationType: 'none' });
  }

  handleSettingsUpdate(data: { attr: string; value: any }) {
    if (data.attr == 'storyboard.include_frame_number_in_pdf') {
      this.storyboard.include_frame_number_in_pdf = data.value;
    }
    if (data.attr == 'storyboard.use_high_res_images_in_pdf') {
      this.storyboard.use_high_res_images_in_pdf = data.value;
    }
    if (data.attr == 'storyboard.include_icons_in_pdf') {
      this.storyboard.include_icons_in_pdf = data.value;
    }
    if (data.attr == 'storyboard.include_label_text_in_output') {
      this.storyboard.include_label_text_in_output = data.value;
    }
    this.emitChange();
  }

  handleSave(
    {
      notificationType,
      callback,
    }: {
      notificationType?: 'storyboardStatus' | 'requestActions' | 'none';
      callback?: GenericCallback;
    } = {
      notificationType: 'requestActions',
    },
  ) {
    const JSONForDefaultField = (currentValues, defaultValues) =>
      JSON.stringify(
        isEqual(currentValues, defaultValues) ? null : currentValues,
      );

    this.is_saving = true;

    const { password, ...storyboardWithoutPassword } = this.storyboard;

    ajaxRuby({
      method: 'patch',
      url: '/storyboards/' + this.storyboard.slug + '.json',
      data: {
        // Extend the storyboard object with the preferences
        storyboard: {
          ...storyboardWithoutPassword,
          document_name: removeUnsupportedCharacters(
            storyboardWithoutPassword.document_name,
          ),
          frame_fields: JSONForDefaultField(
            this.storyboard.frame_fields,
            this.default_frame_fields,
          ),
          preferences: JSONForDefaultField(
            this.storyboard.preferences,
            this.default_preferences,
          ),
        },
      },
      success: (response) => {
        this.storyboard.slug = response.slug;
        this.storyboard.versions = response.versions;
        this.receivePreferences(response);
        this.is_saving = false;
        this.emitChange();

        if (notificationType !== 'none') {
          RequestActions.success.defer('Changes saved');
        }
        callback?.(true);
      },
      error: storyboardErrorHandler(
        {
          // We leave userMessage blank, because we expect a good error message
          // from the server (like 'storyboard needs to have a name')
          rollbarMessage: 'Error saving storyboard',
        },
        () => {
          this.is_saving = false;
          this.emitChange();
          callback?.(false);
        },
      ),
    });
  }

  _debouncedSave: typeof this.handleSave = debounce(
    this.handleSave.bind(this),
    1000,
  );

  /**
   * submits changes to the server without affecting this store's state.
   * Allows you to send updates from contexts where the storyboardStore is
   * not populated
   */
  handleUpdateAndSave(changeSet: Partial<IStoryboard> & { silent?: boolean }) {
    if (!changeSet.slug) throw new Error('changeset needs an slug');

    const { silent, slug, ...changes } = changeSet;
    const storyboardSlug = slug === 'current' ? this.storyboard.slug : slug;

    ajax({
      method: 'patch',
      url: '/storyboards/' + storyboardSlug + '.json',
      data: {
        storyboard: changes,
      },
      success: () => {
        if (!silent) RequestActions.success('Changes saved');
      },
      error: storyboardErrorHandler({
        rollbarMessage: 'Error saving changes',
      }),
    });
  }

  handleRequestComplete() {
    this.is_showing_status_message = true;
    this.is_saving = false;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(
      function () {
        this.is_showing_status_message = false;
        this.emitChange();
      }.bind(this),
      3000,
    );
  }

  handleRequestStart() {
    this.is_showing_status_message = true;
    this.is_saving = true;
    clearTimeout(this.timeout);
  }

  handleToggleSimplifiedView(newValue) {
    this.simplified_view = isBoolean(newValue)
      ? newValue
      : !this.simplified_view;
  }

  handleCommitFrameFields(newFrameFields: FrameField[]) {
    this.storyboard.frame_fields = newFrameFields.map((field) => ({
      id: field.id,
      isEnabled: field.isEnabled,
      ...omit(field, (propValue, k) => propValue === '' || k === 'placeholder'),
    }));

    const preferences = this.storyboard.preferences ?? {
      ...this.default_preferences,
    };
    const fieldIds = pluck(this.storyboard.frame_fields, 'id');
    const wordCountFrom = preferences.word_count_from;

    // If the field we reference in `word_count_from` doesn't exist
    if (
      !isUndefined(wordCountFrom) &&
      wordCountFrom !== 'all' &&
      !includes(fieldIds, wordCountFrom)
    ) {
      // We can't leave the reference nonexistent, so we set it to all and turn
      // off the setting so the user is reminded to turn it on.
      preferences.word_count_from = 'all';
      preferences.show_word_count = false;

      logger.log(
        'Field used for word counting was deleted, resetting word count settings…',
      );
    }

    const subtitlesFrom = preferences.subtitles_from;
    // If the field we reference in `subtitles_from` doesn't exist
    if (!isUndefined(subtitlesFrom) && !includes(fieldIds, subtitlesFrom)) {
      // We can't leave the reference nonexistent, so we set it to reference
      preferences.subtitles_from = 'reference';

      logger.log(
        'Field used for subtitles was deleted, resetting subtitle settings…',
      );
    }

    this._debouncedSave({ notificationType: 'storyboardStatus' });
  }

  handleCopyFrameFields(slug: string) {
    if (!slug) return;
    if (!isString(slug)) throw new Error('slug must be a string');

    ajax({
      url: `/storyboards/${slug}.json`,
      beforeSend: api.setRailsApiAuthHeader,
    })
      .then((response) => {
        const result = response.frame_fields;
        if (!result) {
          if (
            confirm(
              `The storyboard ${response.document_name} does not have any frame fields. Do you want to continue? (this will clear the current fields)`,
            )
          ) {
            this.storyboard.frame_fields = this.default_frame_fields;
          }
        } else {
          this.storyboard.frame_fields = isArray(result)
            ? result
            : values(result);
        }

        this.frame_fields_version++;
        this.emitChange();
        this.handleSave();
        RequestActions.success('Frame fields copied!');
      })
      .catch(
        storyboardErrorHandler({
          messageKey: 'storyboard.errors.copyFrameFields',
        }),
      );
  }

  handleUpdateFrameFieldAtIndex({
    index,
    changeset,
    commit = false,
  }: {
    index: number;
    changeset: Partial<FrameField>;
    commit: boolean;
  }) {
    if (!changeset) throw new Error('Needs changeset');
    const newFrameFields = [...this.storyboard.frame_fields];

    newFrameFields[index] = {
      ...newFrameFields[index],
      ...changeset,
    };

    this.storyboard.frame_fields = newFrameFields;
    if (commit) this._debouncedSave();
  }

  handleToggleGridViewCommentColumn() {
    this.showGridViewCommentColumn = !this.showGridViewCommentColumn;
    LocalState.setValue(
      ShotlistConstants.SHOTLIST_COMMENTS_ENABLE,
      this.showGridViewCommentColumn,
    );
  }
}

(window as any).StoryboardStore = alt.createStore(
  StoryboardStore,
  'StoryboardStore',
);
