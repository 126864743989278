/*
 * @prettier
 */
import type { CommentSection, Comment } from 'javascripts/flux/stores/comment';
import type { DetailedFrame } from 'javascripts/types/frame';
import type { IStoryboardInStore } from 'javascripts/types/storyboard';
import { notNullOrUndefined } from './notUndefined';
import { removeHtmlAddNewlines } from './removeHtmlAddNewlines';

const _ = require('underscore');

export function frameCommentsToText(section?: CommentSection): string {
  const output: string[] = [];

  function printComment(comment: Comment, indent = '') {
    const text = removeHtmlAddNewlines(comment.text);

    const replyIndicator = comment.in_reply_to_comment_id ? '↪ ' : '';
    output.push(
      `${indent}${replyIndicator}${comment.user.name}:\n${indent}${
        ' '.repeat(replyIndicator.length) + text
      }`,
    );

    if (comment.replies) {
      comment.replies.forEach((c) => printComment(c, '  '));
    }

    output.push('');
  }

  if (!section) return '';
  section.comments?.forEach((c) => printComment(c));
  return output.slice(0, -1).filter(notNullOrUndefined).join('\n');
}

export function CommentsTextOutput(
  frames,
  commentSections: Record<string, CommentSection>,
  storyboard: IStoryboardInStore,
  { protocol, host },
) {
  const output = [
    `# ${storyboard.document_name} comments`,
    ' ',
    `${protocol}//${host}/storyboards/${storyboard.slug}`,
    '',
    ' ',
  ].concat(
    frames.map((frame, frameIndex) => {
      const output: string[] = [];
      const section = commentSections[frame.id];

      if (!section || section.comments.length === 0) {
        output.push('(No comments)');
      } else {
        output.push(frameCommentsToText(section));
      }

      return ['---', ' ', 'Frame ' + (frameIndex + 1), ' ', output, ' '];
    }),
  );

  return _.compact(_.flatten(output)).join('\n');
}
