/** @prettier */

import React, { type FC } from 'react';
import {
  labelsToSpace,
  BadgeLabelColors,
  type BadgeSize,
  type BadgeColor,
} from './types';
import classNames from 'classnames';

interface BadgeProps {
  className?: string;
  color?: BadgeColor;
  onClick?: (e: any) => void;
  size?: BadgeSize;
  icon?: React.ReactNode;
  label: string | React.ReactNode;
}

const backgrounds: { [key in BadgeColor]: string } = {
  white: 'bg-action-secondary',
  green: 'bg-surface-success/30',
  blue: 'bg-decorative-blue',
  pink: 'bg-decorative-pink',
  pinkBorder: 'bg-decorative-pink',
  yellow: 'bg-surface-warning',
  yellowToPink: 'bg-gradient-to-r from-brand-yellow/50 to-brand-pink/50',
  violet: 'bg-decorative-violet',
};

const paddings: { [key in BadgeSize]: string } = {
  xl: 'px-3',
  lg: 'px-2.5',
  sm: 'px-[0.4rem]',
};

const fonts: { [key in BadgeSize]: string } = {
  xl: 'text-sm leading-5',
  lg: 'text-xs leading-5',
  sm: 'text-xxs',
};

const heights: { [key in BadgeSize]: string } = {
  xl: 'pb-1.5 pt-1',
  lg: 'h-6 pb-0.5',
  sm: 'pb-0.5 pt-[0.1rem]',
};

const setColorFromLabel = (label: string, color: BadgeColor) =>
  BadgeLabelColors[label.toLowerCase()] !== undefined
    ? BadgeLabelColors[label.toLowerCase()]
    : color;

const dynamicLabelText = (label: string) =>
  labelsToSpace.includes(label)
    ? `Professional ${label.split('professional')[1]}`
    : label;

const Badge: FC<BadgeProps> = (props) => {
  const {
    className,
    color = 'white',
    size = 'lg',
    label,
    onClick,
    icon,
  } = props;
  // Set in ./types
  // This means specific labels (e.g. owner, admin, new) always
  // have the same color wherever they are used.
  const bgColor =
    typeof label === 'string' ? setColorFromLabel(label, color) : color;
  // Set add spaces to professionallarge slugs
  const sanitizedLabel =
    typeof label === 'string' ? dynamicLabelText(label) : label;

  return (
    <div
      onClick={onClick}
      className={classNames(
        'inline-flex items-center justify-center rounded-full font-normal',
        color === 'pinkBorder' && 'border border-brand-pink',
        backgrounds[bgColor],
        paddings[size],
        heights[size],
        className,
      )}
    >
      {icon && <span className="mr-1">{icon}</span>}
      <span className={classNames('inline-block', fonts[size])}>
        {sanitizedLabel}
      </span>
    </div>
  );
};

export default Badge;
