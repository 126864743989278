/**
 * @prettier
 */
const createReactClass = require('create-react-class');
const { Button } = require('../shared/Button');
const PropTypes = require('prop-types');

module.exports = createReactClass({
  displayName: 'Overlay',

  propTypes: {
    onClose: PropTypes.func /** add this to make the overlay closable */,
    message: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
    width: PropTypes.number,
  },

  getDefaultProps: function() {
    return {
      title: "Are you sure?",
    };
  },

  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('keyup', this.onKeyup, { capture: true });
    }, 500)
  },

  componentWillUnmount() {
    window.removeEventListener('keyup', this.onKeyup,  { capture: true });
  },

  onKeyup(e) {
    if (e.key === 'Escape') this.handleTriggerClose(e);
  },

  handleTriggerClose(e) {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
      e.preventDefault();
    }
  },

  render: function() {
    const {
      message,
      width,
      title,
      onClose,
      children,
    } = this.props;

    return ReactDOM.createPortal(

        <div className="fixed top-0 left-0 w-100 h-100 z-999 flex items-center justify-center">
          <div
            className="bg-black o-30 fixed top-0 left-0 w-100 h-100 z-0"
            onClick={this.handleTriggerClose}
          />
          <div
            className="bg-white pv4 ph4 br3 relative z-1 mw6 w6 w-$"
            style={{ width: width }}
          >
            {onClose && (
              <div className="absolute top-2 right-2 mt1">
                <Button
                  icon="cross-small"
                  text="Close"
                  size="medium"
                  preset="transparent"
                  iconSourceSize={12}
                  onClick={this.handleTriggerClose}
                  iconOnly
                  noPadding
                />
              </div>
            )}
            <div className="tl avenir-heavy mb2 f3 lh-copy">
              {title}
            </div>
            {message && (
              <div
                className="tl f4 silver lh-copy"
                dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br/>') }}
              />
            )}
            {children && (
              <div className="mt4">{children}</div>
            )}
          </div>
        </div>
    , document.body)
  },
});
