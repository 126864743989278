/** @prettier */
import { type TourStepTokens, type TourBlueprint } from './tourData';

const tourSteps = [
  'wizardNewStoryboardToggle',
  'wizardNewStoryboardInput',
] as const;

export type wizardNewStoryboardTourAvailableSteps = typeof tourSteps[number];

const tokens: TourStepTokens = {
  name: 'Generate a new storyboard',
  count: 5,
};

export const wizardNewStoryboardTour: TourBlueprint<wizardNewStoryboardTourAvailableSteps> =
  {
    name: 'wizardNewStoryboard',
    allowRestart: true,
    steps: [
      {
        name: 'wizardNewStoryboardToggle',
        header: `Enable AI Assistant`,
        body: `Our AI Assistant will take your storyboard prompt and generate a complete script with sound, voiceover, and action notes.`,
        tokens,
      },
      {
        name: 'wizardNewStoryboardInput',
        header: `Enter Storyboard Subject`,
        body: `Enter the theme for your storyboard, e.g. 'outdoor fashion brand video'. When you're done, click 'Generate' to see a generate a complete storyboard script!`,
        tokens,
      },
    ],
  };
