const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const PureRenderMixin = require('react-addons-pure-render-mixin');

window.Input = createReactClass({
  displayName: 'Input',

  propTypes: {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    children: PropTypes.node,
    showLabel: PropTypes.bool
  },

  mixins: [PureRenderMixin],

  getDefaultProps() {
    return {
      type: 'text',
      value: '',
      inputClassName: 'form__input',
      showLabel: true
    }
  },

  handleChange(e) {
    this.props.onChange({
      [this.props.name]: e.target.value
    }, e)
  },

  render() {
    const {
      label, className, children, inputClassName, showLabel, ...rest
    } = this.props;

    const id = this.props.id || this.props.name + 'Input';

    return (
      <div className={className}>
        { showLabel && <label htmlFor={id}>{label}</label> }
        <input
          {...rest}
          type={this.props.type}
          aria-label={label}
          value={this.props.value}
          onChange={this.handleChange}
          className={inputClassName}
          name={this.props.name}
          id={id}
        />
        {children}
      </div>
    )
  }
})
