/** @format */

import { RequestActions } from 'javascripts/flux/actions/request';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from '../toggle/Toggle';

interface ILogoToggleProps {
  id: number;
  isEnabled?: boolean;
}

export const LogoToggle: React.FC<ILogoToggleProps> = ({
  id,
  isEnabled = false,
}): React.ReactElement => {
  const { t } = useTranslation();
  const [enabled, setEnabled] = React.useState(true);

  React.useEffect(() => {
    setEnabled(isEnabled);
  }, [isEnabled]);

  const handleLinkToggle = async () => {
    const response = await fetch(`/api/teams/${id}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-KEY': BoordsConfig.AuthenticationToken,
      },
      body: JSON.stringify({
        data: {
          attributes: {
            use_logo_on_edit: !enabled,
          },
        },
      }),
    });
    if (response.ok) {
      setEnabled(!enabled);
      RequestActions.success(t(`common.logo_toggle.notices.toggle_success`));
    } else {
      RequestActions.error(t(`common.logo_toggle.notices.toggle_error`));
    }
  };

  return (
    <div className="flex items-center my-4 space-x-2">
      <Toggle value={enabled} onChange={() => handleLinkToggle()} />
      <span className="-mt-1">
        {t(`common.logo_toggle.${enabled ? 'enabled' : 'disabled'}`)}
      </span>
    </div>
  );
};
(window as any).LogoToggle = LogoToggle;
