/** @format */
import { type SavedFontPreferences } from '../../../types/storyboard';
import type { FontSettings } from '../types';
import { StoryboardActions } from '../../../flux/actions/storyboard';
import logger from 'javascripts/helpers/logger';

// To get these values, you can go to FontPicker's onFontChange function,
// log the output of `files` and copy the returned object
const defaults: SavedFontPreferences = {
  latin: {
    family: 'Source Sans 3',
    src: '//fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Ky461EN_io6npfB.ttf',
    normal:
      '//fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Ky461EN_io6npfB.ttf',
    italic:
      '//fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqLlO9C4Ym4fB3Ts.ttf',
    bold: '//fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kxf7FEN_io6npfB.ttf',
    bolditalic:
      '//fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqF5J9C4Ym4fB3Ts.ttf',
    fetched: true,
    slug: 'sourcesans3',
  },
  cyrillic: {
    family: 'Source Sans 3',
    src: '//fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Ky461EN_io6npfB.ttf',
    normal:
      '//fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Ky461EN_io6npfB.ttf',
    italic:
      '//fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqLlO9C4Ym4fB3Ts.ttf',
    bold: '//fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kxf7FEN_io6npfB.ttf',
    bolditalic:
      '//fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqF5J9C4Ym4fB3Ts.ttf',
    fetched: true,
    slug: 'sourcesans3',
  },
  arabic: {
    family: 'Cairo',
    slug: 'Cairo',
    fetched: true,
    src: '//fonts.gstatic.com/s/cairo/v10/SLXGc1nY6HkvamImRJqExst1.ttf',
    normal: '//fonts.gstatic.com/s/cairo/v10/SLXGc1nY6HkvamImRJqExst1.ttf',
    bold: '//fonts.gstatic.com/s/cairo/v10/SLXLc1nY6Hkvalqaa76M7dd8aGZk.ttf',
  },
  hebrew: {
    family: 'Rubik',
    slug: 'Rubrik',
    fetched: true,
    src: '//fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UE80V4bVkA.ttf',
    normal:
      '//fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UE80V4bVkA.ttf',
    italic:
      '//fonts.gstatic.com/s/rubik/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0UwdYPFkJ1O.ttf',
    bold: '//fonts.gstatic.com/s/rubik/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUwdYPFkJ1O.ttf',
    bolditalic:
      '//fonts.gstatic.com/s/rubik/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUwdYPFkJ1O.ttf',
  },
  japanese: {
    family: 'M PLUS 1p',
    slug: 'MPlus1p',
    fetched: true,
    src: '//fonts.gstatic.com/s/mplus1p/v19/e3tjeuShHdiFyPFzBRro-D4Ec2jKqw.ttf',
    normal:
      '//fonts.gstatic.com/s/mplus1p/v19/e3tjeuShHdiFyPFzBRro-D4Ec2jKqw.ttf',
    bold: '//fonts.gstatic.com/s/mplus1p/v19/e3tmeuShHdiFyPFzBRrQRBEge0PWovdU4w.ttf',
  },
  korean: {
    family: 'Nanum Gothic',
    slug: 'NanumGothic',
    fetched: true,
    src: '//fonts.gstatic.com/s/nanumgothic/v17/PN_3Rfi-oW3hYwmKDpxS7F_z_tLfxno73g.ttf',
    normal:
      '//fonts.gstatic.com/s/nanumgothic/v17/PN_3Rfi-oW3hYwmKDpxS7F_z_tLfxno73g.ttf',
    bold: '//fonts.gstatic.com/s/nanumgothic/v17/PN_oRfi-oW3hYwmKDpxS7F_LQv37zlEn14YEUQ.ttf',
  },
  khmer: {
    family: 'Noto Sans Khmer',
    slug: 'NotoSansKhmer',
    fetched: true,
    src: '//fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYuNAJz4kAbrddiA.ttf',
    normal:
      '//fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYuNAJz4kAbrddiA.ttf',
    bold: '//fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYtqB5z4kAbrddiA.ttf',
  },
};

const getUserPreferences = () =>
  StoryboardStore?.getState().storyboard?.font_preference || {};

export const getFontForSubset = (subset: string): FontSettings => {
  if (!defaults[subset]) logger.log('No default font for ' + subset);
  const preference: FontSettings | undefined = getUserPreferences()?.[subset];

  // We need to make sure the saved font preference has the values we're
  // looking for. This has changed since september 2021
  if (preference?.normal) return preference;

  return defaults[subset];
};

export const saveFontPreference = (subset: string, font: FontSettings) => {
  const preferences = getUserPreferences();

  // Prevent duplicate requests
  if (preferences[subset] !== font) {
    // If we end up mutating something locally here, that is fine
    preferences[subset] = font;
    StoryboardActions.updateAndSave.defer({
      slug: 'current',
      font_preference: JSON.stringify(preferences) as any,
      silent: true,
    });
  } else {
    logger.log('ignore update');
  }
};
