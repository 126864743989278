/** @prettier */
import * as React from 'react';
import { useStore } from '../../helpers/useStore';
import type { IStoryboardInStore } from '../../types/storyboard';
import type { StoryboardStore } from '../../flux/stores/storyboard';
import type { IFrame } from '../../types/frame';
import type { panelTypes, PanelbarStore } from '../../flux/stores/panelbar';
import { useIntercomContext } from '../../helpers/useIntercomContext';
import { Button } from '../shared/Button';
import { PanelbarActions } from '../../flux/actions/panelbar';
import { ScriptEditorPane } from './scriptEditor/ScriptEditorPane';
import { PanelbarActivity } from './PanelbarActivity';
import { activityCutoffDate } from '../../flux/stores/ActivityLog';
import { PanelbarImageLibrary } from './PanelbarImageLibrary';
import { ErrorBoundary } from '../shared/ErrorBoundary';
import PanelbarSettings from './PanelbarSettings';
import Tabs from 'blackbird/components/tabs/Tabs';
import type { TabProps } from 'blackbird/components/tabs/types';
import CommentIcon from 'blackbird/images/icons/comment-sidebar.svg';
import ImageIcon from 'blackbird/images/icons/image.svg';
import SettingsIcon from 'blackbird/images/icons/settings-sidebar.svg';
import ScriptEditorIcon from 'blackbird/images/icons/script-editor.svg';
import ActivityIcon from 'blackbird/images/icons/activity.svg';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { notUndefined } from 'javascripts/helpers/notUndefined';

const handleSetPanelbarType = (e: React.MouseEvent<HTMLButtonElement>) =>
  PanelbarActions.open.defer(e.currentTarget.name as panelTypes);
const handleTabSelect = (type: string) => {
  PanelbarActions.open.defer(type as panelTypes);
};
const PanelBarTabButton: React.FC<{
  name: panelTypes;
  icon?: string;
  currentPanel?: string;
  label: string;
  labelLocation?: string;
  disabled?: boolean;
  forbidden: panelTypes[];
}> = (props) =>
  props.forbidden.indexOf(props.name) < 0 ? (
    <Button
      icon={props.icon ?? props.name}
      preset={
        props.currentPanel === props.name
          ? 'transparent'
          : 'transparentWithHover'
      }
      text={props.label}
      name={props.name}
      size="menu"
      onClick={handleSetPanelbarType}
      disabled={props.disabled}
      labelLocation={props.labelLocation as any}
      noPadding
      iconOnly
    />
  ) : null;
const StoryboardPanelbarContainer: React.FC = () => {
  const { t } = useTranslation();
  const storyboard = useStore<IStoryboardInStore, StoryboardStore>(
    'storyboard',
    (store) => store.storyboard,
  );
  const frames = useStore<IFrame[]>('frame', (store) => store.frames);
  const isClosed = useStore<boolean, PanelbarStore>(
    'panelbar',
    (store) => !store.isOpen,
  );

  const forbiddenTypes = useStore<panelTypes[], PanelbarStore>(
    'panelbar',
    (store) => store.forbiddenTypes,
  );

  const panelType = useStore<panelTypes | undefined, PanelbarStore>(
    'panelbar',
    (store) => store.panelType,
  );

  const canShow = storyboard && !isClosed;
  useIntercomContext('sidebar.settings.panelbar', canShow);

  React.useEffect(() => {
    // We only want to call init when we're opening
    if (!isClosed) PanelbarActions.init.defer();
  }, [isClosed]);

  if (!canShow) return null;

  let contents: React.ReactNode;

  switch (panelType) {
    case 'scriptEditor':
      contents = <ScriptEditorPane frame_fields={storyboard.frame_fields} />;
      break;
    case 'comments':
      contents = (
        <PanelbarCommentsContainer
          type="editableCommentList"
          storyboard={storyboard}
          frames={frames}
          canManage={true}
        />
      );
      break;
    case 'activity':
      contents = <PanelbarActivity storyboard={storyboard} />;
      break;
    case 'imageLibrary':
      contents = (
        <PanelbarImageLibrary
          frames={frames}
          storyboard={storyboard}
          hasFrameFocus={false}
        />
      );
      break;
    case 'settings':
      contents = <PanelbarSettings />;
      break;
    default:
      contents = null;
  }

  const activityLogEnabled =
    new Date(storyboard.created_at) > activityCutoffDate;

  const tabs: TabProps<panelTypes>[] = [
    {
      key: 'comments',
      disabled: forbiddenTypes.indexOf('comments') >= 0,
      caption: <CommentIcon />,
      title: t('sidebar.settings.comments.tab_title'),
    },
    forbiddenTypes.indexOf('scriptEditor') < 0
      ? {
          key: 'scriptEditor',
          caption: <ScriptEditorIcon />,
          title: t('sidebar.settings.script_editor.tab_title'),
        }
      : undefined,
    {
      key: 'imageLibrary',
      disabled: forbiddenTypes.indexOf('imageLibrary') >= 0,
      caption: <ImageIcon />,
      title: t('imageLibrary.tab_title'),
    },
    {
      key: 'settings',
      disabled: forbiddenTypes.indexOf('settings') >= 0,
      caption: <SettingsIcon />,
      title: t('sidebar.settings.storyboard.tab_title'),
    },
    {
      key: 'activity',
      caption: <ActivityIcon />,
      disabled: !activityLogEnabled,
      title: activityLogEnabled
        ? t('sidebar.settings.activity.tab_title_enabled')
        : t('sidebar.settings.activity.tab_title_disabled', {
            date: format(activityCutoffDate, 'MMMM dd, yyyy'),
          }),
    },
  ].filter(notUndefined) as TabProps<panelTypes>[];

  return (
    <div className="flex flex-col flex-grow bottom-banner-spacing">
      {/* Currently the only way to make headlessUI tab controlled is by
      using a key to do the re-render */}
      <Tabs
        key={panelType}
        tabs={tabs}
        defaultValue={panelType}
        onSelect={handleTabSelect}
        className="sticky top-0 z-50 bg-white"
        tabClassName="no-padding h-24"
        overflowScroll
      />
      <ErrorBoundary size="small">{contents}</ErrorBoundary>
    </div>
  );
};

const memo = React.memo(StoryboardPanelbarContainer);

export { memo as StoryboardPanelbarContainer };
