/** @prettier */
import { Monitor } from './Monitor';

/** Will return true if online */
export class ConnectionMonitor extends Monitor<boolean> {
  init() {
    const onChange = e => {
      this.notifyListeners(e.type === 'online');
    };
    window.addEventListener('offline', onChange, false);
    window.addEventListener('online', onChange, false);

    this.notifyListeners(navigator.onLine);
  }
}
