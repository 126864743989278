/** @prettier */
import * as React from 'react';
import Button from 'blackbird/components/button/Button';
import { IconButton } from 'blackbird/components/common/IconButton';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { EmptyState } from 'blackbird/components/feedback/empty-state/EmptyState';
import * as Tip from '../../shared/Tip';
import { generateDocx } from 'javascripts/components/storyboard/ExportScript/generateDocx';
import { ScriptEditorActions } from 'javascripts/flux/actions/scripteditor';
import { PanelbarActions } from 'javascripts/flux/actions/panelbar';
import type {
  ScriptEditorFilteredData,
  ScriptEditorStore,
} from 'javascripts/flux/stores/scripteditor';
import { constructFilename } from 'javascripts/helpers/constructFilename';
import { openLightbox } from 'javascripts/helpers/lightbox';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import { scriptEditorUndoManager } from 'javascripts/helpers/undo-stacks';
import type {
  DetailedFrame,
  frameFieldId,
  IFrame,
} from 'javascripts/types/frame';
import type { FrameField } from 'javascripts/types/storyboard';
import { useTranslation } from 'react-i18next';
import { FilterButtons } from './FilterButtons';
import { FilteredScriptEditor } from './FilteredScriptEditor';
import { ScriptEditorFrameFields } from './ScriptEditorFrameFields';
import { useStore } from 'javascripts/helpers/useStore';
import type { FrameStore as FrameStoreType } from 'javascripts/flux/stores/frame';
import * as saveAs from 'file-saver';
import DownloadIcon from 'blackbird/images/icons/download.svg';
import fontLoader from '../../../helpers/font-loader';
import { downloadEvent } from 'blackbird/helpers/eventContextHelper';
import type { StoryboardStore as StoryboardStoreType } from 'javascripts/flux/stores/storyboard';
import Toast from 'blackbird/components/feedback/toast/Toast';

const handleScriptVideoClick: React.MouseEventHandler = (e) => {
  e.preventDefault();
  openLightbox('https://vimeo.com/270081060');
};

const handleCloseClick = () => {
  ScriptEditorActions.close();
  PanelbarActions.close();
};

const errorHandler = RequestErrorHandler('docxGenerator');

interface Props {
  frame_fields: FrameField[];
}

export const ScriptEditorPane = React.memo<Props>((props) => {
  const frames = useStore<DetailedFrame[] | undefined, ScriptEditorStore>(
    'ScriptEditor',
    (f) => f.frames,
  );

  const filter = useStore<frameFieldId | null, ScriptEditorStore>(
    'ScriptEditor',
    (s) => s.filter,
  );
  const hasChanges = useStore<boolean, ScriptEditorStore>(
    'ScriptEditor',
    (s) => s.hasChanges,
  );
  const isSaving = useStore<boolean, ScriptEditorStore>(
    'ScriptEditor',
    (s) => s.isSaving,
  );
  const filteredEditorData = useStore<
    ScriptEditorFilteredData | null,
    ScriptEditorStore
  >('ScriptEditor', (s) => s.filteredEditorData);

  const referenceFieldIsVisible = useStore<boolean, StoryboardStoreType>(
    'storyboard',
    (s) => s.storyboard.preferences?.include_label_text_in_edit_view ?? true,
  );

  const { t } = useTranslation();
  const filteredFieldIndex = props.frame_fields.findIndex(
    (f) => f.id === filter,
  );

  React.useEffect(() => {
    // We want to load italic as well, so this string gets a bit too complicated
    // for our default font loading code.
    fontLoader.load('Source+Code+Pro:ital,wght@0,500;0,700;1,500;1,700');
    Track.once.defer('script editor opened');
    ScriptEditorActions.init.defer();
    // We don't really use undo here, but like this we block the storyboard
    // from receiving undo events.
    scriptEditorUndoManager.enterContext();

    return () => {
      ScriptEditorActions.close.defer();
      scriptEditorUndoManager.leaveContext();
    };
  }, []);

  const handleExport = React.useCallback<React.MouseEventHandler>((e) => {
    e.preventDefault();
    const storyboard = StoryboardStore.getState().storyboard;
    generateDocx(
      StoryboardStore.getState().storyboard,
      FrameStore.getState().frames,
    )
      .then((blob) => {
        saveAs(
          blob,
          constructFilename({
            storyboard: storyboard,
            extension: 'docx',
          }),
        );
        downloadEvent('docx');
      })
      .catch(errorHandler());
  }, []);

  return !frames ? (
    <LoadingIndicator
      text={t('sidebar.settings.script_editor.loading_frames')}
      pad
    />
  ) : frames.length === 0 ? (
    <div className="p-6">
      <EmptyState title={t('sidebar.settings.script_editor.no_frames')}>
        {t('sidebar.settings.script_editor.create_frames_msg')}
      </EmptyState>
      <div className="mt-9">
        <Tip
          text={t('sidebar.settings.script_editor.learn_script_editor')}
          linkText={t('sidebar.settings.script_editor.watch_video')}
          linkEvent={handleScriptVideoClick}
        />
      </div>
    </div>
  ) : (
    <div className="flex flex-col flex-auto">
      <div className="pb-4 px-6 pt-6 sticky bg-white top-[97px] z-40 flex justify-between items-center border-b border-b-border gap-4">
        <FilterButtons
          value={filter}
          onChange={ScriptEditorActions.setFilter.defer}
          frame_fields={props.frame_fields}
        />
        <IconButton
          icon={<DownloadIcon />}
          className="no-padding"
          onClick={handleExport}
          aria-label={t('sidebar.settings.script_editor.download_script')}
        />
      </div>

      {!referenceFieldIsVisible && filter === 'reference' && (
        <Toast
          size="auto"
          kind="warning"
          message={t('sidebar.settings.script_editor.fieldHidden')}
          className="mt-4 mx-6"
          hideIcon
        />
      )}

      <div
        className="flex-auto p-6 overflow-auto space-y-6 bottom-banner-spacing"
        style={{ fontFamily: '"Source Code Pro", monospace', resize: 'none' }}
      >
        {/* It's possible for the filteredFieldIndex to return -1 if a field has been removed since the filter value was stored */}
        {filter && filteredFieldIndex >= 0 ? (
          <FilteredScriptEditor
            filter={filter}
            key={filter}
            value={filteredEditorData}
            onChange={ScriptEditorActions.updateFilteredText}
            frameCount={frames.length}
            frames={frames as DetailedFrame[]}
            fieldIndex={filteredFieldIndex}
          />
        ) : (
          <ScriptEditorFrameFields
            onChange={ScriptEditorActions.updateText}
            // onFocusOut={this.handleFocusChange}
            frameFields={props.frame_fields}
            frames={frames}
            showReferenceField={referenceFieldIsVisible}
          />
        )}
      </div>
      <div className="sticky pb-6 bg-white bottom-banner">
        <div className="px-6 mt-9">
          <Tip
            text={
              <span>
                {t('sidebar.settings.script_editor.add_new_lines')}
                <code style={{ marginBottom: 0 }}> / </code>
              </span>
            }
            linkText={t('sidebar.settings.script_editor.watch_video')}
            linkEvent={handleScriptVideoClick}
          />
        </div>

        <div className="flex justify-end mt-10 mr-5 gap-3">
          <Button type="outline" onClick={handleCloseClick} size="md">
            {hasChanges
              ? t('sidebar.settings.script_editor.cancel')
              : t('sidebar.settings.script_editor.close')}
          </Button>

          <Button
            size="md"
            onClick={ScriptEditorActions.commitChanges}
            disabled={isSaving}
          >
            {t('sidebar.settings.script_editor.save_script')}
          </Button>
        </div>
      </div>
    </div>
  );
});

ScriptEditorPane.displayName = 'ScriptEditorPane';
