/** @prettier */

import { times } from 'underscore';
import type { LastUsedStorage } from './types';

export const defaultFontSize = 116;
export const defaultFontWeight = 400;
export const defaultBrushSize = 8;

export const defaultStyles: LastUsedStorage = {
  textbox: {
    fontFamily: 'Roboto',
    fill: '#333333',
    fontSize: defaultFontSize,
  },
  PSStroke: {
    strokeWidth: 9,
    stroke: '#333333',
  },
  shapes: {
    strokeWidth: 0,
    stroke: '#333333',
    rx: 10,
    ry: 10,
    fill: '#333333',
  },
};

export const brushSizes = [
  {
    value: 8,
    label: '8px',
  },
  {
    value: 16,
    label: '16px',
  },
  {
    value: 32,
    label: '32px',
  },
  {
    value: 48,
    label: '48px',
  },
  {
    value: 64,
    label: '64px',
  },
];

export const brushColors = [
  { value: '#333333' }, // black
  { value: '#ffffff' }, // white
  { value: '#ff8888' }, // red
  { value: '#5853a6' }, // purple
  { value: '#86dde9' }, // blue
  { value: '#b8e986' }, // green
  { value: '#ffe37e' }, // yellow
  { value: '#979797' }, // dark grey
  { value: '#d8d8d8' }, // light grey
];

export const brushColorsValues = brushColors.map((i) => i.value);

// eslint-disable-next-line max-params
function makeTypeScale(ratio: number, starting: number, amount = 8) {
  let last = starting;

  return [starting].concat(
    times(amount! - 1, () => {
      last *= ratio;
      return last;
    }),
  );
}

export const fontSizes = makeTypeScale(1.5, 23).map((value) => ({
  value: Math.round(value),
}));
