/** @prettier */
import Button from 'blackbird/components/button/Button';
import Banner from 'blackbird/components/feedback/banner/Banner';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import * as React from 'react';

export const BannerEdu: React.FC = (): React.ReactElement => {
  React.useLayoutEffect(() => {
    Track.event.defer('banner_view', {
      variant: 'edu',
      posthogCapture: true,
    });
  }, []);

  return (
    <Banner
      kind={'fancy'}
      hideIcon
      className="no-padding-y h-banner"
      customWidth
      message={
        <a
          onClick={() => {
            Track.event.defer('banner_click', {
              category: 'Checkout',
              variant: 'edu',
            });
            FlyoverActions.open.defer({
              type: 'inlinePricing',
            });
          }}
          className="flex items-center justify-start w-full pl-2"
        >
          <div className="flex items-center pl-3 pr-3.5 pt-1 pb-1.5 leading-tight font-semibold rounded-md  bg-brand-yellow/50 text-black/50">
            <LockClosedIcon className="w-3.5 mt-[0.1rem]" />
            <span className="ml-1.5">{`Free Version`}</span>
          </div>

          <div className="ml-3 mr-2 font-semibold hover:underline decoration-brand-pink/30 decoration-2 underline-offset-2">
            <span>{`Get`}</span>
            <span className="mx-1 underline text-brand-pink decoration-brand-pink/30 decoration-2 underline-offset-2">{`50% off`}</span>
            <span>{`with your Education & Non-Profit discount →`}</span>
          </div>

          <div className="flex justify-end flex-auto lg:justify-start">
            <Button type="pink" size="sm" rounded>
              <span className="relative">{`Claim Discount`}</span>
            </Button>
          </div>
        </a>
      }
    />
  );
};
