/** @format */
import * as React from 'react';
import MoreOptions from 'blackbird/components/menu/MoreOptions';
import {
  convertPostActionsToContextMenuOptions,
  type RubyMenuAction,
} from 'javascripts/helpers/convertPostActionsToContextMenuOptions';
import { isEmpty } from 'underscore';
import DialogContextProvider, {
  DialogContext,
} from 'blackbird/components/dialog/DialogContext';
import Dialog from 'blackbird/components/dialog/Dialog';

interface Props {
  actions: RubyMenuAction[];
}

export const TeammateActionPopupMenu: React.FC<Props> = (props) => {
  const dialogContext = React.useContext(DialogContext);
  const actions = convertPostActionsToContextMenuOptions(
    props.actions,
    dialogContext,
  );

  return (
    <>
      <div className="flex w-10 justify-end">
        {!isEmpty(actions) && <MoreOptions blurOnClose options={actions} />}
      </div>
    </>
  );
};

(window as any).TeammateActionPopupMenu = (props) => (
  <DialogContextProvider>
    <TeammateActionPopupMenu {...props} />
  </DialogContextProvider>
);
