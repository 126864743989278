/** @prettier */
const createReactClass = require('create-react-class');
const { default: InfoIcon } = require('blackbird/images/icons/info.svg');
const { default: Icon } = require('blackbird/components/icon/Icon');
var PropTypes = require('prop-types');
const { default: classNames } = require('classnames');
module.exports = createReactClass({
  displayName: 'Tip',

  propTypes: {
    text: PropTypes.any.isRequired,
    linkUrl: PropTypes.string,
    linkText: PropTypes.string,
    linkEvent: PropTypes.func,
    size: PropTypes.string,
  },

  render() {
    const { size = 'xs' } = this.props;
    return (
      <div className="flex items-center justify-between f5 gray gap-1 bg-half-moon-gray rounded-sm p-2 has-links">
        <div className="flex items-center gap-2">
          <Icon icon={<InfoIcon />} />
          <span
            className={classNames(
              'text-type-primary',
              size === 'sm' ? 'text-sm' : 'text-xs',
            )}
          >
            {this.props.text}
          </span>
        </div>
        {typeof this.props.linkUrl !== 'undefined' ? (
          <a
            href={this.props.linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(
              'gray underline mr2 cursor-pointer',
              size === 'sm' ? 'text-sm' : 'text-xs',
            )}
          >
            {this.props.linkText}
          </a>
        ) : null}
        {typeof this.props.linkEvent !== 'undefined' ? (
          <a
            className={classNames(
              'gray underline pointer mr2 cursor-pointer',
              size === 'sm' ? 'text-sm' : 'text-xs',
            )}
            onClick={this.props.linkEvent}
          >
            {this.props.linkText}
          </a>
        ) : null}
      </div>
    );
  },
});
