/** @format */

import React, { useContext } from 'react';
import { format } from 'date-fns';
import Img from 'javascripts/components/shared/Img';
import { Watermark } from 'blackbird/components/watermark/Watermark';
import { ProjectsContext, type ProjectDocument } from './ProjectsContext';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { getFrameImage } from 'javascripts/helpers/getFrameImage';
import { getMissingFrameImageURL } from 'javascripts/helpers/getMissingFrameImageURL';
import { type frameAspectRatio } from 'javascripts/types/storyboard';
import classNames from 'classnames';
import { ProjectStoryboardActions } from './menus/ProjectStoryboardActions';
import { useDrag } from 'react-dnd';
import {
  type DashboardDragItem,
  DRAGGABLE_TYPES,
} from 'javascripts/components/shared/dragAndDropUtils';

interface ProjectStoryboardProps {
  document: ProjectDocument;
}

export const EditedDate: React.FC<{ editedAt: string }> = ({ editedAt }) => {
  try {
    return (
      <div className="text-xs text-type-disabled">
        {`Updated ${format(new Date(editedAt), 'd MMM yy')}`}
      </div>
    );
  } catch (err) {
    // console.error(`EditedDate parsing error (${editedAt}), returning null`);
    return null;
  }
};

const ProjectStoryboard: React.FC<ProjectStoryboardProps> = ({ document }) => {
  const {
    activeGroup,
    selectedStoryboards,
    toggleStoryboardSelection,
    isSingleStoryboardMove,
  } = useContext(ProjectsContext);
  const isSelected =
    selectedStoryboards.includes(document.id) && !isSingleStoryboardMove;
  const { height, width } = BoordsFrameSizeHelper(
    document.frame_aspect_ratio as frameAspectRatio,
  );
  const cover = getFrameImage(
    document.cover_image,
    document.frame_aspect_ratio as frameAspectRatio,
  );

  const canMoveStoryboard = activeGroup?.actions.includes('storyboards.move');
  const canDeleteStoryboard =
    activeGroup?.actions.includes('storyboards.delete');

  const [{ isDragging }, ref] = useDrag<
    DashboardDragItem,
    unknown,
    { isDragging: boolean }
  >(() => ({
    type: DRAGGABLE_TYPES.storyboard,
    item: {
      type: DRAGGABLE_TYPES.storyboard,
      id: document.id,
      parentProjectId: document.project_id,
    },
    canDrag: canMoveStoryboard,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div className="flex flex-col group/storyboard">
      <div
        className={classNames(
          'rounded-lg transition-opacity',
          canMoveStoryboard || canDeleteStoryboard
            ? 'hover:bg-black/10'
            : 'cursor-pointer',
          isDragging
            ? 'opacity-50'
            : isSelected
            ? 'ring-2 ring-brand-pink cursor-pointer'
            : undefined,
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isSelected) {
            window.location.href = '/storyboards/' + document.slug;
          } else {
            if (canMoveStoryboard || canDeleteStoryboard) {
              toggleStoryboardSelection(document.id);
            } else {
              window.location.href = '/storyboards/' + document.slug;
            }
          }
        }}
      >
        <div className="m-1.5" ref={ref}>
          <div className="relative">
            <div
              className="absolute z-50 opacity-0 top-2 right-2 group-hover/storyboard:opacity-100 transition-opacity"
              onClick={(e) => e.stopPropagation()}
            >
              <ProjectStoryboardActions document={document} />
            </div>
            <Watermark>
              <Img
                className="flex-grow rounded-t-md"
                containerClasses="flex"
                src={cover}
                height={height}
                width={width}
                placeholder={getMissingFrameImageURL(
                  document.frame_aspect_ratio as frameAspectRatio,
                )}
                log
              />
            </Watermark>
          </div>
          <div className="flex flex-col justify-center p-4 bg-white border border-border rounded-b-md">
            <a
              className="inline-block text-sm leading-snug cursor-pointer hover:underline"
              href={'/storyboards/' + document.slug}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {document.document_name}
            </a>
            <div className="mt-1">
              <EditedDate editedAt={document.updated_at} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-auto" />
    </div>
  );
};

export default ProjectStoryboard;
