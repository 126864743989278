/** @prettier */
import { splitUpTokensIntoLines } from './textHelpers/splitUpTokensIntoLines';
import type { DocInfo } from '../types';
import { fallbackFontStyle } from './textHelpers/fallbackFontStyle';
import { flattenHTMLTokens } from './textHelpers/flattenHTMLTokens';
import { trimLineBreaks } from 'javascripts/helpers/trimLineBreaks';
import { parseAndSanitizeText } from 'blackbird/helpers/parseAndSanitizeText';

/**
 * When passed a jsPDF `doc`, will return a function to create text
 * in that document.
 */
export const createTextFactory =
  (docInfo: DocInfo) =>
  (
    text: string | number,
    options: {
      x: number;
      y: number;
      maxWidth: number;
      lineHeightFactor?: number;
      baseline?: string;
      align?: string;
      fontSize?: number;
      color?: string;
      originY?: 'top' | 'middle' | 'bottom';
      debug?: boolean;
      boldWeight?: number;
      skipMarkdown?: boolean;
    },
  ) => {
    if (!docInfo.doc) throw new Error('invalid docInfo passed');
    const { doc } = docInfo;
    const previousFontsize = doc.getFontSize();
    const previousColor = doc.getTextColor();

    if (options.fontSize) doc.setFontSize(options.fontSize);
    if (options.color) doc.setTextColor(options.color);
    const fontName = doc.getFont().fontName;

    const lineHeight = options.lineHeightFactor || doc.getLineHeightFactor();
    // We'll be filling these in later
    const dimensions = {
      width: 0,
      height: 0,
      /** The height of one line, in mm */
      lineHeight: 0,
    };

    if (!text) return dimensions;

    const textToUse = String(text).trim();
    if (!textToUse.length) return dimensions;

    const getHeightPerLine = () => doc.getFontSize() * lineHeight;
    const sanitized = parseAndSanitizeText(textToUse, true, {
      skipMarkdown: options.skipMarkdown,
    });
    const tokens = flattenHTMLTokens(sanitized);

    const lines = splitUpTokensIntoLines({
      docInfo,
      tokens,
      maxWidth: options.maxWidth,
      fontName,
      // debug: textToUse.length > 20,
    });

    // Calculate what the final dimensions are going to be
    dimensions.height += getHeightPerLine() * lines.length;
    dimensions.lineHeight = getHeightPerLine();

    // If we want to align the text to a different origin, this is where we'd make
    // corrections based on the calculated height
    let originY = options.y;
    if (options.originY === 'middle') originY -= dimensions.height / 2;
    if (options.originY === 'bottom') originY -= dimensions.height;

    lines.forEach((line, index) => {
      const offsetY = originY + index * getHeightPerLine();
      let offsetX = options.x + (line.indent ?? 0);

      if (!options.align && line.isParagraphRtl) {
        offsetX += options.maxWidth - line.estimatedWidth;
      } else if (options.align === 'center') {
        offsetX -= line.estimatedWidth / 2;
      } else if (options.align === 'right') {
        offsetX -= line.estimatedWidth;
      }

      dimensions.width = Math.max(dimensions.width, line.estimatedWidth);

      if (options.debug) {
        doc.setFillColor(255, 200, 200);
        doc.rect(
          offsetX,
          offsetY,
          line.estimatedWidth,
          getHeightPerLine(),
          'F',
        );
      }

      line.tokens.forEach((token) => {
        const fontStyle = fallbackFontStyle(
          token.fontStyle,
          docInfo.fontSettings,
        );

        doc.setFont(fontName, fontStyle);
        const estimatedWidth = doc.getTextWidth(token.text);
        // If somehow some \n tags survived, we want to trim them off. Any line
        // breaks should have been properly parsed to tokens by now, but
        // occasionally they aren't
        const text = trimLineBreaks(token.text);
        doc.text(text, offsetX, offsetY, {
          lineHeightFactor: options.lineHeightFactor,
          baseline: options.baseline || 'top',
          align: 'left',
        });

        if (token.isDel) {
          const lineY = offsetY + getHeightPerLine() / 2;
          doc.setDrawColor(options.color ?? '#000000');
          doc.setLineWidth(Math.max(1, doc.getFontSize() / 15));
          doc.line(offsetX, lineY, offsetX + estimatedWidth, lineY);
        }

        if (token.link) {
          // The textWithlink implementation of jsPDF is a bit buggy, but does
          // basically the same as this
          doc.link(offsetX, offsetY, estimatedWidth, getHeightPerLine(), {
            url: token.link,
          });

          const lineY = offsetY + doc.getFontSize() * 1.1;
          doc.setDrawColor(options.color ?? '#000000');
          doc.setLineWidth(Math.max(1, doc.getFontSize() / 15));
          doc.line(offsetX, lineY, offsetX + estimatedWidth, lineY);
        }

        offsetX += estimatedWidth;
      });
    });

    if (options.debug) {
      let debugX = options.x;

      if (options.align === 'center') {
        debugX = options.x - options.maxWidth / 2;
      } else if (options.align === 'right') {
        debugX = options.x - options.maxWidth;
      }

      doc.setDrawColor(255, 200, 200);
      doc.setLineWidth(1);
      doc.rect(debugX, originY, options.maxWidth, dimensions.height, 'S');
    }

    if (options.fontSize) doc.setFontSize(previousFontsize);
    if (options.color) doc.setTextColor(previousColor);

    return dimensions;
  };
