/** @prettier */

/** checks if the ref passed is an actual React ref or just an HTML object*/
export const isNativeRef = (
  ref?: HTMLElement | null | React.RefObject<HTMLElement>,
): ref is React.RefObject<HTMLElement> => {
  return ref ? ref.hasOwnProperty('current') : false;
};

/** Will get the current HTML element for a ref, regardless if it's a plain HTML
 * element or a React Ref*/
export const getRefElement = (
  ref?: HTMLElement | null | React.RefObject<HTMLElement>,
) => {
  return isNativeRef(ref) ? ref.current : ref;
};
