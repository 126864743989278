/** @format */

function template(doc, defaults, content) {
  return content.replace(/\n/, '\n    ');
}

export const parseRTF = (string) =>
  new Promise<string>((resolve, reject) => {
    import('@iarna/rtf-to-html').then(({ fromString }) => {
      fromString(
        string,
        {
          template,
        },
        (err, html) => {
          if (err) return reject(err);
          resolve(html);
        },
      );
    }, reject);
  });
