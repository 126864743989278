/**@prettier */
const _ = require('underscore');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const { ExportPdfLayout } = require('./pdf/ExportPdfLayout');
const { ExportPdfFrontCover } = require('./pdf/ExportPdfFrontCover');
const { ExportPdfBackCover } = require('./pdf/ExportPdfBackCover');
const { NewPDFExportOptIn } = require('../pdf/helpers/newPDFExportOptin');

const navigateToRoute = require('../../helpers/router/navigate-to-route');
const { default: Button } = require('blackbird/components/button/Button');
const { default: classNames } = require('classnames');
import { PDFGenerationInProgress } from 'javascripts/components/pdf/UI/PDFGenerationInProgress';
const { openLightbox } = require('javascripts/helpers/lightbox');

window.StoryboardExportPdf = createReactClass({
  propTypes: {
    cover: PropTypes.object.isRequired,
    storyboard: PropTypes.object.isRequired,
    pdf: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired,
    onDownloadExpired: PropTypes.func.isRequired,
  },

  getInitialState: function () {
    return {
      activeTab: 'layout',
      PDFgeneratedThisSession: false,
    };
  },

  _upgrade(e) {
    e.preventDefault();
    navigateToRoute('pricing');
  },

  _export: function (e) {
    e.preventDefault();

    this.setState({
      PDFgeneratedThisSession: true,
    });

    PdfActions.generatePdf.defer({
      storyboard_id: this.props.storyboard.id,
    });
    Track.event.defer('start_generating_pdf');
  },
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.pdf.pdf.has_current_cached_pdf ===
        prevProps.pdf.pdf.has_current_cached_pdf &&
      this.state.PDFgeneratedThisSession ===
        prevState.PDFgeneratedThisSession &&
      this.props.pdf.pdf.is_processing_cached_pdf ===
        prevProps.pdf.pdf.is_processing_cached_pdf
    ) {
      return;
    }
    if (
      (this.props.pdf.pdf.has_current_cached_pdf &&
        this.state.PDFgeneratedThisSession) ||
      this.props.pdf.pdf.is_processing_cached_pdf
    ) {
      this.props.onDownload();
    }
  },
  componentDidMount() {
    Track.event.defer('legacy_pdf_flyover');
  },
  _preview: function (e) {
    e.preventDefault();
    Track.event.defer('start_generating_pdf');
    Track.event.defer('download_generated_pdf');
    Track.event.defer('exported_pdf');

    window.open('/storyboards/' + this.props.storyboard.slug + '.pdf');
  },

  _helpVideo(e) {
    e.preventDefault();
    openLightbox('https://vimeo.com/275861119');
  },

  _close: function (e) {
    e.preventDefault();
    FlyoverActions.close();
  },

  _tab: function (name, e) {
    e.preventDefault();
    this.setState({
      activeTab: name,
    });
  },

  render: function () {
    var content;
    var isPortrait = false;

    if (!_.isNull(this.props.pdf.layout)) {
      isPortrait = this.props.pdf.layout.indexOf('portrait') > -1;
    }

    if (this.props.pdf.pdf.is_processing_cached_pdf) {
      return (
        <PDFGenerationInProgress
          title="Generating your PDF..."
          subtitle="We're just creating your PDF for you. A download link will appear here when it's ready  "
        />
      );
    } else if (
      this.props.pdf.pdf.has_current_cached_pdf &&
      this.state.PDFgeneratedThisSession
    ) {
      return <ExportPdfDone {...this.props} />;
    }

    if (this.state.activeTab == 'layout') {
      content = (
        <ExportPdfLayout {...this.props} layout={this.props.pdf.layout} />
      );
    } else if (this.state.activeTab == 'frontCover') {
      content = <ExportPdfFrontCover {...this.props} />;
    } else if (this.state.activeTab == 'backCover') {
      content = <ExportPdfBackCover {...this.props} />;
    }
    const pageClass = {
      'pb-[89%] w-[70%]': isPortrait,
      'pb-[70%] w-full':
        this.props.cover.cover.storyboard.use_letterbox_pdf_layout &&
        !isPortrait,
      'pb-[70.74%] w-full':
        !isPortrait &&
        !this.props.cover.cover.storyboard.use_letterbox_pdf_layout,
    };

    return (
      <>
        <div className="flex flex-col px-10 lg:flex-row gap-4">
          <div className="flex w-full pb-3 rounded-sm mt2 f4 bg-surface lg:w-60 shrink-0 lg:block">
            <div className="mt2 pb6 f4 bg-moon-gray w5 br2 br--left">
              <div
                onClick={(e) => this._tab('frontCover', e)}
                className={
                  'cursor-pointer p-4 flex justify-center ' +
                  (this.state.activeTab == 'frontCover'
                    ? ' bg-light-silver '
                    : '  ')
                }
              >
                <div className={classNames('bg-white relative', pageClass)}>
                  <div className="absolute flex items-center justify-center w-full h-full">
                    <span className="silver f5">Front cover</span>
                  </div>
                </div>
              </div>
              <div
                onClick={(e) => this._tab('layout', e)}
                className={
                  'cursor-pointer p-4 flex justify-center ' +
                  (this.state.activeTab == 'layout' ? ' bg-surface ' : '  ')
                }
              >
                <div
                  className={classNames(
                    'bg-white shadow-3 relative',
                    pageClass,
                  )}
                >
                  <ExportPdfPreview {...this.props} />
                </div>
              </div>
              <div
                onClick={(e) => this._tab('backCover', e)}
                className={
                  'cursor-pointer p-4 flex justify-center ' +
                  (this.state.activeTab == 'backCover'
                    ? ' bg-light-silver '
                    : '  ')
                }
              >
                <div
                  className={classNames(
                    'bg-white shadow-3 relative ',
                    pageClass,
                  )}
                >
                  <div className="absolute flex items-center justify-center w-full h-full">
                    <span className="silver f5">Back cover</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex-auto bg-white f4 pa4 mt2 h6 br2"
            style={{ maxWidth: '44rem' }}
          >
            {this.props.pdf.hasErrors && (
              <div className="mb4">
                <ExportPDFErrors heading="PDF looking funky?" {...this.props} />
              </div>
            )}

            {content}
          </div>
        </div>

        <div className="sticky bottom-0 p-10 pt-0 bg-white rounded-xl">
          <div className="absolute left-0 right-0 h-px border-t border-border" />
          {window.BoordsConfig.Freeloader ? (
            <div className="flex mt-7">
              <div className="flex-auto">
                <div className="font-semibold">Upgrade required</div>
                <div className="text-sm text-type-subdued">
                  PDF exports aren't available on the Free plan
                </div>
              </div>

              <div className="flex justify-end flex-auto gap-3">
                <Button type="outline" onClick={this._preview}>
                  See a Preview
                </Button>
                <Button size="lg" onClick={this._upgrade}>
                  Upgrade
                </Button>
              </div>
            </div>
          ) : (
            <div className="mt-7">
              <NewPDFExportOptIn>
                <Button
                  type="outline"
                  rounded
                  onClick={this._export}
                  className="flex-grow-0 flex-shrink-0"
                >{`Export Legacy PDF`}</Button>
              </NewPDFExportOptIn>
            </div>
          )}
        </div>
      </>
    );
  },
});
