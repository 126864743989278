/** @format */

import React from 'react';
import Img from 'javascripts/components/shared/Img';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { getFrameImage } from 'javascripts/helpers/getFrameImage';
import { getMissingFrameImageURL } from 'javascripts/helpers/getMissingFrameImageURL';
import { type frameAspectRatio } from 'javascripts/types/storyboard';
import classNames from 'classnames';
import { type Storyboard } from './ShareableProjectContext';

interface ShareableStoryboardProps {
  storyboard: Storyboard;
}

export const ShareableStoryboard: React.FC<ShareableStoryboardProps> = ({
  storyboard,
}) => {
  const { height, width } = BoordsFrameSizeHelper(
    storyboard.frame_aspect_ratio as frameAspectRatio,
  );
  const cover = getFrameImage(
    storyboard.cover_image,
    storyboard.frame_aspect_ratio as frameAspectRatio,
  );

  return (
    <div className="flex flex-col group">
      <a
        className={classNames('rounded-lg cursor-pointer')}
        href={`/s/${storyboard.short_slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="m-1.5">
          <div>
            <Img
              className="flex-grow rounded-t-md"
              containerClasses="flex"
              src={cover}
              height={height}
              width={width}
              placeholder={getMissingFrameImageURL(
                storyboard.frame_aspect_ratio as frameAspectRatio,
              )}
              log
            />
          </div>
          <div className="flex flex-col justify-center p-4 bg-white border border-border rounded-b-md">
            <div className="inline-block text-sm leading-snug cursor-pointer group-hover:underline">
              {storyboard.document_name}
            </div>
          </div>
        </div>
      </a>
      <div className="flex-auto" />
    </div>
  );
};
