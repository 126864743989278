/** @format */
import { posthogClient } from 'javascripts/helpers/posthog';
import { PostHogProvider } from 'posthog-js/react';
import type { FeedbackKind } from 'blackbird/components/feedback/types';
import { SignupProvider } from 'blackbird/helpers/contexts/SignupContext';
import { ErrorBoundary } from 'javascripts/components/shared/ErrorBoundary';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';

import type {
  SoundBite,
  TestimonialStore,
} from 'javascripts/flux/stores/testimonial';
import { useStore } from 'javascripts/helpers/useStore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { findKey } from 'underscore';
import type { OnboardingProps } from './Onboarding';
import Onboarding, { OnboardingType } from './Onboarding';
type rubyFlashMessage = ['alert' | 'error' | 'success' | 'notice', string];
const TESTIMONIAL_IDENTIFIER = 'In-app: Signup';

interface Props {
  authenticityToken: string;
  type: OnboardingType;
  flash: rubyFlashMessage;
  googleSigninPath: string;
  formAction: string;
  inviteToken?: string;
  invitedEmail?: string;
  passwordResetToken?: string;
}

const parseFlash = (
  flash: rubyFlashMessage,
): OnboardingProps['notification'] => {
  const map: Record<rubyFlashMessage[0], FeedbackKind> = {
    alert: 'warning',
    error: 'error',
    success: 'success',
    notice: 'info',
  };

  return {
    kind: map[flash[0]],
    message: flash[1],
  };
};

interface RouteInfo {
  name: OnboardingType;
  title: string;
  formAction: string;
  replaceURL?: boolean;
}

const BlackbirdAuthentication: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [type, setType] = React.useState<OnboardingType | undefined>();
  const oldType = React.useRef(type);
  const [formAction, setFormAction] = React.useState(props.formAction);
  const [flash, setFlash] = React.useState<rubyFlashMessage | undefined>(
    props.flash,
  );

  const routes = React.useMemo<Record<string, RouteInfo>>(
    () => ({
      '/login': {
        title: t('login.title'),
        name: OnboardingType.SIGN_IN,
        formAction: '/login',
      },
      '/welcome/tax-id': {
        title: t('payment.title'),
        name: OnboardingType.TAX_ID,
        formAction: '/',
        replaceURL: false,
      },
      '/signup': {
        title: t('signup.title'),
        name: OnboardingType.SIGN_UP,
        formAction: '/signup',
        replaceURL: false,
      },
      '/forgot_password/new': {
        title: t('accounts.resetPassword.title'),
        name: OnboardingType.RESET_PASSWORD,
        formAction: '/forgot_password',
      },
      '/users/password/edit': {
        title: t('accounts.changePassword.title'),
        name: OnboardingType.CHANGE_PASSWORD,
        formAction: '/users/password',
        replaceURL: false,
      },
      // This is shown when there's an error in the change password flow
      '/users/password': {
        title: t('accounts.changePassword.title'),
        name: OnboardingType.CHANGE_PASSWORD,
        formAction: '/users/password',
        // We don't want to replace the url, because it contains the reset token
        replaceURL: false,
      },
    }),
    [t],
  );

  const handleNav = React.useCallback(
    (newType: OnboardingType) => {
      if (newType !== OnboardingType.SIGN_UP_EMAIL) {
        const match = findKey(routes, (v) => v.name === newType);
        if (!match) throw new Error(`No match for route ${newType}`);
        if (routes[match].replaceURL !== false) {
          history.replaceState(newType, routes[match].title, match);
        }
        setFormAction(routes[match].formAction);
      }

      setType(newType);
      if (oldType.current && newType !== oldType.current) setFlash(undefined);
      oldType.current = newType;
    },
    [routes],
  );

  React.useEffect(() => {
    const type = routes[location.pathname];
    handleNav(type.name);
  }, [handleNav, routes]);

  React.useEffect(() => {
    if (type === 'signup') {
      TestimonialActions.fetch.defer(TESTIMONIAL_IDENTIFIER);
    }
  }, [type]);

  const testimonial = useStore<SoundBite | undefined, TestimonialStore>(
    'testimonial',
    (s) => s.categories[TESTIMONIAL_IDENTIFIER]?.soundbites[0],
  );

  if (!type) return null;
  return (
    <Onboarding
      {...props}
      type={type}
      formAction={formAction}
      notification={flash ? parseFlash(flash) : undefined}
      testimonial={type !== 'signin' ? testimonial : undefined}
      onToggleScreen={handleNav}
      passwordResetToken={props.passwordResetToken}
      inviteToken={props.inviteToken}
    />
  );
};

const BlackbirdAuthenticationContainer: React.FC<Props> = (props) => {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <PostHogProvider client={posthogClient()}>
          <SignupProvider>
            <DefaultStoreContextProvider>
              <BlackbirdAuthentication {...props} />
            </DefaultStoreContextProvider>
          </SignupProvider>
        </PostHogProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

// Because a line break would prevent detection by generateEslintGlobals…
// prettier-ignore
(window as any).BlackbirdAuthenticationContainer = BlackbirdAuthenticationContainer;
