import { AssetsStore } from '../stores/assets';
import { createTypedActions } from '../stores/AltStore';

class AssetsActions {
  fetchFrameAssets: AssetsStore['handleFetchFrameAssets'] = function(options) {
    this.dispatch(options);
  };
  fetchImageAssets: AssetsStore['handleFetchImageAssets'] = function(options) {
    this.dispatch(options);
  };

  replaceExistingFrame = function(options: Parameters<AssetsStore['handleReplaceExistingFrame']>[0] ) {
    this.dispatch(options);
  };
  uploadImageAsset = function(options: Parameters<AssetsStore['handleUploadImageAsset']>[0] ) {
    this.dispatch(options);
  };
  getAssetInfo = function (options: Parameters<AssetsStore['getAssetInfo']>[0]) {
    this.dispatch(options);
  };
  deleteImageAsset = function (options: Parameters<AssetsStore['handleDeleteImageAsset']>[0]) {
    this.dispatch(options);
  };
  deleteFrameAsset = function (options: Parameters<AssetsStore['handleDeleteFrameAsset']>[0]) {
    this.dispatch(options);
  };
  setVisible:AssetsStore['handleSetVisible'] = function (options) {
    this.dispatch(options);
  };
  refresh = function (options: Parameters<AssetsStore['handleRefresh']>[0]) {
    this.dispatch(options);
  };
}

const actions = createTypedActions<AssetsActions>(AssetsActions);
export { actions as AssetsActions };
