/** @prettier */
import { IFPermissionPanel } from 'javascripts/helpers/has-permission';
import logger from 'javascripts/helpers/logger';
import * as React from 'react';
import { IPanelProps } from '../../shared/MultiPanel';
import { SuspenseLoader } from '../../shared/SuspenseLoader';

const ExportSpreadsheet = React.lazy(
  () => import('../ExportSpreadsheet/ExportSpreadsheet'),
);

export const ExportSpreadsheetContainer: React.FC<IPanelProps> = (props) => {
  const ExportContent: React.FC = () => (
    <SuspenseLoader>
      <ExportSpreadsheet {...props} />
    </SuspenseLoader>
  );
  if (props.storyboardOwnerId) {
    return (
      <IFPermissionPanel
        featureName="bulk_export"
        storyboardOwnerId={props.storyboardOwnerId}
      >
        <ExportContent />
      </IFPermissionPanel>
    );
  } else {
    return <ExportContent />;
  }
};
