/** @prettier */
import { type Price } from 'blackbird/helpers/contexts/BillingContext';
import { formatCurrency } from 'javascripts/helpers/currencyHelper';
import * as React from 'react';
import { PricingContext } from './PricingContext';

interface PricingTableUserCostProps {
  price: Price;
}

export const PricingTableUserCost: React.FC<PricingTableUserCostProps> = ({
  price,
}) => {
  const { billingInterval } = React.useContext(PricingContext);

  if (!price.additional_user_amount) {
    return null;
  }

  const amount = Math.floor(
    price.additional_user_amount / 100 / (billingInterval === 'year' ? 12 : 1),
  );

  return (
    <>
      {formatCurrency({
        amount,
        currency: BoordsConfig.Currency,
        showZeros: false,
      })}
    </>
  );
};
