/**@prettier */
const createReactClass = require('create-react-class');
const {
  SuspenseLoader,
} = require('javascripts/components/shared/SuspenseLoader');
const { withTranslation } = require('react-i18next');
const WatchVideo = React.lazy(() => import('./WatchVideo'));
const PanelbarCommentsEmpty = createReactClass({
  getInitialState: function () {
    return {
      inShareableView: ShareableStore.getState().storyboard,
    };
  },

  render: function () {
    const { t } = this.props;
    var text = (
      <div>
        <div className="mb-3">{t('comments.first_comment_info')}</div>
        <SuspenseLoader className="w-4" fill={false}>
          <WatchVideo />
        </SuspenseLoader>
      </div>
    );

    if (this.state.inShareableView) {
      text = t('comments.no_comments');
    }

    return (
      <div className="text-type-subdued text-left rounded px-6 mt-2">
        {text}
      </div>
    );
  },
});
window.PanelbarCommentsEmpty = withTranslation()(PanelbarCommentsEmpty);
