/** @prettier */
/** filters out items that are not undefined in a typesafe way.
 * Used in Array.filter.
 * based on https://stackoverflow.com/a/46700791/847689 */
export function notUndefined<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  if (value === undefined) return false;
  return true;
}

/**
 * filters out items that are not null in a typesafe way.
 * Used in Array.filter.
 * based on https://stackoverflow.com/a/46700791/847689
 */
export function notNull<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  if (value === null) return false;
  return true;
}

/**
 * filters out items that are not false in a typesafe way.
 * Used in Array.filter.
 * based on https://stackoverflow.com/a/46700791/847689
 */
export function notFalse<TValue>(
  value: TValue | false | undefined,
): value is TValue {
  if (value === false) return false;
  return true;
}

/**
 * filters out items that are not null or undefined in a typesafe way.
 * Used in Array.filter.
 * based on https://stackoverflow.com/a/46700791/847689
 */
export function notNullOrUndefined<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  if (value === null || value === undefined) return false;
  return true;
}
