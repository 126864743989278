const Container = require('../shared/Container').default;
const { UserDropdownContainer } = require('blackbird/components/menus/UserDropdown');
const { DefaultStoreContextProvider } = require('javascripts/flux/DefaultStoreContextProvider');

window.AccountPopupMenu = module.exports = Container('user', () => ({
  onMount: UserActions.fetch.defer
}))(() => (
  <DefaultStoreContextProvider>
    <UserDropdownContainer />
  </DefaultStoreContextProvider>
))
