/** @prettier */
import * as React from 'react';
import { isFunction } from 'underscore';
import { LocalState } from './local-state';

type callbackFunc<T> = (current: T) => T;

function isCallback<T>(func: callbackFunc<T> | T): func is callbackFunc<T> {
  return isFunction(func);
}

/** like React's useState hook, but persisted using `LocalState` under `key` */
export function usePersistedState<T>(
  key: string,
  defaultValue: T,
): [T, (args: T | callbackFunc<T>, persist?: boolean) => void] {
  const stored = LocalState.getValue<T>(key);
  const [currentValue, setCurrentValue] = React.useState<T>(
    stored ?? defaultValue,
  );

  const setState = React.useCallback(
    (args: T | callbackFunc<T>, persist = true) => {
      setCurrentValue(current => {
        let newValue = args;

        if (isCallback<T>(args)) {
          newValue = args(current);
        } else {
          newValue = args;
        }

        if (newValue !== current && persist) LocalState.setValue(key, newValue);
        return newValue;
      });
    },
    [key],
  );

  return [currentValue, setState];
}
