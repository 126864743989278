/** @format */

import React, { useContext } from 'react';
import { TabsContext } from './Tabs';
import { Tab as HeadlessTab } from '@headlessui/react';

const TabPanel: React.FC<{ className?: string }> = (props) => {
  const tabsContext = useContext(TabsContext);
  if (!tabsContext) {
    return <></>;
  }
  const { tabs } = tabsContext;
  const { className, children } = props;
  return (
    <HeadlessTab.Panels>
      {tabs.map((_, index) => {
        return (
          <HeadlessTab.Panel className={className} key={tabs[index].key}>
            {React.Children.map(children, (child, childIndex) =>
              childIndex === index ? child : null,
            )}
          </HeadlessTab.Panel>
        );
      })}
    </HeadlessTab.Panels>
  );
};
export default TabPanel;
