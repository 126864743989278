(() => {
  class TrialStore {

    constructor() {
      this.bindListeners({
        handleStart:    TrialActions.START,
        handleStarted:  TrialActions.STARTED,
      });
    }

    handleStarted(team_id) {
      FlyoverActions.open.defer({
        type:    "teamOnboardingName",
        teamId:  team_id,
      });
    }

    handleStart(team_id) {
      RequestActions.start.defer();
      ajaxHelper.ajax({
        method: "post",
        url: "/trial",
        success: function (response) {
          TrialActions.started(team_id);
          RequestActions.complete();
          this.emitChange();
        }.bind(this),
        error: function(response) {
          RequestActions.error("Sorry, we were unable to start your trial");
        }.bind(this),
      });
    }

  }

  window.TrialStore = alt.createStore(TrialStore, 'TrialStore');

})();
