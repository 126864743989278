/** @format */
import * as React from 'react';
import Dialog from 'blackbird/components/dialog/Dialog';
import { useStore } from 'javascripts/helpers/useStore';
import type { CommentStore, Comment } from 'javascripts/flux/stores/comment';
import { useTranslation } from 'react-i18next';
import CommentCard from '../card/CommentCard';
import type { StoryboardStore } from 'javascripts/flux/stores/storyboard';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import type { frameAspectRatio } from 'javascripts/types/storyboard';
import type {
  AnnotationToolEngine,
  AnnotationToolEngineProps,
} from './AnnotationToolEngine';
import { CommentAnnotationsActions } from 'javascripts/flux/actions/commentAnnotations';
import {
  newAvailabilityContext,
  type CommentAnnotationsStore,
} from 'javascripts/flux/stores/commentAnnotations';
import { idleTimeout } from 'javascripts/helpers/idle-timeout';

export const AnnotationPreviewDialog = React.memo(() => {
  const currentAnnotation = useStore<
    AnnotationToolEngineProps | null,
    CommentAnnotationsStore
  >('commentAnnotations', (s) => s.currentAnnotation);
  const ref = React.useRef<HTMLDivElement>(null);
  const [isSleeping, setIsSleeping] = React.useState(true);

  const { t } = useTranslation('translation');
  const accompanyingComment = useStore<Comment | undefined, CommentStore>(
    'comment',
    (c) => {
      if (!currentAnnotation?.id) return undefined;
      const { commentId } = currentAnnotation.id;
      return commentId ? c.commentsRawById[commentId] : undefined;
    },
    undefined,
    // Make this hook dependent on the currentAnnotation
    [currentAnnotation],
  );

  const aspectRatioString = useStore<frameAspectRatio, StoryboardStore>(
    'storyboard',
    ({ storyboard }) => storyboard.frame_aspect_ratio,
  );

  const aspectRatio = useStore<string, StoryboardStore>(
    'storyboard',
    ({ storyboard }) => {
      const { width, height } = BoordsFrameSizeHelper(
        storyboard.frame_aspect_ratio,
      );
      return String(width / height);
    },
  );

  const handleClose = React.useCallback(() => {
    CommentAnnotationsActions.close.defer();
  }, []);

  /** Respond to changing currentAnnotations */
  React.useEffect(() => {
    let engine: AnnotationToolEngine;
    const availabilityContext = newAvailabilityContext({
      name: 'previewDialog',
      editor: false,
      preview: true,
    });

    // Tell the store that we're able to show items
    CommentAnnotationsActions.addContext.defer(availabilityContext);

    // We don't need to start the engine if the user doesn't have an annotation
    // active, but we still want to return the unmounting code later
    if (currentAnnotation && !isSleeping) {
      import('./AnnotationToolEngine').then(({ AnnotationToolEngine }) => {
        engine = new AnnotationToolEngine(
          ref.current!,
          aspectRatioString,
          {
            ...currentAnnotation,
            showBackground: true,
          },
          ref.current!.getBoundingClientRect(),
        );
        CommentAnnotationsActions.registerEngine.defer(engine);
      });
    }

    return () => {
      engine?.unmount();
      CommentAnnotationsActions.registerEngine.defer(null);
      CommentAnnotationsActions.removeContext.defer(availabilityContext);
    };
    // We don't want to tear this down if the context changes
  }, [aspectRatioString, currentAnnotation, isSleeping]);

  /** When we just mount, we want to wait a bit with showing things so we allow
   * the UI/stores to settle. This prevents the situation that the preview will
   * be opened after closing the FrameFocusFlyover with an active annotation in
   * there */
  React.useEffect(() => {
    const timeout = idleTimeout(() => {
      setIsSleeping(false);
    }, 1000);

    return () => timeout.cancel();
  }, [setIsSleeping]);

  if (
    currentAnnotation &&
    currentAnnotation.interactive === false &&
    !isSleeping
  ) {
    const author = accompanyingComment?.user.name ?? 'somebody';

    return (
      <Dialog
        title={t('annotations.preview.title', { author })}
        onConfirm={handleClose}
        size="lg"
        isInformational
        hideActions
        isOpen
      >
        <div
          className="relative w-full overflow-hidden border rounded border-border-image"
          style={{ aspectRatio }}
          ref={ref}
        />

        {accompanyingComment && (
          <div className="px-4 border rounded border-border">
            <CommentCard
              id={accompanyingComment.id}
              frameId={accompanyingComment.frame_id}
              text={accompanyingComment.text}
              userName={author}
              date={new Date(accompanyingComment.created_at)}
              annotation={null}
              isInteractive={false}
              hideBorder
            />
          </div>
        )}
      </Dialog>
    );
  }

  return null;
});

AnnotationPreviewDialog.displayName = 'AnnotationPreviewDialog';
