/** @format */
import React from 'react';
import { isFunction } from 'underscore';

/** A way of checking the currentValue of a state value. Useful for when
 * callbacks might fire later. Think twice about using this, typically you want
 * to use either state or refs, but if you need both in the same file, try this one */
export const useStateWithRef = <T = unknown>(
  defaultValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>, React.MutableRefObject<T>] => {
  const [state, setState] = React.useState<T>(defaultValue);
  const valueRef = React.useRef<T>(defaultValue);

  const updateState = React.useCallback<
    React.Dispatch<React.SetStateAction<T>>
  >((newValue: T) => {
    if (isFunction(newValue)) {
      setState((currentValue) => {
        const output = newValue(currentValue);
        valueRef.current = output;
        return output;
      });
    } else {
      setState(newValue);
      valueRef.current = newValue;
    }
  }, []);

  return [state, updateState, valueRef];
};
