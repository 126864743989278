/*** @prettier */
import {
  BillingContext,
  Price,
} from 'blackbird/helpers/contexts/BillingContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'blackbird/components/button/Button';
import { LineItem } from './PaymentInvoiceLineItem';
import { ModalDivider } from 'blackbird/components/dialog/Dialog';
import logger from 'javascripts/helpers/logger';
import { CheckIcon } from '@heroicons/react/24/outline';
import {
  FeatureInline,
  FeatureName,
} from 'blackbird/components/features/Feature';
import { PricingTableUserCount } from 'blackbird/components/pricing/PricingTableFeatures';
import { formatCurrency } from 'javascripts/helpers/currencyHelper';

interface IntervalProps {
  short?: boolean;
}

const Interval: React.FC<IntervalProps> = ({ short }) => {
  const { t } = useTranslation('billing');
  const { newPlan } = useContext(BillingContext);

  return !newPlan ? null : (
    <div className="text-sm font-normal text-type-subdued">
      {short
        ? t(`notices.${newPlan.interval === 'month' ? 'monthly' : 'yearly'}`)
        : t(`notices.${newPlan.interval === 'month' ? 'perMonth' : 'perYear'}`)}
    </div>
  );
};

const PaymentInlinePlanChangeConfirmation: React.FC = () => {
  const { t } = useTranslation('billing');
  const [hasExistingBalance, setHasExistingBalance] = useState(false);
  const [isPuffin, setIsPuffin] = useState(false);
  const [hasValidDiscount, setHasValidDiscount] = useState(false);

  const {
    invoicePreview,
    newPlan,
    annualUpgrade,
    user,
    customer,
    processing,
    setDialogSubtitle,
    setDialogTitle,
    hasValidCard,
    setNewCustomerProceed,
    updateSubscription,
  } = useContext(BillingContext);

  useEffect(() => {
    if (newPlan) {
      if (['workflow', 'standard'].includes(newPlan.slug)) {
        setIsPuffin(true);
      }
      if (annualUpgrade) {
        setDialogTitle(t('checkout.annualUpgrade.title'));
        setDialogSubtitle(t('checkout.annualUpgrade.subtitle'));
      } else {
        setDialogTitle(
          t(`checkout.${hasValidCard ? 'hasValidCard' : 'newCustomer'}.title`, {
            planName: newPlan.name,
          }),
        );
        setDialogSubtitle(newPlan.description);
      }
    }
  }, [newPlan, hasValidCard, annualUpgrade]);

  const price: Price | undefined = newPlan?.prices.find(
    (price) =>
      price.currency === BoordsConfig.Currency &&
      price.interval === newPlan?.interval,
  );

  useEffect(() => {
    if (
      invoicePreview &&
      invoicePreview.amount_due !== invoicePreview.subtotal
    ) {
      setHasExistingBalance(true);
    }
  }, [invoicePreview]);

  useEffect(() => {
    if (invoicePreview && invoicePreview.total_discount_amounts) {
      invoicePreview.total_discount_amounts.map((amount) => {
        if (amount.discount.coupon.valid) {
          setHasValidDiscount(true);
        }
      });
    }
  }, [invoicePreview]);

  return !newPlan || !invoicePreview || !user ? null : (
    <div className="w-full font-base">
      {hasValidDiscount && (
        <div className="mb-6 -mt-2">
          {invoicePreview.total_discount_amounts.map((amount, idx) => {
            const { percent_off, valid } = amount.discount.coupon;

            if (valid) {
              return (
                <div
                  key={`discount-label-${idx}`}
                  className="flex justify-center pb-4"
                >
                  <span className="bg-type-green/25 text-type-green px-2 pt-0.5 pb-1 rounded-md text-sm">{`${percent_off}% off discount applied`}</span>
                </div>
              );
            }
          })}
        </div>
      )}

      <div className="mt-4 mb-8">
        <ModalDivider />
      </div>

      {hasValidDiscount && (
        <>
          <LineItem amount={invoicePreview.subtotal}>
            <>
              <div className="font-semibold text-type-primary">
                {newPlan.name}
              </div>
            </>
          </LineItem>

          <div>
            {invoicePreview.total_discount_amounts.map((amount, idx) => {
              const { name, percent_off, duration_in_months, valid } =
                amount.discount.coupon;

              if (valid) {
                return (
                  <LineItem
                    isDiscount
                    amount={amount.amount}
                    key={`discount-${idx}`}
                  >
                    <div>
                      <div className="font-semibold text-type-primary">
                        {t(
                          `lineItems.discount.${
                            name.indexOf('np_') > -1 ? 'education' : 'generic'
                          }`,
                          {
                            percent_off,
                          },
                        )}
                      </div>
                      <div className="text-sm">
                        {t('lineItems.discount.duration', {
                          duration_in_months,
                        })}
                      </div>
                    </div>
                  </LineItem>
                );
              }
            })}
          </div>
        </>
      )}

      {isPuffin && price ? (
        <div>
          <LineItem amount={price.amount} showZeros={false}>
            <div className="font-semibold !text-black">{`${newPlan.name} Plan`}</div>
          </LineItem>

          {customer &&
            customer.billable_user_count > 1 &&
            price.additional_user_amount && (
              <LineItem
                amount={
                  price.additional_user_amount *
                  (customer.billable_user_count - 1)
                }
                showZeros={false}
              >
                <div>
                  <div>
                    {`Additional users x ${customer.billable_user_count - 1}
                `}
                  </div>
                  {customer.billable_user_count > 2 && (
                    <div className="text-xs">{`${formatCurrency({
                      amount: price.additional_user_amount / 100,
                      currency: BoordsConfig.Currency,
                      showZeros: false,
                    })} per user`}</div>
                  )}
                </div>
              </LineItem>
            )}

          <div className="mb-10" />
          <div className="mt-4">
            <ModalDivider />
          </div>
          <LineItem
            showZeros={false}
            isTotal
            amount={invoicePreview.subtotal}
            amountDescription={<Interval />}
          >
            {`Total`}
          </LineItem>
        </div>
      ) : hasValidDiscount ? (
        <LineItem
          isTotal
          amount={
            invoicePreview.subtotal -
            invoicePreview.total_discount_amounts[0].amount
          }
          amountDescription={<Interval />}
        >
          {`Total`}
        </LineItem>
      ) : (
        <LineItem
          isTotal
          amountDescription={<Interval />}
          amount={invoicePreview.subtotal}
        >
          <div>
            <div className="mb-2 text-lg">{`${newPlan.name} Plan`}</div>
            <div className="space-y-2 !font-normal">
              {!isPuffin && (
                <PricingTableUserCount plan={newPlan} hideSingleUser />
              )}
              {newPlan.summaryFeatures &&
                newPlan.summaryFeatures.map((f: FeatureName) => (
                  <FeatureInline name={f} key={`feature-${f}`} />
                ))}
            </div>
          </div>
        </LineItem>
      )}

      <div className="mb-10" />

      <div className="mt-4 text-center">
        <ModalDivider />
        <Button
          type="fancy"
          className="w-full mt-8"
          size="md"
          disabled={processing}
          loading={processing}
          onClick={() => {
            if (hasValidCard) {
              updateSubscription();
            } else {
              setNewCustomerProceed(true);
            }
          }}
        >
          {t(`checkout.${hasValidCard ? 'hasValidCard' : 'newCustomer'}.cta`)}
        </Button>
        {!annualUpgrade && (
          <div className="mt-2 text-sm">
            <span className="text-type-disabled">{`or `}</span>
            <a
              className="underline cursor-pointer text-type-subdued decoration-border-mid hover:no-underline underline-offset-2"
              href="/pricing"
            >
              {t('notices.seeAllPlans')}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentInlinePlanChangeConfirmation;
