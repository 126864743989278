/** @prettier */
import * as React from 'react';
import DashboardHeader from 'blackbird/components/headers/dashboard/DashboardHeader';
import type { DashboardType, Membership } from '../../flux/stores/dashboard';
import type { UserStore } from '../../flux/stores/user';
import type { UserResponse } from '../../types/user';
import { useOnMount } from '../../helpers/useOnMount';
import { useStore } from '../../helpers/useStore';
import { ErrorBoundary } from '../shared/ErrorBoundary';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { DashboardTeamTab, DashboardTeamTabs } from './DashboardTeamTabs';
import { useTranslation } from 'react-i18next';

export const DashboardLayout: React.VFC = () => {
  const { t } = useTranslation();
  const fetched = useStore<boolean>('dashboard', (s) => s.fetched);
  const team = useStore<DashboardType | undefined>(
    'dashboard',
    (s) => s.dashboard?.team,
  );
  const personal = useStore<DashboardType | undefined>(
    'dashboard',
    (s) => s.dashboard?.personal,
  );
  const memberships = useStore<Membership[]>(
    'dashboard',
    (s) => s.dashboard?.memberships,
  );

  const user = useStore<UserResponse | undefined, UserStore>(
    'user',
    (store) => store.user,
  );

  const hasFullCollaboration = user?.has_full_collaboration ?? false;
  const isLoading = !fetched || !user || !team || !memberships || !personal;

  useOnMount(DashboardActions.fetch.defer);

  const tabs: DashboardTeamTab[] = React.useMemo(
    () =>
      !isLoading
        ? [
            {
              key: 'personalOrTeam',
              type: hasFullCollaboration ? 'team' : 'personal',
              logoUrl: hasFullCollaboration ? team.logo_url : personal.logo_url,
              caption: hasFullCollaboration ? team.heading : personal.heading,
              projects: hasFullCollaboration
                ? team.projects
                : personal.projects,
              actions: hasFullCollaboration ? team.actions : personal.actions,
              teamId: user.team_id,
            },
            ...memberships.map((m) => ({
              key: `membership-${m.heading}`,
              type: 'membership' as const,
              actions: m.actions,
              caption: m.heading,
              admin: m.admin,
              projects: m.projects,
              teamId: m.team_id,
              logoUrl: m.logo_url,
            })),
          ]
        : [],
    [user, team, personal, memberships, hasFullCollaboration, isLoading],
  );

  if (isLoading) {
    return <LoadingIndicator text={t('loaders.dashboard')} fill />;
  }

  const handleNewProject = (teamId) => {
    FlyoverActions.open({
      type: 'newProject',
      teamId: teamId === 'personalOrTeam' ? user.team_id : teamId,
    });
  };

  return (
    <ErrorBoundary>
      <DashboardHeader />

      <div className="mt-4 fullsize:mt-4">
        <DashboardTeamTabs tabs={tabs} onNewProject={handleNewProject} />
      </div>
    </ErrorBoundary>
  );
};
