/** @prettier */
import React, { useMemo, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from '@stripe/react-stripe-js';
import { OnboardingProps, OnboardingType } from './Onboarding';
import logger from 'javascripts/helpers/logger';
import { formatCurrency } from 'javascripts/helpers/currencyHelper';
import { useTranslation } from 'react-i18next';
import { RequestActions } from 'javascripts/flux/actions/request';
import Button from 'blackbird/components/button/Button';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import Banner from 'blackbird/components/feedback/banner/Banner';

const stripe = loadStripe(BoordsConfig.stripePubKey);

interface CheckoutFormProps {
  currency: string;
  unitAmount: number;
  trialLength: number;
  percentOff: number;
  name: string;
  interval: string;
}

interface FormWrapperProps extends CheckoutFormProps {
  clientSecret: string;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  currency,
  unitAmount,
  percentOff,
  interval,
  trialLength,
  name,
}) => {
  const stripe = useStripe();
  const [submitted, setSubmitted] = useState(false);
  const elements = useElements();
  const discount = percentOff > 0;

  const amount: number = discount
    ? ((unitAmount / 100) * (100 - percentOff)) / 100
    : unitAmount / 100;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'welcome.enterCC',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (!stripe || !elements) {
      setSubmitted(false);
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${BoordsConfig.DefaultHost}/welcome/tax-id`,
      },
    });

    if (result.error) {
      logger.error(result.error.message);
      RequestActions.error.defer(result.error.message);
      setSubmitted(false);
    } else {
      Track.event.defer('StartTrial', { category: 'Checkout' });
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="flex items-center justify-center flex-grow"
    >
      <fieldset className="flex flex-col flex-grow max-w-md space-y-6">
        <legend className="text-2xl text-type-primary">{t('heading')}</legend>

        <Banner
          customWidth
          className="w-full"
          kind={'error'}
          message={`${t('banner.regular', { trialLength })}`}
        />
        <span className="text-type-subdued">
          {t('subheading', {
            name,
            trialLength,
            currencyAndAmount: formatCurrency({ currency, amount }),
            interval: interval === 'year' ? 'yearly' : 'monthly',
          })}
        </span>

        {discount && (
          <Banner
            customWidth
            className="w-full"
            kind={'success'}
            message={`${t('banner.discount', { percentOff, trialLength })}`}
          />
        )}
        <div className="flex items-center px-4 py-2 border-2 rounded">
          <span className="flex-auto">{`${t('planName', { name })}`}</span>
          <span className="space-x-1 ">
            <span
              className={'line-through decoration-brand-pink text-type-subdued'}
            >{` ${formatCurrency({ currency, amount })} / ${interval} `}</span>
            <span className="font-semibold">{`${t('freePeriod', {
              trialLength,
            })}`}</span>
          </span>
        </div>
        <PaymentElement />
        <Button
          size="lg"
          type="solid"
          disabled={!stripe || submitted}
          htmlType="submit"
        >
          {submitted ? t('button.submitted') : t('button.default')}
        </Button>
      </fieldset>
    </form>
  );
};

const FormWrapper: React.FC<FormWrapperProps> = (props) => {
  const options = useMemo(() => {
    return {
      clientSecret: props.clientSecret,
    };
  }, [props.clientSecret]);

  return (
    <Elements stripe={stripe} options={options}>
      <CheckoutForm {...props} />
    </Elements>
  );
};

const getPlan = (): string | null => {
  const params = new URLSearchParams(window.location.search);
  const plan: string | null = params.get('plan')
    ? params.get('plan')
    : 'individual';
  return plan;
};

const EnterCC: React.FC<OnboardingProps> = () => {
  const [formProps, setFormProps] = useState<FormWrapperProps>({
    currency: '',
    clientSecret: '',
    name: '',
    interval: 'monthly',
    unitAmount: 0,
    trialLength: 0,
    percentOff: 0,
  });
  const [loaded, setLoaded] = useState(false);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'welcome.enterCC',
  });

  const TrialAsync = async () => {
    try {
      const plan = getPlan();
      const response = await fetch('/checkout/trial', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          data: {
            attributes: {
              plan: plan,
            },
          },
        }),
      });

      const res = await response.json();

      if (!response.ok) {
        RequestActions.error.defer(res.message);
        location.href = '/billing/stripe';
      } else {
        setFormProps({ ...res.data.attributes });
        setLoaded(true);
      }
    } catch (err) {
      logger.error(err.message);
      RequestActions.error.defer(err.message);
    }
  };

  useEffect(() => {
    if (!loaded) {
      TrialAsync();
      Track.event.defer('ob_show_payment_form', { category: 'Onboarding' });
    }
  }, [loaded]);

  if (!loaded) {
    return (
      <div className="flex items-center justify-center flex-grow">
        <div className="flex flex-col flex-grow max-w-md space-y-3">
          <LoadingIndicator text={t('loading')} />
        </div>
      </div>
    );
  } else {
    return <FormWrapper {...formProps} />;
  }
};
export default EnterCC;
