/** @prettier */
import './StoryboardExportContainer.react';
import * as React from 'react';
import { ErrorBoundary } from '../shared/ErrorBoundary';
import { hasNewPDFExport } from '../pdf/helpers/newPDFExportOptin';
import { PDFRenderer } from '../pdf/PDFRenderer';
import { SuspenseLoader } from '../shared/SuspenseLoader';

const PDFExportContainer = React.lazy(() =>
  import('../pdf/PDFExportContainer'),
);

export const StoryboardExportRolloutGate = props => (
  <ErrorBoundary>
    {hasNewPDFExport() ? (
      <SuspenseLoader>
        <PDFExportContainer renderFunction={PDFRenderer} context="pdf" />
      </SuspenseLoader>
    ) : (
      <StoryboardExportContainer {...props} />
    )}
  </ErrorBoundary>
);
