/** @format */

import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';

interface FlyoverLoadingProps {
  title?: string;
}

const FlyoverLoading: React.FC<FlyoverLoadingProps> = ({
  title = 'Loading...',
}) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldRender(true);
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return shouldRender ? (
    <div className="flex items-center justify-center h-full max-h-dvh text-center">
      <div className="w-full max-w-md p-20 bg-white shadow-md rounded-xl">
        <LoadingIndicator text={title} />
      </div>
    </div>
  ) : null;
};

(window as any).FlyoverLoading = FlyoverLoading;
