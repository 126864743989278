/** @prettier */
import React from 'react';
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Banner from 'blackbird/components/feedback/banner/Banner';
import { OnboardingProps, OnboardingType } from './Onboarding';
import { useTranslation } from 'react-i18next';
import { OnboardingFormWrapper } from './OnboardingFormWrapper';

const ResetPasswordForm: React.FC<OnboardingProps> = (props) => {
  const { notification } = props;
  const handleSubmit = (e) => {
    if (props.onResetPassword) {
      e.preventDefault();
      const form = e.target;
      const email = form['user[email]'].value;
      props.onResetPassword(email);
      return;
    } else if (!props.authenticityToken || !props.formAction) {
      e.preventDefault();
      throw new Error(
        'An authenticity token and formAction prop need to be supplied',
      );
    }
  };

  const { t } = useTranslation();

  return (
    <OnboardingFormWrapper
      title={t('accounts.resetPassword.title')}
      bottomLink={
        <div className="flex items-center justify-center mt-8 text-sm space-x-1 text-type-subdued">
          <a
            className="no-underline hover:underline text-type-primary"
            href={'/login'}
          >{`Go back`}</a>
        </div>
      }
    >
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-center flex-grow"
        method="post"
        action={props.formAction}
      >
        <input name="utf8" type="hidden" value="✓" />
        {props.authenticityToken && (
          <input
            type="hidden"
            name="authenticity_token"
            value={props.authenticityToken}
          />
        )}

        <fieldset className="flex flex-col flex-grow max-w-md space-y-8">
          {!!notification && (
            <Banner
              message={notification.message}
              kind={notification.kind}
              className="w-full-imp"
            />
          )}
          <div className="flex flex-col gap-6">
            <TextInput
              name="user[email]"
              label="Email"
              className="flex-grow-0"
              type="email"
            />
            <Button htmlType="submit" size="lg" rounded>
              {t('accounts.resetPassword.button')}
            </Button>
          </div>
        </fieldset>
      </form>
    </OnboardingFormWrapper>
  );
};
export default ResetPasswordForm;
