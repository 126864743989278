/** @format */

import React, { useContext, useRef, useMemo, useCallback } from 'react';
import { ProjectsContext } from 'blackbird/components/projects/ProjectsContext';
import { TeamContext } from 'blackbird/components/team/TeamContext';
import Button from 'blackbird/components/button/Button';
import Dialog, { ModalDivider } from 'blackbird/components/dialog/Dialog';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Icon from 'blackbird/components/icon/Icon';
import CopyIcon from 'blackbird/images/icons/copy.svg';
import { RequestActions } from 'javascripts/flux/actions/request';
import logger from 'javascripts/helpers/logger';
import Toggle from 'blackbird/components/toggle/Toggle';
import { TeamUpgradePill } from 'blackbird/components/team/TeamUpgradePill';

export const ProjectShareButton: React.FC = () => {
  const {
    activeProject,
    activeGroup,
    updateProject,
    isUpdatingProject,
    setActiveProject,
    isShareModalOpen,
    setIsShareModalOpen,
  } = useContext(ProjectsContext);
  const { teamData } = useContext(TeamContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [password, setPassword] = React.useState(
    activeProject?.share_password || '',
  );

  const handleOpenDialog = () => {
    setIsShareModalOpen(true);
  };

  const handleCloseDialog = () => {
    setIsShareModalOpen(false);
  };

  const fullShareLink = useMemo(() => {
    if (activeProject?.share_slug) {
      return `${window.location.origin}/b/${activeProject.share_slug}`;
    }
    return '';
  }, [activeProject?.share_slug]);

  const handleCopyClick = (
    ref: React.RefObject<HTMLInputElement>,
    message: string,
  ) => {
    if (ref.current) {
      ref.current.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          RequestActions.success.defer(message);
        }
      } catch (err) {
        logger.log(err);
      }
    }
  };

  const handleToggle = useCallback(
    async (field: string) => {
      if (activeProject) {
        const update = { [field]: !activeProject[field] };
        await updateProject(activeProject.id, update);

        setActiveProject((prevProject) =>
          prevProject ? { ...prevProject, ...update } : prevProject,
        );
      }
    },
    [activeProject, updateProject, setActiveProject],
  );

  if (
    (activeGroup && !activeGroup.actions.includes('projects.create')) ||
    !activeProject
  ) {
    return null;
  }

  const {
    share_slug,
    share_enabled,
    share_with_description,
    share_with_sub_projects,
    share_with_password,
    share_password,
  } = activeProject;

  return (
    <>
      <Button type="secondary" onClick={handleOpenDialog}>
        {`Share`}
      </Button>
      <Dialog
        isDark
        hideActions
        size="sm"
        title="Share link to project"
        isOpen={isShareModalOpen}
        onCloseBtnClick={handleCloseDialog}
        onEscapeOrOutsideClick={handleCloseDialog}
      >
        <div className="relative pb-4">
          <div className="mb-8">
            <ModalDivider />
          </div>
          <div className="mb-4 space-y-4">
            <div className="flex items-center justify-between">
              <span>{`Enable public link`}</span>
              {teamData?.has_shareable_projects ? (
                <Toggle
                  value={share_enabled}
                  disabled={isUpdatingProject}
                  onChange={() => handleToggle('share_enabled')}
                />
              ) : (
                <TeamUpgradePill />
              )}
            </div>
          </div>
          {teamData?.has_shareable_projects && share_enabled && share_slug && (
            <div>
              <TextInput
                inputSize="md"
                ref={inputRef}
                value={fullShareLink}
                className="bg-surface text-type-disabled"
                onClick={() => handleCopyClick(inputRef, 'Link copied')}
                readOnly
                rightComponent={
                  <Icon
                    onClick={() => handleCopyClick(inputRef, 'Link copied')}
                    icon={<CopyIcon />}
                  />
                }
              />

              <div className="mt-8 mb-4 text-xs font-semibold tracking-wider uppercase text-type-subdued">{`Link settings`}</div>

              <div className="space-y-3">
                <div className="flex items-center justify-between">
                  <span>{`Include project description`}</span>
                  <Toggle
                    disabled={isUpdatingProject}
                    value={share_with_description}
                    onChange={() => handleToggle('share_with_description')}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <span>{`Include sub-projects`}</span>
                  <Toggle
                    disabled={isUpdatingProject}
                    value={share_with_sub_projects}
                    onChange={() => handleToggle('share_with_sub_projects')}
                  />
                </div>
                {!teamData.has_password_protection ? (
                  <div className="flex items-center justify-between">
                    <span>{`Password protection`}</span>
                    <TeamUpgradePill />
                  </div>
                ) : (
                  <>
                    <div className="flex items-center justify-between">
                      <span>{`Add password protection`}</span>
                      <Toggle
                        disabled={isUpdatingProject}
                        value={share_with_password}
                        onChange={() => handleToggle('share_with_password')}
                      />
                    </div>
                    {share_with_password && (
                      <TextInput
                        inputSize="md"
                        ref={passwordRef}
                        value={share_password}
                        className="bg-surface text-type-disabled"
                        onClick={() =>
                          handleCopyClick(passwordRef, 'Password copied')
                        }
                        readOnly
                        rightComponent={
                          <Icon
                            onClick={() =>
                              handleCopyClick(passwordRef, 'Password copied')
                            }
                            icon={<CopyIcon />}
                          />
                        }
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};
