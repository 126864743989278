/** @prettier */
const PropTypes = require('prop-types')
const createReactClass = require('create-react-class')
const {ColorPicker} = require('./ColorPicker.react')

export const ColorPickerForCover = createReactClass({

  propTypes: {
    color: PropTypes.string,
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
  },

  handleChange: function(newColor) {
    CoverpageActions.updateValue({
      attr: "theme." + this.props.field,
      value: newColor
    });
  },

  render: function() {
    return (
      <ColorPicker
        label={this.props.label}
        color={this.props.color}
        onChange={this.handleChange}
        width={100}
        updateOnChange={false}
      />
    )
  },
})
