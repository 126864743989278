/** @prettier */
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { UnsplashItem } from './UnsplashItem';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import { debounce } from 'underscore';
import { StatusIndicator } from './StatusIndicator';
import { ErrorBoundary } from 'javascripts/components/shared/ErrorBoundary';
import { ImageSearchFilterItem } from './ImageSearchFilterItem';
import { ImageSearchBreadcrumb } from './ImageSearchBreadcrumb';
import { LoadMoreButton } from './LoadMoreButton';
import classNames from 'classnames';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
const errorHandler = RequestErrorHandler('unsplash', 'Unsplash');

export interface UnsplashTopic {
  title: string;
  slug: string;
  cover_photo_thumb_url: string;
  total_photos: number;
}

export interface UnsplashUser {
  name: string;
  links: {
    html: string;
  };
}

export interface UnsplashPhoto {
  id: string;
  color: string;
  urls: {
    full: string;
    raw: string;
    small: string;
    regular: string;
    small_s3: string;
    thumb: string;
  };
  user: UnsplashUser;
}

interface UnsplashSearchResponse {
  results: UnsplashPhoto[];
}

interface Props {
  /** View only a specific topic. Also hides breadcrumbs */
  topic?: UnsplashTopic;
  onItemClick?: (imageUrl: string) => void;
  className?: string;
  innerPadding?: string;
}

export const UnsplashCategories: React.FC<Props> = (
  props,
): React.ReactElement => {
  const { t } = useTranslation('imageLibrary');
  const [activeTopic, setActiveTopic] = React.useState<
    UnsplashTopic | undefined
  >(props.topic);

  const [images, setImages] = React.useState<UnsplashPhoto[] | undefined>(
    undefined,
  );
  const [orientation, setOrientation] = React.useState<string>('landscape');
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [isLoadingMore, setIsLoadingMore] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string | undefined>(
    undefined,
  );

  const perPage = 20;

  React.useEffect(() => {
    if (
      ['9x16', '9:16', '4x5', '4:5'].includes(
        StoryboardStore.getState().storyboard.frame_aspect_ratio,
      )
    ) {
      setOrientation('portrait');
    } else if (
      ['1x1', '1:1'].includes(
        StoryboardStore.getState().storyboard.frame_aspect_ratio,
      )
    ) {
      setOrientation('squarish');
    } else {
      setOrientation('landscape');
    }
  }, []);

  const FetchTopic = React.useCallback(
    async (page = 1) => {
      if (activeTopic) {
        const currentScroll = window.scrollY;

        const request = await apiRequest({
          method: 'get',
          path: `unsplash/${activeTopic.slug}.json?orientation=${orientation}&page=${page}&per_page=${perPage}`,
        });

        if (!request.ok) return errorHandler({ method: 'get' })(request);
        const response: UnsplashPhoto[] = await request.json();

        if (page > 1 && images) {
          setIsLoadingMore(false);
          window.scrollTo(0, currentScroll);
          setImages((prevImages: UnsplashPhoto[]) => [
            ...prevImages,
            ...response,
          ]);
        } else {
          setImages(response);
        }
        setHasMore(response.length === perPage);
      }
    },
    [activeTopic, images],
  );

  React.useEffect(() => {
    FetchTopic();
  }, [activeTopic]);

  const handleInputChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const RunSearch = React.useCallback(
    async (searchTerm: string, page = 1) => {
      setIsSearching(true);

      const request = await apiRequest({
        method: 'get',
        path: `unsplash.json?query=${searchTerm}&orientation=${orientation}&page=${page}`,
      });

      if (!request.ok) return errorHandler({ method: 'get' })(request);
      const response: UnsplashSearchResponse = await request.json();

      const searchImages: UnsplashPhoto[] = [];
      response.results.map((r) => {
        searchImages.push(r);
      });
      setIsSearching(false);
      setIsLoadingMore(false);

      if (page > 1 && images && searchImages) {
        setImages((prevImages: UnsplashPhoto[]) => [
          ...prevImages,
          ...searchImages,
        ]);
      } else {
        setImages(searchImages);
      }
    },
    [images],
  );

  const debouncedSearch = debounce(RunSearch, 500);

  React.useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else if (activeTopic) {
      FetchTopic();
    } else {
      setHasMore(true);
      setImages(undefined);
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm]);

  const topics: UnsplashTopic[] = t('unsplash.topics', { returnObjects: true });

  React.useEffect(() => {
    if (page > 1) {
      setIsLoadingMore(true);
      if (activeTopic) {
        FetchTopic(page);
      } else if (searchTerm) {
        RunSearch(searchTerm, page);
      }
    }
  }, [page]);

  const LoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const Images: React.FC = () => {
    return (
      <>
        {images && (
          <div>
            <div className="grid grid-cols-2 gap-4">
              {images.map((image) => (
                <div
                  className="flex items-center w-full h-full overflow-hidden rounded-sm"
                  style={{ backgroundColor: image.color }}
                  key={image.id}
                >
                  <UnsplashItem
                    table={image}
                    onClick={props.onItemClick}
                    naturalAspectRatio
                  />
                </div>
              ))}
            </div>
            {hasMore && (
              <LoadMoreButton onClick={LoadMore} loading={isLoadingMore} />
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <ErrorBoundary size="small">
      <div className={classNames('flex-auto', props.className)}>
        {activeTopic ? (
          <div className={classNames(props.innerPadding)}>
            {!props.topic && (
              <ImageSearchBreadcrumb
                title={activeTopic.title}
                noun={`Photos`}
                onClick={() => {
                  setImages(undefined);
                  setPage(1);
                  setActiveTopic(undefined);
                }}
              />
            )}

            <div className="mb-6">
              <TextInput
                inputSize="md"
                autoFocus
                disabled={isSearching}
                placeholder={`Search Photos...`}
                onChange={handleInputChange}
              />
            </div>

            {isSearching && !images ? (
              <StatusIndicator text={t('loading')} loading />
            ) : images && images.length === 0 ? (
              <StatusIndicator text={t('empty')} empty />
            ) : (
              <div>
                <Images />
              </div>
            )}
          </div>
        ) : (
          <div className="">
            <div className={classNames(props.innerPadding, 'mb-4')}>
              <TextInput
                inputSize="md"
                autoFocus
                disabled={isSearching}
                placeholder={`Search Photos...`}
                onChange={handleInputChange}
              />
            </div>
            {isSearching && !images ? (
              <StatusIndicator text={t('loading')} loading />
            ) : images && images.length === 0 ? (
              <StatusIndicator text={t('empty')} empty />
            ) : images ? (
              <div className="px-3">
                <Images />
              </div>
            ) : (
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                {topics.map((topic) => (
                  <ImageSearchFilterItem
                    title={topic.title}
                    count={topic.total_photos}
                    url={topic.cover_photo_thumb_url}
                    key={topic.slug}
                    noun={`photos`}
                    onClick={() => setActiveTopic(topic)}
                  />
                ))}
              </div>
            )}

            <div className="mt-4 text-xs text-center text-type-disabled">
              {`Powered by`}{' '}
              <a
                href="https://unsplash.com/?utm_source=boords&utm_medium=referral"
                target="_blank"
                rel="noopener noreferrer"
                className="underline moon-gray no-underline-hover"
              >{`Unsplash`}</a>
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};
