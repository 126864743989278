/** @prettier */
import classNames from 'classnames';
import * as React from 'react';
import { Hr, HrProps } from './frame_editor/toolbar/Hr';

const IconSpacerContext = React.createContext<{
  separatorVariation: HrProps['variation'];
}>({
  separatorVariation: 'default',
});

const gapClasses = {
  normal: 'gap-3',
  narrow: 'gap-2.5',
};

interface Props {
  className?: string;
  dir?: 'horizontal' | 'vertical';
  spacing?: keyof typeof gapClasses;
  separatorVariation?: HrProps['variation'];
}

export const IconSpacer: React.FC<Props> = ({
  spacing = 'normal',
  dir = 'horizontal',
  ...props
}) => {
  const context = React.useContext(IconSpacerContext);
  context.separatorVariation = props.separatorVariation;

  return (
    <div
      className={classNames(
        'items-center',
        dir === 'horizontal' ? 'flex' : 'flex-col',
        gapClasses[spacing],
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

IconSpacer.displayName = 'IconSpacer';

/**
 * Will automatically add a separator at the start or end of the group. In case
 * there are no children, no seperator will be rendered. */
export const IconSpacerGroup: React.FC<{
  separatorLocation?: 'start' | 'end';
  className?: string;
}> = ({ className, separatorLocation = 'end', children, ...props }) => {
  let hasChildren = false;
  React.Children.forEach(children, (child) => {
    if (child) hasChildren = true;
  });

  const { separatorVariation } = React.useContext(IconSpacerContext);

  return (
    <>
      {hasChildren && separatorLocation === 'start' && (
        <span className={className}>
          <Hr variation={separatorVariation} />
        </span>
      )}
      {children}
      {hasChildren && separatorLocation === 'end' && (
        <span className={className}>
          <Hr variation={separatorVariation} />
        </span>
      )}
    </>
  );
};

IconSpacerGroup.displayName = 'IconSpacerGroup';
