/**
 * @prettier
 */
module.exports = (frames, index) => {
  const lastItemGroup = frames[index - 1] ? frames[index - 1].group_id : null;
  const frame = frames[index];
  const lastItemWasGrouped = lastItemGroup === frame.group_id;
  const itemIsGrouped = !!frame.group_id;
  const nextItemIsGrouped =
    frames[index + 1] &&
    frames[index + 1].group_id &&
    frames[index + 1].group_id === frame.group_id;

  if (!itemIsGrouped) {
    return false;
  } else if (!lastItemWasGrouped && itemIsGrouped) {
    return 'start';
  } else if (itemIsGrouped && !nextItemIsGrouped) {
    return 'end';
  } else {
    return true;
  }
};
