window.PdfActions = alt.createActions(function () {

  this.generatePdf = function (obj) {
    this.dispatch(obj);
  };

  this.setLayout = function(obj) {
    this.dispatch(obj);
  };

  this.updateLocalSetting = function (obj) {
    this.dispatch(obj);
  };

  this.invalidateCache = function() {
    this.dispatch();
  };

  this.fetchPdf = function (storyboard_id) {
    this.dispatch(storyboard_id);
  };

  this.pollPdfProcessingComplete = function (storyboard_id) {
    this.dispatch(storyboard_id);
  };

});

