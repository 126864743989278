/** @prettier */
import * as React from 'react';
import Container from '../shared/Container';
import Select from 'blackbird/components/form/select/Select';
import { useTranslation } from 'react-i18next';

const handleChange = (value) => {
  NewStoryboardActions.setNewStoryboardProjectId(parseInt(value));
};

type project = Record<string, any>;

interface ProjectListProps {
  size?: 'md' | 'lg';
  new_storyboard_project: project;
  projects: project[];
}

const NewStoryboardProjectsList: React.FC<ProjectListProps> = ({
  new_storyboard_project,
  projects,
  size = 'md',
}) => {
  const { t } = useTranslation();
  const options = React.useMemo(
    () =>
      projects.map((project) => ({
        label: project.name,
        value: String(project.id),
      })),
    [projects],
  );

  if (projects.length < 2) {
    return null;
  }
  return (
    <Select
      disableInput
      size={size}
      selectContainerClassName="w-full"
      label={t('modals.new_storyboard.labels.project')}
      options={options}
      value={String(new_storyboard_project.id)}
      onChange={handleChange}
    />
  );
};

export default Container('newStoryboard')(NewStoryboardProjectsList);
