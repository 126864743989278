/** @prettier */
import * as React from 'react';
import Container from '../shared/Container';
import { IStoryboard } from '../../types/storyboard';
import Select from 'blackbird/components/form/select/Select';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'underscore';

interface SiblingStoryboards {
  storyboards: IStoryboard[] | null;
  isLoading: boolean;
  error: boolean;
}

interface Props {
  disabled?: boolean;
  projectID: number;
}

const CopyFromStoryboardUI: React.FC<
  Props & {
    project: { siblingStoryboards: SiblingStoryboards; isLoading: boolean };
  }
> = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState('');
  const projects = props.project.siblingStoryboards || {};
  const isLoading = isUndefined(projects.isLoading)
    ? true
    : props.project.isLoading;

  // Fetch when the projectID changes
  React.useEffect(
    () =>
      ProjectActions.fetchSiblingStoryboards.defer({
        projectID: props.projectID,
      }),
    [props.projectID],
  );

  const onChange = (value: string) => {
    NewStoryboardActions.setFrameFields(value);
    setValue(value);
  };
  const options = React.useMemo(
    () => [
      { label: 'Choose a storyboard…', value: '' },
      ...(projects.storyboards ?? []).map((storyboard) => ({
        label: storyboard.document_name,
        value: storyboard.slug,
      })),
    ],
    [projects],
  );
  return (
    <Select
      disableInput
      size="lg"
      selectContainerClassName="w-full"
      label={t('modals.new_storyboard.labels.copy')}
      loading={isLoading}
      disabled={isLoading || props.disabled}
      onChange={onChange}
      options={options}
      value={value}
    />
  );
};

export const CopyFromStoryboard = Container(['project'])(
  CopyFromStoryboardUI,
) as React.FunctionComponent<Props>;
