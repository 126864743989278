/** @prettier */
import { rollbar } from 'javascripts/helpers/rollbar';
import * as React from 'react';
import type { IPanelProps } from '../../shared/MultiPanel';
import type { IFrame } from '../../../types/frame';
import Button from 'blackbird/components/button/Button';
import { useTranslation } from 'react-i18next';
import Toggle from 'blackbird/components/toggle/Toggle';
import { type IFrameLabelProps } from './FrameLabelsContainer';
import { RequestActions } from 'javascripts/flux/actions/request';
import { uniq } from 'underscore';
import { downloadEvent } from 'blackbird/helpers/eventContextHelper';
import { SimplifiedHTML } from 'blackbird/components/form/richTextInput/SimplifiedHTML';
import { isFrameFieldEmpty } from 'javascripts/helpers/storyboard/frameFieldHelpers';
import { getFrameField } from 'javascripts/helpers/fieldDataHelpers';

interface IProps extends IPanelProps {
  storyboardSlug: string;
  storyboardId: number;
}

interface IHydratedProps extends IProps, IFrameLabelProps {}

const findIdenticalLabels = () => {
  try {
    /** `[frameIndex, refValue]` */
    const encountered: [number, string][] = [];
    const duplicates: string[] = [];
    const frames = FrameStore.getState().frames;

    frames.forEach((f: IFrame, frameIndex) => {
      const ref = getFrameField(f, 'reference');
      if (isFrameFieldEmpty(ref)) return;

      const duplicateAtIndex = encountered.find((e) => e[1] === ref);
      if (duplicateAtIndex) {
        const dup = frames[duplicateAtIndex[0]];
        duplicates.push(`${dup.number}. ${getFrameField(dup, 'reference')}`);
        duplicates.push(`${f.number}. ${ref}`);
      } else {
        encountered.push([frameIndex, ref]);
      }
    });

    return uniq(duplicates);
  } catch (error) {
    rollbar.error(error);
    return [];
  }
};

export const ExportBulkDownload = React.memo<IHydratedProps>(
  (props): React.ReactElement => {
    if (!props.storyboardSlug) throw new Error('needs storyboardSlug');

    const { t } = useTranslation();
    const [email, setEmail] = React.useState('');

    const BulkDownload = async () => {
      const response = await fetch(
        `/storyboards/${props.storyboardSlug}/download`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-API-KEY': BoordsConfig.AuthenticationToken,
          },
        },
      );
      if (response.ok) {
        const json = await response.json();
        setEmail(json.data.attributes.email);
        downloadEvent('zip');
      } else {
        RequestActions.error.defer('Error downloading images');
      }
    };
    const identicalLabels = findIdenticalLabels();
    const updateHeight = props.updateHeight;

    React.useEffect(() => {
      updateHeight();
    }, [identicalLabels, updateHeight]);
    return email ? (
      <div className="my-3 text-center text-type-subdued">
        {t('export.images.download_in_progress', { email: email })}
      </div>
    ) : (
      <>
        <div className="my-3">
          <Button onClick={() => BulkDownload()} className="w-full">
            {t('export.images.download_button')}
          </Button>
        </div>

        <div className="my-3 text-center text-type-subdued">
          {t('export.file_names.text')}
        </div>

        <div className="flex items-center p-4 bg-white border rounded gap-3 border-border">
          <Toggle
            value={props.coverpage.use_frame_labels_as_filenames}
            onChange={props.onFrameLabelChange}
          />
          <span>{t('export.file_names.label')}</span>
        </div>

        {identicalLabels.length > 0 &&
        props.coverpage.use_frame_labels_as_filenames ? (
          <div className="p-3 mt-3 rounded bg-surface-warning prose has-lists">
            <p>{t('export.images.duplicate_labels')}</p>
            <ul>
              {identicalLabels.map((l) => (
                // Skip markdown, because we don't want to interpret the leading
                // numbers as lists
                <SimplifiedHTML key={l} text={l} as="li" skipMarkdown />
              ))}
            </ul>
          </div>
        ) : null}
      </>
    );
  },
);

ExportBulkDownload.displayName = 'ExportBulkDownload';
