/** @prettier */

import React, { FC, HTMLAttributes, LegacyRef } from 'react';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import Spinner from '../../images/icons/spinner.svg';
import { isFunction } from 'underscore';

interface PanelProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
  placeholder?: string;
  message?: string;
  loading?: boolean;
  /** Automatically set a max height appropriate for the viewport height  */
  autoMaxHeight?: boolean;
}

const Panel = React.forwardRef<HTMLDivElement, PanelProps>((props, ref) => {
  const {
    className,
    children,
    loading,
    placeholder,
    message,
    autoMaxHeight,
    ...restProps
  } = props;
  const [maxHeight, setMaxHeight] = React.useState<string>();

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex w-full p-8 items-center justify-center">
          <Icon icon={<Spinner />} className="w-6 animate-spin" />
        </div>
      );
    }

    const filteredChildrenCount = Array.isArray(children)
      ? children.filter(Boolean).length
      : children
      ? (children as React.ReactElement).props?.children?.length ?? 1
      : 0;

    if (!filteredChildrenCount) {
      if (message) {
        return (
          <div className="flex w-full p-8 items-center justify-center text-center">
            {message}
          </div>
        );
      }

      return (
        <div className="flex w-full p-8 items-center justify-center text-sm text-icon-subdued text-center">
          {placeholder}
        </div>
      );
    }

    return children;
  };

  const updateSize = React.useCallback(
    (value) => {
      if (isFunction(ref)) ref?.(value);
      if (!value || !autoMaxHeight) return;
      const ratio =
        (value.getBoundingClientRect().top / window.innerHeight) * 100;
      const padding = 10;
      setMaxHeight(`${100 - padding - ratio}vh`);
    },
    [ref, autoMaxHeight],
  );
  const maxHeightProps = maxHeight
    ? {
        maxHeight,
      }
    : {};
  return (
    <div
      className={classNames(
        'flex flex-nowrap flex-col bg-white rounded-lg shadow-lg z-50 overflow-hidden hover:overflow-y-auto',
        className,
      )}
      ref={updateSize}
      {...restProps}
      style={{ ...props.style, ...maxHeightProps }}
    >
      {renderContent()}
    </div>
  );
});

Panel.displayName = 'Panel';
export default Panel;
