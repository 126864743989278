/** @prettier */
import * as React from 'react';
import type { FrameField } from '../../../types/storyboard';
import { SVGIcon } from '../../shared/SVGIcon';
import { eachFrameFieldMap } from '../../../helpers/eachFrameFieldMap';
import { generateFrameFieldLabel } from '../../../helpers/generateFrameFieldLabel';
import { useTranslation } from 'react-i18next';
import { compact } from 'underscore';
import {
  IconTabBar,
  type IconTabItem,
} from 'javascripts/components/shared/IconTabBar';
import classNames from 'classnames';

interface Props {
  onChange: (newValue?: string) => void;
  frame_fields: FrameField[];
  value: string | null;
}

const PlainTextIcon: React.FC<{ children: string; className?: string }> = (
  props,
) => (
  <div className={classNames(props.className, 'text-xxs')}>
    {props.children}
  </div>
);

export const FilterButtons: React.FC<Props> = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sidebar.settings.script_editor',
  });

  const handleSelect: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const name = e.currentTarget.name;
    if (!name) throw new Error('tabs have no name');
    props.onChange(name === 'all' ? undefined : name);
  };

  const tabs: IconTabItem[] = compact([
    {
      title: t('all_title'),
      icon: <PlainTextIcon>{t('all')}</PlainTextIcon>,
      name: 'all',
      onClick: handleSelect,
      active: !props.value,
    },
    ...eachFrameFieldMap(props.frame_fields, (fieldInfo, fieldId, i, label) => {
      const icon = fieldInfo.icon ? (
        <SVGIcon icon={fieldInfo.icon} />
      ) : label === 'Label' ? (
        <SVGIcon icon="label" />
      ) : (
        <PlainTextIcon>{generateFrameFieldLabel(label)}</PlainTextIcon>
      );
      return {
        icon,
        name: fieldId,
        title: t('icon_tooltip', { label: label }),
        onClick: handleSelect,
        active: fieldId === props.value,
      };
    }),
  ]);
  return <IconTabBar icons={tabs} />;
};
