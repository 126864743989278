/** @format */

import { RequestActions } from 'javascripts/flux/actions/request';
import React, { useState, useEffect, useCallback, createContext } from 'react';

interface SubdomainContextProps {
  subdomain: string;
  team_id: number;
  setSubdomain: React.Dispatch<React.SetStateAction<string>>;
  originalSubdomain: string;
  isUsed: boolean;
  submitted: boolean;
  loaded: boolean;
  canSubmit: boolean;
  setCanSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  UpdateSubdomain: (onSuccess?: () => void, teamName?: string) => Promise<void>;
}

export const SubdomainContext = createContext<
  SubdomainContextProps | undefined
>(undefined);

interface SubdomainProviderProps {
  team_id: number;
}

export const SubdomainProvider: React.FC<SubdomainProviderProps> = ({
  team_id,
  children,
}) => {
  const [subdomain, setSubdomain] = useState('');
  const [originalSubdomain, setOriginalSubdomain] = useState('');
  const [isUsed, setIsUsed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const FetchSubdomainAsync = async () => {
    const response = await fetch(`/api/subdomain/${team_id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-KEY': BoordsConfig.AuthenticationToken,
      },
    });

    if (response.status === 403) {
      // No access
      setCanSubmit(false);
    } else if (response.status === 204) {
      // No pre-existing subdomain
      setCanSubmit(true);
    } else if (response.status === 200) {
      const json = await response.json();
      setSubdomain(json.data.attributes.subdomain);
      setOriginalSubdomain(json.data.attributes.subdomain);
      setCanSubmit(true);
    }
    setLoaded(true);
  };

  useEffect(() => {
    FetchSubdomainAsync();
  }, [team_id]);

  const UpdateSubdomain = useCallback(
    async (onSuccess?: () => void, teamName?: string) => {
      setSubmitted(true);

      const endpoint = canSubmit ? 'subdomain' : 'teams';
      const payload = canSubmit
        ? {
            subdomain: subdomain,
            teamName: teamName,
          }
        : {
            name: teamName,
          };

      const response = await fetch(`/api/${endpoint}/${team_id}`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-API-KEY': BoordsConfig.AuthenticationToken,
        },
        body: JSON.stringify({
          data: {
            attributes: payload,
          },
        }),
      });

      if (response.status === 200) {
        if (onSuccess) {
          onSuccess();
        } else {
          setSubmitted(false);
          RequestActions.success.defer('Changes saved');
          window.location.reload();
        }
      } else {
        setSubmitted(false);
        RequestActions.error.defer('Error');
      }
    },
    [subdomain, team_id, canSubmit],
  );

  const VerifySubdomain = useCallback(async () => {
    setIsUsed(false);
    if (subdomain && subdomain.length > 0) {
      const response = await fetch(`/api/subdomain/${team_id}/verify`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-API-KEY': BoordsConfig.AuthenticationToken,
        },
        body: JSON.stringify({
          data: {
            attributes: {
              subdomain: subdomain,
            },
          },
        }),
      });

      if (response.status === 200) {
        setIsUsed(false);
        setCanSubmit(true);
      } else {
        setIsUsed(true);
        setCanSubmit(false);
      }
    }
  }, [team_id, subdomain]);

  useEffect(() => {
    const timeoutId = setTimeout(() => VerifySubdomain(), 300);
    return () => clearTimeout(timeoutId);
  }, [subdomain, VerifySubdomain]);

  const contextValue: SubdomainContextProps = {
    subdomain,
    setSubdomain,
    originalSubdomain,
    isUsed,
    team_id,
    submitted,
    loaded,
    canSubmit,
    setCanSubmit,
    setSubmitted,
    UpdateSubdomain,
  };

  return (
    <SubdomainContext.Provider value={contextValue}>
      {children}
    </SubdomainContext.Provider>
  );
};
