const createReactClass = require('create-react-class');
import {map,flatten,find} from 'underscore';
import ProjectRenamingContainer from './ProjectRenamingContainer';

window.ProjectName = createReactClass({

  getInitialState: function() {
    return {
      project:  this.props.project,
    };
  },

  getDefaultProps: function() {
    return {
      icon: "folder",
    };
  },

  componentDidMount: function() {
    DashboardStore.listen(this._onChange);
  },

  componentWillUnmount: function() {
    DashboardStore.unlisten(this._onChange);
  },

  _onChange: function(state) {
    //CHECKME: We might want to update through the props rather than iterating over whole store
    const projects = map(state.dashboard,(val)=>val.projects || []);
    const changedProject = find(flatten(projects),item=>item.id === this.state.project.id);
    changedProject && this.setState({project: changedProject});
  },

  componentDidUpdate(prevProps) {
    if (prevProps.archivePrompt && !this.props.archivePrompt) {
      this.setState({archiving: false});
    }
  },

  render: function() {

    const {actions} = this.props;
    const {project}= this.state;
    const hasRenamePermission = actions.includes("projects.rename");
    if (!project) {
      return <h1>Loading project...</h1>;
    }


      return <ProjectRenamingContainer
        project={project}
        clickable={false}
        hasRenamePermission={hasRenamePermission}
      />


  }
});
