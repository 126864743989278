const _ = require('underscore');
require('../actions/project_team');
const { RequestErrorHandler } = require('javascripts/helpers/request-error-handler');
const errorHandler = RequestErrorHandler('projectTeam');

(() => {
  class ProjectTeamStore {

    constructor() {

      this.team                = null;
      this.showFacesForProject = -1;

      this.bindListeners({
        handleFetch:             ProjectTeamActions.FETCH,
        handleShowFaces:         ProjectTeamActions.SHOW_FACES,
        handleFetchIfNecessary:  ProjectTeamActions.FETCH_IF_NECESSARY,
      });
    }

    handleShowFaces(project_id) {
      this.showFacesForProject = project_id;
      this.emitChange();
    }

    handleFetch(project_id) {
      this.team = false;
      this.emitChange();

      ajaxHelper.ajax({
        method: "get",
        url: "/projects/" + project_id + "/team.json",
        success: function (response) {
          this.team = response;
          this.team.active_teammates = this._findActiveTeammates(this.team.teammates);
          this.emitChange();
        }.bind(this),
        error: errorHandler({}, () => {
          this.team = false;
          this.emitChange();
        }),
      });
    }

    handleFetchIfNecessary(project_id) {
      if (!this.team) {
        this.handleFetch(project_id);
      }
    }

    _findActiveTeammates(teammates) {
      return _.filter(teammates, function(t) {
        return t.member_can_access_project == true;
      });
    }

  }

  window.ProjectTeamStore = alt.createStore(ProjectTeamStore, 'ProjectTeamStore');

})();
