const createReactClass = require('create-react-class')
window.Loading = createReactClass({
  getInitialState: function() {
    return {
      output: '.'
    };
  },

  interval: null,

  tick: function() {
    var newOutput = (this.state.output == '...') ? '' : this.state.output + '.';
    this.setState({
      output: newOutput
    });
  },

  componentDidMount: function() {
    this.interval = setInterval(this.tick, 100); // Call a method on the mixin
  },

  componentWillUnmount: function() {
    clearInterval(this.interval); // Call a method on the mixin
  },

  render: function() {
    return (
      <div className="loading--container">
        {this.state.output}
      </div>
    )
  }
});
