/** @format */

import { RequestActions } from 'javascripts/flux/actions/request';
import logger from 'javascripts/helpers/logger';
import * as React from 'react';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import CopyIcon from 'blackbird/images/icons/copy.svg';
import Icon from 'blackbird/components/icon/Icon';
import { useTranslation } from 'react-i18next';
import Toggle from '../toggle/Toggle';
import { FeatureUnavailable } from './FeatureUnavailable';

interface ISharedInviteLinkProps {
  link: string;
  teamId: number;
  isEnabled?: boolean;
  hideDetails?: boolean;
  canEnable: boolean;
}

export const SharedInviteLink: React.FC<ISharedInviteLinkProps> = ({
  link,
  teamId,
  isEnabled = true,
  hideDetails = false,
  canEnable,
}): React.ReactElement => {
  const ref = React.createRef<HTMLInputElement>();
  const { t } = useTranslation();
  const [enabled, setEnabled] = React.useState(true);

  React.useEffect(() => {
    setEnabled(isEnabled);
  }, [isEnabled]);

  const handleCopyClick = () => {
    if (enabled) {
      ref.current?.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          RequestActions.success(
            t(`common.shared_invite_link.notices.link_copied`),
          );
          Track.event.defer('copy_team_invite_link');
        } else {
          logger.warn(t(`common.shared_invite_link.notices.warning`));
        }
      } catch (err) {
        logger.log(t(`common.shared_invite_link.notices.error`));
      }
    }
  };

  const handleLinkToggle = async () => {
    const response = await fetch(`/api/teams/${teamId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-KEY': BoordsConfig.AuthenticationToken,
      },
      body: JSON.stringify({
        data: {
          attributes: {
            is_shared_invite_token_enabled: !enabled,
          },
        },
      }),
    });
    if (response.ok) {
      setEnabled(!enabled);
      // RequestActions.success(
      //   t(`common.shared_invite_link.notices.toggle_saved`),
      // );
    } else {
      RequestActions.error(t(`common.shared_invite_link.notices.toggle_error`));
    }
  };

  return !canEnable ? (
    <FeatureUnavailable featureName="shared_invite_link" align="left" />
  ) : (
    <div className="max-w-xl">
      {enabled && !hideDetails && (
        <>
          <div className="my-4 text-type-subdued">
            {t(`common.shared_invite_link.enabled_description`)}
          </div>
        </>
      )}

      <TextInput
        inputSize="md"
        ref={ref}
        value={link}
        className={`bg-surface text-type-disabled`}
        onClick={() => handleCopyClick()}
        disabled={!enabled}
        readOnly
        rightComponent={
          enabled && (
            <Icon onClick={() => handleCopyClick()} icon={<CopyIcon />}></Icon>
          )
        }
      />

      {!hideDetails && (
        <div className="flex items-center my-4 space-x-2">
          <Toggle value={enabled} onChange={() => handleLinkToggle()} />
          <span className="-mt-1">
            {t(
              `common.shared_invite_link.toggle.${
                enabled ? 'enabled' : 'disabled'
              }`,
            )}
          </span>
        </div>
      )}
    </div>
  );
};
(window as any).SharedInviteLink = SharedInviteLink;
