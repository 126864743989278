/** @prettier */
import Container from '../../shared/Container';
import * as get from 'lodash.get';

export interface IFrameLabelProps {
  coverpage: { use_frame_labels_as_filenames: boolean };
  onFrameLabelChange: (newValue: boolean) => void;
}

export const FrameLabelsContainer = Container(
  {
    coverpage: data => ({
      use_frame_labels_as_filenames:
        get(data, 'cover.storyboard.use_frame_labels_as_filenames') || false,
    }),
  },
  (storeData, ownProps) => ({
    onMount: () => {
      if (!ownProps.storyboardId)
        throw new Error('Need storyboardId prop to fetch');
      CoverpageActions.fetch.defer(ownProps.storyboardId);
    },
    onFrameLabelChange: newValue =>
      CoverpageActions.updateValue({
        attr: 'storyboard.use_frame_labels_as_filenames',
        value: newValue,
      }),
  }),
);
