const createReactClass = require('create-react-class')
const PropTypes = require('prop-types')
const ImageViewer = require('./frames/ShareableFrameViewer.react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const { FramePanelBar } = require('../shared/FramePanelBar')
const { FramePanelBarNavigator } = require('../shared/FramePanelBarNavigator');
const { PanelbarDivider } = require('../shared/PanelbarDivider');
const {MobileContext} = require("./MobileContext");
module.exports = createReactClass({
  displayName: 'ShareableMobileLayout',

  propTypes: {
    storyboard:  PropTypes.object.isRequired,
    onSetIndex:  PropTypes.func.isRequired,
    frames:      PropTypes.arrayOf(PropTypes.object).isRequired,
    activeIndex: PropTypes.number.isRequired,
    canManage:   PropTypes.bool.isRequired,
    hasBanner:   PropTypes.bool.isRequired,
  },

  mixins: [PureRenderMixin],

  handleNextClick() {
    this.props.onSetIndex(this.props.activeIndex + 1)
  },

  handlePrevClick() {
    this.props.onSetIndex(this.props.activeIndex - 1)
  },

  handleSelectChange(e) {
    this.props.onSetIndex(parseInt(e.target.value, 10))
  },

  render() {
    const currentFrame = this.props.frames[this.props.activeIndex];

    return (
      <MobileContext.Provider value={true}>
      <div className="h-dvh flex flex-col pt-[60px]">
        <div
          className={`fixed z-header left-0 right-0 bg-surface border-b border-b-border h-[60px] px-3 flex items-center`}
          style={{ top: this.props.hasBanner ? '3em' : 0 }}
        >
          <div className="text-lg text-bold mr-3 truncate">
            {this.props.storyboard.document_name}
          </div>

          {
            (
              this.props.storyboard.include_version_number && this.props.storyboard.version_count > 1
            ) && (
              <div className="text-type-subdued mr-3 nowrap">
                {this.props.storyboard.version_name.replace("Version ", "v")} of {this.props.storyboard.version_count}
              </div>
            )}
        </div>

        <ImageViewer
          frame={currentFrame}
          aspectRatio={this.props.storyboard.frame_aspect_ratio}
        />

        <div className="border-t border-t-border p-3 md:p-4">
            <FramePanelBarNavigator
              {...this.props}
              showFrameStatus={ this.props.storyboard.preferences.share_with_frame_status}
            />


          </div>
            <PanelbarDivider margin="mb-3 mt-0" />
            <FramePanelBar {...this.props}/>

       </div>
       </MobileContext.Provider>
    )
  }
});
