/** @format */
/** Does what it says… */
export function isValidUrl(string) {
  try {
    new URL(string);
  } catch (error) {
    return false;
  }
  return true;
}
