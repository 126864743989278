/** @prettier */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const PanelbarCommentsListHeading: React.FC<{ count: number }> = (
  props,
) => {
  const { t } = useTranslation();
  return (
    <div className="text-lg text-type-primary">
      {t('counters.comments', { count: props.count })}
    </div>
  );
};

(window as any).PanelbarCommentsListHeading = PanelbarCommentsListHeading;
