/** @prettier */
import React from 'react';
import Logo from 'blackbird/components/logo/Logo';
import classNames from 'classnames';

interface OnboardingFormWrapperProps {
  children: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  bottomLink?: React.ReactNode;
  subtitleClassNames?: string;
  hideBorder?: boolean;
  size?: 'sm' | 'lg' | 'xl';
}
export const OnboardingFormWrapper: React.FC<OnboardingFormWrapperProps> = ({
  children,
  title,
  subtitle,
  bottomLink,
  subtitleClassNames,
  size = 'sm',
  hideBorder = false,
}) => (
  <div className="flex items-center justify-center min-h-full">
    <div
      className={classNames(
        'w-full ',
        size === 'sm'
          ? 'max-w-md'
          : size === 'xl'
          ? 'max-w-[67rem]'
          : 'max-w-3xl',
      )}
    >
      <div className="flex justify-center">
        <Logo mark={false} className="w-48 mb-10" />
      </div>
      <div className="px-8 pt-8 pb-10 bg-white shadow-md rounded-xl ">
        <div
          className={classNames(
            'mb-8 ',
            !hideBorder && 'pb-8 border-b border-border',
          )}
        >
          <h1 className="text-3xl font-semibold text-center text-type-primary ">
            {title}
          </h1>

          {subtitle && (
            <div className="flex justify-center mt-4">
              <div
                className={classNames(
                  'max-w-sm text-center text-type-subdued',
                  subtitleClassNames,
                )}
              >
                {subtitle}
              </div>
            </div>
          )}
        </div>
        {children}
      </div>
      {bottomLink}
    </div>
  </div>
);
