/** @prettier */
import { CircleStackIcon } from '@heroicons/react/24/outline';
import React, { useContext } from 'react';
import { GeneratorContext } from './GeneratorContext';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface GeneratorTokenCountProps {
  link?: boolean;
}

export const GeneratorTokenCount: React.FC<GeneratorTokenCountProps> = ({
  link = true,
}) => {
  const { isUnpaidUser, isPanelbar, tokenCount, hasCredits, handlePurchase } =
    useContext(GeneratorContext);
  const { t } = useTranslation('generatorCopy');

  const noop = () => {
    null;
  };

  const normalizedTokenCount = tokenCount < 0 ? 0 : tokenCount;

  const hasUnlimitedTokens = BoordsConfig.IsWorkflow;

  return hasUnlimitedTokens ? null : (
    <Tooltip
      title={
        hasCredits
          ? `${normalizedTokenCount} ${isUnpaidUser ? 'free' : ''} image ${
              normalizedTokenCount === 1 ? 'credit' : 'credits'
            } remaining. ${
              isUnpaidUser
                ? 'Note that paid plan credits are only used when you insert images into storyboards, so you can experiment as much as you like.'
                : ''
            }`
          : t('noCredits')
      }
      placement="top-end"
    >
      <div
        className={classNames(
          'inline-flex items-center px-3 py-1 rounded-full  ',
          hasCredits
            ? 'border border-form'
            : 'text-brand-pink bg-brand-pink-25',
          link && 'cursor-pointer',
        )}
        onClick={link ? handlePurchase : noop}
      >
        <CircleStackIcon
          className={classNames(isPanelbar ? 'w-3 h-3 mr-1' : 'w-4 h-4 mr-2')}
        />

        <div
          className={classNames(
            '-mt-0.5 mr-0.5',
            isPanelbar ? 'text-xs ' : 'text-sm ',
          )}
        >
          {normalizedTokenCount}
        </div>
      </div>
    </Tooltip>
  );
};
