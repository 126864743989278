// Loosely based on https://stackoverflow.com/a/5573422
let supported = false;
const images = {
  basic: "data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoCAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==",
  lossless: "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAQAAAAfQ//73v/+BiOh/AAA="
};

module.exports = (() => {
  const image = new Image();

  image.crossOrigin = "anonymous";
  image.onload = () => supported = true;
  image.onerror = () => supported = false;
  image.src = images.basic;

  return () => supported;
})()
