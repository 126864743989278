import React, { useState, useEffect } from 'react'
const PropTypes = require('prop-types');

const updateAjaxCall = (args) => {
  ajaxHelper.ajax({
    method: "put",
    url: `/admin/${args.model}s/${args.id}`,
    data: args.data,
    success: function () {
      RequestActions.success.defer('Updated');
    }.bind(this),
    error: function(response, text) {
      RequestActions.error.defer(response.responseJSON.error);
    }.bind(this),
  })
}

const AdminInlineField = (props) => {
  const [fieldValue, setFieldValue] = useState()

  useEffect(() => {
    setFieldValue(props.value)
  }, [props.value])

  const saveValue = () => {
    if (fieldValue !== props.value) {
      if (fieldValue === '') {
        RequestActions.error.defer('Please enter a value');
        setFieldValue(props.value);
      } else {
        updateAjaxCall({
          model: props.model,
          id: props.id,
          data: {
            [props.model]: {
              [props.attr]: fieldValue
            }
          }
        });
      }
    }
  }

  return (
    <input
      type={'text'}
      value={fieldValue}
      autoFocus
      onBlur={() => saveValue()}
      onChange={(e) => setFieldValue(e.target.value)}
      onPaste={(e) => setFieldValue(e.target.value)}
      onCut={(e) => setFieldValue(e.target.value)}
      className="db w5 f5 pv1 ph2 ba b--silver bg-white mh2 br2"
    />
  )
}

window.AdminInlineField = AdminInlineField
