/** @format */

import { XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { FastForward } from 'lucide-react';
import React, { useContext, useEffect } from 'react';
import { WizardContext } from './WizardContext';
import { WizardListItem } from './WizardListItem';
import { WizardProgress } from './WizardProgress';

interface WizardItemsProps {
  closeable?: boolean;
  standalone?: boolean;
}

export const WizardItems: React.FC<WizardItemsProps> = ({
  closeable,
  standalone,
}) => {
  const {
    wizardItemsToDo,
    wizardItemsComplete,
    isComplete,
    isLoaded,
    isAvailable,
    tasksRemaining,
    wizardItems,
    setIsOpen,
  } = useContext(WizardContext);

  interface WizardListTitleProps {
    title: string;
  }

  useEffect(() => {
    Track.event.defer(`wizard_open`, {
      posthogCapture: true,
    });
  }, []);

  const WizardListTitle: React.FC<WizardListTitleProps> = ({ title }) => (
    <div className="mb-2 text-xs font-semibold tracking-wider uppercase opacity-50">
      {title}
    </div>
  );

  return !wizardItems || isComplete || !isAvailable || !isLoaded ? null : (
    <div
      className={classNames(
        'relative overflow-hidden',
        standalone
          ? 'bg-white border border-border rounded-xl shadow-md'
          : 'border border-decorative-blue/50 rounded-lg bg-white',
      )}
    >
      <div
        className={classNames(
          'px-5 relative z-0',
          standalone ? 'pt-5 pb-5' : 'py-5 ',
        )}
      >
        <div
          className="absolute top-0 left-0 right-0 z-10 h-2"
          style={{
            backgroundImage:
              'linear-gradient(-220deg,#FBBBCD 0%,#F4BD64 50%, #B6DEF2 100%)',
            // filter: 'blur(80px)',
          }}
        />
        <div className="flex items-center mb-2 ">
          {!standalone && (
            <>
              <div
                className={classNames(
                  'flex-auto font-semibold flex items-center',
                  closeable ? 'text-lg ' : 'text-xl',
                )}
              >
                <span>{`Quick Start`}</span>
              </div>
              {closeable && (
                <span
                  className="flex-shrink-0 cursor-pointer hover:text-type-primary text-type-disabled"
                  onClick={() => setIsOpen(false)}
                >
                  <XMarkIcon className="w-5" />
                </span>
              )}
            </>
          )}
        </div>

        <div
          className={classNames(
            'flex items-center ',
            standalone ? 'mb-2' : 'mb-8',
          )}
        >
          {!standalone && (
            <div
              className={classNames(
                'flex-shrink-0 inline-block text-center my-3 mr-4',
              )}
            >
              <WizardProgress large />
              <div className="text-sm text-type-subdued">{`still to go`}</div>
            </div>
          )}

          <div className="flex-auto">
            {standalone && (
              <div className="flex items-center px-1 mb-2 space-x-2">
                <div className="text-lg font-semibold">{`Quick Start`}</div>
                <div className="pt-0.5">
                  <FastForward className="w-6 h-6 text-brand-blue opacity-60" />
                </div>
                <div className="flex justify-end flex-auto pt-1 text-xs text-type-subdued">
                  <span>{`${wizardItems.length - tasksRemaining + 1} of ${
                    wizardItems.length
                  }`}</span>
                </div>
              </div>
            )}
            {/* <div className="text-base opacity-60">{`Create beautiful client-ready storyboards 2x faster and build your dream pre-production workflow.`}</div> */}
          </div>
        </div>

        <div className="space-y-4">
          {wizardItemsToDo && wizardItemsToDo.length > 0 && (
            <div>
              <div className="space-y-2">
                {wizardItemsToDo.map((item, idx: number) => {
                  return idx > 0 ? null : (
                    <WizardListItem key={item.id} item={item} expanded />
                  );
                })}
              </div>
            </div>
          )}

          {wizardItemsToDo && wizardItemsToDo.length > 1 && (
            <div>
              <WizardListTitle title={`To Do`} />

              <div className="space-y-2">
                {wizardItemsToDo.map((item, idx: number) => {
                  return idx > 0 ? (
                    <WizardListItem key={item.id} item={item} />
                  ) : null;
                })}
              </div>
            </div>
          )}

          {wizardItemsComplete && (
            <div>
              <WizardListTitle title={`Complete`} />
              <div className="space-y-2">
                {wizardItemsComplete.map((item) => (
                  <WizardListItem key={item.id} item={item} />
                ))}
              </div>
            </div>
          )}

          {/* {wizardItemsSkipped && wizardItemsSkipped.length > 0 && ( */}
          {/*   <div> */}
          {/*     <WizardListTitle title={`Skipped`} /> */}
          {/*     <div className="space-y-2"> */}
          {/*       {wizardItemsSkipped.map((item) => ( */}
          {/*         <WizardListItem key={item.id} item={item} /> */}
          {/*       ))} */}
          {/*     </div> */}
          {/*   </div> */}
          {/* )} */}
        </div>
      </div>
    </div>
  );
};
