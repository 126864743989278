/** @format */

import React from 'react';
import Avatar from '../avatar/Avatar';
import { getFirstCharacter } from 'javascripts/helpers/get-first-character';
import { type ProjectGroup } from './ProjectsContext';

export const filterDataUrl = (string?: string) =>
  string?.indexOf('data:') === 0 ? undefined : string;

interface ProjectsGroupLabelProps {
  group: ProjectGroup;
  showName?: boolean;
}

export const ProjectsGroupLabel: React.FC<ProjectsGroupLabelProps> = ({
  group,
  showName = true,
}) => {
  return showName ? (
    <div className="flex items-center space-x-2">
      <Avatar
        className="flex-shrink-0"
        size="xs"
        initial={getFirstCharacter(group.heading)}
        image={filterDataUrl(group.logo_url)}
        rounded={false}
      />
      <h2 className="flex-auto text-sm font-semibold truncate">
        {group.heading}
      </h2>
    </div>
  ) : (
    <Avatar
      className="flex-shrink-0"
      size="sm"
      initial={getFirstCharacter(group.heading)}
      image={filterDataUrl(group.logo_url)}
      rounded={false}
    />
  );
};
