/** @prettier */
import {
  supportedLayouts,
  PDFRendererProps,
  supportedFormats,
  PDFDocumentStyle,
  FontSettings,
} from '../types';
import { getColorScheme } from './colorSchemes';
import {
  getPDFLayoutMetrics,
  getDefaultLayoutMetrics,
} from '../PDFLayoutMetrics';
import { frameAspectRatioNormalizer } from '../../../helpers/frame-size-helper';
import type {
  IStoryboardInStore,
  AspectRatioName,
} from '../../../types/storyboard';
import * as env from '../../../helpers/env';
import {
  charsetName,
  charsetToCharsetInfo,
} from '../../../helpers/detectCharset';
import { isNumber, isNaN } from 'underscore';
import { fallbackIfLayoutNotSupported } from './availableLayouts';
import { getCoverInfo } from './getCoverInfo';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
export interface PDFRendererOverrides {
  covers?: boolean;
  format?: supportedFormats;
  layout?: supportedLayouts;
  style?: Partial<PDFDocumentStyle>;
  forceBackground?: boolean;
}

export const getPDFRendererProps = (
  storyboard: IStoryboardInStore,
  charset: charsetName = 'latin',
  fontSettings: FontSettings,
  orientation: AspectRatioName,
  overrides?: PDFRendererOverrides,
): PDFRendererProps => {
  const cover = CoverpageStore.getState().cover;
  // Temporarily fall back to storyboard if the cover store isn't loaded yet
  const coverProps = cover.storyboard ? cover.storyboard : storyboard;
  let backgroundColor: string | undefined =
    cover.theme.document_background_color?.length === 7
      ? cover.theme.document_background_color
      : undefined;

  if (overrides?.forceBackground && !backgroundColor) {
    backgroundColor = '#ffffff';
  }

  // Take the value from the overrides, or fall back to the user's selection if
  // it's a supported layout
  const layoutRequested = overrides?.layout ?? storyboard.pdf_layout;

  // Take the format from the overrides, or fall back to the user's selection
  // Ignore the letterbox/widescreen layout if the layout is list
  const format =
    overrides?.format ??
    (coverProps.use_letterbox_pdf_layout && layoutRequested !== 'list'
      ? 'widescreen'
      : 'a4');

  const frameAspectRatio = frameAspectRatioNormalizer(
    storyboard.frame_aspect_ratio,
  )!;

  const layout = fallbackIfLayoutNotSupported(
    layoutRequested,
    format,
    frameAspectRatio,
  );

  const isDev = env().development();
  const { debugMode, disableOverflow } = PdfStore.getState();

  const colorScheme = getColorScheme(backgroundColor, cover.theme.font_color);
  let footerLogoUrl = PdfFooterLogoStore.getState().response.url;
  if (footerLogoUrl.indexOf('made-with-boords-v2') > 0) {
    footerLogoUrl = colorScheme.textIsDark
      ? require('../../../../images/made-with-boords-regular.png')
      : require('../../../../images/made-with-boords-inverted.png');
  }

  const { frontCover, backCover } = getCoverInfo({
    storyboard,
    coverStoreData: CoverpageStore.getState().cover,
    customCovers: PdfCoverStore.getState().urls,
    overrides,
  });

  const charsetInfo = charsetToCharsetInfo(charset)!;

  return {
    documentFormat: format,
    footerText: storyboard.document_name,
    style: {
      layout: layout,
      ...getPDFLayoutMetrics(
        format,
        layout,
        frameAspectRatio,
        !coverProps.hide_storyboard_title || cover.theme.show_page_numbers,
      ),
      fontSize:
        isNumber(storyboard.font_size) && !isNaN(storyboard.font_size)
          ? storyboard.font_size!
          : getDefaultLayoutMetrics().fontSize,
      frameFields: storyboard.frame_fields,
      backgroundColor: backgroundColor,
      showFrameNumber: coverProps.include_frame_number_in_pdf,
      showFrameBorder: coverProps.has_frame_border,
      showLabelField: coverProps.include_label_text_in_output,
      showIcons: coverProps.include_icons_in_pdf,
      showStoryboardName: !coverProps.hide_storyboard_title,
      showVersionNumber: coverProps.include_version_number,
      showPageNumber: cover.theme.show_page_numbers,
      isGlobalRtl: charsetInfo.isRtl,
      colors: colorScheme,
      frameSize: BoordsFrameSizeHelper(storyboard.frame_aspect_ratio),
      frameAspectRatio,
      ...(overrides?.style ?? {}),
    },
    footerLogoUrl: footerLogoUrl,
    isTrialPreview: false,
    isQuickMode: true,
    isDebugMode: BoordsConfig.pdfDebugMode ?? (isDev ? debugMode : false),
    disableOverflow: isDev ? disableOverflow : false,
    fontSettings: fontSettings,
    orientation: orientation,
    charset: charsetInfo,
    frontCover,
    backCover,
  };
};
