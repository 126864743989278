/** @format */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ProjectsContext } from '../ProjectsContext';
import { Listbox } from '@headlessui/react';
import {
  ArchiveIcon,
  ArrowDown,
  ArrowUp,
  CheckIcon,
  Search,
} from 'lucide-react';
import classNames from 'classnames';
import Tooltip from '../../feedback/tooltip/Tooltip';
import { ProjectSkeletonGrid, ProjectSkeletonHeader } from '../ProjectSkeleton';
import { ProjectsEmpty } from '../empty/ProjectsEmpty';
import { ArchivedStoryboardCard } from './ArchivedStoryboardCard';
import { ArchivedProjectCard } from './ArchivedProjectCard';

type SortField = 'name' | 'deleted_at';
type SortOrder = 'asc' | 'desc';

interface SortOption {
  value: SortField;
  label: string;
}

const sortOptions: SortOption[] = [
  { value: 'name', label: 'Name' },
  { value: 'deleted_at', label: 'Date archived' },
];

export const ProjectArchive: React.FC = () => {
  const {
    setActiveProject,
    fetchArchive,
    archiveData,
    isLoadingArchive,
    archiveError,
  } = useContext(ProjectsContext);

  const [sortField, setSortField] = useState<SortField>('deleted_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchArchive();
    window.history.pushState({}, '', `/`);
    setActiveProject(null);
  }, []);

  const filteredAndSortedProjects = useMemo(() => {
    if (!archiveData) return [];
    return [...archiveData.projects]
      .filter((project) =>
        project.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      .sort((a, b) => {
        if (sortField === 'name') {
          return sortOrder === 'asc'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        } else {
          const aDate = new Date(a[sortField]).getTime();
          const bDate = new Date(b[sortField]).getTime();
          return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
        }
      });
  }, [archiveData, sortField, sortOrder, searchTerm]);

  const filteredAndSortedStoryboards = useMemo(() => {
    if (!archiveData) return [];
    return [...archiveData.storyboards]
      .filter((storyboard) =>
        storyboard.document_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
      )
      .sort((a, b) => {
        if (sortField === 'name') {
          return sortOrder === 'asc'
            ? a.document_name.localeCompare(b.document_name)
            : b.document_name.localeCompare(a.document_name);
        } else {
          const aDate = new Date(a[sortField]).getTime();
          const bDate = new Date(b[sortField]).getTime();
          return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
        }
      });
  }, [archiveData, sortField, sortOrder, searchTerm]);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  if (isLoadingArchive) {
    return (
      <div className="max-h-full overflow-hidden">
        <ProjectSkeletonHeader />
        <ProjectSkeletonGrid />
      </div>
    );
  }

  if (archiveError) {
    return <div>Error: {archiveError}</div>;
  }

  const hasResults =
    filteredAndSortedProjects.length > 0 ||
    filteredAndSortedStoryboards.length > 0;

  return (
    <div className="min-h-full bg-surface">
      <div className="flex items-center justify-center px-10 py-2 bg-brand-yellow-25 ">
        <ArchiveIcon className="w-4 h-4 mr-2 opacity-50" />
        <span className="text-sm font-semibold text-black/70 pb-0.5">{`You are viewing archived items. Please restore them to edit.`}</span>
      </div>
      <div className="px-10 pt-6 pb-4 bg-white border-b border-border">
        <h1 className="mb-4 text-2xl font-bold">{`Archive`}</h1>

        <div className="flex items-end">
          {/* Search field */}
          <div className="w-full max-w-md pb-1">
            <div className="relative w-full max-w-md">
              <input
                type="text"
                placeholder="Search archived items..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2 pl-10 border rounded-md border-border focus:outline-none focus-visible:outline-none"
              />
              <Search
                className="absolute text-gray-400 left-3 top-1/2 transform -translate-y-1/2"
                size={20}
              />
            </div>
          </div>

          {/* Sorting */}
          {hasResults && (
            <div className="flex items-center justify-end flex-auto flex-shrink-0 mb-0">
              <Listbox
                as="div"
                className="relative inline-block text-left"
                value={sortField}
                onChange={setSortField}
              >
                <Listbox.Button className="inline-flex justify-center w-full px-2 pb-1.5 pt-1 text-sm font-semibold rounded-md hover:bg-surface focus:outline-none">
                  <span>
                    {
                      sortOptions.find((option) => option.value === sortField)
                        ?.label
                    }
                  </span>
                </Listbox.Button>
                <Listbox.Options className="absolute right-0 z-40 w-40 mt-2 bg-white border shadow-lg origin-top-right rounded-md focus:outline-none border-border">
                  <div className="p-2 space-y-1">
                    <div className="px-3 pt-1.5 pb-1 text-xs font-semibold tracking-wider uppercase text-type-subdued">{`Sort by`}</div>
                    {sortOptions.map((option) => (
                      <Listbox.Option key={option.value} value={option.value}>
                        {({ selected }) => (
                          <div
                            className={classNames(
                              'flex items-center text-sm cursor-pointer relative pb-2 pt-1.5 px-3 rounded-md',
                              selected
                                ? 'bg-surface-light'
                                : ' hover:bg-surface-light',
                            )}
                          >
                            <span className="flex-auto">{option.label}</span>
                            {selected && (
                              <CheckIcon className="flex-shrink-0 w-3.5 h-3.5" />
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </div>
                </Listbox.Options>
              </Listbox>
              <Tooltip title={`Sort ascending/descending`} placement="top">
                <div
                  className="p-2 cursor-pointer rounded-md hover:bg-surface"
                  onClick={toggleSortOrder}
                >
                  {sortOrder === 'asc' ? (
                    <ArrowDown strokeWidth={2.5} className="w-4 h-4" />
                  ) : (
                    <ArrowUp strokeWidth={2.5} className="w-4 h-4" />
                  )}
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className="px-10 py-8">
        {filteredAndSortedProjects.length > 0 && (
          <>
            <div className="mb-6 text-xs font-semibold tracking-wider uppercase text-black/70">
              {`Archived Projects (${filteredAndSortedProjects.length})`}
            </div>
            <div className="mb-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxxl:grid-cols-6 gap-x-3 gap-y-5 -ml-1.5">
              {filteredAndSortedProjects.map((project) => (
                <ArchivedProjectCard key={project.id} project={project} />
              ))}
            </div>
          </>
        )}
        {filteredAndSortedStoryboards.length > 0 && (
          <>
            <div className="mb-6 text-xs font-semibold tracking-wider uppercase text-black/70">
              {`Archived Storyboards (${filteredAndSortedStoryboards.length})`}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxxl:grid-cols-6 gap-x-3 gap-y-5 -ml-1.5">
              {filteredAndSortedStoryboards.map((storyboard) => (
                <ArchivedStoryboardCard
                  key={storyboard.id}
                  storyboard={storyboard}
                />
              ))}
            </div>
          </>
        )}
        {!hasResults && (
          <div className="mt-8 text-center text-gray-500">
            <ProjectsEmpty
              title={
                searchTerm
                  ? `No matching archived items found`
                  : `No archived items found`
              }
              subtitle={
                searchTerm
                  ? `Try adjusting your search term.`
                  : `Archived projects and storyboards will appear here.`
              }
            >
              <></>
            </ProjectsEmpty>
          </div>
        )}
      </div>
    </div>
  );
};
