/** @format */

import { ArrowRight } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import { QuizContext } from './QuizContext';
import { QuizBeforeAfter } from './QuizBeforeAfter';
import { type FeatureName } from 'blackbird/components/features/Feature';
import { Rating, Ratings } from '../common/ratings/Ratings';
import classNames from 'classnames';

export const QuizProof: React.FC = () => {
  const { role, isAi, isTeam } = useContext(QuizContext);
  const [fullRole, setFullRole] = useState<string>('Independent Creators');
  const [before, setBefore] = useState<string[]>([]);
  const [after, setAfter] = useState<FeatureName[]>([]);

  useEffect(() => {
    if (!isTeam && role) {
      setFullRole(`Independent ${role}`);
    } else if (role) {
      setFullRole(role);
    } else {
      setFullRole(`Independent Creators`);
    }
  }, [isTeam, role]);

  useEffect(() => {
    const defaultBefore: string[] = [
      `Manual frame numbering`,
      `Time-consuming exports`,
      `Confusing filenames`,
    ];
    const defaultAfter: FeatureName[] = [
      `automatic-frame-numbering`,
      `export-formats`,
      `versioning-specific`,
    ];

    const updatedBefore = [...defaultBefore];
    const updatedAfter = [...defaultAfter];

    if (isTeam) {
      updatedBefore.push(`Manual file sharing`, `Scattered feedback`);
      updatedAfter.push(`real-time-updates`, `team-collaboration`);
    }

    if (isAi) {
      updatedBefore.unshift(`Poor quality drawings`, `Blank-page syndrome`);
      updatedAfter.unshift(
        'storyboard-generator-with-characters',
        'script-generator',
      );
    } else {
      updatedBefore.push(`Missing assets`, `Searching for content`);
      updatedAfter.push('dropbox-google-drive', 'image-library');
    }

    setBefore(updatedBefore);
    setAfter(updatedAfter);
  }, [isAi, isTeam]);

  return (
    <>
      <div className="flex justify-center -mt-4">
        <div className="max-w-md text-center space-x-1 text-type-primary">
          <span className="">{`Boords helps`}</span>
          <span className="font-semibold">{`thousands of`}</span>
          {isAi ? (
            isTeam ? (
              // ✅ AI ✅ Team
              <>
                <span className="font-semibold">{fullRole}</span>
                <span>{`double their storyboarding capacity`}</span>
                <span className="">{`with collaborative, AI-assisted storyboarding tools.`}</span>
              </>
            ) : (
              // ✅ AI 🛑 Team
              <>
                <span className="font-semibold">{fullRole}</span>
                <span>{`double their storyboarding capacity`}</span>
                <span className="">{`with powerful, AI-assisted storyboarding tools.`}</span>
              </>
            )
          ) : isTeam ? (
            // 🛑 AI ✅ Team
            <>
              <span className="font-semibold">{fullRole}</span>
              <span>{`double their storyboarding capacity`}</span>
              <span className="">{`with powerful, collaborative storyboarding tools.`}</span>
            </>
          ) : (
            // 🛑 AI 🛑 Team
            <>
              <span className="font-semibold">{fullRole}</span>
              <span>{`double their storyboarding capacity`}</span>
              <span className="">{`with powerful, intuitive storyboarding tools.`}</span>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center my-12 space-x-4">
        <div className="flex-auto w-1/2">
          <QuizBeforeAfter type={`before`} items={before} />
        </div>
        <div className="flex justify-center flex-shrink-0 w-6 text-type-disabled">
          <ArrowRight size={18} />
        </div>
        <div className="flex-auto w-1/2">
          <QuizBeforeAfter type={`after`} features={after} />
        </div>
      </div>
    </>
  );
};
