/* eslint no-global-assign:0, no-unused-vars:0 */
/* global __webpack_public_path__ */

import '../helpers/rollbar'
import '../../../../tmp/cache/tailwind.css'
import 'intl-pluralrules'
import 'javascripts/i18n/i18n';
import fontLoader from '../helpers/font-loader';

/**
 * Tell webpack to load the chunks from this path as well. This is set in
 * js_config.html.erb, but it has to be in this file as well, else this logic
 * is removed from the compiled code
 */
__webpack_public_path__ = BoordsConfig.packsUrl;

// This must be here, and cannot be in globals, because we need this for
// globals to be dynamically imported
import 'core-js/es6/promise';
import 'core-js/fn/object/assign';
import { onReady } from 'javascripts/helpers/onReady';
import { loadThirdPartyScripts } from 'javascripts/helpers/loadThirdPartyScripts';

const renderReactComponents = require('../helpers/renderReactComponents');

import('../globals').then(() => {
  require('../helpers/rollbar');
  require('../componentRequires');
  // This will defer loading third party scripts. It will also make sure any
  // globals (like `analytics`) will be available in the meantime
  loadThirdPartyScripts()

  onReady(function () {
    renderReactComponents();
    fontLoader.loadMainFonts()
    // Need to defer this to not cause issues with altjs
    import('../helpers/browser-update').then((e => e.checkBrowserCompatibility()));

    if (window.location.hash.startsWith("#purchase-")) {
      const revenue = (window.location.hash.split('-')[1] / 100).toFixed(2)
      window.Track.event.defer('Purchase', {
        category: 'Checkout',
        revenue: revenue,
        value: revenue
      });
      window.location.hash = "";
    }
  });
});

