/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import { ToursActions } from '../actions/tours';
import type {
  Tour,
  allTourSteps,
  TourStep,
  PersistedTour,
} from '../../tours/tourData';
import { without, isArray } from 'underscore';
import { editingTour } from '../../tours/editingTour';
import { generatorTour } from '../../tours/generatorTour';
import { organisationTour } from '../../tours/organisationTour';
import { exportTour } from '../../tours/exportTour';
import * as memoize from 'memoizee';
import { fireConfetti } from '../../helpers/confetti';
import { idleTimeout } from '../../helpers/idle-timeout';
import '../../helpers/local-state';
import '../actions/track';
import { LocalState } from '../../helpers/local-state';
import logger from 'javascripts/helpers/logger';

import { wizardAddImageTour } from 'javascripts/tours/wizardAddImageTour';
import { wizardCameraMovesTour } from 'javascripts/tours/wizardCameraMovesTour';
import { wizardAnimaticTour } from 'javascripts/tours/wizardAnimaticTour';
import { wizardInviteTour } from 'javascripts/tours/wizardInviteTour';
import { wizardNewStoryboardTour } from 'javascripts/tours/wizardNewStoryboardTour';

const availableTours = {
  generator: generatorTour,
  editing: editingTour,
  organisation: organisationTour,
  export: exportTour,
  wizardAddImage: wizardAddImageTour,
  wizardAnimatic: wizardAnimaticTour,
  wizardInvite: wizardInviteTour,
  wizardCameraMoves: wizardCameraMovesTour,
  wizardNewStoryboard: wizardNewStoryboardTour,
} as const;

const LS_KEY = 'activeTours';
const LS_COMPLETED_KEY = 'completedTours';

export type availableTourNames = keyof typeof availableTours;
export class ToursStore extends FakeAltStoreClass<ToursStore> {
  currentTours: Tour[] = [];
  currentSteps: TourStep<allTourSteps>[] = [];
  debugInDevelopment = true;

  constructor() {
    super();
    this.bindListeners({
      handleStart: ToursActions.START,
      handleAdvanceTour: ToursActions.ADVANCE_TOUR,
      handleTriggerEvent: ToursActions.TRIGGER_EVENT,
      handlePreviousStep: ToursActions.PREVIOUS_STEP,
      handleCancelTour: ToursActions.CANCEL_TOUR,
    });

    this.loadPersistedTours();
  }

  handleStart(name: availableTourNames) {
    const tour = availableTours[name];
    if (!tour) throw new Error(`could not start tour with name ${name}`);
    if (this.currentTours.find((t) => t.name === name)) return;

    // Tours with `persists` set to true will be stored in localstorage.
    // that way we can prevent showing tours the user has already completed.
    if (this.hasCompleted(name) && !tour.allowRestart) {
      return logger.log(
        `Not starting tour "${name}", because it has been completed before`,
      );
    }

    this.currentTours.push({ ...tour, currentStep: 0 });

    this.setCurrentStep();
    this.persistTours();
  }

  handleTriggerEvent(eventSymbol: symbol) {
    this.currentTours.forEach((tour) => {
      if (tour.steps[tour.currentStep]?.waitsOn === eventSymbol) {
        this.handleAdvanceTour(tour.name as availableTourNames);
      }
    });
  }

  handleAdvanceTour(name: availableTourNames) {
    this.updateTourStep(name, 1);
  }

  handlePreviousStep(name: availableTourNames) {
    this.updateTourStep(name, -1);
  }

  handleCancelTour(name?: availableTourNames) {
    const activeTourNames = this.currentTours.map((i) => i.name);
    // Because the steps don't really know what tour they're in right now,
    // we can't call this with a tour name. We typically run only one tour
    // anyway, so we'll just reset this.
    this.currentTours = [];
    this.setCurrentStep();

    // If we have a URL parameter, we want to delete it.
    history.replaceState(undefined, document.title, location.pathname);
    this.persistTours();
    this.setCompleted(...activeTourNames);
  }

  private updateTourStep(name: availableTourNames, increment: number) {
    const tourIndex = this.currentTours.findIndex((t) => t.name === name);

    if (tourIndex < 0) throw new Error('could not find tour with name ' + name);

    let tour = this.currentTours[tourIndex];
    const newStep = tour.currentStep + increment;

    if (newStep < 0) {
      // Do nothing
    } else if (tour.steps.length === newStep) {
      this.completeTour(tour);
    } else {
      tour = this.currentTours[tourIndex] = {
        ...tour,
        currentStep: newStep,
      };

      // If any component is watching the currentTours, we want to update it
      this.currentTours = [...this.currentTours];
    }

    this.setCurrentStep();
    this.persistTours();

    // Finally, we send another update event
    // This is because for some reason, some hints don't always update otherwise
    this.emitChange();
  }

  private setCurrentStep({ track = true } = {}) {
    this.currentSteps = this.currentTours.map((tour) => {
      const step = tour.steps[tour.currentStep];
      if (track) this.track(step.name);
      return step as any;
    });
  }

  private track = memoize((eventName: allTourSteps) => {
    Track.event.defer(eventName, { category: 'Tour' });
  });

  private completeTour(tour: Tour) {
    tour.onComplete?.();
    this.currentTours = without(this.currentTours, tour);
    if (tour.confetti !== false) idleTimeout(fireConfetti, 0, 500);
    this.persistTours();
    if (tour.persists) this.setCompleted(tour.name);
  }

  private persistTours() {
    const persist = this.currentTours.map<PersistedTour>((t) => ({
      name: t.name,
      currentStep: t.currentStep,
    }));

    LocalState.setValue(LS_KEY, persist);
  }

  private loadPersistedTours() {
    const persisted = LocalState.getValue<PersistedTour[]>(LS_KEY);
    if (isArray(persisted) && persisted.length) {
      this.currentTours = persisted
        .map((state) => {
          const found = availableTours[state.name];
          return found && !this.hasCompleted(state.name)
            ? {
                ...availableTours[state.name],
                currentStep: state.currentStep,
              }
            : undefined;
        })
        .filter(Boolean);

      this.setCurrentStep({ track: false });
    }
  }

  private getCompleted(): availableTourNames[] {
    const stored: availableTourNames[] | undefined =
      LocalState.getValue(LS_COMPLETED_KEY);

    return stored ?? [];
  }

  private hasCompleted(name: string) {
    return this.getCompleted().indexOf(name as availableTourNames) >= 0;
  }

  private setCompleted(...name: string[]) {
    const newValue = [...this.getCompleted(), ...name];
    LocalState.setValue(LS_COMPLETED_KEY, newValue);

    return newValue;
  }
}

(window as any).ToursStore = alt.createStore(ToursStore, 'ToursStore');
