/** @format */

import React from 'react';
import classNames from 'classnames';
import { CircleCheckIcon, CircleIcon } from 'lucide-react';
import {
  type LocalPlanData,
  type Price,
} from 'blackbird/helpers/contexts/BillingContext';
import { formatCurrency } from 'javascripts/helpers/currencyHelper';
import { type TrialPlan } from './TrialContext';

interface TrialPlanSmallProps {
  price: Price;
  plan: LocalPlanData;
  isCurrentPlan: boolean;
  onSelect: (plan: TrialPlan) => void;
}

export const TrialPlanSmall: React.FC<TrialPlanSmallProps> = ({
  price,
  plan,
  isCurrentPlan,
  onSelect,
}) => {
  const currency = BoordsConfig.Currency;
  return (
    <div
      className={classNames(
        'flex items-center px-4 pt-0 pb-1 border-2 cursor-pointer rounded-xl',
        isCurrentPlan ? 'border-brand-pink' : 'border-border',
      )}
      onClick={() => onSelect(plan.slug as TrialPlan)}
    >
      <div className="hidden sm:flex justify-center items-center flex-shrink-0 text-brand-pink mr-3.5">
        {isCurrentPlan ? (
          <CircleCheckIcon className="w-5 h-5 text-brand-pink" />
        ) : (
          <CircleIcon className="w-5 h-5 text-type-disabled" />
        )}
      </div>
      <div className="flex-auto">
        <div
          className={classNames(
            'text-base font-semibold',
            isCurrentPlan ? '' : 'text-type-subdued',
          )}
        >
          {plan.name}
        </div>
        <div className="text-xs text-type-subdued">{plan.descriptionShort}</div>
      </div>
      <div className="flex flex-col justify-start flex-grow flex-shrink-0 mt-5 ml-5 text-right">
        <div
          className={classNames(
            'text-sm',
            isCurrentPlan ? '' : 'text-type-subdued',
          )}
        >{`Free for 7 days`}</div>
        <div
          className={classNames(
            'text-xs ',
            isCurrentPlan ? 'text-type-subdued' : 'text-type-disabled',
          )}
        >{`then ${formatCurrency({
          amount: price?.amount / 100,
          currency,
        })}/mo`}</div>
        <div className="text-xs opacity-0 text-type-subdued">{`.`}</div>
      </div>
    </div>
  );
};
