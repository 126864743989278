import { PlayerPopoverTitle } from './PlayerPopoverTitle';
/** @format */
import * as React from 'react';
import { PlayerActions } from 'javascripts/flux/actions/player';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import Button from 'blackbird/components/button/Button';
import type { PlayerAudio } from 'javascripts/flux/stores/player';
import { isEmpty } from 'underscore';
import { FileButton } from '../shared/FileButton';

interface Props {
  audio: PlayerAudio;
  resetAudio: () => void;
  removeAudio: () => void;
}

export const AudioUploader: React.FC<Props> = (props) => {
  const handleFileUpload = (file: File) => {
    PlayerActions.updateAudioState.defer('uploading');
    var reader = new FileReader();
    reader.onload = () =>
      PlayerActions.uploadAudio({
        file: file,
        data: reader.result,
      });
    reader.readAsDataURL(file);
  };

  const { t } = useTranslation(undefined, { keyPrefix: 'player.audio' });

  const state = props.audio.state;
  const audioData = props.audio.data;
  const hasFile = audioData && !isEmpty(audioData?.url);

  if (state === 'working' || state === 'processing' || state === 'uploading') {
    return (
      <>
        <LoadingIndicator text={t('processingNotification')} />

        <div className="mt-2 text-center">
          <Button type="text" onClick={props.resetAudio} size="sm">
            {t('restartProcessing')}
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <PlayerPopoverTitle> {t('title')}</PlayerPopoverTitle>
      {hasFile ? (
        <>
          <span className="text-type-subdued">{t('fileName')}</span>&nbsp;
          <a
            href={audioData.url}
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {audioData.file_name}
          </a>
          <div>
            <span className="text-type-subdued">{t('duration')}</span>&nbsp;
            <span className="">
              {audioData.duration.toFixed(2) + ' seconds'}
            </span>
          </div>
        </>
      ) : null}

      <div className="my-6 gap-2 flex">
        {hasFile ? (
          <Button
            type="destructive"
            size="sm"
            onClick={PlayerActions.removeAudio}
          >
            {t('delete')}
          </Button>
        ) : null}

        <FileButton size="sm" onChange={handleFileUpload} type="secondary">
          {hasFile ? t('replace') : t('upload')}
        </FileButton>
      </div>

      <div className="text-type-subdued prose text-sm">
        <p>{t('supportedFileTypes')}</p>
        <p>{t('durationNotice')}</p>
      </div>
    </>
  );
};
