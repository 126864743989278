/**
 * @prettier
 */
const Navigo = require('navigo');
const setupAppRoutes = require('./router/app-routes');
const setupShareableRoutes = require('./router/shareable-routes');
const { activateTourIfPresentInURL } = require('./router/router-helpers');
const root = window.location.protocol + '//' + window.location.host;
const router = new Navigo(root, false);
const { onReady } = require('./onReady');

onReady(() => {
  // Don't load any routes if BoordsConfig is undefined, like on admin
  if (
    typeof BoordsConfig === 'undefined' ||
    window.location.pathname.indexOf('/styleguide') > 0
  )
    return;

  const layout = BoordsConfig.controller;
  if (layout === 'shareable') {
    setupShareableRoutes(router);
  } else {
    setupAppRoutes(router);
  }

  router.hooks({
    after: activateTourIfPresentInURL,
  });
  activateTourIfPresentInURL();
});

module.exports = router;
