/** @format */

import React, { useContext } from 'react';
import { ProjectsContext } from './ProjectsContext';
import { ProjectsGroupLabel } from './ProjectsGroupLabel';

export const ProjectsMinimizedLogo: React.FC = () => {
  const { activeGroup } = useContext(ProjectsContext);
  return !activeGroup ? null : (
    <ProjectsGroupLabel group={activeGroup} showName={false} />
  );
};
