const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const Button = require('blackbird/components/button/Button').default

window.AccountDelete = createReactClass({
  displayName: 'AccountDelete',
  propTypes: {
    user_id: PropTypes.number,
  },

  _delete(e) {
    e.preventDefault();
    if (prompt("Please type DELETE to confirm your account deletion") === "DELETE") {
      UserActions.destroy({
        user_id: this.props.user_id,
      });
    } else {
      RequestActions.error("Account not deleted");
    }
  },

  render() {
    return (
      <Button type="destructive" onClick={this._delete}>
        Delete account
      </Button>
    );
  }
})
