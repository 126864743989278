const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const OverlayListItem = require('../shared/OverlayListItem');

module.exports = createReactClass({
  displayName: 'PopupMenuItem',

  propTypes: {
    label: PropTypes.string.isRequired,
    href: PropTypes.string,
    icon: PropTypes.object,
    onClick: PropTypes.func,
    onClickProps: PropTypes.any,
    className: PropTypes.string,
    height: PropTypes.string,
    padding: PropTypes.string,
    showBorder: PropTypes.bool,
    isActive: PropTypes.bool,
    center: PropTypes.bool,
    showArrow: PropTypes.bool,
    newWindow: PropTypes.bool,
    onMouseEnter: PropTypes.func,
  },

  mixins: [PureRenderMixin],

  getDefaultProps() {
    return {
      onClick: () => {},
      showBorder: true,
      center: true,
      showArrow: false,
      height: "h50px"
    }
  },

  onClick(e) {
    if (this.props.href) {
      window.location = this.props.href;
    } else if (this.props.onClickProps) {
      this.props.onClick(this.props.onClickProps, e)
    } else {
      this.props.onClick(e)
    }

    HoverOverlayActions.closeAll();
  },

  render() {
    return (
      <OverlayListItem
        {...this.props}
        text={this.props.label}
        description={this.props.description}
        onClick={this.onClick}
        onTouchEnd={this.onClick}
        height={this.props.height}
        padding={this.props.padding}
        showArrows={this.props.showArrow}
        newWindow={this.props.newWindow}
        center={this.props.center}
        className={this.props.className}
      />
    )
  }
})
