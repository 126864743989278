window.NewProjectActions = alt.createActions(function () {

  this.receiveNewProject = function (response) {
    this.dispatch(response);
  };

  this.setProjectTeamId = function (team_id) {
    this.dispatch(team_id);
  };

  this.setProjectPrivacy = function(data) {
    this.dispatch(data);
  };

  this.create = function (data) {
    this.dispatch(data);
  };

  this.projectCreated = function (data) {
    this.dispatch(data);
  };

  this.fetchTeams = function () {
    this.dispatch();
  };

});
