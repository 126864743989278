/** @format */
import React from 'react';
import { StoryboardBreadcrumbContents } from './StoryboardBreadcrumbContents';
import { StoryboardBreadcrumbProvider } from './StoryboardBreadcrumbContext';

interface ShareableProjectContainerProps {
  storyboardId: number;
}

export const StoryboardBreadcrumbContainer: React.FC<
  ShareableProjectContainerProps
> = ({ storyboardId }) => {
  return (
    <StoryboardBreadcrumbProvider storyboardId={storyboardId}>
      <StoryboardBreadcrumbContents />
    </StoryboardBreadcrumbProvider>
  );
};
