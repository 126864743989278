/**@prettier */
/**
 * From https://github.com/samuelmeuli/font-picker-react/
 * forking didn't really make sense because it needs compilation to work.
 */
import { Listbox } from '@headlessui/react';
import {
  Category,
  Font,
  FONT_FAMILY_DEFAULT,
  FontManager,
  Options,
  OPTIONS_DEFAULTS,
  Script,
  SortOption,
  Variant,
} from '@samuelmeuli/font-manager';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import withCustomPopper, { CustomPopperProps } from 'blackbird/helpers/popper';
import classNames from 'classnames';
import logger from 'javascripts/helpers/logger';
import React, { KeyboardEvent, PureComponent, ReactElement } from 'react';

type LoadingStatus = 'loading' | 'finished' | 'error';

interface Props {
  // Required props
  apiKey: string;

  // Optional props
  activeFontFamily: string;
  onChange: (font: Font) => void;
  pickerId: string;
  families: string[];
  categories: Category[];
  scripts: Script[];
  variants: Variant[];
  filter: (font: Font) => boolean;
  limit: number;
  sort: SortOption;
  onLoad?: () => void;
}

interface State {
  expanded: boolean;
  loadingStatus: LoadingStatus;
}

/**
 * Return the fontId based on the provided font family
 */
function getFontId(fontFamily: string): string {
  return fontFamily.replace(/\s+/g, '-').toLowerCase();
}

class FontPicker extends PureComponent<Props & CustomPopperProps, State> {
  // Instance of the FontManager class used for managing, downloading and applying fonts
  fontManager: FontManager;

  static defaultProps = {
    activeFontFamily: FONT_FAMILY_DEFAULT,
    onChange: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    pickerId: OPTIONS_DEFAULTS.pickerId,
    families: OPTIONS_DEFAULTS.families,
    categories: OPTIONS_DEFAULTS.categories,
    scripts: OPTIONS_DEFAULTS.scripts,
    variants: OPTIONS_DEFAULTS.variants,
    filter: OPTIONS_DEFAULTS.filter,
    limit: OPTIONS_DEFAULTS.limit,
    sort: OPTIONS_DEFAULTS.sort,
  };

  state: Readonly<State> = {
    expanded: false,
    loadingStatus: 'loading',
  };

  constructor(props: Props & CustomPopperProps) {
    super(props);

    const {
      apiKey,
      activeFontFamily,
      pickerId,
      families,
      categories,
      scripts,
      variants,
      filter,
      limit,
      sort,
      onChange,
    } = this.props;

    const options: Options = {
      pickerId,
      families,
      categories,
      scripts,
      variants,
      filter,
      limit,
      sort,
    };

    // Initialize FontManager object
    this.fontManager = new FontManager(
      apiKey,
      activeFontFamily,
      options,
      onChange,
    );
  }

  componentDidMount = (): void => {
    // Generate font list
    this.fontManager
      .init()
      .then((): void => {
        this.setState(
          {
            loadingStatus: 'finished',
          },
          () => this.props.onLoad?.(),
        );
      })
      .catch((err: Error): void => {
        // On error: Log error message
        this.setState({
          loadingStatus: 'error',
        });
        logger.error('Error trying to fetch the list of available fonts');
        logger.error(err);
      });
  };

  /**
   * After every component update, check whether the activeFontFamily prop has changed. If so,
   * call this.setActiveFontFamily with the new font
   */
  componentDidUpdate = (prevProps: Props): void => {
    const { activeFontFamily, onChange } = this.props;

    // If active font prop has changed: Update font family in font manager and component state
    if (activeFontFamily !== prevProps.activeFontFamily) {
      this.setActiveFontFamily(activeFontFamily);
    }

    // If onChange prop has changed: Update onChange function in font manager
    if (onChange !== prevProps.onChange) {
      this.fontManager.setOnChange(onChange);
    }
  };

  /**
   * Set the specified font as the active font in the fontManager and update activeFontFamily in the
   * state
   */
  setActiveFontFamily = (activeFontFamily: string): void => {
    this.fontManager.setActiveFont(activeFontFamily);
  };

  /**
   * Generate <ul> with all font families
   */
  generateFontList = (fonts: Font[]): ReactElement => {
    const { activeFontFamily } = this.props;
    const { loadingStatus } = this.state;

    if (loadingStatus !== 'finished') {
      return <></>;
    }
    return (
      <>
        {fonts.map((font): ReactElement => {
          const fontId = getFontId(font.family);
          return (
            <Listbox.Option
              key={fontId}
              className="font-list-item cursor-pointer"
              value={font.family}
            >
              {({ active }) => (
                <div
                  id={`font-button-${fontId}${this.fontManager.selectorSuffix}`}
                  className={classNames(
                    'py-3 px-3 rounded-lg flex space-x-6 items-center',
                    {
                      'bg-surface-light': active,
                    },
                  )}
                >
                  {font.family}
                </div>
              )}
            </Listbox.Option>
          );
        })}
      </>
    );
  };

  render() {
    const {
      activeFontFamily,
      sort,
      setPopperParentRef,
      setPopperElement,
      popperStyles,
      popperAttributes,
    } = this.props;
    const { expanded, loadingStatus } = this.state;
    // Extract and sort font list
    const fonts = Array.from(this.fontManager.getFonts().values());
    if (sort === 'alphabet') {
      fonts.sort((font1: Font, font2: Font): number =>
        font1.family.localeCompare(font2.family),
      );
    }

    // Render font picker button and attach font list to it
    return (
      <div className={classNames(expanded ? 'expanded' : '', 'relative')}>
        <Listbox
          value={this.fontManager.getActiveFont().family}
          onChange={this.setActiveFontFamily}
        >
          <Listbox.Button
            ref={setPopperParentRef}
            readOnly
            as={TextInput}
            inputSize="md"
            value={activeFontFamily}
            inputClassName="truncate min-w-17"
            title={activeFontFamily}
          />
          <Listbox.Options
            style={popperStyles.popper}
            {...popperAttributes.popper}
            ref={setPopperElement}
            className="w-full bg-white border border-border z-10 overflow-y-auto"
          >
            {loadingStatus === 'finished' && this.generateFontList(fonts)}
          </Listbox.Options>
        </Listbox>
      </div>
    );
  }
}

export default withCustomPopper(FontPicker)({
  placement: 'bottom',
});
