const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
window.EditableTitle = createReactClass({

  propTypes:        {
    name:           PropTypes.string.isRequired,
    saveAction:     PropTypes.func.isRequired,
    clickable:      PropTypes.bool.isRequired,
    style:          PropTypes.string,
    showEditInput:  PropTypes.bool,
  },

  getDefaultProps: function() {
    return {
      typeClass: 'f4 avenir-heavy',
      clickable: true,
      style: 'default'
    };
  },

  getInitialState: function() {
    return {
      editing:        false,
      lastSavedName:  this.props.name,
      name:           this.props.name
    };
  },

  UNSAFE_componentWillReceiveProps: function(nextProps) {
    if (nextProps.showEditInput) {
      this.setState({editing: true});
    }
  },

  componentDidMount: function() {
    ProjectStore.listen(this._onProjectChange);
  },

  componentWillUnmount: function() {
    ProjectStore.unlisten(this._onProjectChange);
  },

  _onProjectChange: function(state) {
    if (
      state.projectIdBeingRenamed
      && state.projectIdBeingRenamed === this.props.id
    ) {
      this._enableEdit();
    }
  },

  _handleInputChange: function(e) {
    this.setState({name: e.target.value});
    var nameEl = ReactDOM.findDOMNode(this.refs.name);
    var inputEl = ReactDOM.findDOMNode(this.refs.nameEditable);
    inputEl.style.width = (nameEl.clientWidth + 20) + 'px';
  },

  onKeyPress: function(e) {
    if (e.key == 'Escape') {
      e.preventDefault();
      e.currentTarget.blur();
    }
  },

  _onBlur: function() {

    this.setState({editing: false});
    let name = this.state.name;

    if (this.state.name.length < 1) {
      name = this.state.lastSavedName;
    }

    if (this.state.name != this.state.lastSavedName) {
      this.setState({name: name, lastSavedName: name});
      this.props.saveAction(name);
    }
  },

  _clickToEdit: function(e) {
    e.preventDefault();
    if (!this.props.clickable) return;
    this._enableEdit();
  },

  _enableEdit: function() {
    if (!this.state.editing) {
      this.setState({editing: true}, function() {
        var input = ReactDOM.findDOMNode(this.refs.nameEditable);
        input.focus();
        setTimeout(function() {
          input.setSelectionRange(0, input.value.length);
        }.bind(this), 100);
      }.bind(this));
    }
  },

  render: function() {

    var instruction = "";
    if (this.props.instruction && !this.state.editing) {
      instruction = (
        <span className="editable__instruction silver f6" onClick={this._editName}>
          Click to edit your Team name
          <i className="boords-icon--edit" />
        </span>
      )
    }

    return (
      <div onClick={this._clickToEdit} className={this.props.clickable ? 'pointer' : ''}>
          <header className={"bg-brand-pink-25 border-b border-b-border-dark outline-none " + (this.state.editing ? " editable__editing" : '')}>
            <div className="editable__content w-100">
            <input className={"editable__input editable__input--" + this.props.style + ' ' + this.props.typeClass} ref="nameEditable" type="text" value={this.state.name} onChange={this._handleInputChange} onBlur={this._onBlur} onKeyDown={this.onKeyPress} />
              <div className={"editable__static " + this.props.typeClass} ref="name">{this.props.name}</div>
            </div>
          </header>
          {instruction}
        </div>
      );
  }
});
