/** @prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import * as shallowEqual from 'shallowequal';
import { StoreContext, storeContextData } from '../flux/StoreContextProvider';
import type { allTheStoreNames } from '../flux/allTheStores';
import { isUndefined } from 'underscore';

const getStoreName = (storeName) => {
  const name = storeName.slice(0, 1).toUpperCase() + storeName.slice(1);
  return name.indexOf('Store') < 0 ? name + 'Store' : name;
};

/** Look up a store in the context by its name */
const getStoreFromName = (
  storeName: string,
  context?: Partial<storeContextData>,
) => {
  if (!context)
    throw new Error(
      `context is not defined, did you pass any stores to the StoreContextProvider?`,
    );
  const output: any = context[storeName];

  if (!output) {
    if (process.env.NODE_ENV === 'test')
      return { getState: () => {}, listen: () => {} };
    throw new Error(
      `Store with name ${storeName} (or ${storeName}Store) not found. Is it added to \`allTheStores.ts?\``,
    );
  }
  return output;
};

/**
 * Our own custom React hook for getting data from the store! It's inspired
 * by the `useSelector` hook from Redux. It will listen to the store and on
 * updates will return the result of the `selector` function.
 *
 * By default, it will only cause a state update when its `equalityFn` returns
 * false. This is to prevent unnecessary updates. By default the `equalityFn`
 * is a shallowEqual comparison. You can override this if that doesn't work
 * for you (like if you need a deepEqual or you return mutable objects).
 *
 * Like with its Redux counterpart, it's recommended to return only the smallest
 * amount of data you need, and prevent creating a new object inside the
 * selector function. You can use this hook multiple times in one component
 * if you want to.
 */
export function useStore<Output = any, StoreType = any>(
  storeName: allTheStoreNames,
  /** This allows you to return only a subset of the results. As with  */
  selector: (storeData: StoreType) => Output = (i) => i as any,
  equalityFn = shallowEqual,
  dependencies?: unknown[],
) {
  storeName = getStoreName(storeName);
  const context = React.useContext(StoreContext);
  const store = getStoreFromName(storeName, context?.stores);
  const getData = () => selector(store.getState());
  const [result, setResult] = React.useState<Output>(getData() as Output);

  React.useEffect(() => {
    const update = () =>
      // We can't compare with `result` here, because it might be an old
      // version (this effect only really has access to the `result` variable
      // from the closure in which is was created) so we use the callback
      // variant of the setResult function to get the current data.
      setResult((currentData) => {
        const newData = getData();
        if (!equalityFn(newData, currentData)) {
          return newData;
        } else {
          return currentData;
        }
      });

    store.listen(update);
    return () => store.unlisten(update);
  }, [setResult]);

  if (!isUndefined(dependencies)) {
    // Normally putting an effect behind a conditional is not allowed, but
    // considering that the presence of a dependencies array is not going to
    // differ between renders (it shouldn't), and useStore is used a lot,
    // preventing an unnecessary effect might be a good idea
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      setResult(getData());
    }, dependencies);
  }

  return result;
}
