/** @prettier */
const _ = require('underscore');

/**
 * Will fire the passed callback function if the user arrives at the current
 * page by using the back button. This can cause the page to be outdated if
 * changes have been made in between loading the page and returning, so you
 * might want to reload content.
 */
export const onLoadAfterBackButton = (callback: () => any) => {
  if (
    callback &&
    window.performance?.navigation.type ===
      window.performance.navigation.TYPE_BACK_FORWARD
  ) {
    console.log(
      'Got here using the browser "Back" or "Forward" button, firing callback',
    );
    setTimeout(() => callback(), 100);
  }
};
