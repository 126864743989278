import 'fslightbox';

export const LIGHTBOX_EVENT_NAME = 'lightboxEvent'
export type LightboxEvent = CustomEvent<{
  open: boolean
}>

export interface FsLightbox {
  props: {
    sources: unknown[]
    exitFullscreenOnClose: boolean
    disableLocalStorage: boolean
    onClose: () => void
    onOpen: () => void
  },
  open: () => void
}

export const openLightbox = (...sources: string[]):FsLightbox => {
  const constructedEls:HTMLIFrameElement[] = [];

  const parsed = sources.map((source, i) => {
    // Vimeo isn't supported out of the box, hence this custom source
    if (source.indexOf('vimeo.com') > 0) {
      const id = `vimeo` + (i + 1)
      const iframe = document.createElement('iframe');
      const videoId = source.match(/vimeo\.com\/([0-9]+)/)?.[1]
      if (!videoId) throw new Error('Could not detect video id')
      iframe.src = `https://player.vimeo.com/video/${videoId}?byline=0&portrait=0&autoplay=1`;
      iframe.id=id
      iframe.width="1920px"
      iframe.height = "1080px"
      iframe.allow="autoplay; fullscreen"
      iframe.allowFullscreen = true;

      document.body.appendChild(iframe)
      constructedEls.push(iframe)
      return iframe
    } else {
      return source
    }
  })

  const lightbox:FsLightbox = new (window as any).FsLightbox();
  lightbox.props.sources = parsed;
  lightbox.props.exitFullscreenOnClose = true;
  lightbox.props.disableLocalStorage = true;

  lightbox.props.onClose = () => {
    const event: LightboxEvent = new CustomEvent(LIGHTBOX_EVENT_NAME, {
      detail: {open: false}
    })
    document.body.dispatchEvent(event)
  }
  lightbox.props.onOpen = () => {
    const event: LightboxEvent = new CustomEvent(LIGHTBOX_EVENT_NAME, {
      detail: {open: true}
    })
    document.body.dispatchEvent(event)
  }

  lightbox.open();
  return lightbox
}
