/**@prettier */
import React, { useContext } from 'react';
import { TabsContext } from './Tabs';
import TabList from './TabList';

const TabBar: React.FC<{ className?: string }> = (props) => {
  const tabsContext = useContext(TabsContext);
  if (!tabsContext) {
    return <></>;
  }
  const { children, className } = props;
  const { selectedTab, type } = tabsContext;

  return (
    <div className={className}>
      {typeof children === 'function' ? (
        children({ TabList, selectedTab })
      ) : (
        <TabList />
      )}
    </div>
  );
};
export default TabBar;
