/** @format */

import React, { useContext, useState, useEffect } from 'react';
import { addDays, format } from 'date-fns';
import { CheckIcon, BellIcon, CrownIcon } from 'lucide-react';
import Button from 'blackbird/components/button/Button';
import Logo from 'blackbird/components/logo/Logo';
import { TrialContext, TrialProvider } from './TrialContext';
import { PricingTestimonials } from 'blackbird/components/pricing/PricingTestimonials';
import { SignupLogos } from '../pages/onboarding/SignupLogos';
import TextInput from '../form/text-input/TextInput';
import classNames from 'classnames';
import { TrialPlans } from './TrialPlans';
import { OnboardingFormWrapper } from '../pages/onboarding/OnboardingFormWrapper';

interface TrialDates {
  startDate: Date;
  reminderDate: Date;
  endDate: Date;
}

const useTrialDates = (): TrialDates => {
  const [trialDates, setTrialDates] = useState<TrialDates>({
    startDate: new Date(),
    reminderDate: new Date(),
    endDate: new Date(),
  });

  useEffect(() => {
    const start = new Date();
    const reminder = addDays(start, 4);
    const end = addDays(start, 7);
    setTrialDates({
      startDate: start,
      reminderDate: reminder,
      endDate: end,
    });
  }, []);

  return trialDates;
};

export const Timeline: React.FC = () => {
  const trialDates = useTrialDates();
  return (
    <div className="relative space-y-0">
      <div className="absolute top-0 bottom-0 w-0.5 bg-black left-[0.68rem]"></div>
      <div className="absolute top-full w-0.5 border-l-2 border-dashed border-black left-[0.68rem] h-4"></div>
      <div className="relative flex items-start pb-6 space-x-4">
        <div className="z-10 flex items-center justify-center w-6 !h-6 leading-none rounded-full bg-brand-pink flex-shrink-0">
          <CheckIcon strokeWidth={3} className="w-3 h-3 text-white" />
        </div>
        <div className="-mt-0.5">
          <p className="font-semibold">
            {`Today - Start your free 7-day trial`}
          </p>
          <p className="text-sm text-type-subdued">
            {`Add a payment method to start your trial.`}
          </p>
        </div>
      </div>
      <div className="relative flex items-start pb-6 space-x-4">
        <div className="z-10 flex items-center justify-center w-6 !h-6 leading-none rounded-full bg-black flex-shrink-0">
          <BellIcon strokeWidth={3} className="w-3 h-3 text-white" />
        </div>
        <div className="-mt-0.5">
          <p className="font-semibold">
            {format(trialDates.reminderDate, 'd MMMM')}
          </p>
          <p className="text-sm text-type-subdued">
            {`We'll remind you 3 days before your trial ends.`}
          </p>
        </div>
      </div>
      <div className="relative flex items-start space-x-4">
        <div className="z-10 flex items-center justify-center w-6 !h-6 leading-none rounded-full bg-black flex-shrink-0">
          <CrownIcon strokeWidth={3} className="w-3 h-3 text-white" />
        </div>
        <div className="-mt-0.5">
          <p className="font-semibold">
            {format(trialDates.endDate, 'd MMMM')}
          </p>
          <p className="text-sm text-type-subdued">
            {`Your subscription starts, unless you cancel.`}
          </p>
        </div>
      </div>
    </div>
  );
};
