/** @format */

import React from 'react';
import ContextMenu from './ContextMenu';
import MoreOptionsIcon from '../../images/icons/menu-vertical.svg';

interface MoreOptionsProps {
  options: ContextMenuOption[];
  /**
   * If true , it removes focus from the button after the context menu is closed
   */
  blurOnClose?: boolean;
  className?: string;
  size?: 'normal' | 'sm';
  iconClassName?: string;
  onVisibilityChange?: (open?: boolean) => void;
  panelClassName?: string;
}
const MoreOptions = ({
  options,
  blurOnClose,
  iconClassName,
  className,
  panelClassName,
  onVisibilityChange,
  size,
}: MoreOptionsProps) => (
  <ContextMenu
    icon={<MoreOptionsIcon />}
    rounded
    size={size}
    options={options}
    blurOnClose={blurOnClose}
    iconClassName={iconClassName}
    className={className}
    panelClassName={panelClassName}
    onVisibilityChange={onVisibilityChange}
  />
);

export default MoreOptions;
