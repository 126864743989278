const createReactClass = require('create-react-class')
window.FetchUserDataComponent = createReactClass({

  componentDidMount: function() {
    UserActions.fetch();
  },

  render: function() {
    return null;
  }

});


