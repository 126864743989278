const _ = require('underscore');

const letters = 'abcdefghijklmnopqrstuvwxyz';
const getLetter = index => {
  if (index >= letters.length) {
    const letterAmount = Math.floor(index / letters.length) - 1;
    return letters[letterAmount] + letters[index % letters.length]
  } else {
    return letters[index % letters.length]
  }
}

module.exports = frames => {
  let lastFrame;
  let i = 0;
  let groupCounter = 0;

  frames.forEach(frame => {
    const isInGroup = !!frame.group_id
    const lastFrameInGroup = lastFrame && !!lastFrame.group_id && lastFrame.group_id === frame.group_id;

    if (isInGroup) {
      if (!lastFrameInGroup) {
        groupCounter = 0;
        i++
      }
    } else {
      i++
    }

    frame.number = String(isInGroup ? i + getLetter(groupCounter) : i);
    lastFrame = frame

    if (isInGroup) {
      groupCounter++
    }
  })
  return frames;
}
