/** @format */

import classNames from 'classnames';
import React from 'react';
import Avatar, { AvatarProps } from 'blackbird/components/avatar/Avatar';
import { getFirstCharacter } from 'javascripts/helpers/get-first-character';
import { ConditionalTooltip, TooltipProps } from '../feedback/tooltip/Tooltip';
import { isObject } from 'underscore';

interface Props {
  text: string;
  className?: string;
  avatarSize?: AvatarProps['size'];
  /** Which image to display */
  avatar?: AvatarProps['image'];
  selected?: boolean;
  hasAvatar?: boolean;
  tooltip?: string | TooltipProps;
}

const TabCaption: React.FC<Props> = (props) => {
  const {
    text = '',
    selected,
    className = 'text-lg',
    avatarSize = 'xs',
    hasAvatar = true,
  } = props;

  const tooltipProps: TooltipProps = !isObject(props.tooltip)
    ? { title: props.tooltip }
    : props.tooltip;

  return (
    <ConditionalTooltip distance={10} placement="top" {...tooltipProps}>
      <div className="flex items-center gap-3">
        <div className="relative">
          {!selected && (
            <div className="absolute inset-0 z-10 rounded-full mix-blend-color bg-icon-disabled"></div>
          )}
          {hasAvatar && (
            <Avatar
              initial={getFirstCharacter(text)}
              size={avatarSize}
              image={props.avatar}
              className={classNames('relative flex-shrink-0 mt-1.5', {
                'opacity-50 text-icon': !selected,
              })}
            />
          )}
        </div>
        <span className={className}>{text}</span>
      </div>
    </ConditionalTooltip>
  );
};
export default TabCaption;
