/** @format */

import * as React from 'react';
import { ArchiveActions } from 'javascripts/flux/actions/archive';
import type {
  ArchiveServerResponse,
  ArchiveStore,
} from 'javascripts/flux/stores/archive';
import { useOnMount } from 'javascripts/helpers/useOnMount';
import { useStore } from 'javascripts/helpers/useStore';
import type { UserResponse } from 'javascripts/types/user';
import { useTranslation } from 'react-i18next';
import Button from 'blackbird/components/button/Button';
import Dialog from 'blackbird/components/dialog/Dialog';
import * as navigateToRoute from 'javascripts/helpers/router/navigate-to-route.js';
import Tabs from 'blackbird/components/tabs/Tabs';
import { usePersistedState } from 'javascripts/helpers/usePersistedState';
import type { TabProps } from 'blackbird/components/tabs/types';
import { ArchivedProjects } from './ArchivedProjects';
import { ArchivedStoryboards } from './ArchivedStoryboards';

const handleUpgrade = () => navigateToRoute('pricing');
const handleClose = () => FlyoverActions.close();

type TabValues = 'storyboards' | 'projects';
const tabs: TabProps<TabValues>[] = [
  { key: 'storyboards', caption: 'Storyboards' },
  { key: 'projects', caption: 'Projects' },
];

export const ArchivedContainer: React.FC = () => {
  useOnMount(ArchiveActions.fetch.defer);
  const { t } = useTranslation(undefined, { keyPrefix: 'modals.archive' });

  const user = useStore<UserResponse | undefined, ArchiveStore>(
    'archive',
    (s) => s.user,
  );
  const fetched = useStore<boolean, ArchiveStore>('archive', (s) => s.fetched);
  const archive = useStore<ArchiveServerResponse | undefined, ArchiveStore>(
    'archive',
    (s) => s.archive,
  );

  const [selectedTab, setSelectedTab] = usePersistedState<TabValues>(
    'archiveTab',
    'storyboards',
  );

  let component: React.ReactNode;
  let title = 'Archive';
  let fullHeight = false;

  if (!fetched || !user || !archive) {
    return <FlyoverLoading />;
  }

  const unavailable = user.is_free && !user.is_paid_team_member;

  if (unavailable) {
    title = t('unavailable.title');
    component = (
      <div className="flex flex-col gap-10 items-center">
        <div className="text-type-subdued">{t('unavailable.description')}</div>

        <Button size="lg" className="w-full" onClick={handleUpgrade}>
          {t('unavailable.button')}
        </Button>
      </div>
    );
  } else {
    fullHeight = true;
    component =
      selectedTab === 'projects' ? (
        <ArchivedProjects user={user} projects={archive.projects} />
      ) : (
        <ArchivedStoryboards storyboards={archive.storyboards} />
      );
  }

  return (
    <Dialog
      onCancel={handleClose}
      fullHeight={fullHeight}
      title={title}
      isOpen
      isDark
      hideActions
    >
      {!unavailable && (
        <Tabs
          key={selectedTab}
          tabClassName="text-[18px] pb-3"
          // compensate for outer margin
          tabListClassName="-mx-6 pt-3 bg-white"
          tabs={tabs}
          defaultValue={selectedTab}
          onSelect={(tab) => setSelectedTab(tab as TabValues)}
          // compensate for spacing at the top
          className="sticky -top-10 bg-white z-50"
        />
      )}
      {component}
    </Dialog>
  );
};
