/** @format */
import { extendOwn } from 'underscore';

interface Props {
  enabled?: boolean;
  /** The amount of times within `timeRange` that attempts can be made */
  limit?: number;
  /** The amount of time in seconds in which the `limit` can not be exceeded */
  timeRange?: number;
}

export class RateLimiter implements Props {
  _attempts: number[] = [];
  enabled = true;
  limit = 2;
  timeRange = 60;

  constructor(props?: Props) {
    extendOwn(this, props ?? {});
  }

  logAttempt() {
    if (this.isExceeded() && this.enabled) throw Error('rate limit exceeded');
    this._attempts.push(Date.now());
  }

  isExceeded(): boolean {
    if (!this.enabled) return false;
    const target = Date.now() - this.timeRange * 1000;
    const firstWithinTimeRange = this._attempts.findIndex((i) => i >= target);
    if (firstWithinTimeRange < 0) return false;

    // Delete all items that are not within the time range
    this._attempts.splice(0, firstWithinTimeRange);

    return this._attempts.length >= this.limit;
  }
}
