/** @prettier */
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import classNames from 'classnames';
import { TeamManagementActions } from 'javascripts/flux/actions/team_management';

const { default: Toggle } = require('blackbird/components/toggle/Toggle');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');

window.ProjectTeammateAccessToggleButton = createReactClass({
  displayName: 'ProjectTeammateAccessToggleButton',

  propTypes: {
    canAccess: PropTypes.bool.isRequired,
    membershipId: PropTypes.string.isRequired,
    projectId: PropTypes.number.isRequired,
    teamId: PropTypes.string.isRequired,
  },

  getInitialState: function () {
    return {
      processing: false,
    };
  },

  _toggleProjectAccess: function (newAccess) {
    if (!this.state.processing) {
      this.setState({
        processing: true,
      });

      TeamManagementActions.toggleProjectAccess({
        project_id: this.props.projectId,
        membership_id: this.props.membershipId,
        team_id: this.props.teamId,
        newAccess: newAccess,
      });
    }
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {
    if (this.state.processing) {
      this.setState({
        processing: false,
      });
    }
  },

  render() {
    const canAccess = this.props.canAccess;

    return (
      <div className="flex items-center">
        <div
          className={classNames(
            '-mt-1 mr-2 text-sm ',
            canAccess ? 'text-type-primary' : 'text-type-subdued',
          )}
        >
          {canAccess ? `Has access` : `No access`}
        </div>
        <Tooltip
          title={canAccess ? `Disable project access` : `Allow project access`}
        >
          <div className="relative">
            <Toggle
              value={canAccess}
              disabled={this.state.processing}
              onChange={this._toggleProjectAccess}
            />
          </div>
        </Tooltip>
      </div>
    );
  },
});
