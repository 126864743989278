/** @prettier */
import * as fitjs from 'fit.js';
import { Base64FrameImagesResult, DocInfo } from '../types';
import { rollbar } from '../../../helpers/rollbar';

interface Props {
  imageInfo: Base64FrameImagesResult;
  width?: number;
  height?: number;
  x: number;
  y: number;
  maxWidth?: number;
  maxHeight?: number;
  compression?: string;
  originX?: 'left' | 'right';
  originY?: 'top' | 'bottom' | 'middle';

  border?: string;
}

export const createPDFImage = ({ doc }: DocInfo, props: Props) => {
  const { imageInfo } = props;
  let hasError = false;
  const dimensions = fitjs(
    { width: imageInfo.width, height: imageInfo.height },
    {
      width: props.width || props.maxWidth || 999999,
      height: props.height || props.maxHeight || 9999,
    },
  );

  if (!['JPEG', 'PNG', 'WEBP', 'GIF'].includes(imageInfo.type)) {
    throw new Error('Unsupported image type: ' + imageInfo.type);
  }

  let x = props.x;
  let y = props.y;

  if (props.originX === 'right') x -= dimensions.width;
  if (props.originY === 'bottom') y -= dimensions.height;
  if (props.originY === 'middle') y -= dimensions.height / 2;

  const compression = props.compression || 'FAST';

  try {
    doc.addImage(
      imageInfo.image,
      imageInfo.type,
      x,
      y,
      dimensions.width,
      dimensions.height,
      imageInfo.originalUrl,
      compression,
    );
  } catch (error) {
    hasError = true;
    console.warn('could not load image', imageInfo.originalUrl);
    rollbar.warn('[PDFIMAGE] Could not load image', error);
  }

  if (props.border || hasError) {
    doc.setDrawColor(props.border ?? '#E0E0E0');
    doc.rect(props.x, props.y, dimensions.width, dimensions.height, 'S');
  }

  return {
    width: dimensions.width,
    height: dimensions.height,
    x,
    y,
  };
};
