/** @format */
import {
  type ErrorHandlerInstanceOptions,
  RequestErrorHandler,
  httpMethods,
} from 'javascripts/helpers/request-error-handler';

type APIRequestMethod = 'get' | 'post' | 'put' | 'delete';

interface APIRequestArgs {
  path: string;
  method: APIRequestMethod;
  payload?: object | undefined;
}

export const apiRequest = async ({ path, method, payload }: APIRequestArgs) => {
  const requestParameters: RequestInit = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-API-KEY': BoordsConfig.AuthenticationToken,
    },
  };

  if (payload) {
    requestParameters.body = JSON.stringify({
      data: {
        attributes: payload,
      },
    });
  }
  const response = await fetch(`/api/${path}`, requestParameters);

  return response;
};

/** Shortcut function for handling {@link apiRequest} errors. Please use the
regular {@link RequestErrorHandler} if you end up using multiple error handlers
in a file or need more options. */
export const reportApiRequestError = (
  response: Response,
  options: {
    /** Required to display appropriate user messages, as the HTTP method
     * cannot be inferred from the {@link Response} (Try using the `ajax`
     * helpers for a smoother experience) */
    method: httpMethods;
  } & ErrorHandlerInstanceOptions,
) => {
  if (response.ok) return;
  const errorHandler = RequestErrorHandler('apiRequest');
  errorHandler(options)(response);
};
