/** @format */
import { type currentUserFeaturesType } from 'javascripts/flux/stores/user_permissions';
import * as React from 'react';
import { PricingTable } from './PricingTable';
// import { useFeatureFlagVariantKey } from 'posthog-js/react';
// import { posthog } from 'posthog-js';
import { PricingProvider } from './PricingContext';
import SaleProvider from '../sale/SaleContext';

export interface PricingContainerProps {
  triggeredFromFeatureName?: currentUserFeaturesType;
}

export const PricingContainer: React.FC<PricingContainerProps> = (props) => {
  const { triggeredFromFeatureName } = props;
  // const variant = useFeatureFlagVariantKey('appPricingTableTitle');
  // posthog.featureFlags.override({ appPricingTableTitle: 'shortcut_dramatic' });

  React.useEffect(() => {
    Track.event.defer('inline_pricing_load', {
      posthogCapture: true,
      context: triggeredFromFeatureName,
    });
    Track.event.defer('ViewContent', {
      content_name: 'inlinePricing',
    });
  }, [triggeredFromFeatureName]);

  return (
    <SaleProvider>
      <PricingProvider>
        <PricingTable
          title={`Get The Shortcut To Effective Storyboards`}
          subtitle={`Dramatically increase your pre-production capacity & efficiency`}
          {...props}
        />
      </PricingProvider>
    </SaleProvider>
  );
};
