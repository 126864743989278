/** @format */

import React from 'react';
import Dialog from 'blackbird/components/dialog/Dialog';
import { GuidelineCharacterForm } from 'blackbird/components/generator/guidelines/GuidelineCharacterForm';
import { GuidelineBetaBadge } from 'blackbird/components/generator/guidelines/GuidelineBetaBadge';
import { GeneratorCharatcerContext } from 'blackbird/components/generator/guidelines/GeneratorCharacterContext';
import { GeneratorContext } from 'blackbird/components/generator/GeneratorContext';

export const FlyoverCharacterEditor: React.FC = () => {
  const { setIsCharacterEditorOpen } = React.useContext(
    GeneratorCharatcerContext,
  );
  const { isPanelbar } = React.useContext(GeneratorContext);
  return (
    <Dialog
      title={`Character Guideline Editor`}
      subtitle={
        <span className="text-type-primary">
          <GuidelineBetaBadge size="lg" />
        </span>
      }
      size={'lg'}
      isOpen
      isDark
      keepOpen
      hideActions
      onCloseBtnClick={() => {
        if (isPanelbar) {
          setIsCharacterEditorOpen(false);
        } else {
          setIsCharacterEditorOpen(false);
          FlyoverActions.close.defer(0);
        }
      }}
    >
      <GuidelineCharacterForm />
    </Dialog>
  );
};

(window as any).FlyoverCharacterEditor = FlyoverCharacterEditor;
