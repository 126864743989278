/** @format */
import { fabric } from 'fabric';
import type {
  FrameEditorLayerData,
  XYCoordinates,
} from 'javascripts/components/frame_editor/types';
import type { CommentAnnotationData } from 'javascripts/flux/stores/comment';
import { annotationToolLocalState } from 'javascripts/helpers/local-state';
import { undoManager } from 'javascripts/helpers/undo-stacks';
import { FrameDrawingEngine } from './FrameDrawingEngine';
import * as shallowEqual from 'shallowequal';
import * as shapes from 'javascripts/components/frame_editor/shapes';
import type { frameAspectRatio } from 'javascripts/types/storyboard';
import type { WidthAndHeight } from 'javascripts/types/frame';

// prettier-ignore
export type annotationToolMode = 'select' | 'draw' | 'arrow' | 'straightBrush' | 'eraser' | 'draw';

export interface AnnotationID {
  /** A reference to the frame, we need this in some cases (e.g focus) where we
   * might want to restore an annotation after page reload, but only on the
   * frame the annotation belongs to */
  frameId: number | null;
  commentId: number;
}

/** Starting options, A.K.A the document info */
export interface AnnotationToolEngineProps {
  interactive: boolean;
  /** When it's null, we're editing a new annotation */
  annotation: CommentAnnotationData | null;
  /** Typically comment id */
  id: AnnotationID | null;
  /** Background to display */
  background?: string;
  showBackground?: boolean;
}

// Keep this state here, push out some change when it changes internally, but
// also allow receiving the props
export interface AnnotationToolSettings {
  mode: annotationToolMode;
  color: string;
  strokeWidth: number;
}
export class AnnotationToolEngine extends FrameDrawingEngine<
  AnnotationToolSettings,
  FrameEditorLayerData
> {
  outputFormat = 'js' as const;
  name: 'AnnotationTool';

  defaultOptions: Partial<AnnotationToolSettings> = {
    color: '#0000ff',
    strokeWidth: 8,
    ...(annotationToolLocalState.getValue() ?? {}),
    mode: 'draw',
  };

  currentFile: AnnotationToolEngineProps;

  constructor(
    element: HTMLElement,
    aspectRatio: frameAspectRatio,
    annotation: AnnotationToolEngineProps,
    dimensions: WidthAndHeight,
  ) {
    super(element, aspectRatio, dimensions);
    this.loadFile(annotation);
    if (process.env.NODE_ENV === 'development') {
      (window as any).annotationTool = this;
    }
  }

  loadFile(
    annotation: AnnotationToolEngineProps,
    settings?: AnnotationToolSettings,
  ) {
    if (shallowEqual(annotation, this.currentFile)) return;

    if (this.currentFile && annotation) {
      this.unmount(false);
    }

    this.currentFile = annotation;
    this.canvasClass = annotation.interactive
      ? fabric.Canvas
      : fabric.StaticCanvas;

    this.undoContext = annotation.interactive
      ? undoManager.newUndoContext('annotationTool')
      : undefined;

    const options: Partial<AnnotationToolSettings> = {
      ...settings,
      mode: annotation.annotation ? 'select' : 'draw',
    };

    this.init(options, annotation.annotation);
  }

  beforeReady(canvas: any): Promise<void> | undefined {
    if (!canvas) return;
    canvas.on('mouse:down', this.onMouseDown.bind(this));

    // We don't want to show the background when we're in the frame focus view
    if (this.currentFile.showBackground) {
      return this.setBackgroundImage(this.currentFile.background);
    } else {
      return Promise.resolve();
    }
  }

  private setBackgroundImage(url?: string) {
    if (!url) return Promise.resolve();
    return new Promise<void>((resolve) => {
      this.loadExternalImage(url).then((image) => {
        if (!image || !this.canvas) return resolve();
        this.canvas.setBackgroundImage(image, resolve, {
          originX: 'left',
          originY: 'top',
          left: 0,
          top: 0,
        });
      });
    });
  }

  protected setOptions(settings: Partial<AnnotationToolSettings> | undefined) {
    if (!settings) return;
    const options = { ...this.options, ...settings };
    this.options = options;
    this.baseBrush.color = options.color;

    this.canvas.isDrawingMode =
      options.mode === 'draw' || options.mode === 'eraser';
    this.canvas.freeDrawingBrush =
      options.mode === 'eraser' ? this.eraserBrush : this.baseBrush;
    this.canvas.freeDrawingBrush.width = options.strokeWidth;
    this.setSelectable(options.mode === 'select');

    annotationToolLocalState.setValue(options);
  }

  save(): Promise<FrameEditorLayerData> {
    const result = this.canvas.toObject();
    this.updateHistoryStartingPoint();
    return Promise.resolve(result);
  }

  private onMouseDown(event) {
    const { mode } = this.options;
    if (mode === 'arrow' || mode === 'straightBrush') {
      this.createShape(this.canvas.getPointer(event.e), mode);
    }
  }

  isCreatingObject = false;
  /** Based off createShape in the FrameEditorEngine */
  private createShape(pointer: XYCoordinates, type: 'arrow' | 'straightBrush') {
    const { resizeFunc, create, klass } = shapes[type];

    this.canvas.discardActiveObject();
    const color = this.options.color;

    const newShape = create(klass, color, pointer, this.options.strokeWidth);
    // newShape.set('stroke', this.options.color);
    this.canvas.add(newShape);
    this.canvas.requestRenderAll();
    const boundResize = resizeFunc.bind(newShape);

    this.setSelectable(false);
    this.isCreatingObject = true;

    const handler = (event) => {
      if (!this.canvas) return;

      const coords = this.canvas.getPointer(event.e);
      newShape.set(boundResize(coords, this.isShiftPressed));
      newShape.setCoords();
      this.canvas.requestRenderAll();
    };

    const removeHandler = () => {
      this.setSelectable(false);
      this.canvas.off('mouse:move', handler);
      this.canvas.off('mouse:up', removeHandler);
      this.isCreatingObject = false;
      newShape.setCoords();

      this.canvas.selection = true;
      this.canvas.requestRenderAll();
      this.addHistoryItem();
    };

    this.canvas.on('mouse:move', handler);
    this.canvas.on('mouse:up', removeHandler);
  }
}
