/** @prettier */
import * as React from 'react';
import { useStore } from 'javascripts/helpers/useStore';
import type { PlayerStore } from 'javascripts/flux/stores/player';
import { getFrameField } from 'javascripts/helpers/fieldDataHelpers';
import { SimplifiedHTML } from 'blackbird/components/form/richTextInput/SimplifiedHTML';

import type { frameFieldId } from 'javascripts/types/frame';
import { isFrameFieldEmpty } from 'javascripts/helpers/storyboard/frameFieldHelpers';

interface Props {
  size: number;
  /** the field name to use as subtitle. This is exposed as a prop because the
   * storyboard model isn't available on shareable*/
  subtitleSource: frameFieldId;
}

const FrameSubtitles = React.memo<Props>((props) => {
  const { subtitleSource } = props;
  const isSubtitlesEnabled = useStore('player', (s) => s.isSubtitlesEnabled);

  const voiceover = useStore<string | undefined, PlayerStore>('player', (s) => {
    if (!s.currentFrame) return;
    return getFrameField(s.currentFrame, subtitleSource ?? 'reference');
  });

  return isSubtitlesEnabled && voiceover && !isFrameFieldEmpty(voiceover) ? (
    <div
      className="absolute bottom-1/20 inset-x-1/10 text-center"
      style={{ fontSize: props.size }}
    >
      <SimplifiedHTML
        className="p-2 leading-tight bg-black/60 shadow text-shadow-sm text-white tracking-white inline-table rounded-sm backdrop-blur-sm pointer-events-none select-none"
        text={voiceover}
      />
    </div>
  ) : null;
});

FrameSubtitles.displayName = 'FrameSubtitles';
export default FrameSubtitles;
