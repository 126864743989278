const axios = require('axios');

module.exports = {
  uploadAudio: file => {
    const {
      CloudinaryJsFolder, CloudinaryKey, CloudinaryJsUploadPreset
    } = BoordsConfig;

    const cloudName = CloudinaryJsFolder;

    const data = new FormData();
    data.append('file', file);
    data.append('api_key', CloudinaryKey);
    data.append('upload_preset', CloudinaryJsUploadPreset);
    return axios.post(
      `https://api.cloudinary.com/v1_1/${cloudName}/video/upload`,
      data
    )
  }
}
