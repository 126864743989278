/** @format */
import * as React from 'react';
import TextInput, {
  type TextInputProps,
} from 'blackbird/components/form/text-input/TextInput';
import { useTextSearch } from 'javascripts/helpers/useTextSearch';
import SearchIcon from 'blackbird/images/icons/search.svg';
import { ClearButton } from 'blackbird/components/button/ClearButton';
import Icon from 'blackbird/components/icon/Icon';
interface Props<T = Record<string, unknown>> extends TextInputProps {
  options: T[];
  /** Which props of the object should be searched through? By default, this
   * will take all props in the first item of options that have a string or
   * number value */
  propsToSearch?: (keyof T)[];
  children: (filteredOptions: T[]) => React.ReactNode;
}

/** Component that takes an `options` list, then renders a search input and
 * passes along a filtered version of `option` as a render prop  */
export function TextSearch<T>({
  children,
  propsToSearch,
  options,
  ...props
}: Props<T>) {
  const [search, setSearch] = React.useState('');
  const [filteredOptions, triggerTextSearch] = useTextSearch(
    options,
    propsToSearch,
  );

  return (
    <>
      <TextInput
        {...props}
        placeholder={props.placeholder ?? 'Search'}
        value={search}
        inputSize="md"
        onChange={(e) => {
          setSearch(e.currentTarget.value);
          triggerTextSearch(e.currentTarget.value);
        }}
        leftComponent={
          <Icon color="subdued" icon={<SearchIcon />} className="w-4 ml-1" />
        }
        rightComponent={
          search && (
            <ClearButton
              onClick={() => {
                setSearch('');
                triggerTextSearch('');
              }}
            />
          )
        }
      />

      {filteredOptions.length > 0 ? (
        <>{children(filteredOptions)}</>
      ) : (
        <div className="px-3 pt-4 text-sm text-center text-type-subdued">{`No results found`}</div>
      )}
    </>
  );
}
