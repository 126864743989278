/** @format */

import React from 'react';
import type { CSSProperties } from 'react';
import type { DropTargetMonitor, XYCoord } from 'react-dnd';
import { useDrag, useDrop } from 'react-dnd';

export type sortableOnChangeHandler = (
  oldIndex: number,
  newIndex: number,
) => void;

export interface DragItem {
  index: number;
  type: string;
}

interface UseSortableProps<T = DragItem> {
  type: string;
  /** Ref for the drag handle */
  dragRef: React.RefObject<HTMLElement>;
  /** Ref for the entire element, used as a preview */
  previewRef: React.RefObject<HTMLElement>;
  accept?: Array<string | symbol>;
  /** Function that will be called when the order of items changes */
  onChange: sortableOnChangeHandler;
  currentIndex: number;
  enabled?: boolean;
  /** Function to be called once a sucessful drop has been performed */
  onEnd?: (item: T, monitor: DropTargetMonitor) => undefined | void;
}

/**
 * A hook that contains logic for sortable lists using React-dnd with drag
 * handles. It combines code examples from the react-dnd docs, with a bit of
 * help from [this stackoverflow answer](https://stackoverflow.com/a/69282913/847689)
 */
export const useSortable = <T = DragItem>(props: UseSortableProps<T>) => {
  const { dragRef, previewRef, enabled = true } = props;
  const accept = props.accept ?? [props.type];

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: string | symbol | null }
  >({
    accept: accept,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop: props.onEnd as any,
    hover(item: DragItem, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.currentIndex;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.onChange(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag<
    DragItem,
    unknown,
    { isDragging: boolean }
  >({
    type: props.type,
    canDrag: () => enabled,
    item: () => {
      return {
        index: props.currentIndex,
        type: props.type,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // We need to attach these listeners if draggable changes, otherwise
  // the dragging behavior won't attach properly
  React.useEffect(() => {
    drag(dragRef);
    drop(preview(previewRef));
  }, [drag, dragRef, drop, preview, previewRef, props.enabled]);

  const opacity = isDragging ? 0 : 1;
  return { styles: { opacity } as CSSProperties, handlerId, isDragging };
};
