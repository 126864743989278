/** @prettier */
import classNames from 'classnames';
import React from 'react';

interface GuidelineOptionBlankProps {
  title: string;
  primary?: boolean;
}

export const GuidelineOptionBlank: React.FC<GuidelineOptionBlankProps> = ({
  title,
  primary = false,
}) => {
  return (
    <div className="flex items-center cursor-pointer">
      <div
        className={classNames(
          'flex-shrink-0 mr-2 rounded-sm bg-surface',
          primary ? 'w-10 h-10 ' : 'w-6 h-6 ',
        )}
      />
      <div
        className={classNames('text-sm ', primary ? '' : 'text-type-disabled')}
      >
        {title}
      </div>
    </div>
  );
};
