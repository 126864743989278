/** @prettier */
const createReactClass = require('create-react-class');
const {
  canShowOptin,
  optIn,
  dismiss,
  NewPDFExportOptIn,
} = require('../pdf/helpers/newPDFExportOptin');
const { Button } = require('../../components/shared/Button');
import Dialog from 'blackbird/components/dialog/Dialog';
import { OverflowContextProvider } from 'blackbird/helpers/hooks/overflow';
import classNames from 'classnames';
window.StoryboardExportContainer = createReactClass({
  getInitialState: function () {
    return {
      activeSection: 'PDF',
      storyboard: StoryboardStore.getState().storyboard,
      user: UserStore.getState().user,
      cover: PdfCoverStore.getState(),
      pdf: PdfStore.getState(),
      isDownloadScreen: false,
    };
  },
  handleDownload: function () {
    this.setState({ isDownloadScreen: true });
  },
  handleDownloadExpired: function () {
    this.setState({ isDownloadScreen: false });
  },
  componentDidMount: function () {
    Track.event.defer('open_pdf_export_flyover');
    StoryboardStore.listen(this._onStoryboardChange);
    // UserStore.listen(this._onUserChange);
    PdfStore.listen(this._onPdfChange);
    PdfActions.fetchPdf.defer(this.state.storyboard.id);
    CoverpageStore.listen(this._onCoverChange);
    CoverpageActions.fetch.defer(this.state.storyboard.id);
  },

  componentWillUnmount: function () {
    StoryboardStore.unlisten(this._onStoryboardChange);
    // UserStore.unlisten(this._onUserChange);
    PdfStore.unlisten(this._onPdfChange);
    CoverpageStore.unlisten(this._onCoverChange);
  },

  _onCoverChange: function (state) {
    this.setState({ cover: state });
  },

  _onPdfChange: function (state) {
    this.setState({ pdf: state });
  },

  _onStoryboardChange: function (state) {
    this.setState({ storyboard: state.storyboard });
  },

  _onUserChange: function (state) {
    this.setState({ user: state.user });
  },

  _tab: function (name, e) {
    e.preventDefault();
    this.setState({
      activeSection: name,
    });
  },

  onOptinClick() {
    optIn();
    FlyoverActions.close();
    FlyoverActions.open.defer({ type: 'storyboardExport' });
  },

  onDismissClick() {
    dismiss();
    this.forceUpdate();
  },

  handleClose() {
    FlyoverActions.close();
  },
  render: function () {
    const { storyboard, isDownloadScreen, keepOpen } = this.state;
    const isUnsupported = ['4:5', '4:3', '1.85:1', '2.4:1'].includes(
      storyboard.frame_aspect_ratio,
    );

    return (
      <OverflowContextProvider>
        {({ hasOverflow, ref }) => (
          <Dialog
            initialFocus={ref}
            containerRef={ref}
            fullHeight={!isDownloadScreen}
            onCancel={this.handleClose}
            keepOpen={keepOpen}
            size={isUnsupported ? `md` : `lg`}
            hideActions
            isOpen
            isDark
            title={
              isUnsupported
                ? `Please switch to the new PDF Exporter`
                : `Export your storyboard`
            }
            subtitle={
              !isUnsupported
                ? `${storyboard.document_name} v${storyboard.version_number} of ${storyboard.versions.length}`
                : ``
            }
            containerClasses="no-padding"
            headerClasses={classNames(
              'sticky top-0 p-10 z-10 rounded-t-xl bg-white',
              { 'shadow-[0px_2px_0px_rgba(0,0,0,0.12)]': hasOverflow },
            )}
          >
            {this.state.pdf.is_loading || !this.state.cover.cover ? (
              <FlyoverLoading />
            ) : isUnsupported ? (
              <div className="px-10 mb-12">
                <div className="mb-10 -mt-8">
                  {`The legacy PDF exporter does not support the ${storyboard.frame_aspect_ratio} aspect ratio. Please switch to the new exporter to render this storyboard as a PDF.`}
                </div>
                <NewPDFExportOptIn />
              </div>
            ) : (
              <StoryboardExportPdf
                onDownload={this.handleDownload}
                onDownloadExpired={this.handleDownloadExpired}
                {...this.state}
              />
            )}
          </Dialog>
        )}
      </OverflowContextProvider>
    );
  },
});
