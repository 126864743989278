/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import { AssetsActions } from '../actions/assets';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { deserialise, serialise } from 'kitsu-core';
import { FrameActions } from '../actions/frame';
import { isString, without, isEmpty, debounce } from 'underscore';
import { map } from 'async';
import { FileUploadActions } from '../actions/file_upload';
import { DRAGGABLE_TYPES } from '../../components/shared/dragAndDropUtils';
import { RequestActions } from '../actions/request';
import { ajax } from 'javascripts/helpers/ajax';
import { acceptedFrameFileTypes } from './frame';

const api = require('../../helpers/api')();
const errorHandler = RequestErrorHandler('assetsStore');

const maxAgeInMinutes = 2;
const maxAgeOffset = maxAgeInMinutes * 60 * 1000;

// This should probably be something like a apiv2 frame object
export interface StoryboardFramesAsset {
  thumbnail_image_url: string;
  id: string;
  active: boolean;
}

export type typeDifferentiator =
  | typeof DRAGGABLE_TYPES.frame
  | typeof DRAGGABLE_TYPES.image;

export interface StoryboardImageAsset {
  id: string;
  filename: string;
  file_type: string;
  file_size: number;
  image_url: string;
  thumbnail_url: string;
  project_id?: string | number;
  team_id?: string | number;
  isUploading?: boolean;
}

export class AssetsStore extends FakeAltStoreClass<AssetsStore> {
  storyboardFramesAssets: StoryboardFramesAsset[];
  storyboardImageAssets: StoryboardImageAsset[];
  frameAssetsFetchDate: Date;
  imageAssetsFetchDate: Date;
  frameAssetsAreLoading = false;
  imageAssetsAreLoading = false;

  /** Keep track of if the asset panel is visible, so we know when to listen for changes */
  isVisible = false;

  constructor() {
    super();
    this.bindListeners({
      handleFetchFrameAssets: AssetsActions.FETCH_FRAME_ASSETS,
      handleFetchImageAssets: AssetsActions.FETCH_IMAGE_ASSETS,
      handleReplaceExistingFrame: AssetsActions.REPLACE_EXISTING_FRAME,
      handleUploadImageAsset: AssetsActions.UPLOAD_IMAGE_ASSET,
      handleDeleteImageAsset: AssetsActions.DELETE_IMAGE_ASSET,
      handleDeleteFrameAsset: AssetsActions.DELETE_FRAME_ASSET,
      getAssetInfo: AssetsActions.GET_ASSET_INFO,
      handleSetVisible: AssetsActions.SET_VISIBLE,
      handleRefresh: AssetsActions.REFRESH,
    });
  }

  handleFetchFrameAssets(props: { storyboardID: number; force?: boolean }) {
    if (!props.storyboardID) throw new Error('Need storyboardID');

    if (
      !props.force &&
      this.frameAssetsFetchDate &&
      new Date().valueOf() - maxAgeOffset < this.frameAssetsFetchDate.valueOf()
    ) {
      return;
    }

    this.frameAssetsAreLoading = true;

    ajax({
      method: 'get',
      url: api.setRailsApiUrl(
        `storyboards/${props.storyboardID}/frames?fields=thumbnail_image_url,id,created_at,active`,
      ),
      beforeSend: api.setRailsApiAuthHeader,
      complete: () => {
        this.frameAssetsAreLoading = false;
        this.emitChange();
      },
      success: (response) => {
        this.frameAssetsFetchDate = new Date();
        this.storyboardFramesAssets = deserialise(response).data;
        this.emitChange();
      },
      error: errorHandler({
        messageKey: 'imageLibrary.errors.fetchFrameAssets',
      }),
    });
  }

  handleFetchImageAssets(props: { storyboardID: number; force?: boolean }) {
    if (!props.storyboardID) throw new Error('Need storyboardID');

    if (
      !props.force &&
      this.imageAssetsFetchDate &&
      new Date().valueOf() - maxAgeOffset < this.imageAssetsFetchDate.valueOf()
    ) {
      return;
    }

    this.imageAssetsAreLoading = true;

    ajax({
      method: 'get',
      url: api.setRailsApiUrl(
        `storyboards/${props.storyboardID}/library_assets?fields=thumbnail_image_url,id,created_at`,
      ),
      beforeSend: api.setRailsApiAuthHeader,
      complete: () => {
        this.imageAssetsAreLoading = false;
        this.emitChange();
      },
      success: (response) => {
        this.imageAssetsFetchDate = new Date();
        this.storyboardImageAssets = deserialise(response).data;
        this.emitChange();
      },
      error: errorHandler({
        messageKey: 'imageLibrary.errors.fetchImageAssets',
      }),
    });
  }

  handleUploadImageAsset(props: {
    teamId: number;
    projectId: number;
    file: File;
    storyboardId: number;
    target: 'project' | 'team';
  }) {
    // Read the file so we can get a thumbnail
    const reader = new FileReader();
    reader.readAsDataURL(props.file);
    reader.onload = () => {
      const fakeItem: StoryboardImageAsset = {
        id: props.file.name,
        filename: props.file.name,
        file_type: props.file.type,
        file_size: props.file.size,
        image_url: reader.result as string,
        thumbnail_url: reader.result as string,
        project_id: props.target === 'project' ? props.projectId : undefined,
        team_id: props.target === 'team' ? props.teamId : undefined,
        isUploading: true,
      };

      // Add the preview to the list
      if (this.storyboardImageAssets) {
        this.storyboardImageAssets = [fakeItem, ...this.storyboardImageAssets];
      } else {
        this.storyboardImageAssets = [fakeItem];
      }

      this.emitChange();

      // Prepare an error handler
      const onError = errorHandler(
        {
          messageKey: 'imageLibrary.errors.upload',
        },
        () => {
          this.storyboardImageAssets = without(
            this.storyboardImageAssets,
            fakeItem,
          );
          this.emitChange();
        },
      );

      this.emitChange();

      // Upload the image to dolly
      FileUploadActions.uploadFile.defer({
        team_id: props.teamId,
        file: props.file,
        accept: acceptedFrameFileTypes,
        callback: (result) => {
          if (!result) return onError(new Error('image was not uploaded'));

          // Then store the image as an asset
          ajax({
            method: 'post',
            data: serialise('library_asset', {
              ...fakeItem,
              image_url: result.url,
              thumbnail_url: result.url,
            }),
            url: api.setRailsApiUrl(
              `storyboards/${props.storyboardId}/library_assets`,
            ),
            beforeSend: api.setRailsApiAuthHeader,
            success: (response) => {
              const newData = deserialise(response).data;
              RequestActions.success.defer('Asset created');
              Track.event.defer('uploaded_asset');

              // create a new array (to trigger update), and replace the placeholder with the info returned from the server
              this.storyboardImageAssets = this.storyboardImageAssets.map((i) =>
                i === fakeItem ? newData : i,
              );
              this.emitChange();
            },
            error: onError,
          });
        },
      });
    };
  }

  async handleReplaceExistingFrame(props: {
    targetFrameId: number;
    assetId: number | string;
    storyboardId: number;
    teamId: number;
  }) {
    const assetId = isString(props.assetId)
      ? parseInt(props.assetId)
      : props.assetId;

    const targetFrame = FrameStore.getState().frames.find(
      (f) => f.id === props.targetFrameId,
    );

    FrameActions.updateImageStatus.defer({
      frameId: props.targetFrameId,
      status: 'fetching',
    });

    const onError = errorHandler(
      {
        messageKey: 'frames.errors.replace',
      },
      () => {
        FrameActions.updateImageStatus.defer({
          frameId: props.targetFrameId,
          status: 'image_error',
          message: 'Error uploading image',
        });
      },
    );

    // First, we make sure we get all the data belonging to the frame
    const { background_image_url, thumbnail_image_url, image_url, layer_data } =
      await this.getAssetInfo({ type: 'frameAsset', assetId }).catch(onError);

    // Then we fetch each image, and upload it again
    map(
      [background_image_url, thumbnail_image_url, image_url],
      (url, done) => {
        if (!url) return done();

        fetch(url + '?crossorigin20=true')
          .then((response) => response.blob())
          .then((blob) => {
            FileUploadActions.uploadFile({
              file: new File([blob], url, {
                type:
                  // We don't always get a mime type back (haha :'() ), so we
                  // fake it. It's only for the base64 string anyway
                  blob.type && !isEmpty(blob.type) ? blob.type : 'image/jpeg',
              }),
              team_id: props.teamId,
              callback: (file) => {
                if (!file) return done(new Error('no image was uploaded'));
                done(null, file.url);
              },
            });
          })
          .catch((err) => done(err));
      },
      (err, results: Array<string>) => {
        if (err) return onError(err);
        FrameActions.updateImageStatus.defer({
          frameId: props.targetFrameId,
          status: 'generating_thumbnail',
        });

        FrameActions.ensureUndoItems.defer();
        FrameActions.updateFrameImageUrls.defer({
          createHistoryItem: true,
          frame: targetFrame,
          replace: false,
          background_image_url: results[0],
          thumbnail_image_url: results[1],
          large_image_url: results[2],
          layer_data,
        });
      },
    );
  }

  getAssetInfo(props: {
    type: typeDifferentiator;
    assetId: number | string;
    callback?: (error?: Error | null, result?) => void;
  }) {
    return new Promise<any>((resolve, reject) => {
      const onError = (error: Error) => {
        props.callback?.(error);
        reject(error);
      };

      if (props.type === 'frameAsset') {
        ajax({
          method: 'get',
          url: api.setRailsApiUrl(
            `frames/${props.assetId}/?fields=background_image_url,thumbnail_image_url,image_url,layer_data`,
          ),
          beforeSend: api.setRailsApiAuthHeader,
        })
          .then((result) => {
            const output = deserialise(result).data;
            props.callback?.(null, output);
            resolve(output);
          })
          .catch(onError);
      } else {
        const found = this.storyboardImageAssets.find(
          (i) => i.id === props.assetId,
        );
        if (!found) {
          return onError(
            Error('could not find image asset with id ' + props.assetId),
          );
        }

        props.callback?.(null, found);
        resolve(found);
      }
    });
  }

  handleDeleteImageAsset(props: {
    assetId: number | string;
    storyboardId: number;
  }) {
    const current = this.storyboardImageAssets;

    this.storyboardImageAssets = this.storyboardImageAssets.filter(
      (i) => i.id !== props.assetId,
    );

    ajax({
      method: 'delete',
      url: api.setRailsApiUrl(
        `storyboards/${props.storyboardId}/library_assets/${props.assetId}`,
      ),
      beforeSend: api.setRailsApiAuthHeader,
      success: () => {
        RequestActions.success.defer('Asset deleted');
      },
      error: errorHandler({ messageKey: 'imageLibrary.errors.delete' }, () => {
        this.storyboardImageAssets = current;
        this.emitChange();
      }),
    });
  }

  handleDeleteFrameAsset(args: {
    frameId: number;
    storyboardHashid: string;
    storyboardId: number;
  }) {
    const currentValue = this.storyboardFramesAssets;
    this.storyboardFramesAssets = currentValue.filter(
      (i) => String(i.id) !== String(args.frameId),
    );

    FrameActions.permanentlyDeleteFrame.defer({
      ...args,
      callback: (success) => {
        if (!success) {
          this.storyboardFramesAssets = currentValue;
          this.emitChange();
          return;
        }

        // This might be a good time to refresh, but we don't have to force it
        // because we've alreadt removed this item from the view.
        this.handleFetchFrameAssets({ storyboardID: args.storyboardId });
      },
    });
  }

  handleSetVisible(isVisible: boolean) {
    this.isVisible = isVisible;
  }

  handleRefresh = debounce((storyboardID: number) => {
    // Next time we load, we want to make sure we fetch
    this.frameAssetsFetchDate = new Date(0);
    if (this.isVisible) {
      this.handleFetchFrameAssets({
        storyboardID,
      });
    }
  }, 1000);
}

(window as any).AssetsStore = alt.createStore(AssetsStore, 'AssetsStore');
