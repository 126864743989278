/** @format */

import logger from 'javascripts/helpers/logger';
import { useRollout } from 'javascripts/helpers/rollout';
import React from 'react';
import { type Sale } from './types';

export interface ISaleContext {
  activeSale: Sale | null;
}
const defaultSaleContext: ISaleContext = {
  activeSale: null,
};

export const SaleContext =
  React.createContext<ISaleContext>(defaultSaleContext);

interface ISaleProviderProps {
  children: React.ReactNode;
}

export const SaleProvider: React.FC<ISaleProviderProps> = ({ children }) => {
  const [activeSale, setActiveSale] = React.useState<Sale | null>(null);
  const showSales = useRollout('Showsales');

  React.useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await fetch(
          (process.env.NODE_ENV === 'development'
            ? 'http://localhost:3000'
            : 'https://boords.com') + '/api/sales',
        );
        const sales: Sale[] = await response.json();

        const currentDate = new Date();
        const activeSale = sales.find((sale) => {
          const startDate = new Date(sale.startDate);
          const endDate = new Date(sale.endDate);
          return currentDate >= startDate && currentDate <= endDate;
        });

        setActiveSale(activeSale || null);
      } catch (error) {
        logger.error('Failed to fetch sales:', error);
      }
    };

    if (showSales && !BoordsConfig.IsEdu) {
      fetchSales();
    }
  }, [showSales]);

  const value = {
    activeSale,
  };

  return <SaleContext.Provider value={value}>{children}</SaleContext.Provider>;
};

export default SaleProvider;
