/** @prettier */
import * as React from 'react';
import { useStore } from '../../../helpers/useStore';
import { IconButton } from 'blackbird/components/common/IconButton';
import Checkmark from 'blackbird/images/icons/checkmark.svg';
import { useTranslation } from 'react-i18next';
import type { IStoryboardPreferences } from 'javascripts/types/storyboard';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';

const PanelbarCommentsHideCompleted = React.memo(() => {
  const { t } = useTranslation(undefined, { keyPrefix: 'comments.tooltips' });
  const preference: keyof IStoryboardPreferences =
    'hide_completed_comments_edit';

  const hideCompletedComments = useStore(
    'StoryboardStore',
    (store) => store.storyboard.preferences[preference],
  );

  const handleTogglePreference = () => {
    StoryboardActions.updatePreference({
      name: preference,
      value: !hideCompletedComments,
    });
  };

  return (
    <IconButton
      tooltipDistance={1}
      iconDisabled={hideCompletedComments}
      onClick={handleTogglePreference}
      aria-label={
        hideCompletedComments
          ? t('hide_completed_true')
          : t('hide_completed_false')
      }
      icon={<Checkmark />}
    />
  );
});

export default PanelbarCommentsHideCompleted;
