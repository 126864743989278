/** @format */

import { BoordsAiContext } from 'blackbird/helpers/contexts/BoordsAiContext';
import React, { SetStateAction, useContext } from 'react';
import Button from '../button/Button';
import { SecondaryLink } from '../common/SecondaryLink';
import { GeneratorContext } from '../generator/GeneratorContext';

export type AiStyle =
  | 'lino'
  | 'ink-wash'
  | 'flat-mono'
  | 'flat'
  | 'line-art'
  | 'comic-book'
  | 'photographic'
  | 'fantasy-art'
  | '3d-model'
  | 'analog-film'
  | 'digital-art'
  | 'ink-wash-sepia'
  | 'flat-sepia'
  | 'lino-sepia'
  | 'neon-punk'
  | 'cinematic'
  | 'isometric'
  | 'pixel-art'
  | 'origami'
  | 'low-poly'
  | 'anime'
  | 'none';

export type AiCategorySlug =
  | 'all'
  | 'marketing'
  | 'product-explainers'
  | 'e-learning'
  | 'corporate'
  | 'presentations'
  | 'film'
  | 'training'
  | 'blank';

export type AiPreset =
  | 'product-explainers-video'
  | 'digital-marketing-campaign'
  | 'e-learning-course'
  | 'short-film'
  | 'corporate-video'
  | 'company-overview'
  | 'employee-training-video'
  | 'blank'
  | 'health-and-wellness-app-explainer'
  | 'home-automation-product-demo'
  | 'luxury-car-feature-explainer'
  | 'online-education-platform-demo'
  | 'software-update-announcement'
  | 'change-management'
  | 'company-culture-showcase'
  | 'company-milestone-celebration'
  | 'internal-communication'
  | 'recruitment-video'
  | 'corporate-training'
  | 'educational-workshop-presentation'
  | 'health-and-safety-training'
  | 'online-course'
  | 'webinar'
  | 'documentary'
  | 'feature-film'
  | 'film-trailer'
  | 'animated-film'
  | 'annual-report-presentation'
  | 'conference-presentation'
  | 'educational-workshop-presentation'
  | 'executive-summary'
  | 'investor-pitch-presentation'
  | 'social-media-campaign'
  | 'product-demo'
  | 'content-marketing-strategy'
  | 'competitive-analysis'
  | 'marketing-budget-proposal'
  | 'e-commerce-marketing-campaign'
  | 'software-training'
  | 'compliance-training'
  | 'sales-training'
  | 'on-the-job-training'
  | 'leadership-training';

export interface AiExamplesProps {
  handleGenerate: () => void;
  setPrompt: React.Dispatch<SetStateAction<string>>;
}

export const AiExamples: React.FC<AiExamplesProps> = ({
  setPrompt,
  handleGenerate,
}) => {
  const { presets, setPreset, categories, setCategory, setStyle } =
    useContext(BoordsAiContext);

  const { styles, setStyle: setGeneratorStyle } = useContext(GeneratorContext);

  interface ExampleSelectProps {
    presetSlug: AiPreset;
    styleSlug: AiStyle;
    categorySlug: AiCategorySlug;
    prompt: string;
  }

  const handleExampleSelect = (props: ExampleSelectProps) => {
    const { prompt, categorySlug, presetSlug, styleSlug } = props;

    const selectedCategory = categories.find((c) => c.value === categorySlug);
    const selectedPreset = presets.find((c) => c.slug === presetSlug);
    const selectedStyle = styles.find((c) => c.value === styleSlug);

    setPrompt(prompt);

    if (selectedCategory) {
      setCategory(selectedCategory);
    }
    if (selectedPreset) {
      setTimeout(() => {
        setPreset(selectedPreset);

        if (selectedStyle) {
          setGeneratorStyle(selectedStyle);
          setStyle(selectedStyle);
        }

        handleGenerate();
      }, 50);
    }
  };

  return (
    <div className="flex flex-col items-center md:flex-row md:justify-center md:space-x-3 space-y-3 md:space-y-0">
      <Button
        rounded
        size="sm"
        type="secondary"
        className="!font-normal"
        onClick={() =>
          handleExampleSelect({
            prompt: 'pandas',
            categorySlug: 'film',
            presetSlug: 'documentary',
            styleSlug: 'ink-wash',
          })
        }
      >{`Documentary about pandas`}</Button>
      <Button
        rounded
        size="sm"
        type="secondary"
        className="!font-normal"
        onClick={() =>
          handleExampleSelect({
            prompt: 'promoting hiking outdoors',
            categorySlug: 'marketing',
            presetSlug: 'digital-marketing-campaign',
            styleSlug: 'flat',
          })
        }
      >{`Marketing video promoting hiking`}</Button>

      <Button
        rounded
        size="sm"
        type="secondary"
        className="!font-normal"
        onClick={() =>
          handleExampleSelect({
            prompt: 'a llama trying to find its way home',
            categorySlug: 'film',
            presetSlug: 'short-film',
            styleSlug: 'ink-wash',
          })
        }
      >{`Short film about a llama`}</Button>
    </div>
  );
};
