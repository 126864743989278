/** @prettier */
import * as contrast from 'get-contrast';
import { sortBy } from 'underscore';

interface GrayShadeInfo {
  color: string;
  score: number;
}

/**
 * Exports an array of grey tones that have enough contrast with the supplied
 * based on the supplied background color. Sorted from lightest to darkest
 * @param minScore 4.5 is equal to WCAG AA rating, 1.55 is the score of the
 * previous values we had in the PDF editor
 */
export const getGreyValuesWithEnoughContrast = (
  backgroundColor: string,
  minScore = 1.3,
) => {
  const steps = 25;
  const maxScore = 20; // 21 is the highest
  const colors: GrayShadeInfo[] = [];

  for (let i = 0; i <= steps; i++) {
    const value = Math.round((255 / steps) * i);
    let hexValue = value.toString(16);
    if (hexValue.length === 1) hexValue += hexValue;

    const hex = '#' + hexValue + hexValue + hexValue;

    const score = contrast.ratio(backgroundColor, hex);
    if (score >= minScore && score < maxScore) {
      colors.push({
        color: hex.toUpperCase(),
        score: score,
      });
    }
  }

  return colors.reverse();
};

/**
 * Generates `lightest`, `mid`, and `darkest` shades of grey with appropriate
 * contrast with the supplied background
 */
export const getColorSchemeGreyValues = (
  backgroundColor: string = '#ffffff',
) => {
  const values = getGreyValuesWithEnoughContrast(backgroundColor);
  const byScore = sortBy(values, 'score').reverse();
  const midIndex = Math.round(values.length * 0.35);
  return {
    lightest: values[0],
    mid: values[midIndex],
    text: byScore[0],
  };
};
