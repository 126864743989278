const createReactClass = require('create-react-class')
const { Button } = require('../shared/Button');

window.TeamOnboardingName = createReactClass({

  getInitialState: function() {
    return UserStore.getState();
  },

  componentDidMount: function() {
    UserStore.listen(this._onChange);
  },

  componentWillUnmount: function() {
    UserStore.unlisten(this._onChange);
  },

  _onChange: function(state) {
    this.setState(state);
  },

  _handleRenameTeamClick: function(e) {
    e.preventDefault();
    var name;

    if (ReactDOM.findDOMNode(this.refs.name).value.length > 0) {
      name = ReactDOM.findDOMNode(this.refs.name).value;
    } else {
      name = this.state.user.first_name + "'s Team";
    }

    TeamManagementActions.rename({
      name:    name,
      teamId:  this.state.user.team_id,
    });

    FlyoverActions.close.defer();
  },

  render: function() {

    var user = this.state.user;

    if (!user) {

      return null;

    } else {

      return (
        <div className="flyover__section slim" style={{"backgroundColor": "#f4f4f4"}}>
          <div className="tc lh-copy">

            <FlyoverSectionHeader
              title={"Customize your account"}
              icon="pencil-grey-45"
              subtitle="Add your company name and logo to make your account your own"
            />

            <div className="bg-white pa3 shadow-3 mb3 br2 tl">
              <label className="f5 silver db">Company name</label>
              <input name="team[name]" id="name" autoComplete="off" ref="name" placeholder={"e.g. Animade"} autoFocus={true} className="bn w-100" />
            </div>

            <div className="bg-white pa3 shadow-3 mb3 br2 tl">
              <div className="flex items-center">
                <div className="flex-auto">
                  <div className="f5 silver db">Company logo</div>
                  <div className="f5 db">JPG or PNG, 1MB max</div>
                </div>
                <AvatarUploader type="team" id={this.state.user.team_id} />
              </div>
            </div>

            <div className="tc mt4 mb2">
              <div className="dib">
                <Button
                  text="Save & Close"
                  onClick={this._handleRenameTeamClick}
                  size="larger"
                  preset="purple"
                  dotsOnClick={true}
                />
              </div>
            </div>

          </div>
        </div>
      );
    }
  }

});
