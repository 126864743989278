/** @prettier */
import * as React from 'react';

const variations = {
  // small: 'dib h2 w2px bg-grey',
  default: 'w-px bg-border flex-shrink-0 h-6',
};

export interface HrProps {
  variation?: keyof typeof variations;
}

export const Hr: React.FC<HrProps> = (props) => {
  const className = variations[props.variation ?? 'default'];

  return <div className={className} />;
};

Hr.displayName = 'Hr';
