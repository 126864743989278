/** @prettier */
import Button from 'blackbird/components/button/Button';
import i18n from 'i18next';
import { ModalDivider } from 'blackbird/components/dialog/Dialog';
import Toast from 'blackbird/components/feedback/toast/Toast';
import {
  TextInput,
  TextArea,
} from 'blackbird/components/form/text-input/TextInput';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { getFrameImage } from 'javascripts/helpers/getFrameImage';
import classNames from 'classnames';
import { StatusIndicator } from 'javascripts/components/frame_editor/toolbar/image_search/StatusIndicator';
import { FrameImageStatusOverlay } from 'javascripts/components/frames/editor/FrameImageStatus';
import { LoadingIndicator } from '../../common/loading-indicator/LoadingIndicator';
import Img from 'javascripts/components/shared/Img';
import { getMissingFrameImageURL } from 'javascripts/helpers/getMissingFrameImageURL';
import React, { useEffect } from 'react';
import { GeneratorContext } from '../GeneratorContext';
import { GeneratorCharatcerContext } from './GeneratorCharacterContext';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import Badge from 'blackbird/components/badge/Badge';
import Select from 'blackbird/components/form/select/Select';
import logger from 'javascripts/helpers/logger';
import Checkbox from 'blackbird/components/form/checkbox/Checkbox';

export const GuidelineCharacterForm: React.FC = () => {
  const { isPanelbar, selectedCharacter } = React.useContext(GeneratorContext);

  const {
    error,
    handleUpdate,
    setCharacter,
    character,
    name,
    setName,
    negative,
    setNegative,
    positive,
    setPositive,
    isProcessing,
    setIsActive,
    handlePoll,
    handleDuplicate,
    handleDestroy,
    isNameChanged,
    isActiveChanged,
    isActive,
    isAttributesChanged,
    setIsCharacterEditorOpen,
    characterSelectOptions,
  } = React.useContext(GeneratorCharatcerContext);

  const { ages, genders, ethnicities } = characterSelectOptions;

  const [disabled, setDisabled] = React.useState(false);

  useEffect(() => {
    if (isProcessing || (character?.is_global && !BoordsConfig.BackendAccess)) {
      setDisabled(true);
    } else setDisabled(false);
  }, [isProcessing, character]);

  useEffect(() => {
    if (selectedCharacter) {
      setCharacter(selectedCharacter);
    }
  }, [selectedCharacter]);

  const labelClasses = classNames('font-semibold', isPanelbar && 'text-sm');
  const inputSize = isPanelbar ? 'md' : 'lg';

  useEffect(() => {
    if (isProcessing) {
      const interval = setInterval(() => {
        handlePoll();
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isProcessing]);

  return (
    <div>
      {!character || !positive ? (
        <StatusIndicator text={`No character set`} />
      ) : (
        <div>
          <div className="flex items-stretch">
            <div className="flex-shrink-0 w-1/2 pr-5 space-y-6">
              {error && (
                <div className="mb-8">
                  <Toast kind="error" size="full" message={error} />
                </div>
              )}

              <TextInput
                label={`Name`}
                disabled={disabled}
                labelClasses={labelClasses}
                inputSize={inputSize}
                min={1}
                max={200}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />

              {/* <GeneratorStyles isCharacterStyles /> */}

              <div className="grid grid-cols-3 gap-3">
                {ages && (
                  <Select
                    disableInput
                    labelClasses={labelClasses}
                    label={`Age`}
                    disabled={disabled}
                    value={positive.age}
                    options={ages}
                    size={inputSize}
                    onChange={(value: string) => {
                      setPositive({ age: value });
                    }}
                  />
                )}
                {genders && (
                  <Select
                    disableInput
                    labelClasses={labelClasses}
                    label={`Gender`}
                    disabled={disabled}
                    value={positive.gender}
                    options={genders}
                    size={inputSize}
                    onChange={(value: string) => {
                      setPositive({ gender: value });
                    }}
                  />
                )}
                {ethnicities && (
                  <Select
                    disableInput
                    labelClasses={labelClasses}
                    label={`Ethnicity`}
                    disabled={disabled}
                    value={positive.race}
                    options={ethnicities}
                    size={inputSize}
                    onChange={(value: string) => {
                      setPositive({ race: value });
                    }}
                  />
                )}
              </div>

              <TextInput
                placeholder={`Specify a hairstyle - use 'no' for bald!`}
                label={`Hair`}
                value={positive.hair}
                onChange={(e) => {
                  setPositive({ hair: e.target.value });
                }}
                disabled={disabled}
                labelClasses={labelClasses}
                inputSize={inputSize}
              />

              <TextArea
                placeholder={`E.g. beard, sunglasses, etc.`}
                label={`Facial Features`}
                value={positive.facial_features}
                onChange={(str) => {
                  setPositive({ facial_features: str });
                }}
                disabled={disabled}
                labelClasses={labelClasses}
                inputSize={inputSize}
                plainText
                immediate
                maxCharacters={500}
              />

              <TextArea
                placeholder={`E.g. white shirt, black jeans.`}
                label={`Clothing`}
                value={positive.clothing}
                onChange={(str) => {
                  setPositive({ clothing: str });
                }}
                disabled={disabled}
                labelClasses={labelClasses}
                inputSize={inputSize}
                plainText
                immediate
                maxCharacters={500}
              />

              <TextArea
                placeholder={`What should your character not have? E.g. feature, item of clothing, etc.`}
                label={`Features to Avoid`}
                onChange={setNegative}
                value={negative}
                disabled={disabled}
                labelClasses={labelClasses}
                inputSize={inputSize}
                plainText
                immediate
                maxCharacters={1000}
              />
            </div>

            <div className="flex-shrink-0 w-1/2 pl-5">
              <div className="flex flex-col min-h-full">
                <div className="flex items-end flex-shrink-0 mb-2 -mt-4">
                  <div className="flex-auto text-sm font-semibold">{`Preview`}</div>
                </div>

                <div className="flex items-center justify-center flex-auto px-20 border rounded-sm bg-surface-light border-border">
                  {isProcessing ? (
                    <div className="relative">
                      <FrameImageStatusOverlay title="Generating...">
                        <LoadingIndicator className="w-4 h-4 text-white" />
                      </FrameImageStatusOverlay>

                      <Img
                        width={BoordsFrameSizeHelper('9x16').width}
                        height={BoordsFrameSizeHelper('9x16').height}
                        src={getFrameImage(undefined, '9x16')}
                      />
                    </div>
                  ) : (
                    <div>
                      <Img
                        src={
                          character.preview_large_url
                            ? character.preview_large_url
                            : getMissingFrameImageURL(`portrait`)
                        }
                        className={`max-h-full`}
                      />
                      {character.preview_seed && (
                        <Toast
                          className="hidden"
                          kind="info"
                          size="full"
                          message={String(character.preview_seed)}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10">
            <ModalDivider />
            <div className="flex items-center pt-8 ">
              {character.is_global && !BoordsConfig.BackendAccess ? (
                <>
                  <div className="flex items-center flex-auto space-x-2">
                    <Badge
                      label={`Default Character`}
                      icon={
                        <LockClosedIcon className="w-3.5 text-type-subdued" />
                      }
                      size="xl"
                      color="white"
                    />
                  </div>
                  <div className="flex justify-end space-x-3">
                    <span className="flex items-center">
                      {`Make a copy to edit →`}
                    </span>
                    <Button
                      disabled={isProcessing}
                      size="lg"
                      type="solid"
                      onClick={() => {
                        handleDuplicate(character.id);
                      }}
                    >{`Make a Copy`}</Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center flex-auto space-x-4">
                    <Button
                      disabled={disabled}
                      confirm={`Are you sure?`}
                      type="secondary"
                      onClick={() => {
                        handleDestroy(character.id);
                      }}
                    >{`Delete`}</Button>
                    {character.is_global && BoordsConfig.BackendAccess ? (
                      <>
                        <Button
                          disabled={disabled}
                          size="sm"
                          type="secondary"
                          onClick={() => {
                            handleDuplicate(character.id);
                          }}
                        >{`Copy to Team`}</Button>
                        <Button
                          disabled={disabled}
                          size="sm"
                          type="secondary"
                          onClick={() => {
                            handleDuplicate(character.id, true);
                          }}
                        >{`Copy as Global Default`}</Button>
                        <Checkbox
                          label={`Active`}
                          value={character.is_active}
                          onChange={() => {
                            setIsActive(!isActive);
                          }}
                        />
                      </>
                    ) : (
                      <Button
                        disabled={disabled}
                        type="secondary"
                        onClick={() => {
                          handleDuplicate(character.id);
                        }}
                      >{`Make a Copy`}</Button>
                    )}
                  </div>
                  <div className="flex justify-end space-x-4">
                    <Button
                      disabled={disabled}
                      type="outline"
                      onClick={() => {
                        setIsCharacterEditorOpen(false);
                      }}
                    >{`Done`}</Button>
                    <Button
                      disabled={
                        disabled ||
                        (!isNameChanged &&
                          !isAttributesChanged &&
                          !isActiveChanged)
                      }
                      loading={disabled}
                      onClick={handleUpdate}
                      confirm={
                        character.is_global
                          ? `This will update this character for ALL users. Are you sure?`
                          : undefined
                      }
                    >
                      {(isNameChanged || isActiveChanged) &&
                      !isAttributesChanged ? (
                        <>{character.is_global ? `Save Global` : `Save`}</>
                      ) : isAttributesChanged ? (
                        <>{`Generate Preview`}</>
                      ) : (
                        <>{character.is_global ? `Save Global` : `Save`}</>
                      )}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
