/** @format */
import { FakeAltStoreClass } from './AltStore';
import logger from 'javascripts/helpers/logger';
import '../actions/team_management';
import '../actions/team_participation';
import ajaxHelper, { ajaxJSONRest } from 'javascripts/helpers/ajax';

import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import _ from 'underscore';
import { RequestActions } from '../actions/request';
import { TeamManagementActions } from '../actions/team_management';
import { serialise } from 'kitsu-core';
const errorHandler = RequestErrorHandler('teamManagement');
ajaxJSONRest;

export type ProjectAccessChanges = { [projectId: string]: boolean };

export class TeamManagementStore extends FakeAltStoreClass<TeamManagementStore> {
  team: any = false;
  candidates = [];
  allMembers = [];
  editingName = false;
  onboardingComplete = false;
  isFetching = false;
  teamProjects = [];

  constructor() {
    super();
    this.bindListeners({
      handleBulkInviteMember: TeamManagementActions.BULK_INVITE_MEMBER,
      handleClearMembers: TeamManagementActions.CLEAR_MEMBERS,
      handleFetch: TeamManagementActions.FETCH,
      handleInviteMember: TeamManagementActions.INVITE_MEMBER,
      handleRefresh: TeamManagementActions.REFRESH,
      handleRename: TeamManagementActions.RENAME,
      handleSetRole: TeamManagementActions.SET_ROLE,
      handleToggleProjectAccess: TeamManagementActions.TOGGLE_PROJECT_ACCESS,
      handleLeave: TeamParticipationActions.LEAVE,
      handleFetchIfNecessary: TeamManagementActions.FETCH_IF_NECESSARY,
      handleBatchToggleProjectAccess:
        TeamManagementActions.BATCH_TOGGLE_PROJECT_ACCESS,
      handleFetchWithProjects: TeamManagementActions.FETCH_WITH_PROJECTS,
    });
  }

  handleRefresh() {
    if (this.team) {
      this.handleFetch(this.team.id);
    } else {
      // RequestActions.error.defer("Error fetching Team information");
    }
  }

  handleFetchWithProjects(team_id) {
    this.handleFetchIfNecessary(team_id);

    ajaxHelper
      .ajax({
        method: 'get',
        url: '/teams/' + team_id + '/projects',
      })
      .then((response) => {
        this.teamProjects = response;
        this.emitChange();
      }, errorHandler);
  }

  handleSetRole(obj) {
    this._saveAccessAndRole({
      team_id: obj.team_id,
      membership_id: obj.membership_id,
      params: {
        role: obj.role,
      },
    });
  }

  handleLeave(obj) {
    this._saveAccessAndRole({
      team_id: obj.team_id,
      membership_id: obj.membership_id,
      params: {
        role: 'archived',
      },
      callback: function () {
        TeamParticipationActions.fetch.defer(obj.user_id);
      }.bind(this),
    });
  }

  handleToggleProjectAccess(obj) {
    this._saveAccessAndRole({
      team_id: obj.team_id,
      membership_id: obj.membership_id,
      params: {
        project_id: obj.project_id,
        can_access: obj.newAccess,
      },
      callback: function () {
        ProjectTeamActions.fetch.defer(obj.project_id);
      },
    });
  }

  handleBatchToggleProjectAccess(props: {
    newAccess: ProjectAccessChanges;
    membership_id: number;
    team_id: number;
    callback?: () => void;
  }) {
    const { newAccess, membership_id, team_id, callback } = props;
    const payload = serialise('project_access', {
      access_map: _.map(newAccess, (v, k) => ({
        project_id: parseInt(k),
        access: v,
      })),
    });

    ajaxJSONRest({
      method: 'patch',
      url: `project_access/${membership_id}`,
      data: payload,
      success: () => {
        RequestActions.success.defer('Project permissions saved!');
        this.handleFetch(team_id);
        callback?.();
      },
      error: errorHandler({
        messageKey: 'teamManagement.errors.toggleProjectAccess',
      }),
    });
  }

  handleResendInvite(invitation_id) {
    ajaxHelper.ajax({
      method: 'post',
      url: '/invitations/' + invitation_id + '/resend.json',
      success: function (response) {
        RequestActions.success.defer(response.message);
      }.bind(this),
      error: errorHandler({ messageKey: 'teamManagement.errors.resendInvite' }),
    });
  }

  _saveAccessAndRole(obj) {
    ajaxHelper.ajax({
      method: 'put',
      url:
        '/settings/team/' +
        obj.team_id +
        '/invite/' +
        obj.membership_id +
        '.json',
      data: obj.params,
      error: errorHandler(),
      success: function (response) {
        if (!_.isUndefined(obj.params.role) && obj.params.role == 'archived') {
          this._updateTeammates(response, 'remove');
        } else {
          this._updateTeammates(response, 'update');
        }
        if (!_.isUndefined(obj.callback)) {
          obj.callback();
        }
      }.bind(this),
    });
  }

  handleFetch(team_id) {
    if (!_.isNull(team_id)) {
      if (this.team) {
        if (this.team.id !== team_id) {
          this.team = false;
        }
      }
      this.isFetching = true;
      this.emitChange();

      ajaxHelper.ajax({
        method: 'get',
        url: '/teams/' + team_id + '.json',
        success: function (response) {
          this.isFetching = false;
          this.team = response;
          this.emitChange();
        }.bind(this),
        error: errorHandler(),
      });
    } else {
      logger.warn("No Team ID set, can't fetch team data");
    }
  }

  handleFetchIfNecessary(team_id) {
    if (!this.team) {
      this.handleFetch(team_id);
    }
  }

  _updateTeammates(response, action) {
    if (action == 'add') {
      RequestActions.success(response.name + ' has been added to your Team');
    } else if (_.contains(['update', 'remove'], action)) {
      RequestActions.success.defer('Team permissions saved');
    }
    this.handleRefresh();
    this.emitChange();
  }

  handleInviteMember(data) {
    ajaxHelper.ajax({
      method: 'post',
      url: '/teams/' + data.teamId + '/members.json',
      data: {
        email: data.email,
        role: data.role,
        will_have_access_to_project: data.will_have_access_to_project,
      },
      success: function (response) {
        RequestActions.success(
          'Success! An invitation will be sent to ' + data.email,
        );
        this.handleRefresh();
        // this.team.unregistered_invitations.push(response);
        // this.team.total_memberships_and_invitations_size += 1;
        this.emitChange();
      }.bind(this),
      error: errorHandler({
        message: 'Sorry, we were unable to send an invitation to ' + data.email,
      }),
    });
  }

  handleBulkInviteMember(data) {
    var emails: string[] = [];
    _.each(data.emails, function (e) {
      if (e.length > 0) {
        emails.push(e + data.domain);
      }
    });

    RequestActions.success.defer('Sending invitations...');

    ajaxHelper.ajax({
      method: 'post',
      url: '/settings/team/' + data.teamId + '/invite.json',
      data: {
        emails: JSON.stringify(emails),
        role: data.role,
        projects_with_access: data.will_have_access_to_project,
      },
      success: function (response) {
        RequestActions.success('Invitations sent');

        TeamManagementActions.fetch.defer(data.teamId);
        this.emitChange();
        if (data.callback) data.callback();
      }.bind(this),
      error: errorHandler({ messageKey: 'teamManagement.errors.bulkInvite' }),
    });
  }

  handleClearMembers() {
    this.team = false;
    this.candidates = [];
    this.allMembers = [];
    this.emitChange();
  }

  handleRename(data) {
    if (data.name.length < 2) {
      RequestActions.error.defer(
        'Team name needs to be a minimum of 2 characters',
      );
      return;
    }

    ajaxHelper.ajax({
      method: 'put',
      url: '/teams/' + data.teamId + '.json',
      data: { name: data.name },
      error: errorHandler({
        messageKey: 'teamManagement.errors.toggleProjectAccess',
      }),
      success: function (response) {
        TeamManagementActions.fetch(data.teamId);
        DashboardActions.fetch.defer();
        // RequestActions.success.defer("Team name saved");
      }.bind(this),
    });
  }
}

(window as any).TeamManagementStore = alt.createStore(
  TeamManagementStore,
  'TeamManagementStore',
);
