/*
 * Please note that this file is shared between the main app and
 * the marketing website, so please keep the two in sync.
 */
const _ = require('underscore');
window.PlanNameHelper = (function() {

  return {
    call: function(plan, currency, newSuffix) {
      var suffix = _.isUndefined(newSuffix) ? "" : newSuffix;

      if (_.isNull(currency)) {
        return plan + suffix;
      } else if (currency.toLowerCase() == "gbp") {
        return plan + "-GBP";
      } else if (currency.toLowerCase() == "eur") {
        return plan + "-EUR";
      } else {
        return plan + suffix;
      }
    },
    slugToType: function(name) {
      var normalized = name.toLowerCase();
      normalized = normalized.split("-")[0];
      normalized = normalized.replace("annual", "");
      normalized = normalized.replace(" ", "");
      return normalized;
    }
  }

})();
