const createReactClass = require('create-react-class')
const ArchiveListItem = require("./ArchiveListItem")

window.ArchivedStoryboard = createReactClass({

  _restore: function(e) {
    e.preventDefault();
    PermissionActions.verifyStoryboard.defer({
      project_id:  this.props.storyboard.project_id,
      onSuccess: function() {
        StoryboardActions.restoreStoryboard(this.props.storyboard);
      }.bind(this)
    });
  },

  _delete: function(e) {
    e.preventDefault();
    if (prompt("This will completely, irreversibly delete this storyboard from the database. Type DELETE if you want to proceed") === "DELETE") {
      StoryboardActions.deleteStoryboard(this.props.storyboard);
    }
  },

  render: function() {
    const buttons = [
      {
        onClick:  this._restore,
        label:    "Restore",
        type:   "secondary",
      },
      {
        onClick:  this._delete,
        label:    "Delete",
        type:   "destructive",
      },
    ];

    return (
      <ArchiveListItem
        title={this.props.storyboard.document_name.replace('(archived)', '')}
        subtitle={this.props.storyboard.project_name}
        deleted_at={this.props.storyboard.deleted_at}
        buttons={buttons}
      />
    );
  }

});
