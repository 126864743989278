/** @prettier */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const PopupMenuPostAction = require('./PopupMenuPostAction');
const PopupMenuStatusItem = require('./PopupMenuStatusItem');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const PopupMenuItem = require('./PopupMenuItem');
const {
  default: Popover,
} = require('blackbird/components/common/popover/Popover');
const {
  default: TextInput,
} = require('blackbird/components/form/text-input/TextInput');
const { default: Panel } = require('blackbird/components/panel/Panel');
import SearchIcon from 'blackbird/images/icons/search.svg';
import { ClearButton } from 'blackbird/components/button/ClearButton';
import Icon from 'blackbird/components/icon/Icon';
import * as _ from 'underscore';

const maxHeight = { maxHeight: '60vh' };

window.PopupMenu = createReactClass({
  displayName: 'PopupMenu',
  mixins: [PureRenderMixin],

  propTypes: {
    iconPadding: PropTypes.string,
    innerPadding: PropTypes.string,
    useDotButton: PropTypes.bool,
    button: PropTypes.element,
    iconClassName: PropTypes.string,
    distance: PropTypes.number,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        newWindow: PropTypes.bool,
      }),
    ),

    postActions: PropTypes.arrayOf(
      PropTypes.shape({
        actionType: PropTypes.string.isRequired,
        className: PropTypes.string,
        url: PropTypes.string,
        params: PropTypes.object,
        method: PropTypes.string,
        label: PropTypes.string.isRequired,
      }),
    ),

    components: PropTypes.arrayOf(
      PropTypes.shape({
        clickParam: PropTypes.any,
        onClick: PropTypes.func,
        className: PropTypes.string,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,

    initialIndex: PropTypes.number,
    center: PropTypes.bool,
    width: PropTypes.number,
    showArrows: PropTypes.bool,
    handleFocus: PropTypes.bool.isRequired,
    emptyState: PropTypes.element,

    // props passed to HoverOverlay
    opensOnIconHover: PropTypes.bool,
    show: PropTypes.bool,
    position: PropTypes.string,
    observeMutations: PropTypes.bool,
    allowSearch: PropTypes.bool,
    searchLabel: PropTypes.string,
    onFirstOpen: PropTypes.func,
  },

  getDefaultProps() {
    return {
      opensOnIconHover: true,
      useDotButton: false,
      iconClassName: '',
      width: 200,
      iconPadding: '',
      innerPadding: '',
      links: [],
      components: [],
      searchLabel: 'Search',
    };
  },
  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      if (!this.state.firsOpen) {
        this.setState({ firsOpen: true });
        this.props.onFirstOpen();
      }
      this.handleOpenOrClose(this.props.show);
    }
  },
  getInitialState() {
    return {
      searchQuery: '',
      index: _.isNumber(this.props.initialIndex)
        ? this.props.initialIndex
        : false,
      firsOpen: false,
    };
  },

  componentWillUnmount() {
    this.onClose();
  },

  itemRefs: React.createRef(),
  searchfieldRef: React.createRef(),

  focus() {
    this.setState({ index: 0 });

    if (this.props.allowSearch && this.searchfieldRef.current) {
      this.searchfieldRef.current?.focus();
    } else {
      ReactDOM.findDOMNode(this).focus();
    }
  },

  setFocus(index) {
    this.setState({ index });
  },

  onOpen() {
    if (!this.state.fetched) {
      this.setState({ fetched: true });
      this.props.onFirstOpen?.();
    }

    if (this.props.handleFocus) {
      this.focus();
      window.addEventListener('keydown', this.handleKeyDown, { capture: true });
    }
  },

  onClose() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },

  handleOpenOrClose(isOpen) {
    this.setState({ isOpen }, () => {
      if (isOpen) {
        this.onOpen();
      } else {
        this.onClose();
      }
    });
  },

  handleKeyDown(e) {
    if (!this.state.isOpen && !this.props.show) return;

    if (e.key === 'Enter' && _.isNumber(this.state.index)) {
      this.itemRefs.current[this.state.index]?.onClick(e);
      e.preventDefault();
    } else {
      const delta = e.key === 'ArrowUp' ? -1 : e.key === 'ArrowDown' ? 1 : 0;
      const max = this.itemRefs.current.length - 1;

      if (delta !== 0) {
        this.setState(
          (state) => {
            const newIndex = Math.max(0, Math.min(max, state.index + delta));
            return {
              index: newIndex,
            };
          },
          () => {
            ReactDOM.findDOMNode(
              this.itemRefs.current[this.state.index],
            )?.scrollIntoView({
              block: 'nearest',
              inline: 'nearest',
            });
          },
        );

        e.preventDefault();
      }
    }
  },

  filterComponents(components) {
    if (this.state.searchQuery.length < 2) return components;
    return components.filter(
      (c) =>
        c.label?.toLowerCase().indexOf(this.state.searchQuery.toLowerCase()) >=
        0,
    );
  },

  updateSearchQuery(value) {
    this.setState({ searchQuery: value, index: 0 });
  },

  render() {
    const iconClass = 'popup-menu__toggle ' + this.props.iconClassName;
    const position = (this.props.position || 'left').split('-')[0];
    const useDotButton = this.props.useDotButton;
    const minimalStyles = 'ph3 pv10px lh-copy f5 underline-hover';
    const button = this.props.button || <i className={iconClass} />;

    return (
      <div className={this.props.iconPadding}>
        <Popover
          distance={this.props.distance}
          placement={position}
          isOpen={Boolean(this.props.show)}
        >
          <Popover.Button>{button}</Popover.Button>
          <Popover.Panel
            as={Panel}
            className="flex-col w-80 absolute top-full left-0 focus:outline-none"
            autoMaxHeight
          >
            {() => {
              const components = [];
              const current = [];
              this.itemRefs.current = current;
              const ref = (element) => current.push(element);

              const overrideHeight =
                _.any(this.props.links, (l) => l.description) ||
                _.any(this.props.components, (l) => l.description);

              const isActive = (index) =>
                _.isNumber(this.state.index) &&
                components.length + index === this.state.index;

              if (this.props.statuses) {
                components.push(
                  ...this.props.statuses.map((s, index) => (
                    <PopupMenuStatusItem
                      key={'component-' + index}
                      ref={ref}
                      showBorder={index !== this.props.statuses.length}
                      onClick={s.onClick}
                      label={s.status.label}
                      color={s.status.color}
                    />
                  )),
                );
              }

              if (this.props.postActions) {
                components.push(
                  ...this.props.postActions.map((action, index) => (
                    <PopupMenuPostAction
                      key={'action-' + index}
                      action={action}
                    />
                  )),
                );
              }

              if (this.props.components) {
                components.push(
                  ...this.filterComponents(this.props.components).map(
                    (component, index) => (
                      <PopupMenuItem
                        key={'component-' + index}
                        ref={ref}
                        onClick={component.onClick}
                        onClickProps={component.clickParam}
                        className={component.className}
                        label={component.label}
                        description={component.description}
                        showBorder={
                          index !==
                          this.props.links.length +
                            this.props.components.length -
                            1
                        }
                        isActive={isActive(index)}
                        height={
                          useDotButton
                            ? ''
                            : overrideHeight
                            ? 'h-[60px]'
                            : undefined
                        }
                        padding={
                          useDotButton
                            ? minimalStyles
                            : overrideHeight
                            ? 'py-3 px-4'
                            : undefined
                        }
                        center={useDotButton ? false : this.props.center}
                        showArrow={this.props.showArrows}
                        icon={component.icon}
                        onMouseEnter={() => this.setFocus(index)}
                      />
                    ),
                  ),
                );
              }

              if (this.props.links) {
                components.push(
                  ...this.filterComponents(this.props.links).map(
                    (link, index) => (
                      <PopupMenuItem
                        ref={ref}
                        key={'link-' + index}
                        label={link.label}
                        description={link.description}
                        href={link.url}
                        newWindow={link.newWindow}
                        isActive={isActive(index)}
                        height={
                          this.props.useDotButton
                            ? ''
                            : overrideHeight
                            ? 'min-h60px'
                            : undefined
                        }
                        padding={
                          this.props.useDotButton
                            ? minimalStyles
                            : overrideHeight
                            ? 'pa3'
                            : undefined
                        }
                        showBorder={
                          index !== this.props.links.length - 1 ||
                          this.props.components.length > 0
                        }
                        center={
                          this.props.useDotButton ? false : this.props.center
                        }
                        showArrow={this.props.showArrows}
                        icon={link.icon}
                        onMouseEnter={() => this.setFocus(index)}
                      />
                    ),
                  ),
                );
              }

              return (
                <>
                  {this.props.allowSearch && (
                    <TextInput
                      className="m-4 mb-0"
                      value={this.state.searchQuery}
                      name="searchQuery"
                      inputSize="md"
                      placeholder={this.props.searchLabel}
                      onChange={(e) => this.updateSearchQuery(e.target.value)}
                      ref={this.searchfieldRef}
                      leftComponent={
                        <Icon
                          color="subdued"
                          icon={<SearchIcon />}
                          className="w-4 ml-1"
                        />
                      }
                      rightComponent={
                        <ClearButton
                          onClick={() => this.updateSearchQuery('')}
                        />
                      }
                      autoComplete="off"
                      autoFocus
                    />
                  )}

                  <div
                    className="flex--auto overflow-y-auto pt-4"
                    style={maxHeight}
                  >
                    {components?.length > 0
                      ? components
                      : this.props.emptyState}
                  </div>

                  {this.props.bottomSection && (
                    <div className="border-border border-t">
                      {this.props.bottomSection}
                    </div>
                  )}
                </>
              );
            }}
          </Popover.Panel>
        </Popover>
      </div>
    );
  },
});
