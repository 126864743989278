/** @prettier */
import type { supportedFormats, supportedLayouts } from '../types';
import type { AspectRatioName } from 'javascripts/types/storyboard';
import { throttle } from 'underscore';
import logger from 'javascripts/helpers/logger';

/**
 * This object tells us which layouts are available for which papersize and
 * frame orientation (some layouts would be very impractical)
 */
export const availableLayouts: Record<
  supportedFormats,
  Record<AspectRatioName, supportedLayouts[]>
> = {
  a4: {
    landscape: ['6grid', 'list', '4grid', '3up', '2grid', '1up', '1up2Col'],
    widescreen: ['6grid', 'list', '4grid', '3up', '2grid', '1up', '1up2Col'],
    anamorphic: ['6grid', 'list', '4grid', '3up', '2grid', '1up', '1up2Col'],
    portrait: ['3up'],
    social: ['3up'],
    square: ['3up', '2grid', '1up', '1up2Col', 'list'],
    tv: ['3up', '2grid', '1up', '1up2Col', 'list'],
  },
  widescreen: {
    landscape: ['8grid', '6grid', '4grid', '3up', '2grid', '1up', '1up2Col'],
    widescreen: ['8grid', '6grid', '4grid', '3up', '2grid', '1up', '1up2Col'],
    anamorphic: ['8grid', '6grid', '4grid', '3up', '2grid', '1up', '1up2Col'],
    portrait: ['3up'],
    social: ['3up'],
    square: ['3up', '2grid', '1up', '1up2Col'],
    tv: ['3up', '2grid', '1up', '1up2Col'],
  },
};

export const layoutIsSupported = (
  layoutRequested: supportedLayouts,
  format: supportedFormats,
  frameAspectRatio: AspectRatioName,
) => {
  return (
    availableLayouts[format][frameAspectRatio].indexOf(layoutRequested) >= 0
  );
};

const throttledLog = throttle((...args) => logger.log(...args), 2000, {
  trailing: false,
});

export const fallbackIfLayoutNotSupported = (
  layoutRequested: supportedLayouts,
  format: supportedFormats,
  frameAspectRatio: AspectRatioName,
): supportedLayouts => {
  const supported = layoutIsSupported(
    layoutRequested,
    format,
    frameAspectRatio,
  );

  const fallback = availableLayouts[format][frameAspectRatio][0];

  if (!supported) {
    throttledLog(
      `Saved combination not supported: ${format} + ${frameAspectRatio} + ${layoutRequested}. Now using ${fallback}.`,
    );
  }

  return supported ? layoutRequested : fallback;
};
