/** @format */
import { sanitizeHTML } from 'blackbird/components/form/richTextInput/sanitizeHTML';
import {
  markdownParser,
  type MarkdownParserProps,
} from 'javascripts/helpers/markdown-parser';

export interface ParseAndSanitizeTextOptions
  extends Partial<MarkdownParserProps> {
  allowedTags?: string[];
  skipMarkdown?: boolean;
}

/** Combines common text parsing and processing (markdown and sanitisation) in
 * one logic
 */
export function parseAndSanitizeText(
  string?: string,
  asFragment?: true,
  options?: ParseAndSanitizeTextOptions,
): DocumentFragment;
export function parseAndSanitizeText(
  string?: string,
  asFragment?: false,
  options?: ParseAndSanitizeTextOptions,
): string;
export function parseAndSanitizeText(
  string = '',
  asFragment = false,
  options: ParseAndSanitizeTextOptions = {},
) {
  if (!string.length) return asFragment ? new DocumentFragment() : '';

  /** If the text already has HTML in it, we do not need to re-parse it with the
   * markdown parser. Sometimes the markdown parser adds unnecessary whitespace
   * characters, so let's avoid those. We only have to see if the first
   * character is a `<` really */
  const textHasHTML = Boolean(string.match(/\w*<[a-z]/));
  // If the text begins with HTML, we don't want to parse it again
  if (!textHasHTML && !options.skipMarkdown) {
    string = markdownParser(string, {
      openLinksInNewWindow: true,
      allowHeadings: false,
    });
  }
  // the sanitizer throws an error if options.allowedTags is blank, so let's
  // prevent that from happening
  const sanitizeOptions = options.allowedTags
    ? { ALLOWED_TAGS: options.allowedTags }
    : {};

  // This duplication is odd, but required for typescript to not flip out about
  // the overloads
  if (asFragment) {
    return sanitizeHTML(string, asFragment, sanitizeOptions);
  } else {
    return sanitizeHTML(string, asFragment, sanitizeOptions);
  }
}
