/** @prettier */
/* eslint eqeqeq:0, react/jsx-handler-names:0, react/sort-comp:0 */
import * as React from 'react';
import { PanelBarLayout } from '../shared/PanelBarLayout';
import BottomBar from './toolbar/BottomBar';
import { FrameActions } from '../../flux/actions/frame';
import { FilestackActions } from '../../flux/actions/filestack';
import { FrameViewer } from '../player/FrameViewer';
import { cannotCreateNewFrame } from '../../helpers/can-create-new-frame';
import { FrameEditorActions } from '../../flux/actions/frame_editor';
import type { FrameEditorStore } from '../../flux/stores/frame_editor';
import { type IFrameOverlay } from './FrameOverlays';
import type { DrawingTool } from './FrameEditorEngine';
import { FrameFocusTopBar } from '../shared/FrameFocusTopBar';
import { FrameEditorContextMenu } from './FrameEditorContextMenu';
import type { ContextMenuActions } from './types';
import * as memoize from 'memoizee';
import * as navigateToRoute from '../../helpers/router/navigate-to-route';
import { isNumber, pick } from 'underscore';
import { BottomBarBrushOptions } from './toolbar/BottomBarBrushOptions';
import type { arrangementValues } from './types';
import { ToursActions, tourEvents } from '../../flux/actions/tours';
import { FrameFocusPanelbar } from '../panelbars/FrameFocusPanelbar';
import { FrameEditorDropzone } from './FrameEditorDropzone';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { Translation, withTranslation, WithTranslation } from 'react-i18next';
import { FrameEditorDialog } from './FrameEditorDialog';
import { isImagePlaceholder } from 'javascripts/helpers/isImagePlaceholder';
import { ErrorBoundaryContext } from '../shared/ErrorBoundary';
import { isInInput } from 'javascripts/helpers/isInInput';
import { FrameEditorContext } from './FrameEditorContext';
import { AnnotationOverlay } from 'blackbird/components/comments/annotations/AnnotationOverlay';
import { AutoSizedFrame } from '../AutoSizedFrame';

interface Props extends FrameEditorStore, WithTranslation {
  hasSidebar: boolean;
  maxWidth?: number;
  onDropImage: (...props) => void;
  onSave: (...props) => void;
  onSetIndex: (...props) => void;
  onTriggerClose: (...props) => void;
  onSetFocusMode: (...props) => void;
}

// This file should not be required by itself
// It depends on its Container to pass along frame data and handle
// navigation & such.
class FrameEditorUI extends React.PureComponent<Props> {
  /** The element that will hold the actual Frame Editor canvas */
  containerEl: React.RefObject<HTMLDivElement>;
  _lastTimeout: number;
  _isMounted = false;
  isDiscarding = false;
  drawTool?: DrawingTool;
  _currentImageUrl: string;
  /** We want to access the error boundary triggering code */
  static contextType = ErrorBoundaryContext;

  constructor(props) {
    super(props);
    this.containerEl = React.createRef();
  }

  static defaultProps = {
    maxWidth: 1,
  };

  updateLoadingState = (newLoadingState) => {
    if (this._lastTimeout) window.clearTimeout(this._lastTimeout);

    this._lastTimeout = window.setTimeout(() => {
      // if (this.props.isLoading === newLoadingState) { return; }
      FrameEditorActions.updateLoading.defer(newLoadingState);
    }, 200);

    // Rerender the UI to make sure that getters for things
    // like backgroundColor work as expected
    this._forceUIUpdate();
  };

  getDrawingProps(newProps?) {
    const props = newProps || this.props;

    return Object.assign(
      // prettier-ignore
      pick(props, [
        'frame', 'maxWidth', 'onChangeMode', 'onUpdateSaveState', 'shapesColor','eraserSize', 'shapeType', 'isEditorActive', 'togglePreview', 'updateHistoryIndex', 'onionSkinStrength', 'onionSkinFrame', 'currentDecoration',
      ]),
      {
        // Don't add any additional props here, all additional props should be
        // added by the parent, so they can be verified by the PropTypes :)
        mode: props.editingMode,
        onLoad: this.updateLoadingState,
        forceUIUpdate: this._forceUIUpdate,
        onImageDrop: this.props.onDropImage,
        aspectRatio: props.storyboard.frame_aspect_ratio,
        team_id: props.storyboard.project.owner.id,
      },
    );
  }

  _forceUIUpdate = () => {
    if (this._isMounted) this.forceUpdate();
  };

  dimensions: DOMRect;
  mounting = false;
  _initializeDrawingTool(withProps) {
    if (this.mounting) return;
    if (this.drawTool) {
      this.drawTool.unmount();
      this.drawTool = undefined;
    }

    // Store the new image URL so we can check for updated images
    // in componentDidUpdate (after a crop, for example).
    // we have to do it like this because of mutable frame objects).
    this._currentImageUrl = this.props.frame.large_image_url;

    this.mounting = true;
    import('./FrameEditorEngine')
      .then(({ DrawingTool }) => {
        if (!this.containerEl.current || !this._isMounted || !this.dimensions) {
          this.mounting = false;
          return;
        }

        this.drawTool = new DrawingTool(
          this.containerEl.current,
          this.getDrawingProps(withProps) as any,
          this.dimensions,
        );
        this.mounting = false;
      })
      .catch((e) => this.context(e));
  }

  componentDidMount() {
    this._isMounted = true;
    this._initializeDrawingTool(this.props);
    document.addEventListener('keydown', this._handleKeyPress, true);
    window.addEventListener('copy', this.handleCopy);
    window.addEventListener('paste', this.handlePaste);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    ToursActions.triggerEvent.defer(tourEvents.openFrameEditor);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleKeyPress);
    document.removeEventListener('copy', this.handleCopy);
    document.removeEventListener('paste', this.handlePaste);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    this._isMounted = false;

    const clean = () => (this.drawTool = undefined);

    if (this.drawTool) {
      if (this.isDiscarding) {
        this.drawTool.unmount();
        clean();
      } else {
        this.saveIfNecessary(true).then(clean);
      }
    }
  }

  handlePaste = (e) => {
    if (this.drawTool) this.drawTool.paste(e);
  };

  handleCopy = (e) => {
    if (this.drawTool) this.drawTool.copy(e);
  };

  handleBeforeUnload = (e) => {
    if (this.props.unsavedChanges) {
      const string =
        'You have unsaved changes, are you sure you want to leave?';
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = string;
      return string;
    }
  };

  componentDidUpdate(prevProps) {
    // Pass along updated settings to the draw tool
    if (
      prevProps.frame !== this.props.frame ||
      this._currentImageUrl !== this.props.frame.large_image_url
    ) {
      this.updateLoadingState(true);
      this._initializeDrawingTool(this.props);
    } else if (this.drawTool) {
      this.drawTool.setOptions(this.getDrawingProps(this.props));
    }
  }

  saveIfNecessary = (andClose = false) => {
    return new Promise<void>((resolve) => {
      if (!this.drawTool) return resolve();
      if (this.props.unsavedChanges) {
        this.drawTool.save().then((data) => {
          this.props.onSave(data, resolve);
          if (andClose === true) this.drawTool!.unmount();
        });
      } else {
        if (andClose === true) this.drawTool.unmount();
        return resolve();
      }
    });
  };

  toggleOnionSkinStrength = () => {
    FrameEditorActions.updateOnionSkinStrength(
      this.props.onionSkinStrength > 0 ? 0 : 0.2,
    );
  };

  _handleKeyPress = (e) => {
    if (
      !this.drawTool ||
      !this.drawTool.allowShortcuts() ||
      isInInput(e.target)
    )
      return;

    //press e for eraser
    if (e.key == 'e') {
      e.preventDefault();
      FrameEditorActions.updateEditingMode('eraser');
    }

    //press b for brush
    if (e.key == 'b') {
      e.preventDefault();
      FrameEditorActions.updateEditingMode('draw');
    }

    //press t for text
    if (e.key == 't') {
      e.preventDefault();
      FrameEditorActions.updateEditingMode('text');
    }

    if (e.key == '[') {
      e.preventDefault();
      this.drawTool.cycleBrushSize(-1);
    }

    if (e.key == ']') {
      e.preventDefault();
      this.drawTool.cycleBrushSize(+1);
    }

    if (e.key == 'o') {
      e.preventDefault();
      this.drawTool.insertShape('ellipse');
    }

    // If there's a selection, we want to deselect it when pressing escape
    if (e.key === 'Escape' && this.getSelection()) {
      this.drawTool.deselectAll();
      e.stopPropagation();
    }

    // This is also an o, but because of the modifier keys this resolves to
    // a different e.key, so we use keyCode
    if (e.keyCode === 79 && e.altKey && e.shiftKey) {
      e.preventDefault();
      this.toggleOnionSkinStrength();
    }

    // Delete or backspace
    if (e.keyCode == 6 || e.keyCode === 8) {
      e.preventDefault();
      this.drawTool.delete();
    }

    if (e.metaKey || e.ctrlKey) {
      if (e.key === 'd') {
        this.drawTool.duplicate();
        e.preventDefault();
      }
      if (e.key === 'a') {
        this.drawTool.selectAll();
        e.preventDefault();
      }
    } else {
      //press v for select (photoshop style)
      if (e.keyCode == 86) {
        e.preventDefault();
        FrameEditorActions.updateEditingMode('select');
      }

      if (e.key == 'r') {
        e.preventDefault();
        this.drawTool.insertShape('rect');
      }

      if (e.key == 'a') {
        e.preventDefault();
        this.drawTool.insertShape('arrow');
      }
    }
  };

  requestClose = () => {
    if (this.props.dialogOptions) return Promise.reject();
    if (!this.props.unsavedChanges) return Promise.resolve();
    const t = this.props.t;

    return new Promise<void>((resolve) =>
      FrameEditorActions.openDialog({
        title: t('frameEditor.dialogs.unsavedChanges.title'),
        message: t('frameEditor.dialogs.unsavedChanges.message', {
          frameNumber: this.props.frameIndex + 1,
        }),
        actions: [
          {
            label: t('frameEditor.dialogs.unsavedChanges.saveButton'),
            buttonType: 'solid',
            onClick: () =>
              this.drawTool!.save().then((data) =>
                this.props.onSave(data, () => {
                  ToursActions.triggerEvent.defer(
                    tourEvents.saveAndCloseFrameEditor,
                  );
                  resolve();
                }),
              ),
          },
          {
            label: t('frameEditor.dialogs.unsavedChanges.cancelButton'),
            buttonType: 'secondary',
            onClick: () => {},
          },
          {
            label: t('frameEditor.dialogs.unsavedChanges.discardButton'),
            buttonType: 'destructive',
            onClick: () => {
              this.isDiscarding = true;
              resolve();
            },
          },
        ],
      }),
    );
  };

  requestNavigate() {
    if (this.props.dialogOptions) return Promise.reject();
    if (this.drawTool && this.drawTool.getCurrentSelection())
      return Promise.reject();
    return this.saveIfNecessary();
  }

  duplicateFrame = () => {
    const id = this.props.frame.id;
    this.saveIfNecessary().then(() => {
      FrameEditorActions.duplicateFrame.defer(id);
    });
  };

  appendFrame = () => {
    FrameEditorActions.updateLoading(true);

    this.saveIfNecessary().then(() => {
      FrameActions.insertFrame.defer({
        storyboard_id: this.props.storyboard.id,
        dropped_sort_order: parseInt(this.props.frame.sort_order) + 1,
        move_frames_after_here: true,
        callback: () =>
          ToursActions.triggerEvent.defer(tourEvents.createFrameFromEditor),
      });
    });
  };

  handleDeleteFrameClick = (e) => {
    const id = parseInt(e.currentTarget.value);
    if (!isNumber(id)) return;

    FrameEditorActions.openDialog({
      message: 'You are about to delete a frame',
      actions: [
        {
          label: 'Continue',
          buttonType: 'destructive',
          onClick: () => {
            navigateToRoute('storyboard.show', {
              slug: this.props.storyboard.slug,
            });

            FrameActions.deleteFrames.defer(id);
          },
        },
        {
          label: 'Cancel',
          buttonType: 'solid',
          onClick: () => {},
        },
      ],
    });
  };

  handleInsertOverlay = (overlay: IFrameOverlay | string) => {
    FrameEditorActions.updateDecoration({
      overlay: overlay,
    });
    HoverOverlayActions.closeAll.defer();
    Track.event.defer('insert_camera_movement');
  };

  handleImageCrop = () => {
    const message = this.props.unsavedChanges
      ? this.props.t('frameEditor.dialogs.imageCrop.messageWithUnsavedChanges')
      : this.props.t('frameEditor.dialogs.imageCrop.message');

    FrameEditorActions.openDialog({
      message,
      actions: [
        {
          label: 'Cancel',
          buttonType: 'secondary',
          onClick: () => {},
        },
        {
          label: 'Continue',
          buttonType: 'destructive',
          onClick: () =>
            this.saveIfNecessary().then(() => {
              FilestackActions.openCropUI({
                frame: this.props.frame,
                frame_aspect_ratio: this.props.storyboard.frame_aspect_ratio,
              });
            }),
        },
      ],
    });
  };

  clear = () => this.drawTool!.clear();
  undo = () => this.drawTool!.undo();
  redo = () => this.drawTool!.redo();
  delete = () => this.drawTool!.delete();

  handleResize = (size: DOMRect) => {
    this.dimensions = size;
    if (this.drawTool) {
      this.drawTool.resize(size);
    } else {
      this._initializeDrawingTool(this.props);
    }
  };

  onRemoveBackgroundClick = () => {
    this.drawTool!.removeBackground();
  };
  setBackgroundColor = (c) => {
    FrameEditorActions.updateDecoration({
      background: c,
    });
    this._forceUIUpdate();
  };

  getBackgroundColor = () => {
    return this.drawTool ? this.drawTool.getBackgroundColor() : '#ffffff';
  };
  getSelection = () => {
    return this.drawTool ? this.drawTool.getCurrentSelection() : null;
  };

  handleBgClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Check if we're actually clicking on this element and not it's children (a.k.a the actual canvas)
    if (e.target === e.currentTarget) {
      this.drawTool?.deselectAll();
    }
  };

  getActions = memoize(
    (drawTool?: DrawingTool): ContextMenuActions | undefined => {
      if (!drawTool) return;

      return {
        triggerRerender: () => drawTool.canvas.requestRenderAll(),
        handleReorder: (e) => drawTool.reorder(e.currentTarget.value as any),
        handleDeleteObject: (e) => drawTool.delete(),
        handleFlipObjects: (e) => {
          const direction: any = e.currentTarget.value.match(/flip-(\w)/)?.[1];
          if (direction) drawTool.flipObjects(direction);
        },
        handleFlipCanvas: (e) => {
          if (!e) return;
          const direction: any = e.currentTarget.value.match(/flip-(\w)/)?.[1];
          if (direction) drawTool.flipCanvas(direction);
        },
        handleAlignObjects: (align) => drawTool.alignSelectedObjects(align),
        handleArrangeObjects: (e) =>
          drawTool.arrangeObjects(e.currentTarget.value as arrangementValues),
        handleSetFillColor: (object, color) => {
          drawTool.updateObjectProp(object, 'fill', color);
          ToursActions.triggerEvent.defer(tourEvents.changeFillColor);
        },
        handleSetStrokeColor: (object, color) =>
          drawTool.updateObjectProp(object, 'stroke', color),
        handleSetFontFamily: (object, font) => {
          drawTool.setFontFamily(object, font);
          ToursActions.triggerEvent.defer(tourEvents.changeFont);
        },
        handleSetStrokeWidth: (object, e) =>
          drawTool.updateObjectProp(
            object,
            'strokeWidth',
            parseInt(e.currentTarget.value, 10),
          ),
        handleSetFontSize: (object, e) =>
          drawTool.updateObjectProp(
            object,
            'fontSize',
            parseFloat(e.currentTarget.value),
          ),
        handleSetTextAlign: (object, alignment) =>
          drawTool.updateObjectProp(object, 'textAlign', alignment),
        handleSetBorderRadius: (object, e) => {
          const value = parseFloat(e.currentTarget.value) * 10;
          drawTool.updateObjectProp(object, 'rx', value);
          drawTool.updateObjectProp(object, 'ry', value);
        },
        // We add a small delay here, otherwise the deselectAll doesn't always
        // work
        handleDeselectAll: () => drawTool.deselectAll(),
      };
    },
    { size: 1 },
  );

  render() {
    const actions = this.getActions(this.drawTool);
    const hasOnionSkin =
      this.props.onionSkinFrame &&
      this.props.onionSkinStrength > 0 &&
      !isImagePlaceholder(this.props.onionSkinFrame.large_image_url);

    const containerStyle = hasOnionSkin
      ? {
          backgroundColor: 'white',
          backgroundImage: `url(${this.props.onionSkinFrame!.large_image_url})`,
          backgroundSize: 'cover',
        }
      : undefined;

    return (
      <FrameEditorContext.Provider value={this.drawTool}>
        <PanelBarLayout
          sidebarComponent={
            this.props.hasSidebar && (
              <FrameFocusPanelbar
                storyboard={this.props.storyboard}
                frames={this.props.frames}
                activeIndex={this.props.frameIndex}
                onSetFrameIndex={this.props.onSetIndex}
                canManage={true}
                focusType="frameEditor"
                restrictWidth
              />
            )
          }
          footerClassName="text-center"
          footerComponent={
            <BottomBar
              {...this.getDrawingProps()}
              frame={this.props.frame}
              drawToolEnabled={!!this.drawTool}
              backgroundColor={this.getBackgroundColor()}
              onNewFrameClick={this.appendFrame}
              onDuplicateClick={this.duplicateFrame}
              onClearClick={this.clear}
              onUndo={this.undo}
              onRedo={this.redo}
              onChangeBackgroundColor={this.setBackgroundColor}
              handleImageCrop={this.handleImageCrop}
              onRemoveBackgroundClick={this.onRemoveBackgroundClick}
              onToggleOnionSkinStrength={this.toggleOnionSkinStrength}
              canUndo={this.drawTool ? this.drawTool.canUndo() : false}
              canRedo={this.drawTool ? this.drawTool.canRedo() : false}
              onInsertOverlay={this.handleInsertOverlay}
              hasOverlay={Boolean(this.props.currentDecoration?.overlay)}
              canCreateNewFrame={!cannotCreateNewFrame(FrameStore.getState())}
              onFlipCanvas={actions?.handleFlipCanvas ?? false}
              onDeleteFrameClick={this.handleDeleteFrameClick}
              onDeselectAll={actions?.handleDeselectAll}
              hasSidebar={this.props.hasSidebar}
            >
              {this.props.editingMode === 'draw' ||
              this.props.editingMode === 'eraser' ? (
                <BottomBarBrushOptions
                  frameEditor={this.drawTool}
                  actions={actions}
                  editingMode={this.props.editingMode}
                />
              ) : null}
            </BottomBar>
          }
          headerComponent={
            <FrameFocusTopBar
              onTriggerClose={this.props.onTriggerClose}
              onSetMode={this.props.onSetFocusMode}
              onSetIndex={this.props.onSetIndex}
              maxIndex={this.props.frames.length - 1}
            />
          }
        >
          <>
            <div
              className="relative flex items-center justify-center w-full h-full px-7 overflow-hidden"
              onClick={this.handleBgClick}
            >
              <AutoSizedFrame
                frameAspectRatio={this.props.storyboard.frame_aspect_ratio}
                onResize={this.handleResize}
                border
              >
                {(sizes) => (
                  <>
                    <div ref={this.containerEl} style={containerStyle}>
                      <FrameEditorContextMenu
                        selected={this.getSelection()}
                        disabled={!this.drawTool}
                        actions={actions}
                        container={this.containerEl}
                      />

                      <FrameEditorDropzone />
                    </div>

                    {this.props.showPreview && this.containerEl.current ? (
                      <div className="z-2">
                        {hasOnionSkin ? (
                          <FrameViewer
                            frame={this.props.onionSkinFrame}
                            isSubtitlesEnabled={false}
                            frameAspectRatio={
                              this.props.storyboard.frame_aspect_ratio
                            }
                            cursor="wait"
                            framesAreSaving={false}
                            border={false}
                            size={sizes}
                          />
                        ) : null}

                        <FrameViewer
                          frame={this.props.frame}
                          isSubtitlesEnabled={false}
                          frameAspectRatio={
                            this.props.storyboard.frame_aspect_ratio
                          }
                          cursor="wait"
                          framesAreSaving={false}
                          opacity={
                            hasOnionSkin ? 1 - this.props.onionSkinStrength : 1
                          }
                          border={false}
                          size={sizes}
                        />
                      </div>
                    ) : null}

                    <AnnotationOverlay
                      key={this.props.frame.id}
                      currentFrameId={this.props.frame.id}
                      frameAspectRatio={
                        this.props.storyboard.frame_aspect_ratio
                      }
                      sizes={sizes}
                      passThrough={false}
                    />

                    <Translation>
                      {(t) => (
                        <LoadingIndicator
                          fill
                          bg
                          text={
                            this.props.isSaving
                              ? t('frameEditor.saving')
                              : undefined
                          }
                          show={
                            this.props.isSaving ||
                            (this.props.isLoading && this.props.isEditorActive)
                          }
                        />
                      )}
                    </Translation>
                  </>
                )}
              </AutoSizedFrame>
            </div>

            <FrameEditorDialog options={this.props.dialogOptions} />
          </>
        </PanelBarLayout>
      </FrameEditorContext.Provider>
    );
  }
}

export default withTranslation(undefined, { withRef: true })(FrameEditorUI);
