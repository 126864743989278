module.exports = function() {
  return {
    setRailsApiAuthHeader: function(xhr) {
      xhr.setRequestHeader('X-API-KEY', window.BoordsConfig.AuthenticationToken);
    },
    setRailsApiUrl: function(url) {
      return "/api/" + url;
    },
    setGoApiAuthHeader: function(xhr) {
      xhr.setRequestHeader('X-Authentication-Token', window.BoordsConfig.AuthenticationToken);
      xhr.setRequestHeader('X-CustomStorage', window.BoordsConfig.CustomStorage);
      xhr.setRequestHeader('X-Email', window.BoordsConfig.Email);
      xhr.setRequestHeader('X-Pro', true);
      xhr.setRequestHeader('X-Uid', window.BoordsConfig.Uid);
    },
    setGoApiUrl: function(url) {
      return window.BoordsConfig.ApiUrl + url;
    },
  }
};
