/** @prettier */

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { rollbar as Rollbar } from 'javascripts/helpers/rollbar';
import {
  apiRequest,
  reportApiRequestError,
} from 'blackbird/helpers/apiRequestHelper';
import { RequestActions } from 'javascripts/flux/actions/request';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { addMonths, format, startOfMonth } from 'date-fns';
import { type UserStore } from '../../../javascripts/flux/stores/user';
import { useStore } from '../../../javascripts/helpers/useStore';
import { type UserResponse } from '../../../javascripts/types/user';
import { type LocalPlanData } from './BillingContext';

type CancelationStageId =
  | 'pause_offer'
  | 'credit_offer'
  | 'reason'
  | 'confirm_cancelation'
  | 'pause_complete';

export interface Reason {
  label: string;
  value: string;
  prompt: string;
  detail: string;
  cta: string;
  action: string;
  cancelDetail?: string;
}

export interface CancelationParagraph {
  text?: string;
  list_items?: string[];
  inputId?: string;
}

export interface CancelationStage {
  id: CancelationStageId;
  title: string;
  subtitle?: string;
  buttonCta: string;
  paragraphs: CancelationParagraph[];
}

export interface CancelationButtonProps {
  hideBack?: boolean;
  children: React.ReactElement;
}

export interface CancelationContextProps {
  reasons: Reason[];
  creditCount: string;
  pauseRestartMonth: string;
  selectedReason: Reason | undefined;
  setSelectedReason: React.Dispatch<React.SetStateAction<Reason | undefined>>;
  stage: CancelationStage;
  setStage: React.Dispatch<React.SetStateAction<CancelationStage>>;
  planAmount: number | undefined;
  setPlanAmount: React.Dispatch<React.SetStateAction<number | undefined>>;
  detail: string;
  setDetail: React.Dispatch<React.SetStateAction<string>>;
  stageIndex: number;
  setStageIndex: React.Dispatch<React.SetStateAction<number>>;
  cancelling: boolean;
  setCancelling: React.Dispatch<React.SetStateAction<boolean>>;
  claiming: boolean;
  setClaiming: React.Dispatch<React.SetStateAction<boolean>>;
  pausing: boolean;
  setPausing: React.Dispatch<React.SetStateAction<boolean>>;
  deleteTime: boolean;
  setDeleteTime: React.Dispatch<React.SetStateAction<boolean>>;
  deleteLinks: boolean;
  setDeleteLinks: React.Dispatch<React.SetStateAction<boolean>>;
  deleteFeature: boolean;
  setDeleteFeature: React.Dispatch<React.SetStateAction<boolean>>;
  deleteReactivate: boolean;
  setDeleteReactivate: React.Dispatch<React.SetStateAction<boolean>>;
  deletePrice: boolean;
  setDeletePrice: React.Dispatch<React.SetStateAction<boolean>>;
  handlePause: () => Promise<void>;
  handleTrackRescue: () => void;
  handleCancel: () => Promise<void>;
  handleClaim: () => Promise<void>;
  handleUnpause: () => Promise<void>;
  reasonClick: (action: string) => void;
  resetForm: () => void;
}

const initLocalPlans = i18n.t('billing:plans', {
  returnObjects: true,
}) as LocalPlanData[];

const reasons: Reason[] = i18n.t('cancelation:reasons', {
  returnObjects: true,
});
const initialStages: CancelationStage[] = i18n.t('cancelation:stages', {
  returnObjects: true,
});

const pauseRestartMonth = (): string => {
  // Get the current date
  const currentDate = new Date();

  // Calculate the start of the next month
  const startOfNextMonth = startOfMonth(addMonths(currentDate, 1));

  return format(startOfMonth(addMonths(startOfNextMonth, 1)), 'MMMM');
};

const initialContext = {
  selectedReason: undefined,
  reasons: reasons,
  stage: initialStages[0],
  stages: initialStages,
  pauseRestartMonth: pauseRestartMonth(),
  detail: '',
  stageIndex: 0,
  creditCount: '1,000',
  cancelling: false,
  pausing: false,
  claiming: false,
  setClaiming: () => {},
  deleteTime: false,
  deleteLinks: false,
  deletePrice: false,
  deleteFeature: false,
  deleteReactivate: false,
  planAmount: undefined,
  setPlanAmount: () => {},
  setPausing: () => {},
  handleTrackRescue: () => {},
  setDeleteLinks: () => {},
  setDeleteTime: () => {},
  setDeleteFeature: () => {},
  setDeleteReactivate: () => {},
  setDeletePrice: () => {},
  handlePause: async () => {},
  handleClaim: async () => {},
  handleUnpause: async () => {},
  handleCancel: async () => {},
  reasonClick: () => {},
  setOptionSelected: () => {},
  resetForm: () => {},
  setSelectedReason: () => {},
  setStage: () => {},
  setDetail: () => {},
  setStageIndex: () => {},
  setCancelling: () => {},
};

export const CancelationContext =
  createContext<CancelationContextProps>(initialContext);

interface CancelationProviderProps {
  children: React.ReactElement;
}
export const CancelationProvider: React.FC<CancelationProviderProps> = ({
  children,
}) => {
  const { t } = useTranslation('cancelation');
  const reasons = initialContext.reasons;
  const [selectedReason, setSelectedReason] = useState<Reason | undefined>(
    initialContext.selectedReason,
  );
  const [planAmount, setPlanAmount] = useState<number | undefined>(
    initialContext.planAmount,
  );

  const creditCount = '1,000';

  const user = useStore<UserResponse | undefined, UserStore>(
    'user',
    (state) => state.user,
  );

  const offerablePlanSlugs = ['individual', 'group', 'agency', 'powerhouse'];

  useEffect(() => {
    const validPlans = initLocalPlans.filter((p) =>
      offerablePlanSlugs.includes(p.slug),
    );
    if (validPlans) {
      const plan = validPlans.find((p) => p.slug === BoordsConfig.PlanSlug);
      if (plan) {
        const price = plan.prices.find(
          (p) => BoordsConfig.Currency === p.currency && p.interval === 'month',
        );
        if (price) {
          setPlanAmount(price.amount / 100);
        }
      }
    }
  }, []);

  const pauseRestartMonth = initialContext.pauseRestartMonth;

  const [stages, setStages] = useState<CancelationStage[]>(
    t('cancelation:stages', {
      creditCount: creditCount,
      pauseRestartMonth: pauseRestartMonth,
      returnObjects: true,
    }),
  );

  const [stage, setStage] = useState<CancelationStage>(stages[0]);

  const [detail, setDetail] = useState('');
  const [stageIndex, setStageIndex] = useState(initialContext.stageIndex);
  const [cancelling, setCancelling] = useState(false);
  const [pausing, setPausing] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [deleteTime, setDeleteTime] = useState(false);
  const [deleteLinks, setDeleteLinks] = useState(false);
  const [deleteFeature, setDeleteFeature] = useState(false);
  const [deleteReactivate, setDeleteReactivate] = useState(false);
  const [deletePrice, setDeletePrice] = useState(false);

  // Don't show pause for annual or trial
  useEffect(() => {
    if (user?.is_annual || user?.is_cc_trial) {
      setStage(stages[1]);
    }
  }, [user]);

  // const variant = useFeatureFlagVariantKey('appCancelationOffer');

  // posthog.featureFlags.override({ appCancelationOffer: 'test' });

  // Conditionally add credit_offer step
  // useEffect(() => {
  //   if (user) {
  //     if (
  //       !BoordsConfig.IsEdu &&
  //       !user.is_annual &&
  //       !user.free_month_claimed_at &&
  //       offerablePlanSlugs.includes(BoordsConfig.PlanSlug) &&
  //       variant === 'test'
  //     ) {
  //       const baseStages = t('cancelation:stages', {
  //         creditCount: creditCount,
  //         pauseRestartMonth: pauseRestartMonth,
  //         returnObjects: true,
  //       }) as CancelationStage[];

  //       const testStages = t('cancelation:test_stages', {
  //         creditCount: creditCount,
  //         pauseRestartMonth: pauseRestartMonth,
  //         returnObjects: true,
  //       }) as CancelationStage[];

  //       const creditOfferStage = testStages.find(
  //         (s) => s.id === 'credit_offer',
  //       );
  //       if (creditOfferStage) {
  //         // Insert creditOfferStage after reason stage
  //         baseStages.splice(2, 0, creditOfferStage);
  //       }

  //       setStages(baseStages);
  //     }
  //   }
  // }, [user, stage, variant]);

  useEffect(() => {
    if (stageIndex) {
      setStage(stages[stageIndex]);
    }
  }, [stageIndex]);

  const handleError = (errorMessage: string) => {
    RequestActions.error.defer(errorMessage);
    Rollbar.error(errorMessage);
  };

  const handleUnpause = useCallback(async () => {
    try {
      setPausing(true);
      const resumeReq = await fetch(`/api/pause/${BoordsConfig.Uid}`, {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-API-KEY': BoordsConfig.AuthenticationToken,
        },
      });
      await resumeReq.json();
      if (resumeReq.ok) {
        Track.event.defer('resume_subscription', {
          category: 'Subscription',
          posthogCapture: true,
        });
        RequestActions.success.defer(t('success.resume'));
        window.location.reload();
      } else {
        setPausing(false);
        handleError(t('errors.resume'));
      }
    } catch (err) {
      setPausing(false);
      handleError(t('errors.resume'));
    }
  }, [stages]);

  const handlePause = useCallback(async () => {
    try {
      setPausing(true);
      const pauseReq = await fetch(`/api/pause`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-API-KEY': BoordsConfig.AuthenticationToken,
        },
        body: JSON.stringify({
          data: {
            attributes: {
              pause_months: 1,
            },
          },
        }),
      });

      await pauseReq.json();

      if (pauseReq.ok) {
        Track.event.defer('pause_subscription', {
          category: 'Subscription',
          posthogCapture: true,
        });
        handleTrackRescue();
        const pauseCompleteStage = stages.find(
          (stage) => stage.id === 'pause_complete',
        );
        if (pauseCompleteStage) {
          setStage(pauseCompleteStage);
        } else {
          RequestActions.success.defer(t('success.pause'));
          window.location.href = '/billing';
        }
      } else {
        setPausing(false);
        handleError(t('errors.pause'));
      }
    } catch (err) {
      setPausing(false);
      handleError(t('errors.pause'));
    }
  }, [stages]);

  const handleClaim = async () => {
    try {
      setClaiming(true);
      const request = await apiRequest({
        path: `free_month`,
        method: 'post',
      });

      if (!request.ok) return reportApiRequestError(request, { method: 'get' });
      await request.json();

      RequestActions.success.defer(t('success.claim'));

      Track.event.defer('claim_free_month', {
        category: 'Subscription',
        posthogCapture: true,
      });
      handleTrackRescue();

      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    } catch (err) {
      setClaiming(false);
      handleError(t('errors.claim'));
    }
  };

  const handleCancel = useCallback(async () => {
    try {
      setCancelling(true);
      const cancelReq = await fetch(`/api/cancel`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-API-KEY': BoordsConfig.AuthenticationToken,
        },
        body: JSON.stringify({
          data: {
            attributes: {
              reason: selectedReason ? selectedReason.value : 'unknown',
              detail: detail,
            },
          },
        }),
      });

      await cancelReq.json();

      if (cancelReq.ok) {
        RequestActions.success.defer(t('success.cancelation'));
        Track.event.defer('cancelation_submitted', {
          selectedReason: selectedReason ? selectedReason.value : 'unknown',
          category: 'Subscription',
          posthogCapture: true,
        });
        // This gives time for the stripe webhook to deliver
        // and show the correct information on the billing page
        setTimeout(() => {
          window.location.href = '/billing';
        }, 2000);
      } else {
        setCancelling(false);
        handleError(t('errors.cancelation'));
      }
    } catch (err) {
      setCancelling(false);
      handleError(t('errors.cancelation'));
    }
  }, [detail, selectedReason]);

  const reasonClick = (action: string) => {
    if (action === 'pricing') {
      window.location.href = '/pricing';
    } else if (action === 'intercom') {
      Intercom('showNewMessage');
    }
  };

  const resetForm = () => {
    setSelectedReason(undefined);
    setStageIndex(0);
    setStage(stages[0]);
  };

  const handleTrackRescue = () => {
    if (!cancelling && !claiming) {
      Track.event.defer('cancelation_rescue', {
        category: 'Subscription',
        posthogCapture: true,
      });
    }
  };

  const value: CancelationContextProps = {
    selectedReason,
    setSelectedReason,
    creditCount,
    stage,
    setStage,
    detail,
    reasons,
    setDetail,
    stageIndex,
    setStageIndex,
    cancelling,
    setCancelling,
    pausing,
    setPausing,
    pauseRestartMonth,
    deleteTime,
    setDeleteTime,
    deleteLinks,
    setDeleteLinks,
    deleteFeature,
    setDeleteFeature,
    deleteReactivate,
    setDeleteReactivate,
    deletePrice,
    setDeletePrice,
    handlePause,
    handleUnpause,
    handleCancel,
    handleTrackRescue,
    reasonClick,
    resetForm,
    planAmount,
    setPlanAmount,
    claiming,
    setClaiming,
    handleClaim,
  };

  return (
    <CancelationContext.Provider value={value}>
      {children}
    </CancelationContext.Provider>
  );
};
