/** @prettier */
import * as mapLimit from 'async/mapLimit';
import { FakeAltStoreClass } from './AltStore';
import { FileUploadActions } from '../actions/file_upload';
import { FilestackStore, pickerCallbackAmbiguous } from './filestack';
import { PickerOptions } from 'filestack-js/build/main/lib/picker';
import { frameAspectRatio } from '../../types/storyboard';
import { base64String, IFrame } from '../../types/frame';
import type { Base64ImageProp } from './frame';
import { FrameActions } from '../actions/frame';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { frameAspectRatioNormalizer } from '../../helpers/frame-size-helper';
import { maxImageFileSize, maxImageSizeInMB } from '../../helpers/constants';
import { RequestActions } from '../actions/request';
import { ajax, AJAXError } from 'javascripts/helpers/ajax';
import logger from 'javascripts/helpers/logger';
import { isFileTypeAccepted } from 'javascripts/helpers/isFileTypeAccepted';
import { fileTypeListToString } from 'javascripts/helpers/fileTypeListToString';
import type { GenericCallback } from 'blackbird/helpers/types';
import { filter, isString, some } from 'underscore';

// This was 15s, but heroku has it's own timeout, so let's use that one for now
const timeout = undefined;
const errorHandler = RequestErrorHandler('FileUpload');

type progressCallback = (progress: number) => void;

export class FileUploadStore extends FakeAltStoreClass<FileUploadStore> {
  pickerState: 'hidden' | 'start' | 'uploading' | 'error' = 'hidden';
  concurrency = 3;
  pickerOptions?: PickerOptions;
  pickerMaxFiles = 1;
  pickerProgress = 0;
  /** Max file size in megabytes */
  maxFileSize = 20971520;
  /** The maximum amount of pixels allowed for either of an image's sides */
  maxImageSize?: number;
  team_id: number;
  pickerCallback?: pickerCallbackAmbiguous;
  pickerUploadFunc: this['handleUploadFiles'];
  /** Function that will be called instead of this.updateProgress */
  pickerUpdateProgressFuncOverride?: progressCallback | null;
  errorMessage?: string;

  constructor() {
    super();
    this.handleOpenPicker;
    this.bindListeners({
      handleOpenPicker: FileUploadActions.OPEN_PICKER,
      handleClosePicker: FileUploadActions.CLOSE_PICKER,
      handleUploadFile: FileUploadActions.UPLOAD_FILE,
      handleUploadFiles: FileUploadActions.UPLOAD_FILES,
      handleOpenPickerAndAddFrames:
        FileUploadActions.OPEN_PICKER_AND_ADD_FRAMES,
      handleOpenPickerAndUpdateFrame:
        FileUploadActions.OPEN_PICKER_AND_UPDATE_FRAME,
      handlePickerStartUpload: FileUploadActions.PICKER_START_UPLOAD,
      handleUploadFrameImage: FileUploadActions.UPLOAD_FRAME_IMAGE,
      handleUpdateFrame: FileUploadActions.UPDATE_FRAME,
    });
  }

  reset() {
    this.pickerUploadFunc = this.handleUploadFiles;
    this.pickerUpdateProgressFuncOverride = null;
    this.maxImageSize = undefined;
    this.pickerProgress = 0;
  }

  handleOpenPicker: FilestackStore['handleOpenPicker'] = (options) => {
    if (!options.team_id) throw new Error('requires team_id');
    this.reset();

    this.pickerState = 'start';
    this.pickerMaxFiles = options.maxFiles || 1;
    this.pickerCallback = options.callback;
    this.pickerOptions = options.options || {};
    this.team_id = options.team_id;

    const imageMax = (options.options && options.options.imageMax) || undefined;
    if (imageMax) this.maxImageSize = Math.max(...imageMax);

    if (
      !this.pickerOptions ||
      (!this.pickerOptions.accept && process.env.NODE_ENV === 'development')
    ) {
      logger.warn(
        'You opened the picker without an `accept` parameter, please add one ;)',
      );
    }
  };

  handleClosePicker() {
    this.pickerState = 'hidden';
    this.pickerUpdateProgressFuncOverride = null;
  }

  /**
   * This should not be referenced outside of the filestackStore, as this
   * is a temporary replacement of filestackStore's `handleOpenDialog`.
   * Please see comments on those functions to read the motivation. */
  handleOpenPickerAndAddFrames(args: {
    frame_aspect_ratio: frameAspectRatio;
    team_id: number;
    storyboard_id: number;
    maxFrames: number;
    options?: PickerOptions;
    callback?: () => void;
  }) {
    this.handleOpenPicker({
      team_id: args.team_id,
      maxFiles: args.maxFrames,
      options: args.options,
      callback: () => args.callback && args.callback!(),
    });

    this.pickerUploadFunc = ({ files, callback }) => {
      FrameActions.bulkFrameUpload.defer({
        storyboard_id: args.storyboard_id,
        files: files,
        existing_frames_length: 0,
        callback: () => callback([]),
      });
    };
  }

  handleUpdateFrame(args: {
    file: File;
    frame_aspect_ratio: frameAspectRatio;
    team_id: number;
    frame: IFrame;
    callback?: GenericCallback;
  }) {
    FrameActions.updateImageStatus.defer({
      frameId: args.frame.id,
      status: 'creating_crop',
    });

    this.gatherImageDataFromFile({ file: args.file }, (fileData) => {
      this.handleUploadFrameImage({
        fileData: fileData.image,
        team_id: args.team_id,
        frameAspectRatio: args.frame_aspect_ratio,
        callback: (results) => {
          // if there are no results, do nothing
          // (we'll have shown an error already at this point)
          if (!results) {
            FrameActions.updateImageStatus.defer({
              frameId: args.frame.id,

              status: 'image_error',
              message: 'Error uploading image',
            });
            return args.callback?.(false);
          }
          FrameActions.updateFrameImageUrls({
            ...results,
            frame: args.frame,
            callback: args.callback,
            background_image_url: args.frame.background_image_url,
            // We want to clear the frame data in this case (e.g. dragging in an
            // image from unsplash)
            replace: true,
          });
        },
      });
    });
  }

  handleOpenPickerAndUpdateFrame(args: {
    frame_aspect_ratio: frameAspectRatio;
    team_id: number;
    frame: IFrame;
    options?: PickerOptions;
    callback?: () => void;
  }) {
    this.handleOpenPicker({
      team_id: args.team_id,
      maxFiles: 1,
      options: args.options,
      callback: () => args.callback && args.callback!(),
    });

    this.pickerUploadFunc = ({ files, callback }) => {
      this.handleUpdateFrame({
        file: files[0],
        team_id: args.team_id,
        frame_aspect_ratio: args.frame_aspect_ratio,
        frame: args.frame,
        callback: () => callback([]),
      });
    };
  }

  /** called from outside this store, will reset default settings first */
  handleUploadFile: FilestackStore['handleUploadFile'] = (options) => {
    this.reset();
    this.uploadFile(options);
  };

  handleUploadFiles({
    files,
    callback,
    team_id,
    maxFiles = 999,
  }: {
    files: File[];
    callback: pickerCallbackAmbiguous;
    team_id: number;
    maxFiles?: number;
  }) {
    let count = 0;

    // We tell the app not to update the progress, because we
    // want to do it according to the progress in the batch
    if (maxFiles > 1) {
      this.pickerUpdateProgressFuncOverride = () => {};
    }

    mapLimit(
      files,
      this.concurrency,
      (file: File, singleCallback) =>
        this.uploadFile({
          file,
          team_id,
          callback: (file) => {
            count++;
            this.updateBatchProgress(count, files.length);
            singleCallback(null, file);
          },
        }),
      (error, files) => {
        if (error) {
          this.setErrorState('Error uploading images');
          this.emitChange();
          callback();
        } else {
          callback(maxFiles > 1 ? files : files[0]);
        }
      },
    );
  }

  /**
   * Tells the picker that uploading has started, and starts the upload
   * by calling `pickerUploadFunc` */
  handlePickerStartUpload: this['handleUploadFiles'] = (args) => {
    const accepts = this.pickerOptions!.accept || ([] as string[]);
    const invalidFiles =
      !!accepts.length &&
      filter(
        args.files,
        (f) => !some(accepts, (a) => !!f.type.match(new RegExp(a))),
      );

    if (invalidFiles && invalidFiles.length) {
      const fileNames = invalidFiles.map((f) => f.name).join(', ');
      this.setErrorState(
        `The following files are not of supported file types: “${fileNames}”. Try converting them to png, jpg, or psd and try again.`,
      );
      return;
    }

    this.pickerState = 'uploading';
    this.errorMessage = undefined;

    this.pickerUploadFunc({
      ...args,
      callback: (files) => {
        args.callback(files);
        if (files) {
          this.pickerState = 'hidden';
          RequestActions.success.defer('Upload complete!');
          this.emitChange();
        }
      },
    });
  };

  /** Converts a File to a Base64 object */
  private gatherImageDataFromFile(
    options: {
      file: File;
      [addedParam: string]: unknown;
    },
    callback: (
      fileData: {
        image_file_type: string;
        image_file_size: number;
      } & Base64ImageProp,
    ) => void,
  ) {
    var reader = new FileReader();
    reader.readAsDataURL(options.file);
    reader.onload = () => {
      if (!options.file) throw new Error('Did not receive file parameter');
      const output = {
        image_file_type: options.file.type,
        image_file_size: options.file.size,
        image: reader.result as base64String,
      };
      Object.assign(options, output);
      callback(output);
    };
  }

  /** Upload image, and returns the URLs for the different image versions */
  handleUploadFrameImage({
    fileData,
    team_id,
    frameAspectRatio,
    callback,
  }: {
    fileData: string;
    team_id: number;
    frameAspectRatio: frameAspectRatio;
    callback: (result?: {
      large_image_url: string;
      thumbnail_image_url: string;
    }) => void;
  }) {
    if (!team_id) throw new Error('requires team_id');
    ajax({
      progress: this.updateProgress,
      timeout: timeout,
      method: 'post',
      dataType: 'json',
      url: BoordsConfig.DollyUrl + '/upload',
      headers: {
        'X-Authentication-Token': BoordsConfig.AuthenticationToken,
      },
      data: {
        data: fileData,
        team_id: team_id,
        orientation: frameAspectRatioNormalizer(frameAspectRatio),
      },
      success: (response) => {
        callback({
          large_image_url: response.large,
          thumbnail_image_url: response.small,
        });
      },
      error: this.handleUploadError(callback),
    });
  }

  private uploadFile: FilestackStore['handleUploadFile'] = ({
    file,
    team_id,
    callback,
    accept,
  }) => {
    if (!isFileTypeAccepted(accept, file.type)) {
      RequestActions.error.defer({
        key: 'sharedErrors.unsupportedUpload',
        data: {
          fileType: file.type,
          formats: fileTypeListToString(accept),
        },
      });

      return callback();
    }

    if (!team_id) throw new Error('requires team_id');
    var reader = new FileReader();
    const onProgress =
      this.pickerUpdateProgressFuncOverride || this.updateProgress;

    if (file.size > maxImageFileSize) {
      RequestActions.error.defer({
        key: 'sharedErrors.fileSizeExceeded',
        data: { maxSize: maxImageSizeInMB + 'MB' },
      });
      return callback();
    }

    reader.readAsDataURL(file);
    reader.onload = () => {
      if (file.type === 'image/gif') {
        RequestActions.error.defer({
          key: 'sharedErrors.unsupportedUpload',
          data: { fileType: file.type, formats: 'gif' },
        });
        return callback();
      }

      ajax({
        progress: onProgress,
        timeout: timeout,
        method: 'post',
        dataType: 'json',
        url: BoordsConfig.DollyUrl + '/file/upload',
        headers: {
          'X-Authentication-Token': BoordsConfig.AuthenticationToken,
        },
        data: {
          data: reader.result,
          team_id: team_id,
          maxSize: this.maxImageSize,
        },
        success: (response) => {
          callback({
            filename: file.name,
            url: response.url,
            mimetype: file.type,
          });
        },
        error: this.handleUploadError(callback),
      });
    };
  };

  private handleUploadError = (callback?: () => void) => (error: AJAXError) => {
    let message = 'Error saving image';
    let rollbarMessage = '[Dolly error] POST /upload';
    let severity: any = 'error';
    let askUserToRetry = true;

    const isBadImage = error.status && error.status === 403;

    if (error.code === 'ECONNABORTED') {
      rollbarMessage = 'Image upload timeout';
      severity = 'info';
    } else if (isBadImage) {
      message = 'Please upload files in jpg, gif, png or photoshop format';
      rollbarMessage = 'Bad image uploaded';
      severity = 'info';
      askUserToRetry = false;
    }

    errorHandler({
      message,
      rollbarMessage,
      severity,
      askUserToRetry,
      notificationFunc: (error, parsed) => {
        RequestActions.error({
          message: parsed.userMessage!,
          link: parsed.messageLink,
        });
        // Sometimes we use the uploading functions without a picker, so we
        // don't want the picker to reappear to show the error.
        if (this.pickerState !== 'hidden' && isString(parsed.userMessage)) {
          this.setErrorState(parsed.userMessage);
        }
        this.emitChange();
        if (callback) callback();
      },
    })(error);
  };

  private updateProgress = (progress: number) => {
    this.pickerProgress = progress;
    this.emitChange();
  };

  private updateBatchProgress = (
    currentFileIndex: number,
    amountOfFiles: number,
  ) => {
    this.pickerProgress = currentFileIndex / amountOfFiles;
    this.emitChange();
  };

  private setErrorState(message?: string) {
    if (message) this.errorMessage = message;
    this.pickerState = 'error';
    this.pickerProgress = 0;
  }
}

(window as any).FileUploadStore = alt.createStore(
  FileUploadStore,
  'FileUploadStore',
);
