const createReactClass = require('create-react-class')
window.ShareableSignupPrompt = createReactClass({
  render: function() {
    return (
      <a target="_blank" href="https://app.boords.com/signup?from=demo" className="fixed top-2 left-2 z-999 bg-purple white pv2 ph3 br2 pointer hover-bg-dark-purple avenir-heavy">
        Try Boords Free
      </a>
    );
  }
});
