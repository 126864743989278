/** @prettier */

import React, { useState, useMemo, useEffect } from 'react';
import {
  Dashboard,
  DashboardStore,
  Project,
} from '../../../../javascripts/flux/stores/dashboard';
import { useStore } from '../../../../javascripts/helpers/useStore';
import Logo from '../../logo/Logo';
import { UpdatesDropdownContainer } from '../../menus/UpdatesDropdown';
import { UserDropdownContainer } from '../../menus/UserDropdown';
import Search, { type SearchProps } from '../../search/Search';
import { type StoryboardDetails } from './types';
import type { ArrayElement } from '../../../helpers/types';
import { flatMap } from '../../../helpers/misc';
import { useTranslation } from 'react-i18next';
import { DashboardHeaderWorkflowButton } from './DashboardHeaderWorkflowButton';

const extractProjects = (key: keyof Dashboard, dashboardState: Dashboard) => {
  const data = dashboardState[key];
  if (Array.isArray(data)) {
    return flatMap(data, (item) => item.projects);
  }
  return data.projects;
};

const DashboardHeader: React.VFC = () => {
  const dashboardState = useStore<Dashboard, DashboardStore>(
    'dashboard',
    (state) => state.dashboard,
  );
  const allProjects = useMemo(() => {
    if (!dashboardState) return [];
    const dashboardKeys = Object.keys(dashboardState);
    const projects = dashboardKeys.reduce(
      (totalProjects: Project[], currentKey: keyof Dashboard) => {
        const projects = extractProjects(currentKey, dashboardState);
        return [...totalProjects, ...projects];
      },
      [],
    );
    return projects;
  }, [dashboardState]);

  const allStoryboards: StoryboardDetails[] = useMemo(
    () =>
      flatMap(allProjects, (project) =>
        project.storyboards.map((storyboard) => ({
          ...storyboard,
          projectName: project.name,
        })),
      ),
    [allProjects],
  );
  const allOptions: SearchProps['options'] = useMemo(
    () =>
      allStoryboards.map((item: StoryboardDetails) => ({
        label: item.document_name,
        value: item.slug,
        subLabel: item.projectName,
      })),
    [allStoryboards],
  );
  const [searchData, setSearchData] = useState(allOptions);
  const [searchVal, setSearchVal] = useState('');
  const handleSearch = (text: string) => {
    setSearchVal(text);
  };

  useEffect(() => {
    setSearchData(
      searchVal.trim().length === 0
        ? allOptions
        : allOptions.filter(
            (o) =>
              (o.label as string)
                .toLowerCase()
                .includes(searchVal.toLowerCase().trim()) ||
              (o.subLabel as string)
                ?.toLowerCase()
                .includes(searchVal.toLowerCase().trim()),
          ),
    );
  }, [searchVal, allOptions]);

  const { t } = useTranslation();

  const handleSelect = (option: ArrayElement<SearchProps['options']>) => {
    location.href = `/storyboards/${option.value}`;
  };

  return (
    <header className="relative flex items-center p-5 pr-0 bg-white border-b border-border">
      <Logo mark className="flex-shrink-0 mr-4 w-11" />
      <div className="flex items-center flex-auto w-0 mr-6 space-x-3">
        <h1 className="pb-1 mr-3 text-2xl font-semibold truncate">
          {t('dashboard.title')}
        </h1>

        <DashboardHeaderWorkflowButton />
      </div>

      {/* Right side of this toolbar */}
      <div className="flex items-center flex-shrink-0 ml-auto gap-3">
        <div className="mr-2 w-80">
          <Search
            maxHeight={500}
            inputPlaceholder="Search storyboards"
            placeholder="Search by storyboard or project title"
            inputSize="md"
            onSearch={handleSearch}
            onSelect={handleSelect}
            options={searchData}
          />
        </div>
        <UserDropdownContainer />
        <UpdatesDropdownContainer />
      </div>
    </header>
  );
};

export default DashboardHeader;
