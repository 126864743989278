/** @prettier */
import * as React from 'react';
import { ContentfulImageFile } from '../../flux/stores/testimonial';
import { ContentfulPicture } from './ContentfulPicture';

export interface DisplayProps {
  withFrame?: boolean;
  align?: string;
}

export interface Props {
  logo: ContentfulImageFile;
  quote: string;
  url?: string;
  avatar: ContentfulImageFile;
  companyName?: string;
  name: string;
  title: string;
}

const logoStyle = {
  maxWidth: 250,
  maxHeight: 70,
  width: 'auto',
  height: 'auto',
};

export const TestimonialContent = (props: Props & DisplayProps) => (
  <div
    className={
      props.withFrame
        ? 'br3 bg-white shadow-subtle pa4 flex flex-column items-stretch'
        : ''
    }
  >
    <div
      className={`flex items-center justify-${props.align} ${props.align} w-90 max-h3-5 h-100`}
    >
      {props.url ? (
        <a target="_blank" rel="noopener noreferrer nofollow" href={props.url}>
          <ContentfulPicture
            style={logoStyle}
            file={props.logo}
            imgWidth={500}
            alt={props.companyName || ''}
          />
        </a>
      ) : (
        <ContentfulPicture
          style={logoStyle}
          file={props.logo}
          imgWidth={500}
          alt={props.companyName || ''}
        />
      )}
    </div>

    <div className="f4 lh-copy mt14px mb5 flex-auto ti-8px relative">
      <div className="absolute moon-gray" style={{ top: '0', left: '-13px' }}>
        &ldquo;
      </div>
      <span dangerouslySetInnerHTML={{ __html: props.quote }} />
    </div>
    <div className="flex items-center justify-center">
      <div className="mr3">
        <ContentfulPicture
          className="w3 br-100 lh-solid loaded"
          file={props.avatar}
          imgWidth={128}
        />
      </div>
      <div className="flex-auto">
        <div className="avenir-heavy">{props.name}</div>
        <div className="silver nowrap">{props.title}</div>
      </div>
    </div>
  </div>
);

TestimonialContent.displayName = 'TestimonialContent';
TestimonialContent.defaultProps = {
  withFrame: true,
  align: 'center',
};

(window as any).TestimonialContent = TestimonialContent;
