/** @format */
import React, { useContext, useState, useMemo, useEffect } from 'react';
import { RequestActions } from 'javascripts/flux/actions/request';
import Button from 'blackbird/components/button/Button';
import { ProjectsEmpty } from '../projects/empty/ProjectsEmpty';
import {
  ProjectSkeletonGrid,
  ProjectSkeletonHeader,
} from '../projects/ProjectSkeleton';
import { ShareableProjectContext } from './ShareableProjectContext';
import { ShareableProjectHeader } from './ShareableProjectHeader';
import { ShareableStoryboard } from './ShareableStoryboard';
import { ShareableSubProject } from './ShareableSubProject';
import { Listbox } from '@headlessui/react';
import { ArrowDown, ArrowUp, CheckIcon } from 'lucide-react';
import classNames from 'classnames';
import Tooltip from '../feedback/tooltip/Tooltip';
import {
  projectContentsSortFieldLocalState,
  projectContentsSortOrderLocalState,
  unlockedShareableProjectsLocalState,
  type SortField,
  type SortOrder,
} from 'javascripts/helpers/local-state';
import TextInput from '../form/text-input/TextInput';

interface SortOption {
  value: SortField;
  label: string;
}

const sortOptions: SortOption[] = [
  { value: 'name', label: 'Name' },
  { value: 'created_at', label: 'Date created' },
  { value: 'updated_at', label: 'Date modified' },
];

export const ShareableProjectContents: React.FC = () => {
  const { isLoading, subProjects, storyboards, project } = useContext(
    ShareableProjectContext,
  );

  const [password, setPassword] = useState('');
  const [isUnlocked, setIsUnlocked] = useState(false);

  useEffect(() => {
    if (project) {
      const unlockedProjects =
        unlockedShareableProjectsLocalState.getValue() || [];
      const isProjectUnlocked = unlockedProjects.includes(project.id);
      const isParentUnlocked =
        project.parent_id !== undefined &&
        unlockedProjects.includes(project.parent_id);

      if (isParentUnlocked && !isProjectUnlocked) {
        // If the parent is unlocked but the current project isn't, add the current project to the unlocked list
        const updatedUnlockedProjects = [...unlockedProjects, project.id];
        unlockedShareableProjectsLocalState.setValue(updatedUnlockedProjects);
        setIsUnlocked(true);
      } else {
        setIsUnlocked(isProjectUnlocked || isParentUnlocked);
      }
    }
  }, [project]);

  const [sortField, setSortField] = useState<SortField>(() => {
    const savedSortField = projectContentsSortFieldLocalState.getValue();
    return savedSortField || 'name'; // Default to 'name' if no value is present
  });

  const [sortOrder, setSortOrder] = useState<SortOrder>(() => {
    const savedSortOrder = projectContentsSortOrderLocalState.getValue();
    return savedSortOrder || 'asc'; // Default to 'asc' if no value is present
  });

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (project && password === project.share_password) {
      setIsUnlocked(true);
      const unlockedProjects =
        unlockedShareableProjectsLocalState.getValue() || [];
      unlockedShareableProjectsLocalState.setValue([
        ...unlockedProjects,
        project.id,
      ]);
    } else {
      RequestActions.error.defer('Incorrect password');
    }
  };

  useEffect(() => {
    projectContentsSortFieldLocalState.setValue(sortField);
  }, [sortField]);

  useEffect(() => {
    projectContentsSortOrderLocalState.setValue(sortOrder);
  }, [sortOrder]);

  const filteredSubProjects = useMemo(() => {
    return subProjects.filter((subProject) => subProject.share_slug);
  }, [subProjects]);

  const sortedSubProjects = useMemo(() => {
    return [...filteredSubProjects].sort((a, b) => {
      if (sortField === 'name') {
        return sortOrder === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else {
        const aDate = new Date(a[sortField]).getTime();
        const bDate = new Date(b[sortField]).getTime();
        return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
      }
    });
  }, [filteredSubProjects, sortField, sortOrder]);

  const sortedStoryboards = useMemo(() => {
    return [...storyboards].sort((a, b) => {
      if (sortField === 'name') {
        return sortOrder === 'asc'
          ? a.document_name.localeCompare(b.document_name)
          : b.document_name.localeCompare(a.document_name);
      } else {
        const aDate = new Date(a[sortField]).getTime();
        const bDate = new Date(b[sortField]).getTime();
        return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
      }
    });
  }, [storyboards, sortField, sortOrder]);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  if (isLoading) {
    return (
      <div>
        <ProjectSkeletonHeader />
        <ProjectSkeletonGrid />
      </div>
    );
  }

  if (project?.share_with_password && project.share_password && !isUnlocked) {
    return (
      <XhrErrorMessage title={`This project is password protected`}>
        <form noValidate onSubmit={handlePasswordSubmit} className="space-y-6">
          <TextInput
            name="password"
            type="password"
            aria-label="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            placeholder="Enter password"
            autoComplete="off"
            required
            autoFocus
          />
          <Button type="solid" htmlType="submit">
            {`Unlock`}
          </Button>
        </form>
      </XhrErrorMessage>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-surface">
      <div className="px-10 pt-6 pb-4 bg-white border-b border-border">
        <ShareableProjectHeader />

        {(filteredSubProjects.length > 0 || storyboards.length > 0) && (
          <div className="flex items-center justify-end">
            <Listbox
              as="div"
              className="relative inline-block text-left"
              value={sortField}
              onChange={setSortField}
            >
              <Listbox.Button className="inline-flex justify-center w-full px-2 pb-1.5 pt-1 text-sm font-semibold rounded-md hover:bg-surface focus:outline-none">
                <span>
                  {
                    sortOptions.find((option) => option.value === sortField)
                      ?.label
                  }
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute right-0 z-40 w-40 mt-2 bg-white border shadow-lg origin-top-right rounded-md focus:outline-none border-border">
                <div className="p-2 space-y-1">
                  <div className="px-3 pt-1.5 pb-1 text-xs font-semibold tracking-wider uppercase text-type-subdued">{`Sort by`}</div>
                  {sortOptions.map((option) => (
                    <Listbox.Option key={option.value} value={option.value}>
                      {({ selected }) => (
                        <div
                          className={classNames(
                            'flex items-center text-sm cursor-pointer relative pb-2 pt-1.5 px-3 rounded-md',
                            selected
                              ? 'bg-surface-light'
                              : ' hover:bg-surface-light',
                          )}
                        >
                          <span className="flex-auto">{option.label}</span>
                          {selected && (
                            <CheckIcon className="flex-shrink-0 w-3.5 h-3.5" />
                          )}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </div>
              </Listbox.Options>
            </Listbox>
            <Tooltip title={`Sort ascending/descending`} placement="top">
              <div
                className="p-2 cursor-pointer rounded-md hover:bg-surface"
                onClick={toggleSortOrder}
              >
                {sortOrder === 'asc' ? (
                  <ArrowDown strokeWidth={2.5} className="w-4 h-4" />
                ) : (
                  <ArrowUp strokeWidth={2.5} className="w-4 h-4" />
                )}
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="flex-auto">
        {!isLoading &&
          filteredSubProjects.length === 0 &&
          storyboards.length === 0 && (
            <div className="px-10 py-8">
              <ProjectsEmpty
                title={`Nothing to see here`}
                subtitle="Check back later to see if any storyboards have been added"
              >
                <></>
              </ProjectsEmpty>
            </div>
          )}
        <div className="px-10 py-8">
          {filteredSubProjects.length > 0 && project?.share_with_sub_projects && (
            <>
              <div className="mb-6 text-xs font-semibold tracking-wider uppercase text-black/70">{`Sub-projects (${filteredSubProjects.length})`}</div>
              <div className="mb-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxxl:grid-cols-6 gap-x-3 gap-y-5 -ml-1.5">
                {sortedSubProjects.map((subProject) => {
                  return (
                    <ShareableSubProject
                      project={subProject}
                      key={subProject.id}
                    />
                  );
                })}
              </div>
            </>
          )}
          {storyboards.length > 0 && (
            <>
              <div className="mb-6 text-xs font-semibold tracking-wider uppercase text-black/70">{`Storyboards (${storyboards.length})`}</div>
              <div className="mb-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxxl:grid-cols-6 gap-x-3 gap-y-5 -ml-1.5">
                {sortedStoryboards.map((storyboard) => {
                  return (
                    <ShareableStoryboard
                      storyboard={storyboard}
                      key={storyboard.id}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
