window.AvatarActions = alt.createActions(function () {

  this.upload = function(data) {
    this.dispatch(data);
  };

  this.fetch = function(data) {
    this.dispatch(data);
  };

});

