/** @prettier */
import Button from 'blackbird/components/button/Button';
import { Sunset } from 'lucide-react';
import * as React from 'react';
import { LocalState } from '../../../helpers/local-state';
const LS_KEY = 'newPdfExportOptIn';

export const hasOptedIn = () => LocalState.getValue(LS_KEY) ?? true;

export const hasNewPDFExport = () => hasOptedIn();

export const optIn = () => {
  LocalState.setValue(LS_KEY, true);
};

export const optOut = () => {
  LocalState.setValue(LS_KEY, false);
};

interface NewPDFExportOptInProps {
  children?: React.ReactNode;
}
export const NewPDFExportOptIn: React.FC<NewPDFExportOptInProps> = ({
  children,
}) => {
  const handleClick = () => {
    hasOptedIn() ? optOut() : optIn();
    FlyoverActions.close();
    FlyoverActions.open.defer({ type: 'storyboardExport' });
  };

  return !hasOptedIn() ? (
    <div className="flex items-center px-6 py-5 rounded-lg bg-brand-yellow-25">
      <div className="flex-auto pr-10">
        <div className="flex items-center">
          <span className="flex-shrink-0 mr-2 -mt-1">
            <Sunset size={22} />
          </span>
          <div className="mb-2 text-lg font-semibold">
            {`Legacy PDF export end-of-life`}
          </div>
        </div>
        <div className="mb-3">{`The legacy PDF exporter does not support custom frame fields or rich text formatting. Please switch to the latest version for full support.`}</div>
      </div>

      <div className="flex items-center justify-center flex-shrink-0 space-x-3">
        <Button onClick={handleClick} type={'fancy'} rounded>
          {`Switch Now`}
        </Button>
        {children}
      </div>
    </div>
  ) : (
    <button
      type="button"
      className={`underline hover:no-underline text-type-disabled pointer ml2`}
      onClick={handleClick}
    >
      {`Legacy PDF exporter`}
    </button>
  );
};
