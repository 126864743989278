/*** @prettier */
const _ = require('underscore');
const qs = require('querystringify');
const { ToursActions } = require('../../flux/actions/tours');
const { noop } = require('underscore');
const { default: logger } = require('../logger');

require('../../flux/stores/flyover');
require('../../flux/actions/track');

const titleSuffix = ' · Boords';

let routeOnClose = {
  url: window.location.pathname,
  title: document.title,
};

let flyoverShouldBeOpen = false;

/**
 * Close the flyovers, but leave a little time for another flyover to open.
 * In some cases this is necessary to prevent excess animations caused by
 * route updates involving flyovers.
 */
const closeFlyoversDebounced = _.debounce((withName) => {
  document.title = routeOnClose ? routeOnClose.title : 'Boords';
  if (FlyoverStore.getState().open && !flyoverShouldBeOpen) {
    if (!withName || withName === FlyoverStore.getState().type) {
      FlyoverActions.close.defer();
    }
  }
}, 200);

const closeFlyovers = (withName) => () => {
  flyoverShouldBeOpen = false;
  closeFlyoversDebounced(withName);
};

const setTitle = (title) => () => {
  document.title = title + titleSuffix || 'Boords';
};

const activateTourIfPresentInURL = () => {
  const { tour } = qs.parse(window.location.search);

  if (tour) ToursActions.start.defer(tour);
};

const redirectRoute = (name, to) => ({
  [to]: {
    as: name,
    uses: noop,
    hooks: {
      before: (done) => {
        done(false);
        window.location.pathname = to;
      },
    },
  },
});

const trackFlyoverEvent = (flyover) => {
  try {
    Track.page.defer({
      title: flyover,
      path: '/virtual/' + flyover,
      url: window.location.origin + '/virtual/' + flyover,
    });
  } catch (err) {
    logger.error(err);
  }
};

module.exports = {
  setTitle,
  closeFlyovers,
  activateTourIfPresentInURL,
  redirectRoute,
  flyoverRouteFactory:
    (router) =>
    (path, flyover, title, flyoverProps = () => ({})) =>
      router.on({
        [path]: {
          as: flyover,
          uses: (params, query) => {
            const { open, type } = FlyoverStore.getState();
            if (!open || type !== flyover) {
              flyoverShouldBeOpen = true;
              FlyoverActions.open.defer({
                type: flyover,
                ...flyoverProps(params, query),
              });
            }
            trackFlyoverEvent(flyover);

            if (title) document.title = title + titleSuffix;
          },
          hooks: {
            leave: closeFlyovers(flyover),
            before: (done) => {
              flyoverShouldBeOpen = true;
              const sameTarget = path === window.location.pathname;
              routeOnClose = {
                url: sameTarget ? '/' : window.location.pathname,
                title: sameTarget ? 'Boords' : document.title,
              };
              done();
            },
          },
        },
      }),
};
