/** @prettier */
import { PDFDocumentStyle, Base64FrameImagesResult, DocInfo } from '../types';
import { IFrame } from '../../../types/frame';
import { createPDFImage } from './PDFImage';
import { createPDFFrameFields } from './createPDFFrameFields';

interface Props {
  x: number;
  y: number;
  width: number;
  frame: IFrame;
  frameImageInfo: Base64FrameImagesResult;
  marginBottom?: number;
  itemMargin?: number;
  debug?: boolean;
}

export const createPDFGridItem = (
  docInfo: DocInfo,
  style: PDFDocumentStyle,
  props: Props,
) => {
  const doc = docInfo.doc;
  const { frameImageInfo, width } = props;
  // Keep track of the Y position so we place subsequent lines of
  // text underneath each other.
  let offsetY = props.y;
  const itemMargin = props.itemMargin || 7.5;

  // console.log(`Frame ${frame.number}`, { offsetY });

  const dimensions = { width, height: 0 };

  const image = createPDFImage(docInfo, {
    imageInfo: frameImageInfo,
    width: width,
    x: props.x,
    y: offsetY,
    border: style.showFrameBorder ? style.colors.border : undefined,
  });

  // Some space underneath the image
  offsetY += image.height + itemMargin;

  const frameFields = createPDFFrameFields(docInfo, {
    width: width,
    itemMargin,
    style: style,
    x: props.x,
    y: offsetY,
    frame: props.frame,
    isDebug: props.debug,
  });

  offsetY += frameFields.height;

  // Calculate the height if the grid item.
  // We want to remove the last text item's margin
  dimensions.height = offsetY - props.y;

  if (props.debug) {
    doc.setDrawColor(200, 200, 255);
    doc.rect(props.x, props.y, dimensions.width, dimensions.height);
  }

  return dimensions;
};
