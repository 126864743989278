/** @format */

/* eslint react/sort-comp:0 */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Navigation = require('./Navigation').default;
const Timeline = require('./Timeline');
const { CanvasScrubber } = require('./CanvasScrubber/CanvasScrubber');
const { PanelBarLayout } = require('../shared/PanelBarLayout');
const preloadAllImages = require('../../helpers/preload-frame-images');
const { FrameFocusPanelbar } = require('../panelbars/FrameFocusPanelbar');
const FrameViewer = require('./FrameViewer').default;
const FullScreenOverlays = require('./FullScreenOverlays');
const {
  FrameFocusTopBar,
  frameFocusTopBarHeight,
} = require('../shared/FrameFocusTopBar');
const loadScreenfull = require('../../helpers/load-screenfull');
const { isInInput } = require('../../helpers/isInInput');
const { ToursActions, tourEvents } = require('../../flux/actions/tours');
const { TourHintable } = require('../tours/TourHintable');
const { eventSource } = require('blackbird/helpers/eventContextHelper');
const {
  AnnotationOverlay,
} = require('blackbird/components/comments/annotations/AnnotationOverlay');
const { AutoSizedFrame } = require('../AutoSizedFrame');

require('./_player.scss');

module.exports = createReactClass({
  displayName: 'PlayerUI',
  mixins: [PureRenderMixin],

  propTypes: {
    adjustFrameInpoint: PropTypes.func.isRequired,
    audio: PropTypes.object.isRequired,
    currentFrameId: PropTypes.number.isRequired,
    currentFrameIndex: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
    time: PropTypes.number.isRequired,
    frame: PropTypes.object.isRequired,
    frames: PropTypes.array.isRequired,
    frameSize: PropTypes.object.isRequired,
    // goToFrame: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    setFrameInpoint: PropTypes.func.isRequired,
    setFrameOutpoint: PropTypes.func.isRequired,
    setTimingsLock: PropTypes.func.isRequired,
    storyboard: PropTypes.object.isRequired,
    timelineIsOpen: PropTypes.bool.isRequired,
    hasSidebar: PropTypes.bool.isRequired,
    isWaitingOnAudio: PropTypes.bool.isRequired,
    toggleLooping: PropTypes.func.isRequired,
    togglePlay: PropTypes.func.isRequired,
    // toggleSidebar: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    toggleTimeline: PropTypes.func.isRequired,
    updateTime: PropTypes.func.isRequired,
    team: PropTypes.object,
    theme: PropTypes.oneOf(['light', 'dark']).isRequired,

    durationOptions: PropTypes.shape({
      stepAmount: PropTypes.number.isRequired,
      shiftStepAmount: PropTypes.number.isRequired,
    }).isRequired,

    onTriggerClose: PropTypes.func.isRequired,
    onSetIndex: PropTypes.func.isRequired,
    onSetFrame: PropTypes.func.isRequired,
    onSetFocusMode: PropTypes.func, // Not needed in the shareable view
    onChangeView: PropTypes.func,

    framesAreSaving: PropTypes.bool.isRequired,
    canManage: PropTypes.bool.isRequired,
    timelineZoom: PropTypes.number.isRequired,
    frameTimes: PropTypes.object.isRequired,
  },

  getInitialState() {
    return { isFullscreen: false };
  },

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyPress);
    window.addEventListener('keyup', this.onKeyUp);

    loadScreenfull(() => {
      screenfull.on('change', this.handleScreenfullChange);
    });
    ToursActions.triggerEvent.defer(tourEvents.openAnimatic);
    preloadAllImages(this.props.frames);
  },

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyPress);
    window.removeEventListener('keyup', this.onKeyUp);
    if (typeof screenfull !== 'undefined' && screenfull) {
      screenfull.off('change', this.handleScreenfullChange);
    }
  },

  componentDidUpdate(prevProps) {
    if (
      prevProps.hasSidebar !== this.props.hasSidebar ||
      prevProps.timelineIsOpen !== this.props.timelineIsOpen
    ) {
      this.scrubberRef.onResize();
    }
  },

  onFullscreenClick() {
    if (this.state.isFullscreen) {
      screenfull.exit();
    } else {
      this.setState({ isFullscreen: true }, () => {
        screenfull.request(ReactDOM.findDOMNode(this));

        Track.event.defer(`fullscreen`, {
          context: eventSource(undefined),
          animatic: true,
          category: 'Product',
        });
      });
    }
  },

  handleScreenfullChange() {
    this.setState({ isFullscreen: screenfull.isFullscreen });
  },

  onKeyPress(e) {
    const keyCode = e.keyCode;
    const isEditable = this.props.isEditable;
    const isPlaying = this.props.isPlaying;

    let stepAmountKey = 'stepAmount';
    if (e.shiftKey) {
      stepAmountKey = 'shiftStepAmount';
    }
    const stepAmount = this.props.durationOptions[stepAmountKey];

    if (isInInput(e.target)) return;

    if (keyCode === 39) {
      // arrow right
      //   e.preventDefault();
      //   if (e.metaKey) {
      //     this.props.goToFrame(_.last(this.props.frames));
      //   } else {
      //     this.props.navigateNext();
      //   }
      // } else if (keyCode === 37) { // arrow left
      //   e.preventDefault();
      //   if (e.metaKey) {
      //     this.props.goToFrame(_.first(this.props.frames));
      //   } else {
      //     this.props.navigatePrevious();
      //   }
    } else if (keyCode === 32) {
      // space bar
      e.preventDefault();
      this.props.togglePlay();
      // } else if (keyCode === 27 || keyCode === 83) { // esc or 's'
      //   e.preventDefault();
      //   this.props.close();
    } else if (isEditable && keyCode === 190 && !isPlaying) {
      // >
      this.props.adjustFrameInpoint({
        frameId: this.props.frame.id,
        offset: stepAmount,
      });
    } else if (isEditable && keyCode === 188 && !isPlaying) {
      // <
      this.props.adjustFrameInpoint({
        frameId: this.props.frame.id,
        offset: -stepAmount,
      });
    } else if (isEditable && keyCode === 219) {
      // [
      this.props.setFrameInpoint({
        frameId: this.props.frame.id,
        // time: this.props.time
      });
    } else if (isEditable && keyCode === 221) {
      // ]
      this.props.setFrameOutpoint({
        frameId: this.props.frame.id,
        // time: this.props.time
      });
      // } else if (keyCode === 70 && !e.metaKey) { // f
      //   this.props.toggleTimeline();
      // } else if (keyCode === 73 && !e.metaKey) { // I
      //   this.props.toggleSidebar();
    } else if (keyCode === 76 && !e.metaKey) {
      // L
      this.props.toggleLooping();
    } else if (keyCode === 16) {
      //shift
      this.props.setTimingsLock(false);
    }
  },

  onKeyUp(e) {
    if (isInInput(e.target)) return;

    if (e.keyCode === 16) {
      // shift
      this.props.setTimingsLock(true);
      e.preventDefault();
    }
  },

  handleClose() {
    // Reset the player store
    this.props.onTriggerClose();
    this.props.onChangeView && this.props.onChangeView('grid');
  },

  requestClose() {
    this.props.close();
    return Promise.resolve();
  },

  requestNavigate() {
    return Promise.resolve();
  },

  // onSkip(e) {
  //   const direction = e.currentTarget.value;
  //   if (direction === 'start') {
  //     this.props.goToFrame(_.first(this.props.frames))
  //   } else if (direction === 'end') {
  //     this.props.goToFrame(_.last(this.props.frames))
  //   }
  // },

  render() {
    return this.state.isFullscreen ? this.renderFullScreen() : this.renderUI();
  },

  renderFullScreen() {
    return (
      <div
        className="relative vh-100 min-h-vp w-100"
        style={{ backgroundColor: '#000' }}
      >
        <FrameViewer
          ref="viewer"
          frameAspectRatio={this.props.storyboard.frame_aspect_ratio}
          framesAreSaving={this.props.framesAreSaving}
          subtitleSource={this.props.storyboard.preferences.subtitles_from}
        />
        <FullScreenOverlays
          onSetIndex={this.props.onSetIndex}
          toggleFullscreen={this.onFullscreenClick}
        />
      </div>
    );
  },

  renderUI() {
    const hasTopbar = this.props.isEditable;
    const hasAudio =
      (this.props.audio && this.props.audio.track) ||
      this.props.isWaitingOnAudio;

    return (
      <PanelBarLayout
        onCloseClick={hasTopbar ? undefined : this.handleClose}
        theme={this.props.theme}
        sidebarComponent={
          this.props.hasSidebar && (
            <FrameFocusPanelbar
              storyboard={this.props.storyboard}
              frames={this.props.frames}
              onSetFrameIndex={this.props.onSetIndex}
              activeIndex={this.props.currentFrameIndex}
              canManage={this.props.canManage}
              showFrameStatus={
                this.props.storyboard.preferences.share_with_frame_status ||
                BoordsConfig.controller !== 'shareable'
              }
              padding="28px"
              focusType="player"
              restrictWidth
              scroll
            />
          )
        }
        headerHeight={frameFocusTopBarHeight}
        headerComponent={
          hasTopbar && (
            <FrameFocusTopBar
              onTriggerClose={this.handleClose}
              onSetMode={this.props.onSetFocusMode}
              onSetIndex={this.props.onSetIndex}
              maxIndex={this.props.frames.length - 1}
            />
          )
        }
        footerHeight={hasAudio ? 295 : 220}
        // This has to match with the top
        footerPadding="mt-5"
        footerComponent={
          <div className="p-10 pt-5 bg-white">
            <Navigation
              onSetIndex={this.props.onSetIndex}
              theme={this.props.theme}
              toggleFullscreen={this.onFullscreenClick}
              audio={this.props.audio}
            />
            {/* ToursActions.triggerEvent.defer(tourEvents.openShare); */}
            <div className="relative mb-2">
              <TourHintable
                step="wizardAnimaticTimeline"
                overlayPosition="top"
                distance={0}
                deferred
                hideNext
              >
                <CanvasScrubber
                  ref={(r) => (this.scrubberRef = r)}
                  audioData={this.props.audio.data}
                  time={this.props.time}
                  zoom={this.props.timelineZoom}
                  endTime={this.props.endTime}
                  onUpdateTime={this.props.updateTime}
                  currentFrameId={this.props.currentFrameId}
                  currentFrameNumber={
                    this.props.frame ? this.props.frame.number : '1'
                  }
                  frameTimes={this.props.frameTimes}
                  audioIsFetching={
                    this.props.audio?.state === 'fetching' ||
                    this.props.audio?.state === 'unfetched'
                  }
                />
              </TourHintable>
            </div>

            {this.props.timelineIsOpen ? (
              <Timeline
                goToFrame={this.props.onSetFrame}
                theme={this.props.theme}
              />
            ) : null}
          </div>
        }
      >
        {window.location.hash == '#demo' ? <ShareableSignupPrompt /> : null}

        <div className="relative flex-grow flex-shrink-0 mx-10 flex items-center justify-center">
          <AutoSizedFrame
            frameAspectRatio={this.props.storyboard.frame_aspect_ratio}
            border
          >
            {(sizes) => (
              <>
                <FrameViewer
                  frameAspectRatio={this.props.storyboard.frame_aspect_ratio}
                  componentRef={(r) => (this.viewerRef = r)}
                  framesAreSaving={this.props.framesAreSaving}
                  subtitleSource={
                    this.props.storyboard.preferences.subtitles_from
                  }
                  size={sizes}
                  border={false}
                />

                <AnnotationOverlay
                  key={this.props.frame.id}
                  currentFrameId={this.props.frame.id}
                  frameAspectRatio={this.props.storyboard.frame_aspect_ratio}
                  sizes={sizes}
                  passThrough
                />
              </>
            )}
          </AutoSizedFrame>
        </div>
      </PanelBarLayout>
    );
  },
});
