import type { Modifier } from "react-popper";

type modifier = Modifier<'observeMutations', { mutationObserver: MutationObserver }>

const observeMutations: modifier['effect'] = ({ state, options, instance }) => {
    options.mutationObserver = new MutationObserver(() => {
      instance.update()
    });

    options.mutationObserver.observe(state.elements.popper, {
      childList: true,
      subtree: true,
      // attributes: true,
      // attributeFilter: ['style'],
    });


  return () => {
    if (options.mutationObserver) {
      options.mutationObserver.disconnect()
    }
  }
};

export const popperObserveMutationsModifier: modifier =  {
  name: 'observeMutations',
  enabled: true,
  phase: 'afterWrite',
  effect: observeMutations,
}
