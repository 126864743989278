/** @prettier */
import * as React from 'react';
import logoGoogle from 'blackbird/images/logo/google.png';

interface BadgeGoogleProfileProps {
  email: string;
}
export const BadgeGoogleProfile: React.FC<BadgeGoogleProfileProps> = ({
  email,
}) => {
  return (
    <div className="inline-flex items-center justify-center px-3 py-1 rounded-full bg-action-secondary space-x-2">
      <img src={logoGoogle} alt="Google logo" className="w-4" />
      <div className="space-x-1 -mt-[0.2rem]">
        <span>{`Signs in with`}</span>
        <span className="font-semibold">{email}</span>
      </div>
    </div>
  );
};

(window as any).BadgeGoogleProfile = BadgeGoogleProfile;
