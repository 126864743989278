/** @prettier */
/* eslint-disable react/jsx-handler-names */
import * as React from 'react';
import { ContextMenuProps } from '../types';
import { ToolbarInput } from './ToolbarInput';
import { IconSpacerGroup } from '../../IconSpacer';
import { isUndefined } from 'underscore';
import { ColorPicker } from '../../shared/ColorPicker.react';
import { TourHintable } from '../../tours/TourHintable';
import { ColorPickerWellButton } from 'javascripts/components/shared/ColorPickerWellButton';

interface ToolbarLabelProps {
  label: string;
}
export const ToolbarLabel: React.FC<ToolbarLabelProps> = ({ label }) => (
  <div className="mr-3 text-sm ">{label}</div>
);

export const ShapeContextMenu: React.FC<ContextMenuProps> = ({
  actions,
  disabled,
  selected,
}) => {
  return (
    <>
      {selected.type !== 'arrow' && (
        <IconSpacerGroup>
          <div className="flex items-center h-full">
            <ToolbarLabel label={`Fill`} />
            <ColorPicker
              color={selected.fill}
              onChange={(e) => actions.handleSetFillColor(selected, e)}
              disabled={disabled}
              icon={<ColorPickerWellButton color={selected.fill} />}
              showAlpha
            />
          </div>
        </IconSpacerGroup>
      )}

      {selected.type === 'rect' && (
        <IconSpacerGroup>
          <div className="flex items-center h-full">
            <ToolbarLabel label={`Radius`} />
            <div className="">
              <ToolbarInput
                className="h-full mr2"
                min={0}
                max={Math.max(selected.height, selected.width) / 2 / 10}
                step={1}
                type="number"
                defaultValue={!isUndefined(selected.rx) ? selected.rx / 10 : 0}
                onChange={(e) => actions.handleSetBorderRadius(selected, e)}
              />
            </div>
          </div>
        </IconSpacerGroup>
      )}

      <div className="flex items-center ">
        <ToolbarLabel label={`Stroke`} />
        <ToolbarInput
          className="mr-2 shrink-0"
          min={0}
          max={100}
          type="number"
          defaultValue={selected.strokeWidth ?? 0}
          onChange={(e) => actions.handleSetStrokeWidth(selected, e)}
        />

        <ColorPicker
          color={selected.stroke}
          onChange={(e) => actions.handleSetStrokeColor(selected, e)}
          disabled={disabled}
          icon={<ColorPickerWellButton color={selected.stroke} />}
          showAlpha
        />
      </div>
    </>
  );
};

ShapeContextMenu.displayName = 'ShapeContextMenu';
