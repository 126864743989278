const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
window.ProjectTeamMember = createReactClass({

  propTypes:     {
    project_id:  PropTypes.number.isRequired,
    team_id:     PropTypes.number.isRequired,
    member:      PropTypes.object.isRequired,
  },

  UNSAFE_componentWillReceiveProps: function(nextProps) {
    if (nextProps.hidden && !this.props.hidden)
      this._animateOut();
    else if (!nextProps.hidden && this.props.hidden)
      this._animateIn();
  },

  _animateIn: function() {
    var el = ReactDOM.findDOMNode(this);
    var i = this.props.index;
    var marginRight = i == 2 ? 8 : 4 * i;
    TweenLite.to(el, 0.15, {
      x: (-el.clientWidth * i) - (4 * i), // 4 for margin between
      opacity: 1
    }).delay(0.01 * i);
  },

  _animateOut: function() {
    var el = ReactDOM.findDOMNode(this);
    var i = this.props.index + 1;
    TweenLite.to(el, 0.15, {
      x: 0,
      opacity: 0
    }).delay(0.01 * i);
  },

  render: function() {
    return <ProjectTeamMemberAvatar {...this.props} />
  }

});
