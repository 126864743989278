/** @prettier */
import * as Rollbar from 'rollbar/src/browser/core';
import telemeter from 'rollbar/src/telemetry';
import scrub from 'rollbar/src/scrub';
import truncation from 'rollbar/src/truncation';
import { any, throttle } from 'underscore';
const get = require('lodash.get');

const config = typeof BoordsConfig === 'undefined' ? {} : BoordsConfig;

Rollbar.setComponents({
  telemeter: telemeter,
  // instrumenter: instrumenter,
  scrub: scrub,
  truncation: truncation,
});

export const rollbar = Rollbar.init({
  accessToken: config.rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  // enabled: true,
  enabled: process.env.NODE_ENV === 'production' && !!config.rollbarToken,
  scrubTelemetryInputs: true,
  autoInstrument: {
    contentSecurityPolicy: true, // enables telemetry tracking, enabled by default
    errorOnContentSecurityPolicy: true, // send a Rollbar error message, disabled by default
  },
  maxTelemetryEvents: 50,
  captureIp: 'anonymize',
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        code_version: config.codeVersion,
        source_map_enabled: true,
      },
    },
    context: config.rollbarContext,
    person: config.Uid
      ? {
          id: config.Uid,
          team: config.TeamId,
        }
      : undefined,
  },
});

// Error handling for specific messages
const throttledError = throttle(() => {
  const RequestActions = (window as any).RequestActions;
  if (typeof RequestActions === 'undefined') return;

  RequestActions.error.defer(
    "Oops, something didn't go to plan. Please refresh your page or send us a message if the problem persists.",
  );
}, 1000);

rollbar.configure({
  transform: (payload: any) => {
    const errorName = get(payload.body, 'trace.exception.description');

    const reportErrors = [
      /_currentElement/,
      /getBoundingClientRect/,
      /'style' of undefined/,
      /'style' of null/,
      /upperCanvasEl/,
    ];

    if (errorName && any(reportErrors, (p) => p.test(errorName))) {
      throttledError();
      payload.userWasNotified = 'maybe';
    }
  },
});

export const setRollbarContext = (() => {
  const initial = (rollbar.options.payload as any).context;
  const previous: string[] = [];

  return (contextName) => {
    let use = contextName || initial;
    if (contextName === 'previous') {
      previous.pop();
      use = previous.slice(-1);
    }

    rollbar.configure({ payload: { context: use } });
    previous.push(contextName);
  };
})();
