/** @prettier */
import RadioButton from 'blackbird/components/form/radiobutton/RadioButton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import type { IStoryboardInStore } from '../../../types/storyboard';

const inverseFieldMap = {
  'storyboard.has_custom_cover_and_endslate': 'storyboard.include_pdf_cover',
  'storyboard.include_pdf_cover': 'storyboard.has_custom_cover_and_endslate',
};

export const ExportPdfFrontCover: React.FC<{
  storyboard: IStoryboardInStore;
  cover: any;
}> = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'export.pdf.frontCover',
  });
  const handleChange = (field_name) => () => {
    if (field_name === 'no_cover') {
      CoverpageActions.updateValue({
        attr: 'storyboard.has_custom_cover_and_endslate',
        value: false,
      });

      CoverpageActions.updateValue({
        attr: 'storyboard.include_pdf_cover',
        value: false,
      });
      return;
    }
    CoverpageActions.updateValue({
      attr: field_name,
      value: true,
    });
    CoverpageActions.updateValue({
      attr: inverseFieldMap[field_name],
      value: false,
    });
  };

  var cover = props.cover.cover;
  var noCustomCovers = !BoordsConfig.CanChangeBranding;

  if (BoordsConfig.Pro || (BoordsConfig.Team && !BoordsConfig.IsTrial)) {
    noCustomCovers = false;
  }

  return (
    <div className="mb-4 space-y-6">
      <div className="text-lg">{t('title')}</div>
      <div className="text-type-subdued">{t('description')}</div>
      <div className="flex flex-col gap-6">
        <RadioButton
          onChange={handleChange('storyboard.include_pdf_cover')}
          checked={
            cover.storyboard.include_pdf_cover &&
            !cover.storyboard.has_custom_cover_and_endslate
          }
          label={t('useCoverBuilder')}
          name="coverType"
        />
        <RadioButton
          label={t('useCustomCover')}
          onChange={handleChange('storyboard.has_custom_cover_and_endslate')}
          checked={
            !cover.storyboard.include_pdf_cover &&
            cover.storyboard.has_custom_cover_and_endslate
          }
          name="coverType"
        />

        <RadioButton
          label={t('useNoCover')}
          onChange={handleChange('no_cover')}
          checked={
            !cover.storyboard.include_pdf_cover &&
            !cover.storyboard.has_custom_cover_and_endslate
          }
          name="coverType"
        />
      </div>
      <div className="mt-14">
        {cover.storyboard.include_pdf_cover &&
        !cover.storyboard.has_custom_cover_and_endslate ? (
          <ExportPdfCoverBuilder cover={cover} storyboard={props.storyboard} />
        ) : null}
        {cover.storyboard.has_custom_cover_and_endslate ? (
          <ExportPdfBespokeUploader
            cover={cover}
            type="cover"
            storyboard={props.storyboard}
          />
        ) : null}
      </div>
    </div>
  );
};
