import { ajax } from "javascripts/helpers/ajax";
import logger from "javascripts/helpers/logger";
import { Monitor } from "./Monitor";
const Visibility = require('document-visibility');
const INTERVAL_MINUTES = process.env.NODE_ENV === 'production' ? 60 * 6 : 2;
const INTERVAL = 1000 * 60 * INTERVAL_MINUTES;

/**
 * Periodically checks for new versions of the codebase, and notifies the
 * listener (the banner store, in our case) if there is a new version
 */
export class CodeVersionMonitor extends Monitor<boolean> {
  hasNewerVersion = false;
  lastChecked = new Date();
  started = false;
  private timeout;

  init() {
    if (this.started) return;
    this.schedule(INTERVAL, 'initial');
    this.started = true;

    // If the browser becomes visible again, force a check
    Visibility().onChange(isVisible => {
      if (isVisible) {
        this.checkForVersion('visibility');
      } else {
        window.clearTimeout(this.timeout);
      }
    })
  }

  schedule(ms = INTERVAL, reason = "scheduled") {
    if (this.timeout) this.timeout = window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => this.checkForVersion(reason), ms);
  }

  // If we haven't checked in a while, query the server for the current
  // version. If we've checked recently, schedule another lookup
  checkForVersion(reason) {
    if (this.hasNewerVersion) return;
    const now = new Date();

    if ((now.valueOf() - this.lastChecked.valueOf()) > INTERVAL) {
      this.lastChecked = now;
      logger.info(
        'Checking for new version… '
        + (reason ? `(reason: ${reason})` : '')
      );

      ajax('/_version').then(
        newestVersion => {
          if (BoordsConfig.codeVersion !== newestVersion) {
            this.hasNewerVersion = true;
            document.body.classList.add('has--banner');
            this.notifyListeners(true)
          } else {
            this.schedule(INTERVAL / 10);
          }
        },
        () => {
          logger.log('Could not fetch new version information');
          this.schedule(INTERVAL / 10);
        }
      )
    } else {
      this.schedule(INTERVAL / 10);
    }
  }
}
