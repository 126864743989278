/** @format */
import { FrameActions } from '../actions/frame';
import { StoryboardAnalysisActions } from '../actions/storyboardAnalysis';
import shallowEqual from 'shallowequal';
import {
  getFrameField,
  getFrameFieldInfo,
  migrateFieldData,
} from 'javascripts/helpers/fieldDataHelpers';
import { typedLocalState } from 'javascripts/helpers/local-state';
import type {
  DetailedFrame,
  fieldData,
  frameFieldId,
  frameId,
} from 'javascripts/types/frame';
import {
  indexBy,
  isUndefined,
  keys,
  mapObject,
  reduce,
  throttle,
} from 'underscore';
import { RequestActions } from '../actions/request';
import { FakeAltStoreClass } from './AltStore';
import { rollbar } from 'javascripts/helpers/rollbar';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import logger from 'javascripts/helpers/logger';
import type { bulkUpdatesArray } from './frame';
import { ScriptEditorActions } from '../actions/scripteditor';

const errorHandler = RequestErrorHandler('scriptEditor');

const times = (howMany, character) => {
  let output = '';

  for (let index = 0; index < howMany; index++) {
    output += character;
  }
  return output;
};

const replaceSlashesWithLineBreaks = (string = '') =>
  string.replace(/ +(\/+) +/g, (_, match) => {
    return times(match.length, '<br/>');
  });

const localState = typedLocalState<null | frameFieldId>('scriptEditorFilter');

export type ScriptEditorData = Record<frameId, fieldData>;

export type ScriptEditorFilteredData = Record<frameId, string>;

export class ScriptEditorStore extends FakeAltStoreClass<ScriptEditorStore> {
  isOpen = false;

  /** This is where the changes will be stored temporarily. We want to start
   * this out as undefined so we know the difference
   * between empty and undefined */
  frames?: DetailedFrame[];
  /** A relatively quick lookup for the editable fields */
  editorData: ScriptEditorData = {};
  filter: null | frameFieldId = null;
  filteredEditorData: ScriptEditorFilteredData | null;
  CHARACTERS_PER_LINE = 35;
  hasChanges = false;
  isSaving = false;

  // this._handleBeforeUnload = this.handleBeforeUnload.bind(this);

  constructor() {
    super();

    this.bindListeners({
      handleInit: ScriptEditorActions.INIT,
      handleClose: ScriptEditorActions.CLOSE,
      handleUpdateText: ScriptEditorActions.UPDATE_TEXT,
      handleSetFilter: ScriptEditorActions.SET_FILTER,
      handleUpdateFilteredText: ScriptEditorActions.UPDATE_FILTERED_TEXT,
      handleCommitChanges: ScriptEditorActions.COMMIT_CHANGES,
      handleFrameUpdate: FrameActions.RECEIVE_FRAMES,
      handleFrameInsert: ScriptEditorActions.FRAME_INSERTED,
    });
  }

  // Depending on people's saved panelbar state, the script editor can be
  // open without the frameStore being populated. We want to keep track
  // of it if this is the case, and re-initialize
  handleFrameUpdate() {
    if (!this.frames && this.isOpen) {
      this.handleInit();
    }
  }

  // If there are no frames at the start, we want to re-initialize after
  // the user has created some
  handleFrameInsert() {
    const hasFramesNow = FrameStore.getState().frames?.length > 0;

    if (this.isOpen && this.frames?.length === 0 && hasFramesNow) {
      this.handleInit();
    }
  }

  handleInit() {
    const finishedLoading = FrameStore.getState().has_initial_content;
    this.isOpen = true;

    if (!finishedLoading) return;
    this.filter = localState.getValue() || null;
    this.filteredEditorData = null;
    this.isSaving = false;
    this.hasChanges = false;

    // Cloning the frames data
    this.editorData = {};

    // Populate `editorData` while duplicating all frames for editing
    this.frames = FrameStore.getState().frames.map((f) => {
      if (!f.field_data)
        f.field_data = migrateFieldData(f, getFrameFieldInfo());

      this.editorData[f.id] = f.field_data;

      return { ...f };
    });

    if (this.filter) this.handleSetFilter(this.filter);

    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (e) => {
    if (!this.hasChanges) return;
    const string =
      'You have unsaved changes in the script editor, are you sure you want to continue? You will lose your changes.';

    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = string;
    return string;
  };

  handleClose() {
    this.isOpen = false;
    this.hasChanges = false;
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleUpdateText({
    frameId,
    fieldId,
    value,
  }: {
    frameId: number;
    fieldId: string;
    value: string;
  }) {
    if (!frameId) throw new Error('Frame id is required');
    if (isUndefined(value)) throw new Error('fieldValue is missing');
    if (!fieldId) throw new Error('fieldId is missing');

    this.hasChanges = true;
    // Create new editorData object that inherits the previous data
    // but also the updated fields for the current frame
    this.editorData = {
      ...this.editorData,
      [frameId]: {
        ...this.editorData[frameId],
        [fieldId]: value,
      },
    };

    this._scheduleUpdateFrames();
  }

  // Fetch the fields for a specific frame, and replace slashes with breaks
  getFrameData(id: frameId): fieldData {
    // We take every key of editorData (reference, voiceover, etc…)
    // and replace the slashes with line breaks
    return mapObject(this.editorData[String(id)], replaceSlashesWithLineBreaks);
  }

  updateFrames() {
    if (!this.frames) return;
    // Pass the updated frame data to the (fake) storyboard UI

    this.frames = this.frames.map<DetailedFrame>((f) => {
      const newFieldData = this.getFrameData(f.id);

      // We don't want to create new frame objects when nothing has changed
      if (shallowEqual(f.field_data, newFieldData)) {
        return f;
      } else {
        return { ...f, update_field_state: true, field_data: newFieldData };
      }
    });
    this.emitChange();
  }

  // We can call this to periodically trigger an update to storyboard view
  _scheduleUpdateFrames = throttle(this.updateFrames, 200);
  handleSetFilter(fieldName: frameFieldId) {
    if (!this.frames) return;
    this.filter = fieldName;
    localState.setValue(fieldName);

    this.populateFilteredText();
  }

  populateFilteredText() {
    const filter = this.filter;
    if (!this.frames) return;
    if (!filter) {
      this.filteredEditorData = null;
      return;
    }

    this.filteredEditorData = this.frames.reduce<ScriptEditorFilteredData>(
      (output, frame) => {
        const frameContents = getFrameField(frame, filter);
        output[frame.id] = frameContents;
        return output;
      },
      {},
    );
  }

  handleUpdateFilteredText(newData: ScriptEditorFilteredData) {
    if (!this.frames) return;
    if (this.filter === null)
      throw new Error('Cannot update filtered text, no filter is selected');

    // Update our internal data
    this.editorData = reduce<DetailedFrame[], ScriptEditorData>(
      this.frames,
      (editorData, frame, i) => {
        // Create new editorData object that inherits the previous data
        // but also the changes made for the currently filtered field
        editorData[frame.id] = {
          ...this.editorData[frame.id],
          [this.filter!]: newData[frame.id],
        };

        return editorData;
      },
      {},
    );

    this.hasChanges = true;
    this._scheduleUpdateFrames();
    if (Object.keys(this.editorData).length !== this.frames.length) {
      throw new Error(
        'Expected amount of children of the script editor to be equal to the amount of frames',
      );
    }
  }

  handleCommitChanges() {
    const existingFrames: Record<frameId, DetailedFrame> = indexBy(
      FrameStore.getState().frames,
      'id',
    );

    // Find the fields that have changed, and submit only those to the server
    const updates = reduce<ScriptEditorData, bulkUpdatesArray>(
      this.editorData,
      (output, fieldData, frameId) => {
        // const frameId = String(frameId) as unknown as frameId
        const id = parseInt(frameId, 10);
        const existing = existingFrames[frameId];

        if (!existing) {
          rollbar.critical(`could not find frame in Script Editor data`, {
            idWanted: frameId,
            existingFrameIds: keys(existingFrames),
          });
          return output;
        }

        if (
          // This shouldn't happen, but apparently it does
          !existing ||
          !shallowEqual(existing.field_data, fieldData)
        ) {
          // TODO: fix unnecessary updates to fields because of the
          // replaceSlashes thing

          // const diff = omit(fieldData, function (v, k) {
          //   return existing.field_data[k] === v;
          // });

          // if (Object.keys(diff).length) {
          //   console.log('got a diff in frame ' + id, diff, existing.field_data);
          // }

          // Make sure all fields are strings, because the server can't
          // handle different types
          output.push({
            id: id,
            field_data: mapObject(fieldData, (s) =>
              (replaceSlashesWithLineBreaks(s) || '').trim(),
            ),
          });
        }

        return output;
      },
      [],
    );

    if (updates.length === 0) {
      logger.log('No updates to commit');
      // Show some feedback anyway
      RequestActions.success.defer('Script updates saved!');
      return;
    }

    this.updateFrames();
    this.isSaving = true;

    // The .state. here is a bit of a hack, but there's no other way
    // of being notified when an action completes
    FrameStore.state
      .handleBatchUpdates(updates, { commit: true })
      .then(() => {
        this.isSaving = false;
        this.hasChanges = false;
        RequestActions.success.defer('Script updates saved!');
        StoryboardAnalysisActions.scheduleAnalysis.defer();

        this.emitChange();
      })
      .catch(
        errorHandler(
          {
            rollbarMessage: 'Error saving script updates',
            message: 'Something went wrong trying to save your script updates',
          },
          () => {
            this.isSaving = false;
            this.emitChange();
          },
        ),
      );
  }
}

(window as any).ScriptEditorStore = alt.createStore(
  ScriptEditorStore,
  'ScriptEditorStore',
);
