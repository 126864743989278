/** @format */
import * as React from 'react';
import { StoryboardSettingsContainer } from '../storyboard/Settings/StoryboardSettings';
import PanelbarCommentsSettingsContainer from './comments/PanelbarCommentsSettingsContainer';
import { useTranslation } from 'react-i18next';
import Tabs from 'blackbird/components/tabs/Tabs';
import { useStore } from 'javascripts/helpers/useStore';
import type { TabProps } from 'blackbird/components/tabs/types';
import type {
  PanelbarStore,
  panelbarSubPanels,
} from 'javascripts/flux/stores/panelbar';
import { PanelbarActions } from 'javascripts/flux/actions/panelbar';

const PanelbarSettings = () => {
  const { t } = useTranslation();
  const activeSubPanel = useStore<panelbarSubPanels, PanelbarStore>(
    'panelbar',
    (p) => p.subPanel!,
  );

  const handleSelect = (newTab: panelbarSubPanels) => {
    PanelbarActions.setSubPanel(newTab);
  };

  const tabs: TabProps<panelbarSubPanels>[] = [
    {
      caption: t('sidebar.settings.storyboard.tab_caption'),
      key: 'storyboard',
    },
    {
      caption: t('sidebar.settings.comments.tab_caption'),
      key: 'comments',
    },
  ];
  return (
    <Tabs
      type="rounded"
      size="lg"
      defaultValue={activeSubPanel}
      onSelect={handleSelect}
      tabs={tabs}
      customFont
    >
      <div className="mx-6 mt-4">
        <Tabs.Bar className="sticky top-[97px] bg-white z-40" />
      </div>
      <Tabs.Panel>
        <StoryboardSettingsContainer />
        <PanelbarCommentsSettingsContainer />
      </Tabs.Panel>
    </Tabs>
  );
};

export default PanelbarSettings;
