/*** @prettier */
import React, { useContext, useEffect, useRef } from 'react';
import Dialog from 'blackbird/components/dialog/Dialog';
import Button from 'blackbird/components/button/Button';
import { SecondaryLink } from 'blackbird/components/common/SecondaryLink';
import { Timeline } from 'blackbird/components/trial/TrialStart';
import { TrialPlans } from 'blackbird/components/trial/TrialPlans';
import {
  TrialContext,
  TrialProvider,
} from 'blackbird/components/trial/TrialContext';
import { MousePointerClick, ShieldCheck } from 'lucide-react';
import { PricingProvider } from 'blackbird/components/pricing/PricingContext';

const PaymentStartTrialDialog: React.FC = () => {
  const { createTrial, isCreatingTrial } = useContext(TrialContext);

  const tracked = useRef(false);

  useEffect(() => {
    if (!tracked.current) {
      Track.once.defer('CompleteRegistration', {
        category: 'Account',
        posthogCapture: true,
      });

      if (typeof $FPROM !== 'undefined') {
        $FPROM.trackSignup({
          email: BoordsConfig.Email,
        });
      }
      tracked.current = true;
    }
  }, [tracked]);

  useEffect(() => {
    IntercomActions.enterContext.defer('signup');
    return () => {
      IntercomActions.leaveContext.defer('signup');
    };
  }, []);

  return (
    <Dialog
      isOpen
      isBlur
      hideActions
      titleContainerClasses="hidden"
      wrapperClasses="!pb-0"
      containerClasses={`!max-w-[56rem]`}
      keepOpen
      hideClose
      hideActionDivider
      hideCancel
    >
      <>
        <div className="flex flex-col items-center mb-4 md:flex-row">
          <div className="flex-auto mb-3 text-center md:mb-0 md:text-left">
            <div className="text-2xl font-semibold md:text-2xl ">{`Start Your Free Trial to Explore Boords`}</div>
            <div className="mt-2 text-sm md:text-base text-type-subdued">{`Unlock a dramatically faster pre-production process today.`}</div>
          </div>
        </div>
        {/* Main content */}
        <div className="grid md:grid-cols-2 gap-10">
          <div>
            <div className="flex justify-center py-3 mb-8 rounded-lg bg-type-green/15">
              <div className="flex items-center flex-shrink-0 space-x-6">
                <div className="flex items-center flex-shrink-0 text-type-green">
                  <ShieldCheck className="w-4 h-4 mr-1" />
                  <div className="text-sm pb-0.5">{`Secure checkout`}</div>
                </div>
                <div className="flex items-center flex-shrink-0 text-type-green">
                  <MousePointerClick className="w-4 h-4 mr-1" />
                  <div className="text-sm pb-0.5">{`Cancel in 1 click`}</div>
                </div>
              </div>
            </div>
            <Timeline />
          </div>
          <TrialPlans />
        </div>
        {/* Buttons */}
        <div className="mb-10 grid md:grid-cols-2 gap-4 md:gap-10 md:mt-4">
          <div className="flex justify-center order-last mt-4 text-sm space-x-4 text-type-subdued md:order-first md:justify-start">
            <SecondaryLink
              onClick={() => {
                FlyoverActions.open.defer({
                  type: 'inlinePricing',
                });
              }}
            >{`Compare Plans`}</SecondaryLink>
            <SecondaryLink href={`/billing`}>{`Account`}</SecondaryLink>
            <SecondaryLink href={`/logout`}>{`Log out`}</SecondaryLink>
          </div>
          <Button
            className="order-first w-full md:order-last"
            rounded
            type="pink"
            loading={isCreatingTrial}
            onClick={() => createTrial()}
          >{`Start free trial`}</Button>
        </div>
      </>
    </Dialog>
  );
};

const PaymentStartTrial: React.FC = () => (
  <PricingProvider>
    <TrialProvider>
      <PaymentStartTrialDialog />
    </TrialProvider>
  </PricingProvider>
);

(window as any).PaymentStartTrial = PaymentStartTrial;
