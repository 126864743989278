/** @format */

import React, { createContext, useState, useCallback, useContext } from 'react';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import logger from 'javascripts/helpers/logger';
import { RequestActions } from 'javascripts/flux/actions/request';
import { rollbar } from 'javascripts/helpers/rollbar';
import { type SVGIconID } from 'javascripts/types/icons';

const errorHandler = RequestErrorHandler('Templates');

// Define the aspect ratio type (to be confirmed)
export type AspectRatio =
  | '9x16'
  | '16x9'
  | '1x1'
  | '4:5'
  | '4:3'
  | '1.85:1'
  | '2.4:1';

// Define the template field structure
export interface TemplateField {
  id: string;
  label: string;
  icon: SVGIconID;
  isEnabled: boolean;
}

interface TemplatesResponse {
  isFeatureAvailable: boolean;
  templates: Template[];
}

// Define the template structure
export interface Template {
  id: string;
  name: string;
  aspect_ratio: AspectRatio;
  fields: TemplateField[];
  frame_count?: number;
  has_comments?: boolean;
  has_password?: boolean;
  created_at: string;
  placeholder?: string;
  updated_at: string;
}

interface TemplatesContextProps {
  templates: Template[];
  isLoading: boolean;
  error: string | null;
  teamId: string | null;
  setTeamId: React.Dispatch<React.SetStateAction<string>>;
  isFeatureAvailable: boolean;
  setIsFeatureAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  isTemplatesReleased: boolean;
  isFetching: boolean;
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
  isFetched: boolean;
  setIsFetched: React.Dispatch<React.SetStateAction<boolean>>;
  isTemplateModalOpen: boolean;
  setIsTemplateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentTemplate: Template | null;
  setCurrentTemplate: React.Dispatch<React.SetStateAction<Template | null>>;
  fetchTemplates: (teamId: string) => Promise<void>;
  createTemplate: (
    newTemplate: Pick<Template, 'name' | 'aspect_ratio' | 'fields'>,
  ) => Promise<void>;
  updateTemplate: (
    templateId: number,
    updatedTemplate: Partial<Template>,
  ) => Promise<void>;
  setCurrentTemplateFromId: (templateId: string) => void;
  duplicateTemplate: (templateId: string) => Promise<void>;
  deleteTemplate: (templateId: string) => Promise<void>;
}

// Create the context
export const TemplatesContext = createContext<
  TemplatesContextProps | undefined
>(undefined);

// Create a custom hook for using the context
export const useTemplates = () => {
  const context = useContext(TemplatesContext);
  if (context === undefined) {
    throw new Error('useTemplates must be used within a TemplatesProvider');
  }
  return context;
};

// Create the provider component
export const TemplatesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFeatureAvailable, setIsFeatureAvailable] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teamId, setTeamId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);

  const isTemplatesReleased = BoordsConfig.HasTemplates;

  const handleError = (message: string, error?: Error) => {
    RequestActions.error.defer(message);
    if (error) {
      logger.error(error);
      rollbar.error(error);
    }
  };

  const fetchTemplates = useCallback(
    async (initialTeamId: string) => {
      if (!initialTeamId) {
        setIsLoading(false);
        setIsFetched(true);
        setIsFetching(false);
        return;
      }
      if (isFetching) {
        return;
      }
      setIsLoading(true);
      setIsFetching(true);
      setIsFetched(false);
      setError(null);
      setTeamId(initialTeamId);

      try {
        const response = await apiRequest({
          path: `dashboard/templates/${initialTeamId}`,
          method: 'get',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch templates');
        }

        const fetchedTemplates: TemplatesResponse = await response.json();
        setTemplates(fetchedTemplates.templates);
        setIsFeatureAvailable(fetchedTemplates.isFeatureAvailable);
      } catch (err) {
        errorHandler({ method: 'get' })(err);
        handleError(
          'Error fetching templates',
          err instanceof Error ? err : undefined,
        );
        setError('An error occurred while fetching templates');
      } finally {
        setIsFetched(true);
        setIsLoading(false);
        setIsFetching(false);
      }
    },
    [isFetching],
  );

  const setCurrentTemplateFromId = useCallback(
    (templateId: string) => {
      if (templates.length) {
        const foundTemplate = templates.find(
          (template) => template.id === templateId,
        );
        if (foundTemplate) {
          setCurrentTemplate(foundTemplate);
        }
      } else {
        setCurrentTemplate(null);
      }
    },
    [templates],
  );

  const createTemplate = useCallback(
    async (newTemplate: Omit<Template, 'id'>) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await apiRequest({
          path: 'dashboard/templates',
          method: 'post',
          payload: {
            ...newTemplate,
            team_id: teamId,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to create template');
        }

        const createdTemplate: Template = await response.json();
        setTemplates((prevTemplates) => [...prevTemplates, createdTemplate]);
        RequestActions.success.defer('Template created successfully');
      } catch (err) {
        errorHandler({ method: 'post' })(err);
        handleError(
          'Error creating template',
          err instanceof Error ? err : undefined,
        );
        setError('An error occurred while creating the template');
      } finally {
        setIsLoading(false);
      }
    },
    [teamId],
  );

  const duplicateTemplate = useCallback(
    async (templateId: string) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await apiRequest({
          path: `dashboard/templates/${templateId}/duplicate`,
          method: 'post',
          payload: { team_id: teamId },
        });

        if (!response.ok) {
          throw new Error('Failed to duplicate template');
        }

        const duplicatedTemplate: Template = await response.json();
        setTemplates((prevTemplates) => [...prevTemplates, duplicatedTemplate]);
        RequestActions.success.defer('Template duplicated successfully');
      } catch (err) {
        errorHandler({ method: 'post' })(err);
        handleError(
          'Error duplicating template',
          err instanceof Error ? err : undefined,
        );
        setError('An error occurred while duplicating the template');
      } finally {
        setIsLoading(false);
      }
    },
    [teamId],
  );

  const deleteTemplate = useCallback(
    async (templateId: string) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await apiRequest({
          path: `dashboard/templates/${templateId}`,
          method: 'delete',
          payload: { team_id: teamId },
        });

        if (!response.ok) {
          throw new Error('Failed to delete template');
        }

        setTemplates((prevTemplates) =>
          prevTemplates.filter(
            (template) => (template.id as any) !== templateId,
          ),
        );
        RequestActions.success.defer('Template deleted successfully');
      } catch (err) {
        errorHandler({ method: 'delete' })(err);
        handleError(
          'Error deleting template',
          err instanceof Error ? err : undefined,
        );
        setError('An error occurred while deleting the template');
      } finally {
        setIsLoading(false);
      }
    },
    [teamId],
  );

  const updateTemplate = useCallback(
    async (templateId: number, updatedTemplate: Partial<Template>) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await apiRequest({
          path: `dashboard/templates/${templateId}`,
          method: 'put',
          payload: {
            ...updatedTemplate,
            team_id: teamId,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to update template');
        }

        const updatedTemplateData: Template = await response.json();
        setTemplates((prevTemplates) =>
          prevTemplates.map((template) =>
            (template.id as any) === templateId
              ? updatedTemplateData
              : template,
          ),
        );
        RequestActions.success.defer('Template updated successfully');
      } catch (err) {
        errorHandler({ method: 'put' })(err);
        handleError(
          'Error updating template',
          err instanceof Error ? err : undefined,
        );
        setError('An error occurred while updating the template');
      } finally {
        setIsLoading(false);
      }
    },
    [teamId],
  );

  const value: TemplatesContextProps = {
    templates,
    isLoading,
    error,
    isTemplateModalOpen,
    setIsTemplateModalOpen,
    currentTemplate,
    setCurrentTemplate,
    fetchTemplates,
    createTemplate,
    updateTemplate,
    duplicateTemplate,
    deleteTemplate,
    teamId,
    setTeamId,
    isFetched,
    setIsFetched,
    isFetching,
    setIsFetching,
    isFeatureAvailable,
    setIsFeatureAvailable,
    setCurrentTemplateFromId,
    isTemplatesReleased,
  };

  return (
    <TemplatesContext.Provider value={value}>
      {children}
    </TemplatesContext.Provider>
  );
};
