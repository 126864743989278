import { useTranslation } from 'react-i18next';
import React from 'react';
import {posthog} from 'posthog-js';
const { default: Dialog } = require('blackbird/components/dialog/Dialog');

export const FlyoverEmailConfirmation: React.FC = () => {
  const { t } = useTranslation();

  const handleResend = () => {
    window.location.href = "/resend_confirmation"
  }

  return (
    <Dialog
      hideCancel
      keepOpen
      size={`normal`}
      hideClose
      overlayClassName={`from-brand-pink-25 to-brand-yellow-25 bg-gradient-to-br`}
      alignButtons={`left`}
      actionChildren={(
        <div className='flex justify-end flex-auto '>
          <a
            className='text-sm text-type-disabled hover:underline'
            onClick={() => {
              posthog.reset();
            }}
            href={'/logout'}
          >{t('signup.mustConfirmEmailDialog.wrongEmail')}</a>
        </div>
      )}
      confirmText={t('signup.mustConfirmEmailDialog.button')}
      onConfirmBtnClick={handleResend}
      title={t("signup.mustConfirmEmailDialog.title")}
      description={t("signup.mustConfirmEmailDialog.description", { email: BoordsConfig.Email })}
      isOpen
    >
    </Dialog>
  )
}

(window as any).FlyoverEmailConfirmation = FlyoverEmailConfirmation;
