/* eslint-disable no-misleading-character-class */
/** @prettier */
import { isString } from 'underscore';

/** From https://github.com/xpl/printable-characters/ */
const zeroWidthCharacterExceptNewline =
  '\u0000-\u0008\u000B-\u0019\u001b\u009b\u00ad\u200b\u2028\u2029\ufeff\ufe00-\ufe0f';

/** Remove characters that cause issues in other formats like excel, ms word */
export const removeUnsupportedCharacters = (str = '') => {
  if (!isString(str)) return str;
  return str
    .replace(/\v/g, '')
    .replace(new RegExp(`[${zeroWidthCharacterExceptNewline}]`, 'g'), '');
};
