/** @prettier */
import * as React from 'react';
import Button from '../button/Button';
import classNames from 'classnames';

import {
  formatCurrency,
  formatCurrencyParts,
} from 'javascripts/helpers/currencyHelper';

import {
  type LocalPlanData,
  type Price,
} from 'blackbird/helpers/contexts/BillingContext';
import { SaleContext } from '../sale/SaleContext';
import { PricingTableFeatures } from './PricingTableFeatures';
import { PricingContext } from './PricingContext';
import { PricingYearlySaving } from './PricingYearlySaving';
import { PricingTableUserCost } from './PricingTablePuffinUserCost';
import { type TrialPlan } from '../trial/TrialContext';
import { CheckIcon } from 'lucide-react';

export interface PricingTablePuffinPlanProps {
  plan: LocalPlanData;
  price: Price;
  onSelect?: (plan: TrialPlan) => void;
  isSelectedPlan?: boolean;
}

export const PricingTablePuffinPlan: React.FC<PricingTablePuffinPlanProps> = ({
  plan,
  price,
  onSelect,
  isSelectedPlan = false,
}): React.ReactElement => {
  const [isCurrentPlan, setIsCurrentPlan] = React.useState(false);
  const [isSale, setIsSale] = React.useState(false);
  const [monthlyPrice, setMonthlyPrice] = React.useState<Price | undefined>(
    undefined,
  );

  const { activeSale } = React.useContext(SaleContext);
  const { billingInterval } = React.useContext(PricingContext);

  React.useEffect(() => {
    if (plan.prices) {
      setMonthlyPrice(
        plan.prices.find(
          (p) => p.interval === 'month' && p.currency === BoordsConfig.Currency,
        ),
      );
    }
  }, [plan.prices]);

  React.useEffect(() => {
    if (activeSale && billingInterval === 'year') {
      setIsSale(true);
    } else {
      setIsSale(false);
    }
  }, [activeSale, billingInterval]);

  React.useEffect(() => {
    if (
      BoordsConfig.PlanSlug === plan.slug &&
      BoordsConfig.SubscriptionStatus === 'active' &&
      ((billingInterval === 'year' && BoordsConfig.IsAnnual) ||
        (billingInterval === 'month' && !BoordsConfig.IsAnnual))
    ) {
      setIsCurrentPlan(true);
    } else {
      setIsCurrentPlan(false);
    }
  }, [plan.slug, billingInterval]);

  interface DiscountedPriceProps {
    discount: number;
  }

  const DiscountedPrice: React.FC<DiscountedPriceProps> = ({ discount }) => {
    const calculateDiscountedPrice = () => {
      const discountMultiplier = (100 - discount) / 100;
      const monthlyPrice = price.amount / (billingInterval === 'year' ? 12 : 1);
      return Math.floor((monthlyPrice * discountMultiplier) / 100);
    };

    return (
      <>
        <span className="ml-1.5 mr-0.5">
          {
            formatCurrencyParts({
              amount: 0,
              currency: BoordsConfig.Currency,
              showZeros: false,
            }).symbol
          }
        </span>
        <span className="text-xl">
          {
            formatCurrencyParts({
              amount: calculateDiscountedPrice(),
              currency: BoordsConfig.Currency,
              showZeros: false,
            }).value
          }
        </span>
      </>
    );
  };

  interface BasePriceProps {
    basePrice: Price;
    lineThrough: boolean;
    divideYearly?: boolean;
  }

  const BasePrice: React.FC<BasePriceProps> = ({
    basePrice,
    lineThrough,
    divideYearly = false,
  }) => {
    return (
      <div className="relative flex items-baseline">
        {lineThrough && (
          <div className="absolute top-3.5 -left-1 w-[120%] border border-b -rotate-[15deg] border-black/40 z-10" />
        )}
        <span
          className={classNames(
            'mr-0.5 text-base ',
            lineThrough ? 'text-black/30' : '',
          )}
        >
          {
            formatCurrencyParts({
              amount: 0,
              currency: BoordsConfig.Currency,
              showZeros: false,
            }).symbol
          }
        </span>
        <span
          className={classNames('mr-0 text-lg', lineThrough && 'text-black/30')}
        >
          {
            formatCurrencyParts({
              amount: Math.floor(
                basePrice.amount / (divideYearly ? 12 : 1) / 100,
              ),
              currency: BoordsConfig.Currency,
              showZeros: false,
            }).value
          }
        </span>
      </div>
    );
  };

  const handleSelect = () => {
    if (onSelect) onSelect(plan.slug as TrialPlan);
  };

  return (
    <div
      className={classNames(
        'relative py-6 px-7 border-2 rounded-xl bg-surface-light',
        onSelect && 'cursor-pointer',
        plan.featured && !onSelect
          ? 'border-brand-pink'
          : onSelect && isSelectedPlan
          ? 'border-brand-pink'
          : 'border-border ',
      )}
      onClick={handleSelect}
    >
      {/* Plan name */}
      <div className="flex items-center">
        <div
          className={classNames(
            'mb-1 mr-2 font-semibold flex-auto',
            'text-xl',
            isSelectedPlan && 'text-brand-pink',
          )}
        >
          {plan.name}
        </div>
        {onSelect && (
          <div
            className={classNames(
              'flex-shrink-0 w-6 h-6 border-2 rounded-full ',
              isSelectedPlan
                ? 'bg-brand-pink border-brand-pink flex items-center justify-center'
                : 'border-border',
            )}
          >
            {isSelectedPlan && (
              <CheckIcon strokeWidth={3} className="w-4 h-4 text-white" />
            )}
          </div>
        )}
      </div>

      <div className="pb-4 mt-2 mb-5 border-b-2 border-border">
        {plan.descriptionShort ? (
          <span className="inline-block pb-2 text-type-subdued whitespace-nowrap">
            {plan.descriptionShort}
          </span>
        ) : (
          <span className="text-sm ">{plan.description}</span>
        )}
      </div>

      {/* Price information */}
      <div className="mt-2 mb-4">
        {onSelect ? (
          <div className="mb-6 ">
            <div className="flex items-center">
              <div
                className={classNames(
                  'flex-auto',
                  !isSelectedPlan && 'text-type-subdued',
                )}
              >{`FREE 7 day trial`}</div>
              <div className="flex-shrink-0">{`$0`}</div>
            </div>
            <div
              className={classNames(
                'flex items-center mt-1 mb-2 text-sm ',
                !isSelectedPlan ? 'text-type-disabled' : 'opacity-70',
              )}
            >
              <div className={'flex-auto'}>{`After trial ends`}</div>
              <div className="flex-shrink-0">
                {
                  <>{`${formatCurrency({
                    amount: price.amount / 100,
                    currency: BoordsConfig.Currency,
                  })}/mo`}</>
                }
              </div>
            </div>
            <div
              className={classNames(
                'flex items-center ',
                isSelectedPlan ? 'font-semibold' : 'text-type-disabled',
              )}
            >
              <div className={'flex-auto'}>{`Due today`}</div>
              <div className="flex-shrink-0">{`$0`}</div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-baseline">
              {/* Base Price */}

              {/* <BasePrice basePrice={monthlyPrice} lineThrough /> */}
              {isSale ? (
                <BasePrice basePrice={price} lineThrough divideYearly />
              ) : BoordsConfig.IsEdu && billingInterval === 'year' ? (
                <BasePrice basePrice={price} lineThrough divideYearly />
              ) : BoordsConfig.IsEdu && billingInterval === 'month' ? (
                <BasePrice basePrice={price} lineThrough />
              ) : billingInterval === 'year' && monthlyPrice ? (
                <div className="-ml-1.5">
                  <DiscountedPrice discount={0} />
                </div>
              ) : (
                <div className="-ml-1.5">
                  <DiscountedPrice discount={0} />
                </div>
              )}

              {/* Sale or Education Price */}
              {isSale && !BoordsConfig.IsEdu && activeSale ? (
                <DiscountedPrice discount={activeSale.discountPercentage} />
              ) : BoordsConfig.IsEdu ? (
                <DiscountedPrice discount={50} />
              ) : null}

              <span className="whitespace-nowrap text-base ml-0.5">{`/month`}</span>
            </div>

            {billingInterval === 'year' && (
              <div className="mt-1.5">
                <PricingYearlySaving prices={plan.prices} />
              </div>
            )}

            <div className="mt-1 text-xs text-type-subdued">
              {price.additional_user_amount ? (
                <div className="-ml-0.5">
                  <>{`+`}</>
                  <PricingTableUserCost price={price} />
                  <>{`/month per additional user`}</>
                </div>
              ) : (
                <>{`Single user`}</>
              )}
            </div>
          </>
        )}
      </div>

      {!onSelect && (
        <div className="mt-6 mb-6">
          <Button
            rounded
            disabled={isCurrentPlan}
            type={plan.featured || isCurrentPlan ? 'pink' : 'solid'}
            className={classNames('w-full rounded-full')}
            link={`/checkout/${plan.slug}/${billingInterval}ly${
              activeSale && billingInterval === 'year'
                ? `?coupon=${activeSale.coupon}`
                : ''
            }`}
          >
            <span className="font-normal">
              {isCurrentPlan ? (
                <>{`Current Plan`}</>
              ) : (
                <>{`Buy ${plan.name}`}</>
              )}
            </span>
          </Button>
        </div>
      )}

      <div className="pt-6 border-t-2 border-border">
        <PricingTableFeatures
          plan={plan}
          price={price}
          hideUserCount={Boolean(onSelect)}
          title={
            <div className="text-xs font-semibold tracking-wider uppercase text-type-subdued">
              {plan.slug === 'lite'
                ? 'Included:'
                : plan.slug === 'standard'
                ? 'Lite features, plus:'
                : 'Standard features, plus:'}
            </div>
          }
        />
      </div>
    </div>
  );
};
