/** @prettier */

import * as React from 'react';
import Button from 'blackbird/components/button/Button';
import Banner from 'blackbird/components/feedback/banner/Banner';
import classNames from 'classnames';
import { CalendarIcon } from 'lucide-react';
import { differenceInDays } from 'date-fns';

declare const BoordsConfig: {
  V3TrialEndsAt?: number;
};

declare const FlyoverActions: {
  open: {
    defer: (options: { type: string; props: any }) => void;
  };
};

export const BannerWorkflowTrial: React.FC = () => {
  const getDaysRemaining = () => {
    if (!BoordsConfig.V3TrialEndsAt) {
      return null;
    }
    const now = new Date();
    const trialEndDate = new Date(BoordsConfig.V3TrialEndsAt * 1000); // Convert Unix timestamp to milliseconds
    const daysRemaining = differenceInDays(trialEndDate, now);
    return daysRemaining < 0 ? null : Math.max(0, daysRemaining);
  };

  const daysRemaining = getDaysRemaining();

  if (daysRemaining === null) {
    return null;
  }

  return (
    <Banner
      kind={'alert'}
      hideIcon
      className="no-padding-y h-banner bg-premium-100"
      customWidth
      message={
        <div className="relative flex items-center justify-start flex-auto w-full">
          <div className="flex items-center pl-3 pr-3.5 pt-0.5 pb-1 leading-tight font-semibold rounded-md bg-premium-200 text-premium-700">
            <CalendarIcon className="w-3.5 mt-[0.1rem]" />
            <span className="ml-1.5">{`Workflow Trial`}</span>
          </div>
          <div className="ml-3 mr-2.5 font-semibold text-premium-800">
            {`${daysRemaining} days remaining →`}
          </div>
          <div className={classNames('flex justify-start flex-auto')}>
            <Button
              type="premium"
              size="sm"
              rounded
              className="group"
              onClick={() => {
                FlyoverActions.open.defer({
                  type: 'workflowPreview',
                  props: {
                    isOpenedByAdmin: true,
                  },
                });
              }}
            >
              <span className="relative flex items-center">
                <span>{`Review Features & Upgrade`}</span>
              </span>
            </Button>
          </div>
        </div>
      }
    />
  );
};
