/** @prettier */

export function getClosestIndexInArray(num: number, arr: number[]) {
  var closestIndex = 0;
  var diff = Math.abs(num - arr[closestIndex]);
  for (var val = 0; val < arr.length; val++) {
    var newdiff = Math.abs(num - arr[val]);
    if (newdiff < diff) {
      diff = newdiff;
      closestIndex = val;
    }
  }
  return closestIndex;
}
