const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const Logo = require('../../../blackbird/components/logo/Logo').default
const { isUndefined } = require('underscore')

window.XhrErrorMessage = createReactClass({
  displayName: 'XhrErrorMessage',

  propTypes: {
    status_code: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    listItems: PropTypes.array,
    cta: PropTypes.element,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.element
    ]),
  },

  render() {

    const {
      title,
      subtitle,
      status_code,
      listItems,
      cta,
      children
    } = this.props

    return (
      <div className="flex items-center justify-center min-h-vp inset-0 fixed bg-white z-flyover">
        <div className="max-w-lg p-8 space-y-6">
          <a href="/"><Logo /></a>
          <h1 className="text-lg font-semibold">
            {title}
            {!isUndefined(status_code) && (<>{` (${status_code} error)`}</>)}
          </h1>
          {subtitle && (
            <p className="text-base">{subtitle}</p>
          )}
          {listItems && (
            <ul className="ml-4 text-base list-disc space-y-2">
              {listItems.map((li, idx) => (
                <li className="" key={`item-${idx}`}>{li}</li>
              ))}
            </ul>
          )}
          {cta && (<div>{cta}</div>)}
          {children && (<>{children}</>)}

        </div>
      </div>
    )
}
})

module.exports = window.XhrErrorMessage;
