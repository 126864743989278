window.StoryboardMobilityActions = alt.createActions(function () {

  this.check = function (storyboard) {
    this.dispatch(storyboard);
  };

  this.fetchDestinationOptions = function (storyboard) {
    this.dispatch(storyboard);
  };

  this.setDestinationProjectId = function (id) {
    this.dispatch(id);
  };

  this.move = function () {
    this.dispatch();
  };

});
