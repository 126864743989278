/** @format */

import React, { useContext } from 'react';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/20/solid';
import Tooltip from '../feedback/tooltip/Tooltip';
import Badge from '../badge/Badge';
import logoGoogle from 'blackbird/images/logo/google.png';
import { TeamContext, type TeamMember } from './TeamContext';

export const TeamTwoFactorBadge: React.FC<{ member: TeamMember }> = ({
  member,
}) => {
  const { teamData } = useContext(TeamContext);

  if (!teamData) {
    return null;
  }

  const { is_two_factor_enabled, is_oauth_signin, role } = member;
  const isAdmin = role === 'admin';
  const available = teamData.has_two_factor;

  interface AdminDisabledProps {
    label: string;
    link: string;
    cta: string;
  }

  const AdminDisabled: React.FC<AdminDisabledProps> = ({
    label,
    cta,
    link,
  }) => (
    <Tooltip title={label} placement="top">
      <a href={link}>
        <Badge
          label={<span className="whitespace-nowrap">{cta}</span>}
          icon={<LockOpenIcon className="w-3 text-type-subdued" />}
        />
      </a>
    </Tooltip>
  );

  return (
    <>
      {is_oauth_signin ? (
        <Tooltip title={`Signs in with Google`} placement="top">
          <span className="mt-[0.1rem] bg-action-secondary px-1 rounded-full inline-flex items-center justify-center w-6 h-6">
            <img src={logoGoogle} alt="Google logo" className="w-3" />
          </span>
        </Tooltip>
      ) : is_two_factor_enabled ? (
        <Tooltip title={`Two-factor authentication enabled`} placement="top">
          <span>
            <Badge
              label={<span className="">{`2FA`}</span>}
              color="green"
              icon={<LockClosedIcon className="w-3 text-type-green" />}
            />
          </span>
        </Tooltip>
      ) : isAdmin && !available ? (
        <AdminDisabled
          link={`/checkout/workflow/${
            BoordsConfig.IsAnnual ? 'yearly' : 'monthly'
          }`}
          cta={`Enable 2FA`}
          label={`Upgrade to the Workflow plan to enable two-factor authentication for you and your team`}
        />
      ) : isAdmin && available ? (
        <AdminDisabled
          link={`/settings/account/twofactor/new`}
          label={`Finish setting up two-factor authentication`}
          cta={`Complete 2FA Setup`}
        />
      ) : available ? (
        <Badge
          label={<span className="text-type-subdued">{`2FA`}</span>}
          icon={<LockOpenIcon className="w-3 text-type-disabled" />}
        />
      ) : (
        <></>
      )}
    </>
  );
};
