/**@prettier */

import React from 'react';
import classNames from 'classnames';

interface SidebarProps {
  className?: string;
}
const SidebarLayout: React.FC & {
  Bar: React.FC<SidebarProps>;
  Main: React.FC;
} = (props) => {
  const { children } = props;
  return (
    <div className="flex w-full h-full">
      {React.Children.map(children, (child: JSX.Element) => {
        const name = child?.type?.name;
        if (
          // The `name` attribute of these children is only available
          // in development. These values would always return false on
          // production due to minification, causing this map to always
          // return null. It's fine to not test for this in production.
          process.env.NODE_ENV !== 'development' ||
          name === 'Main' ||
          name === 'Sidebar'
        ) {
          return child;
        }
        return null;
      })}
    </div>
  );
};

const Main: React.FC = (props) => {
  const { children } = props;
  return <>{children}</>;
};
const Sidebar: React.FC<SidebarProps> = (props) => {
  const { children, className } = props;
  return (
    <div
      className={classNames(
        className,
        'w-sidebar ml-auto flex-shrink-0',
      )}
    >
      {children}
    </div>
  );
};

export default SidebarLayout;

SidebarLayout.Bar = Sidebar;
SidebarLayout.Main = Main;
