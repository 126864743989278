/** @prettier */
import logger from '../logger';
let loaded = false;

/**
 * Loads pusher with a delay (depending on CPU availability) so we don't
 * clog up connections early in the app.
 */
export const loadPusher = () => {
  if (BoordsConfig.pusher && !loaded) {
    loaded = true;

    loadScript('https://js.pusher.com/4.0/pusher.min.js', (err) => {
      if (err) {
        loaded = false;
        logger.error(err);
        return;
      }

      const pusher = new Pusher(BoordsConfig.pusher.key, {
        cluster: BoordsConfig.pusher.cluster,
        encrypted: true,
        authEndpoint: '/pusher',
        auth: {
          headers: {
            'X-CSRF-Token': BoordsConfig.pusher.token,
          },
        },
      });

      pusher.connection.bind('connected', function () {
        RealtimeActions.pusherLoaded();
      });

      (window as any).pusher = pusher;
    });
  }
};
