/** @format */
import React from 'react';
import { type ArchivedProject } from '../ProjectsContext';
import Icon from '../../icon/Icon';
import ProjectIcon from '../../../images/icons/project.svg';
import { ArchivedItemActions } from '../menus/ArchivedItemActions';
import { format } from 'date-fns';

interface ArchivedProjectCardProps {
  project: ArchivedProject;
}

const EditedDate: React.FC<{ editedAt: string }> = ({ editedAt }) => {
  try {
    return (
      <div className="text-xs text-type-disabled">
        {`Archived ${format(new Date(editedAt), 'd MMM yy')}`}
      </div>
    );
  } catch (err) {
    return null;
  }
};

export const ArchivedProjectCard: React.FC<ArchivedProjectCardProps> = ({
  project,
}) => {
  return (
    <div className="flex flex-col group/archivedproject">
      <div className="rounded-lg hover:bg-black/10">
        <div className="m-1.5">
          <div className="relative p-4 pt-3 bg-white border border-border rounded-md">
            <div
              className="absolute z-50 opacity-0 top-2 right-2 group-hover/archivedproject:opacity-100 transition-opacity"
              onClick={(e) => e.stopPropagation()}
            >
              <ArchivedItemActions itemId={project.id} itemType="project" />
            </div>
            <div className="flex items-center mb-0.5">
              <div className="flex-shrink-0 mr-1.5">
                <div className="flex items-center opacity-20 group-hover:opacity-50">
                  <Icon
                    icon={<ProjectIcon />}
                    className="flex-shrink-0 h-5 mt-0.5"
                    color="black"
                  />
                </div>
              </div>
              <div className="flex-auto">
                <div className="inline-block text-base">{project.name}</div>
              </div>
            </div>
            <EditedDate editedAt={project.deleted_at} />
          </div>
        </div>
      </div>
      <div className="flex-auto" />
    </div>
  );
};
