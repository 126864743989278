const _ = require('underscore');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');

window.AdminDelete = createReactClass({

  propTypes: {
    id: PropTypes.number.isRequired,
  },

  _delete: function(e) {

    var willDelete = prompt("This will completely, irreversibly delete this user and all of their data from the database. Type DELETE if you want to proceed");

    if (!_.isEmpty(willDelete) && (willDelete === "DELETE")) {
      ajaxHelper.ajax({
        method: "post",
        url: "/admin/" + this.props.id + "/remove.json",
        success: function () {
          RequestActions.success.defer("User deleted, redirecting...");
          window.location = "/admin";
        }.bind(this),
        error: function() {
          RequestActions.error.defer("Sorry, we couldn't complete that task");
        }.bind(this),
      });
    } else {
      RequestActions.error.defer("User not deleted.");
    }
  },

  render: function() {
    return (
      <AdminButton
        onClick={this._delete}
        type="destructive"
        text="Permanently Delete User"
      />
    );
  }
});
