/** @format */
import Toast from 'blackbird/components/feedback/toast/Toast';
import type { FeedbackKind } from 'blackbird/components/feedback/types';
import * as React from 'react';

type alertTypes = 'error' | 'success' | 'notice';
interface IProps {
  type: alertTypes;
  message: string;
}

const typeConverter: Record<alertTypes, FeedbackKind> = {
  error: 'error',
  success: 'success',
  notice: 'info',
};

/** @deprecated — Use a Toast component */
export const InlineAlert: React.FC<IProps> = (props) => {
  return (
    <Toast
      kind={typeConverter[props.type]}
      size="full"
      message={props.message}
    />
  );
};

(window as any).InlineAlert = InlineAlert;
