const createReactClass = require('create-react-class')

module.exports = createReactClass({
  displayName: "PopupMenuStatusItem",
  render() {
    return (
      <div
        onClick={this.props.onClick}
        className={"pointer tl ph3 pv3 flex items-center " + (this.props.showBorder ? "bb bw1 b--light-grey" : "")}>
        <div className={"w10px h10px br-100 " + this.props.color} />
        <div className="flex-auto ml3 f5 underline-hover">
          {this.props.label}
        </div>
      </div>
    );
  }
});
