const _ = require('underscore');
const restrictedActions = {
  generic: {
    identifier: "generic",
    cta: {
      intent: "generic",
      recommended_plan: "individual",
      header: "Unlock all Boords features",
      description: 'Get the #1 Storyboard Software for Modern Video Teams',
    }
  },
  collaboration: {
    identifier: "collaboration",
    cta: {
      intent: "collaboration",
      recommended_plan: "individual",
      title: "Unlock team collaboration",
      description: 'Get the #1 Storyboard Software for Modern Video Teams',
    }
  },
  newVersion: {
    identifier: "newVersion",
    cta: {
      intent: "newVersion",
      recommended_plan: "individual",
      title: "Unlock storyboard versions",
      description: 'Get the #1 Storyboard Software for Modern Video Teams',
    }
  },
  newStoryboard: {
    identifier: "newStoryboard",
    cta: {
      intent: "newStoryboard",
      recommended_plan: "individual",
      title: "Unlock more storyboards",
      description: 'Get the #1 Storyboard Software for Modern Video Teams',
    }
  },
  frame_fields: {
    identifier: "frame_fields",
    cta: {
      intent: "frame_fields",
      recommended_plan: "individual",
      title: "Unlock more storyboards",
      description: 'Get the #1 Storyboard Software for Modern Video Teams',
    }
  },
  passwordProtection: {
    identifier: "passwordProtection",
    cta: {
      intent: "passwordProtection",
      recommended_plan: "individual",
      title: "Unlock secure sharing",
      description: 'Get the #1 Storyboard Software for Modern Video Teams',
    }
  }
};


const shouldRestrict = type => (
  (type !== 'newStoryboard' && Object.keys(restrictedActions).includes(type) && window.BoordsConfig.Freeloader)
)

const openFlyover = type => {
  const intent = _.findWhere(restrictedActions, {identifier: type});

  FlyoverActions.open.defer({
    type: "userPermission",
    props: Object.assign(intent, {userId: BoordsConfig.Uid})
  });
  return false;
}

module.exports = () => {
  return {
    access: (data) => {
      if (shouldRestrict(data.type)) {
        openFlyover(data.type);
        return false;
      } else {
        return true;
      }
    },
    actions: restrictedActions
  }
};
