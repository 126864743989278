/** @prettier */

import { type UserStore } from 'javascripts/flux/stores/user';
import { useStore } from 'javascripts/helpers/useStore';
import { type UserResponse } from 'javascripts/types/user';
import { FolderTree } from 'lucide-react';
import React from 'react';
import { hideWorkflowButtonLocalState } from 'javascripts/helpers/local-state';

export const DashboardHeaderWorkflowButton: React.FC = () => {
  const user = useStore<UserResponse | undefined, UserStore>(
    'user',
    (state) => state.user,
  );

  if (
    !user ||
    !BoordsConfig.Rollout.Workflowbutton ||
    Boolean(hideWorkflowButtonLocalState.getValue())
  ) {
    return null;
  }

  const isPaidAdmin = [
    'Agency',
    'Basic',
    'Company Plus',
    'Company',
    'CompanyPlus',
    'Freelance',
    'Group',
    'Individual',
    'Plus',
    'Pro',
    'Powerhouse',
    'Professional Large',
    'Professional Extra Large',
    'Professional Medium',
    'Professional Starter',
    'Studio',
    'Team',
  ].includes(user.plan);

  const isOnPaidTeam = user.teams.some((team) => {
    return (
      (team.id as unknown) !== user.team_id &&
      ['manager', 'supermember'].includes(team.role)
    );
  });

  if (isPaidAdmin || isOnPaidTeam || BoordsConfig.Freeloader) {
    return (
      <span
        className="py-1 pl-3 pr-3.5 font-semibold border rounded-full cursor-pointer border-premium-100 bg-premium-50 hover:bg-premium-100 hover:border-premium-200 group/workflow whitespace-nowrap"
        onClick={() => {
          FlyoverActions.open.defer({
            type: 'workflowPreview',
            props: {
              isOpenedByAdmin: isPaidAdmin || BoordsConfig.Freeloader,
            },
          });
        }}
      >
        <span className="inline-flex items-center space-x-1.5">
          <FolderTree className="w-3.5 h-3.5 text-premium-400 group-hover/workflow:text-premium-500" />
          <span className="pb-0.5 text-premium-700 text-sm">{`Discover Workflow`}</span>
        </span>
      </span>
    );
  } else {
    return null;
  }
};
