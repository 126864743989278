/** @format */
const createReactClass = require('create-react-class');
require('./Alert.react');
var AsyncAlert = createReactClass({
  getInitialState() {
    return RequestStore.getState();
  },

  componentWillUnmount() {
    RequestStore.unlisten(this._onChange);
  },

  componentDidMount() {
    RequestStore.listen(this._onChange);
  },

  _onChange(state) {
    this.setState(state);
  },

  render() {
    if (this.state.message) {
      let message = this.state.message;

      return (
        <Alert
          text={message}
          type={this.state.message_type}
          onClick={
            this.state.messageLink
              ? () => window.open(this.state.messageLink, '_blank')
              : undefined
          }
        >
          {this.state.messageLink ? (
            <>
              {message.slice(-1) !== '.' ? '.' : undefined}
              <span className="underline">
                {' Click here for more information.'}
              </span>
            </>
          ) : null}
        </Alert>
      );
    } else {
      return <div />;
    }
  },
});

window.AsyncAlert = AsyncAlert;
