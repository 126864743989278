/**@prettier */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { IStoryboardInStore } from '../../../types/storyboard';
import RadioButton from 'blackbird/components/form/radiobutton/RadioButton';

export class ExportPdfBackCover extends React.Component<{
  storyboard: IStoryboardInStore;
  cover: any;
}> {
  static propTypes = {
    storyboard: PropTypes.object.isRequired,
    cover: PropTypes.object.isRequired,
  };
  handleChange = (field_name) => () => {
    if (field_name === 'no_cover') {
      CoverpageActions.updateValue({
        attr: 'storyboard.has_custom_cover_and_endslate',
        value: false,
      });
    } else {
      CoverpageActions.updateValue({
        attr: field_name,
        value: true,
      });
      CoverpageActions.updateValue({
        attr: 'storyboard.include_pdf_cover',
        value: false,
      });
    }
  };

  render() {
    var cover = this.props.cover.cover;
    var noCustomCovers = !BoordsConfig.CanChangeBranding;

    if (BoordsConfig.Pro || (BoordsConfig.Team && !BoordsConfig.IsTrial)) {
      noCustomCovers = false;
    }

    return (
      <div className="flex flex-col gap-6">
        <div className="text-lg">Back cover settings</div>
        <div className="text-type-subdued">
          Upload a custom back cover page for your PDF. It will automatically be
          appended to the end of your storyboard frames. Perfect for adding
          credits or contact information.
        </div>
        <RadioButton
          onChange={this.handleChange(
            'storyboard.has_custom_cover_and_endslate',
          )}
          checked={cover.storyboard.has_custom_cover_and_endslate}
          label="Use custom back cover"
        />
        <RadioButton
          label="No back cover"
          onChange={this.handleChange('no_cover')}
          checked={!cover.storyboard.has_custom_cover_and_endslate}
        />
        {cover.storyboard.has_custom_cover_and_endslate ? (
          <ExportPdfBespokeUploader
            cover={cover}
            type="endslate"
            label="Endslate"
            storyboard={this.props.storyboard}
          />
        ) : null}
      </div>
    );
  }
}
