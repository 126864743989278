/** @prettier */
import Img from 'javascripts/components/shared/Img';
import logger from 'javascripts/helpers/logger';
import * as React from 'react';
import type { Icon } from './IconFinderContainer';

interface ImageSearchFilterItemProps {
  onClick: () => void;
  url?: string;
  previewIcons?: Icon[];
  title: string;
  aspect?: string;
  naturalAspectRatio?: boolean;
  count: number;
  noun: string;
}

export const ImageSearchFilterItem: React.FC<ImageSearchFilterItemProps> = ({
  onClick,
  url,
  title,
  aspect = 'video',
  previewIcons,
  naturalAspectRatio,
  count,
  noun,
}) => {
  const [invalid, setInvalid] = React.useState(false);

  React.useEffect(() => {
    if (previewIcons) {
      setInvalid(previewIcons.some((item) => item.preview_url === undefined));
    }
  }, [previewIcons]);

  return invalid ? null : (
    <div
      onClick={onClick}
      className="px-3 py-2 border cursor-pointer hover:bg-surface border-border rounded-md group"
    >
      {previewIcons ? (
        <div className="mt-2 mb-2 grid grid-cols-2 gap-3">
          {previewIcons.map((icon, idx) => (
            <Img
              small
              key={`preview-icon-${icon.icon_id}-${idx}`}
              src={icon.preview_url}
              className={'max-w-full max-h-full aspect-square'}
            />
          ))}
        </div>
      ) : naturalAspectRatio ? (
        <Img src={url} className={'max-w-full max-h-full mb-2'} />
      ) : (
        <div
          className={`cover rounded-sm w-full aspect-${aspect} z-0 bg-center mb-2 rounded-sm mt-1`}
          style={{
            backgroundImage: "url('" + url + "')",
          }}
        />
      )}
      <div className="text-xs group-hover:text-brand-pink">{title}</div>
      <div className="text-xs text-type-disabled">{`${new Intl.NumberFormat(
        `en-US`,
      ).format(count)} ${noun}`}</div>
    </div>
  );
};
