/**@prettier */
const _ = require('underscore');
const { default: Button } = require('blackbird/components/button/Button');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');

window.ExportPdfBespokeUploader = createReactClass({
  propTypes: {
    storyboard: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
  },

  getInitialState: function () {
    return PdfCoverStore.getState();
  },

  componentDidMount: function () {
    PdfCoverStore.listen(this._onChange);
    PdfCoverActions.fetch.defer({
      storyboard_id: this.props.storyboard.id,
    });
  },

  componentWillUnmount: function () {
    clearTimeout(window.PDFBespokeCoverInterval);
    PdfCoverStore.unlisten(this._onChange);
  },

  _onChange: function (state) {
    this.setState(state);
  },

  _handleImageChange: function (e) {
    e.preventDefault();
    PdfCoverActions.upload({
      type: this.props.type,
      storyboard_id: this.props.storyboard.id,
      file: e.target.files[0],
    });
  },

  _handleFilePicker(e) {
    e.preventDefault();
    FilestackActions.openPDF({
      type: this.props.type,
      storyboard_id: this.props.storyboard.id,
      team_id: this.props.storyboard.project.owner.id,
    });
  },

  _cancel: function (e) {
    e.preventDefault();
    PdfCoverActions.remove({
      type: this.props.type,
      storyboard_id: this.props.storyboard.id,
      message: 'PDF processing cancelled',
    });
  },

  _remove: function (e) {
    e.preventDefault();
    PdfCoverActions.remove({
      type: this.props.type,
      storyboard_id: this.props.storyboard.id,
    });
  },

  render: function () {
    var url, file_name;

    if (!this.state.is_loaded) {
      return null;
    }

    if (this.props.type == 'cover') {
      url = this.state.urls.cover.url;
      file_name = this.state.urls.cover.file_name;
    } else {
      url = this.state.urls.endslate.url;
      file_name = this.state.urls.endslate.file_name;
    }

    if (this.state.urls.is_processing) {
      return (
        <div>
          <div className="flex align-center justify-center ba bw1 b--light-gray pa4 lh-copy br2">
            <span className="f4">Processing upload...</span>
            <div className="flex-auto tr">
              <img className="dib w2" src={BoordsConfig.LoaderUrl} />
            </div>
          </div>
          <div className="silver f4 mt3 ml4">
            Taking too long?{' '}
            <a className="underline silver pointer" onClick={this._cancel}>
              Cancel this upload
            </a>{' '}
            and try again
          </div>
        </div>
      );
    }

    return (
      <div className="border border-border-mid rounded p-6">
        {!_.isEmpty(url) ? (
          <div>
            <span className="text-type-subdued">Current file:</span>{' '}
            <a
              className="text-type-subdued underline"
              href={url}
              target="_blank"
            >
              {file_name}
            </a>
          </div>
        ) : null}
        {_.isEmpty(url) ? (
          <div>
            <span className="text-type-subdued">
              No PDF selected.{' '}
              <a
                className="underline"
                href="https://intercom.help/boords/storyboards-and-frames/uploading-custom-coverend-pages-to-pdfs"
                target="_blank"
              >
                Learn about PDF formats
              </a>
            </span>
            <div className="mt-5">
              <Button type="secondary" onClick={this._handleFilePicker}>
                Upload new PDF
              </Button>
            </div>
          </div>
        ) : (
          <div className="mt-5 flex gap-7">
            <Button type="secondary" onClick={this._handleFilePicker}>
              Choose replacement PDF
            </Button>
            <Button type="text" className="underline" onClick={this._remove}>
              Remove
            </Button>
          </div>
        )}
      </div>
    );
  },
});
