/** @format */
import * as React from 'react';
import { omit, each, pick, isNumber, once } from 'underscore';
import classNames from 'classnames';
import { markdownParser } from 'javascripts/helpers/markdown-parser';
import { parseRTF } from './parseRTF';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import { sanitizeHTML } from './sanitizeHTML';
import debounce from 'lodash.debounce';
import * as memoize from 'memoizee';
import { notUndefined } from 'javascripts/helpers/notUndefined';
import logger from 'javascripts/helpers/logger';
import { FormattingToolbar } from './FormattingToolbar';
import { insertAtSelection } from './insertAtSelection';
import GraphemeSplitter from 'grapheme-splitter';
import { PopTransition } from 'blackbird/components/common/Transitions';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { characterInputPattern } from 'javascripts/components/panelbars/scriptEditor/scriptEditorDeletionHander';
import 'react-circular-progressbar/dist/styles.css';
import './richTextInput.css';
import { RequestActions } from 'javascripts/flux/actions/request';
import { parseAndSanitizeText } from 'blackbird/helpers/parseAndSanitizeText';

const splitter = new GraphemeSplitter();

const errorHandler = RequestErrorHandler('richTextInput');

const setDefaultParagraphSeparator = once(() => {
  document.execCommand('defaultParagraphSeparator', false, 'p');
});

function getPathColor(charCountPercentage: number): string | undefined {
  if (charCountPercentage >= 100) {
    return '#F1BECD'; // pink
  } else if (charCountPercentage > 90) {
    return '#FAD4B2'; // orange
  }
  return '#6CBCF4'; // blue
}

type DebouncedProps = {
  cancel: () => void;
  flush: () => void;
};

export type RichTextInputChangeEvent = React.FormEvent<
  HTMLDivElement | HTMLTextAreaElement
>;

export type RichTextInputChangeEventHandler = (
  newValue: string,
  e: RichTextInputChangeEvent,
  name?: string,
) => void;

/** The presence of this event will prevent any automatic insertion, so you have
 * to do that manually, for example with `insertAtSelection`. By this point
 * `e.preventDefault()` has already been called.  */
export type RichTextInputPasteEventHandler = (
  e: React.ClipboardEvent<HTMLDivElement>,
  sanitizedHTML: DocumentFragment | string,
  inputElement: HTMLDivElement,
) => void;

let getStyle = (
  minRows?: number,
  maxRows?: number,
  style?: React.CSSProperties,
): React.CSSProperties =>
  pick(
    {
      // FIXME: ideally we'd use the `lh` unit for this, but it's not widely
      // accepted. At some point we could reintroduce the "rows" props, but
      // this might work oddly with the contentEditable
      minHeight: isNumber(minRows) ? `${minRows * 2.2}em` : undefined,
      maxHeight: isNumber(maxRows) ? `${maxRows * 2.2}em` : undefined,
      /** This should be fixed by the newer `break-words` tailwind class, which
       * uses `overflow-wrap: break-word` under the hood. But I couldn't get
       * this to work.  */
      wordBreak: 'break-word',
      ...style,
    },
    notUndefined,
  );

getStyle = memoize(getStyle) as typeof getStyle;

interface Props
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    'onChange' | 'onPaste' | 'onBlur'
  > {
  onChange?: RichTextInputChangeEventHandler;
  disabled?: boolean;
  value?: string;
  dir?: string;
  autoFocus?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  id?: string;
  name?: string;
  title?: string;
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
  onHeightChange?: () => void;
  /** Because this one can be called from the formatting toolbar itself, we
   * can't always pass along a proper blur event  */
  onBlur?: (e?: React.FocusEvent) => void;
  canFormat?: boolean;
  plainText?: boolean;
  /** Has the `value` passed already been sanitized? */
  preParsed?: boolean;
  onPaste?: RichTextInputPasteEventHandler;
  /** determines the onChange behaviour, when this is false, it will debounce the
changes significantly. Regardless of this setting. We will always fire the
handler if it hasn't fired yet and `onBlur` is triggered */
  immediate: boolean;
  maxCharacters?: number;
}

const markdownParserOptions = {
  openLinksInNewWindow: true,
  allowHeadings: false,
};

/** Props to omit from the input field to prevent warnings in React  */
// prettier-ignore
const fieldsToOmit:(keyof Props | 'ref')[] = ['value', 'ref', 'onChange', 'maxRows', 'minRows','onHeightChange', 'autoFocus', 'plainText', 'preParsed', 'immediate', 'maxCharacters'];

const RichTextInput = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const innerRef = React.useRef<HTMLDivElement>(null);
  const toolbarRef = React.useRef<HTMLDivElement>(null);
  const measuredHeight = React.useRef<number>();
  const [showFormattingToolbar, setShowFormattingToolbar] =
    React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(props.value === '');
  const [charCount, setCharCount] = React.useState(0);
  const charCountPercentage = (charCount / (props.maxCharacters ?? 1)) * 100;
  React.useImperativeHandle(ref, () => innerRef.current!);
  // prettier-ignore
  const { onChange, onBlur, minRows, maxRows, onHeightChange, autoFocus, onClick, onFocus} = props;

  const debouncedUpdate = React.useCallback<
    React.FormEventHandler & DebouncedProps
  >(
    debounce(
      (e) => {
        if (innerRef.current) {
          const content = props.plainText
            ? innerRef.current.innerText
            : innerRef.current.innerHTML;
          onChange?.(content, e, props.name);
        }
      },
      props.immediate ? 0 : 1500,
    ),
    [],
  );

  // TODO: Handle max characters?
  const handlePaste = React.useCallback<
    React.ClipboardEventHandler<HTMLDivElement>
  >(
    (e) => {
      const html = e.clipboardData.getData('text/html');
      const rtf = e.clipboardData.getData('text/rtf');
      const plainText = e.clipboardData.getData('text/plain');
      const target = e.target as HTMLDivElement;
      const range = getSelection()?.getRangeAt(0);
      const selectedLength = range?.toString() ?? '';
      const charactersUsed = innerRef.current?.textContent?.length ?? 0;
      const characterCountOnInsertion = charactersUsed - selectedLength.length;
      e.persist(); // So further processing (e.g. props.onpaste can make use)
      const container = e.currentTarget;

      if (
        props.maxCharacters &&
        characterCountOnInsertion + plainText.length > props.maxCharacters
      ) {
        e.preventDefault();
        RequestActions.error({
          key: 'sharedErrors.pasteMaxCharactersExceeded',
          data: { maxLength: props.maxCharacters },
        });
        return;
      }

      /** We want to fire an `input` event when we've finished processing the
       * paste, because the regular event doesn't fire when we run preventDefault */
      const changeEvent = new Event('input', { bubbles: true });
      if (e.isDefaultPrevented()) {
        // Just want to log this in case confusion occurs:
        // preventDefault may be fine, for example if the paste is intercepted
        // by the link insertion logic in FormattingToolbar.
        logger.log('Paste event happened but default is prevented.');
      } else if (html && html !== '') {
        e.preventDefault();
        const sanitizedHTML = sanitizeHTML(html, true);
        if (props.onPaste) {
          props.onPaste(e, sanitizedHTML, container);
        } else {
          insertAtSelection(sanitizedHTML, props.plainText);
        }
        target.dispatchEvent(changeEvent);
      } else if (rtf && rtf.length) {
        e.preventDefault();
        parseRTF(rtf)
          .then((result) => {
            let textToUse: string | DocumentFragment;

            if (result.length === 0) {
              // Sometimes the RTF parser returns an empty value,
              errorHandler({ messageKey: null, severity: 'warn' })(
                new Error(`RTF returned empty, defaulting to plain text`),
              );
              textToUse = plainText;
            } else {
              textToUse = sanitizeHTML(result, true);
            }

            if (props.onPaste) {
              props.onPaste(e, textToUse, container);
            } else {
              insertAtSelection(textToUse, props.plainText);
            }
            target.dispatchEvent(changeEvent);
          })
          .catch(errorHandler({ messageKey: null }));
      } else if (props.onPaste) {
        e.preventDefault();
        // Normally, we want to default to default behavior when the text is
        // neither HTML / RTF, but not if onPaste is defined
        props.onPaste(e, plainText, container);
      } else {
        e.preventDefault();
        // If we've made it here, it means the content could be anything (even
        // images!) so we should make sure we only paste text.
        document.execCommand('insertText', false, plainText);
      }
    },
    [props.plainText, props.onPaste, props.maxCharacters],
  );

  const handleOnClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target instanceof HTMLAnchorElement) {
      window.open(e.target.href, '_blank');
      e.preventDefault();
      return;
    }

    onClick?.(e);
  };

  const setLengthBasedState = React.useCallback(() => {
    if (innerRef.current) {
      setIsEmpty(innerRef.current.textContent === '');
      if (props.maxCharacters) {
        setCharCount(
          splitter.countGraphemes((innerRef.current.textContent ?? '').trim()),
        );
      }
    }
  }, [props.maxCharacters]);

  /** This will only applied when we need to limit character count */
  const handleKeyDown = React.useCallback<React.KeyboardEventHandler>(
    (e) => {
      if (!props.maxCharacters) return;
      const amountOfCharacters = splitter.countGraphemes(
        (innerRef.current?.textContent ?? '').trim(),
      );

      if (
        amountOfCharacters >= props.maxCharacters &&
        e.key.match(characterInputPattern) &&
        !e.ctrlKey &&
        !e.metaKey
      ) {
        e.preventDefault();
      }
    },
    [props.maxCharacters],
  );

  /** Process incoming changed 'values' */
  React.useEffect(() => {
    setDefaultParagraphSeparator();

    // If we currently have the element in focus, we don't actually want to
    // update it (this prevents the caret from being reset)
    // FIXME: is this actually necessary?
    if (document.activeElement === innerRef.current || !innerRef.current) {
      return;
    }

    let parsed = props.preParsed
      ? props.value ?? ''
      : parseAndSanitizeText(props.value, false, markdownParserOptions);

    if (props.plainText) {
      const parser = new DOMParser();
      parsed = parser.parseFromString(parsed, 'text/html').body.innerText;
    }

    // If there are no changes, we don't have to update the html
    if (innerRef.current.innerHTML.trim() !== parsed.trim()) {
      innerRef.current.innerHTML = parsed;
    }

    if (props.className && props.className.indexOf('placeholder:') >= 0) {
      logger.log(
        `'placeholder:' class names are not supported for RichTextInputs, use the 'placeholder-text…' class names instead`,
      );
    }

    setLengthBasedState();

    measuredHeight.current = innerRef.current.offsetHeight;
  }, [
    props.value,
    props.className,
    props.plainText,
    props.preParsed,
    setLengthBasedState,
  ]);

  // We don't want to fire onChange for every change, because we will be
  // parsing markdown when the value enters or leaves the component, so we
  // want to minimize overhead here.
  const handleChange = React.useCallback<
    React.FormEventHandler<HTMLDivElement>
  >(
    (e) => {
      // Sanitize the HTML in-place
      // This is not sanitizing the surrounding divs (which technically
      // shouldn't be allowed). However, we want to ignore these, so we are fine
      each(e.target, (i) => sanitizeHTML(i, false, { IN_PLACE: true }));
      e.persist();
      debouncedUpdate(e);

      setLengthBasedState();

      // Fire `onHeightChange` callback if height changed
      if (innerRef.current && onHeightChange) {
        const newHeight = innerRef.current.offsetHeight;
        if (measuredHeight.current !== newHeight) {
          onHeightChange();
        }
        measuredHeight.current = newHeight;
      }
    },
    [debouncedUpdate, onHeightChange, setLengthBasedState],
  );

  // This debounced because we want the user to be able to move to the
  // formatting toolbar (for link editing), so we allow some time for that to
  // happen,
  const handleBlur = React.useCallback(
    debounce((e?: React.FocusEvent) => {
      // We want to slightly debounce this. When this triggers, we want to see
      // if the current cursor is in the input or the toolbar, if so, we want to
      // ignore the event for now

      const selection = window.getSelection();
      const anchorElement = selection?.anchorNode?.parentElement;
      if (!anchorElement || !innerRef.current) return;

      if (
        // If the selection is still in the editor
        innerRef.current.contains(anchorElement) ||
        // Or we've moved the cursor to somewhere inside the toolbar
        (anchorElement && !toolbarRef.current?.contains(anchorElement))
      ) {
        debouncedUpdate.flush();
        onBlur?.(e);
        setShowFormattingToolbar(false);
      }
    }, 100),
    [debouncedUpdate, onBlur],
  );

  const handleFocus = React.useCallback<
    React.FocusEventHandler<HTMLDivElement>
  >(
    (e) => {
      onFocus?.(e);
      if (!props.plainText) {
        setShowFormattingToolbar(true);
      }
    },
    [onFocus, props.plainText],
  );

  React.useEffect(() => {
    if (autoFocus) innerRef.current?.focus();

    // Clean up
    return debouncedUpdate.cancel;
  }, [autoFocus, debouncedUpdate.cancel]);

  return (
    <>
      <div
        {...omit(props, fieldsToOmit)}
        onKeyDown={props.maxCharacters ? handleKeyDown : props.onKeyDown}
        onChange={undefined}
        spellCheck
        ref={innerRef}
        onInput={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={classNames(
          'focus:outline-none w-full relative',
          props.className,
          isEmpty && 'empty',
          // As far as I know, the overflow is really only intended behaviour
          // when maxRows is set
          props.maxRows && 'overflow-touch-scroll-y',
          (!props.minRows || props.minRows === 1) && 'truncatedPlaceholder',
        )}
        dir={props.dir ?? 'auto'}
        onPaste={handlePaste}
        onClick={handleOnClick}
        style={getStyle(minRows, maxRows, props.style)}
        contentEditable={!props.disabled}
        aria-placeholder={props.placeholder}
        aria-multiline="true"
        role="textbox"
      />
      {showFormattingToolbar ? (
        <FormattingToolbar
          inputRef={innerRef}
          onClickOutside={handleBlur}
          toolbarRef={toolbarRef}
        />
      ) : null}

      {props.maxCharacters && (
        <PopTransition
          show={charCount > props.maxCharacters * 0.5}
          className="absolute bottom-2 right-2 w-4 h-4"
        >
          <Tooltip
            title={`${charCount}/${props.maxCharacters} allowed characters`}
          >
            <CircularProgressbar
              value={charCountPercentage}
              strokeWidth={50}
              styles={buildStyles({
                strokeLinecap: 'butt',
                pathColor: getPathColor(charCountPercentage),
                trailColor: '#F9F9F5', // surface-light
              })}
            />
          </Tooltip>
        </PopTransition>
      )}
    </>
  );
});

RichTextInput.displayName = 'RichTextInput';

export default React.memo(RichTextInput);
export { Props as RichTextInputProps };
