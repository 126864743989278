/** @prettier */
import Button from 'blackbird/components/button/Button';
import { ModalDivider } from 'blackbird/components/dialog/Dialog';
import Toast from 'blackbird/components/feedback/toast/Toast';
import { BillingContext } from 'blackbird/helpers/contexts/BillingContext';
import logger from 'javascripts/helpers/logger';
import { rollbar } from 'javascripts/helpers/rollbar';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PaymentError: React.FC = () => {
  const {
    handleReload,
    setDialogTitle,
    setDialogSubtitle,
    errorType,
    customer,
    errorMessage,
    trackCheckoutEvent,
  } = useContext(BillingContext);

  const { t } = useTranslation('billing');

  useEffect(() => {
    if (errorType) {
      setDialogTitle(t(`error.${errorType}.title`));
      if (customer) {
        setDialogSubtitle(
          t(`error.${errorType}.subtitle`, {
            planName: customer.current_plan_slug.replace('_', ' '),
            interval: `${customer.current_interval}ly`,
          }),
        );
      } else {
        setDialogSubtitle(t(`error.${errorType}.subtitle`));
      }
      trackCheckoutEvent('checkout_error', {
        errorType,
      });
      rollbar.error('checkout_error', {
        errorType,
        location: 'PaymentError.tsx#39',
      });
    } else {
      setDialogTitle(t('error.generic.title'));
      setDialogSubtitle(t('error.generic.subtitle'));

      trackCheckoutEvent('checkout_error', {
        errorType: 'generic',
      });
      rollbar.error('checkout_error', {
        errorType: 'generic',
        location: 'PaymentError.tsx#53',
      });
    }
  }, [errorType, customer]);

  return (
    <div className="w-full">
      {errorMessage && errorType === 'generic' && (
        <Toast hideIcon kind="error" message={errorMessage} />
      )}
      <div className="mt-8">
        <ModalDivider />
        <Button
          type="fancy"
          className="w-full mt-8"
          size="md"
          onClick={() =>
            errorType !== 'generic'
              ? (window.location.href = t(`error.${errorType}.href`))
              : handleReload()
          }
        >
          {t(`error.${errorType}.cta`)}
        </Button>
      </div>
    </div>
  );
};
