/** @prettier */

import * as React from 'react';
import Button from 'blackbird/components/button/Button';
import Banner from 'blackbird/components/feedback/banner/Banner';
import classNames from 'classnames';
import { CalendarIcon, Gem } from 'lucide-react';
import { differenceInDays, endOfDay } from 'date-fns';

declare const BoordsConfig: {
  TrialEndsAt?: number;
};

export const BannerPuffinTrial: React.FC = () => {
  const getDaysRemaining = () => {
    if (!BoordsConfig.TrialEndsAt) {
      return null;
    }
    const now = new Date();
    const trialEndDate = new Date(BoordsConfig.TrialEndsAt * 1000);
    const daysRemaining = differenceInDays(endOfDay(trialEndDate), now);
    return daysRemaining <= 0 ? null : daysRemaining;
  };

  const daysRemaining = getDaysRemaining();

  if (daysRemaining === null) {
    return null;
  }

  const smallPadding = window.location.pathname.includes('/storyboards/');

  return (
    <Banner
      kind={'alert'}
      isLuxe
      hideIcon
      className="no-padding-y h-banner"
      customWidth
      message={
        <div className="relative flex items-center justify-start flex-auto w-full">
          <a
            className="flex items-center pl-2 pr-2.5 pt-0 pb-0.5 leading-tight rounded-lg bg-white/50 border-white border-2 text-black/70 cursor-pointer"
            href="/billing"
          >
            <CalendarIcon className="w-4 opacity-50" />
            <span className="ml-1.5 font-semibold text-sm">{`Free Trial`}</span>
          </a>
          <div className="ml-3 mr-2">{`${daysRemaining} days left to experience premium features`}</div>

          <div
            className={classNames(
              'flex justify-end flex-auto',
              smallPadding ? 'pr-2' : 'pr-7',
            )}
          >
            <Button
              size="xs"
              type={`fancy`}
              onClick={() => {
                Track.event.defer('banner_click', {
                  category: 'Checkout',
                });
                FlyoverActions.open.defer({
                  type: 'inlinePricing',
                });
              }}
            >
              <span className="inline-flex items-center">
                <Gem className="w-3.5 h-3.5 pt-[0.05rem]" />
                <span className="ml-1 pb-[0.1rem]">{`Upgrade`}</span>
              </span>
            </Button>
            {/* <SecondaryLink */}
            {/*   textColor="text-brand-pink" */}
            {/*   decorationColor="decoration-decorative-pink" */}
            {/*   onClick={() => { */}
            {/*     Track.event.defer('banner_click', { */}
            {/*       category: 'Checkout', */}
            {/*     }); */}
            {/*     FlyoverActions.open.defer({ */}
            {/*       type: 'inlinePricing', */}
            {/*     }); */}
            {/*   }} */}
            {/* > */}
            {/*   {`Compare Plans`} */}
            {/* </SecondaryLink> */}
          </div>
        </div>
      }
    />
  );
};
