module.exports = {
  background: '#F9F9F5',
  waveform: '#CECDCA', // border-mid
  waveformAccent: '#8F8F8D',
  ruler: '#E9E9E7',
  timeText: '#7B7B7B', // Type/primary
  endTimeText: '#121212', // Type/subdued

  waveformPointer: '#000000',
  waveformFrame: '#979797', // light-grey
  waveformText: '#000000',
  scrubberDot: '#CECDCA', // dark-grey
  textClass: 'grey',
  frameIndexClass: 'white',
  buttonPreset: 'dark',
}
