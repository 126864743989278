/**
 * @prettier
 */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const FrameEditorContainer = require('../frame_editor/Container.jsx');
const Container = require('../shared/Container').default;
const { ErrorBoundary } = require('../shared/ErrorBoundary');
const Player = require('../player/Container');
const { IFPermission } = require('../../helpers/has-permission');
const logger = require('../../helpers/logger').default;
const navigateToRoute = require('../../helpers/router/navigate-to-route');
const { isInInput } = require('../../helpers/isInInput');
const { clampNumber } = require('../../helpers/clampNumber');
const { PlayerActions } = require('../../flux/actions/player');
const {
  LoadingIndicator,
} = require('blackbird/components/common/loading-indicator/LoadingIndicator');
const {
  onLoadAfterBackButton,
} = require('../../helpers/onLoadAfterBackButton');

const { tourEvents, ToursActions } = require('javascripts/flux/actions/tours');

require('../../flux/stores/frame_focus');

// I guess this _should_ be a proper component with hooks that set the
// isFetching stuff, but ¯\_(ツ)_/¯
let isFetching = false;
const Loader = (predicate, Component) => {
  function LoaderComponent(props) {
    if (!isFetching && props.fetch) {
      isFetching = true;
      props.fetch();
    }

    return predicate(props) ? (
      <Component {...props} />
    ) : (
      <LoadingIndicator fill text="Loading your storyboard…" />
    );
  }

  if (Component.propTypes) LoaderComponent.propTypes = Component.propTypes;
  return LoaderComponent;
};

const requestDenied = (err) =>
  logger.log('Request for frame navigation or closing denied:', err);

module.exports = Container(
  {
    storyboard: (data) => data.storyboard,
    frameStore: (data) => data,
    frameFocus: (data) => data,
  },
  (storeData, ownProps) => ({
    fetch: () =>
      FrameFocusActions.init.defer({
        mode: ownProps.mode,
        frameIndex: ownProps.frameIndex,
      }),
    setFrameIndex: FrameFocusActions.setFrameIndex,
    setFrame: FrameFocusActions.setFrame,
    handleClose: FrameFocusActions.close.defer,
    toggleSidebar: (/* event */) => FrameFocusActions.toggleSidebar(),
    setMode: FrameFocusActions.setMode,
  }),
)(
  Loader(
    // Predicate
    (props) =>
      !!props.frameStore.frames &&
      props.frameStore.frames.length > 0 &&
      !!props.frameFocus.mode &&
      !!props.frameFocus.activeFrame,
    createReactClass({
      displayName: 'FrameFocusFlyover',

      propTypes: {
        // The initial mode on start, should not be used except for in the
        // container component
        mode: PropTypes.string.isRequired,
        frameStore: PropTypes.shape({
          frames: PropTypes.oneOfType([PropTypes.array, PropTypes.bool])
            .isRequired,
          is_saving: PropTypes.bool.isRequired,
        }),
        frameFocus: PropTypes.shape({
          mode: PropTypes.string,
          activeIndex: PropTypes.number.isRequired,
          hasSidebar: PropTypes.bool.isRequired,
          activeFrame: PropTypes.object,
        }).isRequired,
        storyboard: PropTypes.object, // eslint-disable-line
        fetch: PropTypes.func.isRequired, // eslint-disable-line
        setFrameIndex: PropTypes.func.isRequired,
        setFrame: PropTypes.func.isRequired,
        toggleSidebar: PropTypes.func.isRequired,
        handleClose: PropTypes.func.isRequired,
        setMode: PropTypes.func.isRequired,
      },

      componentDidMount() {
        IntercomActions.enterContext.defer('frameFocus');
        document.addEventListener('keydown', this.handleKeyPress);
        isFetching = false;
        onLoadAfterBackButton(StoryboardActions.reload.defer);
      },

      componentWillUnmount() {
        this.props.handleClose();
        if (this.props.mode === 'player') PlayerActions.close.defer();
        IntercomActions.leaveContext.defer('frameFocus');
        document.removeEventListener('keydown', this.handleKeyPress);
        isFetching = false;
      },

      handleSetFrameIndex(requestIndex) {
        const newIndex = clampNumber(
          requestIndex,
          0,
          this.props.frameStore.frames.length - 1,
        );

        this.mainRef
          .requestNavigate()
          .then(
            () => this.props.setFrameIndex({ index: newIndex }),
            requestDenied,
          );
      },

      handleSetFrame(newFrame) {
        this.mainRef
          .requestNavigate()
          .then(() => this.props.setFrame({ frame: newFrame }), requestDenied);
      },

      handleSetMode(newMode) {
        this.mainRef
          .requestNavigate()
          .then(() => this.props.setMode(newMode), requestDenied);
      },

      handleKeyPress: function (e) {
        if (isInInput(e.target)) return;

        // press right arrow for next, or ⌘ + right for end
        if (e.key === 'ArrowRight') {
          if (e.metaKey) {
            this.handleSetFrameIndex(this.props.frameStore.frames.length - 1);
          } else {
            this.handleSetFrameIndex(this.props.frameFocus.activeIndex + 1);
          }
          e.preventDefault();
        }

        // press left arrow for previous, or ⌘ + left for start
        if (e.key === 'ArrowLeft') {
          if (e.metaKey) {
            this.handleSetFrameIndex(0);
          } else {
            this.handleSetFrameIndex(this.props.frameFocus.activeIndex - 1);
          }
          e.preventDefault();
        }

        if (e.key === 'Escape') {
          this.handleClose();
        }
      },

      getActiveIndex(props) {
        return props.frameStore.frames.indexOf(props.frame);
      },

      handleClose() {
        const slug = this.props.storyboard.slug;
        const close = () => navigateToRoute('storyboard.show', { slug });
        ToursActions.triggerEvent.defer(tourEvents.saveAndCloseFrameEditor);

        if (this.mainRef && this.mainRef.requestClose) {
          const response = this.mainRef.requestClose();
          if (typeof response.then !== 'undefined') {
            // Ignore rejections, it just means we can't close it right now.
            response.then(close, () => {}, requestDenied);
          } else {
            close();
          }
        } else {
          close();
        }
      },

      setRef(ref) {
        this.mainRef = ref;

        if (
          this.mainRef &&
          (!this.mainRef.requestClose || !this.mainRef.requestNavigate)
        ) {
          logger.warn(
            'requestClose and requestNavigate must be defined on the main component',
          );
        }
      },

      render() {
        const mode = this.props.frameFocus.mode;

        return (
          <div className={'w-full h-full bg-surface'}>
            <ErrorBoundary>
              {mode === 'player' ? (
                <IFPermission
                  storyboardOwnerId={
                    this.props.storyboard.project.owner.user_id
                  }
                  featureName="animatics"
                >
                  <Player
                    componentRef={this.setRef}
                    onSetIndex={this.handleSetFrameIndex}
                    onSetFrame={this.handleSetFrame}
                    onTriggerClose={this.handleClose}
                    hasSidebar={this.props.frameFocus.hasSidebar || false}
                    toggleSidebar={this.props.toggleSidebar}
                    onSetFocusMode={this.handleSetMode}
                    framesAreSaving={this.props.frameStore.is_saving}
                    canManage={true}
                    theme="dark"
                  />
                </IFPermission>
              ) : mode === 'frameEditor' ? (
                <FrameEditorContainer
                  componentRef={this.setRef}
                  onTriggerClose={this.handleClose}
                  onSetIndex={this.handleSetFrameIndex}
                  hasSidebar={this.props.frameFocus.hasSidebar || false}
                  onSetFocusMode={this.handleSetMode}
                />
              ) : null}
            </ErrorBoundary>
          </div>
        );
      },
    }),
  ),
);
