const { default: Dialog } = require('blackbird/components/dialog/Dialog');
const { default: Select } = require('blackbird/components/form/select/Select');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const { withTranslation } = require('react-i18next');
const { Button } = require('../shared/Button');
const btnProps = {
  form:'move-storyboard',
  htmlType:"submit"
}
const MoveStoryboardForm = createReactClass({

  propTypes: {
    projects: PropTypes.array.isRequired,
    destinationId:PropTypes.number
  },

  componentDidMount: function() {
    if(!this.props.destinationId)
    StoryboardMobilityActions.setDestinationProjectId(this.props.projects[0].id);
  },

  _setNewProjectId: function(value) {
    StoryboardMobilityActions.setDestinationProjectId(value);
  },

  _onFormSubmit: function(e) {
    e.preventDefault();
    StoryboardMobilityActions.move();
  },
  handleCancel:function(){
    FlyoverActions.close();
  },
  render: function() {
    const {t} = this.props;
    const options = this.props.projects.map((project)=> ({
      label:project.name,
      value:project.id
    }));
    return (
      <Dialog
        isOpen
        hideCancel
        isDark
        confirmText={t('modals.move_storyboard.button')}
        onCancel={this.handleCancel}
        confirmBtnProps={btnProps}
        title={t('modals.move_storyboard.title')}
      >
        <form noValidate="novalidate" id="move-storyboard" onSubmit={this._onFormSubmit}>
            <Select
              disableInput
              selectContainerClassName='w-full'
              options={options}
              onChange={this._setNewProjectId}
              value={this.props.destinationId}
            />
        </form>
      </Dialog>
    );
  }

});
window.MoveStoryboardForm = withTranslation()(MoveStoryboardForm)
