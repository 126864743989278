/** @prettier */
/**
 * List of fonts to include in the font picker. This is the 50 most popular
 * fonts per november 2023, plus those we supported before that, plus DM Sans
 * (on request of the Animade folx).
 *
 * They might still be filtered depending on ttf and charset support
 */
export default [
  // Top 50
  'Roboto',
  'Open Sans',
  'Noto Sans JP',
  'Source Sans Pro', // This one seems to be phased out
  'Source Sans 3',
  'Lato',
  'Montserrat',
  'Roboto Condensed',
  'Oswald',
  'Roboto Mono',
  'Poppins',
  'Raleway',
  'Noto Sans',
  'PT Sans',
  'Roboto Slab',
  'Merriweather',
  'Ubuntu',
  'Playfair Display',
  'Nunito',
  'Lora',
  'PT Serif',
  'Work Sans',
  'Noto Sans TC',
  'Rubik',
  'Noto Serif',
  'Noto Sans KR',
  'Fira Sans',
  'Bebas Neue',
  'Mukta',
  'Teko',
  'Titillium Web',
  'Quicksand',
  'Nunito Sans',
  'Nanum Gothic',
  'Hind Siliguri',
  'Staatliches',
  'Heebo',
  'Yanone Kaffeesatz',
  'Karla',
  'Source Code Pro',
  'Anton',
  'Inconsolata',
  'Oxygen',
  'Arimo',
  'PT Sans Narrow',
  'Dosis',
  'Barlow',
  'Josefin Sans',
  'Libre Baskerville',
  'Crimson Text',
  'Bitter',
  'Maven Pro',
  'Urbanist',
  'Roboto',
  'Inter',
  'Kanit',
  'Mulish',
  'IBM Plex Sans',
  'Manrope',
  'Libre Franklin',

  // Manual additions
  'DM Sans',
];
