/** @prettier */

import React, { ChangeEventHandler, CSSProperties, FC } from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid/non-secure';

interface CheckboxProps {
  className?: string;
  disabled?: boolean;
  label?: React.ReactNode;
  value?: boolean | null;
  smallLabel?: boolean;
  id?: string;
  htmlValue?: string;
  controlled?: boolean;
  error?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  [x: string]: any;
}
const inputStyle: CSSProperties = {
  clipPath: 'polygon(0 0)',
  position: 'absolute',
};

const Checkbox: FC<CheckboxProps> = React.forwardRef<
  HTMLInputElement,
  CheckboxProps
>((props, ref) => {
  const {
    className,
    disabled,
    label,
    smallLabel,
    value: propsValue = false,
    controlled = true,
    htmlValue,
    error,
    onChange,
    ...restProps
  } = props;

  const [value, setValue] = React.useState(controlled ? propsValue : undefined);
  const normalisedValue = !!value;
  const [hasFocus, setHasFocus] = React.useState(false);
  const labelRef = React.useRef<HTMLLabelElement | null>(null);
  const [id] = React.useState(props.id ?? nanoid(10));

  const handleClick = () => {
    if (disabled) return;
    // On clicking the span , we will click the label
    // which will in turn trigger the handleChange
    labelRef.current?.click();
  };

  const handleFocus = React.useCallback<React.FormEventHandler>(
    (e) => setHasFocus(e.type === 'focus'),
    [setHasFocus],
  );

  const handleChange = (e) => {
    if (disabled) return;
    onChange?.(e);
    setValue(e.currentTarget.checked);
  };

  React.useEffect(() => {
    setValue(controlled ? propsValue : undefined);
  }, [controlled, propsValue, setValue]);

  const renderTick = () => (
    <svg
      className="absolute inline-block"
      width="10"
      height="8"
      viewBox="0 0 10 8"
    >
      <path
        d="M3.60212 7.76437L0 4.16224L1.36649 2.79575L3.60212 5.03139L8.63351 0L10 1.36649L3.60212 7.76437Z"
        fill="white"
      />
    </svg>
  );

  const renderMinus = () => (
    <svg
      className="absolute inline-block"
      width="8"
      height="2"
      viewBox="0 0 8 2"
    >
      <rect width="8" height="2" fill="white" />
    </svg>
  );

  return (
    <div>
      <div
        className={classNames(
          'inline-flex items-center group h-6 cursor-default relative',
          { 'cursor-not-allowed': disabled },
          className,
        )}
      >
        <input
          ref={ref}
          tabIndex={disabled ? -1 : 0}
          type="checkbox"
          checked={controlled ? normalisedValue : undefined}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleFocus}
          value={htmlValue}
          {...restProps}
          id={id}
          style={inputStyle}
        />

        <span
          onClick={handleClick}
          className={classNames(
            'box-border inline-flex w-4 h-4 rounded border-2 items-center justify-center',
            {
              'bg-black border-border-dark group-hover:bg-icon-hover':
                !disabled && value !== false && value !== undefined && !error,
              'bg-transparent border-border-dark':
                !disabled && value === false && !error,
              'group-hover:border-icon-hover': !disabled,
              'border-action-primary-disabled': disabled,
              'bg-action-primary-disabled': disabled && value !== false,
              'border-form-error': error,
              'outline-blue': hasFocus,
            },
          )}
        >
          {value === true && renderTick()}

          {value === null && renderMinus()}
        </span>

        {Boolean(label) && (
          <label
            ref={labelRef}
            htmlFor={id}
            className={classNames(
              'cursor-default',
              {
                'text-action-primary-disabled cursor-not-allowed': disabled,
              },
              smallLabel ? 'block -mt-0.5 text-xs ml-2' : 'ml-3.5 ',
            )}
          >
            {label}
          </label>
        )}
      </div>
      {error && <div className="text-form-error text-sm mt-1.5">{error}</div>}
    </div>
  );
});
Checkbox.displayName = 'Checkbox';
export default Checkbox;
