/** @prettier */
import { ConditionalTooltip } from 'blackbird/components/feedback/tooltip/Tooltip';
import { TeamUpgradePill } from 'blackbird/components/team/TeamUpgradePill';
import Toggle from 'blackbird/components/toggle/Toggle';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';
import type { IStoryboardPreferences } from 'javascripts/types/storyboard';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import type { LiteralUnion } from 'type-fest';
import {
  createPermissionFlyoverOpener,
  usePermission,
} from '../../../helpers/has-permission';
import { useStore } from '../../../helpers/useStore';
import PanelbarTitle from '../PanelbarTitle';

interface ItemProps {
  label: string;
  help?: string;
  preferenceKey: LiteralUnion<keyof IStoryboardPreferences, string>;
  /** override the value */
  value?: unknown;
  onToggle?: () => void;
  isSaving: boolean;
}

const PanelbarCommentSettingsItem: React.FC<ItemProps> = (item) => {
  const storeValue = useStore(
    'StoryboardStore',
    (store) => store.storyboard.preferences[item.preferenceKey],
  );

  const togglePreference = (fieldName: keyof IStoryboardPreferences) => {
    StoryboardActions.updatePreference({
      name: fieldName,
      value: !storeValue,
    });
  };

  return (
    <div className="text-sm flex items-center justify-between">
      <div className="flex relative items-center">
        {item.label}
        {item.help && <HelpPopup text={item.help} />}
      </div>
      <Toggle
        className="absolute"
        onChange={
          item.onToggle ?? (() => togglePreference(item.preferenceKey as any))
        }
        value={item.value ?? storeValue}
        disabled={item.isSaving}
      />
    </div>
  );
};

const PanelbarCommentsSettingsContainer: React.FC = () => {
  const { t } = useTranslation();
  const storyboard = useStore('StoryboardStore', (store) => store.storyboard);

  const has_comments_enabled = useStore(
    'StoryboardStore',
    (store) => store.storyboard.has_comments_enabled,
  );

  const is_sending_comment_emails = useStore(
    'StoryboardStore',
    (store) => store.storyboard.is_sending_comment_emails,
  );

  const handleUpgradeClick = (showUpgradeFlyover) => () => {
    if (showUpgradeFlyover)
      createPermissionFlyoverOpener(BoordsConfig.Uid, 'comments')();
  };

  const hasCommentsPermission = usePermission(
    storyboard.project.owner.user_id,
    'comments',
  );

  return (
    <>
      <PanelbarTitle
        title={t('sidebar.settings.comments.title')}
        subtitle={t('sidebar.settings.comments.subtitle')}
      />
      <div className="space-y-6 mt-6 px-6">
        <div className="text-sm pb3 flex items-center justify-between">
          {t('sidebar.settings.comments.enable_comments')}

          {BoordsConfig.HasV3 && !hasCommentsPermission ? (
            <TeamUpgradePill />
          ) : (
            <ConditionalTooltip
              as="div"
              title="Upgrade required"
              disabled={hasCommentsPermission === true}
            >
              <div onClick={handleUpgradeClick(hasCommentsPermission !== true)}>
                <Toggle
                  onChange={() => {
                    StoryboardActions.update({
                      name: 'has_comments_enabled',
                      value: !has_comments_enabled,
                    });
                    StoryboardActions.save.defer();
                  }}
                  value={storyboard.has_comments_enabled}
                  disabled={
                    !!storyboard.is_saving || hasCommentsPermission !== true
                  }
                />
              </div>
            </ConditionalTooltip>
          )}
        </div>

        {storyboard.has_comments_enabled && (
          <>
            <PanelbarCommentSettingsItem
              label={t('sidebar.settings.comments.hide_completed_editing')}
              help={`Hide completed comments for everyone looking at the Edit view of this storyboard`}
              preferenceKey={'hide_completed_comments_edit'}
              isSaving={!!storyboard.is_saving}
            />
            <PanelbarCommentSettingsItem
              label={t('sidebar.settings.comments.show_comment_badge')}
              preferenceKey={'show_comment_badge'}
              isSaving={!!storyboard.is_saving}
            />
            <PanelbarCommentSettingsItem
              label={t('sidebar.settings.comments.hide_completed_presenting')}
              help={
                'Hide completed comments for everyone looking at the Presentation view of this storyboard'
              }
              preferenceKey={'hide_completed_comments_present'}
              isSaving={!!storyboard.is_saving}
            />
            <PanelbarCommentSettingsItem
              label={t('sidebar.settings.comments.send_notifications')}
              help={'Notify team members of new comments on this storyboard'}
              preferenceKey={'is_sending_comment_emails' as string}
              value={is_sending_comment_emails}
              onToggle={() => {
                StoryboardActions.update({
                  name: 'is_sending_comment_emails',
                  value: !is_sending_comment_emails,
                });
                StoryboardActions.save.defer();
              }}
              isSaving={!!storyboard.is_saving}
            />

            {storyboard.is_sending_comment_emails && (
              <PanelbarCommentsUserNotifications
                storyboard_id={storyboard.versions[0].id}
                is_enabled={storyboard.is_sending_comment_emails}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PanelbarCommentsSettingsContainer;
