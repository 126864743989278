/** @format */

import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { QuizContext } from './QuizContext';
import i18n from 'i18next';
import type {
  LocalPlanData,
  Price,
} from 'blackbird/helpers/contexts/BillingContext';
import { QuizPlan } from './QuizPlan';

export const QuizPricing: React.FC = () => {
  const { isTeam } = useContext(QuizContext);

  const initLocalPlans = i18n.t('billing:plans', {
    returnObjects: true,
  }) as LocalPlanData[];

  const [billingInterval] = useState<string>('month');
  const [plans, setPlans] = useState<LocalPlanData[] | undefined>(undefined);

  useEffect(() => {
    const filteredPlans = initLocalPlans.filter((p) =>
      ['free', 'individual', 'group', 'agency', 'powerhouse'].includes(p.slug),
    );

    // filteredPlans.reverse();

    setPlans(filteredPlans);
  }, []);

  return (
    <div className="flex justify-center w-full px-2 my-20 xl:px-0">
      {plans && (
        <div>
          <div className="relative grid grid-cols-4 gap-4 xl:gap-8">
            {plans
              .filter((p) => {
                return [`free`, `individual`, `group`, `agency`].includes(
                  p.slug,
                );
              })
              .map((plan) => {
                const price: Price | undefined = plan.prices.find(
                  (price) =>
                    price.currency === BoordsConfig.Currency &&
                    price.interval === billingInterval,
                );

                return !price ? null : (
                  <QuizPlan
                    featured={
                      (plan.slug === 'individual' && !isTeam) ||
                      (plan.slug === 'group' && isTeam)
                    }
                    key={plan.slug}
                    plan={plan}
                    price={price}
                    billingInterval={billingInterval}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};
