/** @format */
import type { DetailedFrame } from 'javascripts/types/frame';
import type { Primitive } from 'type-fest';
import { has, isObject, isString, isUndefined, mapObject } from 'underscore';

export const toJSONIfObject = (value: unknown, fallback?: unknown) =>
  !isUndefined(value) && isObject(value)
    ? JSON.stringify(value)
    : fallback ?? value;

/** Overview of JSON properties to convert to/from JSON, and which fallback
 * value to use */
const JSONProperties: Record<keyof DetailedFrame, Primitive> = {
  field_data: null,
};

/** Converts some properties parameters to JSON fields if necessary to save to
 * the server */
export const frameDataToJSON = (frame: Partial<DetailedFrame>) =>
  mapObject(frame, (value, fieldName) => {
    const fallback = JSONProperties[fieldName];
    return has(JSONProperties, fieldName)
      ? toJSONIfObject(value, fallback)
      : value;
  });

export const frameToJSON = (frame: Partial<DetailedFrame>) =>
  JSON.stringify(frameDataToJSON(frame));

/** Returns a new frame object with JSON properties converted to objects */
export const deserializeJSONFields = (frame: Partial<DetailedFrame>) =>
  mapObject(frame, (value, fieldName) => {
    return has(JSONProperties, fieldName) && isString(value)
      ? JSON.parse(value)
      : value;
  });
