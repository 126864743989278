/** @format */

import { SubdomainProvider } from 'blackbird/helpers/contexts/SubdomainContext';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import { IFPermissionPanel } from 'javascripts/helpers/has-permission';
import React from 'react';
import { SubdomainForm } from './Subdomain';

interface SubdomainContainerProps {
  team_id: number;
  teamAdminId: number;
}

export const SubdomainContainer: React.FC<SubdomainContainerProps> = ({
  team_id,
  teamAdminId,
}): React.ReactElement => {
  return (
    <DefaultStoreContextProvider>
      <IFPermissionPanel
        storyboardOwnerId={teamAdminId}
        featureName="subdomain"
        align="left"
      >
        <SubdomainProvider team_id={team_id}>
          <SubdomainForm />
        </SubdomainProvider>
      </IFPermissionPanel>
    </DefaultStoreContextProvider>
  );
};

(window as any).SubdomainContainer = SubdomainContainer;
