import { idleTimeout } from "../helpers/idle-timeout";

/** @prettier */
type listenerFunction<T> = (event: T) => void;

/**
 * Monitors a thing, and passes an event up to a parent component.
 * e.g. see if the connection is active, and show a banner if it's not
 *
 * `T` is the type of event that this monitor will return
 */
export class Monitor<T> {
  notifyListeners: listenerFunction<T>;

  constructor(listener: listenerFunction<T>) {
    this.notifyListeners = listener;
    this.init();
  }

  /**
   * Wait with executing this function until CPU is idle, to prevent
   * getting in the way of early-page requests. Could potentially add some queue
   * functionality here to manage requests over different Monitor instances
   */
  fetchWhenIdle = (func: () => void, minDelay = 2000, maxDelay = 5000) => {
    return idleTimeout(func, minDelay, maxDelay)
  }

  init() {}
}
