/** @prettier */
import type { supportedFormats, supportedLayouts } from './types';
import type { AspectRatioName } from 'javascripts/types/storyboard';
import { getAspectRatioNumerical } from 'javascripts/helpers/frame-size-helper';
import * as fitLib from 'fit.js';

/**
 * This is half of full HD, but its measurements are close enough to the A4
 * dimensions, so we don't have to increase the dimensions of everything too
 * much.
 */
export const widescreenPageSize = [960, 540];
const a4PageSize = [842, 595];

const defaults = {
  fontSize: 8,
  footerFontsize: 14,
  coverFontSize: 20,
  margin: {
    top: 30,
    right: 20,
    bottom: 15,
    left: 20,
  },
  /** distance between footer and main content */
  footerMargin: {
    top: 20,
    side: 20,
  },
  frameWidth: 245,
  rowSpacing: 15, // Between frame rows
  gutter: 20, // Between frame columns
  lineHeightFactor: 1.4,
  coverLogoMaxWidth: 200,
  footerLogoMaxWidth: 100,
  footerLogoMaxHeight: 30,
};

export type PDFLayoutMetrics = typeof defaults;
type OptionalOverrides = Partial<PDFLayoutMetrics>;

const gridLike: Record<
  supportedLayouts,
  | {
      rows: number;
      columns: number;
      gutter?: number;
    }
  | undefined
> = {
  '4grid': {
    rows: 2,
    columns: 2,
  },
  '6grid': {
    rows: 2,
    columns: 3,
  },
  '8grid': {
    rows: 2,
    columns: 4,
  },
  '3up': {
    rows: 1,
    columns: 3,
  },
  '2grid': {
    rows: 1,
    columns: 2,
    gutter: defaults.gutter * 2,
  },
  '1up': {
    rows: 1,
    columns: 1,
  },
  '1up2Col': {
    rows: 1,
    columns: 2,
    gutter: defaults.gutter * 1.5,
  },
  list: {
    rows: 4,
    columns: 2,
    gutter: defaults.gutter * 0.5,
  },
};

const getGridLikeDimensions = (
  totalDimensions: [number, number],
  layout: supportedLayouts,
  aspectRatioName: AspectRatioName,
  hasFooter: boolean,
): OptionalOverrides => {
  const ratio = getAspectRatioNumerical(aspectRatioName);
  const ratioSofter = (ratio + 1) / 2;
  const layoutInfo = gridLike[layout];
  const pageResizeRatio = a4PageSize.map((v, i) => v / totalDimensions[i]);

  if (!layoutInfo)
    throw new Error(
      'layout ' + layout + ' is not defined in gridLike reference',
    );

  const footerHeight = hasFooter ? 15 + defaults.footerMargin.top : 0;

  const { rows, columns } = layoutInfo;
  const margin = {
    ...defaults.margin,
  };

  const availableSpace = [
    totalDimensions[0] - margin.left - margin.right,
    totalDimensions[1] - margin.top - margin.bottom - footerHeight,
  ];

  // Calculate a likely height for each frame
  const averageFrameFields = 3;
  const itemMargin = 7.5; // This is a hardcoded value in createPDFGridItem
  const fieldMarginToCalculate = (averageFrameFields - 1) * itemMargin;
  // This number tries to find a way of "guessing" the height of a note field.
  // The amount of columns will indicate how wide text can render, so the more
  // columns, the higher the text
  const averageNoteHeight = (11 * columns) / ratioSofter;
  const averageNotesHeight =
    averageNoteHeight * averageFrameFields +
    fieldMarginToCalculate +
    itemMargin;

  const guttersRequired = columns - 1;
  const gutter = layoutInfo.gutter ?? defaults.gutter;

  const minumumRowSpacing = 15;
  const rowSpacing = minumumRowSpacing * ratio * pageResizeRatio[1];
  const rowSpacingRequired = rows - 1;

  const availableWidthForImages = availableSpace[0] - gutter * guttersRequired;

  const reserveVerticalSpaceForNotes =
    layout === 'list' ? 0 : rows * averageNotesHeight;

  const maxHeightForImage =
    (availableSpace[1] -
      reserveVerticalSpaceForNotes -
      rowSpacingRequired * rowSpacing) /
    rows;

  const imageSize = fitLib(
    { width: maxHeightForImage * ratio, height: maxHeightForImage },
    {
      width: availableWidthForImages / columns,
      height: maxHeightForImage,
    },
  );

  const horizontalSpaceUsed =
    imageSize.width * columns + gutter * guttersRequired;
  const horizontalSpaceAvailable = availableSpace[0] - horizontalSpaceUsed;

  const verticalSpaceUsed =
    (imageSize.height + averageNotesHeight * 1.2) * rows +
    rowSpacing * rowSpacingRequired;
  const verticalSpaceAvailable = availableSpace[1] - verticalSpaceUsed;

  const addToMarginsX = horizontalSpaceAvailable / 2;
  const addToMarginsY = verticalSpaceAvailable / 2;

  if (addToMarginsX > 0 && layout !== 'list') {
    margin.left += addToMarginsX;
    margin.right += addToMarginsX;
  }

  if (addToMarginsY > 0 && layout !== 'list') {
    // We don't want to compensate completely, because text might still be
    // longer than expected
    margin.top += addToMarginsY * 0.66;
  }

  return {
    gutter,
    frameWidth: imageSize.width,
    rowSpacing,
    margin,
  };
};

export const getPDFLayoutMetrics = (
  paperFormat: supportedFormats,
  layout: supportedLayouts,
  aspectRatioName: AspectRatioName,
  hasFooter: boolean,
): typeof defaults => {
  const override: OptionalOverrides = {};

  let availableSpace = (
    paperFormat === 'widescreen' ? widescreenPageSize : a4PageSize
  ) as [number, number];

  if (layout === 'list') {
    // We need to clone this, otherwise we mutate objects unintentionally
    availableSpace = [...availableSpace].reverse() as [number, number];
  }

  if (gridLike[layout]) {
    Object.assign(
      override,
      getGridLikeDimensions(availableSpace, layout, aspectRatioName, hasFooter),
    );
  }

  return { ...defaults, ...override };
};

/**
 * You probably want to use layout metrics for a specific format, so you should
 * use `getPDFlayoutMetrics` instead. However _this_ function does return the
 * overall defaults */
export const getDefaultLayoutMetrics = () => defaults;
