/** @format */
import { ajaxRuby } from 'javascripts/helpers/ajax';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import type { UserResponse } from 'javascripts/types/user';
import { ArchiveActions } from '../actions/archive';
import { RequestActions } from '../actions/request';
import { UserActions } from '../actions/user';
import { FakeAltStoreClass } from './AltStore';
import type { Project } from './dashboard';

const errorHandler = RequestErrorHandler('Archive');

export interface ArchivedStoryboard {
  id: number;
  document_name: string;
  slug: string;
  deleted_at: string;
  project_name: string;
  project_id: number;
}

export interface ArchiveServerResponse {
  projects: Project[];
  storyboards: ArchivedStoryboard[];
}

export class ArchiveStore extends FakeAltStoreClass<ArchiveStore> {
  fetched = false;
  archive?: ArchiveServerResponse;
  user?: UserResponse;

  constructor() {
    super();
    this.bindListeners({
      handleFetch: [ArchiveActions.FETCH, ArchiveActions.PROJECT_RESTORED],
      handleReceive: UserActions.RECEIVE,
      handleRestoreProject: ArchiveActions.RESTORE_PROJECT,
    });
  }

  handleReceive(user) {
    this.user = user;
  }

  handleFetch() {
    this.fetched = false;
    ajaxRuby({
      method: 'get',
      url: '/archive.json',
      success: (response) => {
        this.fetched = true;
        this.archive = response;
        this.emitChange();
      },
    });
    if (!this.user) UserActions.fetch.defer();
  }

  handleRestoreProject(project) {
    ajaxRuby({
      method: 'post',
      url: '/projects/' + project.slug + '/restore.json',
      success: () => {
        ArchiveActions.projectRestored();
        RequestActions.success.defer({ key: 'modals.archive.restored' });
      },
      error: errorHandler(),
    });
  }
}

(window as any).ArchiveStore = alt.createStore(ArchiveStore, 'ArchiveStore');
