/** @format */
import * as React from 'react';
import i18n from 'i18next';
import { HeartIcon } from '@heroicons/react/20/solid';
import { Feature, FeatureInline } from '../../features/Feature';

import classNames from 'classnames';
import Button from 'blackbird/components/button/Button';
import { OfferContext } from '../OfferContext';
import { LineItem } from 'javascripts/components/payment/PaymentInvoiceLineItem';

import { AlarmClock } from 'lucide-react';

import {
  formatCurrency,
  formatNumber,
} from 'javascripts/helpers/currencyHelper';
import { SaleTimer } from 'javascripts/components/shared/CountdownTimer';
import { Lock, Images, UserRound, UsersRound } from 'lucide-react';

export const DefaultBannerTitle: React.FC = () => {
  const { offerExpiryDate, currency } = React.useContext(OfferContext);
  return !offerExpiryDate ? null : (
    <>
      <div className="flex items-center pl-3 pr-1.5 pt-1 pb-1.5 leading-tight font-semibold rounded-md bg-brand-yellow/70 -ml-2">
        <span className="text-black/70">
          <AlarmClock size={16} />
        </span>
        <span className="ml-1.5 mr-1 text-black/70">{`One-Time Offer`}</span>
      </div>

      <div className="ml-3 mr-3 font-semibold space-x-1.5">
        <span>{`Get 1,000 AI credits for ${formatCurrency({
          amount: 0,
          currency,
          showZeros: false,
        })}`}</span>
        <span>{`→`}</span>
      </div>
    </>
  );
};

export const DefaultBannerButton: React.FC = () => {
  const { offerExpiryDate, referencePlan, addOnProduct, offerType } =
    React.useContext(OfferContext);

  return !offerExpiryDate || !referencePlan || !addOnProduct ? null : (
    <Button
      type="pink"
      size="sm"
      rounded
      className="relative !pr-1 -mt-[0.1rem]"
      onClick={() => {
        Track.event.defer('banner_click', {
          category: 'Checkout',
          variant: offerType,
        });
        FlyoverActions.open.defer({ type: 'upgrade' });
      }}
    >
      <div className="absolute top-0 bottom-0 border-r left-[7.5rem] border-white/20" />
      <span className="">
        <span className="mr-4 ">{`Claim Discount`}</span>
        <span className="!text-left font-normal">
          <SaleTimer endDate={offerExpiryDate} />
        </span>
      </span>
    </Button>
  );
};

export const DefaultFlyoverPreheader: React.FC = () => {
  const { offerExpiryDate } = React.useContext(OfferContext);
  return (
    <div className="bg-brand-blue-25">
      <div className="flex items-center justify-center px-8 py-2 font-semibold bg-brand-blue-25 text-brand-blue">
        <span className="pt-[0.1rem]">
          <AlarmClock size={16} />
        </span>
        <div className="mx-1 text-sm">{`One-time offer expiring in: `}</div>
        <span className="flex-shrink-0 w-[2.5rem] text-left !text-sm">
          {offerExpiryDate ? (
            <SaleTimer endDate={offerExpiryDate} />
          ) : (
            <>{`0:00`}</>
          )}
        </span>
      </div>
      <div
        className={classNames(
          'px-6 pb-3 text-center text-sm hidden tall:block ',
        )}
      >
        <div className={classNames('text-sm text-type-primary space-x-1 mb-2')}>
          <span className="-ml-1 px-1 font-semibold bg-white/70  pb-0.5">{`Dramatically increase`}</span>
          <span>{`the speed & quality of your storyboarding process or`}</span>
          <span className="px-1 font-semibold text-type-primary bg-white/70 pb-0.5">{`your money back`}</span>
        </div>
      </div>
    </div>
  );
};

export const DefaultFlyoverTitle: React.FC = () => {
  return (
    <div className="mb-3 text-xl font-semibold leading-tight">
      <span>{`AI Storyboarding Bundle`}</span>
    </div>
  );
};

export const DefaultFlyoverSignupCount: React.FC = () => (
  <div className="flex items-center mt-3 text-sm space-x-1">
    <HeartIcon className="w-4 mt-[2px]" />
    <span className="whitespace-nowrap">{`${parseInt(
      i18n.t('signups', { ns: 'stats' }),
    ).toLocaleString(`en-US`)} video professionals`}</span>
    <span className="text-type-primary/30 whitespace-nowrap">{`joined this week`}</span>
  </div>
);

export const DefaultFlyoverPrice: React.FC = () => {
  const { referencePrice, addOnPrice, percentageDiscount, currency } =
    React.useContext(OfferContext);
  return !referencePrice || !addOnPrice || !percentageDiscount ? null : (
    <div className="flex items-baseline mt-2">
      <span className="mr-2 line-through opacity-70 decoration-brand-pink decoration-2">
        {formatCurrency({
          amount: (addOnPrice.amount + referencePrice.amount) / 100,
          currency,
          showZeros: true,
        })}
      </span>
      <span className="text-lg">
        {formatCurrency({
          amount: referencePrice.amount / 100,
          currency,
          showZeros: true,
        })}
      </span>

      <span className="pb-1 ml-2 text-sm font-semibold text-type-green">{`(${percentageDiscount}% Savings)`}</span>
    </div>
  );
};

export const DefaultFlyoverContents: React.FC = () => {
  const { addOnProduct, addOnPrice, referencePlan, referencePrice, currency } =
    React.useContext(OfferContext);
  return !referencePlan ||
    !referencePrice ||
    !addOnProduct ||
    !addOnPrice ? null : (
    <>
      <LineItem
        amount={referencePrice.amount}
        isTotal
        currency={currency}
        showZeros={false}
      >
        <div className="font-normal">
          <div className="flex items-center text-type-primary space-x-1">
            {referencePlan.slug === 'group' ? (
              <>
                <span className="mr-0.5">
                  <UsersRound size={16} />
                </span>
                <span className="font-semibold">{`${referencePlan.name} Plan`}</span>
                <span className="pt-1 text-xs font-normal">{`(Up to 5 users)`}</span>
              </>
            ) : (
              <>
                <span className="mr-0.5">
                  <UserRound size={16} />
                </span>
                <span className="font-semibold">{`${referencePlan.name} Plan`}</span>
              </>
            )}
          </div>

          <div className="mt-1.5 space-y-1.5 !text-type-subdued relative">
            {referencePlan.slug === 'group' && (
              <FeatureInline
                name="team-editing"
                iconColor="text-type-primary"
              />
            )}

            <>
              <FeatureInline
                name="remove-branding"
                iconColor="text-type-primary"
              />
              <div className="hidden tall:block">
                <FeatureInline
                  name="remove-frame-limit"
                  iconColor="text-type-primary"
                />
              </div>
              <div className="hidden tall:block">
                <FeatureInline
                  name="remove-export-limit"
                  iconColor="text-type-primary"
                />
              </div>
              <FeatureInline
                name="storyboard-generator-with-characters"
                iconColor="text-type-primary"
              />
              <FeatureInline
                name="script-generator"
                iconColor="text-type-primary"
              />
            </>
          </div>
        </div>
      </LineItem>

      <div className={classNames('relative h-[5.2rem] mb-4 mt-3')}>
        <div
          className={classNames(
            'bg-surface-light border-border pt-3',
            'px-8 absolute top-0 bottom-0 -left-8 -right-8 border-y ',
          )}
        >
          <div className="flex items-start">
            <div className="flex-auto">
              <div className="">
                <div className="flex items-center mb-1 space-x-1">
                  <span className="mt-[0.2rem] -ml-0.5 pr-0.5">
                    <Images size={16} />
                  </span>
                  <span className="font-semibold">{`AI Credit Pack`}</span>
                </div>
              </div>
            </div>
            <div className="flex items-center flex-shrink-0 text-base ">
              <span className="line-through opacity-70 decoration-brand-pink decoration-2">
                {formatCurrency({
                  amount: addOnPrice.amount / 100,
                  currency,
                  showZeros: true,
                })}
              </span>
              <span className="ml-1 font-semibold">
                {formatCurrency({
                  amount: 0,
                  currency,
                  showZeros: false,
                })}
              </span>
            </div>
          </div>

          <Feature
            tooltip={`Get an extra ${formatNumber(
              addOnProduct.quantity,
            )} AI image generator credits to use anytime.`}
            iconColor="text-type-primary"
            label={
              <span className="text-type-subdued">{`+${formatNumber(
                addOnProduct.quantity,
              )} AI Image Credits`}</span>
            }
          />
        </div>
      </div>
    </>
  );
};

export const DefaultFlyoverButton: React.FC = () => {
  const { referencePlan, addOnProduct, coupon, offerExpiryDate } =
    React.useContext(OfferContext);

  return !referencePlan || !addOnProduct ? null : (
    <>
      <div className="mb-3.5 space-y-2.5 mt-1">
        <div className="flex items-end justify-center space-x-1">
          <div className="text-sm">{`Offer expires in:`}</div>
          <span className="flex-shrink-0 w-[2rem] ml-1 text-left !text-sm text-[#dc2626]">
            {offerExpiryDate ? (
              <SaleTimer endDate={offerExpiryDate} />
            ) : (
              <>{`0:00`}</>
            )}
          </span>
        </div>
        <div className="flex items-center justify-center space-x-1">
          <span className="text-type-subdued pt-0.5">
            <Lock size={14} />
          </span>
          <div className="text-sm text-type-subdued">{`30-day money-back guarantee`}</div>
        </div>
      </div>

      <Button
        size="lg"
        type={`pink`}
        rounded
        className="w-full mt-2 mb-3.5"
        link={`/checkout/${referencePlan.slug}/monthly?coupon=${coupon}&addon=${addOnProduct.slug}`}
      >
        <>{`Claim Discount`}</>
      </Button>
    </>
  );
};
