/** @prettier */
const striptags = require('striptags');
import * as he from 'he';

export const removeHtmlAddNewlines = (str: string) => {
  // Change paragraphs to be newlines
  let input = str.replace(/<\/p><p>/g, '\n\n');
  // Replace breaks
  input = input.replace(/<br><br>/g, '\n');
  // Replace spaces
  input = input.replace(/&nbsp;/g, ' ');
  // Decode HTML entities (note that 'he' does not
  // decode nbsp
  input = he.decode(input);

  return striptags(input);
};
