/**@prettier */
const createReactClass = require('create-react-class');
const ArchiveListItem = require('./ArchiveListItem');
const { ArchiveActions } = require('javascripts/flux/actions/archive');

window.ArchivedProject = createReactClass({
  _restore: function (e) {
    e.preventDefault();
    PermissionActions.verifyStoryboard.defer({
      project_id: this.props.project.id,
      onSuccess: function () {
        ArchiveActions.restoreProject(this.props.project);
      }.bind(this),
    });
  },

  render: function () {
    const buttons = [
      {
        onClick: this._restore,
        label: 'Restore',
        type: 'secondary',
      },
    ];

    return (
      <ArchiveListItem
        title={this.props.project.name}
        subtitle={this.props.project.team_name}
        deleted_at={this.props.project.deleted_at}
        buttons={buttons}
      />
    );
  },
});
