window.PdfFooterLogoActions = alt.createActions(function () {

  this.checkIfProcessing = function(data) {
    this.dispatch(data);
  };

  this.upload = function(data) {
    this.dispatch(data);
  };

  this.restart = function() {
    this.dispatch();
  };

  this.fetch = function(data) {
    this.dispatch(data);
  };

  this.remove = function(data) {
    this.dispatch(data);
  };

  this.restore = function(data) {
    this.dispatch(data);
  };

});

