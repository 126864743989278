const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
window.TeamMembership = createReactClass({

  propTypes: {
    user: PropTypes.object.isRequired,
    membership: PropTypes.object.isRequired,
    allowLeave: PropTypes.bool,
  },

  getDefaultProps: function() {
    return { 
      allowLeave: true,
    }
  },
  
  _leaveTeam: function(e) {
    e.preventDefault();
    if (this.props.allowLeave) {
      if (confirm("Are you sure you want to leave this Team? You'll no longer have access to all this Team's projects and storyboards if you do...")) {
        TeamParticipationActions.leave({
          user_id:        this.props.user.id,
          team_id:        this.props.membership.team_id,
          membership_id:  this.props.membership.membership_id,
        });
      }
    }
  },

  render: function() {

    return (
      <div className="flex items-center justify-center lh-copy bb bw1 b--light-gray pv3">
        <div className="f5 flex-auto">
          <div className={"avenir-heavy"}>{this.props.membership.name}</div>
          <div className={"silver"}>{window.TeamRoleHelper.call(this.props.membership.role)}</div>
        </div>
        {this.props.allowLeave ? (
          <button className="bg-light-gray br2 pv2 ph3 hover-bg-moon-gray bg-animate" onClick={this._leaveTeam}>Leave</button>
        ) : null}
      </div>
    );

  }

});
