const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
window.WhosOnlineContainer = createReactClass({

  propTypes: {
    storyboard_id: PropTypes.number.isRequired,
  },

  getInitialState: function() {
    return WhosOnlineStore.getState();
  },
  
  componentDidMount: function() {
    WhosOnlineStore.listen(this._onChange);
    WhosOnlineActions.init.defer({storyboard_id: this.props.storyboard_id});
  },
  
  componentWillUnmount: function() {
    WhosOnlineActions.unsub({storyboard_id: this.props.storyboard_id});
    WhosOnlineStore.unlisten(this._onChange);
  },
  
  _onChange: function(state) {
    this.setState(state);
  },
  
  render: function() {
    var name;
    if (this.state.members.length == 0) {
      return null;
    } else {
      return (
        <div className="whos-online--container">
        {this.state.members.map(function(m, i) {
          if (m.id == window.BoordsConfig.Uid) {
            name = m.info.name + " (you)";
          } else {
            name = m.info.name + " is viewing";
          }
          return (
            <div className="avatar__whos-online--container" key={"online-" + i}>
              <div className="hint--top" aria-label={name}>
                <img src={m.info.avatar_url} className="avatar avatar__whos-online" /> 
              </div>
            </div>
          );
        }.bind(this))}
        </div>
      );
    }
  }
});
