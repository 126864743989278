const { default: Icon } = require('blackbird/components/icon/Icon');
import EmptyStateIcon from 'blackbird/images/icons/empty-state.svg';
const createReactClass = require('create-react-class')

window.DashboardProjectEmpty = createReactClass({

  render: function() {
    return (
      <div className="bg-white rounded-lg project is--not-admin">
          <div className="p-10 text-center space-y-4">
            <div className='flex justify-center'>
              <Icon icon={<EmptyStateIcon />} className="w-[98px] opacity-20" />
            </div>
            <div className='text-type-disabled'>{`No projects`}</div>
          </div>
      </div>
    );
  }
});
