/** @prettier */
/* eslint react/jsx-handler-names:0 */
import ReactDOM from 'react-dom';
import * as React from 'react';
import { modeOptionsByValue } from './toolbarOptions';

import { ToolbarButton } from '../../ToolbarButton';
import { IconSpacer, IconSpacerGroup } from '../../IconSpacer';
import { ToolbarModeButton } from './ToolbarModeButton';
import type { IFrame } from '../../../types/frame';
import type { frameEditorMode } from '../types';
import { debounce } from 'underscore';
import type { IFrameOverlay } from 'javascripts/components/frame_editor/FrameOverlays';

import ClearFrameIcon from 'blackbird/images/icons/clear-frame-2.svg';
import OnionSkinOnIcon from 'blackbird/images/icons/onion-skin-on.svg';
import OnionSkinOffIcon from 'blackbird/images/icons/onion-skin-off.svg';
import UndoIcon from 'blackbird/images/icons/undo-2.svg';
import RedoIcon from 'blackbird/images/icons/redo-2.svg';
import FlipHorizontalIcon from 'blackbird/images/icons/flip-horizontal.svg';
import FlipVerticalIcon from 'blackbird/images/icons/flip-vertical.svg';
import CropIcon from 'blackbird/images/icons/crop.svg';
import DuplicateIcon from 'blackbird/images/icons/duplicate-2.svg';
import NewFrameIcon from 'blackbird/images/icons/new-frame.svg';
import DeleteIcon from 'blackbird/images/icons/delete-2.svg';
import classNames from 'classnames';
import type { CommentAnnotationsStore } from 'javascripts/flux/stores/commentAnnotations';
import Container from 'javascripts/components/shared/Container';

interface State {
  openedOverlay: unknown | undefined;
  isModifierPressed: boolean;
  narrow: boolean;
}

interface Props {
  frame: IFrame;
  mode: frameEditorMode;
  drawToolEnabled: boolean;
  shapeType: string;
  hasOverlay: boolean;
  canUndo: boolean;
  canCreateNewFrame: boolean;
  canRedo: boolean;
  backgroundColor: string;
  onionSkinStrength: number;
  hasSidebar: boolean;

  // passed by the parent
  onInsertOverlay: (overlay: IFrameOverlay) => void;
  onChangeBackgroundColor: (color: string) => void;
  onRemoveBackgroundClick: React.MouseEventHandler | false;
  onClearClick: React.MouseEventHandler | false;
  onToggleOnionSkinStrength: React.MouseEventHandler | false;
  onUndo: React.MouseEventHandler | false;
  onRedo: React.MouseEventHandler | false;
  handleImageCrop: React.MouseEventHandler | false;
  onDuplicateClick: React.MouseEventHandler | false;
  onNewFrameClick: React.MouseEventHandler | false;
  onDeleteFrameClick: React.MouseEventHandler | false;
  onFlipCanvas?: React.MouseEventHandler | false;

  onDeselectAll?: () => void;

  // passed by the HOC
  commentAnnotations?: Pick<CommentAnnotationsStore, 'editorState'>;
}
export class BottomBar extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      openedOverlay: null,
      isModifierPressed: false,
      narrow: false,
    };
  }

  static displayName = 'BottomBar';

  activateOptionOverlay = (name) =>
    this.setState((state) => ({
      // If we're asked to activate the current one, hide it instead
      openedOverlay: name === state.openedOverlay ? null : name,
    }));

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyEvent);
    document.addEventListener('keyup', this.handleKeyEvent);
    window.addEventListener('resize', this.debouncedEvaluateNarrowLayout);
    this.evaluateNarrowLayout();
  }

  componentDidUpdate(prevProps) {
    if (this.props.hasSidebar !== prevProps.hasSidebar) {
      this.evaluateNarrowLayout();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyEvent);
    document.removeEventListener('keyup', this.handleKeyEvent);
    window.removeEventListener('resize', this.debouncedEvaluateNarrowLayout);
  }

  evaluateNarrowLayout = () => {
    const element = ReactDOM.findDOMNode(this) as Element | undefined;
    const narrow =
      (element && element.clientWidth > element.parentElement!.clientWidth) ??
      false;

    if (this.state.narrow !== narrow) this.setState({ narrow });
  };

  debouncedEvaluateNarrowLayout = debounce(this.evaluateNarrowLayout, 200);

  handleKeyEvent = (e) => {
    if (e.key === 'Shift' || e.key === 'Alt') {
      this.setState({ isModifierPressed: e.type === 'keydown' });
    }
  };

  activateStockOverlay = () =>
    this.setState({
      openedOverlay: 'stock',
    });

  deactivateStockOverlay = () => {
    if (this.state.openedOverlay === 'stock') {
      this.setState({
        openedOverlay: null,
      });
    }
  };

  activateOverlaysOverlay = () =>
    this.setState({
      openedOverlay: 'overlays',
    });

  deactivateOverlaysOverlay = () => {
    if (this.state.openedOverlay === 'overlays') {
      this.setState({
        openedOverlay: null,
      });
    }
  };

  render() {
    const { frameIndex } = FrameEditorStore.getState();
    // Using the headlessjs transition removes the object from the dom,
    // which will cause layout shift, we don't want that here
    const visible = this.props.commentAnnotations?.editorState === 'off';

    return (
      <div
        className={classNames(
          'inline-flex p-4 bg-white rounded-lg shadow-md transition duration-300',
          visible ? 'translate-y-0' : 'translate-y-72 pointer-events-none',
        )}
      >
        <IconSpacer spacing={this.state.narrow ? 'narrow' : 'normal'}>
          <IconSpacerGroup>
            <ToolbarModeButton
              mode={modeOptionsByValue['select']}
              isActive={this.props.mode === 'select'}
              disabled={!this.props.drawToolEnabled}
            />

            <ToolbarModeButton
              mode={modeOptionsByValue['text']}
              isActive={this.props.mode === 'text'}
              disabled={!this.props.drawToolEnabled}
            />

            <ToolbarModeButton
              mode={modeOptionsByValue['eraser']}
              isActive={this.props.mode === 'eraser'}
              disabled={!this.props.drawToolEnabled}
            />

            <ToolbarModeButton
              mode={modeOptionsByValue['draw']}
              isActive={this.props.mode === 'draw'}
              disabled={!this.props.drawToolEnabled}
            />
          </IconSpacerGroup>

          {this.props.children}

          <IconSpacerGroup>
            {this.state.isModifierPressed ? (
              <ToolbarButton
                value="removeBackground"
                labelKeyPrefix="frameEditor.toolbar"
                icon={<ClearFrameIcon />}
                labelPosition="top"
                onClick={this.props.onRemoveBackgroundClick}
                disabled={!this.props.drawToolEnabled}
              />
            ) : (
              <ToolbarButton
                labelKeyPrefix="frameEditor.toolbar"
                value="clear"
                icon={<ClearFrameIcon />}
                labelPosition="top"
                onClick={this.props.onClearClick}
                disabled={!this.props.drawToolEnabled}
              />
            )}

            <ToolbarButton
              labelKeyPrefix="frameEditor.toolbar"
              labelKey={
                frameIndex === 0
                  ? 'onionSkin.unavailable'
                  : 'onionSkin.available'
              }
              value="onionSkinStrength"
              icon={
                this.props.onionSkinStrength > 0 ? (
                  <OnionSkinOnIcon />
                ) : (
                  <OnionSkinOffIcon />
                )
              }
              labelPosition="top"
              onClick={this.props.onToggleOnionSkinStrength}
              disabled={!this.props.drawToolEnabled || frameIndex === 0}
            />
          </IconSpacerGroup>

          <IconSpacerGroup>
            <ToolbarButton
              labelKeyPrefix="actions"
              value="undo"
              labelPosition="top"
              onClick={this.props.canUndo && this.props.onUndo}
              icon={<UndoIcon />}
              disabled={!this.props.drawToolEnabled}
            />

            <ToolbarButton
              labelKeyPrefix="actions"
              value="redo"
              labelPosition="top"
              icon={<RedoIcon />}
              onClick={this.props.canRedo && this.props.onRedo}
              disabled={!this.props.drawToolEnabled}
            />
          </IconSpacerGroup>
          <IconSpacerGroup>
            <ToolbarButton
              labelKeyPrefix="frameEditor.toolbar"
              value="flip-x"
              labelPosition="top"
              icon={<FlipHorizontalIcon />}
              onClick={this.props.onFlipCanvas}
            />

            <ToolbarButton
              labelKeyPrefix="frameEditor.toolbar"
              value="flip-y"
              labelPosition="top"
              icon={<FlipVerticalIcon />}
              onClick={this.props.onFlipCanvas}
            />
          </IconSpacerGroup>

          <ToolbarButton
            labelKeyPrefix="frameEditor.toolbar"
            labelKey="resizeAndCrop"
            onClick={this.props.handleImageCrop}
            icon={<CropIcon />}
            disabled={!this.props.drawToolEnabled}
          />

          <ToolbarButton
            labelKey="actions.frames.duplicate"
            value="duplicate"
            icon={<DuplicateIcon />}
            labelPosition="top"
            onClick={this.props.onDuplicateClick}
            disabled={
              !this.props.drawToolEnabled || !this.props.canCreateNewFrame
            }
          />

          <ToolbarButton
            labelKey="actions.frames.new"
            value="create-frame"
            onClick={this.props.onNewFrameClick}
            disabled={!this.props.drawToolEnabled}
            icon={<NewFrameIcon />}
          />

          <ToolbarButton
            labelKey="actions.frames.delete_one"
            value={this.props.frame.id}
            icon={<DeleteIcon />}
            onClick={this.props.onDeleteFrameClick}
            disabled={!this.props.drawToolEnabled}
          />
        </IconSpacer>
      </div>
    );
  }
}

export default Container({
  commentAnnotations: (data) => ({ editorState: data.editorState }),
})(BottomBar);
