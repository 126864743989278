(() => {
  class UserStoryboardNotificationStore {

    constructor() {
      this.user = null;

      this.bindListeners({
        handleUpdate:   UserStoryboardNotificationActions.UPDATE,
        handleReceive:  UserActions.RECEIVE,
      });
    }

    handleUpdate(data) {
      ajaxHelper.ajax({
        method: "patch",
        url: "/users/" + this.user.id + "/storyboard_notifications/" + data.storyboard_id + ".json",
        data: {
          send_emails: data.send_emails,
        },
        success: function (response) {
          this.user.no_notification_for_storyboard_ids = response.no_notification_for_storyboard_ids;
          this.emitChange();
          RequestActions.success.defer({ key: 'sidebar.settings.comments.notifications_settings_saved' });
        }.bind(this),
        error: function(response) {
          RequestActions.error.defer({ key: 'sidebar.settings.comments.notifications_settings_error'});
          this.emitChange();
        }.bind(this),
      });
    }

    handleReceive(user) {
      this.user = user;
    }
  }

  window.UserStoryboardNotificationStore = alt.createStore(UserStoryboardNotificationStore, 'UserStoryboardNotificationStore');

})();
