/** @format */
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import classNames from 'classnames';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import * as React from 'react';
const logger = RequestErrorHandler('image');

interface Props extends React.ImgHTMLAttributes<unknown> {
  containerClasses?: string;
  placeholder?: string;
  small?: boolean;
  log?: boolean;
}

export const Img = React.memo<Props>(
  ({ small, containerClasses, placeholder, log, ...props }) => {
    const isCached = React.useMemo(() => {
      if (!props.src) {
        return false;
      } else {
        const imageCache = new Image();
        imageCache.src = props.src;
        return imageCache.complete;
      }
    }, [props.src]);

    const [isLoaded, setIsLoaded] = React.useState(isCached);
    const [isError, setIsError] = React.useState(false);

    const handleLoad = React.useCallback(
      async (e) => {
        const img = e.target;

        try {
          if (img.decode) {
            await img.decode();
          }
          setIsLoaded(true);
        } catch (decodeError) {
          setIsError(true);
          if (log) logger({ severity: 'info', messageKey: null })(decodeError);
        }
      },
      [log],
    );

    const handleError = React.useCallback<
      React.ReactEventHandler<HTMLImageElement>
    >(
      (e) => {
        if (log && navigator.onLine) {
          logger({
            severity: 'info',
            messageKey: null,
            rollbarMessage: 'could not load image',
          })({
            url: e.currentTarget.src,
          });
        }
        setIsError(true);
      },
      [log],
    );

    return (
      <div className={classNames(containerClasses, 'block w-full relative')}>
        {isError ? (
          placeholder && <img {...props} src={placeholder} />
        ) : (
          <img
            data-hj-suppress={true}
            onError={handleError}
            onLoad={handleLoad}
            {...props}
          />
        )}
        <LoadingIndicator
          bg
          fill
          show={!isLoaded && !isError}
          className={classNames(small ? 'w-4' : 'w-10')}
        />
      </div>
    );
  },
);

Img.displayName = 'Img';
export default Img;
