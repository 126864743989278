window.CoverpageActions = alt.createActions(function () {

  this.fetch = function (storyboard_id) {
    this.dispatch(storyboard_id);
  };
  this.fetchIfNecessary = function (storyboard_id) {
    this.dispatch(storyboard_id);
  };

  this.save = function () {
    this.dispatch();
  };

  this.uploadLogo = function (files) {
    this.dispatch(files);
  };

  this.updateValue = function (obj) {
    this.dispatch(obj);
  };

  this.clear = function () {
    this.dispatch();
  };

  this.receive = function (cover) {
    this.dispatch(cover);
  };

});

