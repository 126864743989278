/** @format */
import * as React from 'react';
import Popover from 'blackbird/components/common/popover/Popover';
import { IconSpacer } from 'javascripts/components/IconSpacer';
import { ToolbarButton } from 'javascripts/components/ToolbarButton';
import type { RequireAtLeastOne } from 'type-fest';
import { find } from 'underscore';

type iconType = React.ComponentClass | React.FunctionComponent;

export type OptionButtonOptions<T = string | number> = RequireAtLeastOne<
  {
    value: T;
    /** The translation string key */
    labelKey?: string;
    icon: iconType;
  },
  'value' | 'labelKey'
>;

interface Props {
  show?: boolean;
  onChange: (newMode: string) => void;
  onActivate: (newMode: string) => void;
  name: string;
  labelKey?: string;
  labelKeyPrefix?: string;
  customComponent?: (args: {
    /** deprecated: should not use this.onChange */
    onChange: any;
    label: string;
    value: string | number;
  }) => React.ReactNode;
  indicateActiveState?: boolean;
  coverIcon?: iconType;
  value: string | number;
  isOpen?: boolean;
  disabled?: boolean;
  options: Readonly<OptionButtonOptions[]>;
}

export const OptionButton: React.FC<Props> = ({
  indicateActiveState = true,
  show = true,
  options,
  value,
  ...props
}) => {
  const handleToggleCollapsed = () => props.onActivate(props.name);

  const handleChange = (e) => {
    props.onChange(e.currentTarget.value || e.target.value);
    props.onActivate(props.name);
  };

  const currentOption = find(options, (o) => o.value === value) ?? options[0]!;

  if (!show) return null;
  return (
    <Popover
      isOpen={props.isOpen}
      onClose={handleToggleCollapsed}
      className="rounded-sm"
      placement="top"
      distance={10}
    >
      <Popover.Button>
        <ToolbarButton
          labelKey={String(
            props.labelKey || currentOption.labelKey || currentOption.value,
          )}
          labelKeyPrefix={props.labelKeyPrefix}
          value={value}
          active={props.isOpen}
          hideTooltip={props.isOpen}
          onClick={!props.disabled ? handleToggleCollapsed : undefined}
          labelPosition="top"
          icon={React.createElement(props.coverIcon ?? currentOption.icon)}
          disabled={props.disabled}
        />
      </Popover.Button>

      <Popover.Panel className="bg-white inline-flex p-3 rounded-md shadow-xs">
        <IconSpacer>
          {options.map((o) => (
            <ToolbarButton
              key={o.labelKey || o.value}
              labelKey={String(o.labelKey || o.value)}
              labelKeyPrefix={props.labelKeyPrefix}
              value={o.value}
              active={
                indicateActiveState ? o.value === currentOption.value : true
              }
              onClick={handleChange}
              icon={React.createElement(o.icon)}
            />
          ))}

          {props.customComponent
            ? props.customComponent({
                onChange: props.onChange, // should not use this.onChange
                label: props.labelKey!,
                value: value,
              })
            : null}
        </IconSpacer>
      </Popover.Panel>
    </Popover>
  );
};
