/** @prettier */
import {
  BillingContext,
  type IBillingPlanCurrency,
} from 'blackbird/helpers/contexts/BillingContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { formatCurrency } from 'javascripts/helpers/currencyHelper';
import classNames from 'classnames';

interface LineItemProps {
  children: string | React.ReactElement;
  amount: number;
  amountDescription?: React.ReactElement;
  currency?: IBillingPlanCurrency;
  afterDiscount?: string | number | React.ReactElement;
  amountClassName?: string;
  isSecondary?: boolean;
  bigAmount?: boolean;
  isTotal?: boolean;
  lineThrough?: boolean;
  isDiscount?: boolean;
  showZeros?: boolean;
}

export const LineItem: React.FC<LineItemProps> = ({
  children,
  amount,
  bigAmount,
  amountDescription,
  amountClassName = '',
  isTotal,
  lineThrough,
  currency,
  afterDiscount,
  isSecondary,
  isDiscount,
  showZeros = true,
}) => {
  const { customer } = useContext(BillingContext);
  const [previewCurrency, setPreviewCurrency] =
    useState<IBillingPlanCurrency>('usd');

  useEffect(() => {
    if (customer) {
      setPreviewCurrency(customer.currency as IBillingPlanCurrency);
    } else if (currency) {
      setPreviewCurrency(currency);
    }
  }, [currency, customer]);

  const money = useCallback(
    (amount: number, showZeros = true) => {
      if (previewCurrency) {
        return formatCurrency({
          amount: amount / 100,
          currency: previewCurrency,
          showZeros: showZeros,
        });
      }
    },
    [previewCurrency],
  );

  return (
    <div
      className={`flex items-start my-4 ${
        isTotal ? 'font-semibold' : 'text-type-subdued'
      } ${isSecondary ? '' : ''}`}
    >
      <div className="flex-auto">{children}</div>
      <div className="text-right">
        <span
          className={classNames(
            lineThrough &&
              'line-through decoration-brand-pink/70 decoration-2 text-type-primary',
            afterDiscount && 'font-semibold',
            amountClassName,
          )}
        >
          {isDiscount ? (
            <div className="text-type-green">{`-${money(amount)}`}</div>
          ) : (
            <>{money(amount, showZeros)}</>
          )}
        </span>

        {afterDiscount && (
          <div
            className={classNames(
              'text-type-primary',
              bigAmount ? 'text-lg' : 'text-base',
            )}
          >
            {typeof afterDiscount === 'number'
              ? money(afterDiscount, false)
              : afterDiscount}
          </div>
        )}

        {amountDescription && <div className="mt-0">{amountDescription}</div>}
      </div>
    </div>
  );
};
