/** @format */

import * as React from 'react';
import { Disclosure } from '@headlessui/react';
import { ModalDivider } from 'blackbird/components/dialog/Dialog';
import {
  type TeamMember,
  TeamContext,
  type UnregisteredInvitation,
} from './TeamContext';
import { ProjectsContext } from '../projects/ProjectsContext';
import { TeamProjectMemberRow } from './TeamProjectMemberRow';
import { TeamProjectInviteRow } from './TeamProjectInviteRow';
import ArrowDownIcon from '../../images/icons/arrow.svg';
import Icon from '../icon/Icon';
import classNames from 'classnames';
import { TeamProjectInviteForm } from './TeamProjectInviteForm';

interface MemberDisclosureProps {
  title: string;
  members: TeamMember[];
  invites?: UnregisteredInvitation[];
  defaultOpen?: boolean;
  accessCount?: number;
  isFirst?: boolean;
}

const MemberDisclosure: React.FC<MemberDisclosureProps> = ({
  title,
  members,
  invites = [],
  defaultOpen = false,
  accessCount,
  isFirst = false,
}) => (
  <div className="relative ">
    {!isFirst && (
      <div className="mb-4">
        <ModalDivider />
      </div>
    )}
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button className="inline-flex justify-center px-2 pb-1.5 pt-1 rounded-md hover:bg-surface focus:outline-none -ml-4">
            <span className="transform transition-transform duration-200 ease-in-out">
              <Icon
                icon={<ArrowDownIcon />}
                className={classNames(
                  'flex-shrink-0 h-6 transition-all ml-0 -mt-0.5',
                  open ? 'rotate-0' : '-rotate-90',
                )}
                color="black"
              />
            </span>
            <span className="">
              {accessCount !== undefined ? (
                <span>
                  <>
                    {`${accessCount} ${
                      accessCount === 1 ? 'person has' : 'people have'
                    }`}
                  </>
                </span>
              ) : (
                <span>
                  {members.length + invites.length === 1 ? (
                    <>{`1 person can`}</>
                  ) : (
                    <>{`${members.length + invites.length} people can`}</>
                  )}
                </span>
              )}
              <span className="ml-1">{title}</span>
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className="py-4">
            <div className="space-y-4">
              {members.map((member) => (
                <div className="-ml-1.5" key={member.id}>
                  <TeamProjectMemberRow member={member} />
                </div>
              ))}
              {invites.map((invite) => (
                <div className="-ml-1.5" key={invite.invitation_id}>
                  <TeamProjectInviteRow invite={invite} />
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  </div>
);

export const TeamProjectManageContent: React.FC = () => {
  const { teamData, sortTeamMembers } = React.useContext(TeamContext);
  const { activeProject } = React.useContext(ProjectsContext);

  if (!teamData || !activeProject) {
    return <div>No project data available</div>;
  }

  const projectMembers = sortTeamMembers(teamData.active_and_pending_members);

  const adminManagerSupermembers = projectMembers.filter((member) =>
    ['admin', 'manager', 'supermember'].includes(member.role),
  );

  const regularMembers = projectMembers.filter(
    (member) => member.role === 'member',
  );

  const membersWithAccess = regularMembers.filter((member) =>
    member.projects_with_access.includes(activeProject.slug),
  );

  const invitedMembers = teamData.unregistered_invitations;

  const adminManagerSupermemberInvites = invitedMembers.filter((invite) =>
    ['admin', 'manager', 'supermember'].includes(invite.role),
  );

  const regularMemberInvites = invitedMembers.filter(
    (invite) =>
      invite.role === 'member' &&
      invite.will_have_access_to_project === activeProject.id,
  );

  return (
    <div>
      <TeamProjectInviteForm />
      <div className="mt-6 space-y-4">
        <MemberDisclosure
          title={`access all projects`}
          members={adminManagerSupermembers}
          invites={adminManagerSupermemberInvites}
          defaultOpen={false}
          isFirst={true}
        />
        <MemberDisclosure
          title={`project-level access`}
          members={regularMembers}
          invites={regularMemberInvites}
          defaultOpen={true}
          accessCount={membersWithAccess.length + regularMemberInvites.length}
        />
      </div>
    </div>
  );
};
