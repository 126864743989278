/** @prettier */
import * as React from 'react';

interface Props {
  margin?: string;
  width?: number | string;
}

export const PanelbarDivider: React.FC<Props> = ({
  margin = 'my-3',
  width = '2px',
}) => {
  return (
    <hr
      className={`db border-b-border  flex items-center justify-between ${margin}`}
      style={{ borderWidth: 0, borderBottomWidth: width }}
    />
  );
};
