/** @format */

import React from 'react';
import { TemplatesContainer } from '../templates/TemplatesContainer';
import { ProjectArchive } from './archive/ProjectArchive';
import { ProjectContents } from './ProjectContents';
import { ProjectsContext } from './ProjectsContext';

export const ProjectMainWindow: React.FC = () => {
  const { viewType } = React.useContext(ProjectsContext);

  return viewType === 'archive' ? (
    <ProjectArchive />
  ) : viewType === 'templates' ? (
    <TemplatesContainer />
  ) : (
    <ProjectContents />
  );
};
