const _ = require('underscore');
const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
window.ExportPdfLogoPreview = createReactClass({

  propTypes:     {
    url:         PropTypes.string.isRequired,
    className:   PropTypes.string.isRequired,
    emptyText:   PropTypes.string,
    linkLogo:    PropTypes.bool,
    hideCustom:  PropTypes.bool,
  },

  render: function() {
    if (_.isEmpty(this.props.url) && !_.isEmpty(this.props.emptyText)) {
      return <div className="mt8px silver f5">{this.props.emptyText}</div>

    } else if (_.isEmpty(this.props.url)) {

      return null;

    } else if (this.props.hideCustom && (this.props.url.indexOf("made-with-boords") == -1)) {

      return null;

    } else if (this.props.linkLogo && (this.props.url.indexOf("made-with-boords") > 0)) {

      return (
        <a href={"https://boords.com/?rel=web_footer"} target="_blank">
          <img src={this.props.url.replace("jpg", "png")} className={this.props.className} alt={"Boords logo"} />
        </a>
      );

    } else {

      return <img src={this.props.url} className={this.props.className} alt={"Boords logo"} />;

    }
  }
});
