/** @format */
import * as React from 'react';
import { type IStoryboardPreferences } from '../../../types/storyboard';
import {
  createPermissionFlyoverOpener,
  usePermission,
} from '../../../helpers/has-permission';
import Toggle, {
  type ToggleOnChangeHandler,
} from 'blackbird/components/toggle/Toggle';
import { ConditionalTooltip } from 'blackbird/components/feedback/tooltip/Tooltip';
import QuestionIcon from 'blackbird/images/icons/question-small.svg';
import { IconButton } from 'blackbird/components/common/IconButton';
import { useTranslation } from 'react-i18next';
import { type currentUserFeaturesType } from 'javascripts/flux/stores/user_permissions';
import { AccordionTransition } from 'blackbird/components/common/Transitions';

interface Props {
  show?: boolean;
  /** This will be passed to the underlying toggle's `name` attribute */
  preference: keyof IStoryboardPreferences;
  value?: boolean;
  /** Function to call when the value change, the event target will have the
   * `preference` prop as the `name` */
  onChange: ToggleOnChangeHandler;
  isSaving?: boolean;
  label: string;
  help?: string;
  /** if this feature feature-limited, enter the key here and it will format appropriately */
  permission?: currentUserFeaturesType;
}

export const StoryboardPreferenceToggle = React.memo<Props>((props) => {
  const { t } = useTranslation();
  // The rules of hooks are there for a reason, but because the permission value
  // doesn't ever change, this should be safe.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasPermission = props.permission
    ? usePermission(BoordsConfig.Uid, props.permission)
    : undefined;

  return (
    <AccordionTransition
      show={props.show !== false}
      className="flex items-center"
    >
      <div className="flex flex-auto items-center">
        <div>{props.label}</div>
        {props.help ? (
          <div className="flex-auto">
            <IconButton
              iconClassName="h-6 w-auto text-icon-subdued"
              icon={<QuestionIcon />}
              aria-label={props.help}
            />
          </div>
        ) : null}
      </div>
      <ConditionalTooltip
        as="div"
        title={
          props.permission && !hasPermission
            ? t('modals.share.upgrade')
            : undefined
        }
      >
        <div
          onClick={
            props.permission
              ? () => {
                  createPermissionFlyoverOpener(
                    BoordsConfig.Uid,
                    props.permission!,
                  );
                }
              : undefined
          }
        >
          <Toggle
            name={props.preference}
            onChange={props.onChange}
            value={props.value}
            disabled={!!props.isSaving || (props.permission && !hasPermission)}
          />
        </div>
      </ConditionalTooltip>
    </AccordionTransition>
  );
});

StoryboardPreferenceToggle.displayName = 'StoryboardPreferenceToggle';
