import { UserStore } from '../stores/user';
import { createTypedActions } from '../stores/AltStore';

class UserActions {
  fetch: UserStore['handleFetch'] = function () {
    this.dispatch();
  };

  receive: UserStore['handleReceive'] = function (user) {
    this.dispatch(user);
  };

  updateFields: UserStore['handleUpdateFields'] = function (data) {
    this.dispatch(data);
  };

  updatePassword: UserStore['handleUpdatePassword'] = function (data) {
    this.dispatch(data);
  };

  destroy: UserStore['handleDestroy'] = function(data) {
    this.dispatch(data);
  };

}

const actions = createTypedActions<UserActions>(UserActions);
(window as any).UserActions = actions;
export { actions as UserActions };
