/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import type { IFrame } from '../../types/frame';
import type { IStoryboard } from '../../types/storyboard';
import { FrameActions } from '../actions/frame';
import { FrameEditorActions } from '../actions/frame_editor';
import {
  frameEditorModeLocalState,
  LocalState,
} from '../../helpers/local-state';
import type { FrameEditorDialogOptions } from 'javascripts/components/frame_editor/FrameEditorDialog';
import logger from 'javascripts/helpers/logger';
import type { frameEditorMode } from 'javascripts/components/frame_editor/types';
import { find, keys } from 'underscore';
import { eventSource } from 'blackbird/helpers/eventContextHelper';
import {
  FrameEditorDecoration,
  newFrameEditorDecoration,
} from 'javascripts/components/frame_editor/FrameEditorDecoration';
import { ReadonlyDeep } from 'type-fest';

const navigateToRoute = require('../../helpers/router/navigate-to-route');
const ONION_SKIN_KEY = 'onionSkinStrength';

function resetState(state: FrameEditorStore) {
  state.editingMode = 'select';
  state.unsavedChanges = false;
  state.dialogOptions = null;
  state.historyIndex = 0;
  state.currentDecoration = null;
}

/** An array of allowed values for the frame editor's mode. This _could_ be a
 * good source for the type (frameEditorMode), unfortunately it caused circular references */
export const frameEditorModes: frameEditorMode[] = [
  'eraser',
  'draw',
  'select',
  'text',
];

export class FrameEditorStore extends FakeAltStoreClass<FrameEditorStore> {
  isEditorActive = false;
  _editorActiveTimeout;

  editingMode = frameEditorModeLocalState.getValue() ?? 'select';
  shapeType = 'rect';

  unsavedChanges = false;
  isLoading = true;
  dialogOptions: FrameEditorDialogOptions | null = null;

  // All set by this.handleUpdateFrame(frameID)
  frame: IFrame;
  frames: IFrame[];
  frameIndex: number;
  isSaving = false;
  storyboard: IStoryboard;
  showPreview = true;
  onionSkinStrength: number = LocalState.getValue(ONION_SKIN_KEY) || 0;
  initialized = false;
  historyIndex = 0;
  onionSkinFrame?: IFrame | null;
  currentDecoration: ReadonlyDeep<FrameEditorDecoration> | null;

  constructor() {
    super();

    this.bindListeners({
      handleOpen: FrameEditorActions.OPEN,
      handleUpdateShapeType: FrameEditorActions.UPDATE_SHAPE_TYPE,
      handleUpdateMode: FrameEditorActions.UPDATE_EDITING_MODE,
      handleUpdateFrame: FrameEditorActions.UPDATE_FRAME,
      handleUpdateSaveState: FrameEditorActions.UPDATE_SAVE_STATE,
      handleUpdateLoading: FrameEditorActions.UPDATE_LOADING,
      handleTogglePreview: FrameEditorActions.TOGGLE_PREVIEW,
      handleUpdateOnionSkinStrength:
        FrameEditorActions.UPDATE_ONION_SKIN_STRENGTH,
      handleDuplicateFrame: FrameEditorActions.DUPLICATE_FRAME,
      handleOpenDialog: FrameEditorActions.OPEN_DIALOG,
      handleCloseDialog: FrameEditorActions.CLOSE_DIALOG,
      handleCycleShapes: FrameEditorActions.CYCLE_SHAPES,
      handleUpdateHistoryIndex: FrameEditorActions.UPDATE_HISTORY_INDEX,
      handleUpdateDecoration: FrameEditorActions.UPDATE_DECORATION,
    });

    this.on('unlisten', () => resetState(this));
  }

  handleOpen(frameId: number) {
    this.initialized = true;
    Track.event.defer('Editor open', {
      context: eventSource(undefined),
      category: 'Product',
    });
    this.handleUpdateFrame(frameId);
  }

  handleUpdateFrame(frameId: number) {
    if (!this.initialized) return;
    if (this.frame && frameId !== this.frame.id) {
      resetState(this);
    }

    clearTimeout(this._editorActiveTimeout);

    this.frames = FrameStore.getState().frames;
    const newFrame = find(this.frames, (f) => f.id === frameId);
    if (!newFrame) {
      logger.warn('could not find frame with id ' + frameId);
      return;
    }

    this.frame = newFrame;

    this.storyboard = StoryboardStore.getState().storyboard;
    this.frameIndex = this.frame.sort_order
      ? this.frame.sort_order - 1
      : this.frames.length;

    this.isSaving = FrameStore.getState().is_saving;
    this.onionSkinFrame = this.frames[this.frameIndex - 1]
      ? this.frames[this.frameIndex - 1]
      : null;

    this._editorActiveTimeout = setTimeout(() => {
      this.isEditorActive = true;
      this.emitChange();
    }, 1000);

    navigateToRoute(
      'frameEditor',
      {
        slug: this.storyboard.slug,
        frameIndex: this.frameIndex + 1,
      },
      true,
    );

    document.title = `${this.storyboard.document_name} (frame ${
      this.frame.number || this.frameIndex + 1
    }) (Animatic)' · Boords'`;
    this.isLoading = false;
  }

  handleUpdateMode(newMode: frameEditorMode) {
    this.editingMode = newMode;
  }

  handleUpdateSaveState(newState: boolean) {
    this.unsavedChanges = newState;
  }

  handleUpdateOnionSkinStrength(newValue: number) {
    this.onionSkinStrength = newValue;
    LocalState.setValue(ONION_SKIN_KEY, newValue);
  }

  handleUpdateLoading(newState: boolean) {
    this.isLoading = newState;
  }

  handleOpenDialog(dialogOptions: FrameEditorDialogOptions) {
    this.dialogOptions = dialogOptions;
  }

  handleCloseDialog() {
    this.dialogOptions = null;
  }

  handleUpdateShapeType(shapeType) {
    this.shapeType = shapeType;
  }

  handleDuplicateFrame(frameId: number) {
    const frame = find(this.frames, (f) => f.id === frameId)!;
    this.isLoading = true;

    FrameActions.insertFrame.defer({
      dropped_sort_order: parseInt(frame.sort_order) + 1,
      frame_to_duplicate: frame,
      move_frames_after_here: true,
      storyboard_id: frame.storyboard_id,
    });
  }

  handleCycleShapes() {
    const shapes = ['ellipse', 'rect', 'triangle', 'arrow'];
    const index = shapes.indexOf(this.shapeType);
    if (index < 0) return;
    const newIndex = (index + 1) % shapes.length;
    this.shapeType = shapes[newIndex];
  }

  handleTogglePreview(newValue: boolean) {
    this.showPreview = newValue;
  }

  handleUpdateHistoryIndex(historyIndex: number) {
    this.historyIndex = historyIndex;
  }

  handleUpdateDecoration(decoration: Partial<FrameEditorDecoration>) {
    // If we have a partial, we want to override the object, otherwise just
    // assign the whole thing. This allows for equality checking
    if (keys(decoration).length < keys(this.currentDecoration)?.length) {
      this.currentDecoration = newFrameEditorDecoration({
        ...this.currentDecoration,
        ...decoration,
      });
    } else {
      this.currentDecoration = decoration as FrameEditorDecoration;
    }
  }
}

(window as any).FrameEditorStore = alt.createStore(
  FrameEditorStore,
  'FrameEditorStore',
);
