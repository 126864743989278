/** @prettier */
import { PDFLayoutFunc } from '../types';
import { splitArrayPerAmount } from '../helpers/splitArrayPerAmount';
import * as eachOfSeries from 'async/eachOfSeries';
import { createGridPage } from '../helpers/createGridPage';
import { createDocument } from '../helpers/createDocument';
import { finishPDF } from '../helpers/finishPDF';
import logger from 'javascripts/helpers/logger';

export const PDFSixGridLayout: PDFLayoutFunc<{
  framesPerPage: number;
  columns?: number;
}> = async (props, frames, options = { framesPerPage: 6, columns: 3 }) => {
  const pages = splitArrayPerAmount(frames, options.framesPerPage);
  const docInfo = await createDocument(props, 'landscape');

  let pagesToUse = pages;
  if (props.isQuickMode) {
    pagesToUse = [pages[props.pageNumber || 0]];
  } else if (props.isTrialPreview) {
    // We want to limit to only one page worth of frames
    pagesToUse = [pages[0]];
  }

  // logger.log(`Rendering ${pagesToUse.length} grid pages`);

  return new Promise((resolve, reject) => {
    let tooMuchText = false;
    // Split up all the frames per page
    eachOfSeries(
      pagesToUse,
      (pageFrames, pi, done) => {
        createGridPage(
          docInfo,
          props.style,
          {
            frames: pageFrames,
            columns: options.columns || 3,
            isQuickMode: props.isQuickMode || false,
            footerText: props.footerText,
            disableOverflow: props.disableOverflow,
            debug: props.isDebugMode,
            pageNumber: props.pageNumber,
            isTrialPreview: props.isTrialPreview,
            setTooMuchText: () => (tooMuchText = true),
          },
          done,
        );
      },
      async err => {
        if (err) return reject(err);

        return finishPDF({
          docInfo,
          props,
          callback: resolve,
          tooMuchText,
        });
      },
    );
  });
};
