/** @format */
import type { Cancellable } from 'blackbird/helpers/types';
import { idleTimeout } from './idle-timeout';
import { RequestErrorHandler } from './request-error-handler';
const errorHandler = RequestErrorHandler('retryWhile');

const defaultOptions = {
  timeout: 200,
  maxTries: 10,
  /** How many tries have already been made */
  tries: 1,
};

/** Will execute `callback` as soon as `whileFunc` returns true. If not, it will
 * keep retrying. Uses `idleTimeout` under the hood */
export function retryWhile(
  whileFunc: () => boolean,
  callback: () => void,
  options = defaultOptions,
): Cancellable | undefined {
  const { timeout, tries, maxTries } = { ...defaultOptions, ...options };

  if (tries >= maxTries) {
    errorHandler({
      severity: 'warn',
      messageKey: 'null',
      extraData: { conditionFunc: whileFunc.toString() },
    })(
      new Error(
        `Reached ${maxTries} tries (the maximum) while waiting for condition to be true`,
      ),
    );
    return;
  }
  if (whileFunc() === true) {
    callback();
  } else {
    const newTimeout = timeout * 2;
    return idleTimeout(
      () =>
        retryWhile(whileFunc, callback, {
          timeout: newTimeout,
          tries: tries + 1,
          maxTries,
        }),
      newTimeout,
    );
  }
}
