/** @format */

import Icon from 'blackbird/components/icon/Icon';
import classNames from 'classnames';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
  title?: string;
  icon?: React.ReactElement;
}

export const EmptyState: React.FC<Props> = (props) => (
  <div
    className={classNames(
      'flex flex-nowrap p-4 rounded-md items-center gap-3 bg-surface',
      props.className,
    )}
  >
    {props.icon && (
      <Icon
        className={classNames(props.title ? 'h-9' : 'h-6', 'w-auto shrink-0')}
        icon={props.icon}
      />
    )}

    <div className="text-sm flex-auto">
      {Boolean(props.title) && (
        <div className="font-bold text-base flex">{props.title}</div>
      )}
      {props.children}
    </div>
  </div>
);
