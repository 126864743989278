/**@prettier */
import React, { useMemo, FC } from 'react';
import { Tab as HeadlessTab } from '@headlessui/react';
import type { TabProps } from './types';
import TabBar from './TabBar';
import TabPanel from './TabPanel';
import classNames from 'classnames';

export interface TabsProps {
  className?: string;
  disabled?: boolean;
  tabs: TabProps[];
  defaultValue?: string;
  onSelect?: (value: string) => void;
  manual?: boolean;
  size?: 'default' | 'lg';
  type?: 'default' | 'icon' | 'rounded';
  customBorder?: boolean;
  customFont?: boolean;
  customSpacing?: boolean;
  customPadding?: boolean;
  tabClassName?: string;
  tabListClassName?: string;
  iconClassName?: string;
  overflowScroll?: boolean;
}

// eslint-disable-next-line react/display-name
const TabGroupComponent = React.forwardRef<
  HTMLDivElement,
  { className?: string }
>(({ children, ...rest }, ref) => (
  <div {...rest} ref={ref}>
    {children}
  </div>
));
export const TabsContext = React.createContext<
  (TabsProps & { selectedTab?: string }) | null
>(null);

const TabsProvider: React.FC<TabsProps & { selectedIndex: number }> = (
  props,
) => {
  const { selectedIndex, tabs, children, className, ...rest } = props;
  const contextProps = useMemo(
    () => ({
      ...rest,
      tabs,
      tabListClassName: classNames(
        rest.tabListClassName,
        rest.overflowScroll && 'overflow-touch-scroll-x',
      ),
      selectedTab: tabs?.[selectedIndex]?.key,
    }),
    [selectedIndex, rest, tabs],
  );
  return (
    <TabsContext.Provider value={contextProps}>
      {!children && tabs.length > 0 ? (
        <TabBar className={className} />
      ) : (
        children
      )}
    </TabsContext.Provider>
  );
};

const Tabs: FC<TabsProps> & {
  Bar: React.FC<{ className?: string }>;
  Panel: React.FC<{ className?: string }>;
} = (props) => {
  const { tabs, defaultValue, manual, className } = props;

  const handleChange = (index) => {
    props.onSelect && props.onSelect(tabs[index].key);
  };

  const defaultIndex = useMemo(() => {
    const index = tabs.findIndex((item) => item.key === defaultValue);
    return index === -1 ? 0 : index;
  }, [defaultValue, tabs]);

  return (
    <HeadlessTab.Group
      as={TabGroupComponent}
      manual={manual}
      onChange={handleChange}
      defaultIndex={defaultIndex}
      className={className}
    >
      {({ selectedIndex }) => (
        <TabsProvider
          selectedIndex={selectedIndex}
          {...props}
          // Don't pass along the className, otherwise it will be placed in the
          // DOM twice
          className={undefined}
        />
      )}
    </HeadlessTab.Group>
  );
};

export default Tabs;
Tabs.defaultProps = {
  type: 'default',
  size: 'default',
};
Tabs.Bar = TabBar;
Tabs.Panel = TabPanel;
