/** @format */

import React from 'react';
import { ProjectTeamGlobalSkeleton } from '../projects/ProjectSkeleton';
import { TeamContext, type TeamMember, sortTeamMembers } from './TeamContext';
import { TeamProjectMemberRow } from './TeamProjectMemberRow';
import { TeamProjectInviteRow } from './TeamProjectInviteRow';
import { TeamProjectInviteForm } from './TeamProjectInviteForm';
import Avatar from '../avatar/Avatar';
import { getFirstCharacter } from 'javascripts/helpers/get-first-character';
import { filterDataUrl } from '../projects/ProjectsGroupLabel';
import classNames from 'classnames';
import { TeamGlobalInviteLink } from './TeamGlobalInviteLink';
import { TeamPlanBadge } from './TeamPlanBadge';

export const TeamGlobalManage: React.FC = () => {
  const { isTeamFull, isLoadingTeamData, teamData } =
    React.useContext(TeamContext);

  if (isLoadingTeamData) {
    return <ProjectTeamGlobalSkeleton />;
  }

  if (!teamData) {
    return <div>{`No team found`}</div>;
  }

  const activeMembers = sortTeamMembers(teamData.active_and_pending_members);
  const archivedMembers = sortTeamMembers(teamData.archived_members);
  const invitedMembers = teamData.unregistered_invitations;

  const Heading: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <h2 className="pb-4 mt-6 mb-4 border-b border-border">
      <span className="text-xs font-semibold tracking-wider uppercase text-black/70">
        {children}
      </span>
    </h2>
  );

  const MemberList: React.FC<{
    members: TeamMember[];
    isArchived: boolean;
  }> = ({ members, isArchived }) => (
    <div className="space-y-4">
      {members.map((member) => (
        <div key={member.id} className="-ml-1.5">
          <TeamProjectMemberRow
            member={member}
            showTwoFactor={true}
            showProjectAccess={false}
            isArchived={isArchived}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <div className="flex items-center pt-4 pb-6 border-b border-border">
        <Avatar
          className="flex-shrink-0"
          size="llg"
          borderColor="border-white"
          initial={getFirstCharacter(teamData.name_raw)}
          image={filterDataUrl(teamData.logo_url)}
          rounded={false}
        />
        <div className="flex-auto pb-1 ml-3 ">
          <div className="">
            <span className="mb-1 text-lg">{teamData.name_raw}</span>
            <div>
              <TeamPlanBadge planName={teamData.plan_name} />
            </div>
          </div>
        </div>

        <div className="">
          <TeamGlobalInviteLink />
        </div>
      </div>
      <div className="mt-6">
        <TeamProjectInviteForm />
        <div>
          <Heading>
            <>{`Active Team Members `}</>
            {teamData.plan_name === 'Standard' ? (
              <span
                className={classNames(isTeamFull && '!text-brand-pink')}
              >{`(${activeMembers.length}/${teamData.max_users})`}</span>
            ) : (
              <>{`(${activeMembers.length})`}</>
            )}
          </Heading>
          <MemberList members={activeMembers} isArchived={false} />

          {invitedMembers.length > 0 && (
            <>
              <Heading>{`Pending Invitations (${invitedMembers.length})`}</Heading>
              <div className="space-y-4">
                {invitedMembers.map((invite) => (
                  <div key={invite.invitation_id} className="-ml-1.5">
                    <TeamProjectInviteRow invite={invite} />
                  </div>
                ))}
              </div>
            </>
          )}

          {archivedMembers.length > 0 && (
            <>
              <Heading>{`Archived Team Members (${archivedMembers.length})`}</Heading>
              <MemberList members={archivedMembers} isArchived={true} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
