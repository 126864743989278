window.TrialActions = alt.createActions(function () {

  this.start = function (team_id) {
    this.dispatch(team_id);
  };

  this.started = function (team_id) {
    this.dispatch(team_id);
  };

});
