/** @format */
import React from 'react';
import { type LucideIcon } from 'lucide-react';
import Tooltip from '../feedback/tooltip/Tooltip';

interface ProjectContentsToolbarButtonProps {
  onClick: () => void;
  Icon: LucideIcon;
  label?: string;
}

export const ProjectContentsToolbarButton: React.FC<
  ProjectContentsToolbarButtonProps
> = ({ onClick, Icon, label }) => {
  const IconContents: React.FC = () => (
    <div
      className={`flex-shrink-0 cursor-pointer w-8 h-8 hover:bg-white/10 rounded-md flex items-center justify-center`}
      onClick={onClick}
    >
      <Icon className="w-4 h-4" />
    </div>
  );
  return label ? (
    <Tooltip title={label} placement="top" distance={20}>
      <div>
        <IconContents />
      </div>
    </Tooltip>
  ) : (
    <IconContents />
  );
};
