/** @prettier */
import * as React from 'react';
import type { IFrame } from '../../types/frame';
import { FrameSelect } from './FrameSelect';
import FrameStatusIndicator from '../frames/editor/FrameStatusIndicator';
import DisclosureIcon from 'blackbird/images/icons/disclosure-left.svg';
import { useTranslation } from 'react-i18next';
import { type IconTabItem, IconTabBar } from './IconTabBar';
import { GeneratorContext } from 'blackbird/components/generator/GeneratorContext';

interface Props {
  activeIndex: number;
  frames: IFrame[];
  showFrameStatus: boolean;
  onSetIndex: (newIndex: number) => void;
}

export const FramePanelBarNavigator: React.FC<Props> = ({
  onSetIndex,
  ...props
}) => {
  const { t } = useTranslation();
  const { isGenerating } = React.useContext(GeneratorContext);
  const handleSelectChange = React.useCallback(
    (value: string) => {
      onSetIndex(parseInt(value, 10));
    },
    [onSetIndex],
  );

  // Removing as this was sending 40K events a day
  // React.useEffect(() => {
  //   // This may be better in a store to get additional context,
  //   // e.g. panelbar open, comments open, etc.
  //   Track.event.defer('frame_focus', {
  //     context: eventSource(undefined),
  //     isGuest: typeof BoordsConfig.Uid === 'undefined',
  //     category: 'Product',
  //   });
  // }, [props.activeIndex]);

  const IconTabs: IconTabItem[] = [
    {
      icon: <DisclosureIcon />,
      name: 'prev',
      active: true,
      title: t('actions.frames.navigateToPrevious'),
      onClick: () => onSetIndex(props.activeIndex - 1),
      disabled: props.activeIndex === 0 || isGenerating,
    },
    {
      icon: <DisclosureIcon />,
      name: 'next',
      active: true,
      iconClassName: 'rotate-180',
      title: t('actions.frames.navigateToNext'),
      onClick: () => onSetIndex(props.activeIndex + 1),
      disabled: props.activeIndex === props.frames.length - 1 || isGenerating,
    },
  ];

  const currentFrame = props.frames[props.activeIndex];
  return (
    <div className="flex items-center -mt-0.5">
      <div className="flex items-center mr-2 shrink-0 gap-2">
        <FrameSelect
          label={`Frame ${currentFrame.number || props.activeIndex + 1}`}
          value={props.activeIndex}
          onChange={handleSelectChange}
          frames={props.frames}
        />
        {props.showFrameStatus && (
          <div className="flex items-center">
            <FrameStatusIndicator frame={currentFrame} size={12} />
          </div>
        )}
      </div>

      <div className="flex justify-end flex-auto">
        {props.children && (
          <>
            {props.children}
            <div className="mr-4" />
          </>
        )}

        <IconTabBar icons={IconTabs} />
      </div>
    </div>
  );
};
