/** @prettier */
import { isString, map } from 'underscore';
import type { FrameField } from '../types/storyboard';
import type { frameFieldId } from 'javascripts/types/frame';

/**
 * runs `callback` for every frame field, except for those that are disabled.
 * It will return an array with either the results from the `callback` or `null`
 * if undefined. The `label` parameter to the callback will default to "Field
 * [number]" if it is blank */
export const eachFrameFieldMap = <T = unknown>(
  userFrameFields: FrameField[],
  callback: (
    frameField: FrameField,
    fieldId: frameFieldId,
    index: number,
    label: string,
  ) => T,
) =>
  map(userFrameFields, ({ id, isEnabled }, i) => {
    if (isEnabled) {
      if (!id) throw new Error('frame field does not have id');
      const field = userFrameFields[i];
      let label = field.label;

      if (!label || (isString(field.label) && field.label.trim() === '')) {
        label = `Field ${i + 1}`;
      }

      return callback(field, id, i, label);
    } else {
      return null;
    }
  });
