const defaultWaveform = require('./defaultWaveform');
const Rollbar = require('../../helpers/rollbar').rollbar

let parserCache = {};
function getMedian(args) {
  if (!args.length) { return 0 };
  var numbers = args.slice(0).sort((a, b) => a - b);
  var middle = Math.floor(numbers.length / 2);
  var isEven = numbers.length % 2 === 0;
  return isEven ? (numbers[middle] + numbers[middle - 1]) / 2 : numbers[middle];
}

module.exports = function waveformParser(imageUrl, callback) {
  if (!imageUrl) return callback(null);

  const startParsing = image => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;

    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, canvas.width, canvas.height);

    const imageData = context.getImageData(0, 0, image.width, image.height).data;
    const outputData = new Array(image.width);

    for (let i = 0; i < imageData.length; i += 4) {
      const col = (i / 4) % image.width;

      let newValue = imageData[i] > 200 ? 0 : 1
      outputData[col] = (outputData[col] || 0) + (newValue / image.height);
    }

    parserCache = {};
    let highRange = outputData.filter(current => current > .5);

    parserCache[imageUrl] = {
      data: outputData,
      // Collect what is the most common value within the higher range of volume
      // used for "normalizing" the waveform
      highAvg: getMedian(highRange)
    };

    return callback(parserCache[imageUrl]);
  }

  if (parserCache[imageUrl]) {
    callback(parserCache[imageUrl]);
  } else {
    const image = new Image()
    image.crossOrigin = "anonymous";
    image.src = imageUrl;
    image.onload = () => startParsing(image);
    image.onerror = () => {
      RequestActions.error('Could not fetch the waveform');
      Rollbar.warning('Unable to fetch waveform for audio', { imageUrl });

      callback({
        data: defaultWaveform(10000),
        highAvg: .75,
        compressFunc: v => v // Don't manipulate this value for display
      })
    }
  }
}
