const _ = require('underscore');
/**
 * @prettier
 */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const can = require('../../../helpers/restricted-actions')();
const {
  GetPermission,
  createPermissionFlyoverOpener,
} = require('../../../helpers/has-permission');
const { default: Toggle } = require('blackbird/components/toggle/Toggle');
const {
  default: TextInput,
} = require('blackbird/components/form/text-input/TextInput');
const {
  default: Tooltip,
} = require('blackbird/components/feedback/tooltip/Tooltip');
const { withTranslation } = require('react-i18next');

const PanelbarShareablePassword = createReactClass({
  propTypes: {
    storyboard_id: PropTypes.number.isRequired,
    updateHeight: PropTypes.func,
  },

  getInitialState: function () {
    const store = ShareablePasswordStore.getState();
    return {
      has_changed: false,
      is_saving: false,
      is_loading: store.is_loading,
      password: store.password,
      passwordEnabled: !!store.password || false,
      originalPassword: store.originalPassword,
    };
  },

  _setHeight: function () {
    if (_.isFunction(this.props.updateHeight)) {
      setTimeout(() => this.props.updateHeight(), 50);
    }
  },

  componentDidMount: function () {
    ShareablePasswordStore.listen(this._onChange);
    if (!this.state.password) {
      ShareableActions.fetchPassword.defer({
        storyboard_id: this.props.storyboard_id,
      });
    }
  },

  componentWillUnmount: function () {
    ShareablePasswordStore.unlisten(this._onChange);
  },

  _onChange: function (state) {
    const newState = {
      password: state.password,
      is_saving: state.is_saving,
      is_active: false,
      is_loading: state.is_loading,
      originalPassword: state.originalPassword,
    };

    if (this.state.is_loading) newState.passwordEnabled = !!state.password;

    this.setState(newState);
    this._setHeight();
  },

  handleBlur() {
    setTimeout(() => this.savePassword(), 100);
  },

  handleKeyPress: function (e) {
    if (e.charCode == 13) {
      e.preventDefault();
      this.savePassword();
    }
  },

  slug: function (str) {
    str = str.toLowerCase();
    str = str.replace(/[^a-z0-9]+/g, '-');
    str = str.replace(/^-|-$/g, '');
    return str;
  },

  handlePasswordChange(e) {
    const password = e.target.value;
    this.setState({
      has_changed: true,
      is_saving: false,
      password: this.slug(password),
    });
  },

  handleTogglePassword(passwordEnabled) {
    if (can.access({ type: 'passwordProtection' })) {
      ShareableActions.savePassword({
        password: passwordEnabled ? this.state.originalPassword : '',
        storyboard_id: this.props.storyboard_id,
      });

      this.setState({
        passwordEnabled,
        password: passwordEnabled ? this.state.originalPassword : '',
      });
    }
  },

  savePassword: function () {
    if (this.state.has_changed) {
      ShareableActions.savePassword({
        password: this.state.password,
        storyboard_id: this.props.storyboard_id,
      });
      if (!_.isNull(ReactDOM.findDOMNode(this.refs.pword))) {
        ReactDOM.findDOMNode(this.refs.pword).blur();
      }
      this.setState({
        password: this.state.password,
        has_changed: false,
        is_saving: true,
      });
    } else {
      this.setState({
        is_active: false,
      });
    }
  },
  handleClick: function (e, hasPermission, featureKey) {
    if (hasPermission !== true) {
      createPermissionFlyoverOpener(BoordsConfig.Uid, featureKey)(e);
    }
  },
  render: function () {
    const featureKey = 'password_protection';
    const { t } = this.props;
    return (
      <div>

        {this.state.passwordEnabled && (
          <TextInput
            ref={`pword`}
            inputSize="md"
            name="password"
            className="mb-4"
            onKeyPress={this.handleKeyPress}
            onBlur={this.handleBlur}
            value={this.state.password}
            onChange={this.handlePasswordChange}
            placeholder={t('modals.share_storyboard.placeholder')}
          />
        )}

        {BoordsConfig.HasV3 ? (
        <div className="flex items-center">
          <div className='flex-auto'>{t('modals.share_storyboard.label')}</div>

          <Toggle
            onChange={this.handleTogglePassword}
            value={this.state.passwordEnabled}
          />
        </div>
        ) : (
        <div className="flex items-center">
          <GetPermission
            storyboardOwnerId={BoordsConfig.Uid}
            featureName={featureKey}
            identifier={`passwordProtection`}
          >
            {(hasPermission) => (
              <Tooltip
                disabled={hasPermission === true}
                title={t('warnings.permission.storyboard.password_protect')}
              >
                <div
                  onClick={(e) =>
                    this.handleClick(e, hasPermission, featureKey)
                  }
                >
                  <Toggle
                    onChange={this.handleTogglePassword}
                    value={this.state.passwordEnabled}
                    disabled={hasPermission !== true}
                  />
                </div>
              </Tooltip>
            )}
          </GetPermission>
          <div className="flex-auto ml-2 -mt-1.5">
            {t('modals.share_storyboard.label')}
          </div>
        </div>
        )}


      </div>
    );
  },
});
window.PanelbarShareablePassword = withTranslation()(PanelbarShareablePassword);
