const _ = require('underscore');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const PureRenderMixin = require('react-addons-pure-render-mixin');

module.exports = createReactClass({
  displayName: 'HoverControls',

  propTypes: {
    className: PropTypes.string,
    timeout: PropTypes.number,
    children: PropTypes.node,
    showOnLaunch: PropTypes.bool,
    force: PropTypes.bool
  },

  mixins: [PureRenderMixin],

  getDefaultProps() {
    return {
      showOnLaunch: true,
      force: false,
    }
  },

  getInitialState() {
    return {
      userActive: this.props.force || this.props.showOnLaunch,
      timeout: this.props.timeout || BoordsConfig.IsTouchDevice ? 4000 : 1500
    }
  },

  _isMounted: false,
  // Overwritten in componentDidMount
  scheduleSetInactive: () => { },

  componentDidMount() {
    if (BoordsConfig.IsTouchDevice) return
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('click', this.onMouseMove);
    this._isMounted = true;

    this.scheduleSetInactive = _.debounce(() => {
      if (!this._isMounted) return;
      // Ideally, we'd cancel the debounce here, but underscore.js does not
      // allow for this (Lodash does). That's why we have to keep track
      // ourselves

      this.setState({ userActive: false });
    }, this.state.timeout);
    this.scheduleSetInactive();
  },

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('click', this.onMouseMove);
    this._isMounted = false;
  },

  onMouseMove() {
    this.setState({ userActive: true });
    this.scheduleSetInactive();
  },

  render() {
    const show = this.props.force || this.state.userActive;

    return (
      <div
        className={'transition-opacity duration-300 ' + this.props.className}
        style={{ opacity: show ? 1 : 0 }}
      >{this.props.children}</div>
    );
  }
})
