/** @prettier */
import classNames from 'classnames';
import * as React from 'react';

type Props = React.SelectHTMLAttributes<HTMLSelectElement> & {
  focusClassName?: string;
  label?: string;
};

export const Select: React.FunctionComponent<Props> = (props) => {
  const [hasFocus, setHasFocus] = React.useState(false);

  let color = props.disabled ? 'border--border opacity-50' : 'border--border';
  if (hasFocus) color = props.focusClassName ?? 'border-border-dark';

  return (
    <div
      className={`rounded-sm w-full transition-opacity bg-white border ${color} ${
        props.label ? 'pv2 shadow-btn' : 'bw1 ba '
      } relative`}
    >
      {props.label && (
        <label className="pl-6 mt-4 text-sm block text-type-subdued w-full">
          {props.label}
        </label>
      )}

      <div className="flex items-center">
        <select
          {...props}
          className={classNames(
            'w-full pl-3 pb-3 truncate bg-white rounded-sm leading-none flex-auto ',
            props.label ? 'pt-2' : 'pt-3',
          )}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        >
          {props.children}
        </select>

        <i
          className={`boords-icon--arrow-grey-down-small absolute right-0 bottom-0 mr-2  ${
            props.label ? 'mb-8' : 'mb-6'
          } pointer-events-none`}
        />
      </div>
    </div>
  );
};
