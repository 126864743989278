/** @prettier */
import { createTypedActions } from '../stores/AltStore';

class StoryboardAnalysisActions {
  scheduleAnalysis = function() {
    this.dispatch();
  };
  init = function() {
    this.dispatch();
  };
}

const actions = createTypedActions<StoryboardAnalysisActions>(
  StoryboardAnalysisActions,
);
export { actions as StoryboardAnalysisActions };

// This is not how it's supposed to be, but so many views rely on this, that
// it's better to keep it like this until every instance is updated to use
// actual imports
(window as any).StoryboardAnalysisActions = actions;
