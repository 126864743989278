/** @format */
import type { FC, PropsWithChildren, ReactNode } from 'react';
import React from 'react';
import { isArray } from 'underscore';

interface ComponentComposerProps<T> {
  items: Array<[FC<T>, T] | FC<PropsWithChildren<unknown>>>;
  children: ReactNode;
}

/** Nests the components passed into each other, in order to prevent huge
 * amounts of nested context providers.
 * Based on https://gist.github.com/sstackus/f351ca6b3c0fed39d3f21e96a02a480c */
export const ComponentComposer = <T extends PropsWithChildren<unknown>>({
  items,
  children,
}: ComponentComposerProps<T>): any =>
  items.reduceRight<ReactNode>((acc, def) => {
    let Component: React.FunctionComponent;
    let props: Record<string, unknown> | undefined = undefined;
    if (isArray(def)) {
      Component = def[0];
      props = def[1];
    } else {
      Component = def;
    }
    return React.createElement(Component, props, acc);
  }, children);
