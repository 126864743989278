/**
 * @prettier
 */
const _ = require('underscore');
const screensizeMonitor = require('../../components/shared/screenSizeMonitor');
const sidebarPrefKey = 'focusSidebarPreference';
const { PlayerActions } = require('../actions/player');
const { FrameEditorActions } = require('../actions/frame_editor');
import { focusModeLocalState } from 'javascripts/helpers/local-state';
import logger from 'javascripts/helpers/logger';
import { eventSource } from 'blackbird/helpers/eventContextHelper';
import { ToursActions, tourEvents } from '../actions/tours';
require('../actions/frame_focus');

(() => {
  class FrameFocusStore {
    constructor() {
      this.allowedModes = ['frameEditor', 'player'];
      this.screenSizeBracket = 'frameFocus';
      this.activeIndex = 0;
      this.activeFrame = null;
      this.mode = null;
      this.userWantsSidebar = LocalState.getValue(sidebarPrefKey);
      this.hasSidebar = false;

      screensizeMonitor.register(this.screenSizeBracket, 1010);

      this.bindListeners({
        handleInit: FrameFocusActions.INIT,
        handleSetFrame: FrameFocusActions.SET_FRAME,
        handleSetFrameIndex: FrameFocusActions.SET_FRAME_INDEX,
        handleToggleSidebar: FrameFocusActions.TOGGLE_SIDEBAR,
        handleSetMode: FrameFocusActions.SET_MODE,
        handleClose: FrameFocusActions.close,
      });

      this.handleWindowResize = (newValue) => {
        if (_.isUndefined(this.userWantsSidebar)) {
          this.hasSidebar = newValue ? newValue : this.userWantsSidebar;
          this.emitChange();
        }
      };
    }

    handleInit({ mode, frameIndex }) {
      // If we've already set a mode (through the router) we actually do not
      // want to use the mode prop. Otherwise we have this weird bug that you
      // have to switch modes twice for it to work

      this.mode = this.mode || mode;
      this.handleSetFrameIndex({ index: Math.max(0, frameIndex) });

      this.hasSidebar = _.isUndefined(this.userWantsSidebar)
        ? screensizeMonitor.get(this.screenSizeBracket)
        : this.userWantsSidebar;

      screensizeMonitor.listen(this.screenSizeBracket, this.handleWindowResize);
    }

    handleClose() {
      ToursActions.triggerEvent.defer(tourEvents.closeAnimatic);
      screensizeMonitor.unlisten(
        this.screenSizeBracket,
        this.handleWindowResize,
      );
      this.mode = null;

      if (this.activeFrame)
        CommentActions.setActiveFrame.defer(this.activeFrame.id);

      setTimeout(FrameActions.deselectAll.defer, 100);
    }

    handleSetFrame({ frame, persist = true }) {
      if (!frame) throw new Error('no frame passed in options');
      this.activeFrame = frame;
      this.activeIndex = FrameStore.getState().frames.indexOf(frame);
      persist && this.afterSetFrame(frame);
    }

    handleSetFrameIndex({ index, persist = true, retryCount = 0 }) {
      if (_.isUndefined(index)) throw new Error('no frame passed in options');
      const newFrame = FrameStore.getState().frames[index];

      /**
       * Sometimes the frame data isn't loaded yet, and we can't find the frame
       * we're looking for, so we want to retry, so we don't leave the flyover
       * in a loading state
       */
      const retryInterval = 300;

      if (newFrame) {
        this.activeIndex = index;
        this.activeFrame = newFrame;
        persist && this.afterSetFrame(newFrame);
      } else if (retryCount > 5) {
        index = parseInt(index);
        RequestActions.error.defer({
          key: 'frames.errors.noFrameWithIndex',
          data: {
            frameIndex: index + 1,
          },
        });
      } else {
        retryCount++;
        setTimeout(
          () =>
            FrameFocusActions.setFrameIndex.defer({
              index,
              persist,
              retryCount,
            }),
          retryInterval,
        );
      }
    }

    afterSetFrame(frame) {
      if (!this.allowedModes.includes(this.mode))
        throw new Error(
          `Mode '${
            this.mode
          }' doesn't exist, has to be one of ${this.allowedModes.join(', ')}`,
        );

      if (this.mode === 'frameEditor') {
        FrameEditorActions[
          FrameEditorStore.getState().initialized ? 'updateFrame' : 'open'
        ].defer(frame.id);
      } else if (this.mode === 'player') {
        if (PlayerStore.getState().initialized) {
          PlayerActions.goToFrame.defer(frame);
        } else {
          PlayerActions.open.defer({
            frame,
            isEditable: window.BoordsConfig.TeamRole !== 'guest',
            storyboard: StoryboardStore.getState().storyboard,
          });
        }
      }

      focusModeLocalState.setValue(this.mode);
    }

    handleToggleSidebar(newValue) {
      this.hasSidebar = _.isUndefined(newValue) ? !this.hasSidebar : newValue;
      this.userWantsSidebar = this.hasSidebar;
      LocalState.setValue(sidebarPrefKey, this.hasSidebar);
    }

    handleSetMode(newMode) {
      this.mode = newMode;

      this.afterSetFrame(this.activeFrame);
    }
  }

  window.FrameFocusStore = alt.createStore(FrameFocusStore, 'FrameFocusStore');
})();
