/** @format */
import * as React from 'react';
import { FrameEditorContext } from '../frame_editor/FrameEditorContext';
import type { frameEditorShapeTypes } from '../frame_editor/shapes';
import ArrowIcon from 'blackbird/images/icons/shape-arrow.svg';
import RectangleIcon from 'blackbird/images/icons/shape-square.svg';
import TriangleIcon from 'blackbird/images/icons/shape-triangle.svg';
import CircleIcon from 'blackbird/images/icons/shape-circle.svg';
import HexagonIcon from 'blackbird/images/icons/shape-hexagon.svg';
import LineIcon from 'blackbird/images/icons/shape-line.svg';
import Icon from 'blackbird/components/icon/Icon';
import { useDrag } from 'react-dnd';
import {
  DRAGGABLE_TYPES,
  type frameEditorShapeDragItem,
} from '../shared/dragAndDropUtils';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type shapeItem = {
  key: frameEditorShapeTypes;
  icon: React.ReactElement;
};

const shapes: shapeItem[] = [
  { key: 'arrow', icon: <ArrowIcon /> },
  { key: 'rect', icon: <RectangleIcon /> },
  { key: 'triangle', icon: <TriangleIcon /> },
  { key: 'ellipse', icon: <CircleIcon /> },
  { key: 'straightBrush', icon: <LineIcon /> },
  { key: 'hexagon', icon: <HexagonIcon /> },
];

const PanelbarShapeItem: React.FC<{
  shape: shapeItem;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  const { t } = useTranslation();
  const shape = props.shape;

  const [{ isDragging }, ref] = useDrag(() => ({
    type: DRAGGABLE_TYPES.frameEditorShape,
    item: {
      type: shape.key,
    } as frameEditorShapeDragItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={ref}
      className={classNames(
        'p-3 border hover:bg-surface border-border rounded-md transition-opacity',
        isDragging ? 'cursor-grabbing' : 'cursor-grab',
        {
          'opacity-60': isDragging,
        },
      )}
      title={t('frameEditor.toolbar.shapes.' + shape.key)}
    >
      <Icon className="w-full h-auto pointer-events-none" icon={shape.icon} />
    </div>
  );
};

export const PanelbarShapes: React.FC = () => {
  const context = React.useContext(FrameEditorContext);
  const handleClickShape = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (e) => {
      const shapeToCreate = e.currentTarget.name as frameEditorShapeTypes;
      if (!shapeToCreate)
        throw new Error('Button needs to have a name property');
      if (!context)
        throw new Error("frame editor can't be found in the context");
      context.insertShape(shapeToCreate);
    },
    [context],
  );

  return (
    <div className="grid grid-cols-3 gap-4">
      {shapes.map((shape) => (
        <PanelbarShapeItem
          shape={shape}
          key={shape.key}
          onClick={handleClickShape}
        />
      ))}
    </div>
  );
};
