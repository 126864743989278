/** @format */
import type { StoryboardStore } from 'javascripts/flux/stores/storyboard';
import { last, some } from 'underscore';
import { useStore } from './useStore';

export const useIsOutdatedStoryboardVersion = () => {
  return useStore<[boolean, string | undefined], StoryboardStore>(
    'storyboard',
    ({ storyboard }) => {
      const hasNewerVersion =
        storyboard &&
        some(
          storyboard.versions,
          (i) => i.version_number > storyboard.version_number,
        );

      return [
        hasNewerVersion,
        hasNewerVersion
          ? `/storyboards/${last(storyboard.versions)?.slug}`
          : undefined,
      ];
    },
  );
};
