/** @prettier */
import * as React from 'react';
import * as UnsplashSearch from '../../frame_editor/toolbar/image_search/UnsplashSearch.jsx';
import { FrameActions } from '../../../flux/actions/frame';
import axios from 'axios';
import type { ReplaceProps, AddProps } from './ImageSources';
import type { IPanelProps } from '../../shared/MultiPanel';
import { FileUploadActions } from '../../../flux/actions/file_upload';
import '../../../flux/actions/hoverOverlay';
import { RequestActions } from 'javascripts/flux/actions/request';

type Props = (ReplaceProps | AddProps) & { width?: number } & IPanelProps;

export class UnsplashPanel extends React.PureComponent<Props> {
  handleImageAdd = (url) => {
    RequestActions.success.defer('Preparing image…');

    // Fetch the image, and convert it to a File, so we can upload it again.
    // via https://stackoverflow.com/a/38935544/847689
    axios.get(url, { responseType: 'arraybuffer' }).then((r) => {
      const fileName = new URL(url).pathname.slice(1) + '.jpg';
      const file = new File([r.data], fileName, {
        type: r.headers['content-type'],
      });

      if (this.props.replace && this.props.currentFrame) {
        FileUploadActions.updateFrame({
          file: file,
          frame_aspect_ratio: this.props.storyboard.frame_aspect_ratio,
          frame: this.props.currentFrame,
          team_id: this.props.storyboard.project.owner.id,
        });
      } else {
        FrameActions.bulkFrameUpload({
          storyboard_id: this.props.storyboard.id,
          files: [file],
          callback: () => {
            RequestActions.success.defer('Frame added!');
          },
        });
      }
      HoverOverlayActions.closeAll.defer();
    });
  };

  render() {
    return (
      <div className="pa3 flex" style={{ height: 400 }}>
        <UnsplashSearch
          onAddImageByUrl={this.handleImageAdd}
          gridColumns={this.props.width && this.props.width < 300 ? 2 : 3}
          embed
        />
      </div>
    );
  }
}
