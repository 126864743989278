/** @prettier */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';

interface PaymentTaxIdFormProps {
  callback?: any;
}

const PaymentTaxIdForm: React.FC<PaymentTaxIdFormProps> = ({ callback }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<any>(undefined);

  const FetchUserAsync = async () => {
    const fetchUser = await fetch('/api/me', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-KEY': BoordsConfig.AuthenticationToken,
      },
    });

    const res = await fetchUser.json();

    if (fetchUser.ok) {
      setUser(res.data[0].attributes);
    }
  };

  useEffect(() => {
    FetchUserAsync();
  }, []);

  const UpdateUserAsync = async (args) => {
    const updateUser = await fetch(`/api/me/${BoordsConfig.Uid}`, {
      method: 'put',
      body: JSON.stringify({
        data: {
          attributes: {
            vat_number: args.vatNumber,
            country: args.country,
          },
        },
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-KEY': BoordsConfig.AuthenticationToken,
      },
    });

    await updateUser.json();

    if (updateUser.ok) {
      callback();
    }
  };

  if (!user) {
    return (
      <div className="relative flex-auto">
        <LoadingIndicator />
      </div>
    );
  } else {
    const countryFieldOptions = {
      name: 'user[country]',
      placeholder: t('payment.placeholder'),
      options: user.all_countries,
      defaultValue: user.country,
    };

    const vatFieldOptions = {
      name: 'user[vat_number]',
      defaultValue: user.vat_number ? user.vat_number : '',
    };

    const onSubmit = (args) => {
      UpdateUserAsync(args);
    };

    return (
      <>
        <CountryAndTaxField
          countryFieldOptions={countryFieldOptions}
          vatFieldOptions={vatFieldOptions}
          onSubmit={onSubmit}
        />
      </>
    );
  }
};

export default PaymentTaxIdForm;
