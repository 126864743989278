/** @prettier */
import * as React from 'react';
import Picker from './font-picker-react';
import PDFFontList from '../pdf/PDFFontList';
import type { FontSettings } from '../pdf/types';
import type { Variant } from '@samuelmeuli/font-manager';
import logger from 'javascripts/helpers/logger';

interface Props {
  value?: FontSettings | null;
  onChange: (newFont: FontSettings) => void;
  onLoad?: () => void;
  subsets: string[];
  filterTTF?: boolean;
}

const filterFonts = (font) => {
  const firstValue: string[] = Object.values(font.files);
  return font.files && firstValue && firstValue[0].indexOf('.ttf') > 0;
};

/**
 * Turn urls into protocol-independent urls, because somehow Google doesn't
 * offer them like that by default */
const protocolIndependent = (src?: string) => src?.replace(/https?:/, '');

export const FontPicker: React.FC<Props> = (props) => {
  const useFilter = props.filterTTF ?? true;

  const onFontChange = (font) => {
    const files: Record<Variant, string | undefined> = font.files;

    if (files) {
      const src = files.regular || files['400'] || Object.values(files)[0];

      props.onChange({
        family: font.family,
        src: protocolIndependent(src)!,
        normal: protocolIndependent(src),
        italic: protocolIndependent(files['italic']),
        bold: protocolIndependent(files['700']),
        bolditalic: protocolIndependent(files['700italic']),
      });
    } else {
      logger.log('Font has no files', font);
    }
  };

  const families = props.subsets[0] === 'latin' ? PDFFontList : undefined;

  return (
    <Picker
      activeFontFamily={props.value ? props.value.family : (undefined as any)}
      apiKey={BoordsConfig.googleFontsApiKey}
      scripts={props.subsets as any}
      key={props.subsets[0]}
      sort="popularity"
      filter={useFilter ? filterFonts : undefined}
      families={families}
      onChange={onFontChange}
      onLoad={props.onLoad}
      // Some fonts we want to load don't make the default limit, so we tell the
      // font picker to load more
      limit={150}
    />
  );
};
