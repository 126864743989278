/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { UserPermissionsActions } from '../actions/user_permissions';
import * as _ from 'underscore';
import * as get from 'lodash.get';
import { type availablePlanSlugs } from '../../types/billing';
import { ajax } from 'javascripts/helpers/ajax';
import type { LiteralUnion } from 'type-fest';
import { indexBy } from 'underscore';

const api = require('../../helpers/api')();

const errorHandler = RequestErrorHandler('userFeatures');

// prettier-ignore
/** Keep this in sync with feature_restrictions.en.yml */
export const currentUserFeatures = ['activity_log', 'animatics', 'branding', 'comments', 'mp4', 'pdfs', 'shareable', 'password_protection', 'versioning', 'frame_fields', 'bulk_export', 'shared_invite_link', 'role_based_access', 'subdomain', 'twofactor', 'ai_image_credits'] as const

export type strictUserFeaturesType = typeof currentUserFeatures[number];

export type currentUserFeaturesType = LiteralUnion<
  typeof currentUserFeatures[number],
  string
>;

export interface UserFeatureCTA {
  title: string;
  description: string;
  intent: string;
  recommended_plan?: availablePlanSlugs;
}

interface UserFeatureList {
  [featureName: string]: {
    name: typeof currentUserFeatures;
    available: boolean;
    cta?: UserFeatureCTA;
  };
}
export class UserPermissionsStore extends FakeAltStoreClass<UserPermissionsStore> {
  features: { [id: number]: UserFeatureList } = {};
  fetched: number[] = [];

  constructor() {
    super();
    this.bindListeners({
      handleFetch: UserPermissionsActions.FETCH,
    });
  }

  handleFetch({ userId, force }: { userId: number; force?: boolean }) {
    if (!userId) throw new Error('Needs userId');

    // This is important. Depending on how components might be rendered,
    // the permission checks can fire in large bursts. Here we make sure we
    // only fetch each ID once
    if (this.fetched.indexOf(userId) >= 0 && !force) return;

    this.fetched.push(userId);

    ajax({
      method: 'get',
      url: api.setRailsApiUrl(`features/${userId}`),
      success: (response) => {
        const features = get(response, 'data.attributes.features') || [];
        this.features[userId] = indexBy(features, 'name');
        this.emitChange();
      },
      error: errorHandler({
        message: null,
      }),
    });
  }
}

(window as any).UserPermissionsStore = alt.createStore(
  UserPermissionsStore,
  'UserFeatures',
);
