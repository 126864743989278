/** @prettier */
import { IconButton } from 'blackbird/components/common/IconButton';
import * as React from 'react';
import { isFunction } from 'underscore';
import type * as PopperJS from '@popperjs/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { RequireAtLeastOne } from 'type-fest';

type Props = RequireAtLeastOne<
  {
    /** Translation key for the label */
    labelKey?: string;
    /** this will prefix either labelKey or value with `labelKeyPrefix` when
     * looking up the translation string  */
    labelKeyPrefix?: string;
    value?: string | number;
    name?: string;
    active?: boolean;
    hideHint?: boolean;
    labelPosition?: PopperJS.Placement;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | boolean;
    icon: NonNullable<React.ReactElement>;
    disabled?: boolean;
    hideTooltip?: boolean;
    className?: string;
    activeClassName?: string;
    small?: boolean;
  },
  'labelKey' | 'value'
>;

export const ToolbarButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      hideTooltip = false,
      small = false,
      labelKey,
      className,
      activeClassName,
      value,
      active,
      disabled,
      onClick,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation(undefined, {
      keyPrefix: props.labelKeyPrefix,
    });
    const tapTimeStamp = React.useRef<number | undefined>(undefined);
    const isFunctional = !disabled && isFunction(onClick);
    const handleClick = React.useCallback(
      (e) => {
        if (!isFunctional) return;

        const timeStamp = e.timeStamp;

        if (tapTimeStamp.current) {
          const diff = timeStamp - tapTimeStamp.current;
          if (diff < 400) {
            // Prevent iPads from zooming in on double tap
            e.preventDefault();
          }
        }

        tapTimeStamp.current = timeStamp;
        onClick(e);
      },
      [onClick, tapTimeStamp, isFunctional],
    );

    return (
      <IconButton
        ref={ref}
        aria-label={t(String(labelKey ?? value))}
        name={props.name}
        className={classNames(
          'group rounded -m-1.5',
          active && activeClassName
            ? activeClassName
            : active
            ? 'bg-surface'
            : 'hover:bg-surface-light',
          className,
        )}
        onClick={handleClick}
        iconClassName={classNames(
          small ? 'h-6' : 'h-9',
          isFunctional &&
            'group-hover:fill-brand-pink group-hover:text-brand-pink',
        )}
        tooltipDistance={10}
        icon={props.icon}
        value={value}
        disabled={!isFunctional}
        labelPlacement={props.labelPosition ?? 'top'}
        hasTooltip={!hideTooltip}
      />
    );
  },
);

ToolbarButton.displayName = 'ToolbarButton';
