const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const { FrameCommentCount } = require('../../frames/FrameCommentCount.react');
const FrameStatusIndicator = require('../../frames/editor/FrameStatusIndicator');
const {default:Img} = require("../../shared/Img");
const navigateToRoute = require('../../../helpers/router/navigate-to-route')
const BEMHelper = require('react-bem-helper');
const { ShareableItemInfo } = require('./ShareableItemInfo');
const { getFrameImage } = require('javascripts/helpers/getFrameImage');
const { Watermark } = require('blackbird/components/watermark/Watermark');
const {
  getErrorFrameImageURL,
} = require('javascripts/helpers/getMissingFrameImageURL');
const bemHelper = new BEMHelper({
  name: 'grouped-frame-shareable',
  outputIsString: true
});

module.exports = createReactClass({
  displayName: 'ShareableGridItem',

  propTypes:     {
    storyboard:  PropTypes.object.isRequired,
    frame:       PropTypes.object.isRequired,
    index:       PropTypes.number.isRequired,
    grouped:     PropTypes.oneOf([true, false, 'start', 'end']),
    zoomType:    PropTypes.oneOf(['player', 'zoom']),
    commentCount: PropTypes.number,
  },

  getInitialState: function() {
    return {
      commentsEnabled: this.props.storyboard.has_comments_enabled
    }
  },

  handleHover() {
    ShareableActions.setActiveIndex(this.props.index);
  },

  handleClick(e) {
    e.preventDefault();
    navigateToRoute(this.props.zoomType, {
      frameIndex: this.props.index,
      slug: this.props.storyboard.permaslug
    })
  },

  render: function(){

    const index = this.props.index;
    const frame = this.props.frame;
    const imageClass = (this.props.storyboard.has_frame_border) ? " has-frame-border" : "";
    const { width, height } = BoordsFrameSizeHelper(this.props.storyboard.frame_aspect_ratio)

    const className = this.props.grouped && bemHelper(null, [
      'shareable',
      this.props.grouped === 'start' && 'start',
      this.props.grouped === 'end' && 'end'
    ])

    return (
      <li
        className={`shareable__grid__item flex flex-col gap-4 relative ${className}`}
        onMouseEnter={this.handleHover}
      >
        <div
          className={"cursor-zoom-in " + imageClass}
          onClick={this.handleClick}>

          {this.props.storyboard.preferences.share_with_frame_status &&
            <div className={'absolute -top-2 -right-2 z-10'}>
              <FrameStatusIndicator frame={frame} />
            </div>}
          <Watermark>
            <Img
              src={getFrameImage(this.props.frame.thumbnail_image_url, this.props.storyboard.frame_aspect_ratio)}
              width={width}
              height={height}
              placeholder={getErrorFrameImageURL(this.props.storyboard.frame_aspect_ratio)}
              log
            />
          </Watermark>
        </div>
        <ShareableItemInfo storyboard={this.props.storyboard} frame={frame} index={index} frame_fields={this.props.storyboard.frame_fields} labelsHaveBackground={true} />

        {this.props.commentCount && this.state.commentsEnabled && (
          <div className="absolute top-0 left-0 p-3">
            <FrameCommentCount
              count={this.props.commentCount}
              onClick={this.handleClick}
            />
          </div>
        )}
      </li>
    );
  }

});
