/** @prettier */
import logger from 'javascripts/helpers/logger';
import React from 'react';
import { CommentActions } from '../../flux/actions/comment';
import { FilestackActions } from '../../flux/actions/filestack';
import { FrameActions } from '../../flux/actions/frame';
import { PanelbarActions } from '../../flux/actions/panelbar';
import type { GroupInfo } from '../../flux/stores/frame';
import { hasPermission } from '../../helpers/has-permission';
import navigateToRoute from '../../helpers/router/navigate-to-route';
import { frameUndoManager, undoManager } from '../../helpers/undo-stacks';
import { IFrame } from '../../types/frame';
import { IStoryboardInStore } from '../../types/storyboard';
import FrameStatusPopupMenu from '../popup_menus/FrameStatusPopupMenu';
import FrameTransferPopupMenu from '../popup_menus/FrameTransferPopupMenu';
import { any, isUndefined, pick, pluck } from 'underscore';
import type { TOptions } from 'i18next';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';
import type { DialogContext } from 'blackbird/components/dialog/DialogContext';

export interface FrameUserActionInfo {
  /** The translation string to use as this action's label **/
  labelKey: string;
  /** Data to pass to the translation function for purposes of pluralisation */
  labelData?: TOptions;
  /* is this action currently available or technically possible */
  condition?: boolean;
  /** @deprecated this should happen in the consuming component */
  className?: string;
  onClick?: (e: React.MouseEvent<unknown>) => void;
  /** Component to render instead of a button */
  component?: () => React.ReactNode;
  disabled?: boolean;
}

// prettier-ignore
export type FrameUserActionKeys = 'edit' | 'comment' | 'upload' | 'status' | 'clear' | 'transfer' | 'move' | 'duplicate' | 'group' | 'ungroup' | 'delete' | 'selectAll' | 'deselectAll' | 'undo' | 'redo'

/** Prefixes the translation string with the common `actions.frames` */
const prefix = (str: string) => 'actions.frames.' + str;

/**
 * This function will — based on some conditions — supply a list of allowed
 * actions for a frame. It returns something like this:
 * {
 *   edit: {
 *    label: 'Edit Frame',
 *    onClick: () => {},
 *    condition: true
 *    className: ''
 *   }
 *  …
 * }
 */
const FrameUserActions = (props: {
  isGuest: boolean;
  framesToOperateOn: IFrame[];
  storyboard: IStoryboardInStore;
  frameCount: number;
  groupInfo: Record<string, GroupInfo>;
  commentsEnabled: boolean;
  dialogContext?: typeof DialogContext;
}) => {
  const {
    isGuest,
    framesToOperateOn,
    storyboard,
    frameCount,
    groupInfo,
    ...other
  } = props;
  if (any([frames, storyboard, framesToOperateOn, groupInfo], isUndefined)) {
    logger.warn(
      'Some of the values required for FrameUserActions is undefined, got',
      { frames, storyboard, framesToOperateOn, groupInfo },
    );
  }

  const singleFrame = framesToOperateOn.length === 1 && framesToOperateOn[0];
  const hasAnyFrames = framesToOperateOn.length > 0;
  const commentsEnabled =
    other.commentsEnabled &&
    hasPermission(storyboard.project.owner.user_id, 'comments');

  /*** All the frames in the group the first item belongs to */
  const groupSiblings =
    groupInfo &&
    hasAnyFrames &&
    !!framesToOperateOn[0].group_id &&
    groupInfo[framesToOperateOn[0].group_id].frames;

  /*** Are selected frames the entire contents of the group, nothing more? */
  const entireGroupSelected =
    groupSiblings &&
    groupSiblings.length === framesToOperateOn.length &&
    groupSiblings.every((f) => framesToOperateOn.indexOf(f) > -1);

  /*** Do all the selected items share the same group? */
  const allFramesShareGroup =
    hasAnyFrames &&
    framesToOperateOn[0].group_id &&
    framesToOperateOn.every(
      (f) => f.group_id === framesToOperateOn[0].group_id,
    );

  const frameHasImage =
    singleFrame &&
    singleFrame.thumbnail_image_url.indexOf('assets/missing') === -1;

  const actions: Record<FrameUserActionKeys, FrameUserActionInfo> = {
    edit: {
      labelKey: prefix('open_editor'),
      condition: !isGuest && !!singleFrame,
      onClick: () =>
        navigateToRoute('frameEditor', {
          slug: storyboard.slug,
          frameIndex: singleFrame && singleFrame.sort_order,
        }),
    },

    comment: {
      labelKey: commentsEnabled
        ? 'comments.comment_on_frame'
        : 'comments.comments_disabled',
      condition: !!singleFrame,
      className: commentsEnabled ? '' : 'o-50',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (commentsEnabled && singleFrame) {
          CommentActions.openFrameCommentBox({ frame: singleFrame });
        } else {
          PanelbarActions.open('comments');
        }
      },
    },

    upload: {
      labelKey: prefix('upload_image'),
      condition: !isGuest,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();

        FilestackActions.openDialog({
          frame_aspect_ratio: storyboard.frame_aspect_ratio,
          storyboard_id: storyboard.id,
          frame: singleFrame as IFrame,
          multi: false,
          initial_source: 'local_file_system',
          team_id: storyboard.project.owner.id,
          replace: true,
        });
      },
    },

    status: {
      labelKey: prefix('set_status'),
      condition:
        !isGuest &&
        frameCount > 0 &&
        hasAnyFrames &&
        frameCount !== framesToOperateOn.length,
      onClick: (e) => {
        e.preventDefault();
      },
      component() {
        return <FrameStatusPopupMenu framesToOperateOn={framesToOperateOn} />;
      },
    },

    clear: {
      labelKey: prefix('remove_image'),
      condition: !isGuest && frameHasImage,
      onClick: (e) => {
        e.preventDefault();
        FrameActions.clearFrame.defer((singleFrame as IFrame).id);
      },
    },

    transfer: {
      labelKey: prefix('transfer'),
      condition: !isGuest && frameCount > 0 && hasAnyFrames && !!singleFrame,
      onClick: (e) => {
        e.preventDefault();
      },
      component() {
        return (
          <FrameTransferPopupMenu
            framesToOperateOn={framesToOperateOn}
            sourceStoryboardShortSlug={storyboard.short_slug}
            versionShortSlugs={storyboard.versions.map((v) => v.hashid)}
          />
        );
      },
    },

    move: {
      labelKey: prefix(entireGroupSelected ? 'move_group' : 'move_other'),
      condition:
        !isGuest &&
        frameCount > 0 &&
        hasAnyFrames &&
        frameCount !== framesToOperateOn.length,
      onClick: (e) => {
        e.preventDefault();

        window.setTimeout(() => {
          const value = prompt(
            `Where to move ${
              framesToOperateOn.length === 1 ? 'this frame' : 'these frames'
            }?\nFor example, "5" or "3a"…`,
          );
          if (value) {
            FrameActions.moveToIndex({
              index: value,
              frames: framesToOperateOn,
            });
          }
        }, 0);
      },
    },

    duplicate: {
      labelKey: prefix('duplicate'),
      condition: !isGuest && !!singleFrame,
      onClick: (e) => {
        e.preventDefault();
        const frame = singleFrame as IFrame;

        FrameActions.insertFrame({
          dropped_sort_order: parseInt(frame.sort_order) + 1,
          frame_to_duplicate: frame,
          move_frames_after_here: true,
          storyboard_id: frame.storyboard_id,
        });
      },
    },

    group: {
      labelKey: prefix('group'),
      condition:
        !isGuest &&
        framesToOperateOn.length > 1 &&
        !allFramesShareGroup &&
        !entireGroupSelected,
      onClick: () => FrameActions.groupFrames(framesToOperateOn),
    },

    ungroup: {
      // HOW TO COUNT
      labelKey: prefix(entireGroupSelected ? 'ungroup_group' : 'ungroup'),
      labelData: entireGroupSelected
        ? undefined
        : { count: framesToOperateOn.length },
      condition: !isGuest && any(framesToOperateOn, (f) => f.group_id),
      onClick: () => FrameActions.ungroupFrames(framesToOperateOn),
    },

    delete: {
      labelKey: prefix('delete'),
      labelData: { count: framesToOperateOn.length },
      condition: !isGuest,
      disabled: !hasAnyFrames,
      onClick: async (e) => {
        let question;

        if (framesToOperateOn.length > 1) {
          question = `Delete these ${framesToOperateOn.length} frames?`;
        } else {
          question = 'Delete this frame?';
        }

        if (!(await openConfirmDialog(question, props.dialogContext))) return;

        FrameActions.deleteFrames(pluck(framesToOperateOn, 'id'));
      },
    },

    selectAll: {
      labelKey: prefix('select_all'),
      condition: frameCount !== framesToOperateOn.length,
      onClick: (e) => {
        e.preventDefault();
        FrameActions.selectAll.defer();
      },
    },

    deselectAll: {
      labelKey: prefix('deselect_all'),
      condition: frameCount === framesToOperateOn.length,
      onClick: (e) => {
        e.preventDefault();
        FrameActions.deselectAll.defer();
      },
    },

    undo: {
      labelKey: 'actions.undo',
      disabled: !frameUndoManager.canUndo(),
      onClick: undoManager.triggerUndo,
    },
    redo: {
      labelKey: 'actions.redo',
      disabled: !frameUndoManager.canRedo(),
      onClick: undoManager.triggerRedo,
    },
  };

  return pick(actions, (x) => isUndefined(x.condition) || x.condition);
};

export default FrameUserActions;
