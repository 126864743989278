/** @format */
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import { ShareableActions } from 'javascripts/flux/actions/shareable';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  storyboard_id: number;
}

export const ShareablePasswordProtected: React.FC<Props> = (props) => {
  const [password, setPassword] = React.useState('');
  const { t } = useTranslation();

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();

    ShareableActions.unlock({
      storyboard_id: props.storyboard_id,
      password: password,
    });
  };

  return (
    <XhrErrorMessage title={t('shareable.passwordProtected.title')}>
      <form noValidate onSubmit={handleSubmit} className="space-y-6">
        <TextInput
          name="password"
          type="password"
          aria-label="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          placeholder="Enter password"
          autoComplete="off"
          required
          autoFocus
        />

        <Button type="solid" htmlType="submit">
          {t('shareable.passwordProtected.submit')}
        </Button>
      </form>
    </XhrErrorMessage>
  );
};
