window.AnimaticActions = alt.createActions(function () {
  this.fetch = function(options) {
    this.dispatch(options);
  };

  this.triggerGeneration = function(options) {
    this.dispatch(options);
  };

  this.cancelGeneration = function(options) {
    this.dispatch(options);
  };
});
