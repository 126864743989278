/** @prettier */
import * as React from 'react';
import classNames from 'classnames';
import Icon from 'blackbird/components/icon/Icon';
import ChevronIcon from 'blackbird/images/icons/arrow.svg';

const sharedClasses =
  'px-1.5 py-1 rounded-md focus:outline-none rings-form text-sm';

export const ToolbarInput: React.FC<
  React.InputHTMLAttributes<HTMLInputElement>
> = (props) => {
  return (
    <input
      {...props}
      className={classNames(
        sharedClasses,
        'flex-auto',
        props.className,
        props.type === 'number' && '!w-12',
      )}
    />
  );
};

export const ToolbarSelect: React.FC<
  React.SelectHTMLAttributes<HTMLSelectElement>
> = (props) => {
  const width =
    props.className && props.className.match(/(?:^|\s)w-\d{1,3}/) ? '' : 'w-16';

  return (
    <div
      className={classNames(
        'flex items-center relative',
        width,
        props.className,
      )}
    >
      <select
        {...props}
        className={classNames(
          sharedClasses,
          'appearance-none bg-transparent flex-auto truncate',
        )}
      />
      <Icon
        color="black"
        icon={<ChevronIcon />}
        className="absolute right-0 h-6 mx-1 pointer-events-none top-0.5 shrink-0"
      />
    </div>
  );
};
