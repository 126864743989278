/*** @prettier */
import { type ButtonProps } from 'blackbird/components/button/Button';
import Dialog from 'blackbird/components/dialog/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FreeLockout: React.FC = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'modals.freeLockout',
  });

  let title: string;
  let subtitle: string;

  const confirmBtnProps = {
    type: 'fancy',
  };

  if (
    ['founder', 'creative_director', 'elearning', 'producer'].includes(
      BoordsConfig.AccountType,
    )
  ) {
    title = t(`${BoordsConfig.AccountType}.title`);
    subtitle = t(`${BoordsConfig.AccountType}.subtitle`);
  } else {
    title = t(`generic.title`);
    subtitle = t(`generic.subtitle`);
  }

  return (
    <Dialog
      isOpen
      isBlur
      confirmText={t('cta.label')}
      onConfirmBtnClick={() => {
        window.location = t('cta.href');
      }}
      actionChildren={
        <div className="ml-2 text-sm space-x-4 text-type-subdued">
          <a className="hover:underline" href={t('account.href')}>
            {t('account.label')}
          </a>
        </div>
      }
      keepOpen
      confirmBtnProps={confirmBtnProps as ButtonProps}
      alignButtons="left"
      hideDescription
      hideClose
      hideCancel
      title={title}
      subtitle={subtitle}
    />
  );
};

(window as any).FreeLockout = FreeLockout;
