/** @prettier */
import { IFPermissionPanel } from 'javascripts/helpers/has-permission';
import * as React from 'react';
import { type IPanelProps } from '../../shared/MultiPanel';
import { SuspenseLoader } from '../../shared/SuspenseLoader';

const ExportScript = React.lazy(() => import('../ExportScript/ExportScript'));

export const ExportScriptContainer: React.FC<IPanelProps> = (props) => {
  const ExportContent: React.FC = () => (
    <SuspenseLoader>
      <ExportScript {...props} />
    </SuspenseLoader>
  );

  if (props.storyboardOwnerId) {
    return (
      <IFPermissionPanel
        featureName="bulk_export"
        storyboardOwnerId={props.storyboardOwnerId}
      >
        <ExportContent />
      </IFPermissionPanel>
    );
  } else {
    return <ExportContent />;
  }
};
