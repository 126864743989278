const createReactClass = require('create-react-class');
const {default: logger} = require('javascripts/helpers/logger');
var PropTypes = require('prop-types');
var SortableMixin = require("../../helpers/react-sortable-mixin");

window.DashboardGroup = createReactClass({

  propTypes: {
    logo_url: PropTypes.string.isRequired,
    actions: PropTypes.array,
  },

  getInitialState() {
    return {
      projects: this.props.projects
    }
  },

  componentDidMount() {
    try {
      let storyboardCount = 0;

      this.props.projects.map((project) => {
        storyboardCount +=
          project.storyboards.length > 0 ? project.storyboards.length : 0;
      });
      Track.event.defer('dashboard_load', {
        role: this.props.user.team_id === this.props.team_id ? 'owner' : 'member',
        storyboards: storyboardCount,
        projects: this.props.projects.length,
      }, 1000);
    } catch (e) {
      logger.log(e);
    }
  },

  /**
   * Yes, this could probably be changed to getDerivedStateFromProps,
   * But considering that the only reason we use derived state here is because
   * the Sortable mixin requires it, perhaps it's better to use a new
   * drag & drop plugin instead that isn't a mixin or used derived state, both
   * things are rather deprecated!
   */
  UNSAFE_componentWillReceiveProps: function(nextProps) {
    if (this.props.projects !== nextProps.projects) {
      this.setState({
        projects: nextProps.projects,
      });
    }
  },

  mixins: [SortableMixin],

  sortableOptions: {
    ref:            'projectItems',
    forceFallback:  true,
    handle:         ".js-project-drag-handle",
    model:          "projects",
  },

  handleSort: function () {
    DashboardActions.updateProjectOrder(this.state.projects)
  },

  render: function() {
    const {projects} = this.state;
    const {team_id, title, admin, user, type, actions} = this.props;
    let projectItems;

    if ((projects.length === 0) && type === "membership") {

      projectItems = <DashboardProjectEmpty admin={admin} title={title} />

    } else {

      projectItems = projects.map(function(project){
        return (
          <div key={"projects-" + project.id} className="js-dashboard-project-team" style={{position: "relative"}}>
            <DashboardProjectItem
              projectsLength={projects.length}
              user={user}
              teamId={team_id}
              project={project}
              actions={actions}
            />
          </div>
        );
      }.bind(this));
    }

    return (
      <div className={"projects__container"}>
        <div ref="projectItems" className='flex flex-col mx-5 my-6 space-y-5'>
          {projectItems}
        </div>
      </div>
    );
  }
});
