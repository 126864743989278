/** @prettier */
import React, { useContext } from 'react';
import { SecondaryLink } from '../common/SecondaryLink';
import { ProjectsContext } from '../projects/ProjectsContext';

export const ManageTemplatesButton: React.FC<{
  title?: string;
  useDirectLink?: boolean;
}> = ({ title = `Manage Tempates`, useDirectLink = false }) => {
  const { activeGroup, setViewType } = useContext(ProjectsContext);

  if (!activeGroup) {
    return null;
  }

  return !activeGroup?.actions.includes('team.manage') ? null : (
    <div className="">
      <SecondaryLink
        onClick={(e) => {
          e.stopPropagation();
          if (useDirectLink) {
            window.location.href = `/templates/${activeGroup.hashid}`;
          } else {
            window.history.pushState(
              {},
              '',
              `/templates/${activeGroup.hashid}`,
            );
            setViewType('templates');
          }
        }}
      >
        {title}
      </SecondaryLink>
    </div>
  );
};
