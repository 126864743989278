/** @format */
import React from 'react';
import { format } from 'date-fns';
import Img from 'javascripts/components/shared/Img';
import { Watermark } from 'blackbird/components/watermark/Watermark';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { getMissingFrameImageURL } from 'javascripts/helpers/getMissingFrameImageURL';
import { useTemplates, type Template } from './TemplatesContext';
import { SingleTemplateActions } from './SingleTemplateActions';
import { LayoutTemplateIcon } from 'lucide-react';

interface SingleTemplateProps {
  template: Template;
}

export const SingleTemplate: React.FC<SingleTemplateProps> = ({ template }) => {
  const { height, width } = BoordsFrameSizeHelper(template.aspect_ratio);
  const placeholderImage = getMissingFrameImageURL(template.aspect_ratio);
  const { setCurrentTemplate, setIsTemplateModalOpen } = useTemplates();

  return (
    <div className="flex flex-col group/template">
      <div
        className="rounded-lg cursor-pointer transition-opacity hover:bg-black/10"
        onClick={() => {
          setCurrentTemplate(template);
          setIsTemplateModalOpen(true);
        }}
      >
        <div className="m-1.5">
          <div className="relative">
            <div
              className="absolute z-50 opacity-0 top-2 right-2 group-hover/template:opacity-100 transition-opacity"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <SingleTemplateActions template={template} />
            </div>
            <div className="absolute top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center">
              <div className="bg-white rounded-md">
                <div className="bg-decorative-blue/30 text-brand-blue border-decorative-blue/50 px-2 pb-0.5 rounded-md text-xs font-semibold border  flex items-center space-x-1">
                  <LayoutTemplateIcon className="w-3 h-3" />
                  <span>{`Template`}</span>
                </div>
              </div>
            </div>
            <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-decorative-blue/20" />
            <Img
              className="flex-grow rounded-t-md"
              containerClasses="flex"
              src={placeholderImage}
              height={height}
              width={width}
              placeholder={placeholderImage}
            />
          </div>
          <div className="flex flex-col justify-center p-4 bg-white border border-border rounded-b-md">
            <h3 className="text-sm">{template.name}</h3>
            <div className="mt-1 text-xs text-type-disabled">
              {`${template.fields.length} fields`}
            </div>
            <div className="mt-1 text-xs text-type-disabled">
              {`Created ${format(new Date(template.created_at), 'd MMM yy')}`}
            </div>
            <div className="mt-1 text-xs text-type-disabled">
              {`Modified ${format(new Date(template.updated_at), 'd MMM yy')}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
