/** @format */
import * as React from 'react';

import Avatar from '../avatar/Avatar';
import Toggle from '../toggle/Toggle';
import { filterDataUrl } from '../projects/ProjectsGroupLabel';
import { getFirstCharacter } from 'javascripts/helpers/get-first-character';
import classNames from 'classnames';
import { TeamContext, type TeamMember } from './TeamContext';
import Tooltip from '../feedback/tooltip/Tooltip';
import { TeamRoleSelect, TeamRoleStatic } from './TeamRoleSelect';
import { TrashIcon, XIcon } from 'lucide-react';
import { TeamTwoFactorBadge } from './TeamTwoFactorBadge';
import { DialogContext } from '../dialog/DialogContext';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';

interface TeamProjectMemberRowProps {
  member: TeamMember;
  showProjectAccess?: boolean;
  showTwoFactor?: boolean;
  isArchived?: boolean;
}

export const TeamProjectMemberRow: React.FC<TeamProjectMemberRowProps> = ({
  member,
  showProjectAccess = true,
  showTwoFactor = false,
  isArchived = false,
}) => {
  const {
    teamId,
    teamData,
    activeGroup,
    activeProject,
    toggleMembership,
    updateMembership,
    deleteMembership,
    isTeamFull,
    teamMonthlyUserCost,
  } = React.useContext(TeamContext);
  const dialogContext = React.useContext(DialogContext);
  const canManageTeam = activeGroup?.actions.includes('team.manage') || teamId;
  const canViewTeam = activeGroup?.actions.includes('team.view') || teamId;

  const restrictedWithoutAccess =
    showProjectAccess &&
    activeProject &&
    member.role === 'member' &&
    !member.projects_with_access.includes(activeProject.slug);

  const restrictedWithAccess =
    showProjectAccess &&
    activeProject &&
    member.role === 'member' &&
    member.projects_with_access.includes(activeProject.slug);

  if (!canViewTeam) {
    return null;
  }

  return (
    <div className={classNames('relative flex items-center group')}>
      <div
        className={classNames(
          'flex items-center flex-auto space-x-2',
          (restrictedWithoutAccess || isArchived) && 'opacity-50',
        )}
      >
        <Avatar
          borderColor="border-white"
          className="mt-1"
          size="sm"
          initial={getFirstCharacter(member.name)}
          image={filterDataUrl(member.avatar_url)}
        />
        <div>
          <p className="text-sm">{member.name}</p>
          <p className="text-xs text-type-subdued">{member.email}</p>
        </div>
      </div>
      <div className="flex items-center justify-end space-x-2">
        {!isArchived && canManageTeam && showTwoFactor && (
          <TeamTwoFactorBadge member={member} />
        )}
        {showProjectAccess && member.role === 'member' && activeProject && (
          <Tooltip
            title={`Toggle project access`}
            placement="top"
            disabled={!canManageTeam}
          >
            <span className="mr-2">
              <Toggle
                size="compact"
                disabled={!canManageTeam}
                value={restrictedWithAccess as boolean | undefined}
                onChange={() => {
                  toggleMembership(
                    member.membership_id,
                    activeProject.slug,
                    !member.projects_with_access.includes(activeProject.slug),
                  );
                }}
              />
            </span>
          </Tooltip>
        )}
        {member.role === 'admin' ? (
          <TeamRoleStatic text={TeamRoleHelper.call(member.role)} />
        ) : isArchived && isTeamFull ? (
          <TeamRoleStatic text={TeamRoleHelper.call(member.role)} />
        ) : (
          <TeamRoleSelect
            value={member.role}
            onChange={async (role) => {
              if (member.role === 'archived') {
                if (
                  await openConfirmDialog(
                    `Active members are billed at ${teamMonthlyUserCost}. Do you want to proceed?`,
                    dialogContext,
                  )
                ) {
                  updateMembership(member.membership_id, role);
                }
              } else {
                updateMembership(member.membership_id, role);
              }
            }}
          />
        )}
      </div>
      {!isArchived && member.role !== 'admin' && canManageTeam && (
        <Tooltip placement="top" title={`Archive user`}>
          <div
            className="absolute opacity-0 cursor-pointer -right-6 top-3 group-hover:opacity-100"
            onClick={() => updateMembership(member.membership_id, 'archived')}
          >
            <XIcon className="w-4 h-4" />
          </div>
        </Tooltip>
      )}
      {isArchived && member.role !== 'admin' && canManageTeam && (
        <Tooltip placement="top" title={`Permanently delete user`}>
          <div
            className="absolute opacity-0 cursor-pointer -right-6 top-3 group-hover:opacity-100"
            onClick={async () => {
              if (
                await openConfirmDialog(
                  `Permanently remove ${member.name} from ${teamData?.name_raw}?`,
                  dialogContext,
                )
              ) {
                deleteMembership(member.membership_id);
              }
            }}
          >
            <TrashIcon className="w-4 h-4" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
