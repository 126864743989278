/** @prettier */
import * as React from 'react';
import { differenceInMilliseconds } from 'date-fns';
import logger from 'javascripts/helpers/logger';
import classNames from 'classnames';

interface SaleTimerProps {
  endDate: Date;
}
interface TimeLeft {
  d: number;
  h: number;
  m: number;
  s: number;
}

export const calculateTimeLeft = (endDate: Date): TimeLeft => {
  const currentDateUTC = new Date().toISOString();
  const endDateUTC = endDate;
  const difference = differenceInMilliseconds(
    new Date(endDateUTC),
    new Date(currentDateUTC),
  );

  let timeLeft: TimeLeft = {
    d: 0,
    h: 0,
    m: 0,
    s: 0,
  };

  if (difference > 0) {
    timeLeft = {
      d: Math.floor(difference / (1000 * 60 * 60 * 24)),
      h: Math.floor((difference / (1000 * 60 * 60)) % 24),
      m: Math.floor((difference / 1000 / 60) % 60),
      s: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const RoundedTimeLeft: React.FC<SaleTimerProps> = ({ endDate }) => {
  const [timeLeft, setTimeLeft] = React.useState<TimeLeft>(
    calculateTimeLeft(endDate),
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  const { d, h, m } = timeLeft;

  const formatTimeLeft = () => {
    if (d > 0) {
      return `${d} ${d === 1 ? 'day' : 'days'}`;
    } else if (h > 0) {
      return `${h} ${h === 1 ? 'hour' : 'hours'}`;
    } else if (m > 0) {
      return `${m} ${m === 1 ? 'minute' : 'minutes'}`;
    } else {
      return '1 minute';
    }
  };

  return (
    <span className="inline-block whitespace-nowrap">{formatTimeLeft()}</span>
  );
};

export const SaleTimer: React.FC<SaleTimerProps> = ({ endDate }) => {
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft(endDate));
  const [timeUp, setTimeUp] = React.useState(false);
  const [showTimeUnits, setShowTimeUnits] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    if (timeLeft['d'] > 0 || timeLeft['h'] > 0) {
      setShowTimeUnits(true);
    } else {
      setShowTimeUnits(false);
    }

    if (
      timeLeft['m'] === 0 &&
      timeLeft['s'] === 0 &&
      timeLeft['h'] === 0 &&
      timeLeft['d'] === 0
    ) {
      clearInterval(interval);
      setTimeUp(true);
    }
    return () => clearInterval(interval);
  }, [endDate, timeLeft]);

  const timerComponents: React.ReactElement[] = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval] && !['m', 's', 'h'].includes(interval)) {
      return;
    }
    // Hide hours if no days
    if (!timeLeft[interval] && interval === 'h' && !timeLeft[`d`]) {
      return;
    }

    timerComponents.push(
      <span
        key={`${interval}-${timeLeft[interval]}`}
        className={classNames(timeUp && 'text-brand-pink')}
      >
        {interval === 's' && timeLeft[interval] < 10
          ? `0${timeLeft[interval]}`
          : interval === 's' && !timeLeft[interval]
          ? '00'
          : interval === 'm' && !timeLeft[interval]
          ? '0'
          : `${timeLeft[interval]}`}
        {/* Append d/h/m/s, or : if less than 1 day left */}
        {showTimeUnits ? `${interval} ` : interval === `s` ? '' : ':'}
      </span>,
    );
  });

  return timerComponents.length ? (
    <span
      className={classNames(
        'inline-block whitespace-nowrap',
        timerComponents.length === 4
          ? 'w-[6.6rem]'
          : timerComponents.length === 3
          ? timeLeft['h'] > 10
            ? 'w-[5.5rem]'
            : 'w-[5.2rem]'
          : timeLeft['m'] > 10
          ? 'w-[2.65rem]'
          : 'w-[2.3rem]',
      )}
    >
      {timerComponents}
    </span>
  ) : null;
};
