/** @format */

import React, { type MouseEventHandler } from 'react';
import ReactDOM from 'react-dom';
import { XMarkIcon, LinkIcon } from '@heroicons/react/20/solid';
import RemoveLinkIcon from 'blackbird/images/icons/remove-link.svg';
import { nanoid } from 'nanoid/non-secure';
import { isValidUrl } from 'javascripts/helpers/isValidUrl';
import useCustomPopper from 'blackbird/helpers/hooks/useCustomPopper';
import { type VirtualElement } from '@popperjs/core';
import { BigPopTransition } from 'blackbird/components/common/Transitions';
import { useTranslation } from 'react-i18next';
import { FormattingToolbarLinkInput } from './FormattingToolbarLinkInput';
import { FormattingToolbarButton } from './FormattingToolbarButton';
import { useOnClickOutside } from 'blackbird/helpers/hooks/useOnClickOutside';
import { debounce } from 'underscore';

interface Props {
  inputRef: React.RefObject<HTMLDivElement>;
  onClickOutside: () => void;
  toolbarRef: React.RefObject<HTMLElement>;
}

const allowedActivities = [
  'Bold',
  'createLink',
  'Italic',
  'removeformat',
  'unlink',
  'insertUnorderedList',
  'insertOrderedList',
] as const;

/** Shortcut function to run `document.execCommand` while whitelisting commands */
const execCommand = (type: typeof allowedActivities[number], arg?: string) => {
  if (allowedActivities.indexOf(type) < 0)
    throw new Error(`action ${type} is not allowed`);
  document.execCommand(type, false, arg);
};

export const FormattingToolbar = React.memo<Props>((props) => {
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null,
  );
  const popperElRef = React.useRef<HTMLDivElement>(null);
  const [parentRef, setParentRef] = React.useState<VirtualElement | null>(null);
  const [isLink, setIsLink] = React.useState(false);
  const [isSettingLink, setIsSettingLink] = React.useState(false);
  const { t } = useTranslation();

  const savedLinkSelection = React.useRef<Range | null>();

  const { styles, attributes } = useCustomPopper(parentRef, popperElement, {
    placement: 'top',
    distance: 10,
  });

  /** Handle to be called when selection occurs, currently mapped to mouseUp and
   * key events */
  const evaluateSelection = React.useCallback(() => {
    const selection = window.getSelection();
    if (selection && props.inputRef.current?.contains(selection.anchorNode)) {
      const range = selection.getRangeAt(0);
      if (!range.collapsed) {
        setParentRef(range);

        const containsLink = Boolean(
          range.commonAncestorContainer instanceof HTMLElement &&
            range.commonAncestorContainer.querySelector('a[href]'),
        );

        setIsLink(
          range.commonAncestorContainer instanceof HTMLAnchorElement ||
            range.commonAncestorContainer.parentElement instanceof
              HTMLAnchorElement ||
            containsLink,
        );
      } else {
        setParentRef(null);
      }
      range.detach();
    } else {
      // I'm not sure why this was here, but it wasn't working out; it would
      // disable the formatting toolbar when clicking on the link button.
      // setParentRef(null);
    }
  }, [props.inputRef]);

  /** A debounced version, for when we want to wait a bit after a keydown event fired */
  const debouncedEvaluateSelection = React.useCallback(
    debounce(evaluateSelection, 10),
    [evaluateSelection],
  );

  /** When isSettingLink becomes false (and we're no longer focused in link-related inputs), we want to restore previously saved selection */
  React.useEffect(() => {
    if (!savedLinkSelection.current || isSettingLink === true) {
      return;
    } else {
      window.getSelection()?.removeAllRanges();
      document.getSelection()?.addRange(savedLinkSelection.current);
    }
  }, [isSettingLink]);

  const handleInsertLink = React.useCallback(
    (url?: string) => {
      if (!url || !props.inputRef.current || !savedLinkSelection.current)
        return;

      setIsSettingLink(false); // This will also trigger the selection to be restored
      setIsLink(true);

      // createLink does not allow us to add attributes to the link object,
      // which we want to do, so we create a string that we can use to find the
      // link elements created by the placeholder.
      const placeholder = nanoid(6);
      execCommand('createLink', placeholder);

      // Sometimes, a link can span multiple elements (if part of the link is in
      // a <b> tag, for example, so we have to use querySelectorAll)
      props.inputRef
        .current!.querySelectorAll(`a[href="${placeholder}"]`)
        .forEach((anchor: HTMLAnchorElement) => {
          anchor.href = url;
          anchor.target = '_blank';
          anchor.rel = 'noopener noreferrer';
        });
    },
    [props.inputRef],
  );

  /** Handles keyboard shortcuts */
  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey || e.metaKey) {
        if (e.key === 'b') {
          execCommand('Bold');
          e.preventDefault();
        }
        if (e.key === 'i') {
          execCommand('Italic');
          e.preventDefault();
        }
        if (e.key === 'k') {
          savedLinkSelection.current = document.getSelection()?.getRangeAt(0);
          setIsSettingLink(true);
          e.preventDefault();
        }
        if (e.key === 'a') {
          debouncedEvaluateSelection();
        }
      } else {
        debouncedEvaluateSelection();
      }
    },
    [setIsSettingLink, savedLinkSelection, debouncedEvaluateSelection],
  );

  /** Handle pasting links onto other text */
  const handlePaste = React.useCallback(
    (e: ClipboardEvent) => {
      const selection = window.getSelection();
      // If we don't have a parentRef, it means the toolbar isn't showing
      if (!parentRef) return;
      if (
        selection &&
        // See if the input's element contains the current selection, or if the
        // current selection IS the input's element
        props.inputRef.current?.contains(selection.anchorNode) &&
        selection.toString().length > 0
      ) {
        const text = e.clipboardData?.getData('text');
        savedLinkSelection.current = document.getSelection()?.getRangeAt(0);
        if (text && isValidUrl(text)) {
          e.preventDefault();
          handleInsertLink(text);
        }
      }
    },
    [handleInsertLink, parentRef, props.inputRef],
  );

  useOnClickOutside(popperElRef, () => {
    // Because we will be ignoring blur events while the link dialog is open, we
    // need to keep track of situations in which the user really wants to leave
    // this dialog
    if (isSettingLink) props.onClickOutside();
  });

  React.useEffect(
    () => {
      // The parent takes care of the blur event for us
      document.addEventListener('mouseup', evaluateSelection);
      props.inputRef.current?.addEventListener('keydown', handleKeyDown);
      props.inputRef.current?.addEventListener('paste', handlePaste);
      props.inputRef.current?.addEventListener('selection', handlePaste);

      return () => {
        document.removeEventListener('mouseup', evaluateSelection);
        props.inputRef.current?.removeEventListener('keydown', handleKeyDown);
        props.inputRef.current?.removeEventListener('paste', handlePaste);
        debouncedEvaluateSelection.cancel();
      };
    },
    // prettier-ignore
    [handleKeyDown, handlePaste, evaluateSelection, props.inputRef, debouncedEvaluateSelection],
  );

  const handleFormatButtonClick = React.useCallback<
    MouseEventHandler<HTMLFormElement>
  >(
    (e) => {
      if (!props.inputRef.current) return;
      if (e.button !== 0) return;
      // Prevent focus from going away
      e.preventDefault();

      if (e.currentTarget.name === 'createLink') {
        savedLinkSelection.current = document.getSelection()?.getRangeAt(0);
        setIsSettingLink(true);
      } else {
        execCommand(e.currentTarget.name as any);
      }
    },
    [setIsSettingLink, props.inputRef],
  );

  // I couldn't seem to get classNames to set the size
  // of the icons (heroicons or our own), so setting
  // sizes here seems to be the only way.
  const iconSizeStyle = {
    width: '1rem',
    height: '1rem',
  };

  return ReactDOM.createPortal(
    <div
      ref={popperElRef}
      className="z-tooltip"
      style={styles.popper}
      {...attributes.popper}
    >
      <BigPopTransition
        appear={true}
        show={!!parentRef}
        className="flex items-center px-2.5 py-2 text-sm font-bold rounded-md shadow-lg bg-surface-light gap-2"
        beforeEnter={() => setPopperElement(popperElRef.current)}
        afterLeave={() => setPopperElement(null)}
      >
        {isSettingLink ? (
          <FormattingToolbarLinkInput
            onSubmit={handleInsertLink}
            onClose={() => setIsSettingLink(false)}
            ref={props.toolbarRef}
          />
        ) : (
          <>
            <FormattingToolbarButton
              onMouseDown={handleFormatButtonClick}
              name="Italic"
              icon={<>i</>}
              aria-label={t('formatting.italic')}
              className="italic"
            />

            <FormattingToolbarButton
              onMouseDown={handleFormatButtonClick}
              name="Bold"
              icon={<>B</>}
              aria-label={t('formatting.bold')}
            />

            <FormattingToolbarButton
              onMouseDown={handleFormatButtonClick}
              name="insertUnorderedList"
              icon={<>•</>}
              aria-label={t('formatting.unorderedList')}
            />

            <FormattingToolbarButton
              onMouseDown={handleFormatButtonClick}
              name="insertOrderedList"
              icon={<>1.</>}
              aria-label={t('formatting.orderedList')}
            />

            <FormattingToolbarButton
              onMouseDown={handleFormatButtonClick}
              name="removeformat"
              icon={<XMarkIcon style={iconSizeStyle} />}
              aria-label={t('formatting.remove')}
            />

            <FormattingToolbarButton
              onMouseDown={handleFormatButtonClick}
              name="createLink"
              icon={<LinkIcon style={iconSizeStyle} />}
              aria-label={t('formatting.createLink')}
            />

            {isLink && (
              <FormattingToolbarButton
                onMouseDown={handleFormatButtonClick}
                name="unlink"
                icon={<RemoveLinkIcon style={iconSizeStyle} />}
                aria-label={t('formatting.unlink')}
              />
            )}
          </>
        )}
      </BigPopTransition>
    </div>,
    document.querySelector('body')!,
  );
});

FormattingToolbar.displayName = 'FormattingToolbar';
