/** @prettier */
import type { UploadDrawnImageOptions } from '../flux/stores/frame';
import type { IFrame } from '../types/frame';
import { RequestErrorHandler } from './request-error-handler';
import { isEmpty, isNumber, isString } from 'underscore';
import { mapLimit, eachLimit } from 'async';
import { renderFrameInBackground } from '../components/backgroundFrameRenderer/BackgroundFrameRenderer';
import type { frameAspectRatio, IStoryboard } from '../types/storyboard';
import logger from './logger';
import { FileUploadActions } from 'javascripts/flux/actions/file_upload';
import { ajax } from './ajax';
const api = require('./api')();

const errorHandler = RequestErrorHandler('bulkRenderFrames');

/** rerenders all the frames  */
export const bulkRenderFrames = (
  frames: IFrame[],
  aspectRatio: frameAspectRatio,
) =>
  new Promise<Array<UploadDrawnImageOptions>>((resolve, reject) => {
    mapLimit(
      frames,
      1,
      (frame, done) => {
        renderFrameInBackground(frame, aspectRatio).then(
          (result) => done(null, result),
          (err) => done(err),
        );
      },
      (err, result?: UploadDrawnImageOptions[]) => {
        if (err || !result) return reject(err);
        resolve(result);
      },
    );
  });

/** Bulk uploads multiple drawn images */
export const bulkUploadDrawnImage = (
  results: UploadDrawnImageOptions[],
  storyboard: IStoryboard,
) =>
  new Promise<void>((resolve, reject) => {
    const teamId = storyboard.project.owner.id;
    const ratio = storyboard.frame_aspect_ratio;

    eachLimit(
      results,
      3,
      (result, done) => {
        FileUploadActions.uploadFrameImage.defer({
          fileData: result.image,
          team_id: teamId,
          frameAspectRatio: ratio,

          callback: (uploadResult) => {
            if (!uploadResult) return done(new Error('no upload result'));
            const { thumbnail_image_url, large_image_url } = uploadResult;
            ajax({
              beforeSend: api.setGoApiAuthHeader,
              method: 'put',
              dataType: 'json',
              data: {
                frame: {
                  storyboard_id: storyboard.id,
                  layer_data: JSON.stringify(result.layer_data ?? {}),
                  large_image_url: large_image_url,
                  thumbnail_image_url: thumbnail_image_url ?? large_image_url,
                },
              },
              url: api.setGoApiUrl('frames/' + result.id + '/image_url'),
              success: () => done(),
              error: errorHandler({ message: null }, () =>
                done(new Error('Upload failed')),
              ),
            });
          },
        });
      },
      (err) => {
        if (err) return reject(err);
        resolve();
      },
    );
  });

/**
 * Fetches frames belonging to a storyboard, and calls `bulkRenderFrames` on frames that have layer_data. Then it reuploads those*/
export const fetchAndRerenderFrames = ({
  storyboardId,
  slug,
}: {
  storyboardId: number;
  slug: string;
}) =>
  new Promise((resolve, reject) => {
    if (!isNumber(storyboardId))
      throw new Error('Storyboard ID not passed or was not a number');
    if (!isString(slug))
      throw new Error('Slug not passed or was not a string ');
    logger.log('rerendering frames belonging to storyboard ' + storyboardId);

    Promise.all([
      // Get info on the frames
      ajax({
        beforeSend: api.setGoApiAuthHeader,
        method: 'get',
        dataType: 'json',
        contentType: 'application/json',
        url: api.setGoApiUrl(`storyboards/${storyboardId}`),
      }).then((response) => response.frames),
      // Get the storyboard info
      ajax({
        method: 'get',
        url: '/storyboards/' + slug + '.json',
      }),
    ])
      .then(([frames, storyboard]: [IFrame[], IStoryboard]) => {
        const withoutLayerData = frames.filter(
          ({ layer_data }: IFrame) =>
            !isEmpty(layer_data) && layer_data !== '{}',
        );

        const ratio = storyboard.frame_aspect_ratio;

        bulkRenderFrames(withoutLayerData, ratio)
          .then((image) => bulkUploadDrawnImage(image, storyboard))
          .then(resolve)
          .catch(
            errorHandler(
              {
                message: null,
                rollbarMessage:
                  'Error rendering frames, but continuing without rerendered frames',
              },
              reject,
            ),
          );
      })
      .catch(reject);
  });

(window as any).fetchAndRerenderFrames = fetchAndRerenderFrames;
