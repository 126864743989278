/**@prettier */
import React, { useState, useRef, useLayoutEffect } from 'react';

type OverflowDirection = 'x' | 'y';
/** Hook to detect overflow of an element */
export const useOverflow = (direction: OverflowDirection = 'y') => {
  const [hasOverflow, setHasOverflow] = useState(false);
  const ref = useRef<HTMLDivElement>();
  const observerRef = useRef<ResizeObserver>();
  useLayoutEffect(() => {
    const current = ref?.current;

    const checkOverflow = () => {
      const overflow =
        direction === 'y'
          ? current!.scrollHeight > current!.clientHeight
          : current!.scrollWidth > current!.clientWidth;
      setHasOverflow(overflow);
    };

    if (current) {
      //recheck overflow after element is resized
      if ('ResizeObserver' in window) {
        observerRef.current = new ResizeObserver(checkOverflow);
        observerRef.current.observe(current);
      }
      checkOverflow();
    }
    return () => {
      observerRef.current?.unobserve(current!);
    };
  }, [ref, direction]);

  /**
   * returns an object containing following properties
   * hasOverflow - which is a boolean indicating if there's an overflow
   * ref - A MutableRefObject , which needs to be attached to the element
   * we want to detect overflow of
   */
  return { hasOverflow, ref };
};

export const OverflowContext = React.createContext(false);

/** This provider is used in case of class based component
 * where a hook cannot be used.
 * It returns two render props ,
 * hasOverflow - which is a boolean indicating if there's an overflow
 * ref - A MutableRefObject , which needs to be attached to the element
 * we want to detect overflow of.
 *
 * Alternatively, the context values can be consumed using OverflowContext.Consumer
 */
export const OverflowContextProvider = ({ children }) => {
  const { hasOverflow, ref } = useOverflow();
  return (
    <OverflowContext.Provider value={hasOverflow}>
      {typeof children === 'function'
        ? children({ hasOverflow, ref })
        : children}
    </OverflowContext.Provider>
  );
};
