/**@prettier */
const { default: Toggle } = require('blackbird/components/toggle/Toggle');
const createReactClass = require('create-react-class');
var PropTypes = require('prop-types');
const classnames = require('classnames');

module.exports = createReactClass({
  displayName: 'ToggleButtonWithLabel',
  propTypes: {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
    labelPosition: PropTypes.oneOf(['before', 'after']),
  },

  getDefaultProps() {
    return {
      labelPosition: 'before',
    };
  },

  render() {
    const { label, className, labelPosition, ...props } = this.props;

    const labelElement = <span className="">{label}</span>;

    return (
      <div
        className={classnames(
          'flex items-center justify-between gap-3',
          className,
        )}
      >
        {labelPosition === 'before' && labelElement}
        <Toggle className="flex-shrink-0 mt-1" {...props} />
        {labelPosition === 'after' && labelElement}
      </div>
    );
  },
});
