/** @prettier */
import { createTypedActions } from '../stores/AltStore';
import { StoryboardStore } from '../stores/storyboard';

class StoryboardActions {
  create: StoryboardStore['handleCreate'] = function (data) {
    this.dispatch(data);
  };

  fetch: StoryboardStore['handleFetch'] = function (storyboard_slug) {
    this.dispatch(storyboard_slug);
  };

  trackLoadEvent: StoryboardStore['handleTrackLoadEvent'] = function (options) {
    this.dispatch(options);
  };

  toggleViewMode: StoryboardStore['handleToggleViewMode'] = function (data) {
    this.dispatch(data);
  };

  setStoryboard: StoryboardStore['handleSetStoryboard'] = function (
    storyboard,
  ) {
    this.dispatch(storyboard);
  };

  archive: StoryboardStore['handleArchiveStoryboard'] = function (
    storyboard_slug,
  ) {
    this.dispatch(storyboard_slug);
  };

  receive: StoryboardStore['handleReceive'] = function (storyboard) {
    this.dispatch(storyboard);
  };

  update: StoryboardStore['handleUpdate'] = function (data) {
    this.dispatch(data);
  };

  switchAspectRatio: StoryboardStore['handleSwitchAspectRatio'] = function (
    value,
  ) {
    this.dispatch(value);
  };

  save: StoryboardStore['handleSave'] = function () {
    this.dispatch();
  };

  requestComplete: StoryboardStore['handleRequestComplete'] = function () {
    this.dispatch();
  };

  requestStart: StoryboardStore['handleRequestStart'] = function () {
    this.dispatch();
  };

  clearProjectCache: StoryboardStore['handleClearProjectCache'] = function () {
    this.dispatch();
  };

  duplicate: StoryboardStore['handleDuplicate'] = function (storyboard) {
    this.dispatch(storyboard);
  };

  version: StoryboardStore['handleVersion'] = function (args) {
    this.dispatch(args);
  };

  archiveStoryboard: StoryboardStore['handleArchiveStoryboard'] = function (
    storyboard,
  ) {
    this.dispatch(storyboard);
  };

  archiveStoryboardVersion: StoryboardStore['handleArchiveStoryboardVersion'] =
    function (storyboard) {
      this.dispatch(storyboard);
    };

  restoreStoryboard: StoryboardStore['handleRestoreStoryboard'] = function (
    storyboard,
  ) {
    this.dispatch(storyboard);
  };

  deleteStoryboard: StoryboardStore['handleDeleteStoryboard'] = function (
    storyboard,
  ) {
    this.dispatch(storyboard);
  };

  updateAndSave: StoryboardStore['handleUpdateAndSave'] = function (
    storyboard,
  ) {
    this.dispatch(storyboard);
  };

  updatePreference: StoryboardStore['handleUpdatePreference'] = function (
    storyboard,
  ) {
    this.dispatch(storyboard);
  };

  toggleSimplifiedView: StoryboardStore['handleToggleSimplifiedView'] =
    function (storyboard) {
      this.dispatch(storyboard);
    };

  commitFrameFields: StoryboardStore['handleCommitFrameFields'] = function (
    storyboard,
  ) {
    this.dispatch(storyboard);
  };

  copyFrameFields: StoryboardStore['handleCopyFrameFields'] = function (
    storyboard,
  ) {
    this.dispatch(storyboard);
  };

  toggleGridViewCommentColumn: StoryboardStore['handleToggleGridViewCommentColumn'] =
    function () {
      this.dispatch();
    };

  updateFrameFieldAtIndex: StoryboardStore['handleUpdateFrameFieldAtIndex'] =
    function (storyboard) {
      this.dispatch(storyboard);
    };
  reload: StoryboardStore['handleReload'] = function () {
    this.dispatch();
  };
}

const actions = createTypedActions<StoryboardActions>(StoryboardActions);
export { actions as StoryboardActions };

// This is not how it's supposed to be, but so many views rely on this, that
// it's better to keep it like this until every instance is updated to use
// actual imports
(window as any).StoryboardActions = actions;
