/** @format */
import type { ShareableStore } from 'javascripts/flux/stores/shareable';
import { last, some } from 'underscore';
import { useStore } from './useStore';

export const useIsOutdatedShareableVersion = () => {
  return useStore<[boolean, string | undefined], ShareableStore>(
    'shareable',
    ({ storyboard }) => {
      if (!storyboard) {
        return [false, undefined];
      }
      const hasNewerVersion =
        storyboard &&
        some(
          storyboard.versions,
          (i) => i.version_number > storyboard.version_number, // removed the dot before storyboard
        );
      return [
        hasNewerVersion,
        hasNewerVersion
          ? `/s/${last(storyboard.versions)?.slug.split('-')[0]}`
          : undefined,
      ];
    },
  );
};
