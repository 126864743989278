/**@prettier */
const _ = require('underscore');
const createReactClass = require('create-react-class');
var PropTypes = require('prop-types');
import Banner from 'blackbird/components/feedback/banner/Banner';
window.ExportPDFErrors = createReactClass({
  propTypes: {
    pdf: PropTypes.object.isRequired,
    heading: PropTypes.string.isRequired,
    message: PropTypes.string,
  },

  render: function () {
    const { pdf, heading, message } = this.props;
    const p = pdf.pdf;

    return (
      <Banner
        kind="warning"
        customWidth
        hideIcon
        className="w-full max-w-prose mx-auto"
        title={heading || `Heads up!`}
        message={
          <ul>
            {!_.isEmpty(p.pdf_cover_error_message) && (
              <li>
                Front cover: {p.pdf_cover_error_message}. You might try
                replacing your cover page PDF file?
              </li>
            )}

            {!_.isEmpty(p.pdf_frames_error_message) && (
              <li>{p.pdf_frames_error_message}</li>
            )}

            {!_.isEmpty(p.pdf_endslate_error_message) && (
              <li>Back cover: {p.pdf_endslate_error_message}</li>
            )}

            {!_.isEmpty(message) && <li>{message}</li>}
          </ul>
        }
      />
    );
  },
});
