/** @format */

import React, { useState } from 'react';
import useCustomPopper from './hooks/useCustomPopper';

export type CustomPopperProps = {
  popperStyles: ReturnType<typeof useCustomPopper>['styles'];
  popperAttributes: ReturnType<typeof useCustomPopper>['attributes'];
  setPopperElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setPopperParentRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
};
// eslint-disable-next-line react/display-name
const withCustomPopper =
  (Comp: React.ComponentType<any>) =>
  (popperOptions: Parameters<typeof useCustomPopper>[2]) =>
  // eslint-disable-next-line react/display-name
  (props) => {
    const [parentRef, setParentRef] = useState<HTMLButtonElement | null>();
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(
      null,
    );
    const { styles: popperStyles, attributes: popperAttributes } =
      useCustomPopper(parentRef, popperElement, popperOptions);
    return (
      <Comp
        {...props}
        popperStyles={popperStyles}
        popperAttributes={popperAttributes}
        setPopperElement={setPopperElement}
        setPopperParentRef={setParentRef}
      />
    );
  };
export default withCustomPopper;
