/** @prettier */
import { StoryboardStartersStore } from '../stores/storyboard_starters';
import { createTypedActions } from '../stores/AltStore';

class StoryboardStartersActions {
  fetch: StoryboardStartersStore['handleFetch'] = function() {
    this.dispatch();
  };
}

const actions = createTypedActions<StoryboardStartersActions>(
  StoryboardStartersActions,
);
export { actions as StoryboardStartersActions };
