const createReactClass = require('create-react-class')
const screensizeMonitor = require('../shared/screenSizeMonitor.jsx');
const PureRenderMixin = require("react-addons-pure-render-mixin");

/**
 * This Higher Order Component deals with subscribing a component to the
 * screen size monitor.
 *
 * @example
 *   module.exports = monitorScreenSize(
 *     'DashboardToolbar', 640, 'showIcons'
 *   )(createReactClass({
 *    render() {…}
 *   }))
 *
 * @param {string} keyName
 * @param {number} breakpoint
 * @param {string} propName
 */
module.exports = (keyName, breakpoint, propName) => Component => {
  screensizeMonitor.register(keyName, breakpoint);

  return createReactClass({
    displayName: `ScreensizeMonitor(${Component.displayName || Component.name || 'unknown'})`,
    mixins: [PureRenderMixin],

    getInitialState: function() {
      return {
        [propName]: screensizeMonitor.get(keyName)
      }
    },

    componentDidMount: function() {
      screensizeMonitor.listen(keyName, this._onSizeChange)
    },

    componentWillUnmount: function() {
      screensizeMonitor.unlisten(keyName, this._onSizeChange)
    },

    _onSizeChange: function(isReached) {
      this.setState({ [propName]: isReached })
    },

    render() {
      return <Component {...this.props} {...this.state} />
    }
  })
}
