/** @format */
import { PlayerActions } from '../../flux/actions/player';
import * as React from 'react';
import classNames from 'classnames';
import { feedbackBackgrounds } from 'blackbird/components/feedback/constants';
import { Trans, useTranslation } from 'react-i18next';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';

export const FrameDurationMismatch: React.VFC = () => {
  const { t } = useTranslation();
  const dialogContext = React.useContext(DialogContext);
  const confirmRedistribution = async () => {
    if (
      await openConfirmDialog(
        t('player.frameDurationMismatch.confirm'),
        dialogContext,
      )
    )
      PlayerActions.spreadDurationToMatch.defer();
  };

  return (
    <div
      className={classNames(
        'rounded-lg p-4 whitespace-pre-line text-sm',
        feedbackBackgrounds.warning,
      )}
    >
      <h3 className="font-bold">{t('player.frameDurationMismatch.title')}</h3>

      <Trans
        t={t}
        i18nKey="player.frameDurationMismatch.message"
        className="whitespace-pre-line text-sm"
      >
        There is a mismatch between the length of the sound and your frames.
        <button
          onClick={confirmRedistribution}
          type="button"
          className="underline dib pa0"
        >
          redistribute the frames
        </button>
      </Trans>
    </div>
  );
};
