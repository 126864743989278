/** @prettier */
import * as React from 'react';

import type { Project } from 'javascripts/flux/stores/dashboard';
import type { User } from 'blackbird/components/comments/types';
import { sortBy } from 'underscore';
import { TextSearch } from '../shared/TextSearch';
import { useMaintainSize } from '../shared/useMaintainSize';
import { EmptyState } from 'blackbird/components/feedback/empty-state/EmptyState';
import { useTranslation } from 'react-i18next';

const propsToSearch: (keyof Project)[] = ['name'];

export const ArchivedProjects: React.FC<{
  projects: Project[];
  user: User;
}> = (props) => {
  const { t } = useTranslation();
  const containerRef = React.useRef<HTMLDivElement>(null);
  useMaintainSize(containerRef, [props.projects]);

  const sortedProjects = React.useMemo(
    () =>
      sortBy(props.projects, (p) =>
        new Date(p.deleted_at!).valueOf(),
      ).reverse(),
    [props.projects],
  );

  if (props.projects.length < 1) {
    return <EmptyState>{t('modals.archive.empty.projects')}</EmptyState>;
  }

  return (
    <TextSearch options={sortedProjects} propsToSearch={propsToSearch}>
      {(filteredOptions) => (
        <div className="w-full" ref={containerRef}>
          {filteredOptions.map((p, index) => (
            <ArchivedProject
              user={props.user}
              key={'archived-project-' + index}
              project={p}
            />
          ))}
        </div>
      )}
    </TextSearch>
  );
};
