/** @prettier */
import { IStoryboardInStore } from '../types/storyboard';
const sanitizeFilename = require('sanitize-filename');

export const constructFilename = ({
  storyboard,
  extension,
  suffix,
}: {
  storyboard: Pick<
    IStoryboardInStore,
    'document_name' | 'version_number' | 'include_version_number'
  >;
  extension: string;
  suffix?: string;
}) => {
  if (typeof storyboard === 'undefined')
    throw new Error('missing param "storyboard"');

  let filename = storyboard.document_name.replace(/ /g, '_');

  if (storyboard.include_version_number)
    filename += `_v${storyboard.version_number}`;
  if (suffix) filename += `_${suffix}`;

  filename += `.${extension}`;

  return sanitizeFilename(filename);
};
