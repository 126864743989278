/** @format */

import { memoize, once } from 'underscore';
import { RequestErrorHandler } from './request-error-handler';

const errorHandler = RequestErrorHandler('rollout');

const currentFlags = [
  'Twofactor',
  'Fielddata',
  'Framelimitexemption',
  'Storyboardduration',
  'Welcometemplate',
  'Characters',
  'Annotations',
  'Showsales',
] as const;

type CurrentFlags = typeof currentFlags[number];

const report = memoize((key) =>
  once(() => {
    if (!BoordsConfig) {
      errorHandler({
        messageKey: null,
        severity: 'warn',
      })(new Error(`[ROLLOUT] BoordsConfig was not found`));
    } else {
      errorHandler({
        messageKey: null,
        severity: 'warn',
      })(new Error(`[ROLLOUT] Rollout flag with key ${key} was not found`));
    }
  }),
);

export const hasRolloutFlagEnabled = (key: CurrentFlags): boolean => {
  const value = BoordsConfig?.Rollout?.[key];
  if (!value) report(key);
  return value ?? false;
};

export const useRollout = (key: CurrentFlags) => {
  return hasRolloutFlagEnabled(key);
};
