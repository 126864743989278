/** @prettier */
import * as React from 'react';
import Button from '../button/Button';
import { IFPermissionPanel } from 'javascripts/helpers/has-permission';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import type { ButtonSize, ButtonType } from '../button/types';

interface IRestrictedAccessButtonContainerProps {
  teamAdminId: number;
  link: string;
  text: string;
}

export const RestrictedAccessButtonContainer: React.FC<
  IRestrictedAccessButtonContainerProps
> = ({ teamAdminId, link, text }) => {
  const buttonType: ButtonType = 'solid';
  const buttonSize: ButtonSize = 'sm';
  return (
    <DefaultStoreContextProvider>
      <IFPermissionPanel
        storyboardOwnerId={teamAdminId}
        featureName={`role_based_access`}
        isButton
        buttonType={buttonType}
        buttonSize={buttonSize}
      >
        <Button type={buttonType} size={buttonSize} link={link}>
          {text}
        </Button>
      </IFPermissionPanel>
    </DefaultStoreContextProvider>
  );
};

(window as any).RestrictedAccessButtonContainer =
  RestrictedAccessButtonContainer;
