/** @prettier */
import type { PDFLayoutProps, DocInfo } from '../types';
import type { IFrame } from '../../../types/frame';
import { createPDFFooter } from './createPDFFooter';
import { loadFrameImages } from './loadFrameImages';
import { createPDFListItem } from './createPDFListItem';
import { getCurrentPageNumber } from './getCurrentPageNumber';
import { applyPDFWatermark } from './applyPDFWatermark';
import logger from 'javascripts/helpers/logger';

export const createPDFListPage = (
  docInfo: DocInfo,
  props: PDFLayoutProps,
  frames: IFrame[],
  callback: (err?, framesUsed?: number) => void,
) => {
  const { margin, rowSpacing } = props.style;
  const { doc, pageFormat } = docInfo;
  const width = doc.internal.pageSize.width;
  const height = doc.internal.pageSize.height;
  const availableWidth = width - margin.left - margin.right;

  doc.addPage(pageFormat.format, pageFormat.orientation);

  if (props.style.backgroundColor) {
    doc.setFillColor(props.style.backgroundColor);
    doc.rect(0, 0, width, height, 'F');
  }

  const footer = createPDFFooter(docInfo, {
    bottom: height - props.style.margin.bottom,
    text: props.footerText,
    debug: props.isDebugMode,
    style: props.style,
    pageNumber: props.isQuickMode ? props.pageNumber : undefined,
  });

  const availableHeight =
    height -
    margin.top -
    margin.bottom -
    footer.height -
    props.style.footerMargin.top;

  if (props.isDebugMode) {
    // Draw available area
    doc.setDrawColor(150, 255, 150);
    doc.rect(margin.left, margin.top, availableWidth, availableHeight);
  }

  doc.setFontSize(props.style.fontSize);
  const maxLineWidth = doc.getTextWidth('M') * 52;

  const useLowRes = props.isQuickMode || props.isTrialPreview;
  loadFrameImages(frames, props.style.frameAspectRatio, useLowRes)
    .then((imageUrls) => {
      let offsetY = margin.top;
      let didOverflow = false;
      const framesUsed = [...frames];

      frames.forEach((frame) => {
        const item = createPDFListItem(
          docInfo,
          {
            x: margin.left,
            y: offsetY,
            frame: frame,
            width: availableWidth,
            style: props.style,
            isDebugMode: props.isDebugMode,
            maxLineWidth,
          },
          imageUrls[frame.id],
        );

        offsetY += item.height + rowSpacing;

        const height = offsetY - margin.top;
        if (!props.disableOverflow && height - rowSpacing >= availableHeight) {
          didOverflow = true;
        }
      });

      if (didOverflow && framesUsed.length > 1) {
        framesUsed.pop();
        logger.log(
          `Overflowed, creating a new page with only ${framesUsed.length}/${frames.length} frames`,
        );

        doc.deletePage(getCurrentPageNumber(doc));
        createPDFListPage(docInfo, props, framesUsed, callback);
      } else if (props.isTrialPreview) {
        applyPDFWatermark(docInfo)
          .then(() => callback(null, framesUsed.length))
          .catch((err) => callback(err));
      } else {
        callback(null, framesUsed.length);
      }
    })
    .catch((err) => callback(err));
};
