/** @prettier */
import * as React from 'react';
import { AssetsActions } from '../../../flux/actions/assets';
import { useStore } from '../../../helpers/useStore';
import {
  AssetsStore,
  StoryboardFramesAsset,
} from '../../../flux/stores/assets';
import {
  PanelbarFrameAssetList,
  parsedAssetItem,
} from './PanelbarFrameAssetList';
import { DRAGGABLE_TYPES } from '../../shared/dragAndDropUtils';
import { PanelbarAssetListEmptyState } from './PanelbarAssetListEmptyState';
import { useTranslation } from 'react-i18next';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';

interface Props {
  storyboardId: number;
  storyboardSlug: string;
}

export const PanelbarFrameAssets: React.FC<Props> = (props) => {
  React.useEffect(() => {
    AssetsActions.fetchFrameAssets.defer({
      storyboardID: props.storyboardId,
    });
    AssetsActions.setVisible.defer(true);
    return () => AssetsActions.setVisible.defer(false);
  }, [props.storyboardId]);

  const assets = useStore<StoryboardFramesAsset[] | undefined, AssetsStore>(
    'assets',
    (store) => store.storyboardFramesAssets,
  );

  const isLoading = useStore<boolean, AssetsStore>(
    'assets',
    (store) => store.frameAssetsAreLoading,
  );

  const { t } = useTranslation();
  const dialogContext = React.useContext(DialogContext);

  const handleDeleteItem = React.useCallback(
    async (item: parsedAssetItem) => {
      if (
        await openConfirmDialog(
          'This will permanently delete this frame from your storyboard. There is no undo for this. Do you want to continue?',
          dialogContext,
        )
      ) {
        AssetsActions.deleteFrameAsset.defer({
          frameId: parseInt(item.id as string, 10),
          storyboardHashid: props.storyboardSlug,
          storyboardId: props.storyboardId,
        });
      }
    },
    [props.storyboardSlug, props.storyboardId, dialogContext],
  );

  return (
    <PanelbarFrameAssetList<StoryboardFramesAsset>
      items={assets}
      isLoading={isLoading}
      onDeleteItem={handleDeleteItem}
      emptyState={
        <PanelbarAssetListEmptyState
          title={t('imageLibrary.frames.empty.title')}
          link="https://help.boords.com/en/articles/5394282-using-the-image-library"
        >
          {t('imageLibrary.frames.empty.description')}
        </PanelbarAssetListEmptyState>
      }
      parse={(asset) => ({
        imageUrl: asset.thumbnail_image_url,
        dragType: DRAGGABLE_TYPES.frame,
        canDelete: !asset.active,
        isUploading: false,
        id: asset.id,
      })}
      loadingText={t('loaders.frameAssets')}
    />
  );
};
