/** @format */
import Button from 'blackbird/components/button/Button';
import type { ButtonType } from 'blackbird/components/button/types';
import * as React from 'react';
import { isEmpty } from 'underscore';

interface Props {
  onClick?: React.MouseEventHandler;
  href?: string;
  color?: never;
  confirm?: string;
  target?: string;
  text: string;
  type?: ButtonType;
  shadow?: boolean | string;
  className?: string;
}

const AdminButton: React.FC<Props> = ({ type = 'outline', ...props }) => {
  const shadow: boolean | boolean = props.shadow
    ? JSON.parse(props.shadow as string)
    : undefined;

  if (!isEmpty(props.href)) {
    return (
      <Button
        type={type}
        size="sm"
        link={props.href}
        confirm={props.confirm}
        onClick={props.onClick}
        target={props.target}
        shadow={shadow}
        className={props.className}
      >
        {props.text}
      </Button>
    );
  } else {
    return (
      <Button
        type={type}
        size="sm"
        confirm={props.confirm}
        onClick={props.onClick}
        shadow={shadow}
        className={props.className}
      >
        {props.text}
      </Button>
    );
  }
};

(window as any).AdminButton = AdminButton;
