const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const BemHelper = require('react-bem-helper');
const ignoreWebP = require('../../helpers/ignore-webp');
const bemClasses = new BemHelper('timelineFrame');

module.exports = createReactClass({
  displayName: 'TimelineFramePreview',

  propTypes: {
    frame: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    theme: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  },

  shouldComponentUpdate(nextProps) {
    return this.props.frame.thumbnail_image_url !== nextProps.frame.thumbnail_image_url || this.props.isActive !== nextProps.isActive;
  },

  onClick(e) {
    if (this.props.onClick) {
      e.preventDefault();
      this.props.onClick(this.props.frame);
    }
  },

  render() {
    const { frame, isActive, frameSize } = this.props;
    let title = `Frame ${frame.number}`;
    if (frame.reference) {
      title += ` — ${frame.reference}`;
    }

    const height = 77;
    const ratio = height / frameSize.height;
    const width = Math.round(frameSize.width * ratio);
    const url = ignoreWebP(frame.thumbnail_image_url)

    return (
      <button
        {...bemClasses(null, { active: isActive }, "transition-transform focus:outline-none") }
        type="button"
        title={title}
        onClick={this.onClick}
        style={{ width, height }}
      >
        <img src={url} alt={title} className="h-full" data-hj-suppress/>
        <div
          className={`border-b-2 h-1 ${isActive ? 'block' : 'hidden'}`}
        />
      </button>
    )
  }
});
