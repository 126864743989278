/** @prettier */

import React, { FC, useEffect, useState, useCallback } from 'react';
import TextInput from '../form/text-input/TextInput';
import Panel from '../panel/Panel';
import Icon from '../icon/Icon';
import ClearIcon from '../../images/icons/clear.svg';
import SearchIcon from '../../images/icons/search.svg';
import { debounce, isObject } from 'underscore';
import ListItem, { ListItemSelectHandler } from '../common/list-item/ListItem';
import type { Option } from '../common/types';
import classNames from 'classnames';
import Popover from '../common/popover/Popover';
import { useWidth } from '../../helpers/hooks/useWidth';
import { useManageIndex } from '../../helpers/hooks/useManageIndex';

interface SearchProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  label?: string;
  loading?: boolean;
  onClearSearchHistory?: () => void;
  onSearch?: (value: string) => void;
  onSelect?: ListItemSelectHandler;
  options?: Option[];
  placeholder?: string;
  searchHistory?: string[];
  inputSize?: 'md' | 'lg';
  inputPlaceholder?: string;
  maxHeight?: number;
}

const Search: FC<SearchProps> = (props) => {
  const {
    className,
    disabled,
    error,
    label,
    loading,
    onClearSearchHistory,
    onSearch = () => true,
    onSelect,
    options,
    placeholder,
    searchHistory,
    inputSize,
    inputPlaceholder,
    maxHeight,
  } = props;

  const [open, setOpen] = useState(false);
  const [controlRef, setControlRef] = useState<HTMLDivElement | null>(null);
  const [scrollItemInView, setScrollItemInView] = useState(false);
  const [text, setText] = useState('');
  const handleSelectOption = (option: Option | string) => {
    if (!option) return;
    if (isObject(option)) setText(option.label as string);
    setOpen(false);
    if (onSelect) {
      onSelect(option);
    }
  };

  const enableScrolling = React.useCallback(
    () => setScrollItemInView(true),
    [setScrollItemInView],
  );

  const { currentIndex, setCurrentIndex, onCommit, onCurrentIndexChange } =
    useManageIndex(options?.length || searchHistory?.length, controlRef);

  /** Register handlers for useManageIndex */
  onCommit(() => {
    if (options?.length) {
      if (options.length > currentIndex) {
        handleSelectOption(options[currentIndex]);
      }
    } else {
      if (searchHistory && searchHistory.length > currentIndex) {
        setText(searchHistory[currentIndex]);
      }
    }
  });
  onCurrentIndexChange(enableScrolling);

  useEffect(() => {
    setCurrentIndex(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, text, searchHistory?.length, open, options?.length]);

  const panelWidth = useWidth(controlRef) + 48;
  const onSearchDebounced = useCallback(debounce(onSearch, 50), [onSearch]);

  useEffect(() => {
    onSearchDebounced(text);
  }, [onSearchDebounced, text]);

  const renderHistory = () => {
    if (loading || text || !searchHistory?.length || options?.length) {
      return null;
    }

    return (
      <>
        <div className="flex flex-row justify-between flex-shrink-0 w-full px-4 py-3 overflow-hidden flex-nowrap">
          <div className="text-sm font-bold">Recent searches</div>

          {onClearSearchHistory && (
            <div
              className="text-sm underline cursor-pointer"
              onMouseDown={(event) => event.preventDefault()}
              onClick={onClearSearchHistory}
            >
              Clear history
            </div>
          )}
        </div>
        {searchHistory.map((historyItem, index) => (
          <ListItem
            key={historyItem}
            option={historyItem}
            onClick={() => setText(historyItem)}
            scrollInView={scrollItemInView}
            highlight={currentIndex === -1 ? undefined : currentIndex === index}
            onMouseMove={() => {
              setScrollItemInView(false);
              setCurrentIndex(index);
            }}
          />
        ))}
      </>
    );
  };

  const renderOptions = () => {
    if (loading || !options?.length) {
      return null;
    }

    return (
      <>
        {options.map((option, index) => (
          <ListItem
            key={option.value ?? option.label}
            option={option}
            onClick={handleSelectOption}
            scrollInView={scrollItemInView}
            highlight={currentIndex === -1 ? undefined : currentIndex === index}
            onMouseOver={() => {
              setScrollItemInView(false);
              setCurrentIndex(index);
            }}
          />
        ))}
      </>
    );
  };

  const renderPanel = (styles) => {
    if (!open) {
      return null;
    }

    const panelStyle = {
      maxHeight: styles.popper.maxHeight,
      width: `${panelWidth}px`,
    };

    return (
      <Panel
        loading={loading}
        className={classNames(
          'w-full top-11 left-0 p-2 overflow-hidden hover:overflow-y-auto',
        )}
        placeholder={placeholder}
        message={
          text && !options?.length ? `No results for ${text}` : undefined
        }
        style={panelStyle}
      >
        {renderHistory()}

        {renderOptions()}
      </Panel>
    );
  };
  const renderSearchIcon = () => (
    <Icon color="subdued" icon={<SearchIcon />} className="w-4 ml-1" />
  );
  const renderClear = () => {
    if (!text) {
      return null;
    }

    return (
      <Icon
        color="disabled"
        icon={<ClearIcon />}
        className="h-6 ml-0.5"
        onClick={() => setText('')}
      />
    );
  };
  const handleOpen = () => disabled || setOpen(true);

  return (
    <Popover
      isOpen={open}
      onClose={() => setOpen(false)}
      placement="bottom-start"
      portal
      distance={12}
      offset={-34}
      maxHeight={maxHeight}
    >
      <Popover.Button className="flex">
        {({ setParentRef }) => (
          <TextInput
            ref={(ref) => {
              setParentRef && setParentRef(ref);
              setControlRef(ref);
            }}
            className={className}
            disabled={disabled}
            error={error}
            label={label}
            leftComponent={renderSearchIcon()}
            onBlur={() => setOpen(false)}
            onClick={handleOpen}
            onChange={(e) => {
              setText(e.currentTarget.value);
              setOpen(true);
            }}
            onKeyDown={(e) => {
              if (e.key === ' ') {
                e.stopPropagation();
              }
              if (e.key === 'Escape') {
                setOpen(false);
              }
            }}
            onFocus={handleOpen}
            rightComponent={renderClear()}
            value={text}
            inputSize={inputSize}
            placeholder={inputPlaceholder}
          />
        )}
      </Popover.Button>
      <Popover.Panel>{({ styles }) => renderPanel(styles)}</Popover.Panel>
    </Popover>
  );
};

export default Search;

export { SearchProps };
