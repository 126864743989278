/** @prettier */
import * as React from 'react';
import type { IPanelProps } from '../../shared/MultiPanel';
import {
  FrameLabelsContainer,
  type IFrameLabelProps,
} from './FrameLabelsContainer';
import { ExportBulkDownload } from './ExportBulkDownload';
import { IFPermissionPanel } from 'javascripts/helpers/has-permission';

interface IProps extends IPanelProps {
  storyboardSlug: string;
  storyboardOwnerId: number;
  storyboardId: number;
}

interface IHydratedProps extends IProps, IFrameLabelProps {}

const ExportImages: React.FunctionComponent<IProps> = (
  props: IHydratedProps,
) => {
  return (
    <IFPermissionPanel
      featureName="bulk_export"
      storyboardOwnerId={props.storyboardOwnerId}
    >
      <ExportBulkDownload {...props} />
    </IFPermissionPanel>
  );
};

ExportImages.displayName = 'StoryboardExportImages';

export const withContainer = FrameLabelsContainer(ExportImages);
