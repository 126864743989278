/** @format */

import * as React from 'react';
import { TextInput } from '../text-input/TextInput';
import { FormattingToolbarButton } from './FormattingToolbarButton';
import BackIcon from 'blackbird/images/icons/back.svg';
import Button from 'blackbird/components/button/Button';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { LinkIcon } from '@heroicons/react/20/solid';
import Icon from 'blackbird/components/icon/Icon';

type FormData = { url: string };

interface Props {
  onSubmit: (string: string) => void;
  onClose: () => void;
}

export const FormattingToolbarLinkInput = React.forwardRef<HTMLElement, Props>(
  (props, ref) => {
    const { onSubmit } = props;
    const {
      register,
      handleSubmit: handleValidation,
      formState: { errors },
    } = useForm<FormData>();

    const handleSubmit = React.useCallback<SubmitHandler<FormData>>(
      (data) => {
        onSubmit(data.url);
      },
      [onSubmit],
    );

    return (
      <div className="flex items-center font-normal gap-2" ref={ref as any}>
        <FormattingToolbarButton
          name="back"
          icon={<BackIcon />}
          aria-label="Back"
          onClick={props.onClose}
          className="shrink-0"
          iconClassName="w-5 h-auto"
        />
        <TextInput
          {...register('url', {
            pattern:
              /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
          })}
          inputSize="xs"
          leftComponent={
            <Icon
              className="w-auto h-4 pl-2 -mt-1"
              icon={<LinkIcon />}
              color={errors.url ? 'error' : undefined}
            />
          }
          autoFocus
          inputClassName="p-1.5"
          className="flex-auto my-[4px]"
          inputMode="url"
          color={errors.url ? 'Not a valid URL' : undefined}
        />
        <Button size="sm" onClick={handleValidation(handleSubmit)}>
          {`Save`}
        </Button>
      </div>
    );
  },
);

FormattingToolbarLinkInput.displayName = 'FormattingToolbarLinkInput';
