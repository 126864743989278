/** @format */
import type { ConfirmationDialogProps } from 'blackbird/components/dialog/Dialog';
import { isString } from 'underscore';

/** Opens a dialog and returns a promise with a boolean argument indicating if
 * the dialog was accepted or cancelled */
export const openConfirmDialog = (
  confirmDialogProps: string | ConfirmationDialogProps,
  context: any,
): Promise<boolean> =>
  new Promise((resolve) => {
    const inheritedProps: ConfirmationDialogProps = isString(confirmDialogProps)
      ? {
          description: confirmDialogProps,
          initialFocus: 'confirmButton',
        }
      : confirmDialogProps;

    const props: ConfirmationDialogProps = {
      ...inheritedProps,
      isOpen: true,
      size: 'confirm',
      confirmBtnSize: 'sm',
      cancelBtnSize: 'sm',
      alignButtons: 'left',
      isDark: true,
      onCancel: () => {
        resolve(false);
        context.setDialogState();
      },
      onConfirm: () => {
        context.setDialogState();
        resolve(true);
      },
    };
    context.setDialogState(props);
  });
