/** @prettier */
import * as React from 'react';
import * as navigateToRoute from '../../helpers/router/navigate-to-route';
import * as _ from 'underscore';
import Container from '../shared/Container';
const { FrameDurationMismatch } = require('./FrameDurationMismatch');
import {
  createPermissionFlyoverOpener,
  IFPermissionPanel,
  usePermission,
} from '../../helpers/has-permission';
import Button from 'blackbird/components/button/Button';
import { Trans, useTranslation } from 'react-i18next';
import { PlayerPopoverTitle } from './PlayerPopoverTitle';
import { feedbackBackgrounds } from 'blackbird/components/feedback/constants';
import classNames from 'classnames';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { RelativeTime } from 'blackbird/components/relativeTime/RelativeTime';
import { downloadEvent } from 'blackbird/helpers/eventContextHelper';

export interface IProps {
  storyboardId: number;
  storyboardOwnerId: number;
  frameDurationMismatch: boolean;
  updateHeight?: () => void;
  showTitle?: boolean;
  hasAccess?: boolean;
}

interface IAnimaticData {
  animatic?: {
    animatic?: any;
    fetching: boolean;
    error?: any;
  };
}

interface IAnimaticActions {
  onGenerateClick: (e: React.MouseEvent<HTMLElement>) => void;
  onUpgradeClick: (e: React.MouseEvent<HTMLElement>) => void;
  onCancelClick: (e: React.MouseEvent<HTMLElement>) => void;
  onRegenerateClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export interface IPropsWithContainer
  extends IProps,
    IAnimaticActions,
    IAnimaticData {}

export const DownloadOverlay: React.FC<IPropsWithContainer> = (props) => {
  const { t } = useTranslation();
  // Tell React to run this function after every render
  React.useEffect(() => props.updateHeight?.());

  return (
    <IFPermissionPanel
      featureName="mp4"
      storyboardOwnerId={props.storyboardOwnerId}
    >
      {props.animatic && props.animatic.error ? (
        <>
          <PlayerPopoverTitle className="text-lg font-normal mb-7">
            {t('player.download.title')}
          </PlayerPopoverTitle>
          <div
            className={classNames(
              'rounded-lg p-4 whitespace-pre-line text-sm',
              feedbackBackgrounds.error,
            )}
          >
            {t('player.download.error')}
          </div>
        </>
      ) : !props.animatic || props.animatic.fetching ? (
        <div>
          {props.showTitle && (
            <PlayerPopoverTitle className="text-lg font-normal mb-7">
              {t('player.download.title')}
            </PlayerPopoverTitle>
          )}
          <LoadingIndicator pad />
        </div>
      ) : props.animatic.animatic.url ||
        props.animatic.animatic.is_processing ? (
        <div>
          {props.animatic.animatic.is_processing ? (
            <>
              <PlayerPopoverTitle className="text-lg font-normal mb-7">
                {t('player.download.processingTitle')}
              </PlayerPopoverTitle>
              <LoadingIndicator
                className="w-6 h-6"
                containerClassName="flex gap-4 items-center"
                text={
                  <span className="text-sm">
                    {t('player.download.rendering')}
                  </span>
                }
              />
              <button
                type="button"
                className="mt-5 text-sm underline text-type-subdued"
                onClick={props.onCancelClick}
              >
                {t('player.download.cancelProcessing')}
              </button>
            </>
          ) : (
            <>
              {props.showTitle && (
                <PlayerPopoverTitle className="text-lg font-normal mb-7">
                  {t('player.download.title')}
                </PlayerPopoverTitle>
              )}
              {props.frameDurationMismatch ? (
                <div className="mb-5">
                  <FrameDurationMismatch />
                </div>
              ) : null}
              <div className="flex flex-col gap-3">
                <Button
                  link={`/storyboards/${props.storyboardId}/animatic/download`}
                  className="w-full"
                  size="md"
                >
                  {t('player.download.download')}
                </Button>
                <div className="text-sm text-type-disabled">
                  <Trans
                    t={t}
                    i18nKey="player.download.createdFromNow"
                    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                    components={{
                      fromNow: (
                        <RelativeTime
                          date={new Date(props.animatic.animatic.generated_at)}
                        />
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="flex mt-4 gap-1">
                <div className="text-sm text-type-subdued">
                  {t('player.download.regenerateHeader')}
                </div>
                <button
                  className="text-sm underline"
                  type="button"
                  onClick={props.onRegenerateClick}
                >
                  {t('player.download.regenerateButton')}
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          {props.showTitle && (
            <PlayerPopoverTitle className="text-lg font-normal mb-7">
              {t('player.download.title')}
            </PlayerPopoverTitle>
          )}
          {props.frameDurationMismatch ? (
            <div className="mb-5">
              <FrameDurationMismatch />
            </div>
          ) : null}
          <Button
            className="w-full"
            type="solid"
            size="md"
            onClick={props.onRegenerateClick}
          >
            {t('player.download.generateButton')}
          </Button>
          <div className="mt-4 text-sm text-type-subdued">
            {t('player.download.generateNotice')}
          </div>
        </div>
      )}
    </IFPermissionPanel>
  );
};

DownloadOverlay.displayName = 'DownloadOverlay';

DownloadOverlay.defaultProps = {
  showTitle: true,
};

export const withContainer = Container(
  {
    animatic: (storeData, ownProps) =>
      storeData.storyboards[ownProps.storyboardId] || null,
  },
  (storeData, ownProps) => ({
    onMount: () => AnimaticActions.fetch(ownProps.storyboardId),
    onGenerateClick() {
      AnimaticActions.triggerGeneration.defer(ownProps.storyboardId);
      downloadEvent('mp4');
    },

    onUpgradeClick(e) {
      e.preventDefault();
      createPermissionFlyoverOpener(ownProps.storyboardOwnerId, 'mp4')();
    },

    onCancelClick(e) {
      e.preventDefault();
      AnimaticActions.cancelGeneration.defer(ownProps.storyboardId);
    },

    onRegenerateClick() {
      // if (isEmpty(state.animatic.url) || window.confirm(
      //   'Are you sure? this will throw away the old version of the animatic'
      // )) {
      AnimaticActions.triggerGeneration.defer(ownProps.storyboardId);
      downloadEvent('mp4');
      // }
    },
  }),
)(DownloadOverlay) as React.Component<IProps>;
