import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
const PanelbarTitle = props => (
  <div className={classNames("space-y-2 px-6",{"mt-6":!props.noMargin})}>
    <div className="text-lg text-type-primary">{props.title}</div>
    {typeof props.subtitle !== 'undefined' && (
      <div className="text-type-subdued text-sm">
        {props.subtitle}
      </div>
    )}
  </div>
)

PanelbarTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  noMargin:PropTypes.bool
}

export default PanelbarTitle;
