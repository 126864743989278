const { RequestErrorHandler } = require('../../helpers/request-error-handler');
const errorHandler = RequestErrorHandler('Animatic');
import '../actions/animatic';

(() => {
  const emptyEntry = {
    lastFetched: null,
    fetching: false,
    error: null,
    animatic: null
  }

  class AnimaticStore {
    constructor() {
      this.getEmptyEntry = () => Object.assign({}, emptyEntry());
      this.storyboards = {};
      this.bindListeners({
        handleFetch: AnimaticActions.FETCH,
        handleTriggerGeneration: AnimaticActions.TRIGGER_GENERATION,
        handleCancelGeneration: AnimaticActions.CANCEL_GENERATION,
      });
    }

    updateEntry(storyboardId, changes) {
      const existing = this.storyboards[storyboardId] || {};
      this.storyboards[storyboardId] = Object.assign(emptyEntry, existing, changes);
    }

    fetchAnimatic(storyboardId) {
      this.updateEntry(storyboardId, { fetching: true });

      return ajaxHelper.ajax({
        method: "get",
        url: `/storyboards/${storyboardId}/animatic`,
        success: function (response) {
          this.updateEntry(storyboardId, {
            lastFetched: new Date(),
            fetching: false,
            animatic: response,
            error: null,
          });

          this.emitChange();
        }.bind(this),
        error: function (response) {
          this.updateEntry(storyboardId, {
            lastFetched: new Date(), // So that retrying won't throw an error again every time
            fetching: false,
            error: response
          });

          errorHandler({
            // Don't show this to the user, there's already a message in the UI
            message: null
          })(response)

          this.emitChange();
        }.bind(this),
      });
    }

    handleFetch(storyboardId) {
      const existing = this.storyboards[storyboardId];
      if (
        !existing ||
        !existing.lastFetched ||
        (new Date() - existing.lastFetched > 1000 * 60 * 2)
      ) {
        this.fetchAnimatic(storyboardId)
      }
    }

    handleCancelGeneration(storyboardId) {
      ajaxHelper.ajax({
        method: "delete",
        url: `/storyboards/${storyboardId}/animatic/${storyboardId}`,
        success: function (response) {
          this.updateEntry(storyboardId, { animatic: response });
          RequestActions.success.defer("Render cancelled");
          this.emitChange();
        }.bind(this),
        error: errorHandler({
          messageKey: 'player.errors.cancelAnimatic',
        })
      });
    }

    handleTriggerGeneration(storyboardId) {
      ajaxHelper.ajax({
        method: "post",
        url: `/storyboards/${storyboardId}/animatic`,
        success: function (response) {
          this.updateEntry(storyboardId, { animatic: response });

          const loop = () => {
            this.fetchAnimatic(storyboardId).then(response => {
              if (response.is_processing) {
                window.setTimeout(loop, 5000);
              }

              if (response.errors) {
                RequestActions.error.defer("Sorry, there was a problem creating your MP4 file");
              }
            })
          }

          window.setTimeout(loop, 5000);
          this.emitChange();
        }.bind(this),
        error: errorHandler({
          messageKey: 'player.errors.generate',
        })
      });
    }
  }

  window.AnimaticStore = alt.createStore(AnimaticStore, 'AnimaticStore');

})();
