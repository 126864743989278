/** @format */
import Button from 'blackbird/components/button/Button';
import type { StoryboardStore } from 'javascripts/flux/stores/storyboard';
import { defaultFrameFields } from 'javascripts/helpers/defaultFrameFields';
import { useStore } from 'javascripts/helpers/useStore';
import type { FrameField, StateFrameField } from 'javascripts/types/storyboard';
import * as React from 'react';
import SettingsIcon from 'blackbird/images/icons/settings-3.svg';
import { FrameFieldItem } from './FrameFieldItem';
import {
  addFrameField,
  addPlaceholders,
  FRAME_FIELD_LIMIT,
} from 'javascripts/helpers/storyboard/frameFieldHelpers';
import { useTranslation } from 'react-i18next';
import { isEqual, isString, omit } from 'underscore';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';
import { useToggle } from 'blackbird/helpers/hooks/useToggle';
import { moveInArray } from 'javascripts/helpers/move-in-array';
import type { sortableOnChangeHandler } from 'blackbird/helpers/hooks/useSortable';
import { useRollout } from 'javascripts/helpers/rollout';
import { IconButton } from 'blackbird/components/common/IconButton';

import {
  createPermissionFlyoverOpener,
  usePermission,
} from 'javascripts/helpers/has-permission';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';
import type { timeout } from 'blackbird/helpers/types';

export const FrameFieldEditor: React.FC = () => {
  const { t } = useTranslation();

  const [canAddField, setCanAddField] = React.useState<boolean | undefined>(
    undefined,
  );

  const frameFieldsInStore = useStore<FrameField[], StoryboardStore>(
    'storyboard',
    (s) => s.storyboard.frame_fields ?? defaultFrameFields,
  );
  const frame_fields_version = useStore<number, StoryboardStore>(
    'storyboard',
    (s) => s.frame_fields_version,
  );
  const owner_id = useStore<number, StoryboardStore>(
    'storyboard',
    (s) => s.storyboard.project.owner.user_id,
  );

  const hasPermission = usePermission(owner_id, 'frame_fields');

  React.useEffect(() => {
    if (hasPermission !== 'fetching' && typeof canAddField === 'undefined') {
      setCanAddField(hasPermission);
    }
  }, [hasPermission, canAddField]);

  const [isEditing, toggleIsEditing] = useToggle();
  // Store the current state of the form
  const [frameFields, setFrameFields] = React.useState<StateFrameField[]>(
    addPlaceholders(frameFieldsInStore),
  );
  /** We want to keep track of the current state so we don't have to re-creeate
   * callbacks when the state changes */
  const currentState = React.useRef(frameFields);
  currentState.current = frameFields;

  const updateTimeout = React.useRef<timeout>();

  // Maintain a list of inputs so we can trigger `focus` on newly created inputs
  const lastCount = React.useRef(frameFields.length);
  const inputRefs = React.useRef<(HTMLInputElement | null)[]>([]);
  inputRefs.current = [];

  React.useEffect(() => {
    if (frameFields.length > lastCount.current) {
      inputRefs.current[inputRefs.current.length - 1]?.focus();
    }
    lastCount.current = frameFields.length;
  }, [inputRefs, frameFields.length]);

  const handleBlurOrSubmit = React.useCallback((e?: React.FormEvent) => {
    if (e && e.type === 'submit') e.preventDefault();

    updateTimeout.current = setTimeout(() => {
      StoryboardActions.commitFrameFields.defer(currentState.current);
    }, 200);
  }, []);

  const handleChange = React.useCallback(
    (fieldId, newFieldInfo: Partial<FrameField>) => {
      if (!isString(fieldId) || fieldId.length === 0)
        throw new Error('handleChange was called without a field id');

      if (updateTimeout.current) clearTimeout(updateTimeout.current);

      setFrameFields((prevState) => {
        const index = prevState.findIndex((f) => f.id === fieldId);

        const compare = omit(prevState[index], ['placeholder']);
        if (isEqual(compare, newFieldInfo)) return prevState;

        const newFrameFields = [...prevState];

        newFrameFields[index] = {
          ...prevState[index],
          ...newFieldInfo,
        };

        handleBlurOrSubmit();
        currentState.current = newFrameFields;
        return newFrameFields;
      });
    },
    [handleBlurOrSubmit],
  );

  const handleAddFieldClick = React.useCallback<React.MouseEventHandler>(() => {
    if (!canAddField) {
      createPermissionFlyoverOpener(BoordsConfig.Uid, 'frame_fields')();
    } else {
      setFrameFields((frameFields) => {
        const placeholder = t('frameFields.newFieldInputPlaceholder', {
          defaultValue: '',
          number: frameFields.length + 1,
        });

        Track.event.defer('frame_field_create', {
          frame_fields: frameFields.length - 1,
        });

        return addFrameField(frameFields, {
          placeholder: placeholder,
        });
      });

      handleBlurOrSubmit();
    }
  }, [t, handleBlurOrSubmit, canAddField, owner_id]);

  const handleReorder = React.useCallback<sortableOnChangeHandler>(
    (oldIndex, newIndex) => {
      return setFrameFields((oldValues) =>
        moveInArray(oldValues, [oldValues[oldIndex]], newIndex),
      );
    },
    [],
  );

  const dialogContext = React.useContext(DialogContext);

  const handleDeleteFieldClick = React.useCallback(
    async (fieldId: string) => {
      if (
        await openConfirmDialog(
          t('frameFields.deleteConfirmation'),
          dialogContext,
        )
      ) {
        if (updateTimeout.current) clearTimeout(updateTimeout.current);
        setFrameFields((frameFields) =>
          frameFields.filter((i) => i.id !== fieldId),
        );
        handleBlurOrSubmit();

        Track.event.defer('frame_field_delete', {
          frame_fields: frameFields.length - 1,
        });
      }
    },
    [t, setFrameFields, handleBlurOrSubmit],
  );

  return (
    <form className="flex flex-col gap-4" onSubmit={handleBlurOrSubmit}>
      <div className="flex items-center gap-4">
        <div className="flex-auto text-base text-type-primary">
          {t('sidebar.settings.storyboard.subtitle')}
        </div>

        <div className="mr-1">
          <IconButton
            aria-label={!isEditing ? `Order & delete fields` : `Back`}
            className="leading-none rounded-md py-0.5 px-0.5 hover:bg-surface-light"
            onClick={toggleIsEditing}
            icon={<SettingsIcon />}
            color="subdued"
          />
        </div>
      </div>

      {frameFields.map((field, index) =>
        // Ignore the label field for now
        index === 0 ? null : (
          <div key={field.id ?? index}>
            <FrameFieldItem
              name={field.id}
              key={field.id}
              placeholder={field.placeholder}
              label={field.label}
              isEnabled={field.isEnabled}
              icon={field.icon}
              id={field.id}
              onChange={handleChange}
              fieldVersion={frame_fields_version}
              onDelete={isEditing ? handleDeleteFieldClick : undefined}
              draggable={isEditing}
              onReorder={handleReorder}
              onCommitReorder={handleBlurOrSubmit}
              index={index}
              ref={(r) => inputRefs.current.push(r)}
            />
          </div>
        ),
      )}
      <div className="mb-6 text-right">
        <Button
          type="secondary"
          size="sm"
          onClick={handleAddFieldClick}
          disabled={frameFields.length >= FRAME_FIELD_LIMIT}
        >
          {t('frameFields.create')}
        </Button>

        {frameFields.length >= FRAME_FIELD_LIMIT && (
          <div className="mt-1 text-xs text-center text-type-subdued">
            {t('frameFields.limitReached', { limit: FRAME_FIELD_LIMIT })}
          </div>
        )}
      </div>
      <button
        type="submit"
        className="absolute top-0 right-0 visibility-hidden"
      />
    </form>
  );
};
