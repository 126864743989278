/** @prettier */
import React from 'react';

export const FrameIndex = React.memo<{ frameNo: number; of: number }>(
  (props) => (
    <div className="align-baseline font-bold">
      <span className="text-lg">{props.frameNo}</span>&nbsp;/
      {props.of}
    </div>
  ),
);

FrameIndex.displayName = 'FrameIndex';
