/** @prettier */

/*
   svg files for this component should
   1) not contain fill or stroke attributes with the default color anywhere
   BUT: if fill o stroke must remain empty then this
     should be forced with stroke='none' or fill='none'
     for the corresponding path
   2) not contain width/height attributes
   3) width and height still should be fixed in viewBox='0 0 width height'
      so that inner path elements are rendered correctly
 */

import React from 'react';
import type { FC } from 'react';
import type { IconColor } from './types';
import classNames from 'classnames';

export interface IconProps {
  className?: string;
  color?: IconColor;
  hoverColor?: IconColor;
  onClick?: () => void;
  fill?: boolean;
  hoverable?: boolean;
  icon: React.ReactElement;
  stroke?: boolean;
}

const colors: { [key in IconColor]: string } = {
  black: 'text-type-primary',
  white: 'text-white',
  subdued: 'text-icon-subdued',
  disabled: 'text-action-primary-disabled',
  pink: 'text-action-destructive',
  hover: 'text-icon-hover',
  accent: 'text-accent',
  warning: 'text-type-orange',
  error: 'text-form-error',
  none: '',
};

const Icon: FC<IconProps> = (props) => {
  const {
    className = 'h-6 w-auto',
    color = 'black',
    hoverColor = 'hover',
    onClick,
    fill = true,
    icon,
    stroke = false,
  } = props;

  const hoverable = props.hoverable || Boolean(onClick);

  return React.cloneElement(icon, {
    className: classNames(
      'inline-block',
      { 'fill-current': fill },
      { 'stroke-current': stroke },
      colors[color],
      { [`cursor-pointer hover:${colors[hoverColor]}`]: hoverable },
      className,
    ),
    onClick,
    onMouseDown: (event) => event.preventDefault(),
  });
};

export default Icon;
