/** @prettier */
import Banner from 'blackbird/components/feedback/banner/Banner';
import React, { useEffect, useRef } from 'react';
import { optOut } from '../helpers/newPDFExportOptin';

export const PDFArabicMessage = () => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const handleClick = () => {
    optOut();

    FlyoverActions.close();
    FlyoverActions.open.defer({ type: 'storyboardExport' });
  };

  useEffect(() => {
    ref.current?.scrollIntoView();
  }, []);
  return (
    <Banner
      className="mt-6"
      kind="warning"
      hideIcon
      message={
        <>
          We're still working on proper support for Arabic in our new PDF export
          tool. If you are running into unexpected issues, try using
          <button
            ref={ref}
            type="button"
            className="dib black underline pointer mx-1 pa0"
            onClick={handleClick}
          >
            the old version
          </button>
          of our PDF export.
        </>
      }
    />
  );
};
