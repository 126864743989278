/** @prettier */
import * as React from 'react';
import { type IStoryboard } from '../../types/storyboard';
import { ActivityLogContainer } from '../shared/ActivityLog';

export const PanelbarActivity: React.FC<{
  storyboard: IStoryboard;
}> = (props) => {
  return (
    <div className="px-6 mt-10">
      <ActivityLogContainer
        showTitle
        for="storyboards"
        id={props.storyboard.id}
        user_id={props.storyboard.project.owner.user_id}
      />
    </div>
  );
};
