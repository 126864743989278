/**
 * @prettier
 */

import { CloseButton } from 'blackbird/components/common/CloseButton';
import SidebarLayout from 'blackbird/components/layout/Sidebar';
import classnames from 'classnames';
import classNames from 'classnames';
import React from 'react';
import { ConditionalWrap } from './ConditionalWrap';

export const PanelBarLayout = React.memo<{
  sidebarComponent?: React.ReactNode;
  mainComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  headerPadding?: string | null;
  footerPadding?: string | null;
  footerClassName?: string;
  headerComponent?: React.ReactNode;
  children?: React.ReactNode;
  /** Supplying this function will create a close button */
  onCloseClick?: React.MouseEventHandler;
}>((props) => {
  const {
    sidebarComponent,
    footerComponent,
    headerComponent,
    headerPadding = 'py-5 px-5',
    footerPadding = 'py-7 px-5',
  } = props;

  return (
    <SidebarLayout>
      <SidebarLayout.Main>
        <div
          className={classNames(
            'flex min-h-vp flex-col',
            sidebarComponent ? 'w-sidebar-main' : 'w-full',
          )}
        >
          <div
            className={`flex-auto flex flex-col relative bottom-banner-spacing  max-h-full`}
          >
            <ConditionalWrap
              className={headerPadding || undefined}
              condition={
                Boolean(headerComponent && headerPadding) ||
                !!props.onCloseClick
              }
            >
              {headerComponent}

              {props.onCloseClick && (
                <CloseButton
                  customBackground
                  className="bg-white hover:bg-white/60"
                  onHandleCancel={props.onCloseClick}
                />
              )}
            </ConditionalWrap>

            {props.mainComponent}
            {props.children}

            <ConditionalWrap
              className={classnames(
                footerPadding || undefined,
                props.footerClassName,
              )}
              condition={Boolean(
                footerComponent && (footerPadding || props.footerClassName),
              )}
            >
              {footerComponent}
            </ConditionalWrap>
          </div>
        </div>
      </SidebarLayout.Main>
      {sidebarComponent && (
        <SidebarLayout.Bar className="fixed right-0 z-10 flex flex-col h-full max-h-dvh overflow-y-auto bg-white shadow-lg">
          {sidebarComponent}
        </SidebarLayout.Bar>
      )}
    </SidebarLayout>
  );
});

PanelBarLayout.displayName = 'PanelBarLayout';
