/** @prettier */

import React, { type FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import type * as PopperJS from '@popperjs/core';
import type Popover from '../../common/popover/Popover';
import useCustomPopper from 'blackbird/helpers/hooks/useCustomPopper';
import ReactDOM from 'react-dom';
import useBoolean from 'blackbird/helpers/hooks/useBoolean';

export interface TooltipProps {
  className?: string;
  title: React.ReactNode;
  placement?: PopperJS.Placement;
  /* apply a custom z-index to tooltips */
  zIndexClassName?: string;
  distance?: number;
  disabled?: boolean;
  domProps?: React.HTMLAttributes<unknown>;
  btnRef?:
    | ((instance: HTMLButtonElement | null) => void)
    | React.MutableRefObject<HTMLButtonElement | null>
    | null;
  as?: React.ComponentProps<typeof Popover.Button>['as'];
}

const Tooltip: FC<TooltipProps> = (props) => {
  const {
    children,
    className,
    disabled,
    zIndexClassName = 'z-tooltip',
    title,
    placement,
    distance = 5,
    domProps,
    btnRef,
    as = 'div',
  } = props;
  const isTouchDevice = BoordsConfig.IsTouchDevice;
  const skipTooltip = isTouchDevice;

  const refProps = btnRef ? { ref: btnRef } : {};
  const [parentRef, setParentRef] = useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { attributes, styles } = useCustomPopper(parentRef, popperElement, {
    placement,
    distance,
  });
  const [isHovered, setHover] = useBoolean(false);
  useEffect(() => {
    let stillHovered = false;
    let delayTimeout;
    const hideTooltip = () => {
      setHover.off();
      stillHovered = false;
    };
    if (parentRef) {
      parentRef.addEventListener('mouseenter', () => {
        stillHovered = true;
        delayTimeout = setTimeout(() => {
          if (stillHovered) setHover.on();
        }, 200);
      });
      parentRef.addEventListener('mouseleave', hideTooltip);
      parentRef.addEventListener('click', hideTooltip);
    }

    return () => {
      if (parentRef) {
        parentRef.removeEventListener('mouseenter', setHover.on);
        parentRef.removeEventListener('mouseleave', setHover.off);
        parentRef.removeEventListener('click', setHover.off);
      }
      if (delayTimeout) {
        clearTimeout(delayTimeout);
      }
    };
  }, [parentRef, setHover]);

  return disabled || skipTooltip ? (
    React.createElement(as, { ...domProps }, props.children)
  ) : (
    <>
      {React.createElement(
        as,
        { ...refProps, ...domProps },
        React.Children.map(children, (item) => {
          if (!item) return null;
          if (
            typeof item === 'string' ||
            typeof item === 'number' ||
            typeof item === 'boolean'
          )
            return item;
          // if(isEmptyObject(item))return null;
          if (!React.isValidElement(item)) return null;
          return React.cloneElement(item, {
            ref: (ref) => {
              if (!setParentRef) return;
              // Make sure we handle Class components
              if (ref && typeof ref.render !== 'undefined')
                return setParentRef(ReactDOM.findDOMNode(ref) as HTMLElement);

              setParentRef(ref);
            },
            // FIXME: There is a weird TS error here if I don't mark this as
            // any, should investigate at some point
          } as any);
        }),
      )}

      {isHovered &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={classNames(
              'inline-flex px-3 pb-2.5 pt-2 items-center rounded-md bg-action-primary text-white text-xs max-w-xs whitespace-pre-line',
              className,
              zIndexClassName,
            )}
          >
            {title}
          </div>,
          document.querySelector('body')!,
        )}
    </>
  );
};

/** When you're not sure if a tooltip will appear, you can use this. It'll
 * prevent any of the popover code from running if the `title` prop is not set. */
export const ConditionalTooltip: FC<
  Partial<TooltipProps> & { children: React.ReactElement }
> = ({ children, ...props }) => {
  if (!props.title || props.disabled) {
    return children;
  } else {
    return (
      <Tooltip {...props} title={props.title!}>
        {children}
      </Tooltip>
    );
  }
};

export default Tooltip;
