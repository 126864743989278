/** @prettier */
import { type strictUserFeaturesType } from 'javascripts/flux/stores/user_permissions';
import * as React from 'react';
import Button from '../button/Button';
import { useTranslation } from 'react-i18next';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { type ButtonType, type ButtonSize } from '../button/types';
import Tooltip from '../feedback/tooltip/Tooltip';

interface IFeatureUnavailableButtonProps {
  featureName: strictUserFeaturesType;
  buttonType: ButtonType;
  buttonSize: ButtonSize;
  showIcon?: boolean;
}

export const FeatureUnavailableButton: React.FC<
  IFeatureUnavailableButtonProps
> = ({ featureName, buttonSize, buttonType, showIcon }) => {
  const { t } = useTranslation('inline_ctas');

  const handleClickTracking = () => {
    Track.event.defer(`feature_unavailable_checkout_click`, {
      newPlan: t(`${featureName}.new_plan.slug`),
      context: featureName,
      category: 'Checkout',
    });
  };

  return (
    <Tooltip placement="top" distance={10} title={t(`${featureName}.subtitle`)}>
      <Button
        onMouseDown={handleClickTracking}
        type={buttonType}
        size={buttonSize}
        link={`/checkout/${t(`${featureName}.new_plan.slug`)}/monthly`}
      >
        <span>{t(`${featureName}.title`)}</span>

        {showIcon && <LockClosedIcon className="w-4" />}
      </Button>
    </Tooltip>
  );
};
