/** @prettier */
import * as React from 'react';
import Dialog from 'blackbird/components/dialog/Dialog';
import { ProjectsAccordion } from 'blackbird/components/projects/ProjectsAccordion';
import { ProjectsContext } from 'blackbird/components/projects/ProjectsContext';

export const ProjectSingleMoveDialog: React.FC = () => {
  const {
    isSingleProjectMoveModalOpen,
    setDisabledMoveTargetProjectIds,
    setDisabledMoveTargetParentIds,
    setIsSingleProjectMoveModalOpen,
    selectedProject,
    newProjectGroup,
    newProjectParent,
    moveItems,
  } = React.useContext(ProjectsContext);

  const handleClose = () => {
    setIsSingleProjectMoveModalOpen(false);
    setDisabledMoveTargetProjectIds(new Set());
    setDisabledMoveTargetParentIds(new Set());
  };

  const handleMoveProject = React.useCallback(() => {
    if (selectedProject) {
      // Assuming moveItems can handle moving a single project
      // You might need to adjust this based on your implementation
      moveItems();
    }
    handleClose();
  }, [selectedProject, moveItems]);

  // Disable the button unless there's a parent or group present
  const confirmBtnProps = { disabled: !newProjectGroup && !newProjectParent };

  return !isSingleProjectMoveModalOpen ? null : (
    <Dialog
      isDark
      size={'sm'}
      title={`Move "${selectedProject?.name}" to...`}
      isOpen
      keepOpen
      hideActionDivider
      confirmText={`Move`}
      confirmBtnProps={confirmBtnProps}
      onConfirmBtnClick={handleMoveProject}
      onCancelBtnClick={handleClose}
      onCloseBtnClick={handleClose}
      onEscapeOrOutsideClick={handleClose}
    >
      <div className="border rounded-sm border-form">
        <ProjectsAccordion isSelectionMode />
      </div>
    </Dialog>
  );
};
