/** @format */

import type { fieldData, frameFieldId, IFrame } from 'javascripts/types/frame';
import type {
  FrameField,
  IStoryboardInStore,
} from 'javascripts/types/storyboard';
import { difference, pick, pluck, uniq } from 'underscore';
import logger from './logger';
import { removeNullCharacters } from './removeNullCharacters';
import { removeUnsupportedCharacters } from './removeUnsupportedCharacters';

const fieldsToKeep = [
  'reference',
  'voiceover',
  'direction',
  'note_4',
  'note_5',
  'note_6',
];

/** Gets the frame field data for the current storyboard, throws if there are none */
export const getFrameFieldInfo = () => {
  const storyboard: IStoryboardInStore =
    StoryboardStore.getState().storyboard ||
    ShareableStore.getState().storyboard;

  if (!storyboard.frame_fields) throw new Error('No frame_fields detected');
  return storyboard.frame_fields;
};

/** Removes keys in `field_data` that are not required by the storyboard. This
 * will keep empty values intact */
export const cleanFieldData = <T extends fieldData>(
  fieldData: T,
  storyboardFrameFields: FrameField[],
): Partial<T> => {
  if (!storyboardFrameFields)
    throw new Error('storyboardFieldData must be passed');

  // We want to make sure we extract both the default fields and any ones that
  // the user might have added as field_data. But ones that are no longer
  // present in `storyboardFrameFields` should be removed because they are
  // considered deleted.
  const keys = uniq([...fieldsToKeep, ...pluck(storyboardFrameFields, 'id')]);
  const result = pick(fieldData, keys);

  if (process.env.NODE_ENV === 'development' && result) {
    const removed = difference(Object.keys(fieldData), Object.keys(result));
    if (removed.length > 0)
      logger.log(`removed ${removed.length} keys:`, removed);
  }

  return result as any;
};

/** transforms a `frame`'s legacy frame fields to the new `field_data` property */
export const migrateFieldData = (
  frame: IFrame,
  storyboardFrameFields: FrameField[],
): fieldData => {
  if (!storyboardFrameFields)
    throw new Error('storyboardFieldData must be passed');

  // We want to make sure we extract both the default fields and any ones that
  // the user might have added as field_data. But ones that are no longer
  // present in `storyboardFrameFields` should be removed because they are
  // considered deleted.
  const keys: frameFieldId[] = uniq([
    ...fieldsToKeep,
    ...pluck(storyboardFrameFields, 'id'),
  ]);

  return keys.reduce<fieldData>((o, field) => {
    const data: string | undefined = frame.field_data?.[field] ?? frame[field];
    if (data && data.length > 0) o[field] = data;
    return o;
  }, {});
};

/* TODO@FieldData: should update this when old method is officially removed */
/** Grabs the values from either `field_data` or the deprecated frame fields.
 * Defaults to ''. */
export const getFrameField = (frame: IFrame, fieldId: string): string => {
  return (
    removeUnsupportedCharacters(
      frame.field_data ? frame.field_data[fieldId] : frame[fieldId],
    ) ?? ''
  );
};

/**
 * Sets a frame field. If it exists on the frame as one of the main properties,
 * it updates that as well. Will default to `getFrameFieldInfo` if a
 * `field_data` is not present on the frame and no `frameFieldInfo` has been
 * passed */
export const setFrameField = (
  frame: IFrame,
  fieldId: frameFieldId,
  value: string,
  frameFieldInfo: FrameField[] = getFrameFieldInfo(),
) => {
  // eslint-disable-next-line no-control-regex
  value = removeNullCharacters(value);
  value = removeUnsupportedCharacters(value);

  // TODO@FieldData: remove this when field_data transition is over
  if (Object.prototype.hasOwnProperty.call(frame, fieldId)) {
    frame[fieldId] = value;
  }
  if (!frame.field_data) {
    frame.field_data = migrateFieldData(frame, frameFieldInfo);
  }

  frame.field_data = cleanFieldData(frame.field_data, frameFieldInfo);

  frame.field_data = {
    ...frame.field_data,
    [fieldId]: value,
  };

  return frame.field_data;
};
