/** @prettier */

import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { posthog } from 'posthog-js';
import React, {
  createContext,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { parseISO, isPast, addSeconds, addMinutes } from 'date-fns';
import {
  DefaultBannerTitle,
  DefaultFlyoverTitle,
  DefaultFlyoverPrice,
  DefaultFlyoverContents,
  DefaultFlyoverPreheader,
  DefaultFlyoverButton,
  DefaultBannerButton,
} from './dialog/Default';
import i18n from 'i18next';
import {
  type IBillingPlanCurrency,
  type AddOnProduct,
  type LocalPlanData,
  type Price,
} from 'blackbird/helpers/contexts/BillingContext';
import {
  DiscountBannerTitle,
  DiscountFlyoverButton,
  DiscountFlyoverContents,
  DiscountFlyoverPrice,
  DiscountFlyoverTitle,
} from './dialog/Discount';

export type OfferType = 'freemium_offer' | 'freemium_discount';

const initialOfferContextValues = {
  role: `creator`,
  setRole: () => {},
  isAi: true,
  setIsAi: () => {},
  isTeam: false,
  setIsOfferView: () => {},
  isOfferView: false,
  setIsTeam: () => {},
  offerType: 'freemium_offer' as OfferType,
  offerExpiryDate: false as Date | false,
  offerPeriod: 15,
  setOfferPeriod: () => {},
  setOfferExpiryDate: () => {},
  bannerTitle: <DefaultBannerTitle />,
  bannerButton: <DefaultBannerButton />,
  flyoverPreHeader: <DefaultFlyoverPreheader />,
  flyoverTitle: <DefaultFlyoverTitle />,
  flyoverSignupCount: <></>,
  flyoverPrice: <DefaultFlyoverPrice />,
  flyoverContents: <DefaultFlyoverContents />,
  flyoverButton: <DefaultFlyoverButton />,
  plans: undefined,
  addOnProduct: undefined,
  addOnPrice: undefined,
  referencePlan: undefined,
  referencePrice: undefined,
  percentageDiscount: undefined,
  coupon: BoordsConfig.AiCouponId,
  currency: 'usd' as IBillingPlanCurrency,
};

export const OfferContext = createContext<{
  offerType: OfferType;
  offerPeriod: number;
  setOfferPeriod: React.Dispatch<React.SetStateAction<number>>;
  offerExpiryDate: Date | false;
  setOfferExpiryDate: React.Dispatch<React.SetStateAction<Date | false>>;
  bannerTitle: React.ReactNode;
  bannerButton: React.ReactNode;
  flyoverPreHeader: React.ReactNode;
  flyoverTitle: React.ReactNode;
  flyoverSignupCount: React.ReactNode;
  flyoverPrice: React.ReactNode;
  flyoverContents: React.ReactNode;
  flyoverButton: React.ReactNode;
  plans: LocalPlanData[] | undefined;
  addOnProduct: AddOnProduct | undefined;
  addOnPrice: Price | undefined;
  referencePrice: Price | undefined;
  percentageDiscount: number | undefined;
  referencePlan: LocalPlanData | undefined;
  currency: IBillingPlanCurrency;
  coupon: string;
  role: string;
  setRole: React.Dispatch<React.SetStateAction<string>>;
  isOfferView: boolean;
  setIsOfferView: React.Dispatch<React.SetStateAction<boolean>>;
  isAi: boolean;
  setIsAi: React.Dispatch<React.SetStateAction<boolean>>;
  isTeam: boolean;
  setIsTeam: React.Dispatch<React.SetStateAction<boolean>>;
}>(initialOfferContextValues);

export const OfferProvider: React.FC = ({ children }) => {
  const [role, setRole] = useState<string>(initialOfferContextValues.role);
  const [isAi, setIsAi] = useState<boolean>(initialOfferContextValues.isAi);
  const [isOfferView, setIsOfferView] = useState<boolean>(
    initialOfferContextValues.isOfferView,
  );
  const [isTeam, setIsTeam] = useState<boolean>(
    initialOfferContextValues.isTeam,
  );
  const [offerPeriod, setOfferPeriod] = useState<number>(
    initialOfferContextValues.offerPeriod,
  );

  const [offerType, setOfferType] = useState<OfferType>(
    initialOfferContextValues.offerType,
  );
  const [offerExpiryDate, setOfferExpiryDate] = useState<Date | false>(
    initialOfferContextValues.offerExpiryDate,
  );
  const [coupon, setCoupon] = useState<string>(
    initialOfferContextValues.coupon,
  );
  const [bannerTitle, setBannerTitle] = useState<React.ReactNode>(
    initialOfferContextValues.bannerTitle,
  );
  const [bannerButton, setBannerButton] = useState<React.ReactNode>(
    initialOfferContextValues.bannerButton,
  );
  const [flyoverPreHeader, setFlyoverPreHeader] = useState<React.ReactNode>(
    initialOfferContextValues.flyoverPreHeader,
  );
  const [flyoverTitle, setFlyoverTitle] = useState<React.ReactNode>(
    initialOfferContextValues.flyoverTitle,
  );
  const [flyoverSignupCount, setFlyoverSignupCount] = useState<React.ReactNode>(
    initialOfferContextValues.flyoverSignupCount,
  );
  const [flyoverPrice, setFlyoverPrice] = useState<React.ReactNode>(
    initialOfferContextValues.flyoverPrice,
  );
  const [flyoverContents, setFlyoverContents] = useState<React.ReactNode>(
    initialOfferContextValues.flyoverContents,
  );
  const [flyoverButton, setFlyoverButton] = useState<React.ReactNode>(
    initialOfferContextValues.flyoverButton,
  );

  const [plans, setPlans] = React.useState<LocalPlanData[] | undefined>(
    undefined,
  );

  const [addOnProduct, setAddOnProduct] = React.useState<
    AddOnProduct | undefined
  >(undefined);
  const [addOnPrice, setAddOnPrice] = React.useState<Price | undefined>(
    undefined,
  );

  const [referencePrice, setReferencePrice] = React.useState<Price | undefined>(
    undefined,
  );
  const [percentageDiscount, setPercentageDiscount] = React.useState<
    number | undefined
  >(undefined);
  const [referencePlan, setReferencePlan] = React.useState<
    LocalPlanData | undefined
  >(undefined);

  const currency = BoordsConfig.Currency;
  // const currency = 'usd';

  const initLocalPlans = i18n.t('billing:plans', {
    returnObjects: true,
  }) as LocalPlanData[];

  const initAddOns = i18n.t('topups:products', {
    returnObjects: true,
  }) as AddOnProduct[];

  // ----------------- BOF Posthog ---------------------

  // type offerDiscount = 'control' | 'discount';

  // const discountVariant = useFeatureFlagVariantKey(
  //   'appOfferFirstMonth',
  // ) as offerDiscount;

  // posthog.featureFlags.override({
  //   appOfferFirstMonth: 'discount',
  // });

  // -------------- Set the offer type --------------

  useEffect(() => {
    const { QuizIsAi, QuizIsComplete } = BoordsConfig;
    if (QuizIsComplete) {
      if (QuizIsAi) {
        setOfferType('freemium_offer');
      } else {
        setOfferType('freemium_discount');
      }
    } else {
      if (isAi) {
        setOfferType('freemium_offer');
      } else {
        setOfferType('freemium_discount');
      }
    }
  }, [isAi]);

  useEffect(() => {
    if (offerType === 'freemium_discount') {
      if (currency === 'gbp') {
        setCoupon(`firstmonthgbp`);
      } else if (currency === 'eur') {
        setCoupon(`firstmontheur`);
      } else {
        setCoupon(`firstmonthusd`);
      }

      setBannerTitle(<DiscountBannerTitle />);
      setFlyoverTitle(<DiscountFlyoverTitle />);
      setFlyoverContents(<DiscountFlyoverContents />);
      setFlyoverPrice(<DiscountFlyoverPrice />);
      setFlyoverButton(<DiscountFlyoverButton />);
    }
  }, [offerType, currency]);

  // ----------------- EOF Posthog ---------------------

  useEffect(() => {
    const getOfferExpiryDate = (): Date | false => {
      try {
        const forceShow = false;
        // const forceShow = process.env.NODE_ENV === 'development';

        if (!BoordsConfig.IsConfirmed && !forceShow) {
          return false;
        }

        const parsedDate = forceShow
          ? new Date()
          : parseISO(BoordsConfig.ConfirmedAt);

        const offerExpiryDate = addSeconds(
          addMinutes(parsedDate, offerPeriod),
          0,
        );

        return isPast(offerExpiryDate) ? false : offerExpiryDate;
      } catch (e) {
        return false;
      }
    };

    setOfferExpiryDate(getOfferExpiryDate());
  }, [offerPeriod]);

  useEffect(() => {
    if (isOfferView) {
      const pageTitle = `⏰ One-time offer`;
      document.title = pageTitle;
      window.history.pushState(undefined, pageTitle, '/upgrade');
      return () => {
        window.history.pushState(undefined, 'Boords', '/');
        document.title = 'Project Dashboard • Boords';
      };
    }
  }, [isOfferView]);

  useLayoutEffect(() => {
    if (isOfferView) {
      Track.event.defer('ai_bundle_offer_view', {
        category: 'Checkout',
        isAi,
        isTeam,
      });

      Track.event.defer('ViewContent', {
        content_name: isAi ? 'aiOfferView' : 'discountOfferView',
      });
    }
  }, [isAi, isTeam, isOfferView]);

  useEffect(() => {
    setPlans(
      initLocalPlans.filter((p) => ['individual', 'group'].includes(p.slug)),
    );
  }, []);

  useEffect(() => {
    if (plans) {
      if (isTeam || BoordsConfig.QuizIsTeam) {
        setReferencePlan(plans.find((plan) => plan.slug === 'group'));
      } else {
        setReferencePlan(plans.find((plan) => plan.slug === 'individual'));
      }
    }
  }, [plans, isTeam]);

  useEffect(() => {
    if (referencePlan) {
      setReferencePrice(
        referencePlan.prices.find(
          (p) => currency === p.currency && p.interval === 'month',
        ),
      );
    }
  }, [referencePlan, currency]);

  useEffect(() => {
    setAddOnProduct(initAddOns.find((p) => p.slug === '1000-ai-credits'));
  }, []);

  useEffect(() => {
    if (addOnProduct) {
      setAddOnPrice(addOnProduct.prices.find((p) => p.currency === currency));
    }
  }, [addOnProduct, currency]);

  useEffect(() => {
    if (offerType && offerType === 'freemium_discount' && referencePrice) {
      if (currency === 'eur') {
        setPercentageDiscount(64); // EUR 25 → 9
      } else if (currency === 'gbp') {
        setPercentageDiscount(59.09); // GBP 22 → 9
      } else {
        setPercentageDiscount(68.97); // USD $29 → $9
      }
    } else if (addOnPrice && referencePrice) {
      setPercentageDiscount(
        Math.round(
          100 -
            (referencePrice.amount /
              (addOnPrice.amount + referencePrice.amount)) *
              100,
        ),
      );
    }
  }, [addOnPrice, referencePrice, offerType, currency]);

  const value = {
    offerExpiryDate,
    setOfferExpiryDate,
    offerType,
    setOfferType,
    bannerTitle,
    bannerButton,
    flyoverPreHeader,
    flyoverTitle,
    flyoverPrice,
    flyoverContents,
    flyoverButton,
    plans,
    addOnProduct,
    addOnPrice,
    referencePrice,
    percentageDiscount,
    referencePlan,
    currency,
    coupon,
    setCoupon,
    flyoverSignupCount,
    offerPeriod,
    setOfferPeriod,
    role,
    setRole,
    isAi,
    setIsAi,
    isTeam,
    setIsTeam,
    isOfferView,
    setIsOfferView,
  };

  return (
    <OfferContext.Provider value={value}>{children}</OfferContext.Provider>
  );
};
