/** @prettier */
import Select, {
  type SelectChangeHandler,
} from 'blackbird/components/form/select/Select';
import * as React from 'react';
import * as capitalize from '../../../helpers/capitalize';
import {
  regexTests as availableCharsets,
  charsetToCharsetInfo,
  type CharsetInfo,
  type charsetName,
} from '../../../helpers/detectCharset';

export const PDFCharsetOverride: React.FC<{
  value?: charsetName | null;
  detectedValue?: string;
  onChange: (subset: CharsetInfo | null) => void;
}> = (props) => {
  const [isCollapsed, setIsCollapsed] = React.useState(
    typeof props.value !== 'string' || props.value === '',
  );

  const onChange: SelectChangeHandler = (value: charsetName) => {
    props.onChange(charsetToCharsetInfo(value));
  };

  return isCollapsed ? (
    <button
      type="button"
      className="text-sm underline cursor-pointer text-type-subdued decoration-border-mid hover:no-underline underline-offset-2"
      onClick={() => setIsCollapsed(false)}
    >
      {`Set storyboard language`}
    </button>
  ) : (
    <>
      <div className="mb-5 font-bold">Storyboard language</div>

      <Select
        value={props.value || ''}
        onChange={onChange}
        options={[
          {
            value: '',
            label: `Automatic (${capitalize(props.detectedValue)})`,
          },
          ...Object.keys(availableCharsets).map((charset) => ({
            value: charset,
            label: capitalize(charset),
          })),
        ]}
      />
    </>
  );
};
