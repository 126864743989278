/** @prettier */
import * as React from 'react';
import type { FakeAltStoreClass } from './stores/AltStore';
import lodashMerge from 'lodash.merge';

export type storeContextData = Record<string, FakeAltStoreClass<unknown>>;

// We don't want to import the stores in here, but rather when the provider is used
export type storeContextType = {
  stores: Partial<storeContextData>;
  actions: Record<string, unknown>;
};

export const StoreContext = React.createContext<Partial<storeContextType>>({});

export const StoreContextProvider: React.FC<{
  stores: Partial<storeContextData>;
  actions?: Partial<storeContextType['actions']>;
  /** deep merge the data with store contexts in the higher hierarchy (useful
   * for test/storybook scenarios) */
  merge?: boolean;
}> = ({ children, stores, actions, merge }) => {
  let data: storeContextType = { stores, actions: actions ?? {} };
  const context = React.useContext(StoreContext);

  if (merge) {
    data = lodashMerge({}, context, data);
  }

  return <StoreContext.Provider value={data}>{children}</StoreContext.Provider>;
};
