/** @format */
import { createTypedActions } from '../stores/AltStore';
import type { TeamManagementStore } from '../stores/team_management';

class TeamManagementActions {
  fetch: TeamManagementStore['handleFetch'] = function (id) {
    this.dispatch(id);
  };

  refresh: TeamManagementStore['handleRefresh'] = function () {
    this.dispatch();
  };

  inviteMember: TeamManagementStore['handleInviteMember'] = function (data) {
    this.dispatch(data);
  };

  bulkInviteMember: TeamManagementStore['handleBulkInviteMember'] = function (
    data,
  ) {
    this.dispatch(data);
  };

  resendInvite: TeamManagementStore['handleResendInvite'] = function (
    invitation_id,
  ) {
    this.dispatch(invitation_id);
  };

  clearMembers: TeamManagementStore['handleClearMembers'] = function () {
    this.dispatch();
  };

  rename: TeamManagementStore['handleRename'] = function (data) {
    this.dispatch(data);
  };

  setRole: TeamManagementStore['handleSetRole'] = function (data) {
    this.dispatch(data);
  };

  toggleProjectAccess: TeamManagementStore['handleToggleProjectAccess'] =
    function (data) {
      this.dispatch(data);
    };

  fetchIfNecessary: TeamManagementStore['handleFetchIfNecessary'] = function (
    data,
  ) {
    this.dispatch(data);
  };

  batchToggleProjectAccess: TeamManagementStore['handleBatchToggleProjectAccess'] =
    function (data) {
      this.dispatch(data);
    };
  fetchWithProjects: TeamManagementStore['handleFetchWithProjects'] = function (
    data,
  ) {
    this.dispatch(data);
  };
}

const actions = createTypedActions<TeamManagementActions>(
  TeamManagementActions,
);
export { actions as TeamManagementActions };
