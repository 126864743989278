/** @prettier */
import * as React from 'react';
import Icon from '../icon/Icon';
import ProjectIcon from '../../images/icons/project.svg';
import ArrowDownIcon from '../../images/icons/arrow.svg';

import Panel from '../panel/Panel';
import type { Option } from '../common/types';
import type {
  ISiblingStoryboard,
  IStoryboardInStore,
} from '../../../javascripts/types/storyboard';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import { useStore } from '../../../javascripts/helpers/useStore';
import type { StoryboardStore } from '../../../javascripts/flux/stores/storyboard';
import Button from '../button/Button';
import Toast from '../feedback/toast/Toast';
import { useStoreAction } from 'javascripts/helpers/useStoreAction';
import { useOnMount } from 'javascripts/helpers/useOnMount';
import { once } from 'underscore';
import { rollbar } from 'javascripts/helpers/rollbar';
import { TextSearch } from 'javascripts/components/shared/TextSearch';
import Tooltip from '../feedback/tooltip/Tooltip';

interface Props {
  projectName: string;
  state: 'loading' | 'loaded' | 'error';
  siblingStoryboards?: ISiblingStoryboard[];
  errorMessage?: string;
  onChange?: (o: Option) => void;
  onNewStoryboard: () => void;
}

const logDateError = once((string) => rollbar.error(string));

const sublabelText = (updatedAt: string) => {
  try {
    const date = new Date(updatedAt);
    return `Updated ${formatDistanceToNowStrict(date, {
      addSuffix: true,
    })}`;
  } catch (e) {
    logDateError(`EditedDate parsing error (${updatedAt}), returning null`);
    return undefined;
  }
};

export const ProjectDropdown: React.FC<Props> = (props) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleChange = (value) => {
    props.onChange?.(value);
  };

  const options = React.useMemo<Option[]>(() => {
    return (
      props.siblingStoryboards?.map((s) => ({
        label: s.document_name,
        subLabel: sublabelText(s.updated_at),
        value: s.slug,
      })) ?? []
    );
  }, [props.siblingStoryboards]);

  let message = props.errorMessage;
  if (options.length === 0)
    message = `This is the first storyboard in the “${props.projectName}” project. When you create more storyboards you'll see them here.`;

  return (
    <Listbox as="div" className="relative" onChange={handleChange} value={null}>
      {({ open }) => (
        <>
          <Listbox.Button
            className="flex items-center text-base rounded-sm outline-none text-type-subdued ring-none"
            ref={buttonRef}
          >
            <Icon
              icon={<ProjectIcon />}
              className="flex-shrink-0 h-5 mt-0.5"
              color="black"
            />
            <div className="ml-2 truncate" title={props.projectName}>
              {props.projectName}
            </div>

            <Icon
              icon={<ArrowDownIcon />}
              className={classNames(
                'flex-shrink-0 h-6 transition-all',
                open && '-rotate-180',
              )}
              color="black"
            />
          </Listbox.Button>

          <Listbox.Options
            as={Panel}
            className="absolute left-0 flex-col mt-2 w-80 top-full focus:outline-none"
            loading={props.state === 'loading'}
            autoMaxHeight
          >
            {props.state === 'error' ? (
              <div className="p-4">
                <Toast
                  message="Something went wrong loading this content…"
                  kind="error"
                  size="full"
                />
              </div>
            ) : (
              options.length > 0 && (
                <div className="p-4 pb-0">
                  <TextSearch options={options} autoFocus>
                    {(filteredOptions) => (
                      <div className="flex-auto pt-2 overflow-y-auto">
                        {filteredOptions.map((option) => (
                          <Listbox.Option
                            as="a"
                            href={`/storyboards/${option.value}`}
                            key={option.value}
                            value={option}
                          >
                            {({ active }) => (
                              <div
                                className={classNames(
                                  'flex w-full py-1.5 px-3 flex-nowrap flex-col cursor-pointer rounded',
                                  {
                                    'hover:bg-surface-light hover:text-brand-pink':
                                      active,
                                  },
                                  { 'bg-surface-light': active },
                                )}
                              >
                                <div
                                  className="text-sm truncate"
                                  title={option.label as string}
                                >
                                  {option.label}
                                </div>

                                {Boolean(option.subLabel) && (
                                  <div className="text-xs text-type-subdued">
                                    {option.subLabel}
                                  </div>
                                )}
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </div>
                    )}
                  </TextSearch>
                </div>
              )
            )}
            {props.state !== 'error' && message && (
              <div className="flex items-center justify-center w-full px-8 pt-4 text-center">
                <div className="text-sm text-type-subdued">{message}</div>
              </div>
            )}

            {BoordsConfig.PermittedActions.includes('storyboards.create') && (
              <div
                className={classNames(
                  'w-full p-4 border-border border-t mt-4 bg-surface-light',
                )}
              >
                <Button
                  onClick={props.onNewStoryboard}
                  className="w-full"
                  size="sm"
                >
                  <span className="py-0.5">{`Create Project Storyboard`}</span>
                </Button>
              </div>
            )}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};

export const ProjectDropdownContainer: React.VFC = () => {
  const data = useStore('project', (s) => {
    return s.siblingStoryboards;
  }) ?? { isLoading: true, error: null };

  const storyboard = useStore<IStoryboardInStore, StoryboardStore>(
    'storyboard',
    (s) => s.storyboard,
  );

  const project = storyboard.project;

  const fetchAction = useStoreAction(
    'ProjectActions',
    'fetchSiblingStoryboards',
    {
      projectID: storyboard.project.id,
      storyboardID: storyboard.id,
    },
  );

  useOnMount(fetchAction);
  const projectName = project.name;

  let state: any = data.isLoading ? 'loading' : data.error ? 'error' : 'loaded';
  const siblingStoryboards = data?.storyboards ?? [];
  const handleNewStoryboard = () => {
    FlyoverActions.open({
      type: 'newStoryboard',
      projectId: project.id,
    });
  };
  return (
    <ProjectDropdown
      projectName={projectName}
      state={state}
      siblingStoryboards={siblingStoryboards}
      onNewStoryboard={handleNewStoryboard}
    />
  );
};
