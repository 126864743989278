/** @format */

import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import {
  type IStoryboardInStore,
  type IStoryboard,
} from 'javascripts/types/storyboard';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';
import { TeamUpgradePill } from './TeamUpgradePill';
import { TeamContext } from './TeamContext';
import { TeamProjectManageContent } from './TeamProjectManageContent';
import { ShareableUrlContainer } from '../../../javascripts/components/storyboard/ShareFlyover/ShareableUrlContainer.react';

export interface TeamStoryboardShareToolbarProps {
  storyboard: IStoryboard;
}

export const TeamStoryboardShareToolbar: React.FC<
  TeamStoryboardShareToolbarProps
> = ({ storyboard }) => {
  const { teamData } = React.useContext(TeamContext);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const handleOpenShareModal = () => {
    setIsShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  return (
    <>
      {teamData?.has_shareable_storyboards && (
        <Button link={storyboard.public_url} type="outline">
          {`Present`}
        </Button>
      )}
      <Button type="solid" onClick={handleOpenShareModal}>
        {`Share`}
      </Button>
      <Dialog
        isDark
        hideActions
        size="sm"
        isOpen={isShareModalOpen}
        onCloseBtnClick={handleCloseShareModal}
        onEscapeOrOutsideClick={handleCloseShareModal}
        title={`Share storyboard`}
      >
        <div className="relative pb-4">
          <Tab.Group>
            <Tab.List className="flex mb-8 border-b space-x-6 border-border">
              <Tab className="pb-2 text-xs font-semibold tracking-wider uppercase text-type-subdued focus:outline-none">
                {({ selected }) => (
                  <span
                    className={
                      selected
                        ? 'pb-2 border-b-2 border-brand-pink translate-y-1'
                        : ''
                    }
                  >
                    {`Link`}
                  </span>
                )}
              </Tab>
              <Tab className="pb-2 text-xs font-semibold tracking-wider uppercase text-type-subdued focus:outline-none">
                {({ selected }) => (
                  <span
                    className={
                      selected
                        ? 'pb-2 border-b-2 border-brand-pink translate-y-1'
                        : ''
                    }
                  >
                    {`Team members`}
                  </span>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                {!teamData?.has_shareable_storyboards ? (
                  <div className="mb-4 space-y-4">
                    <div className="flex items-center justify-between">
                      <span>{`Enable public link`}</span>
                      <TeamUpgradePill />
                    </div>
                  </div>
                ) : (
                  <ShareableUrlContainer
                    storyboard={storyboard as IStoryboardInStore}
                  />
                )}
              </Tab.Panel>
              <Tab.Panel>
                <TeamProjectManageContent />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Dialog>
    </>
  );
};
