/** @prettier */
import * as React from 'react';
import { ShareableUrlContainer } from './ShareableUrlContainer.react';
import { IStoryboardInStore } from '../../../types/storyboard';
import Container from '../../shared/Container';
import Dialog from 'blackbird/components/dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { TeamManagementActions } from 'javascripts/flux/actions/team_management';
const TeamMembers = require('../TeamPopup/TeamMembers');

interface Props {
  storyboard: IStoryboardInStore;
  selectedFrameIndex?: number;
}

export const StoryboardShareFlyover = Container('storyboard')(
  (props: Props) => {
    const { t } = useTranslation(undefined, { keyPrefix: 'modals.share' });
    const { storyboard } = props;
    const teamId = storyboard.project.owner.id;

    const { TeamRole } = BoordsConfig;
    const handleCancel = () => {
      FlyoverActions.close();
    };
    React.useEffect(() => {
      TeamManagementActions.fetchIfNecessary.defer(teamId);
    }, [teamId]);

    return (
      <Dialog
        fullHeight
        hideActions
        isOpen
        isDark
        onCancel={handleCancel}
        title={t('title')}
        subtitle={t('subtitle', {
          version_length: storyboard.versions.length,
          document_name: storyboard.document_name,
          version_number: `v${storyboard.version_number}`,
        })}
      >
        <div className="flex flex-col flex-grow gap-6">
          <ShareableUrlContainer
            storyboard={props.storyboard}
            selectedFrameIndex={props.selectedFrameIndex}
          />

          {!['guest', 'member'].includes(TeamRole) && (
            <TeamMembers
              teamId={teamId}
              project={props.storyboard.project}
              isAdmin={TeamRole === 'admin'}
            />
          )}
        </div>
      </Dialog>
    );
  },
);
