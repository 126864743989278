/** @format */

import React from 'react';
import {
  Trash2,
  Copy,
  Edit,
  EllipsisIcon,
  type LucideIcon,
} from 'lucide-react';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import Panel from '../panel/Panel';
import logger from 'javascripts/helpers/logger';
import { useTemplates, type Template } from './TemplatesContext';
import { useConfirmDialog } from '../dialog/hooks';

interface Action {
  label: string;
  icon: LucideIcon;
  onClick: () => void;
}

interface SingleTemplateActionsProps {
  template: Template;
}

export const SingleTemplateActions: React.FC<SingleTemplateActionsProps> = ({
  template,
}) => {
  const {
    setCurrentTemplate,
    setIsTemplateModalOpen,
    duplicateTemplate,
    deleteTemplate,
  } = useTemplates();

  const handleDelete = React.useCallback(() => {
    deleteTemplate(template.id);
  }, [template.id]);

  const { showDialog } = useConfirmDialog({
    size: 'confirm',
    isDark: true,
    alignButtons: 'left',
    cancelBtnSize: 'sm',
    confirmBtnSize: 'sm',
    title: 'Delete template?',
    hideDescription: true,
    onConfirm: handleDelete,
    initialFocus: 'confirmButton',
  });

  const handleDuplicate = React.useCallback(() => {
    duplicateTemplate(template.id);
  }, [template.id]);

  const handleEdit = React.useCallback(() => {
    setCurrentTemplate(template);
    setIsTemplateModalOpen(true);
  }, [template]);

  const options: Action[] = React.useMemo(
    () => [
      {
        label: 'Edit',
        icon: Edit,
        onClick: handleEdit,
      },
      {
        label: 'Duplicate',
        icon: Copy,
        onClick: handleDuplicate,
      },
      {
        label: 'Delete',
        icon: Trash2,
        onClick: showDialog,
      },
    ],
    [handleEdit, handleDuplicate, handleDelete],
  );

  const handleChange = (action: Action) => {
    action.onClick();
  };

  return (
    <Listbox
      as="div"
      className="relative group/templateactions"
      value={null}
      onChange={handleChange}
    >
      {() => (
        <>
          <Listbox.Button className="flex items-center justify-center flex-shrink-0 w-8 h-8 outline-none cursor-pointer rounded-md ring-none hover:bg-surface">
            <EllipsisIcon className="w-4 h-4" />
          </Listbox.Button>
          <Listbox.Options
            as={Panel}
            className="absolute right-0 flex-col w-40 mt-2 border shadow-md rounded-md border-border top-full focus:outline-none"
          >
            <div className="p-2 space-y-1">
              {options.map((option) => (
                <Listbox.Option
                  key={option.label}
                  value={option}
                  as="div"
                  className={({ active }) =>
                    classNames(
                      'cursor-pointer select-none relative pb-2 pt-1.5 px-2 rounded-md flex items-center space-x-2',
                      active ? 'bg-surface-light' : '',
                    )
                  }
                >
                  {({ active }) => (
                    <>
                      <option.icon className="h-4 w-4 pt-0.5" />
                      <span className="text-sm">{option.label}</span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </div>
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};
