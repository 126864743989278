/** @prettier */

import React, { useEffect, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { ChevronDown, GlobeIcon } from 'lucide-react';
import classNames from 'classnames';
import Panel from '../../panel/Panel';
import { TeamUpgradePill } from '../../team/TeamUpgradePill';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { teamOnlyCommentLocalState } from 'javascripts/helpers/local-state';

const options = [
  { id: 'everyone', label: 'Everyone', icon: GlobeIcon },
  { id: 'team-only', label: 'Team only', icon: LockClosedIcon },
];

interface CommentEditorTeamOnlyToggleProps {
  setIsTeamOnly: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CommentEditorTeamOnlyToggle: React.FC<
  CommentEditorTeamOnlyToggleProps
> = ({ setIsTeamOnly }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const hasTeamOnlyFeature = BoordsConfig.HasV3;
  const hasRolloutEnabled = BoordsConfig.Rollout.Teamonlycomments;

  const hasCommentingRole =
    BoordsConfig.TeamRole &&
    [`admin`, `supermember`, `manager`, `member`].includes(
      BoordsConfig.TeamRole,
    );

  useEffect(() => {
    if (!hasCommentingRole || !hasRolloutEnabled) {
      setSelectedOption(options[0]);
      setIsTeamOnly(false);
      return;
    }

    if (hasTeamOnlyFeature) {
      const savedTeamOnly = teamOnlyCommentLocalState.getValue();
      if (savedTeamOnly !== undefined) {
        const option = savedTeamOnly ? options[1] : options[0];
        setSelectedOption(option);
        setIsTeamOnly(savedTeamOnly);
      }
    } else {
      setSelectedOption(options[0]);
      setIsTeamOnly(false);
    }
  }, [hasTeamOnlyFeature, setIsTeamOnly, hasCommentingRole, hasRolloutEnabled]);

  const handleOptionChange = (option) => {
    if (hasTeamOnlyFeature && hasCommentingRole) {
      setSelectedOption(option);
      const isTeamOnly = option.id === 'team-only';
      setIsTeamOnly(isTeamOnly);
      teamOnlyCommentLocalState.setValue(isTeamOnly);
    } else {
      setSelectedOption(options[0]);
      setIsTeamOnly(false);
    }
  };

  if (!hasCommentingRole || !hasRolloutEnabled) {
    return null;
  }

  return (
    <Listbox value={selectedOption} onChange={handleOptionChange}>
      <div className="relative">
        <Listbox.Options
          as={Panel}
          className={classNames(
            'absolute bottom-0 left-0 flex-col mb-2 border shadow-md bottom-full rounded-md border-border focus:outline-none',
            hasTeamOnlyFeature ? 'w-40' : 'w-48',
          )}
        >
          <div className="p-2 space-y-1">
            {options.map((option) => (
              <Listbox.Option
                as="div"
                key={option.id}
                value={option}
                disabled={option.id === 'team-only' && !hasTeamOnlyFeature}
                className={({ active, disabled }) =>
                  classNames(
                    'cursor-pointer select-none relative pb-2 pt-1.5 px-2 rounded-md',
                    active && !disabled ? 'bg-surface-light' : '',
                    disabled ? '' : '',
                  )
                }
              >
                {({ active, disabled }) => (
                  <>
                    <div
                      className={classNames(
                        'flex items-center space-x-2',
                        disabled && 'opacity-50 cursor-not-allowed ',
                      )}
                    >
                      <option.icon className="flex-shrink-0 w-3.5 h-3.5" />
                      <span className="flex-auto text-sm pb-0.5 whitespace-nowrap">
                        {option.label}
                      </span>
                    </div>
                    {disabled && (
                      <div className="whitespace-nowrap">
                        <TeamUpgradePill />
                      </div>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </div>
        </Listbox.Options>
        <Listbox.Button
          className={classNames(
            'inline-flex items-center justify-center min-w-min focus:outline-none focus-visible:outline-blue user-select-none relative pl-2 pr-1.5 pt-0.5 pb-[0.23rem] text-sm rounded-[0.3rem] ',
            selectedOption.id === 'team-only'
              ? 'bg-trial-100 text-trial-600'
              : 'bg-action-secondary text-type-primary hover:bg-action-secondary-hover hover:text-action-hover',
          )}
        >
          <selectedOption.icon className="w-3.5 h-3.5 mr-1 hidden xl:inline" />
          <span className="pb-0.5 whitespace-nowrap">
            {selectedOption.id === 'team-only' ? (
              <>
                <span className="inline xl:hidden">{`Team`}</span>
                <span className="hidden xl:inline">{selectedOption.label}</span>
              </>
            ) : (
              <>{selectedOption.label}</>
            )}
          </span>
          <ChevronDown className="w-4 h-4 ml-0.5 pt-0.5" />
        </Listbox.Button>
      </div>
    </Listbox>
  );
};
