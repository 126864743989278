/** @prettier */
import * as React from 'react';
import Button from 'blackbird/components/button/Button';
import { ArrowDownIcon } from '@heroicons/react/20/solid';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';

interface LoadMoreButtonProps {
  loading?: boolean;
  onClick: () => void;
}

export const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
  loading,
  onClick,
}): React.ReactElement => {
  const { t } = useTranslation('imageLibrary');
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      type="secondary"
      className="w-full mt-4"
    >
      <span className="flex items-center justify-center space-x-1">
        {loading ? (
          <LoadingIndicator secondary />
        ) : (
          <>
            <ArrowDownIcon className="w-4 h-4 text-type-subdued" />
            <span className="text-sm font-normal">{t('loadMore')}</span>
          </>
        )}
      </span>
    </Button>
  );
};
