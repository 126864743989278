const { RequestErrorHandler } = require("../../helpers/request-error-handler");
const errorHandler = RequestErrorHandler('shareablePassword');

(() => {
  class ShareablePasswordStore {
    constructor() {
      this.is_locked        = false;
      this.is_saving        = false;
      this.is_loading       = true;
      this.password         = '';
      this.originalPassword = '';

      this.bindListeners({
        handleUnlock:                  ShareableActions.UNLOCK,
        handleFetchPassword:           ShareableActions.FETCH_PASSWORD,
        handleSavePassword:            ShareableActions.SAVE_PASSWORD,
        handleCheckForPreviousUnlock:  ShareableActions.CHECK_FOR_PREVIOUS_UNLOCK,
      });
    }

    handleCheckForPreviousUnlock(data) {
      if (window.LocalState.idIsInArray(data.storyboardHashid, "unlockedShareableStoryboards") || window.LocalState.idIsInArray(data.project_id, "unlockedShareableProjects") ) {
        this.is_locked = false;
      } else {
        this.is_locked = true;
      }
    }

    handleFetchPassword(data) {
      ajaxHelper.ajax({
        method: "get",
        url: '/storyboards/' + data.storyboard_id + '/storyboard_password.json',
        success: function (response) {
          this.password = response.password || '';
          this.originalPassword = response.password;
          this.is_loading = false;
          this.emitChange();
        }.bind(this),
        error: errorHandler({
          message: "Sorry, we couldn't get the password for this storyboard"
        })
      });
    }

    handleUnlock(data) {
      this.is_locked = true;
      this.emitChange();

      ajaxHelper.ajax({
        method: "post",
        url: '/storyboards/' + data.storyboard_id + '/storyboard_password/verify.json',
        data: {
          password: data.password
        },
        success: function (response) {
          this.is_locked = false;
          this.emitChange();
          RequestActions.success.defer("Storyboard unlocked");
          window.LocalState.addToArray(data.storyboard_id, "unlockedShareableStoryboards");
        }.bind(this),
        error: function (response) {
          this.is_locked = true;
          this.emitChange();
          RequestActions.error("Incorrect password");
        }.bind(this),
      });
    }

    handleSavePassword(data) {
      const oldPassword = this.password;
      this.password = data.password;
      this.is_saving = true;
      this.emitChange();
      ajaxHelper.ajax({
        method: "post",
        url: '/storyboards/' + data.storyboard_id + '/storyboard_password.json',
        data: {
          password: data.password,
        },
        success: function (response) {
          this.is_saving = false;
          this.password  = response.password;
          StoryboardActions.update.defer({
            name:   "password",
            value:  response.password,
          });
          this.emitChange();
          RequestActions.success(response.message);
        }.bind(this),
        error: errorHandler({
          message: "Sorry, there was a problem updating your password"
        }, () => {
          this.is_saving = false;
          this.password = oldPassword;
          this.emitChange();
        }),
      });
    }

  }

  window.ShareablePasswordStore = alt.createStore(ShareablePasswordStore, 'ShareablePasswordStore');

})();
