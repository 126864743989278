/** @prettier */
import React, { useContext } from 'react';
import Button from 'blackbird/components/button/Button';
import type { OnboardingProps } from './Onboarding';
import logger from 'javascripts/helpers/logger';
import {
  OnboardingContext,
  type OnboardingQuestion,
} from 'blackbird/helpers/contexts/OnboardingContext';
import { CircularProgressIndicator } from 'javascripts/components/shared/CircularProgressIndicator';
import { SharedInviteLink } from 'blackbird/components/common/SharedInviteLink';
import { InvitationForm } from 'javascripts/components/settings/SettingsInviteUsers';

const WelcomeScreen: React.FC<OnboardingProps> = () => {
  const {
    index,
    steps,
    setStartWith,
    activeStep,
    setOptionSelected,
    canProceed,
    handleProceed,
  } = useContext(OnboardingContext);

  if (!activeStep || activeStep.skip) {
    return null;
  } else {
    const { heading, question, options, multipleChoice, help } = activeStep;

    const finished = index === steps.length - 1;

    return (
      <div className="flex items-center justify-center flex-grow">
        <div className="flex flex-col flex-grow max-w-md h-[35rem]">
          <div className="flex items-start">
            <CircularProgressIndicator
              steps={2}
              value={index > 1 ? 2 : 1}
              showCheck
            />
          </div>
          <div className="my-3 text-2xl text-type-primary">{heading}</div>
          <span className="mb-4 text-type-subdued">{question}</span>

          {activeStep.id === 'invite' && (
            <>
              <div className="mb-8">
                <InvitationForm
                  initialFieldCount={3}
                  type={`supermember`}
                  compact
                  onSuccess={handleProceed}
                  teamId={BoordsConfig.TeamId}
                >
                  <div className="mt-6">
                    <div className="flex items-center mb-3 space-x-1">
                      <span className="text-base text-type-subdued">{`Or share a team invite link:`}</span>
                    </div>
                    <SharedInviteLink
                      link={BoordsConfig.SharedInviteLink}
                      hideDetails
                      canEnable
                      isEnabled
                      teamId={BoordsConfig.TeamId}
                    />
                  </div>
                </InvitationForm>
              </div>
            </>
          )}
          {options.length > 0 && (
            <div className="flex flex-wrap my-4 gap-3">
              {options.map((option: OnboardingQuestion) => (
                <Button
                  className="w-full !justify-start"
                  onClick={() => {
                    if (finished) {
                      setStartWith(option.slug);
                      setOptionSelected(option.slug);
                    } else {
                      setOptionSelected(option.slug);
                    }
                  }}
                  type={`${option.selected ? 'solid' : 'secondary'}`}
                  key={option.slug}
                >
                  <div className="text-left">
                    <div>{option.label}</div>
                    {option.description && (
                      <div className="text-sm font-normal text-type-subdued">
                        {option.description}
                      </div>
                    )}
                  </div>
                </Button>
              ))}
              <div className="flex items-center w-full mt-4">
                <div className="flex items-start flex-auto">
                  {help && (
                    <div className="text-sm text-type-subdued">{help}</div>
                  )}
                </div>
                {multipleChoice && (
                  <Button
                    size="sm"
                    type="solid"
                    disabled={!canProceed}
                    onClick={() => handleProceed()}
                  >{`Continue`}</Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};
export default WelcomeScreen;
