/** @prettier */
import * as React from 'react';
import { useOnMount } from '../../../helpers/useOnMount';
import { AssetsActions } from '../../../flux/actions/assets';
import { useStore } from '../../../helpers/useStore';
import type {
  AssetsStore,
  StoryboardImageAsset,
} from '../../../flux/stores/assets';
import { DRAGGABLE_TYPES } from '../../shared/dragAndDropUtils';
import {
  PanelbarFrameAssetList,
  parsedAssetItem,
} from './PanelbarFrameAssetList';
import { PanelbarAssetListEmptyState } from './PanelbarAssetListEmptyState';
import DropzoneIcon from 'blackbird/images/icons/dropzone.svg';
import classNames from 'classnames';
import { acceptedFrameFileTypes } from 'javascripts/flux/stores/frame';
import type { IStoryboard } from 'javascripts/types/storyboard';
import { useTranslation } from 'react-i18next';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';

const equalityFunction = (a, b) => a === b;
interface Props {
  storyboard: IStoryboard;
  hasEmptyState?: boolean;
  hasError?: boolean;
  hasFrameFocus: boolean;
}

export const PanelbarImageAssets: React.FC<Props> = (props) => {
  useOnMount(() =>
    AssetsActions.fetchImageAssets.defer({
      storyboardID: props.storyboard.id,
    }),
  );

  const assets = useStore<StoryboardImageAsset[] | undefined, AssetsStore>(
    'assets',
    (store) => store.storyboardImageAssets,
    // We need to use the basic equality function here to make sure we catch
    // changes
    equalityFunction,
  );

  const isLoading = useStore<boolean, AssetsStore>(
    'assets',
    (store) => store.imageAssetsAreLoading,
  );
  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const context = React.useContext(DialogContext);

  const handleDeleteItem = React.useCallback(
    async (item: parsedAssetItem) => {
      if (
        await openConfirmDialog(
          'This will permanently delete this image from your image library. There is no undo for this. Do you want to continue?',
          context,
        )
      ) {
        AssetsActions.deleteImageAsset({
          assetId: item.id,
          storyboardId: props.storyboard.id,
        });
      }
    },
    [props.storyboard.id],
  );
  const handleBrowseClick = () => {
    fileRef.current?.click();
  };
  const handleFileUpload = (event) => {
    for (const file of event.target.files) {
      AssetsActions.uploadImageAsset.defer({
        storyboardId: props.storyboard.id,
        projectId: props.storyboard.project.id,
        teamId: props.storyboard.project.owner.id,
        target: 'project',
        file,
      });
    }
  };
  const { t } = useTranslation();
  return (
    <PanelbarFrameAssetList<StoryboardImageAsset>
      items={assets}
      isLoading={isLoading}
      onDeleteItem={handleDeleteItem}
      loadingText={t('loaders.assets')}
      canDrag={props.hasFrameFocus}
      emptyState={
        <PanelbarAssetListEmptyState link="https://help.boords.com/en/articles/5394282-using-the-image-library">
          <div
            className={classNames(
              'w-full py-12 border-dashed border-2 rounded-lg  flex flex-col gap-4 justify-center items-center',
              {
                'bg-surface-light': !props.hasEmptyState && !props.hasError,
                'border-form-error': props.hasError,
                'border-border-mid': !props.hasError,
              },
            )}
          >
            <DropzoneIcon />
            <div>
              {props.hasError ? (
                'File format must be JPG or PNG'
              ) : (
                <>
                  Drop images here or{' '}
                  <a
                    onClick={handleBrowseClick}
                    className="underline cursor-pointer"
                  >
                    <input
                      ref={fileRef}
                      onChange={handleFileUpload}
                      type="file"
                      accept={acceptedFrameFileTypes.join(',')}
                      hidden
                    />
                    browse
                  </a>
                </>
              )}
            </div>
          </div>
        </PanelbarAssetListEmptyState>
      }
      parse={(asset) => ({
        imageUrl: asset.thumbnail_url,
        dragType: DRAGGABLE_TYPES.image,
        id: asset.id,
        isUploading: asset.isUploading ?? false,
        canDelete: !asset.isUploading,
      })}
    />
  );
};
