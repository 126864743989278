/** @prettier */
import React, { useContext, useEffect, useState } from 'react';
import Button from '../button/Button';
import Badge from '../badge/Badge';
import classNames from 'classnames';
import Tooltip from '../feedback/tooltip/Tooltip';

import {
  formatCurrency,
  formatCurrencyParts,
} from 'javascripts/helpers/currencyHelper';

import {
  type LocalPlanData,
  type Price,
} from 'blackbird/helpers/contexts/BillingContext';
import { CornerLeftDown } from 'lucide-react';
import { QuizContext } from './QuizContext';
import { FeatureInline, type FeatureName } from '../features/Feature';

export interface QuizPlanProps {
  plan: LocalPlanData;
  price: Price;
  featured: boolean;
  billingInterval: string;
}

export const QuizPlan: React.FC<QuizPlanProps> = ({
  plan,
  price,
  featured,
  billingInterval,
}): React.ReactElement => {
  const { handleProceed, role, isAi, isTeam } = useContext(QuizContext);

  const [fullFeatureList, setFullFeatureList] = useState<FeatureName[]>([]);

  useEffect(() => {
    if (isAi && plan.slug === 'free') {
      setFullFeatureList([
        'storyboard-generator-with-characters',
        'script-generator',
        ...fullFeatureList,
      ]);
    } else if (plan.slug === 'free') {
      setFullFeatureList([
        'image-library',
        'dropbox-google-drive-short',
        ...fullFeatureList,
      ]);
    } else if (plan.slug === 'individual') {
      setFullFeatureList([
        'remove-branding',
        'remove-frame-limit',
        'export-formats',
        ...fullFeatureList,
      ]);
    } else if (plan.slug === 'group') {
      setFullFeatureList([
        'team-editing',
        'team-comments',
        'real-time-updates',
        ...fullFeatureList,
      ]);
    } else if (plan.slug === 'agency') {
      setFullFeatureList([
        'project-based-access',
        'team-invite-link',
        ...fullFeatureList,
      ]);
    }
  }, [plan, isAi]);

  return (
    <div
      className={classNames(
        'relative py-6 px-7 border-2 rounded-lg',
        featured
          ? 'border-brand-pink bg-surface'
          : 'border-border bg-surface-light',
      )}
    >
      {featured && (
        <div className="absolute flex items-start -top-10 left-6 text-brand-pink space-x-1">
          <CornerLeftDown size={20} className="mt-2" />
          <span className="text-sm font-semibold whitespace-nowrap space-x-1">
            <span>{`Best for`}</span>
            {!isTeam && <span>{`Independent`}</span>}
            <span>{role}</span>
          </span>
        </div>
      )}
      {/* Plan name */}
      <div className="flex items-center">
        <div className={classNames('mb-1 mr-2 font-semibold', 'text-xl')}>
          {plan.name}
        </div>
      </div>

      {/* Price information */}
      <div className="flex items-baseline mt-1">
        <span className={classNames('mr-0.5', 'text-base text-type-subdued')}>
          {
            formatCurrencyParts({
              amount: 0,
              currency: BoordsConfig.Currency,
              showZeros: false,
            }).symbol
          }
        </span>
        <span
          className={classNames(
            'mr-0 ',
            'text-lg',
            BoordsConfig.IsEdu &&
              'line-through text-black/30 decoration-brand-pink decoration-4 text-lg',
          )}
        >
          {
            formatCurrencyParts({
              amount: Math.floor(
                price.amount / (billingInterval === 'year' ? 12 : 1) / 100,
              ),
              currency: BoordsConfig.Currency,
              showZeros: false,
            }).value
          }
        </span>
        {BoordsConfig.IsEdu && (
          <span className="ml-1 mr-1 text-4xl">
            {
              formatCurrencyParts({
                amount: Math.floor(
                  price.amount /
                    2 /
                    (billingInterval === 'year' ? 12 : 1) /
                    100,
                ),
                currency: BoordsConfig.Currency,
                showZeros: false,
              }).value
            }
          </span>
        )}
        <span className="">
          {billingInterval === 'year' ? (
            <Tooltip
              title={`Get 2 months free with yearly billing (${formatCurrency({
                amount: price.amount / (BoordsConfig.IsEdu ? 2 : 1) / 100,
                currency: BoordsConfig.Currency,
                showZeros: false,
              })} / year)`}
            >
              <div className="flex items-center cursor-help">
                <span className="text-sm text-type-subdued">{` / month, billed yearly`}</span>
              </div>
            </Tooltip>
          ) : (
            <span className="text-sm text-type-subdued">{` / month`}</span>
          )}
        </span>
      </div>

      {BoordsConfig.IsEdu && (
        <div className="flex justify-start mb-3.5 -mt-2">
          <Badge
            label={
              <>
                <span className="opacity-50">{`Includes`}</span>
                <span className="mx-1 text-type-green">{`50%`}</span>
                <span className="opacity-50">{`discount`}</span>
              </>
            }
            color="green"
          />
        </div>
      )}
      <div className="mt-8 mb-4">
        <Button
          type={featured ? 'pink' : 'colorOutline'}
          rounded
          className={classNames(
            'w-full ',
            plan.slug === 'free' ? 'var-accent-border' : 'var-accent-black',
          )}
          onClick={() =>
            plan.slug === 'free'
              ? handleProceed()
              : (window.location.href = `/checkout/${plan.slug}/${billingInterval}ly`)
          }
        >
          <span
            className={classNames('font-normal', featured && 'py-[0.1rem]')}
          >
            {plan.slug === 'free' ? (
              <>{`Get Started`}</>
            ) : (
              <>{`Start with ${plan.name}`}</>
            )}
          </span>
        </Button>
      </div>

      <div>
        <div className="pb-4 -ml-1 space-y-2">
          <div className="pb-1 font-semibold">
            {plan.slug === 'free' ? (
              <span>{`Essential storyboarding`}</span>
            ) : plan.slug === 'individual' ? (
              <span>{`Ideal starter plan`}</span>
            ) : plan.slug === 'group' ? (
              <span>{`For up to 5 users`}</span>
            ) : (
              <span>{`For up to 10 users`}</span>
            )}
          </div>

          {fullFeatureList.map((featureName, index) => {
            return (
              <FeatureInline
                iconColor="text-type-primary"
                key={`feature-${index}`}
                name={featureName}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
