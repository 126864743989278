const noop = () => {};
const ignoreWebP = require('./ignore-webp');

module.exports = frames => frames.forEach(frame => {
  if (!frame.large_image_url) return;
  const i = new Image();
  const handleError = (err) => console.error(`Error preloading image ${i.src}:`, err);

  i.src = ignoreWebP(frame.large_image_url);
  i.dataset.id = frame.id;

  // the decode function prevents additional lag, but is relatively new
  if (i.decode) {
    i.decode().then(noop).catch(handleError);
  } else {
    i.onload = noop;
    i.onerror = handleError
  }
});
