/** @format */
import classNames from 'classnames';
import React from 'react';

interface QuizCardProps {
  title: string;
  compact?: boolean;
  description?: string;
  icon?: React.ReactNode;
  selected?: boolean;
}

export const QuizCard: React.FC<QuizCardProps> = ({
  title,
  description,
  icon,
  selected,
  compact,
}) => (
  <div
    className={classNames(
      'flex flex-col items-center text-center border cursor-pointer border-border group border-2',
      compact ? 'px-2 py-4 rounded-md' : 'px-4 py-8 rounded-lg ',
      selected && compact
        ? 'border-brand-pink bg-brand-pink-25/50'
        : selected
        ? 'border-brand-pink bg-surface'
        : 'bg-surface-light hover:bg-surface ',
    )}
  >
    {icon && (
      <span
        className={classNames(
          selected
            ? 'text-brand-pink'
            : 'text-type-disabled group-hover:text-brand-pink',
        )}
      >
        {React.cloneElement(icon as React.ReactElement, {
          className: `mb-6 mt-2`,
          size: 48,
          strokeWidth: 1.5,
        })}
      </span>
    )}
    <div
      className={classNames(
        'leading-tight',
        compact ? 'font-normal text-sm' : 'text-base font-semibold',
        compact && selected && 'text-brand-pink',
      )}
    >
      {title}
    </div>
    {description && (
      <div className="mt-2 text-sm text-center text-type-subdued">
        {description}
      </div>
    )}
  </div>
);
