/**@prettier */
const _ = require('underscore');
const {
  default: Tooltip,
} = require('blackbird/components/feedback/tooltip/Tooltip');
const {
  default: Checkbox,
} = require('blackbird/components/form/checkbox/Checkbox');
const createReactClass = require('create-react-class');
var PropTypes = require('prop-types');
window.StoryboardExportBoolean = createReactClass({
  propTypes: {
    field_name: PropTypes.string.isRequired,
    is_checked: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    toggle: PropTypes.func,
    is_disabled: PropTypes.bool,
    labelFont: PropTypes.string,
    inverted: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      labelFont: '',
    };
  },

  _toggle: function () {
    if (!_.isUndefined(this.props.toggle)) {
      this.props.toggle();
    } else {
      CoverpageActions.updateValue({
        attr: this.props.field_name,
        value: !this.props.is_checked,
      });
    }
  },

  _upgrade: function (e) {
    e.preventDefault();
    UpgradePromptActions.show('PDF checkbox');
  },

  render: function () {
    let isChecked = this.props.is_checked;
    if (this.props.inverted) isChecked = !isChecked;

    if (this.props.is_disabled) {
      return (
        <Tooltip title="Upgrade required">
          <div onClick={this._upgrade}>
            <Checkbox label={this.props.label} disabled />
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Checkbox
          label={this.props.label}
          name={this.props.field_name}
          id={this.props.field_name}
          onChange={this._toggle}
          value={isChecked}
        />
      );
    }
  },
});
