/** @format */
import { CommentActions } from 'javascripts/flux/actions/comment';
import type { User } from 'blackbird/components/comments/types';
import type { CommentStore } from 'javascripts/flux/stores/comment';
import Container from '../Container';

export interface FrameCommentsContainerProps {
  comment: CommentStore;
  projectTeam: unknown;
  user: User;
  fetch: () => void;
  handleCommentSave: typeof CommentActions.save;
  handleCommentChange: typeof CommentActions.updateNewCommentData;
  handleCommentUpdate: typeof CommentActions.update;
  handleCommentDelete: typeof CommentActions.delete;
}

const FrameCommentsContainer = Container(
  {
    comment: (data) => data,
    projectTeam: (data) => data.team,
    user: (data) => data.user,
  },
  (storeData, ownProps) => ({
    fetch: () => {
      if (!storeData.comment.fetched) {
        CommentActions.fetch.defer(ownProps.storyboard.short_slug);
      }
      if (!storeData.projectTeam) {
        ProjectTeamActions.fetch.defer(ownProps.storyboard.project.id);
      }
    },
    handleCommentSave: CommentActions.save,
    handleCommentChange: CommentActions.updateNewCommentData,
    handleCommentUpdate: CommentActions.update,
    handleCommentDelete: CommentActions.delete,
  }),
  { isPure: false },
);

export default FrameCommentsContainer;
