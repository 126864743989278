/** @prettier */
import type {
  MembershipActions,
  Project,
} from 'javascripts/flux/stores/dashboard';
import Button from 'blackbird/components/button/Button';
import TabCaption from 'blackbird/components/common/TabCaption';
import Tabs from 'blackbird/components/tabs/Tabs';
import type { TabProps } from 'blackbird/components/tabs/types';
import classNames from 'classnames';
import { useScreenSizeMonitor } from 'javascripts/components/shared/useScreenSizeMonitor';
import type { UserStore } from 'javascripts/flux/stores/user';
import { usePersistedState } from 'javascripts/helpers/usePersistedState';
import { useStore } from 'javascripts/helpers/useStore';
import type { UserResponse } from 'javascripts/types/user';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'underscore';
import { WizardContext } from 'blackbird/components/wizard/WizardContext';
import logger from 'javascripts/helpers/logger';

const ST_KEY = 'dashboardSelectedTeam';

/** Sometimes the server returns placeholder urls as data: urls, we want to
 * generate these on the client side */
const filterDataUrl = (string?: string) =>
  string?.indexOf('data:') === 0 ? undefined : string;

export interface DashboardTeamTab {
  /** This is the string used for identifying this team in localStorage */
  key: string;
  type: 'team' | 'personal' | 'membership';
  logoUrl?: string;
  projects: Project[];
  actions: MembershipActions[];
  caption: string;
  admin?: string;
  teamId: number;
}

interface Props {
  tabs: DashboardTeamTab[];
  onNewProject?: (id: number) => void;
  onTeamChanged?: (team: string) => void;
}

export const DashboardTeamTabs: React.FC<Props> = (props) => {
  const user = useStore<UserResponse | undefined, UserStore>(
    'user',
    (store) => store.user,
  );

  const { t } = useTranslation();
  const enoughSpace = useScreenSizeMonitor('dashboard', 1100);
  const [selectedTab, setSelectedTab] = usePersistedState(
    ST_KEY,
    props.tabs[0].key,
  );

  React.useEffect(() => {
    if (location.hash === '#shared') {
      const target = props.tabs.find((i) => i.type === 'membership');
      if (!target) return;
      setSelectedTab(target.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabsWithCaptions = React.useMemo(
    () =>
      props.tabs.map<TabProps>((tab) => ({
        key: tab.key,
        // eslint-disable-next-line react/display-name
        caption: ({ selected }) => (
          <TabCaption
            key={tab.key}
            avatarSize={props.tabs.length < 2 ? 'sm' : 'xs'}
            className={
              props.tabs.length < 2 ? 'text-xl font-semibold' : 'text-lg'
            }
            selected={selected === tab.key}
            avatar={filterDataUrl(tab.logoUrl)}
            text={tab.caption}
            tooltip={
              props.tabs.length > 1
                ? t(
                    tab.type === 'personal'
                      ? 'dashboard.tooltips.personalProjectTab'
                      : 'dashboard.tooltips.teamProjectTab',
                  )
                : undefined
            }
            hasAvatar={props.tabs.length < 3 || enoughSpace}
          />
        ),
      })),
    [enoughSpace, props.tabs, t],
  );

  const handleSelect = (newTab) => {
    props.onTeamChanged && props.onTeamChanged(newTab);
    location.hash = '';
    setSelectedTab(newTab);
  };

  const selectedMembership =
    props.tabs.find((i) => i.key === selectedTab) ?? props.tabs[0];

  return (
    <Tabs
      customBorder
      defaultValue={selectedTab}
      tabs={tabsWithCaptions}
      onSelect={handleSelect}
      tabClassName={classNames('py-3', {
        'border-none': tabsWithCaptions.length < 2,
      })}
    >
      <Tabs.Bar>
        {({ TabList, selectedTab }) => (
          <div className="flex items-center justify-between pl-6 pr-5">
            <TabList className="no-padding" />

            {selectedMembership.actions.includes('projects.create') &&
              isFunction(props.onNewProject) && (
                <Button
                  size="md"
                  onClick={() => props.onNewProject!(selectedMembership.teamId)}
                >
                  {t('actions.project.new')}
                </Button>
              )}
          </div>
        )}
      </Tabs.Bar>
      <Tabs.Panel className="focus:outline-none">
        {props.tabs.map((tab) => (
          <DashboardGroup
            key={tab.key}
            type="membership"
            actions={tab.actions}
            title={tab.caption}
            admin={tab.admin}
            projects={tab.projects}
            team_id={tab.teamId}
            logo_url={tab.logoUrl}
            user={user}
          />
        ))}
      </Tabs.Panel>
    </Tabs>
  );
};

DashboardTeamTabs.displayName = 'DashboardTeamTabs';
