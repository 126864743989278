/** @format */

import React, { useState, useContext, useEffect } from 'react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import Dialog from '../dialog/Dialog';
import { teamMonthlyUserCost, TeamContext, teamBaseCost } from './TeamContext';
import {
  CheckIcon,
  LayoutTemplateIcon,
  LayoutDashboardIcon,
  ShareIcon,
  UsersIcon,
  BriefcaseIcon,
  type LucideIcon,
  MessageSquareWarningIcon,
  FolderTreeIcon,
} from 'lucide-react';
import classNames from 'classnames';
import Button from '../button/Button';
import cdnUrl from 'javascripts/helpers/cdn-url.js';
import { hideWorkflowButtonLocalState } from 'javascripts/helpers/local-state';
import {
  WorkflowEndTrialButton,
  WorkflowStartTrialButton,
} from './WorkflowTrialButtons';
import { differenceInDays } from 'date-fns';

interface Feature {
  title: string;
  description: string;
  image: string;
  icon?: LucideIcon;
}

const features: Feature[] = [
  {
    title: 'Flexible folder organization',
    description: `Organize projects and storyboards effortlessly with an intuitive drag-and-drop folder system.

   Group by client, campaign, status, or any structure that fits your team's workflow.`,
    image: '/assets/workflow-features/nesting-large.jpg',
    icon: FolderTreeIcon,
  },
  {
    title: 'Customizable templates',
    description: `Create reusable storyboard structures with predefined fields, settings, and aspect ratios for your creative projects.

   Save hours of setup time and maintain brand consistency by applying templates across your team's deliverables.`,
    image: '/assets/workflow-features/nesting-large.jpg',
    icon: LayoutTemplateIcon,
  },
  {
    title: 'Private team comments',
    description: `Keep internal feedback private with comments that are visible only to your project team.

   Collaborate freely on revisions and share ideas without exposing works-in-progress to clients.`,
    image: '/assets/workflow-features/team-only-zoom.jpg',
    icon: MessageSquareWarningIcon,
  },
  {
    title: 'Unified project dashboard',
    description: `View all your project's storyboards and sub-projects in one centralized dashboard.

   Keep teams aligned with project briefs, resource links, and creative direction—all in one place.`,
    image: '/assets/workflow-features/project-view-sort.jpg',
    icon: LayoutDashboardIcon,
  },
  {
    title: 'Smart project sharing',
    description: `Share multiple storyboards through a single, secure link with optional password protection.

   Give clients the full picture by including project briefs and related content—no more juggling multiple URLs.`,
    image: '/assets/workflow-features/shareable-project-link.jpg',
    icon: ShareIcon,
  },
  {
    title: 'Streamlined team access',
    description: `Manage team roles and permissions directly from your project dashboard with just a few clicks.

   Onboard new team members instantly using secure invite links and role-based access controls.`,
    image: '/assets/workflow-features/team-management-detail.jpg',
    icon: UsersIcon,
  },
];

declare const BoordsConfig: {
  TeamRole?: string;
  IsAnnual?: boolean;
  Freeloader?: boolean;
  V3TrialEndsAt?: number;
};

interface FlyoverWorkflowPreviewProps {
  isOpenedByAdmin?: boolean;
}

const FlyoverWorkflowPreview: React.FC<FlyoverWorkflowPreviewProps> = ({
  isOpenedByAdmin,
}) => {
  const { activeGroup } = useContext(TeamContext);
  const [openItem, setOpenItem] = useState<string>(features[0].title);

  useEffect(() => {
    features.forEach((feature) => {
      const img = new Image();
      img.src = cdnUrl(feature.image);
    });
  }, []);

  const getDaysRemaining = () => {
    if (!BoordsConfig.V3TrialEndsAt) {
      return null;
    }
    const now = new Date();
    const trialEndDate = new Date(BoordsConfig.V3TrialEndsAt * 1000); // Convert Unix timestamp to milliseconds
    const daysRemaining = differenceInDays(trialEndDate, now);
    return daysRemaining < 0 ? null : Math.max(0, daysRemaining);
  };

  const daysRemaining = getDaysRemaining();

  const handleClose = () => {
    if (window.location.pathname === '/workflow') {
      window.history.pushState(undefined, 'Boords', '/');
    }
    FlyoverActions.close.defer();
  };

  const toggleItem = (title: string) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === title ? '' : title));
  };

  const isAdmin =
    (activeGroup && activeGroup.actions.includes('team.billing')) ||
    (BoordsConfig.TeamRole && BoordsConfig.TeamRole === 'admin') ||
    BoordsConfig.Freeloader ||
    isOpenedByAdmin;

  useEffect(() => {
    Track.event.defer('workflow_flyover_view', {
      isAdmin: Boolean(isAdmin),
      posthogCapture: true,
    });
  }, [isAdmin]);

  const UpgradeCta: React.FC = () => (
    <div className="flex items-start pl-6 pr-4 pt-3 pb-3.5 rounded-lg bg-premium-100 border-premium-200 border">
      {isAdmin ? (
        <>
          <div className="flex-auto pb-0.5">
            {!BoordsConfig.V3TrialEndsAt ? (
              <>
                <div className="text-base font-semibold text-premium-800">{`Try Workflow for free, opt-out any time.`}</div>
                <div className="text-sm space-x-1 mt-0.5 text-premium-700 opacity-60">{`Your plan/billing won't change unless you choose to upgrade.`}</div>
              </>
            ) : (
              <>
                <div className="text-base font-semibold text-premium-800">{`Complete your Workflow upgrade`}</div>

                <div className="text-sm space-x-1 mt-0.5 text-premium-700 opacity-60">
                  <span>{`Workflow is`}</span>
                  <span className="font-semibold">{teamBaseCost}</span>
                  <span>{`plus`}</span>
                  <span className="font-semibold">{teamMonthlyUserCost}</span>
                  <span>{`for each additional user.`}</span>
                </div>

                {daysRemaining !== null && (
                  <div className="text-sm space-x-1 mt-0.5 ">
                    <span className="text-premium-700 opacity-60">{`Don't want to upgrade?`}</span>
                    <WorkflowEndTrialButton />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex-shrink-0 text-center">
            {!BoordsConfig.V3TrialEndsAt ? (
              <WorkflowStartTrialButton />
            ) : (
              <>
                <Button
                  link={`/checkout/workflow/${
                    BoordsConfig.IsAnnual ? 'yearly' : 'monthly'
                  }`}
                  type="premium"
                  rounded
                >
                  {`Go to Checkout`}
                </Button>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="pb-0.5 flex-auto">
          <div className="text-sm text-premium-900">{`Please contact the team owner to request an upgrade.`}</div>
          <div className="text-xs text-premium-900 opacity-50 mt-0.5">{`Questions? Talk to the team via the chat icon.`}</div>
        </div>
      )}
    </div>
  );

  return (
    <Dialog
      size="md"
      isOpen
      keepOpen
      isBlur
      title={
        <div className="-mt-4 fullsize:-mt-8">
          <div>
            <span className="bg-premium-100 !text-premium-600 uppercase font-semibold rounded-sm px-1.5 py-0.5 !text-xxs tracking-wider ">{`Introducing Workflow`}</span>
          </div>
          <div className="mt-2 space-x-1 !text-2xl">
            <span>{`Your Pre-Production Workflow,`}</span>
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-premium-700 to-premium-900">{`Redefined`}</span>
          </div>
        </div>
      }
      subtitle={
        <div className="w-full max-w-lg">{`Workflow is a powerful new Boords plan that gives fast-moving creative teams a game-changing advantage in pre-production.`}</div>
      }
      onCloseBtnClick={handleClose}
      onEscapeOrOutsideClick={handleClose}
      hideActions
    >
      <div className="pb-6">
        <div className="mb-8">
          <UpgradeCta />
        </div>
        <div>
          {features.map((feature, index) => {
            const isFeatureOpen = openItem === feature.title;
            const FeatureIcon = feature.icon || CheckIcon;

            return (
              <div
                key={index}
                className={classNames(
                  'mb-4 border rounded-lg border-premium-100 bg-premium-50',
                  !isFeatureOpen ? '' : '',
                )}
              >
                <div
                  className="flex justify-between w-full px-4 pt-3 pb-2 text-base font-semibold text-left cursor-pointer focus:outline-none "
                  onClick={() => {
                    if (!isFeatureOpen) {
                      toggleItem(feature.title);
                    }
                  }}
                >
                  <div className="flex items-center pb-1">
                    <span className="flex-shrink-0 w-8">
                      <div className="flex items-center justify-center w-5 h-5">
                        <FeatureIcon
                          strokeWidth={2}
                          className="w-4 h-4 text-premium-700"
                        />
                      </div>
                    </span>
                    <span className="pb-1">{feature.title}</span>
                  </div>
                  <ChevronUpIcon
                    className={`${
                      isFeatureOpen
                        ? 'transition-transform transform rotate-180'
                        : ''
                    } w-5 h-5`}
                  />
                </div>
                {isFeatureOpen && (
                  <div className="px-4 pb-4 ml-8 ">
                    <div className="grid grid-cols-2">
                      <div className="pr-8 text-base">
                        <Markdown
                          text={feature.description}
                          className="text-prose"
                        />
                      </div>
                      <div className="w-full rounded-lg aspect-square">
                        <img
                          src={cdnUrl(feature.image)}
                          alt={feature.title}
                          className="border rounded-lg border-premium-100 aspect-square"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="pl-6 mt-6">
          {daysRemaining !== null ? (
            <></>
          ) : (
            <span
              className="text-xs opacity-50 cursor-pointer text-premium-800 hover:underline underline-offset-2"
              onClick={() => {
                hideWorkflowButtonLocalState.setValue(true);
                FlyoverActions.close.defer();
                Track.event.defer('workflow_flyover_dismiss', {
                  isAdmin: Boolean(isAdmin),
                  posthogCapture: true,
                });
              }}
            >
              {`Don't remind me again`}
            </span>
          )}
        </div>
      </div>
    </Dialog>
  );
};

(window as any).FlyoverWorkflowPreview = FlyoverWorkflowPreview;
