/** @format */

/** Determines appropriate labelling and details for the the timeline of the
 * animatic based on the current available screen space and amount of data */
export const getIntervalResolution = (
  msPerPixel: number,
): {
  ruler: number;
  largeRuler: number;
  label: number;
  showFrames: boolean;
} => {
  // console.log(msPerPixel);
  if (msPerPixel > 260) {
    return {
      ruler: 10000,
      largeRuler: 20000,
      label: 20000,
      showFrames: false,
    };
  } else if (msPerPixel > 140) {
    return {
      ruler: 5000,
      largeRuler: 10000,
      label: 10000,
      showFrames: false,
    };
  } else if (msPerPixel > 100) {
    return {
      ruler: 1000,
      largeRuler: 10000,
      label: 10000,
      showFrames: false,
    };
  } else if (msPerPixel > 20) {
    return {
      ruler: 1000,
      largeRuler: 5000,
      label: 5000,
      showFrames: false,
    };
  } else if (msPerPixel > 15) {
    return {
      ruler: 1000,
      largeRuler: 5000,
      label: 5000,
      showFrames: false,
    };
    // } else if (msPerPixel > 26) {
    //   return {
    //     ruler: 1000,
    //     largeRuler: 5000,
    //     label: 5000,
    //     showFrames: false,
    //   };
  } else if (msPerPixel > 12) {
    return {
      ruler: 500,
      largeRuler: 1000,
      label: 1000,
      showFrames: false,
    };
  } else if (msPerPixel > 6) {
    return {
      ruler: 100,
      largeRuler: 1000,
      label: 1000,
      showFrames: true,
    };
  } else if (msPerPixel > 2) {
    return {
      ruler: 100,
      largeRuler: 500,
      label: 500,
      showFrames: true,
    };
  } else if (msPerPixel > 1.5) {
    return {
      ruler: 50,
      largeRuler: 200,
      label: 200,
      showFrames: true,
    };
  }

  return {
    ruler: 50,
    largeRuler: 100,
    label: 100,
    showFrames: true,
  };
};
