import { ToursStore } from '../stores/tours';
import { createTypedActions } from '../stores/AltStore';
import { tourEvents } from '../../tours/tourData';

class ToursActions {
  start: ToursStore['handleStart'] = function(options) {
    this.dispatch(options);
  };
  triggerEvent: ToursStore['handleTriggerEvent'] = function(options) {
    this.dispatch(options);
  };
  advanceTour: ToursStore['handleAdvanceTour'] = function(options) {
    this.dispatch(options);
  };
  previousStep: ToursStore['handlePreviousStep'] = function(options) {
    this.dispatch(options);
  };
  cancelTour: ToursStore['handleCancelTour'] = function(options) {
    this.dispatch(options);
  };
}

const actions = createTypedActions<ToursActions>(ToursActions);
export { actions as ToursActions };
export { tourEvents }
