const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const { Button } = require('../shared/Button');

window.CopyToClipboard = createReactClass({

  propTypes: {
    clipboardText: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    className: PropTypes.string,
  },

  getInitialState: function() {
    return {
      isCopied: false,
    }
  },

  _copy: function(e) {
    e.preventDefault();
    if (window.copyToClipboard(this.props.clipboardText)) {
      RequestActions.success.defer("URL copied to clipboard");
      this.setState({
        isCopied: true,
      });
    } else {
      RequestActions.error.defer("Sorry, there was a problem copying the URL");
      this.setState({
        isCopied: false,
      });
    }

    setTimeout(function() {
      this.setState({
        isCopied: false,
      });
    }.bind(this), 1500);
  },


  render: function() {
    var label = "Copied!";
    var labelClass = (this.state.isCopied) ? "hint--top" : "";

    return (
      <div aria-label={label} className={this.props.className + " " + labelClass}>
        <Button onClick={this._copy} preset="purple" width="w-100" text={this.props.labelText} />
      </div>
    );
  }
});
