/** @prettier */
import { Base64FrameImagesResult } from './types';
import * as queue from 'async/queue';
import { AsyncQueue } from 'async';
import { memoize } from 'underscore';

// We're going to load pdfjs through a CDN because we couldn't overcome
// CORS issues with the self-hosted version. The worker required by pdfjs
// always ended up on another domain
let loaded = false;
const loadPdfjsLib = (): Promise<any> =>
  new Promise((resolve, reject) => {
    if (loaded) resolve((window as any).pdfjsLib);
    loadScript(
      'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.4.456/build/pdf.min.js',
      undefined,
      (error) => {
        if (error) return reject(error);
        loaded = true;
        resolve((window as any).pdfjsLib);
      },
    );
  });

export interface CoverInformation {
  width: number;
  height: number;
  dataUrl: string;
}

let cache: { [cacheKey: string]: CoverInformation } = {};

// Memoise this, so that requests to the same url get combined and we don't
// have to fetch and parse each document multiple times
const getDocument = memoize((url) =>
  loadPdfjsLib().then((pdfjsLib) => pdfjsLib.getDocument(url).promise),
);

const processingQueue: AsyncQueue<{
  pdfURL: string;
  cacheKey: string;
  pageNo: number;
  scale: number;
  background?: string;
}> = queue(({ pdfURL, pageNo, scale, cacheKey, background }, callback) => {
  if (!pdfURL) throw new Error('no url passed');

  // Loading a document.
  getDocument(pdfURL)
    .then(function (pdfDocument) {
      // Request a first page
      pdfDocument
        .getPage(pageNo)
        .then(function (pdfPage) {
          // Display page on the existing canvas with 100% scale.
          const viewport = pdfPage.getViewport({ scale });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const { width, height } = viewport;
          canvas.width = width;
          canvas.height = height;

          pdfPage
            .render({
              canvasContext: context,
              viewport: viewport,
              // Set a transparent background, so that we respect background
              // color(or transparency in the case of the watermark)
              background: background || 'rgba(0,0,0,0)',
            })
            .promise.then(() => {
              const result = {
                dataUrl: canvas.toDataURL('image/png'),
                width,
                height,
              };
              cache[cacheKey] = result;
              callback(null, result);
            })
            .catch((err) => callback(err));
        })
        .catch((err) => callback(err));
    })
    .catch((err) => callback(err));
});

export const PDFToPng = (
  pdfURL: string,
  pageNo = 1,
  scale = 1,
  background?: string,
) =>
  new Promise<CoverInformation>((resolve, reject) => {
    const cacheKey = pdfURL + pageNo + scale;
    if (cache[cacheKey]) return resolve(cache[cacheKey]);

    processingQueue.push(
      {
        pdfURL,
        pageNo,
        scale,
        cacheKey,
        background,
      },
      (err, result: CoverInformation) => {
        if (err) return reject(err);
        resolve(result);
      },
    );
  });

export const PDFToCanvasImage = (pdfURL: string, pageNo = 1, scale = 1) =>
  new Promise<Base64FrameImagesResult>((resolve, reject) => {
    PDFToPng(pdfURL, pageNo, scale).then(({ dataUrl, width, height }) => {
      // reduce the beginning of the dataUrl to just png or jpg
      const index = dataUrl.indexOf(';');
      const type = dataUrl.slice(11, index).toUpperCase();
      const image = new Image();
      image.src = dataUrl;
      resolve({
        originalUrl: pdfURL,
        width,
        height,
        image,
        type,
      });
    }, reject);
  });

export const clearPDFImageCache = () => {
  const amount = Object.keys(cache).length;
  if (amount === 0) return;
  // console.log(`Clearing ${amount} converted pdfs…`);
  cache = {};
};
