/** @format */
import TextInput from 'blackbird/components/form/text-input/TextInput';
import classNames from 'classnames';
import React from 'react';

interface Props extends Omit<React.InputHTMLAttributes<any>, 'onChange'> {
  label: string;
  width: never;
  inputClassName?: string;
  showLabel?: boolean;
  shimmer?: never;
  hasEmoji?: never;
  autoResize?: never;
  rows?: number;
  hasShadow?: boolean;
  borderRadius?: never;
  onChange?: (newValue: string) => void;
}

/** @deprecated in favour of TextInput and TextArea. Only kept alive because
 * of regular use in Admin/settings */
const InputBox: React.FC<Props> = ({
  showLabel = true,
  hasShadow = false,
  onChange,
  ...props
}) => {
  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      if (onChange) onChange(e.currentTarget.value);
    },
    [onChange],
  );

  return (
    <TextInput
      {...props}
      className={classNames('w-full', {
        'shadow-sm': hasShadow,
      })}
      label={showLabel ? props.label : undefined}
      onChange={handleChange}
    />
  );
};

InputBox.displayName = 'InputBox';
(window as any).InputBox = InputBox;
