window.ProjectTeamActions = alt.createActions(function () {

  this.fetch = function (project_id) {
    this.dispatch(project_id);
  };

  this.fetchIfNecessary = function (project_id) {
    this.dispatch(project_id);
  };

  this.showFaces = function (project_id) {
    this.dispatch(project_id);
  };

});
