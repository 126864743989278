/** @prettier */
import Dialog from 'blackbird/components/dialog/Dialog';

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fireConfetti } from 'javascripts/helpers/confetti';
import Button from 'blackbird/components/button/Button';
import BoordsLogo from 'blackbird/images/logo/logomark.svg';
import {
  BillingContext,
  BillingProvider,
} from 'blackbird/helpers/contexts/BillingContext';

interface PaymentCheckoutSuccessProps {
  amount_total: number;
  new_plan_slug: string;
  new_plan_name: string;
  new_plan_interval: string;
}

type StripeRedirectStatusType = 'failed' | 'succeeded';

const PaymentCheckoutSuccessComponent: React.FC<
  PaymentCheckoutSuccessProps
> = ({ amount_total, new_plan_name, new_plan_slug, new_plan_interval }) => {
  const { trackCheckoutEvent } = useContext(BillingContext);

  const [redirectStatus, setRedirectStatus] = useState<
    StripeRedirectStatusType | undefined
  >(undefined);

  const { t } = useTranslation('billing');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlStatus = urlParams.get('redirect_status');

    if (urlStatus === 'failed') {
      setRedirectStatus(urlStatus);
    } else {
      setRedirectStatus('succeeded');
    }
  }, []);

  useEffect(() => {
    if (redirectStatus) {
      trackCheckoutEvent('Purchase', {
        newPlanSlug: new_plan_slug,
        newPlanInterval: new_plan_interval,
        value: amount_total,
        revenue: amount_total,
      });

      if (redirectStatus === 'failed') {
        trackCheckoutEvent('FailedPurchase', {
          newPlanSlug: new_plan_slug,
          newPlanInterval: new_plan_interval,
          value: amount_total,
          revenue: amount_total,
        });
      }
      if (redirectStatus === 'succeeded') {
        setTimeout(() => {
          fireConfetti();
        }, 250);
      }
    }
  }, [redirectStatus, amount_total, new_plan_slug, new_plan_interval]);

  const handleRedirect = () => {
    if (redirectStatus === 'failed') {
      window.location.replace('/billing/stripe');
    } else {
      // We don't want people to be able to
      // go 'back' to this page
      window.location.replace('/');
    }
  };

  return !redirectStatus ? null : (
    <Dialog
      overlayClassName={`from-brand-pink-25 to-brand-yellow-25 bg-gradient-to-br`}
      title={t(`success.${redirectStatus}.title`)}
      subtitle={t(`success.${redirectStatus}.subtitle`, {
        planName: new_plan_name,
      })}
      onCloseBtnClick={handleRedirect}
      onEscapeOrOutsideClick={handleRedirect}
      icon={<BoordsLogo className="w-12" />}
      isOpen
      hideActions
      size="slim"
    >
      <Button
        type="fancy"
        className="w-full"
        size="md"
        onClick={handleRedirect}
      >
        {t(`success.${redirectStatus}.cta`)}
      </Button>
    </Dialog>
  );
};

const PaymentCheckoutSuccessContainer: React.FC<PaymentCheckoutSuccessProps> = (
  props,
) => {
  return (
    <BillingProvider>
      <PaymentCheckoutSuccessComponent {...props} />
    </BillingProvider>
  );
};

(window as any).PaymentCheckoutSuccessContainer =
  PaymentCheckoutSuccessContainer;
