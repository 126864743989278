/** @prettier */
import logger from 'javascripts/helpers/logger';

export const addFreemiumWatermark = (
  originalImage: HTMLImageElement,
): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    fetch('/logotype.svg')
      .then((response) => response.blob())
      .then((svgBlob) => {
        const svgWatermark = new Image();
        const svgUrl = URL.createObjectURL(svgBlob);
        svgWatermark.src = svgUrl;

        svgWatermark.onerror = (err) => {
          logger.error(err);
          resolve(originalImage);
        };

        svgWatermark.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = originalImage.width;
          canvas.height = originalImage.height;

          const context = canvas.getContext('2d');

          if (!context) {
            logger.error('Unable to get 2d context from canvas');
            resolve(originalImage);
          } else {
            // Draw the original image onto the canvas
            context.drawImage(originalImage, 0, 0);

            // Set the opacity for the watermark
            context.globalAlpha = 0.1; // 10% opacity

            // Calculate dimensions and position for the watermark
            const watermarkWidth = originalImage.width * 0.5; // 50% of the original image width
            const watermarkHeight =
              (watermarkWidth / svgWatermark.width) * svgWatermark.height; // Keep aspect ratio
            const watermarkX = (originalImage.width - watermarkWidth) / 2; // Centered horizontally
            const watermarkY = (originalImage.height - watermarkHeight) / 2; // Centered vertically

            context.drawImage(
              svgWatermark,
              watermarkX,
              watermarkY,
              watermarkWidth,
              watermarkHeight,
            );

            // Create a new Image Element for the watermarked image
            const watermarkedImage = new Image();
            watermarkedImage.src = canvas.toDataURL();

            // Once the watermarked image is loaded, resolve the Promise
            watermarkedImage.onload = () => {
              resolve(watermarkedImage);
            };
          }
        };
      })
      .catch((err) => {
        logger.error(err);
        resolve(originalImage);
      });
  });
};
