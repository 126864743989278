/** @prettier */
import type { frameAspectRatio } from '../../types/storyboard';
import type { IFrame } from '../../types/frame';
import * as ignoreWebP from '../../helpers/ignore-webp';
import * as fitLib from 'fit.js';
import type { FabricObject } from '../frame_editor/types';
import type { UploadDrawnImageOptions } from '../../flux/stores/frame';
import fontLoader from '../../helpers/font-loader';
import logger from 'javascripts/helpers/logger';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';

const crossOrigin = { crossOrigin: 'Anonymous' };

export const loadBackgroundImage = (
  fabric,
  canvas,
  background_image_url: string,
) =>
  new Promise<void>((resolve) => {
    if (background_image_url) {
      const imageUrl =
        ignoreWebP(background_image_url)
          .replace(/^http:\/\//, 'https://')
          .replace(',compress:true', '') + '?editor=true';

      fabric.Image.fromURL(
        imageUrl,
        (backgroundImage) => {
          // Because of some previous bugs, it might happen that a background
          // image is not the right size, so we want to position it, cover
          // style
          const {
            width,
            height,
            x: left,
            y: top,
          } = fitLib(
            {
              width: backgroundImage.width,
              height: backgroundImage.height,
              x: 0,
              y: 0,
            },
            {
              width: canvas.getWidth(),
              height: canvas.getHeight(),
              x: 0,
              y: 0,
            },
            {
              apply: false,
              cover: true,
              hAlign: fitLib.CENTER,
              vAlign: fitLib.CENTER,
            },
          );

          canvas.setBackgroundImage(backgroundImage, resolve, {
            originX: 'left',
            originY: 'top',
            left,
            top,
            width,
            height,
          });
        },
        crossOrigin,
      );
    } else {
      resolve();
    }
  });

function loadFonts(objects?: FabricObject[]) {
  const fontsToLoad: string[] = [];

  // Go through the layerdata and figure out which fonts we need
  objects?.forEach((object: FabricObject) => {
    if (object.type === 'textbox') {
      const string = object.fontFamily + ':' + object.fontWeight;
      if (fontsToLoad.indexOf(string) < 0) fontsToLoad.push(string);
      object.set('dirty', true);
    }
  });

  const promises = fontsToLoad.map((fontString) => {
    const [name, weight] = fontString.split(':');
    return fontLoader.load(name, weight, 'latin');
  });

  // Make an array of loadFont promises, and wait for all to resolve
  return Promise.all(promises);
}

export const renderFrameInBackground = (
  frame: IFrame,
  ratio: frameAspectRatio,
) =>
  new Promise<UploadDrawnImageOptions>((resolve, reject) => {
    const res = BoordsFrameSizeHelper(ratio);
    const element = document.createElement('canvas');
    element.width = res.width;
    element.height = res.height;

    Promise.all([import('fabric'), import('@arch-inc/fabricjs-psbrush')]).then(
      ([{ fabric }]) => {
        const canvas = new fabric.Canvas(element, {
          width: res.width,
          height: res.height,
          preserveObjectStacking: true,
        });

        canvas.loadFromJSON(frame.layer_data, () => {
          Promise.all([
            loadFonts(canvas.getObjects()),
            loadBackgroundImage(fabric, canvas, frame.background_image_url),
          ])
            .then(() => {
              canvas.renderAll();

              const options = {
                format: 'jpeg',
                quality: 0.9,
                multiplier: 1,
                ...res,
              };
              const dataURL = canvas.toDataURL(options);
              const layer_data = canvas.toDatalessJSON();

              resolve({
                image: dataURL,
                image_file_type: 'image/jpeg',
                // https://stackoverflow.com/a/39640087/847689
                image_file_size: Math.round(
                  dataURL.replace(/=/g, '').length * 0.75,
                ),
                id: frame.id,
                storyboard_id: frame.storyboard_id,

                background_image_url: null,
                layer_data,
              });

              try {
                canvas.dispose();
                element.remove();
              } catch (e) {
                logger.warn('Could not clean up frame editor properly', e);
              }
            })
            .catch(reject);
        });
      },
      reject,
    );
  });
