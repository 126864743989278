/** @format */
import logger from 'javascripts/helpers/logger';

/** a regular expression that includes letters, numbers and common symbols, used
for destinguishing between "writing" key events and others **/
export const characterInputPattern =
  /^[a-z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\s]$/i;

export const entireElementIsSelected = (parentElement: HTMLElement) => {
  const selection = window.getSelection();
  if (!selection) return;
  const range = selection.getRangeAt(0);

  return (
    range.intersectsNode(parentElement.firstChild!) &&
    range.intersectsNode(parentElement.lastChild!)
  );
};

export const selectionSpansMultipleElements = (parentElement: HTMLElement) => {
  const selection = window.getSelection();
  if (!selection) return;
  const range = selection.getRangeAt(0);

  return range.commonAncestorContainer === parentElement;
};

export const cursorIsAtStartOrEndOfElement = (
  pos: 'start' | 'end',
  parentElement: Element,
  /** Allow for the cursor to be in the first/last x characters for this
   * function to return true. The margin of 1 takes in account some quirks, most
   * notably the one where the anchorOffset would return 1 even though the user
   * is at the beginning of the editable text */
  margin = 1,
) => {
  const selection = window.getSelection();
  if (!selection) return;

  const anchorNode = selection.anchorNode as Node;
  const anchorOffset = selection.anchorOffset;
  const focusNode = selection.focusNode as HTMLElement;
  const focusOffset = selection.focusOffset;
  const anchorElement: HTMLElement =
    anchorNode instanceof HTMLElement ? anchorNode : anchorNode.parentElement!;

  const elementIsOrContains = (parent: Element | null, el: HTMLElement) => {
    // If the parent is null, it might mean that the element is totally empty.
    // In that case, we will assume that anchorElement is the parent.
    if (!parent) return true;
    // If the parent node is a BR, this might also mean that the element is
    // totally empty (this is something that browsers leave behind). In this
    // case we can also assume true here
    if (parent.nodeName === 'BR') return true;
    return parent === anchorElement || parent.contains(el);
  };

  if (!anchorNode) throw new Error('no anchor node');

  if (pos === 'start') {
    return (
      elementIsOrContains(parentElement.firstElementChild, anchorElement) &&
      anchorOffset <= 0 + margin
    );
  } else {
    // Sometimes in the editor, there is a BR element in the way, we want to
    // ignore that
    let lastElementChild = parentElement.lastElementChild!;
    if (lastElementChild && lastElementChild.nodeName === 'BR') {
      lastElementChild = lastElementChild.parentElement!;
    }

    const isLastElement = elementIsOrContains(lastElementChild, anchorElement);

    const endOffset = selection.getRangeAt(0).endOffset;
    const isAtEndOfElement =
      endOffset >= (focusNode?.textContent?.length ?? 0) - margin;
    return isLastElement && isAtEndOfElement;
  }
};

/** Prevents event defaults in cases where the user might accidentally delete
 * content in the editor. It prevents `backspace` at the beginning of divs
 * (inside paragraphs) `delete` at the end of divs (inside paragraphs), and
 * regular character inputs when multiple divs are selected. */
export const scriptEditorDeletionHandler = (
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const selection = window.getSelection();
  const editorElement = event.currentTarget;
  if (!selection) return;

  const anchorNode = selection.anchorNode as Node;

  const anchorElement: HTMLElement =
    anchorNode instanceof HTMLElement ? anchorNode : anchorNode.parentElement!;

  /** This may be undefined when we have multiple fields selected, but then the
   * code that depends on this isn't relevant */
  const fieldComponent = anchorElement.closest('div[data-field]');

  const verifySelectionDoesntContainBreaks = () => {
    if (selectionSpansMultipleElements(editorElement)) {
      logger.log('commonAncestorContainer is field or editor, cancelling');
      event.preventDefault();
    }
  };

  // If the key pressed matches a typical character, and it is not a keyboard
  // shortcut, we want to make sure we don't override
  if (
    event.key.match(characterInputPattern) &&
    !event.metaKey &&
    !event.altKey &&
    !event.ctrlKey
  ) {
    verifySelectionDoesntContainBreaks();
  } else if (event.key === 'Backspace' && fieldComponent) {
    if (cursorIsAtStartOrEndOfElement('start', fieldComponent)) {
      event.preventDefault();
    }
    verifySelectionDoesntContainBreaks();
  } else if (event.key === 'Delete' && fieldComponent) {
    if (cursorIsAtStartOrEndOfElement('end', fieldComponent)) {
      event.preventDefault();
    }
    verifySelectionDoesntContainBreaks();
  }
};
