/** @prettier */
import Button from 'blackbird/components/button/Button';
import { ajaxRuby } from 'javascripts/helpers/ajax';
import * as React from 'react';

interface AdminStarterDeleteProps {
  id: number;
}

const AdminStarterDelete: React.FC<AdminStarterDeleteProps> = ({
  id,
}): React.ReactElement => {
  const handleDelete = async () => {
    if (confirm('Are you sure?')) {
      await ajaxRuby({
        method: 'delete',
        url: `/admin/starters/${id}`,
      });

      window.location.href = '/admin/starters';
    }
  };
  return (
    <Button
      size="sm"
      type="destructive"
      onClick={handleDelete}
    >{`Delete`}</Button>
  );
};
(window as any).AdminStarterDelete = AdminStarterDelete;
