const _ = require('underscore');
/* eslint react/sort-comp:0 */
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const { FramesBlankCanvas } = require('./FramesBlankCanvas');
import "./styles.scss";
window.FramesContainer = createReactClass({

  propTypes:             {
    storyboard:          PropTypes.object.isRequired,
    frames:              PropTypes.array.isRequired,
    selectedFrames:      PropTypes.array.isRequired,
  },

  getInitialState: function() {
    return {
      is_dragging:  false,
    }
  },

  componentDidMount: function() {
    this.enterCounter = 0;
    window.addEventListener('dragenter', this._onDragEnter, false);
    window.addEventListener('dragover', this._onDragOver, false);
    window.addEventListener('dragleave', this._onDragLeave, false);
    window.addEventListener('drop', this._onWindowDrop, false);
    window.addEventListener('click', this.handleClick);
    window.addEventListener('drop', this._onWindowDrop);

    if (window.location.hash === "#export") {
      FlyoverActions.open.defer({
        type: "storyboardExport"
      });
    }
  },

  handleClick(e) {
    const nodeName = e.target.nodeName?.toUpperCase();
    if (e.button !== 0) return;

    if (!e.defaultPrevented && nodeName !== 'BUTTON'
      && nodeName !== 'INPUT'
      && nodeName !== 'LABEL'
      && nodeName !== 'SVG'
      && nodeName !== 'LABEL'
      && nodeName !== 'SVG'
    ) {
      FrameActions.deselectAll()
    }
  },

  componentWillUnmount: function() {
    window.removeEventListener('dragenter', this._onDragEnter);
    window.removeEventListener('dragover', this._onDragOver);
    window.removeEventListener('dragleave', this._onDragLeave);
    window.removeEventListener('drop', this._onWindowDrop);
    window.removeEventListener('click', this.handleClick);
  },

  _onWindowDrop: function(e) {
    // When an image is dropped onto the screen oustide of a dropzone
    // and frames have already been added, ignore the dropped image
    if (this.props.frames) {
      e.preventDefault();
    }
  },

  _onDragEnter: function(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.props.is_dragging_existing) {
      this.enterCounter++;
      this.setState({ is_dragging: true });
    }
  },

  _onDragOver: function(event) {
    event.preventDefault();
    event.stopPropagation();
  },

  _onDragLeave: function (event) {
    event.preventDefault();
    event.stopPropagation();

    //if (!this.props.is_dragging_existing) {
      this.enterCounter--;

      if (this.enterCounter == 0) {
        this.setState({ is_dragging: false });
      }
    //}
  },


  render: function() {
    if (this.state.error_message) {

      return <FrameOverlayMessage title={this.state.error_message} isError={true} />;

    } else if (this.props.is_duplicating_storyboard) {

      return <FrameOverlayMessage title="Creating new version of storyboard" subtitle="The page will refresh automatically when it's complete" />;

    } else if (!this.props.frames || this.props.is_loading) {

      return <FrameOverlayMessage title="Loading your frames…" />;

    } else {
      const isMultipleSelected = this.props.selectedFrames.length > 1;

      return (
        <div className='h-full'>
          {(_.isEmpty(this.props.frames)) ? (
            <div className="overlay-message__container h-full bg-surface top-banner">
              <FramesBlankCanvas
                {...this.props}
                enableFilestack={
                  !window.BoordsConfig.CustomStorage
                }
              />
            </div>
          ) : (
            <FramesSortable {...this.props} isMultipleSelected={isMultipleSelected}/>
          )}
        </div>
      );
    }

  }

});
