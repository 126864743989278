/**@prettier */
import React from 'react';
import MoreOptionsIcon from 'blackbird/images/icons/menu.svg';
import MoreOptions from 'blackbird/components/menu/MoreOptions';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import { Project } from 'javascripts/flux/stores/dashboard';
import Icon from 'blackbird/components/icon/Icon';
import { useTranslation } from 'react-i18next';

interface ProjectDashboardPopupMenuProps {
  project: Project;
  disabledText?: string;
  disabled?: boolean;
  onVisibilityChange?: (open?: boolean) => void;
}
const ProjectDashboardPopupMenu: React.FC<ProjectDashboardPopupMenuProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { project, disabledText, disabled } = props;
  const handleArchiveClick = () => {
    DashboardActions.archiveProject(project);
  };

  const handleRenameClick = () => {
    ProjectActions.editName(project.id);
  };

  const components = [
    {
      id: 'rename',
      label: t('actions.project.rename'),
      onItemSelect: handleRenameClick,
    },
    {
      id: 'archive',
      label: t('actions.project.archive'),
      onItemSelect: handleArchiveClick,
    },
  ];
  const disabledComp = <div className="max-w-xs">{disabledText}</div>;
  return (
    //show tooltip only when our Popupmenu is supposed to be disabled
    <Tooltip disabled={!disabled} title={disabledComp} distance={1}>
      {/* Currently our tooltip component doesn't support direct React components,
      They should be an html element or a ForwardRef*/}
      <div>
        {disabled ? (
          <Icon icon={<MoreOptionsIcon />} className="w-10" />
        ) : (
          <MoreOptions
            onVisibilityChange={props.onVisibilityChange}
            blurOnClose
            options={components}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default ProjectDashboardPopupMenu;
