/**
 * @prettier
 */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const { getFrameImage } = require('../../../helpers/getFrameImage');
const navigateToRoute = require('../../../helpers/router/navigate-to-route');
const Img = require('../../shared/Img').default;
const FrameImageDropZone = require('./FrameImageDropZone').default;
const {
  ImageSourcesMultiPanelDefaultWidth,
  ImageSourcesMultiPanel,
} = require('../AddImageMultipanel/ImageSourcesMultiPanel');
const { default: Button } = require('blackbird/components/button/Button');
const {
  default: Popover,
} = require('blackbird/components/common/popover/Popover');
const { Watermark } = require('blackbird/components/watermark/Watermark');
const { FrameButtonContent } = require('./FrameButtonContent');
const {
  getErrorFrameImageURL,
} = require('javascripts/helpers/getMissingFrameImageURL');

import { ArrowPathIcon } from '@heroicons/react/20/solid';
import {
  ChatBubbleBottomCenterIcon,
  PencilIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import { GeneratorButton } from 'blackbird/components/generator/GeneratorButton';
import { TourHintable } from 'javascripts/components/tours/TourHintable';

const { default: classNames } = require('classnames');

window.FrameImage = createReactClass({
  propTypes: {
    frame: PropTypes.object.isRequired,
    isMinimal: PropTypes.bool,
    frameIsOutsideFrameLimit: PropTypes.bool,
    storyboard: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      isOpen: false,
      hasDrop: false,
      showTour: false,
    };
  },

  _lastClick: null,

  componentWillUnmount() {
    clearTimeout(this.timeout);
  },

  componentDidMount() {
    if (
      ToursStore.state.currentSteps.length > 0 &&
      ToursStore.state.currentSteps[0].name === 'generatorOpen' &&
      this.props.frame.sort_order === 1
    ) {
      this._showButtons();
      this.setState({ showTour: true });
    }
  },

  timeout: null,
  lastClickTime: null,

  /**
   * Manually track click/doubleclick actions, because the native doubleclick
   * event does not fire on touch devices and there's no dblTap or something…
   */
  handleClick(e) {
    if (this.lastClickTime && e.timeStamp - this.lastClickTime < 300) {
      if (this.props.frameIsOutsideFrameLimit) return;
      e.preventDefault();
      navigateToRoute('frameFocus', {
        slug: this.props.storyboard.slug,
        frameIndex: this.props.frame.sort_order,
      });
    }

    // Even if we detect a double click, we still want to execute the single
    // click stuff again, because the first click might have deselected
    if (window.BoordsConfig.TeamRole !== 'guest') {
      e.preventDefault();

      if (e.shiftKey) {
        FrameActions.selectMultiple(this.props.frame.id);
      } else if (e.currentTarget.contains(e.target)) {
        FrameActions.selectFrame(this.props.frame.id);
      }

      CommentActions.closeFrameCommentBox.defer();
    }

    this.lastClickTime = e.timeStamp;
  },

  _comment(e) {
    e.stopPropagation();
    e.preventDefault();
    CommentActions.toggleFrameCommentBox.defer({
      frame: this.props.frame,
    });
  },

  _edit(e) {
    e.stopPropagation();
    e.preventDefault();
    navigateToRoute('frameEditor', {
      slug: this.props.storyboard.slug,
      frameIndex: this.props.frame.sort_order,
    });
  },

  _showButtons() {
    this.timeout = setTimeout(
      function () {
        if (this.state.hasDrop) return;
        this.setState({ isOpen: true });
      }.bind(this),
      150,
    );
  },

  _hideButtons() {
    clearTimeout(this.timeout);
    this.setState({ isOpen: false });
  },
  stopPropagation: function (e) {
    e.stopPropagation();
  },
  handleDropParamsChange({ isOver, canDrop }) {
    this.setState({
      hasDrop: isOver && canDrop,
    });
  },
  render: function () {
    const aspectRatio = this.props.storyboard.frame_aspect_ratio;
    const thumbnail = getFrameImage(
      this.props.frame.thumbnail_image_url,
      aspectRatio,
    );

    const hasAIEnabled = BoordsConfig.ShowGeneratorUI;

    const { height, width } = BoordsFrameSizeHelper(aspectRatio);
    const hintableSteps = ['generatorOpen'];
    return (
      <div
        className="relative pointer frameSortableHandle"
        onMouseEnter={this._showButtons}
        onMouseLeave={this._hideButtons}
        onClick={this.handleClick}
      >
        {this.state.isOpen && !this.props.simpleNewFrameButton ? (
          <div className="absolute inset-0 z-10">
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="absolute inset-0 z-10 bg-black/25" />
              <div
                className={classNames(
                  'relative z-20 ',
                  hasAIEnabled
                    ? 'grid grid-cols-2 gap-x-3 gap-y-3'
                    : 'space-y-1.5',
                )}
              >
                {hasAIEnabled && (
                  <TourHintable
                    step={hintableSteps}
                    canShow={this.state.showTour}
                    overlayPosition="bottom"
                  >
                    <GeneratorButton
                      storyboard={this.props.storyboard}
                      frame={this.props.frame}
                      disabled={this.props.frameIsOutsideFrameLimit}
                    >
                      <FrameButtonContent
                        hideText={this.props.isMinimal}
                        text={`Generate`}
                        IconComponent={SparklesIcon}
                      />
                    </GeneratorButton>
                  </TourHintable>
                )}
                {/* Need to capture event on this div and stop from propagating
                any further as there is no way to override HeadlessUI's
                onClick method and stop propagation to parent element*/}
                <div onClick={this.stopPropagation}>
                  <Popover portal placement="right" distance={10} offset={-100}>
                    <Popover.Button as="div">
                      <Button
                        type="secondary"
                        size="sm"
                        disabled={this.props.frameIsOutsideFrameLimit}
                      >
                        <FrameButtonContent
                          hideText={this.props.isMinimal}
                          text={`Replace`}
                          IconComponent={ArrowPathIcon}
                        />
                      </Button>
                    </Popover.Button>
                    <Popover.Panel
                      className="z-40"
                      onMouseEnter={this.stopPropagation}
                      onMouseLeave={this.stopPropagation}
                    >
                      <ImageSourcesMultiPanel
                        width={ImageSourcesMultiPanelDefaultWidth}
                        storyboard={this.props.storyboard}
                        currentFrame={this.props.frame}
                        replace
                      />
                    </Popover.Panel>
                  </Popover>
                </div>
                <div className="">
                  <Button
                    size="sm"
                    className="w-full"
                    type="secondary"
                    disabled={this.props.frameIsOutsideFrameLimit}
                    onClick={this._edit}
                  >
                    <FrameButtonContent
                      hideText={this.props.isMinimal}
                      text={`Editor`}
                      IconComponent={PencilIcon}
                    />
                  </Button>
                </div>
                {[`lite`, `standard`].includes(
                  BoordsConfig.StoryboardTeamPlanSlug,
                ) ? (
                  <Tooltip
                    placement="top"
                    title={`Please upgrade to enable commenting`}
                  >
                    <div>
                      <Button
                        type="secondary"
                        size="sm"
                        onClick={() => {
                          FlyoverActions.open.defer({
                            type: 'inlinePricing',
                          });
                        }}
                      >
                        <FrameButtonContent
                          textClassName="opacity-50"
                          hideText={this.props.isMinimal}
                          text={`Comment`}
                          IconComponent={ChatBubbleBottomCenterIcon}
                        />
                      </Button>
                    </div>
                  </Tooltip>
                ) : this.props.storyboard.has_comments_enabled ? (
                  <div>
                    <Button type="secondary" size="sm" onClick={this._comment}>
                      <FrameButtonContent
                        hideText={this.props.isMinimal}
                        text={`Comment`}
                        IconComponent={ChatBubbleBottomCenterIcon}
                      />
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        <Watermark>
          <Img
            className="rounded-sm"
            src={thumbnail}
            height={height}
            width={width}
            placeholder={getErrorFrameImageURL(aspectRatio)}
            log
          />
        </Watermark>

        <FrameImageDropZone
          frame={this.props.frame}
          team_id={this.props.storyboard.project.owner.id}
          onDropParamsChange={this.handleDropParamsChange}
          className="z-[2]"
        />
      </div>
    );
  },
});
