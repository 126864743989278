/** @format */
import * as React from 'react';
import { Transition } from '@headlessui/react';

/** This file contains a couple of preset transition types  */

/** Slightly pops in to view */
export const PopTransition: React.FC<
  React.ComponentProps<typeof Transition>
> = (props) => (
  <Transition
    enter="transition duration-75"
    enterFrom="opacity-20 scale-75"
    enterTo="opacity-100 scale-100"
    leave="transition duration-75"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-75"
    {...(props as object)}
  >
    {props.children}
  </Transition>
);

/** pops in to view */
export const BigPopTransition: React.FC<
  React.ComponentProps<typeof Transition>
> = (props) => (
  <Transition
    enter="transition duration-75"
    enterFrom="opacity-20 scale-0"
    enterTo="opacity-100 scale-100"
    leave="transition duration-75"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-0"
    {...(props as object)}
  >
    {props.children}
  </Transition>
);
/** This is supposed to be an accordion/slide-down transition, but this seems
 * impossible with how headlessUI handles this. Probably want to replace with
 * framer motion or react-spring at some point  */
export const AccordionTransition: React.FC<
  React.ComponentProps<typeof Transition>
> = (props) => (
  <Transition
    enter="transition-opacity duration-400"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-400"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    {...(props as object)}
  >
    {props.children}
  </Transition>
);
