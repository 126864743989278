/** @prettier */
import { IFrame } from '../../../types/frame';
import { PDFDocumentStyle, Base64FrameImagesResult, DocInfo } from '../types';
import { createPDFImage } from './PDFImage';
import { createPDFFrameFields } from './createPDFFrameFields';

interface Props {
  frame: IFrame;
  width: number;
  x: number;
  y: number;
  maxLineWidth: number;
  style: PDFDocumentStyle;
  isDebugMode?: boolean;
}

export const createPDFListItem = (
  docInfo: DocInfo,
  props: Props,
  frameImageInfo: Base64FrameImagesResult,
) => {
  const dimensions = { width: props.width, height: 0 };
  const doc = docInfo.doc;

  const image = createPDFImage(docInfo, {
    imageInfo: frameImageInfo,
    width: props.style.frameWidth,
    x: props.x,
    y: props.y,
    border: props.style.showFrameBorder ? props.style.colors.border : undefined,
  });

  const textWidth = Math.min(
    props.width - image.width - props.style.gutter,
    props.maxLineWidth,
  );

  const fields = createPDFFrameFields(docInfo, {
    width: textWidth,
    x: props.x + image.width + props.style.gutter,
    y: props.y,
    frame: props.frame,
    style: props.style,
    isDebug: props.isDebugMode,
  });

  dimensions.height = Math.max(fields.height, image.height);
  dimensions.width = textWidth + image.width + props.style.gutter;

  if (props.isDebugMode) {
    doc.setDrawColor(255, 200, 200);
    doc.rect(props.x, props.y, props.width, dimensions.height);
  }

  return dimensions;
};
