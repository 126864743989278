/** @format */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ProjectsContext } from './ProjectsContext';
import ProjectStoryboard from './ProjectStoryboard';
import { ProjectContentsBreadcrumb } from './ProjectContentsBreadcrumb';
import { ProjectSkeletonGrid, ProjectSkeletonHeader } from './ProjectSkeleton';
import { type Project } from './ProjectsContext';
import { ProjectSubProject } from './ProjectSubProject';
import { ProjectContentsTitle } from './ProjectContentsTitle';
import { ProjectContentsDescripton } from './ProjectContentsDescription';
import { ProjectContentsToolbar } from './ProjectContentsToolbar';
import { ProjectBulkMoveDialog } from './modals/ProjectBulkMoveDialog';
import { Listbox } from '@headlessui/react';
import { ArrowDown, ArrowUp, CheckIcon, CirclePlus } from 'lucide-react';
import classNames from 'classnames';
import {
  projectContentsSortFieldLocalState,
  projectContentsSortOrderLocalState,
  type SortField,
  type SortOrder,
} from 'javascripts/helpers/local-state';
import Tooltip from '../feedback/tooltip/Tooltip';
import { ProjectNewButton } from './menus/ProjectNewButton';
import { ProjectContentsEmpty } from './empty/ProjectContentsEmpty';
import { ProjectOverviewEmpty } from './empty/ProjectOverviewEmpty';
import { TeamProjectManage } from '../team/TeamProjectManage';
import { ProjectShareButton } from './modals/ProjectShareButton';
import logger from 'javascripts/helpers/logger';

interface SortOption {
  value: SortField;
  label: string;
}

const sortOptions: SortOption[] = [
  { value: 'name', label: 'Name' },
  { value: 'created_at', label: 'Date created' },
  { value: 'updated_at', label: 'Date modified' },
];

export const ProjectContents: React.FC = () => {
  const {
    setSelectedStoryboards,
    setSelectedProjects,
    projectContents,
    activeProject,
    activeGroup,
    projectGroups,
    isLoading,
    setNewProjectParent,
    setIsNewProjectModalOpen,
    isLoadingProjectContents,
  } = useContext(ProjectsContext);

  const [sortField, setSortField] = useState<SortField>(() => {
    const savedSortField = projectContentsSortFieldLocalState.getValue();
    if (BoordsConfig.IsPuffinTrial && !savedSortField) {
      return 'created_at';
    }
    return savedSortField || 'name';
  });

  const [sortOrder, setSortOrder] = useState<SortOrder>(() => {
    const savedSortOrder = projectContentsSortOrderLocalState.getValue();
    if (BoordsConfig.IsPuffinTrial && !savedSortOrder) {
      return 'asc';
    }
    return savedSortOrder || 'asc';
  });

  useEffect(() => {
    projectContentsSortFieldLocalState.setValue(sortField);
  }, [sortField]);

  useEffect(() => {
    projectContentsSortOrderLocalState.setValue(sortOrder);
  }, [sortOrder]);

  const childProjects = useMemo(() => {
    if (!activeProject || !projectGroups) return [];

    const allProjects = [
      ...projectGroups.team.projects,
      ...projectGroups.memberships.reduce<Project[]>(
        (acc, group) => [...acc, ...group.projects],
        [],
      ),
    ];

    return allProjects.filter(
      (project) => project.parent_id === activeProject.id,
    );
  }, [activeProject, projectGroups]);

  const sortedChildProjects = useMemo(() => {
    return [...childProjects].sort((a, b) => {
      if (sortField === 'name') {
        return sortOrder === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else {
        const aDate = new Date(a[sortField]).getTime();
        const bDate = new Date(b[sortField]).getTime();
        return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
      }
    });
  }, [childProjects, sortField, sortOrder]);

  const sortedProjectContents = useMemo(() => {
    if (!projectContents) return [];
    return [...projectContents].sort((a, b) => {
      if (sortField === 'name') {
        return sortOrder === 'asc'
          ? a.document_name.localeCompare(b.document_name)
          : b.document_name.localeCompare(a.document_name);
      } else {
        const aDate = new Date(a[sortField]).getTime();
        const bDate = new Date(b[sortField]).getTime();
        return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
      }
    });
  }, [projectContents, sortField, sortOrder]);

  useEffect(() => {
    return () => {
      setSelectedProjects([]);
      setSelectedStoryboards([]);
    };
  }, [activeProject]);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  if (isLoadingProjectContents || isLoading) {
    return (
      <div className="max-h-full overflow-hidden">
        <ProjectSkeletonHeader />
        <ProjectSkeletonGrid />
      </div>
    );
  }

  if (
    (!activeProject || !activeGroup) &&
    !isLoadingProjectContents &&
    !isLoading
  ) {
    return (
      <div className="h-full">
        <ProjectOverviewEmpty />
      </div>
    );
  }

  return (
    <>
      <div
        className="min-h-full bg-surface"
        onClick={() => {
          setSelectedStoryboards([]);
          setSelectedProjects([]);
        }}
      >
        <div className="px-10 pt-6 pb-4 bg-white border-b border-border">
          <div className="flex items-start">
            <div className="flex-auto">
              <ProjectContentsBreadcrumb />
            </div>
            <div className="flex items-center space-x-2">
              <ProjectShareButton />
              <ProjectNewButton />
            </div>
          </div>
          <ProjectContentsTitle />
          <ProjectContentsDescripton />
          {/* Sorting */}
          {sortedChildProjects.length > 0 ||
          sortedProjectContents.length > 0 ? (
            <div className="flex items-center justify-end mb-0">
              <Listbox
                as="div"
                className="relative inline-block text-left"
                value={sortField}
                onChange={setSortField}
              >
                <Listbox.Button className="inline-flex justify-center w-full px-2 pb-1.5 pt-1 text-sm font-semibold rounded-md hover:bg-surface focus:outline-none">
                  <span>
                    {
                      sortOptions.find((option) => option.value === sortField)
                        ?.label
                    }
                  </span>
                </Listbox.Button>
                <Listbox.Options className="absolute right-0 z-40 w-40 mt-2 bg-white border shadow-lg origin-top-right rounded-md focus:outline-none border-border">
                  <div className="p-2 space-y-1">
                    <div className="px-3 pt-1.5 pb-1 text-xs font-semibold tracking-wider uppercase text-type-subdued">{`Sort by`}</div>
                    {sortOptions.map((option) => (
                      <Listbox.Option key={option.value} value={option.value}>
                        {({ selected }) => (
                          <div
                            className={classNames(
                              'flex items-center text-sm cursor-pointer relative pb-2 pt-1.5 px-3 rounded-md',
                              selected
                                ? 'bg-surface-light'
                                : ' hover:bg-surface-light',
                            )}
                          >
                            <span className="flex-auto">{option.label}</span>
                            {selected && (
                              <CheckIcon className="flex-shrink-0 w-3.5 h-3.5" />
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </div>
                </Listbox.Options>
              </Listbox>
              <Tooltip title={`Sort ascending/descending`} placement="top">
                <div
                  className="p-2 cursor-pointer rounded-md hover:bg-surface"
                  onClick={toggleSortOrder}
                >
                  {sortOrder === 'asc' ? (
                    <ArrowDown strokeWidth={2.5} className="w-4 h-4" />
                  ) : (
                    <ArrowUp strokeWidth={2.5} className="w-4 h-4" />
                  )}
                </div>
              </Tooltip>
            </div>
          ) : (
            <div className="mb-3" />
          )}
        </div>
        <div className="px-10 py-8">
          {sortedChildProjects.length > 0 && (
            <>
              <div className="mb-6 text-xs font-semibold tracking-wider uppercase text-black/70">{`Sub-projects (${sortedChildProjects.length})`}</div>
              <div className="mb-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxxl:grid-cols-6 gap-x-3 gap-y-5 -ml-1.5">
                {sortedChildProjects.map((project) => (
                  <ProjectSubProject key={project.id} project={project} />
                ))}

                {activeGroup?.actions.includes(`projects.create`) && (
                  <div
                    onClick={() => {
                      setNewProjectParent(activeProject);
                      setIsNewProjectModalOpen(true);
                    }}
                    className="cursor-pointer bg-surface-frame rounded-md flex items-center hover:bg-surface-add_frame text-center group/newproject m-1.5 pr-4 pl-6 py-3"
                  >
                    <div className="mr-2.5 group-hover/newproject:text-type-subdued text-type-disabled">
                      <CirclePlus size={24} strokeWidth={1.5} />
                    </div>
                    <div className="text-type-subdued text-sm pb-0.5">{`New Project`}</div>
                  </div>
                )}
              </div>
            </>
          )}
          {sortedProjectContents.length > 0 && (
            <>
              <div className="mb-6 text-xs font-semibold tracking-wider uppercase text-black/70">{`Storyboards (${sortedProjectContents.length})`}</div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxxl:grid-cols-6 gap-x-3 gap-y-5 -ml-1.5">
                {sortedProjectContents.map((document) => (
                  <ProjectStoryboard document={document} key={document.id} />
                ))}
                {activeGroup?.actions.includes(`storyboards.create`) &&
                  activeProject && (
                    <div
                      onClick={() => {
                        FlyoverActions.open.defer({
                          type: 'newStoryboard',
                          projectId: activeProject.id,
                          teamId: activeGroup?.team_id,
                          props: {
                            defaultTemplateId: activeProject.template_id,
                            actions: activeGroup.actions,
                          },
                        });
                      }}
                      className="group/newstoryboard cursor-pointer bg-surface-frame rounded-md min-h-[244px] flex items-center justify-center hover:bg-surface-add_frame text-center hover:text-brand-pink text-type-disabled m-1.5"
                    >
                      <div>
                        <div className="flex justify-center group-hover/newstoryboard:text-type-subdued text-type-disabled">
                          <CirclePlus size={36} strokeWidth={1} />
                        </div>
                        <div className="mt-4 text-type-subdued">{`New Storyboard`}</div>
                      </div>
                    </div>
                  )}
              </div>
            </>
          )}

          {sortedProjectContents.length < 1 &&
            sortedChildProjects.length < 1 && <ProjectContentsEmpty />}
        </div>
      </div>
      <ProjectBulkMoveDialog />
      <ProjectContentsToolbar />
      <TeamProjectManage />
    </>
  );
};
