/** @format */

import { isObject } from 'underscore';

/* eslint-disable no-console, prefer-rest-params  */
export function prepareSegment() {
  // This is a formatted version of the standard script segment offers, it queues
  // up events. By default it actually calls the `load` function, but we're
  // deferring that until later
  var analytics = ((window as any).analytics = (window as any).analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory = function (t) {
        return function () {
          var e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (var t = 0; t < analytics.methods.length; t++) {
        var e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = function (t) {
        var e = document.createElement('script');
        e.type = 'text/javascript';
        e.async = !0;
        e.src =
          ('https:' === document.location.protocol ? 'https://' : 'http://') +
          'cdn.segment.com/analytics.js/v1/' +
          t +
          '/analytics.min.js';
        var n = document.getElementsByTagName('script')[0];
        n.parentNode?.insertBefore(e, n);
      };
      analytics.SNIPPET_VERSION = '4.0.0';
    }
}

export function loadSegment() {
  if (BoordsConfig.controller === 'admin') return;
  const { identify, group, appID } = BoordsConfig.segment;
  if (!appID) throw new Error('no app id present for segment integration');

  analytics.load(appID);
  analytics.page();

  if (identify) Track.identify(identify);
  if (group) group.forEach((g) => Track.group(g));
}
