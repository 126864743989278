/** @format */
import { idleTimeout } from './idle-timeout';
import { loadPusher } from './vendor/load-pusher';
import { loadSegment, prepareSegment } from './vendor/load-segment';
import { onLoad } from './onLoad';
import { RequestErrorHandler } from './request-error-handler';
import { loadProfitwell } from './vendor/load-profitwell';
const errorHandler = RequestErrorHandler('thirdPartyScripts');

/**
 * Loads third party dependencies with a delay (depending on CPU availability)
 * so we don't clog up connections early in the app.
 */
export const loadThirdPartyScripts = () =>
  new Promise<void>((resolve) => {
    prepareSegment();
    onLoad(() => {
      idleTimeout(
        () => {
          loadPusher();
          loadSegment();
          loadProfitwell();

          resolve();
        },
        1000,
        10000,
      );
    });
  }).catch(errorHandler);
