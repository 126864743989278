/** @prettier */
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import formatRelative from 'date-fns/formatRelative';
import formatDate from 'date-fns/format';
import i18n from 'i18next';
import logger from 'javascripts/helpers/logger';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import { initReactI18next } from 'react-i18next';
import { isDate } from 'underscore';

const errorHandler = RequestErrorHandler('i18n');
import { detectOS, type OperatingSystem } from 'detect-browser';

const browser = detectOS(navigator.userAgent);
const cmdKeysOSList: OperatingSystem[] = ['iOS', 'Mac OS'];
const hasCmdKey = browser && cmdKeysOSList.includes(browser);

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require('./en.json'),
      stats: require('./stats.json'),
      onboarding: require('./onboarding.json'),
      banners: require('./banners.json'),
      sales: require('./sales.json'),
      wizard: require('./wizard.json'),
      cancelation: require('./cancelation.json'),
      inline_ctas: require('./inline_ctas.json'),
      billing: require('./billing.json'),
      topups: require('./topups.json'),
      imageLibrary: require('./imageLibrary.json'),
      generator: {
        styles: require('./generator/styles.json'),
      },
      generatorCopy: require('./generator/copy.json'),
      ai: {
        categories: require('./ai/categories.json'),
        presets: require('./ai/presets.json'),
        languages: require('./ai/languages.json'),
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    defaultVariables: {
      metaKey: hasCmdKey ? '⌘' : 'ctrl',
    },
    escapeValue: false,
    // From https://dev.to/ekeijl/react-automatic-date-formatting-in-translations-i18next-date-fns-8df
    format: (value, format) => {
      if (isDate(value)) {
        switch (format) {
          case 'relative':
            return formatRelative(value, new Date(), { weekStartsOn: 1 });
          case 'ago':
            return formatDistanceToNowStrict(value, {
              addSuffix: true,
            });
          default:
            formatDate(value, format ?? 'd MMM yy');
        }
      }

      return value;
    },
  },
  saveMissing: true,
  missingKeyHandler: (ng, ns, key, fallbackValue) => {
    // In some cases (like error status codes) we set this to null so we don't
    // show a custom message if there isn't a message for it.
    if (fallbackValue === null) return;

    if (process.env.NODE_ENV === 'development') {
      logger.log(`Translation Key not found: ${key}`);
    } else {
      errorHandler({
        message: null,
        rollbarMessage: `Key not found: ${key}`,
      })();
    }
  },
});
