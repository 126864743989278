/** @prettier */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'underscore';
import { presets } from './buttonPresets';
import * as Icon from './Icon';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import '../../flux/stores/request';
import { contains, once } from 'underscore';

const errorHandler = RequestErrorHandler('button');

const PropTypes = require('prop-types');

const logWrongPreset = once((presetName) =>
  errorHandler({ message: null, severity: 'warn' })(
    new Error(`Call to missing preset "${presetName}", defaulting to green`),
  ),
);

const sizeProps = {
  normal: {
    padding: 'pb-2 pt-1.5 px-4',
    iconSize: 15,
    fontFamily: 'font-semibold',
  },
  pill: {
    padding: 'pv1 ph3 br4',
    fontSize: 'f5',
    iconSize: 15,
  },
  small: {
    padding: 'pb-2 pt-1.5 px-4',
    iconSize: 15,
  },
  smallIcon: {
    padding: 'pa8px lh-solid',
    iconSize: 15,
  },
  medium: {
    padding: 'pb-2 pt-1.5 px-4',
    iconSize: 18,
  },
  slim: {
    padding: 'ph3 pv2 lh-copy',
    iconSize: 17,
  },
  larger: {
    padding: 'ph4 pv3',
    fontSize: 'f4',
    iconSize: 24,
  },
  iconButton: {
    padding: 'w42px h42px pa2',
    fontSize: 'f4',
    iconSize: 30,
  },
  menu: {
    padding: 'ph16px pv13px',
    fontSize: 'f4',
    iconSize: 30,
  },
  smallMenu: {
    padding: 'pa1',
    fontSize: 'f4',
    iconSize: 30,
    // These icons are actually 20px, but setting that
    // variable leads to unexpected results
  },
  smallMenuOff: {
    padding: 'pa1',
    fontSize: 'f4',
    iconSize: 30,
    opacity: 'o-50',
    // These icons are actually 20px, but setting that
    // variable leads to unexpected results
  },
  frameFocusTopBar: {
    padding: 'pr3 pl3 pv2 lh-solid',
    iconSize: 30,
  },
  largest: {
    padding: 'ph5 pv20px',
    fontSize: 'f4',
    iconSize: 30,
  },
} as const;

const sizes = Object.keys(sizeProps);

type sizes = keyof typeof sizeProps;
interface IProps {
  preset: keyof typeof presets;
  size?: sizes;
  text: string;
  type: 'submit' | 'button';
  onClick?: (e: React.MouseEvent<HTMLElement>) => any;
  /**
   * Used instead of onClick, will convert the button to an <a/> tag,
   * better for usability, as it will allow things like ⌘+click
   */
  href?: string;
  hasShadow?: boolean;
  /**
   * By setting the `iconSourceSize`, you can tell the app what the original
   * size of the icon file is, so it can be resized properly if necessary
   * @default 30
   */
  iconSourceSize?: number;
  /** Disable all padding on this button, useful for transparent buttons */
  noPadding?: boolean;
  disabled?: boolean;
  /**
   * Show a loading state (moving dots after a click,
   * controlled by RequestStore.working)
   */
  dotsOnClick?: boolean;
  iconOnly?: boolean;
  icon?: string;
  brDirection?: 'top' | 'right' | 'bottom' | 'left';
  labelLocation?: 'top' | 'right' | 'bottom' | 'left';
  fontFamily?: 'matter' | string;
  width?: 'w-100' | string;
  iconLocation?: 'right' | 'left';
  /**
   * HTML name attribute, useful when using the same click handler between
   * multiple buttons
   */
  name?: string;
  /** Used in combination with the href property, will determine the filename */
  download?: string;
  target?: string;
  id?: string;
  badgeColor?: string;
  title?: string;
}

interface IState {
  textOverride?: string;
  clicked: boolean;
  styleOverride?: React.CSSProperties;
}

export class Button extends React.PureComponent<IProps, IState> {
  static propTypes = {
    preset: PropTypes.oneOf(Object.keys(presets)).isRequired,
    size: PropTypes.oneOf(sizes).isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    size: 'normal',
    hasShadow: false,
    type: 'submit',
    dotsOnClick: false,
    disabled: false,
    labelLocation: 'bottom',
    iconSourceSize: 30,
    iconLocation: 'right',
  };

  interval: any;

  constructor(props) {
    super(props);
    this.state = { clicked: false };
  }

  tick = () => {
    const newtext =
      this.state.textOverride === '....'
        ? '.'
        : (this.state.textOverride || '') + '.';

    this.setState({
      textOverride: newtext,
      clicked: true,
    });
  };

  componentDidMount = () => {
    if (typeof RequestStore !== 'undefined') {
      RequestStore.listen(this.handleRequestChange);
    }
  };

  handleRequestChange = (state) => {
    if (!state.working) {
      clearInterval(this.interval);
      this.setState({
        textOverride: undefined,
        clicked: false,
      });
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
    RequestStore.unlisten(this.handleRequestChange);
  };

  handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.state.clicked && !this.props.disabled) {
      if (this.props.dotsOnClick) {
        this.setState(
          {
            textOverride: undefined,
            clicked: true,
            styleOverride: {
              width: (
                ReactDOM.findDOMNode(this) as Element
              ).getBoundingClientRect().width,
            },
          },
          () => (this.interval = setInterval(this.tick, 100)),
        );
      }
      // If there's an onclick event attached, fire it here. Otherwise, it's
      // probably a form submit.
      if (typeof this.props.onClick !== 'undefined') {
        this.props.onClick(e);
      }
    } else {
      e.preventDefault();
    }
  };

  render() {
    const { size, hasShadow, ...rest } = this.props;

    let preset: any = presets[this.props.preset || 'green'];
    if (!preset) {
      logWrongPreset(this.props.preset);
      preset = presets.green;
    }

    const TagName: any = this.props.href ? 'a' : 'button';
    const sizePropsForSize = (sizeProps[size || 'normal'] ??
      sizeProps['normal']) as any;
    let backgroundColors = preset.backgroundColors;
    let spanStyle;

    const buttonClass = [
      'bg-animate rounded-lg font-semibold w-100 input-reset tc ',
      this.props.brDirection ? 'br--' + this.props.brDirection : '',
      backgroundColors,
      sizePropsForSize.fontSize || 'f5',
      sizePropsForSize.opacity || '',
      sizePropsForSize.fontFamily || 'avenir-heavy',
      this.props.noPadding ? 'pa0' : sizePropsForSize.padding,
      this.props.brDirection === 'left' && this.props.preset === 'darkGreyTab'
        ? ' pl4 '
        : '',
      this.props.brDirection === 'right' && this.props.preset === 'darkGreyTab'
        ? ' pr4 '
        : '',
      this.state.clicked ? 'is--clicked' : '',
      this.props.disabled
        ? (this.props.preset === 'darkGreyTab'
            ? ' pointer '
            : ' cursor-not-allowed ') + (preset.disabledStyle || 'o-70')
        : '',
    ];

    const iconRight = this.props.iconLocation === 'right';

    let textMargin = '';
    if (this.props.icon) {
      textMargin = iconRight ? 'mr2' : 'ml2';
    } else if (this.props.size === 'menu') {
      // Ensure buttons without an icon are the same height as those which do
      spanStyle = { lineHeight: sizePropsForSize.iconSize + 'px' };
    }

    let iconClasses = '';
    if (
      (this.props.iconOnly && sizePropsForSize.padding) ||
      sizePropsForSize.iconSize > this.props.iconSourceSize!
    )
      iconClasses += ` flex items-center justify-center`;

    if (this.props.href) {
      buttonClass.push('db flex items-center');
    } else if (this.props.icon) {
      buttonClass.push(
        ...[
          'flex',
          'items-center',
          iconRight ? 'justify-between' : 'flex-row-reverse justify-end',
        ],
      );
    }

    const hint =
      this.props['aria-label'] ??
      (this.props.iconOnly ? this.props.text : undefined);

    let outerClass = hint ? `hint--${this.props.labelLocation}` : '';

    if (hint?.length > 30) outerClass += ' hint--multiline';

    let badgeClass = `br-100 absolute top-0 right-0 bg-${this.props.badgeColor}`;

    badgeClass += contains(['small', 'medium'], this.props.size)
      ? ' w7px h7px mt2px mr2px'
      : ' w10px h10px mt5px mr5px';

    return (
      <div
        className={`${
          this.props.width || 'inline-block w-auto'
        } ${outerClass} shrink-0`}
        aria-label={hint}
      >
        <TagName
          id={this.props.id}
          type={TagName === 'button' ? this.props.type : undefined}
          onClick={this.handleClick}
          className={buttonClass.join(' ')}
          ref="button"
          name={this.props.name}
          style={this.state.styleOverride}
          download={this.props.download}
          href={this.props.href}
          target={this.props.target}
          title={this.props.title}
        >
          {!this.props.iconOnly ? (
            <span
              className={`${iconClasses} pointer-events-none ${textMargin} ${
                this.props.width || ''
              }`}
              style={spanStyle}
            >
              {this.state.textOverride || this.props.text}
            </span>
          ) : null}

          {this.props.icon ? (
            <Icon
              icon={this.props.icon}
              width={sizePropsForSize.iconSize || 30}
              sourceSize={this.props.iconSourceSize}
              className={iconClasses}
            />
          ) : null}
          {this.props.badgeColor ? <div className={badgeClass} /> : null}
        </TagName>
      </div>
    );
  }
}
