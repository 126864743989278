/** @prettier */
import { Monitor } from './Monitor';
import { idleTimeout } from '../helpers/idle-timeout';

export class UnsupportedExtensionMonitor extends Monitor<{
  extensionName: string;
  url?: string;
} | null> {
  init() {
    idleTimeout(() => {
      let extensionName: string | undefined;
      let url: string | undefined;
      const node = document.createElement('div');
      const innerNode = document.createElement('div');
      innerNode.className = 'fixed';

      // "I don't care about cookies" test
      node.appendChild(innerNode);
      document.body.appendChild(node);

      if (window.getComputedStyle(innerNode, null).display === 'none') {
        extensionName = "I don't care about cookies";
        url = 'https://www.i-dont-care-about-cookies.eu';
      }

      document.body.removeChild(node);
      this.notifyListeners(extensionName ? { extensionName, url } : null);
    });
  }
}
