/** @prettier */
import * as React from 'react';
import * as qs from 'querystringify';
import type { ContentfulImageFile } from '../../flux/stores/testimonial';

interface TransformationProps {
  quality?: number;
  isSquare?: boolean;
  format?: 'jpg' | 'webp' | 'png' | 'png8';
  width?: number;
}

interface Props extends TransformationProps {
  file: ContentfulImageFile;
  /** Constrains the image width to this using a Contentful transformation */
  imgWidth?: number;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}

/**
 * URL parameters for Contentful transformations
 * @see https://www.contentful.com/developers/docs/references/images-api/
 */
interface Transformations {
  w?: number;
  q?: number;
  h?: number;
  fit?: string;
  fm?: string;
  fl?: 'png8';
}

const transformSrc = (src, props: TransformationProps) => {
  const { quality, format, width } = props;
  const transformations: Transformations = {};

  if (!quality && src.match(/.jpg&/)) transformations.q = 90;
  if (format) transformations.fm = format;
  if (format && format === 'png8') transformations.fl = 'png8';

  if (width) {
    transformations.w = width;
    if (props.isSquare) {
      transformations.h = width;
      transformations.fit = 'fill';
    }
  }

  return src + '?' + qs.stringify(transformations);
};

export const ContentfulPicture = React.memo((props: Props) => {
  const { alt, style = { maxWidth: props.imgWidth } } = props;
  const src = props.file.url;
  const isJPG = src.match(/.jpg&/);

  return (
    <picture>
      {!isJPG ? (
        <source
          srcSet={transformSrc(src, {
            width: props.imgWidth,
            format: 'webp',
            quality: props.quality,
          })}
          type="image/webp"
          sizes="100vw"
        />
      ) : null}
      <img
        className={props.className}
        decoding="async"
        src={transformSrc(src, {
          width: props.imgWidth,
          quality: props.quality,
        })}
        alt={alt || ''}
        width={props.file.details.image.width}
        height={props.file.details.image.height}
        style={style}
      />
    </picture>
  );
});

ContentfulPicture.displayName = 'ContentfulPicture';
