/** @format */

import React from 'react';
import { PlusIcon } from 'lucide-react';
import Tooltip from '../feedback/tooltip/Tooltip';
import classNames from 'classnames';
import {
  type ProjectGroup,
  ProjectsContext,
  type Project,
} from './ProjectsContext';

interface ProjectsPlusButtonProps {
  project?: Project;
  group: ProjectGroup;
}

export const ProjectsPlusButton: React.FC<ProjectsPlusButtonProps> = ({
  project,
  group,
}) => {
  const { setIsNewProjectModalOpen } = React.useContext(ProjectsContext);

  const canCreateProject = group.actions.includes('projects.create');

  if (!canCreateProject) {
    return null;
  }

  return (
    <Tooltip
      title={project ? `Create a new sub-project` : `Create a new project`}
      placement="right"
    >
      <div
        className={classNames(
          'cursor-pointer px-0.5 py-0.5 rounded-sm hover:bg-surface-add_frame',
        )}
        onClick={() => setIsNewProjectModalOpen(true)}
      >
        <PlusIcon className="w-4 h-4" />
      </div>
    </Tooltip>
  );
};
