/** @prettier */
import { tourEvents, TourBlueprint } from './tourData';
import { fireConfetti } from '../helpers/confetti';

/**
 * A simple array of the names of all the steps that we have, this is
 * mostly in order to generate a type for all these steps. The `as const` is
 * important for that. We then use the steps in the object below.
 */
// prettier-ignore
const exportTourSteps = ['frameOverview', 'openAnimaticTool', 'pressPlay', 'closeAnimatic', 'openShare'] as const;

export type exportTourAvailableSteps = typeof exportTourSteps[number];

export const exportTour: TourBlueprint<exportTourAvailableSteps> = {
  name: 'export',
  steps: [
    {
      name: 'frameOverview',
      header: 'Welcome to your first storyboard!',
      body: `Storyboards are made up of frames, where you can add images, document direction, voicover, and add custom fields for your project`,
    },
    {
      name: 'openAnimaticTool',
      waitsOn: tourEvents.openAnimatic,
      header: 'Automatic animatics',
      body: `Boords automatically creates animatics from your storyboards, where you can adjust frame timing, add sound and subtitles to show as close to the finished product as possible. Click here to see it in action...`,
    },
    {
      name: 'pressPlay',
      waitsOn: tourEvents.animaticPlay,
      header: 'Voila!',
      body: `Press play to see your animatic in action...`,
    },
    {
      name: 'closeAnimatic',
      waitsOn: tourEvents.closeAnimatic,
      body: `Nice! Now, click here to return to the grid view...`,
    },
    {
      name: 'openShare',
      waitsOn: tourEvents.openShare,
      header: 'Secure Sharing',
      body: `Boords is built for modern video teams who need to collaborate with teammates and clients for faster sign-off. Click here to learn how to collaborate on your storyboards.`,
    },
  ],
};
