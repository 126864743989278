/** @prettier */
import { contains, isUndefined } from 'underscore';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTargetSpec } from 'react-dnd';
import { fileDropResult } from './dragAndDropUtils';
import { acceptedFrameFileTypes } from '../../flux/stores/frame';

/**
 * canDrop handler for react-dnd which returns true if the file matches one of
 * the files Boords accepts */
export const canDropImages = (
  /** Does this dropzone accept items other than native Files (like react-dnd
   * types) */
  acceptsNonFiles: boolean,
): DropTargetSpec<any>['canDrop'] => (props, monitor) => {
  if (monitor.getItemType() === NativeTypes.FILE) {
    const item: fileDropResult = monitor.getItem();
    if (!item.items) return false;

    const type = item.items[0]?.type.toLowerCase();

    // Not all browsers provide any information about the dropped files until
    // they are dropped, so if we don't have info on the file, we just tell it
    // we're good to go for now
    return isUndefined(type) || contains(acceptedFrameFileTypes, type);
  } else {
    return acceptsNonFiles;
  }
};
