/** @prettier */

import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';
import { type GeneratorGuideline, GeneratorContext } from '../GeneratorContext';
import { GuidelineOptionCharacter } from './GuidelineOptionCharacter';

interface GuidelineCharacterGroupProps {
  title: string;
  filter: (guideline: GeneratorGuideline) => boolean;
  additionalComponents?: React.ReactNode;
}

export const GuidelineCharacterGroup: React.FC<
  GuidelineCharacterGroupProps
> = ({ title, filter, additionalComponents }) => {
  const { filteredCharacters, isPanelbar } = React.useContext(GeneratorContext);

  return !filteredCharacters ||
    filteredCharacters.filter(filter).length === 0 ? null : (
    <>
      <div className="mb-2 text-sm font-semibold">{title}</div>
      <div
        className={classNames(
          'grid mb-4 ',
          isPanelbar ? 'gap-3 grid-cols-2' : 'gap-3 grid-cols-3',
        )}
      >
        {additionalComponents && <>{additionalComponents}</>}
        {filteredCharacters
          .filter(filter)
          .sort((a, b) => {
            // Convert age to number, default to 0 if undefined
            const ageA = a.positive?.age ? parseInt(a.positive.age) : 0;
            const ageB = b.positive?.age ? parseInt(b.positive.age) : 0;

            // First, sort by age in descending order
            if (ageB !== ageA) {
              return ageB - ageA;
            }

            // If ages are equal, sort by name in ascending order
            const nameA = a.name?.toLowerCase() || '';
            const nameB = b.name?.toLowerCase() || '';

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // Return 0 if both age and name are equal
            return 0;
          })
          .map((guideline) => (
            <Listbox.Option value={guideline.id} key={`style-${guideline.id}`}>
              <GuidelineOptionCharacter character={guideline} />
            </Listbox.Option>
          ))}
      </div>
    </>
  );
};
