/** @format */

import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Button from '../button/Button';
import * as navigateToRoute from 'javascripts/helpers/router/navigate-to-route.js';
import { LoadingIndicator } from '../common/loading-indicator/LoadingIndicator';
import { AiHelpDocLink } from './AiPrompt';

interface AiWorkingProps {
  id: number;
  onCancel?: () => void;
}

export const AiWorking: React.FC<AiWorkingProps> = ({ id, onCancel }) => {
  const { t } = useTranslation();
  const [aiScriptGenerating, setAiScriptGenerating] = useState(true);
  const [error, setError] = useState('');

  const loadingStatuses: string[] = t('ai.loadingStatuses', {
    returnObjects: true,
  });

  const [loadingStatus, setLoadingStatus] = useState<string>(
    loadingStatuses[0] as string,
  );
  const [loadingStatusIndex, setLoadingStatusIndex] = useState<number>(0);

  useEffect(() => {
    const checkAiScriptGenerating = async () => {
      const response = await fetch(`/api/storyboards/${id}`, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'X-API-KEY': BoordsConfig.AuthenticationToken,
        },
      });

      if (response.ok) {
        const res = await response.json();

        const { slug, ai_script_generating, ai_script_error } =
          res.data[0].attributes;

        if (!ai_script_generating) {
          setAiScriptGenerating(false);
          if (ai_script_error.length < 1) {
            setLoadingStatus(t('ai.redirecting'));
            navigateToRoute('storyboard.show', { slug: slug }, true);
            window.location.reload();
          } else {
            setError(ai_script_error);
          }
        }
      } else {
        setError(t('ai.error.title'));
        setAiScriptGenerating(false);
      }
    };

    if (aiScriptGenerating) {
      const interval = setInterval(() => {
        checkAiScriptGenerating();
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [aiScriptGenerating, id]);

  useEffect(() => {
    if (loadingStatuses) {
      const interval = setInterval(() => {
        if (loadingStatusIndex < loadingStatuses.length - 1) {
          setLoadingStatusIndex(loadingStatusIndex + 1);
        }
        setLoadingStatus(loadingStatuses[loadingStatusIndex]);
      }, 2000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [loadingStatusIndex, loadingStatuses]);

  const Cancel = async () => {
    if (onCancel) {
      onCancel();
    }
    await fetch(`/api/storyboards/${id}/ai_scripts/cancel`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-KEY': BoordsConfig.AuthenticationToken,
      },
    });
  };

  return (
    <div className="flex flex-col pt-8 pb-4 space-y-10">
      {error ? (
        <div className="text-center">
          <div className="px-16 mb-8 text-lg">{error}</div>
          <AiHelpDocLink cta={t('ai.error.helpDocCta')} />
        </div>
      ) : (
        <LoadingIndicator text={loadingStatus} />
      )}
      <div className="flex justify-center">
        <Button
          type="secondary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Cancel();
          }}
        >
          {error ? t('ai.back') : t('ai.cancel')}
        </Button>
      </div>
    </div>
  );
};
