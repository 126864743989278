/** @prettier */
import React from 'react';
import { clampNumber } from '../../../javascripts/helpers/clampNumber';
import { getRefElement } from './getRefElement';

type changeCallback = () => void;
type commitCallback = (e: KeyboardEvent) => void;

interface Return {
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  ref: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  onCurrentIndexChange: (func: changeCallback) => void;
  onCommit: (func: commitCallback) => void;
}

/** Hook that attaches to an input field and allows the user to use the arrow
 * keys to navigate a list of items (for example in searchable dropdowns) */
export const useManageIndex = (
  maxItemCount = 0,
  initialRef?: HTMLElement | null,
): Return => {
  const [ref, setRef] = React.useState<HTMLElement | null | undefined>(
    initialRef,
  );
  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const enterCallbackRef = React.useRef<commitCallback>();
  const callbackRef = React.useRef<changeCallback>();

  const setEnterCallbackRef = (func: commitCallback) => {
    enterCallbackRef.current = func;
  };

  const setCallbackRef = (func: changeCallback) => {
    callbackRef.current = func;
  };

  const modifyIndex = React.useCallback(
    (change: number) => {
      setCurrentIndex((ci) => {
        const newIndex = clampNumber(ci + change, 0, maxItemCount - 1);
        if (newIndex !== ci) {
          callbackRef.current?.();
        }
        return newIndex;
      });
    },
    [maxItemCount, setCurrentIndex],
  );

  React.useEffect(() => {
    setCurrentIndex((ci) => {
      return clampNumber(ci, 0, maxItemCount - 1);
    });
  }, [maxItemCount]);

  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
          enterCallbackRef.current?.(event);
          break;

        case 'ArrowUp':
          modifyIndex(-1);
          return;

        case 'ArrowDown':
          modifyIndex(+1);
          return;
      }
    },
    [modifyIndex],
  );

  React.useLayoutEffect(() => {
    const currentRef = getRefElement(ref);
    currentRef?.addEventListener('keydown', handleKeyDown);

    return () => currentRef?.removeEventListener('keydown', handleKeyDown);
  }, [ref, handleKeyDown]);

  React.useEffect(() => {
    setRef(initialRef);
  }, [initialRef, setRef]);

  return {
    currentIndex,
    setCurrentIndex,
    ref: setRef,
    /**
     * this way of setting callbacks is a bit odd, but it prevents rerenders
     * when the callbacks change. They only really need to listen to the last
     * version
     */
    onCommit: setEnterCallbackRef,
    onCurrentIndexChange: setCallbackRef,
  };
};
