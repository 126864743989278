/** @prettier */
import { flatten } from 'underscore';
import * as React from 'react';
import Container from './Container';
import type { FileUploadStore } from '../../flux/stores/file_upload';
import Button from 'blackbird/components/button/Button';
import { FileUploadActions } from '../../flux/actions/file_upload';
import Dialog from 'blackbird/components/dialog/Dialog';
import Toast from 'blackbird/components/feedback/toast/Toast';

class FileUploadPicker extends React.PureComponent<FileUploadStore> {
  componentDidMount() {
    window.addEventListener('keyup', this.onKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.onKeyPress);
  }

  onKeyPress = (e: KeyboardEvent) => {
    if (e.key !== 'Escape') return;
    e.preventDefault();
    FileUploadActions.closePicker();
  };

  render() {
    return this.props.pickerState !== 'hidden' ? (
      <FileUploadPickerContents {...(this.props as any)} />
    ) : null;
  }
}

/**
 * The UI for the picker. Receives data through the `FileUploadStore`.
 * This component expects a `pickerCallback` function which will be called
 * when uploading is finished. This is automatically set when calling
 * `FileUploadActions.openPicker`
 */
export const FileUploadPickerContents = (props: FileUploadStore) => {
  const handleCancel = () => {
    if (props.pickerState !== 'uploading') {
      FileUploadActions.closePicker();
    }
  };

  const multiFile = props.pickerMaxFiles > 1;

  let accept;
  if (props.pickerOptions && props.pickerOptions.accept) {
    accept = flatten([props.pickerOptions.accept]).join(',');
  }

  const onDrop = (files) => {
    FileUploadActions.pickerStartUpload({
      team_id: props.team_id,
      files: files.slice(0, props.pickerMaxFiles),
      callback: props.pickerCallback!,
      maxFiles: props.pickerMaxFiles,
    });
  };

  return props.pickerState === 'uploading' ? (
    <Dialog
      isOpen
      isDark
      hideActions
      title="Uploading"
      onCancel={handleCancel}
      hideCancel
    >
      <div className="border-brand-yellow mt-4 w-full rounded-sm overflow-hidden bg-brand-yellow-25">
        <div
          className="bg-brand-yellow h-12 transition-[width]"
          style={{ width: `${props.pickerProgress * 100}%` }}
        />
      </div>
    </Dialog>
  ) : (
    <Dialog
      isOpen
      isDark
      hideCancel
      hideActions
      title={multiFile ? 'Upload files' : 'Upload a file'}
      onCancel={handleCancel}
    >
      {props.errorMessage && props.pickerState === 'error' ? (
        <Toast message={props.errorMessage} kind="error" />
      ) : null}

      <Dropzone
        className={'p-10 rounded-md border-2 border-transparent text-center'}
        activeClassName="border-brand-blue"
        rejectClassName="border-form-error"
        multiple={multiFile}
        accept={accept}
        onDrop={onDrop}
      >
        <div className="text-type-subdued">
          Drop files here, or choose files from your computer.
        </div>

        <img
          className="w-auto h-24 my-10 mx-auto"
          src={BoordsConfig.DroppyHandyGreyUrl}
        />

        <Button onClick={() => {}} type="solid" size="lg">
          {multiFile ? 'Choose files' : 'Choose file'}
        </Button>
      </Dropzone>
    </Dialog>
  );
};

(window as any).FileUploadPickerContainer =
  Container('FileUpload')(FileUploadPicker);
