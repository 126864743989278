import React from 'react'
const PropTypes = require('prop-types');
const Badge = require('../../../blackbird/components/badge/Badge.tsx').default

const PlanTag = (props) => {

  let tagText = props.skipParse ? props.planName :  window.PlanNameHelper.slugToType(props.planName);
  let badgeColor = 'blue'
  let tagLabel = "";

  if (props.planName === "Professional Trial" && props.status === 'canceled') {
    // tagText = props.planName

  } else if (props.planName === "Workflow") {
    badgeColor = 'pinkBorder'
  } else if (props.is_free && tagText !== 'free') {
    tagLabel = `${tagText} expired`;
    badgeColor = 'white'
    tagText = 'Free';
  } else if (props.status === 'canceled') {
    tagLabel = `${tagText} canceling`;
    badgeColor = 'white'
  } else if (tagText === "free") {
    badgeColor = 'white'
  } else if (tagText === "team" || tagText === "Team Admin") {
    badgeColor = 'blue'
  } else {
    badgeColor = 'blue'
  }


  return (
    <div className={'capitalize ' + (tagLabel.length > 0 ? ' hint--right' : '') + (props.open_billing_on_click ? ' pointer' : '')} aria-label={tagLabel} onClick={() => props.open_billing_on_click ? window.location = '/billing' : ''}>
      <Badge label={tagText} color={badgeColor} />
    </div>
  )
}

PlanTag.defaultProps = {
  is_free: false,
  status: 'active',
  open_billing_on_click: false
}

PlanTag.propTypes = {
  planName: PropTypes.string.isRequired,
  open_billing_on_click: PropTypes.bool,
  skipParse: PropTypes.bool,
  is_free: PropTypes.bool,
  status: PropTypes.string,
}

window.PlanTag = PlanTag
