/** @prettier */
import { PanelbarStore } from '../stores/panelbar';
import { createTypedActions } from '../stores/AltStore';

class PanelbarActions {
  init: PanelbarStore['handleInit'] = function () {
    this.dispatch();
  };
  open: PanelbarStore['handleOpen'] = function (options) {
    this.dispatch(options);
  };
  setSubPanel: PanelbarStore['handleSetSubPanel'] = function (options) {
    this.dispatch(options);
  };
  close: PanelbarStore['handleClose'] = function () {
    this.dispatch();
  };
  toggle: PanelbarStore['handleToggle'] = function (options) {
    this.dispatch(options);
  };
}

const actions = createTypedActions<PanelbarActions>(PanelbarActions);
export { actions as PanelbarActions };

(window as any).PanelbarActions = actions;
