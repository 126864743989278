/** @prettier */
require('../../helpers/frame-size-helper');
require('../../helpers/local-state');
require('../../flux/actions/frame_focus');
import * as cloudinary from '../../helpers/cloudinary';
import * as navigateToRoute from '../../helpers/router/navigate-to-route';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { FakeAltStoreClass } from './AltStore';
import { detect } from 'detect-browser';
import { FrameActions } from '../actions/frame';
import type {
  IStoryboard,
  IShareableStoryboard,
  IStoryboardPreferences,
} from '../../types/storyboard';
import type { IFrame } from '../../types/frame';
import { PlayerActions } from '../actions/player';
import { rollbar } from '../../helpers/rollbar';
import { ToursActions, tourEvents } from '../actions/tours';
import { LocalState } from '../../helpers/local-state';
import { ensureNumber } from '../../helpers/ensure-number';
import logger from 'javascripts/helpers/logger';
import { RequestActions } from '../actions/request';
import { maxImageSizeInMB } from 'javascripts/helpers/constants';
import { ajax, ajaxRuby } from 'javascripts/helpers/ajax';
import { StoryboardAnalysisActions } from '../actions/storyboardAnalysis';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { fileTypeListToString } from 'javascripts/helpers/fileTypeListToString';
import { isFileTypeAccepted } from 'javascripts/helpers/isFileTypeAccepted';
import { StoryboardActions } from '../actions/storyboard';
import {
  findIndex,
  includes,
  isEmpty,
  isNumber,
  last,
  now,
  reduce,
  values,
} from 'underscore';
import { eventSource } from 'blackbird/helpers/eventContextHelper';
const errorHandler = RequestErrorHandler('player');
const LS_ZOOM_KEY = 'playerZoom';

const browser = detect();

const soundManagerScript =
  'https://cdn.jsdelivr.net/npm/soundmanager2@2.97.20170602/script/soundmanager2.min.js';
const soundManagerSwf =
  'https://cdn.jsdelivr.net/npm/soundmanager2@2.97.20170602/swf';
let soundManagerLoaded = false;

const allowedAudioStates = [
  'unfetched',
  'fetching',
  'buffering',
  'ready',
  'working',
  'uploading',
  'done',
  'processing',
] as const;

export interface DurationOptions {
  max: number;
  min: number;
  stepAmount: number;
  shiftStepAmount: number;
  storyboardMin: number;
  storyboardMax: number;
}

export interface CloudinaryAudioInfo {
  /** Duration in seconds */
  duration: number;
  file_name: string;
  is_processing: boolean;
  public_id: string;
  url: string;
  waveform: string;
}

export interface PlayerAudio {
  state: typeof allowedAudioStates[number];
  data: CloudinaryAudioInfo | null;
  /** SoundManager instance */
  track: any | null;
}

const isShareableStoryboard = (
  storyboard: IStoryboard | IShareableStoryboard | null,
): storyboard is IShareableStoryboard =>
  storyboard && Object.prototype.hasOwnProperty.call(storyboard, 'permaslug');

const isTouchDevice = (): boolean =>
  browser!.os === 'iOS' || BoordsConfig.isTouchDevice;

function resetState(state: PlayerStore) {
  state.currentFrameId = null;
  state.currentFrameIndex = null;
  state.currentFrame = null;
  state.isPlaying = false;
  state.isLooping = false;
  // We keep this in the store so the shareable view also has access to this.
  // This should just be on the storyboard, but that's the legacy
  state.isSubtitlesEnabled = false;
  state.endTime = 0;
  state.lockTimings = true;
  state.frames = [];
  state.framesToUpdate = {};
  state.frameTimes = {};
  state.storyboard = null;
  state.time = 0;
  state.isEditable = false;
  state.isWaitingOnAudio = false;
  state.initialized = false;
  state.frameDurationMismatch = false; // see testForDurationMismatch()
  state.timelineZoom = LocalState.getValue(LS_ZOOM_KEY) || 0;

  if (state?.audio?.track) {
    state.audio.track.destruct();
  }

  state.audio = {
    track: null,
    data: null,
    state: 'unfetched', // See allowedAudioStates
  };
}

function getFrameIndex(frame: IFrame, storyboard: PlayerStore['storyboard']) {
  if (frame.sort_order) {
    return frame.sort_order - 1;
  } else if (isShareableStoryboard(storyboard)) {
    const found = findIndex(storyboard.frames, (f) => f === frame);
    if (found >= 0) {
      return found;
    } else {
      throw new Error('frame not found in storyboard, cannot determine time');
    }
  } else {
    throw new Error('frame not found in storyboard, cannot determine time');
  }
}

function getFrameForTime(time: number, store: PlayerStore) {
  // Find the frame that's the last one with a starting time lower than the
  // current time. (starting times are precalculated in this.frametimes)
  const frame = reduce(
    store.frames,
    (o, frame) => {
      const frameTime = store.frameTimes[frame.id];

      if (!o || time >= frameTime) {
        return frame;
      }

      return o;
    },
    null,
  );

  if (!frame) {
    throw new Error('No frame found for' + time);
  }
  return frame;
}

function getFrameTimes({ frames }: PlayerStore) {
  let cursor = 0;
  return reduce(
    frames,
    (frameTimes, frame) => {
      frameTimes[frame.id] = cursor;
      cursor += frame.duration || 2000;
      return frameTimes;
    },
    {},
  );
}

function getEndTime(store: PlayerStore, fromFrames = false) {
  if (store.audio.data && store.audio.data.duration && !fromFrames) {
    return store.audio.data.duration * 1000;
  } else if (store.frames.length > 0) {
    const lastFrameId = last(store.frames)!.id;
    return store.frameTimes[lastFrameId] + last(store.frames)!.duration;
  }

  return 0;
}

function updateTimes(store: PlayerStore) {
  store.frameTimes = getFrameTimes(store);
  store.endTime = getEndTime(store);
}

function changeFrame(frame: IFrame, store: PlayerStore) {
  if (!store.storyboard) throw new Error('missing storyboard');
  const frameCountChanged =
    store.frames.length !== FrameStore.getState().frames.length;
  store.frames = FrameStore.getState().frames;
  store.currentFrameIndex = getFrameIndex(frame, store.storyboard);

  if (!store.frameTimes || isEmpty(store.frameTimes) || frameCountChanged) {
    updateTimes(store);
  }

  store.time = store.frameTimes[frame.id];
  store.currentFrameId = frame.id;
  store.currentFrame = frame;
  store.isLooping = store.storyboard.is_player_looping;
  store.isSubtitlesEnabled = store.storyboard.is_player_showing_subtitles;

  if (store.audio.track) store.audio.track.setPosition(store.time);

  navigateToRoute(
    'player',
    {
      slug: store.storyboardSlug,
      frameIndex: store.currentFrameIndex + 1,
    },
    true,
  );

  document.title = `${store.storyboard.document_name} (Animatic) · Boords`;
}
export class PlayerStore extends FakeAltStoreClass<PlayerStore> {
  timelineIsOpen = true;
  initialized = false;
  team = null;
  durationOptions: DurationOptions = {
    max: 200 * 1000,
    min: 80,
    stepAmount: 100,
    shiftStepAmount: 500,
    storyboardMin: 3 * 1000,
    storyboardMax: 500 * 1000,
  };

  isEditable = false;
  frameSize: {
    width: number;
    height: number;
  };

  isLooping = false;

  audio: PlayerAudio;

  time: number;
  endTime: number;
  playStartTime: number | null;
  isWaitingOnAudio: boolean;
  isPlaying = false;
  isSubtitlesEnabled = false;
  sidebarIsOpen = false;
  storyboard: IStoryboard | IShareableStoryboard | null;
  storyboardSlug: string | null = null;
  frames: IFrame[];
  frameTimes: { [id: string]: number };

  currentFrameId: number | null;
  currentFrameIndex: number | null;
  currentFrame: IFrame | null;
  lockTimings = false;
  frameDurationMismatch = false;
  timelineZoom: number;

  framesToUpdate: {
    [frameId: string]: {
      id: number;
      duration: number;
      originalDuration: number;
    };
  };

  AudioTimeout: any;

  constructor() {
    super();
    resetState(this);

    this.bindListeners({
      handleOpen: PlayerActions.OPEN,
      handleClose: PlayerActions.CLOSE,
      handleToggleSidebar: PlayerActions.TOGGLE_SIDEBAR,
      handleToggleTimeline: PlayerActions.TOGGLE_TIMELINE,
      handleGoToFrame: PlayerActions.GO_TO_FRAME,
      handleUpdateTime: PlayerActions.UPDATE_TIME,
      handleTogglePlay: PlayerActions.TOGGLE_PLAY,
      handleToggleLooping: PlayerActions.TOGGLE_LOOPING,
      handleUpdateFrameDuration: FrameActions.UPDATE_DURATION,
      handleBatchUpdateFrameDuration: FrameActions.BATCH_SET,
      handleResetAudio: PlayerActions.RESET_AUDIO,
      handleRemoveAudio: PlayerActions.REMOVE_AUDIO,
      handleUploadAudio: PlayerActions.UPLOAD_AUDIO,
      handleUpdateAudioState: PlayerActions.UPDATE_AUDIO_STATE,
      handleBatchTimingChange: PlayerActions.BATCH_TIMING_CHANGE,
      handleCommitFrameTimings: PlayerActions.COMMIT_FRAME_TIMINGS,
      handleCoverPageUpdate: [CoverpageActions.SAVE, CoverpageActions.RECEIVE],
      spreadFrameDuration: PlayerActions.UPDATE_TOTAL_DURATION,
      handleSetFrameInpoint: PlayerActions.SET_FRAME_INPOINT,
      handleSetFrameOutpoint: PlayerActions.SET_FRAME_OUTPOINT,
      handleAdjustFrameInpoint: PlayerActions.ADJUST_FRAME_INPOINT,
      handleResetFramesDuration: PlayerActions.RESET_FRAMES_DURATION,
      handleSetTimingsLock: PlayerActions.SET_TIMINGS_LOCK,
      handleSpreadDurationToMatch: PlayerActions.SPREAD_DURATION_TO_MATCH,
      handleSetTimelineZoom: PlayerActions.SET_TIMELINE_ZOOM,
      handleFetchAudioFile: PlayerActions.FETCH_AUDIO_FILE,
      handleUpdatePreference: StoryboardActions.UPDATE_PREFERENCE,
    });

    // Should not do anything, other components might be listening and will
    // trigger removing state for the player.
    // this.on('unlisten', () => {});
  }

  handleOpen(options: {
    frame: IFrame;
    isEditable: boolean;
    storyboard: IStoryboard | IShareableStoryboard;
    audio?: PlayerAudio;
    team?: any;
  }) {
    const { frame, storyboard, audio, isEditable, team } = options;

    if (!frame) return;
    if (!storyboard) throw new Error('Needs to have storyboard');

    this.storyboard = storyboard;
    this.storyboardSlug = isShareableStoryboard(storyboard)
      ? storyboard.permaslug
      : storyboard.slug;

    changeFrame(frame, this);
    this.isEditable = isEditable || false;
    this.initialized = true;
    this.frameSize = BoordsFrameSizeHelper(this.storyboard.frame_aspect_ratio);

    if (audio) {
      this.audio.state = audio.state;
      this.saveAudio(audio.data!);
    } else {
      this.fetchAudioFile(this.storyboard.id);
    }

    this.team = team;

    if (!this.team) this.fetchTeamInfo();

    // Force the frameFocus UI to settle on the right frame.
    // This prevents the occasional "jumping back in time"
    FrameFocusActions.setFrame.defer({ frame, persist: false });

    Track.event.defer('Player open', {
      context: eventSource(undefined),
      category: 'Product',
    });
    // Fetch this, else we can't toggle looping :/
    CoverpageActions.fetch.defer(this.storyboard.id);
    // IntercomActions.message.defer({
    //   type: "animatic-screencast",
    // });
  }

  /** used by other parts of the app to get data on the animatic duration. This
   * will always fire the callback */
  handleFetchAudioFile({
    storyboardId,
    callback,
  }: {
    storyboardId: number;
    callback?: (response: CloudinaryAudioInfo | null) => void;
  }) {
    if (!this.audio.data) {
      this.fetchAudioFile(storyboardId, callback);
    } else if (callback) {
      callback(this.audio.data);
    }
  }

  fetchAudioFile(storyboardId: number, callback?) {
    this.updateAudio('state', 'fetching');
    this.emitChange();

    ajaxRuby({
      method: 'get',
      url: '/storyboards/' + storyboardId + '/audio.json',
      success: function (response: CloudinaryAudioInfo) {
        this.saveAudio(response);
        if (callback) callback(response);
      }.bind(this),
      error: function () {
        RequestActions.error.defer({ key: 'player.errors.audio' });
        this.updateAudio('state', 'done');
        this.emitChange();
      }.bind(this),
    });
  }

  saveAudio(response: CloudinaryAudioInfo) {
    this.updateAudio('data', response);
    this.updateAudio('track', null);

    this.loadAudioIfNecessary();
    this.endTime = getEndTime(this);
    this.emitChange();
  }

  updateAudio(key: keyof PlayerAudio, value: unknown) {
    if (key === 'state' && !includes(allowedAudioStates, value)) {
      throw new Error(
        `New state ${value} not recognised, must be one of ${allowedAudioStates.join()}`,
      );
    }

    // Using { [key]: value } here didn't work, even though babel should
    // have transformed it
    this.audio = Object.assign({}, this.audio);
    this.audio[key] = value;

    this.isWaitingOnAudio = includes(
      ['loading', 'fetching', 'buffering', 'processing'],
      this.audio.state,
    );
  }

  handleUpdateAudioState(newState: typeof allowedAudioStates[number]) {
    this.updateAudio('state', newState);
  }

  loadAudioIfNecessary() {
    const store = this;
    const audioFileUrl = this.audio.data && this.audio.data.url;
    if (!audioFileUrl) {
      this.updateAudio('state', 'done');
      this.emitChange();
      return;
    }

    this.updateAudio('state', 'buffering');

    const startLoading = () => {
      // In case the player is already closed
      if (!store.storyboard) return;
      // iOS doesn't fire onload? so we're just gonna pretend it's loaded

      if (isTouchDevice()) this.updateAudio('state', 'done');

      this.updateAudio(
        'track',
        soundManager.createSound({
          id: 'Boord' + store.storyboard.id + 'Voiceover',
          position: store.time,
          autoLoad: true,
          multiShot: false,
          url: audioFileUrl,
          stream: false,
          onload: function () {
            window.setTimeout(() => {
              store.updateAudio('state', 'done');
              store.emitChange();
              store.testForDurationMismatch();
            }, 100);
          },
          onerror: function (errorCode, errorDescription) {
            // http://www.schillmania.com/projects/soundmanager2/doc/#smsound-onerror
            if (errorCode === 4) {
              // MEDIA NOT SUPPORTED
              // We'll report the error, but also remove the audio information
              // So that the animatic still plays, but without sounds.
              // Seems reasonable
              RequestActions.error.defer({
                key: 'player.errors.unsupportedAudio',
              });
              rollbar.error(errorDescription, store.audio.data as any);
              store.updateAudio('data', null);
              store.updateAudio('track', null);
            } else {
              logger.error(errorDescription);
            }

            store.updateAudio('state', 'done');
            store.emitChange();
          },
          onfinish: function () {
            if (store.isLooping) {
              this.setPosition(0).play();
            } else {
              store.setTime(this.duration);
              store.isPlaying = false;
              store.emitChange();
            }
          },
        }),
      );
      this.emitChange();
    };

    // Load SoundManager if necessary
    if (!soundManagerLoaded) {
      loadScript(soundManagerScript, (err) => {
        if (err) throw err;
        soundManagerLoaded = true;
        soundManager.setup({
          url: soundManagerSwf,
          onready: startLoading,
          ontimeout: logger.warn,
          debugMode: false,
        });
      });
    } else {
      startLoading();
    }
  }

  handleResetAudio() {
    this.audio = {
      track: null,
      data: null,
      state: 'unfetched',
    };
  }

  handleRemoveAudio() {
    this.updateAudio('state', 'uploading');
    this.isPlaying = false;
    if (this.audio && this.audio.track) {
      this.audio.track.destruct();
    }
    delete this.audio.track;

    ajax({
      method: 'delete',
      url: '/storyboards/' + this.storyboard!.id + '/audio.json',
      success: () => {
        this.handleResetAudio();
        this.emitChange();
      },
      error: errorHandler({ messageKey: 'player.errors.removeAudio' }, () => {
        this.updateAudio('state', 'done');
      }),
    });
  }

  handleUploadAudio({ file }: { file: File; data: unknown }) {
    // prettier-ignore
    const allowedTypes = ["audio/mp3", "audio/mpeg", "audio/mp4", "audio/vnd.wav", "audio/wav", "audio/x-m4a", "audio/m4a", "audio/aiff", "audio/x-wav", "audio/x-aiff"];
    const maxSizeInMB = 20;

    if (!isFileTypeAccepted(allowedTypes, file.type)) {
      RequestActions.error.defer({
        key: 'sharedErrors.unsupportedUpload',
        data: {
          fileType: file.type,
          formats: fileTypeListToString(allowedTypes),
        },
      });
      rollbar.log(`Prevented upload of unsupported file type ${file.type}`);
      this.updateAudio('state', 'done');
      return;
    } else if (file.size / 1000000 > maxSizeInMB) {
      RequestActions.error.defer({
        key: 'sharedErrors.fileSizeExceeded',
        data: { maxSize: maxImageSizeInMB + 'MB' },
      });
      this.updateAudio('state', 'done');
      return;
    }

    this.updateAudio('state', 'uploading');

    const onError = errorHandler(
      {
        messageKey: 'player.errors.upload',
        rollbarMessage: 'Could not upload audio',
        serviceName: 'Boords or our audio storage provider',
      },
      () => {
        this.updateAudio('state', 'done');
        this.emitChange();
      },
    );

    cloudinary
      .uploadAudio(file)
      .then((cloudinaryResponse) => {
        this.updateAudio('state', 'processing');

        ajax({
          method: 'put',
          url: '/storyboards/' + this.storyboard!.id + '/audio.json',
          data: cloudinaryResponse.data,
          success: (response) => {
            Track.event.defer('Player audio uploaded');
            this.updateAudio('state', 'done');
            this.updateAudio('data', response);
            this.emitChange();
            this.audioStateCheck();
          },
          error: onError,
        });
      }, onError)
      .catch(onError);
  }

  audioStateCheck() {
    if (!this.audio.data) return;
    if (this.audio.data.is_processing) {
      this.AudioTimeout = setTimeout(
        function () {
          this.fetchAudioFile(() => this.audioStateCheck());
        }.bind(this),
        1500,
      );
    } else {
      clearTimeout(this.AudioTimeout);
      RequestActions.success.defer('Audio track uploaded successfully!');
      Track.event.defer('added_audio');
      this.loadAudioIfNecessary();
      this.endTime = getEndTime(this);
      this.spreadFrameDuration(this.audio.data.duration * 1000);
    }
  }

  spreadFrameDuration(newEndTime: number) {
    // Since we probably set the endtime to the audio track duration,
    // we need to retrieve the endtime purely based on frame furations
    const originalEndTime = getEndTime(this, true);

    if (newEndTime === originalEndTime) return;

    const min = this.durationOptions.storyboardMin;
    const max = this.durationOptions.storyboardMax;

    if (newEndTime < min) {
      RequestActions.error.defer(`Minimum storyboard length is ${min / 1000}s`);
      this.endTime = min;
      return;
    }

    if (newEndTime > max) {
      RequestActions.error.defer(`Maximum storyboard length is ${max / 1000}s`);
      this.endTime = max;
      return;
    }

    // update the UI now, so the user can keep typing
    this.endTime = newEndTime;

    if (
      newEndTime / originalEndTime > 800 ||
      newEndTime / originalEndTime < 0.002 ||
      !newEndTime
    ) {
      errorHandler({
        message: null,
        rollbarMessage:
          'Difference between new time and old time is really large, is this what you want?',
        severity: 'warn',
      })({ newEndTime, originalEndTime });
      this.endTime = originalEndTime;
      return;
    }

    this.time = newEndTime * (this.time / originalEndTime);

    const newTimes = this.frames.map((f) => {
      const relativeFrameTime = this.frameTimes[f.id] / originalEndTime;
      return newEndTime * relativeFrameTime;
    });

    this.handleBatchTimingChange(newTimes);
    this.handleCommitFrameTimings();
    StoryboardAnalysisActions.scheduleAnalysis.defer();
  }

  handleClose() {
    resetState(this);
  }

  handleToggleSidebar(newStatus) {
    if (newStatus === true || !this.sidebarIsOpen) {
      this.sidebarIsOpen = true;
    } else {
      this.sidebarIsOpen = false;
    }
  }

  handleGoToFrame(frame: IFrame) {
    changeFrame(frame, this);
    if (this.frames.indexOf(frame) === -1) logger.log('Frame is not the same');
  }

  handleUpdateTime(newTime: number) {
    this.setTime(newTime);
    this.playStartTime = now() - newTime;

    if (this.audio.track) {
      this.audio.track.setPosition(newTime);
    }
  }

  setTime(newTime: number) {
    if (!isNumber(newTime)) {
      throw new Error(`new time is not a number, got ${newTime}`);
    }

    // Make sure that we don't exceed the end time, which can somehow happen
    this.time = Math.min(newTime, this.endTime);

    const frame = getFrameForTime(newTime, this);
    if (frame.id !== this.currentFrameId) {
      FrameFocusActions.setFrame.defer({ frame, persist: false });
    }
    this.currentFrameIndex = getFrameIndex(frame, this.storyboard);
    this.currentFrameId = frame.id;
    this.currentFrame = frame;
  }

  handleToggleTimeline() {
    this.timelineIsOpen = !this.timelineIsOpen;
  }

  playTick(store: PlayerStore) {
    if (!store.isPlaying) {
      store.playStartTime = null;
      if (store.audio.track) {
        store.audio.track.pause();
      }
      return;
    }

    if (store.audio.state === 'buffering') {
      store.setTime(store.audio.track.position);
    } else if (store.playStartTime) {
      const difference = now() - store.playStartTime;
      /**
       * Because all of iOS's weird sound quirks, we can't reliably measure
       * actual time playback started, so the progress might seem unreliable.
       * It's probably better to use the actual audio position, even though
       * that one doesn't update frequently enough for a smooth progress bar
       */
      const useSoundTime = browser!.os === 'iOS' && store.audio.track;
      let newTime = Math.floor(
        useSoundTime ? store.audio.track.position : difference,
      );

      if (newTime >= store.endTime) {
        if (store.isLooping) {
          newTime = newTime % store.endTime;
        } else {
          newTime = store.endTime;
          store.isPlaying = false;
        }
      }

      if (difference > 0) {
        store.setTime(newTime);
        store.emitChange();
      }
    } else {
      logger.log('does this even happen?');
    }

    window.requestAnimationFrame(() => store.playTick(store));
  }

  handleTogglePlay(newState?: boolean) {
    const shouldBePlaying = (this.isPlaying =
      typeof newState === 'undefined' ? !this.isPlaying : newState);

    if (this.isWaitingOnAudio && shouldBePlaying) return;

    const startTiming = () => (this.playStartTime = now() - this.time);

    if (shouldBePlaying) {
      ToursActions.triggerEvent.defer(tourEvents.animaticPlay);
      if (this.time >= this.endTime) {
        this.time = 0;
      }

      if (this.audio.track) {
        let playingHasStarted = false;
        // Because some browsers might be slower in reacting, we need to wait
        // for the first whilePlaying/position event to start the time.
        // There is sadly no way to remove event handlers, so we have to keep
        // state ourselves/.
        const onPlayStart = () => {
          if (!playingHasStarted) {
            playingHasStarted = true;
            this.time = this.audio.track.position;
            startTiming();
            this.playTick(this);
          }
        };

        const restart = () => {
          playingHasStarted = false;
          if (isTouchDevice()) {
            window.setTimeout(onPlayStart, 200);
          }
        };

        this.audio.track.setPosition(this.time);

        if (this.audio.track.playState === 1) restart();

        this.audio.track.play({
          // On mobile it doesn't support whileplaying :(
          onplay: restart,
          whileplaying: onPlayStart,
          onpause: () => this.setTime(this.audio.track.position),
          // It seems onresume also doesn't work as expected on mobile
          onresume: restart,
          onbufferchange: (status) => {
            const isBuffering = status === 1;
            this.updateAudio('state', isBuffering ? 'buffering' : 'done');

            playingHasStarted = false;
            // If we're done buffering for now, set the startTime again
            // so we can calculate the current position accurately again
            if (!isBuffering) onPlayStart();
          },
        });
      } else {
        // We set the playStartTime to now, if there's a player we'll set
        // it onPlay
        startTiming();
        this.playTick(this);
      }
    }
  }

  // Update a single frame's duration in the store (triggered by FrameStore)
  handleUpdateFrameDuration(options: { frameId: number; duration: number }) {
    const { min, max } = this.durationOptions;
    if (this.frames.length === 0) return;

    const frame = this.frames.find((f) => f.id === options.frameId)!;

    // Clamp the value
    const duration = Math.max(min, Math.min(max, options.duration));

    frame.duration = duration;
    updateTimes(this);
  }

  // Update multiple frame's duration in the store (triggered by FrameStore)
  // For example when they get reset after a failed request
  handleBatchUpdateFrameDuration(updates: { id: number; duration: number }[]) {
    updates.forEach((u) =>
      this.handleUpdateFrameDuration({
        frameId: u.id,
        duration: u.duration,
      }),
    );
  }

  handleBatchTimingChange(timings: number[]) {
    if (timings.length !== this.frames.length) {
      throw new Error(
        `amount of frames in timings is not equal as frame count in store, forgot the first frame? received ${timings.length}, timings, but there's ${this.frames.length} frames`,
      );
    }

    this.frames.forEach((frame, i) => {
      const endTime = timings[i + 1] || this.endTime;
      const newDuration = ensureNumber(
        Math.max(endTime - timings[i], this.durationOptions.min),
      );

      if (frame.duration === newDuration) return;
      const existing = this.framesToUpdate[frame.id];
      this.framesToUpdate[frame.id] = {
        id: frame.id,
        duration: newDuration,
        originalDuration: ensureNumber(
          existing?.originalDuration ?? frame.duration,
        ),
      };

      // Apply the changes now
      frame.duration = newDuration;
    });

    updateTimes(this);
    this.setTime(this.time);
  }

  handleCommitFrameTimings() {
    FrameActions.batchUpdateDurations.defer(values(this.framesToUpdate));
    this.framesToUpdate = {};
    Track.event.defer('adjust_duration');
    ToursActions.triggerEvent.defer(tourEvents.animaticAdjustTimeline);
  }

  handleToggleLooping() {
    const newValue = !this.isLooping;
    CoverpageActions.updateValue.defer({
      attr: 'storyboard.is_player_looping',
      value: newValue,
    });

    this.isLooping = newValue;
  }

  handleCoverPageUpdate() {
    const coverpageState = CoverpageStore.getState().cover.storyboard;
    if (!coverpageState) return;
    // The state for these properties doesn't update in all the right
    // places, we can count on the coverpageStore to the right
    // updates during application usage
    const { is_player_looping, is_player_showing_subtitles } = coverpageState;

    this.isLooping = is_player_looping;
    this.isSubtitlesEnabled = is_player_showing_subtitles;
    this.emitChange();
  }

  handleSetFrameInpoint({ frameId, time }: { frameId: number; time: number }) {
    let currentFrameIndex;
    // Make a list of the frame timings, and mark the index of current frame
    const newTimes = this.frames.map((f, i) => {
      if (f.id === frameId) currentFrameIndex = i;
      return this.frameTimes[f.id];
    });

    // Note down the out point, so we can make sure we don't exceed it
    const outPoint = newTimes[currentFrameIndex + 1];
    if (currentFrameIndex === 0 || time > outPoint) return;

    // Update the new inpoint and commit the changes
    newTimes[currentFrameIndex] = time;
    this.handleBatchTimingChange(newTimes);
    this.handleCommitFrameTimings();
  }

  handleSetFrameOutpoint({ frameId, time }: { frameId: number; time: number }) {
    let currentFrameIndex;
    // Make a list of the frame timings, and mark the index of current frame
    const newTimes = this.frames.map((f, i) => {
      if (f.id === frameId) currentFrameIndex = i;
      return this.frameTimes[f.id];
    });

    // Note down the out point, so we can make sure we don't exceed it
    const inPoint = newTimes[currentFrameIndex];
    if (currentFrameIndex === newTimes.length - 1 || time < inPoint) return;

    // Update the new inpoint and commit the changes
    newTimes[currentFrameIndex + 1] = time;
    this.handleBatchTimingChange(newTimes);
    this.handleCommitFrameTimings();
  }

  handleAdjustFrameInpoint({
    frameId,
    offset,
  }: {
    frameId: number;
    offset: number;
  }) {
    const newTime = this.frameTimes[frameId] + offset;
    this.handleSetFrameInpoint({ frameId, time: newTime });
  }

  handleResetFramesDuration() {
    const frameTime = Math.round(this.endTime / this.frames.length);

    const newTimes = this.frames.map((f, i) => Math.floor(frameTime * i));

    this.handleBatchTimingChange(newTimes);
    this.handleCommitFrameTimings();
  }

  handleSetTimingsLock(newValue: boolean) {
    this.lockTimings = newValue;
  }

  // This belongs in the storyboard store, but that one is not avaialable
  // in the sharable layout
  fetchTeamInfo() {
    ajax({
      method: 'get',
      url: '/storyboards/' + this.storyboard!.id + '/access.json',
      success: function (response) {
        this.team = response;
        this.emitChange();
      }.bind(this),
      error: function (response) {
        logger.error(response);
      },
    });
  }

  /**
   * Under certain conditions (like batch uploads), the sum of the durations
   * of all the frames would be different from duration of the audio file.
   * This tests for this mismatch and attempts to fix it automatically.
   */
  testForDurationMismatch() {
    if (!this.audio.track || !this.isEditable) return;

    const soundDuration = this.audio.track.duration;
    const frameDurationSum = getEndTime(this, true);
    const diff = frameDurationSum - soundDuration;

    const logDurationFix = (diff) => {
      logger.log(
        `Animatic length mismatch: auto-adjusted the duration of the last frame by ${
          -diff / 1000
        }s`,
      );
      Track.event.defer('auto_adjust_duration', {
        amount: -diff,
        storyboard: this.storyboard!.id,
      });
    };

    /**
     * For some unknown reasons, the diff isn't entirely fixed after fixing,
     * causing a potential loop of correcting, we supply a small margin (in
     * milliseconds) where we leave the durations unaffected)
     */
    const margin = 100;

    if (diff < -margin) {
      // Somehow the duration of the frames is shorter than the audio
      const target = last(this.frames)!;
      FrameActions.updateDuration({
        frameId: target.id,
        duration: target.duration + Math.abs(diff),
        originalDuration: target.duration,
      });

      logDurationFix(diff);
    } else if (diff > margin) {
      // Somehow the sum of all durations is longer than the current sound!
      const target = last(this.frames)!;

      if (target.duration + this.durationOptions.min > diff) {
        // If the last frame is long enough, we can just change that one
        FrameActions.updateDuration({
          frameId: target.id,
          duration: target.duration - diff,
          originalDuration: target.duration,
        });

        logDurationFix(diff);
      } else {
        /**
         * If we can't take the duration of the last frame, we have to
         * redistribute all the frames, this is a flag that toggles displaying
         * a message in the download dialog
         */
        this.frameDurationMismatch = true;
        Track.event.defer('unfixable_duration_mismatch', {
          storyboard: this.storyboard!.id,
        });
      }

      this.emitChange();
    }
  }

  // Spread out the frames so they match the end time defined by the audio
  handleSpreadDurationToMatch() {
    this.spreadFrameDuration(getEndTime(this));
    this.frameDurationMismatch = false;
    this.testForDurationMismatch();
  }

  handleSetTimelineZoom(newZoom: number) {
    this.timelineZoom = newZoom;
    LocalState.setValue(LS_ZOOM_KEY, newZoom);
  }

  handleUpdatePreference<T>(data: {
    name: keyof IStoryboardPreferences;
    value: any;
  }) {
    if (!this.storyboard || !this.initialized) return;
    this.storyboard.preferences = {
      ...this.storyboard.preferences,
      [data.name]: data.value,
    };
  }
}

(window as any).PlayerStore = alt.createStore(PlayerStore, 'PlayerStore');
