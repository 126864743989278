/** @prettier */
import * as React from 'react';

export const PanelbarAssetListEmptyState: React.FC<{
  title?: string;
  link?: string;
}> = (props) => (
  <>
    <div className="flex flex-col flex-grow text-center gap-5">
      {props.title && (
        <div className="text-lg text-type-primary">{props.title}</div>
      )}

      {props.children}

      {props.link ? (
        <div className="mt-2 text-sm text-type-subdued">
          <a
            href={props.link}
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {`Learn more`}
          </a>
        </div>
      ) : null}
    </div>
  </>
);
