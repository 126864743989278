import {isUndefined} from 'underscore'
import { RequestErrorHandler } from "../../helpers/request-error-handler";
import { debounce } from 'underscore'
const errorHandler = RequestErrorHandler('coverpage');

(() => {
  class CoverpageStore {

    constructor() {
      this.cover = false;
      this.files = false;
      this.preview_logo_url = false;

      this.bindListeners({
        handleUploadLogo: CoverpageActions.UPLOAD_LOGO,
        handleReceive: CoverpageActions.RECEIVE,
        handleUpdateValue: CoverpageActions.UPDATE_VALUE,
        handleClear: CoverpageActions.CLEAR,
        handleFetch: CoverpageActions.FETCH,
        handleFetchIfNecessary: CoverpageActions.FETCH_IF_NECESSARY,
        handleSave: CoverpageActions.SAVE
      });

      this.debouncedSave = debounce(this.handleSave, 500)
    }

    handleUploadLogo(args) {
      ajaxHelper.ajax({
        method: "post",
        dataType: "json",
        data: {
          response: args.response
        },
        url: "/coverpages/" + this.cover.storyboard.id + "/logo.json",
        success: (response) => {
          if (!isUndefined(response.errors)) {
            this.cover.theme.logo_is_processing = false;
            this.cover.theme.logo_is_processed = false;
            // Show the error
            RequestActions.error(response.errors[0]);
          } else {
            this.cover.theme = response.theme;
            this.cover.theme.logo_is_processing = false;
            this.cover.theme.logo_is_processed = true;
            this.emitChange();
            RequestActions.success.defer("Logo saved!");
          }
        },
        error: errorHandler({
          messageKey: 'export.pdf.errors.uploadLogo',
        })
      });
    }

    handleReceive(cover) {
      this.cover = cover;
    }

    handleSave() {
      if (this.cover) {
        ajaxHelper.ajax({
          method: "put",
          data: {
            cover: this.cover
          },
          url: "/coverpages/" + this.cover.storyboard.id + ".json",
          success: function (response) {
            // RequestActions.success.defer("Changes saved");
            this.cover.storyboard.slug = response.storyboard.slug;
            this.cover.storyboard.is_player_showing_subtitles = response.storyboard.is_player_showing_subtitles;

            this.cover.storyboard.is_player_looping = response.storyboard.is_player_looping;
            // update listening stores (i.e. player)
            CoverpageActions.receive.defer(this.cover)
            this.emitChange();
          }.bind(this),
          error: errorHandler({ method: 'put' })
        });
      }
    }

    handleUpdateValue(obj) {
      if (obj.attr == "storyboard.name") {
        this.cover.storyboard.name = obj.value;
      }
      if (obj.attr == "storyboard.document_name") {
        this.cover.storyboard.document_name = obj.value;
      }
      if (obj.attr == "storyboard.is_player_looping") {
        this.cover.storyboard.is_player_looping = obj.value;
      }
      if (obj.attr == "storyboard.is_player_showing_subtitles") {
        this.cover.storyboard.is_player_showing_subtitles = obj.value;
      }
      if (obj.attr == "storyboard.document_secondary_info") {
        this.cover.storyboard.document_secondary_info = obj.value;
      }
      if (obj.attr == "storyboard.hide_storyboard_title") {
        this.cover.storyboard.hide_storyboard_title = obj.value;
      }
      if (obj.attr == "storyboard.include_version_number") {
        this.cover.storyboard.include_version_number = obj.value;
      }
      if (obj.attr == "storyboard.has_custom_cover_and_endslate") {
        this.cover.storyboard.has_custom_cover_and_endslate = obj.value;
      }
      if (obj.attr == "storyboard.include_pdf_cover") {
        this.cover.storyboard.include_pdf_cover = obj.value;
      }
      if (obj.attr == "storyboard.has_frame_border") {
        this.cover.storyboard.has_frame_border = obj.value;
      }
      if (obj.attr == "storyboard.use_letterbox_pdf_layout") {
        this.cover.storyboard.use_letterbox_pdf_layout = obj.value;
      }
      if (obj.attr == "storyboard.use_high_res_images_in_pdf") {
        this.cover.storyboard.use_high_res_images_in_pdf = obj.value;
      }
      if (obj.attr == "storyboard.include_frame_number_in_pdf") {
        this.cover.storyboard.include_frame_number_in_pdf = obj.value;
      }
      if (obj.attr == "storyboard.use_frame_labels_as_filenames") {
        this.cover.storyboard.use_frame_labels_as_filenames = obj.value;
      }
      if (obj.attr == "storyboard.include_label_text_in_output") {
        this.cover.storyboard.include_label_text_in_output = obj.value;
      }
      if (obj.attr == "storyboard.include_icons_in_pdf") {
        this.cover.storyboard.include_icons_in_pdf = obj.value;
      }
      if (obj.attr == "theme.foreground_color") {
        this.cover.theme.foreground_color = obj.value;
      }
      if (obj.attr == "theme.background_color") {
        this.cover.theme.background_color = obj.value;
      }
      if (obj.attr == "theme.has_watermark") {
        this.cover.theme.has_watermark = obj.value;
      }

      if (obj.attr == "theme.document_background_color") {
        this.cover.theme.document_background_color = obj.value;
      }

      if (obj.attr == "theme.show_page_numbers") {
        this.cover.theme.show_page_numbers = obj.value;
      }

      if (obj.attr == "theme.font_color") {
        this.cover.theme.font_color = obj.value;
      }

      this.debouncedSave()
      this.emitChange();
    }

    handleClear() {
      this.cover.storyboard.name = '';
      this.cover.storyboard.document_name = '';
      this.cover.storyboard.document_secondary_info = '';
      this.cover.theme.foreground_color = '#ffffff';
      this.cover.theme.background_color = '#ffffff';
      this.emitChange();
    }

    handleFetch(storyboard_id) {

      ajaxHelper.ajax({
        method: "get",
        url: "/coverpages/" + storyboard_id + ".json",
        success: function (response) {
          response.theme.logo_is_processing = false;
          CoverpageActions.receive(response);
          this.emitChange()
        }.bind(this),
        error: errorHandler()
      });
    }

    handleFetchIfNecessary(storyboard_id) {
      if (!this.cover || this.cover.storyboard.id !== storyboard_id) {
        this.handleFetch(storyboard_id)
      }
    }
  }

  window.CoverpageStore = alt.createStore(CoverpageStore, 'CoverpageStore');

})();
