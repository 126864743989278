/** @format */

import React, { useContext } from 'react';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import { CheckIcon, EllipsisIcon } from 'lucide-react';
import { type AccordionSortOption, ProjectsContext } from './ProjectsContext';
import { projectsAccordionSortLocalState } from 'javascripts/helpers/local-state';

export type SortOption = {
  value: AccordionSortOption;
  label: string;
};

const sortOptions: SortOption[] = [
  { value: 'a-to-z', label: 'A to Z' },
  { value: 'z-to-a', label: 'Z to A' },
  { value: 'newest', label: 'Newest' },
  { value: 'oldest', label: 'Oldest' },
];

export const ProjectsAccordionSort: React.FC = () => {
  const { sortOption, setSortOption } = useContext(ProjectsContext);

  const handleSortChange = (option: SortOption) => {
    setSortOption(option.value);
    projectsAccordionSortLocalState.setValue(option.value);
  };

  const selectedSort =
    sortOptions.find((option) => option.value === sortOption) || sortOptions[0];

  return (
    <Listbox
      as="div"
      className="relative inline-block text-left"
      value={selectedSort}
      onChange={handleSortChange}
    >
      <Listbox.Button className="cursor-pointer px-0.5 py-0.5 rounded-sm hover:bg-surface-add_frame">
        <EllipsisIcon className="w-3.5 h-3.5 rotate-90" />
      </Listbox.Button>
      <Listbox.Options className="absolute right-0 z-40 w-40 mt-2 bg-white border shadow-lg origin-top-right rounded-md focus:outline-none border-border">
        <div className="p-2 space-y-1">
          <div className="px-3 pt-1.5 pb-1 text-xs font-semibold tracking-wider uppercase text-type-subdued">
            Sort by
          </div>
          {sortOptions.map((option) => (
            <Listbox.Option key={option.value} value={option}>
              {({ selected }) => (
                <div
                  className={classNames(
                    'flex items-center text-sm cursor-pointer relative pb-2 pt-1.5 px-3 rounded-md',
                    selected ? 'bg-surface-light' : 'hover:bg-surface-light',
                  )}
                >
                  <span className="flex-auto">{option.label}</span>
                  {selected && (
                    <CheckIcon className="flex-shrink-0 w-3.5 h-3.5" />
                  )}
                </div>
              )}
            </Listbox.Option>
          ))}
        </div>
      </Listbox.Options>
    </Listbox>
  );
};
