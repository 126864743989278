/** @format */
import type { AxiosError, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import type { JsonValue, Writable } from 'type-fest';
import {
  isArray,
  isFunction,
  isObject,
  isString,
  isUndefined,
  pick,
} from 'underscore';
import { notNullOrUndefined, notUndefined } from './notUndefined';
import { serialize as toFormData } from 'object-to-formdata';
import { simplifyRequestJSON } from './simplify-request-json';
const api = require('./api')();

type returnType<T> = Promise<T>;
type TypeOrArray<T> = T | T[];

export class AJAXError {
  code?: string;
  status: number;
  response?: { JSON?: JsonValue; text?: string };
  request?: AxiosRequestConfig;

  constructor(props: Partial<AJAXError>) {
    this.status = props.status ?? 0;
    this.response = props.response;
    this.request = props.request;
    this.code = props.code;
  }

  static fromAxios(error: AxiosError, request: AxiosRequestConfig) {
    return new AJAXError({
      status: error.response?.status ?? 0,
      response: {
        JSON: error.response?.data,
        text: error.message,
      },
      request: {
        ...request,
        data: simplifyRequestJSON(request.data),
      },
      code: error.code,
    });
  }
}

type errorCallback = (e: AJAXError) => void;
export interface AjaxSettings
  extends Omit<JQueryAjaxSettings, 'error' | 'beforeSend'> {
  error?: TypeOrArray<errorCallback>;
  progress?: (progress: number) => void;
  beforeSend?: (jqXHR: JQuery.jqXHR, settings: this) => false | void;
  /** This will determine if a CSRF token will be added */
  withCSRF?: boolean;
}

const runCallbacks = <CallbackType = never>(
  callbacks: TypeOrArray<CallbackType> | undefined,
  dataFunc: (callback: CallbackType) => void,
) => {
  if (!callbacks) return null;
  if (isArray(callbacks)) {
    callbacks.forEach((i) => dataFunc(i));
  } else if (isFunction(callbacks)) {
    dataFunc(callbacks);
  }
};

const convertJQAjaxSettings = (
  settings: AjaxSettings = {},
): AxiosRequestConfig => {
  const csrf = document.head.querySelector<HTMLMetaElement>(
    'meta[name=csrf-token]',
  )?.content;
  const withCSRF = settings.withCSRF ?? true;

  const headers = pick(
    {
      'X-CSRF-Token': withCSRF ? csrf : undefined,
      ...settings.headers,
      // Overriding this because sometimes Rails seems to freak out at multiple
      // options?
      Accept: 'application/json',
    },
    notUndefined,
  );

  const fakeXHR: Partial<JQuery.jqXHR> = {
    setRequestHeader: (headerName: string, value: string) => {
      headers[headerName] = value;
    },
  };

  settings.beforeSend?.(fakeXHR as any, settings);

  const config: AxiosRequestConfig = pick(
    {
      url: settings.url,
      method: settings.method ?? 'get',
      data: settings.data,
      headers,
    },
    notNullOrUndefined,
  );

  if (settings.enctype === 'application/x-www-form-urlencoded') {
    config.data = toFormData(settings.data);
  }

  if (settings.progress) {
    const progressHandler = (evt: ProgressEvent) => {
      if (evt.lengthComputable) {
        settings.progress!((evt.loaded / evt.total) * 0.8);
      }
    };

    config.onDownloadProgress = progressHandler;
    config.onUploadProgress = progressHandler;
  }
  return config;
};

/**
 * Meant as a drop-in replacement for jQuery's ajax function
 * @deprecated - please use either `ajaxGo`, `ajaxRuby`, `ajaxExternal`
 */
export function ajax<T = any>(
  url?: string | AjaxSettings | undefined,
  settings?: string | AjaxSettings | undefined,
): returnType<T> {
  return new Promise((resolve, reject) => {
    const requestConfig: AxiosRequestConfig = {
      method: 'get',
    };

    let settingsObject: AjaxSettings | undefined;

    if (isString(url)) {
      requestConfig.url = url;
      if (isObject(settings) || isUndefined(settings)) {
        Object.assign(requestConfig, convertJQAjaxSettings(settings));
        settingsObject = settings;
      }
    } else if (isObject(url)) {
      Object.assign(requestConfig, convertJQAjaxSettings(url));
      settingsObject = url;
    } else {
      throw new Error('params invalid');
    }

    const fakeXHR: Partial<Writable<JQuery.jqXHR>> = {
      getAllResponseHeaders: () => 'fake',
    };

    const runCompleteCallback = (statusText: string) =>
      runCallbacks<JQuery.Ajax.CompleteCallback<any>>(
        settingsObject?.complete,
        (callback) => callback(fakeXHR as JQuery.jqXHR, statusText as any),
      );

    axios.request(requestConfig).then(
      (response) => {
        runCallbacks<JQuery.Ajax.SuccessCallback<any>>(
          settingsObject?.success,
          (callback) =>
            callback(response.data, response.statusText as any, {} as any),
        );
        runCompleteCallback(response.statusText);

        resolve(response.data);
      },
      (error: AxiosError) => {
        runCallbacks<errorCallback>(settingsObject?.error, (callback) =>
          callback(AJAXError.fromAxios(error, requestConfig)),
        );
        runCompleteCallback(
          error.response?.statusText ?? 'Something went wrong',
        );

        reject(fakeXHR);
      },
    );
  });
}

interface ajaxShortcut<T = any> {
  (settings: AjaxSettings): returnType<T>;
}

/** Requests that use the GO api. You no longer need to pass the `beforeSend`
 * option and prefix the URL with `setGoApiUrl` */
export const ajaxGo: ajaxShortcut = (settings) => {
  return ajax({
    withCSRF: true,
    beforeSend: (xhr) => api.setGoApiAuthHeader(xhr),
    ...settings,
    url: BoordsConfig.ApiUrl + settings.url,
  });
};

/** Old v1 ruby API */
export const ajaxRuby: ajaxShortcut = (settings) => {
  return ajax({
    withCSRF: true,
    ...settings,
  });
};

/** New api JSON-REST, you might need to serialize payloads first */
export const ajaxJSONRest: ajaxShortcut = (settings) => {
  return ajax({
    ...settings,
    beforeSend: api.setRailsApiAuthHeader,
    url: '/api/' + settings.url,
  });
};

/** Used for external requests */
export const ajaxExternal: ajaxShortcut = (settings) => {
  return ajax({
    withCSRF: false,
    ...settings,
  });
};

export default { ajax };

(window as any).ajaxHelper = {
  ajax,
};
