/** @format */
import { includes } from 'underscore';

/** In some cases when listening to keyboard events, we want to ignore them when
key events take place in an input element or contentEditable field. */
export const isInInput = (target: HTMLElement | EventTarget | null) => {
  if (target instanceof HTMLElement) {
    return (
      includes(['INPUT', 'TEXTAREA'], target.nodeName) ||
      !!target.dataset?.ui ||
      target.isContentEditable
    );
  } else {
    throw new Error('target element was not HTMLElement');
  }
};
