/** @prettier */
const showdown = require('showdown/dist/showdown.min.js');

export interface MarkdownParserProps {
  openLinksInNewWindow: boolean;
  allowHeadings: boolean;
}

export const markdownParser = (
  text: string,
  props?: Partial<MarkdownParserProps>,
) =>
  new showdown.Converter({
    simpleLineBreaks: true,
    simplifiedAutoLink: true,
    literalMidWordUnderscores: true,
    openLinksInNewWindow: props?.openLinksInNewWindow ?? true,
    excludeTrailingPunctuationFromURLs: true,

    // Load a custom extension/preset that allows us to parse only
    // specific tags, see bottom of this file
    extensions: props?.allowHeadings ? undefined : ['boords'],
  }).makeHtml(text);

// Adapted from https://github.com/showdownjs/showdown/issues/238
showdown.extension('boords', function () {
  var store = '';
  var lngExt = function (text, converter, options) {
    const globals = {
      converter: converter,
      gHtmlBlocks: [],
      gHtmlMdBlocks: [],
      gHtmlSpans: [],
      gUrls: {},
      gTitles: {},
      gDimensions: {},
      gListLevel: 0,
      hashLinkCounts: {},
      ghCodeBlocks: [],
      metadata: {
        parsed: {},
        raw: '',
        format: '',
      },
    };

    text = showdown.subParser('lists')(text, options, globals);
    text = showdown.subParser('hashHTMLBlocks')(text, options, globals);
    text = showdown.subParser('hashCodeTags')(text, options, globals);
    text = showdown.subParser('stripLinkDefinitions')(text, options, globals);
    text = showdown.subParser('paragraphs')(text, options, globals);

    text = showdown.subParser('unhashHTMLSpans')(text, options, globals);
    text = showdown.subParser('unescapeSpecialChars')(text, options, globals);
    // attacklab: Restore dollar signs
    text = text.replace(/¨D/g, '$$');
    // attacklab: Restore tremas
    text = text.replace(/¨T/g, '¨');
    store = text;
    return '';
  };

  return [
    {
      type: 'lang',
      filter: lngExt,
    },
    {
      type: 'output',
      filter: () => store,
    },
  ];
});
