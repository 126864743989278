/** @prettier */
import React, { useContext, useEffect, useState } from 'react';
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import type { OnboardingProps } from './Onboarding';
import { useTranslation } from 'react-i18next';
import { CircularProgressIndicator } from 'javascripts/components/shared/CircularProgressIndicator';
import { SubdomainContext } from 'blackbird/helpers/contexts/SubdomainContext';
import slugify from 'slugify';

const TeamNameScreen: React.FC<OnboardingProps> = ({
  onSaveTeamName,
  onNameSkip,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'onboarding:teamName',
  });

  const slugifyArgs = {
    trim: true,
    strict: true,
    lower: true,
  };

  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [hasTyped, setHasTyped] = useState(false);

  const { UpdateSubdomain, submitted, setSubdomain, subdomain, canSubmit } =
    useContext(SubdomainContext)!;

  const handleSubmit = () => {
    UpdateSubdomain(() => {
      onSaveTeamName && onSaveTeamName();
    }, name);
  };

  useEffect(() => {
    if (BoordsConfig.TeamName) {
      setName(BoordsConfig.TeamName);
    }
  }, []);

  useEffect(() => {
    if (!name) {
      // setSubdomain('');
      setDisabled(true);
      return;
    }
    if (name.length >= 2) {
      setHasTyped(true);
      setDisabled(false);
    }
    setSubdomain(slugify(name, slugifyArgs));
  }, [name, setSubdomain, setHasTyped, slugifyArgs]);

  return (
    <div className="flex items-center justify-center flex-grow">
      <div className="flex flex-col flex-grow max-w-md">
        <div className="flex items-start mb-4">
          <CircularProgressIndicator steps={2} value={2} showCheck />
        </div>
        <div className="my-4 space-y-4">
          <div className="text-2xl text-type-primary">{t('heading')}</div>
          <div className="text-type-subdued">{t('subheading')}</div>
        </div>

        <div className="mt-2 mb-8">
          <TextInput
            inputSize="md"
            label={t('inputLabel')}
            aria-label={t('inputLabel')}
            name="teamName"
            value={name}
            onCut={() => {
              setSubdomain('');
            }}
            autoFocus
            onChange={(e) => setName(e.target.value)}
            placeholder={t('placeholder')}
            className="flex-grow-0"
          />

          <div className={`mt-1 text-sm text-type-subdued`}>
            <>{t('subdomainExplainer')}</>
            <span className="underline">{`${
              canSubmit && subdomain.length > 0
                ? subdomain
                : hasTyped
                ? `app`
                : t('placeholderSubdomain')
            }.boords.com`}</span>
          </div>
        </div>

        <div className="flex items-center justify-end space-x-4">
          {!submitted && (
            <span
              className="text-sm no-underline cursor-pointer text-type-disabled hover:underline"
              onClick={onNameSkip}
            >
              {t('doLater')}
            </span>
          )}

          <Button
            size="sm"
            htmlType="submit"
            disabled={disabled || submitted}
            onClick={handleSubmit}
          >
            {submitted ? t('submitted') : t('button')}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default TeamNameScreen;
