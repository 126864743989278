/** @prettier */
import logger from 'javascripts/helpers/logger';
import * as React from 'react';
import { debounce, isEqual, isUndefined } from 'underscore';
import {
  getPDFRendererProps,
  type PDFRendererOverrides,
} from './helpers/getPDFRendererProps';
import type {
  PDFRendererOutput,
  PDFRendererProps,
  supportedFormats,
  supportedLayouts,
  FontSettings,
  PDFContext,
} from './types';
import type { PDFRenderer } from './PDFRenderer';
import { PDFPreview } from './PDFPreview';
import * as queue from 'async/queue';
import type { AsyncQueue } from 'async';
import { PageFrame, type pageFramePaperSize } from './UI/PageFrame';
import { EmptyPage } from './UI/EmptyPage';
import type {
  IStoryboardInStore,
  AspectRatioName,
} from '../../types/storyboard';
import Container from '../shared/Container';
import { ExportPdfFrontCover } from '../storyboard_export/pdf/ExportPdfFrontCover';
import { ExportPdfBackCover } from '../storyboard_export/pdf/ExportPdfBackCover';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { PDFGenerationInProgress } from './UI/PDFGenerationInProgress';
import { PDFDone } from './UI/PDFDone';
import { PDFPageLayout } from './UI/PDFPageLayout';
import { frameAspectRatioNormalizer } from '../../helpers/frame-size-helper';
import { detectCharset, type CharsetInfo } from '../../helpers/detectCharset';
import { getAllText } from './helpers/getAllText';
import {
  getFontForSubset,
  saveFontPreference,
} from './helpers/getFontForSubset';
import { cleanupCachedFrameImages } from './helpers/loadFrameImages';
import { clearPDFImageCache } from './coverToPng';
import { NewPDFExportOptIn } from './helpers/newPDFExportOptin';
import { ErrorBoundary } from '../shared/ErrorBoundary';
import { constructFilename } from '../../helpers/constructFilename';
import { hasPermission } from '../../helpers/has-permission';
import Dialog from 'blackbird/components/dialog/Dialog';
import Button from 'blackbird/components/button/Button';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import classNames from 'classnames';
import { OverflowContextProvider } from 'blackbird/helpers/hooks/overflow';
import { WithTranslation, withTranslation } from 'react-i18next';
import { LightboxEvent, openLightbox } from 'javascripts/helpers/lightbox';
import { LIGHTBOX_EVENT_NAME } from 'javascripts/helpers/lightbox';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';
const errorHandler = RequestErrorHandler('pdfExport');

interface State {
  keepOpen?: boolean;
  panel: 'layout' | 'frontCover' | 'backCover';
  previewPdf?: PDFRendererOutput;
  fullPdf?: PDFRendererOutput;
  isProcessingPreview: boolean;
  isProcessingFullDocument: boolean;
  isLoading: boolean;
  hasError: boolean;
  hasFrontCover: boolean;
  hasBackCover: boolean;
  format: supportedFormats;
  orientation: AspectRatioName;
  layoutUsed: supportedLayouts;
  fontSubsets?: string[];
  fontSettings?: FontSettings;
  trialRenderUsed?: boolean;
  detectedCharset?: CharsetInfo;
}
let count = 0;

interface Props {
  onCleanup?: () => void;
  renderFunction: typeof PDFRenderer;
  context: PDFContext;
  overrides?: PDFRendererOverrides;
}

class PDFExportContainer extends React.PureComponent<
  {
    storyboard: { storyboard: IStoryboardInStore };
    coverpage: { cover: { storyboard: any; cover: any } };
    pdfCover: any;
    pdfFooterLogo: any;
  } & Props &
    WithTranslation,
  State
> {
  /**
   * We set up a render queue for the PDF. This is mostly in order to
   * ensure we only render one PDF at a time, and don't pile on more renders
   * the worker for this queue is defined in the constructor for this component.
   */
  queue: AsyncQueue<number>;
  lastRendererProps?: PDFRendererProps;
  mounted = true;
  constructor(props) {
    super(props);
    this.state = {
      keepOpen: false,
      panel: 'layout',
      isProcessingPreview: false,
      isLoading: true,
      previewPdf: undefined,
      fullPdf: undefined,
      hasError: false,
      hasFrontCover: false,
      hasBackCover: false,
      format: props.context === 'pdf' ? 'a4' : 'widescreen',
      orientation: 'landscape',
      layoutUsed:
        props.overrides?.format || props.storyboard.storyboard.pdf_layout,
      isProcessingFullDocument: false,
    };

    this.queue = queue((count, callback) => {
      if (!this.mounted) return;
      const storyboard = this.props.storyboard.storyboard;
      const rendererProps = getPDFRendererProps(
        storyboard,
        storyboard.preferences?.charset_override ||
          this.state.detectedCharset?.subset,
        this.state.fontSettings!,
        this.state.orientation!,
        this.props.overrides,
      );

      if (isEqual(rendererProps, this.lastRendererProps)) {
        logger.log('skipping render… renderer props are equal');
        return callback();
      }

      this.renderPDF(rendererProps, false)
        .then((result) => {
          if (!this.mounted) return;

          this.setState(
            {
              previewPdf: result,
              isProcessingPreview: false,
              hasFrontCover: result.hasFrontCover,
              hasBackCover: result.hasBackCover,
              layoutUsed: rendererProps.style.layout,
            },
            this.trackOverflows('preview'),
          );

          callback();
        })
        .catch(
          errorHandler({ messageKey: 'export.pdf.errors.preview' }, (err) => {
            this.setState({ hasError: true, isProcessingPreview: false });
            callback(err);
          }),
        );

      this.lastRendererProps = rendererProps;
    });

    this.queue.pause();
  }

  trackOverflows = (type: 'preview' | 'full') => () => {
    if (
      (type === 'full' && this.state.fullPdf?.hasTooMuchText) ||
      (type === 'preview' && this.state.previewPdf?.hasTooMuchText)
    ) {
      Track.event.defer('pdf_overflow', {
        type,
        format: this.state.format,
        layout: this.state.layoutUsed,
        aspectRatio: this.props.storyboard.storyboard.frame_aspect_ratio,
      });
    }
  };

  getString = (key) => {
    return this.props.t(`export.${this.props.context}.${key}`);
  };

  /** This code will be called for both full renders and previews */
  renderPDF(rendererProps: PDFRendererProps, fullDocument: boolean) {
    const storyboard = this.props.storyboard.storyboard;
    const freeloader = hasPermission(BoordsConfig.Uid, 'pdfs') !== true;
    const trialRender = fullDocument && freeloader;

    this.setState({
      isProcessingPreview: !fullDocument,
      isProcessingFullDocument: fullDocument,
      format: rendererProps.documentFormat,
      orientation: frameAspectRatioNormalizer(storyboard.frame_aspect_ratio)!,
      trialRenderUsed: trialRender,
    });

    return this.props.renderFunction({
      ...rendererProps,
      frames: FrameStore.getState().frames,
      document_name: storyboard.document_name,
      storyboardVersion: storyboard.version_number,
      isQuickMode: !fullDocument,
      pageNumber: fullDocument ? undefined : 0,
      isTrialPreview: trialRender,
      /** Automatically dispose of generated Object URLs */
      autoCleanup: !fullDocument,
    });
  }

  /** Render the full-length PDF document and update the state */
  renderFullPDF = () => {
    const storyboard = this.props.storyboard.storyboard;
    this.renderPDF(
      getPDFRendererProps(
        storyboard,
        storyboard.preferences?.charset_override ||
          this.state.detectedCharset?.subset,
        this.state.fontSettings!,
        this.state.orientation!,
        this.props.overrides,
      ),
      true,
    )
      .then((result) => {
        this.setState(
          { isProcessingFullDocument: false, fullPdf: result },
          this.trackOverflows('full'),
        );
      })
      .catch(
        errorHandler({ message: this.getString('errorMessage') }, () => {
          this.setState({ hasError: true, isProcessingFullDocument: false });
        }),
      );
  };

  /**
   * Set initial values based on detected character sets, and signal the Queue
   * to start processing the PDF
   * */
  onSetCharset = (override?: CharsetInfo | null) => {
    const storyboard = this.props.storyboard.storyboard;
    const detected = detectCharset(
      getAllText(storyboard.frame_fields, FrameStore.getState().frames),
    );

    const charset: CharsetInfo = override ?? detected;

    if (!isUndefined(override)) {
      StoryboardActions.updatePreference({
        name: 'charset_override',
        value: override?.subset,
      });
    }

    this.setState(
      {
        detectedCharset: detected,
        fontSubsets: [charset.subset, 'latin'],
        fontSettings: getFontForSubset(charset.subset),
        isLoading: false,
      },
      () => {
        this.queue.resume();
        // Even is the passed override was empty we want to update the props
        if (typeof override !== 'undefined') {
          this.updateRendererProps();
        }
      },
    );
  };

  /**
   * Triggers a new render if we're ready and aren't already waiting for a new
   * render
   */
  updateRendererProps = debounce(() => {
    if (
      this.state.isLoading &&
      this.props.coverpage.cover &&
      this.props.pdfCover.is_loaded &&
      this.props.pdfFooterLogo.is_loaded
    ) {
      this.onSetCharset();
    }

    if (this.queue.length() < 2) {
      // If the queue length is lower than 2, add it to the queue,
      // if not, we will already rerender, so we don't have to do nothing
      count++;
      this.queue.push(count);
    }
  }, 500);

  // prettier-ignore
  /** When any of these stores update, we'll check if a rerender is due */
  listen = [StoryboardStore, PdfStore, CoverpageStore, PdfCoverStore, PdfFooterLogoStore];

  componentDidMount() {
    Track.event.defer(
      this.props.context === 'pdf'
        ? 'open_pdf_export_flyover'
        : 'open_slides_flyover',
    );

    this.updateRendererProps();
    this.listen.forEach((store) => store.listen(this.updateRendererProps));
    FilestackStore.listen(this.onFileStackChanges);
    const storyboardId = StoryboardStore.getState().storyboard.id;
    PdfActions.fetchPdf.defer(storyboardId);
    PdfFooterLogoActions.fetch.defer({ storyboard_id: storyboardId });
    CoverpageActions.fetch.defer(storyboardId);
    PdfCoverActions.fetch.defer({
      storyboard_id: storyboardId,
    });

    document.body.addEventListener(LIGHTBOX_EVENT_NAME, this.onLightboxEvent);
  }
  onFileStackChanges = (state) => {
    this.setState({ keepOpen: state.pdfDialogOpened });
  };
  onLightboxEvent = (e: LightboxEvent) => {
    this.setState({ keepOpen: e.detail.open });
  };

  setStateAndReload = (state: Partial<State>) => {
    this.setState(state as State);
    this.updateRendererProps();
  };

  // This has a habit of firing multiple times
  onFontChange = debounce((e) => {
    this.setStateAndReload({ fontSettings: e });
    saveFontPreference(this.state.fontSubsets![0], e);
  }, 100);

  componentWillUnmount() {
    this.queue.kill();
    this.listen.forEach((store) => store.unlisten(this.updateRendererProps));
    this.mounted = false;
    cleanupCachedFrameImages();
    clearPDFImageCache();

    document.body.removeEventListener(
      LIGHTBOX_EVENT_NAME,
      this.onLightboxEvent,
    );
  }
  handleClose() {
    FlyoverActions.close();
  }

  render() {
    const storyboard = this.props.storyboard.storyboard;
    const isPaperPortrait = this.state.layoutUsed === 'list';
    const coversShouldRender = this.props.overrides?.covers !== false;

    const pdfFilename = constructFilename({
      storyboard,
      extension: this.state.fullPdf?.extension ?? 'pdf',
      suffix: this.state.trialRenderUsed ? 'preview' : undefined,
    });

    let paperSize: pageFramePaperSize = 'a4';
    if (isPaperPortrait) {
      paperSize = 'a4-portrait';
    } else if (this.state.format === 'widescreen') {
      paperSize = 'widescreen';
    }

    let content;

    if (this.state.isLoading) {
      content = (
        <div className="flex h-full max-h-[calc(100vh-270px)] flex-col items-center justify-center">
          <LoadingIndicator />
        </div>
      );
    } else if (this.state.panel === 'layout') {
      content = (
        <PDFPageLayout
          charsetOverride={storyboard.preferences?.charset_override}
          detectedCharset={this.state.detectedCharset}
          storyboard={storyboard}
          cover={this.props.coverpage}
          layout={storyboard.pdf_layout}
          orientation={this.state.orientation}
          documentFormat={this.state.format}
          isPaperPortrait={isPaperPortrait}
          fontSubsets={this.state.fontSubsets!}
          currentFont={this.state.fontSettings}
          onFontChange={this.onFontChange}
          onCharsetOverrideChange={this.onSetCharset}
          context={this.props.context}
        />
      );
    } else if (this.state.panel === 'frontCover') {
      content = (
        <ExportPdfFrontCover
          storyboard={storyboard}
          cover={this.props.coverpage}
        />
      );
    } else if (this.state.panel === 'backCover') {
      content = (
        <ExportPdfBackCover
          storyboard={storyboard}
          cover={this.props.coverpage}
        />
      );
    }
    const fullHeight =
      !this.state.isProcessingFullDocument && !this.state.fullPdf;

    const title = this.state.isProcessingFullDocument
      ? this.props.t('export.pdf.titleGenerating')
      : this.state.fullPdf
      ? this.props.t('export.pdf.titleReady')
      : this.getString('title');

    return (
      <OverflowContextProvider>
        {({ hasOverflow, ref }) => (
          <Dialog
            initialFocus={ref}
            stickyOverflow={false}
            containerRef={ref}
            onCancel={this.handleClose}
            keepOpen={this.state.keepOpen}
            size={fullHeight ? 'lg' : 'slim'}
            hideActions
            isOpen
            isDark
            title={title}
            subtitle={`${storyboard.document_name} v${storyboard.version_number} of ${storyboard.versions.length}`}
            fullHeight={fullHeight}
            containerClasses="no-padding"
            headerClasses={classNames(
              'sticky top-0 px-10 -mx-10 fullsize:pb-10 pt-5 pb-6 z-10 rounded-t-xl bg-white',
              fullHeight ? 'fullsize:pt-0 ' : 'pt-10',
              { 'shadow-[0px_2px_0px_rgba(0,0,0,0.12)]': hasOverflow },
            )}
          >
            {this.state.isProcessingFullDocument ? (
              <PDFGenerationInProgress context={this.props.context} />
            ) : this.state.fullPdf ? (
              <PDFDone
                downloadUrl={this.state.fullPdf.url}
                filename={pdfFilename}
                onExpire={() => this.setStateAndReload({ fullPdf: undefined })}
                hasTooMuchText={this.state.fullPdf.hasTooMuchText}
                context={this.props.context}
              />
            ) : (
              <>
                <ErrorBoundary size="small" bg="p-5 bg-white">
                  <div className="flex flex-col gap-4 lg:flex-row">
                    <div className="flex w-full pb-3 rounded-sm mt2 f4 shrink-0 bg-surface lg:block lg:w-72">
                      {coversShouldRender ? (
                        <PageFrame
                          isActive={false}
                          isEnabled={this.state.hasFrontCover}
                          paperSize={paperSize}
                          onClick={() => this.setState({ panel: 'frontCover' })}
                        >
                          {this.state.hasFrontCover ? (
                            <div className="absolute inset-0">
                              <PDFPreview
                                pageNumber={1}
                                url={
                                  this.state.previewPdf
                                    ? this.state.previewPdf.url
                                    : undefined
                                }
                                isLoading={this.state.isProcessingPreview}
                                scale={1}
                                title={this.getString(
                                  'frontCover.sidebarTitle',
                                )}
                                className="w-full h-full"
                              />
                            </div>
                          ) : (
                            <EmptyPage
                              title={this.getString('frontCover.sidebarTitle')}
                              isLoading={this.state.isProcessingPreview}
                            />
                          )}
                        </PageFrame>
                      ) : null}

                      <PageFrame
                        isActive={false}
                        isEnabled={true}
                        paperSize={paperSize}
                        onClick={() => this.setState({ panel: 'layout' })}
                      >
                        <div className="absolute inset-0">
                          <PDFPreview
                            pageNumber={this.state.hasFrontCover ? 2 : 1}
                            url={
                              this.state.previewPdf
                                ? this.state.previewPdf.url
                                : undefined
                            }
                            isLoading={this.state.isProcessingPreview}
                            scale={1}
                            title={this.getString('framePage')}
                            className="w-full h-full"
                          />
                        </div>
                      </PageFrame>

                      {coversShouldRender ? (
                        <PageFrame
                          isActive={false}
                          isEnabled={this.state.hasBackCover}
                          paperSize={paperSize}
                          onClick={() => this.setState({ panel: 'backCover' })}
                        >
                          {this.state.hasBackCover ? (
                            <div className="absolute inset-0">
                              <PDFPreview
                                pageNumber={this.state.previewPdf!.pageCount}
                                url={
                                  this.state.previewPdf
                                    ? this.state.previewPdf.url
                                    : undefined
                                }
                                isLoading={this.state.isProcessingPreview}
                                scale={1}
                                title={this.getString('backCover.sidebarTitle')}
                                className="w-full h-full"
                              />
                            </div>
                          ) : (
                            <EmptyPage
                              title={this.getString('backCover.sidebarTitle')}
                              isLoading={this.state.isProcessingPreview}
                            />
                          )}
                        </PageFrame>
                      ) : null}
                    </div>

                    <div
                      className="flex-auto bg-white f4 pa4 mt2 h6 br2 w-70"
                      style={{ maxWidth: '45rem' }}
                    >
                      {content}
                    </div>
                  </div>
                </ErrorBoundary>
                <div className="sticky bottom-0 p-10 -mb-10 -mx-10 pt-0 bg-white rounded-xl">
                  <div className="absolute left-0 right-0 h-px border-t border-border" />
                  {!hasPermission(BoordsConfig.Uid, 'pdfs') === true ? (
                    <div className="flex px-6 py-5 rounded-lg mt-7 bg-brand-blue-25">
                      <div className="flex-auto">
                        <div className="mb-2 text-lg font-semibold">
                          {this.props.t('export.pdf.upgrade.title')}
                        </div>
                        <div className="mb-2.5">
                          {this.getString('updateRequired')}
                        </div>
                      </div>

                      <div className="flex items-center justify-end flex-auto gap-3">
                        <Button
                          size="lg"
                          rounded
                          className="flex-grow-0"
                          type="fancy"
                          onClick={() => {
                            FlyoverActions.open.defer({
                              type: 'inlinePricing',
                            });
                          }}
                        >
                          {this.props.t('export.pdf.upgrade.button')}
                        </Button>

                        <Button
                          rounded
                          className="flex-grow-0"
                          type="outline"
                          onClick={() => this.renderFullPDF()}
                        >
                          {this.props.t('export.pdf.upgrade.preview')}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center mt-7">
                      {this.props.context === 'pdf' &&
                      !BoordsConfig.IsProfessional &&
                      !['4:5', '4:3', '1.85:1', '2.4:1'].includes(
                        storyboard.frame_aspect_ratio,
                      ) ? (
                        <NewPDFExportOptIn />
                      ) : BoordsConfig.IsProfessionalFree ? (
                        <a
                          className="text-sm underline cursor-pointer decoration-black/20 hover:no-underline underline-offset-2"
                          onClick={() => {
                            openLightbox('https://vimeo.com/275861119');
                          }}
                        >
                          {this.getString('tip.title')}
                        </a>
                      ) : null}
                      <div className="flex justify-end flex-auto gap-3">
                        <Button
                          type="outline"
                          onClick={() => FlyoverActions.close()}
                        >
                          {this.props.t('export.pdf.close')}
                        </Button>
                        <Button htmlType="button" onClick={this.renderFullPDF}>
                          {this.getString('startButton')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </Dialog>
        )}
      </OverflowContextProvider>
    );
  }
}

export default withTranslation()(
  Container(['coverpage', 'storyboard', 'PdfCover', 'PdfFooterLogo'])(
    PDFExportContainer,
  ),
) as React.ComponentClass<Props>;
