/** @prettier */
const _ = require('underscore');
const createReactClass = require('create-react-class');
var PropTypes = require('prop-types');
import Button from 'blackbird/components/button/Button';
import { downloadEvent } from 'blackbird/helpers/eventContextHelper';
window.ExportPdfDone = createReactClass({
  propTypes: {
    pdf: PropTypes.object.isRequired,
    onDownloadExpired: PropTypes.func.isRequired,
  },

  handleDownloadEvent() {
    downloadEvent('pdf');
  },

  handleExpirePdf: function (e) {
    e.preventDefault();
    this.props.onDownloadExpired();
    PdfActions.invalidateCache();
  },

  render: function () {
    return (
      <div className="flex flex-col p-10 pt-0 gap-8">
        <div className="space-y-6">
          <div className="text-2xl text-center">
            {`Your PDF is ready to download`}
          </div>
          <div className="text-center text-type-subdued">
            {`Click the button below to download your PDF`}
          </div>

          <div className="flex flex-col items-center gap-3">
            <Button
              onClick={this.handleDownloadEvent}
              link={this.props.pdf.pdf.cached_pdf_url}
              target="_blank"
              download
              className="w-64 minW0 no-outline"
            >
              <span className="truncate">
                {`Download ` +
                  _.last(
                    decodeURIComponent(this.props.pdf.pdf.cached_pdf_url).split(
                      '/',
                    ),
                  ).replace(/\?\d*/, '')}
              </span>
            </Button>

            <Button
              className="no-outline"
              type="outline"
              onClick={this.handleExpirePdf}
            >
              {`Back to PDF Export settings`}
            </Button>
          </div>
          {this.props.pdf.hasErrors ? (
            <div className="mt-5 mb-4">
              <ExportPDFErrors {...this.props} heading="PDF looking strange?" />
            </div>
          ) : null}
        </div>
      </div>
    );
  },
});
