/** @format */
import * as React from 'react';
import classNames from 'classnames';
import Toast from 'blackbird/components/feedback/toast/Toast';
import { Trans, useTranslation } from 'react-i18next';
import { SecondaryLink } from 'blackbird/components/common/SecondaryLink';

export const CommentingOnOldVersion: React.FC<{
  newVersionUrl: string;
  className?: string;
}> = ({ className, newVersionUrl }) => {
  const { t } = useTranslation();
  return (
    <Toast
      size="full"
      kind="warning"
      message={<Trans t={t} i18nKey="comments.commenting_on_old_storyboard" />}
      className={classNames('has-links', className)}
    >
      <div className="mt-1">
        <SecondaryLink href={newVersionUrl} textColor="text-type-primary">
          <>{`Go to Latest Version`}</>
        </SecondaryLink>
      </div>
    </Toast>
  );
};
