const _ = require('underscore');
const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
const {   withTranslation } = require('react-i18next');

const PanelbarCommentsList = createReactClass({

  propTypes: {
    sections: PropTypes.array,
    team: PropTypes.any,
    activeFrame: PropTypes.any,
    canManage: PropTypes.bool.isRequired,
    user: PropTypes.object,
  },

  render: function() {
    let active;
    const {t} = this.props;
    const sections = this.props.sections.map((section, i) => {

      if (_.isUndefined(this.props.activeFrame)) {
        active = false;
      } else {
        active = this.props.activeFrame?.frameId == section.frameId;
      }
      return <PanelbarCommentsSection {...this.props} frameId={section.frameId} comments={section.comments} headerText={t('counters.frame',{count:section.index})} active={active} key={`comment-section-${i}`} onClick={() => CommentActions.setActiveFrame.defer(section.frameId)} />;

    });

    return <div className='px-6'>{sections}</div>;

  }

});
window.PanelbarCommentsList = withTranslation()(PanelbarCommentsList);
