/** @format */
import * as React from 'react';
import { OfferContext } from '../OfferContext';
import { Lock, AlarmClock, UserRound, UsersRound } from 'lucide-react';

import { FeatureInline } from '../../features/Feature';
import { formatCurrency } from 'javascripts/helpers/currencyHelper';
import { ModalDivider } from 'blackbird/components/dialog/Dialog';
import Badge from 'blackbird/components/badge/Badge';
import Button from 'blackbird/components/button/Button';

import { SaleTimer } from 'javascripts/components/shared/CountdownTimer';

export const DiscountedPrice: React.FC = () => {
  const { referencePrice, percentageDiscount, currency } =
    React.useContext(OfferContext);

  return !referencePrice || !percentageDiscount ? (
    <></>
  ) : (
    <>{`${formatCurrency({
      amount:
        (referencePrice.amount -
          (referencePrice.amount / 100) * percentageDiscount) /
        100,
      currency,
    })}`}</>
  );
};

export const DiscountFlyoverTitle: React.FC = () => {
  return (
    <div className="mb-3 text-2xl font-semibold leading-tight">
      <span>{`Get 1 Month for `}</span>
      <DiscountedPrice />
    </div>
  );
};

export const DiscountBannerTitle: React.FC = () => {
  return (
    <>
      <div className="flex items-center pl-3 pr-1.5 pt-1 pb-1.5 leading-tight font-semibold rounded-md bg-brand-yellow/70 -ml-2">
        <span className="text-black/70">
          <AlarmClock size={16} />
        </span>
        <span className="ml-1.5 mr-1 text-black/70">{`One-Time Offer`}</span>
      </div>

      <div className="ml-3 mr-3 font-semibold space-x-1.5">
        <span>{`Get 1 Month for `}</span>
        <DiscountedPrice />
        <span>{`→`}</span>
      </div>
    </>
  );
};

export const DiscountFlyoverPrice: React.FC = () => {
  const { referencePrice, addOnPrice, percentageDiscount, currency } =
    React.useContext(OfferContext);
  return !referencePrice || !addOnPrice || !percentageDiscount ? null : (
    <div className="flex items-baseline mt-2">
      <span className="mr-2 line-through opacity-70 decoration-brand-pink decoration-2">
        {formatCurrency({
          amount: referencePrice.amount / 100,
          currency,
          showZeros: true,
        })}
      </span>
      <span className="text-lg">
        <DiscountedPrice />
      </span>

      <span className="pb-1 ml-2 text-sm font-semibold text-type-green">{`(${Math.floor(
        percentageDiscount,
      )}% Savings)`}</span>
    </div>
  );
};

export const DiscountFlyoverContents: React.FC = () => {
  const { addOnProduct, addOnPrice, referencePlan, referencePrice, currency } =
    React.useContext(OfferContext);
  return !referencePlan ||
    !referencePrice ||
    !addOnProduct ||
    !addOnPrice ? null : (
    <div className="pt-4 pb-4">
      <div className="font-normal">
        <div className="flex items-center text-type-primary ">
          <div className="flex items-center flex-auto space-x-1">
            {referencePlan.slug === 'group' ? (
              <>
                <span className="mr-0.5">
                  <UsersRound size={16} />
                </span>
                <span className="font-semibold">{`${referencePlan.name} Plan`}</span>
                <span className="pt-1 text-xs font-normal">{`(Up to 5 users)`}</span>
              </>
            ) : (
              <>
                <span className="mr-0.5">
                  <UserRound size={16} />
                </span>
                <span className="font-semibold">{`${referencePlan.name} Plan`}</span>
              </>
            )}
          </div>
          <span>
            <Badge label={`Popular`} color="blue" />
          </span>
        </div>

        <div className="mt-1.5 space-y-1.5 !text-type-subdued relative">
          {referencePlan.slug === 'group' && (
            <FeatureInline name="team-editing" iconColor="text-type-primary" />
          )}

          <>
            <FeatureInline
              name="remove-branding"
              iconColor="text-type-primary"
            />
            <div className="hidden tall:block">
              <FeatureInline
                name="remove-frame-limit"
                iconColor="text-type-primary"
              />
            </div>
            <div className="hidden tall:block">
              <FeatureInline
                name="remove-export-limit"
                iconColor="text-type-primary"
              />
            </div>
            <FeatureInline
              name="storyboard-generator-with-characters"
              iconColor="text-type-primary"
            />
            <FeatureInline
              name="script-generator"
              iconColor="text-type-primary"
            />
          </>
        </div>
      </div>
      <div className="mt-8">
        <ModalDivider />
      </div>
    </div>
  );
};

export const DiscountFlyoverButton: React.FC = () => {
  const { referencePlan, coupon, offerExpiryDate } =
    React.useContext(OfferContext);

  return !referencePlan || !offerExpiryDate ? null : (
    <>
      <div className="mb-3.5 space-y-2.5 mt-1">
        <div className="flex items-end justify-center space-x-1">
          <div className="text-sm">{`Offer expires in:`}</div>
          <span className="flex-shrink-0 w-[2rem] ml-1 text-left !text-sm text-[#dc2626]">
            <SaleTimer endDate={offerExpiryDate} />
          </span>
        </div>
        <div className="flex items-center justify-center space-x-1">
          <span className="text-type-subdued pt-0.5">
            <Lock size={14} />
          </span>
          <div className="text-sm text-type-subdued">{`30-day money-back guarantee`}</div>
        </div>
      </div>

      <Button
        size="lg"
        type={`pink`}
        rounded
        className="w-full mt-2 mb-3.5"
        link={`/checkout/${referencePlan.slug}/monthly?coupon=${coupon}`}
      >
        <>{`Claim Discount`}</>
      </Button>
    </>
  );
};
