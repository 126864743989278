/** @prettier */
import * as React from 'react';
interface Props extends React.HTMLProps<HTMLCanvasElement> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  height?: number;
}

export class PureCanvas extends React.Component<Props> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { canvasRef, ...rest } = this.props;
    return (
      <canvas {...rest} ref={canvasRef} style={{ height: this.props.height }} />
    );
  }
}
