/** @prettier */
import type { PDFDocument } from 'pdf-lib';

/**
 * Reorder pages within a PDFDocument.
 * @see https://github.com/Hopding/pdf-lib/issues/506#issuecomment-650772604
 * @param pdfDoc The PDFDocument whose pages are to be reordered.
 * @param newOrder An array of the new page order. e.g. `[2, 0, 1]` means
 *                 page 2 is now page 0 and so on.
 */
export const reorderPDFPages = (pdfDoc: PDFDocument, newOrder: number[]) => {
  const pages = pdfDoc.getPages();
  for (let currentPage = 0; currentPage < newOrder.length; currentPage++) {
    pdfDoc.removePage(currentPage);
    pdfDoc.insertPage(currentPage, pages[newOrder[currentPage]]);
  }
};
