/** @prettier */
/* eslint-disable react/display-name */
import * as React from 'react';
import Tooltip from '../feedback/tooltip/Tooltip';
import Icon, { IconProps } from '../icon/Icon';
import type * as PopperJS from '@popperjs/core';
import { IconColor } from '../icon/types';

/** Simple wrapper around `<button />` and `<Icon />`  */
export interface IconButtonprops
  extends Omit<React.ButtonHTMLAttributes<unknown>, 'color'>,
    Omit<IconProps, 'onClick'> {
  color?: IconColor;
  icon: React.ReactElement;
  'aria-label': string;
  tooltipDistance?: number;
  iconClassName?: string;
  hasTooltip?: boolean;
  iconDisabled?: boolean;
  labelPlacement?: PopperJS.Placement;
  isLinkElem?: boolean;
  tooltipDisabled?: boolean;
  renderTooltipAs?: React.ComponentProps<typeof Tooltip>['as'];
  containerClassName?: string;
  download?: boolean;
}

export const IconButton = React.memo(
  React.forwardRef<HTMLButtonElement, IconButtonprops>(
    (
      {
        icon,
        fill,
        color,
        stroke,
        hoverColor,
        tooltipDistance,
        iconClassName,
        hasTooltip = true,
        renderTooltipAs = 'button',
        containerClassName,
        ...props
      },
      ref,
    ) => {
      /**
       * Passing the disabled props down to button element stops showing the tooltip altogether.
       * Therefore we are not passing down disabled prop to the button element,
       *  instead we will just mimic the disabled behavior.
       */
      const { isLinkElem, iconDisabled, labelPlacement, ...btnProps } = props;
      const { type, disabled, ...rest } = btnProps;
      if (isLinkElem) {
        renderTooltipAs = 'div';
      }
      if (renderTooltipAs === 'button' && !type) {
        //if type is not defined, we should set it as button by default.
        // Otherwise it acts as a submit button inside the form
        // and could lead to unpredictable behavior
        btnProps.type = 'button';
      }

      const IconComp = (
        <Icon
          className={iconClassName}
          icon={icon}
          color={disabled || iconDisabled ? 'disabled' : color}
          fill={fill}
          stroke={stroke}
          hoverColor={hoverColor}
          hoverable={
            !hoverColor && !disabled && props.onClick && color === 'subdued'
          }
        />
      );
      const button = isLinkElem ? (
        <a {...rest}>{IconComp}</a>
      ) : (
        // We need this div to hold the Ref otherwise our
        // tooltip won't show.
        <div className={containerClassName}>{IconComp}</div>
      );

      return hasTooltip ? (
        <Tooltip
          title={props['aria-label']}
          distance={tooltipDistance}
          placement={labelPlacement}
          domProps={btnProps}
          btnRef={ref}
          as={renderTooltipAs}
        >
          {button}
        </Tooltip>
      ) : (
        <button type="button" ref={ref} title={props['aria-label']} {...rest}>
          {button}
        </button>
      );
    },
  ),
);

IconButton.displayName = 'IconButton';
