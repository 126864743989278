/** @format */

import React, { useContext, useRef } from 'react';
import { TeamContext } from 'blackbird/components/team/TeamContext';
import Button from 'blackbird/components/button/Button';
import Dialog, { ModalDivider } from 'blackbird/components/dialog/Dialog';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Icon from 'blackbird/components/icon/Icon';
import CopyIcon from 'blackbird/images/icons/copy.svg';
import { RequestActions } from 'javascripts/flux/actions/request';
import logger from 'javascripts/helpers/logger';
import Toggle from 'blackbird/components/toggle/Toggle';
import { TeamUpgradePill } from './TeamUpgradePill';
import { LinkIcon } from 'lucide-react';

export const TeamGlobalInviteLink: React.FC = () => {
  const { teamData, isLoading, toggleSharedInviteLink } =
    useContext(TeamContext);
  const [isInviteModalOpen, setIsInviteModalOpen] = React.useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOpenDialog = () => {
    setIsInviteModalOpen(true);
  };

  const handleCloseDialog = () => {
    setIsInviteModalOpen(false);
  };

  const handleCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          RequestActions.success.defer('Invite link copied');
        }
      } catch (err) {
        logger.log(err);
      }
    }
  };

  if (!teamData) {
    return null;
  }

  const inviteLink = `${window.location.origin}/join/${teamData.shared_invite_token}`;

  return (
    <>
      <div className="flex items-center space-x-1.5">
        <div className="text-xs text-type-subdued">{`Invite via link`}</div>
        <div
          className="flex items-center justify-center w-6 h-6 rounded-full cursor-pointer bg-surface hover:bg-surface-add_frame"
          onClick={handleOpenDialog}
        >
          <LinkIcon className="w-3 h-3 text-type-subdued" />
        </div>
      </div>
      <Dialog
        isDark
        hideActions
        size="sm"
        title={`Team Invite Link`}
        isOpen={isInviteModalOpen}
        onCloseBtnClick={handleCloseDialog}
        onEscapeOrOutsideClick={handleCloseDialog}
      >
        <div className="relative pb-4">
          <div className="mb-8">
            <ModalDivider />
          </div>
          <div className="mb-4 space-y-4">
            <div className="flex items-center justify-between">
              <span>{`Enable invite link`}</span>
              {teamData.has_shared_invite_link ? (
                <Toggle
                  value={teamData.is_shared_invite_token_enabled}
                  disabled={isLoading}
                  onChange={toggleSharedInviteLink}
                />
              ) : (
                <TeamUpgradePill />
              )}
            </div>
          </div>
          {teamData.is_shared_invite_token_enabled &&
            teamData.shared_invite_token &&
            teamData.has_shared_invite_link && (
              <div>
                <TextInput
                  inputSize="md"
                  ref={inputRef}
                  value={inviteLink}
                  className="bg-surface text-type-disabled"
                  onClick={handleCopyClick}
                  readOnly
                  rightComponent={
                    <Icon onClick={handleCopyClick} icon={<CopyIcon />} />
                  }
                />
                <div className="mt-4 text-sm text-type-subdued">
                  {`Anyone with this link can join your team with full access.`}
                </div>
              </div>
            )}
        </div>
      </Dialog>
    </>
  );
};
