require('../../flux/actions/hoverOverlay');
(() => {
  class HoverOverlayStore {

    constructor() {
      this.currentOverlay = null
      this.count = 0;
      this.bindListeners({
        handleOpen:  HoverOverlayActions.OPEN,
        handleCloseAll:  HoverOverlayActions.CLOSE_ALL
      });
    }

    getId() {
      this.count++
      const id = this.count;
      return id;
    }

    handleOpen(overlayId) {
      this.currentOverlay = overlayId
      this.emitChange();
    }

    handleCloseAll() {
      this.currentOverlay = null
    }

  }

  window.HoverOverlayStore = alt.createStore(HoverOverlayStore, 'HoverOverlayStore');

})();
