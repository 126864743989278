/** @prettier */
import * as React from 'react';
import Container from '../shared/Container';
import * as ToggleButtonWithLabel from '../shared/ToggleButtonWithLabel';
import { UserActions } from '../../flux/actions/user';

(window as any).NotificationSettingsContainer = Container('user', () => ({
  onMount: UserActions.fetch.defer,
  updateNotification: newValue =>
    UserActions.updateFields({
      is_receiving_comment_notifications: newValue,
    }),
}))(props => (
  <ToggleButtonWithLabel
    onChange={props.updateNotification}
    value={props.user && props.user.is_receiving_comment_notifications}
    isBusy={!!props.isFetching}
    label="Receive emails when new comments are left on storyboards"
  />
));
