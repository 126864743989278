/** @prettier */
import * as React from 'react';
import Dialog from 'blackbird/components/dialog/Dialog';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import { ProjectsAccordion } from 'blackbird/components/projects/ProjectsAccordion';
import { ProjectsContext } from 'blackbird/components/projects/ProjectsContext';
import { TemplateSelect } from 'blackbird/components/templates/TemplateSelect';
import logger from 'javascripts/helpers/logger';

export const ProjectCreateDialog: React.FC = () => {
  const {
    isNewProjectModalOpen,
    setIsNewProjectModalOpen,
    createProject,
    newProjectGroup,
    newProjectParent,
  } = React.useContext(ProjectsContext);

  const [name, setName] = React.useState<string>('');
  const [templateId, setTemplateId] = React.useState<string>('');

  // Disable the button unless there's a
  // project name, and a parent or group present
  const confirmBtnProps = {
    disabled: name.length < 1 || (!newProjectGroup && !newProjectParent),
  };

  const handleClose = () => {
    setIsNewProjectModalOpen(false);
  };

  return !isNewProjectModalOpen ? null : (
    <Dialog
      isDark
      size={'sm'}
      title={`Create a project`}
      subtitle={`Projects keep storyboards organized in one place.`}
      isOpen
      keepOpen
      hideActionDivider
      confirmText={`Create`}
      confirmBtnProps={confirmBtnProps}
      onConfirmBtnClick={() => {
        createProject({ name, template_id: templateId });
        handleClose();
        setTimeout(() => {
          setName('');
          setTemplateId('');
        }, 50);
      }}
      onCancelBtnClick={handleClose}
      onCloseBtnClick={handleClose}
      onEscapeOrOutsideClick={handleClose}
    >
      <div>
        <TextInput
          inputSize="lg"
          autoFocus
          className="mb-6"
          labelClasses="font-semibold"
          label={`Name`}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="mb-2 font-semibold">{`Location`}</div>
        <div className="border rounded-md border-form">
          <ProjectsAccordion isSelectionMode />
        </div>
      </div>
    </Dialog>
  );
};
