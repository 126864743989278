const api = require('../../helpers/api')();
const _ = require("underscore")
const { RequestErrorHandler } = require('../../helpers/request-error-handler');
const newStoryboardErrorHandler = RequestErrorHandler('newStoryboard');

(() => {
  class NewStoryboardStore {

    constructor() {
      this.projects               = false;
      this.show_project_dropdown  = false;
      this.new_storyboard_project = false;
      this.frame_fields_cache     = {}
      this.frame_fields           = null

      this.bindListeners({
        handleFetchProjects:              NewStoryboardActions.FETCH_PROJECTS,
        handleFetchProjectsAndSetId:      NewStoryboardActions.FETCH_PROJECTS_AND_SET_ID,
        handleReceiveStoryboard:          StoryboardActions.RECEIVE,
        handleSetNewStoryboardProjectId:  NewStoryboardActions.SET_NEW_STORYBOARD_PROJECT_ID,
        handleSetFrameFields:             NewStoryboardActions.SET_FRAME_FIELDS,
      });
    }

    handleReceiveStoryboard(storyboard) {
      this.handleSetNewStoryboardProjectId(storyboard.project_id);
    }

    handleFetchProjectsAndSetId(project_id) {
      ajaxHelper.ajax({
        method: "get",
        url: "/dashboard/flattened.json",
        success: function (response) {
          this.projects = response;
          this.handleSetNewStoryboardProjectId(project_id);
          this.emitChange();
        }.bind(this)
      });
    }

    handleSetNewStoryboardProjectId(project_id) {
      if (_.isNull(project_id)) {
        this.show_project_dropdown  = true;
        this.new_storyboard_project = this.projects[0];
      } else {
        var new_project            = _.findWhere(this.projects, {id: parseInt(project_id, 10)});

        if (!_.isUndefined(new_project)) {
          this.new_storyboard_project = new_project;
        } else if (this.projects[0]) {
          // Quick hack to prevent the show_project_dropdown from being
          // shown if you haven't set a project yet.
          this.new_storyboard_project = this.projects[0];
          this.show_project_dropdown  = true;
        }
      }
    }

    handleFetchProjects() {
      ajaxHelper.ajax({
        method: "get",
        url: "/projects.json",
        success: function (response) {
          // this.projects = response.projects;
          // this.teams    = response.teams;
          // this.handleSetProjectTeamId();
          // this.handleSetNewStoryboardProjectId(selected_project_id);
          this.emitChange();
        }.bind(this)
      });
    }

    handleSetFrameFields(storyboardSlug) {
      if (!storyboardSlug) return

      if (_.has(this.frame_fields_cache, storyboardSlug)) {
        this.frame_fields = this.frame_fields_cache[storyboardSlug]
        return;
      }

      ajaxHelper.ajax({
        url: `/storyboards/${storyboardSlug}.json`,
        beforeSend: api.setRailsApiAuthHeader,
      }).then(response => {
        const result = _.isObject(response.frame_fields) ? _.values(response.frame_fields) : response.frame_fields;

        this.frame_fields_cache[storyboardSlug] = result;
        this.frame_fields = result;
        this.emitChange()
      }, newStoryboardErrorHandler({
        messageKey: 'storyboard.errors.copyFrameFields',
      }))
    }

  }

  window.NewStoryboardStore = alt.createStore(NewStoryboardStore, 'NewStoryboardStore');

})();
