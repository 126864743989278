import { ActivityLogStore } from '../stores/ActivityLog';
import { createTypedActions } from '../stores/AltStore';

class ActivityLogActions {
  fetch: ActivityLogStore['handleFetch'] = function(options) {
    this.dispatch(options);
  };
  refresh: ActivityLogStore['handleRefresh'] = function(options) {
    this.dispatch(options);
  };
}

const actions = createTypedActions<ActivityLogActions>(ActivityLogActions);
export { actions as ActivityLogActions };
