/** @prettier */
import { PDFColorScheme } from '../types';
import { getColorSchemeGreyValues } from './getContrastyGrayValues';

export const getColorScheme = (
  backgroundColor = '#ffffff',
  textOverride?: string,
): PDFColorScheme => {
  const { lightest, mid, text } = getColorSchemeGreyValues(backgroundColor);
  const textColor = textOverride || text.color;
  return {
    text: textColor,
    light: mid.color,
    veryLight: lightest.color,
    border: lightest.color,
    textIsDark: parseInt(textColor.slice(1, 3), 16) < 127,
  };
};
