/** @format */

import type { WidthAndHeight } from 'javascripts/types/frame';

/** Returns the thumbnail size to use for an image and ensures it's not too
 * low-res for some displays */
export function getThumbnailSize(
  fullSize: WidthAndHeight,
  resizeFactor = 4,
): WidthAndHeight {
  const width = fullSize.width / resizeFactor;
  const height = fullSize.height / resizeFactor;

  if (width < 480 && resizeFactor > 1) {
    return getThumbnailSize(fullSize, resizeFactor - 1);
  }

  return { width, height };
}
