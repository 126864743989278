/** @format */
type scriptEditorColorItem = [textColor: string, placeholder: string];

export const scriptEditorColors: scriptEditorColorItem[] = [
  // Reference
  ['text-brand-pink', 'placeholder-opacity-50'],
  // Voiceover
  ['text-type-orange', 'placeholder-opacity-50'],
  // Direction
  ['text-brand-blue', 'placeholder-opacity-50'],
  // Notes
  ['text-type-green', 'placeholder-opacity-50'],
];

export const getScriptEditorColor = (
  fieldIndex: number,
): scriptEditorColorItem =>
  scriptEditorColors[fieldIndex % scriptEditorColors.length];
