/**@prettier */
const { default: Button } = require('blackbird/components/button/Button');
const {
  RelativeTime,
} = require('blackbird/components/relativeTime/RelativeTime');
const { default: classNames } = require('classnames');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');

module.exports = createReactClass({
  displayName: 'ArchiveListItem',

  propTypes: {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    deleted_at: PropTypes.string,
    buttons: PropTypes.array,
  },

  render() {
    const archiveDate = new Date(this.props.deleted_at);

    return (
      <div className="border-b border-b-border py-5 relative flex items-center gap-4">
        <div className="flex-auto w-0">
          <div className="break-words">{this.props.title}</div>
          <div className="text-type-subdued truncate">
            {this.props.subtitle}
          </div>
          {this.props.deleted_at && (
            <RelativeTime
              className="text-type-subdued text-sm"
              date={archiveDate}
            />
          )}
        </div>
        <div className="flex items-center gap-3.5">
          {this.props.buttons.map(
            function (b, i) {
              return (
                <div key={'button-' + this.props.title + i}>
                  <Button
                    className={classNames({
                      'border-2 box-border border-action-secondary':
                        b.type === 'secondary',
                    })}
                    size="sm"
                    type={b.type}
                    onClick={b.onClick}
                  >
                    {b.label}
                  </Button>
                </div>
              );
            }.bind(this),
          )}
        </div>
      </div>
    );
  },
});
