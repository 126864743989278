/** @prettier */

import React, { FC } from 'react';
import Logomark from '../../images/logo/logomark.svg';
import Logotype from '../../images/logo/logotype.svg';

interface LogoProps {
  mark?: boolean;
  className?: string;
}

const Logo = React.forwardRef<HTMLDivElement, LogoProps>((props, ref) => {
  const { mark = true, className = 'w-12' } = props;

  if (mark) {
    return (
      <div className={className} ref={ref}>
        <Logomark alt="Boords" />
      </div>
    );
  }

  return (
    <div className={className} ref={ref}>
      <Logotype alt="Boords" />
    </div>
  );
});

Logo.displayName = 'Logo';
export default Logo;
