/**@prettier */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const {
  default: QuestionIcon,
} = require('blackbird/images/icons/question-small.svg');
const { default: Icon } = require('blackbird/components/icon/Icon');
const {
  default: Tooltip,
} = require('blackbird/components/feedback/tooltip/Tooltip');
window.HelpPopup = module.exports = createReactClass({
  displayName: 'HelpPopup',

  propTypes: {
    text: PropTypes.string,
  },

  _helpText() {
    return { __html: this.props.text };
  },

  render() {
    return (
      <Tooltip
        title={<div dangerouslySetInnerHTML={this._helpText()} />}
        className="p-4 items-center rounded-md bg-action-primary text-white text-sm w-56"
      >
        <div>
          <Icon icon={<QuestionIcon />} color="disabled" />
        </div>
      </Tooltip>
    );
  },
});
