const _ = require('underscore');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');

window.AdminStripeCustomerID = createReactClass({

  propTypes: {
    id: PropTypes.number.isRequired,
  },

  _active: function(e) {

    var stripeCustomerID = prompt("Please enter the Stripe Customer ID to associate this user with e.g. cus_XXXXXXXXXX");

    if (!_.isEmpty(stripeCustomerID)) {
      ajaxHelper.ajax({
        method: "post",
        url: "/admin/" + this.props.id + "/stripe_customer_id.json",
        data: {
          stripe_customer_id: stripeCustomerID,
        },
        success: function () {
          RequestActions.success.defer("Customer ID set, redirecting...");
          window.location = "/admin/" + this.props.id;
        }.bind(this),
        error: function(response, text) {
          RequestActions.error.defer(response.responseJSON.error);
        }.bind(this),
      });
    } else {
      RequestActions.error.defer("Stripe ID not changed");
    }
  },

  render: function() {
    return (
      <AdminButton
        onClick={this._active}
        text="Change Stripe Customer ID"
      />
    );
  }
});
