/** @prettier */

import React from 'react';
import type { ChangeEvent, FC, HTMLProps, ReactNode } from 'react';
import classNames from 'classnames';
import { useFocusable } from '../../helpers/hooks/useFocusable';

export interface ToggleProps
  extends Omit<
    HTMLProps<HTMLInputElement>,
    'label' | 'size' | 'value' | 'onChange'
  > {
  disabled?: boolean;
  value?: boolean;
  size?: 'compact' | 'regular' | 'large';
  onChange: ToggleOnChangeHandler;
  highlightColor?: 'black' | 'blue' | 'pink' | 'fancy' | 'premium';
  className?: string;
  name?: string;
  label?: ReactNode;
}

export type ToggleOnChangeHandler = (
  newValue: boolean,
  e: ChangeEvent<HTMLInputElement>,
) => void;

const Toggle: FC<ToggleProps> = (props) => {
  const {
    size,
    highlightColor,
    disabled,
    value,
    onChange,
    className,
    ...restProps
  } = props;

  const [ref, hasFocus, inputStyle] = useFocusable();

  return (
    <label
      className={classNames(
        'group inline-flex items-center gap-1.5 cursor-pointer leading-none',
      )}
    >
      <div
        className={classNames(
          'relative',
          size === 'compact' ? 'h-3 w-6' : 'h-4 w-8 ',
          className,
        )}
      >
        <input
          disabled={disabled}
          ref={ref}
          tabIndex={disabled ? -1 : 0}
          type="checkbox"
          checked={!!value}
          onChange={(e) => onChange(e.currentTarget.checked, e)}
          style={inputStyle}
          {...restProps}
        />
        <span
          className={classNames(
            'inline-block box-border transition-all duration-75 absolute rounded-3xl',
            size === 'large'
              ? 'w-8 px-1.3 h-5 border-0 '
              : size === 'compact'
              ? 'w-6 px-1.3 h-3.5 border-2  '
              : 'w-8 px-1.3 h-4 border-2  ',
            {
              'group-hover:bg-icon-hover': value && !disabled,
              'group-hover:border-icon-hover': !disabled,
              'cursor-not-allowed border-action-primary-disabled': disabled,
              'bg-action-primary-disabled':
                (disabled && value) || (!value && size === 'large'),
              'outline-blue': hasFocus && size !== 'large',
              'bg-transparent':
                !value && (!size || size === 'regular' || size === 'compact'),
              'bg-black border-border-dark ':
                (!highlightColor || highlightColor === 'black') &&
                value &&
                !disabled,
              'bg-gradient-to-r from-brand-yellow to-brand-pink border-0':
                highlightColor === 'fancy' && value && !disabled,
              'bg-brand-blue border-brand-blue':
                highlightColor === 'blue' && value && !disabled,
              'bg-brand-pink border-brand-pink':
                highlightColor === 'pink' && value && !disabled,
              'bg-premium-700 border-premium-700':
                highlightColor === 'premium' && value && !disabled,
            },
          )}
        />
        <span
          className={classNames(
            'inline-block absolute transition-all rounded-full border-0',
            size === 'large'
              ? 'w-4 h-4 top-[0.12rem] '
              : size === 'compact'
              ? 'w-1.5 h-1.5 top-1 '
              : 'w-1.5 h-1.5 top-1.3 ',
            value && 'bg-white',
            // Regular dot position
            value && (!size || size === 'regular') && 'right-1.3',
            !value &&
              !disabled &&
              (!size || size === 'regular') &&
              'left-1.3 bg-black group-hover:bg-icon-hover',
            !value &&
              disabled &&
              (!size || size === 'regular') &&
              'left-1.3 bg-action-primary-disabled',
            // compact dot position
            value && size === 'compact' && 'right-1',
            !value &&
              !disabled &&
              size === 'compact' &&
              'left-1.3 bg-black group-hover:bg-icon-hover',
            !value &&
              disabled &&
              size === 'compact' &&
              'left-1.3 bg-action-primary-disabled',
            // large dot position
            value && size === 'large' && 'right-[0.12rem]',
            !value &&
              !disabled &&
              size === 'large' &&
              'left-[0.12rem] bg-white',
            !value &&
              disabled &&
              size === 'large' &&
              'left-[0.12rem] bg-action-primary-disabled',
          )}
        />
      </div>
      {props.label}
    </label>
  );
};

export default Toggle;
