/** @prettier */
import * as React from 'react';
import { idleTimeout } from './idle-timeout';

/**
 * Utility function to replicate componentDidMount. It's basically a plain
 * React.useEffect, but without a return, so you do not have to think about
 * what the function returns. This saves you a few lines. e.g useEffect(Store.
 * fetch.defer)
 *
 * **Do not use this for things that attach listeners**, because you won't be
 * able to unlisten. Use a regular `React.UseEffect` for that.
 *
 * The 2nd parameter (`urgent`) will, when set to `false` use the `idleTimeout`
 * helper to wait with excution until the CPU is idle.
 */
export const useOnMount = (func?: () => void, urgent = true) => {
  const funcRef = React.useRef<(() => void) | undefined>(func);

  React.useEffect(() => {
    if (urgent) {
      funcRef.current?.();
    } else if (funcRef.current) {
      idleTimeout(funcRef.current);
    }
  }, []);
};

export const useOnDismount = (func?: () => void) => {
  React.useEffect(() => func?.());
};
