/** @prettier */
import type { Cancellable } from 'blackbird/helpers/types';

/**
 * Wait with executing this function until CPU is idle, to prevent
 * getting in the way of early-page requests. is Cancellable.
 */
export const idleTimeout = (
  func: () => void,
  minDelay = 250,
  maxDelay = 5000,
): Cancellable => {
  const idleCallback = (window as any).requestIdleCallback;
  const timeouts: ReturnType<typeof setTimeout>[] = [];
  let idleCallbackId: number;

  timeouts.push(
    setTimeout(function () {
      if (idleCallback) {
        idleCallbackId = idleCallback(func, { timeout: maxDelay - minDelay });
      } else {
        timeouts.push(setTimeout(func, maxDelay - minDelay));
      }
    }, minDelay),
  );

  return {
    cancel: () => {
      timeouts.forEach((i) => clearTimeout(i));
      if (idleCallbackId) cancelIdleCallback(idleCallbackId);
    },
  };
};

/** Similar to `idleTimeout`, but uses a promise to execute the callback.
 * Caution, this one is not cancellable  */
export const idlePromise = (minDelay = 250, maxDelay = 5000) =>
  new Promise<void>((resolve) => {
    return idleTimeout(resolve, minDelay, maxDelay);
  });
