/** @prettier */
/* eslint-disable react/display-name */
import * as React from 'react';
import type { DetailedFrame } from '../../types/frame';
import type { IStoryboard } from '../../types/storyboard';
import { FrameInfo } from '../frames/editor/FrameInfo';

export const ScriptEditorStoryboardItem = React.memo<{
  frame: DetailedFrame;
  storyboard: IStoryboard;
  isMinimal: boolean;
}>((props) => (
  <div className="frame__wrapper flex flex-col pointer-events-none opacity-50 p-3">
    <FrameImage
      frame={props.frame}
      storyboard={props.storyboard}
      isMultipleSelected={false}
      isCommenting={false}
      index={props.frame.number}
      isInteractive={false}
      isMinimal={props.isMinimal}
    />
    <FrameInfo
      frame={props.frame}
      isCommenting={false}
      storyboardId={props.storyboard.id}
      storyboardSlug={props.storyboard.slug}
      frame_fields={props.storyboard.frame_fields!}
      isInteractive={false}
      index={props.frame.number as any}
      isMinimal={props.isMinimal}
    />
  </div>
));

ScriptEditorStoryboardItem.displayName = 'ScriptEditorStoryboardItem';
