const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');

window.DashboardGroupHeader = createReactClass({

  propTypes:   {
    user:      PropTypes.object.isRequired,
    logo_url:  PropTypes.string.isRequired,
    team_id:   PropTypes.number.isRequired,
    title:     PropTypes.string.isRequired,
  },

  _isAdmin: function() {
    return (this.props.user.team_id === this.props.team_id);
  },

  _onClick: function(e) {
    e.preventDefault();
    if (this._isAdmin()) {
      window.location = "/settings/team/general";
    }
  },

  render: function() {
    const {logo_url, title} = this.props;
    const is_admin = this._isAdmin();

    return (
      <div data-hj-suppress className={"flex items-center " + (is_admin ? " pointer" : "")} onClick={this._onClick}>
        <div className="mr3 avatar" style={ {'backgroundImage': "url('" + logo_url + "')"} } />
        <div aria-label={"Open settings"} className={"f3 avenir-heavy " + (is_admin ? "hint--right" : "")}>
          {title}
        </div>
      </div>
    );
  }
});
