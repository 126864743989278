/** @format */

import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import React from 'react';
import Badge from 'blackbird/components/badge/Badge';
import { type BadgeSize } from 'blackbird/components/badge/types';

interface GuidelineBetaBadgeProps {
  size: BadgeSize;
}

export const GuidelineBetaBadge: React.FC<GuidelineBetaBadgeProps> = ({
  size,
}) => {
  return (
    <div className="relative">
      <Tooltip
        title={
          <div className="text-sm space-y-2">
            <div className="font-semibold">{`✨ Character Guidelines help you create more consistent AI-generated characters in your storyboards.`}</div>
            <div>{`Please note Character Guidelines are in beta, and even with a character selected, the AI may produce unexpected results. If so, you can try:`}</div>
            <div className="">
              <div>{`• Regenerating your image`}</div>
              <div>{`• Editing your character attributes`}</div>
              <div>{`• Editing the character prompt`}</div>
            </div>
            <div>{`The Character Guidelines feature is continually evolving, and your feedback helps us improve!`}</div>
          </div>
        }
      >
        <span>
          <Badge
            label={`Beta`}
            color="blue"
            size={size}
            className="cursor-help"
          />
        </span>
      </Tooltip>
    </div>
  );
};
