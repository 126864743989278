/** @prettier */
import * as React from 'react';
import { type currentUserFeaturesType } from '../../flux/stores/user_permissions';
import { PricingFeatureLocked } from 'blackbird/components/pricing/PricingFeatureLocked';

export const UserPermissionFlyover = (props: {
  featureName: currentUserFeaturesType;
  userId: number;
  limit?: number;
  identifier?: string;
  count?: number;
  team_admin_id?: number;
  hideClose?: boolean;
}) => {
  const { featureName } = props;

  return <PricingFeatureLocked triggeredFromFeatureName={featureName} />;
};
