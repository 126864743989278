/** @format */

import { calculateTimeLeft } from './timeRemaining';
import React, { type ReactElement, useState, useEffect } from 'react';

interface TimerProps {
  endDate: Date | string;
}

export const CountdownTimer: React.FC<TimerProps> = ({ endDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);
    return () => clearInterval(interval);
  }, [endDate]);

  const timerComponents: ReactElement[] = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      // if (!timeLeft[interval] || interval === 's') {
      return;
    }

    timerComponents.push(
      <span key={`${interval}-${timeLeft[interval]}`}>
        {timeLeft[interval]}
        {interval}{' '}
      </span>,
    );
  });

  return timerComponents.length ? <span>{timerComponents}</span> : null;
};
