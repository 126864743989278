/**@prettier */
const {
  LoadingIndicator,
} = require('blackbird/components/common/loading-indicator/LoadingIndicator');
const createReactClass = require('create-react-class');
var FrameOverlayMessage = createReactClass({
  render: function () {
    var subtitle;

    if (this.props.isError) {
      subtitle =
        'The Boords Team are aware and are working hard to resolve this issue as soon as possible.';
    } else {
      subtitle = this.props.subtitle;
    }

    return (
      <LoadingIndicator
        fill
        text={
          <div className="space-y-2">
            <h2>{this.props.title}</h2>
            <p className="text-sm text-type-subdued">{subtitle}</p>
          </div>
        }
      />
    );
  },
});

window.FrameOverlayMessage = FrameOverlayMessage;
