/** @format */
import * as React from 'react';
import Spinner from 'blackbird/images/icons/spinner.svg';
import Icon from 'blackbird/components/icon/Icon';
import classNames from 'classnames';
import { PopTransition } from '../Transitions';

export const LoadingIndicator: React.VFC<{
  /** Fills the available size and centers itself */
  fill?: boolean;
  /**
   * Used to style the container. Useful in cases we wan't a horizontal layout
   */
  containerClassName?: string;
  /** Used for setting the width of the icon. Defaults to `w-5` */
  className?: string;
  text?: React.ReactNode;
  /** Show or hide the loading indicator, this is passed along to a Transition
   * component. You can use this rather than conditionally rendering the loading
   * indicator */
  show?: boolean;
  /** Whether to 'pop' in */
  appear?: boolean;
  /** Show secondary text style */
  secondary?: boolean;
  /** Applies a standard amount of padding to the container */
  pad?: boolean;
  /** Applies a light background to the loading indicator in case the
   * background is too dark and contrast issues appear  */
  bg?: boolean;
}> = ({
  className = 'w-5',
  text,
  fill,
  secondary = false,
  appear = true,
  show = true,
  containerClassName,
  ...props
}) => {
  const padding = props.pad ? 'p-10' : undefined;
  const bg = props.bg ? 'bg-surface/90 backdrop-blur-sm' : undefined;

  const icon = (
    <div className={classNames('text-center', containerClassName)}>
      <Icon
        className={classNames('animate-spin', className)}
        icon={<Spinner />}
      />

      {/* Add styling only if text is a string. If it's React Element,
      apply styling on the Element itself */}
      {typeof text === 'string' ? (
        <div
          className={classNames(
            'mx-auto mt-7 max-w-prose',
            secondary ? 'text-sm text-type-subdued' : 'text-lg',
          )}
        >
          {text}
        </div>
      ) : (
        text
      )}
    </div>
  );

  return (
    <PopTransition
      appear={appear}
      show={show}
      className={classNames(
        fill
          ? 'z-[1] absolute inset-0 text-center flex justify-center items-center'
          : padding,
        fill && bg,
      )}
    >
      {icon}
    </PopTransition>
  );
};
