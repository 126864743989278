import { RequestErrorHandler } from "javascripts/helpers/request-error-handler";
import { isFileTypeAccepted } from "javascripts/helpers/isFileTypeAccepted";
import { isEmpty } from 'underscore';

const errorHandler = RequestErrorHandler('PDFFooterLogo');

(() => {
  class PdfFooterLogoStore {

    constructor() {
      this.file          = null;
      this.is_loaded     = false;
      this.force_restart = false;
      this.response      = {};

      this.bindListeners({
        handleFetch:    PdfFooterLogoActions.FETCH,
        handleUpload:   PdfFooterLogoActions.UPLOAD,
        handleRemove:   PdfFooterLogoActions.REMOVE,
        handleRestart:  PdfFooterLogoActions.RESTART,
        handleRestore:  PdfFooterLogoActions.RESTORE,
      });
    }

    handleRestart() {
      this.force_restart = true;
    }

    handleCheckIfProcessing(data) {

      if (this.response.is_processing) {

        window.PDFFooterLogoTimeout = setTimeout(function() {
          this.handleFetch(data);
        }.bind(this), 1500);

      } else {

        clearTimeout(window.PDFFooterLogoTimeout);
      }
    }

    handleRestore(data) {
      ajaxHelper.ajax({
        method: "post",
        url: "/storyboards/" + data.storyboard_id + "/pdf_footer_logo/restore.json",
        success: function (response) {
          this.response = response;
          RequestActions.success.defer("Boords logo restored");
          CoverpageActions.fetch.defer(data.storyboard_id);
          this.emitChange();
        }.bind(this),
        error: errorHandler({
          message: "Sorry, we couldn't restore the logo"
        }),
      });
    }

    handleRemove(data) {
      ajaxHelper.ajax({
        method: "delete",
        url: "/storyboards/" + data.storyboard_id + "/pdf_footer_logo/" + data.storyboard_id + ".json",
        success: function (response) {
          this.response = response;
          RequestActions.success.defer("PDF logo removed");
          CoverpageActions.fetch.defer(data.storyboard_id);
          this.emitChange();
        }.bind(this),
        error: errorHandler({ message: "Sorry, we couldn't remove your logo" })
      });
    }

    handleFetch(data) {
      ajaxHelper.ajax({
        method: "get",
        url: "/storyboards/" + data.storyboard_id + "/pdf_footer_logo.json",
        success: function (response) {

          if (!isEmpty(this.response) && !response.is_processing && this.response.is_processing) {
            RequestActions.success.defer("PDF logo uploaded successfully!");
            CoverpageActions.fetch.defer(data.storyboard_id);
          }

          this.response      = response;
          this.is_loaded = true;
          this.emitChange();

          this.handleCheckIfProcessing({
            storyboard_id: data.storyboard_id,
          });

        }.bind(this),
        error: errorHandler({ message: "Sorry, we couldn't get your PDF footer logo" })
      });
    }

    handleUpload(args) {
      this.is_working = true;
      this.emitChange();

      ajaxHelper.ajax({
        method: "post",
        url: "/storyboards/" + args.storyboard_id + "/pdf_footer_logo.json",
        data: {
          response: args.response
        },
        success: (response) =>{
          this.response  = response;
          this.is_loaded = true;
          this.emitChange();
        },
        error: errorHandler({ message: "Sorry, we couldn't upload your logo" }, () => {
          this.is_working = false;
          this.emitChange();
        })
      });
    }

    _verifyFile(file) {
      var allowedTypes = ["image/jpg", "image/jpeg"];
      var  maxSizeInMB     = 2;

      if (!isFileTypeAccepted(allowedTypes, file.type)) {
        RequestActions.error.defer({ key: 'sharedErrors.unsupportedUpload', data: { fileType: file.type, formats: 'jpg' } }); return false;
      } else if ((parseInt(file.size, 10)/1000000).toFixed(2) > maxSizeInMB) {
        RequestActions.error.defer({ key: 'sharedErrors.fileSizeExceeded', data: { maxSize: maxSizeInMB + "MB" } });
        return false;
      } else {
        return true;
      }
    }
  }

  window.PdfFooterLogoStore = alt.createStore(PdfFooterLogoStore, 'PdfFooterLogoStore');

})();
