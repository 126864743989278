/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { Listbox } from '@headlessui/react';
import {
  PlusIcon,
  FolderIcon,
  type LucideIcon,
  ImagePlusIcon,
} from 'lucide-react';
import classNames from 'classnames';
import Button from '../../button/Button';
import Panel from '../../panel/Panel';
import { ProjectsContext } from '../ProjectsContext';

interface NewOption {
  label: string;
  icon: LucideIcon;
  onClick: () => void;
}

export const ProjectNewButton: React.FC = () => {
  const {
    setNewProjectParent,
    setIsNewProjectModalOpen,
    activeProject,
    activeGroup,
  } = useContext(ProjectsContext);
  const [options, setOptions] = useState<NewOption[] | null>(null);

  useEffect(() => {
    if (activeGroup) {
      const newOptions: NewOption[] = [];

      if (activeGroup.actions.includes('storyboards.create')) {
        newOptions.push({
          label: 'Create storyboard',
          icon: ImagePlusIcon,
          onClick: () => {
            FlyoverActions.open({
              type: 'newStoryboard',
              projectId: activeProject?.id,
              teamId: activeGroup.team_id,
              props: {
                actions: activeGroup.actions,
                defaultTemplateId: activeProject?.template_id,
              },
            });
          },
        });
      }

      if (activeGroup.actions.includes('projects.create')) {
        newOptions.push({
          label: 'Create sub-project',
          icon: FolderIcon,
          onClick: () => {
            setNewProjectParent(activeProject);
            setIsNewProjectModalOpen(true);
          },
        });
      }

      setOptions(newOptions.length > 0 ? newOptions : null);
    } else {
      setOptions(null);
    }
  }, [
    activeGroup,
    activeProject,
    setNewProjectParent,
    setIsNewProjectModalOpen,
  ]);

  const handleChange = (option: NewOption) => {
    option.onClick();
  };

  if (!options) {
    return null;
  }

  return (
    <Listbox
      as="div"
      className="relative group/projectnew"
      value={null}
      onChange={handleChange}
    >
      {() => (
        <>
          <Listbox.Button as={React.Fragment}>
            <Button type="solid">
              <span className="flex items-center">
                <span className="mr-1.5 -ml-1.5 pt-0.5 leading-none">
                  <PlusIcon className="w-5 h-5" strokeWidth={2.5} />
                </span>
                <span>{`New`}</span>
              </span>
            </Button>
          </Listbox.Button>
          <Listbox.Options
            as={Panel}
            className="absolute right-0 flex-col w-48 mt-2 border shadow-md rounded-md border-border top-full focus:outline-none"
          >
            <div className="p-2 space-y-1">
              {options.map((option) => (
                <Listbox.Option
                  key={option.label}
                  value={option}
                  as="div"
                  className={({ active }) =>
                    classNames(
                      'cursor-pointer select-none relative pb-2 pt-1.5 px-2 rounded-md flex items-center space-x-2',
                      active ? 'bg-surface-light' : '',
                    )
                  }
                >
                  {({ active }) => (
                    <>
                      <option.icon className="h-4 w-4 pt-0.5" />
                      <span className="text-sm">{option.label}</span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </div>
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};
