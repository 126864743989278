/** @prettier */
import { isInInput } from './isInInput';
import { clone } from 'underscore';

interface Stack<SI> {
  getStack: () => SI[];
  clearStack: () => void;
  getCurrent: () => SI;
  indexOf: (item: SI) => number;
  add: (item: SI) => void;
  navigateTo: (newIndex: number) => SI;
  canNavigateTo: (newIndex: number) => boolean;
  getCurrentIndex: () => number;
  getLength: () => number;
}

/**
 * This code allows for the creation of navigatable stacks of items, such as
 * an undo history. You can navigate forwards/backwards and add items to the
 * stack
 */
const newStack = <SI>(
  stackName: string = 'stack',
  shouldClone = true,
): Stack<SI> => {
  let stack: SI[] = [];
  let currentIndex = -1;

  const getCurrent = () => stack[currentIndex];
  const canNavigateTo = (newIndex) =>
    newIndex >= 0 && newIndex <= stack.length - 1;

  return {
    getStack: () => stack,
    clearStack: () => {
      currentIndex = -1;
      stack = [];
    },
    getCurrent,
    canNavigateTo,
    getCurrentIndex: () => currentIndex,
    getLength: () => stack.length,
    indexOf: (item) => stack.indexOf(item),
    add(item) {
      // We shallow clone the item, so that the data cannot be mutated
      const toAdd = shouldClone ? clone(item) : item;
      stack = [...stack.slice(0, currentIndex + 1), toAdd];
      currentIndex = stack.length - 1;
    },
    navigateTo(newIndex) {
      if (newIndex < 0 || newIndex > stack.length - 1)
        throw new Error(
          `cannot navigate ${stackName}, new index ${newIndex} is not valid [0-${
            stack.length - 1
          }]`,
        );

      currentIndex = newIndex;
      return getCurrent();
    },
  };
};

/** pass this history type */
interface UndoStackInfo<SI> {
  name: string;
  stack: Stack<SI>;
  callback?: (newState: SI) => void;
}

/**
 * creates a new manager for undo stacks and registers the hotkeys. It returns
 * a number of functions to manipulate the undo stacks.
 */
export const createUndoStackManager = (keyEvent?: string) => {
  const contexts = newStack<UndoStackInfo<unknown>>('contexts', false);

  const navigate = (delta: number) => {
    const { stack, callback } = contexts.getCurrent();
    const newIndex = stack.getCurrentIndex() + delta;
    if (!stack.canNavigateTo(newIndex)) return;
    const newItem = stack.navigateTo(newIndex);

    if (callback) callback(newItem);
  };

  const triggerUndo = () => navigate(-1);
  const triggerRedo = () => navigate(1);

  if (keyEvent) {
    window.addEventListener(keyEvent, (e: KeyboardEvent) => {
      const isInput = isInInput(e.target);

      if (!isInput && e.key === 'z' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        navigate(e.shiftKey ? 1 : -1);
      } else if (!isInput && e.key === 'y' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        navigate(1);
      }
    });
  }

  /**
   * @param stackName Name for this specific stack, not really used at the
   * moment
   * @param clone Wether or not to clone the state objects, this is recommended in most cases to ensure that objects and arrays won't be mutated after being saved to the undo stack.
   */
  function newUndoContext<CT>(stackName: string, clone: boolean = true) {
    /** Callback to be fired when the history state changes */
    type changeCallback = (newState: CT) => void;
    const history = newStack<CT>(`${stackName} history`, clone);

    const stackData: UndoStackInfo<CT> = {
      name: stackName,
      stack: history,
    };

    const canNavigate = (delta: number) => {
      const newIndex = history.getCurrentIndex() + delta;
      return history.canNavigateTo(newIndex);
    };

    const canUndo = () => canNavigate(-1);
    const canRedo = () => canNavigate(1);

    return {
      enterContext: (callback?: changeCallback) => {
        contexts.add(stackData);
        if (callback && stackData.callback && stackData.callback !== callback)
          throw new Error('Undo stack can have only one callback registered');

        stackData.callback = callback;
      },
      leaveContext: () => {
        const newIndex = contexts.indexOf(stackData) - 1;
        delete stackData.callback;
        contexts.navigateTo(newIndex);
      },
      add: history.add,
      getCurrent: history.getCurrent,
      getCurrentIndex: history.getCurrentIndex,
      getLength: history.getLength,
      clearStack: history.clearStack,
      navigate,
      stackData,
      canUndo,
      canRedo,
    };
  }

  // Create an initial state, won't be used, but that way there's always a
  // context present, which means every newer context can be left without issues
  newUndoContext('initial').enterContext();

  return {
    newUndoContext,
    getCurrentContext: contexts.getCurrent,
    triggerUndo,
    triggerRedo,
  };
};

export type UndoStackManager = ReturnType<typeof createUndoStackManager>;
export type UndoContext = ReturnType<UndoStackManager['newUndoContext']>;
