/** @prettier */

import React, { createContext, useState, useMemo } from 'react';
import { memoize } from 'underscore';
import Dialog, { type DialogProps } from './Dialog';

const DialogContext = createContext<{
  dialogState?: DialogProps;
  setDialogState: React.Dispatch<React.SetStateAction<DialogProps | undefined>>;
}>({
  dialogState: undefined,
  setDialogState: () => {},
});
const DialogContextProvider: React.FC = ({ children }) => {
  const [dialogState, setDialogState] = useState<DialogProps>();
  const dialogContextValue = useMemo(
    () => ({ dialogState, setDialogState }),
    [dialogState],
  );

  /** Closes the dialog and passes through any additional closing handler that
   * should be performed according to the dialogState */
  const closeAndClick = memoize((fn?: () => void) => () => {
    setDialogState((current) => ({ ...current, isOpen: false }));
    fn?.();
  });

  return (
    <DialogContext.Provider value={dialogContextValue}>
      {children}
      {dialogState && (
        <Dialog
          {...dialogState}
          onEscapeOrOutsideClick={closeAndClick(
            dialogState.onEscapeOrOutsideClick,
          )}
          onConfirmBtnClick={closeAndClick(dialogState.onConfirmBtnClick)}
        />
      )}
    </DialogContext.Provider>
  );
};

export default DialogContextProvider;
export { DialogContext };
