/** @format */

import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Listbox } from '@headlessui/react';
import { ArrowDown, ArrowUp, CheckIcon, CirclePlus } from 'lucide-react';
import classNames from 'classnames';
import Button from '../button/Button';
import { ProjectsEmpty } from '../projects/empty/ProjectsEmpty';
import { useTemplates } from './TemplatesContext';
import { TemplateFormDialog } from './TemplateFormDialog';
import Tooltip from '../feedback/tooltip/Tooltip';
import { PlusIcon } from 'lucide-react';
import { SingleTemplate } from './SingleTemplate';
import logger from 'javascripts/helpers/logger';
import { ProjectsContext } from '../projects/ProjectsContext';
import {
  ProjectSkeletonGrid,
  ProjectSkeletonHeader,
} from '../projects/ProjectSkeleton';

type SortField = 'name' | 'created_at' | 'updated_at';
type SortOrder = 'asc' | 'desc';

interface SortOption {
  value: SortField;
  label: string;
}

const sortOptions: SortOption[] = [
  { value: 'name', label: 'Name' },
  { value: 'created_at', label: 'Date created' },
  { value: 'updated_at', label: 'Date modified' },
];

export const TemplatesContainer: React.FC = () => {
  const { setViewType } = useContext(ProjectsContext);
  const {
    setCurrentTemplate,
    fetchTemplates,
    setIsTemplateModalOpen,
    isFetched,
    templates,
  } = useTemplates();
  const [sortField, setSortField] = useState<SortField>('name');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  useEffect(() => {
    setCurrentTemplate(null);
  }, []);

  useEffect(() => {
    const teamId = window.location.pathname.split('/').pop();
    document.title = `Templates • Boords`;
    if (teamId) {
      fetchTemplates(teamId);
    }
    return () => {
      setCurrentTemplate(null);
      setViewType('projects');
    };
  }, []);

  const sortedTemplates = useMemo(() => {
    return [...templates].sort((a, b) => {
      if (sortField === 'name') {
        return sortOrder === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else {
        const aDate = new Date(a[sortField]).getTime();
        const bDate = new Date(b[sortField]).getTime();
        return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
      }
    });
  }, [templates, sortField, sortOrder]);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  if (!isFetched) {
    return (
      <div className="max-h-full overflow-hidden">
        <ProjectSkeletonHeader />
        <ProjectSkeletonGrid />
      </div>
    );
  }

  const TemplatesBreadcrumb: React.FC = () => {
    return (
      <div className="flex items-start mb-2 text-sm">
        <div
          className="underline truncate cursor-pointer text-type-subdued whitespace-nowrap decoration-border-mid hover:no-underline underline-offset-2"
          onClick={() => setViewType('projects')}
        >
          {`Dashboard`}
        </div>
        <div className="text-type-disabled mx-1.5">/</div>
        <div className="truncate text-type-subdued whitespace-nowrap">
          {`Templates`}
        </div>
      </div>
    );
  };

  const TemplateSort: React.FC = () => (
    <>
      {templates.length > 0 && (
        <div className="flex items-center justify-end mb-0">
          <Listbox
            as="div"
            className="relative z-50 inline-block text-left"
            value={sortField}
            onChange={setSortField}
          >
            <Listbox.Button className="inline-flex justify-center w-full px-2 pb-1.5 pt-1 text-sm font-semibold rounded-md hover:bg-surface focus:outline-none">
              <span>
                {
                  sortOptions.find((option) => option.value === sortField)
                    ?.label
                }
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute right-0 z-40 w-40 mt-2 bg-white border shadow-lg origin-top-right rounded-md focus:outline-none border-border">
              <div className="p-2 space-y-1">
                <div className="px-3 pt-1.5 pb-1 text-xs font-semibold tracking-wider uppercase text-type-subdued">{`Sort by`}</div>
                {sortOptions.map((option) => (
                  <Listbox.Option key={option.value} value={option.value}>
                    {({ selected }) => (
                      <div
                        className={classNames(
                          'flex items-center text-sm cursor-pointer relative pb-2 pt-1.5 px-3 rounded-md',
                          selected
                            ? 'bg-surface-light'
                            : ' hover:bg-surface-light',
                        )}
                      >
                        <span className="flex-auto">{option.label}</span>
                        {selected && (
                          <CheckIcon className="flex-shrink-0 w-3.5 h-3.5" />
                        )}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </div>
            </Listbox.Options>
          </Listbox>
          <Tooltip title={`Sort ascending/descending`} placement="top">
            <div
              className="p-2 cursor-pointer rounded-md hover:bg-surface"
              onClick={toggleSortOrder}
            >
              {sortOrder === 'asc' ? (
                <ArrowDown strokeWidth={2.5} className="w-4 h-4" />
              ) : (
                <ArrowUp strokeWidth={2.5} className="w-4 h-4" />
              )}
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );

  return (
    <>
      <TemplateFormDialog />
      <div className="min-h-full bg-surface">
        <div className="px-10 pt-6 pb-4 bg-white border-b border-border">
          <div className="flex items-start justify-between mb-4">
            <div className="">
              <TemplatesBreadcrumb />
              <h1 className="text-2xl font-bold">{`Storyboard Templates`}</h1>
              <p className="mt-4 text-type-subdued">{`Build reusable templates to streamline your storyboarding workflow.`}</p>
            </div>
            <div className="">
              <Button
                type="solid"
                size="md"
                onClick={() => setIsTemplateModalOpen(true)}
              >
                <span className="flex items-center">
                  <span className="mr-1.5 -ml-1.5 pt-0.5 leading-none">
                    <PlusIcon className="w-5 h-5" strokeWidth={2.5} />
                  </span>
                  <span>{`New Template`}</span>
                </span>
              </Button>
              <div className="mt-[2.6rem]">
                <TemplateSort />
              </div>
            </div>
          </div>
        </div>
        <div className="px-10 py-8">
          {templates.length > 0 ? (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxxl:grid-cols-6 gap-x-3 gap-y-5 -ml-1.5">
                {/* <div */}
                {/*   onClick={() => setIsTemplateModalOpen(true)} */}
                {/*   className="group/newtemplate cursor-pointer bg-surface-frame rounded-md min-h-[100px] flex items-center justify-center hover:bg-surface-add_frame text-center hover:text-brand-pink text-type-disabled m-1.5" */}
                {/* > */}
                {/*   <div> */}
                {/*     <div className="flex justify-center group-hover/newtemplate:text-type-subdued text-type-disabled"> */}
                {/*       <CirclePlus size={36} strokeWidth={1} /> */}
                {/*     </div> */}
                {/*     <div className="mt-4 text-type-subdued">{`New Template`}</div> */}
                {/*   </div> */}
                {/* </div> */}
                {sortedTemplates.map((template) => (
                  <SingleTemplate template={template} key={template.id} />
                ))}
              </div>
            </>
          ) : (
            <div className="mt-8 text-center text-gray-500">
              <ProjectsEmpty
                title={`Create your first template`}
                subtitle={`Save time and maintain consistency by creating reusable templates for your team's storyboards.`}
              >
                <Button
                  type="outline"
                  size="lg"
                  onClick={() => setIsTemplateModalOpen(true)}
                >{`Create Template`}</Button>
              </ProjectsEmpty>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
