/** @prettier */
import { FrameActions } from '../actions/frame';
import { idleTimeout } from '../../helpers/idle-timeout';
import {
  editableCommentsLocalState,
  LocalState,
} from '../../helpers/local-state';
import { FakeAltStoreClass } from './AltStore';
import { PanelbarActions } from '../actions/panelbar';
import { UserActions } from '../actions/user';
import type { frameId, IFrame } from '../../types/frame';
import { CommentActions } from '../actions/comment';
import { StoryboardActions } from '../actions/storyboard';
import { RequestActions } from '../actions/request';
import { ajax, ajaxJSONRest, ajaxRuby } from 'javascripts/helpers/ajax';
import { removeNullCharacters } from 'javascripts/helpers/removeNullCharacters';
import {
  find,
  findLastIndex,
  indexBy,
  isUndefined,
  reduce,
  reject,
  some,
  sortBy,
} from 'underscore';
import {
  eventSource,
  type EventSource,
} from 'blackbird/helpers/eventContextHelper';
import type { FrameEditorLayerData } from 'javascripts/components/frame_editor/types';
import { toJSONIfObject } from 'javascripts/helpers/frame/frameJSON';
import { differenceInSeconds } from 'date-fns';
import { SetOptional } from 'type-fest';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { deserialise } from 'kitsu-core';
import { retryWhile } from 'javascripts/helpers/retry-while';
import logger from 'javascripts/helpers/logger';
require('../actions/panelbar');
require('../actions/user');
require('../actions/realtime');

const _ = require('underscore');

const commentErrorHandler = RequestErrorHandler('comments');

const emptyCommentData = (): NewCommentData => ({
  name: LocalState.getValue('commentName'),
  comment: '',
  annotation: null,
});

export interface CommentUser {
  id?: number;
  name: string;
  avatar?: string;
}

export interface CommentSaveProps {
  text: string;
  frameId: frameId;
  /** This is optional in case of logged in users, but is typically filled in */
  name?: string;
  in_reply_to_comment_id?: number;
  isTeamOnly?: boolean;
  context?: EventSource;
  storyboardSlug: string;
  annotation: CommentAnnotationData | null;
  callback?: (comment: Comment) => void;
}

export type CommentAnnotationData = FrameEditorLayerData;
export type PossibleCommentAnnotation = FrameEditorLayerData | null;

export interface Comment {
  id: number;
  frame_id: number;
  text: string;
  in_reply_to_comment_id?: number | null;
  isTeamOnly?: boolean;
  user: CommentUser;
  acknowledged_by?: unknown;
  created_at: string;
  /** @deprecated please calculate the relative time on the frontend, for
   * example by using <RelativeTime /> */
  created_time_ago: string;
  replies?: Comment[];
  annotation: CommentAnnotationData | null;
  /** Currently only a client-side attribute */
  modified_at?: string;
}

export interface NewCommentData {
  name?: string;
  comment: string;
  annotation: CommentAnnotationData | null;
}

interface NewCommentRequestParams {
  frame_id: frameId;
  text: string;
  guest_name: string;
  in_reply_to_comment_id?: number;
  isTeamOnly?: boolean;
  annotation: string | null;
}
export interface CommentSection {
  frameId: number;
  index: string;
  sort_order: number;
  comments: Comment[];
  count: number;
  frame: IFrame;
}
export class CommentStore extends FakeAltStoreClass<CommentStore> {
  storyboardId: number;
  is_listening: boolean;
  isPanelbarOpen = false;
  pusher_loaded = false;
  commentsUnfiltered = false;
  commentsRaw?: Comment[];
  commentsRawById: Record<string, Comment | undefined> = {};
  commentSections: CommentSection[] = [];
  commentSectionsById: Record<string, CommentSection> = {};
  commentCount = 0;
  hasHiddenComments = false;
  fetched = false;
  fetching = false;
  isPosting = false;
  activeFrame?: CommentSection | null;
  commentingInFrame = false;
  commentingInPanelbar = false;
  newCommentData = emptyCommentData();
  maxCommentLength = 10240;

  constructor() {
    super();
    this.bindListeners({
      handleAcknowledge: CommentActions.ACKNOWLEDGE,
      handleClearActiveFrame: [
        CommentActions.CLEAR_ACTIVE_FRAME,
        PanelbarActions.CLOSE,
      ],
      handleCloseFrameCommentBox: CommentActions.CLOSE_FRAME_COMMENT_BOX,
      handleDelete: CommentActions.DELETE,
      handleFetch: CommentActions.FETCH,
      handleFetchIfNecessary: CommentActions.FETCH_IF_NECESSARY,
      handleOpenFrameCommentBox: CommentActions.OPEN_FRAME_COMMENT_BOX,
      handleToggleFrameCommentBox: CommentActions.TOGGLE_FRAME_COMMENT_BOX,
      handleOpenPanelbarCommentBox: CommentActions.OPEN_PANELBAR_COMMENT_BOX,
      handlePanelbarClose: PanelbarActions.CLOSE,
      handlePanelbarOpen: PanelbarActions.OPEN,
      handleReceive: CommentActions.RECEIVE,
      handleRefreshComments: [
        FrameActions.SORT_FRAMES,
        FrameActions.INSERT_FRAME,
        FrameActions.DELETE_FRAMES,
        StoryboardActions.UPDATE_PREFERENCE,
        UserActions.RECEIVE,
      ],
      handleSave: CommentActions.SAVE,
      handleSetActiveFrame: CommentActions.SET_ACTIVE_FRAME,
      handleUpdate: CommentActions.UPDATE,
      handlePusherLoaded: RealtimeActions.PUSHER_LOADED,
      handleUpdateNewCommentData: CommentActions.UPDATE_NEW_COMMENT_DATA,
      handleReceiveFrames: FrameActions.RECEIVE_FRAMES,
    });
  }

  handleAcknowledge(data) {
    ajax({
      method: 'post',
      url:
        '/storyboards/' +
        data.storyboard_id +
        '/comments/' +
        data.comment_id +
        '/acknowledge.json',
      data: {
        acknowledged: data.acknowledged,
      },
      success: (response) => {
        const index = this.commentsRaw!.findIndex(
          (c) => c.id === data.comment_id,
        );
        // It is possible that the realtime event for this action has arrived
        // already by this point, in which case the comment might have been
        // removed from `commentsRaw`. In that case, we do nothing in order to
        // prevent an error message
        if (index >= 0) {
          this._updateComment(data.comment_id, response);
          this.emitChange();
        }
      },
      error: commentErrorHandler(),
    });
  }

  handlePanelbarOpen() {
    this.isPanelbarOpen = true;
  }

  handlePanelbarClose() {
    this.isPanelbarOpen = false;
  }

  handlePusherLoaded() {
    this.pusher_loaded = true;
    this._tryRealtime();
  }

  _tryRealtime() {
    if (!this.is_listening && this.pusher_loaded && this.storyboardId) {
      this.is_listening = true;
      var channel = pusher.subscribe(
        'storyboard-comments-' + this.storyboardId,
      );

      const hasCommentingRole =
        BoordsConfig.TeamRole &&
        [`admin`, `supermember`, `manager`, `member`].includes(
          BoordsConfig.TeamRole,
        );

      channel.bind('create', (data) => {
        if (
          !data.message.isTeamOnly ||
          (data.message.isTeamOnly && hasCommentingRole)
        ) {
          const id = parseInt(data.message.id);
          // Wait until posting is completed before we try to fetch the comment
          // again,in order to prevent a race condition
          retryWhile(
            () => !this.isPosting,
            () => {
              // Don't need to do anything if we're the originator of this comment
              if (!this.commentsRawById[id]) {
                this.commentsRaw!.push(data.message);
                this.fetchComment(id);
              }
            },
          );
        }
      });

      channel.bind(
        'destroy',
        function (data) {
          this.commentsRaw = reject(this.commentsRaw, function (c) {
            return c.id == data.message.id;
          });
          this.commentsRawById = indexBy(this.commentsRaw, 'id');
          CommentActions.receive(this.commentsRaw);
        }.bind(this),
      );

      channel.bind(
        'update',
        function (data) {
          this._updateComment(data.message.id, data.message);
        }.bind(this),
      );
    }
  }

  /** updates a comment from a realtime call. Be aware that we receive this for
   * edits made from this session as well (there's currently no way of
   * distinguishing) */
  _updateComment(comment_id, comment: SetOptional<Comment, 'annotation'>) {
    if (!this.commentsRaw) return;
    const index = this.commentsRaw.findIndex((c) => c.id === comment_id);
    const found = this.commentsRaw[index];
    if (!found)
      return commentErrorHandler({ severity: 'warn', messageKey: null })(
        new Error(`Cannot find the comment we're supposed to update`),
      );

    // Leave the existing annotation around if we have it (unless it gets set to
    // null or undefined)
    const newComment = (this.commentsRaw[index] = {
      annotation: found.annotation,
      modified_at: found.modified_at,
      ...comment,
    });

    CommentActions.receive([...this.commentsRaw]);

    // Upon updatin, we've set a `modified_at` property on the client side, so
    // when that is present we know it's likely coming from this machine
    const modified = newComment.modified_at && new Date(newComment.modified_at);

    // if the comment has no annotation value, that means that we weren't able
    // to include it in the payload for size reasons, so we need to fetch it
    // manually.
    if (isUndefined(comment.annotation)) {
      if (
        !modified ||
        (modified && differenceInSeconds(modified, new Date()) > 5)
      ) {
        this.fetchComment(newComment.id);
      }
    }
  }

  /** Fetch a specific comment (for example if we don't have an annotation yet) */
  fetchComment(id: number) {
    if (!this.commentsRaw) return;
    const matchingCommentIndex = findLastIndex(
      this.commentsRaw!,
      (i) => i.id === id,
    );

    ajaxJSONRest({
      url: `comments/${id}`,
      success: (response) => {
        const result = deserialise(response).data?.[0];
        if (!result) {
          throw new Error('unexpected data returned');
        }

        /** the response we get back is in the JSONAPI format and doesn't follow
         * the same structure as the rest of the API, so we have to convert */
        const newComment: Comment = {
          ...(this.commentsRawById[id] ?? {}),
          id: id,
          frame_id: parseInt(result.frame.data.id),
          text: result.body,
          in_reply_to_comment_id: result.in_reply_to_comment_id,
          isTeamOnly: result.is_team_only,
          acknowledged_by: result.acknowledged_by,
          user: {
            name: result.commenter_name,
            avatar: result.commenter_avatar_url,
          },
          created_at: result.created_at,
          annotation: result.annotation,
          created_time_ago: 'just now',
        };

        const newComments = [...this.commentsRaw!];
        if (matchingCommentIndex === -1) {
          newComments.push(newComment);
        } else {
          newComments[matchingCommentIndex] = newComment;
        }

        this.commentsRawById[id] = newComment;
        CommentActions.receive.defer(newComments);
      },
    }).catch(
      commentErrorHandler(
        {
          messageKey: null,
          rollbarMessage: `Could not fetch comment with id ${id}, fetching all…`,
        },
        () => {
          CommentActions.fetch.defer(this.storyboardId);
        },
      ),
    );
  }

  handleReceive(response) {
    const storyboard =
      StoryboardStore.getState().storyboard ||
      ShareableStore.getState().storyboard;

    if (
      (BoordsConfig.controller === 'shareable' &&
        storyboard.preferences.hide_completed_comments_present) ||
      (BoordsConfig.controller === 'storyboards' &&
        storyboard.preferences.hide_completed_comments_edit)
    ) {
      this.commentsRaw = response.filter((comment) => !comment.acknowledged_by);
      this.hasHiddenComments = this.commentsRaw!.length < response.length;
    } else {
      this.commentsRaw = response;
    }
    this.commentsRawById = indexBy(this.commentsRaw!, 'id');

    if (this.commentingInFrame) {
      CommentActions.closeFrameCommentBox.defer();
    }
    if (this.activeFrame) {
      this.setActiveFrame(this.activeFrame.frameId);
    }
    // if (!this.fetched) {
    //   Track.event.defer(
    //     'comments_load',
    //     {
    //       count: this.commentCount,
    //       context: eventSource(undefined),
    //       category: 'Product',
    //     },
    //     1000,
    //   );
    // }

    // If we don't have frame data yet, we'll wait for that to finish (in
    // handleReceiveFrames) before marking the fetch as complete, so the UI
    // doesn't show up empty
    if (!FrameStore.getState().is_loading) {
      this.updateSections();
      this.markFetchAsFinished();
    }

    this.emitChange();
  }

  markFetchAsFinished() {
    this.fetched = true;
    this.fetching = false;
  }

  handleFetch(storyboardId) {
    if (this.fetching) return;

    this.fetching = true;
    this.storyboardId = storyboardId;
    this._tryRealtime();

    ajax({
      method: 'get',
      url: `/storyboards/${storyboardId}/comments.json`,
      // We leave it up to the receive action to update `this.fetching`
      success: (response) => CommentActions.receive.defer(response),
      error: commentErrorHandler(
        {
          message: "Sorry, we couldn't load the comments for this storyboard",
        },
        () => {
          this.fetching = false;
          this.emitChange();
        },
      ),
    });
  }

  handleFetchIfNecessary(storyboardId) {
    if (this.fetching || this.fetched === true) return;
    this.handleFetch(storyboardId);
  }

  trackCommentEvent(data: CommentSaveProps) {
    Track.event.defer('comment_created', {
      context: eventSource(data.context),
      isGuest: typeof BoordsConfig.Uid === 'undefined',
      isReply: data.in_reply_to_comment_id ? true : false,
    });
  }

  handleSave({ callback, ...data }: CommentSaveProps) {
    if (!data.text) return;
    CommentActions.closeFrameCommentBox.defer();
    this.isPosting = true;

    var name = data.name || '';
    var params: NewCommentRequestParams = {
      frame_id: data.frameId,
      text: removeNullCharacters(data.text),
      guest_name: name,
      in_reply_to_comment_id: data.in_reply_to_comment_id,
      isTeamOnly: data.isTeamOnly,
      annotation: toJSONIfObject(data.annotation) as string,
    };

    this.commentsRaw!.push({
      ...params,
      annotation: data.annotation,
      id: 99999,
      created_at: new Date().toUTCString(),
      created_time_ago: 'just now',
      user: { name: data.name ?? 'You', id: 0 },
    });

    this.updateSections();

    ajaxRuby({
      method: 'post',
      url: `/storyboards/${data.storyboardSlug}/comments`,
      data: params,
      success: (response: { comments: Comment[]; new_comment_id: number }) => {
        const { comments, new_comment_id } = response;
        editableCommentsLocalState.addToArray(new_comment_id);
        CommentActions.receive(comments);

        if (data.context === 'grid') {
          RequestActions.success.defer({ key: 'comments.notifications.saved' });
          PanelbarActions.open.defer('comments');
        } else if (data.context !== 'panelbar') {
          RequestActions.success.defer({ key: 'comments.notifications.saved' });
        }

        this.trackCommentEvent(data);

        this.newCommentData = emptyCommentData();
        this.emitChange();

        const newComment = this.commentsRawById[new_comment_id];
        if (newComment && callback) callback(newComment);
        this.isPosting = false;
      },
      error: commentErrorHandler(
        {
          rollbarMessage: 'Could not save comment',
        },
        () => {
          this.isPosting = false;
          // Remove the fake comment
          this.commentsRaw!.pop();

          // Reset the comment text
          this.newCommentData = { ...emptyCommentData(), comment: data.text };

          this.updateSections();
          this.emitChange();
        },
      ),
    });
  }

  handleUpdate(
    data: Pick<Comment, 'text' | 'annotation' | 'id'> & {
      storyboardId: number;
    },
  ) {
    this.isPosting = true;

    const comment = this.commentsRaw!.find((c) => c.id === data.id);
    const originalComment = { ...comment };
    if (comment) {
      Object.assign(comment, data, { modified_at: new Date().toUTCString() });
      this.updateSections();
    }

    ajax({
      method: 'put',
      url: `/storyboards/${data.storyboardId}/comments/${data.id}`,
      data: {
        comment: {
          text: removeNullCharacters(data.text),
          annotation: toJSONIfObject(data.annotation),
        },
      },
      success: (response) => {
        CommentActions.receive(response);
        RequestActions.success.defer({ key: 'comments.notifications.updated' });
        this.isPosting = false;
        this.emitChange();
      },
      error: commentErrorHandler({}, () => {
        if (comment) Object.assign(comment, originalComment);
        this.isPosting = false;
        this.emitChange();
      }),
    });
  }

  handleDelete(data: { id: number; storyboardId: number }) {
    if (!this.commentsRaw) return;
    this.commentsRaw = this.commentsRaw.filter((c) => c.id !== data.id);
    this.commentsRawById = indexBy(this.commentsRaw, 'id');
    this.updateSections();

    ajax({
      method: 'delete',
      url: `/storyboards/${data.storyboardId}/comments/${data.id}`,
      success: (response) => {
        CommentActions.receive(response);
        RequestActions.success.defer({ key: 'comments.notifications.deleted' });
        this.emitChange();
      },
      error: (err) => {
        if (err.status === 404) {
          // I we can't find the comment, it's probaby already
          RequestActions.success.defer({
            key: 'comments.notifications.deleted',
          });
        } else {
          commentErrorHandler({
            message: "Sorry, we couldn't delete your comment",
          })(err);
        }
      },
    });
  }

  handleOpenFrameCommentBox(data: { frame: IFrame }) {
    if (!isUndefined(data.frame)) {
      this.setActiveFrame(data.frame.id);
      this.commentingInFrame = true;
      this.emitChange();
      IntercomActions.enterContext.defer('commentBox');
    }
  }

  handleCloseFrameCommentBox() {
    // If we're in an inline / floating comment box, we want to re-show
    // the intercom chat bubble. Otherwise we leave it (we might be in a
    // comments sidebar instead, in which case we want to keep it hidden)
    if (this.commentingInFrame) {
      IntercomActions.leaveContext.defer('commentBox');
    }

    this.commentingInFrame = false;
    this.activeFrame = null;
    this.emitChange();
  }

  handleToggleFrameCommentBox(data: { frame: IFrame }) {
    if (
      this.activeFrame &&
      !isUndefined(data.frame) &&
      data.frame.id === this.activeFrame.frameId
    ) {
      this.handleCloseFrameCommentBox();
    } else {
      this.handleOpenFrameCommentBox(data);
    }
  }

  handleOpenPanelbarCommentBox(data) {
    this.setActiveFrame(data.frame.id);
    this.commentingInPanelbar = true;
    this.emitChange();
    PanelbarActions.open.defer('comments');
  }

  // handleOpenSlideshowCommentBox() {
  //   this.commentingInSlideshow = true;
  //   this.emitChange();
  // }

  handleClearActiveFrame() {
    this.activeFrame = null;
    this.emitChange();
  }

  handleSetActiveFrame(id) {
    if (!isUndefined(id)) {
      this.commentingInFrame = false;
      this.setActiveFrame(id);
      this.emitChange();
    }
  }

  handleRefreshComments() {
    if (this.storyboardId) {
      this.handleFetch(this.storyboardId);
    }
  }

  setActiveFrame(frameId) {
    this.activeFrame = this.findFrameComments(frameId);
    if (isUndefined(this.activeFrame)) {
      this.activeFrame = this.addEmptyFrameSection(frameId);
    }
  }

  closeCommentBox() {
    this.activeFrame = null;
    this.emitChange();
  }

  getCurrentFrameSectionIndex() {
    var index = 0;
    this.commentSections.forEach((s, i) => {
      if (s.frameId == this.activeFrame!.frameId) {
        index = i;
      }
    });
    return index;
  }

  updateSections() {
    if (!this.commentsRaw) return;
    this.commentSections = this.sectionComments();
    this.commentSectionsById = indexBy(this.commentSections, 'frameId');
  }

  handleReceiveFrames() {
    idleTimeout(
      () => {
        this.updateSections();
        // We only want to mark as fetched once we finished fetching and
        // populating commentsRaw
        if (this.commentsRaw) this.markFetchAsFinished();
        this.emitChange();
      },
      300,
      1000,
    );
  }

  sectionComments(): CommentSection[] {
    if (!this.commentsRaw) return [];
    // We need to filter out orphaned comments, so we need the Frame store to be
    // populated. We will call this again in response to FrameActions.receive
    if (!FrameStore.getState().frames.length || !this.commentsRaw) return [];

    // Convert array of comments into sections of comments
    const commentSections: CommentSection[] = [];
    const self = this;
    const replies: Record<string, Comment[]> = {};
    this.commentCount = 0;

    this.commentsRaw.forEach((c) => {
      const replyTo = c.in_reply_to_comment_id;
      if (replyTo) {
        if (!replies[replyTo]) replies[replyTo] = [];
        replies[replyTo].push(c);
      }
    });

    this.commentsRaw.forEach((comment) => {
      let inserted = false;
      const withReplies = Object.assign(comment, {
        replies: replies[comment.id] || [],
      });

      // If the frame it belongs to it does not exist, skip it
      // this can also happen when frame_id is null.
      var commentFrame = self.findFrameInfo(comment.frame_id);
      if (!commentFrame) return;

      // If the comment is a reply to a comment that doesn't exist,
      // remove the reference
      const replyTo = comment.in_reply_to_comment_id;
      if (replyTo && !some(this.commentsRaw!, (c) => c.id === replyTo))
        comment.in_reply_to_comment_id = null;

      this.commentCount += 1;

      commentSections.forEach((section, i) => {
        if (
          section.frameId === comment.frame_id &&
          !comment.in_reply_to_comment_id
        ) {
          // Add comment to existing frame section
          commentSections[i].comments.push(withReplies);
          inserted = true;
        }

        commentSections[i].count = reduce(
          commentSections[i].comments,
          (o, c) => o + 1 + (c.replies ? c.replies.length : 0),
          0,
        );
      });

      const number = commentFrame.number || commentFrame.sort_order;
      // if we haven't inserted this comment yet (into the "replies" property
      // of a parent comment), then we create a new section for it.
      if (!inserted && !comment.in_reply_to_comment_id) {
        commentSections.push({
          frameId: comment.frame_id,
          index: isUndefined(number) ? 99999 : number,
          sort_order: commentFrame.sort_order,
          comments: [withReplies],
          count: 1,
          frame: this.findFrameInfo(comment.frame_id),
        });
      }
    });

    // Sort comments within each section
    commentSections.forEach((section) => {
      section.comments = sortBy(section.comments, 'created_at');
    });

    return sortBy(commentSections, 'sort_order');
  }

  findFrameComments(frameId) {
    return find(this.commentSections, (s) => s.frameId === frameId);
  }

  findFrameInfo(frameId: frameId) {
    var frames = FrameStore.getState().frames;
    return find(frames, (f) => f.id === frameId);
  }

  findFrameIndex(frameId) {
    var frames = FrameStore.getState().frames;
    var frame = find(frames, (f) => f.id === frameId);
    return frame ? frame.sort_order : false;
  }

  addEmptyFrameSection(frameId): CommentSection {
    return {
      frameId: frameId,
      index: this.findFrameIndex(frameId),
      sort_order: 0,
      count: 0,
      comments: [],
      frame: this.findFrameInfo(frameId),
    };
  }

  handleUpdateNewCommentData(changes) {
    if (changes.comment) {
      changes.comment = changes.comment.slice(0, this.maxCommentLength);
    }
    this.newCommentData = { ...this.newCommentData, ...changes };
    LocalState.setValue('commentName', this.newCommentData.name);
  }
}

(window as any).CommentStore = alt.createStore(CommentStore, 'CommentStore');
