/** @format */

import React from 'react';

/** Maintains the size of an element after initial render. Useful for elements
 * that will frequently change size because of removing/adding children. Like
 * search results  */
export const useMaintainSize = (
  ref: React.RefObject<HTMLElement>,
  deps: unknown[] = [],
) => {
  React.useEffect(() => {
    const el = ref.current;
    if (!el) return;

    el.style.width = 'auto';
    el.style.height = 'auto';

    const { width, height } = el.getBoundingClientRect();
    el.style.width = width + 'px';
    el.style.height = height + 'px';

    return () => {
      el.style.width = 'auto';
      el.style.height = 'auto';
    };
  }, [...deps, ref]);
};
