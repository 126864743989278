/** @format */

import type { Opaque } from 'type-fest';
import type { IFrameOverlay } from './FrameOverlays';
import type { FabricCanvas } from './types';

export type FrameEditorBackgroundColor = Opaque<string, 'color'>;

export interface FrameEditorBackgroundImage {
  url: string;
  flipX?: boolean;
  flipY?: boolean;
}

export type FrameEditorDecoration = {
  /** The backgrounds as we currently use them are currently exclusive.
   * Background images are always padded with a white backround by Dolly, so if
   * there's an image ,there is no color, etc… */
  background: FrameEditorBackgroundImage | FrameEditorBackgroundColor | null;
  overlay: string | IFrameOverlay | null;
};

export const isFrameEditorBackgroundImage = (
  background: FrameEditorDecoration['background'],
): background is FrameEditorBackgroundImage => {
  return !!background && 'url' in background;
};

export const newFrameEditorDecoration = (
  props: Partial<FrameEditorDecoration>,
) => {
  return {
    background: null,
    overlay: null,
    ...props,
  };
};

export const frameEditorDecorationFromCanvas = (
  canvas: FabricCanvas,
): FrameEditorDecoration => {
  const output = newFrameEditorDecoration({});

  if (canvas.backgroundImage) {
    const bgImage: FrameEditorBackgroundImage = {
      url: canvas.backgroundImage.getSrc(),
      flipX: canvas.backgroundImage.flipX,
      flipY: canvas.backgroundImage.flipY,
    };

    output.background = bgImage;
  } else if (canvas.backgroundColor) {
    if (canvas.backgroundColor === 'white') {
      output.background = '#ffffff' as FrameEditorBackgroundColor;
    } else {
      output.background = canvas.backgroundColor;
    }
  }

  if (canvas.overlayImage) {
    output.overlay = canvas.overlayImage.getSrc();
  }
  return output;
};
