/** @prettier */
import { IStoryboardInStore } from '../../../types/storyboard';
import { PDFCoverPicture } from '../types';

import { PDFRendererOverrides } from './getPDFRendererProps';

const getPDFCover = (
  coverObject: { url: string },
  overrides?: PDFRendererOverrides,
) => {
  if (overrides?.covers === false) return undefined;
  if (!coverObject.url) return undefined;
  return {
    enabled: true,
    type: 'pdf',
    url: coverObject.url,
  } as PDFCoverPicture;
};

interface Props {
  storyboard: IStoryboardInStore;
  coverStoreData: any;
  customCovers: any;
  overrides?: PDFRendererOverrides;
}

export const getInfoForCover = (
  coverType: 'front' | 'back',
  { storyboard, coverStoreData, customCovers, overrides }: Props,
): PDFCoverPicture | undefined => {
  const cover = coverStoreData;
  // Temporarily fall back to storyboard if the cover store isn't loaded yet
  const coverProps = cover.storyboard ? cover.storyboard : storyboard;

  if (overrides?.covers === false) {
    return undefined;
  } else if (coverProps.has_custom_cover_and_endslate) {
    return getPDFCover(
      customCovers[coverType === 'front' ? 'cover' : 'endslate'],
    );
  } else if (
    cover.theme.logo_url &&
    coverType === 'front' &&
    coverProps.include_pdf_cover
  ) {
    return {
      enabled: coverProps.include_pdf_cover,
      type: 'logo',
      url: cover.theme.logo_url,
      backgroundColor: cover.theme.background_color,
      textColor: cover.theme.foreground_color,
      footerText: coverProps.document_secondary_info,
    };
  } else {
    return undefined;
  }
};

export const getCoverInfo = (props: Props) => {
  return {
    frontCover: getInfoForCover('front', props),
    backCover: getInfoForCover('back', props),
  };
};
