/** @prettier */
import { createTypedActions } from '../stores/AltStore';
import type { StoryboardZoomStore } from '../stores/storyboardZoom';

class StoryboardZoomActions {
  updateZoomLevel: StoryboardZoomStore['handleUpdateZoomLevel'] = function (
    data,
  ) {
    this.dispatch(data);
  };
}

const actions = createTypedActions<StoryboardZoomActions>(
  StoryboardZoomActions,
);
export { actions as StoryboardZoomActions };

// This is not how it's supposed to be, but so many views rely on this, that
// it's better to keep it like this until every instance is updated to use
// actual imports
(window as any).StoryboardZoomActions = actions;
