/** @format */

import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import logger from 'javascripts/helpers/logger';
import { RequestActions } from 'javascripts/flux/actions/request';
import { rollbar } from 'javascripts/helpers/rollbar';

const errorHandler = RequestErrorHandler('StoryboardBreadcrumb');

export interface ProjectHierarchy {
  id: number;
  name: string;
  slug: string;
  parent: ProjectHierarchy | null;
}

interface StoryboardBreadcrumbResponse {
  data: {
    attributes: {
      project_hierarchy: ProjectHierarchy;
      team_name: string;
    };
  };
}

interface StoryboardBreadcrumbContextProps {
  projectHierarchy: ProjectHierarchy | null;
  teamName: string | null;
  isLoading: boolean;
  error: string | null;
  fetchBreadcrumb: (storyboardId: number) => Promise<void>;
}

const defaultValues: StoryboardBreadcrumbContextProps = {
  projectHierarchy: null,
  teamName: null,
  isLoading: false,
  error: null,
  fetchBreadcrumb: async () => {},
};

export const StoryboardBreadcrumbContext =
  createContext<StoryboardBreadcrumbContextProps>(defaultValues);

interface StoryboardBreadcrumbProviderProps {
  children: React.ReactNode;
  storyboardId: number;
}

export const StoryboardBreadcrumbProvider: React.FC<
  StoryboardBreadcrumbProviderProps
> = ({ children, storyboardId }) => {
  const [projectHierarchy, setProjectHierarchy] =
    useState<ProjectHierarchy | null>(null);
  const [teamName, setTeamName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const isMounted = useRef(true);

  const handleError = (message: string, error?: Error) => {
    if (isMounted.current) {
      RequestActions.error.defer(message);
      if (error) {
        logger.error(error);
        rollbar.error(error);
      }
    }
  };

  const fetchBreadcrumb = useCallback(async (storyboardId: number) => {
    if (isLoading || !isMounted.current) return; // Prevent concurrent requests and check if still mounted
    setIsLoading(true);
    setError(null);
    try {
      const request = await apiRequest({
        path: `dashboard/storyboards/${storyboardId}/breadcrumb`,
        method: 'get',
      });
      if (!request.ok) {
        throw new Error('Failed to fetch storyboard breadcrumb');
      }
      const response: StoryboardBreadcrumbResponse = await request.json();
      if (isMounted.current) {
        const { project_hierarchy, team_name } = response.data.attributes;
        setProjectHierarchy(project_hierarchy);
        setTeamName(team_name);
      }
    } catch (err) {
      if (isMounted.current) {
        handleError('Error fetching storyboard breadcrumb', err);
        errorHandler({ method: 'get' })(err);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchBreadcrumb(storyboardId);

    return () => {
      isMounted.current = false; // Clean up when component unmounts
    };
  }, [fetchBreadcrumb, storyboardId]);

  const value: StoryboardBreadcrumbContextProps = {
    projectHierarchy,
    teamName,
    isLoading,
    error,
    fetchBreadcrumb,
  };

  return (
    <StoryboardBreadcrumbContext.Provider value={value}>
      {children}
    </StoryboardBreadcrumbContext.Provider>
  );
};
