/** @prettier */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  CanvasScrubberRenderer,
  type CanvasScrubberProps,
} from './CanvasScrubberRenderer';
import { diff } from 'deep-object-diff';
import { PureCanvas } from '../../shared/PureCanvas';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';

interface Props extends Omit<CanvasScrubberProps, 'onLoad'> {
  height?: number;
  audioIsFetching: boolean;
}

export class CanvasScrubber extends React.PureComponent<
  Props,
  { isLoaded: boolean }
> {
  canvasInstance: CanvasScrubberRenderer;
  canvasRef: React.RefObject<HTMLCanvasElement>;

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = { isLoaded: false };
  }

  static defaultProps = {
    height: 85,
    marginBottom: 6,
  };

  componentDidMount() {
    const node = ReactDOM.findDOMNode(this.canvasRef.current!) as any;
    this.canvasInstance = new CanvasScrubberRenderer(node, {
      audioData: this.props.audioData,
      time: this.props.time,
      zoom: this.props.zoom,
      endTime: this.props.endTime,
      onUpdateTime: this.props.onUpdateTime,
      frameTimes: this.props.frameTimes,
      disableCommit: this.props.disableCommit,
      currentFrameId: this.props.currentFrameId,
      currentFrameNumber: this.props.currentFrameNumber,
      audioIsFetching: this.props.audioIsFetching,
      marginBottom: this.props.marginBottom,
      onLoad: () => this.setState({ isLoaded: true }),
    });
    this.lastProps = this.props;

    window.addEventListener('resize', this.onResize);
  }

  lastProps: CanvasScrubberProps;

  componentDidUpdate() {
    // Please note, this library returns unexpected values for non-primitives
    const updatedProps: Partial<CanvasScrubberProps> = {
      ...diff(this.lastProps ?? {}, this.props),
      audioData: this.props.audioData,
    };

    // The way the diff library processes changed arrays leads to unexpected
    // results, so we have to override that
    if (this.lastProps.frameTimes !== this.props.frameTimes) {
      updatedProps.frameTimes = this.props.frameTimes;
    } else {
      delete updatedProps.frameTimes;
    }

    this.lastProps = this.props;

    this.canvasInstance.update(updatedProps);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    if (!this.canvasInstance) return;
    this.canvasInstance.unmount();
  }

  /** Called by the parent element when the window or UI resizes */
  onResize = () => {
    if (!this.canvasInstance) return;
    this.canvasInstance.resize();
  };

  render() {
    return (
      <>
        <PureCanvas
          className="w-full"
          height={this.props.height! + this.props.marginBottom}
          canvasRef={this.canvasRef}
        />
        <LoadingIndicator fill show={!this.state.isLoaded} />
      </>
    );
  }
}
