/** @prettier */
import * as React from 'react';
import { allTourSteps, TourStep, Tour } from '../../tours/tourData';
import { useStore } from '../../helpers/useStore';
import type { availableTourNames, ToursStore } from '../../flux/stores/tours';
import type { Placement } from '@popperjs/core';
import { isArray } from 'underscore';
import CloseIcon from 'blackbird/images/icons/close.svg';
import { ToursActions } from '../../flux/actions/tours';
// Make sure we actually import this file, the import statement above only imports a type value
import '../../flux/stores/tours';
import { IconButton } from 'blackbird/components/common/IconButton';
import useCustomPopper from 'blackbird/helpers/hooks/useCustomPopper';
import ReactDOM from 'react-dom';
import Button from 'blackbird/components/button/Button';
import { CircleStackIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import { WizardTokenCount } from 'blackbird/components/wizard/WizardTokenCount';

const highlightColors = {
  blue: 'border-brand-blue border-2',
  yellow: 'border-brand-yellow border-2 bg-brand-yellow/25',
};
interface Props {
  step: allTourSteps | allTourSteps[];
  /**
   * Do we want to show the tour elements at all on this component? this saves
   * us a listener to the Tours store.
   */
  canShow?: boolean;
  nextTourName?: availableTourNames;
  zIndex?: number;
  distance?: number;
  /**
   * Disable the text overlay, useful in cases when we want to wait while an
   * overlay is open. Contrary to `canShow`, this will still show the blue
   * overlay around the wrapped elements
   */
  canShowText?: boolean;
  overlayPosition?: Placement;
  preset?: 'normal' | 'toolbarButton' | 'rounded';
  highlightColor?: keyof typeof highlightColors;
  scrollIntoView?: boolean;
  /** Disable the "next" button */
  disableNext?: boolean;
  /** Hide the "next" button */
  hideNext?: boolean;
  /** Hide the "next" button and step indicator "1/2" */
  hideNextAndSteps?: boolean;
  /** Should we show the purple highlight? */
  showHighlight?: boolean;
  /** css `display` value for the icon, you might need to override this sometimes */
  display?: string;
  /** Slightly delay showing the notification to help with positioning */
  deferred?: boolean;
  /** Function to trigger when clicking next */
  onNext?: () => void;
  /** Function to trigger when the hint becomes active */
  onActivate?: () => void;
  /** Function to trigger when the hint becomes inactive */
  onDeactivate?: () => void;
}

const TourHintableElement: React.FC<Props> = ({
  canShowText = true,
  deferred = false,
  nextTourName,
  scrollIntoView = true,
  showHighlight = true,
  highlightColor = 'blue',
  distance = 15,
  display = 'flex',
  onDeactivate,
  onActivate,
  ...props
}) => {
  const lastRenderActiveState = React.useRef(false);
  const [parentRef, setParentRef] = React.useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null,
  );
  const [shouldShow, setShouldShow] = React.useState(!deferred);

  const stepInfo = useStore<TourStep | undefined, ToursStore>(
    'tours',
    (store) =>
      store.currentSteps.find((s) =>
        isArray(props.step)
          ? props.step.indexOf(s.name) >= 0
          : s.name === props.step,
      ),
  );

  const currentTours = useStore<Tour[] | undefined, ToursStore>(
    'tours',
    (store) => store.currentTours,
  );

  React.useEffect(() => {
    if (deferred) setTimeout(() => setShouldShow(true), 200);
  }, [deferred]);

  React.useEffect(() => {
    if (lastRenderActiveState.current === false && stepInfo) {
      // We want to wait a bit to make sure we don't interfere with any
      // onDeactivate handlers
      setTimeout(() => onActivate?.(), 100);
      if (scrollIntoView && parentRef)
        parentRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
    }

    if (lastRenderActiveState.current === true && !stepInfo) {
      onDeactivate?.();
    }

    lastRenderActiveState.current = Boolean(stepInfo);
  }, [stepInfo, onActivate, onDeactivate, scrollIntoView]);

  const { attributes, styles } = useCustomPopper(parentRef, popperElement, {
    placement: props.overlayPosition,
    distance: distance,
  });

  if (!stepInfo || !currentTours) return <>{props.children}</>;

  let margin = 'ma-5px';
  if (props.preset === 'toolbarButton') {
    // because these buttons have negative margin, we need to compensate for it
    // to keep equal spacing with these button's active state
    margin = 'ml-8px mr-8px mt-5px mb-5px';
  }

  return (
    <div className="relative">
      <div className={display} ref={setParentRef}>
        {props.children}
      </div>

      {showHighlight && (
        <div
          className={`absolute -inset-1 z-1 pointer-events-none ${margin} ${
            props.preset === 'rounded' ? 'rounded-full' : 'rounded-md'
          } ${highlightColors[highlightColor]}`}
        />
      )}

      {canShowText &&
        shouldShow &&
        ReactDOM.createPortal(
          <div
            className="shadow-md bg-brand-blue-25 w-80 z-tooltip rounded-md"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div className="relative group">
              {stepInfo.tokens && (
                <div className="absolute top-0 left-0 right-0 flex items-center pl-6 pr-4 py-1.5 rounded-t-md  bg-white/50 border-b border-brand-blue/50">
                  <div className="flex-auto text-sm text-brand-blue">
                    {stepInfo.tokens.name}
                  </div>
                </div>
              )}
              <div
                className={classNames(
                  'p-6 ',
                  stepInfo.tokens ? 'pt-14' : 'pt-5',
                )}
              >
                <div className="relative ">
                  <div className="absolute -mt-0.5 top-0 opacity-0 -right-2 transition group-hover:opacity-30 hover:opacity-100">
                    <IconButton
                      aria-label={`Skip task`}
                      color="black"
                      onClick={() => ToursActions.cancelTour.defer()}
                      icon={<CloseIcon />}
                    />
                  </div>

                  <div className="flex items-center pr-2 mr-3 text-base font-semibold">
                    {stepInfo.header}
                  </div>
                </div>
                {stepInfo.body && (
                  <div className={stepInfo.header ? 'mt-2' : undefined}>
                    {stepInfo.body}
                  </div>
                )}

                {!props.hideNextAndSteps && (
                  <div className="flex items-center mt-6">
                    <div className="flex-auto text-xs text-black/50">
                      <span>{currentTours[0].currentStep + 1}</span>
                      <span>{`/`}</span>
                      <span>
                        {JSON.stringify(currentTours[0].steps.length)}
                      </span>
                    </div>

                    {!props.hideNext && (
                      <Button
                        disabled={props.disableNext}
                        type="solid"
                        size="sm"
                        onClick={() => {
                          if (typeof props.onNext !== 'undefined') {
                            props.onNext();
                          }
                          ToursActions.advanceTour(
                            currentTours[0].name as availableTourNames,
                          );
                        }}
                      >
                        {stepInfo.buttonText ? stepInfo.buttonText : `Next`}
                      </Button>
                    )}
                  </div>
                )}

                {nextTourName && (
                  <div className="flex justify-start mt-4">
                    <Button
                      type="outline"
                      size="sm"
                      onClick={() => ToursActions.advanceTour(nextTourName)}
                    >
                      {`Next`}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};

TourHintableElement.displayName = 'TourHintableElement';

/**
 * For performance reasons, we only want to add store listeners if we
 * are allowed to show the tour.
 */
export const TourHintable: React.FC<Props> = (props) => {
  return props.canShow !== false && process.env.NODE_ENV !== 'test' ? (
    <TourHintableElement {...props} />
  ) : (
    <>{props.children}</>
  );
};

TourHintable.displayName = 'TourHintable';
