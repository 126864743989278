/** @prettier */
import * as React from 'react';
import ClearIcon from '../../images/icons/clear.svg';
import classNames from 'classnames';
import { IconColor } from '../icon/types';
import { IconButton } from '../common/IconButton';

interface Props extends React.ButtonHTMLAttributes<unknown> {
  color?: IconColor;
}

export const ClearButton: React.VFC<Props> = (props) => {
  return (
    <IconButton
      aria-label={`Clear`}
      {...props}
      className={classNames(props.className, 'focus:outline-blue ml-0.5')}
      color={props.color}
      icon={<ClearIcon />}
    />
  );
};

ClearButton.defaultProps = {
  color: 'subdued',
};
