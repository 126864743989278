/** @prettier */
import { fetchPDFJSFont } from './helpers/fetchPdfJSFont';
import type { IFrame } from '../../types/frame';
import type {
  FontSettings,
  supportedFormats,
  PDFDocumentStyle,
  PDFLayoutProps,
  PDFLayoutFunc,
  PDFCoverPicture,
  FontSettingsAfterFetch,
} from './types';
import type { AspectRatioName } from 'javascripts/types/storyboard';
import { loadPDFIcons } from './helpers/loadPDFIcons';
import { PDFSixGridLayout } from './layouts/6grid';
import '../../helpers/frame-size-helper';
import { PDFFrameListLayout } from './layouts/frameList';
import { PDFOneUpLayout } from './layouts/1up';
import { idleTimeout } from '../../helpers/idle-timeout';
import { layoutIsSupported } from './helpers/availableLayouts';
import { getFontForSubset } from './helpers/getFontForSubset';
import { PDFOneUpTwoColLayout } from './layouts/1up-2col';

export interface Props {
  style: PDFDocumentStyle;
  frames: IFrame[];
  fontSettings: FontSettings | undefined;
  documentFormat: supportedFormats;
  document_name: string;
  storyboardVersion: number;
  footerLogoUrl?: string;
  orientation: AspectRatioName;
  isQuickMode?: boolean;
  disableOverflow?: boolean;
  isDebugMode?: boolean;
  pageNumber?: number;
  isTrialPreview: boolean;
  /** Automatically dispose of generated Object URLs */
  autoCleanup?: boolean;
  frontCover?: PDFCoverPicture;
  backCover?: PDFCoverPicture;
}

let lastObjectUrl: string;

export const PDFRenderer = (props: Props) =>
  Promise.all([
    fetchPDFJSFont(props.fontSettings || getFontForSubset('latin')),
    loadPDFIcons(props.footerLogoUrl, props.style.frameFields),
  ]).then(([fontInfo]: [FontSettingsAfterFetch, any]) => {
    let footerText;
    if (props.style.showStoryboardName) {
      footerText = props.document_name;
      if (props.style.showVersionNumber)
        footerText += ` V${props.storyboardVersion}`;
    }

    const generatorProps: PDFLayoutProps = {
      pageNumber: props.pageNumber,
      documentFormat: props.documentFormat,
      isQuickMode: props.isQuickMode,
      isDebugMode: props.isDebugMode,
      disableOverflow: props.disableOverflow,
      footerText: footerText,
      fontInfo: props.fontSettings ? fontInfo : undefined,
      frontCover: props.frontCover,
      backCover: props.backCover,
      footerLogoUrl: props.footerLogoUrl,
      style: props.style,
      isTrialPreview: props.isTrialPreview,
    };

    if (
      !layoutIsSupported(
        props.style.layout,
        props.documentFormat,
        props.style.frameAspectRatio,
      )
    ) {
      throw new Error(
        `Current combination not supported: ${props.documentFormat} — ${props.style.frameAspectRatio} + ${props.style.layout}`,
      );
    }

    let promise: ReturnType<PDFLayoutFunc>;
    if (props.style.layout === '6grid') {
      promise = PDFSixGridLayout(generatorProps, props.frames);
    } else if (props.style.layout === '4grid') {
      promise = PDFSixGridLayout(generatorProps, props.frames, {
        framesPerPage: 4,
        columns: 2,
      });
    } else if (props.style.layout === '8grid') {
      promise = PDFSixGridLayout(generatorProps, props.frames, {
        framesPerPage: 8,
        columns: 4,
      });
    } else if (props.style.layout === '3up') {
      promise = PDFSixGridLayout(generatorProps, props.frames, {
        framesPerPage: 3,
      });
    } else if (props.style.layout === '2grid') {
      promise = PDFSixGridLayout(generatorProps, props.frames, {
        framesPerPage: 2,
        columns: 2,
      });
    } else if (props.style.layout === '1up') {
      promise = PDFOneUpLayout(generatorProps, props.frames);
    } else if (props.style.layout === '1up2Col') {
      promise = PDFOneUpTwoColLayout(generatorProps, props.frames);
    } else if (props.style.layout === 'list') {
      promise = PDFFrameListLayout(generatorProps, props.frames);
    } else {
      throw new Error(`Don't know how to handle layout ${props.style.layout}`);
    }

    promise.then((result) => {
      if (props.autoCleanup !== false && lastObjectUrl) {
        const cleanup = lastObjectUrl;
        idleTimeout(() => URL.revokeObjectURL(cleanup));
      }
      lastObjectUrl = result.url;
    });

    return promise;
  });
