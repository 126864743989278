/** @prettier */
import * as React from 'react';
import { IStoryboard } from '../../types/storyboard';

interface IProps {
  storyboard: IStoryboard;
  [key: string]: any;
}

const EmptyButton = ({ width = 138, height = 58 }) => (
  <div className={`br2 bg-lighter-grey`} style={{ width, height }} />
);

export const StoryboardFTU: React.SFC<IProps> = (props) =>
  props.frames ? (
    <div className={'storyboard storyboard--editable viewing-as-' + props.view}>
      <header className="header">
        <div className="flex header__flex">
          <a className="db headerLogo flex items-center" href="/">
            <img src={BoordsConfig.LogoUrl} />
          </a>

          <div className="header__inner flex justify-between items-center w-100">
            <hgroup className="header__left">
              <div className="flex items-center mb2">
                <div className="header__title" data-hj-suppress>
                  {props.storyboard.document_name}
                </div>

                {BoordsConfig.TeamRole !== 'guest' ? (
                  <div className="js_versions mh3">
                    <div className="js_versions">
                      <EmptyButton width={48} height={28} />
                    </div>
                  </div>
                ) : null}
              </div>

              <EmptyButton width={206} height={28} />
            </hgroup>

            <div className="flex items-center self-center br3">
              <div className="header__nav__item">
                <EmptyButton width={58} height={58} />
              </div>
              <div className="header__nav__item">
                <EmptyButton />
              </div>
              <div className="header__nav__item">
                <EmptyButton width={138} />
              </div>
              <div className="header__nav__item">
                <EmptyButton width={122} />
              </div>
              <div className="header__nav__item">
                <EmptyButton width={109} />
              </div>
              <div className="header__nav__item">
                <EmptyButton width={132} />
              </div>
              <div className="header__nav__item">
                <EmptyButton width={58} />
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="container__section">
        <FramesContainer selectedFrames={[]} {...props} />
      </section>
    </div>
  ) : null;
