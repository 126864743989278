/** @format */

import React, { useState } from 'react';
import Button from '../button/Button';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import { RequestActions } from 'javascripts/flux/actions/request';
import logger from 'javascripts/helpers/logger';
import { hideWorkflowButtonLocalState } from 'javascripts/helpers/local-state';

export const WorkflowStartTrialButton: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const startTrial = async () => {
    setIsLoading(true);
    try {
      const response = await apiRequest({
        path: 'dashboard/trial',
        method: 'post',
      });

      if (!response.ok) {
        throw new Error('Failed to start trial');
      }

      RequestActions.success.defer('Trial started, redirecting...');
      Track.event.defer('workflow_trial_start', {
        posthogCapture: true,
      });
      setTimeout(() => {
        window.location.href = '/';
      }, 200);
    } catch (error) {
      logger.error('Error starting trial:', error);
      RequestActions.error.defer('An error occurred while starting the trial');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button type="premium" rounded onClick={startTrial} disabled={isLoading}>
      {`Try Workflow Free`}
    </Button>
  );
};

export const WorkflowEndTrialButton: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const endTrial = async () => {
    setIsLoading(true);
    try {
      const response = await apiRequest({
        path: 'dashboard/trial/end',
        method: 'delete',
      });
      if (!response.ok) {
        throw new Error('Failed to end trial');
      }
      RequestActions.success.defer('Trial ended, redirecting...');

      hideWorkflowButtonLocalState.setValue(true);

      Track.event.defer('workflow_trial_end', {
        posthogCapture: true,
      });

      setTimeout(() => {
        window.location.href = '/';
      }, 200);
    } catch (error) {
      logger.error('Error ending trial:', error);
      RequestActions.error.defer('An error occurred while ending the trial');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <span
      onClick={endTrial}
      className="underline cursor-pointer opacity-60 text-premium-700 underline-offset-2 hover:no-underline decoration-premium-300"
    >
      {`End trial now.`}
    </span>
  );
};
