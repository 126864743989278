window.PermissionActions = alt.createActions(function () {

  this.verifyManageStoryboard = function (obj) {
    this.dispatch(obj);
  };

  this.verifyStoryboard = function (obj) {
    this.dispatch(obj);
  };

  this.verifyProject = function (obj) {
    this.dispatch(obj);
  };

  this.verifyStoryboardMobility = function (obj) {
    this.dispatch(obj);
  };

});

