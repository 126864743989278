/**
 * @prettier
 */
const { flyoverRouteFactory, setTitle } = require('./router-helpers');
const qs = require('querystringify');
const { focusModeLocalState } = require('../local-state');
const { noop } = require('underscore');

/**
 * The routes for everything that's not the shareable view
 */
module.exports = (router) => {
  const addFlyoverRoute = flyoverRouteFactory(router);

  addFlyoverRoute('/storyboards/new', 'newStoryboard', 'New Storyboard');
  addFlyoverRoute('/projects/new', 'newProject', 'New Project');

  // router.on('/pricing', () => {
  //   setTitle('Pricing')
  //   router.replaceState()
  // });
  router.on('/card', () => {
    location.href = 'https://app.boords.com/card';
  });

  router.on('/', setTitle('Project Dashboard'));
  router.on({
    '/storyboards/:slug/': { as: 'storyboard.show', uses: noop },
    '/storyboards/:slug/focus/:frameIndex': {
      as: 'frameFocus',
      uses: (params) => {
        const mode = focusModeLocalState.getValue() ?? 'frameEditor';
        router.historyAPIUpdateMethod('replaceState');
        router.navigate(router.generate(mode, params));
        router.historyAPIUpdateMethod('pushState');
      },
    },
  });

  addFlyoverRoute(
    '/storyboards/:slug/editor/:frameIndex',
    'frameEditor',
    null,
    (params) => ({
      index: parseInt(params.frameIndex, 10) - 1,
    }),
  );

  addFlyoverRoute(
    '/storyboards/:slug/player/:frameIndex',
    'player',
    null,
    (params) => ({
      index: parseInt(params.frameIndex, 10) - 1,
    }),
  );

  addFlyoverRoute(
    '/storyboards/:slug/generator/:frameIndex',
    'imageGenerator',
    null,
    (params) => ({
      index: parseInt(params.frameIndex, 10) - 1,
    }),
  );

  const checkoutHandler = (params, query) => ({
    plan_name: params.plan_name.toLowerCase(),
    props: {
      interval: params.frequency,
      teammates: query ? parseInt(qs.parse(query).teammates, 10) : null,
      is_annual: params.frequency === 'yearly',
      code: query ? qs.parse(query).code : null,
    },
  });

  addFlyoverRoute(
    '/checkout/:plan_name',
    'checkout',
    'Checkout',
    checkoutHandler,
  );

  addFlyoverRoute(
    '/checkout/:plan_name/:frequency',
    'checkout',
    'Checkout',
    checkoutHandler,
  );

  addFlyoverRoute(
    '/confirm/:plan_name/:frequency',
    'confirm',
    'Confirm Change',
    checkoutHandler,
  );

  router.resolve();
};
