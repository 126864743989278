/** @prettier */
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/20/solid';
import { useRollout } from 'javascripts/helpers/rollout';
import * as React from 'react';
import Tooltip from '../feedback/tooltip/Tooltip';
import Badge from './Badge';
import logoGoogle from 'blackbird/images/logo/google.png';

interface BadgeTwoFactorProps {
  enabled: boolean;
  isAdmin: boolean;
  available: boolean;
  isOauthSignin: boolean;
}

export const BadgeTwoFactor: React.FC<BadgeTwoFactorProps> = ({
  enabled,
  isAdmin,
  available,
  isOauthSignin,
}) => {
  const twoFactorLaunched = useRollout('Twofactor');

  if (!twoFactorLaunched) {
    return null;
  }

  interface AdminDisabledProps {
    label: string;
    link: string;
    cta: string;
  }
  const AdminDisabled: React.FC<AdminDisabledProps> = ({
    label,
    cta,
    link,
  }) => (
    <Tooltip title={label} placement="top">
      <a href={link}>
        <Badge
          label={<span className="">{cta}</span>}
          icon={<LockOpenIcon className="w-3 text-type-subdued" />}
        />
      </a>
    </Tooltip>
  );

  return (
    <>
      {isOauthSignin ? (
        <Tooltip title={`Signs in with Google`} placement="top">
          <span className="mt-[0.1rem] bg-action-secondary px-1 rounded-full inline-flex items-center justify-center w-6 h-6">
            <img src={logoGoogle} alt="Google logo" className="w-3" />
          </span>
        </Tooltip>
      ) : enabled ? (
        <Tooltip title={`Two-factor authentication enabled`} placement="top">
          <span>
            <Badge
              label={<span className="">{`2FA`}</span>}
              color="green"
              icon={<LockClosedIcon className="w-3 text-type-green" />}
            />
          </span>
        </Tooltip>
      ) : isAdmin && !available ? (
        <AdminDisabled
          link={`/checkout/powerhouse/${
            BoordsConfig.IsAnnual ? 'yearly' : 'monthly'
          }`}
          cta={`Enable 2FA`}
          label={`Upgrade to the Powerhouse plan to enable two-factor authentication for you and your team`}
        />
      ) : isAdmin && available ? (
        <AdminDisabled
          link={`/settings/account/twofactor/new`}
          label={`Finish setting up two-factor authentication`}
          cta={`Complete 2FA Setup`}
        />
      ) : (
        <Badge
          label={<span className="text-type-subdued">{`2FA`}</span>}
          icon={<LockOpenIcon className="w-3 text-type-disabled" />}
        />
      )}
    </>
  );
};

(window as any).BadgeTwoFactor = BadgeTwoFactor;
