const _ = require('underscore');
/** @prettier */
const {
  FRAME_COLORS,
  FrameStatus,
} = require('blackbird/components/menus/constants');
const {
  default: Tooltip,
} = require('blackbird/components/feedback/tooltip/Tooltip');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');

const btnProps = { className: 'rounded-full' };

module.exports = createReactClass({
  displayName: 'FrameStatusIndicator',

  propTypes: {
    frame: PropTypes.object.isRequired,
    size: PropTypes.number,
  },

  getDefaultProps() {
    return {
      size: 16,
    };
  },

  render() {
    let frameStatus;

    if (_.isEmpty(this.props.frame.status)) {
      return null;
    } else if (typeof this.props.frame.status === 'string') {
      frameStatus = JSON.parse(this.props.frame.status);
    } else {
      frameStatus = this.props.frame.status;
    }

    if (
      _.isEmpty(frameStatus) ||
      frameStatus.type === '' ||
      frameStatus.type === FrameStatus.CLEAR
    ) {
      return null;
    }

    return (
      <Tooltip
        containerClassName="block"
        title={frameStatus.type}
        btnProps={btnProps}
        distance={10}
      >
        <div
          className={`h-4 w-4 rounded-full shadow-[0px_1px_2px_rgba(0,0,0,0.24)] ${
            FRAME_COLORS[frameStatus.type]
          }`}
        />
      </Tooltip>
    );
  },
});
