import { PlayerStore } from '../stores/player';
import { createTypedActions } from '../stores/AltStore';
import { isBoolean, isUndefined } from 'underscore';

class PlayerActions {
  open: PlayerStore['handleOpen'] = function(options) {
    this.dispatch(options);
  };

  close: PlayerStore['handleClose'] = function() {
    this.dispatch();
  };

  toggleSidebar: PlayerStore['handleToggleSidebar'] = function() {
    this.dispatch();
  };

  toggleTimeline: PlayerStore['handleToggleTimeline'] = function() {
    this.dispatch();
  };

  goToFrame: PlayerStore['handleGoToFrame'] = function(options) {
    this.dispatch(options);
  };

  updateTime: PlayerStore['handleUpdateTime'] = function(newTime) {
    this.dispatch(newTime);
  };

  togglePlay: PlayerStore['handleTogglePlay'] = function(options) {
    if (!isBoolean(options) && !isUndefined(options)) {
      throw new Error('togglePlay needs to be passed a boolean or nothing, got ' + JSON.stringify(options, serializer(null, null)));
    }
    this.dispatch(options);
  };

  toggleLooping: PlayerStore['handleToggleLooping'] = function() {
    this.dispatch();
  };

  resetAudio: PlayerStore['handleResetAudio'] = function() {
    this.dispatch();
  };

  removeAudio: PlayerStore['handleRemoveAudio'] = function() {
    this.dispatch();
  };

  uploadAudio: PlayerStore['handleUploadAudio'] = function(options) {
    this.dispatch(options);
  };

  updateAudioState: PlayerStore['handleUpdateAudioState'] = function(options) {
    this.dispatch(options);
  }

  batchTimingChange: PlayerStore['handleBatchTimingChange'] = function(options) {
    this.dispatch(options);
  };

  commitFrameTimings: PlayerStore['handleCommitFrameTimings'] = function() {
    this.dispatch();
  };

  setFrameInpoint: PlayerStore['handleSetFrameInpoint'] = function(options) {
    this.dispatch(options);
  };

  updateTotalDuration: PlayerStore['spreadFrameDuration'] = function(options) {
    this.dispatch(options);
  };

  setFrameOutpoint: PlayerStore['handleSetFrameOutpoint'] = function(options) {
    this.dispatch(options);
  };

  adjustFrameInpoint: PlayerStore['handleAdjustFrameInpoint'] = function(options) {
    this.dispatch(options);
  };

  resetFramesDuration: PlayerStore['handleResetFramesDuration'] = function() {
    this.dispatch();
  };

  setTimingsLock: PlayerStore['handleSetTimingsLock'] = function(options) {
    this.dispatch(options);
  };

  spreadDurationToMatch: PlayerStore['handleSpreadDurationToMatch'] = function() {
    this.dispatch();
  };

  setTimelineZoom: PlayerStore['handleSetTimelineZoom'] = function(options) {
    this.dispatch(options);
  };

  fetchAudioFile: PlayerStore['handleFetchAudioFile'] = function (options) {
    this.dispatch(options)
  }
}

// https://github.com/moll/json-stringify-safe/blob/master/stringify.js
function serializer(replacer, cycleReplacer) {
  var stack:any[] = [];
  var keys:any[] = [];

  if (cycleReplacer == null) cycleReplacer = function(key, value) {
    if (stack[0] === value) return "[Circular ~]"
    return "[Circular ~." + keys.slice(0, stack.indexOf(value)).join(".") + "]"
  }

  return function(key, value) {
    if (stack.length > 0) {
      var thisPos = stack.indexOf(this)
      ~thisPos ? stack.splice(thisPos + 1) : stack.push(this)
      ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key)
      if (~stack.indexOf(value)) value = cycleReplacer.call(this, key, value)
    }
    else stack.push(value)

    return replacer == null ? value : replacer.call(this, key, value)
  }
}


const actions = createTypedActions<PlayerActions>(PlayerActions);
export { actions as PlayerActions };
