/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import * as screenSizeMonitor from '../../components/shared/screenSizeMonitor';
import '../actions/storyboardZoom';
import './panelbar';
import '../actions/storyboard';
import { gridViewColumnsLocalState } from '../../helpers/local-state';
import { isNumber } from 'underscore';
import { StoryboardZoomActions } from '../actions/storyboardZoom';
import { clampNumber } from 'javascripts/helpers/clampNumber';
import { PanelbarActions } from '../actions/panelbar';

export interface IStoryboardZoomSettings {
  columns: number;
  // /** The step increments for the zoom slider */
  // steps: number;
  min: number;
  max: number;
}

const PANEL_BAR_WIDTH = 400;

const getZoomSettings = (
  columns: number,
  screenWidth: number,
): IStoryboardZoomSettings => {
  const minWidth = 140;
  const averageGutter = 16;
  const lostInWhiteSpace = 50 - averageGutter; // one less

  const left = screenWidth - lostInWhiteSpace;
  const max = Math.floor(left / (minWidth + averageGutter));

  const zoomSettings = {
    min: 2,
    max,
  };

  return {
    ...zoomSettings,
    columns: clampNumber(columns, zoomSettings.min, zoomSettings.max),
  };
};

export class StoryboardZoomStore extends FakeAltStoreClass<StoryboardZoomStore> {
  zoomSettings: IStoryboardZoomSettings;
  frameRatio: number;
  panelbarIsOpen: boolean;

  constructor() {
    super();
    const columns = gridViewColumnsLocalState.getValue() ?? 4;
    this.panelbarIsOpen = PanelbarStore.getState().isOpen;

    let width = window.innerWidth;
    if (this.panelbarIsOpen) width -= PANEL_BAR_WIDTH;
    this.zoomSettings = getZoomSettings(columns, width);

    this.bindListeners({
      handleUpdateZoomLevel: [StoryboardZoomActions.UPDATE_ZOOM_LEVEL],
      handlePanelbarChange: [
        PanelbarActions.TOGGLE,
        PanelbarActions.OPEN,
        PanelbarActions.CLOSE,
      ],
    });

    screenSizeMonitor.listen('all', this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.handleUpdateZoomLevel(this.zoomSettings.columns);
    this.emitChange();
  };

  handlePanelbarChange = () => {
    const isOpening = PanelbarStore.getState().isOpen;
    if (isOpening === this.panelbarIsOpen) return;
    this.panelbarIsOpen = isOpening;
    let newColumns = this.zoomSettings.columns;

    if (isOpening) {
      newColumns -= 1;
    } else {
      newColumns += 1;
    }

    this.handleUpdateZoomLevel(newColumns);
    this.emitChange();
  };

  handleUpdateZoomLevel(newColumns: number) {
    if (!isNumber(newColumns)) throw new Error('newColumns should be a number');

    if (newColumns !== this.zoomSettings.columns) {
      // Commit the new value to localStorage, even if it doesn't fit for now
      gridViewColumnsLocalState.setValue(newColumns);
    }

    let width = window.innerWidth;
    if (PanelbarStore.getState().isOpen) width -= PANEL_BAR_WIDTH;
    this.zoomSettings = getZoomSettings(newColumns, width);

    this.emitChange();
  }
}

(window as any).StoryboardZoomStore = alt.createStore(
  StoryboardZoomStore,
  'StoryboardZoomStore',
);
