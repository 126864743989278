/**
 * @prettier
 * returns a new object with only the properties whose values were not
 * undefined. THis goes without saying, but don't modify this function to
 * also check for `null` or `false`
 */
export function pickDefined<T = { [key: string]: any }>(object: T): T {
  const obj: any = {};
  for (const key in object) {
    if (object[key] !== undefined) {
      obj[key] = object[key];
    }
  }
  return obj;
}
