/** @format */

import React, { useContext } from 'react';
import { sortTeamMembers, TeamContext } from './TeamContext';
import { ProjectsContext } from '../projects/ProjectsContext';
import Avatar from '../avatar/Avatar';
import { filterDataUrl } from '../projects/ProjectsGroupLabel';
import { getFirstCharacter } from 'javascripts/helpers/get-first-character';
import { PlusIcon } from 'lucide-react';

export const TeamProjectMembers: React.FC = () => {
  const limit = 5;
  const { setIsTeamProjectModalOpen, teamData } = useContext(TeamContext);
  const { activeProject, activeGroup } = useContext(ProjectsContext);

  if (!teamData || !activeProject) return null;

  const projectMembers = sortTeamMembers(
    teamData.active_and_pending_members.filter(
      (member) =>
        ['manager', 'supermember', 'admin'].includes(member.role) ||
        (member.role === 'member' &&
          member.projects_with_access.includes(activeProject.slug)),
    ),
  );

  if (
    projectMembers.length === 0 ||
    !activeGroup?.actions.includes('team.view')
  ) {
    return null;
  }

  const canManageTeam = activeGroup?.actions.includes('team.manage');

  const displayedMembers = limit
    ? projectMembers.slice(0, limit)
    : projectMembers;
  const remainingCount = limit ? Math.max(0, projectMembers.length - limit) : 0;

  return (
    <div
      className="flex items-center cursor-pointer"
      onClick={() => {
        setIsTeamProjectModalOpen(true);
      }}
    >
      {canManageTeam && (
        <div className="flex items-center justify-center w-6 h-6 border border-white rounded-full text-xxs bg-surface-add_frame text-type-subdued box-border">
          <span>
            <PlusIcon className="w-3 h-3" />
          </span>
        </div>
      )}
      <div className="flex items-center -space-x-1">
        {displayedMembers.map((member) => (
          <Avatar
            key={member.id}
            borderColor="border-white"
            size="xxs"
            initial={getFirstCharacter(member.name)}
            image={filterDataUrl(member.avatar_url)}
          />
        ))}
        {remainingCount > 0 && (
          <div className="flex items-center justify-center w-6 h-6 border border-white rounded-full text-xxs bg-surface-add_frame text-type-subdued box-border">
            <span>
              <span className="opacity-50">{`+`}</span>
              <span>{remainingCount}</span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
