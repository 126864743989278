/** @prettier */
import { ErrorBoundaryContext } from 'javascripts/components/shared/ErrorBoundary';
import { SuspenseLoader } from 'javascripts/components/shared/SuspenseLoader';
import * as React from 'react';
import Panel from '../panel/Panel';

const emojiMartStyles = { border: '0 none' };
const width = { width: 350 };

const Picker = React.lazy(() => import('@emoji-mart/react'));

interface EmojiPickerProps {
  onSelect: (emoji: unknown) => void;
  popperElement?: HTMLDivElement | null;
}

export const EmojiPicker: React.VFC<EmojiPickerProps> = (props) => {
  const reportError = React.useContext(ErrorBoundaryContext);
  const fetchPickerData = React.useCallback(
    () =>
      import('@emoji-mart/data')
        .then(
          (response) => response.default,
          (err) => reportError(err),
        )
        .catch(reportError),
    [reportError],
  );

  return (
    // This thing apparently needs a hard-coded width to not freak out chrome
    <Panel className="min-h-96 relative overflow-hidden" style={width}>
      <SuspenseLoader fill>
        <Picker
          set="native"
          data={fetchPickerData}
          onEmojiSelect={props.onSelect}
          style={emojiMartStyles}
          color="#F4BD64"
          theme="light"
          dynamicWidth
          autoFocus
        />
      </SuspenseLoader>
    </Panel>
  );
};
