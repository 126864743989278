/** @prettier */
/* eslint-disable react/display-name */
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import * as React from 'react';
import { PanelbarFrameAssetItem } from './PanelbarFrameAssetItem';

export type parsedAssetItem = {
  id: string | number;
  imageUrl: string;
  dragType: string;
  canDelete?: boolean;
  isUploading: boolean;
};
interface Props<T = unknown> {
  parse: (item: T) => parsedAssetItem;
  items?: T[];
  isLoading: boolean;
  onDeleteItem?: (item: parsedAssetItem) => void;
  loadingText: string;
  emptyState?: React.ReactNode;
  canDrag?: boolean;
}

// From: https://stackoverflow.com/a/73795494/847689
interface WithForwardRefType extends React.FC<Props<unknown>> {
  <T>(props: Props<T>): ReturnType<React.FC<Props<T>>>;
}

export const PanelbarFrameAssetList: WithForwardRefType = React.forwardRef(
  (props, ref: React.Ref<HTMLDivElement>) => {
    return props.items ? (
      props.items.length === 0 && props.emptyState ? (
        <div
          className="flex flex-auto items-center justify-center p-6"
          ref={ref}
        >
          {props.emptyState}
        </div>
      ) : (
        <>
          {props.children}
          <div
            className="grid flex-grow grid-cols-2 items-start gap-4 p-6 auto-rows-min"
            ref={ref}
          >
            {props.items.map((item) => {
              const parsed = props.parse(item);
              return (
                <PanelbarFrameAssetItem
                  canDrag={props.canDrag}
                  key={parsed.id}
                  imageUrl={parsed.imageUrl}
                  dragType={parsed.dragType}
                  id={parsed.id}
                  canDelete={parsed.canDelete}
                  isUploading={parsed.isUploading}
                  onDelete={
                    props.onDeleteItem
                      ? () => props.onDeleteItem!(parsed)
                      : undefined
                  }
                />
              );
            })}
          </div>
          <div className="h-16" />
        </>
      )
    ) : props.isLoading ? (
      <LoadingIndicator text={props.loadingText} pad />
    ) : null;
  },
);

PanelbarFrameAssetList.displayName = 'PanelbarFrameAssetList';
