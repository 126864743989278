/** @prettier */
import * as React from 'react';
import { type strictUserFeaturesType } from 'javascripts/flux/stores/user_permissions';
import { useTranslation } from 'react-i18next';
import Button from '../button/Button';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import logger from 'javascripts/helpers/logger';

interface FeatureUnavailableProps {
  featureName: strictUserFeaturesType;
  maxUsers?: number;
  limitReached?: boolean;
  align?: undefined | 'left';
}

interface INewPlan {
  name: string;
  slug: string;
}

export const FeatureUnavailable: React.FC<FeatureUnavailableProps> = ({
  featureName,
  limitReached,
  maxUsers,
  align,
}): React.ReactElement => {
  const { t } = useTranslation('inline_ctas');
  const [newPlan, setNewPlan] = React.useState<INewPlan | undefined>();
  const [isEnterprise, setIsEnterprise] = React.useState(false);

  const trackEvent = (name: string) => {
    Track.event.defer(`feature_unavailable_${name}`, {
      newPlan: newPlan ? newPlan.slug : undefined,
      context: featureName,
      category: 'Checkout',
    });
  };

  React.useEffect(() => {
    if (BoordsConfig.Rollout.Puffin) {
      if (t(`${featureName}.puffin_plan`, { defaultValue: false })) {
        setNewPlan(
          t(`${featureName}.puffin_plan`, { returnObjects: true }) as INewPlan,
        );
      }
    } else {
      if (maxUsers && maxUsers <= 5) {
        setNewPlan({
          name: 'Agency',
          slug: 'agency',
        });
      } else if (maxUsers && maxUsers <= 10) {
        setNewPlan({
          name: 'Powerhouse',
          slug: 'powerhouse',
        });
      } else if (maxUsers && maxUsers >= 30) {
        setIsEnterprise(true);
      } else if (t(`${featureName}.new_plan`, { defaultValue: false })) {
        setNewPlan(
          t(`${featureName}.new_plan`, { returnObjects: true }) as INewPlan,
        );
      }
    }
  }, [featureName, maxUsers]);

  const handleEnterpriseClick = () => {
    Intercom(
      'showNewMessage',
      `I'd like to discuss a custom plan with more space`,
    );
  };

  return (
    <>
      <div
        className={classNames(
          `flex py-3 items-center text-sm rounded-t-lg space-x-2`,
          limitReached
            ? 'bg-brand-yellow-25/50 text-brand-yellow '
            : 'bg-brand-blue-25 text-brand-blue ',
          align === 'left' ? 'px-6' : 'pl-2 pr-6 justify-center ',
        )}
      >
        <LockClosedIcon className="w-4" />
        <span>
          {limitReached ? `Team Size Limit Reached` : `Upgrade Required`}
        </span>
      </div>
      <div
        className={classNames(
          'w-auto pt-6 pb-8 rounded-b-lg bg-surface-light',
          align === 'left' ? 'px-6' : 'text-center px-6 ',
        )}
      >
        <div className={classNames(align === 'left' && 'flex items-center')}>
          <div className={classNames(align === 'left' && 'flex-auto pr-8')}>
            <div className="text-lg font-semibold">
              {t(`${featureName}.title`, { name: newPlan && newPlan.name })}
            </div>

            <div
              className={classNames(align === 'left' ? 'my-3' : 'mb-6 mt-4')}
            >
              {isEnterprise
                ? t('team_overage.enterprise_subtitle', { maxUsers: maxUsers })
                : t(`${featureName}.subtitle`, {
                    name: newPlan && newPlan.name,
                    maxUsers: maxUsers,
                  })}
            </div>
          </div>

          <div className="flex-shrink-0 text-center">
            {isEnterprise ? (
              <Button onClick={handleEnterpriseClick}>
                {t(`cta.enterprise`)}
              </Button>
            ) : !newPlan ? (
              <Button
                rounded
                link={t(`cta.href`)}
                onMouseDown={() => trackEvent('pricing_click')}
              >
                {t(`cta.pricing`)}
              </Button>
            ) : (
              <Button
                rounded
                onClick={() => {
                  FlyoverActions.open.defer({
                    type: 'inlinePricing',
                  });
                }}
                onMouseDown={() => trackEvent('pricing_click')}
              >
                {`Compare Plans`}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

(window as any).FeatureUnavailable = FeatureUnavailable;
