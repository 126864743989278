/** @format */

import classNames from 'classnames';
import React from 'react';

interface SecondaryLinkProps {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  children: React.ReactNode;
  href?: string;
  newWindow?: boolean;
  textColor?: string;
  decorationColor?: string;
}

export const SecondaryLink: React.FC<SecondaryLinkProps> = ({
  onClick,
  children,
  href,
  newWindow,
  textColor = 'text-type-subdued',
  decorationColor = 'decoration-border-mid',
}) => {
  const linkClassNames =
    'text-sm font-normal underline cursor-pointer hover:no-underline underline-offset-2';

  return href && newWindow ? (
    <a
      className={classNames(linkClassNames, textColor, decorationColor)}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {`${children} ↗`}
    </a>
  ) : href ? (
    <a
      className={classNames(linkClassNames, textColor, decorationColor)}
      href={href}
    >
      {children}
    </a>
  ) : (
    <a
      className={classNames(linkClassNames, textColor, decorationColor)}
      onClick={onClick}
    >
      {children}
    </a>
  );
};
