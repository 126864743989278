const createReactClass = require('create-react-class')
const _ = require("underscore")
const PropTypes = require('prop-types');
const Overlay = require('../frame_editor/Overlay');
const {default:MoreOptions} = require('../../../blackbird/components/menu/MoreOptions');
const { default: logger } = require('javascripts/helpers/logger');
const { default: Dialog } = require('blackbird/components/dialog/Dialog');
const { default: TextInput } = require('blackbird/components/form/text-input/TextInput');
const { withTranslation } = require('react-i18next');
const DashboardStoryboardPopupMenu = createReactClass({

  propTypes:    {
    storyboard:  PropTypes.object.isRequired,
  },

  getInitialState: function() {
    return {
      ...StoryboardMobilityStore.getState(),
      shareOpen: false
    };
  },

  componentDidMount: function() {
    StoryboardMobilityStore.listen(this._onChange);
  },

  componentWillUnmount: function() {
    StoryboardMobilityStore.unlisten(this._onChange);
  },

  _onChange: function(state) {
    this.setState(state);
  },

  _check: function() {
    StoryboardMobilityActions.check(this.props.storyboard);
  },

  _handleDuplicate: function() {
    PermissionActions.verifyStoryboard.defer({
      project_id: this.props.storyboard.project_id,
      onSuccess: function () {
        StoryboardActions.duplicate.defer(this.props.storyboard);
      }.bind(this),
    });
  },

  _handleMove: function() {
    FlyoverActions.open({
      type:        "moveStoryboard",
      storyboard:  this.props.storyboard,
    });
  },

  _handleArchive: function() {
    if (BoordsConfig.Freeloader) {
      FlyoverActions.open.defer({
        type: "archiveUpgradePrompt",
        props: {
          storyboard: this.props.storyboard
        }
      });
    } else {
      StoryboardActions.archiveStoryboard(this.props.storyboard);
    }
  },

  toggleShareOverlay() {
    this.setState( state => ({ shareOpen: !state.shareOpen }))
  },

  onShareInputClick(e) {
    e.target.select();

    try {
      var successful = document.execCommand('copy');
      if (successful) {
        RequestActions.success('Copied to clipboard');
      } else {
        logger.warn('Unable to copy to clipboard')
      }
    } catch (err) {
      logger.log('Oops, unable to copy');
    }
  },

  render: function() {
    const {t}=this.props;
    var components = [];

    if (!_.contains(this.state.has_checked_storyboard_id, this.props.storyboard.id)) {
      components.push({
        id:"options",
        label:    t('loaders.storyboard.options')
      });
    } else if (!BoordsConfig.Freeloader) {
      components.push({
        id:"duplicate",
        label:    t("actions.storyboard.duplicate"),
        onItemSelect:  this._handleDuplicate,
      });
    }

    if (_.contains(this.state.is_able_to_move, this.props.storyboard.id)) {
      components.push({
        id:"move",
        label:    t("actions.storyboard.move"),
        onItemSelect:  this._handleMove,
      });

      components.push({
        id:"archive",
        label:    t("actions.storyboard.archive"),
        onItemSelect:  this._handleArchive,
      });
    }

      components.push({
        id:"share",
        label: t("actions.storyboard.share"),
        onItemSelect: this.toggleShareOverlay
      })

    return (
      <div onMouseEnter={this._check} onTouchEnd={this._check}>
        <MoreOptions
          options={components}
          blurOnClose
          className='bg-surface-light'
        />
          <Dialog
            hideActions
            isDark
            isOpen={this.state.shareOpen}
            title={t("modals.share_storyboard.title")}
            description={t("modals.share_storyboard.description")}
            onCancel={this.toggleShareOverlay}>
            <TextInput
              value={this.props.storyboard.public_url}
              onClick={this.onShareInputClick}
              readOnly
            />
            <div className="mt4 pv3 bt bb bw1 b--light-grey">
              <PanelbarShareablePassword
                storyboard_id={this.props.storyboard.id}
              />
            </div>
          </Dialog>
      </div>
    );
  }
});
window.DashboardStoryboardPopupMenu = withTranslation()(DashboardStoryboardPopupMenu)
