/** @prettier */

/** Your typical helper to wait for `window.onload`. Please note there is also a
 * {@link onReady} function */
export function onLoad(fn: () => void) {
  if (document.readyState !== 'complete') {
    fn();
  } else {
    document.addEventListener('load', fn);
  }
}
