/** @format */
import { contains, isArray } from 'underscore';

/** checks if a MIME type is allowed. Please note that this expects MIME types,
 * not file extensions. `File.type` typically is. If `accept` is undefined, it
 * will accept all file types */
export const isFileTypeAccepted = (
  accept?: string[],
  fileType?: string,
): boolean => {
  if (!fileType)
    throw new Error(
      'File type could not be checked, because it was not passed',
    );

  if (isArray(accept) && accept.length && fileType) {
    return contains(accept, fileType);
  }
  return true;
};
