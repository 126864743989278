/** @format */

import * as React from 'react';
import Button from '../button/Button';
import TextInput from '../form/text-input/TextInput';
import { TeamContext, type TeamRole } from './TeamContext';
import { ProjectsContext } from '../projects/ProjectsContext';
import { TeamRoleSelect } from './TeamRoleSelect';
import classNames from 'classnames';
import { TeamUpgradePill } from './TeamUpgradePill';

const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const TeamProjectInviteForm: React.FC = () => {
  const {
    teamData,
    teamMonthlyUserCost,
    isTeamFull,
    createInvite,
    isInviting,
  } = React.useContext(TeamContext);
  const { activeProject } = React.useContext(ProjectsContext);

  const [email, setEmail] = React.useState('');
  const [selectedRole, setSelectedRole] =
    React.useState<TeamRole>('supermember');
  const isEmailValid = isValidEmail(email);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isEmailValid) return;
    if (selectedRole === 'member' && activeProject) {
      await createInvite(email, selectedRole, activeProject.id);
    } else {
      await createInvite(email, selectedRole);
    }
    setEmail('');
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className={classNames(
          'flex items-center space-x-2',
          isTeamFull && 'opacity-50',
        )}
      >
        <TextInput
          inputSize="md"
          className="flex-auto h-10"
          type="email"
          placeholder="Invite by email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isInviting || isTeamFull}
          rightComponent={
            <TeamRoleSelect value={selectedRole} onChange={setSelectedRole} />
          }
        />
        <Button
          className="flex-shrink-0 h-10 px-4"
          type="solid"
          onClick={handleSubmit}
          disabled={isInviting || !isEmailValid}
        >
          <span className="text-sm">{`Invite`}</span>
        </Button>
      </form>
      {isEmailValid && teamData?.plan_name !== 'Trialing' && (
        <div className="text-xs text-type-subdued mt-1.5">
          {`New members will be billed at ${teamMonthlyUserCost} when they accept your invite.`}
        </div>
      )}
      {isTeamFull && (
        <div className="flex items-center px-3 py-2 mt-2 bg-premium-50 rounded-md">
          <div className="flex-auto text-sm text-premium-500">{`Upgrade to invite unlimited users`}</div>
          <TeamUpgradePill />
        </div>
      )}
    </div>
  );
};
