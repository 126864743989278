/** @format */

import i18n from 'i18next';
import logger from 'javascripts/helpers/logger';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { apiRequest } from '../apiRequestHelper';
import {
  type IBilllingPlanInterval,
  type LocalPlanData,
} from './BillingContext';

export interface Offer {
  title: string;
  bannerCopy: string;
  plan: string;
  couponId: string;
  discount: number;
  startDate: Date;
  endDate: Date;
}

interface IApiOfferAttributes {
  attributes: Offer;
}

interface IApiOfferResponse {
  data: IApiOfferAttributes[];
}

const initialContext = {
  coupon: null,
  currency: 'usd',
  newPlanSlug: null,
  amount: null,
  maxStoryboards: null,
  discountAmount: null,
  signupWithPlan: false,
  offer: null,
  newPlanInterval: 'year' as IBilllingPlanInterval,
  setSignupWithPlan: () => {},
  setMaxStoryboards: () => {},
  setAmount: () => {},
  setDiscountAmount: () => {},
  setNewPlanInterval: () => {},
  setCurrency: () => {},
  setCoupon: () => {},
  setOffer: () => {},
  setNewPlanSlug: () => {},
};

interface SignupContextProps {
  signupWithPlan: boolean;
  coupon: string | null;
  amount: number | null;
  maxStoryboards: number | null;
  discountAmount: number | null;
  currency: string;
  newPlanInterval: IBilllingPlanInterval;
  newPlanSlug: string | null;
  offer: Offer | null;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setMaxStoryboards: React.Dispatch<React.SetStateAction<number>>;
  setDiscountAmount: React.Dispatch<React.SetStateAction<number>>;
  setNewPlanInterval: React.Dispatch<
    React.SetStateAction<IBilllingPlanInterval>
  >;
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  setOffer: React.Dispatch<React.SetStateAction<Offer>>;
  setNewPlanSlug: React.Dispatch<React.SetStateAction<string>>;
  setCoupon: React.Dispatch<React.SetStateAction<string>>;
}

export const SignupContext = createContext<SignupContextProps>(initialContext);

export const SignupProvider: React.FC = ({ children }) => {
  const [signupWithPlan, setSignupWithPlan] = useState(
    initialContext.signupWithPlan,
  );
  const [newPlanInterval, setNewPlanInterval] = useState<IBilllingPlanInterval>(
    initialContext.newPlanInterval,
  );
  const [amount, setAmount] = useState<number | null>(initialContext.amount);
  const [maxStoryboards, setMaxStoryboards] = useState<number | null>(
    initialContext.maxStoryboards,
  );
  const [discountAmount, setDiscountAmount] = useState<number | null>(
    initialContext.discountAmount,
  );
  const [currency, setCurrency] = useState<string>(initialContext.currency);
  const [offer, setOffer] = useState<Offer | null>(initialContext.offer);
  const [coupon, setCoupon] = useState<string | null>(initialContext.coupon);
  const [newPlanSlug, setNewPlanSlug] = useState<string | null>(
    initialContext.newPlanSlug,
  );

  const FetchOffer = useCallback(async () => {
    const request = await apiRequest({
      path: 'offer',
      method: 'get',
    });
    const response: IApiOfferResponse = await request.json();

    if (request.ok) {
      const { couponId: contentfulCoupon, plan: contentfulPlanSlug } =
        response.data[0].attributes;

      // Check the coupon id and plan against the contentful entry
      if (coupon === contentfulCoupon && contentfulPlanSlug === newPlanSlug) {
        setOffer(response.data[0].attributes);
      }
    } else {
      logger.log(response);
    }
  }, [coupon, newPlanSlug]);

  // Check for new plan on signup
  useEffect(() => {
    if (!coupon || !newPlanSlug || !newPlanInterval) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const nextUrl = urlSearchParams.get('next_url');

      if (nextUrl) {
        const planNameMatch = /\/checkout\/(.*?)\//.exec(nextUrl);
        const couponMatch = /coupon=([^\s&]+)/.exec(nextUrl);
        const intervalMatch = /\/(monthly|yearly)\b/.exec(nextUrl);

        if (planNameMatch) {
          setNewPlanSlug(planNameMatch[1]);
        }
        if (intervalMatch) {
          setNewPlanInterval(
            intervalMatch[1].replace('ly', '') as IBilllingPlanInterval,
          );
        }
        if (couponMatch) {
          setCoupon(couponMatch[1]);
        }
      }
    }
  }, [coupon, newPlanSlug, newPlanInterval]);

  // Check for currency
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const currencyParam = urlSearchParams.get('trial_currency');
    if (currencyParam) {
      setCurrency(currencyParam);
    }
  }, []);

  // Set local plan data

  useEffect(() => {
    if (offer && newPlanSlug && newPlanInterval) {
      const localPlans: LocalPlanData[] = i18n.t('billing:plans', {
        returnObjects: true,
      });

      const localPlan = localPlans.find((plan) => plan.slug === newPlanSlug);

      if (localPlan) {
        const price = localPlan.prices.find(
          (price) =>
            price.currency === currency &&
            price.interval === newPlanInterval &&
            newPlanInterval === 'year', // Only allow yearly
        );
        if (price) {
          setMaxStoryboards(localPlan.maxStoryboards);
          setAmount(price.amount);
          setDiscountAmount((price.amount / 100) * (100 - offer.discount));
        }
      }
    }
  }, [offer, newPlanSlug, currency, newPlanInterval]);

  // Fetch contentful data
  useEffect(() => {
    if (coupon && newPlanSlug && !offer) {
      FetchOffer();
      setSignupWithPlan(true);
    }
  }, [coupon, newPlanSlug, offer]);

  const values = {
    maxStoryboards,
    setMaxStoryboards,
    amount,
    setAmount,
    discountAmount,
    setDiscountAmount,
    newPlanInterval,
    setNewPlanInterval,
    currency,
    setCurrency,
    offer,
    setOffer,
    coupon,
    newPlanSlug,
    setCoupon,
    setNewPlanSlug,
    signupWithPlan,
    setSignupWithPlan,
  };
  return (
    <SignupContext.Provider value={values}>{children}</SignupContext.Provider>
  );
};
