const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');

window.MoveStoryboardFormContainer = createReactClass({

  propTypes: {
    storyboard: PropTypes.object.isRequired,
  },

  getInitialState: function() {
    return StoryboardMobilityStore.getState();
  },

  componentDidMount: function() {
    StoryboardMobilityStore.listen(this._onChange);
    StoryboardMobilityActions.fetchDestinationOptions.defer(this.props.storyboard);
  },

  componentWillUnmount: function() {
    StoryboardMobilityStore.unlisten(this._onChange);
  },

  _onChange: function(state) {
    this.setState(state);
  },

  render: function() {
    if (!this.state.projects) {
      return <FlyoverLoading />;
    } else {
      return <MoveStoryboardForm destinationId={this.state.destination_project_id} projects={this.state.projects} />;
    }
  }

});
