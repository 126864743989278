/** @format */
import {
  parseAndSanitizeText,
  type ParseAndSanitizeTextOptions,
} from 'blackbird/helpers/parseAndSanitizeText';
import * as React from 'react';

interface Props extends Partial<ParseAndSanitizeTextOptions> {
  className?: string;
  text: string;
  as?: string;
}

/** Want to display some of the formatting from the frame fields, but without
 * line breaks and complicated stuff, well… this is how you do it. Automatically
 * sanitizes and parses markdown*/
export const SimplifiedHTML = React.memo<Props>(
  ({ className, text, as, ...sanitizeOptions }) => {
    return React.createElement(as ?? 'div', {
      className: className,
      dangerouslySetInnerHTML: {
        __html: parseAndSanitizeText(text, false, {
          /** Special rules for HTML sanitisation. Most notably, we want to ignore line
           * breaks and paragraphs here  */
          ...sanitizeOptions,
          // prettier-ignore
          allowedTags: ['b', 'i', 'em', 'strong', 'code'],
        }),
      },
    });
  },
);

SimplifiedHTML.displayName = 'SimplifiedHTML';
