/** @prettier */
import {
  ArrowsRightLeftIcon,
  ArrowUpTrayIcon,
  CameraIcon,
  PencilIcon,
  PhotoIcon,
  RectangleStackIcon,
  SparklesIcon,
  Squares2X2Icon,
} from '@heroicons/react/24/outline';
import ShapesIcon from 'blackbird/images/icons/shapes.svg';
import classNames from 'classnames';
import * as React from 'react';

interface PanelbarImageLibraryIconProps {
  id: string;
  active?: boolean;
}

export const PanelbarImageLibraryIcon: React.FC<
  PanelbarImageLibraryIconProps
> = ({ id, active }): React.ReactElement => {
  const size = 'w-5 h-5';
  const baseClassname = classNames(
    `group-hover:text-brand-pink`,
    active && 'text-brand-pink',
  );
  if (id === 'illustrations') {
    return <PencilIcon className={classNames(baseClassname, size)} />;
  } else if (id === 'ai') {
    return <SparklesIcon className={classNames(baseClassname, size)} />;
  } else if (id === 'iconFinder') {
    return <Squares2X2Icon className={classNames(baseClassname, size)} />;
  } else if (id === 'cameraMoves') {
    return <ArrowsRightLeftIcon className={classNames(baseClassname, size)} />;
  } else if (id === 'shapes') {
    return <ShapesIcon className={classNames(baseClassname, 'w-6 h-auto')} />;
  } else if (id === 'backgrounds') {
    return <PhotoIcon className={classNames(baseClassname, size)} />;
  } else if (id === 'photos') {
    return <CameraIcon className={classNames(baseClassname, size)} />;
  } else if (id === 'frames') {
    return <RectangleStackIcon className={classNames(baseClassname, size)} />;
  } else if (id === 'uploads') {
    return <ArrowUpTrayIcon className={classNames(baseClassname, size)} />;
  } else {
    return <SparklesIcon className={classNames(baseClassname, size)} />;
  }
};
