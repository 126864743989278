/** @prettier */
import { splitArrayPerAmount } from './splitArrayPerAmount';
import { IFrame } from '../../../types/frame';
import { loadFrameImages } from './loadFrameImages';
import { createPDFGridItem } from './createPDFGridItem';
import type { PDFDocumentStyle, supportedLayouts, DocInfo } from '../types';
import * as eachSeries from 'async/eachSeries';
import { createPDFFooter } from './createPDFFooter';
import { getCurrentPageNumber } from './getCurrentPageNumber';
import { applyPDFWatermark } from './applyPDFWatermark';
import logger from 'javascripts/helpers/logger';

interface Props {
  frames: IFrame[];
  columns: number;
  footerText?: string;
  isQuickMode: boolean;
  debug?: boolean;
  disableOverflow?: boolean;
  pageNumber?: number;
  isTrialPreview: boolean;
  // Tell the renderer that we have too much text for this layout
  setTooMuchText: () => void;
}

export const createGridPage = (
  docInfo: DocInfo,
  style: PDFDocumentStyle,
  { frames, columns, ...props }: Props,
  callback: (err?: any) => void,
) => {
  const { doc, pageFormat } = docInfo;
  const useLowRes = props.isQuickMode || props.isTrialPreview;
  loadFrameImages(frames, style.frameAspectRatio, useLowRes)
    .then((imageUrls) => {
      const layoutsThatCanOverflow: supportedLayouts[] = ['6grid', '4grid'];
      const width = doc.internal.pageSize.width;
      const height = doc.internal.pageSize.height;
      const { gutter, margin, rowSpacing } = style;

      doc.addPage(pageFormat.format, pageFormat.orientation);

      if (style.backgroundColor) {
        doc.setFillColor(style.backgroundColor);
        doc.rect(0, 0, width, height, 'F');
      }

      const availableWidth = width - margin.left - margin.right;

      const footer = createPDFFooter(docInfo, {
        bottom: height - style.margin.bottom,
        text: props.footerText,
        debug: props.debug,
        pageNumber: props.isQuickMode ? props.pageNumber : undefined,
        style,
      });

      const availableHeight =
        height -
        margin.top -
        margin.bottom -
        footer.height -
        style.footerMargin.top;

      if (props.debug) {
        // Draw available area
        doc.setDrawColor(150, 255, 150);
        doc.rect(margin.left, margin.top, availableWidth, availableHeight);
      }

      // console.log(`Page ${pi + 1}`);

      // We want to keep track of how tall the generated rows will be
      let rowOffset = margin.top;
      let rowCount = 0;

      // For the 6-frame grid, we want to split up the frames in rows of
      // three.
      splitArrayPerAmount(frames, columns).map((rowFrames, ri) => {
        rowCount++;
        // Let's keep track of the position of the grid items/row
        let rowHeight = 0;
        let offsetX = margin.left;

        // If we ever want to extract it, we should probably return the
        // dimensions
        rowFrames.forEach((frame, i) => {
          const frameImageInfo = imageUrls[frame.id];

          if (!frameImageInfo) {
            throw new Error(`Cannot find image for frame ${frame.id}`);
          }

          const frameItem = createPDFGridItem(docInfo, style, {
            y: rowOffset,
            x: offsetX,
            width: style.frameWidth,
            frame: frame,
            debug: props.debug,
            frameImageInfo,
          });

          // If this column was higher than any previous one, mark this
          // as the row's height.
          rowHeight = Math.max(rowHeight, frameItem.height);

          // Since we're done with this frame, we can move over to the
          // next frame in this row.
          offsetX += frameItem.width + gutter;
        });

        if (props.debug) {
          doc.setDrawColor(255, 200, 200);
          doc.rect(margin.left, rowOffset, availableWidth, rowHeight);
        }

        // Now we're done with this row, we can move over to a next one
        rowOffset += Math.round(rowHeight) + rowSpacing;
      });

      // Remove the last row's rowSpacing from the measurement
      rowOffset -= rowSpacing;

      const didOverflow =
        rowOffset > availableHeight + margin.top &&
        !props.disableOverflow &&
        layoutsThatCanOverflow.includes(style.layout);

      if (
        // We don't want to trigger an overflow when we only have one row, that
        // would cause an infinite loop
        didOverflow &&
        rowCount > 1
      ) {
        logger.log('Overflowed, deleting page and adding new pages');

        doc.deletePage(getCurrentPageNumber(doc));

        // Determine the next pages to render
        let newPagesToRender = splitArrayPerAmount(frames, columns);
        if (props.isTrialPreview || props.isQuickMode)
          newPagesToRender = [newPagesToRender[0]];

        eachSeries(
          newPagesToRender,
          (rowFrames, cb) => {
            createGridPage(
              docInfo,
              style,
              { columns, ...props, frames: rowFrames },
              cb,
            );
          },
          callback,
        );

        if (didOverflow) props.setTooMuchText();
      } else if (props.isTrialPreview) {
        applyPDFWatermark(docInfo).then(
          () => callback(),
          (err) => callback(err),
        );
      } else {
        if (didOverflow) props.setTooMuchText();
        callback();
      }
    })
    .catch((err) => callback(err));
};
