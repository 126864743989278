import { UserPermissionsStore } from '../stores/user_permissions';
import { createTypedActions } from '../stores/AltStore';

class UserPermissionsActions {
  fetch: UserPermissionsStore['handleFetch'] = function (id) {
    this.dispatch(id);
  };
}

const actions = createTypedActions<UserPermissionsActions>(UserPermissionsActions);
export { actions as UserPermissionsActions };
