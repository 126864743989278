/** @format */
import Avatar from 'blackbird/components/avatar/Avatar';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import { FilestackActions } from 'javascripts/flux/actions/filestack';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import { getFirstCharacter } from 'javascripts/helpers/get-first-character';
import { useOnMount } from 'javascripts/helpers/useOnMount';
import { useStore } from 'javascripts/helpers/useStore';
import { useStoreAction } from 'javascripts/helpers/useStoreAction';
import React from 'react';

interface Props {
  type: 'user' | 'team';
  name: string;
  id: number;
}

const AvatarUploader: React.FC<Props> = (props) => {
  const fetch = useStoreAction('AvatarActions', 'fetch', {
    type: props.type,
    id: props.id,
  });

  const avatarUrl = useStore('avatar', (a) => a.avatar_url);
  const isWorking = useStore('avatar', (a) => a.is_working);

  useOnMount(fetch, false);

  const handleOnClick = () =>
    FilestackActions.openAvatar.defer({
      type: props.type,
      id: props.id,
    });

  return (
    <Tooltip title="Replace avatar" distance={10}>
      <Avatar
        onClick={isWorking ? undefined : handleOnClick}
        size="xl"
        image={avatarUrl}
        isLoading={isWorking}
        initial={props.name ? getFirstCharacter(props.name) : undefined}
      />
    </Tooltip>
  );
};

// eslint-disable-next-line react/display-name
(window as any).AvatarUploader = (props) => (
  <DefaultStoreContextProvider>
    <AvatarUploader {...props} />
  </DefaultStoreContextProvider>
);
