/** @prettier */
/** safely decodes HTML entities (e.g. &#39; instead of ')  */
export function htmlDecode(input) {
  // Normally, this would remove trailing spaces, but we need these for the
  // markdown flattener, so we have to replace them with something else that
  // will not be removed
  var doc = new DOMParser().parseFromString(
    input.replace(/ /g, '&nbsp;'),
    'text/html',
  );
  return doc.documentElement.textContent!.replace(/\xA0/g, ' ');
}
