/** @prettier */
import posthog from 'posthog-js';
import { type PostHog } from 'posthog-js/react/dist/types';

export const posthogClient = (): PostHog | undefined => {
  const apiKey = 'phc_srXnTQCK6KgfMDlBuIFCqqZWnHtM57FC2iqJ3Xwtptl';
  const apiHost = 'https://eu.posthog.com';
  // posthog.debug(false);

  const posthogConfig = {
    api_host: apiHost,
    autocapture: false,
    capture_pageleave: false,
    disable_session_recording: process.env.NODE_ENV === 'development',
    persistence: 'localStorage+cookie',
  };

  // @ts-expect-error localStorage+cookie is not included in types, even though it's valid
  const client = posthog.init(apiKey, posthogConfig);

  return client || undefined;
};
