/** @format */

import classNames from 'classnames';
import React from 'react';
import { TeamContext } from './TeamContext';

interface TeamUpgradePillProps {
  linkToPricing?: boolean;
  showPlanName?: boolean;
  isAdmin?: boolean;
  title?: string;
  label?: React.ReactNode;
}

export const TeamUpgradePill: React.FC<TeamUpgradePillProps> = ({
  linkToPricing = true,
  showPlanName = true,
  isAdmin,
  label,
  title,
}) => {
  const { teamData, activeGroup } = React.useContext(TeamContext);

  const handleClick = React.useCallback(() => {
    const isTeamAdmin =
      teamData?.active_and_pending_members.some(
        (m) => m.user_id === BoordsConfig.Uid && m.role === 'admin',
      ) || isAdmin;

    if (
      (activeGroup && activeGroup.actions.includes('team.billing')) ||
      (BoordsConfig.TeamRole && BoordsConfig.TeamRole === 'admin') ||
      isTeamAdmin
    ) {
      // Show direct upgrade (puffin)
      if (
        BoordsConfig.IsPuffinTrial ||
        ['standard', 'lite'].includes(BoordsConfig.PlanSlug)
      ) {
        FlyoverActions.open.defer({ type: 'inlinePricing' });
      } else {
        FlyoverActions.open.defer({
          type: 'workflowPreview',
          props: {
            isOpenedByAdmin: isAdmin,
          },
        });
      }
    } else {
      FlyoverActions.open.defer({ type: 'workflowPreview' });
    }
  }, [activeGroup, teamData]);

  return (
    <>
      <span
        className={classNames(
          'uppercase font-semibold rounded-sm text-xxs tracking-wider inline-flex group/upgradepill overflow-hidden',
          linkToPricing ? 'cursor-pointer' : '',
        )}
        onClick={linkToPricing ? handleClick : undefined}
      >
        {label && (
          <span className="pl-1.5 pr-1 py-0.5 inline-block bg-premium-800 !text-premium-100">
            {label}
          </span>
        )}
        <span className="px-1.5 py-0.5 inline-block bg-premium-100 !text-premium-600 group-hover/upgradepill:bg-premium-200 ">
          {title ? (
            title
          ) : showPlanName ? (
            <>{`Upgrade to Workflow`}</>
          ) : (
            <>{`Upgrade`}</>
          )}
        </span>
      </span>
    </>
  );
};
