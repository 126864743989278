/** @prettier */
import React, { useState, useRef, useMemo } from 'react';
import * as PopupMenuItem from '../popup_menus/PopupMenuItem';
import { IconButton } from 'blackbird/components/common/IconButton';
import TransferIcon from 'blackbird/images/icons/move-to-storyboard.svg';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import Checkbox from 'blackbird/components/form/checkbox/Checkbox';
import Popover from 'blackbird/components/common/popover/Popover';
import { ClearButton } from 'blackbird/components/button/ClearButton';
import Panel from 'blackbird/components/panel/Panel';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Icon from 'blackbird/components/icon/Icon';
import SearchIcon from 'blackbird/images/icons/search.svg';
import { isNumber } from 'underscore';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';

const LS_KEY = 'redirectAfterFrameMove';
const maxHeight = { maxHeight: '60vh' };

const filterComponents = (components, searchQuery) => {
  if (searchQuery.length < 2) return components;
  return components.filter(
    (c) => c.label?.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0,
  );
};
const FrameTransferPopupMenu = (props) => {
  const firstOpen = useRef(true);
  const [open, setOpen] = useState(false);
  const [storyboards, setStoryboards] = useState(undefined);
  const [redirectAfterMove, setRedirectAfterMove] = useState(
    LocalState.getValue(LS_KEY) ?? true,
  );
  const [activeIndex, setActiveIndex] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dialogContext = React.useContext(DialogContext);
  const [isEmpty, setIsEmpty] = useState(false);
  const components = [];
  const padding = 'py-3 px-4';

  const handleRedirectPreferenceChange = React.useCallback(
    (e) => {
      const newValue = e.currentTarget.checked;
      setRedirectAfterMove(newValue);
      LocalState.setValue(LS_KEY, newValue);
    },
    [setRedirectAfterMove],
  );

  const FetchStoryboardsAsync = React.useCallback(async () => {
    try {
      const fetchStoryboards = await fetch(
        `/api/frames/${props.framesToOperateOn[0].id}/destination_storyboards`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-API-KEY': BoordsConfig.AuthenticationToken,
          },
        },
      );

      const res = await fetchStoryboards.json();

      if (fetchStoryboards.ok) {
        if (res.data.length === 0) {
          setIsEmpty(true);
        } else {
          setStoryboards(res.data);
        }
      }
    } catch (err) {
      RequestActions.error.defer('Error fetching storyboard destinations');
    }
  }, [props.framesToOperateOn]);

  const TransferStoryboard = React.useCallback(
    async (args) => {
      try {
        const transfer = await fetch(`/api/frames/${args.frame_id}/move`, {
          method: 'put',
          body: JSON.stringify({
            data: {
              attributes: {
                source_storyboard_hashid: args.from,
                destination_storyboard_hashid: args.to,
              },
            },
          }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-API-KEY': BoordsConfig.AuthenticationToken,
          },
        });

        const res = await transfer.json();

        if (transfer.ok) {
          Track.event.defer('transfered_frame_to_new_storyboard');
          if (redirectAfterMove) {
            window.location = args.edit_url;
          }
        }
      } catch (err) {
        RequestActions.success.defer('Error transferring frame');
      }
    },
    [redirectAfterMove],
  );

  const button = (
    <IconButton
      icon={<TransferIcon />}
      aria-label="Move to storyboard"
      onClick={(e) => {
        e.stopPropagation();
        setOpen((open) => !open);
        handleOpen();
      }}
    />
  );

  const emptyState = isEmpty ? (
    <>
      <PopupMenuItem
        label="No destination storyboards"
        description="You can't move this frame"
        height="min-h60px"
        showBorder={false}
        showArrow={false}
        padding={padding}
      />
    </>
  ) : (
    <div className="p-3">
      <LoadingIndicator className="w-6 h-6" />
    </div>
  );

  const allStoryboards = storyboards?.map((storyboard, index) => {
    let label;

    if (props.versionShortSlugs.includes(storyboard.attributes.short_slug)) {
      label = `(${storyboard.attributes.version_name}) ${storyboard.attributes.name}`;
    } else {
      label = storyboard.attributes.name;
    }

    return {
      label: label,
      description: `${storyboard.relationships.project.attributes.name}`,
      onClick: async () => {
        if (
          await openConfirmDialog(
            `Move selected frame to '${label}'?`,
            dialogContext,
          )
        ) {
          TransferStoryboard({
            frame_id: props.framesToOperateOn[0].id,
            from: props.sourceStoryboardShortSlug,
            to: storyboard.attributes.short_slug,
            edit_url: storyboard.attributes.edit_url,
          });
        }
      },
    };
  });

  const handleOpen = () => {
    if (firstOpen.current) {
      FetchStoryboardsAsync();
      firstOpen.current = false;
    }
  };

  const isActive = (index) =>
    isNumber(activeIndex) && components.length + index === activeIndex;

  const filteredStoryboards = useMemo(
    () => filterComponents(allStoryboards, searchQuery),
    [allStoryboards, searchQuery],
  );
  const updateSearchQuery = (value) => {
    setSearchQuery(value);
    setActiveIndex(0);
  };
  return (
    <Popover distance={10} placement="bottom" isOpen={open}>
      <Popover.Button>{button}</Popover.Button>
      <Popover.Panel
        as={Panel}
        className="absolute left-0 flex-col w-80 top-full focus:outline-none"
        autoMaxHeight
      >
        <TextInput
          className="m-4 mb-0"
          value={searchQuery}
          name="searchQuery"
          inputSize="md"
          placeholder="Search storyboards"
          onChange={(e) => updateSearchQuery(e.target.value)}
          // ref={searchfieldRef}
          leftComponent={
            <Icon color="subdued" icon={<SearchIcon />} className="w-4 ml-1" />
          }
          rightComponent={<ClearButton onClick={() => updateSearchQuery('')} />}
          autoComplete="off"
          autoFocus
        />
        <div className="pt-4 overflow-y-auto flex--auto" style={maxHeight}>
          {filteredStoryboards?.length > 0
            ? filteredStoryboards.map((storyboard, index) => (
                <PopupMenuItem
                  key={'storyboard-' + index}
                  onClick={storyboard.onClick}
                  onClickProps={storyboard.clickParam}
                  className={storyboard.className}
                  label={storyboard.label}
                  description={storyboard.description}
                  showBorder={index !== allStoryboards.length - 1}
                  isActive={isActive(index)}
                  height="h-[60px]"
                  padding={padding}
                  icon={storyboard.icon}
                  onMouseEnter={() => setActiveIndex(index)}
                />
              ))
            : emptyState}
        </div>

        <div className="border-t border-border">
          <div className="flex items-center p-4">
            <Checkbox
              id="redirectAfterMoving"
              value={redirectAfterMove}
              onChange={handleRedirectPreferenceChange}
              label=" Redirect after moving"
            />
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default FrameTransferPopupMenu;
