/** @prettier */
import * as React from 'react';
import { IFPermissionPanel } from 'javascripts/helpers/has-permission';
import {
  activityLogType,
  ActivityLogStore,
  ActivityItem,
  availableActivityLogTypes,
} from '../../flux/stores/ActivityLog';
import { useStore } from '../../helpers/useStore';
import { ActivityLogActions } from '../../flux/actions/activityLog';
import { ActivityLogItem } from './ActivityLogItem';
import { ErrorBoundary } from './ErrorBoundary';
import { useTranslation } from 'react-i18next';
import Button from 'blackbird/components/button/Button';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import ActivityIcon from 'blackbird/images/icons/activity.svg';
import { EmptyState } from 'blackbird/components/feedback/empty-state/EmptyState';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';

const PAGE_AMOUNT = 50;

interface IActivityLogProps {
  for: activityLogType;
  id: number;
  refreshIntervalInSeconds?: number;
}

const ActivityLog: React.FC<IActivityLogProps> = ({
  refreshIntervalInSeconds = 30,
  ...props
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sidebar.settings.activity',
  });
  const timerRef = React.useRef<any>(null);
  const [pageCount, setPageCount] = React.useState(1);

  const incrementPage = React.useCallback(
    () => setPageCount((state) => state + 1),
    [setPageCount],
  );

  if (availableActivityLogTypes.indexOf(props.for) < 0)
    //prettier-ignore
    throw new Error(
      `${props.for} is not a valid activity type, expects one of ${availableActivityLogTypes.join(', ',)}`,
    );

  const activities = useStore<ActivityItem[] | undefined, ActivityLogStore>(
    'activityLog',
    (store) => store.activities[props.for][props.id],
  );

  React.useEffect(() => {
    ActivityLogActions.fetch.defer({ id: props.id, type: props.for });

    timerRef.current = setInterval(
      () =>
        ActivityLogActions.refresh.defer({
          id: props.id,
          type: props.for,
        }),
      refreshIntervalInSeconds * 3600,
    );

    return () => {
      clearInterval(timerRef.current);
    };
  }, [props.for, props.id, refreshIntervalInSeconds]);

  return typeof activities === 'undefined' ? (
    <LoadingIndicator text={t('loading')} />
  ) : (
    <>
      {activities && activities.length ? (
        <>
          <div className="space-y-3">
            {activities.slice(0, pageCount * PAGE_AMOUNT).map((activity) => (
              <ActivityLogItem key={activity.id} activity={activity} />
            ))}
          </div>

          {activities.length > pageCount * PAGE_AMOUNT ? (
            <div className="mt-4">
              <Button size="sm" onClick={incrementPage}>
                {t('load_more_activities')}
              </Button>
            </div>
          ) : pageCount > 1 ? (
            <EmptyState icon={<ActivityIcon />}>
              {t('no_activities')}
            </EmptyState>
          ) : null}
        </>
      ) : (
        <EmptyState icon={<ActivityIcon />}>
          {t('no_activities_to_display')}
        </EmptyState>
      )}
    </>
  );
};

const Memoized = React.memo(ActivityLog);
export { Memoized as ActivityLog };

(window as any).ActivityLog = (props) => (
  <ErrorBoundary size="small">
    <Memoized {...props} />
  </ErrorBoundary>
);

interface IActivityLogContainerProps extends IActivityLogProps {
  showTitle?: boolean;
  user_id?: number;
  align?: 'left' | undefined;
}

export const ActivityLogContainer: React.FC<IActivityLogContainerProps> = (
  props,
) => {
  const { t } = useTranslation();
  return (
    <DefaultStoreContextProvider>
      <IFPermissionPanel
        storyboardOwnerId={props.user_id ? props.user_id : props.id}
        featureName="activity_log"
        align={props.align}
      >
        <>
          {props.showTitle && (
            <div className="mb-4 text-lg">
              {t('sidebar.settings.activity.title')}
            </div>
          )}
          <ActivityLog {...props} />
        </>
      </IFPermissionPanel>
    </DefaultStoreContextProvider>
  );
};

(window as any).ActivityLogContainer = ActivityLogContainer;
