/** @prettier */
import { detectCharset } from '../../../helpers/detectCharset';
import { IFrame } from '../../../types/frame';
import { PDFDocumentStyle, DocInfo } from '../types';
import { eachFrameFieldMap } from '../../../helpers/eachFrameFieldMap';
import { getPDFIcon } from './loadPDFIcons';
import { createTextFactory } from './createText';
import { createPDFImage } from './PDFImage';
import { getFrameField } from 'javascripts/helpers/fieldDataHelpers';

interface Props {
  x: number;
  y: number;
  marginBottom?: number;
  frame: IFrame;
  style: PDFDocumentStyle;
  width: number;
  labelPadding?: number;
  isDebug?: boolean;
  itemMargin?: number;
}

export const createPDFFrameFields = (
  docInfo: DocInfo,
  { style, width, labelPadding = 2, ...props }: Props,
) => {
  const doc = docInfo.doc;
  const dimensions = { width: width, height: 0 };
  const iconSize = style.fontSize * style.lineHeightFactor;
  const frameNumber = props.frame.number || props.frame.sort_order;
  // Used for measurement
  doc.setFontSize(style.fontSize);
  const frameNumberWidth = Math.round(
    doc.getTextWidth(frameNumber) + labelPadding * 2,
  );
  const frameNumberHeight =
    style.lineHeightFactor * style.fontSize + labelPadding * 2;
  const iconAndNumberWidth = Math.max(iconSize, frameNumberWidth);
  /** We want to center the icon position based on the frame number width */
  const iconPositionOffset = (iconAndNumberWidth - iconSize) / 2;
  const marginBottom = props.marginBottom || doc.getFontSize() * 0.65;

  let offsetY = props.y;
  const itemMargin = props.itemMargin || 7.5;
  const createText = createTextFactory(docInfo);

  eachFrameFieldMap(style.frameFields, (field, fieldId) => {
    if (
      !style.showFrameNumber &&
      !style.showLabelField &&
      fieldId === 'reference'
    ) {
      return;
    }

    const value = getFrameField(props.frame, fieldId);
    if (
      value.length === 0 &&
      fieldId === 'reference' &&
      !style.showFrameNumber
    ) {
      return;
    }

    const minRowHeight = fieldId === 'reference' ? frameNumberHeight : iconSize;

    // We want to make sure we render the frame number,
    // even if the reference value is empty
    if ((value && value.length) || fieldId === 'reference') {
      if (style.fontSize) doc.setFontSize(style.fontSize);

      // We want to know if we want to place the frame number on the left
      // or the right of the text, so base that on if the first line uses a RTL
      // script. We fall back to a 'global' guess that we made when determining
      // the charset requirement for fonts.
      const firstLine = doc.splitTextToSize(value, width - 20)[0];
      const isRtl = firstLine.length
        ? detectCharset(firstLine).isRtl
        : style.isGlobalRtl || false;

      let textMargin = 0;

      if (fieldId === 'reference' && style.showFrameNumber) {
        doc.setFillColor(style.colors.veryLight);
        doc.roundedRect(
          isRtl ? props.x + width - iconAndNumberWidth : props.x,
          offsetY,
          iconAndNumberWidth,
          frameNumberHeight,
          2,
          2,
          'F',
        );

        /** Because the frame number might be wider, we want to give the icon
         * an offset to center it */
        const iconOffset = iconAndNumberWidth / 2;
        createText(frameNumber, {
          x: isRtl ? props.x + width - iconOffset : props.x + iconOffset,
          y:
            offsetY +
            labelPadding +
            (style.lineHeightFactor - 1) * 0.5 * style.fontSize,
          maxWidth: iconAndNumberWidth,
          lineHeightFactor: 1,

          align: 'center',
          debug: props.isDebug,
          fontSize: style.fontSize,
          color: '#000000',
          skipMarkdown: true,
        });

        textMargin = iconAndNumberWidth + itemMargin;
        offsetY += labelPadding;
      } else if (field.icon && style.showIcons) {
        createPDFImage(docInfo, {
          imageInfo: getPDFIcon(field.icon)!,
          x: isRtl
            ? props.x + width - iconPositionOffset - iconAndNumberWidth
            : props.x + iconPositionOffset,
          y: offsetY,
          maxWidth: iconSize,
          maxHeight: iconSize,
        });

        textMargin = iconAndNumberWidth + itemMargin;
      }

      const textValue =
        fieldId === 'reference' && !style.showLabelField ? ' ' : value;

      const text = createText(textValue, {
        x: isRtl ? props.x : props.x + textMargin,
        y: offsetY + 1,
        maxWidth: width - textMargin,
        lineHeightFactor: style.lineHeightFactor,
        baseline: 'top',
        debug: props.isDebug,
        fontSize: style.fontSize,
        color: style.colors.text,
      });
      offsetY += Math.max(minRowHeight, text.height);

      if (props.isDebug) {
        doc.setDrawColor(200, 200, 255);
        doc.line(props.x, offsetY, props.x + width, offsetY);
      }
      // Add a line break at the bottom
      offsetY += marginBottom;
    }
  });

  dimensions.height = offsetY - props.y - marginBottom;

  return dimensions;
};
