/** @format */

import React from 'react';
import { TeamPlanBadge } from './TeamPlanBadge';

export const TeamTrialBlock: React.FC = () => {
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        FlyoverActions.open.defer({
          type: 'inlinePricing',
        });
      }}
    >
      <TeamPlanBadge planName={`Trialing`} />
    </div>
  );
  return (
    <div
      className="px-2 py-2 cursor-pointer bg-trial-100 rounded-md text-trial-600 hover:bg-trial-50"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        FlyoverActions.open.defer({
          type: 'inlinePricing',
        });
      }}
    >
      <div className="flex items-start">
        <div className="flex items-center flex-auto mb-2">
          <TeamPlanBadge planName={`Trialing`} />
          <div className="ml-1.5 text-xs font-semibold">{`${BoordsConfig.TrialDaysRemaining} remaining`}</div>
        </div>
      </div>
      <div className="pl-0.5 text-xs mb-3">
        {`Upgrade now for unlimited storyboards, members, and more.`}
      </div>

      <div className="uppercase font-semibold rounded-sm px-1.5 py-0.5 text-xxs tracking-wider bg-trial-600 text-trial-100 text-center">{`Upgrade`}</div>
    </div>
  );
};
