/** @format */

import classNames from 'classnames';
import { useStore } from 'javascripts/helpers/useStore';
import type { UserStore } from 'javascripts/flux/stores/user';
import type { UserResponse } from 'javascripts/types/user';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button/Button';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import { annualUpgradeDismissedLocalState } from 'javascripts/helpers/local-state';

export const AnnualUpgradeCtaComponent: React.FC = () => {
  const { t } = useTranslation(`billing`, {
    keyPrefix: 'checkout.annualUpgrade',
  });

  const user = useStore<UserResponse | undefined, UserStore>(
    'userStore',
    (u) => u.user,
  );

  // This only shows when the user has dismissed the
  // upgrade banner
  return user &&
    user.is_eligible_for_annual_upgrade &&
    annualUpgradeDismissedLocalState.getValue() ? (
    <div
      className={classNames(
        'flex items-center my-6 text-white bg-black rounded-md',
        'px-6 pt-4 pb-5',
      )}
    >
      <div className="flex-auto pr-10">
        <div className={classNames('font-semibold mb-1')}>{t(`title`)}</div>
        <div className={classNames('opacity-70', 'text-sm')}>
          {t(`subtitle`)}
        </div>
      </div>

      <div className="flex-shrink-0 text-center">
        <Button
          rounded
          type="fancy"
          size={'sm'}
          link={`/checkout/${BoordsConfig.PlanSlug}/yearly`}
        >
          {t(`cta`)}
        </Button>
      </div>
    </div>
  ) : null;
};

export const AnnualUpgradeCta: React.FC = (props) => {
  return (
    <DefaultStoreContextProvider>
      <AnnualUpgradeCtaComponent {...props} />
    </DefaultStoreContextProvider>
  );
};

(window as any).AnnualUpgradeCta = AnnualUpgradeCta;
