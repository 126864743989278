/** @prettier */
import { INormalizedData } from '../components/comments/types';
import { Comment } from '../../javascripts/flux/stores/comment';

const normalize = (comments: Comment[]) => {
  return comments.reduce<INormalizedData>(
    (normalData: INormalizedData, item: Comment) => {
      let replies = normalData.replies;
      let comments = normalData.comments;
      /**Normalizing Replies */
      if (item.in_reply_to_comment_id) {
        const byIdReplies = normalData.replies.byId;
        const currentData = byIdReplies[item.in_reply_to_comment_id] ?? [];
        const newById = {
          ...byIdReplies,
          [item.in_reply_to_comment_id]: [...currentData, item],
        };
        replies = { byId: newById };
      }
      /** Normalizing Comments */
      const byIdComments = normalData.comments.byId;
      const newCommentsById = {
        ...byIdComments,
        [item.id]: item,
      };
      comments = { byId: newCommentsById };

      return { ...normalData, replies, comments };
    },
    { replies: { byId: {} }, comments: { byId: {} } },
  );
};
function flattenReplies(comments: Comment[]): Comment[] {
  let result: Comment[] = [];
  comments.forEach((comment: Comment) => {
    result.push(comment);
    if (Array.isArray(comment.replies)) {
      result = result.concat(flattenReplies(comment.replies));
    }
  });
  return result;
}
function deleteComment(
  commentId: number,
  comments: Comment[],
): [Comment[], Comment | null] {
  let deletedComment: Comment | null = null;
  let updatedComments: Comment[] = comments;
  for (const comment of updatedComments) {
    if (comment.id === commentId) {
      updatedComments = comments.filter((c) => c.id !== commentId);
      deletedComment = comment;
      break;
    }
    if (Array.isArray(comment.replies)) {
      const [updatedReplies] = deleteComment(commentId, comment.replies);
      comment.replies = updatedReplies;
    }
  }
  return [updatedComments, deletedComment];
}
function flatMap<T>(array: Array<T>, lambda: (item: T) => Array<any>) {
  return Array.prototype.concat.apply([], array.map(lambda));
}

function slugToType(name: string): string {
  var normalized = name.toLowerCase();
  normalized = normalized.split('-')[0];
  normalized = normalized.replace('annual', '');
  return normalized;
}
export { flatMap, normalize, slugToType, flattenReplies, deleteComment };
