/** @prettier */
/* eslint  react-perf/jsx-no-new-object-as-prop:0 */
import * as React from 'react';
import * as OverlayListItem from '../../shared/OverlayListItem';
import { IPanelProps } from '../../shared/MultiPanel';
import * as Icon from '../../shared/Icon';
import DropboxIcon from 'blackbird/images/icons/dropbox.svg';
type IProps = IPanelProps;

const integrationIconClassName = 'mh1 bg-white br-100 shadow-subtle pa2';

const listItemProps = {
  showBorder: false,
  center: false,
  showArrow: true,
  padding: 'px-3',
  height: 'h-[42px]',
  size: 'larger',
  animateIconOpacity: true,
};

const handlePDFExportClick = () => {
  FlyoverActions.open({ type: 'storyboardExport' });
};

const handlePresentationExportClick = () => {
  FlyoverActions.open({ type: 'presentationExport' });
};

export const ExportOptions = React.memo<IProps>((props) => (
  <>
    <div className="my-2">
      <OverlayListItem
        {...listItemProps}
        onClick={handlePDFExportClick}
        text="PDF"
        showArrow={false}
        showPopupIndicator
      />

      <OverlayListItem
        {...listItemProps}
        onClick={handlePresentationExportClick}
        text="Google Slides"
        showArrow={false}
        showPopupIndicator
      />

      <OverlayListItem
        {...listItemProps}
        onClick={props.goToPanelF('animatic')}
        text="Animatic"
        showArrow={true}
      />

      <OverlayListItem
        {...listItemProps}
        onClick={props.goToPanelF('spreadsheet')}
        text="Google Sheets"
        showArrow={true}
      />

      <OverlayListItem
        {...listItemProps}
        onClick={props.goToPanelF('script')}
        text="Script"
        showArrow={true}
      />

      <OverlayListItem
        {...listItemProps}
        onClick={props.goToPanelF('images')}
        text="Images"
        showArrow={true}
      />

      <OverlayListItem
        {...listItemProps}
        onClick={props.goToPanelF('afterEffects')}
        text="After Effects"
        showArrow={true}
      />
    </div>
  </>
));

ExportOptions.displayName = 'StoryboardExportOptions';
