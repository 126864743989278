const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const FramePreview = require('./TimelineFramePreview.jsx');
const Container = require('./Container2.jsx');
const ScrollBar = require('react-gemini-scrollbar');

const Timeline = createReactClass({
  displayName: 'PlayerTimeline',

  propTypes: {
    frames: PropTypes.array.isRequired,
    frameSize: PropTypes.object.isRequired,
    currentFrameId: PropTypes.number.isRequired,
    goToFrame: PropTypes.func.isRequired,
    theme: PropTypes.string.isRequired,
  },

  componentDidUpdate(prevProps) {
    if (prevProps.currentFrameId !== this.props.currentFrameId) {
      const node = ReactDOM.findDOMNode(this.refs[this.props.currentFrameId]);
      node.scrollIntoView(false);
    }
  },

  render() {
    const {
      frames, frameSize, currentFrameId
    } = this.props;

    return (
      <div className="storyboardPlayer__timelineContainer">
        <ScrollBar autoshow>
          <div className="gap-3 flex flex-nowrap">
            { frames.map( f => (
              <FramePreview
                frame={f}
                key={f.id}
                ref={f.id}
                isActive={f.id === currentFrameId}
                frameSize={frameSize}
                onClick={this.props.goToFrame}
                theme={this.props.theme}
              />
            ))}
          </div>
        </ScrollBar>
      </div>
    )
  }
});

module.exports = Container(Timeline);
