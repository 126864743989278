window.DashboardActions = alt.createActions(function () {

  this.fetch = function() {
    this.dispatch();
  };

  this.receive = function(dashboard) {
    this.dispatch(dashboard);
  };

  this.addProject = function(project) {
    this.dispatch(project);
  };

  this.updateProject = function (data) {
    this.dispatch(data);
  };

  this.archiveProject = function (project) {
    this.dispatch(project);
  };

  this.toggleArchive = function(showArchive) {
    this.dispatch(showArchive);
  };

  this.updateBoardsOrder = function(state) {
    this.dispatch(state);
  };

  this.updateProjectOrder = function(state) {
    this.dispatch(state);
  };

});

