const createReactClass = require('create-react-class')
window.StoryboardVersion = createReactClass({

  getInitialState: function() {
    return {
      isOpen: false
    }
  },

  render:  function() {

    var isActive,
      activeVersionNumber;

    return (
      <div className="select btn--dropdown" onClick={this._onClick}>
        {/*<div className="dropdown--trigger button">{"Version " + activeVersionNumber}</div>*/}
        {/*<div className="dropdown--list">{versions}</div>*/}
        <StoryboardVersion versions={this.props.versions} />
      </div>
    )
  }
});

