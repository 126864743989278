/** @format */

import React, { createContext, useCallback, useEffect, useState } from 'react';
import { UserActions } from 'javascripts/flux/actions/user';
import i18n from 'i18next';
import logger from 'javascripts/helpers/logger';

export interface OnboardingQuestion {
  label: string;
  slug: string;
  description?: string;
  selected?: boolean;
}

export interface OnboardingStep {
  heading: string;
  question: string;
  help?: string;
  id: string;
  multipleChoice?: boolean;
  skip?: boolean;
  options: OnboardingQuestion[];
  additionalStep?: OnboardingStep;
}

interface OnboardingContextProps {
  index: number;
  canProceed: boolean;
  canSave: boolean;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  steps: OnboardingStep[];
  activeStep: OnboardingStep;
  handleProceed: () => void;
  accountType: string;
  startWith: string;
  setAccountType: React.Dispatch<React.SetStateAction<string>>;
  setStartWith: React.Dispatch<React.SetStateAction<string>>;
  setActiveStep: React.Dispatch<React.SetStateAction<OnboardingStep>>;
  setOptionSelected: React.Dispatch<React.SetStateAction<string>>;
  setCanProceed: React.Dispatch<React.SetStateAction<boolean>>;
  setCanSave: React.Dispatch<React.SetStateAction<boolean>>;
}

const steps: OnboardingStep[] = i18n.t('onboarding:steps', {
  returnObjects: true,
});

const initialContext = {
  index: 0,
  accountType: '',
  startWith: '',
  canProceed: false,
  canSave: false,
  activeStep: steps[0],
  steps: steps,
  handleProceed: () => {},
  saveStartWith: () => {},
  setIndex: () => {},
  setCanSave: () => {},
  setAccountType: () => {},
  setStartWith: () => {},
  setCanProceed: () => {},
  setActiveStep: () => {},
  setOptionSelected: () => {},
};

export const OnboardingContext =
  createContext<OnboardingContextProps>(initialContext);

export const OnboardingProvider: React.FC = ({ children }) => {
  const [index, setIndex] = useState<number>(initialContext.index);
  const [canSave, setCanSave] = useState<boolean>(initialContext.canSave);
  const [canProceed, setCanProceed] = useState<boolean>(
    initialContext.canProceed,
  );
  const [accountType, setAccountType] = useState<string>(
    initialContext.accountType,
  );
  const [startWith, setStartWith] = useState<string>(initialContext.startWith);
  const [activeStep, setActiveStep] = useState<OnboardingStep>(
    initialContext.activeStep,
  );

  const getSelectedOptionSlugs = useCallback(() => {
    if (activeStep && activeStep.options) {
      return activeStep.options
        .filter((option) => option.selected)
        .map((option) => option.slug);
    }
    return [];
  }, [activeStep]);

  // Updates the step index
  const handleProceed = useCallback(() => {
    if (index === steps.length - 1) {
      // We can't get getSelectedOptionSlugs here...
    } else {
      setIndex(index + 1);
    }
    setCanSave(true);
  }, [index]);

  // Sets buttons as selected
  const setOptionSelected = (slug: string) => {
    setActiveStep((prevStep) => {
      const updatedOptions = prevStep.options.map((option) => {
        if (option.slug === slug) {
          return { ...option, selected: !option.selected };
        }
        return option;
      });
      return { ...prevStep, options: updatedOptions };
    });
    // Move to the next step for non-multiple choice answers
    if (activeStep && !activeStep.multipleChoice) {
      handleProceed();
    }
  };

  const saveRole = (slug: string) => {
    setAccountType(slug);
    Track.event.defer(`uc_${slug}`);
    Track.event.defer('ob_audience', {
      category: 'Onboarding',
      label: slug,
    });
    UserActions.updateFields.defer({
      account_type: slug,
    });
  };

  const saveJobsToBeDone = (jobs: string[]) => {
    jobs.map((job) => {
      Track.event.defer(`ob_jtbd_${job}`);
    });
  };

  const saveData = useCallback(() => {
    const selectedOptions = getSelectedOptionSlugs();

    if (activeStep.id === 'role') {
      saveRole(selectedOptions[0]);
    } else if (activeStep.id === 'jtbd') {
      saveJobsToBeDone(selectedOptions);
      if (!selectedOptions.includes('collaborate_with_my_team')) {
        setIndex(index + 1);
        setCanProceed(true);
      }
    } else {
      logger.log('unknown ID?');
      logger.log(activeStep);
    }
  }, [index, activeStep, getSelectedOptionSlugs]);

  const countSelectedOptions = useCallback(() => {
    if (activeStep && activeStep.options) {
      return activeStep.options.reduce((count, option) => {
        if (option.selected) {
          return count + 1;
        }
        return count;
      }, 0);
    }
    return 0;
  }, [activeStep]);

  // Triggers save events
  useEffect(() => {
    if (canSave) {
      setCanSave(false);
      saveData();
    }
  }, [canSave, saveData]);

  // Updates the activeStep when index changes
  useEffect(() => {
    if (index) {
      setActiveStep(steps[index]);
    }
  }, [index]);

  // Detemines whether we can move to the next step
  useEffect(() => {
    if (activeStep && activeStep.options) {
      setCanProceed(countSelectedOptions() > 0);
    }
  }, [activeStep, countSelectedOptions]);

  // Skip the "start with" step
  useEffect(() => {
    if (activeStep && activeStep.id === 'start_with' && activeStep.skip) {
      setStartWith('start_from_scratch');
      setCanProceed(true);
    }
  }, [activeStep]);

  const values = {
    index,
    setIndex,
    accountType,
    setAccountType,
    canSave,
    setCanSave,
    canProceed,
    handleProceed,
    setCanProceed,
    startWith,
    setStartWith,
    steps,
    activeStep,
    setActiveStep,
    setOptionSelected,
  };

  return (
    <OnboardingContext.Provider value={values}>
      {children}
    </OnboardingContext.Provider>
  );
};
