/** @prettier */
/* eslint-disable no-console */

const noop = (...args) => {};

/**
 * A noisy console is a bit hard to work with (especially when working on
 * unrelated stuff), so we should be a bit more dilligent with figuring out
 * what really needs to be logged to the user's console, and what can be left to
 * error handling and other telemetry.
 *
 * That's why eslint now rejects console.logs (and to prevent accidentally
 * leaving in log statements.
 *
 * This is a basic wrapper for console.log that can be used when you *really*
 * want to write to the console. This way it communicates specific intent. */
const logger =
  process.env.NODE_ENV !== 'test'
    ? console
    : ({
        log: noop,
        warn: noop,
        error: noop,
      } as Console);
export default logger;

export const logInDevelopment =
  process.env.NODE_ENV === 'development' ? console.log : noop;
