/** @prettier */
import * as React from 'react';
import { SaleContext } from '../sale/SaleContext';
import { parseISO } from 'date-fns';
import { SaleTimer } from 'javascripts/components/shared/CountdownTimer';

export const PricingTableSaleBanner: React.FC = () => {
  const { activeSale } = React.useContext(SaleContext);

  return !activeSale ? null : (
    <>
      <div className="absolute flex items-center px-10 -top-[2.5rem] -left-10 -right-10 bg-brand-yellow-25 py-2 text-sm">
        <div className="flex items-center flex-auto">
          <div className="flex items-center pl-3 pr-1.5 pt-1 pb-1.5 leading-tight font-semibold rounded-md bg-brand-yellow/70 -ml-2">
            <span>{activeSale.bannerBadgeIcon}</span>
            <span className="ml-1.5 mr-1 text-black/70">
              {activeSale.bannerBadgeText}
            </span>
          </div>

          <div className="ml-3 mr-3 font-semibold space-x-1.5">
            <span>{`${activeSale.bannerDescription}.`}</span>
          </div>
        </div>

        <span className="inline-block pl-1.5 rounded-md bg-white/30 text-brand-pink pb-0.5 pr-1.5">
          <span className="mr-1 font-semibold">{`Offer ends in`}</span>
          <SaleTimer endDate={parseISO(activeSale.endDate)} />
        </span>
      </div>
    </>
  );
};
