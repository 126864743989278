/** @format */

import React from 'react';

import HalfCircle from 'blackbird/images/icons/half-circle.svg';
import Square from 'blackbird/images/icons/square.svg';
import VerticalFreq from 'blackbird/images/icons/move-vertical-frequency.svg';
import MoveHorizontal from 'blackbird/images/icons/move-horizontal.svg';
import Circle from 'blackbird/images/icons/circle.svg';
import WaveForCircle from 'blackbird/images/icons/wave-for-circle.svg';
import QuarterSquareCircle from 'blackbird/images/icons/quarter-scale-circle.svg';

import Sparkle from 'blackbird/images/icons/sparkle.svg';

export const SparkleGraphic: React.FC = () => {
  return (
    <>
      <div className="absolute right-0 flex flex-col top-36 w-36 gap-14">
        <MoveHorizontal className="moveHorizontal" />
        <Sparkle />
      </div>
      <div className="absolute flex flex-col w-12 gap-4 left-44 top-1/2 -translate-y-1/2">
        <VerticalFreq className="flex-shrink-0" />
        <Sparkle className="sparkleFlipped" />
      </div>
      <div className="absolute bottom-0 text-white left-6">
        <div className="relative h-60">
          <Square className="text-black fill-white absolute bottom-0 h-[100px] w-[100px]" />
          <Circle className="absolute left-6 bottom-[96px] h-[100px] w-[100px]" />
          <div className="absolute left-[98px] bottom-0">
            <div className="relative h-[100px] w-[100px]">
              <Circle className="absolute" />
              <WaveForCircle className="absolute top-0 left-0" />
            </div>
          </div>
          <div className="absolute  bottom-[96px] left-[160px]">
            <div className="relative h-[100px] w-[100px]">
              <Square className="-rotate-[25deg] text-black fill-white" />
              <QuarterSquareCircle className="absolute right-0 w-6 h-6 text-black fill-white top-1/2" />
            </div>
          </div>
          <div className="absolute  bottom-0 left-[225px]">
            <div className="relative h-[100px] w-[100px]">
              <Square className="text-black fill-white" />
              <HalfCircle className="absolute -right-[10px] top-6 translate-y-1/2 -rotate-90" />
            </div>
          </div>
        </div>
      </div>
      <Square className="text-black fill-white absolute h-[100px] w-[100px] left-0 top-1/2 -rotate-[15deg] -translate-x-8" />
    </>
  );
};

(window as any).SparkleGraphic = SparkleGraphic;
