const { default: Dialog } = require('blackbird/components/dialog/Dialog');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');

window.FlyoverSection = createReactClass({

  propTypes:    {
    component:  PropTypes.element,
    title:      PropTypes.string, // If undefined will just show the content
    description: PropTypes.string,
    icon:       PropTypes.string,
    className:  PropTypes.string,
    hideClose:  PropTypes.bool,
    isOpen:     PropTypes.bool
  },
  handleCancel:function(){
    FlyoverActions.close();
  },
  render: function() {
    return(
      <Dialog isOpen={this.props.isOpen} isDark title={this.props.title} hideActions  onCancel={this.handleCancel} description={this.props.description} >
        {this.props.component}
      </Dialog>
    );
  }

});
