/** @prettier */
import Button from 'blackbird/components/button/Button';
import Select from 'blackbird/components/form/select/Select';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import * as React from 'react';
import { indexBy } from 'underscore';

interface Props {
  countryFieldOptions: {
    name: string;
    placeholder?: string;
    defaultValue: string;
  };
  vatFieldOptions: {
    name: string;
    placeholder?: string;
    defaultValue: string;
  };
  buttonText?: string;
  onSubmit?: (options) => void;
  countries: Record<
    string,
    { value: string; label: string; needsVAT: boolean }
  >;
}

const CountryAndTaxField: React.FC<Props> = (props) => {
  const [submitted, setSubmitted] = React.useState(false);
  const { countryFieldOptions, vatFieldOptions } = props;
  const [country, setCountry] = React.useState(
    props.countryFieldOptions.defaultValue || 'US',
  );

  const [vatNumber, setVatNumber] = React.useState(
    props.vatFieldOptions.defaultValue || '',
  );
  const handleChange = (value) => setCountry(value);
  const options = Object.values(props.countries);
  return (
    <div className="flex flex-col gap-5">
      <div>
        <Select
          size="md"
          selectContainerClassName="w-full"
          placeholder={countryFieldOptions.placeholder}
          name={countryFieldOptions.name}
          value={country}
          label={`Country of Residence`}
          onChange={handleChange}
          options={options}
        />
      </div>
      {country &&
        props.countries[country] &&
        props.countries[country].needsVAT && (
          <div className="relative bg-white mb4 bb b--transparent bw2 tl t-ob br2 shadow-btn w-100 pt3">
            <TextInput
              inputSize="md"
              label={`VAT / Tax Number (Optional)`}
              name={vatFieldOptions.name}
              type="text"
              aria-label={`VAT / Tax Number (Optional)`}
              className="bg-transparent f5 pl3 pr3 bn w-100 pl-grey lh-solid pb3 pt1"
              value={vatNumber}
              onChange={(e) => {
                setVatNumber(e.target.value);
              }}
            />
          </div>
        )}
      {props.onSubmit && (
        <Button
          className="w-full"
          htmlType="button"
          onClick={() => {
            setSubmitted(true);
            props.onSubmit!({ vatNumber: vatNumber, country: country });
          }}
          size="lg"
        >
          {!submitted ? `Save` : 'Processing...'}
        </Button>
      )}
    </div>
  );
};

// Transform the countries array to something a bit more usable before we send
// it to the main component
const CountryAndTaxFieldContainer: React.FC<any> = (props) => {
  const countries = indexBy(
    props.countryFieldOptions.options.map((i) => ({
      value: i[0],
      label: i[1],
      needsVAT: i[2],
    })),
    'value',
  );

  return <CountryAndTaxField {...props} countries={countries} />;
};

(window as any).CountryAndTaxField = CountryAndTaxFieldContainer;
