/** @format */

import React, { useState, useCallback, useEffect } from 'react';
import { ProjectsProvider } from './ProjectsContext';
import { ProjectsAccordion } from './ProjectsAccordion';
import { ProjectCreateDialog } from './modals/ProjectCreateDialog';
import Logo from '../logo/Logo';
import { UserDropdownContainer } from '../menus/UserDropdown';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import classNames from 'classnames';
import {
  sidebarWidthLocalState,
  sidebarMinimizedLocalState,
} from 'javascripts/helpers/local-state';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import DialogContextProvider from '../dialog/DialogContext';
import { ProjectsMinimizedLogo } from './ProjectsMinimizedLogo';
import { ProjectSingleMoveDialog } from './modals/ProjectSingleMoveDialog';
import { TeamProvider } from '../team/TeamContext';
import { ProjectMainWindow } from './ProjectMainWindow';
import { BoordsDndProvider } from 'javascripts/components/shared/BoordsDndProvider';
import { ComponentComposer } from 'javascripts/components/shared/ComponentComposer';
import { TemplatesProvider } from '../templates/TemplatesContext';
import { WizardItems } from '../wizard/WizardItems';
import { WizardProvider } from '../wizard/WizardContext';

const MINIMIZED_WIDTH = 70;

interface ProjectsContainerProps {
  project_id?: string;
}

export const ProjectsContainer: React.FC<ProjectsContainerProps> = (props) => {
  const [sidebarWidth, setSidebarWidth] = useState(() => {
    const savedWidth = sidebarWidthLocalState.getValue();
    return savedWidth && savedWidth >= 200 && savedWidth <= 600
      ? savedWidth
      : 350;
  });
  const [isMinimized, setIsMinimized] = useState(
    () => sidebarMinimizedLocalState.getValue() ?? false,
  );
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
    document.body.style.userSelect = 'none';
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    document.body.style.userSelect = '';
    if (!isMinimized) {
      sidebarWidthLocalState.setValue(sidebarWidth);
    }
  }, [sidebarWidth, isMinimized]);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging && !isMinimized) {
        e.preventDefault();
        const newWidth = e.clientX;
        setSidebarWidth((prevWidth) => {
          const clampedWidth = Math.max(200, Math.min(600, newWidth));
          return clampedWidth;
        });
      }
    },
    [isDragging, isMinimized],
  );

  const toggleMinimized = useCallback(() => {
    setIsMinimized((prev) => {
      const newValue = !prev;
      sidebarMinimizedLocalState.setValue(newValue);
      return newValue;
    });
  }, []);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  const currentWidth = isMinimized ? MINIMIZED_WIDTH : sidebarWidth;

  useEffect(() => {
    Track.event.defer('dashboard_load', {}, 1000);
  }, []);

  const providers = React.useMemo<React.PropsWithChildren<any>[]>(
    () => [
      DefaultStoreContextProvider,
      BoordsDndProvider,
      [ProjectsProvider, { ...props, updateUrlOnLoad: true }],
      TeamProvider,
      TemplatesProvider,
      DialogContextProvider,
    ],
    [props],
  );

  return (
    <ComponentComposer items={providers}>
      <ProjectSingleMoveDialog />
      <ProjectCreateDialog />
      <div className="flex justify-end h-full max-h-dvh">
        {/* Sidebar container */}
        <div
          className="fixed h-full -left-5 z-sidebar group/projectsidebar"
          style={{ width: `${currentWidth + 20}px` }}
        >
          {/* Sidebar content */}
          <div
            className={classNames(
              'h-full flex flex-col flex-shrink-0 pt-0 ml-auto bg-white shadow-lg max-h-dvh',
              isMinimized && 'overflow-hidden',
            )}
            style={{ width: `${currentWidth}px` }}
          >
            {isMinimized ? (
              <div className="flex-auto">
                <div className="flex items-center justify-center mt-5">
                  <ProjectsMinimizedLogo />
                </div>
              </div>
            ) : (
              <div className="flex flex-col flex-auto mt-2 overflow-y-auto">
                <ProjectsAccordion />
              </div>
            )}

            {!isMinimized && (
              <div className="hidden short:block absolute bottom-[7.5rem] right-0 left-5 px-3">
                <WizardProvider>
                  <WizardItems standalone />
                </WizardProvider>
              </div>
            )}

            {/* Bottom bar */}
            <div
              className={classNames(
                'flex items-center flex-shrink-0 py-6 mx-5 accordion-bottom-bar',
                isMinimized ? '' : 'border-t border-border',
              )}
            >
              <div className="flex justify-start flex-auto">
                <UserDropdownContainer size="xs" placement="top-end" />
              </div>
              {!isMinimized && (
                <div className="flex-shrink-0">
                  <Logo mark={false} className="w-32" />
                </div>
              )}
            </div>
            {/* Draggable handle */}
            {!isMinimized && (
              <div
                className={classNames(
                  'absolute top-0 right-0 w-1 h-full cursor-ew-resize hover:bg-brand-blue transition-colors',
                  isDragging && 'bg-brand-blue',
                )}
                onMouseDown={handleMouseDown}
              />
            )}
          </div>
          {/* Toggle button */}
          <div
            className="absolute z-10 flex items-center justify-center w-6 h-6 bg-white border rounded-full shadow-md opacity-0 cursor-pointer border-border top-4 -right-3 text-type-subdued hover:text-type-primary group-hover/projectsidebar:opacity-100"
            onClick={toggleMinimized}
          >
            {isMinimized ? (
              <ChevronRight size={16} className="pl-0.5" />
            ) : (
              <ChevronLeft size={16} />
            )}
          </div>
        </div>
        {/* Main content */}
        <div className="w-full" style={{ paddingLeft: `${currentWidth}px` }}>
          <ProjectMainWindow />
        </div>
      </div>
    </ComponentComposer>
  );
};

(window as any).ProjectsContainer = ProjectsContainer;
