/** @prettier */
import * as React from 'react';
import type { ConnectDropTarget, DropTargetSpec } from 'react-dnd';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { AssetsActions } from '../../../flux/actions/assets';
import { FrameActions } from '../../../flux/actions/frame';
import type { IFrame } from '../../../types/frame';
import { canDropImages } from '../../shared/canDropImages';
import type {
  fileDropResult,
  imageLibraryDragItem,
} from '../../shared/dragAndDropUtils';
import {
  allAcceptedDropTypes,
  DRAGGABLE_TYPES,
} from '../../shared/dragAndDropUtils';
import { FileUploadActions } from '../../../flux/actions/file_upload';
import { without } from 'underscore';

interface DropParams {
  isOver?: boolean;
  canDrop?: boolean;
}
interface OuterProps {
  frame: IFrame;
  team_id: number;
  onDropParamsChange: ({ isOver, canDrop }: DropParams) => void;
  className?: string;
}

interface InnerProps extends OuterProps {
  connectDropTarget: ConnectDropTarget;
  isOver: boolean;
  canDrop: boolean;
}

const FrameImageDropZone: React.FC<InnerProps> = (props) => {
  const className = `flex items-center justify-center absolute inset-0 ${
    props.isOver && props.canDrop ? 'ring' : ''
  } ${props.className || ''}`;

  React.useEffect(() => {
    props.onDropParamsChange({ isOver: props.isOver, canDrop: props.canDrop });
  }, [props]);

  return props.connectDropTarget(
    <div className={className}>{props.canDrop ? props.children : null}</div>,
  );
};

const dropTargetSpec: DropTargetSpec<OuterProps, any> = {
  drop(props, monitor) {
    const type = monitor.getItemType() as typeof allAcceptedDropTypes[number];

    if (type === NativeTypes.FILE) {
      const item: fileDropResult = monitor.getItem();
      const file = item.items?.[0]?.getAsFile();

      if (file) {
        FrameActions.uploadImage.defer({
          id: props.frame.id,
          image_file_type: file.type,
          image_file_size: file.size,
          storyboard_id: props.frame.storyboard_id,
          background_image_url: null,
          file,
        });
      }
    } else if (type === DRAGGABLE_TYPES.frame) {
      const item: imageLibraryDragItem = monitor.getItem();
      AssetsActions.replaceExistingFrame({
        targetFrameId: props.frame.id,
        assetId: item.id,
        storyboardId: props.frame.storyboard_id,
        teamId: props.team_id,
      });
    } else if (type === DRAGGABLE_TYPES.unsplash) {
      const item: imageLibraryDragItem = monitor.getItem();
      FrameActions.updateImageStatus({
        frameId: props.frame.id,
        status: 'fetching',
      });

      // We fetch the image from unsplash, then
      fetch(item.image)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], item.image, {
            type: blob.type,
          });

          FileUploadActions.updateFrame({
            file: file,
            frame_aspect_ratio:
              StoryboardStore.getState().storyboard.frame_aspect_ratio,
            frame: props.frame,
            team_id: props.team_id,
          });
        });
    }
  },
  canDrop: canDropImages(true),
};

export default React.memo<OuterProps>(
  DropTarget(
    without(
      allAcceptedDropTypes,
      DRAGGABLE_TYPES.image,
      DRAGGABLE_TYPES.iconfinder,
    ),
    dropTargetSpec,
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  )(FrameImageDropZone),
);
