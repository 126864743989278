/** @format */
import * as React from 'react';
import Dialog from 'blackbird/components/dialog/Dialog';
import BoordsLogo from 'blackbird/images/logo/logomark.svg';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import Toggle from '../toggle/Toggle';
import { type Price } from 'blackbird/helpers/contexts/BillingContext';
import { PricingTablePlan } from './PricingTablePlan';
import { type PricingContainerProps } from './PricingContainer';
import { PricingContext } from './PricingContext';
import classNames from 'classnames';
import { SaleContext } from '../sale/SaleContext';
import { PricingTableSaleBanner } from './PricingTableSaleBanner';
import { PricingTableEduBanner } from './PricingTableEduBanner';
import { PricingTablePuffinPlan } from './PricingTablePuffinPlan';

interface PricingTableProps extends PricingContainerProps {
  title: string;
  subtitle: string;
}

export const PricingTable: React.FC<PricingTableProps> = ({
  triggeredFromFeatureName,
  title,
  subtitle,
}) => {
  const { isPuffinPrices, plans, billingInterval, setBillingInterval } =
    React.useContext(PricingContext);

  const { activeSale } = React.useContext(SaleContext);

  return !plans ? null : (
    <Dialog
      size={isPuffinPrices ? 'xl' : 'xl'}
      hideActions
      isDark
      isOpen
      title={`x`}
      onEscapeOrOutsideClick={() => {
        FlyoverActions.close.defer();
      }}
      onCloseBtnClick={() => {
        FlyoverActions.close.defer();
      }}
      wrapperClasses="!pb-0"
      containerClasses="!pb-0"
      titleTextContainerClasses="opacity-0"
      closeButtonClasses="z-[10001]"
      overlayClassName={`from-brand-pink-25 to-brand-yellow-25 bg-gradient-to-br`}
    >
      <div className="relative ">
        {/* Title & Logo */}
        <div className="flex items-center -mt-[4.7rem] relative">
          {BoordsConfig.IsEdu ? (
            <PricingTableEduBanner />
          ) : (
            <PricingTableSaleBanner />
          )}
          <div
            className={classNames(
              'flex-auto text-center',
              (activeSale || BoordsConfig.IsEdu) && 'pt-8',
            )}
          >
            <div className="flex justify-center ">
              {triggeredFromFeatureName ? (
                <div className="mb-4">
                  <div
                    className={classNames(
                      `inline-flex items-center text-xs rounded-md space-x-1`,
                      'bg-brand-yellow-25 text-brand-yellow border-brand-yellow border',
                      'pr-3 pl-2 pt-1.5 pb-2 mb-2',
                    )}
                  >
                    <LockClosedIcon className="w-3" />
                    <span>{`Upgrade Required`}</span>
                  </div>
                </div>
              ) : (
                <div className="mt-2 mb-4">
                  <span className="text-transparent bg-gradient-to-r from-brand-pink to-brand-yellow bg-clip-text">{`Plans & Pricing`}</span>
                </div>
              )}
            </div>
            <div className="mb-3 text-3xl font-semibold">
              <span className="">{`Hours Saved for a Few `}</span>
              {BoordsConfig.Currency === 'gbp' ? (
                <>{`Pounds`}</>
              ) : BoordsConfig.Currency === 'eur' ? (
                <>{`Bucks`}</>
              ) : (
                <>{`Bucks`}</>
              )}
            </div>
            <div className="flex justify-center">
              <div className="max-w-lg text-type-subdued">{subtitle}</div>
            </div>
          </div>
        </div>

        {/* Interval toggle */}
        <div
          className={classNames(
            'flex justify-center mt-8 text-sm space-x-3 text-type-subdued',
          )}
        >
          <div>{`Annual Discount`}</div>
          <div className="mt-[0.02rem]">
            <Toggle
              size="large"
              highlightColor="pink"
              value={billingInterval === 'year'}
              onChange={() =>
                setBillingInterval(
                  billingInterval === 'year' ? 'month' : 'year',
                )
              }
            />
          </div>
        </div>

        {/* Plan grid */}
        <div
          className={classNames(
            'relative pb-2 mt-10 grid grid-cols-1 sm:grid-cols-3 gap-6',
            isPuffinPrices ? '' : 'md:grid-cols-4',
          )}
        >
          {plans.map((plan) => {
            const price: Price | undefined = plan.prices.find(
              (price) =>
                price.currency === BoordsConfig.Currency &&
                price.interval === billingInterval,
            );

            return !price ? null : isPuffinPrices ? (
              <PricingTablePuffinPlan
                key={plan.slug}
                plan={plan}
                price={price}
              />
            ) : (
              <PricingTablePlan key={plan.slug} plan={plan} price={price} />
            );
          })}
        </div>

        <div className="mt-24" />
        <div className="absolute bottom-0 -left-10 -right-10">
          <>
            {/* Spacer to counteract absolute positioning */}
            <div className="mt-14" />
            <div className="absolute bottom-0 left-0 right-0">
              <div className="flex justify-center pt-5 pb-6 bg-surface-light">
                <div className="flex items-center">
                  <LockClosedIcon className="w-5 text-type-disabled" />
                  <div className="ml-2 text-sm text-type-subdued">{`Secure & encrypted payment, cancel anytime.`}</div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </Dialog>
  );
};
