/** @prettier */
import { type TourBlueprint } from './tourData';

const tourSteps = ['wizardInviteInput'] as const;

export type wizardInviteTourAvailableSteps = typeof tourSteps[number];

export const wizardInviteTour: TourBlueprint<wizardInviteTourAvailableSteps> = {
  name: 'wizardInvite',
  allowRestart: true,
  steps: [
    {
      name: 'wizardInviteInput',
      header: `Collaborate with your team`,
      body: `Invite coworkers to edit and comment on storyboards with you.`,
      tokens: {
        name: 'Invite a team member',
        count: 10,
      },
    },
  ],
};
