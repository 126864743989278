/** @prettier */
import { IconButton } from 'blackbird/components/common/IconButton';
import classNames from 'classnames';
import * as React from 'react';

export interface IconTabItem {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  name: string;
  disabled?: boolean;
  iconClassName?: string;
  icon: React.ReactElement;
  active?: boolean;
}

interface ImageSearchIconTabProps {
  icons: IconTabItem[];
  width?: 'auto' | string;
}

const widthForItemLength = (count: number) => {
  if (count < 6) {
    return 'w-10';
  } else if (count < 8) {
    return 'w-8';
  }

  return 'w-6';
};

export const IconTabBar: React.FC<ImageSearchIconTabProps> = (
  props,
): React.ReactElement => {
  const icons = props.icons;
  const width = props.width ?? 'auto';
  const widthClass =
    width !== 'auto' ? props.width : widthForItemLength(icons.length);

  // my god, the basis should have been on the parent, jeez
  return (
    <div
      className={classNames(
        'border border-border rounded-md flex overflow-hidden',
        icons.length <= 10 ? 'flex-nowrap' : 'flex-wrap',
      )}
    >
      {icons.map((tab, idx) => (
        <IconButton
          key={`tab-${tab.title}-${idx}`}
          className={classNames(
            'group border-border flex border-r justify-center px-1',
            idx === icons.length - 1 ? 'border-none' : 'border-r',
            widthClass,
            tab.active
              ? 'bg-surface-light hover:bg-surface'
              : 'hover:bg-surface-light',
            tab.disabled && 'cursor-not-allowed',
          )}
          iconClassName={classNames(
            'w-4 mb-2 mt-2',
            !tab.disabled &&
              'group-hover:text-brand-pink group-hover:fill-brand-pink ',
            !tab.active && 'opacity-40 group-hover:opacity-100 ',
            tab.iconClassName,
          )}
          icon={tab.icon}
          disabled={tab.disabled}
          hasTooltip={!tab.disabled && tab.title.length > 0}
          aria-label={tab.title}
          name={tab.name}
          onClick={tab.onClick}
        />
      ))}
    </div>
  );
};
