/** @prettier */

import * as React from 'react';
import Button from 'blackbird/components/button/Button';
import Banner from 'blackbird/components/feedback/banner/Banner';
import classNames from 'classnames';
import { CalendarIcon } from 'lucide-react';
import { differenceInDays, endOfDay } from 'date-fns';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';

declare const BoordsConfig: {
  TrialEndsAt?: number;
};

export const BannerPaidTrial: React.FC = () => {
  const getDaysRemaining = () => {
    if (!BoordsConfig.TrialEndsAt) {
      return null;
    }
    const now = new Date();
    const trialEndDate = new Date(BoordsConfig.TrialEndsAt * 1000);
    const daysRemaining = differenceInDays(endOfDay(trialEndDate), now);
    return daysRemaining <= 0 ? null : daysRemaining;
  };

  const daysRemaining = getDaysRemaining();

  if (daysRemaining === null) {
    return null;
  }

  return (
    <Banner
      kind={'alert'}
      hideIcon
      isLuxe
      className="no-padding-y h-banner bg-premium-100"
      customWidth
      message={
        <div className="relative flex items-center justify-start flex-auto w-full">
          <Tooltip
            placement="bottom-start"
            title={`View trial status, upgrade, or cancel`}
          >
            <a
              className="flex items-center pl-2 pr-2.5 pt-0 pb-0.5 leading-tight rounded-lg bg-white/50 border-white border text-black/70"
              href="/billing"
            >
              <CalendarIcon className="w-3.5 mt-[0.1rem]" />
              <span className="ml-1.5 text-sm font-semibold">{`${daysRemaining} Days Remaining`}</span>
            </a>
          </Tooltip>
          <div className="ml-3 mr-2.5">{`Upgrade now →`}</div>

          <div className={classNames('flex justify-start flex-auto')}>
            <Button
              type="solid"
              className="group"
              size="xs"
              rounded
              onClick={() => {
                Track.event.defer('banner_click', {
                  category: 'Checkout',
                });
                FlyoverActions.open.defer({
                  type: 'inlinePricing',
                });
              }}
            >
              <span className="relative flex items-center px-1 py-0.5">
                <span>{`Compare Plans`}</span>
              </span>
            </Button>
          </div>
        </div>
      }
    />
  );
};
