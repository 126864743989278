import { FakeAltStoreClass } from './AltStore';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import '../actions/testimonials';
import { ajax } from 'javascripts/helpers/ajax';

const errorHandler = RequestErrorHandler('testimonial');

export interface ContentfulImageFile {
  url: string;
  contentType: string
  fileName: string
  details: {
    image: {
      width: number,
      height: number
    },
    size: number
  }
}
interface ContentfulFlattenedImage {
  title?: string
  file: ContentfulImageFile
}

export interface SoundBite {
  quote: string,
  name: string
  role: string,
  avatar: ContentfulFlattenedImage
  company: {
    companyName: string,
    logo: ContentfulFlattenedImage
  }
  backlink: string
}

interface Category {
  identifier: string,
  soundbites: SoundBite[]
}

export class TestimonialStore extends FakeAltStoreClass<TestimonialStore> {
  categories: { [identifier: string]: Category } = {};

  constructor() {
    super();
    this.bindListeners({
      handleFetch: TestimonialActions.FETCH
    })
  }

  handleFetch(identifier: string)  {
    if (this.categories[identifier]) return
    ajax('/_testimonials/' + encodeURI(identifier)).then((response:Category) => {
        this.categories = {
        ...this.categories, [response.identifier]: response
      }
      this.emitChange()
    }).catch(errorHandler({ message: null }))
  }
}

(window as any).TestimonialStore = alt.createStore(TestimonialStore, 'TestimonialStore');
