/** @prettier */
/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';
import Logotype from '../../../images/logo/logotype.svg';
import CloseIcon from '../../../images/icons/close.svg';
import { IconButton } from '../../common/IconButton';
import type { IShareableStoryboard } from '../../../../javascripts/types/storyboard';
import { createPermissionFlyoverOpener } from 'javascripts/helpers/has-permission';

type MadeWithBoordsProps = {
  storyboardHasWatermark: boolean;
  /** Does the current user have the ability to disable the "made with Boords text?" */
  canDisable: boolean;
  onDisableClick: () => void;
  storyboard: IShareableStoryboard;
};
export const PresentationMadeWithBoords: React.FC<MadeWithBoordsProps> = (
  props,
) => {
  const [show, setShow] = React.useState(props.storyboardHasWatermark);
  const { onDisableClick, storyboard } = props;

  const handleDisableClick = () => {
    const canRemoveWatermark =
      storyboard.has_premium_features && !storyboard.is_trial;
    if (canRemoveWatermark) {
      setShow(false);
      onDisableClick();
    } else {
      createPermissionFlyoverOpener(
        storyboard.project.owner.user_id,
        'branding',
      )();
    }
  };

  return show ? (
    <div className="relative inline-flex flex-shrink-0 group whitespace-nowrap">
      <a
        href="https://boords.com"
        target="_blank"
        className="inline-flex items-center text-sm text-type-subdued"
      >
        {`Made with`}
        <Logotype className="h-3 ml-1 mt-[2px]" />
      </a>

      {props.canDisable && (
        <IconButton
          className="absolute w-5 rounded-full opacity-0 focus:outline-blue -right-4 -top-2 transition-opacity group-hover:opacity-100"
          aria-label="Disable “Made With Boords” label for this storyboard"
          onClick={handleDisableClick}
          icon={<CloseIcon />}
          color="subdued"
          tooltipDistance={10}
        />
      )}
    </div>
  ) : null;
};

type MadeWithBoordsContainerProps = {
  onDisableClick: () => void;
  storyboard: IShareableStoryboard;
  canManage: boolean;
};

export const PresentViewMadeWithBoordsContainer: React.FC<
  MadeWithBoordsContainerProps
> = (props) => {
  // This might also be dependent on the user's plan
  const canDisable = props.canManage;
  return (
    <PresentationMadeWithBoords
      storyboardHasWatermark={
        props.storyboard.has_watermark || BoordsConfig.IsProfessionalFree
      }
      canDisable={canDisable}
      onDisableClick={props.onDisableClick}
      storyboard={props.storyboard}
    />
  );
};
