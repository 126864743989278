/** @prettier */
import React, { useContext } from 'react';
import { ErrorBoundary } from 'javascripts/components/shared/ErrorBoundary';
import Onboarding, { OnboardingType } from './Onboarding';
import { useOnMount } from 'javascripts/helpers/useOnMount';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import { BoordsAiProvider } from 'blackbird/helpers/contexts/BoordsAiContext';
import {
  OnboardingProvider,
  OnboardingContext,
} from 'blackbird/helpers/contexts/OnboardingContext';
import { posthogClient } from 'javascripts/helpers/posthog';
import { PostHogProvider } from 'posthog-js/react';
import { QuizContainer } from 'blackbird/components/quiz/QuizContainer';
import { QuizProvider } from 'blackbird/components/quiz/QuizContext';
import { ComponentComposer } from 'javascripts/components/shared/ComponentComposer';

export interface Props {
  projectId: number;
  username: string;
  redirectTo?: string;
}

const noop = () => {};

const redirectToFirstStoryboard = () => {
  window.location.href = '/storyboards/first';
};

export const WelcomeLayout: React.VFC<Props> = (props) => {
  const [step, setStep] = React.useState<OnboardingType>(
    OnboardingType.WELCOME,
  );

  const { accountType, startWith } = useContext(OnboardingContext);

  React.useEffect(() => {
    if (accountType && startWith) {
      Track.event.defer(`ob_start_with_${startWith}`);

      const starterId =
        startWith === 'see_an_example'
          ? parseInt(BoordsConfig.defaultStarterId, 10)
          : undefined;

      const documentName =
        startWith === 'see_an_example'
          ? 'Example Storyboard'
          : 'Untitled Storyboard';

      StoryboardActions.create.defer({
        documentName: documentName,
        projectId: props.projectId,
        blank_frame_count: 6,
        track_first_storyboard: true,
        starter_storyboard_id: starterId,
        generateThumbnails: false,
        callbackOnly: true,
        callback: (success) => {
          if (!success) {
            setStep(OnboardingType.NAME_TEAM);
          } else {
            if (accountType === 'student' || BoordsConfig.IsEdu) {
              redirectToFirstStoryboard();
            } else {
              setStep(OnboardingType.NAME_TEAM);
            }
          }
        },
      });
    }
  }, [startWith, accountType, props.projectId]);

  const handleSaveNameTeam = React.useCallback(() => {
    Track.event.defer('ob_add_account_name', {
      category: 'Onboarding',
    });
    redirectToFirstStoryboard();
  }, []);

  const handleNameSkip = React.useCallback(() => {
    Track.event.defer('ob_skip_account_name', {
      category: 'Onboarding',
    });
    redirectToFirstStoryboard();
  }, []);

  return (
    <Onboarding
      onChangePassword={noop}
      {...props}
      username={props.username}
      type={step}
      onSaveTeamName={handleSaveNameTeam}
      onNameSkip={handleNameSkip}
    />
  );
};

const WelcomeContainer: React.VFC<Props> = (props) => {
  useOnMount(() => {
    Track.once.defer('CompleteRegistration', {
      category: 'Account',
      posthogCapture: true,
    });

    if (typeof $FPROM !== 'undefined') {
      $FPROM.trackSignup({
        email: BoordsConfig.Email,
      });
    }
  });

  const providers = React.useMemo<React.PropsWithChildren<any>[]>(
    () => [
      React.StrictMode,
      ErrorBoundary,
      [PostHogProvider, { client: posthogClient() }],
      OnboardingProvider,
      BoordsAiProvider,
      DefaultStoreContextProvider,
      [QuizProvider, { projectId: props.projectId }],
    ],
    [props.projectId],
  );

  return (
    <ComponentComposer items={providers}>
      {!BoordsConfig.IsConfirmed ? (
        <FlyoverEmailConfirmation />
      ) : (
        <QuizContainer />
      )}
    </ComponentComposer>
  );
};

(window as any).WelcomeContainer = WelcomeContainer;
