import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'underscore';

export const useWidth = (
  refElement?: HTMLElement | null
) => {
  const [width, setWidth] = useState(0);

  const updateWidth = useCallback(debounce(() => {
    if(refElement) {
      const rect = refElement.getBoundingClientRect();
      setWidth(rect.width);
    }
  }, 20), [refElement]);

  useEffect(() => {
    updateWidth();
  }, [refElement]);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
  })

  return width;
}
