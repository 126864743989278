/** @prettier */
import React from 'react';
import { OfferAddonDialog } from 'blackbird/components/offer/OfferAddonDialog';

export const FlyoverUpgrade: React.FC = () => {
  return (
    <OfferAddonDialog
      fancy
      role={BoordsConfig.AccountType}
      isAi={BoordsConfig.QuizIsAi}
      isTeam={BoordsConfig.QuizIsTeam}
      onDecline={() => {
        FlyoverActions.close.defer();
      }}
    />
  );
};

(window as any).FlyoverUpgrade = FlyoverUpgrade;
