/** @prettier */
export const presets = {
  green: {
    backgroundColors: 'bg-black text-white',
    disabledStyle: 'bg-action-primary-disabled'
  },
  purple: {
    backgroundColors: 'bg-black !text-white ',
    disabledStyle: 'bg-action-primary-disabled'
  },
  yellow: {
    backgroundColors: 'bg-yellow hover-bg-dark-yellow',
  },
  whiteWithShadow: {
    backgroundColors: 'bg-white text-black hover-bg-half-moon-gray',
  },
  white: {
    backgroundColors: 'bg-white text-black border-2 border-black',
  },
  whiteTransparent: {
    backgroundColors:
      'white bg-white-translucent hover-bg-white hover-dark-purple br1',
  },
  whiteTransparentHover: {
    backgroundColors: 'bg-white-transparent-hover',
  },
  headerButton: {
    backgroundColors: 'bg-lighter-grey hover-bg-light-grey',
  },
  darkGreyTab: {
    backgroundColors: 'white bg-white-transparent-hover br-pill',
    disabledStyle: 'br-pill bg-near-black-bang hover-bg-near-black white', // Tab is active, so it lights up
  },
  darkGrey: {
    backgroundColors: 'bg-mid-gray text-white hover-bg-gray',
  },
  dark: {
    backgroundColors: 'bg-darker-grey text-white hover-bg-hygge-grey',
  },
  hyggeGrey: {
    backgroundColors: 'bg-hygge-grey white',
  },
  grey: {
    backgroundColors: 'bg-light-grey mid-grey hover-dark-grey hover-bg-grey',
  },
  darkerGrey: {
    backgroundColors: 'bg-grey hover-bg-moon-gray',
  },
  lightishGrey: {
    backgroundColors: 'bg-lightish-grey hover-bg-moon-gray',
  },
  barelyGrey: {
    backgroundColors: 'bg-barely-grey hover-bg-light-grey',
  },
  blue: {
    backgroundColors: 'bg-blue white',
  },
  dangerZone: {
    backgroundColors: 'bg-dark-red white',
    disabledStyle: 'o-60 ',
  },
  purpleBorder: {
    backgroundColors:
      'bg-white purple b--purple bw1 ba hover-bg-dark-purple hover-white',
  },
  transparent: {
    backgroundColors: 't-o',
    disabledStyle: 'o-30',
  },
  transparentWithHover: {
    backgroundColors: 'o-50 hover-o-70 t-o',
    disabledStyle: 'o-30',
  },
};
