/** @format */
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import * as React from 'react';

/**
 * A basic component which shows the Boords pencil while it's child components
 * are loading.
 */
export const SuspenseLoader: React.FC<
  React.ComponentPropsWithoutRef<typeof LoadingIndicator>
> = ({ children, ...props }) => {
  return (
    <React.Suspense fallback={<LoadingIndicator fill {...props} />}>
      {children}
    </React.Suspense>
  );
};
