/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import { UserActions } from '../actions/user';
import { UserResponse } from '../../types/user';
import {
  RequestErrorHandler,
  isErrorXHR,
} from '../../helpers/request-error-handler';
import '../actions/trial';
import '../actions/new_project';
import '../actions/archive';
import { rollbar } from '../../helpers/rollbar';
import { RequestActions } from '../actions/request';
import { ajax } from 'javascripts/helpers/ajax';
import { ArchiveActions } from '../actions/archive';

const errorHandler = RequestErrorHandler('user');

export class UserStore extends FakeAltStoreClass<UserStore> {
  /** This can be unpopulated (e.g. on Shareable) */
  user?: UserResponse;

  constructor() {
    super();

    this.bindListeners({
      handleFetch: [TrialActions.STARTED, UserActions.FETCH],
      handleProjectCreated: [
        NewProjectActions.PROJECT_CREATED,
        ArchiveActions.RESTORE_PROJECT,
      ],
      handleReceive: UserActions.RECEIVE,
      handleUpdateFields: UserActions.UPDATE_FIELDS,
      handleUpdatePassword: UserActions.UPDATE_PASSWORD,
      handleDestroy: UserActions.DESTROY,
    });
  }

  handleReceive(user) {
    this.user = user;
    this.emitChange();
  }

  handleFetch() {
    ajax({
      method: 'get',
      url: '/account/me.json',
      success: function (response) {
        UserActions.receive(response);
      }.bind(this),
      error: errorHandler({ method: 'get' }, (error) => {
        if (isErrorXHR(error) && error.status === 401) {
          rollbar.info('Redirecting unauthorised user to login page');
          setTimeout(() => (window.location.href = '/login'), 2000);
        }
      }),
    });
  }

  handleProjectCreated(response) {
    if (!this.user) throw new Error('User is missing');
    this.user.active_project_count += 1;
    this.emitChange();
  }

  handleDestroy(data) {
    ajax({
      method: 'delete',
      url: '/users/' + data.user_id,
      success: function (response) {
        RequestActions.success({ key: 'accounts.deleted' });
        window.location.href = '/';
      }.bind(this),
      error: errorHandler({ method: 'delete' }),
    });
  }

  handleUpdateFields(data: Partial<UserResponse>) {
    if (!this.user) throw new Error('User is missing');
    this.user = Object.assign(this.user, data);
    this.emitChange();
    ajax({
      method: 'patch',
      url: '/account/settings',
      data: {
        user: data,
      },
      success: function (response) {
        UserActions.receive(response);

        // Don't show when updating account type during onboarding
        if (
          !data.account_type &&
          !data.quiz_items &&
          !data.is_marketing_opt_in
        ) {
          RequestActions.success({ key: 'accounts.genericSuccess' });
        }
      }.bind(this),
      error: errorHandler({ method: 'patch' }),
    });
  }

  handleUpdatePassword(data) {
    ajax({
      method: 'patch',
      url: '/account/password',
      data: {
        user: {
          password: data.password,
          password_confirmation: data.passwordConfirm,
        },
      },
      success: function (response) {
        RequestActions.success({ key: 'accounts.changePassword.success' });
      }.bind(this),
      error: errorHandler({ method: 'patch' }),
    });
  }
}

(window as any).UserStore = alt.createStore(UserStore, 'UserStore');
