/**@prettier */
import AddCircle from 'blackbird/images/icons/add-circle.svg';
import Icon from 'blackbird/components/icon/Icon';
import DashboardStoryboardItem from './DashboardStoryboardItem';
import { map, flatten, find } from 'underscore';
import './styles.css';
import { CirclePlus } from 'lucide-react';
const createReactClass = require('create-react-class');
var SortableMixin = require('../../helpers/react-sortable-mixin');

window.DashboardStoryboards = createReactClass({
  getInitialState: function () {
    return {
      projectId: this.props.projectId,
      storyboards: this.props.storyboards,
    };
  },

  componentDidMount: function () {
    DashboardStore.listen(this._onChange);
    window.addEventListener('touchstart', this.disableSortableForTouch);
  },

  disableSortableForTouch() {
    // For this view, disable drag & drop when it's a touch device
    // reason: The d&d library blocks touch events, so you can't navigate
    // to the storyboards.
    const isTouchDevice =
      document.querySelector('html') &&
      document.querySelector('html').className.indexOf('is-touch-device') >= 0;

    // I guess we could change this in favour of an edit "mode" that switches
    // enabled and disabled sorting
    if (this._sortableInstance) {
      this._sortableInstance.option('disabled', isTouchDevice);
    }
  },

  componentWillUnmount: function () {
    DashboardStore.unlisten(this._onChange);
    window.removeEventListener('touchstart', this.disableSortableForTouch);
  },

  mixins: [SortableMixin],

  sortableOptions: {
    ref: 'items',
    filter: '.storyboard--new',
    handle: '.js-storyboard-drag-handle',
    model: 'storyboards',
  },

  handleSort: function () {
    DashboardActions.updateBoardsOrder(this.state);
  },

  _onChange: function (state) {
    const projects = map(state.dashboard, (val) => val.projects || []);
    const changedProject = find(
      flatten(projects),
      (item) => item.id === this.state.projectId,
    );
    changedProject &&
      this.setState({ storyboards: changedProject.storyboards });
  },

  _handleNewStoryboardClick: function (e) {
    e.preventDefault();

    FlyoverActions.open({
      type: 'newStoryboard',
      projectId: this.props.projectId,
      props: {
        actions: this.props.actions,
      },
    });
  },

  render: function () {
    const { actions } = this.props;
    var storyboards = this.state.storyboards;

    return storyboards ? (
      <ul
        className="pb-8 container__section__list grid grid-storyboard gap-6"
        ref="items"
      >
        {storyboards.map((storyboard, i) => {
          return (
            storyboard && (
              <li
                className={
                  'storyboard--card group relative br3 js-storyboard-drag-handle'
                }
                key={
                  'project-' +
                  this.props.projectId +
                  '-storyboard-' +
                  storyboard.id
                }
              >
                <DashboardStoryboardItem
                  actions={actions}
                  storyboard={storyboard}
                  projectId={this.props.projectId}
                />
              </li>
            )
          );
        })}

        {actions.includes('storyboards.create') && (
          <li
            onClick={this._handleNewStoryboardClick}
            className="cursor-pointer bg-surface-frame rounded-lg min-h-[244px] flex items-center justify-center border border-border-mid hover:bg-surface-add_frame text-center hover:text-brand-pink text-type-disabled"
          >
            <div>
              <div className="flex justify-center ">
                <CirclePlus size={48} strokeWidth={1} />
              </div>
              <div className="mt-4 text-lg !text-type-primary">{`New Storyboard`}</div>
            </div>
          </li>
        )}
      </ul>
    ) : null;
  },
});
