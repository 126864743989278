/** @prettier */
import classNames from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import type { IFrameOverlay } from './FrameOverlays';
import { ToursActions } from 'javascripts/flux/actions/tours';
import { type availableTourNames } from 'javascripts/flux/stores/tours';
import { WizardContext } from 'blackbird/components/wizard/WizardContext';
import { GeneratorContext } from 'blackbird/components/generator/GeneratorContext';

interface IProps {
  overlay: IFrameOverlay;
  className?: string;
  onInsertOverlay: (overlay: IFrameOverlay) => void;
}

export const FrameOverlayBrowserItem: React.VFC<IProps> = (props) => {
  const overlay = props.overlay;

  const { handleComplete } = React.useContext(WizardContext);
  const { GetTokenCount } = React.useContext(GeneratorContext);

  const tourIsActive = (tourName: availableTourNames) =>
    ToursStore.state.currentTours.length > 0 &&
    ToursStore.state.currentTours[0].name === tourName;

  const handleClick = () => {
    props.onInsertOverlay(props.overlay);
    if (tourIsActive('wizardCameraMoves')) {
      ToursActions.advanceTour('wizardCameraMoves');
    }
    handleComplete('camera-moves', GetTokenCount);
  };

  const { t } = useTranslation(undefined, {
    keyPrefix: 'frameEditor.overlays.' + overlay.key,
  });

  return (
    <button
      type="button"
      key={overlay.key}
      className={classNames(
        'hover:bg-surface-light rounded-sm flex gap-3 text-left text-sm group',
        props.className,
      )}
      onClick={handleClick}
    >
      <img
        src={overlay.preview}
        className="w-16 h-16 rounded-sm shadow-xs shrink-0"
      />

      <div className="flex-auto">
        <div className="-mt-0.5 font-semibold group-hover:text-brand-pink">
          {t('title')}
        </div>
        <div className="text-sm text-type-disabled">{t('description')}</div>
      </div>
    </button>
  );
};
