/** @format */

import {
  type IconButtonprops,
  IconButton,
} from 'blackbird/components/common/IconButton';
import classNames from 'classnames';
import * as React from 'react';

export const FormattingToolbarButton: React.FC<
  IconButtonprops & {
    name: string;
    'aria-label': string;
  }
> = (props) => (
  <IconButton
    {...props}
    className={classNames(
      'h-7 w-7 hover:bg-surface-frame border-surface-frame border rounded-md flex justify-center items-center',
      props.className,
    )}
    iconClassName={classNames(
      'flex justify-center items-center text-center leading-none h-7 w-7',
      props.iconClassName,
    )}
    // Wrap the icon in a span, so the iconClassName will be applied and we have
    // a consistent width
    icon={<span aria-hidden>{props.icon}</span>}
    tooltipDistance={10}
    labelPlacement="top"
    fill={false}
  />
);
