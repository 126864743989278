import { FakeAltStoreClass } from './AltStore';
import '../actions/project';
import { RequestActions } from '../actions/request';
import { ajax } from 'javascripts/helpers/ajax';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import { TeamManagementActions } from '../actions/team_management';

const errorHandler = RequestErrorHandler('project')

export class ProjectStore extends FakeAltStoreClass<ProjectStore> {
  user: any;
  projectIdBeingRenamed: any;
  siblingStoryboards: any;
  siblingStoryboardsProject: any;
  constructor() {
    super();
    this.user = null;
    this.projectIdBeingRenamed = null;
    this.siblingStoryboards = null;
    this.siblingStoryboardsProject = null

    this.bindListeners({
      handleTransferOwnership: ProjectActions.TRANSFER_OWNERSHIP,
      handleEditName: ProjectActions.EDIT_NAME,
      handleFetchSiblingStoryboards: ProjectActions.FETCH_SIBLING_STORYBOARDS
    });
  }

  handleTransferOwnership(data) {

    var dataToSend = { user_id: data.userId };

    if (data.teamId !== 'personal') {
      (dataToSend as any).recipient_team_id = data.teamId
    } else {
      (dataToSend as any).recipient_user_id = data.userId
    }

    ajax({
      method: "post",
      url: '/projects/' + data.projectSlug + '/transfer.json',
      data: dataToSend,
      success: function (response) {
        DashboardActions.fetch();

        if (response.owner.type === 'user') {
          TeamManagementActions.clearMembers();
        } else {
          TeamManagementActions.fetch(response.owner.id);
        }

        RequestActions.success("Project ownership transferred");
      }.bind(this),
      error: errorHandler()
    });
  }

  handleEditName(id) {
    this.projectIdBeingRenamed = id;
    this.emitChange();
  }

  handleFetchSiblingStoryboards({ projectID, storyboardID }) {

    if (!projectID) throw new Error('Needs project ID')
    if (
      this.siblingStoryboardsProject === projectID &&
      this.siblingStoryboards &&
      this.siblingStoryboards.error === false
    ) return;

    if (this.siblingStoryboardsProject === projectID) return

    this.siblingStoryboardsProject = projectID;
    this.siblingStoryboards = {
      error: false,
      isLoading: true,
      storyboards: null
    }

    ajax(`/projects/${projectID}/storyboards`).then(
      response => {
        this.siblingStoryboards = {
          storyboards: response.filter(s => !storyboardID || s.id !== storyboardID),
          isLoading: false,
          error: false
        };
        this.emitChange()
      },
      errorHandler({ rollbarMessage: 'error fetching project storyboards'}, () => {
        this.siblingStoryboards = { storyboards: null, loading: false, error: true }
        this.emitChange()
      }),
    );
  }

}



(window as any).ProjectStore = alt.createStore(ProjectStore, 'ProjectStore');
