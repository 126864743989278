/** @format */

import { useCallback, useEffect, useState } from 'react';
import type { ViewType, ProjectGroup, Project } from '../ProjectsContext';

interface UseRouteHandlerProps {
  canUpdateUrlAndTitle: boolean;
  projectGroups: {
    team: ProjectGroup;
    memberships: ProjectGroup[];
  } | null;
  onViewTypeChange?: (viewType: ViewType) => void;
  onActiveProjectChange?: (project: Project | null) => void;
  onActiveGroupChange?: (group: ProjectGroup | null) => void;
}

interface RouteState {
  viewType: ViewType;
  templateId: string | null;
}

export const useRouteHandler = ({
  canUpdateUrlAndTitle,
  projectGroups,
  onViewTypeChange,
  onActiveProjectChange,
  onActiveGroupChange,
}: UseRouteHandlerProps) => {
  // Helper functions
  const getCurrentTemplateIdFromUrl = useCallback(() => {
    const path = window.location.pathname;
    const matches = path.match(/\/templates\/([^\/]+)/);
    return matches ? matches[1] : null;
  }, []);

  const determineInitialViewType = useCallback((): ViewType => {
    const path = window.location.pathname;
    if (path.startsWith('/templates')) {
      return 'templates';
    } else if (path.startsWith('/archive')) {
      return 'archive';
    }
    return 'projects';
  }, []);

  const [routeState, setRouteState] = useState<RouteState>(() => ({
    viewType: determineInitialViewType(),
    templateId: getCurrentTemplateIdFromUrl(),
  }));

  const findProjectAndGroup = useCallback(
    (
      searchFn: (project: Project) => boolean,
    ): [Project | null, ProjectGroup | null] => {
      if (!projectGroups) return [null, null];

      const allGroups = [projectGroups.team, ...projectGroups.memberships];

      for (const group of allGroups) {
        const project = group.projects.find(searchFn);
        if (project) {
          return [project, group];
        }
      }

      return [null, null];
    },
    [projectGroups],
  );

  // Handle browser back/forward
  useEffect(() => {
    const handlePopState = () => {
      const newViewType = determineInitialViewType();
      const newTemplateId = getCurrentTemplateIdFromUrl();

      setRouteState({ viewType: newViewType, templateId: newTemplateId });
      onViewTypeChange?.(newViewType);

      if (newViewType === 'templates') {
        if (newTemplateId && projectGroups) {
          const [project, group] = findProjectAndGroup(
            (p) =>
              p.template_id === newTemplateId || p.short_slug === newTemplateId,
          );
          onActiveProjectChange?.(project);
          onActiveGroupChange?.(group);
        } else {
          onActiveProjectChange?.(null);
          onActiveGroupChange?.(projectGroups?.team ?? null);
        }
      } else if (newViewType === 'archive') {
        onActiveProjectChange?.(null);
      } else {
        // Handle projects view
        const slug = window.location.pathname.split('/').pop();
        if (slug) {
          const [project, group] = findProjectAndGroup((p) => p.slug === slug);
          onActiveProjectChange?.(project);
          onActiveGroupChange?.(group);
        }
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [
    projectGroups,
    onViewTypeChange,
    onActiveProjectChange,
    onActiveGroupChange,
  ]);

  // Handle URL and title updates
  useEffect(() => {
    if (!canUpdateUrlAndTitle) return;

    const { viewType, templateId } = routeState;

    switch (viewType) {
      case 'templates':
        if (templateId) {
          const [project] = findProjectAndGroup(
            (p) => p.template_id === templateId || p.short_slug === templateId,
          );
          window.history.pushState(
            { viewType, templateId },
            '',
            `/templates/${templateId}`,
          );
          document.title = project
            ? `${project.name} Template • Boords`
            : 'Templates • Boords';
        } else {
          window.history.pushState({ viewType }, '', '/templates');
          document.title = 'Templates • Boords';
        }
        break;
      case 'archive':
        window.history.pushState({ viewType }, '', '/archive');
        document.title = 'Archive • Boords';
        break;
      case 'projects':
        // URL updates handled by ProjectsContext
        break;
    }
  }, [routeState, canUpdateUrlAndTitle, projectGroups]);

  const setViewType = useCallback(
    (newViewType: ViewType) => {
      setRouteState((prev) => ({
        ...prev,
        viewType: newViewType,
        templateId:
          newViewType === 'templates' ? getCurrentTemplateIdFromUrl() : null,
      }));
      onViewTypeChange?.(newViewType);
    },
    [getCurrentTemplateIdFromUrl, onViewTypeChange],
  );

  return {
    viewType: routeState.viewType,
    templateId: routeState.templateId,
    setViewType,
    getCurrentTemplateIdFromUrl,
  };
};
