window.XhrErrorActions = alt.createActions(function () {

  this.show = function (args) {
    this.dispatch(args);
  };

  this.hide = function (args) {
    this.dispatch(args);
  };


});

