/** @prettier */

import React, { type FC, useEffect, useState } from 'react';
import { type Option } from '../types';
import classNames from 'classnames';

export type ListItemSelectHandler = (option: Option | string) => void;
interface ListItemProps {
  option: Option | string;
  highlight?: boolean;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  selectedRightComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  scrollInView?: boolean;
  onClick: ListItemSelectHandler;
  [x: string]: any;
}

const ListItem: FC<ListItemProps> = (props) => {
  const {
    option,
    onClick,
    highlight,
    className,
    selected,
    disabled,
    selectedRightComponent,
    rightComponent,
    scrollInView = false,
    ...restProps
  } = props;
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref && highlight && scrollInView) {
      ref.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, [ref, highlight, scrollInView]);

  const optionContent = () => {
    if (typeof option === 'object') {
      const { label, subLabel } = option as Option;

      return (
        <span className={classNames('inline-flex items-center', className)}>
          <span className="flex-auto">
            {label}
            {Boolean(subLabel) && (
              <div className="text-xs text-type-subdued">{subLabel}</div>
            )}
          </span>
          {Boolean(rightComponent) && (
            <div className="flex-shrink-0">{rightComponent}</div>
          )}
          {Boolean(selected) && Boolean(selectedRightComponent) && (
            <div className="flex-shrink-0">{selectedRightComponent}</div>
          )}
        </span>
      );
    }
    return null;
  };

  const stringContent = () => {
    if (typeof option === 'string') {
      return option as string;
    }

    return null;
  };
  const handleClick = (e) => {
    e.stopPropagation();
    onClick?.(option);
  };
  //Has to stop propagation in this handler instead of onClick
  // This is the caveat that comes with using Headless UI's Popover
  const handleMouseDown = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <div
      ref={setRef}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      className={classNames(
        'flex w-full py-3 px-4 flex-nowrap flex-col rounded',
        { 'hover:bg-surface-light': highlight === undefined && !disabled },
        { 'bg-surface-light': (highlight || selected) && !disabled },
        disabled ? 'cursor-not-allowed opacity-40' : 'cursor-pointer',
      )}
      {...restProps}
    >
      {optionContent()}

      {stringContent()}
    </div>
  );
};

export default ListItem;
