/** @format */
import { EditableTitle } from 'blackbird/components/common/EditableTitle';
import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Img from '../shared/Img';
import type {
  DashboardType,
  Storyboard,
} from 'javascripts/flux/stores/dashboard';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';
import { getFrameImage } from 'javascripts/helpers/getFrameImage';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { rollbar } from 'javascripts/helpers/rollbar';
import { once } from 'underscore';
import { Watermark } from 'blackbird/components/watermark/Watermark';
import { getMissingFrameImageURL } from 'javascripts/helpers/getMissingFrameImageURL';

interface DashboardStoyboardItemProps {
  actions: DashboardType['actions'];
  storyboard: Storyboard;
}

interface EditedDateProps {
  editedAt: string;
}

const logDateError = once((string) => rollbar.error(string));

const EditedDate: React.FC<EditedDateProps> = ({ editedAt }) => {
  try {
    return (
      <div className="text-xs text-type-disabled">
        {`Updated ${format(new Date(editedAt), 'd MMM yy')}`}
      </div>
    );
  } catch (err) {
    logDateError(
      `EditedDate parsing error (${editedAt as string}), returning null`,
    );
    return null;
  }
};

const DashboardStoryboardItem: React.FC<DashboardStoyboardItemProps> = (
  props,
) => {
  const { storyboard, actions } = props;

  const cover = getFrameImage(
    storyboard.cover_image,
    storyboard.frame_aspect_ratio,
  );
  const actionsRef = useRef<HTMLDivElement | null>(null);
  const hasDeletePermission = useMemo(
    () => actions.includes('storyboards.delete'),
    [actions],
  );

  const onRename = (newName) => {
    if (newName) {
      StoryboardActions.updateAndSave({
        slug: storyboard.slug,
        document_name: newName.trim(),
      });

      // There's no other way to do this :/
      window.setTimeout(() => DashboardActions.fetch.defer(), 500);
    }
  };
  const { height, width } = BoordsFrameSizeHelper(
    storyboard.frame_aspect_ratio,
  );

  return (
    <a data-hj-suppress href={'/storyboards/' + storyboard.slug}>
      <Watermark>
        <Img
          className="flex-grow"
          containerClasses="flex"
          src={cover}
          height={height}
          width={width}
          placeholder={getMissingFrameImageURL(storyboard.frame_aspect_ratio)}
          log
        />
      </Watermark>
      {hasDeletePermission && (
        <div
          className="absolute z-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out top-3 right-3"
          ref={(ref) => (actionsRef.current = ref)}
        >
          <DashboardStoryboardPopupMenu storyboard={storyboard} />
        </div>
      )}

      {/* Info */}
      <div className="flex flex-col justify-center p-4 border-border border rounded-br-[4px] rounded-bl-[4px]">
        <EditableTitle
          className="text-type-primary text-[16px]"
          onChange={onRename}
          value={storyboard.document_name}
        />
        <EditedDate editedAt={storyboard.updated_at} />
      </div>
    </a>
  );
};

DashboardStoryboardItem.propTypes = {
  actions: PropTypes.array.isRequired,
};
export default DashboardStoryboardItem;
