/** @format */

import classNames from 'classnames';
import React, { useContext } from 'react';
import { WizardContext } from './WizardContext';

interface WizardProgressProps {
  large?: boolean;
  small?: boolean;
}

export const WizardProgress: React.FC<WizardProgressProps> = ({
  large,
  small,
}) => {
  const { tasksRemaining, percentageComplete } = useContext(WizardContext);

  const progressStyle = {
    transition: 'stroke-dashoffset 0.35s',
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%',
  };

  const radius = 40;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (percentageComplete / 100) * circumference;

  const strokeWidth = large ? 8 : small ? 20 : 16;

  return (
    <div
      className={classNames(
        'relative ',
        large
          ? 'w-[4.5rem] h-[4.5rem]'
          : small
          ? 'w-[1.5rem] h-[1.5rem]'
          : 'w-[2rem] h-[2rem]',
      )}
    >
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <circle
          className={classNames(
            'stroke-current ',
            large ? 'text-surface' : 'text-brand-blue/20',
          )}
          strokeWidth={strokeWidth}
          cx="50"
          cy="50"
          r={radius}
          fill="transparent"
        ></circle>
        <circle
          style={progressStyle}
          className="stroke-current text-brand-blue"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          cx="50"
          cy="50"
          r={radius}
          fill="transparent"
          strokeDashoffset={offset}
        ></circle>

        {!small && (
          <text
            x="50"
            y="52"
            className={classNames('font-body', large ? 'text-lg' : 'text-2xl')}
            textAnchor="middle"
            alignmentBaseline="middle"
          >
            {tasksRemaining}
          </text>
        )}
      </svg>
    </div>
  );
};
