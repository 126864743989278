/** @prettier */
import * as React from 'react';
import { PlayerActions } from '../../flux/actions/player';
import { useStore } from '../../helpers/useStore';
import type {
  PlayerStore,
  CloudinaryAudioInfo,
} from '../../flux/stores/player';
import { TotalDuration } from './TotalDuration';
import { FrameDurationMismatch } from './FrameDurationMismatch';
import { useOnMount } from '../../helpers/useOnMount';
import { notificationIfNoPermission } from '../../helpers/has-permission';
import type { IStoryboard } from '../../types/storyboard';
import { useTranslation } from 'react-i18next';
import { PlayerPopoverTitle } from './PlayerPopoverTitle';
import Toggle from 'blackbird/components/toggle/Toggle';
import Button from 'blackbird/components/button/Button';
import { isEmpty } from 'underscore';
import Select, {
  SelectChangeHandler,
} from 'blackbird/components/form/select/Select';
import { frameFieldId } from 'javascripts/types/frame';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';
import { Option } from 'blackbird/components/common/types';
import { StoryboardStore } from 'javascripts/flux/stores/storyboard';
import { eachFrameFieldMap } from 'javascripts/helpers/eachFrameFieldMap';
import { notNull } from 'javascripts/helpers/notUndefined';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';

interface Props {
  storyboard: Pick<
    IStoryboard,
    'id' | 'project' | 'preferences' | 'frame_fields'
  >;
}

export const PlayerSettings: React.FC<Props> = (props) => {
  const [subtitleChange, setSubtitleChange] = React.useState(false);
  const dialogContext = React.useContext(DialogContext);
  const { t } = useTranslation();

  const onDistributeClick = async () => {
    if (
      await openConfirmDialog(
        t('player.frameDurationMismatch.confirm'),
        dialogContext,
      )
    ) {
      PlayerActions.resetFramesDuration.defer();
    }
  };

  useOnMount(() =>
    CoverpageActions.fetchIfNecessary.defer(props.storyboard.id),
  );

  const handleSubtitleChange = React.useCallback<SelectChangeHandler>(
    (newField: frameFieldId) => {
      StoryboardActions.updatePreference({
        name: 'subtitles_from',
        value: newField,
      });
      setSubtitleChange(true);
    },
    [],
  );

  const handleCoverChange = (
    newValue: boolean,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      notificationIfNoPermission(props.storyboard.project.owner.id, 'shareable')
    )
      return;

    CoverpageActions.updateValue({
      attr: `storyboard.${e.currentTarget.name}`,
      value: newValue,
    });
  };

  const audioData = useStore<CloudinaryAudioInfo | null, PlayerStore>(
    'player',
    (store) => store.audio.data,
  );

  const timelineIsOpen = useStore<boolean, PlayerStore>(
    'player',
    (store) => store.timelineIsOpen,
  );

  const frameDurationMismatch = useStore<boolean, PlayerStore>(
    'player',
    (store) => store.frameDurationMismatch,
  );

  const isPlayerLooping: boolean = useStore<boolean>(
    'coverpage',
    (store) => store.cover?.storyboard?.is_player_looping ?? false,
  );

  const isPlayerShowingSubtitles: boolean = useStore<boolean>(
    'coverpage',
    (store) => {
      const value = store.cover?.storyboard?.is_player_showing_subtitles;
      return value;
    },
  );

  const subtitlesOptions = React.useMemo<Option[]>(() => {
    return eachFrameFieldMap<Option>(
      props.storyboard.frame_fields!,
      (_, id, index, label) => ({
        label: label,
        value: id,
      }),
    ).filter(notNull);
  }, [props.storyboard.frame_fields]);

  const subtitlesFrom = useStore<frameFieldId, StoryboardStore>(
    'storyboard',
    ({ storyboard }) => storyboard.preferences?.subtitles_from ?? 'reference',
  );

  return (
    <>
      <PlayerPopoverTitle>{t('player.settings.title')}</PlayerPopoverTitle>

      <div className="flex flex-col gap-y-6">
        <div className="flex items-center justify-between">
          {t('player.settings.toggleTimeline')}
          <Toggle
            value={timelineIsOpen}
            onChange={PlayerActions.toggleTimeline}
          />
        </div>

        <div className="flex items-center justify-between">
          {t('player.settings.toggleLoop')}
          <Toggle
            name="is_player_looping"
            value={isPlayerLooping}
            onChange={handleCoverChange}
          />
        </div>

        <div className="flex items-center justify-between">
          {t('player.settings.toggleSubtitles')}
          <Toggle
            name="is_player_showing_subtitles"
            value={isPlayerShowingSubtitles}
            onChange={handleCoverChange}
          />
        </div>
      </div>

      <div className="mt-6">
        <div className="font-semibold mb-2">
          {t('player.settings.subtitlesFrom')}
        </div>

        <Select
          value={subtitlesFrom}
          options={subtitlesOptions}
          className="w-full"
          zIndex="z-sidebar"
          onChange={handleSubtitleChange}
          disableInput
        />

        {subtitleChange && (
          <div className="mt-2 text-sm">
            You may need to reload the page for these changes to go into effect
          </div>
        )}
      </div>

      <div className="mt-6">
        <div className="font-semibold mb-2">
          {t('player.settings.duration')}
        </div>
        <TotalDuration isEditable={isEmpty(audioData && audioData.url)} />

        {frameDurationMismatch ? (
          <div className="my-6">
            <FrameDurationMismatch />
          </div>
        ) : null}

        <div className="mt-6 text-center">
          <Button type="destructive" onClick={onDistributeClick} size="sm">
            {t('player.settings.redistributeFrames')}
          </Button>
        </div>
      </div>
    </>
  );
};
