/** @format */
import React from 'react';
import { type ArchivedStoryboard } from '../ProjectsContext';
import { Watermark } from 'blackbird/components/watermark/Watermark';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { getFrameImage } from 'javascripts/helpers/getFrameImage';
import { getMissingFrameImageURL } from 'javascripts/helpers/getMissingFrameImageURL';
import { type frameAspectRatio } from 'javascripts/types/storyboard';
import Img from 'javascripts/components/shared/Img';
import { ArchivedItemActions } from '../menus/ArchivedItemActions';
import { format } from 'date-fns';

interface ArchivedStoryboardCardProps {
  storyboard: ArchivedStoryboard;
}

const EditedDate: React.FC<{ editedAt: string }> = ({ editedAt }) => {
  try {
    return (
      <div className="text-xs text-type-disabled">
        {`Archived ${format(new Date(editedAt), 'd MMM yy')}`}
      </div>
    );
  } catch (err) {
    return null;
  }
};

export const ArchivedStoryboardCard: React.FC<ArchivedStoryboardCardProps> = ({
  storyboard,
}) => {
  const { height, width } = BoordsFrameSizeHelper('16:9' as frameAspectRatio);
  const cover = getFrameImage(null, '16:9' as frameAspectRatio);

  return (
    <div className="flex flex-col group/archivedstoryboard">
      <div className="rounded-lg hover:bg-black/10">
        <div className="m-1.5">
          <div className="relative">
            <div
              className="absolute z-50 opacity-0 top-2 right-2 group-hover/archivedstoryboard:opacity-100 transition-opacity"
              onClick={(e) => e.stopPropagation()}
            >
              <ArchivedItemActions
                itemId={storyboard.slug}
                itemType="storyboard"
              />
            </div>
            <Watermark>
              <Img
                className="flex-grow rounded-t-md"
                containerClasses="flex"
                src={cover}
                height={height}
                width={width}
                placeholder={getMissingFrameImageURL(
                  '16:9' as frameAspectRatio,
                )}
                log
              />
            </Watermark>
          </div>
          <div className="flex flex-col justify-center p-4 bg-white border border-border rounded-b-md">
            <div className="inline-block text-sm leading-snug">
              {storyboard.document_name}
            </div>
            <div className="mt-1">
              <EditedDate editedAt={storyboard.deleted_at} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-auto" />
    </div>
  );
};
