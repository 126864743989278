/** @prettier */
import type { CanvasScrubberRenderer } from './CanvasScrubberRenderer';
import { clampNumber } from '../../../helpers/clampNumber';
import { pixelRound } from '../../../helpers/pixel-round';
import type {
  FabricGroup,
  FabricObject,
} from 'javascripts/components/frame_editor/types';
const width = 28;
const tailWidth = 2;

export type ScrubHandleGroup = FabricGroup & {
  text: FabricObject;
  updateHandleTime: (time: number) => void;
};

export const createScrubHandle = (props: {
  fabric: any;
  renderer: CanvasScrubberRenderer;
  color: string;
  currentFrameNumber: string;
  time: number;
  totalHeight: number;
  totalWidth: number;
  onTimeChange: (time: number) => void;
}): ScrubHandleGroup => {
  const dimensions = props.renderer.dimensions.handle;

  const text = new props.fabric.Text(props.currentFrameNumber, {
    width: width,
    left: width / 2,
    top: dimensions.height / 2,
    textAlign: 'center',
    originX: 'center',
    originY: 'center',
    fontFamily: 'matter',
    fill: 'white',
    fontSize: 14,
  });

  let time = props.time;

  const main = new props.fabric.Group([
    new props.fabric.Rect({
      width: width,
      height: dimensions.height,
      left: 0,
      fill: props.color,
      rx: 4,
      ry: 4,
      strokeWidth: 0,
    }),
    text,
  ]);

  const handleTop = dimensions.height + dimensions.marginBottom;

  const tail = new props.fabric.Path('M12 0H0V6L4 11H8L12 6V0Z');
  const tailTopWidth = 12;
  tail.set({
    left: width / 2 - tailTopWidth / 2,
    top: handleTop,
    fill: props.color,
    strokeWidth: 0,
  });

  const handle = new props.fabric.Group(
    [
      new props.fabric.Rect({
        width: tailWidth,
        top: handleTop,
        height:
          props.totalHeight -
          handleTop -
          props.renderer.dimensions.ruler.textMarginBottom,
        left: width / 2 - tailWidth / 2,
        fill: props.color,
        rx: 3,
        ry: 3,
        strokeWidth: 0,
      }),
      tail,
      main,
    ],
    {
      originX: 'middle',
      originY: 'middle',
      hasControls: false,
      hasBorders: false,
      hoverCursor: 'grab',
      moveCursor: 'grabbing',
      lockMovementY: true,
      left: props.renderer.timeToPosition(time) || 0,
      // Add a reference to the text object
      text,
    },
  );

  const mainLeft = main.left;
  const updateHandleTime = (newTime: number) => {
    const newLeft = props.renderer.timeToPosition(newTime) || 0;
    const clampedLeft = clampNumber(
      newLeft,
      width / 2,
      props.totalWidth - width / 2,
    );
    handle.left = pixelRound(newLeft);
    const diff = clampedLeft - newLeft;
    main.set('left', pixelRound(mainLeft + diff));
  };

  handle.on('moving', ({ transform }) => {
    time = Math.max(
      0,
      Math.min(
        props.renderer.endTime,
        props.renderer.positionToTime(transform.target.left) || 0,
      ),
    );
    updateHandleTime(time);
    props.onTimeChange(time);
  });

  handle.updateHandleTime = (time) => {
    updateHandleTime(time);
    handle.setCoords();
  };

  updateHandleTime(time);

  return handle;
};
