/** @format */

import React from 'react';
import DialogContextProvider from '../dialog/DialogContext';
import { TeamProvider } from './TeamContext';
import { TeamGlobalManage } from './TeamGlobalManage';

interface TeamGlobalContainerProps {
  teamId: string;
}
export const TeamGlobalContainer: React.FC<TeamGlobalContainerProps> = ({
  teamId,
}) => {
  return (
    <div className="max-w-lg">
      <DialogContextProvider>
        <TeamProvider teamId={teamId}>
          <TeamGlobalManage />
        </TeamProvider>
      </DialogContextProvider>
    </div>
  );
};

(window as any).TeamGlobalContainer = TeamGlobalContainer;
