/** @prettier */

import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { FrameStatus, FRAME_COLORS } from './constants';
import { Listbox } from '@headlessui/react';
import useCustomPopper from '../../helpers/hooks/useCustomPopper';
import ReactDOM from 'react-dom';

type ListBoxButtonProps = Parameters<typeof Listbox['Button']>[0];
interface StatusDropdownProps {
  portal?: boolean;
  status: FrameStatus;
  disabled?: boolean;
  onStatusChange?: (value: FrameStatus) => void;
}

const StatusDropdown: React.FC<StatusDropdownProps & ListBoxButtonProps> = (
  props,
) => {
  const { status, children, disabled, portal, onStatusChange, as, ...rest } =
    props;
  const [currentStatus, setStatus] = useState(status);
  const [parentRef, setParentRef] = useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = useCustomPopper(parentRef, popperElement, {
    placement: 'bottom-end',
    distance: 8,
  });
  const allStatuses = useMemo(() => {
    return Object.values(FrameStatus);
  }, []);
  const handleChange = (value) => {
    setStatus(value);
    onStatusChange && onStatusChange(value);
    Track.event.defer('frame_status_change');
  };
  const asProps = as ? { as } : {};
  const Options = (
    <Listbox.Options
      ref={(ref) => setPopperElement(ref)}
      style={styles.popper}
      {...attributes.popper}
      className="z-40 p-2 bg-white rounded-lg shadow-lg w-60 focus-visible:outline-none space-y-2"
    >
      {allStatuses.map((item) => (
        <Listbox.Option className="" value={item} key={item}>
          {({ active }) => (
            <div
              className={classNames(
                'py-3 px-3 rounded-lg flex space-x-6 items-center',
                {
                  'bg-surface-light': active,
                },
              )}
            >
              <div
                className={classNames(
                  'border border-icon-subdued w-2 h-2 rounded-full',
                  FRAME_COLORS[item],
                )}
              ></div>
              <span>{item}</span>
            </div>
          )}
        </Listbox.Option>
      ))}
    </Listbox.Options>
  );
  return (
    <Listbox disabled={disabled} value={currentStatus} onChange={handleChange}>
      <Listbox.Button {...asProps} ref={(ref) => setParentRef(ref)} {...rest}>
        {children}
      </Listbox.Button>
      <div>
        {portal
          ? ReactDOM.createPortal(Options, document.querySelector('body')!)
          : Options}
      </div>
    </Listbox>
  );
};

export default StatusDropdown;
