/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import { PanelbarActions } from '../actions/panelbar';
import * as qs from 'querystringify';
import { StoryboardActions } from '../actions/storyboard';
import type { allowedStoryboardViews } from '../stores/storyboard';
import '../actions/frame_focus';
import { idleTimeout } from '../../helpers/idle-timeout';
import { typedLocalState } from '../../helpers/local-state';
import { contains, isObject } from 'underscore';
import logger from 'javascripts/helpers/logger';
import { eventSource } from 'blackbird/helpers/eventContextHelper';

// prettier-ignore
// Sorted by priority
const allowedPanelTypes = ['settings', 'comments','scriptEditor','activity', 'imageLibrary'] as const
export type panelTypes = typeof allowedPanelTypes[number];
export type panelbarSubPanels = 'comments' | 'storyboard';
export type panelbarEventTypes = 'load' | 'open' | 'close';

// Allowed subpanels per tab, defaults to the first one in the list
const allowedSubPanels: Partial<{ [key in panelTypes]: panelbarSubPanels[] }> =
  {
    settings: ['storyboard', 'comments'],
  };
interface SubPanelState {
  settings?: typeof allowedSubPanels['settings'];
}

const localState = typedLocalState<panelTypes | 'closed'>('panelbarState');
const subPanelState = typedLocalState<SubPanelState>(
  'panelbarSubStateSubPanel',
);

const getStartingPanelType = (): panelTypes | undefined => {
  const { panel } = qs.parse(window.location.search);
  if (panel && allowedPanelTypes.indexOf(panel) >= 0) {
    return panel;
  }

  if (window.location.hash === '#script') {
    // remove the hash from the url
    history.replaceState(undefined, document.title, window.location.pathname);
    return 'scriptEditor';
  }

  const local = localState.getValue();
  if (local === 'closed' || !local) return;

  // remove !local above and uncomment below to
  // open a default panel for users
  // if (!contains(allowedPanelTypes, local)) {
  //   return allowedPanelTypes[0];
  // }
  return local;
};

/** Gets the subpanel from LocalStorage if it's allowed for the current panel type */
const getStartingSubPanel = (
  currentPanel?: panelTypes,
): panelbarSubPanels | undefined => {
  if (!currentPanel) return undefined;
  const local = subPanelState.getValue();

  if (local && local[currentPanel]) {
    return local[currentPanel];
  } else if (allowedSubPanels[currentPanel]) {
    return allowedSubPanels[currentPanel]?.[0];
  }
};

export class PanelbarStore extends FakeAltStoreClass<PanelbarStore> {
  // Figure out if we want to start opened or not, to prevent extra UI switches
  panelType = getStartingPanelType();
  subPanel?: panelbarSubPanels = getStartingSubPanel(getStartingPanelType());
  isOpen = !!this.panelType;
  storyboardView: allowedStoryboardViews;
  forbiddenTypes: panelTypes[] = [];

  constructor() {
    super();
    this.bindListeners({
      handleInit: PanelbarActions.INIT,
      handleOpen: PanelbarActions.OPEN,
      handleSetSubPanel: PanelbarActions.SET_SUB_PANEL,
      handleClose: PanelbarActions.CLOSE,
      handleToggle: PanelbarActions.TOGGLE,
      handleFocusUpdate: [
        FrameFocusActions.INIT,
        FrameFocusActions.SET_MODE,
        FrameFocusActions.CLOSE,
        StoryboardActions.TOGGLE_VIEW_MODE,
      ],
    });
  }

  // Make sure we can actually open here.
  handleInit() {
    const defaultPanel: panelTypes = 'settings';
    this.storyboardView = StoryboardStore.getState().view;

    /**
     * if we have a starting panel type (if a query string was used to force
     * one open, or we have one saved to localStorage) we use that, otherwise,
     * use the default panel */
    if (this.panelType) {
      this.setPanelType(this.panelType ?? defaultPanel);

      this.trackPanelbarEvent('load');
    }
  }

  handleOpen(
    options?:
      | panelTypes
      | {
          type: panelTypes;
          subPanel?: panelbarSubPanels;
        },
  ) {
    const panelType = isObject(options) ? options.type : options ?? 'settings';
    const subPanel = isObject(options) ? options.subPanel : undefined;

    this.setPanelType(panelType ?? getStartingPanelType(), subPanel);

    this.emitChange();

    // ImageLibrary is tracked in PanelbarImageLibrary.tsx
    if (this.panelType !== 'imageLibrary') {
      this.trackPanelbarEvent('open');
    }
  }

  trackPanelbarEvent(action: panelbarEventTypes) {
    const source = eventSource(undefined);

    if (
      action === 'load' &&
      ['editorPanelbar', 'playerPanelbar'].includes(source)
    ) {
      // Do nothing for editor/player loads
    } else {
      Track.event.defer(
        `panelbar_${action}`,
        {
          panelType: this.panelType,
          context: source,
          category: 'Product',
        },
        action === 'load' ? 1000 : 0,
      );
    }
  }

  handleClose() {
    if (!this.isSafeToChangePanelType()) return;
    this.isOpen = false;
    localState.setValue('closed');

    this.trackPanelbarEvent('close');
  }

  handleToggle(panelType?: panelTypes) {
    if (!this.isSafeToChangePanelType()) return;

    if (this.isOpen && (!panelType || panelType === this.panelType)) {
      this.handleClose();
    } else {
      this.handleOpen(panelType);
    }
  }

  /** Requests changing the subpanel without knowing the current panel type */
  handleSetSubPanel(subPanel: panelbarSubPanels) {
    this.setPanelType(this.panelType!, subPanel);

    Track.event.defer(`panelbar_toggle`, {
      panelType: this.panelType,
      subPanel: subPanel,
      context: eventSource(undefined),
      category: 'Product',
    });
  }

  /**
   * Sets the new panel type if that type is allowed in the current context,
   * It will try to find the next best option otherwise. */
  private setPanelType(type: panelTypes, subPanel?: panelbarSubPanels) {
    if (!this.isSafeToChangePanelType()) return;
    this.forbiddenTypes = [];

    if (this.storyboardView === 'shotlist')
      this.forbiddenTypes.push('scriptEditor');

    if (this.forbiddenTypes.indexOf(type) >= 0) {
      this.panelType = allowedPanelTypes.find(
        (i) => this.forbiddenTypes.indexOf(i) < 0,
      );
    } else if (this.panelType !== type) {
      // We make this a condition so we don't save the item when the new
      // panel wasn't allowed
      this.panelType = type;
      localState.setValue(this.panelType);
    }

    if (subPanel && this.panelType) {
      const subPanelsAllowed = allowedSubPanels[this.panelType];
      if (subPanelsAllowed && contains(subPanelsAllowed, subPanel)) {
        this.subPanel = subPanel;
        subPanelState.setValue({
          ...(subPanelState.getValue() ?? {}),
          [this.panelType]: subPanel,
        });
      } else {
        this.subPanel = undefined;
      }
    }

    this.isOpen = !!this.panelType;
    this.emitChange();
  }

  private isSafeToChangePanelType() {
    if (this.isOpen === false) return true;

    const hasUnsavedChanges =
      this.panelType === 'scriptEditor' &&
      ScriptEditorStore.getState().hasChanges &&
      !confirm(
        'Closing the script editor will cause you to lose your unsaved changes - are you sure you want to continue?',
      );

    if (hasUnsavedChanges) logger.log('not safe to change panel type');
    return !hasUnsavedChanges;
  }

  /**
   * We want to keep track of the state of the storyboard view, so we can show/allow different content based on that */
  handleFocusUpdate() {
    // We need a timeout to make sure we have the new values for storyboardView
    idleTimeout(
      () => {
        this.storyboardView = StoryboardStore.getState().view;
        // We only want to re-set the panel type when it's closed, otherwise
        // the script editor would open again after closing the editor.
        // We might want to have this check inside setPanelType, but I'm not
        // sure if we had a reason not to do that.
        if (this.panelType && this.isOpen) this.setPanelType(this.panelType);
      },
      50,
      500,
    );
  }
}

(window as any).PanelbarStore = alt.createStore(PanelbarStore, 'PanelbarStore');
