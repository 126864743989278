/** @prettier */
import classNames from 'classnames';
import * as React from 'react';

export type pageFramePaperSize = 'a4' | 'widescreen' | 'a4-portrait';

export const PageFrame: React.FC<{
  isActive?: boolean;
  paperSize: pageFramePaperSize;
  onClick: any;
  isEnabled: boolean;
}> = (props) => {
  const onClick = (e) => {
    if (e.target.nodeName === 'BUTTON') return;
    props.onClick(e);
  };

  return (
    <div
      className={`pointer p-4 flex justify-center t-o flex-grow ${
        props.isActive ? 'bg-surface-light' : ''
      }`}
      onClick={onClick}
    >
      <div
        className={classNames(
          `shadow-3 relative pdf-${props.paperSize} w-full bg-light-grey`,
          {
            'pb-[56.25%]': props.paperSize === 'widescreen',
            'pb-[142.857%]': props.paperSize === 'a4-portrait',
            'pb-[70.74%]': props.paperSize === 'a4',
          },
        )}
      >
        {props.children}
      </div>
    </div>
  );
};
