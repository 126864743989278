const _ = require('underscore');
const emptyPDF = () => ({
  is_processing_cached_pdf: false,
  cached_pdf_url: null,
  has_current_cached_pdf: false
});

const LS_PREFIX = 'PDF_DEV_';

(() => {
  class PdfStore {

    constructor() {

      this.pdf = emptyPDF();
      this.is_loading         = true;
      this.layout             = "six-up";
      this.upgrade_for_layout = false;
      this.hasErrors          = false;
      this.pollCount = 0;

      this.debugMode = LocalState.getValue(LS_PREFIX + 'debugMode') || false
      this.disableOverflow = LocalState.getValue(LS_PREFIX + 'disableOverflow') || false

      this.bindListeners({
        handleSetLayout:                  PdfActions.SET_LAYOUT,
        handleFetchPdf:                   PdfActions.FETCH_PDF,
        handleGeneratePdf:                PdfActions.GENERATE_PDF,
        handleInvalidateCachedPdf:        [StoryboardActions.REQUEST_START, FrameActions.BULK_FRAME_UPLOAD, FrameActions.SORT_FRAMES, FrameActions.INSERT_FRAME, FrameActions.UPLOAD_IMAGE, FrameActions.SAVE_TEXT, CoverpageActions.SAVE, PdfActions.INVALIDATE_CACHE],
        handlePollPdfProcessingComplete:  PdfActions.POLL_PDF_PROCESSING_COMPLETE,
        handleUpdateLocalSetting:         PdfActions.UPDATE_LOCAL_SETTING,
      });

      this.on('unlisten', () => window.clearTimeout(this.timeout) );
    }

    handleError() {
      this.pdf = emptyPDF();
      this.emitChange();
      RequestActions.error.defer("Could not load information on a your PDF. Please refresh your page or send us a message if the problem persists.");
    }

    handleSetLayout(obj) {
      this.layout = obj.layout_name;
      if (obj.has_premium_features || obj.layout_name == "six-up") {
        this.upgrade_for_layout = false;
      } else {
        this.upgrade_for_layout = false;
      }
    }

    handleInvalidateCachedPdf() {
      this.pdf.has_current_cached_pdf   = false;
      this.pdf.is_processing_cached_pdf = false;
      this.emitChange();
    }

    handleGeneratePdf(obj) {
      this.pdf.is_processing_cached_pdf = true;
      this.emitChange();
      ajaxHelper.ajax({
        method: "post",
        data: {
          layout: this.layout,
        },
        url: "/storyboards/" + obj.storyboard_id + "/cached_pdf_processing.json",
        success: () => this.startPolling(obj.storyboard_id),
        error: () => this.handleError()
      });
    }

    handleFetchPdf(storyboard_id) {
      ajaxHelper.ajax({
        method: "get",
        url: "/storyboards/" + storyboard_id + "/cached_pdf_processing.json",
        success: function (response) {
          this.pdf = response;
          this.is_loading = false;
          this.pollCount = 0;

          if (response.is_processing_cached_pdf) {
            this.startPolling(storyboard_id);
          }

          this.testForErrors(this.pdf);
          this.emitChange();
        }.bind(this),
        error: () => this.handleError()
      });
    }

    testForErrors(pdf) {
       this.hasErrors = !_.isEmpty(pdf.pdf_cover_error_message)
                          || !_.isEmpty(pdf.pdf_frames_error_message)
                          || !_.isEmpty(pdf.pdf_endslate_error_message);
    }

    handlePollPdfProcessingComplete(storyboard_id) {
      return ajaxHelper.ajax({
        method: "get",
        url: "/storyboards/" + storyboard_id + "/cached_pdf_processing.json",
        success: response => {
          if (
            !response.is_processing_cached_pdf
            && response.has_current_cached_pdf
            && response.cached_pdf_url
          ) {
            // NEVER GETS HERE
            console.info(response);
            this.pdf = response;
            this.testForErrors(this.pdf);

            if (!_.isUndefined(window.tour)) {
              window.tour.next();
            }
            this.emitChange();
          }
        },
        error: error => {
          console.error(error);
          this.handleError();
        }
      });
    }

    startPolling(storyboard_id) {
      window.clearTimeout(this.timeout);
      if (this.pollCount > 100) {
        console.log('Aborting PDF fetch');
        this.handleError();
        return;
      }

      this.timeout = window.setTimeout(() => {
        this.pollCount++;

        this.handlePollPdfProcessingComplete(storyboard_id).then(response => {
          if (response.is_processing_cached_pdf || !response.cached_pdf_url) {
            this.startPolling(storyboard_id);
          }
        })
      }, 1000)
    }

    handleUpdateLocalSetting({ name, value }) {
      this[name] = value
      LocalState.setValue(LS_PREFIX + name, value)
    }
  }

  window.PdfStore = alt.createStore(PdfStore, 'PdfStore');

})();
