/** @format */
import type { CommentAnnotationsStore } from '../stores/commentAnnotations';
import { createTypedActions } from '../stores/AltStore';

class CommentAnnotationsActions {
  open = function (
    options: Parameters<CommentAnnotationsStore['handleOpen']>[0],
  ) {
    this.dispatch(options);
  };
  toggle: CommentAnnotationsStore['handleToggle'] = function (options) {
    this.dispatch(options);
  };

  close = function (
    options?: Parameters<CommentAnnotationsStore['handleClose']>[0],
  ) {
    this.dispatch(options);
  };
  registerEngine: CommentAnnotationsStore['handleRegisterEngine'] = function (
    options,
  ) {
    this.dispatch(options);
  };
  addContext: CommentAnnotationsStore['handleAddContext'] = function (options) {
    this.dispatch(options);
  };
  removeContext: CommentAnnotationsStore['handleRemoveContext'] = function (
    options,
  ) {
    this.dispatch(options);
  };
}

const actions = createTypedActions<CommentAnnotationsActions>(
  CommentAnnotationsActions,
);
export { actions as CommentAnnotationsActions };
