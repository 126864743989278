/** @format */
import type { BannerStore } from '../stores/banner';
import { createTypedActions } from '../stores/AltStore';

class BannerActions {
  init: BannerStore['handleInit'] = function (obj) {
    this.dispatch(obj);
  };
  add: BannerStore['handleAdd'] = function (obj) {
    this.dispatch(obj);
  };
  remove: BannerStore['handleRemove'] = function (obj) {
    this.dispatch(obj);
  };
}

const actions = createTypedActions<BannerActions>(BannerActions);
export { actions as BannerActions };
