/** @format */

import React, { useContext } from 'react';
import { AiPrompt } from '../ai/AiPrompt';
import { GeneratorProvider } from '../generator/GeneratorContext';
import { QuizContext } from './QuizContext';

export const QuizGenerate: React.FC = () => {
  const { projectId } = useContext(QuizContext);

  return (
    <GeneratorProvider>
      <AiPrompt showDivider={false} simplified project_id={projectId} />
    </GeneratorProvider>
  );
};
