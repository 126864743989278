/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import * as _ from 'underscore';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { LocalState } from '../../helpers/local-state';
import '../actions/changelog';
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import { ajax } from 'javascripts/helpers/ajax';
import { any } from 'underscore';
const errorHandler = RequestErrorHandler('changelog');

export type changelogType = 'Fix' | 'Improvement' | 'New' | 'Resource';
export interface IChangelogItem {
  title: string;
  metaDescription: string;
  slug: string;
  url: string;
  type: changelogType;
  published: boolean;
  date: string;
  author: any;
  content: string;
}

export class ChangelogStore extends FakeAltStoreClass<ChangelogStore> {
  hasNewItems: boolean = false;
  isLoading: boolean = false;
  hasError: boolean = false;
  lastReadDate: Date = new Date(0);
  items: IChangelogItem[] | null;

  constructor() {
    super();
    const lastReadDate = new Date(LocalState.getValue('changelogRead') || 0);
    if (isValid(lastReadDate)) this.lastReadDate = lastReadDate;

    this.bindListeners({
      handleFetch: ChangelogActions.FETCH,
      handleMarkAsRead: ChangelogActions.MARK_AS_READ,
    });
  }

  handleFetch() {
    this.isLoading = true;
    this.hasError = false;

    ajax('/_changelog')
      .then((result) => {
        this.items = result;
        this.isLoading = false;
        this.updateHasNewItems(this.items);
        this.emitChange();
      })
      .catch(
        errorHandler(
          {
            message: null,
            rollbarMessage: 'Could not load changelog',
          },
          () => {
            this.hasError = true;
            this.emitChange();
          },
        ),
      );
  }

  handleMarkAsRead() {
    /**
     * We don't want to update the changelog date every time the user opens
     * the popover, so we can only really mark items as read since the last time
     * that there were new items
     */
    if (this.hasNewItems === false) return;

    this.lastReadDate = new Date();
    LocalState.setValue('changelogRead', this.lastReadDate.toUTCString());
    this.updateHasNewItems(this.items);
    this.emitChange();
  }

  private updateHasNewItems(items: IChangelogItem[] | null) {
    if (!items) return;
    this.hasNewItems = any(items, () =>
      isAfter(startOfDay(new Date(items[0].date)), new Date(this.lastReadDate)),
    );
  }
}

(window as any).ChangelogStore = alt.createStore(
  ChangelogStore,
  'ChangelogStore',
);
