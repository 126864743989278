/**@prettier */
import { CheckIcon } from '@heroicons/react/24/outline';
import { CountdownPill } from 'blackbird/components/common/countdown/CountdownPill';
import { ModalDivider } from 'blackbird/components/dialog/Dialog';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import { IBillingPlanCurrency } from 'blackbird/helpers/contexts/BillingContext';
import { SignupContext } from 'blackbird/helpers/contexts/SignupContext';
import classNames from 'classnames';
import { LineItem } from 'javascripts/components/payment/PaymentInvoiceLineItem';
import React, { useContext } from 'react';
import { SparkleGraphic } from './SparkleGraphic';

const Divider: React.FC = () => (
  <div className="my-8">
    <ModalDivider />
  </div>
);

interface IFeatureProps {
  label: string | React.ReactNode;
  large?: boolean;
  iconColor?: string;
  customIcon?: React.ReactElement;
  tooltip?: string | React.ReactNode;
}
export const Feature: React.FC<IFeatureProps> = ({
  label,
  tooltip,
  large,
  customIcon,
  iconColor = 'text-type-green',
}) => {
  const Label: React.FC = () => (
    <span
      className={classNames(
        large ? 'text-base' : 'text-sm',
        tooltip &&
          'underline decoration-dotted cursor-help underline-offset-2 decoration-border-mid',
      )}
    >
      {label}
    </span>
  );

  return (
    <div className="flex items-center">
      {customIcon ? (
        <>{customIcon}</>
      ) : (
        <CheckIcon
          className={classNames(
            iconColor,
            'mt-0.5 mr-1.5',
            large ? 'w-6 ' : 'w-4 ',
          )}
        />
      )}

      {tooltip ? (
        <Tooltip placement="right" title={tooltip} as="div" disabled={!tooltip}>
          <span>
            <Label />
          </span>
        </Tooltip>
      ) : (
        <Label />
      )}
    </div>
  );
};

export const SignupWithPlanPreview: React.FC = () => {
  const { maxStoryboards, offer, amount, discountAmount, currency } =
    useContext(SignupContext);

  return !offer ||
    !discountAmount ||
    !amount ||
    !currency ||
    !maxStoryboards ? (
    <SparkleGraphic />
  ) : (
    <div className="px-8">
      <div className="relative p-8 bg-white rounded-lg">
        <div className="space-y-4">
          <div className="text-lg font-semibold">{offer.title}</div>
        </div>
        <Divider />
        <div className="">
          <LineItem
            amount={amount}
            currency={currency as IBillingPlanCurrency}
            amountDescription={
              <span className="text-sm">{`Billed Yearly`}</span>
            }
          >
            <div>
              <div className="font-semibold text-type-primary">{`Professional Large Plan`}</div>

              <div className="mt-1 mb-4 space-y-1">
                <Feature label={`Unlimited Team Members`} />
                <Feature label={`Space for ${maxStoryboards} storyboards`} />
                <Feature label={`Revision & Version Control`} />
                <Feature label={`Feedback Management`} />
                <Feature label={`Automatic Animatics`} />
                <Feature label={`30-Day Money-Back Guarantee`} />
              </div>
            </div>
          </LineItem>

          <LineItem
            isDiscount
            isTotal
            amount={amount - discountAmount}
            currency={currency as IBillingPlanCurrency}
          >
            {`Lifetime ${offer.discount}% Discount`}
          </LineItem>

          <Divider />

          <LineItem
            isTotal
            amount={discountAmount}
            currency={currency as IBillingPlanCurrency}
          >
            <div className="space-y-2">
              <div>{`Total Due Today`}</div>
              <CountdownPill endDate={offer.endDate as Date} />
            </div>
          </LineItem>
        </div>
      </div>
    </div>
  );
};
