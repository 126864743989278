/** @format */
import { createTypedActions } from '../stores/AltStore';
import type { ShareableStore } from '../stores/shareable';

class ShareableActions {
  fetch: ShareableStore['handleFetch'] = function (data) {
    this.dispatch(data);
  };

  setStoryboard: ShareableStore['handleSetStoryboard'] = function (data) {
    this.dispatch(data);
  };

  /** This one is being used by ShareablePasswordStore, and thus is manually typed for now */
  fetchPassword = function (obj: { storyboard_id: number }) {
    this.dispatch(obj);
  };

  /** This one is being used by ShareablePasswordStore, and thus is manually
   * typed for now */
  savePassword = function (val: { password: string; storyboard_id: number }) {
    this.dispatch(val);
  };

  /** This one is being used by ShareablePasswordStore, and thus is manually typed for now */
  unlock = function (data: { password: string; storyboard_id: number }) {
    this.dispatch(data);
  };

  /** This one is being used by ShareablePasswordStore, and thus is manually
   * typed for now */
  checkForPreviousUnlock = function (data: {
    storyboardHashid: string;
    project_id: number;
  }) {
    this.dispatch(data);
  };

  updateView: ShareableStore['handleUpdateView'] = function (data) {
    this.dispatch(data);
  };

  openFrame: ShareableStore['handleOpenFrame'] = function (data) {
    this.dispatch(data);
  };

  setActiveIndex: ShareableStore['handleSetActiveIndex'] = function (data) {
    this.dispatch(data);
  };

  setFullscreen: ShareableStore['handleSetFullscreen'] = function (data) {
    this.dispatch(data);
  };

  openFrameIndex: ShareableStore['handleOpenFrameIndex'] = function (data) {
    this.dispatch(data);
  };

  receive: ShareableStore['handleReceive'] = function (data) {
    this.dispatch(data);
  };
}

const actions = createTypedActions<ShareableActions>(ShareableActions);
export { actions as ShareableActions };

(window as any).ShareableActions = actions;
