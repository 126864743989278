/** @prettier */
import * as mapLimit from 'async/mapLimit';
import type { IFrame } from '../../../types/frame';
import type { Base64FrameImagesResult } from '../types';
import {
  getMissingFrameImageURL,
  getErrorFrameImageURL,
} from '../../../helpers/getMissingFrameImageURL';
import { RequestErrorHandler } from '../../../helpers/request-error-handler';
import { idleTimeout } from '../../../helpers/idle-timeout';
import { forceNonWebP } from '../../../helpers/force-non-webp';
import { isUndefined, clone, omit } from 'underscore';
import { isImagePlaceholder } from 'javascripts/helpers/isImagePlaceholder';
import logger from 'javascripts/helpers/logger';
import { type AspectRatioName } from 'javascripts/types/storyboard';
import { addFreemiumWatermark } from './addFreemiumWatermark';
const errorHandler = RequestErrorHandler('pdfFrameImage');

let cache: Record<string, Base64FrameImagesResult | null> = {};

type doneCallback = (
  err?: any,
  result?: Base64FrameImagesResult | null,
) => void;

export const loadPDFImage = (
  url: string,
  done: doneCallback,
  options?: { failSilent: boolean },
) => {
  if (!url || url.length === 0) return done(null);
  if (!isUndefined(cache[url])) return done(null, clone(cache[url]));

  fetch(url + '?crossorigin20=true')
    .then((response) => response.blob())
    .then((blob) => {
      const objectURL = URL.createObjectURL(blob);
      const image = new Image();
      image.onload = async () => {
        const outputImage =
          BoordsConfig.IsProfessionalFree || BoordsConfig.Freeloader
            ? await addFreemiumWatermark(image)
            : image;

        cache[url] = {
          originalUrl: url,
          // converting to pts
          width: outputImage.width * 0.75,
          height: outputImage.height * 0.75,
          image: outputImage,
          type: blob.type.replace('image/', '').split(';')[0].toUpperCase(),
        };

        // logger.log(BoordsConfig.IsProfessionalFree);

        if (!blob.type || blob.type === '') {
          const extension = url.match(/\.(\w{3,4})$/)?.[1];
          if (!extension)
            return done('could not determine type of file for ' + url);

          cache[url]!.type = extension.toUpperCase().replace('JPG', 'JPEG');
        }

        done(null, cache[url]);
      };

      image.onerror = () => {
        if (options?.failSilent) {
          done(null, null);
          cache[url] = null;
          logger.log(
            `Could not load image ${JSON.stringify(url)}. Failing silently…`,
          );
        } else {
          done(new Error('Could not load image ' + JSON.stringify(url)));
        }
      };
      image.src = objectURL;
    })
    .catch((err) => {
      logger.log(`Could not load image ${JSON.stringify(url)}`);
      if (options?.failSilent) {
        cache[url] = null;
        done(null, null);
      } else {
        // If it fails here, it's almost certainly a network error, so we want
        // to pass along the error as is
        done(err);
      }
    });
};

/**
 * Loads an image, but will fallback to an error placeholder if the request
 * fails, for example if the image is not there */
export const loadPDFImageWithFallback = (
  url: string,
  frameAspectRatio: AspectRatioName,
  done: (err, result?: Base64FrameImagesResult) => void,
) => {
  loadPDFImage(url, (err, result) => {
    if (err) {
      errorHandler({
        message: null,
        severity: 'warn',
        rollbarMessage: 'Could not load image',
      })(err);
      loadPDFImage(getErrorFrameImageURL(frameAspectRatio), done);
      return;
    }

    if (!result) {
      return done(new Error('Result was empty'));
    }
    done(null, result);
  });
};

export const loadFrameImages = (
  frames: IFrame[],
  frameAspectRatio: AspectRatioName,
  lowResImages = false,
) =>
  new Promise<Record<string, Base64FrameImagesResult>>((resolve, reject) => {
    mapLimit(
      frames,
      2,
      (frame, done: doneCallback) => {
        let url = lowResImages
          ? frame.thumbnail_image_url || frame.large_image_url
          : frame.large_image_url;

        url = forceNonWebP(url);

        if (!url || url.length === 0 || isImagePlaceholder(url)) {
          url = getMissingFrameImageURL(frameAspectRatio);
        }

        loadPDFImageWithFallback(url, frameAspectRatio, done);
      },
      (error, result: Base64FrameImagesResult[]) => {
        if (error) return reject(error);

        resolve(
          result.reduce(
            (output: Record<string, Base64FrameImagesResult>, result, i) => {
              output[frames[i].id] = result;
              return output;
            },
            {},
          ),
        );
      },
    );
  });

export const cleanupCachedFrameImages = () => {
  const entries = Object.values(cache);
  if (entries.length === 0) return;

  idleTimeout(() => {
    // logger.log(`Clearing ${entries.length} cached images…`);
    entries.forEach((o) => {
      if (o) URL.revokeObjectURL(o.image.src);
    });
    cache = omit(cache, (v) => entries.indexOf(v) >= 0) as any;
  });
};
