/** @prettier */
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import * as React from 'react';
export const EmptyPage: React.FC<{
  isLoading: boolean;
  title: string;
}> = (props) => (
  <div className="absolute inset-0 flex flex-col bg-white">
    <div className="flex-auto flex items-center justify-center">
      {props.isLoading && <LoadingIndicator />}
    </div>
    <div className="bg-black/40 px-1.5 py-1 text-white text-xxs">
      {props.title}
    </div>
  </div>
);
