const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const ShareableGridItem = require('./ShareableGridItem.react');
const Container = require('../../shared/Container').default;
const frameGroupedState = require('../../../helpers/frame-grouped-state');
require('./ShareableGrid.scss');
module.exports = module.exports = Container(
  ['comment'],
  (storeData, ownProps) => ({
    fetchComments: () => CommentActions.fetch.defer(ownProps.storyboard.short_slug)
  })
)(createReactClass({
  displayName: 'ShareableGrid',

  propTypes:      {
    storyboard: PropTypes.object.isRequired,
    frames: PropTypes.arrayOf(PropTypes.object).isRequired,
    zoomType: PropTypes.oneOf(['player', 'zoom']),

    // From the container
    fetchComments: PropTypes.func.isRequired,
    comment: PropTypes.shape({
      commentSectionsById: PropTypes.object.isRequired,
      fetched: PropTypes.bool.isRequired
    }).isRequired
  },

  componentDidMount() {
    this.props.fetchComments()
  },

  render() {
    const comments = this.props.comment.commentSectionsById;

    // We use this.props.frames instead of this.props.storyboard.frames
    // so that we have the number attribute
    return (
      <ul className="flex-auto p-6 overflow-x-hidden shareable__section__list gap-6 bg-surface">
        {this.props.frames.map( (frame, index) => frame ? (
          <ShareableGridItem
            {...this.props}
            key={"frame:" + frame.id}
            frame={frame}
            grouped={frameGroupedState(this.props.frames, index)}
            index={index + 1}
            commentCount={comments[frame.id] && comments[frame.id] .count}
            zoomType={this.props.zoomType}
          />
        ): null)}
      </ul>
    );
  }

}));
