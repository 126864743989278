/** @prettier */
import { IFrame } from '../../../types/frame';

export const splitArrayPerAmount = (
  frames: IFrame[] = [],
  amountOfFrames: number,
) => {
  const pages: IFrame[][] = [];
  for (let index = 0; index < frames.length; index += amountOfFrames) {
    if (frames[index]) pages.push(frames.slice(index, index + amountOfFrames));
  }

  return pages;
};
