/** @prettier */
import { type TourStepTokens, type TourBlueprint } from './tourData';

const tourSteps = [
  'generatorPrompt',
  'generatorTimer',
  'generatorInsert',
] as const;

export type wizardAddImageTourAvailableSteps = typeof tourSteps[number];

const tokens: TourStepTokens = {
  name: 'Generate your first image',
  count: 5,
};

export const wizardAddImageTour: TourBlueprint<wizardAddImageTourAvailableSteps> =
  {
    name: 'wizardAddImage',
    allowRestart: true,
    steps: [
      {
        name: 'generatorPrompt',
        header: `Enter a prompt`,
        body: `Let's generate an image for this frame. When you're happy with your text prompt, click 'Next' to create your image.`,
        tokens,
      },
      {
        name: 'generatorTimer',
        header: 'Generating image...',
        body: `This usually takes about 20 seconds. Not long to go!`,
        tokens,
      },
      {
        name: 'generatorInsert',
        header: 'Success!',
        body: `Let's add your new image to your storyboard.`,
        buttonText: 'Insert Image',
        tokens,
      },
    ],
  };
