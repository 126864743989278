/** @prettier */
/* eslint-disable i18next/no-literal-string */
import * as React from 'react';
import * as qs from 'querystringify';
import sub from 'date-fns/sub';
import isBefore from 'date-fns/isBefore';
import formatDistance from 'date-fns/formatDistance';
import { ajax } from 'javascripts/helpers/ajax';
import { includes } from 'underscore';
interface IProps {
  token: string;
  userId: number;
}

interface IError {
  assigned_user_id: number;
  /** Also the id in https://rollbar.com/app43797063-heroku.com/Boords/items/2553/occurrences/69776962119/ */
  counter: number;
  id: number;
  integrations_data: any;
  last_occurrence_timestamp: number;
  last_occurrence_id: number;
  level: string;
  status: string;
  title: string;
  total_occurrences: number;
  unique_occurrences: number;
  // And more!
}

interface IState {
  errors: IError[];
  isExpanded: boolean;
}

class AdminRecentErrors extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { errors: [], isExpanded: false };
  }

  componentDidMount() {
    const url =
      'https://api.rollbar.com/api/1/items?' +
      qs.stringify({
        access_token: this.props.token,
        query: 'user_id:' + this.props.userId,
        status: 'active',
      });

    ajax(url).then((response) => {
      if (response.err !== 0) return;
      const errors: IError[] = [];
      const cutoff = sub(new Date(), { days: 2 });
      response.result.items.forEach((e: IError) => {
        if (
          e.title &&
          errors.length < 5 &&
          includes(['error', 'critical'], e.level) &&
          isBefore(cutoff, new Date(e.last_occurrence_timestamp * 1000))
        ) {
          errors.push(e);
        }
      });
      this.setState({ errors });
    });
  }

  handleExpandClick = () => {
    this.setState({ isExpanded: true });
  };

  render() {
    return this.state.errors.length ? (
      <>
        <div className="text-xl font-bold mb-8 mt-16">Rollbar Errors</div>

        {this.state.isExpanded === false ? (
          <div>
            <button
              type="button"
              onClick={this.handleExpandClick}
              className="bg-brand-yellow-25 p-6 rounded-md underline-hover block w-full"
            >
              {this.state.errors.length} recent errors for this user, the latest
              one{' '}
              {formatDistance(
                new Date(this.state.errors[0].last_occurrence_timestamp * 1000),
                new Date(),
                { addSuffix: true },
              )}
              . Click here to reveal…
            </button>
          </div>
        ) : (
          <div className="bg-brand-yellow-25 p-6 rounded-md">
            {this.state.errors.map((e) => (
              <div className="flex items-center" key={e.id}>
                <a
                  href={`https://rollbar.com/app43797063-heroku.com/Boords/items/${e.counter}/occurrences/${e.last_occurrence_id}`}
                  target="_blank"
                  className="underline-hover"
                >
                  <span className="font-bold">
                    {formatDistance(
                      new Date(e.last_occurrence_timestamp * 1000),
                      new Date(),
                      { addSuffix: true },
                    )}
                    &nbsp;
                  </span>
                  {e.title}
                  &nbsp;
                </a>
                {e.integrations_data && e.integrations_data.trello && (
                  <div className="font-display ">
                    (
                    <a
                      className="underline"
                      href={`https://trello.com/c/${e.integrations_data.trello.id}`}
                    >
                      Trello card
                    </a>
                    )&nbsp;
                  </div>
                )}
                <div>
                  {e.assigned_user_id ? (
                    <span title="Someone's working on this">👩‍💻</span>
                  ) : null}
                </div>
              </div>
            ))}
            <a
              target="_blank"
              className="block mt-6 underline-hover"
              href={`https://rollbar.com/app43797063-heroku.com/Boords/person/?environment=production&person_id=${this.props.userId}`}
            >
              More errors on Rollbar…
            </a>
          </div>
        )}
      </>
    ) : null;
  }
}

(window as any).AdminRecentErrors = AdminRecentErrors;
