/** @prettier */

import type { OptionButtonOptions } from './OptionButton';
import RelativeLeftIcon from 'blackbird/images/icons/relative-left.svg';
import RelativeRightIcon from 'blackbird/images/icons/relative-right.svg';
import RelativeTopIcon from 'blackbird/images/icons/relative-top.svg';
import IconRelativeBottom from 'blackbird/images/icons/relative-bottom.svg';
import RelativeCentreVerticalIcon from 'blackbird/images/icons/relative-centre-vertical.svg';
import RelativeCentreHorizontalIcon from 'blackbird/images/icons/relative-centre-horizontal.svg';
import CircleIcon from 'blackbird/images/icons/shape-circle.svg';
import RectangleIcon from 'blackbird/images/icons/shape-square.svg';
import ArrowIcon from 'blackbird/images/icons/shape-arrow.svg';
import TriangleIcon from 'blackbird/images/icons/shape-triangle.svg';
import SelectIcon from 'blackbird/images/icons/move-2.svg';
import TypeIcon from 'blackbird/images/icons/type.svg';
import EraserIcon from 'blackbird/images/icons/erase.svg';
import DrawIcon from 'blackbird/images/icons/draw.svg';
import AlignCentreIcon from 'blackbird/images/icons/align-centre.svg';
import AlignCentreHorizontallyIcon from 'blackbird/images/icons/align-centre-horizontally.svg';
import AlignCentreVerticallyIcon from 'blackbird/images/icons/align-centre-vertically.svg';
import AlignCentreTopIcon from 'blackbird/images/icons/align-centre-top-middle.svg';
import AlignCentreBottomIcon from 'blackbird/images/icons/align-centre-bottom-middle.svg';
import type { frameEditorShapeTypes } from '../shapes';
import type {
  alignmentValues,
  arrangementValues,
  frameEditorMode,
} from '../types';
import { indexBy } from 'underscore';

export const alignmentOptions: Readonly<
  OptionButtonOptions<alignmentValues>[]
> = [
  { value: 'middle', icon: AlignCentreIcon },
  {
    value: 'horizontal',
    icon: AlignCentreHorizontallyIcon,
  },
  {
    value: 'vertical',
    icon: AlignCentreVerticallyIcon,
  },
  { value: 'top', icon: AlignCentreTopIcon },
  {
    value: 'bottom',
    icon: AlignCentreBottomIcon,
  },
] as const;

export const arrangementOptions: Readonly<
  OptionButtonOptions<arrangementValues>[]
> = [
  {
    value: 'left',
    icon: RelativeLeftIcon,
  },
  {
    value: 'horizontal',
    icon: RelativeCentreVerticalIcon,
  },
  {
    value: 'right',
    icon: RelativeRightIcon,
  },
  {
    value: 'top',
    icon: RelativeTopIcon,
  },
  {
    value: 'vertical',
    icon: RelativeCentreHorizontalIcon,
  },
  {
    value: 'bottom',
    icon: IconRelativeBottom,
  },
] as const;

export const textAlignOptions = [
  { value: 'left', iconName: 'text-align-left' },
  { value: 'center', iconName: 'text-align-center' },
  { value: 'right', iconName: 'text-align-right' },
];

export const modeOptions: Readonly<OptionButtonOptions<frameEditorMode>[]> = [
  {
    value: 'draw',
    icon: DrawIcon,
  },
  {
    value: 'eraser',
    icon: EraserIcon,
  },
  {
    value: 'select',
    icon: SelectIcon,
  },
  {
    value: 'text',
    icon: TypeIcon,
  },
];

export const modeOptionsByValue = indexBy(modeOptions, 'value');

export const shapeTypes: Readonly<
  OptionButtonOptions<frameEditorShapeTypes>[]
> = [
  {
    value: 'ellipse', // don't change, needed so that it matches up with fabric
    icon: CircleIcon,
  },
  {
    value: 'rect',
    icon: RectangleIcon,
  },
  {
    value: 'triangle',
    icon: TriangleIcon,
  },
  {
    value: 'arrow',
    icon: ArrowIcon,
  },
];

export const shapeTypesByValue = indexBy(shapeTypes, 'value');
