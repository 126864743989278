module.exports = function() {
  return {
    development: function() {
      return (process.env.NODE_ENV === "development");
    },
    production: function() {
      return (process.env.NODE_ENV === "production");
    },
  }
};
