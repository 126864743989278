/** @prettier */
import {
  tourEvents,
  type TourStepTokens,
  type TourBlueprint,
} from './tourData';

const tourSteps = ['wizardAnimaticTimeline', 'pressPlay'] as const;

export type wizardAnimaticTourAvailableSteps = typeof tourSteps[number];

const tokens: TourStepTokens = {
  name: 'Explore animatics',
  count: 5,
};

export const wizardAnimaticTour: TourBlueprint<wizardAnimaticTourAvailableSteps> =
  {
    name: 'wizardAnimatic',
    allowRestart: true,
    steps: [
      {
        name: 'wizardAnimaticTimeline',
        header: `Adjust your Timeline`,
        body: `Click and drag the grey dots to adjust frame duration.`,
        waitsOn: tourEvents.animaticAdjustTimeline,
        tokens,
      },
      {
        name: 'pressPlay',
        header: 'Play your Animatic',
        body: `Click play to see your storyboard as an animatic!`,
        tokens,
      },
    ],
  };
