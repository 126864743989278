/** @format */
import classNames from 'classnames';
import {
  DRAGGABLE_TYPES,
  type DashboardDragItem,
} from 'javascripts/components/shared/dragAndDropUtils';
import * as React from 'react';
import { type ConnectDropTarget, useDrop } from 'react-dnd';
import { isNumber } from 'underscore';
import { ProjectsContext } from '../ProjectsContext';

export interface ProjectDropZoneChildArgs {
  isInvalidDrag: boolean;
  isOver: boolean;
  ref: ConnectDropTarget;
}

/** Creates a container representing a Project in the dashboard, and allows
 * storyboards and projects to be dropped in to it  */
export const ProjectDropZone: React.FC<
  {
    className?: string;
    disabled?: boolean;
    projectId: number | null;
    canDrop?: boolean;
    accept?: (keyof typeof DRAGGABLE_TYPES)[];
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className, disabled, projectId, canDrop = true, accept, ...rest }) => {
  const { moveItems } = React.useContext(ProjectsContext);

  const [{ isInvalidDrag, isOver }, ref] = useDrop(
    () => ({
      accept: accept ?? [DRAGGABLE_TYPES.project, DRAGGABLE_TYPES.storyboard],
      canDrop: (item: DashboardDragItem, monitor) => {
        if (!canDrop) return false;
        if (disabled) return false;
        // You can't move a storyboard into it's current project
        // You can't move a project into it's current parent;
        if (item.parentProjectId === projectId) return false;
        // You can't move a project into itself
        if (item.id === projectId) return false;

        // If the project has children, and our project ID is included, don't
        // allow dropping
        if (
          monitor.getItemType() === DRAGGABLE_TYPES.project &&
          isNumber(projectId) &&
          item.childrenIds &&
          item.childrenIds.indexOf(projectId) >= 0
        ) {
          return false;
        }

        return true;
      },
      drop: (item: DashboardDragItem, monitor) => {
        const type = monitor.getItemType();
        if (!type)
          throw new Error(
            'not sure how to handle this dropped item, it has no type',
          );

        if (type === DRAGGABLE_TYPES.storyboard) {
          moveItems(projectId, undefined, [item.id]);
        } else if (monitor.getItemType() === DRAGGABLE_TYPES.project) {
          moveItems(projectId, [item.id]);
        } else {
          return;
        }
      },
      collect: (monitor) => ({
        // Are we currently dragging anything, anywhere in the app?
        isInvalidDrag: monitor.getItemType() ? !monitor.canDrop() : false,
        isOver: monitor.isOver() && monitor.canDrop(),
      }),
    }),
    // [],
  );

  return (
    <div
      {...rest}
      className={classNames(
        'transition-opacity rounded-md',
        className,
        disabled || isInvalidDrag ? 'opacity-40' : undefined,
        isOver && 'ring-2 ring-brand-pink',
      )}
      ref={ref}
    />
  );
};
