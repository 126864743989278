/** @prettier  */
const PropTypes = require('prop-types');

/**
 * Hello! if the icon looks the wrong size, it's because it assumes
 * that the source icon file is 30px high.
 * If it's not 30px, set this.props.sourceSize
 */
const Icon = ({
  icon,
  width,
  sourceSize = 30,
  className = '',
  margin = 0,
}) => {
  let iconTransform = null;

  if (width) {
    const diff = width / sourceSize;
    // Do not scale up images, it leads to bad results.
    // (the framePanelBar nav buttons for example)
    if (diff < 1) {
      iconTransform = {
        transform: `scale(${diff})`,
        transformOrigin: '0 0',
        imageRendering: 'initial',
        margin,
      };
    }
  }

  return (
    <div className={`w${width}px h${width}px ${className}`}>
      <i className={'boords-icon--' + icon} style={iconTransform} />
    </div>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  margin: PropTypes.number,
  sourceSize: PropTypes.number,
};

module.exports = Icon;
