const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
window.ProjectTeamMemberAvatar = createReactClass({

  propTypes:  {
    project_id:  PropTypes.number.isRequired,
    team_id:     PropTypes.number.isRequired,
    member:      PropTypes.object.isRequired,
  },

  getInitialState: function(e) {
    return {
      active: false
    }
  },

  _memberName: function(member) {
    var suffix = "";
    if (member.is_you) {
      suffix = " (You)";
    }
    return member.name + suffix;
  },

  _handleMouseEnter: function(e) {
    this.setState({active: true});
  },

  _handleMouseLeave: function(e) {
    this.setState({active: false});
  },


  render: function() {

    var member    = this.props.member,
      activeClass = this.state.active ? 'active' : '';

    return (
      <div className={"hint--bottom project__team__member " + activeClass} onMouseEnter={this._handleMouseEnter} aria-label={this._memberName(member)} onMouseLeave={this._handleMouseLeave}>
        <img src={member.avatar_url} />
      </div>
    );
  }
});
