/** @format */
/** Places the text editor inside the given designated element, useful when
 * contentEditable nodes have other elements inside them. */
export const focusInsideElement = (
  elementToFocusInsideOf?: HTMLElement | null,
  position: 'start' | 'end' = 'start',
) => {
  if (!elementToFocusInsideOf) return;
  const range = document.createRange();
  if (position === 'end') {
    range.selectNodeContents(elementToFocusInsideOf);
    range.collapse(false); // Set the range to the end
  } else {
    range.setStart(elementToFocusInsideOf, 0);
    range.setEnd(elementToFocusInsideOf, 0);
  }
  const selection = window.getSelection()!;
  selection.removeAllRanges();
  selection.addRange(range);
};
