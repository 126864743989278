/** @format */
import * as React from 'react';
import { any, debounce, isNumber, isString, reduce } from 'underscore';
import logger from './logger';

type changeHandler = (newValue: string) => void;

/**
 * Returns a filtered version of `options` according to a text value, and a
callback function to call with the updated search query. Slightly debounced.
 * When it receives `options` for the first time, it will look for all props
 * that have a string or number value, these will than be used for filtering
 * once the callback is run. Optionally, you can pass a `searchableProps` array
 * if you want to determine these fields manually.
 * ```
 * const [filteredOptions, triggerTextSearch] = useTextSearch(
 *    options,
 *    searchableProps,
 * );
 * ``` */
export const useTextSearch = <T = Record<string, unknown>>(
  options: T[],
  /** Which props of the object should be searched through? By default, this
   * will take all props in the first item of options that have a string or
   * number value */
  propertiesToScan?: Array<keyof T>,
): [T[], changeHandler] => {
  const [output, setOutput] = React.useState<T[]>(options);

  // Automatically determine which fields we want to index
  const scanList = React.useMemo(() => {
    return (
      propertiesToScan ??
      reduce<any, Array<keyof T>>(
        options[0],
        (o, value, key) => {
          if (key === 'value') return o;
          if (isString(value) || isNumber(value)) {
            o.push(key);
            logger.log(`Determining the key ${key} as an indexable field`);
          }
          return o;
        },
        [],
      )
    );
  }, [options, propertiesToScan]);

  const handleChange = React.useCallback<changeHandler>(
    debounce((search) => {
      search = search.trim();

      if (search.length < 2) {
        setOutput(options);
        return;
      }

      const result = options.filter((o) =>
        any(scanList, (keyToFilter) => {
          const value = o[keyToFilter]! as string | number;
          return String(value).toLowerCase().includes(search.toLowerCase());
        }),
      );

      setOutput(result);
    }, 50),
    [options, scanList],
  );

  React.useEffect(() => {
    setOutput(options);
  }, [options]);

  return [output, handleChange];
};
