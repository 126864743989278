import * as React from 'react';
import { ErrorBoundary } from '../shared/ErrorBoundary';
import { SuspenseLoader } from '../shared/SuspenseLoader';

import type { Shotlist  as shotlistType } from './Shotlist'

const Shotlist = React.lazy(() =>
  import('./Shotlist'),
);

export const ShotlistContainer:typeof shotlistType = props => {
  return (
  <ErrorBoundary>
      <SuspenseLoader>
        <Shotlist {...props} />
      </SuspenseLoader>
  </ErrorBoundary>
)};
