/** @format */

import { isNull, mapObject, some } from 'underscore';
const maxStringLength = 200;

var isPlainObject = function (obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

/** Filters through a JSON object and truncates values that are too large (like
 * file upload strings) */
export const simplifyRequestJSON = (json: any) => {
  return mapObject(json, (v) => {
    if (isPlainObject(v)) return simplifyRequestJSON(v);
    if (typeof v === 'string') {
      if (v.indexOf('data:') === 0) return v.slice(0, 50) + '…';
      if (v.length > maxStringLength) return v.slice(0, maxStringLength) + '…';
      return v;
    } else if (
      some(['boolean', 'number', 'undefined'], (i) => typeof v === i) ||
      isNull(v)
    ) {
      return v;
    } else {
      return `[${v.name || typeof v}]`;
    }
  });
};
