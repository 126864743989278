import type { storeContextData } from './StoreContextProvider';
import './stores/storyboard';
import './stores/comment';
import './stores/frame';
import './stores/panelbar';
import './stores/user_permissions';
import './stores/tours';
import './stores/dashboard';
import './stores/user';
import './stores/changelog';
import './stores/storyboardAnalysis';
import './stores/testimonial';
import './stores/storyboard_starters'
import './stores/frame_focus'
import './stores/player'
import './stores/assets'
import './stores/project';
import "./stores/storyboardZoom"
import "./stores/ActivityLog"
import "./stores/coverpage"
import './stores/assets';
import './stores/avatar';
import './stores/scripteditor'
import './stores/shareable';
import './stores/archive'
import './stores/frame_editor'
import './stores/commentAnnotations'

/** An (incomplete) list of all the stores, used for passing to the
 * `storeContextProvider`  */
export const allTheStores: storeContextData = {
  StoryboardStore,
  CommentStore,
  FrameStore,
  PanelbarStore,
  ToursStore,
  UserPermissionsStore,
  DashboardStore,
  UserStore,
  ChangelogStore,
  StoryboardAnalysisStore,
  TestimonialStore,
  StoryboardStartersStore,
  FrameFocusStore,
  AssetsStore,
  ProjectStore,
  StoryboardZoomStore,
  PlayerStore,
  ActivityLogStore,
  CoverpageStore,
  AvatarStore,
  ScriptEditorStore,
  ShareableStore,
  ArchiveStore,
  FrameEditorStore,
  CommentAnnotationsStore
};

export type allTheStoreNames = keyof typeof allTheStores
