/** @format */

export enum FrameStatus {
  PROGRESS = 'In Progress',
  APPROVED = 'Approved',
  REVIEW = 'Review Needed',
  CLEAR = 'Remove Status',
}

export const FRAME_COLORS = {
  [FrameStatus.PROGRESS]: 'bg-brand-blue',
  [FrameStatus.REVIEW]: 'bg-brand-yellow',
  [FrameStatus.APPROVED]: 'bg-type-green',
  [FrameStatus.CLEAR]: 'bg-white',
};
