/** @prettier */
import * as React from 'react';
import { StarIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import Dialog, { ModalDivider } from '../dialog/Dialog';
import { GeneratorContext } from '../generator/GeneratorContext';
import { OfferContext, OfferProvider } from './OfferContext';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';

interface OfferInlineDialogProps {
  fancy?: boolean;
  onDecline?: () => void;
  role: string;
  isAi: boolean;
  isTeam: boolean;
}

const OfferAddonDialogComponent: React.FC<OfferInlineDialogProps> = ({
  onDecline,
  fancy,
  role,
  isAi,
  isTeam,
}) => {
  const { setOfferOpen } = React.useContext(GeneratorContext);
  const {
    flyoverTitle,
    flyoverPreHeader,
    flyoverSignupCount,
    flyoverContents,
    flyoverButton,
    flyoverPrice,
    setRole,
    setIsAi,
    setIsTeam,
    setIsOfferView,
  } = React.useContext(OfferContext);

  const handleDecline = () => {
    if (typeof onDecline !== 'undefined') {
      onDecline();
    } else {
      setOfferOpen(false);
    }
  };

  React.useEffect(() => {
    setRole(role);
    setIsAi(isAi);
    setIsTeam(isTeam);
  }, [role, isAi, isTeam]);

  React.useEffect(() => {
    setIsOfferView(true);
    // loadStripeCheckoutSession();
  }, []);

  const Stars: React.FC = () => (
    <div className="flex items-center mb-1.5 mt-0">
      <span className="mr-1 text-xs">5/5</span>
      {[1, 2, 3, 4, 5].map((f) => (
        <StarIcon className="w-4 text-brand-yellow" key={`star-${f}`} />
      ))}
      <span className="ml-1 text-xs">{`Rated by verified customers`}</span>
    </div>
  );

  return (
    <Dialog
      size={'slim'}
      hideActions
      isDark
      isBlur
      isOpen
      keepOpen
      // onEscapeOrOutsideClick={() => handleDecline()}
      closeButtonClasses="mt-8 z-50 hidden"
      containerClasses={classNames('!pb-0 overflow-x-hidden pt-5 tall:pt-24')}
      overlayClassName={
        fancy ? `from-brand-pink-25 to-brand-yellow-25 bg-gradient-to-br` : ''
      }
      titleTextContainerClasses="hidden"
      title={`x`}
    >
      <div className="absolute top-0 left-0 right-0">{flyoverPreHeader}</div>

      <div>
        {flyoverTitle}
        <Stars />
        {flyoverPrice}
        {flyoverSignupCount}
      </div>

      <div>
        <ModalDivider />

        <div className={classNames('pt-1')}>
          <div className="flex flex-col">
            {flyoverContents}
            <div className="text-center">
              {flyoverButton}
              <div className="text-center">
                <span
                  onClick={() => handleDecline()}
                  className="w-full text-sm underline cursor-pointer underline-offset-2 text-type-subdued decoration-black/20 hover:no-underline"
                >
                  {`No thanks, I'll pay full price later`}
                </span>
              </div>
              <div className="mt-6" />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export const OfferAddonDialog: React.FC<OfferInlineDialogProps> = (props) => (
  <DefaultStoreContextProvider>
    <OfferProvider>
      <OfferAddonDialogComponent {...props} />
    </OfferProvider>
  </DefaultStoreContextProvider>
);
