/** @format */

import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import {
  ProjectsContext,
  type Project,
  type ProjectGroup,
} from './ProjectsContext';
import { ProjectSkeletonBreadcrumb } from './ProjectSkeleton';

const BreadcrumbItem: React.FC<{
  name: string;
  project?: Project;
  isLast: boolean;
  isGroupName?: boolean;
  allEntriesClickable?: boolean;
}> = ({ name, project, isGroupName, isLast, allEntriesClickable }) => {
  const { setActiveProject } = useContext(ProjectsContext);
  const isClickable =
    (!isLast || allEntriesClickable) && !isGroupName && project;
  const content = (
    <div
      className={classNames(
        'text-type-subdued truncate whitespace-nowrap',
        isClickable &&
          'underline cursor-pointer text-type-subdued decoration-border-mid hover:no-underline underline-offset-2',
      )}
    >
      {name}
    </div>
  );
  const wrappedContent = isClickable ? (
    allEntriesClickable ? (
      <a href={isGroupName ? '/' : `/p/${project.slug}`}>{content}</a>
    ) : (
      <div onClick={() => setActiveProject(project)}>{content}</div>
    )
  ) : (
    content
  );
  return (
    <>
      {wrappedContent}
      {!isLast && <div className="text-type-disabled mx-1.5">{`/`}</div>}
    </>
  );
};

const EllipsisItem: React.FC = () => (
  <>
    <div className="text-type-disabled">...</div>
    <div className="text-type-disabled mx-1.5">/</div>
  </>
);

interface ProjectContentsBreadcrumbProps {
  allEntriesClickable?: boolean;
}

export const ProjectContentsBreadcrumb: React.FC<
  ProjectContentsBreadcrumbProps
> = ({ allEntriesClickable }) => {
  const { activeProject, activeGroup, projectGroups } =
    useContext(ProjectsContext);

  const findProjectById = (id: number): Project | undefined => {
    if (!projectGroups) return undefined;
    const allProjects = [
      ...projectGroups.team.projects,
      ...projectGroups.memberships.reduce(
        (acc: Project[], group: ProjectGroup) => {
          return acc.concat(group.projects);
        },
        [],
      ),
    ];
    return allProjects.find((project) => project.id === id);
  };

  const buildBreadcrumbTrail = useMemo(() => {
    if (!activeProject) return [];
    const trail: Project[] = [];
    let currentProject: Project | undefined = activeProject;
    while (currentProject) {
      trail.unshift(currentProject);
      currentProject = currentProject.parent_id
        ? findProjectById(currentProject.parent_id)
        : undefined;
    }
    return trail;
  }, [activeProject, projectGroups]);

  if ((!activeProject || !activeGroup) && allEntriesClickable) {
    return <ProjectSkeletonBreadcrumb />;
  } else if (
    !activeProject ||
    !activeGroup ||
    buildBreadcrumbTrail.length === 0
  ) {
    return null;
  }

  const renderTruncatedBreadcrumb = () => {
    if (buildBreadcrumbTrail.length <= 3) {
      // If there are 2 or fewer items, render them all
      return buildBreadcrumbTrail.map((project, index) => (
        <BreadcrumbItem
          key={project.id}
          name={project.name}
          project={project}
          isLast={index === buildBreadcrumbTrail.length - 1}
          allEntriesClickable={allEntriesClickable}
        />
      ));
    } else {
      // Render ellipsis and last two items
      return (
        <>
          <EllipsisItem />
          {buildBreadcrumbTrail.slice(-3).map((project, index, array) => (
            <BreadcrumbItem
              key={project.id}
              name={project.name}
              project={project}
              isLast={index === array.length - 1}
              allEntriesClickable={allEntriesClickable}
            />
          ))}
        </>
      );
    }
  };

  return (
    <div className="flex items-center text-sm">
      <BreadcrumbItem
        name={activeGroup.heading}
        isLast={false}
        isGroupName
        allEntriesClickable={allEntriesClickable}
      />
      {renderTruncatedBreadcrumb()}
    </div>
  );
};
