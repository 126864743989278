/** @prettier */
import Button from 'blackbird/components/button/Button';
import { BillingContext } from 'blackbird/helpers/contexts/BillingContext';
import { fireConfetti } from 'javascripts/helpers/confetti';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PaymentUpdateSuccess: React.FC = () => {
  const { setDialogTitle, setDialogSubtitle, newPlan } =
    useContext(BillingContext);

  const { t } = useTranslation('billing');

  useEffect(() => {
    if (newPlan) {
      setDialogTitle(t('success.update.title'));
      setDialogSubtitle(
        t('success.update.subtitle', { planName: newPlan.name }),
      );
      setTimeout(() => {
        fireConfetti();
      }, 250);
    }
  }, [newPlan]);

  return (
    <div className="w-full mt-8 text-center">
      <Button
        type="solid"
        className="w-full"
        size="md"
        onClick={() => (window.location.href = '/')}
      >
        {t('success.update.cta')}
      </Button>
    </div>
  );
};
