/** @prettier */
import { tourEvents, TourBlueprint } from './tourData';
import { ToursActions } from '../flux/actions/tours';

/**
 * A simple array of the names of all the steps that we have, this is
 * mostly in order to generate a type for all these steps. The `as const` is
 * important for that. We then use the steps in the object below.
 */
// prettier-ignore
const organisationTourSteps = ['addTextToFrame', 'viewShotList'] as const;

export type organisationTourAvailableSteps = typeof organisationTourSteps[number];

export const organisationTour: TourBlueprint<organisationTourAvailableSteps> = {
  name: 'organisation',
  steps: [
    {
      name: 'addTextToFrame',
      waitsOn: tourEvents.addTextToFrame,
      header: `Welcome! Type here to start`,
      body: `To get started, write a description of the sound in this scene. If you're stuck, type something like "Intro music plays"`,
    },
    {
      name: 'viewShotList',
      waitsOn: tourEvents.shotListOpened,
      header: `Excellent! Now click here to see the shot list view...`,
      body: `Shot lists are a great way to see all your storyboard frames and text in a compact layout.`,
    },
  ],
  onComplete: () => ToursActions.start.defer('export'),
};
