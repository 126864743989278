/** @prettier */
import * as React from 'react';
import {
  currentUserFeaturesType,
  currentUserFeatures,
  UserPermissionsStore as StoreClass,
  UserFeatureCTA,
  strictUserFeaturesType,
} from '../flux/stores/user_permissions';
import { UserPermissionsActions } from '../flux/actions/user_permissions';
import { UserPermissionFlyover } from '../components/flyover/UserPermissionFlyover';
import Container from '../components/shared/Container';
import { useStore } from './useStore';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { FeatureUnavailable } from 'blackbird/components/common/FeatureUnavailable';
import { FeatureUnavailableButton } from 'blackbird/components/common/FeatureUnavailableButton';
import { ButtonSize, ButtonType } from 'blackbird/components/button/types';

type permissionState = boolean | 'fetching';

const checkPermission = (
  userId: number,
  featureName: currentUserFeaturesType,
  storeData: StoreClass,
) => {
  if (storeData.features[userId]) {
    const result = storeData.features[userId][featureName as any];
    if (!result) throw new Error(`Feature ${featureName} not found`);
    return result.available;
  } else {
    UserPermissionsActions.fetch.defer({ userId });
    return 'fetching';
  }
};

export const hasPermission = (
  userId: number,
  featureName: currentUserFeaturesType,
) => {
  if (!userId) throw new Error('requires user id');
  if (currentUserFeatures.indexOf(featureName as any) < 0)
    throw new Error(
      `Feature "${featureName}" is not valid, needs to be one of ${currentUserFeatures.join()}`,
    );

  return checkPermission(userId, featureName, UserPermissionsStore.getState());
};

export const getFeatureRestrictionCTA = (
  userId: number,
  featureName: currentUserFeaturesType,
): UserFeatureCTA =>
  UserPermissionsStore.getState().features[userId][featureName as any].cta;

const getFlyoverProps = (
  userId: number,
  featureName: currentUserFeaturesType,
) => ({
  userId,
  featureName: featureName,
  identifier: featureName,
  cta: getFeatureRestrictionCTA(userId, featureName),
});

export const createPermissionFlyoverOpener =
  (userId: number, featureName: currentUserFeaturesType) => () =>
    FlyoverActions.open({
      type: 'userPermission',
      props: getFlyoverProps(userId, featureName),
    });

/**
 * This function returns `true` if user does **not** have permission.
 * That way it can be used to control flow
 * @example if (notificationIfNoPermission(state)) return;
 */
export const notificationIfNoPermission = (
  userId: number,
  featureName: currentUserFeaturesType,
): boolean => {
  if (!hasPermission(userId, featureName)) {
    createPermissionFlyoverOpener(userId, featureName)();
    return true;
  } else {
    return false;
  }
};

interface IFPermissionProps {
  inline?: boolean;
  storyboardOwnerId: number;
  featureName: currentUserFeaturesType;
}

/**
 * A component that only renders if the user has access to the feature, will
 * show the flyover if it is not allowed
 */
const IFPermissionComponent: React.FunctionComponent<
  IFPermissionProps & {
    userPermissions: StoreClass;
    hideClose?: boolean;
    className?: string;
  }
> = (props) => {
  const result = checkPermission(
    props.storyboardOwnerId,
    props.featureName,
    props.userPermissions,
  );

  const className = `w-100 h-100 flex items-center justify-center ${
    props.className || ''
  }`;

  return result === 'fetching' ? (
    <div className={className}>
      <LoadingIndicator fill />
    </div>
  ) : result === false ? (
    props.inline ? (
      <div>{`hello`}</div>
    ) : (
      <div className={className}>
        <UserPermissionFlyover
          {...getFlyoverProps(props.storyboardOwnerId, props.featureName)}
          hideClose={props.hideClose}
        />
      </div>
    )
  ) : (
    <>{props.children}</>
  );
};

/**
 * A component that only renders if the user has access to the feature, will
 * show the flyover if it is not allowed
 */
export const IFPermission = Container(['userPermissions'])(
  IFPermissionComponent,
) as typeof IFPermissionComponent;

/**
 * custom hook to see if a feature is enabled for the user id supplied, this is
 * typically the storyboard owner's id.
 */
export const usePermission = (
  userId: number,
  featureName: currentUserFeaturesType,
): permissionState => {
  const hasPermission = useStore('userPermissions', (store) =>
    checkPermission(userId, featureName, store),
  );

  return hasPermission;
};

/**
 * A component that only renders if the user has access to the feature, will
 * show a feature unavailable component if not
 */

interface PermissionPanelProps {
  storyboardOwnerId: number;
  featureName: strictUserFeaturesType;
  children: React.ReactElement;
  align?: undefined | 'left';
  isButton?: boolean;
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
}

type FinalPermissionPanelProps = PermissionPanelProps &
  (
    | { isButton?: false }
    | ({ isButton: true } & Required<
        Pick<PermissionPanelProps, 'buttonType' | 'buttonSize'>
      >)
  );

export const IFPermissionPanel: React.FC<FinalPermissionPanelProps> = ({
  storyboardOwnerId,
  featureName,
  children,
  isButton,
  align,
  buttonType,
  buttonSize,
}) => {
  const hasPermission = usePermission(storyboardOwnerId, featureName);

  return hasPermission === 'fetching' ? null : !hasPermission ? (
    isButton ? (
      <FeatureUnavailableButton
        featureName={featureName}
        buttonType={buttonType}
        buttonSize={buttonSize}
      />
    ) : (
      <FeatureUnavailable align={align} featureName={featureName} />
    )
  ) : (
    <>{children}</>
  );
};

/**
 * If you're unable to use the `usePermission` hook because you're not in a
 * function component, you can use this component to use the "render props"
 * pattern to get an automatically updating permissions value.
 * @example <GetPermission>{ (hasPermission) => (<MyComponent />)}</GetPermission>
 */
export const GetPermission: React.FC<
  IFPermissionProps & {
    children: (permissionState: permissionState) => React.ReactElement;
  }
> = (props) => {
  const hasPermission = usePermission(
    props.storyboardOwnerId,
    props.featureName,
  );
  return props.children(hasPermission);
};
