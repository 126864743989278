/** @format */
import type {
  AnnotationID,
  AnnotationToolEngine,
  AnnotationToolEngineProps,
  AnnotationToolSettings,
} from 'blackbird/components/comments/annotations/AnnotationToolEngine';
import { FakeAltStoreClass } from './AltStore';
import type {
  CommentAnnotationData,
  CommentStore as CommentStoreType,
} from 'javascripts/flux/stores/comment';
import { type FrameStore as FrameStoreType } from 'javascripts/flux/stores/frame';
import * as shallowEqual from 'shallowequal';
import { isFunction, some, throttle, without } from 'underscore';
import { logInDevelopment } from 'javascripts/helpers/logger';
import { CommentAnnotationsActions } from '../actions/commentAnnotations';
import { retryWhile } from 'javascripts/helpers/retry-while';
import { activeAnnotationLocalState } from 'javascripts/helpers/local-state';
import { CommentActions } from '../actions/comment';
import { ShareableActions } from '../actions/shareable';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
const errorHandler = RequestErrorHandler('commentAnnotationsStore');

export type AnnotationOverlayState =
  | 'off'
  | 'loading'
  | 'editing'
  | 'previewing';

interface AnnotationsAvailabilityContext {
  name: string;
  editor: boolean;
  preview: boolean;
}

export const newAvailabilityContext = (
  props: AnnotationsAvailabilityContext,
): AnnotationsAvailabilityContext => {
  return props;
};

export class CommentAnnotationsStore extends FakeAltStoreClass<CommentAnnotationsStore> {
  // TODO: It's probably better to expose an array with available 'contexts' and
  // derive these variables from that. The current approach can lead to trouble
  // if one component dismounts and sets one of these to `false` even though
  // another is still active (that might not happen in the app, though)
  isEditorAvailable: boolean;
  /** is either the frame focus overlay or the preview dialog available? */
  isPreviewAvailable: boolean;
  private contextsAvailable: AnnotationsAvailabilityContext[] = [];
  editorState: AnnotationOverlayState = 'off';
  settings?: AnnotationToolSettings;
  // open: OpenAnnotationFunc;
  // close: CloseAnnotationFunc;
  /** Basically indicates if it should be on? */
  currentAnnotation: AnnotationToolEngineProps | null;
  private pendingAnnotation: AnnotationToolEngineProps | null;
  canUndo: boolean;
  canRedo: boolean;
  engine: AnnotationToolEngine | null;

  constructor() {
    super();

    this.bindListeners({
      handleOpen: CommentAnnotationsActions.OPEN,
      handleClose: [
        CommentAnnotationsActions.CLOSE,
        FrameFocusActions.CLOSE,
        ShareableActions.SET_ACTIVE_INDEX,
      ],
      handleToggle: CommentAnnotationsActions.TOGGLE,
      handleRegisterEngine: CommentAnnotationsActions.REGISTER_ENGINE,
      handleAddContext: CommentAnnotationsActions.ADD_CONTEXT,
      handleRemoveContext: CommentAnnotationsActions.REMOVE_CONTEXT,
      handleCommentDelete: CommentActions.DELETE,
    });
  }

  async handleOpen(openProps: {
    commentId: AnnotationID | null;
    interactive: boolean;
    /** if the editor is not available, fail silently */
    silent?: boolean;
  }) {
    if (openProps.interactive === false && !this.isPreviewAvailable) {
      if (!openProps.silent) throw new Error('no preview context available');
      return;
    }
    if (openProps.interactive === true && !this.isEditorAvailable) {
      if (!openProps.silent) throw new Error('no editor context available');
      return;
    }

    const annotationProps: AnnotationToolEngineProps = {
      // The showBackground prop is optional, but we want to pass a default
      // for cleaner code down the line
      showBackground: false,
      id: openProps.commentId,
      interactive: openProps.interactive,
      annotation: null,
      background: undefined,
    };

    if (openProps.commentId) {
      const commentStore = CommentStore.getState() as CommentStoreType;
      const matchingComment =
        commentStore.commentsRawById[openProps.commentId.commentId];
      const frameStore = FrameStore.getState() as FrameStoreType;
      const matchingFrame = frameStore.frames.find(
        (f) => f.id === matchingComment?.frame_id,
      );

      if (matchingFrame && matchingComment) {
        annotationProps.annotation = matchingComment?.annotation;
        annotationProps.background = matchingFrame?.large_image_url;
        // This isn't always passed, but at this point we know it, so let's add
        // it so it can be persisted later
        if (annotationProps.id) annotationProps.id.frameId = matchingFrame.id;
      } else {
        logInDevelopment(
          `could not find comment with Id ${openProps.commentId.commentId} or frame with id ${matchingComment?.frame_id}. Could be that the comment store isn't loaded`,
        );
        errorHandler({ severity: 'info', messageKey: null })(
          new Error(
            'Could not find matching comment and frame, aborting opening comment',
          ),
        );
      }
    }

    this.pendingAnnotation = annotationProps;
    this.commit();
  }

  commitPaused = false;
  /** Commit the actual changes. Throttled. Can be temporarily paused */
  private commit = throttle(
    () => retryWhile(() => !this.commitPaused, this.commitStep),
    100,
    { leading: false },
  );

  /** The actual commit logic */
  private commitStep = () => {
    if (!shallowEqual(this.pendingAnnotation, this.currentAnnotation)) {
      const oldAnnotation = this.currentAnnotation;
      this.currentAnnotation = this.pendingAnnotation;

      if (!this.currentAnnotation) {
        this.editorState = 'off';
        this.settings = undefined;
      } else {
        this.editorState = this.currentAnnotation?.interactive
          ? 'editing'
          : 'previewing';

        if (oldAnnotation && this.pendingAnnotation && this.engine) {
          this.engine.loadFile(this.pendingAnnotation);
        }
      }

      activeAnnotationLocalState.setValue(this.currentAnnotation?.id || null);

      this.emitChange();
    }
  };

  async handleClose(
    callback?: (result: CommentAnnotationData | undefined) => void,
  ) {
    let result: CommentAnnotationData | undefined = undefined;

    // Ideally this would happen in the commit step, but we don't have access to
    // the callback there.
    if (this.engine && this.engine.hasChanges && callback) {
      this.commitPaused = true;
      result = await this.engine.save();
    }

    // Depending on which action this is called by, the props passed might not
    // match our expectations (result might not be a function)
    if (isFunction(callback)) callback(result);

    this.pendingAnnotation = null;
    this.commitPaused = false;
    this.commit();
  }

  handleToggle(props: Parameters<typeof this.handleOpen>[0]) {
    if (
      this.currentAnnotation &&
      (this.currentAnnotation?.id?.commentId === props.commentId?.commentId ||
        // If it's null, it means we want to open an empty file
        (props.commentId === null && this.currentAnnotation.id === null))
    ) {
      // We don't need to deal with the result so we don't need to await
      this.handleClose();
    } else {
      this.handleOpen(props);
    }
  }

  // handleEngineLoad = () => this.loading
  private handleEngineOptionsEvent = (diff, newOptions) => {
    this.settings = newOptions;
    this.emitChange();
  };

  private handleEngineHistoryEvent = () => {
    this.canUndo = this.engine?.canUndo ?? false;
    this.canRedo = this.engine?.canRedo ?? false;
    this.emitChange();
  };

  handleRegisterEngine(engine: AnnotationToolEngine | null) {
    if (this.engine && engine) {
      logInDevelopment(
        'New engine was registered even though we still have another one!',
      );
    }

    // First, remove existing events if they're there
    if (this.engine) {
      this.engine.off('history', this.handleEngineHistoryEvent);
      this.engine.off('options', this.handleEngineOptionsEvent);
    }

    // Then attach new events
    if (engine) {
      this.settings = engine.options;
      engine.on('history', this.handleEngineHistoryEvent);
      engine.on('options', this.handleEngineOptionsEvent);
    }

    this.engine = engine;
    // Make sure we send this event again, it doesn't always arrive
    this.emitChange();
  }

  private calculateAvailableContexts() {
    this.isPreviewAvailable = some(this.contextsAvailable, (i) => i.preview);
    this.isEditorAvailable = some(this.contextsAvailable, (i) => i.editor);
  }

  handleAddContext(context: AnnotationsAvailabilityContext) {
    this.contextsAvailable.push(context);
    this.calculateAvailableContexts();
  }
  handleRemoveContext(context: AnnotationsAvailabilityContext) {
    this.contextsAvailable = without(this.contextsAvailable, context);
    this.calculateAvailableContexts();
  }

  handleCommentDelete: CommentStoreType['handleDelete'] = ({ id }) => {
    if (this.currentAnnotation?.id?.commentId === id) {
      this.handleClose();
    }
  };
}

(window as any).CommentAnnotationsStore = alt.createStore(
  CommentAnnotationsStore,
  'CommentAnnotationsStore',
);
