/** @prettier */
import type { editingTourAvailableSteps } from './editingTour';
import type { organisationTourAvailableSteps } from './organisationTour';
import type { exportTourAvailableSteps } from './exportTour';
import type { generatorTourAvailableSteps } from './generatorTour';
import type { wizardAddImageTourAvailableSteps } from './wizardAddImageTour';
import type { wizardCameraMovesTourAvailableSteps } from './wizardCameraMovesTour';
import type { wizardAnimaticTourAvailableSteps } from './wizardAnimaticTour';
import type { wizardInviteTourAvailableSteps } from './wizardInviteTour';
import type { wizardNewStoryboardTourAvailableSteps } from './wizardNewStoryboardTour';

/** A list of all events that tours could respond to */
export const tourEvents = {
  addTextToFrame: Symbol(),
  openFrameEditor: Symbol(),
  openCameraMoves: Symbol(),
  insertCameraMove: Symbol(),
  createFrameFromEditor: Symbol(),
  importFromImageLibrary: Symbol(),
  generatorFlyoverOpen: Symbol(),
  generatorPromptAdded: Symbol(),
  generatorStartClicked: Symbol(),
  openShapeMenu: Symbol(),
  addAShape: Symbol(),
  changeFillColor: Symbol(),
  addTextObject: Symbol(),
  changeFont: Symbol(),
  saveAndCloseFrameEditor: Symbol(),
  shotListOpened: Symbol(),
  openAnimatic: Symbol(),
  animaticAdjustTimeline: Symbol(),
  closeAnimatic: Symbol(),
  openShare: Symbol(),
  animaticPlay: Symbol(),
};

/** Contains the names of all the events tours could respond to */
export type availableTourEvents = keyof typeof tourEvents;

/**
 * We want to compose a list of all the 'steps' across all the tours that
 * TourHintable elements can respond to. We first have to compose the steps for
 * every tour, and we combine them here
 */
export type allTourSteps =
  | editingTourAvailableSteps
  | organisationTourAvailableSteps
  | exportTourAvailableSteps
  | generatorTourAvailableSteps
  | wizardCameraMovesTourAvailableSteps
  | wizardAnimaticTourAvailableSteps
  | wizardInviteTourAvailableSteps
  | wizardNewStoryboardTourAvailableSteps
  | wizardAddImageTourAvailableSteps;

export interface TourStepTokens {
  readonly name: string;
  readonly count: number;
}

export interface TourStep<Name = string> {
  readonly name: Name;
  readonly header?: string;
  readonly buttonText?: string;
  readonly body?: string;
  /**
   * An event that this step will wait on before advancing. This corresponds
   * to a value in the `tourEvents` object.
   */
  readonly waitsOn?: symbol;
  /** Shows the number of AI tokens a user gets for completing a given tour*/
  readonly tokens?: TourStepTokens;
}
export interface TourBlueprint<StepNames = string> {
  readonly name: string;
  /** Allow the tour to be completed more than once (i.e. for tokens) */
  readonly allowRestart?: boolean;
  readonly steps: Readonly<TourStep<StepNames>[]>;
  /**
   * Is the progress and completed state of this tour saved in localStorage?
   * this will also prevent the tour from being started again if it has been
   * completed */
  readonly persists?: boolean;
  readonly confetti?: boolean;
  readonly onComplete?: () => void;
}

/** The tour once it's active in the store */
export interface Tour extends TourBlueprint {
  currentStep: number;
}

export interface PersistedTour {
  name: string;
  currentStep: number;
}
