/** @prettier */
import {
  PageFormat,
  PDFLayoutProps,
  PDFRendererOutput,
  DocInfo,
} from '../types';
import { applyPDFWatermark } from './applyPDFWatermark';
import { copyPDFPage } from './copyPDFPage';
import { createPDFCoverPage } from './createPDFCoverPage';
import { getCurrentPageNumber } from './getCurrentPageNumber';
import { times } from 'underscore';
import { reorderPDFPages } from './reorderPDFPages';
import { RequestErrorHandler } from '../../../helpers/request-error-handler';

const errorHandler = RequestErrorHandler('finishPDF');

/**
 * Returns the finished PDF and its data. In the process it will delete the
 * initially generated empty first page and add covers
 */
export const finishPDF = async ({
  docInfo,
  props,
  callback,
  tooMuchText,
}: {
  docInfo: DocInfo;
  props: PDFLayoutProps;
  callback: (done: PDFRendererOutput) => void;
  tooMuchText: boolean;
}) => {
  const doc = docInfo.doc;
  // Keep track if we actually render the cover pages
  let hasFrontCover = false;
  let hasBackCover = false;

  // PDFjs automatically creates an initial page, but we want to
  // create the pages inside the createGridPage function, so we need
  // to delete the first empty page, because there  is no way of
  // preventing it's creation.
  // additionally, it's not zero-indexed? jfc
  doc.deletePage(1);

  // Add the covers in, but if they're pdfs we want to merge them in later
  if (props.frontCover && props.frontCover.type !== 'pdf') {
    // console.log('Adding front cover…');
    await createPDFCoverPage(docInfo, props.frontCover, props);
    if (props.isTrialPreview) await applyPDFWatermark(docInfo);
    doc.movePage(getCurrentPageNumber(doc), 1); // it's not zero-indexed
    hasFrontCover = true;
  }

  // Add the covers in, but if they're pdfs we want to merge them in later
  if (props.backCover && props.backCover.type !== 'pdf') {
    // console.log('Adding back cover…');
    await createPDFCoverPage(docInfo, props.backCover, props);
    if (props.isTrialPreview) await applyPDFWatermark(docInfo);
    hasBackCover = true;
  }

  let pageCount = getCurrentPageNumber(doc);
  let url: string;

  // If either of the covers are of the PDF type we'll need to merge those
  // documents with the document created with the PDF export tool
  // For that we need to create a new document, and we need to use another
  // library
  if (props.frontCover?.type === 'pdf' || props.backCover?.type === 'pdf') {
    const { PDFDocument } = await import('pdf-lib');

    // Load the original document (just the frames) and create a new PDFDocument
    const originalData = doc.output('dataurlstring').toString();
    const newDocument = await PDFDocument.load(originalData);

    /**
     * Keep track of what we want the order of the pages to be. There is no
     * way to insert pages at a specific point, so we have to move them later.
     * Here we're making an array consisting of the frame pages' indices.
     */
    const intendedPageOrder = times(newDocument.getPageCount(), (i) => i);

    // fetches the cover page, and copy it over to the new document
    if (props.frontCover?.type === 'pdf') {
      try {
        const frontCover = await copyPDFPage(props.frontCover.url, newDocument);
        newDocument.addPage(frontCover);
        // add the current page index to the front of the intended page order
        intendedPageOrder.unshift(newDocument.getPageCount() - 1);
        hasFrontCover = true;
      } catch (error) {
        errorHandler({
          messageKey: 'export.pdf.errors.frontCoverMerge',
          severity: 'warn',
          rollbarMessage: 'Could not merge front cover ',
        })(error);
      }
    }

    // fetches the cover page, and copy it over to the new document
    if (props.backCover?.type === 'pdf') {
      try {
        const backCover = await copyPDFPage(props.backCover.url, newDocument);
        newDocument.addPage(backCover);
        // add the current page index to the back of the intended page order
        intendedPageOrder.push(newDocument.getPageCount() - 1);
        hasBackCover = true;
      } catch (error) {
        errorHandler({
          messageKey: 'export.pdf.errors.backCoverMerge',
          severity: 'warn',
          rollbarMessage: 'Could not merge back cover ',
        })(error);
      }
    }

    // Now we want to reorder the pages so the front page is at the front
    reorderPDFPages(newDocument, intendedPageOrder);

    pageCount = newDocument.getPageCount();
    const arrayBuffer = await newDocument.save();

    // Prepare the saved PDF as a blob with a URL
    url = URL.createObjectURL(
      new Blob([arrayBuffer], { type: 'application/pdf' }),
    );
  } else {
    url = doc.output('bloburl').toString();
  }

  return callback({
    pageCount,
    url,
    extension: 'pdf',
    hasTooMuchText: tooMuchText,
    hasFrontCover,
    hasBackCover,
  });
};
