/** @format */

import Icon from '../icon/Icon';
import ProjectIcon from '../../images/icons/project.svg';
import React from 'react';
import classNames from 'classnames';
import { type SubProject } from './ShareableProjectContext';

interface ProjectSubProjectProps {
  project: SubProject;
}
export const ShareableSubProject: React.FC<ProjectSubProjectProps> = ({
  project,
}) => {
  return (
    <a
      className={classNames('rounded-lg p-1.5 cursor-pointer')}
      href={`/b/${project.share_slug}`}
    >
      <div className="p-4 pt-3 bg-white border border-border rounded-md group">
        <div className="flex items-center mb-0.5">
          <div className="flex-shrink-0 mr-1.5">
            <div className="flex items-center opacity-20 group-hover:opacity-50">
              <Icon
                icon={<ProjectIcon />}
                className="flex-shrink-0 h-5 mt-0.5"
                color="black"
              />
            </div>
          </div>
          <div className="flex-auto">
            <div className="inline-block text-base cursor-pointer group-hover:underline">
              {project.name}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};
