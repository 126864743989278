/** @format */
import React, { useState, useCallback } from 'react';
import {
  Trash2,
  EllipsisIcon,
  type LucideIcon,
  ArchiveRestore,
} from 'lucide-react';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import Panel from '../../panel/Panel';
import { ProjectsContext } from '../ProjectsContext';
import { RequestActions } from 'javascripts/flux/actions/request';

interface Action {
  label: string;
  icon: LucideIcon;
  onClick: () => void;
}

interface ArchivedItemActionsProps {
  itemId: number | string;
  itemType: 'project' | 'storyboard';
}

export const ArchivedItemActions: React.FC<ArchivedItemActionsProps> = ({
  itemId,
  itemType,
}) => {
  const { restoreProject, restoreStoryboard, deleteStoryboard } =
    React.useContext(ProjectsContext);
  const [selectedAction, setSelectedAction] = useState<Action | null>(null);

  const handleRestore = useCallback(() => {
    if (itemType === 'project') {
      restoreProject(itemId);
    } else {
      restoreStoryboard(itemId);
    }
    RequestActions.success.defer(
      `${itemType === 'project' ? 'Project' : 'Storyboard'} restored`,
    );
  }, [itemId, itemType, restoreProject, restoreStoryboard]);

  const handleDelete = useCallback(() => {
    const confirmMessage = `Are you sure you want to permanently delete this storyboard?`;
    if (confirm(confirmMessage)) {
      deleteStoryboard(itemId);
      RequestActions.success.defer('Storyboard deleted');
    }
  }, [itemId, deleteStoryboard]);

  const options: Action[] = [
    {
      label: 'Restore',
      icon: ArchiveRestore,
      onClick: handleRestore,
    },
  ];

  // Only add the delete option for storyboards
  if (itemType === 'storyboard') {
    options.push({
      label: 'Delete',
      icon: Trash2,
      onClick: handleDelete,
    });
  }

  const handleChange = useCallback((action: Action | null) => {
    setSelectedAction(action);
    if (action) {
      action.onClick();
    }
  }, []);

  return (
    <Listbox
      as="div"
      className="relative group/archiveditemactions"
      value={selectedAction}
      onChange={handleChange}
    >
      <Listbox.Button className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-white outline-none cursor-pointer rounded-md ring-none bg-opacity-80 hover:bg-opacity-100 transition-opacity">
        <EllipsisIcon className="w-4 h-4" />
      </Listbox.Button>
      <Listbox.Options
        as={Panel}
        className="absolute right-0 flex-col w-40 mt-2 border shadow-md rounded-md border-border top-full focus:outline-none"
      >
        <div className="p-2 space-y-1">
          {options.map((option) => (
            <Listbox.Option
              key={option.label}
              value={option}
              as="div"
              className={({ active }) =>
                classNames(
                  'cursor-pointer select-none relative pb-2 pt-1.5 px-2 rounded-md',
                  active ? 'bg-surface-light' : '',
                )
              }
            >
              {({ active }) => (
                <div className="flex items-center space-x-2">
                  <option.icon className="flex-shrink-0 h-4 w-4 pt-0.5" />
                  <span className="flex-auto text-sm">{option.label}</span>
                </div>
              )}
            </Listbox.Option>
          ))}
        </div>
      </Listbox.Options>
    </Listbox>
  );
};
