/** @prettier */
import classNames from 'classnames';
import React from 'react';
import { type GeneratorGuideline } from '../GeneratorContext';
import Img from 'javascripts/components/shared/Img';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';

interface GuidelineCharacterPreviewProps {
  character: GeneratorGuideline;
  hideName?: boolean;
}

export const GuidelineCharacterPreview: React.FC<
  GuidelineCharacterPreviewProps
> = ({ character, hideName }) => {
  return (
    <div className="flex items-center flex-auto ">
      <div className="relative flex-shrink-0 w-6 h-6 mr-2 overflow-hidden rounded-md ">
        <Tooltip
          title={character.name}
          disabled={!hideName}
          placement="top-start"
        >
          <span className="absolute -top-[0.5rem] w-20 -left-[1.75rem]">
            <Img
              className="mb-0 leading-none "
              small
              src={character.preview_thumbnail_url}
            />
          </span>
        </Tooltip>
      </div>

      {!hideName && (
        <div className={classNames('flex items-center group')}>
          <div
            className={classNames(
              'flex-auto text-base text-left whitespace-nowrap truncate ',
            )}
          >{`${character.name}`}</div>
        </div>
      )}
    </div>
  );
};
