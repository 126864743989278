/** @prettier */
import * as React from 'react';
import Container from '../shared/Container';
import { BannerContents } from './BannerContents';
import 'javascripts/flux/stores/banner';
import {
  sunsetPendingNotificationLocalState,
  sunsetNotificationLocalState,
  annualUpgradeDismissedLocalState,
  preSignupInvitationDismissedLocalState,
} from 'javascripts/helpers/local-state';
import { BannerActions } from 'javascripts/flux/actions/banner';

(window as any).BannerContainer = Container(
  'banner',
  (storeData, ownProps) => ({
    onMount: () => {
      if (
        sunsetNotificationLocalState.getValue() &&
        ownProps.type === 'sunset'
      ) {
        return null;
      } else if (
        annualUpgradeDismissedLocalState.getValue() &&
        ownProps.type === 'annual_upgrade'
      ) {
        return null;
      } else if (
        sunsetPendingNotificationLocalState.getValue() &&
        ownProps.type === 'sunset_pending'
      ) {
        return null;
      } else if (
        preSignupInvitationDismissedLocalState.getValue() &&
        ownProps.type === 'pre_signup_invitation'
      ) {
        return null;
      } else {
        // It's fine if ownProps is empty or type is null, we have to do
        // preparations anyway
        return BannerActions.init.defer(ownProps as any);
      }
    },
  }),
)((props) =>
  props.activeBanner ? (
    <div className="fixed top-0 left-0 right-0 hidden sm:block bannerContainer z-banner">
      <BannerContents
        type={props.activeBanner.type}
        data={props.activeBanner.data}
      />
    </div>
  ) : null,
);
