/** @prettier */
import {
  FontSettings,
  FontSettingsVariations,
  FontSettingsAfterFetch,
} from '../types';
import { isString } from 'underscore';
import * as memoize from 'memoizee';
import { mapValues } from 'async';
import { toSlug } from '../../../helpers/to-slug';

export const fetchPDFJSFont = memoize(
  (settings: FontSettings, variants?: Array<keyof FontSettingsVariations>) =>
    new Promise<FontSettingsAfterFetch | undefined>((resolve, reject) => {
      console.log(
        variants
          ? `fetching ${variants.length} variants of ${settings.family}`
          : `fetching all font variants of ${settings.family}`,
      );

      mapValues(
        settings as any,
        (value, key: any, done) => {
          const shouldLoadVariant = !variants || variants.indexOf(key) >= 0;
          if (!shouldLoadVariant) return done(null, undefined);

          if (
            isString(value) &&
            (value.indexOf('http') === 0 || value.indexOf('//') === 0)
          ) {
            fetch(value)
              .then(response => response.blob())
              .then(blob => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  const result = reader.result as string;
                  done(null, result.substr(result.indexOf('base64,') + 7));
                };
                reader.onerror = err => {
                  console.log(err);
                  done(new Error('Could not read font with url ' + value));
                };
                reader.readAsDataURL(blob);
              })
              .catch(err => done(err));
          } else {
            done(null, value);
          }
        },
        (err, result) => {
          if (err) return reject(err);
          result.fetched = true;
          result.slug = toSlug(settings.family);
          resolve(result as any);
        },
      );
    }),
  {
    promise: true,
    length: 2,
    max: 50,
  },
);
