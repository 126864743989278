/** @format */

import React, { useState, useEffect } from 'react';

export const GeneratorTimer: React.FC = () => {
  const [elapsedTime, setElapsedTime] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 0.1);
    }, 100); // 0.1 seconds interval

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return <>{`${elapsedTime.toFixed(1)}s`}</>;
};
