/** @prettier */
import * as React from 'react';

const defaultSeparator = <div className="w-px bg-border flex-shrink-0 h-6" />;
export const SingleSeparator = () => defaultSeparator;

/** Adds a separator between every child of this component */
export const WithSeparator: React.FC<{ separator?: React.ReactNode }> = (
  props,
) => {
  const separator = props.separator ?? defaultSeparator;
  const children = React.Children.toArray(props.children).filter(Boolean);

  return (
    <>
      {children.map((c, ci) => (
        <React.Fragment key={ci}>
          {c}
          {ci < children.length - 1 && separator}
        </React.Fragment>
      ))}
    </>
  );
};
