/** @prettier **/
import type { CanvasScrubberRenderer } from './CanvasScrubberRenderer';
import { pixelRound } from '../../../helpers/pixel-round';
import type { FabricObject } from 'javascripts/components/frame_editor/types';
import { clampNumber } from 'javascripts/helpers/clampNumber';

interface FrameIndicatorProps {
  frameId: number;
  time: number;
  sizeModifier?: number;
  onTimeChange: (props: { time: number; frameId: number; left }) => void;
  onMoveComplete: () => void;
  siblingTimes: [number, number];
  top: number;
  isMovable: boolean;
  hidden: boolean;
}

export type FrameIndicator = FabricObject & {
  time: number;
  setActive: (active: boolean) => void;
  setSizeModifier: (modifier: number) => void;
};

export type newFrameIndicatorFunc = (
  props: FrameIndicatorProps,
) => FrameIndicator;

/**
 * Because we are loading fabric asynchronously, we can't directly require
 * it in the scrubberRenderer, so in order to extract this class, we export
 * not the class itself, but a function that creates the class within the
 * fabric instance supplied.
 */
export const FrameIndicatorFactory = (
  fabric,
  renderer: CanvasScrubberRenderer,
): newFrameIndicatorFunc => {
  const klass = fabric.util.createClass(fabric.Circle, {
    initialize: function ({
      frameId,
      time,
      onTimeChange,
      onMoveComplete,
      siblingTimes,
      top,
      hidden,
      sizeModifier = 1,
    }: FrameIndicatorProps) {
      this.baseRadius = renderer.dimensions.indicatorHeight / 2;
      this.sizeModifier = sizeModifier;
      this.time = time;
      this.fill = renderer.colors.scrubberDot;
      this.baseTop = pixelRound(top);
      this.top = this.baseTop;
      this.onTimeChange = onTimeChange;
      this.onMoveComplete = onMoveComplete;
      this.isActiveFrame = renderer.currentFrameId === this.frameId;
      this.siblingTimes = siblingTimes;
      this.setPosition();
      if (hidden) return;

      this.on('mouseover', this.handleMouseOver);
      this.on('moving', this.handleMove);
      this.on('modified', this.handleMoveComplete);
      this.on('removed', this.handleRemove);

      this.callSuper('initialize', {
        originX: 'center',
        originY: 'center',
        // A reference to the frame id that this dot represents
        frameId: frameId,
        radius: this.getRadius(),
        hasControls: false,
        hasBorders: false,
        lockMovementY: true,
        padding: 5,
        cursor: '',
        hoverCursor: 'default',
        moveCursor: 'ew-resize',
        strokeWidth: 0,
        selectable: false,
        isFrameIndicator: true,
      });
      this.setMovable(true);
    },
    getRadius: function () {
      return this.baseRadius * this.sizeModifier;
    },
    setSizeModifier(newModifier) {
      this.sizeModifier = newModifier;
      this.set('radius', this.getRadius());
    },
    setMovable: function (isMovable) {
      this.set('selectable', isMovable);
      this.set('hoverCursor', isMovable ? 'ew-resize' : 'default');
      this.isMovable = isMovable;
      if (!isMovable) this.revertStyle();
    },
    setPosition: function () {
      this.left = renderer.timeToPosition(this.time);
      this.fill = this.getFillStyle();
    },
    setActive: function (isActive) {
      if (this.isActiveFrame === isActive) return;
      this.isActiveFrame = isActive;
      this.set('fill', this.getFillStyle());
    },
    _render: function (ctx) {
      this.setPosition();
      // A position might be out of bounds, so we don't render this dot
      if (this.left === null) return;

      this.setCoords();
      this.callSuper('_render', ctx);
    },
    getFillStyle: function (inactiveColor = renderer.colors.scrubberDot) {
      return this.frameId === renderer.currentFrameId
        ? '#000000'
        : inactiveColor;
    },
    handleMouseOver: function ({ target }: { target: FabricObject }) {
      if (!target) return;
      target.set('fill', '#666666');
      target.animate('radius', pixelRound(this.getRadius()), {
        onChange: renderer.rerender,
        duration: 100,
      });

      this.on('mouseout', this.revertStyle);
    },

    // called when mouseout
    revertStyle: function () {
      this.set('fill', this.getFillStyle());
      this.animate('radius', this.getRadius(), {
        onChange: renderer.rerender,
        duration: 100,
      });
    },

    handleRemove: function () {},

    /** Update the position and `time` value based on a new time value */
    setTime: function (draggedTime) {
      // Make sure the frame isn't too close to its neighbouring frames
      const newTime = clampNumber(
        draggedTime,
        this.siblingTimes[0] + renderer.durationOptions.min,
        this.siblingTimes[1] - renderer.durationOptions.min,
      );

      const duration = this.siblingTimes[1] - newTime;
      // The duration shouldn't exceed the maximum, so we see if
      // there's any adjustments to make
      const adjustment = Math.max(duration - renderer.durationOptions.max, 0);
      this.time = Math.round(newTime + adjustment);
      this.left = renderer.progressToPosition(this.time / renderer.endTime);
    },

    /**
     * Sets the new position and updates the main scrubber with the indicator's
     * new time
     */
    handleMove: function ({ transform }) {
      this.setTime(
        renderer.positionToProgress(transform.target.left + this.radius / 2) *
          renderer.endTime,
      );
      const onTimeChange: FrameIndicatorProps['onTimeChange'] =
        this.onTimeChange;
      onTimeChange({
        frameId: this.frameId,
        time: this.time,
        left: transform.target.left,
      });
    },

    handleMoveComplete: function (e) {
      if (e.action === 'drag') this.onMoveComplete();
    },
  });

  return (props: FrameIndicatorProps) => new klass(props);
};
