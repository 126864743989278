/** @prettier */

import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import * as React from 'react';
import { type Icon } from './IconFinderContainer';

interface IconFinderSearchInputProps {
  searchString: string;
  submittedSearchString: string;
  isSearching: boolean;
  RunSearch: (searchString: string, page?: number) => Promise<void>;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  setIcons: React.Dispatch<React.SetStateAction<Icon[] | undefined>>;
  setSubmittedSearchString: React.Dispatch<React.SetStateAction<string>>;
}

export const IconFinderSearchInput: React.FC<IconFinderSearchInputProps> = ({
  isSearching,
  searchString,
  setIcons,
  RunSearch,
  setSearchString,
  submittedSearchString,
  setSubmittedSearchString,
}) => {
  return (
    <div className="relative">
      {searchString && !isSearching && (
        <div className="absolute z-10 top-1 right-1">
          {searchString === submittedSearchString ? (
            <Button
              type="secondary"
              size="sm"
              onClick={() => {
                setSearchString('');
                setSubmittedSearchString('');
                setIcons(undefined);
              }}
            >
              {`Clear`}
            </Button>
          ) : (
            <Button
              type="solid"
              size="sm"
              disabled={isSearching}
              onClick={() => RunSearch(searchString)}
            >
              {`Search`}
            </Button>
          )}
        </div>
      )}

      <TextInput
        placeholder={`Search Icons...`}
        inputSize="md"
        disabled={isSearching}
        autoFocus
        onKeyDown={(e) => {
          if (e.key === 'Enter' && searchString) {
            RunSearch(searchString);
          }
        }}
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
      />
    </div>
  );
};
