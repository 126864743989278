/** @prettier */
import * as React from 'react';
import Container from '../shared/Container';
import ToggleButtonWithLabel from '../shared/ToggleButtonWithLabel';
import { UserActions } from '../../flux/actions/user';
import { type UserResponse } from 'javascripts/types/user';
import { useTranslation } from 'react-i18next';
import logger from 'javascripts/helpers/logger';

interface IProps {
  user: UserResponse;
  isFetching: boolean;
  handleUpdate: (newValue: boolean) => void;
}

const GeneratorModelToggle = Container('user', () => ({
  onMount: UserActions.fetch.defer,
  handleUpdate: (newValue: boolean) => {
    UserActions.updateFields({
      ai_image_model: newValue ? 'core' : 'legacy',
    });
  },
}))((props: IProps) => {
  const { t } = useTranslation('generatorCopy');
  return (
    <ToggleButtonWithLabel
      onChange={props.handleUpdate}
      value={props.user?.ai_image_model === 'core'}
      disabled={props.isFetching}
      labelPosition={`after`}
      label={
        <div className="flex items-center space-x-2">
          <span>{t('modelToggleLabel')}</span>
        </div>
      }
    />
  );
});

(window as any).GeneratorModelToggle = GeneratorModelToggle;
