const _ = require('underscore');
const { default: Toggle } = require('blackbird/components/toggle/Toggle');
const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
const { Trans,withTranslation } = require('react-i18next');
const PanelbarCommentsUserNotifications= createReactClass({

  propTypes:        {
    storyboard_id:  PropTypes.number.isRequired,
  },

  getInitialState: function() {
    return UserStoryboardNotificationStore.getState();
  },

  componentDidMount: function() {
    UserStoryboardNotificationStore.listen(this._onChange);
  },

  componentWillUnmount: function() {
    UserStoryboardNotificationStore.unlisten(this._onChange);
  },

  _isChecked: function() {
    return !_.contains(this.state.user.no_notification_for_storyboard_ids, this.props.storyboard_id);
  },

  _onChange: function(state) {
    this.setState(state);
  },

  _toggle: function(e) {
    UserStoryboardNotificationActions.update({
      storyboard_id:  this.props.storyboard_id,
      send_emails:    !this._isChecked(),
    });
  },

  render: function() {
    const {t} = this.props;
    if (_.isNull(this.state.user)) {
      return null;
    }

    if (!this.state.user.is_receiving_comment_notifications) {
      return (
        <div className="lh-copy text-sm f5 mb4 br2">
          <span>{t("sidebar.settings.comments.turn_off_comments_email",{email:this.state.user.email})}</span>
          <Trans i18nKey="sidebar.settings.comments.receive_email">
          To receive emails when new comments are added, visit your <a className='underline' href='/settings/account/notification'>account settings page</a>.
          </Trans>
        </div>
      )
    } else {

      return (
        <>
          <div className="text-sm flex items-center justify-between bt bw2px b--light-grey f5">
            <div className="flex items-center">
              {t("sidebar.settings.comments.personal_notifications")}&nbsp;
              <HelpPopup
                text={`Receive email notifications at ${this.state.user.email} when new comments are added to this storyboard`}
              />
            </div>
            <Toggle
              onChange={this._toggle}
              value={this._isChecked()}
            />
          </div>
          <div className="text-type-subdued text-sm">
            <Trans i18nKey="sidebar.settings.comments.turn_off_comments">
              Turn off all comment emails in your <a className="underline" href="/settings/account/notification">account</a>
            </Trans>
          </div>
        </>
      );
    }
  }
});
window.PanelbarCommentsUserNotifications =withTranslation()(PanelbarCommentsUserNotifications);
