/** @format */

import { fabric } from 'fabric';
import type { frameAspectRatio } from 'javascripts/types/storyboard';
import type { timeout } from 'blackbird/helpers/types';
import type { base64String, WidthAndHeight } from 'javascripts/types/frame';
import type { UndoContext } from 'javascripts/helpers/undo-stack';
import type {
  FabricCanvas,
  FabricObject,
} from 'javascripts/components/frame_editor/types';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { FabricOriginalEraserBrush } from 'javascripts/components/frame_editor/shapes';
import { isEmpty } from 'underscore';
import logger from 'javascripts/helpers/logger';
import { PSBrush } from '@arch-inc/fabricjs-psbrush';
import EventEmitter from 'eventemitter3';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import type { PossibleCommentAnnotation } from 'javascripts/flux/stores/comment';
import { detect } from 'detect-browser';

/** A basic class for floating drawing tools */
export abstract class FrameDrawingEngine<
  Options = Record<string, unknown>,
  outputFormat = base64String,
> extends EventEmitter {
  protected undoContext?: UndoContext;
  // In case we want to switch it out at some point
  protected canvasClass: FabricCanvas = fabric.Canvas;
  protected targetOpacity = 1;
  protected outputFormat = 'jpg';
  protected outputQuality = 0.9;

  protected canvas?: FabricCanvas;
  private element: HTMLCanvasElement;
  private containerElement: HTMLElement;
  protected abstract defaultOptions: Partial<Options>;
  protected abstract name: string;
  protected errorHandler: ReturnType<typeof RequestErrorHandler>;

  options: Options;

  protected isAltPressed = false;
  protected isShiftPressed = false;

  /** mark from what point in the history we should consider the editor to be in
   * a "dirty"/unsaved state. */
  historyStartingPoint = 0;

  /** internal method for interpreting options. You probably don't want to call
   * this directly, use `updateOptions` instead, which will notify subscribers */
  protected abstract setOptions(options: Partial<Options> | undefined): void;
  // TODO: I could allow the user to make an object with keys from the props
  // object that fires handlers for each prop that has actually changed

  /** Process options coming from external components */
  receiveOptions(options: Partial<Options> | undefined) {
    if (!options || isEmpty(options)) return;
    this.updateOptions(options);
  }

  /** For updates that should be propagated to other components */
  protected updateOptions(options: Partial<Options>) {
    if (!options || isEmpty(options)) return;
    this.setOptions(options);
    this.emit('options', options, this.options);
  }

  protected baseBrush: any;
  protected eraserBrush: any;
  /** The element referring to fabric's outer most element, type depends on `this.canvasClass` */
  private wrapperEl: HTMLCanvasElement | HTMLDivElement;
  /** Temporarily disable creation of history items, for example during canvas
   * clearing in `canvas.loadFromJSON`. See {@link updateHistory} for more
   * info */
  private disableNewHistoryItems = false;

  /** if the user is in the process of drawing something */
  isDrawing = false;

  protected nativeResolution: {
    width: number;
    height: number;
  };

  protected dimensions: WidthAndHeight;

  constructor(
    containerElement: HTMLElement,
    frameAspectRatio: frameAspectRatio,
    dimensions: WidthAndHeight,
  ) {
    super();
    const element = (this.element = document.createElement('canvas'));
    this.containerElement = containerElement;
    containerElement.appendChild(element);
    this.nativeResolution = BoordsFrameSizeHelper(frameAspectRatio);
    this.dimensions = dimensions;

    this.updateLoadingState(false);
  }

  updateLoadingState(newState: boolean) {
    this.emit('load', newState);
  }

  async init(
    withOptions?: Partial<Options>,
    withData?: PossibleCommentAnnotation,
  ): Promise<FabricCanvas> {
    this.errorHandler = RequestErrorHandler(this.name);
    const { width, height } = this.dimensions;
    this.canvas = new this.canvasClass(this.element, {
      width,
      height,
      enablePointerEvents: true,
    });
    const scale = width / this.nativeResolution.width;
    this.canvas.setViewportTransform([scale, 0, 0, scale, 0, 0]);

    this.wrapperEl = this.canvas.wrapperEl ?? this.canvas.lowerCanvasEl;
    this.wrapperEl.style.transition = '300ms ease-in-out opacity';

    this.baseBrush = new PSBrush(this.canvas);
    this.baseBrush.pressureManager.fallback = 1;
    this.eraserBrush = new FabricOriginalEraserBrush(this.canvas);
    this.canvas.freeDrawingBrush = this.baseBrush;

    if (
      process.env.NODE_ENV === 'development' &&
      !this.undoContext &&
      this.isInteractiveCanvas
    ) {
      logger.log('DrawingEngine was initialised without an undo stack');
    }

    const addHistoryItem = this.addHistoryItem.bind(this);
    window.addEventListener('keyup', this.onKeyEvent);
    window.addEventListener('keydown', this.onKeyEvent);

    this.canvas
      .on('path:created', addHistoryItem)
      .on('object:modified', addHistoryItem)
      .on('object:removed', addHistoryItem)
      .on('mouse:down', this.onMouseEvent.bind(this, 'mouseDown'))
      .on('mouse:up', this.onMouseEvent.bind(this, 'mouseUp'));

    this.undoContext?.enterContext(this.updateHistory.bind(this));
    this.undoContext?.clearStack();
    this.updateOptions({ ...this.defaultOptions, ...withOptions });
    await this.loadLayerData(withData);
    await this.beforeReady(this.canvas);
    this.addHistoryItem();

    // Prevent an error if we've unmounted by now
    this.canvas?.requestRenderAll();
    this.updateLoadingState(false);
    return this.canvas;
  }

  /** define this function to do additional work on the canvas before we do the
   * first render and mark loading as completed */
  abstract beforeReady(canvas: FabricCanvas): Promise<void> | undefined;

  unmount(removeListeners = true) {
    window.removeEventListener('keyup', this.onKeyEvent);
    window.removeEventListener('keydown', this.onKeyEvent);
    this.undoContext?.clearStack();
    this.undoContext?.leaveContext();
    clearTimeout(this.displaySizeTimeout);

    // If we're completely removing the engine, we want to also remove the
    // eventemitter listeners. But sometimes we're just reloading fabric and not
    // removing the engine, in that case, we want to keep them around
    if (removeListeners) this.removeAllListeners();

    try {
      this.canvas.dispose();
    } catch (e) {
      // console.warn('Could not clean up frame editor properly', e);
    }
    delete this.canvas;
  }

  onMouseEvent(type: 'mouseDown' | 'mouseUp') {
    this.isDrawing = this.canvas.isDrawingMode && type === 'mouseDown';

    if (!this.isDrawing && this.shouldResizeAfterDrawingFinishes) {
      this.setDisplaySize();
    }
  }

  get isInteractiveCanvas() {
    return this.canvasClass === fabric.Canvas;
  }

  loadLayerData(data?: PossibleCommentAnnotation) {
    return new Promise<void>((resolve) => {
      if (data) {
        this.canvas.loadFromJSON(data, () => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  hide(node: HTMLElement = this.wrapperEl) {
    node.style.position = 'absolute';
    node.style.opacity = '0';
  }

  show(node: HTMLElement = this.wrapperEl) {
    node.style.position = 'auto';
    node.style.opacity = String(this.targetOpacity);
  }

  displaySizeTimeout: timeout;
  /** Do we have a `setDisplaySize` queued up? */
  shouldResizeAfterDrawingFinishes = false;

  resize(sizes: WidthAndHeight) {
    this.dimensions = sizes;
    if (this.isDrawing) {
      this.shouldResizeAfterDrawingFinishes = true;
      logger.log('waiting for drawing to finish before we resize');
    } else {
      this.setDisplaySize();
    }
  }

  private setDisplaySize() {
    this.shouldResizeAfterDrawingFinishes = false;
    const sizes = this.dimensions;
    const scale = sizes.width / this.nativeResolution.width;
    this.canvas.setDimensions({
      width: sizes.width,
      height: sizes.height,
    });
    this.canvas.setViewportTransform([scale, 0, 0, scale, 0, 0]);
    this.canvas.requestRenderAll();
  }

  private getHistoryState() {
    return this.canvas.toObject();
  }

  protected addHistoryItem() {
    // since Fabric 4.6, object events (e.g. `object:removed`, `path:created`)
    // will be called when we call `canvas.loadFromJSON`. These events are
    // normally used to create history items, but we don't actually want to
    // add to the history when performing an undo, so we have to ignore them.
    if (this.disableNewHistoryItems) return;

    this.undoContext?.add(this.getHistoryState());
    this.emit('history');
  }

  private updateHistory(newHistory) {
    this.disableNewHistoryItems = true;
    return new Promise<void>((resolve) => {
      this.canvas.loadFromJSON(newHistory, () => {
        this.canvas.requestRenderAll();
        this.emit('history');
        resolve();
        this.disableNewHistoryItems = false;
      });
    });
  }

  onBeforeSave() {}

  get hasChanges() {
    if (!this.isInteractiveCanvas) return false;
    if (!this.undoContext) throw new Error(`feature requires an undoContext`);
    return this.undoContext.getCurrentIndex() > this.historyStartingPoint;
  }

  /** Saves the content of the canvas in a format useful for masking */
  save() {
    this.onBeforeSave?.();

    return new Promise<outputFormat>((resolve) => {
      const previousZoom = this.canvas.getZoom();
      const originalState = this.getHistoryState();

      this.canvas.forEachObject((o: FabricObject) => {
        if (o.type === 'PSStroke') {
          o.set('stroke', 'black');
        } else {
          // Delete the placeholder text
          this.canvas.remove(o);
        }
      });

      const options = Object.assign(
        {
          format: this.outputFormat,
          quality: this.outputQuality,
          multiplier: 1,
        },
        this.nativeResolution,
        {},
      );

      this.canvas.setZoom(1);
      const dataURL = this.canvas.toDataURL(options);
      this.canvas.setZoom(previousZoom);

      this.canvas.loadFromJSON(originalState, () => {
        this.canvas.requestRenderAll();
        resolve(dataURL);
      });

      this.updateHistoryStartingPoint();
    });
  }

  protected loadExternalImage(url: string) {
    return new Promise<undefined | any>((resolve) => {
      const imageUrl = new URL(url);
      imageUrl.protocol = 'https';
      imageUrl.href = imageUrl.href.replace(',compress:true', '');

      fabric.Image.fromURL(
        imageUrl.href,
        (image, isError) => {
          if (isError)
            return this.handleImageError(
              'Boords could not load the background',
              imageUrl.href,
              () => resolve(undefined),
            );

          resolve(image);
        },
        { crossOrigin: 'Anonymous' },
      );
    });
  }

  updateHistoryStartingPoint() {
    if (this.undoContext)
      this.historyStartingPoint = this.undoContext.getCurrentIndex();
  }

  onKeyEvent = (e) => {
    if (e.target?.isContentEditable) return;

    if (e.key === 'Alt') {
      this.isAltPressed = e.type !== 'keyup';
    } else if (e.key === 'Shift') {
      this.isShiftPressed = e.type !== 'keyup';
    } else if (
      e.key === 'Delete' ||
      (detect()?.os === 'Mac OS' && e.key === 'Backspace')
    ) {
      this.delete();
    }
  };

  undo = () => {
    this.undoContext?.navigate(-1);
  };
  redo = () => {
    this.undoContext?.navigate(1);
  };

  get canUndo() {
    return this.undoContext?.canUndo() ?? false;
  }
  get canRedo() {
    return this.undoContext?.canRedo() ?? false;
  }

  get isEmpty() {
    return this.canvas._objects.length === 0;
  }

  protected handleImageError(
    message: string,
    url?: string,
    callback?: () => void,
  ) {
    this.errorHandler(
      {
        message: message,
        rollbarMessage: 'Could not load image',
        reason: 0,
        askUserToRetry: false,
      },
      () => {
        this.emit('error', 'could not load image');
        callback?.();
      },
    )({ url });
  }

  delete() {
    const active = this.canvas.getActiveObject();
    if (!active) return;

    if (active.type === 'activeSelection') {
      active.forEachObject((i) => {
        active.removeWithUpdate(i);
        this.canvas.remove(i);
      });
      active.setCoords();
      // Deselect, so we notify any listeners (like the context menu)
      this.canvas.discardActiveObject();
    }

    this.canvas.remove(active);

    // We don't need to call addHistoryItem here, because we already
    // listen to object:removed
    this.canvas.requestRenderAll();
  }

  /** Makes the canvas and objects contained on it selectable or not */
  protected setSelectable(isSelectable: boolean) {
    this.canvas.selection = isSelectable;
    this.canvas.forEachObject(
      (o: FabricObject) => (o.selectable = isSelectable),
    );
  }
}
