(() => {
  class ConfirmationStore {

    constructor() {
      this.bindListeners({
        handleResend:  ConfirmationActions.RESEND,
      });
    }

    handleResend(id) {
      ajaxHelper.ajax({
        method: "put",
        url: "/resend_confirmation/" + id + ".json",
        success: function (response) {
          FlyoverActions.close.defer();
          RequestActions.success.defer(response.message);
        }.bind(this),
        error: function(response) {
          RequestActions.error.defer("Sorry, we couldn't complete that task");
        }.bind(this),
      });
    }
  }

  window.ConfirmationStore = alt.createStore(ConfirmationStore, 'ConfirmationStore');

})();
