const _ = require('underscore');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
window.ProjectTeammateAccessToggle = createReactClass({

  propTypes:       {
    team:          PropTypes.object.isRequired,
    member:        PropTypes.object.isRequired,
    project:       PropTypes.object.isRequired,
    labelType:     PropTypes.oneOf(['projectName', 'user']).isRequired,
    showRole:      PropTypes.bool,
  },

  render() {

    var member = this.props.member;
    var className;
    var label;

    // --------------------------------------------------
    // Decide what to use as the label

    if (this.props.labelType === 'projectName') {

      className = "list__item__table__row";
      label     = this.props.project.name;

    } else {

      className = "flex items-center justify-center border-b border-b-border py-3 w-full last:border-b-0";
      label     = <TeammateAvatarName member={member} showRole={this.props.showRole} />;

    }

    const canAccess = (
      member.can_access_all_projects
        || _.contains(member.projects_with_access, this.props.project.slug)
    )

    return (
      <div className={className}>
        <div className="flex-auto">
          {label}
        </div>
        <div className="mr1">
          <ProjectTeammateAccessToggleButton
            teamId={this.props.team.id}
            projectId={this.props.project.id}
            canAccess={canAccess}
            membershipId={this.props.member.membership_id}
          />
        </div>
      </div>
    );

  }

});

