const _ = require('underscore');
const can = require("../../helpers/restricted-actions")();
const { setRollbarContext } = require('../../helpers/rollbar')
require('../actions/flyover');
require('../actions/hoverOverlay');
require('../actions/user');

(() => {
  class FlyoverStore {
    constructor() {

      this.frame     = null;
      this.open      = false;
      this.projectId = null;
      this.plan_name = null;
      this.projects  = [];
      this.team      = null;
      this.teamId    = null;
      this.type      = '';
      this.user      = false;

      this.bindListeners({
        handleClose:    FlyoverActions.CLOSE,
        handleOpen:     FlyoverActions.OPEN,
        handleReceive:  UserActions.RECEIVE,
      });
    }

    handleReceive(user) {
      this.user = user;
      this.emitChange();
    }

    handleOpen(data) {

      if (can.access(data) === false) {

        this.emitChange();
        return false;

      } else if (data.type === "newProject") {

        this.teamId = data.teamId;
        this.emitChange();

        PermissionActions.verifyProject.defer({
          team_id:    data.teamId,
          onSuccess:  function() {
            this._openFlyover(data);
            this.emitChange();
          }.bind(this),
          onError:   function() {
            FlyoverActions.open.defer({
              type: "recommendations"
            });
          }.bind(this),
        });

      } else if (data.type === "newStoryboard") {

        this.projectId = data.projectId;
        this.emitChange();

        PermissionActions.verifyStoryboard.defer({
          project_id: data.projectId,
          onSuccess: function() {
            this._openFlyover(data);
            this.emitChange();
          }.bind(this),
        });

      } else {
        this._openFlyover(data);
        HoverOverlayActions.closeAll.defer();
      }
    }

    _openFlyover(data) {
      setRollbarContext('Flyover: ' + data.type);
      this.open        = true;
      this.type        = data.type;
      this.plan_name   = data.plan_name || null;
      this.teamId      = data.teamId || this.teamId;
      this.projectId   = data.projectId || null;
      this.projectName = data.projectName || null;
      this.storyboard  = data.storyboard || null;
      this.team        = data.team || null;
      this.frame       = data.frame || null;
      this.index       = !_.isUndefined(data.index) ? data.index : null;
      this.props       = data.props || {};
    }

    handleClose(options) {
      this.open = false;
      this.type = '';

      setRollbarContext(null);
    }

  }

  window.FlyoverStore = alt.createStore(FlyoverStore, 'FlyoverStore');

})();
