/** @format */
import * as cdnUrl from '../../helpers/cdn-url';
import { mapObject } from 'underscore';
import type { AspectRatioName, frameAspectRatio } from '../../types/storyboard';
import { frameAspectRatioNormalizer } from 'javascripts/helpers/frame-size-helper';

type ImagesForOverlay = {
  [key in AspectRatioName]: string;
};

/**
 * An overlay that can be applied to frames in the frame editor,
 * used to indicate things like camera movements or transitions
 */
export interface IFrameOverlay {
  key: string;
  /** URL to preview image */
  preview: string;
  images: ImagesForOverlay;
}

// Depending on CDN etc
const prefix = (string) => cdnUrl(`/assets/frame-overlays/${string}`);

const imagesFor = (fileNamePart: string): ImagesForOverlay =>
  mapObject(
    {
      square: `1x1-${fileNamePart}.svg`,
      portrait: `9x16-${fileNamePart}.svg`,
      landscape: `16x9-${fileNamePart}.svg`,
      social: `social-${fileNamePart}.svg`,
      tv: `tv-${fileNamePart}.svg`,
      widescreen: `widescreen-${fileNamePart}.svg`,
      anamorphic: `anamorphic-${fileNamePart}.svg`,
    },
    prefix,
  );

export const frameOverlays: IFrameOverlay[] = [
  {
    key: 'zoomOut',
    preview: prefix('preview-zoom-out.jpg'),
    images: imagesFor('zoom-out'),
  },
  {
    key: 'zoomIn',
    preview: prefix('preview-zoom-in.jpg'),
    images: imagesFor('zoom-in'),
  },
  {
    key: 'tiltUp',
    preview: prefix('preview-tilt-up.jpg'),
    images: imagesFor('tilt-up'),
  },
  {
    key: 'tiltDown',
    preview: prefix('preview-tilt-down.jpg'),
    images: imagesFor('tilt-down'),
  },
  {
    key: 'panRight',
    preview: prefix('preview-pan-right.jpg'),
    images: imagesFor('pan-right'),
  },
  {
    key: 'panLeft',
    preview: prefix('preview-pan-left.jpg'),
    images: imagesFor('pan-left'),
  },
];

export const overlayImageForAspectRatio = (
  overlay: IFrameOverlay,
  aspectRatio: frameAspectRatio,
) => {
  const overlayImagePath =
    overlay.images[frameAspectRatioNormalizer(aspectRatio)];

  if (!overlayImagePath) {
    throw new Error(
      `could not find the overlay file for "${overlay.key}" with aspect ratio ${aspectRatio}`,
    );
  }

  return overlayImagePath;
};
