/** @prettier */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const { ErrorBoundary } = require('../shared/ErrorBoundary');
const { IFPermission } = require('../../helpers/has-permission');
const {
  DefaultStoreContextProvider,
} = require('../../flux/DefaultStoreContextProvider');
const {
  ShareablePasswordProtected,
} = require('./ShareablePasswordProtected.react');
const {
  default: DialogContextProvider,
} = require('blackbird/components/dialog/DialogContext');

window.ShareableLanding = createReactClass({
  propTypes: {
    canManage: PropTypes.bool.isRequired,
    storyboardHashid: PropTypes.string.isRequired,
    storyboard_id: PropTypes.number.isRequired,
    project_id: PropTypes.number.isRequired,
    storyboard_owner_id: PropTypes.number.isRequired,
  },

  getInitialState: function () {
    return {
      is_locked: this.props.locked,
    };
  },

  componentDidMount: function () {
    ShareablePasswordStore.listen(this._onChange);
    if (this.props.locked) {
      ShareableActions.checkForPreviousUnlock.defer({
        storyboardHashid: this.props.storyboard_id,
        project_id: this.props.project_id,
      });
    }
  },

  componentWillUnmount: function () {
    ShareablePasswordStore.unlisten(this._onChange);
  },

  _onChange: function (state) {
    this.setState(state);
  },

  render: function () {
    return (
      <IFPermission
        featureName="shareable"
        className="vh100"
        storyboardOwnerId={this.props.storyboard_owner_id}
      >
        <DialogContextProvider>
          <DefaultStoreContextProvider>
            {this.state.is_locked ? (
              <ShareablePasswordProtected {...this.props} />
            ) : (
              <ErrorBoundary>
                <ShareableContainer {...this.props} />
              </ErrorBoundary>
            )}
          </DefaultStoreContextProvider>
        </DialogContextProvider>
      </IFPermission>
    );
  },
});
