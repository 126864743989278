window.TeamParticipationActions = alt.createActions(function () {

  this.fetch = function (user_id) {
    this.dispatch(user_id);
  };

  this.receive = function (teams) {
    this.dispatch(teams);
  };

  this.leave = function (data) {
    this.dispatch(data);
  };

});

