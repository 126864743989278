/** @prettier */
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import React from 'react';
import { OnboardingFormWrapper } from './OnboardingFormWrapper';

interface TwoFactorLoginProps {
  authenticityToken: string;
}

const TwoFactorLogin: React.FC<TwoFactorLoginProps> = ({
  authenticityToken,
}) => {
  return (
    <div className="relative flex min-h-vp">
      <div className="flex flex-col flex-grow p-8 bg-surface">
        <OnboardingFormWrapper
          title={`Two-Factor Authentication`}
          subtitle={`Please enter your authenticator app code or a recovery code.`}
          bottomLink={
            <div className="flex items-center justify-center mt-8 text-sm space-x-1 text-type-subdued">
              <a
                className="no-underline hover:underline text-type-primary"
                href={'/login'}
              >{`Go back`}</a>
            </div>
          }
        >
          <form className="space-y-4" method="post" action={'/login'}>
            <input name="utf8" type="hidden" value="✓" />
            <input
              type="hidden"
              name="authenticity_token"
              value={authenticityToken}
            />
            <TextInput
              name="user[otp_attempt]"
              type="text"
              inputSize="lg"
              autoComplete="one-time-code"
            />
            <Button
              htmlType="submit"
              size="lg"
              rounded
              type="solid"
              className="w-full"
            >
              {`Verify`}
            </Button>
          </form>
        </OnboardingFormWrapper>
      </div>
    </div>
  );
};

(window as any).TwoFactorLogin = TwoFactorLogin;
