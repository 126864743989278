const _ = require('underscore');
(() => {
  class StoryboardMobilityStore {
    constructor() {

      this.is_able_to_move           = [];
      this.projects                  = false;
      this.storyboard                = false;
      this.has_checked_storyboard_id = [];
      this.destination_project_id    = null;

      this.bindListeners({
        handleCheck:                    StoryboardMobilityActions.CHECK,
        handleFetchDestinationOptions:  StoryboardMobilityActions.FETCH_DESTINATION_OPTIONS,
        handleSetDestinationProjectId:  StoryboardMobilityActions.SET_DESTINATION_PROJECT_ID,
        handleMove:                     StoryboardMobilityActions.MOVE,
      });
    }

    handleSetDestinationProjectId(project_id) {
      this.destination_project_id = project_id;
    }

    handleMove() {
      ajaxHelper.ajax({
        method: "post",
        url: "/storyboard_move/" + this.storyboard.id + "/move.json",
        data: {
          project_id: this.destination_project_id,
        },
        success: function (response) {
          FlyoverActions.close.defer();
          DashboardActions.fetch.defer();
          RequestActions.success.defer("Success- we've moved " + this.storyboard.document_name + " to " + this._findDesinationProjectName());
        }.bind(this),
        error: function (response) {
          RequestActions.error.defer("There was a problem moving your storyboard");
        }.bind(this),
      });
    }

    _findDesinationProjectName() {
      return "your selected project";
    }

    handleFetchDestinationOptions(storyboard) {
      this.storyboard = storyboard;
      ajaxHelper.ajax({
        method: "get",
        url: "/storyboard_move/" + storyboard.id + "/destination_options.json",
        success: function (response) {
          this.projects = response;
          this.emitChange();
        }.bind(this),
        error: function (response) {
          RequestActions.error.defer("There was a problem fetching your Project list");
        }.bind(this),
      });
    }

    handleCheck(storyboard) {
      this.storyboard = storyboard;
      PermissionActions.verifyStoryboardMobility.defer({
        storyboard_id:  storyboard.id,
        onSuccess:   function() {
          this.is_able_to_move.push(storyboard.id);
          this.has_checked_storyboard_id.push(storyboard.id);
          this.emitChange();
        }.bind(this),
        onError:   function() {
          this.has_checked_storyboard_id.push(storyboard.id);
          this.is_able_to_move = _.without(this.is_able_to_move, storyboard.id);
          this.emitChange();
        }.bind(this),
      });
    }

  }

  window.StoryboardMobilityStore = alt.createStore(StoryboardMobilityStore, 'StoryboardMobilityStore');

})();
