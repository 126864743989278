/**
 * @prettier
 */
const ActionListeners = require('alt/utils/ActionListeners');
const listener = new ActionListeners(alt);
const { redirectRoute } = require('./router-helpers');
require('javascripts/flux/actions/shareable');

const openView = (view) => (params) =>
  ShareableActions.openFrameIndex.defer({
    frameIndex: params.frameIndex,
    view: view,
  });

module.exports = (router) => {
  router.on(redirectRoute('checkout', '/checkout'));
  router.on(redirectRoute('pricing', '/pricing'));
  router.resolve();

  // Wait until the shareableStore has data before we trigger routes
  listener.addActionListener(ShareableActions.RECEIVE, () => {
    router.on({
      '/s/:slug/player/:frameIndex': {
        as: 'player',
        uses: openView('player'),
      },
      '/s/:slug/player': {
        as: 'playerInitial',
        uses: openView('player'),
      },
      '/s/:slug/frame/:frameIndex': {
        as: 'zoom',
        uses: openView('zoom'),
      },
      '/s/:slug/frame': {
        as: 'zoomInitial',
        uses: openView('zoom'),
      },
      '/s/:slug/': {
        as: 'shareable',
        uses: () => {
          ShareableActions.updateView.defer('grid');
        },
      },
    });

    router.resolve();
  });
};
