/** @prettier */
/* eslint react-perf/jsx-no-new-array-as-prop:0 react/jsx-handler-names:0 */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
import FrameCommentsContainer from '../../shared/containers/FrameCommentsContainer';
import PanelbarCommentsHideCompleted from './PanelbarCommentsHideCompleted';
import logger from 'javascripts/helpers/logger';
import { IconButton } from 'blackbird/components/common/IconButton';
import DownloadIcon from 'blackbird/images/icons/download.svg';
import SettingsIcon from 'blackbird/images/icons/settings.svg';
import Banner from 'blackbird/components/feedback/banner/Banner';
import { withTranslation } from 'react-i18next';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { idleTimeout } from 'javascripts/helpers/idle-timeout';
import { downloadEvent } from 'blackbird/helpers/eventContextHelper';
import { CommentsTextOutput } from '../../../helpers/comments-text-output';

const {
  PanelbarCommentsNoPermissions,
} = require('./PanelbarCommentsNoPermissions.react');
const { GetPermission } = require('../../../helpers/has-permission');

const PanelbarCommentsContainer = FrameCommentsContainer(
  createReactClass({
    displayName: 'PanelbarCommentsContainer',

    propTypes: {
      canManage: PropTypes.bool.isRequired,
      storyboard: PropTypes.object.isRequired,
      frames: PropTypes.array,

      // From the container:
      comment: PropTypes.shape({
        activeFrame: PropTypes.any,
        commentSections: PropTypes.array.isRequired,
        commentCount: PropTypes.number.isRequired,
        hasHiddenComments: PropTypes.bool,
        fetching: PropTypes.bool,
        fetched: PropTypes.bool,
      }).isRequired,
      projectTeam: PropTypes.any,
      user: PropTypes.object,
      fetch: PropTypes.func.isRequired,

      handleCommentSave: PropTypes.func.isRequired,
      handleCommentUpdate: PropTypes.func.isRequired,
      handleCommentDelete: PropTypes.func.isRequired,
      handleCommentChange: PropTypes.func.isRequired,
      hideCompletedComments: PropTypes.bool,
    },

    _isMounted: true,

    getInitialState() {
      return {
        commentExportLink: undefined,
      };
    },

    componentDidMount() {
      this._isMounted = true;
      this.props.fetch();
      idleTimeout(() => {
        const commentExportLink = this.getCommentExportLink(this.props);
        if (this._isMounted !== false) this.setState({ commentExportLink });
      }, 50);
    },

    componentWillUnmount() {
      this._isMounted = false;
    },

    getCommentExportLink(props) {
      if (!props.frames) {
        logger.log("Couldn't get comment export link, no frames were passed");
        return null;
      }

      const output = CommentsTextOutput(
        props.frames,
        props.comment.commentSectionsById,
        props.storyboard,
        window.location,
      );

      return 'data:text/plain;charset=utf-8,' + encodeURIComponent(output);
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        this.props.comment.commentSections !== nextProps.comment.commentSections
      ) {
        this.setState({
          commentExportLink: this.getCommentExportLink(nextProps),
        });
      }
    },

    render: function () {
      const { t } = this.props;
      const storyboard = this.props.storyboard;
      const activeFrame = this.props.comment.activeFrame;

      return (
        <GetPermission
          storyboardOwnerId={storyboard.project.owner.user_id}
          featureName="comments"
        >
          {(hasCommentsPermission) =>
            !this.props.projectTeam || hasCommentsPermission === 'fetching' ? (
              <LoadingIndicator text={t('loaders.comments')} pad />
            ) : !hasCommentsPermission ? (
              <PanelbarCommentsNoPermissions
                ownerId={storyboard.project.owner.user_id}
              />
            ) : !storyboard.has_comments_enabled ? (
              <PanelbarCommentsDisabled {...this.props} />
            ) : (
              <>
                <div className="flex justify-between items-center bg-white sticky p-6 top-[97px] z-40">
                  <PanelbarCommentsListHeading
                    count={this.props.comment.commentCount}
                  />
                  <div className="flex items-center gap-5">
                    <PanelbarCommentsHideCompleted storyboard={storyboard} />

                    <IconButton
                      isLinkElem
                      onClick={() => downloadEvent('txt')}
                      icon={<DownloadIcon />}
                      aria-label="Download .txt"
                      href={this.state.commentExportLink}
                      download={storyboard.document_name + ' comments.txt'}
                      disabled={this.state.commentExportLink}
                    />
                    <IconButton
                      onClick={() =>
                        PanelbarActions.open.defer({
                          type: 'settings',
                          subPanel: 'comments',
                        })
                      }
                      aria-label="Comment settings"
                      icon={<SettingsIcon />}
                      size="lg"
                    />
                  </div>
                </div>

                {this.props.comment.fetching || !this.props.comment.fetched ? (
                  <LoadingIndicator text={t('loaders.comments')} pad />
                ) : this.props.comment.commentSections.length === 0 &&
                  this.props.comment.hasHiddenComments ? (
                  <div className="m-4">
                    <Banner
                      hideIcon
                      kind="warning"
                      message={t('comments.hidden_comments_notice')}
                    />
                  </div>
                ) : this.props.comment.commentSections.length === 0 &&
                  !this.props.comment.hasHiddenComments ? (
                  <PanelbarCommentsEmpty />
                ) : (
                  <div className="flex-grow overflow-auto">
                    <PanelbarCommentsList
                      canManage={this.props.canManage}
                      team={this.props.projectTeam}
                      sections={this.props.comment.commentSections}
                      user={this.props.user}
                      storyboard={storyboard}
                      activeFrame={activeFrame}
                      handleCommentSave={this.props.handleCommentSave}
                      handleCommentUpdate={this.props.handleCommentUpdate}
                      handleCommentDelete={this.props.handleCommentDelete}
                      handleCommentChange={this.props.handleCommentChange}
                      hideCompletedComments={this.props.hideCompletedComments}
                    />
                  </div>
                )}
              </>
            )
          }
        </GetPermission>
      );
    },
  }),
);

window.PanelbarCommentsContainer = withTranslation()(PanelbarCommentsContainer);
