/** @format */
import { FakeAltStoreClass } from './AltStore';
import '../actions/dashboard';
import '../actions/archive';
import '../actions/trial';
import '../actions/new_project';
import type { frameAspectRatio } from 'javascripts/types/storyboard';
import { RequestActions } from '../actions/request';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import type { LiteralUnion } from 'type-fest';
import { ajax } from 'javascripts/helpers/ajax';
import { compact } from 'underscore';
import logger from 'javascripts/helpers/logger';
import { ArchiveActions } from '../actions/archive';

const errorHandler = RequestErrorHandler('dashboard');
interface Dashboard {
  personal: DashboardType;
  team: DashboardType;
  memberships: Membership[];
}

export type MembershipActions = LiteralUnion<
  | 'storyboards.create'
  | 'storyboards.rename'
  | 'storyboards.delete'
  | 'projects.create'
  | 'projects.rename'
  | 'projects.delete',
  string
>;

interface Membership {
  team_id: number;
  heading: string;
  admin: string;
  is_lapsed: boolean;
  logo_url: string;
  projects: Project[];
  actions: MembershipActions[];
}

interface Project {
  id: number;
  name: string;
  slug: string;
  storyboards: Storyboard[];
  is_admin: boolean;
  is_accessible: boolean;
  deleted_at?: string;
}

interface Storyboard {
  id: number;
  slug: string;
  document_name: string;
  frame_aspect_ratio: frameAspectRatio;
  updated_at: string /** might need to convert to Date later  */;
  public_url: string;
  cover_image: null | string;
}

interface DashboardType {
  heading: string;
  logo_url: string;
  projects: Project[];
  actions: MembershipActions[];
}

class DashboardStore extends FakeAltStoreClass<DashboardStore> {
  fetched: boolean;
  dashboard: Dashboard;
  constructor() {
    super();
    this.fetched = false;
    this.bindListeners({
      handleFetch: [
        NewProjectActions.PROJECT_CREATED,
        DashboardActions.FETCH,
        ArchiveActions.PROJECT_RESTORED,
        TrialActions.STARTED,
      ],
      handleReceive: DashboardActions.RECEIVE,
      handleArchiveProject: DashboardActions.ARCHIVE_PROJECT,
      handleUpdateProject: DashboardActions.UPDATE_PROJECT,
      handleReorder: DashboardActions.UPDATE_BOARDS_ORDER,
      handleProjectReorder: DashboardActions.UPDATE_PROJECT_ORDER,
    });
  }

  handleReceive(response) {
    this.fetched = true;
    this.dashboard = response;
    this.emitChange();
  }

  handleFetch() {
    this.fetched = false;
    ajax({
      method: 'get',
      url: '/dashboard.json',
      success: function (response) {
        DashboardActions.receive(response);
      }.bind(this),
      error: function (response, textStatus, errorThrown) {
        XhrErrorActions.show.defer({
          status_code: response.status,
          context: 'dashboard',
          response,
        });
      }.bind(this),
    });
  }

  handleUpdateProject(data) {
    ajax({
      method: 'patch',
      url: '/projects/' + data.id + '.json',
      data: {
        project: {
          name: data.name,
        },
      },
      success: function (response) {
        DashboardActions.fetch();
        RequestActions.success({ key: 'dashboard.notifications.renamed' });
        this.emitChange();
      }.bind(this),
      error: errorHandler(),
    });
  }

  handleArchiveProject(project) {
    ajax({
      method: 'post',
      url: '/projects/' + project.slug + '/archive.json',
      success: function (response) {
        DashboardActions.fetch();
        RequestActions.success({
          key: 'dashboard.notifications.projectArchived',
        });
        this.emitChange();
      }.bind(this),
      error: errorHandler(),
    });
  }

  handleReorder(state) {
    const { projectId, storyboards } = state;
    const data = compact(storyboards).map((s) => s.id);

    ajax({
      method: 'patch',
      url: '/storyboard_order/' + projectId + '.json',
      data: { order: data },
      error: errorHandler({ messageKey: 'dashboard.errors.storyboardOrder' }),
    });
  }

  handleProjectReorder(projects) {
    const data = projects.map((s) => s.id);

    ajax({
      method: 'post',
      url: '/project_order.json',
      data: { order: data },
      error: errorHandler({ messageKey: 'dashboard.errors.projectOrder' }),
    });
  }
}

(window as any).DashboardStore = alt.createStore(
  DashboardStore,
  'DashboardStore',
);

export {
  DashboardStore,
  Dashboard,
  Membership,
  DashboardType,
  Project,
  Storyboard,
};
