/** @prettier */
interface CurrencyProps {
  amount: number;
  currency: string;
  showZeros?: boolean;
}
export const formatCurrency = ({
  amount,
  currency,
  showZeros,
}: CurrencyProps) => {
  const total = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount);

  const priceElements = total.split('.');

  if (priceElements[1] === '00' && !showZeros) {
    return priceElements[0];
  } else {
    return total;
  }
};

export const formatCurrencyParts = ({
  amount,
  currency,
  showZeros,
}: CurrencyProps) => {
  // Format the amount using Intl.NumberFormat and extract parts
  const parts = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).formatToParts(amount);

  // Initialize symbol and value strings
  let symbol = '';
  let value = '';

  // Iterate over the parts to separate the symbol and the numeric value
  parts.forEach((part) => {
    if (part.type === 'currency') {
      symbol = part.value;
    } else if (['integer', 'decimal', 'fraction'].includes(part.type)) {
      value += part.value;
    }
  });

  // If showZeros is false and value ends with ".00", remove the decimal part
  if (!showZeros && value.endsWith('.00')) {
    value = value.substring(0, value.length - 3);
  }

  // Return an object with symbol and value
  return { symbol, value };
};

export const formatNumber = (number: number) =>
  new Intl.NumberFormat('en-US').format(number);
