/** @prettier */
import Button from 'blackbird/components/button/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
const navigateToRoute = require('../../helpers/router/navigate-to-route');
import '../flyover/FlyoverSection.react';
import { PricingFeatureLocked } from 'blackbird/components/pricing/PricingFeatureLocked';

export const FrameLimitFlyover = (props: { paid: false }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'modals.frame_limit' });

  return !props.paid ? (
    <PricingFeatureLocked triggeredFromFeatureName={`frameLimit`} />
  ) : (
    <FlyoverSection
      isOpen
      title={t('title_paid')}
      component={
        <div className="flex flex-col gap-6">
          <div className="text-type-subdued">
            {t('description_paid', {
              frameLimit: FrameStore.getState().paidFrameCountLimit,
            })}
          </div>

          <Button size="lg" onClick={() => navigateToRoute('newStoryboard')}>
            {t('create_new_storyboard')}
          </Button>
        </div>
      }
      hideClose={false}
    />
  );
};
