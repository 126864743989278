const _ = require('underscore');
const createReactClass = require('create-react-class')
const PureRenderMixin = require('react-addons-pure-render-mixin');
const { PlayerActions } = require('../../flux/actions/player')

module.exports = (Component, withActions) => createReactClass({
  displayName: `PlayerContainer(${Component.displayName || Component.name})`,

  mixins: [PureRenderMixin],

  getInitialState() {
    const newState = this.getState(this.getStateFromStore());

    if (withActions) {
      withActions.forEach( key => {
        newState[key] = PlayerActions[key];

        if (_.isUndefined(newState[key])) {
          console.warn(`could not find key '${key}' in ${Object.keys(PlayerActions)}'`)
        }
      })

    }

    return newState;
  },

  componentDidMount: function () {
    PlayerStore.listen(this.update);
    if (Component.propTypes.frame) FrameStore.listen(this.update);
  },

  componentWillUnmount: function () {
    PlayerStore.unlisten(this.update);
    if (Component.propTypes.frame) FrameStore.unlisten(this.update);
  },

  getStateFromStore() {
    const state = Object.assign({}, PlayerStore.getState());

    if (Component.propTypes.frame) {
      state.frame = _.find(
        FrameStore.getState().frames,
        f => f && f.id === state.currentFrameId
      );
    }

    return state;
  },

  getState(props, prevProps) {
    const propsToUse = Object.keys(Component.propTypes);
    let count = 0;

    const updates = _.reduce(propsToUse, (o, key) => {
      // If this is an action, let's not overwrite that in the state
      if (_.contains(withActions, key)) return o;

      if (!prevProps || prevProps[key] !== props[key]) { count++; }
      o[key] = props[key];

      // if (_.isUndefined(props[key])) {
      //   console.warn(`could not find key '${key}' in ${Object.keys(props)}'`)
      // }

      return o;
    }, {});

    return count === 0 ? {} : updates;
  },

  update() {
    const newState = this.getState(this.getStateFromStore(), this.state);
    if (!_.isEmpty(newState)) this.setState(newState);
  },

  render() {
    return this.state.frame || this.state.currentFrameId || this.state.storyboard
        ? <Component ref={this.props.componentRef} {...this.state} {...this.props}/>
        : null;
  }
})
