/** @format */

import React from 'react';

export const ProjectSkeletonBreadcrumb: React.FC = () => (
  <div className="max-w-sm grid grid-cols-3 gap-3 animate-pulse">
    <div className="h-4 rounded-full col-span-1 bg-surface-add_frame" />
    <div className="h-4 rounded-full col-span-1 bg-surface-add_frame" />
    <div className="h-4 rounded-full col-span-1 bg-surface-add_frame" />
  </div>
);

export const ProjectTeamGlobalSkeleton: React.FC<{ memberCount?: number }> = ({
  memberCount = 4,
}) => (
  <div className="animate-pulse">
    <div className="pt-4 pb-6 mb-6 border-b border-border">
      {/* Title */}
      <div className="h-8 max-w-sm rounded-lg bg-surface-add_frame" />
    </div>
    {/* Subheading */}
    <div className="max-w-xs mb-6">
      <div className="h-3 rounded-md bg-surface-add_frame" />
    </div>
    {/* Member rows */}
    {Array.from({ length: memberCount }).map((_, index) => (
      <div key={index} className="flex items-center mb-6">
        <div className="w-8 h-8 mr-2 rounded-full bg-surface-add_frame" />
        <div className="flex-auto">
          <div className="max-w-xs h-3 rounded-md bg-surface-add_frame mb-1.5" />
          <div className="w-32 h-2 rounded-md bg-surface-add_frame" />
        </div>
      </div>
    ))}
  </div>
);

export const ProjectSkeletonHeader: React.FC = () => (
  <div className="px-10 py-6 bg-white border-b border-border space-y-4">
    <ProjectSkeletonBreadcrumb />
    <div className="max-w-md animate-pulse">
      <div className="h-8 rounded-lg bg-surface-add_frame" />
    </div>

    <div className="space-y-2">
      <div className="max-w-sm animate-pulse">
        <div className="h-3 rounded-md bg-surface-add_frame" />
      </div>
      <div className="max-w-xs animate-pulse">
        <div className="h-3 rounded-md bg-surface-add_frame" />
      </div>
    </div>
  </div>
);

export const ProjectSkeletonGrid: React.FC = () => (
  <div className="px-10 py-8 overflow-hidden grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 animate-pulse">
    {[...Array(12)].map((_, index) => (
      <div
        key={index}
        className="rounded-lg aspect-square bg-surface-add_frame"
      />
    ))}
  </div>
);

export const ProjectSkeletonAccordion: React.FC = () => (
  <div className="p-3 space-y-4 animate-pulse">
    <div className="w-1/4 h-4 rounded-md bg-surface-add_frame" />
    <div className="w-1/3 h-4 rounded-md bg-surface-add_frame" />
    <div className="w-1/2 h-4 ml-4 rounded-md bg-surface-add_frame" />
    <div className="w-1/2 h-4 ml-4 rounded-md bg-surface-add_frame" />
    <div className="w-1/2 h-4 ml-4 rounded-md bg-surface-add_frame" />
    <div className="w-1/3 h-4 rounded-md bg-surface-add_frame" />
    <div className="w-1/2 h-4 ml-4 rounded-md bg-surface-add_frame" />
    <div className="w-1/2 h-4 ml-4 rounded-md bg-surface-add_frame" />
  </div>
);
