/** @prettier */
import * as React from 'react';
import type { ContextMenuProps } from '../types';
import { ToolbarSelect } from './ToolbarInput';
import { FontPicker } from '../../shared/FontPicker';
import type { FontSettings } from '../../pdf/types';
import { fontSizes, defaultFontSize } from '../frameEditorDefaults';
import { textAlignOptions } from './toolbarOptions';
import { ToolbarButton } from '../../ToolbarButton';
import { ColorPicker } from '../../shared/ColorPicker.react';
import { TourHintable } from '../../tours/TourHintable';

import TextJustifyRightIcon from 'blackbird/images/icons/text-justify-right.svg';
import TextJustifyLeftIcon from 'blackbird/images/icons/text-justify-left.svg';
import TextJustifyCentreIcon from 'blackbird/images/icons/text-justify-centre.svg';
import { ColorPickerWellButton } from 'javascripts/components/shared/ColorPickerWellButton';

const subsets = ['latin'];

const [alignLeft, alignCenter, alignRight] = textAlignOptions;

export const TextContextMenu: React.FC<ContextMenuProps> = ({
  actions,
  disabled,
  selected,
}) => {
  const [selectedFont, setSelectedFont] = React.useState({
    family: selected.fontFamily,
    src: selected.fontFamily,
  });

  const onChange = React.useCallback(
    (newFont: FontSettings) => {
      actions.handleSetFontFamily(selected, newFont);
      setSelectedFont(newFont);
    },
    [selected, actions],
  );

  const setTextAlign = React.useCallback(
    (e) => {
      actions.handleSetTextAlign(selected, e.currentTarget.value);
    },
    [selected, actions],
  );

  return (
    <>
      <FontPicker
        subsets={subsets}
        onChange={onChange}
        value={selectedFont}
        onLoad={actions.triggerRerender}
      />

      <ToolbarSelect
        className="w100px mr2 shrink-0"
        defaultValue={selected.fontSize ?? defaultFontSize}
        onChange={(e) => actions.handleSetFontSize(selected, e)}
        disabled={disabled}
      >
        {fontSizes.map((o) => (
          <option key={o.value} value={o.value}>
            {o.value}
          </option>
        ))}
      </ToolbarSelect>

      <ToolbarButton
        labelKeyPrefix="frameEditor.toolbar.textAlign"
        value={alignLeft.value}
        icon={<TextJustifyLeftIcon />}
        labelPosition="top"
        onClick={setTextAlign}
      />

      <ToolbarButton
        labelKeyPrefix="frameEditor.toolbar.textAlign"
        value={alignCenter.value}
        icon={<TextJustifyCentreIcon />}
        labelPosition="top"
        onClick={setTextAlign}
      />

      <ToolbarButton
        labelKeyPrefix="frameEditor.toolbar.textAlign"
        value={alignRight.value}
        icon={<TextJustifyRightIcon />}
        labelPosition="top"
        onClick={setTextAlign}
      />

      <ColorPicker
        color={selected.fill}
        onChange={(e) => actions.handleSetFillColor(selected, e)}
        disabled={disabled}
        icon={<ColorPickerWellButton color={selected.fill} />}
        showAlpha
      />
    </>
  );
};

TextContextMenu.displayName = 'TextContextMenu';
