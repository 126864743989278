/** @prettier */

import React, { useEffect, useState } from 'react';
import TextInput from '../text-input/TextInput';
import Panel from '../../panel/Panel';
import ListItem from '../../common/list-item/ListItem';
import type { Option } from '../../common/types';
import Popover from '../../common/popover/Popover';
import classNames from 'classnames';
import { useWidth } from '../../../helpers/hooks/useWidth';
import { useManageIndex } from '../../../helpers/hooks/useManageIndex';
import { useTextSearch } from 'javascripts/helpers/useTextSearch';
import { CheckIcon, ChevronDown, X } from 'lucide-react';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import { LockClosedIcon } from '@heroicons/react/20/solid';

export type SelectChangeHandler = (
  value: string,
  option: Option | null,
) => void;
const searchableProps: (keyof Option)[] = ['label', 'subLabel'];

interface SelectProps {
  size?: React.ComponentProps<typeof TextInput>['inputSize'];
  className?: string;
  disabled?: boolean;
  error?: string;
  label?: React.ReactNode;
  loading?: boolean;
  onChange?: SelectChangeHandler;
  onAllowRemove?: () => void;
  options?: Option[];
  placeholder?: string;
  value?: string;
  selectContainerClassName?: string;
  labelClasses?: string;
  name?: string;
  /**
   * Controls if typing is allowed in the input section of Select
   */
  disableInput?: boolean;
  /** The class name to use for z-indexing the overlay panel
   * FIXME: this isn't that clean of a solution, it should position itself
   * better. Perhaps I can abuse the insideDialogContext for this
   */
  zIndex?: string;
  inputClassName?: string;
  allowRemove?: boolean;
}

const getCurrentOption = (options?: Option[], value?: string) =>
  options?.find((o) => o.value === value);

const Select = React.memo<SelectProps>((props) => {
  const {
    className,
    disabled,
    error,
    label,
    loading,
    options = [],
    placeholder,
    value,
    selectContainerClassName,
    size = 'lg',
    name,
    onAllowRemove,
    disableInput,
    inputClassName,
    labelClasses,
    allowRemove = false,
  } = props;

  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [currentValue, setCurrentValue] = useState<Option | undefined>(
    getCurrentOption(options, value),
  );
  const [placeholderActive, setPlaceholderActive] = useState<boolean>(
    Boolean(currentValue),
  );
  const [controlRef, setControlRef] = useState<HTMLDivElement | null>();
  const [scrollItemInView, setScrollItemInView] = useState(false);
  const enableScrolling = React.useCallback(
    () => setScrollItemInView(true),
    [setScrollItemInView],
  );

  const panelWidth = useWidth(controlRef) + 44;

  /** Show all the values that are not the current selected value  */
  const [filteredOptions, triggerTextSearch] = useTextSearch(
    options,
    searchableProps,
  );

  useEffect(() => {
    if (!open) {
      setText('');
    }
  }, [open]);

  useEffect(() => {
    if (value !== currentValue?.value) {
      setCurrentValue(getCurrentOption(options, value));
    }
  }, [value, options, currentValue]);

  const handleOpen = () => {
    if (open) return;
    if (!disabled) {
      setOpen(true);
      controlRef?.focus();
    }
    setPlaceholderActive(false);
  };

  const handleClose = () => {
    setOpen(false);
    controlRef?.blur();
    setPlaceholderActive(true);
  };

  const handleSelectOption = (option: Option) => {
    setCurrentValue(option);
    props.onChange?.(option.value, option);
    setPlaceholderActive(true);
    handleClose();
  };

  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation();
    controlRef?.blur();
    props.onChange?.('', null);
    setCurrentValue(undefined);
    setPlaceholderActive(false);
    if (onAllowRemove) {
      onAllowRemove();
      setPlaceholderActive(true);
    }
  };

  const RemoveIcon: React.FC<{ hideTooltip?: boolean }> = ({
    hideTooltip = false,
  }) =>
    allowRemove ? (
      <Tooltip placement="top" title={`Remove`} disabled={hideTooltip}>
        <div
          className="p-1 rounded-sm opacity-0 cursor-pointer group-hover/selectoption:opacity-100 text-type-disabled hover:bg-surface-add_frame hover:text-type-primary group-hover/selectcontainer:opacity-100"
          onClick={handleRemove}
        >
          <X className="w-4 h-4" />
        </div>
      </Tooltip>
    ) : null;

  const { currentIndex, setCurrentIndex, onCommit, onCurrentIndexChange } =
    useManageIndex(filteredOptions.length, controlRef);

  /** Register handlers for useManageIndex */
  onCommit(() => handleSelectOption(options![currentIndex]));
  onCurrentIndexChange(enableScrolling);

  const renderOptions = () => {
    if (loading || !options?.length) {
      return null;
    }

    return (
      <div className="space-y-1">
        {filteredOptions.map((option, index) => (
          <ListItem
            key={option.value ?? option.label}
            option={option}
            onClick={() => {
              if (!option.disabled) handleSelectOption(option);
            }}
            selected={
              currentValue?.value === option.value && option.value !== ''
            }
            disabled={option.disabled}
            className={classNames('group/selectoption')}
            rightComponent={
              option.disabled && <LockClosedIcon className="w-3 h-3" />
            }
            selectedRightComponent={
              <div className={classNames('flex items-center space-x-1')}>
                <RemoveIcon hideTooltip />
                <div className={classNames('flex-shrink-0')}>
                  <CheckIcon className="w-4 h-4" />
                </div>
              </div>
            }
            scrollInView={scrollItemInView}
            highlight={currentIndex === -1 ? undefined : currentIndex === index}
            onMouseOver={() => {
              setScrollItemInView(false);
              setCurrentIndex(index);
            }}
          />
        ))}
      </div>
    );
  };

  const renderPanel = (styles) => {
    if (!open) {
      return null;
    }

    const panelStyle = {
      maxHeight: styles.popper.maxHeight,
      width: `${panelWidth}px`,
      fontSize: ['md', 'xs'].includes(size) ? '0.875rem' : '1rem',
    };

    return (
      <Panel
        loading={loading}
        className="left-0 w-full p-2 top-11"
        placeholder={placeholder}
        message={
          text && !filteredOptions?.length
            ? `No results for ${text}`
            : undefined
        }
        style={panelStyle}
      >
        {renderOptions()}
      </Panel>
    );
  };

  const renderChevron = () => (
    <span className="inline-flex items-center space-x-2">
      <div
        className="flex-shrink-0 cursor-pointer"
        onClick={open ? handleClose : handleOpen}
      >
        <ChevronDown
          className={classNames(
            'w-4 h-4 transition-all',
            open && 'transform -rotate-180',
            disabled && 'text-type-disabled',
          )}
        />
      </div>
    </span>
  );

  return (
    <Popover
      isOpen={open}
      placement="bottom-start"
      portal
      distance={12}
      offset={-14}
    >
      <Popover.Button className={selectContainerClassName}>
        {({ setParentRef }) => (
          <>
            <input
              type="hidden"
              value={currentValue?.value ?? ''}
              name={name}
            />
            <TextInput
              readOnly={disableInput}
              inputSize={size}
              /**
               * Keep inactive placeholder color unless a value is selected.
               */
              inputClassName={classNames(
                {
                  'group/select placeholder:text-type-primary':
                    placeholderActive,
                  'cursor-default': disableInput,
                },
                inputClassName,
                size === 'md' && 'text-sm',
              )}
              ref={(ref) => {
                setParentRef && setParentRef(ref);
                setControlRef(ref);
              }}
              className={classNames(className, 'group/selectcontainer')}
              disabled={disabled}
              error={error}
              label={label}
              labelClasses={classNames(
                labelClasses,
                ['md', 'lg'].includes(size) && 'font-semibold text-sm !mb-1',
              )}
              onBlur={handleClose}
              placeholder={(currentValue?.label as string) || placeholder}
              onClick={handleOpen}
              onChange={(e) => {
                setText(e.currentTarget.value);
                triggerTextSearch(e.currentTarget.value);
                setOpen(true);
                setPlaceholderActive(false);
              }}
              onFocus={handleOpen}
              rightComponent={
                <span
                  className={classNames(
                    'inline-flex items-center space-x-2',
                    allowRemove && 'pt-1',
                  )}
                >
                  <RemoveIcon />
                  {renderChevron()}
                </span>
              }
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.stopPropagation();
                }
                if (e.key === 'Escape') {
                  handleClose();
                }
              }}
              value={text}
              autoComplete="off"
              // In this case we want to leave the name field to the hidden form
              // field so when it's submitted with a regular HTML form (i.e.
              // CountryAndTaxField the value is correct
            />
          </>
        )}
      </Popover.Button>
      {open && (
        <Popover.Panel className={props.zIndex} static>
          {({ styles }) => renderPanel(styles)}
        </Popover.Panel>
      )}
    </Popover>
  );
});

Select.displayName = 'Select';
export default Select;
