/** @prettier */
import * as React from 'react';
import ActionIcon from 'blackbird/images/icons/action.svg';
import MuteIcon from 'blackbird/images/icons/sound.svg';
import AlertIcon from 'blackbird/images/icons/warning.svg';
import BellIcon from 'blackbird/images/icons/bell.svg';
import ClipboardIcon from 'blackbird/images/icons/clipboard.svg';
import CalendarIcon from 'blackbird/images/icons/calendar.svg';
import CameraIcon from 'blackbird/images/icons/camera.svg';
import DeleteIcon from 'blackbird/images/icons/delete.svg';
import EnvelopIcon from 'blackbird/images/icons/email.svg';
import EyeIcon from 'blackbird/images/icons/eye.svg';
import GearIcon from 'blackbird/images/icons/settings.svg';
import GlobeIcon from 'blackbird/images/icons/globe.svg';
import HeadphoneIcon from 'blackbird/images/icons/headphones.svg';
import HeartIcon from 'blackbird/images/icons/heart.svg';
import HomeIcon from 'blackbird/images/icons/home.svg';
import LocationIcon from 'blackbird/images/icons/location-pin.svg';
import LockIcon from 'blackbird/images/icons/lock.svg';
import MaleIcon from 'blackbird/images/icons/avatar-male.svg';
import FemaleIcon from 'blackbird/images/icons/avatar-female.svg';
import MicIcon from 'blackbird/images/icons/mic.svg';
import MobileIcon from 'blackbird/images/icons/mobile.svg';
import MonitorIcon from 'blackbird/images/icons/monitor.svg';
import PhoneIcon from 'blackbird/images/icons/phone.svg';
import SupportIcon from 'blackbird/images/icons/help.svg';
import CommentIcon from 'blackbird/images/icons/comment.svg';
import ClockIcon from 'blackbird/images/icons/clock.svg';
import WifiIcon from 'blackbird/images/icons/wifi.svg';
import LabelIcon from 'blackbird/images/icons/label.svg';
import PDFSixUp from 'blackbird/images/icons/six-up.svg';
import PDFFiveUpPortrait from 'blackbird/images/icons/five-up-portrait.svg';
import PDFFourUp from 'blackbird/images/icons/four-up.svg';
import PDFThreeUp16x9 from 'blackbird/images/icons/three-up-16x9.svg';
import PDFThreeUp1x1 from 'blackbird/images/icons/three-up-1x1.svg';
import PDFThreeUp9x3 from 'blackbird/images/icons/three-up-9x3.svg';
import PDFThreeUp9x16 from 'blackbird/images/icons/three-up-9x16.svg';
import PDFTwoUp from 'blackbird/images/icons/two-up.svg';
import PDFTwoUp1x1 from 'blackbird/images/icons/two-up-1x1.svg';
import PDFOneUp from 'blackbird/images/icons/one-up.svg';
import PDFOneUp1x1 from 'blackbird/images/icons/one-up-1x1.svg';
import PDFEightUp from 'blackbird/images/icons/eight-up.svg';
import PDFOneUpTwoCol from 'blackbird/images/icons/one-up-two-col.svg';

import Icon from 'blackbird/components/icon/Icon';

const FIELD_ICONS = {
  action: ActionIcon,
  mute: MuteIcon,
  alert: AlertIcon,
  bell: BellIcon,
  calendar: CalendarIcon,
  camera: CameraIcon,
  chat_bubble: CommentIcon,
  clipboard: ClipboardIcon,
  delete: DeleteIcon,
  envelope: EnvelopIcon,
  eye: EyeIcon,
  gear: GearIcon,
  globe: GlobeIcon,
  headphone: HeadphoneIcon,
  heart: HeartIcon,
  home: HomeIcon,
  location: LocationIcon,
  lock: LockIcon,
  male: MaleIcon,
  female: FemaleIcon,
  mic: MicIcon,
  mobile: MobileIcon,
  monitor: MonitorIcon,
  phone: PhoneIcon,
  support: SupportIcon,
  wifi: WifiIcon,
  label: LabelIcon,
  clock: ClockIcon,

  /**
   * Export icons
   */
  'pdf-six-up': PDFSixUp,
  'pdf-five-up-portrait': PDFFiveUpPortrait,
  'pdf-four-up': PDFFourUp,
  'pdf-three-up-16x9': PDFThreeUp16x9,
  'pdf-three-up-9x16': PDFThreeUp9x16,
  'pdf-three-up-1x1': PDFThreeUp1x1,
  'pdf-': PDFThreeUp9x3,
  'pdf-two-up': PDFTwoUp,
  'pdf-two-up-1x1': PDFTwoUp1x1,
  'pdf-one-up': PDFOneUp,
  'pdf-one-up-1x1': PDFOneUp1x1,
  'pdf-eight-up': PDFEightUp,
  'pdf-one-up-two-col': PDFOneUpTwoCol,
};

export type SVGIconType = keyof typeof FIELD_ICONS;
interface Props extends Omit<React.ComponentProps<typeof Icon>, 'icon'> {
  icon: SVGIconType;
}
const SVGIconComp = (props: Props) => {
  const IconComp = FIELD_ICONS[props.icon];
  return <>{IconComp && <Icon {...props} icon={<IconComp />} />}</>;
};
export const SVGIcon = React.memo<Props>(SVGIconComp);
