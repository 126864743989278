/** @prettier */
import * as React from 'react';
import { PDFToPng } from './coverToPng';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { SuspenseLoader } from '../shared/SuspenseLoader';
import { openLightbox } from 'javascripts/helpers/lightbox';
const FullScreenButton = React.lazy(() => import('./FullScreenButton'));
const errorHandler = RequestErrorHandler('pdfPreview');

export interface Props {
  url?: string | null;
  isLoading: boolean;
  /** not zero indexed */
  pageNumber?: number;
  hasError?: boolean;
  scale?: number;
  title?: string;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  // ratio: string
}

export interface State {
  isLoading: boolean;
  hasError: boolean;
  displayURL?: string;
}

export class PDFPreview extends React.PureComponent<Props, State> {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { displayURL: undefined, isLoading: false, hasError: false };
    this.handlePreview = this.handlePreview.bind(this);
  }

  componentDidMount() {
    this.updatePreview(this.props);
    this._isMounted = true;
  }

  updatePreview(props: Props) {
    if (!props.url) return;
    this.setState({ isLoading: true, hasError: false });

    PDFToPng(
      props.url!,
      props.pageNumber,
      (this.props.scale || 1) * devicePixelRatio,
      'white',
    )
      .then((data) => {
        if (!this._isMounted || !data) return;
        this.setState({
          displayURL: data.dataUrl,
          isLoading: false,
        });
      })
      .catch((err) => {
        if (!this._isMounted) return;
        this.setState({ hasError: true });
        errorHandler({ message: null, severity: 'warn' })(err);
      });
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.url !== prevProps.url ||
      this.props.pageNumber !== prevProps.pageNumber
    ) {
      this.updatePreview(this.props);
    } else if (this.props.isLoading !== prevProps.isLoading) {
      this.setState({ isLoading: this.props.isLoading });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  handlePreview() {
    openLightbox(this.state.displayURL!);
  }
  render() {
    return (
      <div
        className={`relative hover-child-black-background overflow-hidden ${
          this.props.className || ''
        }`}
        onClick={this.props.onClick}
      >
        {this.props.hasError || this.state.hasError ? (
          <div className="absolute inset-0 flex items-center justify-center bg-white-transparent avenir-heavy f5 pa3">
            <div>Something went wrong loading this page's preview</div>
          </div>
        ) : this.state.isLoading ? (
          <div className="absolute inset-0 flex items-center justify-center bg-white-transparent">
            <LoadingIndicator />
          </div>
        ) : null}
        <>
          {this.state.displayURL ? (
            <img
              src={this.state.displayURL}
              key={this.state.displayURL}
              className="w-full border border-border-image"
            />
          ) : (
            <div className="h-full w-full bg-surface" />
          )}

          {this.state.displayURL || this.props.title ? (
            <div className="absolute left-0 bottom-0 right-0 child bg-black/40 text-white flex justify-between f5 pa1 items-center">
              <div className="px-1.5 py-1 text-xxs">{this.props.title}</div>
              <SuspenseLoader className="w-4" fill={false}>
                {this.state.displayURL && (
                  <FullScreenButton onPreview={this.handlePreview} />
                )}
              </SuspenseLoader>
            </div>
          ) : null}
        </>
      </div>
    );
  }
}
