/** @prettier */
import type { FrameField } from '../types/storyboard';

/**
 * This array is mirrored in PdfDataPresenter in the ruby codebase, we
 * should keep those consistent.
 */
export const defaultFrameFields: FrameField[] = [
  {
    // This field isn't really editable at the moment
    label: 'Label',
    isEnabled: true,
    id: 'reference',
  },
  {
    label: 'Sound',
    icon: 'mute',
    isEnabled: true,
    id: 'voiceover',
  },
  {
    label: 'Action',
    icon: 'action',
    isEnabled: true,
    id: 'direction',
  },
];

if (process.env.NODE_ENV === 'test') {
  defaultFrameFields.push(
    {
      label: 'Lighting',
      icon: 'clipboard',
      isEnabled: false,
      id: 'note_4',
    },
    {
      label: 'Camera',
      icon: 'camera',
      isEnabled: false,
      id: 'note_5',
    },
    {
      label: 'Notes',
      icon: 'clipboard',
      isEnabled: false,
      id: 'note_6',
    },
  );
}
