/** @format */

import React from 'react';
import { WizardProvider } from './WizardContext';
import { WizardDropdownContainer } from './WizardDropdownContainer';

export const WizardWrapped: React.FC = () => {
  return (
    <WizardProvider>
      <WizardDropdownContainer />
    </WizardProvider>
  );
};

(window as any).WizardWrapped = WizardWrapped;
