/** @prettier */
import { PDFLayoutFunc } from '../types';
import { createDocument } from '../helpers/createDocument';
import * as whilst from 'async/whilst';
import { createPDFListPage } from '../helpers/createPDFListPage';
import { finishPDF } from '../helpers/finishPDF';
import { isNumber } from 'underscore';

export const PDFFrameListLayout: PDFLayoutFunc = async (props, frames) => {
  const docInfo = await createDocument(props, 'portrait');

  const avgFramesPerPage = 5;

  // When not in preview mode we want to pass along all the frames to the loop
  let framesStartIndex = 0;
  let framesEndIndex = frames.length;

  if (props.isQuickMode && isNumber(props.pageNumber)) {
    framesStartIndex = props.pageNumber * avgFramesPerPage;
    framesEndIndex = framesStartIndex + avgFramesPerPage;
  } else if (props.isTrialPreview) {
    // If we're doing a trial preview, we also only want to use 1 page worth
    // of frames
    framesEndIndex = framesStartIndex + avgFramesPerPage;
  }

  const framesToUse = frames.slice(framesStartIndex, framesEndIndex);
  let hasRenderedPage = false;

  /**
   * We are going to use an async while loop, which we want to continue
   * as long as we still have frames to use. However, if we're in a trial
   * preview (a.k.a. watermarked), we only want to render if we haven't already
   * rendered a page
   */
  const renderWhile = () => {
    return (
      framesToUse.length > 0 && (!props.isTrialPreview || !hasRenderedPage)
    );
  };

  return new Promise((resolve, reject) => {
    whilst(
      renderWhile,
      (done) => {
        const framesForPage = framesToUse.slice(0, avgFramesPerPage);
        createPDFListPage(docInfo, props, framesForPage, (err, framesUsed) => {
          if (err) return done(err);
          framesToUse.splice(0, framesUsed);
          hasRenderedPage = true;

          done();
        });
      },

      async (err) => {
        if (err) return reject(err);

        return finishPDF({
          docInfo,
          props,
          callback: resolve,
          tooMuchText: false,
        });
      },
    );
  });
};
