/** @prettier */
import type { CommentStore } from '../stores/comment';
import { createTypedActions } from '../stores/AltStore';

class CommentActions {
  receive: CommentStore['handleReceive'] = function (data) {
    this.dispatch(data);
  };

  fetch: CommentStore['handleFetch'] = function (data) {
    this.dispatch(data);
  };

  fetchIfNecessary = function (data) {
    this.dispatch(data);
  };

  save: CommentStore['handleSave'] = function (data) {
    this.dispatch(data);
  };

  update: CommentStore['handleUpdate'] = function (data) {
    this.dispatch(data);
  };

  delete: CommentStore['handleDelete'] = function (data) {
    this.dispatch(data);
  };

  acknowledge: CommentStore['handleAcknowledge'] = function (data) {
    this.dispatch(data);
  };

  openFrameCommentBox: CommentStore['handleOpenFrameCommentBox'] = function (
    data,
  ) {
    this.dispatch(data);
  };

  toggleFrameCommentBox: CommentStore['handleToggleFrameCommentBox'] =
    function (data) {
      this.dispatch(data);
    };

  closeFrameCommentBox: CommentStore['handleCloseFrameCommentBox'] =
    function () {
      this.dispatch();
    };

  openPanelbarCommentBox: CommentStore['handleOpenPanelbarCommentBox'] =
    function (data) {
      this.dispatch(data);
    };

  setActiveFrame: CommentStore['handleSetActiveFrame'] = function (data) {
    this.dispatch(data);
  };

  clearActiveFrame: CommentStore['handleClearActiveFrame'] = function () {};

  updateNewCommentData: CommentStore['handleUpdateNewCommentData'] = function (
    data,
  ) {
    this.dispatch(data);
  };
}

const actions = createTypedActions<CommentActions>(CommentActions);
export { actions as CommentActions };

(window as any).CommentActions = actions;
