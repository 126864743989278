window.InvoiceActions = alt.createActions(function () {

  this.fetch = function() {
    this.dispatch();
  };
  this.fetchStatus = function() {
    this.dispatch();
  };

});
