/** @prettier */
import * as React from 'react';
import Dialog from 'blackbird/components/dialog/Dialog';
import { ProjectsAccordion } from 'blackbird/components/projects/ProjectsAccordion';
import { ProjectsContext } from 'blackbird/components/projects/ProjectsContext';

export const ProjectBulkMoveDialog: React.FC = () => {
  const {
    isBulkMoveModalOpen,
    setDisabledMoveTargetProjectIds,
    setDisabledMoveTargetParentIds,
    setIsBulkMoveModalOpen,
    selectedProjects,
    selectedStoryboards,
    newProjectGroup,
    newProjectParent,
    moveItems,
  } = React.useContext(ProjectsContext);

  const totalSelected = React.useMemo(() => {
    return selectedProjects.length + selectedStoryboards.length;
  }, [selectedProjects, selectedStoryboards]);

  const handleClose = () => {
    setIsBulkMoveModalOpen(false);
    setDisabledMoveTargetProjectIds(new Set());

    setDisabledMoveTargetParentIds(new Set());
  };

  const noun = React.useMemo(() => {
    if (selectedProjects.length && selectedStoryboards.length) {
      return 'item';
    } else if (selectedProjects.length) {
      return 'project';
    } else if (selectedStoryboards.length) {
      return 'storyboard';
    } else {
      return 'item';
    }
  }, [selectedProjects, selectedStoryboards]);

  // Disable the button unless there's a parent or group present
  const confirmBtnProps = { disabled: !newProjectGroup && !newProjectParent };

  return !isBulkMoveModalOpen ? null : (
    <Dialog
      isDark
      size={'sm'}
      title={`Move ${totalSelected} ${noun}${
        totalSelected === 1 ? '' : 's'
      } to...`}
      isOpen
      keepOpen
      hideActionDivider
      confirmText={`Move`}
      confirmBtnProps={confirmBtnProps}
      onConfirmBtnClick={moveItems}
      onCancelBtnClick={handleClose}
      onCloseBtnClick={handleClose}
      onEscapeOrOutsideClick={handleClose}
    >
      <div className="border rounded-sm border-form">
        <ProjectsAccordion isSelectionMode />
      </div>
    </Dialog>
  );
};
