/** @format */
import * as React from 'react';
import { IconSpacer, IconSpacerGroup } from 'javascripts/components/IconSpacer';
import { ToolbarButton } from 'javascripts/components/ToolbarButton';
import ArrowIcon from 'blackbird/images/icons/shape-arrow.svg';
import LineIcon from 'blackbird/images/icons/shape-line.svg';
import EraserIcon from 'blackbird/images/icons/erase.svg';
import UndoIcon from 'blackbird/images/icons/undo-2.svg';
import DrawIcon from 'blackbird/images/icons/draw.svg';
import RedoIcon from 'blackbird/images/icons/redo-2.svg';
import { ToolbarSelect } from 'javascripts/components/frame_editor/toolbar/ToolbarInput';
import { brushSizes } from 'javascripts/components/frame_editor/frameEditorDefaults';
import { ColorPicker } from 'javascripts/components/shared/ColorPicker.react';
import { ColorPickerWellButton } from 'javascripts/components/shared/ColorPickerWellButton';
import SelectIcon from 'blackbird/images/icons/move-2.svg';
import DeleteIcon from 'blackbird/images/icons/delete-2.svg';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';
import { useTranslation } from 'react-i18next';
import { useStore } from 'javascripts/helpers/useStore';
import type {
  AnnotationToolEngine,
  AnnotationToolSettings,
} from './AnnotationToolEngine';
import type { CommentAnnotationsStore } from 'javascripts/flux/stores/commentAnnotations';
import { pick } from 'underscore';

interface Props {
  onDelete: () => void;
  compact?: boolean;
}

export const AnnotationToolbar = React.memo<Props>((props) => {
  const dialogContext = React.useContext(DialogContext);
  const currentEngine = useStore<
    AnnotationToolEngine | null,
    CommentAnnotationsStore
  >('commentAnnotations', (s) => s.engine);
  const settings = useStore<
    AnnotationToolSettings | undefined,
    CommentAnnotationsStore
  >('commentAnnotations', (s) => s.settings);
  const { canUndo, canRedo } = useStore<
    Record<string, boolean>,
    CommentAnnotationsStore
  >('commentAnnotations', (s) => pick(s, 'canUndo', 'canRedo'));

  const { t } = useTranslation();

  const handleUpdateSetting = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (e) => {
      if (!currentEngine) return;
      const { name, value } = e.currentTarget;

      currentEngine.receiveOptions({
        [name]: value,
      });
    },
    [currentEngine],
  );

  const handleDeleteClick = React.useCallback(async () => {
    // Components might rely on us firing this when closing, so let's fire it
    if (currentEngine?.isEmpty) return props.onDelete();

    if (
      !currentEngine?.isEmpty &&
      (await openConfirmDialog(t('annotations.delete.confirm'), dialogContext))
    ) {
      return props.onDelete();
    }
  }, [currentEngine, dialogContext, props, t]);

  if (!settings) return null;
  const mode = settings.mode;
  const hasColors = mode !== 'eraser';

  return (
    <IconSpacer
      className="bg-surface-light pl-3 xl:pl-5 py-1.5 mb-2 type-text-sm w-full border-y border-border"
      spacing="normal"
    >
      <IconSpacerGroup>
        <ToolbarButton
          activeClassName="!bg-surface-add_frame"
          labelKeyPrefix="annotations.toolbar"
          value="select"
          name="mode"
          onClick={handleUpdateSetting}
          icon={<SelectIcon />}
          active={mode === 'select'}
          small
        />
        <ToolbarButton
          activeClassName="!bg-surface-add_frame"
          labelKeyPrefix="annotations.toolbar"
          value="draw"
          name="mode"
          onClick={handleUpdateSetting}
          icon={<DrawIcon />}
          active={mode === 'draw'}
          small
        />

        <ToolbarButton
          activeClassName="!bg-surface-add_frame"
          labelKeyPrefix="annotations.toolbar"
          value="arrow"
          icon={<ArrowIcon />}
          name="mode"
          onClick={handleUpdateSetting}
          active={mode === 'arrow'}
          small
        />

        <ToolbarButton
          activeClassName="!bg-surface-add_frame"
          className="hidden xl:inline"
          labelKeyPrefix="annotations.toolbar"
          value="straightBrush"
          icon={<LineIcon />}
          active={mode === 'straightBrush'}
          name="mode"
          onClick={handleUpdateSetting}
          small
        />
        <ToolbarButton
          activeClassName="!bg-surface-add_frame"
          labelKeyPrefix="annotations.toolbar"
          value="eraser"
          name="mode"
          onClick={handleUpdateSetting}
          icon={<EraserIcon />}
          active={mode === 'eraser'}
          small
        />
      </IconSpacerGroup>
      <IconSpacerGroup>
        <ToolbarSelect
          className="w-12 shrink-0"
          value={settings.strokeWidth}
          onChange={(e) =>
            currentEngine?.receiveOptions({
              strokeWidth: parseInt(e.currentTarget.value, 10),
            })
          }
        >
          {brushSizes.map((o) => (
            <option key={o.value} value={o.value}>
              {o.value}
            </option>
          ))}
        </ToolbarSelect>

        <ColorPicker
          color={settings.color}
          onChange={(value) => currentEngine?.receiveOptions({ color: value })}
          disabled={hasColors}
          icon={<ColorPickerWellButton color={settings.color} />}
          // Brushes don't do well with alpha
          showAlpha={false}
          updateOnChange={false}
        />
      </IconSpacerGroup>

      {!props.compact && (
        <IconSpacerGroup className="hidden xl:inline">
          <ToolbarButton
            className="hidden xl:inline"
            labelKeyPrefix="actions"
            value="undo"
            icon={<UndoIcon />}
            small
            onClick={currentEngine?.undo}
            disabled={!canUndo}
          />

          <ToolbarButton
            className="hidden xl:inline"
            labelKeyPrefix="actions"
            value="redo"
            icon={<RedoIcon />}
            small
            onClick={currentEngine?.redo}
            disabled={!canRedo}
          />
        </IconSpacerGroup>
      )}

      <ToolbarButton
        labelKey="annotations.delete.button"
        icon={<DeleteIcon />}
        onClick={handleDeleteClick}
        small
      />
    </IconSpacer>
  );
});

AnnotationToolbar.displayName = 'AnnotationToolbar';
