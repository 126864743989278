/** @prettier */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentTaxIdForm from 'javascripts/components/payment/PaymentTaxIdForm';
import { RequestActions } from 'javascripts/flux/actions/request';
import { LocalState } from 'javascripts/helpers/local-state';
import { ONBOARDING_STORYBOARD_REDIRECT_URL } from 'javascripts/flux/stores/storyboard';

const AddTaxId: React.FC = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'payment',
  });

  useEffect(() => {
    Track.event.defer('ob_show_tax_form', { category: 'Onboarding' });
  }, []);

  const handleSubmit = async (event) => {
    Track.event.defer('ob_save_tax_form', { category: 'Onboarding' });
    RequestActions.redirect.defer(
      LocalState.getValue(ONBOARDING_STORYBOARD_REDIRECT_URL) || '/',
    );
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex items-center justify-center flex-grow"
    >
      <fieldset className="flex flex-col flex-grow max-w-md space-y-6">
        <legend className="text-2xl text-type-primary">{t('title')}</legend>
        <span className="text-type-subdued">{t('subtitle')}</span>
        <PaymentTaxIdForm callback={handleSubmit} />
      </fieldset>
    </form>
  );
};

export default AddTaxId;
