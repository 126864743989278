/** @prettier */
import logger from 'javascripts/helpers/logger';
import { type PDFPage, type PDFDocument as PDFDoc } from 'pdf-lib';

/**
 * `fetch`es a PDF file by url and returns its cover page as a `PDFPage`
 * prepared for the target document */
export const copyPDFPage = (url: string, targetDocument: PDFDoc) =>
  new Promise<PDFPage>((resolve, reject) => {
    Promise.all([import('pdf-lib'), fetch(url)])
      .then(async ([{ PDFDocument }, response]) => {
        const arrayBuffer = await response.arrayBuffer();
        const { width, height } = targetDocument.getPage(0).getSize();
        const targetAspectRatio = width / height;

        if (!arrayBuffer) return reject('response is empty');
        PDFDocument.load(arrayBuffer)
          .then((coverDocument) => {
            const coverPage = coverDocument.getPage(0);
            const pageDimensions = coverPage.getSize();
            const originalAspectRatio =
              pageDimensions.width / pageDimensions.height;

            // If the aspect ratio is the same-ish for both documents, we will
            // resize the cover page to match our PDF export, that way it looks
            // a tad more professional
            const tolerance = 0.05;
            const shouldResize =
              Math.abs(originalAspectRatio - targetAspectRatio) < tolerance &&
              pageDimensions.width !== width;

            if (shouldResize) {
              const scale = width / pageDimensions.width;
              logger.log(`Resizing PDF cover page to match export dimensions`);
              coverPage.scale(scale, scale);
            }

            targetDocument
              .copyPages(coverDocument, [0])
              .then((pages) => {
                resolve(pages[0]);
              })
              .catch(reject);
          })
          .catch(reject);
      }, reject)
      .catch(reject);
  });
