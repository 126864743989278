/**@prettier */
const _ = require('underscore');
const createReactClass = require('create-react-class');
var PropTypes = require('prop-types');
window.ExportPdfPreview = createReactClass({
  propTypes: {
    cover: PropTypes.object.isRequired,
    storyboard: PropTypes.object.isRequired,
  },

  render: function () {
    var c = this.props.cover.cover,
      s = c.storyboard,
      imageClass = '',
      frameClass = '',
      containerClass = ' py-4 px-2 ',
      framesPerPage = 0;

    imageClass += s.has_frame_border ? ' b--silver ' : ' b--moon-gray  ';

    if (_.isNull(this.props.storyboard.pdf_layout)) {
      imageClass += ' pb-[56.25%] ';
    } else if (this.props.storyboard.pdf_layout.indexOf('portrait') > -1) {
      imageClass += ' py-[11px] ';
    } else if (this.props.storyboard.pdf_layout.indexOf('9x16') > -1) {
      imageClass += ' pb-[178%] ';
    } else {
      imageClass +=
        this.props.storyboard.pdf_layout.indexOf('1x1') > -1
          ? ' pb-[89%] '
          : ' pb-[56.25%] ';
    }

    switch (this.props.storyboard.pdf_layout) {
      case 'six-up':
        framesPerPage = 6;
        frameClass = ' w-1/3 px-2 float-left mb-2 ';
        break;
      case 'five-up-portrait':
        framesPerPage = 5;
        frameClass = ' w-full flex ';
        imageClass += ' w-[40%] mr-2 ';
        containerClass = ' p-[10px] ';
        break;
      case 'four-up':
        framesPerPage = 4;
        frameClass = ' w-1/2 px-[12px] float-left mb-2 ';
        containerClass = ' py-[10px] px-[25px] ';
        break;
      case 'three-up-9x16':
        framesPerPage = 3;
        frameClass = ' w-1/3 ph-[5px] float-left mb-2 ';
        containerClass = ' py-[10px] px-[5px] ';
        break;
      case 'three-up-1x1':
        framesPerPage = 3;
        frameClass = ' w-1/3 px-[5px] float-left mb-2 ';
        containerClass = ' py-[30px] px-[5px] ';
        break;
      case 'two-up-1x1':
        framesPerPage = 2;
        frameClass = ' w-1/2 px-2 float-left mb-2 ';
        containerClass = ' py-[20px] px-[10px] ';
        break;
      case 'two-up':
        framesPerPage = 2;
        frameClass = ' w-1/2 px-2 float-left mb-2 ';
        containerClass = ' py-[30px] px-[10px] ';
        break;
      case 'one-up-1x1':
        framesPerPage = 1;
        frameClass = ' w-full px-[20px] float-left mb-2  ';
        containerClass = ' py-[15px] px-[30px] ';
        break;
      case 'one-up':
        framesPerPage = 1;
        frameClass = ' w-full float-left mb-2 ';
        containerClass = ' py-[20px] px-[30px] ';
        break;
      default:
        containerClass = ' p-4 px-2 ';
    }

    return (
      <div className={'absolute w-full h-full cf ' + containerClass}>
        {_.times(framesPerPage, function (n) {
          return (
            <div key={'frame-preview-' + n} className={'' + frameClass}>
              <div
                className={
                  'border border-type-subdued bg-surface-light mb-1 ' +
                  imageClass
                }
              />

              <div className="flex-auto">
                {s.include_frame_number_in_pdf ||
                s.include_label_text_in_output ? (
                  <div className="flex mb-[3px]">
                    {s.include_frame_number_in_pdf ? (
                      <div className="bg-type-subdued w-[2px] h-[2px] rounded-full mr-1" />
                    ) : null}
                    {s.include_label_text_in_output ? (
                      <div className="bg-surface h-[2px] flex-auto rounded-sm " />
                    ) : null}
                  </div>
                ) : null}

                {_.times(2, function (m) {
                  return (
                    <div key={'line-' + m + n} className="flex mb-[3px]">
                      {s.include_icons_in_pdf ? (
                        <div className="bg-type-subdued w-[2px] h-[2px] rounded-full mr-1" />
                      ) : null}
                      <div className="bg-surface h-[2px] flex-auto rounded-sm " />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        <div className="absolute bottom-2 right-2 left-2 flex items-center justify-between">
          <div className="flex gap-1">
            {!s.hide_storyboard_title && (
              <div className="bg-type-subdued h-[2px] w-8 rounded-sm" />
            )}
            {s.include_version_number && !s.hide_storyboard_title && (
              <div className="bg-type-subdued h-[2px] w-1 rounded-sm" />
            )}
          </div>
          <ExportPdfLogoPreview
            url={s.pdf_footer_logo}
            className="lh-solid w-[35px] "
          />
        </div>
      </div>
    );
  },
});
