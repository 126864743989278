import React, { useState, useEffect } from 'react'
const PropTypes = require('prop-types');

const updateAjaxCall = (args) => {
  ajaxHelper.ajax({
    method: "put",
    url: `/admin/team_memberships/${args.id}`,
    data: args.data,
    success: function () {
      RequestActions.success.defer('Updated');
    }.bind(this),
    error: function(response, text) {
      RequestActions.error.defer(response.responseJSON.error);
    }.bind(this),
  })
}

const AdminMembershipRoleToggle = (props) => {
  const [role, setRole] = useState()

  const roles = ['admin', 'manager', 'supermember', 'member', 'archived']
  const roleIcons = ['🏆', '🥇', '🥈', '🥉', '💀']

  useEffect(() => {
    setRole(props.role)
  }, [props.role])

  const saveRole = (value) => {
    if (props.role === 'admin') {
      alert("Can't change an admin role");
    } else {
      updateAjaxCall({
        id: props.id,
        data: {
          team_membership: {
            role: value
          }
        }
      })
      setRole(value)
    }
  }

  return (
    <select value={role} onChange={(e) => saveRole(e.target.value)} className="inline-block text-sm py-1 px-2 border border-border-mid bg-white mx-2 rounded-sm capitalize">
      {roles.map((r, index) => {
        return (
          <option key={`role-${index}`} value={r}>
            {`${roleIcons[index]}${r}`}
          </option>
        )
      })}
    </select>
  )
}

window.AdminMembershipRoleToggle = AdminMembershipRoleToggle
