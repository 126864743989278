/**@prettier */
const createReactClass = require('create-react-class');
var { ColorPickerForCover } = require('../../shared/ColorPickerForCover');
var PropTypes = require('prop-types');
const {
  default: TextInput,
} = require('blackbird/components/form/text-input/TextInput');
import format from 'date-fns/format';
window.ExportPdfCoverBuilder = createReactClass({
  propTypes: {
    storyboard: PropTypes.object.isRequired,
    cover: PropTypes.object.isRequired,
  },

  _updateDocumentSecondaryInfo: function (e) {
    CoverpageActions.updateValue({
      attr: 'storyboard.document_secondary_info',
      value: e.target.value,
    });
  },

  _useDateForDocumentSecondaryInfo: function (e) {
    e.preventDefault();
    CoverpageActions.updateValue({
      attr: 'storyboard.document_secondary_info',
      value: format(new Date(), 'd MMM yyyy'),
    });
  },

  render: function () {
    var cover = this.props.cover;

    return (
      <div className="flex flex-col gap-6">
        <div className="text-lg">PDF Cover Builder</div>
        <div className="flex gap-4">
          <ColorPickerForCover
            label="Background"
            cover={cover}
            field="background_color"
            color={cover.theme.background_color}
          />

          <ColorPickerForCover
            label="Text Color"
            cover={cover}
            field="foreground_color"
            color={cover.theme.foreground_color}
          />

          <TextInput
            labelClasses="font-bold"
            label="Footer text"
            inputSize="md"
            value={cover.storyboard.document_secondary_info || ''}
            onChange={this._updateDocumentSecondaryInfo}
          />
        </div>
        <CoverpageDocument
          {...this.props}
          foregroundColor={cover.theme.foreground_color}
          backgroundColor={cover.theme.background_color}
        />
      </div>
    );
  },
});
