/** @prettier */
import { AnnotationPreviewDialog } from 'blackbird/components/comments/annotations/AnnotationPreviewDialog';
import { GeneratorCharacterProvider } from 'blackbird/components/generator/guidelines/GeneratorCharacterContext';
import { WizardProvider } from 'blackbird/components/wizard/WizardContext';
import { posthogClient } from 'javascripts/helpers/posthog';
import { PostHogProvider } from 'posthog-js/react';
const _ = require('underscore');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const {
  ScriptEditorStoryboardView,
} = require('../script_editor/ScriptEditorStoryboard');
const {
  ScriptEditorStoryboardBanner,
} = require('../script_editor/ScriptEditorStoryboardBanner');
const navigateToRoute = require('../../helpers/router/navigate-to-route');
const { StoryboardFTU } = require('./StoryboardFTU');
const {
  StoryboardPanelbarContainer,
} = require('../panelbars/StoryboardPanelbarContainer');
const { ErrorBoundary } = require('../shared/ErrorBoundary');
const { BoordsDndProvider } = require('../shared/BoordsDndProvider');
const { frameUndoManager } = require('../../helpers/undo-stacks');
const { PlayerActions } = require('../../flux/actions/player');
const { ShotlistContainer } = require('../shotlist/ShotlistContainer');
const shotlistConstants = require('../shotlist/ShotlistConstants');
const {
  onLoadAfterBackButton,
} = require('../../helpers/onLoadAfterBackButton');
const { StoreContextProvider } = require('../../flux/StoreContextProvider');
const { allTheStores } = require('../../flux/allTheStores');
const { allTheActions } = require('javascripts/flux/allTheActions');
const {
  default: SidebarLayout,
} = require('blackbird/components/layout/Sidebar');
const { default: classNames } = require('classnames');
const {
  default: DialogContextProvider,
} = require('blackbird/components/dialog/DialogContext');
const {
  BoordsAiProvider,
} = require('blackbird/helpers/contexts/BoordsAiContext');
const { RequestActions } = require('javascripts/flux/actions/request');
const {
  GeneratorProvider,
} = require('blackbird/components/generator/GeneratorContext');

const ToursDebug = React.lazy(() => import('../tours/ToursDebug'));

window.Storyboard = createReactClass({
  propTypes: {
    storyboard: PropTypes.object.isRequired,
    ftu_variant: PropTypes.string,
  },

  getInitialState: function () {
    var state = StoryboardStore.getState();
    state.scriptEditorOpen = false;
    state.panelbarIsOpen = PanelbarStore.getState().isOpen;
    state.isHidden = !!FrameFocusStore.getState().mode;
    state.selectedFrames = FrameStore.getState().selectedFrames;
    return state;
  },

  componentDidMount: function () {
    StoryboardStore.listen(this._onChange);
    FrameStore.listen(this._onChange);
    StoryboardActions.setStoryboard(this.props.storyboard);
    PanelbarStore.listen(this._onPanelbarChange);
    FrameFocusStore.listen(this._onFrameFocusStoreChange);
    ScriptEditorStore.listen(this._onScriptEditorStoreChange);
    frameUndoManager.enterContext(FrameActions.updateFrameHistory);

    window.Mousetrap.bind('s', () => {
      if (this.state.frames[0]) {
        navigateToRoute('frameEditor', {
          slug: this.props.storyboard.slug,
          frameIndex: this.state.frames[0].sort_order,
        });
      }
    });
    window.Mousetrap.bind('command+s', (e) => {
      RequestActions.success('Boords automatically saves your work');
      e.preventDefault();
    });
    onLoadAfterBackButton(StoryboardActions.reload.defer);
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {
    StoryboardActions.fetch({ slug: nextProps.storyboard_slug });
  },

  componentWillUnmount: function () {
    StoryboardStore.unlisten(this._onChange);
    FrameStore.unlisten(this._onChange);
    PanelbarStore.unlisten(this._onPanelbarChange);
    ScriptEditorStore.unlisten(this._onScriptEditorStoreChange);
    FrameFocusStore.unlisten(this._onFrameFocusStoreChange);
    frameUndoManager.leaveContext();
  },

  _onFrameFocusStoreChange({ mode }) {
    if (this.state.isHidden !== !!mode) this.setState({ isHidden: !!mode });
  },

  _onScriptEditorStoreChange(state) {
    this.setState({ scriptEditorOpen: state.isOpen });
  },

  _onPanelbarChange: function (state) {
    this.setState({ panelbarIsOpen: state.isOpen });
  },

  _onChange: function (state) {
    if (state.storyboard && this.state.storyboard) {
      history.replaceState(
        {},
        state.storyboard.document_name,
        '/storyboards/' + state.storyboard.slug,
      );
    }
    this.setState(state);

    if (state.storyboard) {
      if (window.location.hash === '#animatic') {
        setTimeout(
          function () {
            PlayerActions.open.defer({
              frame: this.state.frames[0],
              storyboard: this.state.storyboard,
              isEditable: window.BoordsConfig.TeamRole !== 'guest',
            });
          }.bind(this),
          200,
        );
        window.location.hash = '';
      }
    }
  },

  render: function () {
    if (
      !this.state.storyboard ||
      _.isUndefined(this.state.storyboard) ||
      this.state.isHidden
    ) {
      return null;
    } else {
      if (this.state.simplified_view) {
        return <StoryboardFTU {...this.props} {...this.state} />;
      }
      const frameCount = this.state.frames ? this.state.frames.length : 0;

      var selectedFrames = this.state.selectedFrames;

      var panelbarIsOpenClass = this.state.panelbarIsOpen
        ? 'storyboard__has-panelbar'
        : '';

      let containerClass =
        'flex-auto p-5 bg-surface relative container__section ' +
        (frameCount > 0 ? 'container__section__has-drawer' : '');

      if (this.state.view === shotlistConstants.SHOTLIST)
        containerClass = 'flex-auto ';

      return (
        <PostHogProvider client={posthogClient()}>
          <StoreContextProvider stores={allTheStores} actions={allTheActions}>
            <BoordsAiProvider>
              <WizardProvider>
                <GeneratorProvider>
                  <GeneratorCharacterProvider>
                    <BoordsDndProvider>
                      <DialogContextProvider>
                        <SidebarLayout>
                          <SidebarLayout.Main>
                            <div
                              className={classNames(
                                'storyboard flex flex-col storyboard--editable min-h-vp ' +
                                  panelbarIsOpenClass +
                                  ' viewing-as-' +
                                  this.state.view,
                                this.state.panelbarIsOpen
                                  ? 'w-sidebar-main'
                                  : 'w-full',
                              )}
                            >
                              {this.state.storyboard.is_archived && (
                                <FlyoverArchivedStoryboardContainer
                                  {...this.state}
                                />
                              )}

                              {this.state.storyboard.ai_script_generating && (
                                <FlyoverAiScriptGenerator {...this.state} />
                              )}

                              <FlyoverImageGenerator />

                              <div
                                className={
                                  'storyboard__saving-overlay ' +
                                  (this.state.is_saving ? 'is--active' : '')
                                }
                              />

                              <StoryboardHeader
                                {...this.state}
                                selectedFrames={selectedFrames}
                              >
                                {this.state.scriptEditorOpen
                                  ? (padding) => (
                                      <ScriptEditorStoryboardBanner
                                        className={padding}
                                      />
                                    )
                                  : null}
                              </StoryboardHeader>

                              <ErrorBoundary>
                                <section className={containerClass}>
                                  {this.state.scriptEditorOpen &&
                                  this.state.frames?.length ? (
                                    <ScriptEditorStoryboardView
                                      frames={this.state.frames}
                                      state={this.state}
                                      storyboard={this.state.storyboard}
                                    />
                                  ) : this.state.view ===
                                      shotlistConstants.SHOTLIST &&
                                    this.state.frames?.length > 0 ? (
                                    <ShotlistContainer
                                      frames={this.state.frames}
                                      storyboard={this.state.storyboard}
                                      showGridViewColumn={
                                        this.state.showGridViewCommentColumn
                                      }
                                    />
                                  ) : this.state.frames ? (
                                    <FramesContainer
                                      selectedFrames={selectedFrames}
                                      {...this.state}
                                    />
                                  ) : null}
                                </section>
                              </ErrorBoundary>

                              <AnnotationPreviewDialog />

                              {process.env.NODE_ENV === 'development' && (
                                <React.Suspense fallback={null}>
                                  <ToursDebug />
                                </React.Suspense>
                              )}
                            </div>
                          </SidebarLayout.Main>
                          {this.state.panelbarIsOpen && (
                            <SidebarLayout.Bar className="fixed right-0 flex flex-col h-full overflow-y-auto bg-white shadow-lg max-h-dvh z-sidebar">
                              <StoryboardPanelbarContainer />
                            </SidebarLayout.Bar>
                          )}
                        </SidebarLayout>
                      </DialogContextProvider>
                    </BoordsDndProvider>
                  </GeneratorCharacterProvider>
                </GeneratorProvider>
              </WizardProvider>
            </BoordsAiProvider>
          </StoreContextProvider>
        </PostHogProvider>
      );
    }
  },
});
