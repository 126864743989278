/** @format */
/* eslint-disable no-irregular-whitespace */

import * as DOMPurify from 'isomorphic-dompurify';
import logger from 'javascripts/helpers/logger';
import { forEach } from 'underscore';

const sanitizeOptions: DOMPurify.Config = {
  // prettier-ignore
  ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'code', 'br', 'li','ul','ol'],
  ALLOWED_ATTR: ['href', 'target', 'rel'],
};

export { sanitizeOptions as defaultSanitizeOptions };

const sanitizeAsFragmentOptions = {
  ...sanitizeOptions,
  RETURN_DOM_FRAGMENT: true,
};

/** Sanitizes HTML to allow only a set of tags. You can extend this by passing
 * your own `DOMPurify.Config`. */
export function sanitizeHTML(
  string: string,
  asFragment: true,
  config?: DOMPurify.Config,
): DocumentFragment;
export function sanitizeHTML(
  string: string,
  asFragment: false,
  config?: DOMPurify.Config,
): string;
export function sanitizeHTML(
  string: string,
  asFragment: boolean,
  config?: DOMPurify.Config,
) {
  let options = asFragment ? sanitizeAsFragmentOptions : sanitizeOptions;
  if (config) options = { ...options, ...config };
  const hookName = 'beforeSanitizeElements';

  function sanitizeNode(currentNode: Element) {
    // We want to replace header tags with <strong> tags, because we don't allow
    // headers here.
    if (/H[0-9]/.test(currentNode.nodeName)) {
      const strong = document.createElement('strong');
      strong.innerHTML = currentNode.innerHTML;
      const parent = currentNode.parentElement!;

      if (parent.firstChild !== currentNode) {
        // First, let's add a line break
        parent.insertBefore(document.createElement('br'), currentNode);
      }

      // Replace the node with a "strong" element
      currentNode.replaceWith(strong);
    } else if (currentNode instanceof HTMLDivElement) {
      // When copying list-like content from powerpoint, divs might appear in
      // places where there ought to be paragraphs
      // https://www.notion.so/presentable/Word-doc-formatting-65e20527d983449f88c9cbbbacd86bf0?pvs=4
      const newParagraph = document.createElement('p');
      newParagraph.innerHTML = currentNode.innerHTML;

      // Replace the node with a "strong" element
      currentNode.replaceWith(newParagraph);
    } else if (
      currentNode instanceof HTMLAnchorElement ||
      currentNode.tagName === 'A'
    ) {
      // This is super weird, but somehow instanceof returns false for some data
      const node = currentNode as HTMLAnchorElement;
      node.target = '_blank';
      node.rel = 'noopener noreferrer';
    } else if (
      // A specific fix for Google Docs, which (when using chrome) copies
      // regular text as `<b style="font-weight:normal;">`. I kid you not.
      currentNode instanceof HTMLElement &&
      currentNode.tagName === 'B' &&
      currentNode.style.fontWeight === 'normal'
    ) {
      /** Since google docs places P tags inside these B tags, we want to just
       * 'unwrap' the children and remove the B tag */
      try {
        // Make sure the children are sanitised as well as there is a chance
        // they will be skipped when we change the DOM structure like this.
        currentNode.childNodes.forEach(sanitizeNode);
        // Place the children after the original element
        currentNode.after(...Array.from(currentNode.childNodes));
        currentNode.remove();
        logger.log('replaced Google Docs <b> tag');
      } catch (error) {
        logger.error('could not replace Google Docs <b> tag', error);
      }
    } else if (currentNode.tagName === 'SPAN') {
      const style = (currentNode as HTMLElement).style;
      const fontWeight = style.fontWeight;

      /** The node that will replace `currentNode`, either `B` or `I` */
      let replacementNode: HTMLElement | undefined;
      /** The node that we will move existing content into, in case we're
       * nesting bold and italic nodes */
      let contentElement: HTMLElement | undefined = undefined;

      if (
        fontWeight === 'bold' ||
        fontWeight === 'bolder' ||
        parseInt(fontWeight) > 400
      ) {
        const tag = document.createElement('b');
        contentElement = tag;
        replacementNode = tag;
      }

      if (style.fontStyle === 'italic') {
        const tag = document.createElement('i');

        // We will be putting the content of `currentElement` in this new node
        contentElement = tag;
        // it's possible that the replacement node is already set, for example
        // if a span has both the font weight and font style set. In that case
        // we would already be inside a replacementNode, and we just have to
        // make sure our new <i> tag will be put in there
        if (replacementNode) {
          replacementNode.append(tag);
        } else {
          replacementNode = tag;
        }
      }

      if (
        typeof replacementNode !== 'undefined' &&
        typeof contentElement !== 'undefined'
      ) {
        // A regular forEach seems to skip an item, odd
        forEach(currentNode.childNodes, (child) => {
          contentElement!.append(child.cloneNode(true));
        });

        currentNode.replaceWith(replacementNode);
        // tell the rest of the script that it should act on the replacement
        // node instead
        currentNode = replacementNode;
      }
    }

    // We want to clean nbsp's from our data, as they're not visible
    // anyway and likely unintentional.
    if (
      // We only want to  process text nodes, otherwise we might remove
      // formatting and line breaks
      currentNode.TEXT_NODE === currentNode.nodeType &&
      currentNode.textContent
    ) {
      const hasLeadingNbsp =
        currentNode.textContent.indexOf(' ') >= 0 ||
        currentNode.textContent.indexOf(' ') >= 0 ||
        currentNode.textContent.indexOf('\u00A0') >= 0;

      if (
        hasLeadingNbsp &&
        // Plain spaces (in between elements) should be ignored
        currentNode.textContent !== ' '
      ) {
        // Remove spaces at the beginning. They won't be displayed in
        // contentEditable fields anyway, and thus confuse output
        const replacement =
          currentNode.parentElement?.firstChild === currentNode ? '' : ' ';

        currentNode.textContent = currentNode.textContent.replace(
          /^\s+/g,
          replacement,
        );
      }

      // clear out double spacing in between words
      currentNode.textContent = currentNode.textContent.replace(
        / {2,999}/g,
        ' ',
      );

      // at this point, we should not have line breaks or returns left, if
      // they're still there, we should replace them, as they might trip up
      // other parts of the app (export features)
      // an extra space character is better than a missing one between words!
      currentNode.textContent = currentNode.textContent.replace(/\n|\r/g, ' ');
    }

    return currentNode;
  }

  DOMPurify.addHook(hookName, sanitizeNode);
  const output = DOMPurify.sanitize(string, options) as any;
  DOMPurify.removeHooks(hookName);
  return output;
}

/** removes empty paragraphs from the start and end */
export const trimHTML = (string: string): string => {
  return (
    string
      .replace(/^(\n?<p>(?:<br>)?<\/p>)+/, '')
      .replace(/(\n?<p>(?:<br>)?<\/p>)+$/, '')
      // Frames that are empty except for empty paragraphs
      .replace(/^(<p>(?:<br>)?<\/p>)+$/, '')
      .trim()
  );
};
