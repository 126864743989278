/** @format */
import * as React from 'react';
import CommentsIcon from 'blackbird/images/icons/comment-2.svg';
import Button from 'blackbird/components/button/Button';

interface Props {
  count: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tabIndex?: number;
}

export const FrameCommentCount = React.memo<Props>(
  ({ count, onClick, ...buttonProps }) =>
    count > 0 ? (
      <Button
        {...buttonProps}
        icon={<CommentsIcon />}
        onClick={onClick}
        size="sm"
        iconLocation="left"
        customPadding
        className="px-2 py-1 opacity-70 z-10"
        tabIndex={-1}
      >
        {count}
      </Button>
    ) : null,
);
FrameCommentCount.displayName = 'FrameCommentCount';
