window.FrameFocusActions = alt.createActions(function () {
  this.init = function (options) {
    this.dispatch(options);
  };

  this.close = function (options) {
    this.dispatch(options);
  };

  this.setFrame = function (options) {
    this.dispatch(options);
  };

  this.setFrameIndex = function (options) {
    this.dispatch(options);
  };

  this.toggleSidebar = function(options) {
    this.dispatch(options)
  }

  this.setMode = function(options) {
    this.dispatch(options)
  }
});
