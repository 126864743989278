/** @format */
import React from 'react';
import { allTheActions } from './allTheActions';
import { allTheStores } from './allTheStores';
import { StoreContextProvider } from './StoreContextProvider';

/** Provides the typical stores and actions to its children.  */
export const DefaultStoreContextProvider: React.FC = (props) => (
  <StoreContextProvider stores={allTheStores} actions={allTheActions}>
    {props.children}
  </StoreContextProvider>
);
