/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { ajax } from 'javascripts/helpers/ajax';
import { isArray, isObject } from 'underscore';
const api = require('../../helpers/api')();

const errorHandler = RequestErrorHandler('invoices');
export type invoiceStatus =
  | 'draft'
  | 'open'
  | 'paid'
  | 'uncollectible'
  | 'void';

export class InvoiceStore extends FakeAltStoreClass<InvoiceStore> {
  invoices: unknown[] | false = false;
  latestStatus?: string;
  latestInvoice?: invoiceStatus;

  constructor() {
    super();
    this.bindListeners({
      handleFetch: InvoiceActions.FETCH,
      handleFetchStatus: InvoiceActions.FETCH_STATUS,
    });
  }

  handleFetch() {
    ajax({
      method: '',
      url: '/account/invoices.json',
      success: (response) => {
        this.invoices = response;
        this.emitChange();
      },
      error: errorHandler({
        message: "Sorry, we couldn't fetch your invoices",
      }),
    });
  }

  handleFetchStatus() {
    this.latestStatus = undefined;

    ajax({
      url: api.setRailsApiUrl('latest_invoice?fields=id,date,status'),
      beforeSend: api.setRailsApiAuthHeader,
      complete: () => this.emitChange(),
    })
      .then(({ data }) => data)
      .then((response) => {
        if (isArray(response) && response.length) {
          this.latestStatus = response[0].attributes.status;
          this.emitChange();
        }
      })
      .catch((jqXHR: JQueryXHR) => {
        if (isObject(jqXHR) && jqXHR.status !== 404)
          errorHandler({
            // Don't show the user a message if this fails
            message: null,
          })(jqXHR);
      });
  }
}

(window as any).InvoiceStore = alt.createStore(InvoiceStore, 'InvoiceStore');
