/** @prettier */
/** From https://stackoverflow.com/a/19772220/847689 */
export const throttledAnimationFrame = (
  /** Function doing the actual animation, return false to stop loop */
  animFunction: (frameNo: number) => false | void,
  fps = 30,
) => {
  const fpsInterval = 1000 / fps;
  let stop = false;
  let now;
  let elapsed;
  let then = Date.now();
  let animationFrame;
  let frameNo = 0;

  // the animation loop calculates time elapsed since the last loop
  // and only draws if your specified fps interval is achieved
  function animate() {
    if (stop) return;
    // request another frame

    animationFrame = requestAnimationFrame(animate);

    // calc elapsed time since last loop

    now = Date.now();
    elapsed = now - then;

    // if enough time has elapsed, draw the next frame

    if (elapsed > fpsInterval) {
      // Get ready for next frame by setting then=now, but also adjust for your
      // specified fpsInterval not being a multiple of RAF's interval (16.7ms)
      then = now - (elapsed % fpsInterval);
      frameNo++;
      const output = animFunction(frameNo);
      if (output === false) {
        stop = true;
        cancelAnimationFrame(animationFrame);
      }
    }
  }

  animate();
};
