/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import { ActivityLogActions } from '../actions/activityLog';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { deserialise } from 'kitsu-core';
import { ajax } from 'javascripts/helpers/ajax';

const api = require('../../helpers/api')();
const errorHandler = RequestErrorHandler('activityLog');

// Mon Apr 19 2021 11:16
export const activityCutoffDate = new Date(1618827393791);

// prettier-ignore
export const availableActivityLogTypes = [
  'storyboards', 'teams', 'users',
] as const;
export type activityLogType = typeof availableActivityLogTypes[number];

export interface ActivityItem {
  action: unknown;
  user_avatar_url?: string;
  action_type: string;
  created_at: string;

  /** user-friendly representation of the event in Markdown */
  description: string;
  id: string;
  links: unknown;
  target: string;
  target_id: number;
  team_id: number;
  type: 'event';
  updated_at: string;
  user_id: number;
}

export class ActivityLogStore extends FakeAltStoreClass<ActivityLogStore> {
  activities: Record<
    activityLogType,
    Record<string, ActivityItem[] | undefined>
  > = {
    storyboards: {},
    teams: {},
    users: {},
  };

  constructor() {
    super();
    this.bindListeners({
      handleFetch: ActivityLogActions.FETCH,
      handleRefresh: ActivityLogActions.REFRESH,
    });
  }

  handleFetch(props: { id: number; type: activityLogType }) {
    ajax({
      method: 'get',
      url: api.setRailsApiUrl(`${props.type}/${props.id}/events`),
      beforeSend: api.setRailsApiAuthHeader,
      success: (response) => {
        const result = deserialise(response).data;
        this.activities[props.type][props.id] = result;
        this.emitChange();
      },
      error: errorHandler({
        message: 'could not load your activity log',
      }),
    });
  }

  handleRefresh(props: { id: number; type: activityLogType }) {
    this.handleFetch(props);
  }
}

(window as any).ActivityLogStore = alt.createStore(
  ActivityLogStore,
  'ActivityLogStore',
);
