/** @prettier */

import { useStore } from '../../../../javascripts/helpers/useStore';
import type { StoryboardStore } from '../../../../javascripts/flux/stores/storyboard';
import type { StoryboardAnalysisStore } from '../../../../javascripts/flux/stores/storyboardAnalysis';
import React from 'react';
import { useStoreAction } from 'javascripts/helpers/useStoreAction';
import { useOnMount } from 'javascripts/helpers/useOnMount';
import { isUndefined } from 'underscore';
import type { IStoryboardPreferences } from 'javascripts/types/storyboard';

interface WordCountContainerProps {
  storyboardId: number;
}

interface WordCountProps {
  count?: number;
}
export const WordCount: React.FC<WordCountProps> = (props) => {
  return (
    <div className="flex items-center text-type-subdued  whitespace-nowrap">
      {props.count ?? 0} words
    </div>
  );
};

export const canShowWordCount = (
  preferences?: Partial<IStoryboardPreferences>,
) =>
  isUndefined(preferences?.word_count_from)
    ? preferences?.show_word_count ?? false
    : preferences?.word_count_from !== null;

export const useCanShowWordCount = () =>
  useStore<boolean, StoryboardStore>('storyboard', ({ storyboard }) =>
    canShowWordCount(storyboard.preferences),
  );

export const WordCountContainer: React.FC<WordCountContainerProps> = (
  props,
) => {
  const { storyboardId } = props;

  const wordCount = useStore<number | undefined, StoryboardAnalysisStore>(
    'storyboardAnalysis',
    (store) => store.analysis[storyboardId]?.wordCount,
  );

  const init = useStoreAction('StoryboardAnalysisActions', 'init');
  useOnMount(init);

  return <WordCount count={wordCount} />;
};
