// From https://blog.thirdrocktechkno.com/how-to-draw-an-arrow-using-html-5-canvas-and-fabricjs-9500c3f50ecb
const fabric = require('fabric').fabric;

module.exports = fabric.Arrow = fabric.util.createClass(fabric.Line, {
  type: 'arrow',

  initialize: function(element, options) {
    options || (options = {});
    this.callSuper('initialize', element, options);
  },

  toObject: function() {
    return fabric.util.object.extend(this.callSuper('toObject'));
  },

  _render: function(ctx) {
    this.callSuper('_render', ctx);

    // do not render if width/height are zeros or object is not visible
    if ((this.width === 0 && this.height === 0) || !this.visible) return;

    ctx.save();

    var xDiff = this.x2 - this.x1;
    var yDiff = this.y2 - this.y1;
    var angle = Math.atan2(yDiff, xDiff);
    ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
    ctx.rotate(angle);

    // move a bit in front of line to start the arrow so it does not have the
    // square line end showing in front (0,0)
    ctx.beginPath();
    ctx.fillStyle = this.stroke;
    ctx.moveTo(this.strokeWidth / 2, 0);
    ctx.lineTo(-(this.strokeWidth * 1.2), this.strokeWidth * 2);
    ctx.lineTo(-(this.strokeWidth * 1.2), -(this.strokeWidth * 2));
    ctx.closePath();
    ctx.fill();

    ctx.restore();

  }
});

fabric.Arrow.fromObject = function(object, callback) {
  callback && callback(new fabric.Arrow([object.x1, object.y1, object.x2, object.y2], object));
};

fabric.Arrow.async = true;
fabric.Arrow.prototype.lockUniScaling = true;
