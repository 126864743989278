/** @prettier */
import * as React from 'react';
import type { IStoryboardInStore } from '../../../types/storyboard';
import SettingsIcon from 'blackbird/images/icons/settings-3.svg';
import {
  createPermissionFlyoverOpener,
  GetPermission,
} from '../../../helpers/has-permission';
import { StoryboardActions } from '../../../flux/actions/storyboard';
import logger from 'javascripts/helpers/logger';
import Toggle, {
  type ToggleOnChangeHandler,
} from 'blackbird/components/toggle/Toggle';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import CopyIcon from 'blackbird/images/icons/copy.svg';
import Icon from 'blackbird/components/icon/Icon';
import { withTranslation, type WithTranslation } from 'react-i18next';
import { RequestActions } from 'javascripts/flux/actions/request';
import { StoryboardPreferenceToggle } from './StoryboardPreferenceToggle';
import { AccordionTransition } from 'blackbird/components/common/Transitions';
import { isEmpty } from 'underscore';
import Button from 'blackbird/components/button/Button';
import { Cog6ToothIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { TeamUpgradePill } from 'blackbird/components/team/TeamUpgradePill';

interface IProps extends WithTranslation {
  storyboard: IStoryboardInStore;
  selectedFrameIndex?: number;
}

interface IState {
  startAtSelectedFrame: boolean;
  showSettings: boolean;
}
const ref = React.createRef<HTMLInputElement>();

class ShareableUrlContainerComp extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      startAtSelectedFrame: false,
      showSettings: BoordsConfig.HasV3,
    };
  }
  handleCopyClick() {
    ref.current?.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        RequestActions.success('Copied to clipboard');
        Track.event.defer('copy_shareable_link');
      } else {
        logger.warn('Unable to copy to clipboard');
      }
    } catch (err) {
      logger.log('Oops, unable to copy');
    }
  }

  handleTogglePreference: ToggleOnChangeHandler = (newValue, e) => {
    if (!e.currentTarget.name)
      throw new Error(`Event target needs to have a name`);
    StoryboardActions.updatePreference({
      name: e.currentTarget.name as any,
      value: newValue,
    });
  };

  handleToggleComments = (newValue) => {
    StoryboardActions.update({
      name: 'has_comments_enabled',
      value: newValue,
    });
    StoryboardActions.save.defer();
    this.forceUpdate();
  };

  render() {
    const { storyboard, t }: IProps = this.props;
    const { share_as_animatic, share_as_grid, share_with_version_number } =
      storyboard.preferences!;
    let url = storyboard.public_url;

    if (share_as_animatic && !share_as_grid) {
      url += '/player';
    } else if (!share_as_grid) {
      url += '/frame';
    }

    if (this.state.startAtSelectedFrame) {
      url += '/' + this.props.selectedFrameIndex + 1;
    }

    return (
      <>
        <div>
          {!BoordsConfig.HasV3 && (
            <div className="flex items-baseline mb-3">
              <div className="flex-auto text-lg">
                {t('modals.share.presentation_link')}
              </div>
              <div className="flex-shrink-0">
                <a
                  href={url}
                  className="text-sm underline text-type-subdued hover:no-underline decoration-black/20"
                >
                  {t('modals.share.preview')}
                </a>
              </div>
            </div>
          )}

          {!isEmpty(storyboard.newest_version) && (
            <div className="tc rusty-red pa3 br2 mt3 bg-washed-red">
              {t('modals.share.newer_version.description')}
              <a
                className="underline rusty-red"
                href={storyboard.newest_version}
              >
                {t('modals.share.newer_version.cta')}
              </a>
            </div>
          )}

          <TextInput
            inputSize="md"
            className={`bg-surface text-type-disabled`}
            ref={ref}
            value={url}
            onClick={this.handleCopyClick}
            readOnly
            rightComponent={
              <Icon onClick={this.handleCopyClick} icon={<CopyIcon />}></Icon>
            }
          />

          <div
            className={classNames(
              '',
              BoordsConfig.HasV3 && 'mb-4 mt-6',
              'mt-4',
            )}
          >
            {BoordsConfig.HasV3 && (
              <div
                className={classNames(
                  'font-semibold mb-2',
                  'text-xs tracking-wider uppercase text-type-subdued ',
                )}
              >{`Security`}</div>
            )}
            {BoordsConfig.StoryboardTeamPlanSlug === 'trialing' ? (
              <div className="flex items-center justify-between">
                <span>{`Require password`}</span>
                <TeamUpgradePill />
              </div>
            ) : (
              <PanelbarShareablePassword storyboard_id={storyboard.id} />
            )}
          </div>

          {!BoordsConfig.HasV3 && (
            <div className="mb-4 text-right -mt-7">
              <Button
                type="secondary"
                size="sm"
                onClick={() =>
                  this.setState((prevState) => ({
                    showSettings: !prevState.showSettings,
                  }))
                }
              >
                <span>{`Link Settings`}</span>
                <Cog6ToothIcon className="w-4" />
              </Button>
            </div>
          )}
        </div>

        {this.state.showSettings && (
          <div
            className={classNames(
              '',
              BoordsConfig.HasV3
                ? ''
                : 'py-6 -mt-5 border-t border-b border-border',
            )}
          >
            <div className="mb-4 space-y-2">
              <div
                className={classNames(
                  'font-semibold ',
                  BoordsConfig.HasV3
                    ? 'text-xs tracking-wider uppercase text-type-subdued pt-2'
                    : 'text-base',
                )}
              >{`General`}</div>

              <StoryboardPreferenceToggle
                onChange={this.handleTogglePreference}
                isSaving={storyboard.is_saving}
                preference="share_as_grid"
                value={storyboard.preferences?.share_as_grid}
                label={t('modals.share.default_grid_view')}
              />

              <StoryboardPreferenceToggle
                onChange={this.handleTogglePreference}
                isSaving={storyboard.is_saving}
                preference="share_as_animatic"
                value={storyboard.preferences?.share_as_animatic}
                label={t('modals.share.share_as_animatic')}
                help={t('modals.share.animatic_help')}
                permission="animatics"
              />

              <StoryboardPreferenceToggle
                onChange={this.handleTogglePreference}
                isSaving={storyboard.is_saving}
                preference="share_with_frame_status"
                value={storyboard.preferences?.share_with_frame_status}
                label={t('modals.share.show_frame_status')}
                help={t('modals.share.frame_status_help')}
              />
            </div>

            <div className="mb-4 space-y-2">
              <div
                className={classNames(
                  'font-semibold ',
                  BoordsConfig.HasV3
                    ? 'text-xs tracking-wider uppercase text-type-subdued pt-2 '
                    : 'text-base',
                )}
              >{`Comments`}</div>

              <div className="flex items-center ">
                <div className="flex-auto">
                  {t('modals.share.enable_comments')}
                </div>
                <GetPermission
                  storyboardOwnerId={storyboard.project.owner.user_id}
                  featureName="comments"
                >
                  {(hasPermission) => (
                    <Tooltip
                      as="div"
                      disabled={hasPermission === true}
                      title={t('modals.share.upgrade')}
                    >
                      <div
                        onClick={() => {
                          createPermissionFlyoverOpener(
                            BoordsConfig.Uid,
                            'comments',
                          );
                        }}
                      >
                        <Toggle
                          onChange={this.handleToggleComments}
                          value={storyboard.has_comments_enabled}
                          disabled={
                            !!storyboard.is_saving || hasPermission !== true
                          }
                        />
                      </div>
                    </Tooltip>
                  )}
                </GetPermission>
              </div>

              <StoryboardPreferenceToggle
                show={storyboard.has_comments_enabled}
                onChange={this.handleTogglePreference}
                isSaving={storyboard.is_saving}
                preference="hide_completed_comments_present"
                value={storyboard.preferences?.hide_completed_comments_present}
                label={t('modals.share.hide_completed_comments')}
              />
            </div>

            <div className="mb-4 space-y-2">
              <div
                className={classNames(
                  'font-semibold ',
                  BoordsConfig.HasV3
                    ? 'text-xs tracking-wider uppercase text-type-subdued pt-2 '
                    : 'text-base',
                )}
              >{`Guest Settings`}</div>

              <StoryboardPreferenceToggle
                onChange={this.handleTogglePreference}
                isSaving={storyboard.is_saving}
                preference="share_with_word_count"
                value={storyboard.preferences?.share_with_word_count}
                label={t('modals.share.share_with_word_count')}
              />

              <StoryboardPreferenceToggle
                onChange={this.handleTogglePreference}
                isSaving={storyboard.is_saving}
                preference="share_with_version_number"
                value={storyboard.preferences?.share_with_version_number}
                label={t('modals.share.share_with_version_number')}
              />

              <AccordionTransition
                show={share_with_version_number}
                className="space-y-2"
              >
                <StoryboardPreferenceToggle
                  onChange={this.handleTogglePreference}
                  isSaving={storyboard.is_saving}
                  preference="share_with_version_switching"
                  value={storyboard.preferences?.share_with_version_switching}
                  label={t('modals.share.guest_version_switch')}
                  help={t('modals.share.version_switch_help')}
                />

                <StoryboardPreferenceToggle
                  onChange={this.handleTogglePreference}
                  isSaving={storyboard.is_saving}
                  preference="share_with_version_notification"
                  value={
                    storyboard.preferences?.share_with_version_notification
                  }
                  label={t('modals.share.share_with_version_notification')}
                  help={t('modals.share.share_with_version_notification_help')}
                />
              </AccordionTransition>
            </div>
          </div>
        )}
      </>
    );
  }
}

export const ShareableUrlContainer = withTranslation()(
  ShareableUrlContainerComp,
);
