/** @prettier */
import * as React from 'react';
import { FrameOverlayBrowserItem } from './FrameOverlayBrowserItem';
import { useTranslation } from 'react-i18next';
import { frameOverlays } from './FrameOverlays';
import Button from 'blackbird/components/button/Button';
import { FrameEditorActions } from 'javascripts/flux/actions/frame_editor';
import { useStore } from 'javascripts/helpers/useStore';
import type { FrameEditorStore } from 'javascripts/flux/stores/frame_editor';

import { ToursActions, tourEvents } from 'javascripts/flux/actions/tours';
import { TourHintable } from '../tours/TourHintable';

interface IProps {
  hideTitle?: boolean;
}

const FrameOverlayBrowser: React.FC<IProps> = (props) => {
  React.useLayoutEffect(() => {
    ToursActions.triggerEvent.defer(tourEvents.openCameraMoves);
  }, []);

  const handleClearClick = () =>
    FrameEditorActions.updateDecoration({
      overlay: null,
    });
  const hasOverlay = useStore<boolean, FrameEditorStore>('frameEditor', (s) =>
    Boolean(s.currentDecoration?.overlay),
  );
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      {!props.hideTitle && (
        <div className="p-3 text-lg text-type-primary">
          {t('frameEditor.overlays.title')}
        </div>
      )}

      <TourHintable
        step="wizardCameraMovesInsert"
        overlayPosition="left"
        hideNext
        deferred
      >
        <div className="flex flex-col flex-auto overflow-y-auto gap-3">
          {frameOverlays.map((o) => (
            <FrameOverlayBrowserItem
              key={o.key}
              overlay={o}
              onInsertOverlay={(overlay) => {
                FrameEditorActions.updateDecoration({
                  overlay: overlay,
                });
              }}
              className="p-3"
            />
          ))}
        </div>
      </TourHintable>

      {hasOverlay && (
        <div className="mt-4">
          <Button
            onClick={handleClearClick}
            className="w-full"
            type="secondary"
            size="sm"
          >
            <span className="text-sm font-normal">
              {t('frameEditor.overlays.remove')}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default FrameOverlayBrowser;
