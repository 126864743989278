window.IntercomActions = alt.createActions(function () {
  this.message = function(options) {
    this.dispatch(options);
  };

  this.enterContext = function(options) {
    this.dispatch(options);
  }

  this.leaveContext = function(options) {
    this.dispatch(options);
  }
});
