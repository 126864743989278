/** @prettier */
import * as React from 'react';
import cdnUrl from 'javascripts/helpers/cdn-url.js';

export const SignupLogos: React.FC = () => {
  const logos: string[] = [
    `partizan`,
    `moving-brands`,
    `framestore`,
    `publicis`,
  ];
  return (
    <div className="grid grid-cols-4 gap-6">
      {logos.map((logo) => (
        <div className="mix-blend-multiply opacity-40" key={logo}>
          <img src={cdnUrl(`/assets/company-logos/${logo}.jpg`)} alt={logo} />
        </div>
      ))}
    </div>
  );
};
