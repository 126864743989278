window.TeamRoleHelper = (function() {

  return {
    call: function(role) {
      var strapline = "";

      if (role === "pending") {
        strapline = "";
      } else if (role === "admin") {
        strapline = "Owner";
      } else if (role === "manager") {
        strapline = "Admin";
      } else if (role === "supermember") {
        strapline = "Full access";
      } else if (role === "member") {
        strapline = "Restricted";
      } else if (role === "archived") {
        strapline = "Archived";
      }
      return strapline;
    }
  }

})();
