/** @format */
import classNames from 'classnames';
import * as React from 'react';
import type { StoryboardZoomStore } from 'javascripts/flux/stores/storyboardZoom';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { useStore } from 'javascripts/helpers/useStore';
import type { frameAspectRatio } from 'javascripts/types/storyboard';
import { useContainerQuery } from 'react-container-query';

interface Props {
  children: (isMinimal: boolean) => React.ReactNode;
  className?: string;
  frameAspectRatio: frameAspectRatio;
}

const query = {
  minimal: {
    maxWidth: 220,
    maxHeight: 260,
  },
};

export const FrameGrid = React.memo<Props>((props) => {
  const columns = useStore<number, StoryboardZoomStore>(
    'storyboardZoom',
    (s) => s.zoomSettings.columns,
  );
  const aspectRatio = BoordsFrameSizeHelper(props.frameAspectRatio);
  const roughEstimatedWidth = (window.innerWidth * 0.9) / columns;
  const roughEstimatedDimensions = {
    width: roughEstimatedWidth,
    height: roughEstimatedWidth / (aspectRatio.width / aspectRatio.height),
  };
  const [params, containerRef] = useContainerQuery(
    query,
    roughEstimatedDimensions,
  );
  return (
    <div
      className={classNames(
        // please not that the margin/padding is mirrored in frameContainer and
        // frameAddButton, and ScriptEditorStoryboardItem
        'grid grid-flow-row auto-rows-max -m-3',
        props.className,
      )}
      style={{
        gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
      }}
    >
      {props.children(params.minimal)}
      {/* Measurer */}
      <div
        ref={containerRef}
        style={{ aspectRatio: `${aspectRatio.width / aspectRatio.height}` }}
      />
    </div>
  );
});

FrameGrid.displayName = 'FrameGrid';
