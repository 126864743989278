/**
 * @prettier
 */
const createGuid = require('./guid');
const _ = require('underscore');

const sortFrames = frames => {
  const lastGroupedIndex = {};
  const output = frames.reduce((o, f, i) => {
    if (f.group_id) {
      const lastIndex = lastGroupedIndex[f.group_id];

      if (_.isFinite(lastIndex) && i - lastIndex > 1) {
        const newIndex = lastIndex + 1;
        // Putting the frame where it belongs
        o.splice(newIndex, 0, f);
        lastGroupedIndex[f.group_id] = newIndex;
        return o;
      } else {
        lastGroupedIndex[f.group_id] = i;
      }
    }

    o.push(f);
    return o;
  }, []);
  output.forEach((f, i) => (f.sort_order = i + 1));
  return output;
};

module.exports = {
  sort: sortFrames,
  group(framesToGroup) {
    if (!framesToGroup || !framesToGroup.length) return frames;

    const found = _.find(framesToGroup, f => f.group_id);
    const groupId = found ? found.group_id : createGuid().slice(0, 8);
    framesToGroup.forEach(f => (f.group_id = groupId));

    return framesToGroup;
  },

  ungroup(framesToUngroup, allFrames = []) {
    framesToUngroup.forEach(f => (f.group_id = null));

    if (allFrames.length) {
      const groups = _.groupBy(allFrames, 'group_id');
      _.each(groups, (v, k) => {
        if (k !== 'undefined' && v.length === 1) {
          v.forEach(f => (f.group_id = null));
        }
      });
    }

    return framesToUngroup;
  },
};
