/** @prettier */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { IStoryboardInStore } from '../../../types/storyboard';
import { supportedLayouts } from '../../pdf/types';
import { hasNewPDFExport } from '../../pdf/helpers/newPDFExportOptin';
import { hasPermission } from '../../../helpers/has-permission';
import IconDropdown from 'javascripts/components/shared/IconDropdown';
import { isNull } from 'underscore';

export class ExportPdfLayout extends React.Component<{
  storyboard: IStoryboardInStore;
  cover: any;
  layout: supportedLayouts;
}> {
  static propTypes = {
    storyboard: PropTypes.object.isRequired,
    cover: PropTypes.object.isRequired,
    layout: PropTypes.string.isRequired,
  };

  componentDidMount() {
    if (!isNull(this.props.storyboard.pdf_layout)) {
      PdfActions.setLayout.defer({
        layout_name: this.props.storyboard.pdf_layout,
        has_premium_features: this.props.storyboard.has_premium_features,
      });
    } else {
      PdfActions.setLayout.defer({
        layout_name: this.props.layout,
        has_premium_features: this.props.storyboard.has_premium_features,
      });
    }
  }

  _setLayout(name) {
    PdfActions.setLayout.defer({
      layout_name: name,
    });
  }

  render() {
    var dropdownOptions: any[] = [],
      isPortrait = false;

    if (!isNull(this.props.layout)) {
      isPortrait = this.props.layout.indexOf('portrait') > -1;
    }

    if (this.props.storyboard.frame_aspect_ratio == '1x1') {
      dropdownOptions.push({
        iconClass: 'pdf-three-up-1x1',
        title: 'Three up',
        onClick: this._setLayout,
        isSelectedWhen: 'three-up-1x1',
        trialAccess: true,
      });

      dropdownOptions.push({
        iconClass: 'pdf-two-up-1x1',
        title: 'Two up',
        onClick: this._setLayout,
        isSelectedWhen: 'two-up-1x1',
        trialAccess: true,
      });

      dropdownOptions.push({
        iconClass: 'pdf-one-up-1x1',
        title: 'Full page',
        onClick: this._setLayout,
        isSelectedWhen: 'one-up-1x1',
        trialAccess: true,
      });
    } else if (this.props.storyboard.frame_aspect_ratio == '9x16') {
      dropdownOptions.push({
        iconClass: 'pdf-three-up-9x16',
        title: 'Three up',
        onClick: this._setLayout,
        isSelectedWhen: 'three-up-9x16',
        trialAccess: true,
      });
    } else {
      dropdownOptions.push({
        iconClass: 'pdf-six-up',
        title: 'Six frame grid',
        // subtitle:     "Good for showing a lot of detail at once",
        onClick: this._setLayout,
        isSelectedWhen: 'six-up',
        trialAccess: true,
      });

      dropdownOptions.push({
        iconClass: 'pdf-five-up-portrait',
        title: 'Five frame list',
        // subtitle:     "Good for showing a lot of detail at once",
        onClick: this._setLayout,
        isSelectedWhen: 'five-up-portrait',
        trialAccess: true,
      });

      dropdownOptions.push({
        iconClass: 'pdf-four-up',
        title: 'Four frame grid',
        // subtitle:        "Large frames but include more detail",
        onClick: this._setLayout,
        isSelectedWhen: 'four-up',
        trialAccess: true,
      });

      dropdownOptions.push({
        iconClass: 'pdf-two-up',
        title: 'Two up',
        // subtitle:        "Large images and lots of text",
        onClick: this._setLayout,
        isSelectedWhen: 'two-up',
        trialAccess: true,
      });

      dropdownOptions.push({
        iconClass: 'pdf-one-up',
        title: 'Full page',
        // subtitle:     "Perfect for showing off your images",
        onClick: this._setLayout,
        isSelectedWhen: 'one-up',
        trialAccess: true,
      });
    }

    var cover = this.props.cover.cover;

    const canChangeBranding = hasPermission(
      this.props.storyboard.project.owner.user_id,
      'branding',
    );

    return (
      <div>
        <div className="font-bold mb-6">Storyboard settings</div>
        <div className="mb-6 space-y-1.5">
          <div className="font-bold ">Template</div>
          <IconDropdown
            selected={this.props.layout}
            options={dropdownOptions}
          />
        </div>

        <div className="flex mb-6 justify-between">
          <div>
            <div className="font-bold mb-5">Frame display</div>
            <div className="flex flex-col gap-6">
              <StoryboardExportBoolean
                field_name="storyboard.has_frame_border"
                label="Show border around images"
                is_checked={cover.storyboard.has_frame_border || false}
              />
              <StoryboardExportBoolean
                field_name="storyboard.include_label_text_in_output"
                label='Show "Label" text'
                is_checked={
                  cover.storyboard.include_label_text_in_output || false
                }
              />
              <StoryboardExportBoolean
                field_name="storyboard.include_frame_number_in_pdf"
                label="Show frame number"
                is_checked={
                  cover.storyboard.include_frame_number_in_pdf || false
                }
              />
              <StoryboardExportBoolean
                field_name="storyboard.include_icons_in_pdf"
                label="Show sound & action icons"
                is_checked={cover.storyboard.include_icons_in_pdf || false}
              />
              {!hasNewPDFExport() && (
                <StoryboardExportBoolean
                  field_name="storyboard.use_high_res_images_in_pdf"
                  label="Always use high-resolution images"
                  is_checked={
                    cover.storyboard.use_high_res_images_in_pdf || false
                  }
                  is_disabled={!canChangeBranding}
                />
              )}
            </div>
          </div>
          <div>
            <div className="font-bold mb-5">Document settings</div>
            <div className="flex flex-col gap-6">
              {!isPortrait ? (
                <StoryboardExportBoolean
                  field_name="storyboard.use_letterbox_pdf_layout"
                  label="Use widescreen page size"
                  is_checked={
                    cover.storyboard.use_letterbox_pdf_layout || false
                  }
                />
              ) : null}
              <StoryboardExportBoolean
                field_name="storyboard.include_version_number"
                label="Show version number in PDF filename"
                is_checked={cover.storyboard.include_version_number || false}
              />
              <StoryboardExportBoolean
                field_name="storyboard.hide_storyboard_title"
                label="Hide storyboard name in footer"
                is_checked={cover.storyboard.hide_storyboard_title || false}
              />
            </div>
          </div>
        </div>

        {!BoordsConfig.Freeloader && (
          <div className="mb-6 flex flex-col gap-2">
            <div className="font-bold">Footer logo</div>
            <ExportPdfFooterLogo
              cover={this.props.cover}
              storyboard={this.props.storyboard}
              is_available={canChangeBranding}
            />
          </div>
        )}
      </div>
    );
  }
}
