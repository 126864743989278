/** @format */

import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import { RequestActions } from 'javascripts/flux/actions/request';
import i18n from 'i18next';
import logger from 'javascripts/helpers/logger';
import { isEqual } from 'underscore';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import {
  type GeneratorPositive,
  type GeneratorGuideline,
  GeneratorContext,
  EventProps,
} from '../GeneratorContext';
import { type GeneratorStyle } from 'javascripts/types/frame';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
const errorHandler = RequestErrorHandler('generatorCharacter');

interface SelectOption {
  label: string;
  value: string;
}

interface CharacterSelectOptions {
  ethnicities: SelectOption[];
  ages: SelectOption[];
  genders: SelectOption[];
}

const characterSelectOptions = i18n.t('generator:styles', {
  returnObjects: true,
})['character'] as CharacterSelectOptions;

const initialContext = {
  error: '',
  setError: () => {},
  characterSelectOptions: characterSelectOptions,
  isCharacterEditorOpen: false,
  setIsCharacterEditorOpen: () => {},
  isNameChanged: false,
  setIsNameChanged: () => {},
  isAttributesChanged: false,
  setIsAttributesChanged: () => {},
  isProcessing: false,
  setIsProcessing: () => {},
  character: undefined,
  setCharacter: () => {},
  style: undefined,
  setStyle: () => {},
  originalStyle: undefined,
  setOriginalStyle: () => {},
  characterStyles: undefined,
  setCharacterStyles: () => {},
  isActive: true,
  setIsActive: () => {},
  originalIsActive: true,
  setOriginalIsActive: () => {},
  isActiveChanged: false,
  setIsActiveChanged: () => {},
  negative: undefined,
  setNegative: () => {},
  originalNegative: undefined,
  setOriginalNegative: () => {},
  name: undefined,
  setName: () => {},
  originalName: undefined,
  setOriginalName: () => {},
  positive: undefined,
  setPositive: () => {},
  originalPositive: undefined,
  setOriginalPositive: () => {},
  handleUpdate: async () => {},
  handlePoll: async () => {},
  handleDuplicate: async () => {},
  handleDestroy: async () => {},
  TrackCharacterEvent: () => {},
};

interface GeneratorGuidelineApiResponse {
  data: {
    attributes: {
      guideline: GeneratorGuideline;
    };
  };
}

interface GeneratorCharacterContextProps {
  TrackCharacterEvent: (args: EventProps) => void;
  isCharacterEditorOpen: boolean;
  characterSelectOptions: CharacterSelectOptions;
  setIsCharacterEditorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isNameChanged: boolean;
  setIsNameChanged: React.Dispatch<React.SetStateAction<boolean>>;
  isAttributesChanged: boolean;
  setIsAttributesChanged: React.Dispatch<React.SetStateAction<boolean>>;
  isProcessing: boolean;
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  character: GeneratorGuideline | undefined;
  setCharacter: React.Dispatch<
    React.SetStateAction<GeneratorGuideline | undefined>
  >;
  characterStyles: GeneratorStyle[] | undefined;
  setCharacterStyles: React.Dispatch<
    React.SetStateAction<GeneratorStyle[] | undefined>
  >;
  style: GeneratorStyle | undefined;
  setStyle: React.Dispatch<React.SetStateAction<GeneratorStyle | undefined>>;
  originalStyle: GeneratorStyle | undefined;
  setOriginalStyle: React.Dispatch<
    React.SetStateAction<GeneratorStyle | undefined>
  >;
  name: string | undefined;
  setName: React.Dispatch<React.SetStateAction<string | undefined>>;
  originalName: string | undefined;
  setOriginalName: React.Dispatch<React.SetStateAction<string | undefined>>;
  originalNegative: string | undefined;
  setOriginalNegative: React.Dispatch<React.SetStateAction<string | undefined>>;
  negative: string | undefined;
  setNegative: React.Dispatch<React.SetStateAction<string | undefined>>;
  positive: GeneratorPositive | undefined;
  setPositive: React.Dispatch<
    React.SetStateAction<GeneratorPositive | undefined>
  >;
  originalPositive: GeneratorPositive | undefined;
  setOriginalPositive: React.Dispatch<
    React.SetStateAction<GeneratorPositive | undefined>
  >;
  handleUpdate: () => Promise<void>;
  handlePoll: () => Promise<void>;
  handleDuplicate: (
    characterId: string,
    duplicate_as_global?: boolean,
  ) => Promise<void>;
  handleDestroy: (characterId: string) => Promise<void>;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  originalIsActive: boolean;
  setOriginalIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveChanged: boolean;
  setIsActiveChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const initStyles = i18n.t('generator:styles', {
  returnObjects: true,
})['items'] as GeneratorStyle[];

const defaultStyle = initStyles.find(
  (s) => s.value === 'lino',
) as GeneratorStyle;

export const GeneratorCharatcerContext =
  createContext<GeneratorCharacterContextProps>(initialContext);

export const GeneratorCharacterProvider: React.FC = ({ children }) => {
  const {
    selectedCharacter,
    setSelectedCharacter,
    generatorGuidelines,
    setGeneratorGuidelines,
  } = React.useContext(GeneratorContext);

  const [characterSelectOptions] = React.useState(
    initialContext.characterSelectOptions,
  );

  const [isProcessing, setIsProcessing] = useState(initialContext.isProcessing);
  const [isNameChanged, setIsNameChanged] = useState(
    initialContext.isNameChanged,
  );
  const [isActiveChanged, setIsActiveChanged] = useState(
    initialContext.isActiveChanged,
  );
  const [isActive, setIsActive] = useState(initialContext.isActive);
  const [originalIsActive, setOriginalIsActive] = useState(
    initialContext.originalIsActive,
  );
  const [isAttributesChanged, setIsAttributesChanged] = useState(
    initialContext.isAttributesChanged,
  );
  const [isCharacterEditorOpen, setIsCharacterEditorOpen] = useState(
    initialContext.isCharacterEditorOpen,
  );

  const [error, setError] = useState(initialContext.error);
  const [character, setCharacter] = useState<GeneratorGuideline | undefined>(
    initialContext.character,
  );
  const [characterStyles, setCharacterStyles] = useState<
    GeneratorStyle[] | undefined
  >(initialContext.characterStyles);

  const [name, setName] = useState<string | undefined>(initialContext.name);
  const [originalName, setOriginalName] = useState<string | undefined>(
    initialContext.originalName,
  );

  const [style, setStyle] = useState<GeneratorStyle | undefined>(defaultStyle);
  const [originalStyle, setOriginalStyle] = useState<
    GeneratorStyle | undefined
  >(defaultStyle);

  const [negative, setNegative] = useState<string | undefined>(
    initialContext.negative,
  );
  const [originalNegative, setOriginalNegative] = useState<string | undefined>(
    initialContext.originalNegative,
  );

  const [positive, setPositiveState] = useState<GeneratorPositive | undefined>(
    initialContext.positive,
  );

  const [originalPositive, setOriginalPositive] = useState<
    GeneratorPositive | undefined
  >(initialContext.originalPositive);

  const setPositive = (updates: Partial<GeneratorPositive>) => {
    setPositiveState((currentPositive) => ({ ...currentPositive, ...updates }));
  };

  const TrackCharacterEvent = useCallback(
    ({ event_name, args = {} }: EventProps) => {
      Track.event.defer(
        event_name,
        Object.assign(
          {
            posthogCapture: true,
            characterId: selectedCharacter?.id,
            characterAge: selectedCharacter?.positive.age,
            characterRace: selectedCharacter?.positive.race,
            characterGender: selectedCharacter?.positive.gender,
            characterIsGlobal: selectedCharacter?.is_global ? true : false,
          },
          args,
        ),
      );
    },
    [selectedCharacter],
  );

  useEffect(() => {
    setCharacterStyles(initStyles.filter((s) => s.hasCharacters));
  }, []);

  useEffect(() => {
    if (character) {
      setNegative(character.negative.general);
      setPositive(character.positive);
      setName(character.name);
      setIsActive(character.is_active);

      const savedStyle = initStyles.find(
        (s) => s.value === character.preview_style,
      ) as GeneratorStyle;

      if (savedStyle) {
        setStyle(savedStyle);
        setOriginalStyle(savedStyle);
      }

      setOriginalNegative(character.negative.general);
      setOriginalPositive(character.positive);
      setOriginalName(character.name);
      setOriginalIsActive(character.is_active);
    }
  }, [character]);

  useEffect(() => {
    if (isCharacterEditorOpen) {
      TrackCharacterEvent({ event_name: `generator_character_open` });
    }
  }, [isCharacterEditorOpen]);

  useEffect(() => {
    if (name) {
      setIsNameChanged(originalName !== name);
    }
  }, [originalName, name]);

  useEffect(() => {
    setIsActiveChanged(originalIsActive !== isActive);
  }, [originalIsActive, isActive]);

  useEffect(() => {
    if (style) {
      setIsAttributesChanged(style !== originalStyle);
    }
  }, [originalStyle, style]);

  useEffect(() => {
    if (positive) {
      setIsAttributesChanged(!isEqual(originalPositive, positive));
    }
  }, [originalPositive, positive]);

  useEffect(() => {
    if (negative) {
      setIsAttributesChanged(originalNegative !== negative);
    }
  }, [originalNegative, negative]);

  const updateGeneratorGuidelinesAndSelectCharacter = (
    updatedCharacter: GeneratorGuideline,
  ) => {
    setGeneratorGuidelines((prevGuidelines) => {
      if (prevGuidelines) {
        const index = prevGuidelines.findIndex(
          (item) => item.id === updatedCharacter.id,
        );
        if (index !== -1) {
          const newGuidelines = [...prevGuidelines];
          newGuidelines[index] = updatedCharacter;
          return newGuidelines;
        }
      }
      return prevGuidelines;
    });

    setCharacter(updatedCharacter);
    setSelectedCharacter(updatedCharacter);
  };

  const handlePoll = useCallback(async () => {
    if (character) {
      const request = await apiRequest({
        path: `generator_guidelines/${character.id}`,
        method: 'get',
      });

      if (!request.ok) return errorHandler({ method: 'get' })(request);
      const response: GeneratorGuidelineApiResponse = await request.json();

      const { guideline: updatedCharacter } = response.data.attributes;

      if (!updatedCharacter.preview_processing) {
        setIsProcessing(false);
        updateGeneratorGuidelinesAndSelectCharacter(updatedCharacter);
      }
    }
  }, [character]);

  const handleDuplicate = async (
    characterId: string,
    duplicate_as_global: boolean,
  ) => {
    const request = await apiRequest({
      path: `generator_guidelines/${characterId}/duplicate`,
      method: 'post',
      payload: {
        team_id: BoordsConfig.TeamId,
        duplicate_as_global: duplicate_as_global,
      },
    });

    if (!request.ok) return errorHandler({ method: 'post' })(request);
    const response: GeneratorGuidelineApiResponse = await request.json();

    const newCharacter = response.data.attributes.guideline;

    // append to guidelines
    if (generatorGuidelines) {
      setGeneratorGuidelines([...generatorGuidelines, newCharacter]);
    }
    setCharacter(newCharacter);

    RequestActions.success.defer(`Character duplicated`);

    TrackCharacterEvent({
      event_name: `generator_character_copy`,
      args: {
        characterId: newCharacter.id,
        characterAge: newCharacter.positive.age,
        characterRace: newCharacter.positive.race,
        characterGender: newCharacter.positive.gender,
        characterIsGlobal: newCharacter.is_global ? true : false,
      },
    });
  };

  const handleDestroy = async (characterId: string) => {
    const request = await apiRequest({
      path: `generator_guidelines/${characterId}`,
      method: 'delete',
    });

    if (!request.ok) return errorHandler({ method: 'delete' })(request);
    RequestActions.success.defer(`Character deleted`);

    TrackCharacterEvent({ event_name: `generator_character_delete` });

    if (generatorGuidelines) {
      setGeneratorGuidelines(
        generatorGuidelines.filter((character) => character.id !== characterId),
      );
    }

    if (selectedCharacter && selectedCharacter.id === characterId) {
      setSelectedCharacter(undefined);
    }

    setCharacter(undefined);
    setIsCharacterEditorOpen(false);
  };

  const handleUpdate = useCallback(async () => {
    if (character && positive) {
      try {
        if (isAttributesChanged) {
          setIsProcessing(true);
        }
        const prompt = `<character:${character.id}> standing in front of a grey matte background, full length shot, looking at viewer, facing viewer, centered in frame`;
        const negativePrompt = `cartoon, juvenile`;

        const preset = style ? style.value : 'lino';

        const request = await apiRequest({
          path: `generator_guidelines/${character.id}`,
          method: 'put',
          payload: {
            generate_preview: isAttributesChanged,
            name: name,
            negativeAttributes: negative,
            positiveAttributes: JSON.stringify(positive),
            prompt: prompt,
            negativePrompt: negativePrompt,
            preset: preset,
            seed: 3795420879,
            team_id: BoordsConfig.TeamId,
            is_active: isActive,
          },
        });

        if (!request.ok) return errorHandler({ method: 'put' })(request);
        const response: GeneratorGuidelineApiResponse = await request.json();
        const newCharacter = response.data.attributes.guideline;

        if (!isAttributesChanged) {
          RequestActions.success.defer(`Changes saved`);
        }

        updateGeneratorGuidelinesAndSelectCharacter(newCharacter);

        TrackCharacterEvent({
          event_name: `generator_character_update`,
          args: {
            characterId: newCharacter.id,
            characterAge: newCharacter.positive.age,
            characterRace: newCharacter.positive.race,
            characterGender: newCharacter.positive.gender,
            characterIsGlobal: newCharacter.is_global ? true : false,
          },
        });

        setOriginalName(name);
        setOriginalNegative(negative);
        setOriginalPositive(positive);
        setIsAttributesChanged(false);
        setIsNameChanged(false);
      } catch (e) {
        logger.error(e);
        setError(e);
      }
    } else {
      setError(`Unable to generate preview`);
      logger.error('did not generate preview');
      logger.log(positive);
      logger.log(negative);
      logger.log(character);
    }
  }, [
    character,
    positive,
    negative,
    name,
    isAttributesChanged,
    style,
    isActive,
  ]);

  const values = {
    character,
    setCharacter,
    negative,
    setNegative,
    positive,
    setPositive,
    name,
    setName,
    isProcessing,
    setIsProcessing,
    error,
    setError,
    handleUpdate,
    handlePoll,
    handleDuplicate,
    handleDestroy,
    isNameChanged,
    setIsNameChanged,
    isAttributesChanged,
    setIsAttributesChanged,
    originalName,
    setOriginalName,
    originalPositive,
    setOriginalPositive,
    originalNegative,
    setOriginalNegative,
    isCharacterEditorOpen,
    setIsCharacterEditorOpen,
    characterStyles,
    setCharacterStyles,
    style,
    setStyle,
    originalStyle,
    setOriginalStyle,
    characterSelectOptions,
    isActive,
    setIsActive,
    originalIsActive,
    setOriginalIsActive,
    isActiveChanged,
    setIsActiveChanged,
    TrackCharacterEvent,
  };

  return (
    <GeneratorCharatcerContext.Provider value={values}>
      {children}
    </GeneratorCharatcerContext.Provider>
  );
};
