/** @prettier */
import React, { useEffect, useState } from 'react';
import { InlineAlert } from '../shared/InlineAlert';
import { Select } from '../shared/Select';
import '../../flux/stores/team_management';
import { unique } from 'underscore';
import { RequestActions } from 'javascripts/flux/actions/request';
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import { useTranslation } from 'react-i18next';
import {
  WizardContext,
  WizardProvider,
} from 'blackbird/components/wizard/WizardContext';
import { TourHintable } from '../tours/TourHintable';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import { ToursActions } from 'javascripts/flux/actions/tours';
import { TeamManagementActions } from 'javascripts/flux/actions/team_management';

interface Option {
  label: string;
  value: string | number;
}

type variants = 'teammate' | 'collaborator' | 'supermember';

interface CommonProps {
  teamId: number;
  children?: React.ReactElement;
  initialFieldCount?: number;
  maximumFieldCount?: number;
  notice?: string;
  compact?: boolean;
  onSuccess?: () => void;
  teamName?: string;
  type: variants;
  userTypes?: Option[];
  projectList?: Option[];
}

const filterEmails = (emails: string[]) =>
  emails.filter((e) => e && e.length > 3 && e.indexOf('@') > 0);

const InvitationFormElement: React.FC<CommonProps> = (props) => {
  const {
    type,
    compact,
    children,
    notice,
    initialFieldCount = 3,
    maximumFieldCount = 10,
    teamId,
    projectList,
    userTypes,
    teamName,
    onSuccess,
  } = props;

  const { handleComplete } = React.useContext(WizardContext);

  const { t } = useTranslation();
  const [placeholder, setPlaceholder] = useState('coworker@example.com');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filteredEmails, setFilteredEmails] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>(
    new Array(initialFieldCount).fill(''),
  );
  const [willHaveAccessToProject, setWillHaveAccessToProject] = useState<
    string | undefined
  >(
    type === 'collaborator' && projectList
      ? projectList[0]?.value?.toString()
      : undefined,
  );

  useEffect(() => {
    if (BoordsConfig.HasCompanyDomain) {
      setPlaceholder(`coworker@${BoordsConfig.Email.split('@')[1]}`);
    }
  }, []);

  // Filter email address when emails changes
  // (seems simpler than using debouce, which led to missed characters
  // at the end of strings)
  useEffect(() => {
    setFilteredEmails(() => {
      const newEmails = filterEmails(emails);
      const allEmailsAreValid = newEmails.length === emails.length;
      const maximumIsReached = newEmails.length >= maximumFieldCount!;

      if (allEmailsAreValid && !maximumIsReached) {
        setEmails((prevEmails) => [...prevEmails, '']);
      }
      return newEmails;
    });
  }, [emails, maximumFieldCount]);

  const setInitialUserType = () => {
    if (props.type === 'teammate' && userTypes) {
      return userTypes[0]?.value;
    } else if (props.type === 'supermember') {
      return 'supermember';
    } else {
      return undefined;
    }
  };
  const [userType, setUserType] = useState<string | number | undefined>(
    setInitialUserType(),
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    const redirect = onSuccess
      ? onSuccess
      : () => {
          window.location.pathname =
            typeof teamId !== 'undefined'
              ? `/settings/team/${teamId}/manage`
              : '/settings/team/manage';
        };

    const handleError = (message: string) => {
      setIsSubmitting(false);
      RequestActions.error(message);
    };

    const filteredEmails = filterEmails(emails);

    if (filteredEmails.length === 0) {
      return handleError(t('common.invite_form.notices.invalid_email'));
    }

    if (type === 'collaborator' && !willHaveAccessToProject) {
      return handleError(t('common.invite_form.notices.invalid_project'));
    } else if (type !== 'collaborator' && !userType) {
      return handleError(t('common.invite_form.notices.invalid_user_type'));
    }

    TeamManagementActions.bulkInviteMember({
      emails: filteredEmails,
      domain: '',
      teamId: teamId,
      teamName: teamName,
      role: userType,
      will_have_access_to_project: willHaveAccessToProject,
      callback: () => {
        if (
          ToursStore.state.currentTours.length > 0 &&
          ToursStore.state.currentTours[0].name === 'wizardInvite'
        ) {
          ToursActions.advanceTour.defer('wizardInvite');
          ToursActions.cancelTour.defer('wizardInvite');
        }
        handleComplete('invite-teammates');
        setTimeout(redirect, 500);
      },
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const match = e.currentTarget.name.match(/\d+/);
    if (!match) return;

    const { value } = e.currentTarget;
    const index = parseInt(match[0], 10);

    setEmails((state) => {
      const newState = [...state];
      newState[index] = value;
      return unique(newState);
    });
  };

  return (
    <>
      {type === 'collaborator' && projectList && (
        <div className="mb-8">
          <div className="mb-6">
            <label htmlFor="will_have_access_to_project" className="font-bold">
              {t('common.invite_form.invite_to_project.title')}
            </label>
            <div className="mt-1 text-type-subdued">
              {t('common.invite_form.invite_to_project.description')}
            </div>
          </div>

          <Select
            value={willHaveAccessToProject}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setWillHaveAccessToProject(e.target.value)
            }
            id="will_have_access_to_project"
            name="will_have_access_to_project"
          >
            <optgroup
              label={t('common.invite_form.invite_to_project.choose_dropdown')}
            />
            {projectList.map(({ value, label }) => (
              <option key={value.toString()} value={value.toString()}>
                {label}
              </option>
            ))}
          </Select>
        </div>
      )}

      {!compact && (
        <div className="mb-3 font-semibold">
          {t('common.invite_form.email_label')}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        {emails.map((value, i) => (
          <div className={`${compact ? `mb-4` : `mb-6`}`} key={i}>
            <TourHintable
              step={`wizardInviteInput`}
              hideNextAndSteps
              overlayPosition="right-start"
              distance={20}
              canShow={i === 0}
            >
              <TextInput
                name={`emails.${i}`}
                placeholder={placeholder}
                onChange={handleEmailChange}
                value={value}
                type="email"
                inputSize={compact ? 'md' : 'lg'}
                autoComplete={'nope'}
              />
            </TourHintable>
          </div>
        ))}

        {type === 'teammate' && userTypes && (
          <div className="mt-8">
            <div className="flex items-center mb-6">
              <label htmlFor="userType" className="text-base font-semibold">
                {t('common.invite_form.teammate.label')}
              </label>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://help.boords.com/getting-started/team-roles"
                className="ml-4 underline no-underline-hover"
              >
                {t('common.invite_form.teammate.helpDoc')}
              </a>
            </div>

            <Select
              name="userType"
              id="userType"
              value={userType}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setUserType(e.target.value)
              }
            >
              <optgroup
                label={t('common.invite_form.teammate.choose_dropdown')}
              />
              {userTypes.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </div>
        )}

        {notice && (
          <div className="mt-6">
            <InlineAlert type="notice" message={notice} />
          </div>
        )}

        {children}

        <div
          className={`flex items-center mt-8 ${compact ? 'justify-end' : ''}`}
        >
          <div className="flex items-center justify-end space-x-4">
            {compact && onSuccess && (
              <div
                className="text-sm underline cursor-pointer text-type-subdued decoration-border-mid hover:no-underline underline-offset-2"
                onClick={onSuccess}
              >
                {t('common.invite_form.buttons.skip')}
              </div>
            )}
            <Button
              type="solid"
              htmlType="submit"
              size={compact ? 'sm' : 'lg'}
              disabled={filteredEmails.length === 0}
            >
              {compact
                ? t('common.invite_form.buttons.compact')
                : t('common.invite_form.buttons.full')}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export const InvitationForm: React.FC<CommonProps> = (props) => {
  return (
    <DefaultStoreContextProvider>
      <WizardProvider>
        <InvitationFormElement {...props} />
      </WizardProvider>
    </DefaultStoreContextProvider>
  );
};

(window as any).SettingsInviteUsers = InvitationForm;
