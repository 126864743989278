/** @prettier */
import * as React from 'react';
import { useTemplates } from './TemplatesContext';
import { useTranslation } from 'react-i18next';
import Select from 'blackbird/components/form/select/Select';
import type { Option } from 'blackbird/components/common/types';
import { frameAspectRatioNormalizer } from 'javascripts/helpers/frame-size-helper';

interface TemplateSelectProps {
  teamId: number;
  label?: React.ReactNode;
  placeholder?: string;
  hideLabel?: boolean;
  defaultTemplateId?: string;
  onSelect?: (templateId: string) => void;
}

export const TemplateSelect: React.FC<TemplateSelectProps> = ({
  teamId,
  label,
  defaultTemplateId,
  hideLabel,
  placeholder = 'Choose a template...',
  onSelect,
}) => {
  const {
    currentTemplate,
    setCurrentTemplate,
    setCurrentTemplateFromId,
    templates,
    isFetched,
    fetchTemplates,
  } = useTemplates();

  const { t } = useTranslation();

  React.useEffect(() => {
    if (!isFetched) {
      fetchTemplates(teamId as any);
    }
  }, [teamId, isFetched]);

  React.useEffect(() => {
    if (isFetched && defaultTemplateId) {
      setCurrentTemplateFromId(defaultTemplateId);
    }
  }, [defaultTemplateId, isFetched]);

  const options: Option[] = React.useMemo(() => {
    if (!templates) return [];

    return templates
      .sort((a, b) => a.name.localeCompare(b.name)) // Sort templates by name
      .map((template) => ({
        label: template.name,
        subLabel: `${t(
          `aspectRatios.${frameAspectRatioNormalizer(template.aspect_ratio)}`,
        )} / ${template.fields.length} Fields`,
        value: template.id.toString(),
      }));
  }, [templates, t]);

  const handleChange: React.ComponentProps<typeof Select>['onChange'] = (
    value,
  ) => {
    setCurrentTemplateFromId(value);
    // setSelectedDropdownTemplateId(value);
    if (onSelect) {
      onSelect(value);
    }
  };

  if (!templates || templates.length === 0) {
    return null;
  }

  return (
    <Select
      size="lg"
      allowRemove
      onAllowRemove={() => {
        setCurrentTemplate(null);
      }}
      labelClasses="!w-full"
      label={
        hideLabel ? null : currentTemplate ? (
          <span className="!text-base inline-flex items-center w-full">
            <span className="inline-flex items-center flex-auto space-x-1">
              <span>{`Template`}</span>
              <HelpPopup
                text={`Storyboards in this project have been assigned this template as a default.`}
              />
            </span>
          </span>
        ) : (
          <span className="!text-base">{label}</span>
        )
      }
      options={options}
      selectContainerClassName="w-full"
      disableInput
      value={currentTemplate?.id}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};
