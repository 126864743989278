const _ = require('underscore');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
const { RequestErrorHandler } = require('../../helpers/request-error-handler');
const { openConfirmDialog } = require('javascripts/helpers/openDialog');
const {DialogContext} = require('blackbird/components/dialog/DialogContext')
const errorHandler = RequestErrorHandler('popupMenu');

module.exports = createReactClass({

  displayName: "PopupMenuPostAction",

  propTypes: {
    action: PropTypes.shape({
      label: PropTypes.string.isRequired,
      className: PropTypes.string,
      url: PropTypes.string,
      params: PropTypes.object,
      newSection: PropTypes.bool,
      confirmWith: PropTypes.string,
      actionType: PropTypes.string,
      JsActionIdentifier: PropTypes.string,
      method: PropTypes.string,
    })
  },

  contextType: DialogContext,

  _executeAction(e) {
    e.preventDefault();
    const {confirmWith} = this.props.action;

    if (!_.isEmpty(confirmWith)) {
      openConfirmDialog(confirmWith, this.context).then((confirmed) => {
        if (confirmed) this._requestHandler();
      })
    } else {
      this._requestHandler();
    }

  },

  _requestHandler() {
    const {actionType} = this.props.action;

    if (actionType === "js") {
      this._performJsAction();
    } else if (actionType === "http") {
      this._performHttpRequest();
    }
  },

  _performJsAction() {
    const {JsActionIdentifier, params} = this.props.action;

    if (JsActionIdentifier === "edit_project_access") {
      FlyoverActions.open({
        type: 'editProjectAccess', props: { member: params.membership }
      })
    } else {
      console.error("No action defined for " + JsActionIdentifier);
    }
  },

  _performHttpRequest() {
    const {url, params, method} = this.props.action;
    ajaxHelper.ajax({
      url: url,
      method: method,
      dataType: "json",
      data: params,
      success: function(response) {
        window.location.reload();
      }.bind(this),
      // We let the error handler find the right error message.
      error: errorHandler({ method }, () => {
      })
    });
  },

  render() {

    const {method, className, label, newSection, url} = this.props.action;

    const classes = "f5 no-underline underline-hover pv1 lh-copy db ph3 pointer " +  className;

    return (
      <div>
        {newSection && (
          <div className="bb bw1 b--grey pt2 mb2" />
        )}
        {(method === "get") ? (
          <a className={classes} href={url}>
            {label}
          </a>
        ) : (
          <div className={classes} onClick={this._executeAction}>
            {label}
          </div>
        )}
      </div>
    );
  }
});
