const Rollbar = require('../../helpers/rollbar').rollbar
require('../../flux/actions/xhr_error');

(() => {
  class XhrErrorStore {

    constructor() {
      this.show        = false;
      this.status_code = -1;
      this.context     = '';

      this.bindListeners({
        handleShow:  XhrErrorActions.SHOW,
        handleHide:  XhrErrorActions.HIDE,
      });
    }

    handleShow(args) {
      this.show        = true;
      this.status_code = args.status_code;
      this.context     = args.context;
      this._rollbar(args);
    }

    handleHide(args) {
      this.show = false;
    }

    _rollbar(args) {
      // Log the error and the response if present
      Rollbar.warning(args.context + ": HTTP STATUS " + args.status_code, args.response);
    }

  }

 window.XhrErrorStore = alt.createStore(XhrErrorStore, 'XhrErrorStore');

})();
