/**@prettier */
import { Modifier, usePopper } from 'react-popper';
import * as PopperJS from '@popperjs/core';
import maxSize from 'popper-max-size-modifier';
import React from 'react';
import { ModifierArguments } from '@popperjs/core';

export interface CustomPopperData {
  styles: { [key: string]: React.CSSProperties };
  attributes: { [key: string]: { [key: string]: string } | undefined };
  state: PopperJS.State | null;
  update: PopperJS.Instance['update'] | null;
  forceUpdate: PopperJS.Instance['forceUpdate'] | null;
}

function useCustomPopper(
  referenceElement?: Element | PopperJS.VirtualElement | null,
  popperElement?: HTMLElement | null,
  options?: Omit<Partial<PopperJS.Options>, 'modifiers'> & {
    createPopper?: typeof PopperJS.createPopper;
    offset?: number;
    distance?: number;
    padding?: number;
    maxHeight?: number;
  },
): CustomPopperData {
  const applyMaxSize = React.useCallback(
    ({ state }: ModifierArguments<Record<string, unknown>>) => {
      // The `maxSize` modifier provides this data
      const maxSize = state.modifiersData.maxSize;
      const height = Math.min(maxSize.height, options?.maxHeight ?? 636);

      state.styles.popper.maxHeight = `${Math.max(200, height)}px`;
    },
    [],
  );
  const distance = options?.distance ?? 0;
  const offset = options?.offset ?? 0;
  const padding = options?.padding ?? 0;

  const popperConfig = usePopper(referenceElement, popperElement, {
    ...options,
    modifiers: [
      {
        name: 'flip',
        enabled: true,
      },
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [offset, distance],
        },
      },
      {
        ...maxSize,
        options: {
          padding,
        },
      },
      {
        name: 'applyMaxSize',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['maxSize'],
        fn: applyMaxSize,
      },
    ],
  });

  return popperConfig;
}

export default useCustomPopper;
