/** @prettier */
import { isNumber, last } from 'underscore';
import logger from './logger';
export interface TransformFilestackUrlProps {
  url: string;
  width: number;
  height: number;
  format?: string;
}

export const transformFilestackUrl = (props: TransformFilestackUrlProps) => {
  const apiKey = FilestackStore.getState().apiKey;
  if (!apiKey) throw new Error('could not find filestack API key');
  if (!isNumber(props.width) || !isNumber(props.height))
    throw new Error(
      `Invalid image size passed, received ${props.width}, ${props.height}`,
    );
  if (props.url.indexOf('cdn.filestackcontent.com') < 0) {
    logger.error('Could not transform url ' + props.url);
    return props.url;
  }
  const imageId = last(props.url.split('/'));

  // prettier-ignore
  let transformation = `resize=fit:crop,w:${Math.round(props.width)},h:${Math.round(props.height)}/output=`;

  transformation += [
    props.format ? `format:${props.format}` : undefined,
    'compress:true',
    'strip:true',
    'q:90',
  ]
    .filter(Boolean)
    .join(',');

  return `https://process.filestackapi.com/${apiKey}/${transformation}/${imageId}`;
};
