/**@prettier */
const { default: Dialog } = require('blackbird/components/dialog/Dialog');
const createReactClass = require('create-react-class');
window.FlyoverArchivedStoryboardContainer = createReactClass({
  getInitialState() {
    return {
      loading: true,
      canRestore: false,
      isOpen: false,
    };
  },

  componentDidMount() {
    PermissionActions.verifyStoryboard.defer({
      project_id: this.props.storyboard.project.id,
      onSuccess: function () {
        this.setState({
          loading: false,
          canRestore: true,
          isOpen: false,
        });
      }.bind(this),
      onError: function () {
        this.setState({
          loading: false,
          canRestore: false,
          isOpen: true,
        });
      }.bind(this),
    });
  },

  render: function () {
    const { loading, canRestore } = this.state;

    if (loading) {
      return null;
    } else {
      return (
        <Dialog
          size="confirm"
          isBlur
          confirmBtnSize="sm"
          hideDescription
          hideClose
          hideCancel
          isOpen
          keepOpen
          alignButtons="left"
          isDark
          title={
            !canRestore ? `Upgrade Required` : `This Storyboard is Archived`
          }
          subtitle={
            !canRestore
              ? `Please upgrade to restore this storyboard.`
              : `Archived storyboards can't be edited or shared.`
          }
          confirmText={!canRestore ? `Upgrade` : `Restore Storyboard`}
          onConfirmBtnClick={() => {
            if (canRestore) {
              StoryboardActions.restoreStoryboard(this.props.storyboard);
            } else {
              this.setState({ isOpen: false });
              UpgradePromptActions.show('Prompt from Archived Storyboard');
            }
          }}
          actionChildren={
            <div className="text-sm space-x-4 text-type-subdued">
              <a href={`/`}>{`Go to Dashboard`}</a>
            </div>
          }
        />
      );
    }
  },
});
