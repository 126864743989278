/** @format */
import Button from 'blackbird/components/button/Button';
import { PanelbarActions } from 'javascripts/flux/actions/panelbar';
import * as React from 'react';

const PanelbarCommentsDisabled: React.FC = () => (
  <div className="m-8 space-y-6">
    <div className="text-base font-semibold">Comments not enabled</div>
    <Button
      onClick={() =>
        PanelbarActions.open.defer({ type: 'settings', subPanel: 'comments' })
      }
    >
      Open settings
    </Button>
  </div>
);

(window as any).PanelbarCommentsDisabled = PanelbarCommentsDisabled;
