/** @format */
import React, { useContext } from 'react';
import { ShareableProjectContext } from './ShareableProjectContext';
import RichTextInput from '../form/richTextInput/RichTextInput';
import classNames from 'classnames';
import Avatar from '../avatar/Avatar';
import { getFirstCharacter } from 'javascripts/helpers/get-first-character';

export const filterDataUrl = (string?: string) =>
  string?.indexOf('data:') === 0 ? undefined : string;

export const ShareableProjectHeader: React.FC = () => {
  const { project, team } = useContext(ShareableProjectContext);

  if (!project) {
    return null;
  }

  return (
    <>
      {team && (
        <div className="flex items-center mb-4 space-x-2">
          <Avatar
            className="flex-shrink-0"
            size="xs"
            borderColor="border-white"
            initial={getFirstCharacter(team.name)}
            image={filterDataUrl(team.logo_url)}
            rounded={false}
          />
          <h2 className="flex-auto text-sm font-semibold truncate">
            {team.name}
          </h2>
        </div>
      )}
      <div className="text-xl font-semibold">{project.name}</div>
      <div className="mb-2">
        {project.description && project.share_with_description ? (
          <div className={classNames('mt-4')}>
            <RichTextInput
              disabled={true}
              immediate={false}
              autoFocus={false}
              value={project.description}
              onChange={() => {}} // No-op function as it's not editable
              className="min-h-[1.5rem] max-w-2xl rounded-sm px-1 -ml-1"
            />
          </div>
        ) : (
          <div className="" />
        )}
      </div>
    </>
  );
};
