/**
 * @prettier
 * Hello! this file should be self-documented enough, but it also has a
 * markdown file dedicated to it in the docs folder (Error-Handling.md)
 */
/* tslint:disable callable-types */

import * as _ from 'underscore';
import * as get from 'lodash.get';
import { rollbar } from './rollbar';
import { compact, includes, isUndefined } from 'underscore';
import logger from './logger';
import * as i18next from 'i18next';
import type { JsonObject, LiteralUnion } from 'type-fest';
import { AJAXError } from './ajax';

const NETWORK_ERROR_HELP_LINK =
  'https://help.boords.com/en/articles/8679926-what-should-i-do-if-i-encounter-an-error-message-while-attempting-to-upload-images';

let isUnloading = false;
window.addEventListener('beforeunload', () => {
  isUnloading = true;
  return undefined; // Do not cause the dialog to popup!
});

type errorReasons = 500 | 520 | 503 | 525 | 0 | 422 | 408;
export type httpMethods = LiteralUnion<
  'get' | 'post' | 'delete' | 'patch',
  string
>;
type severity = 'error' | 'warn' | 'debug' | 'critical' | 'info';
type acceptedError =
  | Error
  | AJAXError
  | Response
  | Record<string, any>
  | undefined;

export type notificationFunc = (
  error: acceptedError,
  parsed: IParsedError,
) => void;

const defaultNotificationFunc: notificationFunc = (err, parsed) => {
  // Importing this the proper way seems to create a race condition
  const RequestActions = (window as any).RequestActions;
  if (typeof RequestActions !== 'undefined')
    RequestActions.error.defer(parsed.userMessage);
};

interface IErrorHandlerConstructor {
  /** Create a new error handler for a specific context/store (e.g. Billing) */
  (context?: string, serviceName?: string): IErrorHandlerInstance;
}

interface IErrorHandlerInstance {
  /**
   * Set any options for the error you are about to log, like the user-facing
   * message or the Rollbar specific error. If left blank, it will automatically
   * try to find an appropriate message, especially when passing a method prop
   */
  (
    options?: ErrorHandlerInstanceOptions,
    callback?: (err: acceptedError) => void,
  ): IErrorLogger;
}

/** Options passed to the handler after it's been created */
export interface ErrorHandlerInstanceOptions {
  /**
   * User-facing error message. Will automatically be suffixed with something
   * like “Try again or contact us…” unless askUserToRetry is false. Use null
   * to prevent showing a message to the user.
   * @deprecated in favour of messageKey
   */
  message?: string | null;
  /**
   * User-facing error message. Will automatically be suffixed with something
   * like “Try again or contact us…” unless askUserToRetry is false. Use null
   * to prevent showing a message to the user.
   */
  messageKey?: string | null;
  /** data to be passed along to i18next for the translation string */
  messageData?: Record<string, unknown>;
  messageLink?: string;
  /**
   * the message to be logged to Rollbar (and logged to console). The actual
   * error/request object will be sent alongside this
   */
  rollbarMessage?: string;
  /*** Rollbar + console error severity */
  severity?: severity;
  /***
   * When true, a message will be added to the user-facing message asking them to retry, defaults to true
   */
  askUserToRetry?: boolean;
  /**
   * Will be used to find an appropriate error message when no manual messsage
   * was passed. For example, it will refer to 'saving' changes when this
   * is 'post
   */
  method?: httpMethods;

  shouldLogConsole?: boolean;
  notificationFunc?: notificationFunc;
  /** The name of the service that might have caused this issue. This defaults
   * to Boords, but in some cases you might want to name another service like
   * `Unsplash`. Feel free to enter `Boords or ShittyService` in case multiple
   * services are used. */
  serviceName?: string;
  /** Override the status code of the error used to determine the right text
   * to show to the user
   */
  reason?: errorReasons;
  /** data to be added to Rollbar's error occurrence in the `message.extra`
   * field  */
  extraData?: JsonObject;
}

/** resulting function (e.g. the actual error-handling function) */
interface IErrorLogger {
  /**
   * Log an error, this can be left blank to fall back to the messages defined
   * in the options
   */
  (error?: acceptedError, textStatus?: string, errorThrown?: any): any;
}
interface IParsedError {
  userMessage?: string;
  messageLink?: string;
  rollbarError?: Error;
  stack?: string;
  shouldLogRollbar: boolean;
  severity: severity;
  shouldShowUserMessage: boolean;
  shouldLogConsole: boolean;
  /** data to be added to Rollbar's error occurrence in the `message.extra`
   * field */
  data: JsonObject;
}

const getErrorMessage = (status: number, clientName = 'Boords') => {
  return i18next.t(`errorHandling.status.${status}`, {
    service: clientName,
    defaultValue: null,
  });
};

const getUserMessage = ({
  message,
  messageKey,
  messageData,
}: ErrorHandlerInstanceOptions) => {
  if (typeof message !== 'undefined') {
    return message;
  } else if (typeof messageKey !== 'undefined') {
    return messageKey === null
      ? messageKey
      : i18next.t(messageKey, messageData);
  }
};

export const parseError = async (
  context,
  options: ErrorHandlerInstanceOptions = {},
  error?: acceptedError,
  textStatus?: string,
): Promise<IParsedError> => {
  const contextString = context ? `[${context.toUpperCase()}] ` : '';
  const userMessage = getUserMessage(options);
  const output: IParsedError = {
    userMessage: userMessage || undefined,
    shouldShowUserMessage: userMessage === null ? false : true,
    shouldLogRollbar: true,
    severity: options.severity || 'error',
    shouldLogConsole:
      typeof options.shouldLogConsole !== 'undefined'
        ? options.shouldLogConsole
        : true,
    data: options.extraData ?? {},
  };

  let askUserToRetry =
    typeof options.askUserToRetry === 'undefined'
      ? true
      : options.askUserToRetry;

  let reason = isUndefined(options.reason)
    ? undefined
    : getErrorMessage(options.reason, options.serviceName);

  if (!error) {
    const errorMessage = options.rollbarMessage || userMessage || undefined;
    if (errorMessage) {
      error = new Error(errorMessage);
    } else {
      const noMessageError = new Error(
        'Got an error, but was unable to display it because of a missing Error or user-defined message',
      );
      if (process.env.NODE_ENV === 'test') throw noMessageError;
    }
  }

  if (error instanceof Response) {
    // Because we don't want to duplicate all the logic below, we're going to
    // pretend that this is an ajaxError
    let text: string;
    let json: any;

    try {
      text = await error.clone().text();
      json = await error.clone().json();
    } catch {
      text = 'unknown error';
    }
    error = new AJAXError({
      status: navigator.onLine ? error.status : 0,
      request: {
        url: error.url,
      },
      response: {
        JSON: json,
        text: text,
      },
    });
  }
  if (error instanceof Error) {
    if (error.message.indexOf('NetworkError') === 0 && !output.userMessage) {
      output.userMessage = i18next.t('errorHandling.status.0');
      output.severity = 'info';
      output.messageLink = NETWORK_ERROR_HELP_LINK;
    }

    output.userMessage = output.userMessage || error.message;
    output.rollbarError = error;
  } else if (error instanceof AJAXError) {
    const messageForType = i18next.t(
      'errorHandling.method.' + (options.method ?? 'get'),
    );

    const status = options.reason ?? error.status;

    /** The human-readable error returned by the server */
    const requestUserErrorMessage =
      get(error.response?.JSON, 'error') ||
      get(error.response?.JSON, 'errors.0.title') ||
      get(error.response?.JSON, 'message');

    let requestErrorText =
      requestUserErrorMessage ||
      (error.response?.text && error.response.text.slice(0, 50)) ||
      (textStatus && textStatus.slice(0, 50)) ||
      error.response?.text;

    if (
      !requestErrorText ||
      requestErrorText === 'true' ||
      requestErrorText === 'false' ||
      // Ignore HTML as status
      requestErrorText[0] === '<'
    ) {
      requestErrorText = null;
    }

    // we want to show the reason a request fails, but in case the server offers
    // an explanation for the 422 errors, we don't have to add the reason
    if (status !== 422 || !requestErrorText) {
      reason = getErrorMessage(status, options.serviceName);
    }

    if (status === 0) {
      output.severity = 'info';
      output.messageLink = NETWORK_ERROR_HELP_LINK;
    }

    let rollbarMessage;
    if (status === 429) {
      rollbarMessage = 'Rate limit exceeded';
    } else if (options.rollbarMessage && requestUserErrorMessage) {
      rollbarMessage = `${options.rollbarMessage} (${requestUserErrorMessage})`;
    } else {
      rollbarMessage =
        options.rollbarMessage ||
        requestErrorText ||
        options.message ||
        'Unknown error';
    }

    output.rollbarError = new Error(rollbarMessage);

    Object.assign(output.rollbarError, error);

    output.userMessage = output.userMessage
      ? compact([output.userMessage, requestUserErrorMessage]).join('. ')
      : requestUserErrorMessage || messageForType;

    if (includes([0, 401, 422, 500, 503, 429, 525], status)) {
      output.severity = 'info';
    }

    // Probably don't want people to retry when being rate limited or when they
    // made the actual mistake
    if (includes([422, 429], status)) {
      askUserToRetry = false;
    }
  } else {
    output.rollbarError = new Error(options.rollbarMessage);
  }

  output.userMessage = (
    compact([
      output.userMessage,
      reason,
      askUserToRetry &&
        i18next.t('errorHandling.retry', 'Please try again later'),
    ]).join('. ') + '.'
  )
    .replace('!.', '!')
    .replace(/\.\./g, '.');

  output.shouldLogRollbar =
    output.shouldLogRollbar &&
    !!output.rollbarError &&
    options.severity !== 'debug';

  // Add prefix
  if (output.rollbarError) {
    const stack = output.rollbarError.stack;
    output.rollbarError = Object.assign(new Error(), output.rollbarError, {
      message: contextString + output.rollbarError.message,
    });
    output.rollbarError.stack = stack;
  }

  return output;
};

/*
 * This function is meant as a generic error handler for AJAX request and such
 * It has two stages of construction, an initialiser that gets a context (used
 * for logging), and a second stage with options for the specific occasion
 * for example:
 * const handler = RequestErrorHandler('billing')
 * ajax('/url).then(doStuff, handler('something went wrong'))
 *
 * The goal is to automate most of the error handling, and show appropriate
 * messages to the users when possible
 */
export const RequestErrorHandler: IErrorHandlerConstructor =
  (context, serviceName) =>
  (
    options: ErrorHandlerInstanceOptions = {},
    callback?: (error: acceptedError, parsedError: IParsedError) => any,
  ) =>
  async (error, textStatus) => {
    const notificationFunc =
      options.notificationFunc || defaultNotificationFunc;
    const parsed = await parseError(
      context,
      { serviceName, ...options },
      error,
      textStatus,
    );
    // Don't show a message if we're unloading the page
    if (isUnloading) return;

    const logSeverity =
      {
        warn: 'warn',
        debug: 'info',
        info: 'info',
        error: 'error',
        critical: 'error',
      }[parsed.severity || 'error'] || 'error';

    if (parsed.shouldLogConsole)
      logger[logSeverity](parsed.rollbarError, error);

    // Actually show the errors
    if (parsed.shouldLogRollbar) {
      if (parsed.userMessage && parsed.shouldShowUserMessage) {
        parsed.data.notification = parsed.userMessage;
      }

      rollbar[parsed.severity](parsed.rollbarError!, parsed.data, () => {
        callback?.(error, parsed);
      });
    }

    if (parsed.shouldShowUserMessage) {
      notificationFunc(error, parsed);
    }

    // If shouldLogRollbar is true, we will delay the callback after we have
    // succesfull sent the error to rollbar
    if (callback && !parsed.shouldLogRollbar) callback(error, parsed);
  };

/**
 * Helper function that allows error callbacks to determine if the error
 * returned is jquery XHR or something else. There is probably a way to
 * do this without a helper function, trough some TS Generics, but i tried and
 * failed!
 */
export const isErrorXHR = (error: acceptedError): error is AJAXError =>
  typeof error !== 'undefined' && error instanceof AJAXError;
