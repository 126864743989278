/** @prettier */
import classNames from 'classnames';
import * as React from 'react';
const striptags = require('striptags');

type Props = {
  value: string;
  onChange: (newValue: string) => void;
  clickable?: boolean;
  isEditable?: boolean;
  className?: string;
  onBlur?: () => void;
  readOnly?: boolean;
  autoFocus?: boolean;
};

function sanitize(string: string) {
  return striptags(string.replace(/\n/g, ''));
}

function preventNewLines(e) {
  e.stopPropagation();
  if (e.key === 'Enter') e.currentTarget.blur();
}

export const EditableTitle: React.FC<Props> = (props) => {
  const { onChange, clickable, className, isEditable, readOnly, autoFocus } =
    props;
  const ref = React.useRef<HTMLHeadingElement>(null);
  const [editable, setEditable] = React.useState(false);
  const handleClick = React.useCallback<React.MouseEventHandler>(
    (e) => {
      e.preventDefault();
      if (readOnly) return;
      if (clickable) setEditable(true);
    },
    [clickable, readOnly],
  );

  const handleBlur = (e) => {
    if (readOnly) return;
    const newText =
      e.currentTarget.innerText.length > 0
        ? sanitize(e.currentTarget.innerText)
        : props.value;
    e.currentTarget.scroll(0, 0);

    if (newText !== props.value) onChange(newText);
    setEditable(false);
    props.onBlur && props.onBlur();
  };

  // Focus when it becomes editable changes
  React.useEffect(() => {
    if ((editable || isEditable) && ref.current && autoFocus) {
      const selection = window.getSelection();
      const range = document.createRange();
      selection?.removeAllRanges();
      range.selectNodeContents(ref.current);
      range.collapse(false);
      selection?.addRange(range);
      ref.current.focus();
    }
  }, [editable, isEditable, autoFocus]);

  return (
    <h1
      ref={ref}
      className={classNames(
        `text-lg truncate  min-w-10 rounded-sm ${className}`,
        {
          'bg-brand-pink-25 border-b border-b-border-dark outline-none cursor-pointer':
            editable && !readOnly,
          'pointer-events-none': readOnly,
        },
      )}
      onClick={handleClick}
      title={props.value}
      contentEditable={(isEditable ?? editable) && !readOnly}
      dangerouslySetInnerHTML={{ __html: props.value }}
      onBlur={handleBlur}
      onPaste={(e) => {
        const sanitized = sanitize(e.clipboardData.getData('text/plain'));
        document.execCommand('insertText', false, sanitized);
        e.preventDefault();
      }}
      onKeyDown={preventNewLines}
    ></h1>
  );
};

EditableTitle.defaultProps = {
  autoFocus: true,
};
