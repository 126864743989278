/** @prettier */
import { tourEvents, type TourBlueprint } from './tourData';

/**
 * A simple array of the names of all the steps that we have, this is
 * mostly in order to generate a type for all these steps. The `as const` is
 * important for that. We then use the steps in the object below.
 */
// prettier-ignore
const editingTourSteps = ['openFrame','imageLibrarySidebar', 'generatorPrompt', 'generatorTimer', 'generatorInsert', 'saveAndCloseTheEditor'] as const;

export type editingTourAvailableSteps = typeof editingTourSteps[number];

export const editingTour: TourBlueprint<editingTourAvailableSteps> = {
  name: 'editing',
  steps: [
    {
      name: 'openFrame',
      waitsOn: tourEvents.openFrameEditor,
      header: `Welcome to the Storyboard View!`,
      body: `Let's take a 60-second tour...`,
    },
    {
      name: 'imageLibrarySidebar',
      header: `AI, Icons, and more...`,
      body: `With Boords you can create storyboards with our AI generator, Asset Library, or using your own images.`,
    },
    {
      name: 'generatorPrompt',
      header: `The Image Generator`,
      body: `Let's generate an image for this frame. When you're happy with the text prompt, click 'Next' to create your image.`,
    },
    {
      name: 'generatorTimer',
      header: 'Generating Image...',
      body: `This usually takes about 30 seconds. Not long to go!`,
    },
    {
      name: 'generatorInsert',
      header: 'Success!',
      body: `Let's add your new image to your storyboard.`,
      buttonText: 'Sounds Good!',
    },
    {
      name: 'saveAndCloseTheEditor',
      waitsOn: tourEvents.saveAndCloseFrameEditor,
      header: 'Save & Close',
      body: `Click here to save your frame and return to the Storyboard View.`,
      buttonText: 'Complete Tour',
    },
  ],
};
