/** @prettier */
import * as React from 'react';
import { PanelbarFrameAssetDragPreview } from '../panelbars/ImageLibrary/PanelbarFrameAssetItem';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';

export const BoordsDndProvider: React.FC = props => (
  <DndProvider backend={MultiBackend} options={HTML5toTouch}>
    <PanelbarFrameAssetDragPreview />
    {props.children}
  </DndProvider>
);
