/** @prettier */
/* eslint-disable react/jsx-handler-names */
import * as React from 'react';
import { ToolbarButton } from '../../ToolbarButton';
import type { ContextMenuProps } from '../types';
import { arrangementOptions } from './toolbarOptions';

export const MultiSelectContextMenu: React.FC<ContextMenuProps> = ({
  disabled,
  actions,
}) => {
  return (
    <>
      {arrangementOptions.map((options) => (
        <ToolbarButton
          key={options.value}
          labelKey={String(options.labelKey ?? options.value)}
          labelKeyPrefix="frameEditor.toolbar.arrangement"
          value={options.value}
          icon={React.createElement(options.icon)}
          labelPosition="top"
          onClick={actions.handleArrangeObjects}
          disabled={disabled}
        />
      ))}
    </>
  );
};

MultiSelectContextMenu.displayName = 'MultiSelectContextMenu';
