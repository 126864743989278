const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
const { openLightbox } = require('javascripts/helpers/lightbox');

window.Lightbox = createReactClass({

  propTypes: {
    url: PropTypes.string.isRequired,
  },

  componentDidMount: function() {
    openLightbox(this.props.url);
    var virtualFlyoverUrl = "/virtual/" + this.props.url;

    Track.page.defer({
      title: "Video: " + this.props.url,
      path:  virtualFlyoverUrl,
      url:   window.location.origin + virtualFlyoverUrl,
    });
    history.pushState({}, null, "/");
  },

  render: function() {
    return null;
  }

});
