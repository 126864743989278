/** @prettier */
import * as React from 'react';
import { useDrop } from 'react-dnd';
import { canDropImages } from '../shared/canDropImages';
import type {
  fileDropResult,
  frameEditorShapeDragItem,
  imageLibraryDragItem,
} from '../shared/dragAndDropUtils';
import { DRAGGABLE_TYPES } from '../shared/dragAndDropUtils';
import { allAcceptedDropTypes } from '../shared/dragAndDropUtils';
import { FrameEditorContext } from './FrameEditorContext';
import type { XYCoordinates } from './types';

export type FrameEditorDropHandler = (
  type: typeof allAcceptedDropTypes[number],
  item: fileDropResult | imageLibraryDragItem | frameEditorShapeDragItem,
  position?: XYCoordinates | null,
) => void;

const acceptedDropTypes = [
  ...allAcceptedDropTypes,
  DRAGGABLE_TYPES.frameEditorShape,
];

export const FrameEditorDropzone: React.FC = () => {
  const context = React.useContext(FrameEditorContext);

  const [{ isDragging, isOver }, ref] = useDrop(
    () => ({
      accept: acceptedDropTypes as any,
      canDrop: canDropImages(true),
      drop: (item, monitor) => {
        if (context) {
          context.onDrop(
            monitor.getItemType() as any,
            monitor.getItem(),
            monitor.getClientOffset(),
          );
        }
      },
      collect: (monitor) => ({
        // Are we currently dragging anything, anywhere in the app?
        isDragging: monitor.canDrop(),
        isOver: monitor.isOver() && monitor.canDrop(),
      }),
    }),
    [context],
  );

  React.useEffect(() => {
    context?.onDragOver(isOver);
  }, [context, isOver]);

  // We want to make sure we ignore pointer events on this element while we're
  // not dragging. It's only when dragging starts somewhere in the app we want
  // to bring this element to the foreground
  return (
    <div
      ref={ref}
      className={`absolute inset-0 ${
        isDragging ? 'z-10' : 'pointer-events-none'
      }`}
    ></div>
  );
};
