const _ = require('underscore');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');

window.AdminPause = createReactClass({

  propTypes: {
    id: PropTypes.number.isRequired,
  },

  _pause: function(e) {

    var pauseDate = prompt("Enter the date you would like billing to resume, e.g. 28 May 2050");

    if (!_.isEmpty(pauseDate)) {
      ajaxHelper.ajax({
        method: "post",
        data: {
          pause_ends_in: pauseDate,
          id: this.props.id
        },
        url: "/admin/" + this.props.id + "/pause.json",
        success: function () {
          RequestActions.success.defer("Subscription paused, redirecting...");
          window.location = "/admin/" + this.props.id;
        }.bind(this),
        error: function() {
          RequestActions.error.defer("Sorry, we couldn't complete that task");
        }.bind(this),
      });
    } else {
      RequestActions.error.defer("Unable to pause subscription");
    }
  },

  render: function() {
    return (
      <AdminButton
        onClick={this._pause}
        text="Pause Subscription"
      />
    );
  }
});
