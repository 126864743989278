const _ = require('underscore');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const Empty = require('./EmptySearchResult.jsx');
const { UnsplashItem } = require('./UnsplashItem');
const { ajaxExternal } = require('javascripts/helpers/ajax');
const { RequestErrorHandler } = require('../../../../helpers/request-error-handler');
const { default: TextInput } = require('blackbird/components/form/text-input/TextInput');
const { default: classNames } = require('classnames');
const { withTranslation } = require('react-i18next');
const errorHandler = RequestErrorHandler('Unsplash')

const UnsplashSearch = createReactClass({
  displayName: 'UnsplashSearch',

  propTypes: {
    onAddImageByUrl: PropTypes.func,
    height: PropTypes.number,
    gridColumns: PropTypes.number,
    embed: PropTypes.bool
  },

  getDefaultProps() {
    return {
      height: 325,
      gridColumns: 3,
    }
  },


  getInitialState() {
    return {
      results: [],
      loaded: false,
      error: false,
      working: true,
      searchTerm: "",
    }
  },

  componentDidMount() {
    this._initialSet();
    this._triggerSearch = _.debounce(q => this._search(q), 300);
  },

  handleChange(e) {
    this.setState({ searchTerm: e.target.value });
    this._triggerSearch(e.target.value)
  },

  handleFocusChange(e) {
    const hasFocus = e.type === 'focus';
    window.setTimeout( () => this.setState({ hasFocus }), 300);
  },

  _reset() {
    this.setState({
      results: [],
      working: true,
    });
  },

  _handleError(e) {
    this.setState({
      error: true,
      results: []
    })
  },

  _initialSet() {
    const {t} = this.props;
    const local = LocalState.getValue("unsplashDefaults")
    if (_.isUndefined(local) || local?.length < 15) {
      ajaxExternal({
        method: "get",
        url: BoordsConfig.DollyUrl + "/unsplash/random.json",
        headers: {
          'X-Authentication-Token': BoordsConfig.AuthenticationToken,
        },
        success: function (response) {
          LocalState.setValue("unsplashDefaults", response);
          this.setState({
            results: response,
            working: false,
          });
        }.bind(this),
        error: errorHandler({
          rollbarMessage: "Could not load initial set",
          serviceName: t('imageLibrary.unsplash.service_name'),
          severity: 'info'
        }),
      }, this._handleError);
    } else {
      this.setState({
        results: local,
        working: false,
      });
    }
  },

  _search(q) {
    const {t} = this.props;
    if (q.length <= 2) {
      this._initialSet();
    } else {
      this._reset();
      ajaxHelper.ajax({
        method: "get",
        url: BoordsConfig.DollyUrl + "/unsplash.json?query=" + encodeURIComponent(q),
        headers: {
          'X-Authentication-Token': BoordsConfig.AuthenticationToken,
        },
        success: function (response) {
          this.setState({
            results: response,
            working: false,
          });
        }.bind(this),
        error: errorHandler({
          serviceName: t('imageLibrary.unsplash.service_name'),
          severity: 'info'
        }, this._handleError),
      });
    }
  },



  render() {
    const { t ,embed} = this.props;
    const {results, searchTerm, working, error} = this.state;
    return (
      <div className={classNames("gap-5 flex flex-col flex-auto",embed ?'px-3':'px-6')}>
        <div className={classNames("flex flex-col bg-white",{"sticky z-40 top-[97px] py-6 gap-2":!embed,'pt-5 gap-4':embed})}>
          <div className="text-xs text-center text-type-disabled">{t('imageLibrary.powered_by')} <a href="https://unsplash.com/?utm_source=boords&utm_medium=referral" target="_blank" rel="noopener noreferrer" className="underline moon-gray no-underline-hover">Unsplash</a></div>
          <TextInput
            inputSize="md"
            placeholder={t('imageLibrary.unsplash.placeholder')}
            value={this.state.searchTerm}
            onChange={this.handleChange}
            onFocus={this.handleFocusChange}
            ref="input"
          />
        </div>
          {results.length === 0 ? (
            error ? (
              <Empty text={t('imageLibrary.unsplash.error')} />
            ) : searchTerm.length === 0 || working ? (
              <Empty text={t('imageLibrary.unsplash.loading')} />
            ) : (
              <Empty text={t("imageLibrary.unsplash.no_results",{searchTerm})} />
            )
          ) : (
            <div className={classNames("relative z-0 pb-6",{'overflow-y-auto':embed})}>
              <div className={classNames('gap-[14px] grid',{
                'grid-cols-3':this.props.gridColumns===3,
                'grid-cols-2':this.props.gridColumns===2,
                'grid-cols-5':this.props.gridColumns===5,
              })}>
                {results.map(
                    (image) => (
                      <UnsplashItem
                        table={image.attributes.table}
                        key={image.attributes.table.id}
                        onClick={this.props.onAddImageByUrl}
                      />
                  )
                )}
              </div>
            </div>
            )}
      </div>
    );
  }
})

module.exports = withTranslation()(UnsplashSearch)
