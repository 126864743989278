/** @prettier */
import * as React from 'react';
import Container from '../shared/Container';
import ToggleButtonWithLabel from '../shared/ToggleButtonWithLabel';
import { UserActions } from '../../flux/actions/user';
import { type UserResponse } from 'javascripts/types/user';
import { useTranslation } from 'react-i18next';

interface IProps {
  user: UserResponse;
  isFetching: boolean;
  handleUpdate: (newValue: boolean) => void;
}

const GeneratorEnable = Container('user', () => ({
  onMount: UserActions.fetch.defer,
  handleUpdate: (newValue: boolean) =>
    UserActions.updateFields({
      is_ai_image_generator_enabled: newValue,
    }),
}))((props: IProps) => {
  const { t } = useTranslation('generatorCopy');
  return (
    <ToggleButtonWithLabel
      onChange={props.handleUpdate}
      value={props.user?.is_ai_image_generator_enabled ?? false}
      disabled={props.isFetching}
      labelPosition={`after`}
      label={
        <div className="flex items-center space-x-2">
          <span>{t('settingsToggleLabel')}</span>
        </div>
      }
    />
  );
});

(window as any).GeneratorEnable = GeneratorEnable;
