/** @format */
import Button from 'blackbird/components/button/Button';
import classNames from 'classnames';
import * as React from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

type Props = Omit<React.ComponentPropsWithoutRef<typeof Button>, 'onChange'> & {
  onChange: (file: File) => void;
};

export const FileButton: React.FC<Props> = (props) => {
  const { onChange, ...rest } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'player.audio' });
  const [{ isDragging, isOver }, ref] = useDrop(() => ({
    accept: NativeTypes.FILE,
    drop: (item, monitor) => {
      onChange(monitor.getItem<{ files: File[] }>().files[0]);
    },
    collect: (monitor) => ({
      // Are we currently dragging anything, anywhere in the app?
      isDragging: monitor.canDrop(),
      isOver: monitor.isOver() && monitor.canDrop(),
    }),
  }));

  return (
    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
    <Button
      {...rest}
      ref={ref}
      onClick={() => {
        const input = document.createElement('input');
        input.type = 'file';
        input.click();
        input.onchange = (e: any) => props.onChange(e.target!.files[0]);
      }}
      className={classNames({
        'ring ring-brand-yellow/50': isDragging && !isOver,
        'ring ring-brand-yellow': isOver,
      })}
    >
      {isDragging ? t('uploadByDrop') : props.children}
    </Button>
  );
};
