/*** @prettier */
import React, { useContext, useEffect, useState } from 'react';
import Dialog from 'blackbird/components/dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { type ButtonProps } from 'blackbird/components/button/Button';
import {
  CancelationContext,
  CancelationProvider,
} from 'blackbird/helpers/contexts/CancelationContext';
import { allTheActions } from 'javascripts/flux/allTheActions';
import { allTheStores } from 'javascripts/flux/allTheStores';
import { StoreContextProvider } from 'javascripts/flux/StoreContextProvider';

interface Props {
  pause_ends_at_pretty: string;
  plan: string;
}

const PauseFlyover: React.FC<Props> = ({ plan, pause_ends_at_pretty }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'modals.paymentPaused',
  });

  const { handleUnpause } = useContext(CancelationContext);

  const [proceed, setProceed] = useState(false);
  const [prefix, setPrefix] = useState('notice');
  const [buttonProps, setButtonProps] = useState<ButtonProps>({
    type: 'fancy',
  });

  useEffect(() => {
    if (proceed) {
      setPrefix('confirm');
    } else {
      setPrefix('notice');
    }
  }, [proceed]);

  return (
    <Dialog
      isOpen
      isBlur
      hideDescription
      hideClose
      hideCancel
      keepOpen
      title={t(`${prefix}.title`)}
      subtitle={t(`${prefix}.subtitle`, {
        pause_ends_at_pretty: pause_ends_at_pretty,
        plan: plan,
      })}
      confirmBtnProps={buttonProps}
      confirmText={t(`${prefix}.cta.label`)}
      onConfirmBtnClick={() => {
        if (proceed) {
          handleUnpause();

          setButtonProps({
            type: 'fancy',
            disabled: true,
            loading: true,
          });
        } else {
          setProceed(true);
        }
      }}
      alignButtons="left"
      actionChildren={
        <div className="ml-2 text-sm space-x-4 text-type-subdued">
          <a
            className="cursor-pointer hover:underline"
            onClick={() => {
              if (proceed) {
                setProceed(false);
              } else {
                window.location.href = '/billing';
              }
            }}
          >
            {t(`${prefix}.action.label`)}
          </a>
        </div>
      }
    />
  );
};

const PauseContainer: React.FC<Props> = (props) => {
  return (
    <StoreContextProvider stores={allTheStores} actions={allTheActions}>
      <CancelationProvider>
        <PauseFlyover {...props} />
      </CancelationProvider>
    </StoreContextProvider>
  );
};

(window as any).PaymentPaused = PauseContainer;
