/** @prettier */
import React, {
  useState,
  useCallback,
  Fragment,
  useEffect,
  useRef,
} from 'react';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import Button, { type ButtonProps } from '../button/Button';
import classNames from 'classnames';
import { ErrorBoundary } from 'javascripts/components/shared/ErrorBoundary';
import { CloseButton } from '../common/CloseButton';

export type DialogSize =
  | 'xl'
  | 'lg'
  | 'md'
  | 'normal'
  | 'sm'
  | 'slim'
  | 'confirm';

type CommonDialogProps = {
  title?: React.ReactNode;
  icon?: React.ReactNode;
  subtitleIndicator?: React.ReactNode;
  footer?: React.ReactNode;
  description?: string;
  hideDescription?: boolean;
  hideActionDivider?: boolean;
  isOpen?: boolean;
  confirmBtnRounded?: boolean;
  alignButtons?: 'right' | 'left';
  /**
   * Classes for dialog root component
   */
  className?: string;
  /**
   * Classes to be added to customize overlay
   */
  overlayClassName?: string;
  /**
   * Custom text for confirm button
   */
  confirmText?: string;
  /**
   * Custom text for cancel button
   */
  cancelText?: string;
  /** Executed when the user confirms the dialog. If this function returns a
   * `Promise` it will wait with closing until the promise has been fulfilled */
  onConfirm?: () => void | Promise<void>;
  hideCancel?: boolean;
  hideClose?: boolean;
  hideConfirm?: boolean;
  confirmBtnProps?: ButtonProps;
  confirmBtnSize?: ButtonProps['size'];

  cancelBtnProps?: ButtonProps;
  cancelBtnSize?: ButtonProps['size'];

  actionChildren?: React.ReactNode;
  hideActions?: boolean;
  /**
   * Classes for the close button
   */
  closeButtonClasses?: string;
  /**
   * Classes for the div that encloses the titleTextContainer
   */
  titleContainerClasses?: string;
  /**
   * Classes for the parent div that encloses title and subtitle
   */
  titleTextContainerClasses?: string;
  isDark?: boolean;
  isBlur?: boolean;
  subtitle?: React.ReactNode;
  /**
   * Use this prop for the dialogs that takes up more height
   * than the screen height. It will adjust the classes accordingly
   * for better scrolling behavior without clipping any sections
   */
  fullHeight?: boolean;
  size?: DialogSize;
  /**
   * To disable closing of a dialog
   */
  keepOpen?: boolean;
  /**
   * This is required when we need to perform an action
   * on close button click when keepOpen is true
   */
  onCloseBtnClick?: () => void;
  /**
   * Works in conjuction with keepOpen.
   * Using these will allow us to get callbacks on
   * button clicks while still keeping the dialog open
   */
  onConfirmBtnClick?: () => void;
  onCancelBtnClick?: () => void;
  onEscapeOrOutsideClick?: () => void;

  wrapperClasses?: string;
  containerClasses?: string;
  headerClasses?: string;
  /**
   * To control the spacing among the header , description and actions
   */
  customSpacing?: boolean;
  containerRef?: React.ClassAttributes<HTMLDivElement>['ref'];
  initialFocus?:
    | Parameters<typeof HeadlessDialog>[0]['initialFocus']
    | 'cancelButton'
    | 'confirmButton';
  /** Makes the bottom section sticky when the dialog overflows. Defaults to `true` */
  stickyOverflow?: boolean;
};
type ConfirmationDialogProps = {
  onCancel?: () => void;
  isInformational?: false;
} & CommonDialogProps;
type InformationalDialogProps = {
  isInformational: true;
} & CommonDialogProps;

type DialogProps = (ConfirmationDialogProps | InformationalDialogProps) & {
  isInformational?: boolean;
};

/**
 * Both dialogs have same layout but needed to create two different components
 * to play well with React's propTypes warning. React's propTypes doesn't work
 * well with discriminated unions (so discriminating)
 */
const InformationalDialog: React.FC<InformationalDialogProps> = ({
  children,
}) => {
  return <>{children}</>;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  children,
}) => {
  return <>{children}</>;
};

interface ModalDividerProps {
  className?: string;
}
export const ModalDivider: React.FC<ModalDividerProps> = ({ className }) => (
  <div className={classNames('h-[1px]', className)}>
    <div className="absolute left-0 right-0 border-t border-t-border" />
  </div>
);

export const InsideDialogContext = React.createContext(false);
const Dialog: React.FC<DialogProps> = (props) => {
  const {
    icon,
    title,
    footer,
    subtitle,
    subtitleIndicator,
    description,
    hideDescription,
    hideActionDivider,
    overlayClassName,
    className,
    confirmText,
    children,
    confirmBtnRounded,
    confirmBtnSize = 'md',
    cancelBtnSize = 'md',
    alignButtons = 'right',
    hideActions,
    actionChildren,
    hideClose,
    titleContainerClasses,
    closeButtonClasses,
    titleTextContainerClasses,
    isDark,
    isBlur,
    fullHeight,
    size = 'normal',
    stickyOverflow = true,
    containerClasses,
    wrapperClasses,
    headerClasses,
    customSpacing,
    onCloseBtnClick,
    onConfirmBtnClick,
    onCancelBtnClick,
    containerRef,
    initialFocus,
  } = props;
  const cancelRef: Parameters<typeof HeadlessDialog>[0]['initialFocus'] =
    useRef(null);
  const confirmRef: Parameters<typeof HeadlessDialog>[0]['initialFocus'] =
    useRef(null);
  const internalRef = useRef<HTMLDivElement | null>(null);
  let initialFocusRef: Parameters<typeof HeadlessDialog>[0]['initialFocus'] =
    useRef(null);

  const [isOpen, setIsOpen] = useState(props.isOpen ?? false);
  const [isWaiting, setIsWaiting] = useState(false);

  const handleCancel = () => {
    if (props.keepOpen) return;
    setIsOpen(false);
    if (!props.isInformational) {
      props.onCancel && props.onCancel();
    } else {
      props.onConfirm && props.onConfirm();
    }
  };
  const handleConfirm = () => {
    if (props.keepOpen) return;
    const onConfirmReturned = props.onConfirm?.();
    setIsWaiting(true);
    // This will "resolve" regardless of the actual value returned by
    // onConfirmReturned. If it's a promise, we want to wait with closing until
    // the promise is resolved.
    Promise.resolve(onConfirmReturned).then(() => {
      setIsWaiting(false);
      setIsOpen(false);
    });
  };
  const handleCloseButton = () => {
    onCloseBtnClick?.();
    handleCancel();
  };
  const handleCancelButton = () => {
    onCancelBtnClick?.();
    handleCancel();
  };
  const handleConfirmButton = () => {
    onConfirmBtnClick?.();
    handleConfirm();
  };
  const onEscapeOrOutsideClick = () => {
    props.onEscapeOrOutsideClick?.();
    handleCancel();
  };

  useEffect(() => {
    setIsOpen(Boolean(props.isOpen));
  }, [props.isOpen]);
  const WrapperComponent = useCallback(
    (wrapperProps) => {
      return props.isInformational ? (
        <InformationalDialog {...wrapperProps} isInformational />
      ) : (
        <ConfirmationDialog {...wrapperProps} />
      );
    },
    [props.isInformational],
  );
  const dialogRef = containerRef ?? internalRef;

  if (initialFocus === 'cancelButton') {
    initialFocusRef = cancelRef;
  } else if (initialFocus === 'confirmButton') {
    initialFocusRef = confirmRef;
  } else {
    // Give the dialog's container div the initial focus in case no initialFocus prop is passed
    // This is to avoid the default behavior , which will pass focus to the first focusable element
    // Which will always be CloseButton in our implementation
    initialFocusRef =
      initialFocus ??
      (dialogRef as React.MutableRefObject<HTMLDivElement | null>);
  }

  return (
    <InsideDialogContext.Provider value={true}>
      <WrapperComponent>
        <Transition appear show={isOpen} as={Fragment}>
          {/* Need a higher z-index than flyoverRouter otherwise modal closes on clicking on screen */}
          <HeadlessDialog
            tabIndex={0}
            className={classNames(
              'fixed inset-0  flex flex-col z-modal justify-center',
              { 'justify-center': !fullHeight },
              { 'm-8': fullHeight },
              className,
            )}
            onClose={onEscapeOrOutsideClick}
            initialFocus={initialFocusRef}
          >
            {/* <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-30"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-30"
        > */}
            <HeadlessDialog.Overlay
              className={classNames(
                'fixed inset-0',
                overlayClassName,
                { 'bg-black/40': !overlayClassName && isDark },
                {
                  'backdrop-blur-[2px] bg-black/60':
                    !overlayClassName && isBlur,
                },
              )}
            />
            {/* </Transition.Child> */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* Adding this extra div because passing ref's to first
              child of Transition.Child doesn't work for some reason */}
              <div className="flex flex-col justify-center h-full">
                <div
                  ref={dialogRef}
                  className={classNames(
                    'w-full z-10 bg-white overflow-y-auto shadow-custom  mx-auto rounded-xl relative',
                    size === 'lg'
                      ? 'max-w-5xl'
                      : ['xl'].includes(size)
                      ? 'max-w-6xl'
                      : ['md'].includes(size)
                      ? 'max-w-[46rem]'
                      : ['slim', 'confirm'].includes(size)
                      ? 'max-w-[24rem]'
                      : 'max-w-lg',
                    containerClasses,
                  )}
                >
                  <div
                    className={classNames(
                      { 'space-y-8': !customSpacing },
                      size === 'confirm' && 'pt-7',
                      ['slim', 'confirm'].includes(size)
                        ? 'p-8 pb-8'
                        : 'px-10 fullsize:pt-10 pt-6 pb-8',
                      wrapperClasses,
                    )}
                  >
                    <ErrorBoundary size="small">
                      {icon && (
                        <div className="flex justify-center w-full">{icon}</div>
                      )}
                      {title && (
                        <HeadlessDialog.Title className={headerClasses}>
                          <div
                            className={classNames(
                              {
                                'flex justify-between':
                                  !titleContainerClasses && size !== 'slim',
                              },
                              titleContainerClasses,
                            )}
                          >
                            <div
                              className={classNames(
                                'flex flex-col gap-2 w-full',
                                titleTextContainerClasses,
                                size === 'slim' && 'text-center',
                              )}
                            >
                              <span
                                className={classNames(
                                  size === 'slim' && icon && '-mt-2',
                                  size === 'sm'
                                    ? 'text-xl font-semibold'
                                    : ['slim', 'confirm'].includes(size)
                                    ? 'text-lg font-semibold mb-2'
                                    : 'text-3xl font-semibold',
                                )}
                              >
                                {title}
                              </span>
                              {subtitle && (
                                <span
                                  className={classNames(
                                    'flex items-center',
                                    subtitleIndicator &&
                                      size !== 'xl' &&
                                      !hideClose &&
                                      'min-w-[110%] relative z-10',
                                    subtitleIndicator &&
                                      size === 'xl' &&
                                      !hideClose &&
                                      'min-w-[103.5%] relative z-10',
                                  )}
                                >
                                  <span className="flex-auto text-type-subdued">
                                    {subtitle}
                                  </span>
                                  {subtitleIndicator && (
                                    <span className="flex-shrink-0 ml-3">
                                      {subtitleIndicator}
                                    </span>
                                  )}
                                </span>
                              )}
                            </div>
                            {!hideClose && size !== 'confirm' && (
                              <div
                                className={classNames(
                                  size === 'slim' && 'absolute top-4 right-4',
                                  closeButtonClasses,
                                )}
                              >
                                <CloseButton
                                  className={classNames(
                                    size === 'slim' && 'opacity-50',
                                  )}
                                  onHandleCancel={handleCloseButton}
                                />
                              </div>
                            )}
                          </div>
                        </HeadlessDialog.Title>
                      )}
                      {!hideDescription && (
                        <HeadlessDialog.Description as="div">
                          <div className={'flex justify-between'}>
                            <div className="flex flex-col flex-grow w-full gap-6">
                              {description && (
                                <span
                                  className={classNames(
                                    'text-[18px] max-w-prose',
                                    {
                                      'text-type-subdued': size !== 'sm',
                                    },
                                  )}
                                >
                                  {description}
                                </span>
                              )}
                              {children}
                            </div>
                            {!title && !hideClose && (
                              <CloseButton onHandleCancel={handleCancel} />
                            )}
                          </div>
                        </HeadlessDialog.Description>
                      )}
                      {/*
              To Prevent border collapse
          */}

                      {!hideActions && (
                        <div
                          className={classNames(
                            'space-y-8',
                            stickyOverflow && 'sticky bottom-0  bg-white',
                            ['slim', 'confirm'].includes(size)
                              ? undefined
                              : 'pb-8',
                          )}
                        >
                          {size !== 'confirm' && !hideActionDivider && (
                            <ModalDivider />
                          )}
                          <div
                            className={`flex items-center ${
                              alignButtons === 'right'
                                ? 'justify-end'
                                : 'justify-start'
                            }`}
                          >
                            {actionChildren && (
                              <div
                                className={`flex justify-start flex-auto ${
                                  alignButtons === 'left'
                                    ? 'order-last ml-4'
                                    : 'order-first'
                                }`}
                              >
                                {actionChildren}
                              </div>
                            )}
                            <div className="flex items-stretch">
                              {!props.isInformational && !props.hideCancel && (
                                <Button
                                  ref={(ref) => (cancelRef.current = ref)}
                                  type="outline"
                                  className={classNames(
                                    alignButtons === 'left' ? 'mr-2' : 'ml-2',
                                  )}
                                  size={cancelBtnSize}
                                  onClick={handleCancelButton}
                                  {...props.cancelBtnProps}
                                >
                                  {props.cancelText ?? 'Cancel'}
                                </Button>
                              )}
                              {!props.hideConfirm && (
                                <Button
                                  className={classNames(
                                    alignButtons === 'left'
                                      ? 'order-first mr-2'
                                      : 'ml-2',
                                  )}
                                  rounded={confirmBtnRounded}
                                  ref={(ref) => (confirmRef.current = ref)}
                                  size={confirmBtnSize}
                                  onClick={handleConfirmButton}
                                  loading={isWaiting}
                                  {...props.confirmBtnProps}
                                >
                                  {confirmText ?? 'Confirm'}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </ErrorBoundary>
                    {footer && <div>{footer}</div>}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </HeadlessDialog>
        </Transition>
      </WrapperComponent>
    </InsideDialogContext.Provider>
  );
};
export default Dialog;
export { DialogProps, ConfirmationDialogProps, InformationalDialogProps };
