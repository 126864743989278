/** @prettier */
import { useStore } from 'javascripts/helpers/useStore';
import type { UserStore } from 'javascripts/flux/stores/user';
import { formatCurrency } from 'javascripts/helpers/currencyHelper';
import type { UserResponse } from 'javascripts/types/user';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { findWhere } from 'underscore';
import Dialog, { ModalDivider } from 'blackbird/components/dialog/Dialog';
import { TextArea } from 'blackbird/components/form/text-input/TextInput';
import ToggleButtonWithLabel from '../shared/ToggleButtonWithLabel';
import Button from 'blackbird/components/button/Button';
import BoordsLogo from 'blackbird/images/logo/logomark.svg';
import {
  CancelationContext,
  CancelationProvider,
  type Reason,
  type CancelationButtonProps,
} from 'blackbird/helpers/contexts/CancelationContext';
import { CheckIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { Feature } from 'blackbird/components/pages/onboarding/SignUpWithPlanPreview';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import classNames from 'classnames';

const CancelationComponent: React.FC<UserResponse> = ({
  plan,
  pause_ends_at_pretty,
}): any => {
  const [isOpen, setIsOpen] = useState(true);
  const {
    selectedReason,
    setSelectedReason,
    creditCount,
    stage,
    reasons,
    setDetail,
    pauseRestartMonth,
    stageIndex,
    setStageIndex,
    cancelling,
    pausing,
    deleteTime,
    setDeleteTime,
    deleteLinks,
    setDeleteLinks,
    deleteFeature,
    setDeleteFeature,
    deleteReactivate,
    setDeleteReactivate,
    deletePrice,
    setDeletePrice,
    handlePause,
    handleCancel,
    handleTrackRescue,
    reasonClick,
    resetForm,
    planAmount,
    handleClaim,
    claiming,
  } = useContext(CancelationContext);

  const handleCloseButton = useCallback(() => {
    setIsOpen(false);
    FlyoverActions.close();
    Track.event.defer('cancelation_close', {
      category: 'Subscription',
      posthogCapture: true,
    });
    if (!pausing && !cancelling) {
      handleTrackRescue();
    }
  }, [pausing, cancelling]);

  useEffect(() => {
    if (pause_ends_at_pretty) {
      setStageIndex((s) => s + 1);
    }

    Track.event.defer('cancelation_open', {
      category: 'Subscription',
      posthogCapture: true,
    });
  }, [pause_ends_at_pretty]);

  const letters = [`A`, `B`, `C`, `D`, `E`, `F`, `G`];

  const ReasonOptions: React.FC = () => (
    <>
      <div className="grid-cols-1 grid gap-4">
        {reasons.map((r: Reason, idx: number) => {
          return (
            <label
              key={r.value}
              className={`inline-flex items-center justify-center min-w-min focus:outline-none focus-visible:outline-blue user-select-none px-4 pt-3 pb-3.5 rounded-md font-semibold hover:bg-action-secondary-hover hover:text-action-hover cursor-pointer bg-surface `}
            >
              <input
                type="radio"
                className="hidden w-0 h-0"
                name="reason"
                style={{ appearance: 'none' }}
                onChange={(e) => {
                  setSelectedReason(
                    findWhere(reasons, {
                      value: e.currentTarget.value,
                    }),
                  );
                }}
                value={r.value}
                checked={
                  selectedReason ? selectedReason.value === r.value : false
                }
              />
              <span className="flex items-center w-full">
                <span className="flex items-center justify-center flex-shrink-0 w-8 h-8 mr-3 text-sm font-semibold bg-decorative-blue rounded-md">
                  <span>{letters[idx]}</span>
                </span>

                <span className="flex-auto text-left">{r.label}</span>
              </span>
            </label>
          );
        })}
      </div>
    </>
  );

  const CancelationButtons: React.FC<CancelationButtonProps> = ({
    children,
    hideBack = false,
  }) => {
    return (
      <div className="mt-8">
        <ModalDivider />
        <div className="flex items-center justify-end pt-8">
          <div className="flex-auto">
            {!hideBack && (
              <Button
                type="secondary"
                size="md"
                disabled={cancelling}
                onClick={() =>
                  stageIndex === 0 ? handleCloseButton() : resetForm()
                }
              >
                {`Go Back`}
              </Button>
            )}
          </div>
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className="relative w-full">
      <Dialog
        icon={<BoordsLogo className={`w-12`} />}
        size="slim"
        title={stage.title}
        subtitle={stage.subtitle}
        isOpen={isOpen}
        isBlur
        containerClasses={classNames(stage.id === 'credit_offer' && 'pt-14')}
        isDark
        hideClose={stage.id === 'credit_offer'}
        fullHeight
        hideActions
        keepOpen
        onCloseBtnClick={handleCloseButton}
        onEscapeOrOutsideClick={handleCloseButton}
      >
        <>
          <ModalDivider />
          {(stage.id !== 'reason' || !selectedReason) && (
            <>
              {stage.paragraphs.map((p, index) => (
                <div key={`${stageIndex}-copy-${index}`}>
                  {p.inputId && (
                    <>
                      {p.inputId === 'deleteFeature' && (
                        <ToggleButtonWithLabel
                          name={p.inputId}
                          value={deleteFeature}
                          onChange={setDeleteFeature}
                          label={
                            <Tooltip
                              title={`All your storyboards will become inaccessible. You can reactivate them if you rejoin in the next 12 months.`}
                              placement="top-start"
                            >
                              <span
                                className={`underline text-type-subdued decoration-dotted cursor-help underline-offset-2 decoration-border-mid text-sm pr-2 ${
                                  deleteFeature
                                    ? 'text-action-destructive'
                                    : 'text-type-subdued'
                                }`}
                              >
                                <span>{p.text}</span>
                              </span>
                            </Tooltip>
                          }
                        />
                      )}
                      {p.inputId === 'deleteTime' && (
                        <ToggleButtonWithLabel
                          name={p.inputId}
                          value={deleteTime}
                          onChange={setDeleteTime}
                          label={
                            <Tooltip
                              title={`Lose the workflow benefits of a purpose-built storyboarding tool for modern video teams.`}
                              placement="top-start"
                            >
                              <span
                                className={`underline text-type-subdued decoration-dotted cursor-help underline-offset-2 decoration-border-mid text-sm pr-2 ${
                                  deleteTime
                                    ? 'text-action-destructive'
                                    : 'text-type-subdued'
                                }`}
                              >
                                {p.text}
                              </span>
                            </Tooltip>
                          }
                        />
                      )}
                      {p.inputId === 'deleteLinks' && (
                        <ToggleButtonWithLabel
                          name={p.inputId}
                          value={deleteLinks}
                          onChange={setDeleteLinks}
                          label={
                            <Tooltip
                              title={`Any links shared with external collaborators or your team will stop working.`}
                              placement="top-start"
                            >
                              <span
                                className={`underline text-type-subdued decoration-dotted cursor-help underline-offset-2 decoration-border-mid text-sm pr-2 ${
                                  deleteLinks
                                    ? 'text-action-destructive'
                                    : 'text-type-subdued'
                                }`}
                              >
                                {p.text}
                              </span>
                            </Tooltip>
                          }
                        />
                      )}
                      {p.inputId === 'deleteReactivate' && (
                        <ToggleButtonWithLabel
                          name={p.inputId}
                          value={deleteReactivate}
                          onChange={setDeleteReactivate}
                          label={
                            <span
                              className={`underline text-type-subdued decoration-dotted cursor-help underline-offset-2 decoration-border-mid text-sm pr-2 ${
                                deleteReactivate
                                  ? 'text-action-destructive'
                                  : 'text-type-subdued'
                              }`}
                            >
                              {p.text}
                            </span>
                          }
                        />
                      )}
                      {p.inputId === 'deletePrice' && (
                        <ToggleButtonWithLabel
                          name={p.inputId}
                          value={deletePrice}
                          onChange={setDeletePrice}
                          label={
                            <Tooltip
                              title={`You may not be able to rejoin at your current price in future.`}
                              placement="top-start"
                            >
                              <span
                                className={`underline text-type-subdued decoration-dotted cursor-help underline-offset-2 decoration-border-mid text-sm pr-2 ${
                                  deletePrice
                                    ? 'text-action-destructive'
                                    : 'text-type-subdued'
                                }`}
                              >
                                {p.text}
                              </span>
                            </Tooltip>
                          }
                        />
                      )}
                    </>
                  )}
                  {!p.inputId && p.text && <p>{p.text}</p>}
                  {p.list_items && (
                    <ul className="-mt-2 space-y-2">
                      {p.list_items.map((item, idx) => (
                        <li
                          key={`${stageIndex}-copy-${index}-${idx}`}
                          className="flex items-center"
                        >
                          <CheckIcon className="w-6 h-6 text-type-green" />
                          <div className="ml-2">{item}</div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </>
          )}

          {stage.id === 'pause_offer' && (
            <div>
              <Button
                size="md"
                className="w-full"
                type="fancy"
                loading={pausing}
                onClick={handlePause}
              >
                {`Pause until ${pauseRestartMonth}`}
              </Button>
              <CancelationButtons>
                <Button
                  size="md"
                  type="outline"
                  onClick={() => setStageIndex((s) => s + 1)}
                >
                  {stage.buttonCta}
                </Button>
              </CancelationButtons>
            </div>
          )}

          {stage.id === 'reason' && (
            <div className="space-y-6">
              {!selectedReason ? (
                <ReasonOptions />
              ) : (
                <div className="">
                  {selectedReason.value !== 'something-else' && (
                    <div className="mb-8">
                      <div className="mb-8">
                        {selectedReason.value === 'not-using' ? (
                          <div className="mb-8 space-y-6">
                            <p>{selectedReason.detail}</p>
                            <Button
                              size="md"
                              onClick={() => reasonClick(selectedReason.action)}
                            >
                              {selectedReason.cta}
                            </Button>
                          </div>
                        ) : (
                          <div className="mb-8 space-y-6">
                            <div className="font-semibold">
                              {selectedReason.prompt}
                            </div>
                            <div className="">{selectedReason.detail}</div>
                            <div className="">
                              <Button
                                type="fancy"
                                size="md"
                                onClick={() =>
                                  reasonClick(selectedReason.action)
                                }
                              >
                                {selectedReason.cta}
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                      <ModalDivider />
                    </div>
                  )}

                  <div className="mb-4">
                    <span className="font-semibold">{`Before you continue,`}</span>{' '}
                    <span>{selectedReason && selectedReason.cancelDetail}</span>
                  </div>
                  <TextArea
                    id="details"
                    onChange={setDetail}
                    plainText
                    immediate
                  />

                  <CancelationButtons>
                    <Button
                      size="md"
                      type="outline"
                      onClick={() => setStageIndex((s) => s + 1)}
                    >
                      {stage.buttonCta}
                    </Button>
                  </CancelationButtons>
                </div>
              )}
            </div>
          )}

          {stage.id === 'credit_offer' && planAmount && (
            <div>
              <div className="absolute top-0 left-0 right-0 py-2 text-sm font-semibold text-center bg-brand-blue-25 text-brand-blue">{`Special Offer`}</div>
              <div
                className={
                  'bg-decorative-blue/10 border-brand-blue border rounded-md px-4 py-3 mb-4 mt-2'
                }
              >
                <div className="flex items-start">
                  <div className="flex-auto">
                    <div className="">
                      <div className="flex items-center mb-1 space-x-1">
                        <CheckCircleIcon className="w-5" />
                        <span>{`${BoordsConfig.Plan} Plan`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center flex-shrink-0 text-base ">
                    <span className="font-semibold line-through text-black/30 decoration-brand-pink decoration-2">
                      {formatCurrency({
                        amount: planAmount,
                        currency: BoordsConfig.Currency,
                        showZeros: true,
                      })}
                    </span>
                    <span className="ml-1 ">{`FREE`}</span>
                  </div>
                </div>

                <Feature
                  tooltip={`Maintain access to all your storyboards and data.`}
                  label={
                    <span className="text-type-subdued">{`Keep full access for free`}</span>
                  }
                />
              </div>
              <div
                className={
                  'bg-decorative-blue/10 border-brand-blue border rounded-md px-4 py-3 mb-6 mt-2'
                }
              >
                <div className="flex items-start">
                  <div className="flex-auto">
                    <div className="">
                      <div className="flex items-center mb-1 space-x-1">
                        <PlusCircleIcon className="w-5" />
                        <span>{`AI Credit Pack`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center flex-shrink-0 text-base ">
                    <span className="font-semibold line-through text-black/30 decoration-brand-pink decoration-2">
                      {formatCurrency({
                        amount: 49,
                        currency: BoordsConfig.Currency,
                        showZeros: true,
                      })}
                    </span>
                    <span className="ml-1 ">{`FREE`}</span>
                  </div>
                </div>

                <Feature
                  tooltip={`Get an extra ${creditCount} AI image generator credits to use anytime.`}
                  label={
                    <span className="text-type-subdued">{`+${creditCount} AI Image Credits`}</span>
                  }
                />
              </div>

              <div className="mb-3 text-sm text-center">{`We're working hard to give you more consistent AI image generation (including characters!) and we're continually releasing improvements.`}</div>

              <div className="mb-6 text-sm text-center">{`If you cancel, you won't get a chance to see these improvements as they're released.`}</div>

              <Button
                size="md"
                className="w-full"
                type="fancy"
                disabled={claiming}
                onClick={handleClaim}
              >
                {`Claim Free Access + Credits`}
              </Button>
              <CancelationButtons>
                <Button
                  type="outline"
                  size="md"
                  onClick={() => setStageIndex((s) => s + 1)}
                >
                  {stage.buttonCta}
                </Button>
              </CancelationButtons>
            </div>
          )}

          {stage.id === 'confirm_cancelation' && (
            <CancelationButtons>
              <Button
                type="destructive"
                size="md"
                loading={cancelling}
                disabled={
                  !deletePrice || !deleteFeature || !deleteTime || !deleteLinks
                }
                onClick={handleCancel}
              >
                {stage.buttonCta}
              </Button>
            </CancelationButtons>
          )}

          {stage.id === 'pause_complete' && (
            <CancelationButtons hideBack>
              <Button
                type="solid"
                size="md"
                className="w-full"
                onClick={() => (window.location.href = '/billing')}
              >
                {stage.buttonCta}
              </Button>
            </CancelationButtons>
          )}
        </>
      </Dialog>
    </div>
  );
};

export const Cancelation: React.FC = () => {
  const user = useStore<UserResponse | undefined, UserStore>(
    'user',
    (state) => state.user,
  );

  return user ? (
    <CancelationProvider>
      <CancelationComponent {...user} />
    </CancelationProvider>
  ) : null;
};
