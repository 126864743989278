/** @prettier */
import classNames from 'classnames';
import React from 'react';
import { type GeneratorGuideline, GeneratorContext } from '../GeneratorContext';
import { getMissingFrameImageURL } from 'javascripts/helpers/getMissingFrameImageURL';
import Img from 'javascripts/components/shared/Img';
import { GeneratorCharatcerContext } from './GeneratorCharacterContext';
import { generatorCharacterLocalState } from 'javascripts/helpers/local-state';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';

interface GuidelineOptionCharacterProps {
  character: GeneratorGuideline;
  small?: boolean;
}

export const GuidelineOptionCharacter: React.FC<
  GuidelineOptionCharacterProps
> = ({ character, small = false }) => {
  const { isPanelbar, selectedCharacter, setSelectedCharacter } =
    React.useContext(GeneratorContext);

  const { setIsCharacterEditorOpen } = React.useContext(
    GeneratorCharatcerContext,
  );

  const handleEditCharacter = (e: React.MouseEvent<HTMLElement>) => {
    setSelectedCharacter(character);
    generatorCharacterLocalState.setValue(undefined);
    e.preventDefault();
    e.stopPropagation();
    setIsCharacterEditorOpen(true);
  };

  interface GuidelineActionsProps {
    character: GeneratorGuideline;
  }
  const GuidelineActions: React.FC<GuidelineActionsProps> = ({ character }) => {
    return (
      <div className="absolute hidden top-2 right-2 z-60 group-hover:block">
        <Tooltip
          title={
            character.is_global ? `Copy & Edit Guideline` : `Edit Guideline`
          }
          placement="left"
        >
          <div
            className="inline-flex justify-center p-1.5 rounded-full cursor-pointer bg-surface-light/50 hover:bg-surface-light"
            onClick={handleEditCharacter}
          >
            <AdjustmentsHorizontalIcon className="w-3" />
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className="flex items-center flex-auto cursor-pointer">
      {small && (
        <>
          {character.preview_thumbnail_url ? (
            <div className="relative flex-shrink-0 w-6 h-6 mr-2 overflow-hidden rounded-sm ">
              <span className="absolute -top-[0.5rem] w-20 -left-[1.75rem]">
                <Img
                  className="mb-0 leading-none "
                  small
                  src={character.preview_thumbnail_url}
                />
              </span>
            </div>
          ) : (
            <div
              className={classNames(
                'flex-shrink-0 bg-surface w-6 h-6 rounded-sm mr-2',
              )}
            />
          )}
        </>
      )}

      <div className="flex-auto ">
        {small ? (
          <div className={classNames('flex items-center group')}>
            <div
              className={classNames(
                'flex-auto text-base text-left whitespace-nowrap truncate ',
                isPanelbar ? 'max-w-[11.5rem]' : 'max-w-[19.5rem]',
              )}
            >{`${character.name}`}</div>
            <div
              className={classNames(
                'flex items-center justify-end flex-auto text-xs opacity-0 text-type-disabled group-hover:opacity-100',
                isPanelbar ? 'text-xs' : 'text-sm',
              )}
            >
              <span
                className="cursor-pointer hover:text-type-subdued"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedCharacter(undefined);
                  generatorCharacterLocalState.setValue(undefined);
                }}
              >{`Remove`}</span>
            </div>
          </div>
        ) : (
          <div className="group">
            <div
              className={classNames(
                'flex-shrink-0 bg-surface w-full rounded-md hover:border-brand-pink border-2 relative overflow-hidden',
                selectedCharacter?.id === character.id
                  ? 'border-brand-pink'
                  : !character.is_active
                  ? 'border-brand-yellow'
                  : 'border-white',
              )}
            >
              <Img
                className="mb-0 leading-none"
                small
                src={
                  character.preview_large_url
                    ? character.preview_large_url
                    : getMissingFrameImageURL(`portrait`)
                }
              />
              <GuidelineActions character={character} />
            </div>
            <div className="mt-1 text-sm text-type-disabled group-hover:text-type-primary">
              {character.name}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
