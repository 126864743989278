/** @format */

import GraphemeSplitter from 'grapheme-splitter';
const splitter = new GraphemeSplitter();

/**
 * Some symbols (like emoji) are represented by several characters. This can
 * cause issues when splitting strings and inadvertently cutting a multi-char
 * letter in half, or when you need the actual number of letters in a string.
 * This module uses [grapheme-splitter](https://github.com/orling/grapheme-splitter) under the hood */
export const getFirstCharacter = (string: string) =>
  splitter.splitGraphemes(string)[0];
