/** @prettier */
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import * as React from 'react';
import Toggle from 'blackbird/components/toggle/Toggle';

interface IProps {
  project: any;
  canAccess: boolean;
  onChange: (id, newAccess) => void;
  isBusy?: boolean;
}

export class ProjectAccessItem extends React.Component<IProps> {
  handleChange = (newValue) => {
    this.props.onChange(this.props.project.id, newValue);
  };

  render() {
    const toggleLabel = this.props.canAccess
      ? 'Disable project access'
      : 'Allow project access';

    return (
      <div
        className="flex items-center justify-between"
        key={this.props.project.id}
      >
        {this.props.project.name}
        <Tooltip title={toggleLabel}>
          <div>
            <Toggle
              value={this.props.canAccess || false}
              onChange={this.handleChange}
              disabled={this.props.isBusy}
            />
          </div>
        </Tooltip>
      </div>
    );
  }
}
