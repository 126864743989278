/**@prettier */
import React, { Fragment, useContext } from 'react';
import { Tab as HeadlessTab } from '@headlessui/react';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import { TabsContext } from './Tabs';
import Tooltip from '../feedback/tooltip/Tooltip';
import type { TabProps } from './types';

const TabList: React.FC<{ className?: string }> = (props) => {
  const tabsContext = useContext(TabsContext);
  if (!tabsContext) {
    return <></>;
  }
  const { className } = props;
  const {
    tabs,
    size,
    disabled: tabsDisabled,
    customBorder,
    customFont,
    customPadding,
    customSpacing,
    type,
    tabClassName = 'py-3',
    tabListClassName,
    iconClassName,
  } = tabsContext;

  const renderTabs = (tab: TabProps, hasPointer: boolean): React.ReactNode => {
    const disabled = tabsDisabled || tab.disabled;
    const isStringCaption = typeof tab.caption === 'string';
    const tabContent = (
      //need this div to hold ref. Tooltip doesn't work without it.
      <div className={classNames(type === 'rounded' && ' flex items-stretch ')}>
        <HeadlessTab as={Fragment}>
          {({ selected }) => (
            <button
              disabled={disabled}
              type="button"
              className={classNames(
                tabClassName,
                {
                  'border-b-2 hover:border-b-border-mid': type !== 'rounded',
                },
                {
                  'text-lg':
                    isStringCaption && !customFont && type !== 'rounded',
                },
                {
                  'pb-6 ': type === 'icon',
                },
                {
                  'text-action-primary-disabled cursor-not-allowed hover:border-b-transparent':
                    disabled,
                },
                {
                  'text-type-primary border-b-border-dark':
                    selected && !disabled && type !== 'rounded',
                },
                {
                  'text-type-disabled hover:border-b-border-mid border-b-transparent':
                    !selected && !disabled && type !== 'rounded',
                },
                hasPointer ? 'cursor-pointer' : 'cursor-default',
                {
                  'rounded-none hover:!text-brand-pink text-center font-semibold w-full ':
                    type === 'rounded',
                },
                size === 'lg' &&
                  type === 'rounded' &&
                  'text-sm pt-1.5 pb-[0.45rem] ',
                size === 'default' &&
                  type === 'rounded' &&
                  'text-xs pt-1.5 pb-[0.45rem] ',
                {
                  'text-type-disabled bg-white hover:bg-surface-light':
                    !selected && !disabled && type === 'rounded',
                },
                {
                  'bg-surface-light hover:bg-surface':
                    selected && !disabled && type === 'rounded',
                },
              )}
            >
              {isStringCaption ? (
                tab.caption
              ) : type === 'default' && typeof tab.caption === 'function' ? (
                tab.caption({ selected: tabsContext.selectedTab! })
              ) : (
                <Icon
                  className={iconClassName ?? 'h-10'}
                  icon={tab.caption as any}
                  color={selected ? 'black' : 'subdued'}
                  fill={tab.iconFill}
                  stroke={tab.iconStroke}
                />
              )}
            </button>
          )}
        </HeadlessTab>
      </div>
    );
    return tab.title ? (
      <Tooltip key={tab.key} as="div" title={tab.title}>
        {tabContent}
      </Tooltip>
    ) : (
      <div key={tab.key}>{tabContent}</div>
    );
  };

  return (
    <HeadlessTab.List
      className={classNames(
        className || tabsContext.className,
        tabListClassName,
        type === 'rounded' && tabs.length === 2 && 'grid-cols-2',
        type === 'rounded' && tabs.length === 3 && 'grid-cols-3',
        type === 'rounded' &&
          tabs.length === 4 &&
          'grid-cols-2 xl:grid-cols-4 divide-y xl:divide-y-0',
        type === 'rounded' && tabs.length === 5 && 'grid-cols-5',
        type === 'rounded' && tabs.length === 6 && 'grid-cols-6',
        type === 'rounded'
          ? `grid overflow-hidden border rounded-md border-border divide-x divide-border`
          : `flex`,
        {
          'border-b-border border-b': !customBorder && type !== 'rounded',
          'px-6': !customPadding && type !== 'rounded',
          'gap-9': !customSpacing && type !== 'rounded',
        },
      )}
    >
      {tabs.map((tab, idx) => {
        return renderTabs(tab, tabs.length > 1);
      })}
    </HeadlessTab.List>
  );
};
export default TabList;
