/** @prettier */
import { PDFLayoutFunc } from '../types';
import * as eachOfSeries from 'async/eachOfSeries';
import { createDocument } from '../helpers/createDocument';
import { applyPDFWatermark } from '../helpers/applyPDFWatermark';
import { createPDFGridItem } from '../helpers/createPDFGridItem';
import { loadFrameImages } from '../helpers/loadFrameImages';
import { createPDFFooter } from '../helpers/createPDFFooter';
import { finishPDF } from '../helpers/finishPDF';
import logger from 'javascripts/helpers/logger';

export const PDFOneUpLayout: PDFLayoutFunc<{
  framesPerPage: number;
  columns?: number;
}> = async (props, frames) => {
  const docInfo = await createDocument(props, 'landscape');
  const { doc, pageFormat } = docInfo;

  let tooMuchText = false;

  let pagesToUse = frames;
  if (props.isQuickMode) {
    pagesToUse = [frames[props.pageNumber || 0]];
  } else if (props.isTrialPreview) {
    // We want to limit to only one page worth of frames
    pagesToUse = [frames[0]];
  }

  // logger.log(`Rendering ${pagesToUse.length} grid pages`);

  return new Promise((resolve, reject) => {
    const useLowRes = props.isQuickMode || props.isTrialPreview;

    // Split up all the frames per page
    eachOfSeries(
      pagesToUse,
      (frame, pi, done) => {
        loadFrameImages([frame], props.style.frameAspectRatio, useLowRes)
          .then(imageUrls => {
            const { style } = props;
            const { width, height } = doc.internal.pageSize;

            doc.addPage(pageFormat.format, pageFormat.orientation);

            if (style.backgroundColor) {
              doc.setFillColor(style.backgroundColor);
              doc.rect(0, 0, width, height, 'F');
            }

            const frameItem = createPDFGridItem(docInfo, props.style, {
              x: width / 2 - style.frameWidth / 2,
              y: style.margin.top,
              width: style.frameWidth,
              frame: frame,
              frameImageInfo: imageUrls[frame.id],
              debug: props.isDebugMode,
            });

            if (style.margin.top + frameItem.height > height) {
              tooMuchText = true;
            }

            createPDFFooter(docInfo, {
              bottom: height - style.margin.bottom,
              text: props.footerText,
              debug: props.isDebugMode,
              pageNumber: props.isQuickMode ? props.pageNumber : undefined,
              style,
            });

            if (props.isTrialPreview) {
              applyPDFWatermark(docInfo).then(
                () => done(),
                err => done(err),
              );
            } else {
              done();
            }
          })
          .catch(err => done(err));
      },
      async err => {
        if (err) return reject(err);

        return finishPDF({
          docInfo,
          props,
          callback: resolve,
          tooMuchText,
        });
      },
    );
  });
};
