/** @prettier */
import type { FrameField, StateFrameField } from 'javascripts/types/storyboard';
import { nanoid } from 'nanoid/non-secure';
import { every, isEmpty, isEqual, some } from 'underscore';
import { defaultFrameFields } from '../defaultFrameFields';
import logger from '../logger';

export const FRAME_FIELD_LIMIT = 20;

const newId = (): string => nanoid(6);

const getLabelName = (index: number) => `Field ${index + 1}`;

/** Adds a placeholder to each item in a frame fields array */
export const addPlaceholders = (fields: FrameField[]): StateFrameField[] =>
  fields.map((field, index) => ({
    ...field,
    placeholder: field.label ?? getLabelName(index),
  }));

/** returns a copy of the {@link FrameField} array with a empty field added */
export const addFrameField = <T extends FrameField>(
  fields: T[],
  field: Partial<T> = {},
): T[] => {
  if (fields.length >= FRAME_FIELD_LIMIT)
    throw new Error('Frame field limit exceeded');

  const fieldData: FrameField = {
    id: newId(),
    label: getLabelName(fields.length - 1),
    icon: 'clipboard',
    isEnabled: true,
  };

  const newField = { ...fieldData, ...field } as T;

  return [...fields, newField];
};

/** In old uses of frame_fields, we do not have an `id` field for each field, so
 * we have to set them manually. Requires a `saveCallback` that should be fired
 * to save the new frame fields to the server  */
export function ensureFrameFieldsHaveIds(
  fields: FrameField[] | undefined,
  saveCallback: (newFields: FrameField[]) => void,
): FrameField[] {
  if (!fields || isEmpty(fields)) return defaultFrameFields;
  if (every(fields, (f) => !!f.id)) return fields;

  if (some(fields, (f) => !!f.id)) {
    // This part is not strictly necessary, but if this happens we have a leak
    // somewhere (where we should have run this function)
    throw new Error(
      'Inconsistent state, some fields have ids, others do not. Something is weird here.',
    );
  }

  const output = fields.map((field, i) => {
    const id = field.id ?? defaultFrameFields[i]?.id ?? newId();
    return { ...field, id };
  });

  if (!isEqual(output, fields)) {
    logger.log('Added ids to frame field array');
    saveCallback(output);
  }

  return output;
}

export const isFrameFieldEmpty = (htmlString: string) => {
  const strippedString = htmlString.replace(/(<([^>]+)>)/gi, '');
  return strippedString.trim().length === 0;
};
