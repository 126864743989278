import React from 'react'
import * as PropTypes from 'prop-types'

const buttonStyles = {
  green: 'bg-surface-success/50 text-type-green',
  blue: 'bg-surface-light text-decorative-blue',
  orange: 'bg-brand-yellow-25 text-type-orange',
  red: 'bg-surface-critical text-form-critical',
  grey: 'bg-surface-light '
}

const SubscriptionStatusTag = (props) => {

  let tagStyle;
  let tagText = props.status;

  if (typeof props.status === 'undefined' || props.status == null) {
    tagStyle = buttonStyles.red;
    tagText = 'Missing status'
  } else if (props.status === 'active') {
    tagStyle = buttonStyles.green;
  } else if (props.status === 'past_due' || props.status === 'incomplete') {
    tagStyle = buttonStyles.orange;
  } else if (props.status === 'unpaid' || props.status === 'incomplete_expired') {
    tagStyle = buttonStyles.red;
  } else if (props.status === 'trialing') {
    if (props.is_paused) {
      tagStyle = buttonStyles.orange;
      tagText = 'Paused'
    } else {
      tagStyle = buttonStyles.green;
      tagText = 'Trial'
    }
  } else if (props.is_trialing) {
    tagStyle = buttonStyles.green;
    tagText = 'Trial'
  } else if (props.is_expired_trial) {
    tagStyle = buttonStyles.grey;
    tagText = 'Expired'
  } else if (props.status === 'canceled' && props.in_date) {
    tagStyle = buttonStyles.blue;
    tagText = 'Canceling'
  } else if (props.status === 'canceled' && !props.in_date) {
    tagStyle = buttonStyles.grey;
  } else {
    tagStyle = buttonStyles.grey;
  }

  return (
    <div className={"whitespace-nowrap capitalize text-xs rounded-full px-2.5 h-6 leading-5 " + tagStyle}>
      {tagText.replace('_', ' ')}
    </div>
  )
};

SubscriptionStatusTag.defaultProps = {
  in_date: true
}

SubscriptionStatusTag.propTypes = {
  status: PropTypes.string,
  in_date: PropTypes.bool,
  is_expired_trial: PropTypes.bool,
  is_trialing: PropTypes.bool,
  is_paused: PropTypes.bool,
}

window.SubscriptionStatusTag = SubscriptionStatusTag
