const PlayerUI = require('./Player.jsx');
const Container = require('./Container2.jsx');

module.exports = Container(PlayerUI, [
  'adjustFrameInpoint', 'setFrameInpoint', 'setFrameOutpoint', 'setTimingsLock',
  'toggleLooping', 'togglePlay',
  'toggleTimeline', 'updateTime', 'close'
]);
// module.exports = React.createClass({
//   displayName: 'PlayerContainer',
//   mixins: [PureRenderMixin],

//   getInitialState: function() {
//     const actions = _.pick(PlayerActions, [
//       'navigateNext', 'navigatePrevious', 'goToFrame', 'updateTime', 'close',
//       'toggleSidebar', 'toggleSettings', 'togglePlay', 'setFrameInpoint',
//       'setFrameOutpoint', 'adjustFrameInpoint'
//     ]);

//     return Object.assign(this._collectState(), actions);
//   },

//   componentDidMount: function() {
//     PlayerStore.listen(this._update);
//     FrameStore.listen(this._update);
//   },

//   componentWillUnmount: function() {
//     PlayerStore.unlisten(this._update);
//     FrameStore.unlisten(this._update);
//   },

//   _collectState: function() {
//     const state = PlayerStore.getState();

//     const frame = _.find(
//       FrameStore.getState().frames,
//       f => f.id === state.currentFrameId
//     );

//     return Object.assign({}, state, { frame })
//   },

//   _update: function() {
//     this.setState(this._collectState());
//   },

//   render() {
//     return <PlayerUI {...this.state}/>;
//   }
// });
