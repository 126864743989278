window.WhosOnlineActions = alt.createActions(function () {

  this.init = function (data) {
    this.dispatch(data);
  };

  this.unsub = function (data) {
    this.dispatch(data);
  };

});

