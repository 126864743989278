/** @format */

import React, { useContext } from 'react';
import { PlusIcon } from 'lucide-react';
import Button from 'blackbird/components/button/Button';
import { ProjectsContext } from '../ProjectsContext';
import { ProjectsEmpty } from './ProjectsEmpty';

export const ProjectContentsEmpty: React.FC = () => {
  const { activeGroup, activeProject } = useContext(ProjectsContext);

  if (!activeProject) {
    return null;
  }

  const canCreateStoryboard =
    activeGroup?.actions.includes('storyboards.create');

  if (canCreateStoryboard) {
    return (
      <ProjectsEmpty
        title={`Add content to this project`}
        subtitle="Click the button below to add a new storyboard"
      >
        <Button
          type="outline"
          onClick={() => {
            FlyoverActions.open({
              type: 'newStoryboard',
              projectId: activeProject.id,
              teamId: activeGroup?.team_id,
              props: {
                actions: activeGroup?.actions,
                defaultTemplateId: activeProject.template_id,
              },
            });
          }}
        >
          <span className="flex items-center">
            <span className="mr-1.5 -ml-1.5 pt-0.5 leading-none">
              <PlusIcon className="w-5 h-5" strokeWidth={2.5} />
            </span>
            <span>{`New Storyboard`}</span>
          </span>
        </Button>
      </ProjectsEmpty>
    );
  } else {
    return (
      <ProjectsEmpty
        title={`No content in this project`}
        subtitle="You don't have permission to create new storyboards in this project"
      >
        <></>
      </ProjectsEmpty>
    );
  }
};
