/** @prettier */
import * as React from 'react';
import { ErrorBoundary } from '../../shared/ErrorBoundary';
import PDFExportContainer from '../../pdf/PDFExportContainer';
import { PDFRenderer } from '../../pdf/PDFRenderer';
import { PDFRendererOverrides } from '../../pdf/helpers/getPDFRendererProps';

const overrides: PDFRendererOverrides = {
  format: 'widescreen',
  covers: false,
  forceBackground: true,
};

export const PresentationExportFlyover: React.FC = () => {
  const renderFunction: typeof PDFRenderer = (renderProps) =>
    import('./renderPresentation').then(({ renderPresentationImages }) =>
      renderPresentationImages(renderProps),
    );

  const cleanup = () =>
    import('./renderPresentation').then(({ cleanupPresentationImages }) => {
      cleanupPresentationImages();
    });

  return (
    <ErrorBoundary>
      <PDFExportContainer
        onCleanup={cleanup}
        renderFunction={renderFunction}
        context="presentationImages"
        overrides={overrides}
      />
    </ErrorBoundary>
  );
};
