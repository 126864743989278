import type { ScreamingSnakeCase } from "type-fest";

/**
 * You can inherit from this when you create a new Alt store, so you don't
 * get errors for accessing properties like this.emitChange.
 *
 * I couldn't figure out how to correctly do this without too much boilerplate
 * so I copied this from
 * github.com/goatslacker/alt/blob/master/docs/typescript.md
 * consider this a WIP
 */
export class FakeAltStoreClass<S> {
  protected bindActions: (...actions: any[]) => void;
  protected bindAction: (...args: any[]) => void;
  protected bindListeners: (obj: any) => void;
  protected exportPublicMethods: (config: { [key: string]: (...args: any[]) => any }) => any;
  protected exportAsync: (source: any) => void;
  protected waitFor: any;
  protected exportConfig: any;
  protected emitChange: () => void;
  protected state: S;
  protected on: (event:string, callback: () => void) => void
  getState: () => S;
}

/** Creates alt actions, but ensures that types are passed on correctly! */
export function createTypedActions<T>(store): StoreWithSymbols<T> {
  return alt.createActions(store)
}

/**
 * Takes all the keys and types of T, and adds a .defer property to each of
 * them. Also generates action constants (e.g. `FooActions.OPEN_BAR`).
 */
type StoreWithSymbols<T> = {
  [P in keyof T]: T[P] & { defer: T[P] };
} & {
  [P in keyof T as ScreamingSnakeCase<P>]: unknown
};
