
/**
 * Would probably put these in the stores, but not all stores allow for using
 * named exports, so we centralise them here.
 */

import { createUndoStackManager } from "./undo-stack";
import type { FrameEditorHistoryItem } from "javascripts/components/frame_editor/types";
export const undoManager = createUndoStackManager('keydown');

export const frameUndoManager = undoManager.newUndoContext('storyboard');
export const frameEditorUndoManager = undoManager.newUndoContext<FrameEditorHistoryItem>('frameEditor');
export const scriptEditorUndoManager = undoManager.newUndoContext('scriptEditor');
