/** @format */

import * as React from 'react';
import Avatar from '../avatar/Avatar';
import { filterDataUrl } from '../projects/ProjectsGroupLabel';
import { getFirstCharacter } from 'javascripts/helpers/get-first-character';
import { TeamContext, type UnregisteredInvitation } from './TeamContext';
import { TeamRoleStatic } from './TeamRoleSelect';
import { XIcon } from 'lucide-react';
import Tooltip from '../feedback/tooltip/Tooltip';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';

interface ProjectInviteRowProps {
  invite: UnregisteredInvitation;
}

export const TeamProjectInviteRow: React.FC<ProjectInviteRowProps> = ({
  invite,
}) => {
  const { teamId, activeGroup, resendInvite, deleteInvite } =
    React.useContext(TeamContext);
  const dialogContext = React.useContext(DialogContext);

  const canViewTeam = activeGroup?.actions.includes('team.view') || teamId;

  return !canViewTeam ? null : (
    <div className="relative flex items-center group">
      <div className="flex items-center flex-auto space-x-2">
        <Avatar
          borderColor="border-white"
          className="mt-1"
          size="sm"
          initial={getFirstCharacter(invite.email).toUpperCase()}
          image={filterDataUrl(invite.avatar_url)}
        />
        <div>
          <p className="text-sm leading-tight">{invite.email}</p>
          <p className="text-xs leading-tight">
            <span className="text-type-subdued">{`Pending`}</span>
            <span className="text-type-disabled mx-0.5">{`–`}</span>
            <span
              className="cursor-pointer text-brand-pink hover:opacity-70"
              onClick={() => resendInvite(invite.invitation_id)}
            >{`Resend invite`}</span>
          </p>
        </div>
      </div>
      <div className="flex justify-end">
        <TeamRoleStatic text={TeamRoleHelper.call(invite.role)} />
      </div>

      <Tooltip placement="top" title={`Delete invite`}>
        <div
          className="absolute pl-6 opacity-0 cursor-pointer -right-6 top-3 group-hover:opacity-100"
          onClick={async () => {
            if (await openConfirmDialog('Delete invite?', dialogContext)) {
              deleteInvite(invite.invitation_id);
            }
          }}
        >
          <XIcon className="w-4 h-4" />
        </div>
      </Tooltip>

      {/* <TeamProjectInviteMenu inviteId={invite.invitation_id} /> */}
    </div>
  );
};
