/** @format */

import * as React from 'react';
import Dialog from 'blackbird/components/dialog/Dialog';
import { TeamContext } from './TeamContext';
import { ProjectsContext } from '../projects/ProjectsContext';
import { TeamProjectManageContent } from './TeamProjectManageContent';

export const TeamProjectManage: React.FC = () => {
  const { isTeamProjectModalOpen, setIsTeamProjectModalOpen, teamData } =
    React.useContext(TeamContext);
  const { activeProject } = React.useContext(ProjectsContext);

  const handleClose = () => {
    setIsTeamProjectModalOpen(false);
  };

  if (!teamData || !activeProject) return null;

  return !isTeamProjectModalOpen ? null : (
    <Dialog
      isDark
      size={'sm'}
      title={`Project members`}
      subtitle={`Manage who can edit ${activeProject.name}`}
      isOpen
      keepOpen
      hideActions
      onCloseBtnClick={handleClose}
      onEscapeOrOutsideClick={handleClose}
    >
      <TeamProjectManageContent />
    </Dialog>
  );
};
