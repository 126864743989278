const _ = require('underscore');
const { RequestErrorHandler } = require('../../helpers/request-error-handler');
const router = require('../router');
const qs = require('querystringify');
const errorHandler = RequestErrorHandler('routing');

/**
 * Triggers navigation to the passed route name, will ignore if the route is
 * the same, and log if the route is incorrect. Pass the `pause` param to
 * ignore any route hooks, this will also use replaceState().
 */
module.exports = function navigateToRoute(routeName, params, pause = false) {

  if (routeName === 'pricing') {
    window.location = '/pricing';
  } else if (routeName === 'billing/stripe') {
    window.location = '/billing/stripe';
  } else {
    if (window.location.pathname.indexOf('/styleguide') === 0) return;
    let generated = router.generate(routeName, params);

    if (routeName === 'root') generated = '/'

    if (params && !_.isEmpty(params.query)) {
      generated += '?' + qs.stringify(params.query);
    }

    const current = window.location.pathname + window.location.search;

    if (!generated) {
      const error = new Error(`Invalid route name: ${routeName}`)
      errorHandler({
        message: 'Sorry, but something went wrong loading that page',
        askUserToRetry: false
      })(error)
    } else if (generated !== current) {
      if (pause) router.pause();
      router.navigate(generated);
      if (pause) router.resume();
    }
  }
}
