/** @prettier */
import * as React from 'react';
import { ContextMenuProps } from '../types';
import { ToolbarSelect } from './ToolbarInput';
import { brushSizes, defaultBrushSize } from '../frameEditorDefaults';
import { ColorPicker } from '../../shared/ColorPicker.react';
import { ColorPickerWellButton } from 'javascripts/components/shared/ColorPickerWellButton';

export const DrawingContextMenu: React.FC<ContextMenuProps> = ({
  actions,
  disabled,
  selected,
}) => {
  return (
    <>
      <ToolbarSelect
        className="w85px shrink-0"
        value={selected.strokeWidth ?? defaultBrushSize}
        onChange={(e) => actions.handleSetStrokeWidth(selected, e)}
        disabled={disabled}
      >
        {brushSizes.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </ToolbarSelect>

      <ColorPicker
        color={selected.stroke}
        onChange={(e) => actions.handleSetStrokeColor(selected, e)}
        disabled={disabled}
        icon={<ColorPickerWellButton color={selected.stroke} />}
        showAlpha
      />
    </>
  );
};

DrawingContextMenu.displayName = 'DrawingContextMenu';
