/** @prettier */

const {
  StoryboardHeaderBar,
} = require('blackbird/components/headers/storyboard/StoryboardHeaderBar');
const {
  FrameProcessingBar,
} = require('blackbird/components/headers/storyboard/FrameProcessingBar');
const PropTypes = require('prop-types');

const { default: FrameUserActions } = require('../shared/FrameUserActions');
const { ToursActions, tourEvents } = require('javascripts/flux/actions/tours');
const { openLightbox } = require('javascripts/helpers/lightbox');
const { DialogContext } = require('blackbird/components/dialog/DialogContext');

class StoryboardHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = PanelbarStore.getState();
  }

  handleArchiveStoryboard = () => {
    if (BoordsConfig.Freeloader && BoordsConfig.IsStoryboardAdmin) {
      FlyoverActions.open.defer({
        type: 'archiveUpgradePrompt',
        props: {
          storyboard: this.props.storyboard,
        },
      });
    } else {
      StoryboardActions.archiveStoryboard(this.props.storyboard);
    }
  };

  handleDuplicate = () => {
    PermissionActions.verifyStoryboard.defer({
      project_id: this.props.storyboard.project.id,
      onSuccess: () => {
        StoryboardActions.duplicate.defer(this.props.storyboard);
      },
    });
  };

  handleTutorial = () => {
    openLightbox('https://vimeo.com/292926791');
  };
  handleFormatting = () => {
    window.location =
      'https://help.boords.com/en/articles/2477419-formatting-text-with-markdown';
  };

  componentDidMount = () => {
    PanelbarStore.listen(this._onPanelbarChange);
  };

  componentWillUnmount = () => {
    PanelbarStore.unlisten(this._onPanelbarChange);
  };

  _onPanelbarChange = (state) => {
    this.setState(state);
  };

  saveAction = (name) => {
    StoryboardActions.update({
      name: 'document_name',
      value: name,
    });
    StoryboardActions.save();
  };
  handleSidebarToggle = () => {
    PanelbarActions.toggle.defer();
  };
  handleChangeZoom = (zoomLevel) => {
    StoryboardZoomActions.updateZoomLevel(zoomLevel);
  };
  handleChangeView = (view) => {
    StoryboardActions.toggleViewMode(view);
  };

  handleShare = () => {
    const index = FrameStore.getState().frames.findIndex((f) => f.is_selected);
    ToursActions.triggerEvent.defer(tourEvents.openShare);
    Track.event.defer('Share open');
    FlyoverActions.open({
      type: 'storyboardShare',
      props: {
        selectedFrameIndex: index >= 0 ? index : undefined,
        storyboard: this.state.storyboard,
      },
    });
  };

  handleStatusChange = (status) => {
    FrameActions.setFramesStatus({
      status,
      frames: this.props.selectedFrames,
    });
  };

  static contextType = DialogContext;
  getActions() {
    return FrameUserActions({
      isGuest: BoordsConfig.TeamRole === 'guest',
      commentsEnabled: this.props.storyboard.has_comments_enabled,
      framesToOperateOn: this.props.selectedFrames,
      storyboard: this.props.storyboard,
      frameCount: this.props.frames?.length || 0,
      groupInfo: this.props.groupInfo,
      dialogContext: this.context,
    });
  }

  render() {
    const storyboard = this.props.storyboard;

    if (!this.props.frames) {
      return null;
    }

    const actions = {
      ...this.getActions(),
      status: {
        label: 'change status',
      },
    };

    return (
      <>
        <header className={'header relative z-3'}>
          <StoryboardHeaderBar
            onArchiveStoryboard={this.handleArchiveStoryboard}
            onDuplicateStoryboard={this.handleDuplicate}
            onFormatting={this.handleFormatting}
            onWatchTutorial={this.handleTutorial}
            onDownload={this.handleDownload}
            onShare={this.handleShare}
            onPresent={this.handlePresent}
            onStoryboardTitleChange={this.saveAction}
            storyboard={storyboard}
          />
        </header>
        {this.props.frames?.length > 0 && (
          <FrameProcessingBar
            actions={actions}
            onSidebarToggle={this.handleSidebarToggle}
            onChangeZoom={this.handleChangeZoom}
            onChangeView={this.handleChangeView}
            onStatusChange={this.handleStatusChange}
          >
            {this.props.children}
          </FrameProcessingBar>
        )}
      </>
    );
  }
}

StoryboardHeader.propTypes = {
  storyboard: PropTypes.object.isRequired,
  selectedFrames: PropTypes.array,
  frames: PropTypes.any,
  children: PropTypes.any,
};

window.StoryboardHeader = StoryboardHeader;
