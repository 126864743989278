/** @prettier */
import { FileUploadStore } from '../stores/file_upload';
import { createTypedActions } from '../stores/AltStore';

class FileUploadActions {
  openPicker: FileUploadStore['handleOpenPicker'] = function(options) {
    this.dispatch(options);
  };
  closePicker: FileUploadStore['handleClosePicker'] = function() {
    this.dispatch();
  };
  uploadFiles: FileUploadStore['handleUploadFiles'] = function(options) {
    this.dispatch(options);
  };
  uploadFile: FileUploadStore['handleUploadFile'] = function(options) {
    this.dispatch(options);
  };
  openPickerAndAddFrames: FileUploadStore['handleOpenPickerAndAddFrames'] = function(
    options,
  ) {
    this.dispatch(options);
  };
  openPickerAndUpdateFrame: FileUploadStore['handleOpenPickerAndUpdateFrame'] = function(
    options,
  ) {
    this.dispatch(options);
  };

  updateFrame: FileUploadStore['handleUpdateFrame'] = function(options) {
    this.dispatch(options);
  };

  /** Upload image, and returns the URLs for the different image versions */
  uploadFrameImage: FileUploadStore['handleUploadFrameImage'] = function(
    options,
  ) {
    this.dispatch(options);
  };

  /**
   * Tells the picker that uploading has started, and starts the upload
   * by calling `pickerUploadFunc` */
  pickerStartUpload: FileUploadStore['handlePickerStartUpload'] = function(
    options,
  ) {
    this.dispatch(options);
  };
}

const actions = createTypedActions<FileUploadActions>(FileUploadActions);
export { actions as FileUploadActions };
