module.exports = () => {
  return {
    progress: {
      label: "In Progress",
      color: "bg-google b--navy",
      textColor: 'white'
    },
    approved: {
      label: "Approved",
      color: "bg-racing-green b--dark-green",
      textColor: 'white'
    },
    review: {
      label: "Review Needed",
      color: "bg-yellow b--dark-yellow",
      textColor: ''
    },
    clear: {
      label: "Remove Status",
      color: "bg-transparent b--moon-gray ba bw1",
      textColor: ''
    },
  };
};
