/** @format */

import React, { useContext } from 'react';
import { TrialContext, TrialProvider } from './TrialContext';
import { PricingProvider, PricingContext } from '../pricing/PricingContext';
import { type Price } from 'blackbird/helpers/contexts/BillingContext';
import { TrialPlanSmall } from './TrialPlanSmall';
import { PricingTablePuffinPlan } from '../pricing/PricingTablePuffinPlan';
import classNames from 'classnames';

export interface TrialPlansProps {
  showFeatures?: boolean;
}

export const TrialPlans: React.FC<TrialPlansProps> = ({ showFeatures }) => {
  const { currentPlan, setCurrentPlan } = useContext(TrialContext);
  const { plans } = useContext(PricingContext);

  const currency = BoordsConfig.Currency;

  return !plans ? null : (
    <div
      className={classNames(
        '',
        showFeatures ? 'grid md:grid-cols-3 gap-6' : 'space-y-4',
      )}
    >
      {plans.map((plan) => {
        const price: Price | undefined = plan.prices.find(
          (price) => price.currency === currency && price.interval === 'month',
        );
        return !price ? null : showFeatures ? (
          <PricingTablePuffinPlan
            key={plan.slug}
            price={price}
            plan={plan}
            isSelectedPlan={currentPlan === plan.slug}
            onSelect={(selectedPlan) => setCurrentPlan(selectedPlan)}
          />
        ) : (
          <TrialPlanSmall
            key={plan.slug}
            price={price}
            plan={plan}
            isCurrentPlan={currentPlan === plan.slug}
            onSelect={(selectedPlan) => setCurrentPlan(selectedPlan)}
          />
        );
      })}
    </div>
  );
};

export const TrialPlansWrapped: React.FC<TrialPlansProps> = (props) => (
  <PricingProvider>
    <TrialProvider>
      <TrialPlans {...props} />
    </TrialProvider>
  </PricingProvider>
);
