const _ = require('underscore');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');
window.FlyoverSectionHeader = createReactClass({

  propTypes:   {
    title:     PropTypes.string,
    subtitle:  PropTypes.string,
    icon:      PropTypes.string,
  },

  render: function() {
    var icon;

    if (_.isUndefined(this.props.icon)) {
      icon = "logo-45";
    } else {
      icon = this.props.icon;
    }

    return(
      <div className="flex flex-col gap-3">
        <div className="text-2xl font-display text-type-primary">{this.props.title}</div>
        {!_.isUndefined(this.props.subtitle) ? (
          <div className="text-type-subdued">{this.props.subtitle}</div>
        ) : null}
      </div>
    );
  }

});
