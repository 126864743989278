/** @format */
import * as React from 'react';
import { useStore } from 'javascripts/helpers/useStore';
import { PlayerActions } from 'javascripts/flux/actions/player';
import { useTranslation } from 'react-i18next';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import { debounce } from 'underscore';

const debouncedUpdate = debounce(
  (amount) => PlayerActions.updateTotalDuration.defer(amount),
  1500,
);

export const TotalDuration: React.FC<{ isEditable: boolean }> = (props) => {
  const editable = props.isEditable;
  const endTime = useStore<number>('player', (s) => s.endTime);
  const [duration, setDuration] = React.useState(endTime);
  const { t } = useTranslation();

  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => {
    const endTime = parseInt(e.target.value, 10) * 1000;
    setDuration(endTime);
    debouncedUpdate(endTime);
  }, []);

  return (
    <div>
      <div className="flex items-center mb-2 gap-2">
        <TextInput
          inputSize="md"
          disabled={!editable}
          className="max-w-[96px]"
          inputClassName={editable ? undefined : 'cursor-not-allowed'}
          type="number"
          readOnly={!editable}
          value={(duration / 1000).toFixed(0)}
          max="500"
          onChange={handleChange}
          onBlur={() => PlayerActions.updateTotalDuration.defer(duration)}
          min="3"
          step="1"
        />
        <div className="text-type-subdued">seconds</div>
      </div>

      {editable ? (
        <div className="text-sm text-type-subdued">
          {t('player.settings.durationDescription')}
        </div>
      ) : (
        <div className="text-sm leading-tight">
          {t('player.settings.durationLocked')}
        </div>
      )}
    </div>
  );
};
