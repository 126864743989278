/** @prettier */
import {
  PDFLayoutProps,
  PaperOrientation,
  PageFormat,
  FontSettingsVariations,
  DocInfo,
} from '../types';
import { widescreenPageSize } from '../PDFLayoutMetrics';
import { toSlug } from '../../../helpers/to-slug';
import React from 'react';
// Using import doesn't work here. It's a problem with our typescript setup
// maybe something regarding the allowSyntheticDefaultImports. I've encountered
// this before, but forgot where. In any case, 'fixing' it in the config would
// cause issues with many other requires throughout the app.

const sanitizeFilename = require('sanitize-filename');

type Props = Pick<PDFLayoutProps, 'documentFormat' | 'fontInfo'> &
  Partial<PDFLayoutProps>;

// const  jsPDF = import('./jspdf').;


export const createDocument = async (
  { documentFormat, fontInfo }: Props,
  paperOrientation: PaperOrientation,
  formatOverride?: number[],
): Promise<DocInfo> => {
  const pageFormat: PageFormat = {
    orientation: paperOrientation,
    format:
      formatOverride ??
      (documentFormat === 'widescreen' ? widescreenPageSize : documentFormat),
  };

  // create a document using the page format above
  // we do NOT want to compress the pdf, because that messes up
  // support for international characters. Fantastic.
  const { jsPDF } = await import('jspdf');
  const doc = new jsPDF({ ...pageFormat, unit: 'pt', compress: false });

  if (fontInfo) {
    // prettier-ignore
    const variationsToLoad: Array<keyof FontSettingsVariations> = [
      'normal', 'italic', 'bold', 'bolditalic',
    ];

    variationsToLoad.forEach(variation => {
      const fontInfoVariation = fontInfo[variation];
      if (!fontInfoVariation) return;
      // Add each font variation to pdfJS's virtual file system so that it
      // can be used in the PDF
      const fileName = sanitizeFilename(
        toSlug(`${fontInfo.slug}-${variation}`),
      );
      doc.addFileToVFS(fileName, fontInfoVariation);
      doc.addFont(fileName, fontInfo.slug, variation);
    });

    doc.setFont(fontInfo.slug, 'normal');
  }
  return {
    doc: doc,
    fontSettings: fontInfo,
    pageFormat,
  };
};
