/** @prettier */
import * as React from 'react';
import Button from '../button/Button';

export const PricingButton: React.FC = () => {
  return (
    <Button
      type="solid"
      onClick={() => {
        FlyoverActions.open.defer({
          type: 'inlinePricing',
        });
      }}
    >
      {`Compare Plans`}
    </Button>
  );
};

(window as any).PricingButton = PricingButton;
