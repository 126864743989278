/** @format */
import Icon from '../icon/Icon';
import CloseIcon from '../../images/icons/close.svg';
import classNames from 'classnames';
import React from 'react';
export const CloseButton: React.FC<{
  onHandleCancel: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  customBackground?: boolean;
}> = ({ onHandleCancel, className, customBackground }) => {
  return (
    <button
      type="button"
      onClick={onHandleCancel}
      className={classNames(
        'flex-shrink-0 focus:outline-none focus-visible:outline-blue flex items-center justify-center h-10 w-10 rounded-full',
        { 'bg-surface-light hover:bg-surface': !customBackground },
        className,
      )}
    >
      <span className="-mt-[4px] mr-0.5 fullsize:mr-0">
        <Icon icon={<CloseIcon />} />
      </span>
    </button>
  );
};
