/** @prettier */
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OnboardingProps } from './Onboarding';

const FinishOverboardingScreen: React.FC<OnboardingProps> = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'welcome.finishing',
  });

  const { projectName } = props;

  return (
    <div className="flex-grow flex justify-center items-center">
      <div className="flex flex-grow flex-col max-w-md space-y-3">
        <h2 className="text-2xl text-type-primary">
          {t('heading', { projectName })}
        </h2>
        <span className="text-type-subdued">{t('subheading')}</span>
      </div>
    </div>
  );
};

export default FinishOverboardingScreen;
