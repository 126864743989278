/** @prettier */
import { BookmarkIcon } from '@heroicons/react/24/outline';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import Tabs from 'blackbird/components/tabs/Tabs';
import { type TabProps } from 'blackbird/components/tabs/types';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import classNames from 'classnames';
import { ErrorBoundary } from 'javascripts/components/shared/ErrorBoundary';
import {
  iconFinderCategoryLocalState,
  iconFinderIconsetLocalState,
  iconFinderSavedIconsetsLocalState,
  iconFinderStyleLocalState,
  iconFinderTabLocalState,
} from 'javascripts/helpers/local-state';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IconFinderItem } from './IconFinderItem';
import { IconFinderSearchInput } from './IconFinderSearchInput';
import { ImageSearchBreadcrumb } from './ImageSearchBreadcrumb';
import { ImageSearchFilterItem } from './ImageSearchFilterItem';
import { LoadMoreButton } from './LoadMoreButton';
import { StatusIndicator } from './StatusIndicator';
const errorHandler = RequestErrorHandler('iconFinder', 'Boords or IconFinder');

export interface IconFinderStyleOrCategory {
  name: string;
  identifier: string;
  count: number;
  preview_icons?: Icon[];
}

export interface Icon {
  preview_url: string;
  icon_id?: number;
  image_url?: string;
}

export interface IconSet {
  iconset_id: number;
  identifier: string;
  icons_count: number;
  name: string;
  preview_icons: Icon[];
}

interface IconFinderStyleOrCategoryResponse {
  data: {
    attributes: {
      total_count: number;
      iconsets: IconSet[];
    };
  };
}

interface IconFinderIconSetResponse {
  data: {
    attributes: {
      icons: Icon[];
    };
  };
}

export const IconFinderContainer: React.FC = () => {
  const { t } = useTranslation('imageLibrary');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const [searchString, setSearchString] = React.useState<string>('');
  const [submittedSearchString, setSubmittedSearchString] =
    React.useState<string>('');
  const [hasMore, setHasMore] = React.useState(true);

  const [page, setPage] = React.useState(1);

  const styles: IconFinderStyleOrCategory[] = t('iconFinder.styles', {
    returnObjects: true,
  });
  const popular: IconSet[] = t('iconFinder.popular', {
    returnObjects: true,
  });
  const categories: IconFinderStyleOrCategory[] = t('iconFinder.categories', {
    returnObjects: true,
  });

  const [iconSetCount, setIconSetCount] = React.useState<number | undefined>(
    undefined,
  );

  const [iconSets, setIconSets] = React.useState<IconSet[] | undefined>(
    undefined,
  );

  const [savedIconSets, setSavedIconSets] = React.useState<IconSet[] | []>(
    iconFinderSavedIconsetsLocalState.getValue() || [],
  );

  const [icons, setIcons] = React.useState<Icon[] | undefined>(undefined);

  const [view, setView] = React.useState(
    iconFinderTabLocalState.getValue() || 'popular',
  );
  const [activeStyle, setActiveStyle] = React.useState<
    IconFinderStyleOrCategory | undefined
  >(iconFinderStyleLocalState.getValue() || undefined);

  const [activeIconSet, setActiveIconSet] = React.useState<IconSet | undefined>(
    iconFinderIconsetLocalState.getValue() || undefined,
  );

  const [activeCategory, setActiveCategory] = React.useState<
    IconFinderStyleOrCategory | undefined
  >(iconFinderCategoryLocalState.getValue() || undefined);

  const saveLocalState = React.useCallback(
    (location: string) => {
      if (location === 'category') {
        const existingCategory = iconFinderCategoryLocalState.getValue();
        if (
          (!existingCategory && activeCategory) ||
          (existingCategory &&
            activeCategory &&
            existingCategory.identifier !== activeCategory.identifier)
        ) {
          iconFinderCategoryLocalState.setValue(activeCategory);
          iconFinderIconsetLocalState.setValue(undefined);
          iconFinderStyleLocalState.setValue(undefined);
        }
      } else if (location === 'style') {
        const existingStyle = iconFinderStyleLocalState.getValue();
        if (
          (!existingStyle && activeStyle) ||
          (existingStyle &&
            activeStyle &&
            existingStyle.identifier !== activeStyle.identifier)
        ) {
          iconFinderStyleLocalState.setValue(activeStyle);
          iconFinderIconsetLocalState.setValue(undefined);
          iconFinderCategoryLocalState.setValue(undefined);
        }
      } else if (location === 'iconset') {
        const existingIconSet = iconFinderIconsetLocalState.getValue();
        if (
          (!existingIconSet && activeIconSet) ||
          (existingIconSet &&
            activeIconSet &&
            existingIconSet.iconset_id !== activeIconSet.iconset_id)
        ) {
          iconFinderIconsetLocalState.setValue(activeIconSet);
        }
      } else if (location === 'tab') {
        iconFinderTabLocalState.setValue(view);
        iconFinderIconsetLocalState.setValue(undefined);
        iconFinderStyleLocalState.setValue(undefined);
        iconFinderCategoryLocalState.setValue(undefined);
      }
    },
    [activeStyle, activeCategory, activeIconSet, view],
  );

  const FetchIconSet = React.useCallback(async () => {
    if (activeIconSet) {
      setIsLoading(true);
      const request = await apiRequest({
        method: 'get',
        path: `iconfinder/iconsets/${activeIconSet.iconset_id}`,
      });

      if (!request.ok) return errorHandler({ method: 'get' })(request);
      const response: IconFinderIconSetResponse = await request.json();
      setIsLoading(false);
      setIcons(response.data.attributes.icons);
    }
  }, [activeIconSet]);

  const FetchCategoryOrStyle = React.useCallback(
    async (
      endpoint: string,
      identifier: string,
      after?: number | undefined,
    ) => {
      const perPage = 8;
      const currentScroll = window.scrollY;

      if (after) {
        setIsLoadingMore(true);
      } else {
        setIsLoading(true);
      }
      const request = await apiRequest({
        method: 'get',
        path: `iconfinder/${endpoint}/${identifier}?count=${perPage}${
          after ? `&after=${after}` : ''
        }`,
      });
      if (!request.ok) return errorHandler({ method: 'get' })(request);
      const response: IconFinderStyleOrCategoryResponse = await request.json();
      setIsLoading(false);
      setIsLoadingMore(false);
      setIconSetCount(response.data.attributes.total_count);

      if (after && iconSets) {
        window.scrollTo(0, currentScroll);
        setIconSets((prevIcons: IconSet[]) => [
          ...prevIcons,
          ...response.data.attributes.iconsets,
        ]);
      } else {
        setIconSets(response.data.attributes.iconsets);
      }
    },
    [iconSets],
  );

  React.useEffect(() => {
    if (iconSets && iconSetCount) {
      if (iconSets.length < iconSetCount) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(true);
    }
  }, [iconSets, iconSetCount]);

  React.useEffect(() => {
    if (activeIconSet) {
      FetchIconSet();
      saveLocalState('iconset');
    }
  }, [activeIconSet]);

  React.useEffect(() => {
    saveLocalState('tab');
  }, [view]);

  React.useEffect(() => {
    if (activeStyle) {
      setPage(1);
      FetchCategoryOrStyle('styles', activeStyle.identifier);
      saveLocalState('style');
    }
  }, [activeStyle]);

  React.useEffect(() => {
    if (activeCategory) {
      setPage(1);
      FetchCategoryOrStyle('categories', activeCategory.identifier);
      saveLocalState('category');
    }
  }, [activeCategory]);

  React.useEffect(() => {
    if (page > 1) {
      setIsLoadingMore(true);
      if (activeStyle && iconSets) {
        FetchCategoryOrStyle(
          'styles',
          activeStyle.identifier,
          iconSets[iconSets.length - 1].iconset_id,
        );
      } else if (activeCategory && iconSets) {
        FetchCategoryOrStyle(
          'categories',
          activeCategory.identifier,
          iconSets[iconSets.length - 1].iconset_id,
        );
      }
    }
  }, [page, activeCategory, activeStyle]);

  const tabs = React.useMemo<TabProps[]>(
    () => [
      {
        key: 'popular',
        caption: 'Popular',
      },
      {
        key: 'categories',
        caption: 'Categories',
      },
      {
        key: 'styles',
        caption: 'Styles',
      },
      {
        key: 'saved',
        caption: 'Saved',
      },
    ],
    [setView, view],
  );

  const LoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const IconGrid: React.FC = () => {
    return !icons ? null : (
      <div className="px-3 mb-8 grid grid-cols-3 gap-4">
        {icons.map((icon) => (
          <IconFinderItem
            key={`icon-${icon.icon_id}`}
            icon={icon}
            fullUrl={icon.image_url}
            previewUrl={icon.preview_url}
          />
        ))}
      </div>
    );
  };

  const IconSetGrid: React.FC = () => {
    return !iconSets ? null : (
      <div className="mb-8">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
          {iconSets.map((iconSet) => (
            <ImageSearchFilterItem
              key={iconSet.iconset_id}
              previewIcons={iconSet.preview_icons}
              title={iconSet.name}
              count={iconSet.icons_count}
              naturalAspectRatio
              noun="icons"
              aspect="square"
              onClick={() => setActiveIconSet(iconSet)}
            />
          ))}
        </div>

        {hasMore && (
          <div className="mt-4">
            <LoadMoreButton onClick={LoadMore} loading={isLoadingMore} />
          </div>
        )}
      </div>
    );
  };

  const RunSearch = async (searchString: string, page = 1) => {
    if (searchString) {
      setIsSearching(true);
      setSubmittedSearchString(searchString);

      const request = await apiRequest({
        method: 'get',
        path: `iconfinder?query=${searchString}`,
      });

      if (!request.ok) return errorHandler({ method: 'get' })(request);
      const response: IconFinderIconSetResponse = await request.json();

      const { icons: iconSearchResults } = response.data.attributes;

      setIcons(iconSearchResults);
      setIsSearching(false);
    }
  };

  // const debouncedSearch = debounce(RunSearch, 500);

  // React.useEffect(() => {
  //   if (searchString) {
  //     debouncedSearch(searchString);
  //   }
  // }, [searchString]);

  const saveIconSet = (iconset: IconSet) => {
    if (
      savedIconSets.some((saved) => saved.identifier === iconset.identifier)
    ) {
      const filteredIconSets = savedIconSets.filter(
        (saved) => saved.identifier !== iconset.identifier,
      );
      setSavedIconSets(filteredIconSets);
      iconFinderSavedIconsetsLocalState.setValue(filteredIconSets);
    } else {
      const newSavedIconSets = [...savedIconSets, iconset];
      setSavedIconSets(newSavedIconSets);
      iconFinderSavedIconsetsLocalState.setValue(newSavedIconSets);
    }
  };

  interface ToggleSaveIconProps {
    iconSet: IconSet;
  }

  const ToggleSaveIcon: React.FC<ToggleSaveIconProps> = ({ iconSet }) => {
    const isSaved = savedIconSets.some(
      (s) => s.identifier === iconSet.identifier && 'fill-black',
    );
    return (
      <Tooltip
        title={isSaved ? 'Remove from Favorites' : 'Add to Favorites'}
        placement="top"
      >
        <BookmarkIcon
          className={classNames(
            'cursor-pointer w-4 h-4',
            isSaved
              ? 'fill-brand-yellow text-brand-yellow'
              : 'text-type-subdued hover:text-brand-yellow',
          )}
          onClick={() => {
            saveIconSet(iconSet);
          }}
        />
      </Tooltip>
    );
  };

  return (
    <ErrorBoundary size="small">
      {activeIconSet ? (
        <div className="px-3 mt-4">
          <div className="flex items-center px-3">
            <div className="flex-auto">
              <ImageSearchBreadcrumb
                title={activeIconSet.name}
                newLine
                noun={`Icons / ${view === 'saved' ? 'Saved' : ''}${
                  view === 'popular' ? 'Popular' : ''
                }${activeCategory ? activeCategory.name : ''}${
                  activeStyle ? activeStyle.name : ''
                }`}
                onClick={() => {
                  setActiveIconSet(undefined);
                  iconFinderIconsetLocalState.setValue(undefined);
                  setIcons(undefined);
                  if (!activeCategory && !activeStyle) {
                    setIsLoading(true);
                  }
                }}
              />
            </div>
            <ToggleSaveIcon iconSet={activeIconSet} />
          </div>

          {isLoading ? (
            <StatusIndicator text={t('loading')} loading />
          ) : (
            icons && <IconGrid />
          )}
        </div>
      ) : activeStyle ? (
        <div className="px-3 mt-4">
          <div className="px-3">
            <ImageSearchBreadcrumb
              title={activeStyle.name}
              count={iconSetCount}
              newLine
              noun={`Icons / Styles`}
              onClick={() => {
                setActiveStyle(undefined);
                setIconSets(undefined);
                setIconSetCount(undefined);
                saveLocalState('tab');
              }}
            />
          </div>
          {isLoading ? (
            <StatusIndicator text={t('loading')} loading />
          ) : (
            iconSets && <IconSetGrid />
          )}
        </div>
      ) : activeCategory ? (
        <div className="px-3 mt-4">
          <div className="px-3">
            <ImageSearchBreadcrumb
              title={activeCategory.name}
              noun={`Icons / Categories`}
              count={iconSetCount}
              newLine
              onClick={() => {
                setActiveCategory(undefined);
                setIconSets(undefined);
                setIconSetCount(undefined);
                saveLocalState('tab');
              }}
            />
            {isLoading ? (
              <StatusIndicator text={t('loading')} loading />
            ) : (
              iconSets && <IconSetGrid />
            )}
          </div>
        </div>
      ) : (
        <div className="px-3 mb-8">
          <div className="my-5">
            <IconFinderSearchInput
              submittedSearchString={submittedSearchString}
              setSubmittedSearchString={setSubmittedSearchString}
              setIcons={setIcons}
              RunSearch={RunSearch}
              searchString={searchString}
              setSearchString={setSearchString}
              isSearching={isSearching}
            />
          </div>
          {isSearching ? (
            <StatusIndicator text={t('loading')} loading />
          ) : icons ? (
            icons.length === 0 ? (
              <StatusIndicator text={t('empty')} />
            ) : (
              <IconGrid />
            )
          ) : (
            <>
              <div className="my-5">
                <Tabs
                  type="rounded"
                  onSelect={setView}
                  tabs={tabs}
                  defaultValue={view}
                  className="w-full"
                  tabClassName=""
                  customBorder
                  manual
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                {view === 'styles' ? (
                  styles.map((style, idx) => (
                    <ImageSearchFilterItem
                      key={`style-${idx}`}
                      previewIcons={style.preview_icons}
                      title={style.name}
                      count={style.count}
                      noun={`icon sets`}
                      onClick={() => setActiveStyle(style)}
                    />
                  ))
                ) : view === 'popular' ? (
                  popular.map((iconSet, idx) => (
                    <ImageSearchFilterItem
                      key={`popular-${idx}`}
                      previewIcons={iconSet.preview_icons}
                      title={iconSet.name}
                      count={iconSet.icons_count}
                      noun={`icons`}
                      onClick={() => setActiveIconSet(iconSet)}
                    />
                  ))
                ) : view === 'saved' ? (
                  savedIconSets.length === 0 ? (
                    <div className="flex justify-center w-full col-span-2">
                      <StatusIndicator
                        text={t('iconFinder.emptySaved')}
                        empty
                      />
                    </div>
                  ) : (
                    savedIconSets.map((iconSet: IconSet, idx: number) => (
                      <ImageSearchFilterItem
                        key={`popular-${idx}`}
                        previewIcons={iconSet.preview_icons}
                        title={iconSet.name}
                        count={iconSet.icons_count}
                        noun={`icons`}
                        onClick={() => setActiveIconSet(iconSet)}
                      />
                    ))
                  )
                ) : (
                  categories.map((category, idx) => (
                    <ImageSearchFilterItem
                      key={`category-${idx}`}
                      previewIcons={category.preview_icons}
                      title={category.name}
                      count={category.count}
                      noun={`icon sets`}
                      onClick={() => setActiveCategory(category)}
                    />
                  ))
                )}
              </div>
            </>
          )}
        </div>
      )}
    </ErrorBoundary>
  );
};
