/** @prettier */
import { insertAtSelection } from 'blackbird/components/form/richTextInput/insertAtSelection';
import type {
  RichTextInputChangeEventHandler,
  RichTextInputPasteEventHandler,
} from 'blackbird/components/form/richTextInput/RichTextInput';
import { TextArea } from 'blackbird/components/form/text-input/TextInput';
import classNames from 'classnames';
import { eachFrameFieldMap } from 'javascripts/helpers/eachFrameFieldMap';
import { getFrameField } from 'javascripts/helpers/fieldDataHelpers';
import type { ScriptEditorStore } from 'javascripts/flux/stores/scripteditor';
import type { IFrame } from 'javascripts/types/frame';
import type { FrameField } from 'javascripts/types/storyboard';
import * as React from 'react';
import { focusInsideElement } from './focusInsideElement';
import { getScriptEditorColor } from './scriptEditorColors';
import { cursorIsAtStartOrEndOfElement } from './scriptEditorDeletionHander';

interface Props {
  frames: IFrame[];
  frameFields: FrameField[];
  onChange: ScriptEditorStore['handleUpdateText'];
  showReferenceField: boolean;
}

export class ScriptEditorFrameFields extends React.PureComponent<Props> {
  // This is not allowed to be a proper ref, which is dumb
  inputsRef: HTMLElement[] = [];

  handleChange: RichTextInputChangeEventHandler = (value, e, name) => {
    if (!name) throw new Error('no name present');

    const [idString, fieldId] = name.split('.');
    this.props.onChange({
      frameId: parseInt(idString),
      fieldId: fieldId,
      value: value,
    });
  };

  parser = new DOMParser();

  handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const selection = window.getSelection();
    const currentIndex = this.inputsRef.indexOf(e.currentTarget);
    if (!selection || e.shiftKey) return;

    // When navigating up and down, we can add a bit of extra margin, so that
    // even though we're not at the very end or beginning, we will accept
    // navigating to the next or previous field
    const margin = e.key === 'ArrowUp' || e.key === 'ArrowDown' ? 7 : 0;

    if (
      (e.key === 'ArrowUp' || e.key === 'ArrowLeft') &&
      cursorIsAtStartOrEndOfElement('start', e.currentTarget, margin) &&
      currentIndex > 0
    ) {
      const el = this.inputsRef[currentIndex - 1];
      focusInsideElement(el, 'end');
      e.preventDefault();
      return;
    } else if (
      (e.key === 'ArrowDown' || e.key === 'ArrowRight') &&
      cursorIsAtStartOrEndOfElement('end', e.currentTarget, margin) &&
      currentIndex < this.inputsRef.length - 1
    ) {
      const el = this.inputsRef[currentIndex + 1];
      el.focus();
      e.preventDefault();
      return;
    }
  };

  handlePaste: RichTextInputPasteEventHandler = (e, textPasted) => {
    if (textPasted instanceof DocumentFragment) {
      insertAtSelection(textPasted);
    } else {
      document.execCommand('insertText', false, textPasted);
    }
  };

  render() {
    const width = this.props.frames?.length > 10 ? 30 : undefined;
    this.inputsRef = [];

    return (
      <>
        {this.props.frames.map((frame, frameIndex) => (
          <div key={frame.id} className="flex gap-5">
            <div
              className="relative flex items-center justify-between shrink-0 w-7"
              style={{ width }}
            >
              <div className="text-sm">{frame.number || frameIndex + 1}</div>
              <div className="self-stretch w-1 border-2 border-r-0 border-type-primary"></div>
            </div>

            <div className="text-sm font-medium w-100 links-underlined flex-auto">
              {eachFrameFieldMap(
                this.props.frameFields,
                (field, id, i, label) => {
                  if (id === 'reference' && !this.props.showReferenceField)
                    return null;

                  return (
                    <TextArea
                      noBorder
                      key={field.id}
                      inputSize="xs"
                      ref={(el) => el && this.inputsRef.push(el)}
                      name={String(frame.id) + '.' + field.id}
                      placeholder={`+ ${label}`}
                      title={label}
                      value={getFrameField(frame, field.id)}
                      textAreaClassName={classNames(
                        'resize-none shrink-0 has-lists has-paragraph-spacing',
                        ...getScriptEditorColor(i),
                      )}
                      minRows={1}
                      onChange={this.handleChange}
                      onPaste={this.handlePaste}
                      onKeyDown={this.handleKeyDown}
                      immediate
                    />
                  );
                },
              )}
            </div>
          </div>
        ))}
      </>
    );
  }
}
