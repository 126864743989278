/** @prettier */
import * as React from 'react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import sub from 'date-fns/sub';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import formatISO from 'date-fns/formatISO';
import format from 'date-fns/format';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

interface Props {
  date: Date;
  includeSeconds?: boolean;
  addSuffix?: boolean;
  capitalizeFirst?: boolean;
  className?: string;
  /**
   * if skipPrefix is set to true , it will remove helper prefixes like  'almost', 'over', 'less than', 'about' etc.
   */
  skipPrefix?: boolean;
}

const getRelativeTime = (props: Props) => {
  const formatFn = props.skipPrefix
    ? formatDistanceToNowStrict
    : formatDistanceToNow;

  let distance = formatFn(props.date, {
    addSuffix: props.addSuffix,
    includeSeconds: props.includeSeconds,
  });

  if (props.capitalizeFirst && distance) {
    distance = distance.charAt(0).toUpperCase() + distance.slice(1);
  }

  return distance;
};

export const RelativeTime: React.FC<Props> = (props) => {
  const timeout = React.useRef<number>();

  const [relativeTime, setRelativeTime] = React.useState(
    getRelativeTime(props),
  );

  const updateRelativeTime = React.useCallback(() => {
    const fiveMinAgo = sub(props.date, { minutes: 5 });
    const oneMinAgo = sub(props.date, { minutes: 1 });
    const dayAgo = sub(props.date, { days: 1 });

    let updateTimeout;
    setRelativeTime(getRelativeTime(props));
    clearTimeout(timeout.current);

    // If it's older than a day old, don't schedule an update
    if (isBefore(props.date, dayAgo)) {
      return;
      // Newer than a minute? refresh every 5 seconds
    } else if (
      (isAfter(props.date, oneMinAgo) && props.includeSeconds) ||
      props.skipPrefix
    ) {
      updateTimeout = 5 * 1000;
      // Newer than 5 minutes? refresh every 30 seconds
    } else if (isAfter(props.date, fiveMinAgo)) {
      updateTimeout = 30 * 1000;
      // Otherwise, update every 20 minutes
    } else {
      updateTimeout = 60 * 1000 * 20;
    }

    if (updateTimeout) {
      timeout.current = window.setTimeout(() => {
        updateRelativeTime();
      }, updateTimeout);
    }
  }, [props]);

  React.useEffect(() => {
    updateRelativeTime();
    return () => clearTimeout(timeout.current);
  }, [updateRelativeTime, props.includeSeconds]);

  return (
    <time
      className={props.className}
      dateTime={formatISO(props.date)}
      title={format(props.date, 'PPPPpp')}
    >
      {relativeTime}
    </time>
  );
};

RelativeTime.defaultProps = {
  addSuffix: true,
  includeSeconds: false,
};
