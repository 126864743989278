/** @prettier */
import { ModalDivider } from 'blackbird/components/dialog/Dialog';
import * as React from 'react';

interface PanelbarLibraryTitleProps {
  title: string;
  subtitle?: string | React.ReactNode;
  children?: React.ReactElement;
}

export const PanelbarLibraryTitle: React.FC<PanelbarLibraryTitleProps> = ({
  title,
  subtitle,
  children,
}): React.ReactElement => {
  return (
    <div className="px-3 pt-3 pb-0 xl:px-6 xl:pt-4">
      <div className="flex items-center">
        <div className="flex-auto ">
          <div className="text-base font-semibold xl:text-lg">{title}</div>
          {subtitle && (
            <div className="hidden text-sm xl:block text-type-disabled">
              {subtitle}
            </div>
          )}
        </div>
        {children && <div className="flex-shrink-0 ml-2">{children}</div>}
      </div>

      <div className="mt-3 xl:mt-5">
        <ModalDivider />
      </div>
    </div>
  );
};
