/** @prettier */
import confetti from 'canvas-confetti';

const count = 200;

const createInstance = (particleRatio: number, opts: any): Promise<void> =>
  confetti({
    ...opts,
    // Boords-coloured confetti
    colors: ['#6CBCF4', '#F4BD64', '#E37D9B'],
    particleCount: Math.floor(count * particleRatio),
    origin: { y: 0.8, x: opts.originX ?? 0.5 },
    zIndex: 10000, // one more than z-modal
  });

export const fireConfetti = () => {
  const prefersReducedMotion =
    typeof matchMedia === 'function' &&
    matchMedia('(prefers-reduced-motion)').matches;

  if (prefersReducedMotion) Promise.resolve();

  return Promise.all([
    createInstance(0.25, {
      spread: 26,
      startVelocity: 55,
      originX: 0.35,
      angle: 135,
    }),
    createInstance(0.2, {
      spread: 60,
      originX: 0.66,
      angle: 45,
    }),
    createInstance(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
      originX: 0.5,
    }),
    createInstance(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
      originX: 0.3,
    }),
    createInstance(0.1, {
      spread: 120,
      startVelocity: 45,
      originX: 0.7,
    }),
  ]);
};
