window.PdfCoverActions = alt.createActions(function () {

  this.checkIfProcessing = function(data) {
    this.dispatch(data);
  };

  this.uploadByURL = function(data) {
    this.dispatch(data);
  };

  this.upload = function(data) {
    this.dispatch(data);
  };

  this.fetch = function(data) {
    this.dispatch(data);
  };

  this.remove = function(data) {
    this.dispatch(data);
  };

});

