/** @prettier */
const _ = require('underscore');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');

window.ProjectTeammates = createReactClass({
  propTypes: {
    members: PropTypes.array,
    team: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
    showToggle: PropTypes.bool.isRequired,
    project: PropTypes.object.isRequired,
  },

  render: function () {
    var members = this.props.members;
    if (_.isEmpty(members)) return null;

    return (
      <>
        {members.map((member, i) =>
          this.props.showToggle ? (
            <ProjectTeammateAccessToggle
              team={this.props.team}
              member={member}
              project={this.props.project}
              labelType="user"
              showRole
              key={member.id ?? member.invitation_id}
            />
          ) : (
            <TeammateMember
              team={this.props.team}
              member={member}
              key={member.id ?? member.invitation_id}
            />
          ),
        )}
      </>
    );
  },
});
