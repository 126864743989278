/** @format */

import React, { useContext, useEffect, useState } from 'react';
import Button from '../button/Button';
import { type ButtonSize } from '../button/types';
import { GeneratorContext } from './GeneratorContext';
import { generatorLastRunLocalState } from 'javascripts/helpers/local-state';
import { differenceInSeconds, fromUnixTime } from 'date-fns';
import Badge from '../badge/Badge';
import classNames from 'classnames';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import Tooltip from '../feedback/tooltip/Tooltip';
// import { useFeatureFlagVariantKey } from 'posthog-js/react';
// import { posthog } from 'posthog-js';

interface GeneratorStartButtonProps {
  buttonSize: ButtonSize;
}

export const GeneratorStartButton = React.forwardRef<
  HTMLButtonElement,
  GeneratorStartButtonProps
>(({ buttonSize }, ref) => {
  // -------------- BOF A/B test -----------------
  const [canEnableLockout, setCanEnableLockout] =
    React.useState<boolean>(false);

  // const variant = useFeatureFlagVariantKey('appGeneratorRateLimit');
  // posthog.featureFlags.override({ appGeneratorRateLimit: 'test' });
  // useEffect(() => {
  //   if (variant === 'test' && BoordsConfig.IsProfessionalFree) {
  //     setCanEnableLockout(true);
  //   } else {
  //     setCanEnableLockout(false);
  //   }
  // }, [variant]);
  // -------------- EOF A/B test -----------------

  const [isLocked, setIsLocked] = useState(true);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [unlockProgress, setUnlockProgress] = useState(0);

  const { isPanelbar, GenerateImage, useSeed, seed, prompt, generatorPreview } =
    useContext(GeneratorContext);

  const unlockInSeconds = 60;

  useEffect(() => {
    if (!canEnableLockout) {
      setIsLocked(false);
    } else {
      const now = new Date();
      const lastRunValue = generatorLastRunLocalState.getValue();
      const lastRunDate = lastRunValue
        ? fromUnixTime(parseInt(lastRunValue))
        : null;

      // Determine if the button should be initially locked or unlocked
      let shouldLock = true;
      let initialTimeLeft = unlockInSeconds;

      if (lastRunDate) {
        const timeSinceLastRun = differenceInSeconds(now, lastRunDate);
        if (timeSinceLastRun >= unlockInSeconds) {
          shouldLock = false;
          initialTimeLeft = 0;
        } else {
          initialTimeLeft = unlockInSeconds - timeSinceLastRun;
        }
      } else {
        shouldLock = false;
      }

      setIsLocked(shouldLock);
      setTimeLeft(initialTimeLeft);
      setUnlockProgress(
        shouldLock
          ? (100 * (unlockInSeconds - initialTimeLeft)) / unlockInSeconds
          : 100,
      );

      if (shouldLock) {
        Track.event.defer('generator_rate_limit_view', {
          posthogCapture: true,
        });

        const interval = setInterval(() => {
          setTimeLeft((prevTimeLeft) => {
            const newTimeLeft = Math.max(0, prevTimeLeft - 1);
            const newUnlockProgress =
              (100 * (unlockInSeconds - newTimeLeft)) / unlockInSeconds;
            setUnlockProgress(newUnlockProgress);
            if (newTimeLeft === 0) {
              setIsLocked(false);
              clearInterval(interval);
            }
            return newTimeLeft;
          });
        }, 1000);

        return () => clearInterval(interval);
      }
    }
  }, [canEnableLockout]);

  return (
    <>
      {isLocked ? (
        <div
          className="pl-3 pr-2 pt-2 pb-2.5 text-left text-type-subdued bg-brand-blue-25 rounded-md flex items-center"
          ref={ref as any}
        >
          <div
            className={classNames(
              'flex items-center flex-auto',
              isPanelbar && '-mt-1',
            )}
          >
            <Tooltip
              title={`Free plans can generate 1 image every ${unlockInSeconds} seconds.`}
              placement="top"
            >
              <span>
                <Badge
                  color="blue"
                  size="sm"
                  label={
                    <span
                      className={classNames(
                        'inline-flex items-center text-type-primary',
                        !isPanelbar && 'py-0.5 pl-0.5 pr-1',
                      )}
                    >
                      <LockClosedIcon
                        className={classNames(
                          'opacity-50',
                          isPanelbar ? 'mr-1 w-2.5 ' : 'mr-1.5 w-3',
                        )}
                      />
                      <span
                        className={classNames(
                          'text-type-primary/70',
                          !isPanelbar && 'text-sm',
                        )}
                      >{`Free Limit`}</span>
                    </span>
                  }
                />
              </span>
            </Tooltip>
            <span
              className={classNames(
                'ml-1.5 text-type-primary/80',
                isPanelbar ? 'text-xs pt-[0.2rem]' : 'text-sm',
              )}
            >{`Please wait ${timeLeft}s`}</span>
          </div>

          <div
            className={classNames(
              'flex-shrink-0',
              isPanelbar && 'hidden xl:block',
            )}
          >
            <Button
              size="sm"
              type="fancy"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                FlyoverActions.open({
                  type: 'inlinePricing',
                });
              }}
            >
              <span className="text-xs">
                {isPanelbar ? `Upgrade` : `Compare Plans`}
              </span>
            </Button>
          </div>
        </div>
      ) : (
        <Button
          className="w-full"
          type="fancy"
          size={buttonSize}
          disabled={prompt === '' || isLocked}
          onClick={GenerateImage}
          ref={ref}
        >
          <>
            {isLocked && <div className="absolute w-4 opacity-60 left-4"></div>}
            {useSeed && seed ? (
              <span className="space-x-1">
                <span>{`Generate`}</span>
                <span className="hidden xl:inline">{`from Seed`}</span>
              </span>
            ) : generatorPreview ? (
              <>{`Generate`}</>
            ) : (
              <>{`Generate`}</>
            )}
          </>
        </Button>
      )}
    </>
  );
});

GeneratorStartButton.displayName = 'GeneratorStartButton';
