import { isFileTypeAccepted } from "javascripts/helpers/isFileTypeAccepted";
import { RequestErrorHandler } from "javascripts/helpers/request-error-handler";
import { isUndefined, isEmpty} from 'underscore';

const errorHandler = RequestErrorHandler('PDFCover');

(() => {
  class PdfCoverStore {

    constructor() {

      this.file       = null;
      this.is_loaded  = false;
      this.urls       = {};

      this.bindListeners({
        handleFetch:        PdfCoverActions.FETCH,
        handleUploadByURL:  PdfCoverActions.UPLOAD_BY_URL,
        handleUpload:       PdfCoverActions.UPLOAD,
        handleRemove:       PdfCoverActions.REMOVE,
      });
    }

    handleCheckIfProcessing(data) {

      if (this.urls.is_processing) {

        window.PDFBespokeCoverTimeout = setTimeout(function() {
          this.handleFetch(data);
        }.bind(this), 1500);

      } else {

        clearTimeout(window.PDFBespokeCoverTimeout);
      }
    }

    handleRemove(data) {
      ajaxHelper.ajax({
        method: "delete",
        url: "/storyboards/" + data.storyboard_id + "/pdf_cover/" + data.type +  ".json",
        success: function (response) {
          this.urls      = response;
          if (!isUndefined(data.message)) {
            RequestActions.success.defer(data.message);
          } else {
            RequestActions.success.defer("PDF removed");
          }
          this.emitChange();
        }.bind(this),
        error: errorHandler({ message: "Sorry, we couldn't delete your PDF" })
      });
    }

    handleFetch(data) {
      ajaxHelper.ajax({
        method: "get",
        url: "/storyboards/" + data.storyboard_id + "/pdf_cover.json",
        success: function (response) {

          if (!isEmpty(this.urls) && !response.is_processing && this.urls.is_processing) {
            RequestActions.success.defer("PDF uploaded successfully!");
          }

          this.urls      = response;
          this.is_loaded = true;
          this.emitChange();

          this.handleCheckIfProcessing({
            storyboard_id: data.storyboard_id,
          });

        }.bind(this),
        error: errorHandler({ message: "Sorry, we couldn't get your PDF cover" })
      });
    }

    handleUpload(data) {
      var reader = new FileReader();

      reader.onload = function() {
        if (!this._verifyFile(data.file)) {
          this.file = null;
        } else {
          this._uploadFile({
            type:           data.type,
            storyboard_id:  data.storyboard_id,
            result:         reader.result,
            file_name:      data.file.name,
          });
        }
        this.emitChange();
      }.bind(this);
      reader.readAsDataURL(data.file);
    }

    handleUploadByURL(data) {
      ajaxHelper.ajax({
        method: "put",
        url: "/storyboards/" + data.storyboard_id + "/pdf_cover/" + data.storyboard_id + ".json",
        data: data,
        success: function (response) {
          this.urls      = response;
          this.is_loaded = true;
          this.emitChange();
        }.bind(this),
        error: errorHandler({ message: "Sorry, we couldn't upload your PDF" }, () => {
          this.is_working = false;
          this.emitChange();
        })
      });
    }

    _uploadFile(data) {
      this.is_working = true;
      this.emitChange();

      ajaxHelper.ajax({
        method: "post",
        url: "/storyboards/" + data.storyboard_id + "/pdf_cover.json",
        data: {
          type: data.type,
          file: data.result,
          file_name: data.file_name,
        },
        success: function (response) {
          this.urls      = response;
          this.is_loaded = true;
          this.emitChange();

          this.handleCheckIfProcessing({
            storyboard_id: data.storyboard_id,
          });
        }.bind(this),
        error: errorHandler({ message: "Sorry, we couldn't upload your PDF" }, () => {
          this.is_working = false;
          this.emitChange();
        })
      });
    }

    _verifyFile(file) {
      var allowed_types = ["application/pdf"],
        maxSizeInMB     = 20;

      if (!isFileTypeAccepted(allowed_types, file.type)) {
        RequestActions.error.defer({ key: 'sharedErrors.unsupportedUpload', data: { fileType: file.type, formats: 'pdf' } });
        return false;
      } else if ((parseInt(file.size, 10)/1000000).toFixed(2) > maxSizeInMB) {
        RequestActions.error.defer({ key: 'sharedErrors.fileSizeExceeded', data: { maxSize: maxSizeInMB + "MB" } });
        return false;
      } else {
        return true;
      }
    }
  }

  window.PdfCoverStore = alt.createStore(PdfCoverStore, 'PdfCoverStore');

})();
