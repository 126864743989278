/** @format */
import * as React from 'react';
import classNames from 'classnames';
import { canDropImages } from '../shared/canDropImages';
import {
  DRAGGABLE_TYPES,
  type fileDropResult,
  type imageLibraryDragItem,
} from '../shared/dragAndDropUtils';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { FrameActions } from 'javascripts/flux/actions/frame';
import type { IStoryboardInStore } from 'javascripts/types/storyboard';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const acceptedDropTypes = [
  NativeTypes.FILE,
  // DRAGGABLE_TYPES.frame,
  DRAGGABLE_TYPES.image,
  DRAGGABLE_TYPES.unsplash,
] as const;

interface Props {
  children: React.ReactNode;
  className?: string;
  storyboard: IStoryboardInStore;
  sortOrder: number;
}
export const GridViewDropZone: React.FC<Props> = (props) => {
  const [isBusy, setIsBusy] = React.useState(false);
  const { t } = useTranslation();

  const [{ isOver }, ref] = useDrop(() => ({
    accept: acceptedDropTypes as any,
    canDrop: canDropImages(true),
    drop: (item, monitor) => {
      if (isBusy) return;
      Track.event.defer('image_dropped');
      const type = monitor.getItemType() as typeof acceptedDropTypes[number];
      if (type === NativeTypes.FILE) {
        const item: fileDropResult = monitor.getItem();
        const files = item.files;

        FrameActions.bulkFrameUpload({
          storyboard_id: props.storyboard.id,
          files: files,
          existing_frames_length: props.sortOrder,
        });
      } else if (type === DRAGGABLE_TYPES.unsplash) {
        const item: imageLibraryDragItem = monitor.getItem();
        // We fetch the image from unsplash, then
        fetch(item.image)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], item.image, {
              type: blob.type,
            });

            setIsBusy(false);

            FrameActions.bulkFrameUpload({
              storyboard_id: props.storyboard.id,
              files: [file],
              existing_frames_length: props.sortOrder ?? 0,
            });
          });
      }
    },
    collect: (monitor) => ({
      // Are we currently dragging anything, anywhere in the app?
      isDragging: monitor.canDrop(),
      isOver: monitor.isOver() && monitor.canDrop(),
    }),
  }));

  return (
    <div
      className={classNames(props.className, 'relative', isOver ? 'ring' : '')}
      ref={ref}
    >
      {props.children}
      {isBusy && (
        <LoadingIndicator text={t('frames.status.fetching')} fill bg />
      )}
    </div>
  );
};

GridViewDropZone.displayName = 'GridViewDropZone';
