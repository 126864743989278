import { RequestErrorHandler } from "javascripts/helpers/request-error-handler";

const errorHandler = RequestErrorHandler('avatar');

(() => {
  class AvatarStore {

    constructor() {
      this.avatar_url = null;
      this.file       = null;
      this.error      = null;
      this.data       = null;
      this.is_working = false;

      this.bindListeners({
        handleUpload:  AvatarActions.UPLOAD,
        handleFetch:   AvatarActions.FETCH,
      });
    }

    handleFetch(data) {
      this.data = data;
      this.emitChange();
      ajaxHelper.ajax({
        method: "get",
        url: "/avatar/" + data.id + "/" + data.type,
        success: function (response) {
          this.avatar_url = response.url;
          this.emitChange();
        }.bind(this),
        error: errorHandler(),
      });
    }

    handleUpload(data) {
      this.is_working = true;
      this.emitChange();

      ajaxHelper.ajax({
        method: "post",
        url: "/avatar.json",
        data: data,
        success: function(response) {
          this.avatar_url = response.url;
          this.is_working = false;
          this.emitChange();
          if (this.data.type == "user") {
            UserActions.fetch.defer();
          } else if (this.data.type == "team") {
            TeamManagementActions.fetch.defer(this.data.id);
          }
          DashboardActions.fetch.defer();
        }.bind(this),
        error: errorHandler({ message: "Sorry, we couldn't save your Image" }, () => {

          this.is_working = false;
          this.emitChange();
        })
      });
    }
  }

  window.AvatarStore = alt.createStore(AvatarStore, 'AvatarStore');

})();
