const _ = require('underscore');
const { RequestErrorHandler } = require('../../helpers/request-error-handler');
const errorHandler = RequestErrorHandler('newProject');

(() => {
  class NewProjectStore {

    constructor() {
      this.project_team    = false;
      this.teams           = false;
      this.project_private = true;

      this.bindListeners({
        handleCreate:            NewProjectActions.CREATE,
        handleFetchTeams:        NewProjectActions.FETCH_TEAMS,
        handleSetProjectTeamId:  NewProjectActions.SET_PROJECT_TEAM_ID,
        handleSetProjectPrivacy:  NewProjectActions.SET_PROJECT_PRIVACY,
        handleProjectCreated:    NewProjectActions.PROJECT_CREATED,
      });
    }

    handleCreate(data) {
      ajaxHelper.ajax({
        method: "post",
        url: '/projects.json',
        // enctype: 'application/x-www-form-urlencoded',
        data: {
          project: {
            name:        data.name,
            team_id:     this.project_team.id,
            is_private:  this.project_private,
          }
        },
        success: (response) => {
          NewProjectActions.projectCreated.defer(response);
        },
        error: errorHandler({
          message: 'Could not create your project',
          rollbarMessage: 'error creating new project'
        })
      });
    }

    handleProjectCreated(response) {
      FlyoverActions.open.defer({
        type:       "newStoryboard",
        projectId:  response.id,
      });
    }

    handleFetchTeams() {
      ajaxHelper.ajax({
        method: "get",
        url: "/teams.json",
        success: function (response) {
          this.teams = response;
          this.emitChange();
        }.bind(this),
        error: errorHandler({
          message: null
        })
      });
    }

    handleSetProjectTeamId(team_id) {
      if (_.isNull(team_id)) {
        this.project_team = this.teams[0];
      } else {
        var team =  _.findWhere(this.teams, {id: parseInt(team_id, 10)});

        if (!_.isUndefined(team)) {
          this.project_team = team;
        } else {
          this.project_team = this.teams[0];
        }
      }
    }

    handleSetProjectPrivacy(privacy) {
      this.project_private = privacy;
    }
  }

  window.NewProjectStore = alt.createStore(NewProjectStore, 'NewProjectStore');

})();
