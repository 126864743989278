/** @prettier */
import Button from 'blackbird/components/button/Button';
import Banner from 'blackbird/components/feedback/banner/Banner';
import * as React from 'react';
import { BannerLocked } from './BannerLocked';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { posthog } from 'posthog-js';
import classNames from 'classnames';

interface BannerFreemiumControlProps {
  variant: string;
}
export const BannerFreemiumControl: React.FC<BannerFreemiumControlProps> = ({
  variant,
}): React.ReactElement => {
  React.useLayoutEffect(() => {
    Track.event.defer('banner_view', {
      variant: variant,
      posthogCapture: true,
    });
  }, [variant]);

  const [ctaCopy] = React.useState(`Compare Plans`);

  return (
    <Banner
      kind={'alert'}
      hideIcon
      className="no-padding-y h-banner"
      customWidth
      message={
        <div className="relative flex items-center justify-start w-full pl-2">
          <BannerLocked text={`Free Version`} />

          <div className="ml-3 mr-2.5 font-semibold">
            {`Unlock Full Access →`}
          </div>

          <div className={classNames('flex justify-start flex-auto')}>
            <Button
              type="pink"
              size="sm"
              rounded
              className="group"
              onClick={() => {
                Track.event.defer('banner_click', {
                  category: 'Checkout',
                  variant: variant,
                });
                FlyoverActions.open.defer({
                  type: 'inlinePricing',
                });
              }}
            >
              <span className="relative flex items-center">
                <span>{ctaCopy}</span>
              </span>
            </Button>
          </div>
        </div>
      }
    />
  );
};
