/** @format */

import React from 'react';
import classNames from 'classnames';
import type { WizardItem, WizardItemType } from './types';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { wizardActionMap } from './wizardActionMap';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Tooltip from '../feedback/tooltip/Tooltip';
import { WizardContext } from './WizardContext';

interface WizardItemProps {
  item: WizardItem;
  expanded?: boolean;
}

export const WizardListItem: React.FC<WizardItemProps> = ({
  item,
  expanded,
}) => {
  const { handleSkip } = React.useContext(WizardContext);

  const done = item.isComplete;
  const toDo = !item.isComplete && !expanded;
  const next = expanded;

  return (
    <div
      className={classNames(
        'flex group relative cursor-pointer items-center',
        done && 'border rounded-md bg-type-green/15 border-type-green',
        toDo && 'border rounded-md border-border',
        next &&
          'bg-decorative-blue/20 border border-decorative-blue rounded-lg hover:bg-decorative-blue/30 transition-all',
        done ? 'pr-2 pl-3 ' : 'pr-2 pl-4 ',
        next ? 'py-3' : 'py-2',
      )}
      onClick={wizardActionMap(item.id as WizardItemType)}
    >
      {item.isComplete && (
        <CheckCircleIcon className="flex-shrink-0 w-5 mr-1.5 text-type-green" />
      )}
      <div className={classNames('flex-auto', next && 'mr-3')}>
        <div
          className={classNames(
            '',
            next ? 'text-base font-semibold' : 'text-sm',
            done && 'pb-0.5',
          )}
        >
          <span
            className={classNames(
              done && 'text-type-green',
              toDo && 'text-type-subdued',
            )}
          >
            {item.title}
          </span>
          {next && (
            <span className="pl-1 group-hover:pl-1.5 transition-all text-brand-blue">{`→`}</span>
          )}
        </div>
        {next && (
          <div className="mt-1 mb-1 text-sm opacity-70">{item.description}</div>
        )}
      </div>

      <div className="flex-shrink-0">
        {/* {next && ( */}
        {/*   <div className="mx-4"> */}
        {/*     <MousePointerClick className="w-6 h-6 text-brand-blue" /> */}
        {/*   </div> */}
        {/* )} */}
        {next && (
          <Tooltip title={`Skip`} placement="top" zIndexClassName="z-[10001]">
            <XMarkIcon
              className="absolute w-5 opacity-0 cursor-pointer top-2 right-2 text-type-primary group-hover:opacity-20 hover:opacity-100 "
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSkip(item.id);
              }}
            />
          </Tooltip>
        )}
        {/* <WizardTokenCount */}
        {/*   count={item.credits} */}
        {/*   isComplete={item.isComplete} */}
        {/*   isExpanded={expanded} */}
        {/* /> */}
      </div>
    </div>
  );
};
