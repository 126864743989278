/** @prettier */
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import * as React from 'react';
import type { PDFContext } from '../types';
export const PDFGenerationInProgress: React.FC<{
  context?: PDFContext;
  title?: string;
  subtitle?: string;
}> = (props) => {
  return (
    <div className="px-10 pb-32">
      <LoadingIndicator />
    </div>
  );
};
