/** @prettier */
import * as React from 'react';
import Button from '../button/Button';
import Badge from '../badge/Badge';
import classNames from 'classnames';

import { formatCurrencyParts } from 'javascripts/helpers/currencyHelper';

import {
  type LocalPlanData,
  type Price,
} from 'blackbird/helpers/contexts/BillingContext';
import { SaleContext } from '../sale/SaleContext';
import { PricingTableFeatures } from './PricingTableFeatures';
import { PricingContext } from './PricingContext';

export interface PricingTablePlanProps {
  plan: LocalPlanData;
  price: Price;
}

export const PricingTablePlan: React.FC<PricingTablePlanProps> = ({
  plan,
  price,
}): React.ReactElement => {
  const [isCurrentPlan, setIsCurrentPlan] = React.useState(false);
  const [isSale, setIsSale] = React.useState(false);
  const [monthlyPrice, setMonthlyPrice] = React.useState<Price | undefined>(
    undefined,
  );

  const { activeSale } = React.useContext(SaleContext);
  const { billingInterval } = React.useContext(PricingContext);

  React.useEffect(() => {
    if (plan.prices) {
      setMonthlyPrice(
        plan.prices.find(
          (p) => p.interval === 'month' && p.currency === BoordsConfig.Currency,
        ),
      );
    }
  }, [plan.prices]);

  React.useEffect(() => {
    if (activeSale && billingInterval === 'year') {
      setIsSale(true);
    } else {
      setIsSale(false);
    }
  }, [activeSale, billingInterval]);

  React.useEffect(() => {
    if (
      BoordsConfig.PlanSlug === plan.slug &&
      BoordsConfig.SubscriptionStatus === 'active' &&
      ((billingInterval === 'year' && BoordsConfig.IsAnnual) ||
        (billingInterval === 'month' && !BoordsConfig.IsAnnual))
    ) {
      setIsCurrentPlan(true);
    } else {
      setIsCurrentPlan(false);
    }
  }, [plan.slug, billingInterval]);

  interface DiscountedPriceProps {
    discount: number;
  }

  const DiscountedPrice: React.FC<DiscountedPriceProps> = ({ discount }) => {
    const calculateDiscountedPrice = () => {
      const discountMultiplier = (100 - discount) / 100;
      const monthlyPrice = price.amount / (billingInterval === 'year' ? 12 : 1);
      return Math.floor((monthlyPrice * discountMultiplier) / 100);
    };

    return (
      <>
        <span className="ml-1.5 mr-0.5">
          {
            formatCurrencyParts({
              amount: 0,
              currency: BoordsConfig.Currency,
              showZeros: false,
            }).symbol
          }
        </span>
        <span className="text-xl">
          {
            formatCurrencyParts({
              amount: calculateDiscountedPrice(),
              currency: BoordsConfig.Currency,
              showZeros: false,
            }).value
          }
        </span>
      </>
    );
  };

  interface BasePriceProps {
    basePrice: Price;
    lineThrough: boolean;
    divideYearly?: boolean;
  }

  const BasePrice: React.FC<BasePriceProps> = ({
    basePrice,
    lineThrough,
    divideYearly = false,
  }) => {
    return (
      <div className="relative flex items-baseline">
        {lineThrough && (
          <div className="absolute top-3.5 -left-1 w-[120%] border border-b -rotate-[15deg] border-black/40 z-10" />
        )}
        <span
          className={classNames(
            'mr-0.5 text-base ',
            lineThrough ? 'text-black/30' : '',
          )}
        >
          {
            formatCurrencyParts({
              amount: 0,
              currency: BoordsConfig.Currency,
              showZeros: false,
            }).symbol
          }
        </span>
        <span
          className={classNames('mr-0 text-lg', lineThrough && 'text-black/30')}
        >
          {
            formatCurrencyParts({
              amount: Math.floor(
                basePrice.amount / (divideYearly ? 12 : 1) / 100,
              ),
              currency: BoordsConfig.Currency,
              showZeros: false,
            }).value
          }
        </span>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        'relative py-6 px-7 border-2 rounded-xl bg-surface-light',
        plan.featured ? 'border-brand-pink' : 'border-border ',
      )}
    >
      {/* Plan name */}
      <div className="flex items-center">
        <div className={classNames('mb-1 mr-2 font-semibold', 'text-xl')}>
          {plan.name}
        </div>
        {plan.featured && (
          <div className="-mt-1">
            <Badge label={`Top Rated`} color="pink" />
          </div>
        )}
      </div>

      {/* Price information */}
      <div className="mt-2 mb-4">
        <div className="flex items-baseline">
          {/* Base Price */}
          {isSale ? (
            <BasePrice basePrice={price} lineThrough divideYearly />
          ) : BoordsConfig.IsEdu && billingInterval === 'year' ? (
            <BasePrice basePrice={price} lineThrough divideYearly />
          ) : BoordsConfig.IsEdu && billingInterval === 'month' ? (
            <BasePrice basePrice={price} lineThrough />
          ) : billingInterval === 'year' && monthlyPrice ? (
            <BasePrice basePrice={monthlyPrice} lineThrough />
          ) : (
            <div className="-ml-1.5">
              <DiscountedPrice discount={0} />
            </div>
          )}

          {/* Sale or Education Price */}
          {isSale && !BoordsConfig.IsEdu && activeSale ? (
            <DiscountedPrice discount={activeSale.discountPercentage} />
          ) : BoordsConfig.IsEdu ? (
            <DiscountedPrice discount={50} />
          ) : billingInterval === 'year' ? (
            <DiscountedPrice discount={0} />
          ) : null}

          <span className="text-type-disabled whitespace-nowrap">
            {billingInterval === 'year' ? (
              <span className="ml-0.5 text-xs">{`/month, billed yearly`}</span>
            ) : (
              <span className="text-sm ml-1.5">{` per month`}</span>
            )}
          </span>
        </div>
      </div>

      <div className="mt-6 mb-4">
        <Button
          rounded
          disabled={isCurrentPlan}
          type={
            isCurrentPlan ? 'secondary' : plan.featured ? 'pink' : 'outline'
          }
          className={classNames('w-full rounded-full')}
          link={`/checkout/${plan.slug}/${billingInterval}ly${
            activeSale && billingInterval === 'year'
              ? `?coupon=${activeSale.coupon}`
              : ''
          }`}
        >
          <span className="font-normal">
            {isCurrentPlan ? <>{`Current Plan`}</> : <>{`Buy ${plan.name}`}</>}
          </span>
        </Button>
      </div>

      <div>
        <PricingTableFeatures plan={plan} />
      </div>
    </div>
  );
};
