/** @format */

import {
  type CharsetInfo,
  detectCharset,
} from 'javascripts/helpers/detectCharset';
import logger from 'javascripts/helpers/logger';
import { pipe } from 'javascripts/helpers/pipe';
import { rollbar } from 'javascripts/helpers/rollbar';
import { chain, clone, isEqual, isString, last, omit } from 'underscore';
import type { TokenListInfo } from '../../types';
import type { SequentialToken } from './splitUpTokensIntoLines';

interface ParsingState {
  isBold: boolean;
  isItalic: boolean;
  isDel: boolean;
  currentLink?: string | number;
  charset?: CharsetInfo;
  lastItemParsed?: supportedNode;
  list?: TokenListInfo;
}

const initialState: ParsingState = {
  isBold: false,
  isItalic: false,
  isDel: false,
  currentLink: undefined,
};

const remapNodeTypes: Record<string, supportedNode> = {
  b: 'strong',
  italic: 'em',
  i: 'em',
  '#text': 'text',
  ol: 'list',
  ul: 'list',
};

const newListItem = (
  type: string,
  id: string,
  level: number,
  props?: Partial<TokenListInfo>,
): TokenListInfo => ({
  id,
  type: type === 'OL' ? 'ordered' : 'unordered',
  currentMarker: type === 'OL' ? '1.' : '•',
  ...props,
  level,
  index: 0,
});

// prettier-ignore
const supportedNodeTypes = ['em', 'text', 'a', 'del', 'strong', 'p', 'br', 'li', 'list'] as const;
export type supportedNode = typeof supportedNodeTypes[number];

type Flatteners = {
  [name in supportedNode]: (t: ChildNode) => Array<SequentialToken | undefined>;
} & {
  text: (t: ChildNode | string) => Array<SequentialToken | undefined>;
};

let listId = 0;
const flattenTokens = (
  tokens: NodeListOf<ChildNode>,
  currentState: typeof initialState,
) => {
  const flatteners: Partial<Flatteners> = {
    // We want to reset the state every paragraph
    p: (p) => {
      return [
        ...flattenTokens(p.childNodes, {
          ...initialState,
          charset: detectCharset(p.textContent),
        }),
        { type: 'space', amount: 2 },
      ];
    },
    br: (p) => [{ ...p, type: 'space', amount: 1 }],
    text: (t) => {
      let fontStyle: any = 'normal';
      if (currentState.isBold && currentState.isItalic) {
        fontStyle = 'bolditalic';
      } else if (currentState.isBold) {
        fontStyle = 'bold';
      } else if (currentState.isItalic) {
        fontStyle = 'italic';
      }

      const textContent: string = isString(t) ? t : t.textContent;
      if (!textContent) return [];

      return [
        {
          type: 'text',
          // If any line breaks end up here, ignored by the markdown parser, we
          // want to ignore them here as well for visual consistency with other
          // parts of the app
          text: textContent.replace(/\n|\r|\t/g, ' '),
          isDel: currentState.isDel,
          link: currentState.currentLink,
          isRtl: currentState.charset?.isRtl,
          // Making a copy of list to freeze the `currentMarker` intact
          list: currentState.list ? { ...currentState.list } : undefined,
          fontStyle,
        } as SequentialToken,
      ];
    },
    em: (em) => {
      currentState.isItalic = true;
      const result = flattenTokens(em.childNodes, currentState);
      currentState.isItalic = false;
      return result;
    },
    del: (del) => {
      currentState.isDel = true;
      const result = flattenTokens(del.childNodes, currentState);
      currentState.isDel = false;
      return result;
    },
    strong: (strong) => {
      currentState.isBold = true;
      const result = flattenTokens(strong.childNodes, currentState);
      currentState.isBold = false;
      return result;
    },
    a: (link: HTMLAnchorElement) => {
      currentState.currentLink = link.href;
      const result = flattenTokens(link.childNodes, currentState);
      currentState.currentLink = undefined;
      return result;
    },
    list: (list) => {
      const previousList = currentState.list ?? undefined;
      const newState: ParsingState = {
        ...initialState,
        list: newListItem(
          list.nodeName,
          String(listId),
          (currentState.list?.level ?? 0) + 1,
        ),
      };

      const result = flattenTokens(list.childNodes, newState);
      currentState.list = previousList;
      listId++;

      // Create a "space" so we know we would need to start from a new paragraph
      // tag in word
      return [...result, { type: 'space', amount: 0 }];
    },
    li: (listItem) => {
      if (!currentState.list) {
        currentState.list = newListItem('UL', String(listId), 1);
        listId++;
        rollbar.warn(
          'expected list to be defined, probably cause by invalid HTML. Creating fake list for now',
        );
      }
      if (currentState.list.type === 'ordered') {
        currentState.list.currentMarker = `${currentState.list.index + 1}.`;
      }

      currentState.list.index!++;

      return [
        ...flattenTokens(listItem.childNodes, currentState),
        { type: 'space' },
      ];
    },
  };

  return chain(tokens)
    .map((i: ChildNode) => {
      const nodeName =
        remapNodeTypes[i.nodeName.toLowerCase()] ?? i.nodeName.toLowerCase();
      // Check if we support this HTML Node
      if (flatteners[nodeName]) {
        const output = flatteners[nodeName]!(i);
        currentState.lastItemParsed = nodeName;
        return output;
        // If we can extract the text from an unsupported node, try that
      } else if (i) {
        logger.log(
          'unsupported node of type, attempting to extract sound ' + nodeName,
        );
        return [flatteners.text!(i)];
      }
      logger.log('unsupported node of type ' + nodeName);
    })
    .flatten()
    .compact()
    .reduce<SequentialToken[]>((o, current, i) => {
      const previous = o[i - 1];
      if (
        previous?.type === 'text' &&
        isEqual(omit(previous, 'text'), omit(current, 'text'))
      ) {
        previous.text += current.text;
        return o;
      }

      o.push(current);
      return o;
    }, [])
    .value();
};

const trimSpaces = (tokens: SequentialToken[]): SequentialToken[] => {
  if (last(tokens)?.type === 'space') {
    tokens.pop();
    return trimSpaces(tokens);
  } else {
    return tokens;
  }
};

/** Merges adjacent spaces, adjusting `amount` accordingly */
const mergeSpaces = (tokens: SequentialToken[]) =>
  tokens.reduce<SequentialToken[]>((output, current) => {
    const lastItem = last(output);
    if (lastItem && lastItem.type === 'space' && current.type === 'space') {
      lastItem.amount = (lastItem.amount ?? 1) + (current.amount ?? 1);
    } else {
      output.push(current);
    }
    return output;
  }, []);

export const flattenHTMLTokens = (
  document: Document | DocumentFragment,
): SequentialToken[] => {
  const currentState = clone(initialState);
  const childnodes =
    document instanceof Document
      ? document.body.childNodes
      : document.childNodes;

  const flattened = flattenTokens(childnodes, currentState);
  return pipe(mergeSpaces, trimSpaces)(flattened);
};
