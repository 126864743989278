/** @format */
import { isArray } from 'underscore';

const dedupe = (str: string) => {
  switch (str.toLowerCase()) {
    case 'image/jpg':
    case 'image/jpeg':
      return 'image/jpg';

    case 'image/vnd.adobe.photoshop':
    case 'application/x-photoshop':
    case 'application/photoshop':
    case 'image/psd':
    case 'application/psd':
      return 'photoshop';

    // Audio
    case 'audio/mp3':
    case 'audio/mpeg':
      return 'mp3';
    case 'audio/x-m4a':
    case 'audio/m4a':
      return 'm4a';
    case 'audio/x-aiff':
    case 'audio/aiff':
      return 'aiff';
    case 'audio/vnd.wav':
    case 'audio/x-wav':
    case 'audio/wav':
      return 'wav';

    default:
      return str;
  }
};

/** checks if a MIME type is allowed and returns an error message if it's not.
 * Please note that this expects MIME types, not file extensions */
// TODO: test me
export const fileTypeListToString = (accept?: string[]): string => {
  if (!isArray(accept))
    throw new Error('list of accepted file types must be an array');

  const list = accept.reduce<string[]>((o, item) => {
    const deduped = dedupe(item).toUpperCase();
    if (o.indexOf(deduped) < 0) o.push(deduped);
    return o;
  }, []);

  if (list.length > 1) {
    const last = list.pop();
    return list.join(', ') + `, or ${last}`;
  }

  return list.join(', ');
};
