const createReactClass = require('create-react-class')
var PropTypes = require('prop-types');
window.ProjectTeam = createReactClass({

  timeout: null,

  propTypes: {
    project_team: PropTypes.object.isRequired,
  },

  getInitialState: function() {
    return {
      collapsed:          true,
      membersShownCount:  5,
    }
  },

  componentDidMount: function() {
    this.setState({
      collapsed:  false,
    });
  },

  _handleMoreMouseEnter: function(e) {
    e.preventDefault();
    clearTimeout(this.timeout);
    this.setState({
      collapsed:  false,
    });
  },

  _handleMouseOff: function(e) {
    e.preventDefault();
    this.timeout = setTimeout(function() {
      this.setState({
        collapsed:  true,
      });
    }.bind(this), 250);
  },

  _handleManageTeamClick: function(e) {
    e.preventDefault();
    if (this.props.project_team.is_admin) {
      FlyoverActions.open({
        type:         'projectTeam',
        projectId:    this.props.project_team.project_id,
        projectName:  this.props.project_team.project_name,
        teamId:       this.props.project_team.team_id,
      });
    }
  },

  render: function() {

    var project_team = this.props.project_team,
      label,
      teamButton,
      collapsedClass,
      remainderCount,
      hidden,
      remainder,
      teamMembers;


    teamMembers = project_team.active_teammates.map(function(member, i) {
      if (i >= this.state.membersShownCount) {
        return false;
      }
      hidden = this.state.collapsed ? true : false;
      return <ProjectTeamMember team_id={project_team.team_id} project_id={project_team.project_id} member={member} hidden={hidden} key={'project-team-member-' + project_team.project_id + "-" + member.membership_id} index={i} manageTeamClick={this._handleManageTeamClick} />;
    }.bind(this));

    collapsedClass = this.state.collapsed ? 'collapsed' : '' ;
    remainderCount = project_team.active_teammates.length - this.state.membersShownCount;

    if (remainderCount > 0) {
      remainder = (
        <div className="project__team__remainder type__heading--3">
          <small>+</small>
          {remainderCount}
        </div>
      );
    }

    if (this.props.project_team.is_admin) {

      teamButton = (
        <div aria-label={"Manage Team"} className="is--project-admin project__team__more hint--bottom" onMouseEnter={this._handleMoreMouseEnter} onClick={this._handleManageTeamClick}>
          <div>
            <i className="boords-icon--team-badge" />
            {remainder}
          </div>
        </div>
      );

    } else {

      teamButton = (
        <div className="project__team__more" aria-label="Project Team" onMouseEnter={this._handleMoreMouseEnter}>
          <i className="boords-icon--team-badge" />
          {remainder}
        </div>
      );

    }

    return (
      <div className={"project__team " + collapsedClass} onMouseEnter={this._handleMoreMouseEnter} onMouseLeave={this._handleMouseOff}>
        <div className="project__team__members">
          {teamMembers}
        </div>
        {teamButton}
      </div>
    )

  }

});
