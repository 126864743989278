/** @format */
import * as React from 'react';
import classNames from 'classnames';
import { FrameEditorActions } from 'javascripts/flux/actions/frame_editor';
import type { FrameEditorStore } from 'javascripts/flux/stores/frame_editor';
import { userColoursLocalState } from 'javascripts/helpers/local-state';
import { notUndefined } from 'javascripts/helpers/notUndefined';
import { usePersistedState } from 'javascripts/helpers/usePersistedState';
import { useStore } from 'javascripts/helpers/useStore';
import { useTranslation } from 'react-i18next';
import { contains, flatten, isString, uniq } from 'underscore';
import AddIcon from 'blackbird/images/icons/add-xsmall.svg';
import {
  type FrameEditorBackgroundColor,
  type FrameEditorDecoration,
  type FrameEditorBackgroundImage,
  isFrameEditorBackgroundImage,
} from '../frame_editor/FrameEditorDecoration';
import { ColorPicker } from '../shared/ColorPicker.react';
import {
  UnsplashCategories,
  type UnsplashTopic,
} from '../frame_editor/toolbar/image_search/UnsplashCategories';
import Icon from 'blackbird/components/icon/Icon';
import { FrameEditorContext } from '../frame_editor/FrameEditorContext';

interface Props {
  teamId: number;
}

const colorGroups = [
  // TODO: source of truth for these colors?
  ['#1F2937', '#4B5563', '#9CA3AF', '#FFFFFF'], // Gray colors
  ['#991B1B', '#EF4444', '#FCA5A5', '#FEE2E2'], // Red colors
  ['#B45309', '#F97316', '#FDBA74', '#FFEDD5'], // Orange colors
  ['#D97706', '#F59E0B', '#FDE047', '#FEF3C7'], // Yellow colors
  ['#059669', '#10B981', '#6EE7B7', '#A7F3D0'], // Green colors
  ['#1E3A8A', '#3B82F6', '#93C5FD', '#BFDBFE'], // Blue colors
  ['#7C3EDD', '#9333EA', '#D8B4FE', '#EDE9FE'], // Purple colors
];

const flattened = flatten(colorGroups);

const ColorItem = React.memo<{
  color: FrameEditorBackgroundColor;
  variation: 'active' | 'suggestion' | null;
  onClick: (color: FrameEditorBackgroundColor) => void;
  className?: string;
}>((props) => {
  const style: React.CSSProperties = {};
  if (props.variation === 'suggestion') {
    style.border = `2px solid ${props.color}`;
  } else {
    style.background = props.color;
  }
  return (
    <button
      type="button"
      className={classNames(
        'focus:ring focus:z-10 min-w-6 h-6 flex items-center justify-center',
        props.variation !== 'suggestion' && 'border-border-image',
        props.className,
      )}
      onClick={() => props.onClick(props.color)}
      style={style}
    >
      {props.variation === 'suggestion' ? <Icon icon={<AddIcon />} /> : null}
    </button>
  );
});

ColorItem.displayName = 'ColorItem';

const backgroundInfoToUI = (
  background?: FrameEditorDecoration['background'] | null,
): {
  subtitle: string;
  placeholderStyle: React.CSSProperties;
} => {
  if (!background) {
    return {
      subtitle: '#ffffff',
      placeholderStyle: {
        backgroundColor: '#ffffff',
      },
    };
  } else if (isString(background)) {
    return {
      subtitle: background,
      placeholderStyle: {
        backgroundColor: background,
      },
    };
  } else if (isFrameEditorBackgroundImage(background)) {
    return {
      subtitle: 'Image',
      placeholderStyle: {
        backgroundImage: `url(${background.url})`,
        backgroundSize: 'cover',
      },
    };
  } else {
    return { subtitle: 'not implemented', placeholderStyle: {} };
  }
};

export const PanelbarBackgroundSelector = React.memo<Props>((props) => {
  const currentDecoration = useStore<
    FrameEditorDecoration | null,
    FrameEditorStore
  >('frameEditor', (s) => s.currentDecoration);
  const editorContext = React.useContext(FrameEditorContext);
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = usePersistedState(
    'panelbarBackgroundTabSelected',
    'solid',
  );
  const [savedColors, setSavedColors] = React.useState<string[]>(
    userColoursLocalState.getValue() ?? [],
  );

  const handleUpdateBackground = React.useCallback(
    (value: FrameEditorDecoration['background']) => {
      FrameEditorActions.updateDecoration({
        background: value,
      });
    },
    [],
  );

  const handleSetBackgroundImage = React.useCallback(
    (string: string) => {
      if (!editorContext) return;

      editorContext.copyImage(string).then((result) => {
        if (!result) return;

        const background: FrameEditorBackgroundImage = {
          url: result.url,
        };
        FrameEditorActions.updateDecoration({
          background,
        });
      });
    },
    [editorContext],
  );

  const additionalColors = React.useMemo(
    () =>
      uniq([...savedColors]).filter(
        (i) => notUndefined(i) && !contains(flattened, i),
      ),
    [savedColors],
  );

  const currentColor =
    currentDecoration && isString(currentDecoration?.background)
      ? currentDecoration.background
      : null;

  const currentColorIsInSwatches = React.useMemo(() => {
    try {
      if (!currentColor) {
        return false;
      }
      return contains(
        [...flattened, ...additionalColors].map((color) => color.toLowerCase()),
        currentColor.toLowerCase(),
      );
    } catch (e) {
      return false;
    }
  }, [additionalColors, currentColor]);

  const gridClassNames = 'grid gap-2 grid-cols-7';

  const UIForBackground = backgroundInfoToUI(currentDecoration?.background);

  const handleAddColor = React.useCallback((color: string) => {
    userColoursLocalState.addToArray(color);
    setSavedColors(userColoursLocalState.getValue() ?? []);
  }, []);

  // Attempt to retrieve the topic for the unsplash
  const topics: UnsplashTopic[] = t('unsplash.topics', {
    returnObjects: true,
    ns: 'imageLibrary',
  });
  const topic = topics?.find?.((i) => i.slug === 'textures-patterns');

  return (
    // Ideally, we'd reduce the spacing to px-3 when we are using the small
    // variation of the panelbar
    <div className="px-6 pt-5">
      <ColorPicker
        showUserSwatches={false}
        showAlpha={false}
        icon={
          <div className="flex items-center gap-3">
            <div
              style={UIForBackground.placeholderStyle}
              className="w-12 h-12 border cursor-pointer border-border-image rounded-md"
            />

            <div className="-mt-2">
              <div className="text-lg font-semibold text-black">{`Background`}</div>
              <div
                className={classNames(
                  'text-sm underline cursor-pointer text-type-subdued decoration-dotted underline-offset-2 decoration-border-mid hover:no-underline',
                  UIForBackground.subtitle !== 'Image' && 'uppercase',
                )}
              >
                {UIForBackground.subtitle}
              </div>
            </div>
          </div>
        }
        onChange={(color) =>
          handleUpdateBackground(color as FrameEditorBackgroundColor)
        }
      />

      <div className="mt-5">
        <div className={classNames('my-5', gridClassNames)}>
          {colorGroups.map((group) => (
            <div
              key={group[0]}
              className="flex flex-col overflow-hidden border rounded-md border-border-image"
            >
              {group.map((color) => (
                <ColorItem
                  color={color as FrameEditorBackgroundColor}
                  key={color}
                  variation={currentColor === color ? 'active' : null}
                  onClick={handleUpdateBackground}
                />
              ))}
            </div>
          ))}
        </div>

        <div
          className={classNames(
            gridClassNames,
            additionalColors.length === 0 &&
              currentColorIsInSwatches &&
              'hidden',
          )}
        >
          {additionalColors.map((i) => (
            <ColorItem
              color={i as FrameEditorBackgroundColor}
              key={i}
              variation={currentColor === i ? 'active' : null}
              onClick={handleUpdateBackground}
              className="h-6 border rounded-md border-border-image"
            />
          ))}

          {!currentColorIsInSwatches ? (
            <ColorItem
              color={currentColor as FrameEditorBackgroundColor}
              variation="suggestion"
              onClick={handleAddColor}
              className="w-full h-6 rounded-md"
            />
          ) : null}
        </div>
      </div>

      {topic ? (
        <div className={classNames('mt-6 mb-8')}>
          <UnsplashCategories
            topic={topic}
            onItemClick={handleSetBackgroundImage}
          />
        </div>
      ) : null}
    </div>
  );
});

PanelbarBackgroundSelector.displayName = 'PanelbarBackgroundSelector';
