/** @prettier */
import * as React from 'react';
import Button from 'blackbird/components/button/Button';
import Popover from 'blackbird/components/common/popover/Popover';
import {
  ImageSourcesMultiPanelDefaultWidth,
  ImageSourcesMultiPanel,
} from './AddImageMultipanel/ImageSourcesMultiPanel';
import { notificationIfCannotCreateNewFrame } from '../../helpers/can-create-new-frame';
import type { IStoryboardInStore } from 'javascripts/types/storyboard';
import { FrameActions } from 'javascripts/flux/actions/frame';
import { GridViewDropZone } from './GridviewDropzone';
import { useContainerQuery } from 'react-container-query';
import { CirclePlus, SquarePlus } from 'lucide-react';

interface Props {
  storyboard: IStoryboardInStore;
  sort_order: number;
}

const query = {
  expanded: {
    minHeight: 150,
  },
};

const defaultSize = { width: 200, height: 200 };

export const FrameAddButton: React.FC<Props> = (props) => {
  const [{ expanded }, containerRef] = useContainerQuery(query, defaultSize);
  const handleAddButtonClick = React.useCallback<
    React.MouseEventHandler<HTMLElement>
  >(
    (e) => {
      // If we clicked the browse button deeper in the hierarchy, we don't want
      // to create a new frame (there currently isn't a way of preventing that
      // event from bubbling)
      if (e.target instanceof HTMLElement && e.target.dataset.browse) {
        return false;
      }
      // don't do anything if we have clicked the upload button
      //  if (e.target.nodeName === 'BUTTON') return;
      // If creating a new frame is not allowed, stop and show a notification
      if (notificationIfCannotCreateNewFrame(FrameStore.getState())) return;
      e.preventDefault();
      e.stopPropagation();
      FrameActions.insertFrame({
        storyboard_id: props.storyboard.id,
        dropped_sort_order: parseInt(String(props.sort_order)) + 1,
        move_frames_after_here: true,
      });
    },
    [props.sort_order, props.storyboard.id],
  );

  const handleUploadBtnClick =
    React.useCallback<React.MouseEventHandler>(() => {
      // Because we defer to the Image Sources flyover, we only want to do
      // anything here if creating a new frame is not allowed.
      // In that case, we stop and show a notification, otherwise we don't do
      // anything
      if (notificationIfCannotCreateNewFrame(FrameStore.getState())) return;
    }, []);

  return (
    <div className="flex flex-col p-3 frame__wrapper new" ref={containerRef}>
      <div className="relative flex flex-col flex-grow">
        <GridViewDropZone
          storyboard={props.storyboard}
          sortOrder={props.sort_order}
          className="flex flex-col flex-grow w-full border rounded-lg bg-surface-frame hover:bg-surface-add_frame group border-border-mid"
        >
          <div
            className="flex flex-col items-center justify-center flex-grow w-full py-4 cursor-pointer"
            onClick={handleAddButtonClick}
          >
            {expanded ? (
              <div className={`text-center -mt-2`}>
                <div className="flex justify-center text-type-disabled group-hover:text-brand-pink">
                  <CirclePlus size={48} strokeWidth={1} />
                </div>
                <div className="mt-4 mb-1">{`Add Frame`}</div>

                <Popover placement="top">
                  <Popover.Button as="div">
                    <div className="flex items-center space-x-1">
                      <span className="text-sm text-type-subdued">{`or`}</span>
                      <Button
                        type="text"
                        htmlType="button"
                        className="text-sm !font-normal underline !text-type-subdued underline-offset-2 decoration-black/20 hover:no-underline hover:!bg-transparent !px-0 !py-0"
                        onClick={handleUploadBtnClick}
                        data-browse
                      >
                        {`upload files`}
                      </Button>
                    </div>
                  </Popover.Button>
                  <Popover.Panel className="z-40">
                    <ImageSourcesMultiPanel
                      width={ImageSourcesMultiPanelDefaultWidth}
                      storyboard={props.storyboard}
                      replace={false}
                    />
                  </Popover.Panel>
                </Popover>
              </div>
            ) : null}
          </div>
        </GridViewDropZone>
      </div>
    </div>
  );
};
