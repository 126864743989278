/** @format */

/** Insert the HTML string as a fragment in place of the user's selection */
export const insertAtSelection = (
  fragment: DocumentFragment | Element | string,
  plainText = false,
) => {
  const selection = window.getSelection();
  if (!selection?.rangeCount) return;

  if (typeof fragment === 'string') {
    document.execCommand('insertText', false, fragment);
  } else if (plainText) {
    document.execCommand('insertText', false, fragment.textContent!);
  } else if (fragment instanceof DocumentFragment) {
    const contents = document.createElement('div');
    contents.append(fragment);
    document.execCommand('insertHTML', false, contents.innerHTML);
  } else {
    document.execCommand('insertHTML', false, fragment.innerHTML);
  }

  selection.collapseToEnd();
};
