/** @prettier */
/* eslint  react-perf/jsx-no-new-object-as-prop:0 */
import * as React from 'react';
import * as OverlayListItem from '../../shared/OverlayListItem';
import type { fileStackSource } from '../../../flux/stores/filestack';
import type { IPanelProps } from '../../shared/MultiPanel';
import type { IStoryboardInStore } from '../../../types/storyboard';
import { FilestackActions } from '../../../flux/actions/filestack';
import type { DetailedFrame, IFrame } from '../../../types/frame';
import FrameUserActions from '../../shared/FrameUserActions';
import { size, some } from 'underscore';
import UnsplashIcon from 'blackbird/images/icons/unsplash.svg';
import DropboxIcon from 'blackbird/images/icons/dropbox.svg';
import GoogleDriveIcon from 'blackbird/images/icons/google-drive.svg';
import ClearFrameIcon from 'blackbird/images/icons/clear-frame.svg';
import EditIcon from 'blackbird/images/icons/edit.svg';
import UploadIcon from 'blackbird/images/icons/upload.svg';
import DuplicateIcon from 'blackbird/images/icons/duplicate-2.svg';
import { isImagePlaceholder } from 'javascripts/helpers/isImagePlaceholder';

const listItemProps = {
  showBorder: false,
  center: false,
  showArrow: false,
  padding: 'px-1',
  height: 'h-[42px]',
  size: 'small',
};

/** Used in the Checkout overlay when free users try to use web sources */
const nameMap = {
  dropbox: 'Dropbox',
  googledrive: 'Google Drive',
};

interface Props {
  storyboard: IStoryboardInStore;
}

export interface AddProps extends Props {
  replace: false;
}

export interface ReplaceProps extends Props {
  replace: true;
  currentFrame: IFrame;
}

export class ImageSourcesPanel extends React.PureComponent<
  (AddProps | ReplaceProps) & IPanelProps,
  { actions: ReturnType<typeof FrameUserActions>; hasImages: boolean }
> {
  constructor(props) {
    super(props);
    this.state = ImageSourcesPanel.getActions(props);
  }
  /**
   * If a service is limited to paid users, this handler will open up a checkout
   * overlay instead of the Filestack overlay
   */
  handleLimitedSourceClick = (e: React.MouseEvent<HTMLElement>) => {
    const source = (e.currentTarget as HTMLButtonElement)
      .value as fileStackSource;
    const name = nameMap[source];
    if (!name)
      throw new Error(
        `could not find name for service ${source} in ${Object.keys(nameMap)}`,
      );

    if (BoordsConfig.Freeloader && BoordsConfig.IsStoryboardAdmin) {
      FlyoverActions.open.defer({
        type: 'checkout',
        plan_name: 'individual',
        frequency: 'monthly',
        props: {
          checkoutHeader: 'Upgrade to upload images from ' + name,
        },
      });
    } else {
      this.handleFilestackSourceClick(e);
    }
  };

  handleFilestackSourceClick = (e: React.MouseEvent<HTMLElement>) => {
    const source = (e.currentTarget as HTMLButtonElement)
      .value as fileStackSource;

    const props = this.props.replace
      ? {
          replace: true,
          frame: this.props.currentFrame,
          multi: false,
        }
      : {
          multi: true,
        };

    FilestackActions.openDialog({
      team_id: this.props.storyboard.project.owner.id,
      storyboard_id: this.props.storyboard.id,
      frame_aspect_ratio: this.props.storyboard.frame_aspect_ratio,
      initial_source: source,
      ...props,
    });

    Track.event.defer('open_image_search');
    Track.once.defer('open_' + source);
  };

  static getActions(props) {
    // I know, I know…
    const { frames, groupInfo } = FrameStore.getState();

    const hasImages = some(
      frames,
      (i: DetailedFrame) =>
        i.large_image_url && !isImagePlaceholder(i.large_image_url),
    );

    const actions = FrameUserActions({
      isGuest: BoordsConfig.TeamRole === 'guest',
      commentsEnabled: props.storyboard.has_comments_enabled,
      framesToOperateOn: props.replace ? [props.currentFrame] : [],
      storyboard: props.storyboard,
      frameCount: frames.length,
      groupInfo: groupInfo,
    });

    return { actions, hasImages };
  }

  static getDerivedStateFromProps(props) {
    return ImageSourcesPanel.getActions(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (size(prevState.actions) !== size(this.state.actions)) {
      this.props.updateHeight();
    }
  }

  render() {
    const actions = this.state.actions;

    return (
      <div className="p-2">
        {actions.upload ? (
          <OverlayListItem
            {...listItemProps}
            onClick={this.props.goToPanelF('frameInStoryboard')}
            text="Copy from frame"
            showArrow={true}
            icon={<DuplicateIcon />}
            disabled={!this.state.hasImages}
          />
        ) : null}

        {actions.upload && !BoordsConfig.CustomStorage ? (
          <>
            <OverlayListItem
              {...listItemProps}
              onClick={this.handleLimitedSourceClick}
              text="Dropbox"
              value="dropbox"
              icon={<DropboxIcon />}
            />
            <OverlayListItem
              {...listItemProps}
              onClick={this.handleLimitedSourceClick}
              text="Google Drive"
              value="googledrive"
              showBorder={false}
              center={false}
              showArrow={false}
              icon={<GoogleDriveIcon />}
            />
          </>
        ) : null}
        {actions.upload ? (
          <OverlayListItem
            {...listItemProps}
            onClick={this.handleFilestackSourceClick}
            text={this.props.replace ? 'Upload Image' : 'Upload Images'}
            value="local_file_system"
            icon={<UploadIcon />}
          />
        ) : null}

        {actions.clear && (
          <OverlayListItem
            {...listItemProps}
            onClick={actions.clear.onClick}
            text="Remove image"
            icon={<ClearFrameIcon />}
          />
        )}

        {actions.edit && (
          <OverlayListItem
            {...listItemProps}
            onClick={actions.edit.onClick}
            text="Open Editor"
            icon={<EditIcon />}
          />
        )}
      </div>
    );
  }
}
