/** @format */

export type EventSource =
  | 'grid'
  | 'panelbar'
  | 'presentation'
  | 'editorPanelbar'
  | 'playerPanelbar'
  | 'gridPanelbar';

export type DownloadFiletype =
  | 'docx'
  | 'pdf'
  | 'mp4'
  | 'xlsx'
  | 'txt'
  | 'google_slides'
  | 'zip';

export const eventSource = (context: EventSource | undefined) => {
  const path = window.location.pathname;
  let source: EventSource =
    BoordsConfig.controller === 'shareable'
      ? 'presentation'
      : context
      ? context
      : 'panelbar';

  if (source === 'panelbar' && path.includes('/storyboards/')) {
    if (path.includes('/editor/')) {
      source = 'editorPanelbar';
    } else if (path.includes('/player/')) {
      source = 'playerPanelbar';
    } else {
      source = 'gridPanelbar';
    }
  }
  return source;
};

export const downloadEvent = (filetype: DownloadFiletype) => {
  Track.event.defer('export', {
    context: eventSource(undefined),
    filetype: filetype,
    category: 'Product',
  });
};
