/** @format */
/* eslint react-perf/jsx-no-new-object-as-prop:0 */
import * as React from 'react';
import { MultiPanel } from '../../shared/MultiPanel';
import { FrameInStoryboardPanel } from './FramesInStoryboardPanel';
import {
  ImageSourcesPanel,
  type AddProps,
  type ReplaceProps,
} from './ImageSources';
import { UnsplashPanel } from './UnsplashPanel';

export const ImageSourcesMultiPanelDefaultWidth = 270;
type Props = (ReplaceProps | AddProps) & { width?: number };

export const ImageSourcesMultiPanel: React.FC<Props> = (props) => {
  const width = props.width ?? ImageSourcesMultiPanelDefaultWidth;
  const propsWithWidth = { ...props, width };

  return (
    <MultiPanel
      width={width}
      defaultPanel="index"
      innerPadding=""
      panels={{
        index: {
          Component: ImageSourcesPanel,
          props: propsWithWidth,
        },
        unsplash: { Component: UnsplashPanel, props: propsWithWidth },
        frameInStoryboard: {
          Component: FrameInStoryboardPanel,
          props: propsWithWidth,
          title: 'Copy from frame',
        },
      }}
    />
  );
};
