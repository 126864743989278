/** @prettier */
import React, { useEffect, useRef, useState } from 'react';
import BoltIcon from '../../images/icons/bolt.svg';
import Icon from '../icon/Icon';
import classNames from 'classnames';
import { useStore } from '../../../javascripts/helpers/useStore';
import {
  ChangelogStore,
  IChangelogItem,
} from '../../../javascripts/flux/stores/changelog';
import { Listbox } from '@headlessui/react';
import Badge from '../badge/Badge';
import useCustomPopper from '../../helpers/hooks/useCustomPopper';
import { useStoreAction } from '../../../javascripts/helpers/useStoreAction';
import { useOnMount } from '../../../javascripts/helpers/useOnMount';
import type { timeout } from 'blackbird/helpers/types';

interface UpdatesItemsProps {
  item: IChangelogItem;
  active?: boolean;
}
interface UpdatesDropdownProps {
  hasUpdates: boolean;
  items: IChangelogItem[];
  title: string;
  onSelectItem?: (
    value: React.MutableRefObject<HTMLAnchorElement | null> | (() => void),
  ) => void;
}
interface UpdatePanelProps extends UpdatesDropdownProps {
  open?: boolean;
}
const UpdatesItem = React.forwardRef<HTMLAnchorElement, UpdatesItemsProps>(
  (props, ref) => {
    const { item, active } = props;
    return (
      <div className="flex cursor-pointer flex-col space-y-2">
        <a
          href={item.url}
          className={classNames('font-body font-medium', { underline: active })}
          target="_blank"
          rel="noopener noreferrer"
          ref={ref}
        >
          {item.title}
        </a>
        <div>
          <Badge label={item.type} size="lg" color="yellow" />
        </div>
        <p className="text-sm text-type-subdued">{item.metaDescription}</p>
      </div>
    );
  },
);
UpdatesItem.displayName = 'UpdatesItem';

const UpdatesListOption = React.forwardRef<
  HTMLAnchorElement,
  { item: IChangelogItem }
>((props, ref) => {
  const { item } = props;
  const refValue = ref ?? React.useRef<HTMLAnchorElement>(null);
  return (
    <Listbox.Option
      className={({ active }) =>
        classNames(`${active ? 'bg-surface-light' : ''}`, 'py-2 px-6')
      }
      key={item.slug}
      value={refValue}
    >
      {({ active }) => (
        <UpdatesItem ref={refValue} item={item} active={active} />
      )}
    </Listbox.Option>
  );
});
UpdatesListOption.displayName = 'UpdatesListOption';

const UpdatesPanel: React.FC<UpdatePanelProps> = (props) => {
  const timer = useRef<timeout>();
  const handleMarkAsRead = useStoreAction('ChangelogActions', 'markAsRead');
  const { open, items } = props;
  const handleOpen = React.useCallback(() => {
    timer.current = setTimeout(handleMarkAsRead, 1500);
  }, [timer, handleMarkAsRead]);
  const handleClose = React.useCallback(
    () => timer.current && clearTimeout(timer.current),
    [timer],
  );

  useEffect(() => {
    open ? handleOpen() : handleClose();
  }, [open, handleOpen, handleClose]);
  return (
    <>
      <div className="text-lg px-6 pb-2 pt-6">
        {props.title}
        {/**
  Spacer  ,using margins wont work as they will just collapse with the margin from the
  below element
  */}
        <div className="h-[10px]"></div>
      </div>
      {items.map((item) => (
        <UpdatesListOption key={item.slug} item={item} />
      ))}

      <div className="border-t-border border-t sticky px-6 py-4 bottom-0 bg-white z-50">
        <a
          href="https://boords.com/blog/"
          className="hover:underline text-sm"
          target="_blank"
        >
          See all updates
        </a>
      </div>
    </>
  );
};

export const UpdatesDropdown: React.FC<UpdatesDropdownProps> = (props) => {
  const [parentRef, setParentRef] = useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [selectedItem, setSelectedItem] = useState<
    React.MutableRefObject<HTMLAnchorElement | null> | (() => void)
  >();
  const { hasUpdates } = props;
  const handleChange = (
    selectedItem:
      | React.MutableRefObject<HTMLAnchorElement | null>
      | (() => void),
  ) => {
    setSelectedItem(selectedItem);
    props.onSelectItem && props.onSelectItem(selectedItem);
  };

  const { styles, attributes } = useCustomPopper(parentRef, popperElement, {
    placement: 'left-start',
    distance: 4,
  });

  return (
    <Listbox value={selectedItem} onChange={handleChange}>
      <Listbox.Button
        className={classNames(
          'rounded-l-full border-2 p-2 pr-1 border-r-0 border-black',
          hasUpdates ? 'bg-black text-white' : '',
        )}
        ref={(ref) => setParentRef(ref)}
      >
        <Icon icon={<BoltIcon />} color="none" />
      </Listbox.Button>
      {/*
          Listbox.Options component is mounted on and off the DOM
          when we click on ListBox.Button. But for PopperJS to calculate the correct position,
          it needs the panel to be mounted. Therefore, if we only use Listbox.Options without
          this extra div, then initially clicking on Listbox.Button won't give us the correct
          positioning and it would appear slightly shifted towards the top.
          But on the subsequent clicks, it will align correctly.
          More info on here : https://www.notion.so/presentable/UpdatesDropDown-PopperJS-Alignment-Issue-eccf8ea11bee415c921ae19019d9d2d4
        */}
      <div
        ref={(ref) => setPopperElement(ref)}
        className="bg-white z-40 space-y-4 overflow-auto flex-col max-w-xs shadow-lg rounded-lg focus:outline-none"
        style={styles.popper}
        {...attributes.popper}
      >
        <Listbox.Options>
          {({ open }) => <UpdatesPanel {...props} open={open} />}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export const UpdatesDropdownContainer: React.VFC = () => {
  const hasUpdates = useStore<boolean, ChangelogStore>(
    'changelog',
    (state) => state.hasNewItems,
  );
  const items = useStore<IChangelogItem[], ChangelogStore>(
    'changelog',
    (state) => state.items ?? [],
  );
  const handleSelectItem = (
    value: React.MutableRefObject<HTMLAnchorElement | null> | (() => void),
  ) => {
    if (!value) return;
    if (typeof value === 'function') return value();
    value.current?.click();
  };

  const fetchFn = useStoreAction('ChangelogActions', 'fetch');
  useOnMount(fetchFn, false);

  return (
    <UpdatesDropdown
      hasUpdates={hasUpdates}
      items={items}
      onSelectItem={handleSelectItem}
      title="What’s new with Boords?"
    />
  );
};
