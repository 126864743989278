/** @prettier */
const createReactClass = require('create-react-class');
const { Button } = require('../shared/Button');
const statuses = require('../../helpers/frame-statuses')();
const { noop } = require('underscore');

module.exports = createReactClass({
  displayName: 'FrameStatusPopupMenu',

  _setStatus(e, status) {
    e.preventDefault();
    FrameActions.setFramesStatus({
      frames: this.props.framesToOperateOn,
      status: status,
    });
    Track.event.defer('frame_status_change');
  },

  _clear(e) {
    this._setStatus(e, '');
  },

  _progress(e) {
    this._setStatus(e, statuses.progress.label);
  },

  _approved(e) {
    this._setStatus(e, statuses.approved.label);
  },

  _review(e) {
    this._setStatus(e, statuses.review.label);
  },

  render() {
    var components = [
      {
        onClick: this._progress,
        status: statuses.progress,
      },
      {
        onClick: this._review,
        status: statuses.review,
      },
      {
        onClick: this._approved,
        status: statuses.approved,
      },
      {
        onClick: this._clear,
        status: statuses.clear,
      },
    ];

    const button = (
      <Button
        preset="transparent"
        size="menu"
        icon="status"
        text="Status"
        onClick={noop}
        noPadding
        iconOnly
      />
    );

    return (
      <PopupMenu
        position="bottom"
        button={button}
        statuses={components}
        opensOnIconHover={true}
        handleFocus={false}
      />
    );
  },
});
