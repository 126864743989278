/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import '../actions/banner';
import { CodeVersionMonitor } from '../../monitors/CodeVersionMonitor';
import { ConnectionMonitor } from '../../monitors/ConnectionMonitor';
import { UnsupportedExtensionMonitor } from '../../monitors/UnsupportedExtensionMonitor';
import { StoryboardVersionMonitor } from 'javascripts/monitors/StoryboardVersionMonitor';
import { includes, isObject, min } from 'underscore';
import { BannerActions } from '../actions/banner';

/**
 * List of possible banner types, order is important! The top one will get
 * the highest priority (ie. will be visible) if multiple banners are active */
export const possibleBannerTypes = [
  'unsupported_browser',
  'offline',
  'freemium',
  'trialing',
  'sunset',
  'sunset_pending',
  'newVersion',
  'billing_alert',
  'pre_signup_invitation',
  'unsupported_extension',
  'freeloader',
  'offer',
  'newStoryboardVersion',
  'annual_upgrade',
  'v3_trial',
  'cc_trial',
  'puffin_trial',
  'confirm_email',
] as const;

type possibleBannerType = typeof possibleBannerTypes[number];

interface CommonBannerProps {
  type: possibleBannerType;
  closable?: boolean;
  location?: 'top' | 'bottom';
  data?: any;
}

export type BannerProps = CommonBannerProps;
export class BannerStore extends FakeAltStoreClass<BannerStore> {
  activeBanners: BannerProps[] = [];
  activeBanner?: BannerProps;

  constructor() {
    super();

    this.bindListeners({
      handleInit: BannerActions.INIT,
      handleAdd: BannerActions.ADD,
      handleRemove: BannerActions.REMOVE,
    });
  }

  handleInit(initialBanner?: BannerProps) {
    if (initialBanner) this.handleAdd(initialBanner);

    new CodeVersionMonitor((hasNewVersion) =>
      this.toggle('newVersion', hasNewVersion),
    );

    new ConnectionMonitor((isOnline) => this.toggle('offline', !isOnline));
    new UnsupportedExtensionMonitor((info) =>
      this.toggle('unsupported_extension', !!info, info),
    );

    new StoryboardVersionMonitor((info) =>
      this.toggle('newStoryboardVersion', !!info, info),
    );
  }

  toggle(bannerType: possibleBannerType, shouldShow, data?: unknown) {
    if (shouldShow) {
      this.handleAdd({ type: bannerType, data } as any);
    } else {
      this.handleRemove(bannerType);
    }
  }

  handleAdd(banner: BannerProps) {
    if (!includes(possibleBannerTypes, banner.type)) return;
    this.handleRemove(banner.type);
    this.activeBanners.push(banner);
    this.handleUpdate();
  }

  handleRemove(bannerType: possibleBannerType) {
    this.activeBanners = this.activeBanners.filter(
      (b) => b.type !== bannerType,
    );
    this.handleUpdate();
  }

  handleUpdate() {
    const activeBanner = min(this.activeBanners, (banner) => {
      const index = possibleBannerTypes.indexOf(banner.type);
      if (index < 0)
        throw new Error(
          `could not find banner type ${banner.type} in possibleBannerTypes`,
        );
      return index;
    });

    this.activeBanner = isObject(activeBanner) ? activeBanner : undefined;

    // Using toggle here would be nice, but it's not supported everywhere
    if (this.activeBanner) {
      document.body.classList.add('has--banner');
    } else {
      document.body.classList.remove('has--banner');
    }
    this.emitChange();
  }
}

(window as any).BannerStore = alt.createStore(BannerStore, 'BannerStore');
