/** @prettier
 * Generates a 2-character abbreviation for the frame field, used
 * in the absence of an icon
 */

export const generateFrameFieldLabel = (label: string) => {
  const split = label.split(' ');
  const firstItems =
    split.length > 1
      ? split
          .slice(0, 2)
          .map(i => i[0])
          .join('')
          .toUpperCase()
      : label[0].toUpperCase() + (label[1] || '').toLowerCase();
  return firstItems;
};
