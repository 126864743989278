const _ = require('underscore');
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types');

window.AdminProjectTransfer = createReactClass({

  propTypes: {
    project_id: PropTypes.number.isRequired,
  },
  getInitialState() {
    return {
      transferred: false,
      email: "",
    }
  },

  _transfer: function(e) {

    var email = prompt("Please enter the email address of the user you would like to transfer this project to");

    if (!_.isEmpty(email)) {

      this.setState({
        email: email,
      });

      ajaxHelper.ajax({
        method: "post",
        url: "/admin/transfer_project.json",
        data: {
          project_id: this.props.project_id,
          email: email,
        },
        success: function () {
          RequestActions.success.defer("Project successfully transferred to " + email);
          this.setState({
            transferred: true,
          });
        }.bind(this),
        error: function(response) {
          RequestActions.error.defer(response.responseJSON.error);
        }.bind(this),
      });
    } else {
      RequestActions.error.defer("Project not transferred");
    }
  },

  render: function() {
    if (this.state.transferred) {
      return (
        <AdminButton text={"Transferred to " + this.state.email}/>
      );
    }
    return (
      <AdminButton onClick={this._transfer} text="Transfer Ownership"/>
    );
  }
});
